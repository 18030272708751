import { Metrics, SiteByProperty } from "../../../models/models";
import {
  PropertyHistory,
  PropertySplit,
  TableMetrics,
  TableProperty,
} from "./types";
import { SortOrder } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { handleSortByMetric } from "../../../helpers";
import { handleSortProperties, reduceProperties } from "./helpers";
import { groupByRegion } from "../Locations/helpers";

export type PropertyContentState = {
  properties: TableProperty[];
  propertyHistory: PropertyHistory[];
  selectedMetrics: (keyof TableMetrics)[];
  sortField: keyof TableMetrics;
  sortOrder: SortOrder;
  selectedRows: number[] | undefined;
  registeredProperties: number[];
};

export type PropertyContentActions =
  | {
      type: "UPDATE_TABLE_STATE";
      payload: { sortField: keyof TableMetrics; sortOrder: SortOrder };
    }
  | {
      type: "UPDATE_PROPERTIES_DATA";
      payload: {
        currentByProperty?: SiteByProperty[];
        propertySplits?: { propertyId: number; data: PropertySplit }[];
      };
    }
  | {
      type: "UPDATE_SELECTED_METRICS";
      payload: { selectedMetrics: (keyof TableMetrics)[] };
    }
  | {
      type: "ADD_SELECTED_ROW";
      payload: { propertyId: number };
    }
  | {
      type: "REMOVE_SELECTED_ROW";
      payload: { propertyId: number };
    }
  | {
      type: "UPDATE_PROPERTIES_HISTORY";
      payload: { history: PropertyHistory };
    }
  | {
      type: "ADD_PROPERTY_SPLIT";
      payload: { propertyID: number };
    }
  | {
      type: "REMOVE_PROPERTY_SPLIT";
      payload: { propertyID: number };
    }
  | {
      type: "UPDATE_PROPERTY_HISTORY";
      payload: { currentByProperty: SiteByProperty[] };
    };

export const initialState: PropertyContentState = {
  sortField: Metrics.reqPerSec,
  sortOrder: "desc",
  selectedRows: undefined,
  selectedMetrics: [
    Metrics.reqPerSec,
    Metrics.fourZeroFourPerSec,
    Metrics.fiveZeroThreePerSec,
    Metrics.fiveZeroFourPerSec,
  ],
  properties: [],
  propertyHistory: [],
  registeredProperties: [],
};

const DEFAULT_DISPLAYED_PROPERTIES_COUNT = 5;

export const reducer = (
  state: PropertyContentState,
  action: PropertyContentActions
): PropertyContentState => {
  switch (action.type) {
    case "UPDATE_SELECTED_METRICS":
      return {
        ...state,
        selectedMetrics: action.payload.selectedMetrics,
      };
    case "UPDATE_TABLE_STATE":
      return {
        ...state,
        properties: handleSortProperties(
          action.payload.sortField,
          action.payload.sortOrder,
          state.properties
        ),
        sortField: action.payload.sortField,
        sortOrder: action.payload.sortOrder,
      };
    case "UPDATE_PROPERTIES_DATA":
      // handle first render
      if (
        state.selectedRows === undefined &&
        action.payload.currentByProperty?.length
      ) {
        const _properties = handleSortByMetric(
          state.sortField,
          state.sortOrder,
          reduceProperties(action.payload.currentByProperty)
        );
        return {
          ...state,
          selectedRows: _properties
            .slice(0, DEFAULT_DISPLAYED_PROPERTIES_COUNT)
            .map((prop) => prop.propertyId),
          properties: _properties,
        };
      }

      // if currentByProperty, update property level data
      let newProperties = state.properties;
      if (action.payload.currentByProperty) {
        newProperties = reduceProperties(action.payload.currentByProperty);
      }

      if (action.payload.propertySplits) {
        newProperties = newProperties.map((prop) => {
          if (state.registeredProperties.includes(prop.propertyId)) {
            const refreshedData = action.payload.propertySplits?.find(
              (split) => split.propertyId === prop.propertyId
            );
            if (refreshedData === undefined) {
              // prop.regions = undefined;
              return prop;
            }
            return {
              ...prop,
              regions: groupByRegion(
                refreshedData.data.metros,
                refreshedData.data.regions
              ),
            };
          } else {
            return prop;
          }
        });
      }
      return {
        ...state,
        properties: handleSortProperties(
          state.sortField,
          state.sortOrder,
          newProperties
        ),
      };
    case "ADD_SELECTED_ROW":
      return {
        ...state,
        selectedRows: state.selectedRows
          ? [...state.selectedRows, action.payload.propertyId]
          : [action.payload.propertyId],
      };
    case "REMOVE_SELECTED_ROW":
      return {
        ...state,
        selectedRows: state.selectedRows?.filter(
          (id) => id !== action.payload.propertyId
        ),
      };
    case "UPDATE_PROPERTIES_HISTORY":
      return {
        ...state,
        propertyHistory: [...state.propertyHistory, action.payload.history],
      };
    case "ADD_PROPERTY_SPLIT":
      if (state.registeredProperties.includes(action.payload.propertyID)) {
        throw new Error(
          `Property ID ${action.payload.propertyID} is already registered`
        );
      }
      return {
        ...state,
        registeredProperties: [
          ...state.registeredProperties,
          action.payload.propertyID,
        ],
      };
    case "REMOVE_PROPERTY_SPLIT":
      if (!state.registeredProperties.includes(action.payload.propertyID)) {
        throw new Error(
          `Property ID ${action.payload.propertyID} not registered`
        );
      }
      return {
        ...state,
        registeredProperties: state.registeredProperties.filter(
          (id) => id !== action.payload.propertyID
        ),
      };
    case "UPDATE_PROPERTY_HISTORY":
      // TODO: make it a pure function to avoid double reducer execution.
      const newPropertyHistory: PropertyHistory[] = JSON.parse(
        JSON.stringify(state.propertyHistory)
      );
      state.selectedRows?.forEach((propertyId) => {
        const currentValueIndex = action.payload.currentByProperty.findIndex(
          (p) => p.propertyId === propertyId
        );
        if (currentValueIndex >= 0) {
          const propertyHistoryIndex = newPropertyHistory.findIndex(
            (p) => p.propertyId === propertyId
          );
          if (propertyHistoryIndex >= 0) {
            if (
              newPropertyHistory[propertyHistoryIndex].points.length >
              6 * 60
            ) {
              newPropertyHistory[propertyHistoryIndex].points.shift();
            }
            newPropertyHistory[propertyHistoryIndex].points.push({
              site: action.payload.currentByProperty[currentValueIndex],
              time: Math.trunc(Date.now() / 1000),
            });
          }
        }
      });
      return {
        ...state,
        propertyHistory: newPropertyHistory,
      };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};
