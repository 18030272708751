import { ReactElement, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { useIsMounted } from "../../../hooks/use-is-mounted";
import { Button } from "../../atoms/Button/Button";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";

const TRANSITION_MS = 400;

export interface GuidanceModalProps {
  buttonLabel: string;
  title: string;
  content: string;
  initOpen?: boolean;
}

export const GuidanceModal = ({
  buttonLabel,
  title,
  content,
  initOpen = false,
}: GuidanceModalProps): ReactElement => {
  const modalRef = useRef(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(initOpen);
  const isMounted = useIsMounted();

  const handleClose = () => {
    sidebarRef.current?.classList.remove("open");
    setTimeout(() => {
      if (isMounted.current) setOpen(false);
    }, TRANSITION_MS);
  };

  useEffect(() => {
    if (open) sidebarRef.current?.classList.add("open");
  }, [open]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        label={buttonLabel}
        dataTestId="show-guidance-button"
      />
      <Modal
        ref={modalRef}
        open={open}
        onClick={(e) => {
          if (e.target === modalRef.current) handleClose();
        }}
      >
        <ModalContent ref={sidebarRef}>
          <ModalTitleContainer>
            <CrossContainer
              role="button"
              onClick={handleClose}
              data-testid="guidance-modal-cross-button"
            >
              <CrossIcon name={Icons.CROSS} />
            </CrossContainer>
            <ModalTitle>{title}</ModalTitle>
          </ModalTitleContainer>
          <ModalBody>
            <CustomRemark
              rehypeReactOptions={{
                components: {
                  p: (props: any) => <RemarkP {...props} />,
                  ul: (props: any) => <RemarkUl {...props} />,
                },
              }}
            >
              {content}
            </CustomRemark>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const Modal = styled.div<{ open: boolean }>`
  display: none;
  font-family: Inter;
  text-align: initial;
  ${({ open }) =>
    open &&
    css`
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999999;
      background-color: rgba(0, 0, 0, 0.64);
    `}
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.base};
  width: 40%;
  height: 100%;
  margin-left: auto;
  position: relative;
  overflow: auto;
  transition: all ${TRANSITION_MS / 1000}s ease-out;
  right: -100px;
  opacity: 0;
  color: ${({ theme }) => theme.text.primary};

  &.open {
    right: 0;
    opacity: 1;
  }
`;

const ModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: 32px;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  margin: 24px;
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 8px;

  ol,
  ul {
    margin: 0;
    padding: 0;
    padding-left: 18px;
  }

  ol {
    li {
      margin-bottom: 32px;
    }

    ol {
      list-style-type: lower-alpha;
      padding: 0;
      margin-left: 18px;
      margin-top: 32px;
    }
  }

  ul {
    margin-bottom: 32px;
  }

  li {
    &::marker {
      font-weight: 600;
    }
  }
`;

const CrossContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 24px;
  transition-duration: 0.2s;
  transition-property: transform;

  &:active {
    transform: translateY(4px);
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.2) rotate(90deg);
  }
`;

const CrossIcon = styled(Icon)`
  height: 1em;
  fill: ${({ theme }) => theme.icon.secondary};
`;

const RemarkP = styled.p`
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 0;
  margin-bottom: 32px;

  strong {
    font-size: 16px !important;
  }
`;

const RemarkUl = styled.ul`
  font-size: 16px !important;
`;
