import {
  Metrics,
  Region as RegionEnum,
  SiteByMetro,
  SiteByRegion,
} from "../../../models/models";
import { groupByRegion, handleSortRegionsAndMetros } from "./helpers";
import { TableRegion, TableMetrics } from "./types";
import { SortOrder } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";

export type MapContentState = {
  regions: TableRegion[];
  selectedRegions: RegionEnum[] | undefined;
  selectedMetros: string[] | undefined;
  selectedMetrics: (keyof TableMetrics)[];
  sortField: keyof TableMetrics;
  sortOrder: SortOrder;
};

export type MapContentActions =
  | {
      type: "UPDATE_TABLE_STATE";
      payload: { sortField: keyof TableMetrics; sortOrder: SortOrder };
    }
  | {
      type: "UPDATE_SELECTED_METRICS";
      payload: { selectedMetrics: (keyof TableMetrics)[] };
    }
  | {
      type: "UPDATE_SELECTED_REGIONS";
      payload: { regions: RegionEnum[] };
    }
  | {
      type: "ADD_SELECTED_REGION";
      payload: { region: RegionEnum };
    }
  | {
      type: "REMOVE_SELECTED_REGION";
      payload: { region: RegionEnum; metroList: string[] };
    }
  | {
      type: "ADD_SELECTED_METRO";
      payload: { selectedMetro: string };
    }
  | {
      type: "REMOVE_SELECTED_METRO";
      payload: { selectedMetro: string };
    }
  | {
      type: "UPDATE_REGIONS_DATA";
      payload: {
        currentByMetro: SiteByMetro[];
        currentByRegion: SiteByRegion[];
      };
    };

export const initialState: MapContentState = {
  regions: [],
  selectedRegions: undefined,
  selectedMetros: undefined,
  sortField: Metrics.reqPerSec,
  sortOrder: "desc",
  selectedMetrics: [
    Metrics.reqPerSec,
    Metrics.fourZeroFourPerSec,
    Metrics.fiveZeroThreePerSec,
    Metrics.fiveZeroFourPerSec,
  ],
};

export const reducer = (
  state: MapContentState,
  action: MapContentActions
): MapContentState => {
  switch (action.type) {
    case "UPDATE_TABLE_STATE":
      return {
        ...state,
        regions: handleSortRegionsAndMetros(
          action.payload.sortField,
          action.payload.sortOrder,
          state.regions
        ),
        sortField: action.payload.sortField,
        sortOrder: action.payload.sortOrder,
      };
    case "UPDATE_REGIONS_DATA":
      const regions = handleSortRegionsAndMetros(
        state.sortField,
        state.sortOrder,
        groupByRegion(
          action.payload.currentByMetro,
          action.payload.currentByRegion
        )
      );

      // initialisation of selectedRegions
      let selectedRegions = state.selectedRegions;
      if (selectedRegions === undefined && regions.length > 0) {
        selectedRegions = regions.map((r) => r.region);
      }

      return {
        ...state,
        regions,
        selectedRegions,
      };
    case "UPDATE_SELECTED_METRICS":
      return {
        ...state,
        selectedMetrics: action.payload.selectedMetrics,
      };
    case "UPDATE_SELECTED_REGIONS":
      return {
        ...state,
        selectedRegions: action.payload.regions,
      };
    case "ADD_SELECTED_REGION":
      return {
        ...state,
        selectedRegions: state.selectedRegions
          ? [...state.selectedRegions, action.payload.region]
          : [action.payload.region],
      };
    case "REMOVE_SELECTED_REGION":
      return {
        ...state,
        selectedRegions: state.selectedRegions?.filter(
          (region) => region !== action.payload.region
        ),
        selectedMetros: state.selectedMetros?.filter(
          (metro) => !action.payload.metroList.includes(metro)
        ),
      };
    case "ADD_SELECTED_METRO":
      return {
        ...state,
        selectedMetros: state.selectedMetros
          ? [...state.selectedMetros, action.payload.selectedMetro]
          : [action.payload.selectedMetro],
      };
    case "REMOVE_SELECTED_METRO":
      return {
        ...state,
        selectedMetros: state.selectedMetros?.filter(
          (metro) => metro !== action.payload.selectedMetro
        ),
      };
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};
