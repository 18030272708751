import { FC, HTMLAttributes } from "react";
import styled from "@emotion/styled";

import { InputBase } from "../../atoms/InputBase/InputBase";
import { Icon, Icons } from "../../atoms/Icon/Icon";

type InputProps = HTMLAttributes<HTMLInputElement>;
type SearchInputWrapperProps = HTMLAttributes<HTMLDivElement>;

interface SearchInputProps {
  inputProps?: InputProps;
  searchInputWrapperProps?: SearchInputWrapperProps;
}

export const SearchInput: FC<SearchInputProps> = ({
  inputProps = {},
  searchInputWrapperProps = {},
}) => {
  return (
    <SearchInputWrapper {...searchInputWrapperProps}>
      <SearchIcon name={Icons.SEARCH} size={20} />
      <StyledInput {...inputProps} />
    </SearchInputWrapper>
  );
};

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`};
  border-radius: 4px;
  padding: 12px 16px;
`;

const StyledInput = styled(InputBase)`
  width: 100%;
  color: ${({ theme }) => theme.text.primary};
`;

const SearchIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  & path {
    fill: #65686c;
  }
`;
