import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { FlexBoxBase } from "../../../../components/atoms/FlexBox/FlexBox";
import { LabelValueDropdownButton } from "../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { Modal } from "../../../../components/atoms/Modal/Modal";
import { SearchBar } from "../../../../components/molecules/SearchBar/SearchBar";
import { Dropdown } from "../../../../components/organisms/Dropdown/Dropdown";
import { ApiKeyModal } from "./ApiKeyModal";
import {
  ApiKeysOwners,
  ApiKeysRoles,
} from "../../../../models/user-management/apiKeys/apiKeys.interfaces";
import { ApiKeysCustomerFilter, ApiKeysOrder, ApiKeysStatus } from "./types";
import { VyvxFiberCompany } from "../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { VyvxApiKeysCompanies } from "./vyvx/VyvxApiKeysCompanies";
import { CdnApikeysDropdown } from "./cdn/CdnApiKeysDropdown";

export interface ApiKeysTableFilterProps {
  onSearchInputChange: (value: string) => void;
  roleFilter: string;
  onRoleFilterChange: (value: string) => void;
  statusFilter: ApiKeysStatus;
  onStatusFilterChange: (value: ApiKeysStatus) => void;
  roles: ApiKeysRoles[];
  owners: ApiKeysOwners[];
  orderFilter: ApiKeysOrder;
  onOrderFilter: (value: ApiKeysOrder) => void;
  isVyvx: boolean;
  fiberCompanies?: VyvxFiberCompany[];
  customerFilter: ApiKeysCustomerFilter;
  onCustomerFilter: (value: ApiKeysCustomerFilter) => void;
}

export const ApiKeysTableFilter = ({
  onSearchInputChange,
  roleFilter,
  onRoleFilterChange,
  statusFilter,
  onStatusFilterChange,
  roles,
  owners,
  orderFilter,
  onOrderFilter,
  isVyvx,
  fiberCompanies,
  customerFilter,
  onCustomerFilter,
}: ApiKeysTableFilterProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");

  const roleDropdownItems = [
    {
      label: t("STATUS_DROPDOWN_ALL"),
      value: "all",
      default: true,
    },
  ].concat(
    Object.values(roles).map((role) => {
      return {
        label: role.name,
        value: role.name,
        default: false,
        data: role.name,
      };
    })
  );

  const statusDropdownItems: Map<ApiKeysStatus, string> = new Map([
    [ApiKeysStatus.ALL, t("STATUS_DROPDOWN_ALL")],
    [ApiKeysStatus.ACTIVE, t("STATUS_DROPDOWN_ACTIVE")],
    [ApiKeysStatus.DISABLED, t("STATUS_DROPDOWN_DISABLED")],
  ]);

  const orderDropdownItems: Map<ApiKeysOrder, string> = new Map([
    [ApiKeysOrder.RECENT, t("ORDER_DROPDOWN_RECENT")],
    [ApiKeysOrder.OLDEST, t("ORDER_DROPDOWN_OLDEST")],
  ]);

  return (
    <TableContainer>
      <Container>
        <div style={{ width: "90%" }}>
          <SearchBar
            placeholder={t("API_KEYS_TABLE_SEARCH_PLACEHOLDER")}
            onChange={onSearchInputChange}
          />
        </div>
        <Modal
          openButtonProps={{
            label: t("API_KEYS_CREATE_KEY_BUTTON"),
          }}
          size="large"
        >
          <ApiKeyModal
            roles={roles}
            owners={owners}
            isVyvx={isVyvx}
            fiberCompanies={fiberCompanies || []}
            customerFilter={customerFilter}
          />
        </Modal>
      </Container>
      <Container>
        <FilterContainer>
          {isVyvx && fiberCompanies ? (
            <VyvxApiKeysCompanies
              fiberCompanies={fiberCompanies}
              keyCompanyFilter={customerFilter}
              onCompnayFilterChange={onCustomerFilter}
              isFilter
              isVyvx={isVyvx}
            />
          ) : (
            <CdnApikeysDropdown
              accessGroupFilter={customerFilter}
              handleSelectAccessGroup={onCustomerFilter}
              isFilter
              isVyvx={isVyvx}
            />
          )}

          <Dropdown
            customButton={
              <LabelValueDropdownButton
                placeholder={t("ROLE_DROPDOWN_PLACEHOLDER")}
                value={roleFilter}
              />
            }
            id="apikeys-role-dropdown"
            placeholder={t("API_KEYS_TABLE_ROLE_PLACEHOLDER")}
            items={roleDropdownItems}
            onSelect={(role) => {
              onRoleFilterChange(role.value);
            }}
          />
          <Dropdown<ApiKeysStatus>
            customButton={
              <LabelValueDropdownButton
                placeholder={t("STATUS_DROPDOWN_PLACEHOLDER")}
                value={statusFilter}
              />
            }
            id="apikeys-status-dropdown"
            placeholder={t("API_KEYS_TABLE_STATUS_PLACEHOLDER")}
            items={Array.from(statusDropdownItems, ([value, label]) => ({
              value,
              label,
            }))}
            onSelect={(status) => {
              onStatusFilterChange(status.value);
            }}
          />
        </FilterContainer>
        <Dropdown<ApiKeysOrder>
          customButton={
            <LabelValueDropdownButton
              placeholder={t("ORDER_DROPDOWN_PLACEHOLDER")}
              value={orderFilter}
            />
          }
          id="apikeys-order-dropdown"
          placeholder={t("API_KEYS_TABLE_ORDER_PLACEHOLDER")}
          items={Array.from(orderDropdownItems, ([value, label]) => ({
            value,
            label,
          }))}
          onSelect={(role) => {
            onOrderFilter(role.value);
          }}
        />
      </Container>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin-bottom: 32px;
`;

const Container = styled(FlexBoxBase)`
  justify-content: space-between;
`;

const FilterContainer = styled.div``;
