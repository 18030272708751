import styled from "@emotion/styled";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { PageContainer } from "../../../../components/atoms/PageContainer/PageContainer";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { UsersTable } from "./UsersTable";

export const Users = (): ReactElement => {
  const { t } = useTranslation("usersPage");

  return (
    <Container>
      <ExpandableContent
        title={t("EXPANDABLE_CONTENT_TITLE")}
        content={t("EXPANDABLE_CONTENT_CONTENT")}
      />
      <TableContainer>
        <UsersTable />
      </TableContainer>
    </Container>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
`;

const TableContainer = styled.div`
  padding-top: 32px;x
`;
