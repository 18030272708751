import { ReactElement, useContext } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Theme, ThemeContext } from "../../../contexts/themeContext";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";

export const SchemaVersionUpgradeInfo = (): ReactElement => {
  const themeContext = useContext(ThemeContext);
  const [t] = useTranslation("configurationsPage");

  return (
    <VersionUpgradeContainer
      data-testid="configuration-header-version-upgrade"
      isdarkmode={themeContext.theme === Theme.DARK_MODE}
    >
      <VersionUpgrade>
        <InfoIcon name={Icons.INFO} />
        <VersionText>
          <CustomRemark>{t("SCHEMA_VERSION_UPGRADE")}</CustomRemark>
        </VersionText>
      </VersionUpgrade>
    </VersionUpgradeContainer>
  );
};

const VersionUpgradeContainer = styled.div<{ isdarkmode: boolean }>`
  background-color: ${({ theme }) => theme.backgrounds.infoLight};
  padding-left: 24px;
  ${({ isdarkmode }) =>
    isdarkmode &&
    css`
      color: #000000;
    `}
`;

const VersionUpgrade = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

const VersionText = styled.div`
  max-width: 500px;
`;

const InfoIcon = styled(Icon)`
  margin-right: 16px;

  * {
    fill: ${({ theme }) => theme.colors.navy50};
  }
`;
