import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { LuaDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createLuaDefinition = (
  name: string,
  rest: Pick<
    LuaDefinitionType,
    "description" | "heavy" | "script" | "signature" | "variables"
  >
): LuaDefinitionType => ({
  ...parseDefinition({
    name,
    description: rest.description,
    type: TypeDefinitions.LUA,
  }),
  heavy: rest.heavy,
  script: rest.script,
  signature: rest.signature,
  variables: rest.variables,
});
