import { useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep, isEqual } from "lodash";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { CustomLocation } from "../../../../../../components/atoms/BlockingNavModal/BlockingNavModal";
import { useQuery } from "../../../../../../hooks/use-query";
import {
  IFeatures,
  IMatchGroup,
  IMatchRule,
} from "../../../../../../models/configuration/definitions";
import { MatchBlocks } from "../../../../../../models/configuration/definitions/matchlogic";
import { useSelectedConfiguration } from "../../../../../../store/slices/caching/hooks";
import { handleUpdateMatchRule } from "../../../../../../store/slices/caching/thunks";
import { PropertyDefinitionType } from "../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../store/types";
import { denormalizeCustomLogData, normalizeCustomLogData } from "./helpers";

export const useMatchRulesPage = (): [
  matchRuleLocation: string,
  matchRule: IMatchRule,
  matchBlockQueried: MatchBlocks,
  handleSaveMatchRule: (formValues: IFeatures, navigate?: boolean) => void,
  onMatchRuleChange: (newValue: IMatchRule) => void,
  handleTransition: (
    location: CustomLocation,
    newMatchRule: IMatchRule | undefined
  ) => boolean,
  initialMatchRule: IMatchRule | undefined
] => {
  const history = useHistory();
  const location = useLocation();

  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();

  const [property, setProperty] = useState<PropertyDefinitionType>();
  const [matchRule, setMatchRule] = useState<IMatchRule>();

  const [matchBlockQueried] = useQuery<MatchBlocks>("matchBlock");
  const [matchGroupQueried] = useQuery<string>("matchGroup");
  const [matchRuleQueried] = useQuery("matchRule");
  const [matchRuleLocation] = useQuery("matchRulelocation");
  const [editionMode] = useQuery("editionMode");
  const configurationUrl = useRouteMatch<{
    configName: string;
    propertyName: string;
  }>("/cmv3/configurations/:configName/properties/:propertyName/match_rules");

  useEffect(() => {
    const _property = selectedConfiguration?.config?.propertyDefinitions.find(
      (p) => p.name === configurationUrl!.params.propertyName
    );

    setProperty(_property);
  }, [
    setProperty,
    configurationUrl,
    selectedConfiguration?.config?.propertyDefinitions,
  ]);

  useEffect(() => {
    if (property?.matchLogic) {
      const matchLogicDefinition = selectedConfiguration?.config?.matchLogicDefinitions?.find(
        (mLD) => mLD.name === property?.matchLogic
      );

      if (matchLogicDefinition && matchRuleQueried) {
        setMatchRule(
          cloneDeep(
            getMatchRule(
              matchRuleQueried,
              matchLogicDefinition.matchBlocks[matchBlockQueried!]?.matchGroups[
                Number(matchGroupQueried)
              ]
            )
          )
        );
      }
    }
  }, [property]);

  const onMatchRuleChange = (newMatchRule: IMatchRule) => {
    setMatchRule(cloneDeep(newMatchRule));
  };

  const handleSaveMatchRule = useCallback(
    (features: IFeatures, navigate = true) => {
      if (property?.matchLogic) {
        const matchLogicDefinition = selectedConfiguration?.config?.matchLogicDefinitions?.find(
          (mLD) => mLD.name === property?.matchLogic
        );
        const _newMatchRule = { ...matchRule, features };

        if (
          matchLogicDefinition &&
          matchGroupQueried &&
          matchBlockQueried &&
          matchRuleQueried &&
          matchLogicDefinition.matchBlocks[matchBlockQueried] &&
          _newMatchRule
        ) {
          const newMatchRule = JSON.parse(
            JSON.stringify(_newMatchRule)
          ) as typeof _newMatchRule;

          dispatch(
            handleUpdateMatchRule({
              mldName: property?.matchLogic,
              matchBlockQueried,
              matchGroupQueried,
              matchRuleQueried,
              matchRule: denormalizeCustomLogData(newMatchRule),
            })
          );
        }
      }

      if (navigate) {
        if (
          !validateQuery(matchBlockQueried, matchGroupQueried, matchRuleQueried)
        ) {
          history.goBack();
        }
        history.push(
          `${location.pathname}` +
            `?matchBlock=${matchBlockQueried}` +
            `&matchGroup=${matchGroupQueried}` +
            `&matchRule=${matchRuleQueried}` +
            `&matchRulelocation=${matchRuleLocation}` +
            `&editionMode=${editionMode}`
        );
      }
    },
    [
      matchRuleLocation,
      editionMode,
      matchGroupQueried,
      matchBlockQueried,
      matchRuleQueried,
      selectedConfiguration,
      matchRule,
    ]
  );

  const handleTransition = (
    loc: CustomLocation,
    _newMatchRule: IMatchRule | undefined
  ) => {
    // Prevent stoping transition when navigating inside match rule page
    if (loc.search.includes("&editionMode=")) {
      return false;
    }
    // In any other case, compare match rule with saved config version
    if (property?.matchLogic) {
      const matchLogicDefinition = selectedConfiguration?.config?.matchLogicDefinitions?.find(
        (mLD) => mLD.name === property?.matchLogic
      );
      const _matchRule = getMatchRule(
        matchRuleQueried,
        matchLogicDefinition?.matchBlocks[matchBlockQueried!]?.matchGroups[
          Number(matchGroupQueried)
        ]
      );

      const newMatchRule = JSON.parse(JSON.stringify(_newMatchRule));
      denormalizeCustomLogData(newMatchRule);

      if (newMatchRule && isEqual(newMatchRule, _matchRule)) {
        return false;
      }
    }
    return true;
  };

  return [
    matchRuleLocation!,
    matchRule!,
    matchBlockQueried!,
    handleSaveMatchRule,
    onMatchRuleChange,
    handleTransition,
    // initial match rule from the store
    useMemo(() => {
      const _property = selectedConfiguration?.config?.propertyDefinitions.find(
        (p) => p.name === configurationUrl!.params.propertyName
      );
      const matchLogicDefinition = selectedConfiguration?.config?.matchLogicDefinitions?.find(
        (mLD) => mLD.name === _property?.matchLogic
      );
      const __matchRule = getMatchRule(
        matchRuleQueried,
        matchLogicDefinition?.matchBlocks[matchBlockQueried!]?.matchGroups[
          Number(matchGroupQueried)
        ]
      );

      const _matchRule = __matchRule
        ? (JSON.parse(JSON.stringify(__matchRule)) as Exclude<
            typeof __matchRule,
            undefined
          >)
        : __matchRule;

      if (_matchRule) {
        normalizeCustomLogData(_matchRule);
      }

      return _matchRule;
    }, [
      configurationUrl,
      matchBlockQueried,
      matchGroupQueried,
      matchRuleQueried,
      selectedConfiguration?.config?.matchLogicDefinitions,
      selectedConfiguration?.config?.propertyDefinitions,
    ]),
  ];
};

const validateQuery = (
  matchBlock?: MatchBlocks,
  matchGroup?: string,
  matchRule?: string
) => {
  return (
    matchBlock !== undefined &&
    [
      MatchBlocks.CLIENT_REQ,
      MatchBlocks.ORIGIN_REQ,
      MatchBlocks.ORIGIN_RESP,
    ].includes(matchBlock) &&
    matchGroup !== undefined &&
    Number.isInteger(Number(matchGroup)) &&
    matchRule !== undefined &&
    matchRule.split("-").every((mr) => Number.isInteger(Number(mr)))
  );
};

const getMatchRule = (
  matchRuleQueried: string | undefined,
  matchGroups: IMatchGroup | undefined
) =>
  matchRuleQueried?.split("-").reduce((prev, curr, i) => {
    if (i % 2 === 0) {
      // Matchrule
      return (prev as IMatchRule[] | undefined)?.[Number(curr)];
    }

    // Matchblock
    return (prev as IMatchRule | undefined)?.matchGroups?.[Number(curr)]
      .matchRules;
  }, matchGroups?.matchRules as any);
