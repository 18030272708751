import React from "react";
import format from "date-fns/format";
import styled from "@emotion/styled";

import { ITransactionHistory } from "../../../../models/configuration/configuration_details.interfaces";
import { EnvironmentHistoryTransactionCard } from "../../../../components/molecules/EnvironmentHistoryTransactionCard/EnvironmentHistoryTransactionCard";

export interface ITransactionsList {
  transactionHistory: ITransactionHistory[];
}

export const TransactionsList: React.FC<ITransactionsList> = ({
  transactionHistory,
}) => {
  const datedTransactions: { [date: string]: ITransactionHistory[] } = {};
  transactionHistory.forEach((transaction) => {
    if (transaction) {
      const latestTime =
        transaction.completeTime !== undefined
          ? transaction.completeTime
          : transaction.startTime !== undefined
          ? transaction.startTime
          : transaction.queueTime !== undefined
          ? transaction.queueTime
          : "";

      const date = new Date(latestTime);
      const formattedDate = `${format(date, "eeee, MMMM do yyyy")}`;
      if (datedTransactions[formattedDate]) {
        datedTransactions[formattedDate].push(transaction);
      } else {
        datedTransactions[formattedDate] = [transaction];
      }
    }
  });

  return (
    <TransactionsListContainer>
      {Object.entries(datedTransactions).map((dateTransaction, i) => (
        <Block key={i} className="block">
          <DateHeader className="-text--h4">{dateTransaction[0]}</DateHeader>
          {dateTransaction[1].map((transaction, j) => (
            <TransactionCardContainer key={j}>
              <EnvironmentHistoryTransactionCard
                transactionHistory={transaction}
              />
            </TransactionCardContainer>
          ))}
        </Block>
      ))}
    </TransactionsListContainer>
  );
};

const TransactionsListContainer = styled.div`
  & > .block:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.backgrounds.highlightLight}`};
  }
`;

const Block = styled.div`
  padding-bottom: 2.5rem;

  &:not(:first-child) {
    padding: 2rem 0rem 2.5rem 0rem;
  }

  .-text--h4:first-child {
    margin-top: 0 !important;
  }
`;

const TransactionCardContainer = styled.div`
  padding: 0.2rem 0rem;
`;

const DateHeader = styled.div`
  padding-bottom: 0.5rem;
`;
