import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { isDefinitionNameValid } from "../../../utils/string";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  Field,
  FieldInput,
  FieldName,
  Table,
  TableBody,
} from "../DefinitionModal/DefinitionModal.styled";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { ReportingOverrideDefinitionType } from "../../../store/slices/caching/types";
import { createReportingOverrideDefinition } from "../../../store/slices/caching/helpers/reporting-override-definition/createReportingOverrideDefinition";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";
import { getReportingOverrideReferences } from "../../../store/slices/caching/helpers/reporting-override-definition/getReportingOverrideReferences";

type ReportingOverridesDefinitionProps = IDefaultDefinitionModalProps<ReportingOverrideDefinitionType>;

export const ReportingOverridesModal = ({
  definition,
  parentConfig,
  onSubmit,
}: ReportingOverridesDefinitionProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationDefinitionsPage");

  const [name, setName] = useState(definition?.name ?? "");
  const [description, setDescription] = useState(
    definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
  );
  const [reportingName, setReportingName] = useState(
    definition?.reportingName || ""
  );
  const [error, setError] = useState("");

  const initState = () => {
    setError("");
    setName(definition?.name ?? "");
    setDescription(
      definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
    );
    setReportingName(definition?.reportingName || "");
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.REPORTING_OVERRIDE]
      )
    ) {
      setError(
        t("ERROR_DEFINITION_REPORTING_OVERRIDE_ALREADY_EXISTS", { name })
      );
      return false;
    }

    if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    if (reportingName.length > 0) {
      return true;
    }

    setError(t("ERROR_DEFINITION_REPORTING_OVERRIDE_NOT_EXISTS"));
    return false;
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="reportingoverride-name"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
          dataTestId="reporting-overrides-modal-header"
        />
      }
      references={
        definition
          ? getReportingOverrideReferences(definition, parentConfig)
          : 0
      }
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        if (definition) {
          onSubmit({
            ...definition,
            name,
            reportingName,
            _schemaDescription: description,
          });
        } else {
          if (selectedConfiguration && selectedConfiguration.config) {
            onSubmit(
              createReportingOverrideDefinition(name, {
                description,
                reportingName,
              })
            );
          }
        }

        initState();
      }}
      dataTestId="reporting-overrides"
    >
      <Table>
        <TableBody>
          <Field>
            <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>
            <FieldInput
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
              data-testid="reporting-overrides-modal-definition-name-input"
            />
          </Field>
          <Field>
            <FieldName required>
              {t(
                "EDIT_DEFINITION_REPORTING_OVERRIDES_REPORTING_NAME_FIELD_LABEL"
              )}
            </FieldName>
            <FieldInput
              placeholder={t(
                "EDIT_DEFINITION_REPORTING_OVERRIDES_REPORTING_NAME_FIELD_PLACEHOLDER"
              )}
              value={reportingName}
              onChange={(event) => setReportingName(event.target.value)}
              data-testid="reporting-overrides-modal-reporting-name-field"
            />
          </Field>
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_REPORTING_OVERRIDES_DESCRIPTION_FIELD_LABEL")}
            </FieldName>
            <FieldInput
              placeholder={t(
                "EDIT_DEFINITION_REPORTING_OVERRIDES_DESCRIPTION_FIELD_PLACEHOLDER"
              )}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              data-testid="reporting-overrides-modal-description-field"
            />
          </Field>
        </TableBody>
      </Table>
      {error && (
        <NotificationCard
          dataTestId="error-reporting-overrides-modal"
          theme="error"
          title={error}
        />
      )}
    </DefinitionModal>
  );
};
