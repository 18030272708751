import { FC } from "react";
import styled from "@emotion/styled";

import { ReactComponent as TripleDotSvg } from "../../../assets/icons/triple_dot.svg";
import { UserRoles } from "../../../models/permissions";
import { ListItem } from "../../atoms/ListItem/ListItem";
import { Badge } from "../../atoms/Badge/Badge";
import {
  Dropdown,
  DropdownPosition,
  IDropdownItem,
} from "../../organisms/Dropdown/Dropdown";
import { Protected } from "../../atoms/Protected/Protected";
import {
  CertificateMetadata,
  IField,
} from "../CertificateMetadata/CertificateMetadata";
import { ColorSet } from "../../../contexts/themeContext";

interface IHeader {
  title: string;
  badge: { text: string; color: keyof ColorSet["colors"] };
}

export interface IListCardProps {
  fields: IField[];
  header: IHeader;
  dropdownItems: {
    item: IDropdownItem<string>;
    action: () => void;
  }[];
  extra?: string;
  onClick?: () => void;
  dataTestId?: string;
}

export const ListCard: FC<IListCardProps> = ({
  header,
  fields,
  dropdownItems,
  extra,
  dataTestId,
  onClick,
}) => (
  <Card
    spacing="m"
    onClick={() => {
      if (onClick) onClick();
    }}
    data-testid={dataTestId}
  >
    <Header>
      <TitleContainer>
        <Title>{header.title}</Title>
        <CardBadge label={header.badge.text} color={header.badge.color} />
        {extra && <Extra>{extra}</Extra>}
      </TitleContainer>
      <Protected permissions={UserRoles.EDIT_CONFIG}>
        <Dropdown
          id="list-card-dropdown"
          placeholder=""
          customButton={
            <TripleDot data-testid="certificates-card-dropdown-button" />
          }
          onSelect={(item) =>
            dropdownItems
              .find((ditem) => item.value === ditem.item.value)!
              .action()
          }
          outline={false}
          items={dropdownItems.map((dropdownItem) => dropdownItem.item)}
          position={DropdownPosition.LEFT}
        />
      </Protected>
    </Header>
    <CertificateMetadata fields={fields} />
  </Card>
);

const Card = styled(ListItem)`
  &:not(:first-child) {
    margin: 1em 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const CardBadge = styled(Badge)``;

const Title = styled.h4`
  margin-right: 0.5em !important;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TripleDot = styled(TripleDotSvg)`
  margin-right: 8px;
  margin-top: 16px;
  cursor: pointer;
`;

const Extra = styled.span`
  color: ${({ theme }) => theme.text.secondary};
  margin-left: 8px;
`;
