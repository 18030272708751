import styled from "@emotion/styled";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CheckIcon } from "../../atoms/CheckIcon/CheckIcon";

interface DeploymentCardProps {
  selected: boolean;
  onClick: () => void;
  value: number;
  configurationName: string;
  isNewConfig: boolean;
  disabled?: boolean;
}

export const DeploymentCard = ({
  selected,
  onClick,
  value,
  configurationName,
  isNewConfig,
  disabled = false,
}: DeploymentCardProps): ReactElement => {
  const [t] = useTranslation("misc");
  const [configurationLabel, setConfigurationLabel] = useState<string>(
    t("SLOT_AVAILABLE")
  );

  useEffect(() => {
    setConfigurationLabel(
      configurationName === ""
        ? t("SLOT_AVAILABLE")
        : !isNewConfig
        ? `Currently: ${configurationName}`
        : configurationName
    );
  }, [configurationName, isNewConfig]);

  return (
    <ConfigurationSlot
      selected={selected}
      onClick={() => !disabled && onClick()}
      disabled={disabled}
    >
      <RadioButton selected={selected} />
      <Header selected={selected}>{value}%</Header>
      <ConfigurationNameContainer>
        <ConfigurationName
          available={configurationName === ""}
          selected={selected}
        >
          {configurationName === "" && <CheckIcon />}
          {configurationLabel}
        </ConfigurationName>
      </ConfigurationNameContainer>
    </ConfigurationSlot>
  );
};

const ConfigurationSlot = styled.div<{
  selected: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.blue60 : theme.text.secondaryAlt};
  cursor: pointer;
  opacity: ${({ disabled }) => disabled && 0.7};
  flex-grow: 1;
`;

const RadioButton = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  border: solid
    ${({ selected, theme }) =>
      selected
        ? `${theme.borders.base} 4px`
        : `${theme.borders.mutedDark} 1px`};
  border-radius: 50%;
`;

const Header = styled.h3<{ selected?: boolean }>`
  color: ${({ selected, theme }) => selected && theme.colors.white};
`;

const ConfigurationNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const ConfigurationName = styled.div<{
  available: boolean;
  selected: boolean;
}>`
  border: ${({ available, theme }) =>
    available
      ? `dashed 1px ${theme.borders.mutedDark}`
      : "solid 1px transparent"};
  border-radius: 4px;
  background-color: ${({ available, selected, theme }) =>
    !available && (selected ? theme.backgrounds.info : theme.colors.blue10)};
  color: ${({ selected, available, theme }) =>
    selected ? theme.colors.white : !available && theme.colors.grey100};
  width: fit-content;
  height: fit-content;
  padding: 8px;
`;
