import { MetroHistory } from "./../../../modules/rtm/components/templates/Locations/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IHistorical,
  Property,
  RegionHistory,
  Site,
  SiteByMetro,
  SiteByProperty,
  SiteByRegion,
} from "../../../modules/rtm/models/models";
import { RTMSlice } from "./types";
import { Status } from "../../../@types/status";

const initialState: RTMSlice = {
  currentByMetro: undefined,
  currentByRegion: undefined,
  currentByProperty: undefined,
  currentAccessGroupData: undefined,
  historical: undefined,
  properties: undefined,
  regionsHistory: [],
  metrosHistory: [],
  currentDataStatus: Status.IDLE,
  currentPropertyStatus: Status.IDLE,
  historyStatus: Status.IDLE,
  regionHistoryStatus: Status.IDLE,
  metroHistoryStatus: Status.IDLE,
};

export const rtmSlice = createSlice({
  name: "rtm",
  initialState,
  reducers: {
    setCurrentAccessGroupData: (state, action: PayloadAction<Site>) => {
      state.currentAccessGroupData = action.payload;
    },
    setCurrentByMetro: (state, action: PayloadAction<SiteByMetro[]>) => {
      state.currentByMetro = action.payload;
    },
    setCurrentByRegion: (state, action: PayloadAction<SiteByRegion[]>) => {
      state.currentByRegion = action.payload;
    },
    setCurrentByProperty: (state, action: PayloadAction<SiteByProperty[]>) => {
      state.currentByProperty = action.payload;
    },
    setHistorical: (state, action: PayloadAction<IHistorical>) => {
      state.historical = action.payload;
    },
    updateHistorical: (
      state,
      action: PayloadAction<{ site: Site; time: number }>
    ) => {
      if (state.historical) {
        // Check for full history, i.e. 6 hours of data with one point per minute.
        if (state.historical.length >= 6 * 60) {
          state.historical.shift();
        }
        state.historical.push(action.payload);
      } else {
        state.historical = [action.payload];
      }
    },
    setRegionsHistory: (state, action: PayloadAction<RegionHistory[]>) => {
      state.regionsHistory = action.payload;
    },
    updateRegionsHistory: (
      state,
      action: PayloadAction<{ values: SiteByRegion[]; time: number }>
    ) => {
      action.payload.values.forEach((siteByRegion) => {
        const stateIndex = state.regionsHistory.findIndex(
          (rh) => rh.region === siteByRegion.region
        );
        if (stateIndex >= 0) {
          // Check for full history, i.e. 6 hours of data with one point per minute.
          if (state.regionsHistory[stateIndex].points.length >= 6 * 60) {
            state.regionsHistory[stateIndex].points.shift();
          }
          state.regionsHistory[stateIndex].points.push({
            site: siteByRegion,
            time: action.payload.time,
          });
        } else {
          state.regionsHistory.push({
            region: siteByRegion.region,
            points: [{ site: siteByRegion, time: action.payload.time }],
          });
        }
      });
    },
    setMetrosHistory: (state, action: PayloadAction<MetroHistory[]>) => {
      state.metrosHistory = action.payload;
    },
    setCurrentDataStatus: (
      state,
      action: PayloadAction<RTMSlice["currentDataStatus"]>
    ) => {
      state.currentDataStatus = action.payload;
    },
    setCurrentPropertyStatus: (
      state,
      action: PayloadAction<RTMSlice["currentPropertyStatus"]>
    ) => {
      state.currentPropertyStatus = action.payload;
    },
    setHistoryStatus: (
      state,
      action: PayloadAction<RTMSlice["historyStatus"]>
    ) => {
      state.historyStatus = action.payload;
    },
    setRegionHistoryStatus: (
      state,
      action: PayloadAction<RTMSlice["regionHistoryStatus"]>
    ) => {
      state.regionHistoryStatus = action.payload;
    },
    setMetroHistoryStatus: (
      state,
      action: PayloadAction<RTMSlice["metroHistoryStatus"]>
    ) => {
      state.metroHistoryStatus = action.payload;
    },
    updateMetrosHistoryWithCurrentData: (
      state,
      action: PayloadAction<{ values: SiteByMetro[]; time: number }>
    ) => {
      // state.metrosHistory = action.payload;
      state.metrosHistory.forEach((mH) => {
        const newValue = action.payload.values.find(
          (v) => v.metro === mH.metro
        );
        if (newValue) {
          // Check for full history, i.e. 6 hours of data with one point per minute.
          if (mH.points.length >= 6 * 60) {
            mH.points.shift();
          }
          mH.points.push({
            site: newValue,
            time: action.payload.time,
          });
        }
      });
    },
    addMetroToHistory: (state, action: PayloadAction<MetroHistory>) => {
      const foundMetroIndex = state.metrosHistory.findIndex(
        (mH) => mH.metro === action.payload.metro
      );
      if (foundMetroIndex >= 0) {
        state.metrosHistory[foundMetroIndex] = action.payload;
      } else {
        state.metrosHistory.push(action.payload);
      }
    },
    removeMetroFromHistory: (state, action: PayloadAction<string>) => {
      state.metrosHistory = state.metrosHistory.filter(
        (mH) => mH.metro !== action.payload
      );
    },
    setProperties: (state, action: PayloadAction<Property[]>) => {
      state.properties = action.payload;
    },
  },
});

export const {
  setCurrentAccessGroupData,
  setCurrentByMetro,
  setCurrentByRegion,
  setCurrentByProperty,
  setHistorical,
  updateHistorical,
  updateRegionsHistory,
  setRegionsHistory,
  setProperties,
  setMetrosHistory,
  updateMetrosHistoryWithCurrentData,
  addMetroToHistory,
  removeMetroFromHistory,
  setCurrentDataStatus,
  setCurrentPropertyStatus,
  setHistoryStatus,
  setRegionHistoryStatus,
  setMetroHistoryStatus,
} = rtmSlice.actions;

export default rtmSlice.reducer;
