import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { PropertyDefinitionBlock } from "../../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IDropdownItem } from "../../../../../../../../components/organisms/Dropdown/Dropdown";
import { TrafficType } from "../../../../../../../../models/configuration/definitions/property";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import { getSubscriberTrafficTypes } from "../../../../../../../../models/subscriber";
import { useSubscriberId } from "../../../../../../../../store/slices/subscriber/hooks";

interface TrafficTypeSectionProps {
  property: PropertyDefinitionType;
  onChange: (updatedPropertyDef: PropertyDefinitionType) => void;
}

interface DropdownItem {
  label: string;
  value: string;
  default: boolean;
  dataTestId: string;
}

interface DropdownItem {
  label: string;
  value: string;
  default: boolean;
  dataTestId: string;
}

export const TrafficTypeSection = ({
  property,
  onChange,
}: TrafficTypeSectionProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");
  const isViewMode = useIsViewMode();
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>();
  const subscriberId = useSubscriberId();

  useEffect(() => {
    const getTrafficTypes = async () => {
      const trafficTypes = await getSubscriberTrafficTypes(subscriberId);

      const items = trafficTypes.map<DropdownItem>((trafficType) => ({
        label: trafficType,
        value: trafficType,
        default: property.trafficType === trafficType,
        dataTestId: `traffic-type-${trafficType}`,
      }));

      setDropdownItems(items);
    };

    getTrafficTypes();
  }, []);

  return (
    <TrafficTypeContainer>
      <Subsection>{t("PROPERTY_SECTION_TRAFFIC_TYPE_TITLE")}</Subsection>
      <PropertyDefinitionBlock
        propertyCards={
          <PropertyDefinitionCard
            title={t("PROPERTY_CARD_TRAFFIC_TYPE_TYPE_TITLE")}
            additionalInfoTitle={t(
              "PROPERTY_CARD_TRAFFIC_TYPE_TYPE_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "PROPERTY_CARD_TRAFFIC_TYPE_TYPE_DESCRIPTION_CONTENT"
            )}
            required
            fieldType={FieldType.DropdownType}
            fieldProps={{
              placeholder: property.trafficType
                ? property.trafficType
                : t("PROPERTY_CARD_TRAFFIC_TYPE_TYPE_PLACEHOLDER"),
              items: dropdownItems,
              onSelect: (item: IDropdownItem<TrafficType>) => {
                onChange({ ...property, trafficType: item.value });
              },
              disabled: isViewMode,
              dataTestId: "traffic-type-dropdown-selector",
            }}
          />
        }
      />
    </TrafficTypeContainer>
  );
};

const TrafficTypeContainer = styled.div`
  margin-top: 40px;
`;

const Subsection = styled.h4``;
