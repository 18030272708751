import { FC } from "react";
import { useTranslation } from "react-i18next";

import { WidgetsContainer } from "../../../../../components/atoms/WidgetsContainer/WidgetsContainer";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { LineChartWidget } from "../../../../../components/organisms/LineChartWidget/LineChartWidget";
import { Report } from "../../../../../components/organisms/Report/Report";
import { SingleNumberWidget } from "../../../../../components/organisms/SingleNumberWidget/SingleNumberWidget";
import { SortedTableWidget } from "../../../../../components/organisms/SortedTable/SortedTable";

import { FixedWidgetIds, Metrics } from "../../../models/models";
import { SectionContainer } from "../../../report/styled";

export const Overview: FC = () => {
  const { t } = useTranslation("rtmReportPage");
  return (
    <Report id="rtm.overview">
      <ExpandableContent
        title={t("OVERVIEW_REPORT_EXPANDABLE_TITLE")}
        content={t("OVERVIEW_REPORT_EXPANDABLE_CONTENT")}
      />
      <br />
      <SectionContainer>
        <WidgetsContainer>
          <SingleNumberWidget
            metric={Metrics.reqPerSec}
            dataTestId="overview-report-reqPerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.mbPerSec}
            dataTestId="overview-report-mbPerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.missMbPerSec}
            dataTestId="overview-report-missMbPerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.missPerSec}
            dataTestId="overview-report-missPerSec-number"
          />
        </WidgetsContainer>
        <WidgetsContainer>
          <SingleNumberWidget
            metric={Metrics.fourZeroFourPerSec}
            dataTestId="overview-report-fourZeroFourPerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.fiveZeroThreePerSec}
            dataTestId="overview-report-fiveZeroThreePerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.fiveZeroFourPerSec}
            dataTestId="overview-report-fiveZeroFourPerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.fiveXXPerSec}
            dataTestId="overview-report-fiveXXPerSec-number"
          />
          <SingleNumberWidget
            metric={Metrics.hitRatePercent}
            dataTestId="overview-report-hitRatePercent-number"
          />
        </WidgetsContainer>
      </SectionContainer>
      <SectionContainer>
        <WidgetsContainer>
          <LineChartWidget
            id={FixedWidgetIds.REQ_PER_SEC}
            metrics={[Metrics.reqPerSec]}
            title={t("OVERVIEW_CHART_REQ_PERS_SEC")}
            dataTestId="overview-report-reqPerSec-history"
          />
        </WidgetsContainer>
        <WidgetsContainer>
          <LineChartWidget
            id={FixedWidgetIds.HIT_RATE_PERCENT}
            metrics={[Metrics.hitRatePercent]}
            title={t("OVERVIEW_CHART_HIT_RATE_PERCENT")}
            dataTestId="overview-report-hitRatePercent-history"
          />
        </WidgetsContainer>
      </SectionContainer>
      <SectionContainer>
        <WidgetsContainer>
          <SortedTableWidget
            title={t("OVERVIEW_TOP_PROPERTIES")}
            type="property"
            metric={Metrics.reqPerSec}
            dataTestId="top-property-reqPerSec"
          />
          <SortedTableWidget
            title={t("OVERVIEW_TOP_LOCATIONS")}
            type="region"
            metric={Metrics.reqPerSec}
            dataTestId="top-location-reqPerSec"
          />
        </WidgetsContainer>
      </SectionContainer>
    </Report>
  );
};
