import { TypeDefinitions } from "../../../../models/configuration/definitions/definition";
import { removeDeadPaths } from "../../../../utils/object";
import { DefinitionType, PrepareOptionsType } from "../types";

export const prepareDefinition = <T extends DefinitionType>(
  isStorage: boolean,
  definition: T,
  options?: PrepareOptionsType<T>
) => {
  const {
    type,
    name,
    _schemaDescription,
    unhandledFields,
    ...commonData
  } = definition;

  const definitionData = isStorage
    ? commonData
    : removeDeadPaths(commonData, options?.deadPathExceptions);

  const preparedDefinition = {
    ...Object.fromEntries(unhandledFields),
    ...definitionData,
    description: _schemaDescription,
  };

  if (
    type === TypeDefinitions.SIMPLE_DEFINITION &&
    (preparedDefinition as any).listType === "geo"
  ) {
    delete preparedDefinition.description;
  }

  if (type === TypeDefinitions.ORIGIN) {
    (preparedDefinition as any).type = preparedDefinition.originType;
    delete preparedDefinition.originType;
  }

  if (isStorage) {
    return preparedDefinition;
  } else {
    const { id, ...rest } = preparedDefinition;
    return rest;
  }
};
