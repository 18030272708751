import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { IModalChildren } from "../../../../../../components/atoms/Modal/Modal";
import { UserManagementUserData } from "../../../../../../models/user-management/users/users.interfaces";
import { ModalLineInputField } from "../../../../../../components/molecules/ModalLineInputField/ModalLineInputField";
import { TitleContentButtonsModalBody } from "../../../../../../components/molecules/TitleContentButtonsModalBody/TitleContentButtonsModalBody";

interface EditUserInfoModalProps extends IModalChildren {
  userInfo: UserManagementUserData;
  save: (firstName: string, lastName: string, userName: string) => void;
}

export const EditUserInfoModal = ({
  userInfo,
  closeModal,
  save,
}: EditUserInfoModalProps): ReactElement => {
  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [userName, setUserName] = useState(userInfo.userName);
  const { t } = useTranslation("usersPage");

  return (
    <TitleContentButtonsModalBody
      title={t("EDIT_USER_INFO_MODAL_TITLE")}
      cancelButton={{
        label: t("EDIT_USER_INFO_MODAL_CANCEL_BUTTON_LABEL"),
        backgroundColor: "baseLight",
        textColor: "primary",
        onClick: closeModal || (() => {}),
      }}
      submitButton={{
        label: t("EDIT_USER_INFO_MODAL_SAVE_BUTTON_LABEL"),
        onClick: () => {
          save(firstName, lastName, userName);
          if (closeModal) closeModal();
        },
      }}
    >
      <Fields>
        <ModalLineInputField
          label={t("EDIT_USER_INFO_MODAL_FIRST_NAME_LABEL")}
          value={firstName}
          onChange={setFirstName}
        />
        <ModalLineInputField
          label={t("EDIT_USER_INFO_MODAL_LAST_NAME_LABEL")}
          value={lastName}
          onChange={setLastName}
        />
        <ModalLineInputField
          label={t("EDIT_USER_INFO_MODAL_USERNAME_LABEL")}
          value={userName}
          onChange={setUserName}
        />
      </Fields>
    </TitleContentButtonsModalBody>
  );
};

const Fields = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
`;
