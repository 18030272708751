import styled from "@emotion/styled";

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  display: flex;
`;

export const Column = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
`;

export const StatusColumn = styled(Column)`
  align-items: flex-end;
`;

export const Title = styled.label`
  color: ${({ theme }) => theme.text.secondary};
  border-bottom: ${({ theme }) => `solid 1px ${theme.borders.mutedLight}`};
  padding-bottom: 16px;
`;

const OverflowWrappedTitle = styled.h5`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
`;

export const Bold = styled(OverflowWrappedTitle)`
  margin: 24px 0 !important;
`;

export const Regular = styled(OverflowWrappedTitle)`
  margin: 24px 0 !important;
  font-weight: 400 !important;
`;
