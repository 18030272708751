/* eslint-disable complexity */
import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IDropdownItem } from "../../../../../../../../components/organisms/Dropdown/Dropdown";
import {
  Certificate,
  CertificateValidity,
} from "../../../../../../../../models/certificate";
import {
  EncryptionLevels,
  IProtocolSetting,
  PropertySection,
  PropertySubsection,
  ServiceTypes,
} from "../../../../../../../../models/configuration/definitions/property";
import { SubscriberPermissions } from "../../../../../../../../models/subscriber";

import {
  useCertificates,
  useConfigurationsErrors,
} from "../../../../../../../../store/slices/caching/hooks";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import { useCheckSubscriberPremiumFeature } from "../../../../../../../../store/slices/subscriber/hooks";
import { setProtocolSettingsServiceType } from "./helpers";
import { getCertBadgeInfo } from "../../../../../../../../store/slices/caching/helpers/getCertBadgeInfo";
import { ColorSet } from "../../../../../../../../contexts/themeContext";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
} from "../../../../../../../../models/configuration/errors";
import { useAppDispatch } from "../../../../../../../../store/types";
import { handleUpdateConfigurationErrors } from "../../../../../../../../store/slices/caching/thunks";
import { Button } from "../../../../../../../../components/atoms/Button/Button";

interface HttpsConfigCardProps {
  protocolSettings: IProtocolSetting;
  updateProtocolSettings: (prop: IProtocolSetting) => void;
  id: string;
  index?: number;
  encryptionError?: string;
  registeredProperty?: boolean;
}

type CertificateDateColor = keyof ColorSet["text"] | keyof ColorSet["colors"];

export const HttpsConfigCard = ({
  protocolSettings,
  updateProtocolSettings,
  id,
  index,
  encryptionError,
  registeredProperty = false,
}: HttpsConfigCardProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");

  const isViewMode = useIsViewMode();
  const errors = useConfigurationsErrors();
  const dispatch = useAppDispatch();

  const checkSubscriberPermissions = useCheckSubscriberPremiumFeature();

  const [isPremiumAllowed, setIsPremiumAllowed] = useState(
    protocolSettings.httpsConfig?.serviceType === ServiceTypes.PREMIUM
  );
  const certificates = useCertificates();
  const [selectedCertificate, setSelectedCertificate] = useState<
    Certificate | undefined
  >();

  const [selectedCertificateDetails, setSelectedCertificateDetails] = useState<{
    from: { date: string; color: CertificateDateColor };
    to: { date: string; color: CertificateDateColor };
  }>();

  useEffect(() => {
    if (
      isPremiumAllowed === false &&
      protocolSettings.httpsConfig?.serviceType === ServiceTypes.PREMIUM
    ) {
      setIsPremiumAllowed(
        protocolSettings.httpsConfig?.serviceType === ServiceTypes.PREMIUM
      );
    }
  }, [protocolSettings]);

  useEffect(() => {
    if (selectedCertificate) {
      let fromColor: CertificateDateColor = "primary";
      let toColor: CertificateDateColor = "primary";

      const badge = getCertBadgeInfo(selectedCertificate);
      switch (badge.validity) {
        case CertificateValidity.NOT_VALID_YET:
          fromColor = "grey60";
          break;
        case CertificateValidity.VALID:
          toColor = "green60";
          break;
        case CertificateValidity.EXPIRES_SOON:
          toColor = "yellow50";
          break;
        case CertificateValidity.EXPIRED:
          toColor = "red70";
          break;
      }

      setSelectedCertificateDetails({
        from: {
          date: selectedCertificate.validity_not_before,
          color: fromColor,
        },
        to: {
          date: selectedCertificate.validity_not_after,
          color: toColor,
        },
      });
    }
  }, [selectedCertificate]);

  const certificatesDropdownItems = certificates
    ? Object.values(certificates).map((cert) => ({
        label: cert.cert_name,
        value: cert,
        dataTestId: `${id ? `${id}-` : ""}alias-dropdown-item-${
          cert.cert_name
        }`,
        default: protocolSettings.httpsConfig?.certificate === cert.cert_name,
      }))
    : [];

  return (
    <>
      <PropertyDefinitionCard
        title={t("PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_DESCRIPTION_CONTENT"
        )}
        required
        divider
        fieldType={FieldType.RadioButtonsType}
        fieldProps={{
          inline: true,
          radioButtonsProps: [
            {
              onClick: () => {
                const [checked] = checkSubscriberPermissions([
                  SubscriberPermissions.SSL_BASIC,
                ]);

                if (checked) {
                  if (isPremiumAllowed) {
                    toast.warn(
                      t(
                        "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_BASIC_WARNING_PREMIUM"
                      )
                    );
                  }

                  dispatch(
                    handleUpdateConfigurationErrors(
                      [],
                      [
                        (err) =>
                          !(
                            err.level === ConfigurationErrorLevel.ERROR &&
                            err.type ===
                              ConfigurationErrorType.PROPERTY_CERTIFICATE_EMPTY &&
                            err.data?.section === PropertySection.ALIASES &&
                            err.data?.subsection ===
                              PropertySubsection.PROTOCOL_SETTINGS
                          ),
                      ]
                    )
                  );

                  updateProtocolSettings(
                    setProtocolSettingsServiceType(protocolSettings, {
                      serviceType: ServiceTypes.BASIC,
                      certificate: undefined,
                    })
                  );
                } else {
                  toast.warn(
                    t(
                      "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_BASIC_WARNING"
                    ),
                    {
                      autoClose: false,
                      hideProgressBar: true,
                      progress: undefined,
                    }
                  );
                }
              },
              defaultChecked:
                protocolSettings.httpsConfig?.serviceType ===
                ServiceTypes.BASIC,
              value: ServiceTypes.BASIC,
              label: t(
                "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_BASIC_LABEL"
              ),
              name: `${id}-service-type-basic`,
              id: `${id}-service-type-basic`,
              disabled:
                isViewMode ||
                (registeredProperty &&
                  protocolSettings.httpsConfig?.serviceType !==
                    ServiceTypes.BASIC),
              dataTestId: "alias-ssl-certificate-type-protected-radio".concat(
                typeof index === "number" ? `-${index}` : ""
              ),
              popupText:
                registeredProperty &&
                protocolSettings.httpsConfig?.serviceType !== ServiceTypes.BASIC
                  ? "Changing his field will change postfix of your primary alias. We disabled editing because primary alias cannot be changed after it has been deployed / registered to the network. You can find all primary aliases that have been registered to the network on Production Tab → Property Registry Table."
                  : "",
            },
            {
              onClick: () => {
                const [checked] = checkSubscriberPermissions([
                  SubscriberPermissions.SSL_PREMIUM,
                ]);

                if (checked) {
                  if (isPremiumAllowed) {
                    toast.warn(
                      t(
                        "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_SNI_WARNING_PREMIUM"
                      )
                    );
                  }

                  if (!protocolSettings.httpsConfig?.certificate) {
                    dispatch(
                      handleUpdateConfigurationErrors(
                        [
                          {
                            level: ConfigurationErrorLevel.ERROR,
                            type:
                              ConfigurationErrorType.PROPERTY_CERTIFICATE_EMPTY,
                            data: {
                              section: PropertySection.ALIASES,
                              subsection: PropertySubsection.PROTOCOL_SETTINGS,
                            },
                          },
                        ],
                        []
                      )
                    );
                  }

                  updateProtocolSettings(
                    setProtocolSettingsServiceType(protocolSettings, {
                      serviceType: ServiceTypes.SNI,
                    })
                  );
                } else {
                  toast.warn(
                    t(
                      "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_SNI_WARNING"
                    ),
                    {
                      autoClose: false,
                      hideProgressBar: true,
                      progress: undefined,
                    }
                  );
                }
              },
              defaultChecked:
                protocolSettings.httpsConfig?.serviceType === ServiceTypes.SNI,
              value: ServiceTypes.SNI,
              label: t(
                "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_SNI_LABEL"
              ),
              name: `${id}-service-type-sni`,
              id: `${id}-service-type-sni`,
              disabled:
                isViewMode ||
                (registeredProperty &&
                  protocolSettings.httpsConfig?.serviceType ===
                    ServiceTypes.BASIC),
              dataTestId: "alias-ssl-certificate-type-own-radio".concat(
                typeof index === "number" ? `-${index}` : ""
              ),
              popupText:
                registeredProperty &&
                protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC
                  ? "Changing his field will change postfix of your primary alias. We disabled editing because primary alias cannot be changed after it has been deployed / registered to the network. You can find all primary aliases that have been registered to the network on Production Tab → Property Registry Table."
                  : "",
            },
            {
              value: ServiceTypes.PREMIUM,
              onClick: () => {
                updateProtocolSettings(
                  setProtocolSettingsServiceType(protocolSettings, {
                    serviceType: ServiceTypes.PREMIUM,
                  })
                );
              },
              label: t(
                "PROPERTY_CARD_ALIASES_OVERRIDE_SERVICE_TYPE_RADIO_PREMIUM_LABEL"
              ),
              name: `${id}-service-type-premium`,
              id: `${id}-service-type-premium`,
              disabled: isViewMode || !isPremiumAllowed,
              defaultChecked:
                protocolSettings.httpsConfig?.serviceType ===
                ServiceTypes.PREMIUM,
              dataTestId: "alias-ssl-certificate-type-own-premium-radio",
            },
          ],
        }}
        dataTestId="alias-ssl-certificate-type"
      />
      <PropertyDefinitionCard
        title={t("PROPERTY_CARD_ALIASES_OVERRIDE_ENCRYPTION_LEVELS_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ALIASES_OVERRIDE_ENCRYPTION_LEVELS_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ALIASES_OVERRIDE_ENCRYPTION_LEVELS_DESCRIPTION_CONTENT"
        )}
        required
        divider
        fieldType={FieldType.RadioButtonsType}
        fieldProps={{
          inline: true,
          radioButtonsProps: [
            {
              value: EncryptionLevels.RISKY,
              onClick: () => {
                updateProtocolSettings(
                  setProtocolSettingsServiceType(protocolSettings, {
                    encryptionLevel: EncryptionLevels.RISKY,
                  })
                );
              },
              label: t(
                "PROPERTY_CARD_ALIASES_OVERRIDE_ENCRYPTION_LEVELS_RADIO_RISKY_LABEL"
              ),
              name: `${id}-encryption-level-risky`,
              id: `${id}-encryption-level-risky`,
              defaultChecked:
                protocolSettings.httpsConfig?.encryptionLevel ===
                EncryptionLevels.RISKY,
              disabled: isViewMode,
              dataTestId: "alias-encryption-levels-risky-radio".concat(
                typeof index === "number" ? `-${index}` : ""
              ),
            },
            {
              onClick: () => {
                updateProtocolSettings(
                  setProtocolSettingsServiceType(protocolSettings, {
                    encryptionLevel: EncryptionLevels.DEFAULT,
                  })
                );
              },
              defaultChecked:
                protocolSettings.httpsConfig?.encryptionLevel ===
                EncryptionLevels.DEFAULT,
              value: EncryptionLevels.DEFAULT,
              label: t(
                "PROPERTY_CARD_ALIASES_OVERRIDE_ENCRYPTION_LEVELS_RADIO_DEFAULT_LABEL"
              ),
              name: `${id}-encryption-level-default`,
              id: `${id}-encryption-level-default`,
              disabled: isViewMode,
              dataTestId: "alias-encryption-levels-default-radio".concat(
                typeof index === "number" ? `-${index}` : ""
              ),
            },
            {
              onClick: () => {
                updateProtocolSettings(
                  setProtocolSettingsServiceType(protocolSettings, {
                    encryptionLevel: EncryptionLevels.ADVANCED,
                  })
                );
              },
              defaultChecked:
                protocolSettings.httpsConfig?.encryptionLevel ===
                EncryptionLevels.ADVANCED,
              value: EncryptionLevels.ADVANCED,
              label: t(
                "PROPERTY_CARD_ALIASES_OVERRIDE_ENCRYPTION_LEVELS_RADIO_ADVANCED_LABEL"
              ),
              name: `${id}-encryption-level-advanced`,
              id: `${id}-encryption-level-advanced`,
              disabled: isViewMode,
              dataTestId: "alias-encryption-levels-advanced-radio".concat(
                typeof index === "number" ? `-${index}` : ""
              ),
            },
          ],
        }}
        dataTestId={"alias-encryption-levels".concat(
          typeof index === "number" ? `-${index}` : ""
        )}
        errorMessage={encryptionError}
      />
      {protocolSettings.httpsConfig!.serviceType === ServiceTypes.SNI && (
        <PropertyDefinitionCard
          required
          divider
          title={t("PROPERTY_CARD_ALIASES_OVERRIDE_CERTIFICATE_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ALIASES_OVERRIDE_CERTIFICATE_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ALIASES_OVERRIDE_CERTIFICATE_DESCRIPTION_CONTENT"
          )}
          errorMessage={
            errors.some(
              (err) =>
                err.type ===
                  ConfigurationErrorType.PROPERTY_CERTIFICATE_EMPTY &&
                err.data.section === PropertySection.ALIASES &&
                err.data.subsection === PropertySubsection.PROTOCOL_SETTINGS
            )
              ? t("ERROR_ALIAS_NO_CERTIFICATE")
              : undefined
          }
          fieldType={FieldType.DropdownType}
          fieldProps={{
            value: protocolSettings.httpsConfig?.certificate,
            placeholder: t(
              "PROPERTY_CARD_ALIASES_OVERRIDE_CERTIFICATE_PLACEHOLDER"
            ),
            onSelect: (newCertificate: IDropdownItem<Certificate>) => {
              updateProtocolSettings(
                setProtocolSettingsServiceType(protocolSettings, {
                  certificate: newCertificate.label,
                })
              );
              setSelectedCertificate(newCertificate.value);

              dispatch(
                handleUpdateConfigurationErrors(
                  [],
                  [
                    (err) =>
                      !(
                        err.level === ConfigurationErrorLevel.ERROR &&
                        err.type ===
                          ConfigurationErrorType.PROPERTY_CERTIFICATE_EMPTY &&
                        err.data?.section === PropertySection.ALIASES &&
                        err.data?.subsection ===
                          PropertySubsection.PROTOCOL_SETTINGS
                      ),
                  ]
                )
              );
            },
            items: certificatesDropdownItems,
            other:
              selectedCertificate && selectedCertificateDetails ? (
                <CertificateDetail>
                  From{" "}
                  <CertificateDate
                    color={selectedCertificateDetails.from.color}
                  >
                    {new Date(
                      selectedCertificateDetails.from.date
                    ).toLocaleDateString("fr-FR")}
                  </CertificateDate>{" "}
                  to{" "}
                  <CertificateDate color={selectedCertificateDetails.to.color}>
                    {new Date(
                      selectedCertificateDetails.to.date
                    ).toLocaleDateString("fr-FR")}
                  </CertificateDate>
                </CertificateDetail>
              ) : certificatesDropdownItems.length === 0 ? (
                <CertificateDetail>
                  <Button
                    onClick={() => {
                      window.open("/cmv3/certificates", "_blank");
                    }}
                    label={t("ADD_CERTIFICATE")}
                    backgroundColor="baseLight"
                    textColor="highlight"
                    borderColor="highlight"
                    dataTestId="property-header-raw-json"
                  />
                </CertificateDetail>
              ) : undefined,
            disabled: isViewMode,
            dataTestId: `${
              id ? `${id}-` : ""
            }alias-certificate-dropdown-selector`,
          }}
          dataTestId="alias-certificate"
        />
      )}
      <PropertyDefinitionCard
        divider
        title={t("PROPERTY_CARD_ALIASES_OCSP_TITLE")}
        additionalInfoTitle={t("PROPERTY_CARD_ALIASES_OCSP_DESCRIPTION_TITLE")}
        additionalInfoContent={t(
          "PROPERTY_CARD_ALIASES_OCSP_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.CheckboxType}
        fieldProps={{
          value: protocolSettings.httpsConfig?.ocsp,
          checked: protocolSettings.httpsConfig?.ocsp,
          label: t("PROPERTY_CARD_ALIASES_OCSP_LABEL"),
          onChange: (ocsp) => {
            updateProtocolSettings(
              setProtocolSettingsServiceType(protocolSettings, {
                ocsp,
              })
            );
          },
          disabled: isViewMode,
          dataTestId: "alias-ocsp",
        }}
        dataTestId="alias-ocsp"
      />
      <PropertyDefinitionCard
        title={t("PROPERTY_CARD_ALIASES_OVERRIDE_INVALID_CERT_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ALIASES_OVERRIDE_INVALID_CERT_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ALIASES_OVERRIDE_INVALID_CERT_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.CheckboxType}
        fieldProps={{
          value: protocolSettings.httpsConfig?.http2,
          checked: protocolSettings.httpsConfig?.http2,
          label: t(
            "PROPERTY_CARD_ALIASES_OVERRIDE_INVALID_CERT_CHECKBOX_HTTP2_LABEL"
          ),
          onChange: (http2) => {
            updateProtocolSettings(
              setProtocolSettingsServiceType(protocolSettings, {
                http2,
              })
            );
          },
          disabled:
            isViewMode ||
            (registeredProperty &&
              protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC),
          dataTestId: "alias-http-2".concat(
            typeof index === "number" ? `-${index}` : ""
          ),
        }}
        dataTestId="alias-http-2"
      />
    </>
  );
};

const CertificateDate = styled.span<{
  color: CertificateDateColor;
}>`
  font-weight: 500;
  color: ${({ color, theme }) => ({ ...theme.text, ...theme.colors }[color])};
`;

const CertificateDetail = styled.span`
  font-size: 14px;
`;
