import { FC } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ProductionSlot } from "../../../models/configuration";
import { checkUserPermissions, UserRoles } from "../../../models/permissions";
import { DeploymentModal } from "../../../modules/caching/configurations/deployment";
import {
  handleDeleteConfiguration,
  handleSaveSelectedConfiguration,
} from "../../../store/slices/caching/thunks";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useUserPermissions } from "../../../store/slices/permissions/hooks";
import { useAppDispatch } from "../../../store/types";
import { Badge } from "../../atoms/Badge/Badge";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { ListItem } from "../../atoms/ListItem/ListItem";
import { Protected } from "../../atoms/Protected/Protected";
import { DeleteConfigurationModal } from "../DeleteConfigurationModal/DeleteConfigurationModal";
import { Tooltip } from "../Tooltip/Tooltip";
import { ConfigurationType } from "../../../store/slices/caching/types";
import { useIsProductionDeployed } from "../../../store/slices/caching/hooks";
import { Button } from "../../atoms/Button/Button";
import { submitConfiguration } from "../../../store/slices/caching/services";
import { APIError } from "../../../models/error";

export interface IConfigurationListItemProps {
  onEdit?: () => void;
  configuration: ConfigurationType;
}

export const ConfigurationListItem: FC<IConfigurationListItemProps> = ({
  configuration,
  onEdit,
}) => {
  const permissions = useUserPermissions();

  const deploymentData = useIsProductionDeployed(
    configuration?.configName ?? ""
  );
  const { t } = useTranslation("configurationsPage");
  const [tConfigurationPage] = useTranslation("configurationPage");

  const dispatch = useAppDispatch();

  const handleMediaPortalError = useDispatchAPIError();

  return (
    <ConfigurationListItemContainer
      className="-p--1 -pl--2 -pr--3"
      data-testid={`configuration-card-${configuration.configName}`}
    >
      <SubContainer>
        <LeftPart>
          <Name className=" -text--h5">{configuration.configName} </Name>
          <ProductionBadge
            data-testid={`configuration-card-production-badge-${configuration.configName}`}
          >
            {deploymentData?.current !== undefined && (
              <Badge
                label={t("CONFIGURATION_CARD_PRODUCTION_SLOT", {
                  productionSlot: ProductionSlot.CURRENT,
                })}
                color={
                  deploymentData.current !== configuration.versionId
                    ? "orange50"
                    : "navy60"
                }
                customHelpIcon={
                  deploymentData.current !== configuration.versionId ? (
                    <CustomHelpIconContainer data-testid="configuration-card-production-badge-current-container">
                      <Tooltip
                        popupText={t(
                          "CONFIGURATION_CARD_PRODUCTION_BADGE_POPUP"
                        )}
                        id={`configuration-card-production-badge-current-popup-${configuration.transactionId}`}
                        dataTestId="configuration-card-production-badge-current-popup"
                      >
                        <Icon
                          name={Icons.INFO_OUTLINE}
                          size={12}
                          color="white"
                        />
                      </Tooltip>
                    </CustomHelpIconContainer>
                  ) : undefined
                }
              />
            )}
            {deploymentData?.candidate !== undefined && (
              <CandidateBadge>
                <Badge
                  label={t("CONFIGURATION_CARD_PRODUCTION_SLOT", {
                    productionSlot: ProductionSlot.CANDIDATE,
                  })}
                  color={
                    deploymentData.candidate !== configuration.versionId
                      ? "orange50"
                      : "grey60"
                  }
                  customHelpIcon={
                    deploymentData.candidate !== configuration.versionId ? (
                      <CustomHelpIconContainer data-testid="configuration-card-production-badge-candidate-container">
                        <Tooltip
                          popupText={t(
                            "CONFIGURATION_CARD_PRODUCTION_BADGE_POPUP"
                          )}
                          id={`configuration-card-production-badge-candidate-popup-${configuration.transactionId}`}
                          dataTestId="configuration-card-production-badge-candidate-popup"
                        >
                          <Icon
                            name={Icons.INFO_OUTLINE}
                            size={12}
                            color="white"
                          />
                        </Tooltip>
                      </CustomHelpIconContainer>
                    ) : undefined
                  }
                />
              </CandidateBadge>
            )}
          </ProductionBadge>
        </LeftPart>
        <Version className=" -text--h5">
          <VersionLabel>{t("CONFIGURATION_CARD_VERSION")}</VersionLabel> &nbsp;{" "}
          {t("CONFIGURATION_CARD_VERSION_COUNT", {
            count: configuration.versionId,
          })}
        </Version>
      </SubContainer>
      <RightPart>
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          {configuration.isLocalDraft ? (
            <div style={{ paddingRight: "10px" }}>
              <Badge
                helpText={t("CONFIGURATION_CARD_DRAFT_BADGE_HELPER")}
                label={t("CONFIGURATION_CARD_DRAFT_BADGE_LABEL")}
                color="yellow40"
                dataTestId={`configuration-card-changes-badge-${configuration.configName}`}
              />
            </div>
          ) : null}
          <CommitButton
            label={t("CONFIGURATION_CARD_COMMIT_BUTTON")}
            disabled={!configuration.isLocalDraft}
            onClick={() => {
              submitConfiguration(configuration)
                .then(
                  ({
                    versions,
                    versionId,
                    isLocalDraft,
                    modifiedTime,
                    genSchemaVersion,
                  }) => {
                    const newConfig: ConfigurationType = {
                      ...configuration,
                      versions,
                      versionId,
                      isLocalDraft,
                      modifiedTime,
                      schemaVersion:
                        genSchemaVersion ?? configuration.schemaVersion,
                    };

                    if (newConfig.config) {
                      newConfig.config = {
                        ...newConfig.config,
                        genSchemaVersion:
                          genSchemaVersion ?? newConfig.config.genSchemaVersion,
                      };
                    }

                    dispatch(handleSaveSelectedConfiguration(newConfig, false));
                    toast.success(
                      `${tConfigurationPage("CONFIGURATION_SUBMIT_SUCCESS", {
                        id: versionId,
                      })}`
                    );
                  }
                )
                .catch((err) => {
                  handleMediaPortalError(err as APIError);
                });
            }}
            dataTestId={`configuration-card-commit-button-${configuration.configName}`}
          />
          <div onClick={(e) => e.stopPropagation()}>
            <DeploymentModal
              configuration={configuration}
              openButtonProps={{
                label: t("CONFIGURATION_CARD_DEPLOY_BUTTON"),
                dataTestId: `configuration-card-deploy-button-${configuration.configName}`,
              }}
              onError={handleMediaPortalError}
            />
          </div>
        </Protected>
        <EditIcon
          name={
            checkUserPermissions(UserRoles.EDIT_CONFIG, permissions)
              ? Icons.EDIT
              : Icons.EYE
          }
          color="secondary"
          onClick={() => onEdit && onEdit()}
          dataTestId={`configuration-card-${configuration.configName}-edit-button`}
        />
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          <DeleteConfigurationModal
            configName={configuration.configName}
            onDelete={() => dispatch(handleDeleteConfiguration(configuration))}
          />
        </Protected>
      </RightPart>
    </ConfigurationListItemContainer>
  );
};

const ConfigurationListItemContainer = styled(ListItem)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const LeftPart = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CommitButton = styled(Button)`
  margin-right: 48px;
`;

const Name = styled.h5`
  padding-right: 15px;
`;

const Version = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const VersionLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const ProductionBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  font-size: 12px;
`;

const CustomHelpIconContainer = styled.div`
  padding-right: 4px;
`;

const CandidateBadge = styled.div`
  padding-left: 12px;
`;

const EditIcon = styled(Icon)`
  margin: 0 48px;
  cursor: pointer;
`;
