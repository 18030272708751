import { FC } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Modal } from "../../atoms/Modal/Modal";
import { ModalChild } from "./ModalChild";
import { ReactComponent as PenSVG } from "../../../assets/icons/pen.svg";
import { ReactComponent as EyeSVG } from "../../../assets/icons/eye.svg";
import { UserRoles } from "../../../models/permissions";
import { Protected } from "../../atoms/Protected/Protected";
import {
  ConfigurationDetailsType,
  DefinitionType,
} from "../../../store/slices/caching/types";

export interface IDefinitionModalProps {
  title?: string;
  header?: JSX.Element;
  references: number;
  propertyReferences?: number;
  isEdit: boolean;
  size?: "auto" | "large" | "small";
  dataTestId?: string;
  className?: string;
  canEdit?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  validation: () => boolean;
}

export const DefinitionModal: FC<IDefinitionModalProps> = ({
  children,
  references,
  propertyReferences,
  header,
  isEdit,
  size,
  dataTestId,
  className,
  canEdit = true,
  onSubmit,
  onCancel,
  validation,
}) => {
  const { t } = useTranslation("configurationDefinitionsPage");

  return (
    <Protected permissions={UserRoles.EDIT_CONFIG}>
      <Modal
        customButton={
          isEdit ? (
            canEdit ? (
              <EditModalButton
                data-testid={`definition-modal-edit-button-${dataTestId}`}
              />
            ) : (
              <ViewModalButton
                data-testid={`definition-modal-view-button-${dataTestId}`}
              />
            )
          ) : (
            <AddDefinitionButton
              type="button"
              data-testid={`definition-modal-add-button-${dataTestId}`}
            >
              {t("ADD_DEFINITION_BUTTON")}
            </AddDefinitionButton>
          )
        }
        onBlur={onCancel}
        size={size}
      >
        <ModalChild
          header={header}
          references={references}
          propertyReferences={propertyReferences}
          onSubmit={onSubmit}
          onCancel={onCancel}
          validation={validation}
          dataTestId={dataTestId}
          className={className}
        >
          {children}
        </ModalChild>
      </Modal>
    </Protected>
  );
};

export interface IDefaultDefinitionModalProps<
  Definition extends DefinitionType
> {
  definition?: Definition;
  canEdit?: boolean;
  parentConfig: ConfigurationDetailsType;
  onSubmit: (definition: Definition) => void;
}

const EditModalButton = styled(PenSVG)`
  width: 16px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.icon.secondary};
  }
`;

const ViewModalButton = styled(EyeSVG)`
  width: 16px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.icon.secondary};
  }
`;

const AddDefinitionButton = styled.button`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: all 0.5s linear;
  font-weight: 550;
  color: ${({ theme }) => theme.text.highlight};

  :hover {
    opacity: 0.5;
  }
`;
