import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../@types/status";
import {
  AccessGroupInfo,
  AccessGroupMember,
  AccessGroupProduct,
} from "../../../models/user-management/access-group/access-group.interfaces";
import { UserManagementAccessGroupSlice } from "./types";

const initialState: UserManagementAccessGroupSlice = {
  infos: undefined,
  members: undefined,
  products: undefined,
  status: Status.IDLE,
  availableProducts: undefined,
};

export const userManagementAccessGroupSlice = createSlice({
  name: "userManagementAccessGroup",
  initialState,
  reducers: {
    setAccessGroupInfos: (
      state,
      action: PayloadAction<AccessGroupInfo | undefined>
    ) => {
      state.infos = action.payload;
    },
    setAccessGroupProducts: (
      state,
      action: PayloadAction<AccessGroupProduct[]>
    ) => {
      state.products = action.payload;
    },
    setAvailableProducts: (
      state,
      action: PayloadAction<AccessGroupProduct[]>
    ) => {
      state.availableProducts = action.payload;
    },
    setAccessGroupMembers: (
      state,
      action: PayloadAction<AccessGroupMember[]>
    ) => {
      state.members = action.payload;
    },
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
  },
});

export const {
  setAccessGroupInfos,
  setAccessGroupProducts,
  setAvailableProducts,
  setAccessGroupMembers,
  setStatus,
} = userManagementAccessGroupSlice.actions;

export default userManagementAccessGroupSlice.reducer;
