import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { ExpandableContent } from "../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { useQuery } from "../../../../../../hooks/use-query";
import { IMatchRule } from "../../../../../../models/configuration/definitions";
import { MatchBlocks } from "../../../../../../models/configuration/definitions/matchlogic";
import { ConditionsEditor } from "./conditions-editor";
import { ConditionsUI } from "./conditions-ui";
import { useInitialPreview } from "./useInitialPreview";
import { getQueryParams } from "../../../../../../utils/getQueryParams";
import { ContentSwitcher } from "../../../../../../components/molecules/ContentSwitcher/ContentSwitcher";
import { FlexBoxBase } from "../../../../../../components/atoms/FlexBox/FlexBox";
import { useSaveValidatedMatchLogic } from "../../../../../../hooks/use-save-validated-match-logic/useSaveValidatedMatchLogic";
import { toast } from "react-toastify";

export enum EditionModes {
  CODE_EDITOR = "codeEditor",
  UI = "UI",
}

interface ConditionsProps {
  handleMatchRuleChange: (modifiedMatchRule: IMatchRule) => void;
  handleSaveMatchRule: () => void;
  matchBlock: MatchBlocks;
  matchRule: IMatchRule;
}

export const Conditions = ({
  handleMatchRuleChange,
  handleSaveMatchRule,
  matchBlock,
  matchRule,
}: ConditionsProps): ReactElement => {
  const [editionModeQueried] = useQuery<EditionModes>("editionMode");

  const { t } = useTranslation("configurationMatchRulesPage");
  const location = useLocation();
  const history = useHistory();
  const onSave = useSaveValidatedMatchLogic(
    matchBlock,
    matchRule,
    handleSaveMatchRule
  );

  const shouldNotRender = useInitialPreview(matchBlock, matchRule);
  if (shouldNotRender) return <></>;

  return (
    <>
      <HeaderContainer>
        <ExpandableContent
          title={t("CONDITIONS_DESCRIPTION_TITLE")}
          content={t("CONDITIONS_DESCRIPTION_CONTENT")}
        />
        <ContentSwitcher
          tabs={[
            {
              label: t("CONDITIONS_CONTENT_SWITCHER_BUILDER"),
              value: EditionModes.UI,
              dataTestId: "conditions-content-switcher-builder",
            },
            {
              label: t("CONDITIONS_CONTENT_SWITCHER_CODE"),
              value: EditionModes.CODE_EDITOR,
              dataTestId: "conditions-content-switcher-code",
            },
          ]}
          onChange={(tab) => {
            onSave().then((response) => {
              if (response !== false) {
                const queryParams = getQueryParams(location);
                queryParams.set("editionMode", tab.value || EditionModes.UI);
                history.push(`${location.pathname}?${queryParams.toString()}`);
                toast.success(t("EXPRESSION_SAVED_LOCALLY"));
              }
            });
          }}
          value={editionModeQueried}
        />
      </HeaderContainer>
      <ConditionsWrapper>
        {editionModeQueried === EditionModes.UI ? (
          <ConditionsUI
            onCodeChange={handleMatchRuleChange}
            handleSave={handleSaveMatchRule}
            matchRule={matchRule}
            matchBlock={matchBlock}
          />
        ) : (
          <ConditionsEditor
            onCodeChange={handleMatchRuleChange}
            handleSave={handleSaveMatchRule}
            matchRule={matchRule}
            matchBlock={matchBlock}
          />
        )}
      </ConditionsWrapper>
    </>
  );
};

const HeaderContainer = styled(FlexBoxBase)`
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ConditionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;
