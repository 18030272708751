import { SubscriberPermissions } from "../../../models/subscriber";
import { useAppSelector } from "../../types";
import { SubscriberSlice } from "./types";

export const useUnsafeScid = (): string | undefined =>
  useAppSelector((store) => store.subscriber.scid);

export const useSubscriberId = (): number => {
  const subscriberId = useAppSelector((store) => store.subscriber.subscriberId);

  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "subscriber"');
  }

  return subscriberId;
};

export const useSubscriberStatus = (): SubscriberSlice["subscriberStatus"] =>
  useAppSelector((store) => store.subscriber.subscriberStatus);

export const useCheckSubscriberPremiumFeature = (): ((
  permissionsToCheck: SubscriberPermissions[]
) => boolean[]) => {
  const premiumFeatures = useAppSelector(
    (store) => store.subscriber.premiumFeatures
  );
  return (permissionsToCheck) =>
    permissionsToCheck.map((permission) =>
      premiumFeatures
        ? premiumFeatures.premiumFeatures.services.includes(permission) ||
          premiumFeatures.premiumFeatures.overrides.includes(permission)
        : false
    );
};
