import styled from "@emotion/styled";
import { animated } from "react-spring";

export const Tab = styled.h4<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  color: ${({ selected, theme }) =>
    selected ? theme.text.highlight : theme.text.primary};
  transition: color 0.25s ease-in-out;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
`;

export const Slider = styled(animated.div)`
  position: relative;
  margin-top: 1px;
  background-color: ${({ theme }) => theme.text.highlight};
  height: 2px;
`;
