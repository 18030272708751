import { ConfigurationType } from "../../../../store/slices/caching/types";
import { ConfigurationOpt } from "../configuration_page/properties/property_page/CompareVersionModal";

export const getConfigurationOptions = (
  configurations: ConfigurationType[],
  getSelected: (configuration: ConfigurationType) => boolean
): ConfigurationOpt[] =>
  configurations.reduce<ConfigurationOpt[]>((options, c, index) => {
    options.push({
      index,
      text: `${c.configName}`,
      value: c.id,
      selected: getSelected(c),
    });

    return options;
  }, []);
