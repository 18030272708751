import styled from "@emotion/styled";
import { animated } from "react-spring";

export const DropdownMenu = styled(animated.div)`
  display: block !important;
  min-width: 250px;
  width: inherit;
  background-color: ${({ theme }) => theme.backgrounds.baseLight}!important;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow-y: auto !important;
  max-height: 250px !important;
`;
