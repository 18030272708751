import styled from "@emotion/styled";
import { AxiosError } from "axios";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonProps,
} from "../../../../../../components/atoms/Button/Button";
import { CustomRemark } from "../../../../../../components/atoms/CustomRemark/CustomRemark";
import { FlexBoxBase } from "../../../../../../components/atoms/FlexBox/FlexBox";
import { IModalChildren } from "../../../../../../components/atoms/Modal/Modal";
import { httpClient } from "../../../../../../core/http-client";
import { ErrorLevel, APIError } from "../../../../../../models/error";
import {
  resetUserPassword,
  updateUserInfo,
} from "../../../../../../models/user-management/users";
import {
  UserManagementUserData,
  UserStatus,
} from "../../../../../../models/user-management/users/users.interfaces";
import { handleAPIError } from "../../../../../../store/slices/api-error/thunks";
import { useAppDispatch } from "../../../../../../store/types";
import { DeleteUserWarningModal } from "./DeleteUserWarningModal";

export enum ActionTypes {
  DELETE = "delete",
  DISABLE = "disable",
  ENABLE = "enable",
  PASSWORD_RESET = "reset_password",
}

interface ActionModalProps extends IModalChildren {
  action?: ActionTypes;
  userInfo: UserManagementUserData;
}

interface DeleteErrorResponse {
  userId: number;
  apiKeys: {
    name: string;
    id: number;
  }[];
}

export const ActionModal = ({
  action,
  closeModal,
  userInfo,
}: ActionModalProps): ReactElement => {
  const { t } = useTranslation("usersPage");
  const dispatch = useAppDispatch();
  const [deletedUserApiKeys, setDeletedUserApiKeys] = useState<
    DeleteErrorResponse["apiKeys"]
  >();

  let content = "";
  let title = "";
  let cancelButtonProps: Omit<ButtonProps, "onClick"> = { label: "" };
  let approveButtonProps: Omit<ButtonProps, "onClick"> = { label: "" };

  switch (action) {
    case ActionTypes.DELETE:
      title = t("DELETE_USER_MODAL_TITLE");
      content = t("DELETE_USER_MODAL_CONTENT", {
        userName: userInfo.firstName + " " + userInfo.lastName,
      });
      approveButtonProps = {
        label: t("DELETE_USER_MODAL_APPROVE_BUTTON"),
        backgroundColor: "error",
      };
      cancelButtonProps = {
        label: t("DELETE_USER_MODAL_CANCEL_BUTTON"),
        backgroundColor: "baseLight",
        textColor: "primary",
        borderColor: "mutedLight",
      };
      break;
    case ActionTypes.DISABLE:
      title = t("DISABLE_USER_MODAL_TITLE");
      content = t("DISABLE_USER_MODAL_CONTENT", {
        userName: userInfo.firstName + " " + userInfo.lastName,
      });
      approveButtonProps = {
        label: t("DISABLE_USER_MODAL_APPROVE_BUTTON"),
        backgroundColor: "error",
      };
      cancelButtonProps = {
        label: t("DISABLE_USER_MODAL_CANCEL_BUTTON"),
        backgroundColor: "baseLight",
        textColor: "primary",
        borderColor: "mutedLight",
      };
      break;
    case ActionTypes.ENABLE:
      title = t("ENABLE_USER_MODAL_TITLE");
      content = t("ENABLE_USER_MODAL_CONTENT", {
        userName: userInfo.firstName + " " + userInfo.lastName,
      });
      approveButtonProps = { label: t("ENABLE_USER_MODAL_APPROVE_BUTTON") };
      cancelButtonProps = {
        label: t("ENABLE_USER_MODAL_CANCEL_BUTTON"),
        backgroundColor: "baseLight",
        textColor: "primary",
        borderColor: "mutedLight",
      };
      break;
    case ActionTypes.PASSWORD_RESET:
      title = t("RESET_PASSWORD_MODAL_TITLE");
      content = t("RESET_PASSWORD_MODAL_CONTENT", {
        userName: userInfo.firstName + " " + userInfo.lastName,
      });
      approveButtonProps = {
        label: t("RESET_PASSWORD_MODAL_APPROVE_BUTTON"),
      };
      cancelButtonProps = {
        label: t("RESET_PASSWORD_MODAL_CANCEL_BUTTON"),
        backgroundColor: "baseLight",
        textColor: "primary",
        borderColor: "mutedLight",
      };
      break;
  }

  const handleAction = () => {
    switch (action) {
      case ActionTypes.DELETE:
        httpClient
          .delete(`/users/${userInfo?.userId}`)
          .then(closeModal)
          .catch((err) => {
            const error = err as AxiosError<DeleteErrorResponse>;
            if (
              error.response?.status === 403 &&
              error.response.data.apiKeys.length > 0
            ) {
              setDeletedUserApiKeys(error.response.data.apiKeys);
            } else {
              dispatch(
                handleAPIError(
                  new APIError(
                    error.message,
                    `User.action(${action})`,
                    ErrorLevel.ERROR,
                    error.response
                  )
                )
              );
            }
          });
        break;
      case ActionTypes.DISABLE:
        updateUserInfo(userInfo.userId, {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          userName: userInfo.userName,
          status: UserStatus.DISABLED,
          email: userInfo.email,
        })
          .then(closeModal)
          .catch((err) => {
            dispatch(handleAPIError(err as APIError));
          });
        break;
      case ActionTypes.ENABLE:
        updateUserInfo(userInfo.userId, {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          userName: userInfo.userName,
          status: UserStatus.ACTIVE,
          email: userInfo.email,
        })
          .then(closeModal)
          .catch((err) => {
            dispatch(handleAPIError(err as APIError));
          });
        break;
      case ActionTypes.PASSWORD_RESET:
        resetUserPassword(userInfo.userId)
          .then(closeModal)
          .catch((err) => {
            dispatch(handleAPIError(err as APIError));
          });
        break;
    }
  };

  return (
    <>
      {deletedUserApiKeys ? (
        <DeleteUserWarningModal
          apiKeys={deletedUserApiKeys}
          userName={userInfo.firstName + " " + userInfo.lastName}
          closeModal={closeModal}
        />
      ) : (
        <Container>
          <Title>{title}</Title>
          <Content>
            <CustomRemark>{content}</CustomRemark>
          </Content>
          <ButtonsContainer>
            <CancelButton
              {...cancelButtonProps}
              onClick={closeModal || (() => {})}
            />
            <ApproveButton {...approveButtonProps} onClick={handleAction} />
          </ButtonsContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  padding: 32px;
  width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 16px;
`;

const Content = styled.div`
  padding-bottom: 32px;
`;

const ButtonsContainer = styled(FlexBoxBase)`
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
  padding: 12px 16px;
`;

const ApproveButton = styled(Button)`
  padding: 12px 16px;
`;
