import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { MatchLogicDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createMatchLogicDefinition = (
  name: string,
  rest: Pick<MatchLogicDefinitionType, "matchBlocks">
): MatchLogicDefinitionType => ({
  ...parseDefinition({
    name,
    type: TypeDefinitions.MATCH_LOGIC,
  }),
  matchBlocks: rest.matchBlocks,
});
