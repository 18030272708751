import { IMatchRule } from "../../../../../models/configuration/definitions";

export const updateHeaderReferences = (oldName: string, newName: string) => (
  matchRule: IMatchRule
): IMatchRule => {
  if (matchRule.features.reqHeaders) {
    const newReqHeaders = matchRule.features.reqHeaders.nameList.map(
      (headerName) => (headerName === oldName ? newName : headerName)
    );

    matchRule.features.reqHeaders.nameList = newReqHeaders;
  }

  if (matchRule.features.respHeaders) {
    const newRespHeaders = matchRule.features.respHeaders.nameList.map(
      (headerName) => (headerName === oldName ? newName : headerName)
    );

    matchRule.features.respHeaders.nameList = newRespHeaders;
  }

  return matchRule;
};
