import { SpringValue } from "react-spring";
import styled from "@emotion/styled";

import { DropDownOptionContainer } from "../../atoms/Dropdown/DropdownOptionContainer";
import { SelectionCircle } from "../../atoms/SelectionCircle/SelectionCircle";

export interface IDropdownItem<Value = string> {
  label: string;
  value: Value;
  default?: boolean;
  dataTestId?: string;
}

export interface IDropDownOptionProps<Data = string> {
  styles: {
    opacity: SpringValue<number>;
  };
  selection: IDropdownItem<Data>;
  selected: boolean;
  multiSelect?: boolean;
  dataTestId?: string;

  onSelect: (selection: IDropdownItem<Data>) => void;
}

export const DropDownOption = <T extends unknown>({
  styles,
  selection,
  onSelect,
  selected,
  multiSelect = false,
  dataTestId,
}: IDropDownOptionProps<T>): JSX.Element => {
  return (
    <DropDownOptionContainer
      style={styles}
      onClick={() => {
        onSelect(selection);
      }}
      selected={selected}
      data-testid={selection.dataTestId}
    >
      {multiSelect ? (
        <div className="chi-checkbox">
          <input
            type="checkbox"
            className="chi-checkbox__input"
            checked={selected}
            onChange={() => {}}
            data-testid={`${selection.dataTestId}-checkbox-input`}
          />
          <Label className="chi-checkbox__label">{selection.label}</Label>
        </div>
      ) : (
        <>
          <SelectionCircle selected={selected} />
          <Item data-testid={`${dataTestId}-${selection.label}`}>
            {selection.label}
          </Item>
        </>
      )}
    </DropDownOptionContainer>
  );
};

const Item = styled.div`
  flex: 1 1;
`;

const Label = styled.label`
  ::before {
    top: calc(50% - 0.5rem) !important;
  }
`;
