import { FeatureTypes } from "../../../../../../../components/molecules/FeatureType/FeatureType";
import { IFeatures } from "../../../../../../../models/configuration/definitions";
import { FilterFeatureTypeValue } from "../types";

type CheckFiltered = (
  selectedFeatureType: FilterFeatureTypeValue,
  ...checkSearchedParams: string[]
) => boolean;

const checkSearched: (
  featureType: FeatureTypes,
  ...contents: string[]
) => boolean = (featureType, searchValue, featureName, featureDesc) => {
  if (!!searchValue) {
    const isMatch = [featureName, featureType, featureDesc].some((content) =>
      content.toLowerCase().includes(searchValue.toLowerCase().trim())
    );

    return isMatch;
  }

  return true;
};

const checkFiltered: (featureType: FeatureTypes) => CheckFiltered = (
  featureType
) => (selectedFeatureType, ...checkSearchedParams) => {
  const isFilteredFeatureType =
    selectedFeatureType === "all" || selectedFeatureType === featureType;

  return (
    isFilteredFeatureType && checkSearched(featureType, ...checkSearchedParams)
  );
};

export const FEATURES_FILTER_CONFIGS: Record<keyof IFeatures, CheckFiltered> = {
  allowCompress: checkFiltered(FeatureTypes.CONTENT_MANIPULATION),
  cacheControlHeaderOverride: checkFiltered(FeatureTypes.CACHE_CONTROL),
  cacheKey: checkFiltered(FeatureTypes.CACHE_CONTROL),
  customLogData: checkFiltered(FeatureTypes.LOGGING),
  defaultCache: checkFiltered(FeatureTypes.CACHE_CONTROL),
  denyPolicy: checkFiltered(FeatureTypes.ACCESS_CONTROL),
  disableIfNoneMatch: checkFiltered(FeatureTypes.CACHE_CONTROL),
  failedRefreshTTL: checkFiltered(FeatureTypes.CACHE_CONTROL),
  followRedirects: checkFiltered(FeatureTypes.REQUEST_MANIPULATION),
  generatedResponse: checkFiltered(FeatureTypes.REQUEST_MANIPULATION),
  geoRestrictions: checkFiltered(FeatureTypes.ACCESS_CONTROL),
  ipRestrictions: checkFiltered(FeatureTypes.ACCESS_CONTROL),
  negativeTTL: checkFiltered(FeatureTypes.CACHE_CONTROL),
  originFillPolicy: checkFiltered(FeatureTypes.ORIGIN_SELECTION),
  qshMode: checkFiltered(FeatureTypes.CACHE_CONTROL),
  reportingOverride: checkFiltered(FeatureTypes.LOGGING),
  reqHeaders: checkFiltered(FeatureTypes.REQUEST_MANIPULATION),
  reqLua: checkFiltered(FeatureTypes.LUA),
  respHeaders: checkFiltered(FeatureTypes.REQUEST_MANIPULATION),
  respLua: checkFiltered(FeatureTypes.LUA),
  serve200For416: checkFiltered(FeatureTypes.REQUEST_MANIPULATION),
  staleContentControl: checkFiltered(FeatureTypes.CACHE_CONTROL),
  tokenAuthentication: checkFiltered(FeatureTypes.ACCESS_CONTROL),
  uriRewrite: checkFiltered(FeatureTypes.REQUEST_MANIPULATION),
};
