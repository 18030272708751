import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../components/atoms/Button/Button";
import { Modal } from "../../../../../../components/atoms/Modal/Modal";
import { TextButton } from "../../../../../../components/atoms/TextButton/TextButton";
import { ExpandableContent } from "../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import {
  IOption,
  SearchDropdown,
} from "../../../../../../components/molecules/SearchDropdown/SearchDropdown";
import { IVersionHistory } from "../../../../../../models/configuration/configuration_details.interfaces";
import { getConfigurationHistory } from "../../../../../../store/slices/caching/services";
import { ConfigurationType } from "../../../../../../store/slices/caching/types";
import { useDispatchAPIError } from "../../../../../../store/slices/api-error/hooks";

export type ConfigurationOpt = IOption<string>;

export const CompareVersionModal = (
  props: Pick<
    ComparisonModalLayoutProps,
    | "configuration"
    | "configurations"
    | "setSelectedConfiguration"
    | "setSelectedVersion"
    | "versions"
  >
): JSX.Element => {
  const { t } = useTranslation("configurationPropertyPage");

  return (
    <Modal
      customButton={
        <TextButton data-testid="version-compare-modal-button">
          {t("COMPARISON_TITLE")}
        </TextButton>
      }
    >
      <ComparisonModalLayout {...props} />
    </Modal>
  );
};

interface ComparisonModalLayoutProps {
  configuration: ConfigurationType;
  configurations: ConfigurationType[];
  versions: IVersionHistory[];
  closeModal?: () => void;
  setSelectedVersion: (sV: IOption<number>) => void;
  setSelectedConfiguration: (sC: ConfigurationType) => void;
}

const ComparisonModalLayout = ({
  versions,
  closeModal,
  configuration,
  configurations,
  ...props
}: ComparisonModalLayoutProps): ReactElement => {
  const [tMisc] = useTranslation("misc");
  const handleMediaPortalError = useDispatchAPIError();

  const [loading, setLoading] = useState(false);
  const [latestVersions, setLatestVersions] = useState(versions);

  const configurationOptions = configurations.reduce(
    (options, _configuration, index) => {
      options.push({
        index,
        text: _configuration.configName,
        value: _configuration.id,
        selected: _configuration.id === configuration.id,
      });

      return options;
    },
    [] as ConfigurationOpt[]
  );

  const [selectedConfiguration, setSelectedConfiguration] = useState(
    configurationOptions.find(
      (configurationOption) => configurationOption.selected
    )
  );
  const [selectedVersion, setSelectedVersion] = useState<IOption<number>>();

  return (
    <>
      <ComparisonModalContent
        disabled={loading}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedConfiguration={selectedConfiguration}
        setSelectedConfiguration={setSelectedConfiguration}
        options={latestVersions.reduce((options, version, index) => {
          if (version.versionId !== configuration.versionId) {
            options.push({
              index,
              text: `${version.versionId}`,
              value: version.versionId,
              selected: false,
            });
          }

          return options;
        }, [] as IOption<number>[])}
        configurationOptions={configurationOptions}
        onSelectedConfiguration={(configurationOption) => {
          setLoading(true);
          const selectedC: ConfigurationType = configurations.find(
            (c) => c.id === configurationOption.value
          )!;

          getConfigurationHistory(selectedC)
            .then(setLatestVersions)
            .catch(handleMediaPortalError)
            .finally(() => {
              setLoading(false);
            });
        }}
      />
      <ModalFooter>
        <Button
          label={tMisc("CANCEL")}
          onClick={() => {
            closeModal && closeModal();
          }}
        />
        <Button
          label={tMisc("COMPARE")}
          onClick={() => {
            closeModal && closeModal();

            props.setSelectedConfiguration(
              configurations.find((c) => c.id === selectedConfiguration!.value)!
            );
            props.setSelectedVersion(selectedVersion!);
          }}
          dataTestId="submit-button-compare-modal"
          disabled={loading || !selectedVersion}
        />
      </ModalFooter>
    </>
  );
};

interface ComparisonModalContentProps {
  configurationOptions: ConfigurationOpt[];
  disabled: boolean;
  options: IOption<number>[];
  selectedConfiguration: ConfigurationOpt | undefined;
  selectedVersion: IOption<number> | undefined;
  onSelectedConfiguration: (param: ConfigurationOpt) => void;
  setSelectedConfiguration: (param: ConfigurationOpt | undefined) => void;
  setSelectedVersion: (param: IOption<number> | undefined) => void;
}

export const ComparisonModalContent = ({
  configurationOptions,
  disabled,
  options,
  selectedConfiguration,
  selectedVersion,
  onSelectedConfiguration,
  setSelectedConfiguration,
  setSelectedVersion,
}: ComparisonModalContentProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");

  const [cOptions, setCOptions] = useState(configurationOptions);
  const [versionOptions, setVersionOptions] = useState(options);

  useEffect(() => {
    setVersionOptions(options);
  }, [options]);

  const shouldRenderVersionDropdown =
    !!versionOptions && versionOptions.length > 0 && !disabled;

  return (
    <CompareListContainer>
      <Title>{t("COMPARISON_TITLE")}</Title>
      <ExpandableContent
        title={t("COMPARISON_INFO_TITLE")}
        content={t("COMPARISON_INFO_DESCRIPTION")}
      />
      <DropdownContainer>
        <SearchDropdown
          placeholder={selectedConfiguration?.text ?? "Select a configuration"}
          options={cOptions}
          toggleSelection={(selected) => {
            setSelectedConfiguration(selected);
            setCOptions((_cOptions) =>
              _cOptions.map((_cOption) => ({
                ..._cOption,
                selected: _cOption.index === selected.index,
              }))
            );
            setVersionOptions([]);
            setSelectedVersion(undefined);
            onSelectedConfiguration(selected);
          }}
          filterfunction={(search, option) =>
            option.text.toLowerCase().startsWith(search.toLocaleLowerCase()) ||
            option.value.toLowerCase().startsWith(search.toLowerCase())
          }
          dataTestId="configuration-selector-dropdown"
          searchPlaceholder={t("CONFIGURATION_SEARCH_PLACEHOLDER")}
          listWidth="auto"
        />
      </DropdownContainer>
      {shouldRenderVersionDropdown && (
        <DropdownContainer>
          <SearchDropdown
            placeholder={selectedVersion?.text ?? "Select a version"}
            options={versionOptions}
            toggleSelection={(selected) => {
              setSelectedVersion(selected);
              setVersionOptions((_versionOptions) =>
                _versionOptions.map((_versionOption) => ({
                  ..._versionOption,
                  selected: _versionOption.index === selected.index,
                }))
              );
            }}
            filterfunction={(search, option) =>
              option.text
                .toLowerCase()
                .startsWith(search.toLocaleLowerCase()) ||
              option.value
                .toString()
                .toLowerCase()
                .startsWith(search.toLowerCase())
            }
            dataTestId="version-selector-dropdown"
            searchPlaceholder={t("VERSION_SEARCH_PLACEHOLDER")}
            listWidth="auto"
          />
        </DropdownContainer>
      )}
    </CompareListContainer>
  );
};

const CompareListContainer = styled.div`
  padding: 32px;
  width: 600px;

  & > div {
    margin-bottom: 32px;
  }
`;

const DropdownContainer = styled.div`
  & > .-m--1 {
    margin: 0 !important;
  }
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: ${({ theme }) => theme.text.primary};
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 32px;

  & .chi-button:not(:last-child),
  & button:not(:last-child) {
    margin-right: 8px;
  }
`;
