import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { Modal } from "../../atoms/Modal/Modal";
import { TrashIcon } from "../../atoms/TrashIcon/TrashIcon";
import { PresentationModal } from "../PresentationModal/PresentationModal";

interface DeleteModalProps {
  title: string;
  deleteItemName: string;
  onDelete: () => void;
  dataTestId?: string;
}

export const DeleteModal = ({
  title,
  deleteItemName,
  dataTestId,
  onDelete,
}: DeleteModalProps): ReactElement => {
  const [tMisc] = useTranslation("misc");

  return (
    <Modal
      customButton={<TrashIcon dataTestId={`${dataTestId}-delete-button`} />}
    >
      <PresentationModal
        title={title}
        onSubmit={onDelete}
        button={{
          label: tMisc("DELETE"),
        }}
        dataTestId={"delete-modal"}
      >
        <CustomRemark>
          {tMisc("DELETE_CONFIRMATION", { itemName: deleteItemName })}
        </CustomRemark>
      </PresentationModal>
    </Modal>
  );
};
