import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, IconProps, Icons } from "../Icon/Icon";

export const ArrowDownIcon = (
  props: Omit<IconProps, "name"> & ArrowDownProps
): ReactElement => <ArrowDown {...props} name={Icons.ARROW_SMALL} />;

interface ArrowDownProps {
  open: boolean;
}

const ArrowDown = styled(Icon)<ArrowDownProps>`
  transition: transform 0.2s;
  transform: rotate(${({ open }) => (open ? "270deg" : "90deg")});
  height: 8px;
  fill: ${({ theme }) => theme.backgrounds.mutedDark};
  cursor: pointer;
`;
