import React, { useCallback, useRef, useState } from "react";
import styled from "@emotion/styled";

import { animated, config, useTransition } from "react-spring";
import { Icon, Icons } from "../Icon/Icon";
import { useClickOutsideRef } from "../../../hooks/use-detect-click-outside-ref";

export interface IPopoverProps {
  position?: PopoverPosition;
  onHover?: boolean;
  className?: string;
  info?: string;
}

export type PopoverPosition =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "bottom-left";

interface LayoutData {
  x: number;
  y: number;
  width: number;
  height: number;
}

const buildTransformFromPosition = (
  iconData: LayoutData,
  position: PopoverPosition,
  info?: string
): string => {
  // handle horizontale auto reposition:
  if (position === "right" && iconData.x > window.innerWidth / 2) {
    position = "left";
  } else if (position === "left" && iconData.x < window.innerWidth / 2) {
    position = "right";
  }
  if (info) {
    const infoWidth = document
      .createElement("canvas")
      .getContext("2d")
      ?.measureText(info).width;
    if (infoWidth && iconData.x + infoWidth > window.innerWidth) {
      position = "bottom-left";
    }
  }

  switch (position) {
    case "top":
      return `translate(${iconData.width / 2}px, 0px) translate(-100%, -50%)`;
    case "bottom":
      return `translate(${iconData.width / 2}px, ${
        iconData.height
      }px) translate(-50%, 0px)`;
    case "bottom-left":
      return `translate(${iconData.width / 2}px, ${
        iconData.height
      }px) translate(-100%, 0px)`;
    case "left":
      return `translate(0px, ${iconData.height / 2}px) translate(-100%, -50%)`;
    default:
      return `translate(${iconData.width}px, ${
        iconData.height / 2
      }px) translate(0px, -50%)`;
  }
};

export const Popover: React.FunctionComponent<IPopoverProps> = ({
  position = "bottom",
  onHover = false,
  className,
  children,
  info,
}) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const [iconData, setIconData] = useState<LayoutData>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const opening = useTransition(showContent, {
    config: config.gentle,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const iconRef = useRef<HTMLDivElement>();
  const iconMeasuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      iconRef.current = node;
      const {
        width,
        height,
        left,
        top,
      } = iconRef.current.getBoundingClientRect();
      setIconData({
        x: left,
        y: top,
        width,
        height,
      });
    }
  }, []);

  const messageRef = useRef<HTMLElement>(null);

  useClickOutsideRef(messageRef, () => {
    setShowContent(false);
  });

  // TODO: handle auto override position

  return (
    <PopoverContainer className={className}>
      <div
        ref={iconMeasuredRef}
        onClick={() => {
          !onHover && setShowContent((current) => !current);
        }}
        onMouseEnter={() => {
          onHover && setShowContent(() => true);
        }}
        onMouseLeave={() => {
          onHover && setShowContent(() => false);
        }}
      >
        <Icon name={Icons.QUESTION} color="highlight" size={20} />
      </div>
      {opening(
        (styles, show) =>
          show && (
            <PopoverContent
              messagePosition={buildTransformFromPosition(
                iconData,
                position,
                info
              )}
              ref={messageRef}
              style={styles}
              position={position}
            >
              {children}
            </PopoverContent>
          )
      )}
    </PopoverContainer>
  );
};

const PopoverContainer = styled.div`
  position: relative;
`;

const PopoverContent = styled(animated.section)<{
  messagePosition: string;
  position: PopoverPosition;
}>`
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: ${({ messagePosition }) => messagePosition};
`;
