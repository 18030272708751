import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { PropertyDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createPropertyDefinition = (
  name: string,
  rest: Pick<
    PropertyDefinitionType,
    | "matchLogic"
    | "trafficType"
    | "primaryAlias"
    | "protocolSettings"
    | "aliases"
    | "aliasOverrides"
    | "logging"
    | "originFillPolicy"
    | "signature"
  >
): PropertyDefinitionType => ({
  ...parseDefinition({
    name,
    type: TypeDefinitions.PROPERTY,
  }),
  matchLogic: rest.matchLogic,
  trafficType: rest.trafficType,
  primaryAlias: rest.primaryAlias,
  protocolSettings: rest.protocolSettings,
  aliases: rest.aliases,
  aliasOverrides: rest.aliasOverrides,
  logging: rest.logging,
  originFillPolicy: rest.originFillPolicy,
  signature: rest.signature,
});
