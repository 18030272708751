import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { Status } from "../../../@types/status";
import { ErrorLevel, CMv3APIError, APIError } from "../../../models/error";
import { getAccessGroups } from "../../../models/user";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import {
  setAccessGroupsQueryStatus,
  setAccessGroups,
  setAccessGroupId,
} from "../access-groups/slice";

const NO_ACCESS_GROUP = "User has no access groups";

export const handleAccessGroups = (
  accessGroupId: number | undefined
): AppThunk => async (dispatch) => {
  let accessGroupToSelect: number | undefined = undefined;

  try {
    dispatch(setAccessGroupsQueryStatus(Status.LOADING));

    const accessGroups = await getAccessGroups();

    if (accessGroupId) {
      accessGroupToSelect = accessGroupId;
    } else if (accessGroups.length === 0) {
      throw new Error(NO_ACCESS_GROUP);
    } else {
      accessGroupToSelect = accessGroups[0].id;
    }

    dispatch(setAccessGroups(accessGroups));
    dispatch(handleSetAccessGroupId(accessGroupToSelect));

    dispatch(setAccessGroupsQueryStatus(Status.SUCCESS));
  } catch (err: any) {
    if (err.message === NO_ACCESS_GROUP) {
      dispatch(
        handleAPIError(
          new APIError(
            "You need access to at least one access group.",
            "handleAccessGroups()",
            ErrorLevel.ERROR
          )
        )
      );
    }

    const error = err as AxiosError<CMv3APIError>;

    dispatch(
      handleAPIError(
        new APIError(
          error.message,
          "handleAccessGroups()",
          ErrorLevel.ERROR,
          error.response
        )
      )
    );

    dispatch(setAccessGroupsQueryStatus(Status.ERROR));
  }

  Sentry.setContext("accessGroupId", {
    accessGroupToSelect,
  });
};

export const handleSetAccessGroupId = (
  accessGroupId: number
): AppThunk => async (dispatch) => {
  dispatch(setAccessGroupId(accessGroupId));
  window.localStorage.setItem("cmv3.access_group_id", accessGroupId.toString());
};
