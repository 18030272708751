import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { ReactComponent as TripleDotSvg } from "../../../assets/icons/triple_dot.svg";
import { ProductionSlot } from "../../../models/configuration";
import { IVersionHistory } from "../../../models/configuration/configuration_details.interfaces";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { useIsViewMode } from "../../../store/slices/permissions/hooks";
import { Badge } from "../../atoms/Badge/Badge";
import { CardContainer } from "../../atoms/CardContainer/CardContainer";
import { VersionActionModal } from "../../molecules/VersionActionModal/VersionActionModal";
import { Dropdown } from "../Dropdown/Dropdown";
import { ViewVersionButton } from "./ViewVersionButton";

interface VersionHistoryCardProps {
  versionHistory: IVersionHistory;
  productionSlot?: ProductionSlot;
}

export enum VersionActions {
  LIVE = "live",
  EDIT = "edit",
}

export const VersionHistoryCard = ({
  versionHistory,
  productionSlot,
}: VersionHistoryCardProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const isViewMode = useIsViewMode();

  const { t } = useTranslation("configurationVersionsPage");
  const [tMisc] = useTranslation("misc");

  const [activeAction, setActiveAction] = useState<VersionActions>();

  const dropdownItems = (productionSlot === undefined
    ? [
        {
          label: t("VERSION_HISTORY_DEPLOY_BUTTON"),
          value: VersionActions.LIVE,
          dataTestId: `version-history-card-deploy-button-${versionHistory.configName}-${versionHistory.versionId}`,
        },
      ]
    : []
  ).concat([
    {
      label: t("VERSION_HISTORY_EDIT_BUTTON"),
      value: VersionActions.EDIT,
      dataTestId: `version-history-card-edit-button-${versionHistory.configName}-${versionHistory.versionId}`,
    },
  ]);

  const resetActiveAction = () => {
    setActiveAction(undefined);
  };

  return (
    <VersionHistoryCardContainer
      data-testid={`version-history-card-${versionHistory.configName}-${versionHistory.versionId}`}
    >
      <Splitter>
        <TopPart>
          <Label>
            {versionHistory.modifiedTime !== undefined &&
              `${format(
                new Date(versionHistory.modifiedTime),
                "PPPppp"
              )} ${tMisc("VERSION", { version: versionHistory.versionId })}`}
          </Label>
          {productionSlot !== undefined && (
            <BadgeContainer>
              <Badge
                label={t("VERSION_HISTORY_PRODUCTION_SLOT", {
                  productionSlot,
                })}
                color={
                  productionSlot === ProductionSlot.CURRENT
                    ? "navy60"
                    : "grey60"
                }
              />
            </BadgeContainer>
          )}
        </TopPart>
        <BotPart>
          {t("VERSION_HISTORY_CARD_NOTES", {
            comment: versionHistory.comment
              ? versionHistory.comment
              : t("VERSION_HISTORY_CARD_NOTES_DEFAULT"),
          })}
        </BotPart>
      </Splitter>
      {isViewMode ? (
        <ViewVersionButton
          activeAction={activeAction}
          resetActiveAction={resetActiveAction}
          versionHistory={versionHistory}
          onClick={() => {
            setActiveAction(VersionActions.EDIT);
          }}
        />
      ) : (
        <>
          {versionHistory.versionId !== selectedConfiguration?.versionId && (
            <Dropdown
              items={dropdownItems}
              id={`version-${versionHistory.versionId}-actions`}
              placeholder=""
              onSelect={(item) => setActiveAction(item.value)}
              customButton={
                <TripleDot
                  data-testid={`version-history-card-actions-${versionHistory.configName}-${versionHistory.versionId}`}
                />
              }
            />
          )}
          <VersionActionModal
            activeAction={activeAction}
            resetActiveAction={resetActiveAction}
            versionHistory={versionHistory}
          />
        </>
      )}
    </VersionHistoryCardContainer>
  );
};

const VersionHistoryCardContainer = styled(CardContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 15px;

  cursor: default;
`;

const Splitter = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopPart = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const Label = styled.div`
  font-weight: 600;
`;

const BadgeContainer = styled.div`
  padding-left: 16px;
`;

const BotPart = styled.div``;

const TripleDot = styled(TripleDotSvg)`
  margin-right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.icon.primary};
`;
