import { IFeatures } from "../../../models/configuration/definitions";
import { HeadersBase } from "./HeadersBase";
import { FeatureComponent } from "./types";

type RespHeadersComponent = FeatureComponent<
  Exclude<IFeatures["respHeaders"], undefined>
>;

export const RespHeaders: RespHeadersComponent = ({
  feature,
  tPropertyPage,
}) => (
  <HeadersBase
    dataTestId="respHeaders"
    title={tPropertyPage("FEATURE_CARD_HEADERS_TITLE")}
    {...feature}
  />
);
