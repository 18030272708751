import styled from "@emotion/styled";

export const SectionContainer = styled.div`
  margin-bottom: 16px;
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
