import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import {
  Button,
  ButtonProps,
} from "../../../../../../components/atoms/Button/Button";
import { Icon, Icons } from "../../../../../../components/atoms/Icon/Icon";

interface IUnsavedChangesToastProps {
  buttons: ButtonProps[];
  description: string;
  title?: string;
}

export const UnsavedChangesToast: React.FC<IUnsavedChangesToastProps> = ({
  buttons,
  title,
  description,
}) => {
  const { t } = useTranslation("configurationPropertyPage");
  return (
    <WarningContainer>
      <WarningIconContainer>
        <Icon name={Icons.WARNING} color="yellow40" />
      </WarningIconContainer>
      <div>
        <WarningTitle>
          {title || t("WARNING_UNSAVED_CHANGES_TITLE")}
        </WarningTitle>
        <WarningDescription>{description}</WarningDescription>
        <ButtonsContainer>
          {buttons.map((button, i) => (
            <Button {...button} key={i} />
          ))}
        </ButtonsContainer>
      </div>
    </WarningContainer>
  );
};

const WarningContainer = styled.div`
  display: flex;
`;

const ButtonsContainer = styled.div`
  display: flex;
  *:not(:last-child) {
    margin-right: 8px;
  }
`;

const WarningTitle = styled.div`
  color: ${({ theme }) => theme.text.warning};
  margin-bottom: 8px;
  font-weight: 600;
`;

const WarningIconContainer = styled.div`
  margin-right: 8px;
`;

const WarningDescription = styled.div`
  margin-bottom: 16px;
`;
