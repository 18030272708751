import { ReactElement } from "react";
import { Route } from "react-router-dom";
import { AccessGroups } from ".";
import { AccessGroupPage } from "./accessGroup";

export const AccessGroupsRouter = (): ReactElement => {
  return (
    <>
      <Route exact path="/userManagement/accessGroups">
        <AccessGroups />
      </Route>
      <Route path="/userManagement/accessGroups/:accessGroupId">
        <AccessGroupPage />
      </Route>
    </>
  );
};
