import { useTranslation } from "react-i18next";

import { Modal } from "../../../../../../components/atoms/Modal/Modal";
import { CustomRemark } from "../../../../../../components/atoms/CustomRemark/CustomRemark";
import { PresentationModal } from "../../../../../../components/molecules/PresentationModal/PresentationModal";

interface ExpiredBannersModalProps {
  handleExpiredBanners: (
    bannerId: string,
    notificationId: number
  ) => Promise<void>;
  bannerId: string;
  bannerName: string;
  notificationId: number;
  dataTestId?: string;
}

export const ExpiredBannersModal: React.FC<ExpiredBannersModalProps> = ({
  handleExpiredBanners,
  bannerId,
  bannerName,
  notificationId,
  dataTestId,
}) => {
  const { t } = useTranslation("bannersModalPage");

  const handleExpired = () => {
    handleExpiredBanners(bannerId, notificationId);
  };
  return (
    <Modal
      openButtonProps={{
        backgroundColor: "baseLight",
        textColor: "highlight",
        borderColor: "highlight",
        label: t("BANNERS_EXPIRED_NOW"),
      }}
      size="large"
    >
      <PresentationModal
        title={t("BANNER_MODAL_TITLE")}
        onSubmit={handleExpired}
        button={{
          label: t("BANNER_MODAL_SUBMIT_BUTTON_LABEL"),
          backgroundColor: "red60",
          textColor: "primaryAlt",
        }}
        dataTestId={dataTestId}
      >
        <CustomRemark>
          {t("EXPIRED_BANNERS", { itemName: bannerName })}
        </CustomRemark>
      </PresentationModal>
    </Modal>
  );
};
