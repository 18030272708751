import type { ReactElement } from "react";
import type { FieldValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { useSubscriberSettingsSystemLimits } from "../../../../../../../../../store/slices/subscriber-settings/hooks";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { useInitOpen } from "./useInitOpen";

interface IDefaultCacheFeatureBlockProps {
  datatestId: string;
  errors: ValidationErrors;
  initIsOpen: boolean;
  maxWidth: number;
  onDelete: () => void;
  register: UseFormRegister<FieldValues>;
}

export const DefaultCacheFeatureBlock = ({
  datatestId,
  errors,
  initIsOpen,
  maxWidth,
  onDelete,
  register,
}: IDefaultCacheFeatureBlockProps): ReactElement => {
  const isOpen = useInitOpen(initIsOpen);
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();
  const systemLimits = useSubscriberSettingsSystemLimits();
  const defaultCacheLimits = systemLimits.defaultCache;

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_DEFAULT_CACHE_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_DEFAULT_CACHE_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_DEFAULT_CACHE_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_DEFAULT_CACHE_TIME_VALUE_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_DEFAULT_CACHE_TIME_VALUE_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_DEFAULT_CACHE_TIME_VALUE_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          fieldProps={{
            other: (
              <HeadlessInputField
                disabled={isViewMode}
                placeholder={t(
                  "FEATURE_CARD_DEFAULT_CACHE_TIME_VALUE_PLACEHOLDER"
                )}
                {...register("defaultCache.cachePolicy", {
                  required: tMisc("ERROR_REQUIRED_FIELD"),
                  min: {
                    value: defaultCacheLimits.min,
                    message: `Must be min ${defaultCacheLimits.min}`,
                  },
                  max: {
                    value: defaultCacheLimits.max,
                    message: `Must be max ${defaultCacheLimits.max}`,
                  },
                  validate: (value) => value >= 0 || "Must be positive",
                  valueAsNumber: true,
                })}
                data-testid="default-cache-policy-input"
                type="number"
              />
            ),
          }}
          errorMessage={errors.defaultCache?.cachePolicy?.message}
          maxWidth={maxWidth}
          dataTestId="default-cache-input"
        />
      }
      initIsOpen={isOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName="defaultCache"
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="default-cache"
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};
