import * as Sentry from "@sentry/react";

export enum ErrorLevel {
  /* error caused by frotend error, http error causing a disruption in the user experience */
  ERROR = "error",
  /* error causing the crash or reload of the application, critical features of the app */
  FATAL = "fatal",
  /* debug or info */
  INFO = "info",
  /* error caused by wrong user behavior, wrong user input */
  WARNING = "warning",
}

const shouldSendError = (error: APIError): boolean => {
  if (
    error.message.includes("Network Error") ||
    error.message.includes("Request aborted")
  ) {
    return false;
  }
  // In case of http error, data contains status field (int) with response status code.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (error.data?.status && error.data.status >= 500) {
    return false;
  }
  return true;
};

export class APIError<T = unknown> extends Error {
  public errorLevel: ErrorLevel;
  public data?: T;
  public location: string;
  public name: string;

  constructor(
    message: string,
    location: string,
    errorLevel: ErrorLevel = ErrorLevel.WARNING,
    data?: T,
    sendToSentry = true
  ) {
    super(message);
    this.name = location;
    this.errorLevel = errorLevel;
    this.location = location;
    this.data = data;

    if (sendToSentry && shouldSendError(this)) {
      Sentry.captureException(this, (scope) => {
        const { data: extraData, ...level1 } = this;
        scope.setContext("Error infos", { data: level1 });
        if (errorLevel === ErrorLevel.FATAL) {
          scope.setLevel(Sentry.Severity.Fatal);
          scope.setTag("priority", "P1");
          scope.setTag("handled", "no");
        } else {
          scope.setTag("priority", "P2");
        }
        if (extraData) {
          scope.setExtra("Data", { extraData });
        }
        return scope;
      });
    }
  }
}

export type { CMv3APIError, MediaPortalAPIError } from "./APIError";
