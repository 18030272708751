import { FC } from "react";

import { CustomRemark } from "../CustomRemark/CustomRemark";
import {
  EmptyPageWrapper,
  EmptyPageContainer,
  EmptyPageBanner,
  EmptyPageTitle,
  EmptyPageDescription,
  EmptyPageButtonsContainer,
} from "./EmptyPageWrapper";
import { ImageVariants, useImage } from "./useImage";

export interface EmptyPageProps {
  content: string;
  images: ImageVariants;
  title: string;
  dataTestId?: string;
}
export const EmptyPage: FC<EmptyPageProps> = ({
  children,
  content,
  images,
  title,
  dataTestId,
}) => (
  <EmptyPageWrapper data-testid={dataTestId}>
    <EmptyPageContainer>
      <EmptyPageBanner src={useImage(images)} alt="empty page" />
      <EmptyPageTitle>{title}</EmptyPageTitle>
      <EmptyPageDescription>
        <CustomRemark>{content}</CustomRemark>
      </EmptyPageDescription>
      <EmptyPageButtonsContainer>{children}</EmptyPageButtonsContainer>
    </EmptyPageContainer>
  </EmptyPageWrapper>
);
