import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../../components/atoms/Button/Button";
import { EmptyPageDescription } from "../../../../../components/atoms/EmptyPage/EmptyPageWrapper";

import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";
import { CdnApiKeyLayer } from "../../../../../components/layers/ApiKeys/ApiKeys";
import { ErrorLayer } from "../../../../../components/molecules/ErrorLayer/ErrorLayer";
import {
  useCdnApiKey,
  useCdnApiKeysOwners,
  useCdnApiKeysRoles,
} from "../../../../../store/slices/user-management-api-keys/hooks";
import { ApiKeyPageContent } from "./ApiKeyPageContent";

export const CdnApiKeyPage = (): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const history = useHistory();
  const apiKeyInfo = useCdnApiKey();
  const roles = useCdnApiKeysRoles();
  const owners = useCdnApiKeysOwners();

  return (
    <CdnApiKeyLayer>
      <Container>
        {!!apiKeyInfo && !!roles && !!owners ? (
          <ApiKeyPageContent
            apiKeyInfo={apiKeyInfo}
            isVyvx={false}
            roles={roles}
            owners={owners}
          />
        ) : (
          <ErrorLayer
            title={t("API_KEY_PAGE_ERROR_TITLE")}
            content={
              <EmptyPageDescription>
                <div>{t("API_KEY_NOT_FOUND")}</div>
                <Button
                  onClick={() => {
                    history.push("/userManagement/apiKeys/vyvx");
                  }}
                  label={t("API_KEY_PAGE_ERROR_RETURN_BUTTON")}
                />
              </EmptyPageDescription>
            }
          />
        )}
      </Container>
    </CdnApiKeyLayer>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
`;
