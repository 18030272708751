import { FC } from "react";
import styled from "@emotion/styled";

import { Button } from "../../../../../../../components/atoms/Button/Button";
import { Protected } from "../../../../../../../components/atoms/Protected/Protected";
import { CodeEditor } from "../../../../../../../components/organisms/CodeEditor/CodeEditor";
import { UserRoles } from "../../../../../../../models/permissions";
import { useMatchRulesPage } from "../useMatchRulesPage";
import { useIsViewMode } from "../../../../../../../store/slices/permissions/hooks";

interface IMatchRuleEditorProps {
  code: string;
  dataTestId?: string;
  setCode: (newCode: string) => void;
  save: () => void;
}

export const MatchRuleEditor: FC<IMatchRuleEditorProps> = ({
  code,
  dataTestId,
  setCode,
  save,
}) => {
  const [, matchRule] = useMatchRulesPage();

  const cancel = () => {
    setCode(matchRule.expression || "");
  };

  const isViewMode = useIsViewMode();

  return (
    <CodeEditorContainer data-testid={dataTestId}>
      <CodeEditor
        readOnly={isViewMode}
        id="json-editor"
        code={code}
        onChange={setCode}
      />
      <Protected permissions={UserRoles.EDIT_CONFIG}>
        <Buttons>
          <Button label="Reset" onClick={cancel} />
          <Button
            label="Save"
            onClick={save}
            dataTestId="code-editor-save-button"
          />
        </Buttons>
      </Protected>
    </CodeEditorContainer>
  );
};

const CodeEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 100%;
`;

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
`;
