import { SelectionStatus } from "../../../../../components/atoms/Checkbox/CustomCheckBoxInput";
import { UserAccessGroup } from "../../../../../models/user-management/users/users.interfaces";
import { AccessGroup } from "../../../../../models/user/user.interfaces";
import { VyvxAssociatedCompany } from "../../../../../models/vyvx/vyvx.interfaces";
import { filterAccessGroups } from "../../accessGroups/helpers";
import { DisplayedAssociatedCompany } from "./modals/EditVyvxAssociatedCompaniesModal";

export type UserAccessGroupWithChildren = UserAccessGroup & {
  list?: UserAccessGroupWithChildren[];
};

export const buildAccessGroupsWithChildren = (
  userAccessGroups: UserAccessGroup[],
  allAccessGroupsWithChildren: AccessGroup[],
  searchValue: string,
  selectedRole: string
): UserAccessGroupWithChildren[] => {
  const newAccessGroups: UserAccessGroupWithChildren[] = [];

  const newAllAccessGroups = filterAccessGroups(
    searchValue,
    undefined
  )(allAccessGroupsWithChildren);

  newAllAccessGroups.map((group) => {
    const newGroup = buildNewGroup(
      group,
      userAccessGroups.filter(
        (gr) => selectedRole === "" || gr.roleName === selectedRole
      )
    );
    if (newGroup !== undefined) {
      newAccessGroups.push(...newGroup);
    }
  });

  return newAccessGroups;
};

const buildNewGroup = (
  group: AccessGroup,
  accessGroups: UserAccessGroup[],
  parentRole?: string
): UserAccessGroupWithChildren[] | undefined => {
  const g = accessGroups.find((gr) => gr.id === group.id);
  if (g) {
    const childGroups = group.list
      ?.map((child) => buildNewGroup(child, accessGroups, g.roleName) || [])
      .flat();
    return [{ ...g, list: childGroups }];
  }

  const childGroups = group.list
    ?.map((child) => buildNewGroup(child, accessGroups, parentRole) || [])
    .flat();

  if (parentRole) {
    return [
      {
        id: group.id,
        name: group.name,
        roleName: parentRole,
        list: childGroups,
      },
    ];
  }

  return childGroups;
};

export const handleFilterAccessGroupsToAdd = (
  search: string,
  showSelectedOnly: boolean,
  selectedRows: string[]
) => (accessGroups: AccessGroup[]): AccessGroup[] => {
  const newAccessGroups = filterAccessGroups(search, undefined)(accessGroups);
  if (showSelectedOnly) {
    return newAccessGroups
      .map((group) => showSelected(group, selectedRows))
      .filter((group) => group !== undefined) as AccessGroup[];
  }

  return newAccessGroups;
};

const showSelected = (
  group: AccessGroup,
  selectedRows: string[]
): AccessGroup | undefined => {
  const newList = group.list
    ?.map((g) => showSelected(g, selectedRows))
    .filter((g) => g !== undefined) as AccessGroup[] | undefined;

  if (newList && newList.length > 0) {
    return { ...group, list: newList };
  }

  if (selectedRows.includes(group.name)) {
    return { ...group, list: undefined };
  }

  return undefined;
};

export interface MinimalAccessGroup {
  name: string;
  list?: MinimalAccessGroup[];
}

export const generateAccessGroupSelectionState = (
  accessGroup: MinimalAccessGroup,
  selectedRows: string[]
): SelectionStatus => {
  if (selectedRows.includes(accessGroup.name)) {
    return SelectionStatus.SELECTED;
  }
  if (
    accessGroup.list?.some((group) =>
      [SelectionStatus.SELECTED, SelectionStatus.PARTIAL].includes(
        generateAccessGroupSelectionState(group, selectedRows)
      )
    )
  ) {
    return SelectionStatus.PARTIAL;
  }

  return SelectionStatus.NOT_SELECTED;
};

export const buildAccessGroupsUpdated = (
  originalAccessGroups: UserAccessGroup[],
  allAccessGroups: AccessGroup[],
  newSelectedAccessGroups: string[]
): { accessGroupsToAdd: number[]; accessGroupsToDelete: number[] } => {
  const accessGroupsNamesToAdd: string[] = newSelectedAccessGroups.filter(
    (groupName) =>
      !originalAccessGroups.some((group) => group.name === groupName)
  );

  const accessGroupsToAdd: number[] = [];
  accessGroupsNamesToAdd.forEach((name) => {
    const groupId = findAccessGroupIdByName(allAccessGroups, name);
    if (groupId) {
      accessGroupsToAdd.push(groupId);
    }
  });

  const accessGroupsToDelete = originalAccessGroups
    .filter((group) => !newSelectedAccessGroups.includes(group.name))
    .map((group) => group.id);

  return {
    accessGroupsToDelete,
    accessGroupsToAdd,
  };
};

const findAccessGroupIdByName = (
  groups: AccessGroup[],
  name: string
): number | undefined => {
  for (const group of groups) {
    if (group.name === name) {
      return group.id;
    }
    if (group.list) {
      const res = findAccessGroupIdByName(group.list, name);
      if (res) return res;
    }
  }
  return undefined;
};

export const handleToggleAssociatedCompany = (
  row: DisplayedAssociatedCompany,
  status: SelectionStatus
) => (prev: string[]): string[] => {
  if (row.children.length === 0) {
    if (prev.includes(row.label)) {
      return prev.filter((item) => item !== row.label);
    } else {
      return prev.concat(row.label);
    }
  }

  const filtered = prev.filter((item) => !row.children.includes(item));
  if (status === SelectionStatus.SELECTED) {
    return filtered;
  } else {
    return filtered.concat(row.children);
  }
};

interface GroupData {
  id: string;
  companies: {
    name: string;
    id: string;
  }[];
}

export const buildDisplayedAssociatedCompanies = (
  companies: VyvxAssociatedCompany[]
): DisplayedAssociatedCompany[] => {
  const companiesPerGroup = new Map<string, GroupData>();
  for (const company of companies) {
    if (!companiesPerGroup.has(company.customerGroupName)) {
      companiesPerGroup.set(company.customerGroupName, {
        id: company.customerGroupId,
        companies: [{ name: company.companyName, id: company.companyAbbr }],
      });
    } else {
      companiesPerGroup.get(company.customerGroupName)?.companies.push({
        name: company.companyName,
        id: company.companyAbbr,
      });
    }
  }

  const result: DisplayedAssociatedCompany[] = [];
  for (const group of companiesPerGroup.keys()) {
    const value = companiesPerGroup.get(group)!;
    result.push({
      label: group,
      id: value.id,
      children: value.companies.map((company) => company.name),
    });
    value.companies.forEach((company) => {
      result.push({
        label: company.name,
        id: company.id,
        children: [],
      });
    });
  }

  return result;
};

export const generateAssociatedCompaniesSelectionState = (
  row: DisplayedAssociatedCompany,
  selectedRows: string[]
): SelectionStatus => {
  if (row.children.length === 0) {
    return selectedRows.includes(row.label)
      ? SelectionStatus.SELECTED
      : SelectionStatus.NOT_SELECTED;
  }

  const selected = row.children.filter((child) => selectedRows.includes(child));
  if (selected.length === row.children.length) {
    return SelectionStatus.SELECTED;
  } else if (selected.length > 0) {
    return SelectionStatus.PARTIAL;
  }
  return SelectionStatus.NOT_SELECTED;
};

export const handleFilterAssociatedCompanies = (
  search: string,
  showSelectedOnly: boolean,
  selectedRows: string[]
) => (
  companies: DisplayedAssociatedCompany[]
): DisplayedAssociatedCompany[] => {
  const filtered = companies.filter(
    (company) =>
      search === "" ||
      company.label.toLowerCase().includes(search.toLowerCase()) ||
      company.children.some((item) =>
        item.toLowerCase().includes(search.toLowerCase())
      )
  );

  if (showSelectedOnly) {
    return filtered.filter(
      (company) =>
        selectedRows.includes(company.label) ||
        company.children.some((child) => selectedRows.includes(child))
    );
  }
  return filtered;
};
