import styled from "@emotion/styled";

interface TextGroupProps {
  title: string;
  subtitle: string;
  dataTestId?: string;
}

export const TextGroup: React.FC<TextGroupProps> = ({
  title,
  subtitle,
  dataTestId,
}) => {
  return (
    <TextGroupContainer>
      <Title>{title}</Title>
      <SubTitle data-testid={`${dataTestId}-subtitle`}>{subtitle}</SubTitle>
    </TextGroupContainer>
  );
};

const TextGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  color: ${({ theme }) => theme.text.secondary};
  font-weight: 400;
  line-height: 2em;
`;

const SubTitle = styled.span`
  font-weight: 700;
`;
