import { ColorSet } from "../../../../../contexts/themeContext";
import {
  Metrics,
  Point,
  SiteByMetro,
  TableSiteWithPercentage,
  ValueWithPercentage,
} from "../../../models/models";

export type TableMetrics = TableSiteWithPercentage;

export enum Radios {
  CHART,
  MAP,
}

export type TableRegion = Pick<SiteByMetro, "region"> &
  TableSiteWithPercentage & {
    metroList: {
      metro: string;
      [Metrics.reqPerSec]: ValueWithPercentage;
      [Metrics.mbPerSec]: ValueWithPercentage;
      [Metrics.missMbPerSec]: ValueWithPercentage;
      [Metrics.missPerSec]: ValueWithPercentage;
      [Metrics.fourZeroFourPerSec]: ValueWithPercentage;
      [Metrics.fiveZeroThreePerSec]: ValueWithPercentage;
      [Metrics.fiveZeroFourPerSec]: ValueWithPercentage;
      [Metrics.fiveXXPerSec]: ValueWithPercentage;
      [Metrics.hitRatePercent]: ValueWithPercentage;
    }[];
  };

export interface RegionColor {
  color: keyof ColorSet["colors"];
  backgroundColor: keyof ColorSet["colors"];
}

export interface MetroHistory {
  metro: string;
  region: string;
  points: Point[];
}
