import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook } from "react-redux";

import apiError from "./slices/api-error/slice";
import auth from "./slices/auth/slice";
import subscriber from "./slices/subscriber/slice";
import caching from "./slices/caching/slice";
import user from "./slices/user/slice";
import header from "./slices/header/slice";
import permissions from "./slices/permissions/slice";
import subscriberSettings from "./slices/subscriber-settings/slice";
import featureFlags from "./slices/feature-flags/slice";
import rtm from "./slices/rtm/slice";
import accessGroups from "./slices/access-groups/slice";
import userManagementAccessGroup from "./slices/user-management-access-group/slice";
import userManagementUsers from "./slices/user-management-users/slice";
import userManagementApiKeys from "./slices/user-management-api-keys/slice";
import vyvx from "./slices/vyvx/slice";

export const store = configureStore({
  reducer: {
    apiError,
    auth,
    subscriber,
    caching,
    user,
    permissions,
    header,
    subscriberSettings,
    featureFlags,
    rtm,
    accessGroups,
    userManagementAccessGroup,
    userManagementUsers,
    userManagementApiKeys,
    vyvx,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  undefined,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = TypedUseSelectorHook<RootState>;
