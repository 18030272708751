import type { ReactElement } from "react";
import type { UseFormSetValue } from "react-hook-form";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import {
  CachePolicy,
  CCHOInt,
  IFeatures,
} from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { isString } from "../../../../../../../../../../utils/string";
import { VALID_STRINGS } from "./constants";
import { IntNonTTL } from "./IntNonTTL";
import { IntTTL } from "./IntTTL";
import type { FormProps } from "./types";

interface InternalPolicyTypeProps extends FormProps {
  cachePolicy: CCHOInt["cachePolicy"];
  numberErrorMessage?: string;
  handleCreateFeature: UseFormSetValue<IFeatures>;
}

export const InternalPolicyType = ({
  cachePolicy,
  disabled,
  handleCreateFeature,
  maxWidth,
  numberErrorMessage,
  register,
  t,
  tMisc,
  unregister,
}: InternalPolicyTypeProps): ReactElement => {
  const nonTTLChecked = isString(cachePolicy, VALID_STRINGS);

  return (
    <>
      <PropertyDefinitionCard
        title={t("FEATURE_CARD_CCHO_ICP_TYPE_TITLE")}
        asterisk
        additionalInfoTitle={t("FEATURE_CARD_CCHO_ICP_TYPE_DESCRIPTION_TITLE")}
        additionalInfoContent={t(
          "FEATURE_CARD_CCHO_ICP_TYPE_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.OtherType}
        fieldProps={{
          other: (
            <RadioButtonsSelector
              inline
              headlessRadio={true}
              radioButtonsProps={[
                {
                  disabled,
                  label: t("FEATURE_CARD_CCHO_CP_TYPE_RADIO_NUMBER_LABEL"),
                  id: "ccho-int-number",
                  dataTestId: "ccho-icp-ttl-radio",
                  checked: !nonTTLChecked,
                  onChange: () => {
                    handleCreateFeature(
                      "cacheControlHeaderOverride.int.cachePolicy",
                      ""
                    );
                  },
                },
                {
                  disabled,
                  label: t("FEATURE_CARD_CCHO_CP_TYPE_RADIO_STRING_LABEL"),
                  id: "ccho-int-string",
                  dataTestId: "ccho-icp-nonttl-radio",
                  checked: nonTTLChecked,
                  onChange: () => {
                    // "cachePolicy" registered as a number input field. So we
                    // need to unregister it before setting a string value.
                    unregister("cacheControlHeaderOverride.int.cachePolicy");
                    handleCreateFeature(
                      "cacheControlHeaderOverride.int.cachePolicy",
                      CachePolicy.NO_CACHE
                    );
                  },
                },
              ]}
            />
          ),
        }}
        divider
        dataTestId="ccho-icp-type"
      />

      {nonTTLChecked ? (
        <IntNonTTL register={register} disabled={disabled} t={t} />
      ) : (
        <IntTTL
          disabled={disabled}
          errorMessage={numberErrorMessage}
          maxWidth={maxWidth}
          register={register}
          t={t}
          tMisc={tMisc}
        />
      )}
    </>
  );
};
