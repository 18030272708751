import styled from "@emotion/styled";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "../../../../components/atoms/Button/Button";
import { Icon, Icons } from "../../../../components/atoms/Icon/Icon";
import { InputField } from "../../../../components/molecules/InputField/InputField";
import {
  IModalChildren,
  Modal,
} from "../../../../components/atoms/Modal/Modal";
import {
  useConfigurations,
  useSelectedConfiguration,
} from "../../../../store/slices/caching/hooks";
import {
  handleCreateNewConfiguration,
  handleDupplicateConfiguration,
  handleSetSelectedConfigurationByName,
} from "../../../../store/slices/caching/thunks";
import { useAppDispatch } from "../../../../store/types";

export const ConfigurationNameModal: React.FC<{ isRenaming?: boolean }> = ({
  isRenaming = false,
}) => {
  const { t } = useTranslation("configurationsPage");

  return (
    <Modal
      openButtonProps={{
        label: t("ADD_CONFIGURATION_BUTTON"),
        dataTestId: "configuration-list-add-button",
      }}
      size="large"
    >
      <ConfigurationNameModalContent isRenaming={isRenaming} />
    </Modal>
  );
};

interface INewConfigurationModalChildProps extends IModalChildren {
  isRenaming: boolean;
}

export const ConfigurationNameModalContent: React.FC<INewConfigurationModalChildProps> = ({
  closeModal,
  isRenaming,
}) => {
  const history = useHistory();
  const { t } = useTranslation("configurationsPage");
  const dispatch = useAppDispatch();

  const [configurationName, setConfigurationName] = useState<string>("");
  const [error, setError] = useState("");

  const selectedConfiguration = useSelectedConfiguration();
  const configurations = useConfigurations();

  const handleSaveNewConfig = useCallback(() => {
    if (isRenaming) {
      if (selectedConfiguration) {
        dispatch(
          handleDupplicateConfiguration(
            selectedConfiguration,
            configurationName
          )
        );
      }
    } else {
      dispatch(handleCreateNewConfiguration(configurationName));
    }
    dispatch(handleSetSelectedConfigurationByName(configurationName));
    closeModal && closeModal();

    history.push(`/cmv3/configurations/${configurationName}/properties`);
  }, [configurationName, isRenaming]);

  const handlePressEnter: React.KeyboardEventHandler<HTMLDivElement> = (
    event
  ) => {
    if (event.key === "Enter" && !error && configurationName) {
      handleSaveNewConfig();
    }
  };

  return (
    <NewPropertyModalContainer onKeyPress={handlePressEnter}>
      <Title>
        {isRenaming
          ? t("RENAME_CONFIGURATION_MODAL_TITLE")
          : t("ADD_CONFIGURATION_MODAL_TITLE")}
      </Title>
      <Description>{t("CONFIGURATION_FORM_DESCRIPTION")}</Description>
      <InputContainer>
        <InputField
          placeholder={t("ADD_CONFIGURATION_FORM_INPUT_PLACEHOLDER")}
          value={configurationName}
          hasBorder
          onChange={(name) => {
            if (configurations?.some((config) => config.configName === name)) {
              setError(t("CONFIGURATION_FORM_DUPLICATE_ERROR"));
            } else if (name.includes(" ")) {
              setError(t("CONFIGURATION_FORM_SPACE_ERROR"));
            } else {
              if (error.length > 0) {
                setError("");
              }
            }
            setConfigurationName(name);
          }}
          dataTestId="add-configuration-modal-input"
        />
        {error && (
          <Error data-testid="add-configuration-modal-error">{error}</Error>
        )}
      </InputContainer>
      {isRenaming && (
        <InfoCard>
          <InfoIcon name={Icons.INFO} size={32} />
          {t("CONFIGURATION_FORM_INFO_TEXT")}
        </InfoCard>
      )}
      <Buttons>
        <Button
          backgroundColor="mutedDark"
          textColor="primaryAlt"
          label={t("CONFIGURATION_FORM_CANCEL_BUTTON")}
          onClick={() => closeModal && closeModal()}
        />
        <ButtonContainer>
          <Button
            label={t("CONFIGURATION_FORM_CONFIRM_BUTTON")}
            disabled={!configurationName.length || error.length > 0}
            onClick={handleSaveNewConfig}
            dataTestId="add-configuration-modal-submit-button"
          />
        </ButtonContainer>
      </Buttons>
    </NewPropertyModalContainer>
  );
};

const NewPropertyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 32px;
`;

const Title = styled.h3`
  margin-bottom: 50px !important;
  text-align: center;
`;

const Description = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const InputContainer = styled.div`
  margin-bottom: 50px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const Error = styled.div`
  margin-top: 0.5em;
  font-size: 14px;
  color: ${({ theme }) => theme.text.error};
`;

const InfoIcon = styled(Icon)`
  margin-right: 16px;
`;

const InfoCard = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-top: 4px solid ${({ theme }) => theme.borders.info};
  display: flex;
  padding: 24px;
  margin-bottom: 50px;
`;
