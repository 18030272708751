import { ErrorLevel } from "../../../../../../../models/error";
import { Fields } from "./Condition";
import {
  ErrorNames,
  LocalizedMediaPortalError,
} from "./LocalizedMediaPortalError";
import { OperatorsDictionnary } from "./dictionnaries";
import { NEGATED_OPERATORS, NON_NEGATED_OPERATORS } from "./operators";
import { OperatorParserData } from "./types";

const setOperators = (
  _operatorsDictionnary: OperatorsDictionnary,
  expression: string,
  data: OperatorParserData
) => {
  for (const operatorToFind of _operatorsDictionnary) {
    // Check for the englishOperatorInsensitive property
    if (operatorToFind[1].englishOperatorInsensitive) {
      const engResult = expression.split(
        operatorToFind[1].englishOperatorInsensitive
      );
      if (engResult.length === 2) {
        data.leftOfOperator = engResult[0];
        data.rightOfOperator = engResult[1];
        data.operator = operatorToFind[0];
        data.englishOperator = true;
        data.sensitive = false;
        break;
      }
    }

    // Check for the englishOperatorSensitive property
    if (operatorToFind[1].englishOperatorSensitive) {
      const engResult = expression.split(
        operatorToFind[1].englishOperatorSensitive
      );
      if (engResult.length === 2) {
        data.leftOfOperator = engResult[0];
        data.rightOfOperator = engResult[1];
        data.operator = operatorToFind[0];
        data.englishOperator = true;
        break;
      }
    }

    const insensitiveResult = expression.split(operatorToFind[1].insensitive);
    if (insensitiveResult.length === 2) {
      data.sensitive = false;
      data.leftOfOperator = insensitiveResult[0];
      data.rightOfOperator = insensitiveResult[1];
      data.operator = operatorToFind[0];
      break;
    }

    const sensitiveResult = expression.split(operatorToFind[1].sensitive);
    if (sensitiveResult.length === 2) {
      data.leftOfOperator = sensitiveResult[0];
      data.rightOfOperator = sensitiveResult[1];
      data.operator = operatorToFind[0];
      break;
    }
  }
};

export const operatorParser = (
  expression: string,
  data: OperatorParserData
): void => {
  // When checking for operators, we prioritize negated operators because
  // their string representation is a superset of their non-negated
  // counterparts (e.g., "!globmatch" includes "globmatch"). If we don't
  // prioritize negated operators, the loop might find a non-negated operator
  // first and stop searching for other operators, causing it to miss the
  // actual negated operator that should have been found.
  //
  // By prioritizing negated operators, we ensure that they are detected
  // correctly in the input expression before checking for non-negated
  // operators.
  setOperators(NEGATED_OPERATORS, expression, data);

  if (!data.operator) {
    setOperators(NON_NEGATED_OPERATORS, expression, data);
  }

  if (!data.operator) {
    data.errors.push(
      new LocalizedMediaPortalError(
        "ERROR_MATCH_RULE_CARD_EXPECTED_VALUE_MISSING_OR_NOT_SUPPORTED",
        ErrorNames.EXPRESSION_PARSING_ERROR,
        ErrorLevel.WARNING,
        { expression, missingField: Fields.OPERATOR }
      )
    );
  }
};
