import { useAppSelector } from "../../types";
import { UserManagementAccessGroupSlice } from "./types";

export const useAccessGroupInfos = (): UserManagementAccessGroupSlice["infos"] =>
  useAppSelector((store) => store.userManagementAccessGroup.infos);

export const useAccessGroupProducts = (): UserManagementAccessGroupSlice["products"] =>
  useAppSelector((store) => store.userManagementAccessGroup.products);

export const useAccessGroupAvailableProducts = (): UserManagementAccessGroupSlice["availableProducts"] =>
  useAppSelector((store) => store.userManagementAccessGroup.availableProducts);

export const useAccessGroupMembers = (): UserManagementAccessGroupSlice["members"] =>
  useAppSelector((store) => store.userManagementAccessGroup.members);

export const useAccessGroupStatus = (): UserManagementAccessGroupSlice["status"] =>
  useAppSelector((store) => store.userManagementAccessGroup.status);
