import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../components/molecules/DeleteModal/DeleteModal";

interface DeleteBannersModalProps {
  bannersName: string;
  onDelete: () => void;
  dataTestId?: string;
}

export const DeleteBannersModal = (
  deleteBannersModal: DeleteBannersModalProps
) => {
  const { t } = useTranslation("bannersModalPage");

  return (
    <DeleteModal
      title={t("TITLE_DELETE_MODAL")}
      deleteItemName={deleteBannersModal.bannersName}
      onDelete={deleteBannersModal.onDelete}
      dataTestId={deleteBannersModal.dataTestId}
    />
  );
};
