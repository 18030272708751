import { ReactElement } from "react";

import {
  DetailedFeatureCard as DetailedFeatureCardMolecule,
  DetailedFeatureCardProps as DetailedFeatureCardMoleculeProps,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { CacheControlHeaderOverride } from "./CacheControlHeaderOverride";
import { CacheKey } from "./CacheKey";
import { FEATURES_AS_STRING, FEATURE_NAME_TRANSLATES } from "./constants";
import { CustomLogData } from "./CustomLogData";
import { DefaultCache } from "./DefaultCache";
import { DenyPolicy } from "./DenyPolicy";
import { FailedRefreshTTL } from "./FailedRefreshTTL";
import { FollowRedirects } from "./FollowRedirects";
import { GeneratedResponse } from "./GeneratedResponse";
import { GeoRestrictions } from "./GeoRestrictions";
import { IpRestrictions } from "./IpRestrictions";
import { Lua } from "./Lua";
import { NegativeTTL } from "./NegativeTTL";
import { OriginFillPolicy } from "./OriginFillPolicy";
import { QSHMode } from "./QSHMode";
import { ReportingOverride } from "./ReportingOverride";
import { ReqHeaders } from "./ReqHeaders";
import { RespHeaders } from "./RespHeaders";
import { StaleContentControl } from "./StaleContentControl";
import { TokenAuthentication } from "./TokenAuthentication";
import type {
  DetailedFeatureCardProps,
  FeatureComponent,
  FeatureMapKeys,
  FeaturesAsString,
} from "./types";
import { UriRewrite } from "./UriRewrite";

const FEATURE_MAP: Record<FeatureMapKeys, FeatureComponent<any>> = {
  cacheControlHeaderOverride: CacheControlHeaderOverride,
  cacheKey: CacheKey,
  customLogData: CustomLogData,
  defaultCache: DefaultCache,
  denyPolicy: DenyPolicy,
  failedRefreshTTL: FailedRefreshTTL,
  followRedirects: FollowRedirects,
  generatedResponse: GeneratedResponse,
  geoRestrictions: GeoRestrictions,
  ipRestrictions: IpRestrictions,
  negativeTTL: NegativeTTL,
  originFillPolicy: OriginFillPolicy,
  qshMode: QSHMode,
  reportingOverride: ReportingOverride,
  reqHeaders: ReqHeaders,
  reqLua: Lua,
  respHeaders: RespHeaders,
  respLua: Lua,
  staleContentControl: StaleContentControl,
  tokenAuthentication: TokenAuthentication,
  uriRewrite: UriRewrite,
};

const isFeatureAsString = (
  name: FeatureMapKeys | FeaturesAsString
): name is FeaturesAsString =>
  FEATURES_AS_STRING.includes(name as FeaturesAsString);

export const DetailedFeatureCard = ({
  name,
  value,
  isCompact,
  tMatchRulesPage,
  tPropertyPage,
  ...iconProps
}: DetailedFeatureCardProps): ReactElement => {
  const cardProps: DetailedFeatureCardMoleculeProps = {
    title: tMatchRulesPage(FEATURE_NAME_TRANSLATES[name]),
    ...iconProps,
  };

  if (!isCompact) {
    if (isFeatureAsString(name)) {
      cardProps.subTitle = "feature activated";
    } else {
      cardProps.children = FEATURE_MAP[name]({
        dataTestId: iconProps.dataTestId,
        feature: value,
        tMatchRulesPage,
        tPropertyPage,
      });
    }
  }

  return <DetailedFeatureCardMolecule {...cardProps} />;
};
