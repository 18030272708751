import { IMatchRule } from "../../../../../models/configuration/definitions";
import { ListType } from "../../../../../models/configuration/definitions/simpleDefinition";

export const updateSimpleDefinitionReferences = (listType: ListType) => (
  oldName: string,
  newName: string
) => (matchRule: IMatchRule): IMatchRule => {
  switch (listType) {
    case "geo":
      if (matchRule.features.geoRestrictions) {
        matchRule.features.geoRestrictions.nameList = matchRule.features.geoRestrictions?.nameList.map(
          (geoName) => (geoName === oldName ? newName : geoName)
        );
      }
      break;

    case "ip":
      if (matchRule.features.ipRestrictions) {
        matchRule.features.ipRestrictions.nameList = matchRule.features.ipRestrictions.nameList.map(
          (ipName) => (ipName === oldName ? newName : ipName)
        );
      }

      if (matchRule.features.geoRestrictions) {
        matchRule.features.geoRestrictions.ipWhitelist = matchRule.features.geoRestrictions.ipWhitelist?.map(
          (ipName) => (ipName === oldName ? newName : ipName)
        );
      }

      if (matchRule.features.tokenAuthentication) {
        matchRule.features.tokenAuthentication.ipWhitelist = matchRule.features.tokenAuthentication.ipWhitelist?.map(
          (ipName) => (ipName === oldName ? newName : ipName)
        );
      }
      break;
  }

  return matchRule;
};
