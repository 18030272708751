import { AxiosError } from "axios";

import { httpClient } from "../../core/http-client";
import { ErrorLevel, APIError } from "../error";

export interface ISubscriber {
  scid: string | undefined;
  subscriberId: number | undefined;
}

interface ISubscriberInfo {
  creationTime: string;
  itmNetwork: string;
  modifiedTime: string;
  scid: string;
  subscriberId: number;
  subscriberName: string;
  versionId: number;
}

export interface ISytemLimits {
  ccho: {
    int: {
      min: number;
      max: number;
    };
    ext: {
      min: number;
      max: number;
    };
  };
  aliasesPerProp: number;
  propsPerSub: number;
  reportIdsPerSub: number;
  altNamesPerCert: number;
  defaultCache: {
    min: number;
    max: number;
  };
  failedRefreshTTL: {
    min: number;
    max: number;
  };
  negativeTTL: {
    min: number;
    max: number;
  };
  matchLogicPerSub: number;
  matchGroupPerBlock: number;
  matchRulePerGroup: number;
  serviceImageSize: number;
  luaScriptLength: number;
  luaVariableNameLength: number;
  luaVariableValueLength: number;
  simpleListElementLength: number;
  elementsPerSimpleList: number;
  elementsPerCustomLogData: number;
  customLogDataKeyNameLength: number;
  customLogDataValueLength: number;
  definitionsPerBlock: number;
  headerNameLength: number;
  headerValueLength: number;
  expressionLength: number;
}

export enum SubscriberPermissions {
  SSL_PREMIUM = "SSL Premium",
  SSL_BASIC = "SSL Basic",
}

export interface IPremiumFeatures {
  premiumFeatures: {
    services: string[];
    overrides: string[];
  };
}

export type SubscriberInfo = {
  info: ISubscriberInfo;
  premiumFeatures: { overrides: string[]; services: string[] };
  properties: Record<
    string,
    { primaryAlias: string; propertyId: number; status: string }
  >;
  reportingOverrides: Record<
    string,
    { reportingId: number; reportingName: string; status: string }
  >;
  trafficTypes: string[];
  systemLimits: ISytemLimits;
};

export const getSubscriberTrafficTypes = async (subscriberId: number) => {
  try {
    const { data } = await httpClient.get<{ trafficTypes: string[] }>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/trafficTypes`
    );

    return data.trafficTypes;
  } catch (err) {
    const error = err as AxiosError;
    throw new APIError(
      error.message,
      "getSubscriberTrafficTypes()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
