import { ConfigurationDetailsType } from "../types";

export const getDefinitionsLength = (
  config: ConfigurationDetailsType
): number =>
  Object.values(config.simpleListDefinitions || {}).filter(
    (simpleListDefinition) =>
      simpleListDefinition.listType === "geo" ||
      simpleListDefinition.listType === "ip"
  ).length +
  Object.keys(config.generatedResponseDefinitions || {}).length +
  Object.keys(config.headerDefinitions || {}).length +
  Object.keys(config.luaDefinitions || {}).length +
  Object.keys(config.originFillPolicyDefinitions || {}).length +
  Object.keys(config.reportingOverrideDefinitions || {}).length +
  Object.keys(config.tokenAuthenticationDefinitions || {}).length;
