/* eslint-disable import/named */
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { animated, config, useTransition } from "react-spring";

import { useClickOutsideRef } from "../../../hooks/use-detect-click-outside-ref";
import { DropdownItems } from "../../molecules/DropdownItems/DropdownItems";
import { DropdownButton } from "../../atoms/DropdownButton/DropdownButton";

export enum DropdownColors {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum DropdownPosition {
  LEFT = "left",
  RIGHT = "right",
}

export interface IDropdownItem<T> {
  label: string;
  value: T;
  default?: boolean;
  dataTestId?: string;
}

export interface DropdownProps<T> {
  key?: any;
  id: string;
  items: IDropdownItem<T>[];
  placeholder: string;
  color?: DropdownColors;
  outline?: boolean;
  outlineClassName?: string;
  customButton?: ReactNode;
  bold?: boolean;
  position?: DropdownPosition;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  dataTestId?: string;
  onSelect: (selectedItem: IDropdownItem<T>) => void;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  onBlur?: (selectedItem?: IDropdownItem<T>) => void;
}

export function Dropdown<T>({
  items,
  id,
  placeholder,
  color,
  outline = true,
  customButton,
  bold = true,
  position = DropdownPosition.RIGHT,
  disabled = false,
  type,
  dataTestId,
  outlineClassName = "-m--1",
  onSelect,
  setError,
  clearErrors,
  onBlur,
}: DropdownProps<T>): ReactElement {
  const [t] = useTranslation("misc");

  const [isOpen, setIsOpen] = useState(false);
  const [isMount, setIsMount] = useState<boolean>(true);
  const [dropdownPosition, setDropdownPosition] = useState(position);
  const [selectedItem, setSelectedItem] = useState<
    IDropdownItem<any> | undefined
  >();

  const opening = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0px" },
    enter: { opacity: 1, height: `${items.length * 2}rem` },
    leave: { opacity: 0, height: "0px" },
  });

  const updateErrors = () => {
    if (setError && clearErrors) {
      if (!selectedItem) {
        setError(id, { type: "manual", message: t("ERROR_REQUIRED_FIELD") });
      } else {
        clearErrors(id);
      }
    }
  };

  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    updateErrors();
  }, [selectedItem === undefined]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideRef(dropdownRef, () => {
    setIsOpen(false);
    if (setError && clearErrors) {
      updateErrors();
    }
  });

  useEffect(() => {
    if (!selectedItem) {
      const defaultSelected = items.find((item) => item.default);
      setSelectedItem(defaultSelected);
    }
  }, [items, selectedItem]);

  // overrides `position` props if it doesn't fit in the screen
  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      if (dropdownPosition === DropdownPosition.RIGHT) {
        const dropdownRightPosition =
          dropdownRef.current.getBoundingClientRect().left +
          dropdownRef.current.querySelector(".chi-dropdown__menu")!.clientWidth;
        if (dropdownRightPosition > document.documentElement.clientWidth) {
          setDropdownPosition(DropdownPosition.LEFT);
        }
      } else {
        const dropdownLeftPosition =
          dropdownRef.current.getBoundingClientRect().right +
          dropdownRef.current.querySelector(".chi-dropdown__menu")!.clientWidth;

        if (dropdownLeftPosition > document.documentElement.clientWidth) {
          setDropdownPosition(DropdownPosition.LEFT);
        }
      }
    }
  }, [isOpen]);

  return (
    <DropdownContainer
      open={isOpen}
      className={`chi-dropdown ${outline && outlineClassName}`}
      onClick={(e) => {
        if (!disabled) {
          setIsOpen(!isOpen);
        }
        e.stopPropagation();
      }}
      onBlur={() => {
        onBlur && onBlur(selectedItem);
      }}
      ref={dropdownRef}
      customButton={!!customButton}
    >
      {customButton ? (
        customButton
      ) : (
        <DropdownButton
          type={type}
          disabled={disabled}
          buttonprops={{ outline, bold }}
          id={`dropdown-${id}`}
          className={`chi-button chi-dropdown__trigger ${
            color === DropdownColors.PRIMARY
              ? "-bg--primary -text--light"
              : null
          }`}
          data-testid={dataTestId ?? id}
        >
          {selectedItem ? selectedItem.label : placeholder}
        </DropdownButton>
      )}
      {opening(
        (styles, show) =>
          show && (
            <DropdownMenu
              position={dropdownPosition}
              custombutton={!!customButton ? 1 : 0}
              style={styles}
              className="chi-dropdown__menu"
            >
              <DropdownItems
                isOpen={isOpen}
                items={items}
                onSelect={onSelect}
                setSelectedItem={setSelectedItem}
                setIsOpen={setIsOpen}
              />
            </DropdownMenu>
          )
      )}
    </DropdownContainer>
  );
}

const DropdownContainer = styled.div<{
  open: boolean;
  customButton: boolean;
}>`
  padding: 0px;
  height: 100%;

  ${({ open }) =>
    open &&
    css`
      button {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    `}
`;

export const DropdownMenu = styled(animated.div)<{
  position: DropdownPosition;
  custombutton: number;
}>`
  display: table !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  width: inherit;
  background-color: ${({ theme }) => theme.backgrounds.baseLight}!important;
  ${({ position, custombutton }) =>
    custombutton &&
    position === DropdownPosition.LEFT &&
    css`
      transform: translateX(-90%);
    `}
`;
