import { Status } from "../../../@types/status";
import { ISytemLimits, SubscriberInfo } from "../../../models/subscriber";
import { useAppSelector } from "../../types";

export const useSubscriberSettingsStatus = (): Status =>
  useAppSelector((store) => store.subscriberSettings.status);

export const useSubscriberSettingsSystemLimits = (): ISytemLimits => {
  const systemLimits = useAppSelector(
    (store) => store.subscriberSettings.value?.systemLimits
  );

  if (!systemLimits) {
    throw new Error('"systemLimits" not defined in "subscriberSettings"');
  }

  return systemLimits;
};

export const useSubscriberSettingsReportingOverrides = (): SubscriberInfo["reportingOverrides"] => {
  const reportingOverrides = useAppSelector(
    (store) => store.subscriberSettings.value?.reportingOverrides
  );

  if (!reportingOverrides) {
    throw new Error('"reportingOverrides" not defined in "subscriberSettings"');
  }

  return reportingOverrides;
};

export const useSubscriberSettingsInfo = (): SubscriberInfo["info"] => {
  const info = useAppSelector((store) => store.subscriberSettings.value?.info);

  if (!info) {
    throw new Error('"info" not defined in "subscriberSettings"');
  }

  return info;
};

export const useSubscriberSettingsTrafficTypes = (): SubscriberInfo["trafficTypes"] => {
  const trafficTypes = useAppSelector(
    (store) => store.subscriberSettings.value?.trafficTypes
  );

  if (!trafficTypes) {
    throw new Error('"trafficTypes" not defined in "subscriberSettings"');
  }

  return trafficTypes;
};
