import { ReactElement } from "react";

import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";

interface HeadersBaseProps {
  dataTestId: string;
  title: string;
  nameList: string[];
}

export const HeadersBase = ({
  dataTestId,
  nameList,
  title,
}: HeadersBaseProps): ReactElement => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock title={title}>
      <ExpandableList dataTestId={dataTestId}>
        {nameList.map(renderColContent)}
      </ExpandableList>
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
