import { useState, useMemo } from "react";
import styled from "@emotion/styled";

import { ApiKeysTableFilter } from "./ApiKeysTableFilter";
import { filterApiKeys } from "./helpers";
import {
  ApiKeysCustomerFilter,
  ApiKeysOrder,
  ApiKeysStatus,
  FilteredApiKeysData,
} from "./types";
import { ApiKeysRow } from "./ApiKeysRow";
import {
  ApiKeysData,
  ApiKeysOwners,
  ApiKeysRoles,
} from "../../../../models/user-management/apiKeys/apiKeys.interfaces";
import { VyvxFiberCompany } from "../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { InfiniteScrollContainer } from "../../../../components/atoms/InfiniteScrollContainer/InfiniteScrollContainer";

interface ApiKeysTableProps {
  apiKeys: ApiKeysData[];
  roles: ApiKeysRoles[];
  fiberCompanies?: VyvxFiberCompany[];
  owners: ApiKeysOwners[];
  isVyvx: boolean;
  parentCompany?: string;
}

export const ApiKeysTable = ({
  apiKeys,
  roles,
  fiberCompanies,
  owners,
  isVyvx,
  parentCompany,
}: ApiKeysTableProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [roleFilter, setRoleFilter] = useState<string>("all");
  const [statusFilter, setStatusFilter] = useState<ApiKeysStatus>(
    ApiKeysStatus.ALL
  );
  const [orderFilter, setOrderFilter] = useState<ApiKeysOrder>(
    ApiKeysOrder.RECENT
  );
  const [customerFilter, setCustomerFilter] = useState<ApiKeysCustomerFilter>({
    customerName: "all",
    customerId: "0",
  });
  const [tableData, setTableData] = useState<FilteredApiKeysData[]>([]);

  const displayedApiKeys = useMemo(
    () =>
      filterApiKeys(
        searchInput,
        roleFilter,
        statusFilter,
        orderFilter,
        customerFilter,
        isVyvx
      )(apiKeys),
    [apiKeys, roleFilter, statusFilter, searchInput, customerFilter]
  );

  return (
    <>
      <ApiKeysTableFilter
        onSearchInputChange={setSearchInput}
        roleFilter={roleFilter}
        onRoleFilterChange={setRoleFilter}
        statusFilter={statusFilter}
        onStatusFilterChange={setStatusFilter}
        roles={roles}
        orderFilter={orderFilter}
        onOrderFilter={setOrderFilter}
        isVyvx={isVyvx}
        owners={owners}
        fiberCompanies={fiberCompanies}
        customerFilter={customerFilter}
        onCustomerFilter={setCustomerFilter}
      />
      <InfiniteScrollContainer
        data={displayedApiKeys}
        pageSize={100}
        onUpdateData={setTableData}
        scrollableTarget="api-keys-rows-container"
      >
        <ApiKeysContainer id="api-keys-rows-container">
          {tableData.map((apiKey, i) => (
            <ApiKeysRow
              key={i}
              apiKey={apiKey}
              roles={roles}
              fiberCompanies={fiberCompanies}
              isVyvx={isVyvx}
            />
          ))}
        </ApiKeysContainer>
      </InfiniteScrollContainer>
    </>
  );
};

const ApiKeysContainer = styled.div`
  overflow-y: auto;
  height: 50vh;
`;
