import { v4 } from "uuid";
import { IDefinition } from "../../../../models/configuration/definitions/definition";
import { DefinitionType } from "../types";

export const parseDefinition = (definition: IDefinition): DefinitionType => {
  const parsedDefinition: DefinitionType = {
    id: definition.id || v4(),
    name: definition.name,
    type: definition.type,
    _schemaDescription: definition.description,
    unhandledFields: [],
  };

  Object.entries(definition).map(([key, value]) => {
    if (
      !Array.from(Object.keys(parsedDefinition)).find((item) => item === key) &&
      key !== "description"
    ) {
      parsedDefinition.unhandledFields.push([key, value]);
    }
  });

  return parsedDefinition;
};
