/* eslint-disable complexity */
import { useTranslation } from "react-i18next";
import { Accordion } from "../../../../../../../../../components/molecules/Accordion/Accordion";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { Icons } from "../../../../../../../../../components/atoms/Icon/Icon";
import {
  AuthenticationTypes,
  DEFAULT_HTTPS_PORT,
  DEFAULT_HTTP_PORT,
  IAwsv4,
  IHeadersAuthentication,
  IMember,
  ORIGIN_USE_CLIENT_HOST_VALUE,
  Protocols,
  MINIMUM_HTTP_TIMEOUT,
  OriginTypes,
} from "../../../../../../../../../models/configuration/definitions/origin";
import {
  PropertySection,
  PropertySubsection,
} from "../../../../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../../../../../../../models/configuration/errors";
import { isValidRootPath } from "../../../../../../../../../utils/string";
import { HeadersTable } from "./HeadersTable";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { useConfigurationsErrors } from "../../../../../../../../../store/slices/caching/hooks";
import { useAppDispatch } from "../../../../../../../../../store/types";
import {
  handleAddConfigurationError,
  handleRemoveConfigurationError,
  handleUpdateConfigurationErrors,
} from "../../../../../../../../../store/slices/caching/thunks";
import { OriginDefinitionType } from "../../../../../../../../../store/slices/caching/types";
import { removeOriginMember } from "../../../../../../../../../store/slices/caching/helpers/removeOriginMember";

interface IOriginBlockProps {
  member: IMember;
  onChange: (updatedOriginDef: Partial<OriginDefinitionType>) => void;
  originDefinition: OriginDefinitionType;
  index: number;
  isOptional: boolean;
}

export const OriginBlock: React.FC<IOriginBlockProps> = ({
  member,
  onChange,
  originDefinition,
  index,
  isOptional,
}) => {
  const { t } = useTranslation("configurationPropertyPage");
  const [tConfigurationDefinitionsPage] = useTranslation(
    "configurationDefinitionsPage"
  );
  const isViewMode = useIsViewMode();
  const errors = useConfigurationsErrors();
  const dispatch = useAppDispatch();

  const authDefined = member.authentication?.type !== undefined;

  const getMemberDescription = (memberIndex: number): string => {
    if (originDefinition.members.length === 1) {
      return t("PROPERTY_CARD_ORIGIN_MEMBER_DESCRIPTION_SINGLE");
    }
    switch (memberIndex) {
      case 0:
        return t("PROPERTY_CARD_ORIGIN_MEMBER_DESCRIPTION_1");
      case 1:
        return t("PROPERTY_CARD_ORIGIN_MEMBER_DESCRIPTION_2");
      case 2:
        return t("PROPERTY_CARD_ORIGIN_MEMBER_DESCRIPTION_3");
      default:
        throw Error(`Index ${memberIndex} is invalid. Should be minor to 2`);
    }
  };

  const isHeadersNameEmptyError = errors.some(
    (err) =>
      err.type ===
        ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_EMPTY &&
      err.data?.section === PropertySection.ORIGIN &&
      err.data?.subsection === PropertySubsection.ORIGIN &&
      err.data?.authType === AuthenticationTypes.HEADERS &&
      err.data?.index === index
  );

  return (
    <Accordion
      dataTestId={`origin-single-${index}`}
      title={`Member ${index + 1}`}
      icon={Icons.PILE}
      description={getMemberDescription(index)}
      mandatory={!isOptional}
      onDelete={
        isOptional
          ? () => {
              onChange({
                members: removeOriginMember(index, originDefinition),
              });
            }
          : undefined
      }
    >
      <PropertyDefinitionCard
        title={t("PROPERTY_CARD_ORIGIN_NAME_TITLE")}
        required
        divider
        additionalInfoTitle={t("PROPERTY_CARD_ORIGIN_NAME_DESCRIPTION_TITLE")}
        additionalInfoContent={t(
          "PROPERTY_CARD_ORIGIN_NAME_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.InputFieldType}
        fieldProps={{
          placeholder: t("PROPERTY_CARD_ORIGIN_NAME_PLACEHOLDER"),
          disabled: isViewMode,
          value: member.name,
          onBlur: (name: string) => {
            if (!name) {
              dispatch(
                handleAddConfigurationError(
                  ConfigurationErrorLevel.ERROR,
                  ConfigurationErrorType.PROPERTY_ORIGIN_NAME_EMPTY,
                  {
                    section: PropertySection.ORIGIN,
                    index,
                  }
                )
              );
            }
            if (name.length > 94) {
              dispatch(
                handleAddConfigurationError(
                  ConfigurationErrorLevel.ERROR,
                  ConfigurationErrorType.PROPERTY_ORIGIN_NAME_SIZE,
                  {
                    section: PropertySection.ORIGIN,
                    index,
                  }
                )
              );
            }
          },
          onChange: (newName) => {
            const errorsToAdd: IConfigurationError[] = [];
            const errorsToRemove: ((
              err: IConfigurationError
            ) => boolean)[] = [];

            if (newName.length === 0) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type: ConfigurationErrorType.PROPERTY_ORIGIN_NAME_EMPTY,
                data: {
                  section: PropertySection.ORIGIN,
                  index,
                },
              });
            } else if (newName.length > 94) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type: ConfigurationErrorType.PROPERTY_ORIGIN_NAME_SIZE,
                data: {
                  section: PropertySection.ORIGIN,
                  index,
                },
              });
            } else {
              errorsToRemove.push(
                (err) =>
                  !(
                    err.type ===
                      ConfigurationErrorType.PROPERTY_ORIGIN_NAME_EMPTY &&
                    err.data?.section === PropertySection.ORIGIN &&
                    err.data?.index === index
                  )
              );
              errorsToRemove.push(
                (err) =>
                  !(
                    err.type ===
                      ConfigurationErrorType.PROPERTY_ORIGIN_NAME_SIZE &&
                    err.data?.section === PropertySection.ORIGIN &&
                    err.data?.index === index
                  )
              );
            }

            if (originDefinition.originType === OriginTypes.FAILOVER) {
              if (
                originDefinition.members.some(
                  (origin) => origin.name === newName
                )
              ) {
                errorsToAdd.push({
                  level: ConfigurationErrorLevel.ERROR,
                  type: ConfigurationErrorType.PROPERTY_ORIGIN_NAME_DUPLICATE,
                  data: {
                    section: PropertySection.ORIGIN,
                    originType: OriginTypes.FAILOVER,
                    index,
                  },
                });
              } else {
                errorsToRemove.push(
                  (err) =>
                    !(
                      err.type ===
                        ConfigurationErrorType.PROPERTY_ORIGIN_NAME_DUPLICATE &&
                      err.data?.section === PropertySection.ORIGIN &&
                      err.data?.originType === OriginTypes.FAILOVER &&
                      err.data?.index === index
                    )
                );
              }
            }

            dispatch(
              handleUpdateConfigurationErrors(errorsToAdd, errorsToRemove)
            );
            onChange({
              members: originDefinition.members.map((m, i) =>
                i === index ? { ...member, name: newName } : m
              ),
            });
          },
          dataTestId: `origin-${index}-origin-name`,
        }}
        errorMessage={
          errors.some(
            (err) =>
              err.type === ConfigurationErrorType.PROPERTY_ORIGIN_NAME_EMPTY &&
              err.data?.section === PropertySection.ORIGIN &&
              err.data?.index === index
          )
            ? t("ERROR_ORIGIN_NAME_EMPTY")
            : errors.some(
                (err) =>
                  err.type ===
                    ConfigurationErrorType.PROPERTY_ORIGIN_NAME_SIZE &&
                  err.data?.section === PropertySection.ORIGIN &&
                  err.data?.index === index
              )
            ? t("ERROR_ORIGIN_NAME_SIZE")
            : errors.some(
                (err) =>
                  err.type ===
                    ConfigurationErrorType.PROPERTY_ORIGIN_NAME_DUPLICATE &&
                  err.data?.section === PropertySection.ORIGIN &&
                  err.data?.index === index
              )
            ? t("ERROR_ORIGIN_NAME_DUPLICATE")
            : undefined
        }
        dataTestId={`origin-${index}-origin-name`}
      />
      <PropertyDefinitionCard
        divider
        required
        title={t("PROPERTY_CARD_ORIGIN_HOST_OTHER_HOST_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ORIGIN_HOST_OTHER_HOST_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ORIGIN_HOST_OTHER_HOST_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.InputWithCheckboxType}
        fieldProps={{
          inputDisabled:
            isViewMode || member.origin.host === ORIGIN_USE_CLIENT_HOST_VALUE,
          dataTestId: `origin-${index}-origin-host`,
          value: member.origin.host,
          placeholder: t("PROPERTY_CARD_ORIGIN_HOST_OTHER_HOST_PLACEHOLDER"),
          onChange: (host) => {
            if (host.length === 0) {
              dispatch(
                handleAddConfigurationError(
                  ConfigurationErrorLevel.ERROR,
                  ConfigurationErrorType.PROPERTY_ORIGIN_HOST_EMPTY,
                  {
                    section: PropertySection.ORIGIN,
                    index,
                  }
                )
              );
            } else {
              dispatch(
                handleRemoveConfigurationError(
                  (err) =>
                    !(
                      err.type ===
                        ConfigurationErrorType.PROPERTY_ORIGIN_HOST_EMPTY &&
                      err.data?.section === PropertySection.ORIGIN &&
                      err.data?.index === index
                    )
                )
              );
            }
            onChange({
              members: originDefinition.members.map((m, i) =>
                i === index
                  ? { ...member, origin: { ...member.origin, host } }
                  : m
              ),
            });
          },
          checkboxDisabled: isViewMode,
          checkboxValue: member.origin.host === ORIGIN_USE_CLIENT_HOST_VALUE,
          onCheckBoxChange: (check) => {
            if (check) {
              if (member.origin.host !== ORIGIN_USE_CLIENT_HOST_VALUE) {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_HOST_EMPTY &&
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
              }
              onChange({
                members: originDefinition.members.map((m, i) =>
                  i === index
                    ? {
                        ...member,
                        origin: {
                          ...member.origin,
                          host: ORIGIN_USE_CLIENT_HOST_VALUE,
                          address: member.origin.address || "",
                        },
                      }
                    : m
                ),
              });
            } else {
              if (member.origin.host === ORIGIN_USE_CLIENT_HOST_VALUE) {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_HOST_ADDRESS_EMPTY &&
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
                onChange({
                  members: originDefinition.members.map((m, i) =>
                    i === index
                      ? { ...member, origin: { ...member.origin, host: "" } }
                      : m
                  ),
                });
              }
            }
          },
          label: t("PROPERTY_CARD_ORIGIN_HOST_CLIENT_HOST_LABEL"),
        }}
        errorMessage={
          errors.some(
            (err) =>
              err.type === ConfigurationErrorType.PROPERTY_ORIGIN_HOST_EMPTY &&
              err.data?.section === PropertySection.ORIGIN &&
              err.data?.index === index
          )
            ? t("ERROR_ORIGIN_HOST_EMPTY")
            : undefined
        }
        dataTestId={`origin-${index}-origin-host-input`}
      />
      <PropertyDefinitionCard
        required={member.origin.host === ORIGIN_USE_CLIENT_HOST_VALUE}
        divider
        title={t("PROPERTY_CARD_ORIGIN_ADDRESS_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ORIGIN_ADDRESS_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ORIGIN_ADDRESS_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.InputFieldType}
        fieldProps={{
          disabled: isViewMode,
          value: member.origin.address,
          placeholder: t("PROPERTY_CARD_ORIGIN_ADDRESS_PLACEHOLDER"),
          onChange: (address) => {
            if (member.origin.host === ORIGIN_USE_CLIENT_HOST_VALUE) {
              if (address.length === 0) {
                dispatch(
                  handleAddConfigurationError(
                    ConfigurationErrorLevel.ERROR,
                    ConfigurationErrorType.PROPERTY_ORIGIN_HOST_ADDRESS_EMPTY,
                    {
                      section: PropertySection.ORIGIN,
                      index,
                    }
                  )
                );
              } else {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_HOST_ADDRESS_EMPTY &&
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
              }
            }
            onChange({
              members: originDefinition.members.map((m, i) =>
                i === index
                  ? { ...member, origin: { ...member.origin, address } }
                  : m
              ),
            });
          },
          dataTestId: `origin-${index}-ip-address`,
        }}
        errorMessage={
          errors.some(
            (err) =>
              err.type ===
                ConfigurationErrorType.PROPERTY_ORIGIN_HOST_ADDRESS_EMPTY &&
              err.data?.section === PropertySection.ORIGIN &&
              err.data?.index === index
          )
            ? t("ERROR_ORIGIN_HOST_ADDRESS_EMPTY")
            : undefined
        }
        dataTestId={`origin-${index}-ip-address`}
      />
      <PropertyDefinitionCard
        divider
        required
        title={t("PROPERTY_CARD_ORIGIN_PROTOCOL_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ORIGIN_PROTOCOL_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ORIGIN_PROTOCOL_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.RadioWithInputType}
        fieldProps={{
          placeholder: t("PROPERTY_CARD_ORIGIN_PROTOCOL_PLACEHOLDER"),
          inline: true,
          radioButtonsProps: [
            {
              onClick: () => {
                if (member.origin.protocol === Protocols.HTTPS) {
                  dispatch(
                    handleRemoveConfigurationError(
                      (err) =>
                        !(
                          err.type ===
                            ConfigurationErrorType.PROPERTY_ORIGIN_PORT_EMPTY &&
                          err.data?.section === PropertySection.ORIGIN &&
                          err.data?.index === index
                        )
                    )
                  );
                  onChange({
                    members: originDefinition.members.map((m, i) =>
                      i === index
                        ? {
                            ...member,
                            origin: {
                              ...member.origin,
                              protocol: Protocols.HTTP,
                              port: DEFAULT_HTTP_PORT,
                            },
                          }
                        : m
                    ),
                  });
                }
              },
              disabled: isViewMode,
              defaultChecked: member.origin.protocol === Protocols.HTTP,
              value: Protocols.HTTP,
              label: t("PROPERTY_CARD_ORIGIN_PROTOCOL_RADIO_HTTP_LABEL"),
              name: `protocol-${index}`,
              id: `Protocols-HTTP-${index}`,
              dataTestId: `origin-${index}-protocol-http-radio`,
            },
            {
              value: Protocols.HTTPS,
              disabled: isViewMode,
              onClick: () => {
                if (member.origin.protocol === Protocols.HTTP) {
                  dispatch(
                    handleRemoveConfigurationError(
                      (err) =>
                        !(
                          err.type ===
                            ConfigurationErrorType.PROPERTY_ORIGIN_PORT_EMPTY &&
                          err.data?.section === PropertySection.ORIGIN &&
                          err.data?.index === index
                        )
                    )
                  );
                  onChange({
                    members: originDefinition.members.map((m, i) =>
                      i === index
                        ? {
                            ...member,
                            origin: {
                              ...member.origin,
                              protocol: Protocols.HTTPS,
                              port: DEFAULT_HTTPS_PORT,
                            },
                          }
                        : m
                    ),
                  });
                }
              },
              label: t("PROPERTY_CARD_ORIGIN_PROTOCOL_RADIO_HTTPS_LABEL"),
              name: `protocol-${index}`,
              id: `Protocols-HTTPS-${index}`,
              defaultChecked: member.origin.protocol === Protocols.HTTPS,
              dataTestId: `origin-${index}-protocol-https-radio`,
            },
          ],
          disabled: isViewMode,
          inputType: "number",
          min: 1,
          onChange: (newPortValue) => {
            if (isNaN(newPortValue)) {
              dispatch(
                handleAddConfigurationError(
                  ConfigurationErrorLevel.ERROR,
                  ConfigurationErrorType.PROPERTY_ORIGIN_PORT_EMPTY,
                  { section: PropertySection.ORIGIN, index }
                )
              );
            } else {
              dispatch(
                handleRemoveConfigurationError(
                  (err) =>
                    !(
                      err.type ===
                        ConfigurationErrorType.PROPERTY_ORIGIN_PORT_EMPTY &&
                      err.data?.section === PropertySection.ORIGIN &&
                      err.data?.index === index
                    )
                )
              );
            }
            onChange({
              members: originDefinition.members.map((m, i) =>
                i === index
                  ? {
                      ...member,
                      origin: { ...member.origin, port: Number(newPortValue) },
                    }
                  : m
              ),
            });
          },
          value: member.origin.port,
          dataTestId: `origin-${index}-port`,
        }}
        errorMessage={
          errors.some(
            (err) =>
              err.type === ConfigurationErrorType.PROPERTY_ORIGIN_PORT_EMPTY &&
              err.data?.section === PropertySection.ORIGIN &&
              err.data?.index === index
          )
            ? t("ERROR_ORIGIN_PORT_EMPTY")
            : undefined
        }
        dataTestId={`origin-${index}-protocol`}
      />
      {member.origin.protocol === Protocols.HTTPS ? (
        <PropertyDefinitionCard
          divider
          title={t("PROPERTY_CARD_ORIGIN_INVALID_CERT_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ORIGIN_INVALID_CERT_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ORIGIN_INVALID_CERT_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.CheckboxType}
          fieldProps={{
            disabled: isViewMode,
            value: member.origin.ignoreInvalidCert,
            checked: member.origin.ignoreInvalidCert,
            label: t("PROPERTY_CARD_ORIGIN_INVALID_CERT_CHECKBOX_LABEL"),
            onChange: (ignoreInvalidCert) => {
              onChange({
                members: originDefinition.members.map((m, i) =>
                  i === index
                    ? {
                        ...member,
                        origin: { ...member.origin, ignoreInvalidCert },
                      }
                    : m
                ),
              });
            },
            dataTestId: `origin-${index}-ignore-invalid-certificate`,
          }}
          dataTestId={`origin-${index}-ignore-invalid-certificate`}
        />
      ) : null}
      <PropertyDefinitionCard
        divider
        title={t("PROPERTY_CARD_ORIGIN_WEB_ROOT_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ORIGIN_WEB_ROOT_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ORIGIN_WEB_ROOT_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.InputFieldType}
        fieldProps={{
          disabled: isViewMode,
          value: member.origin.webroot,
          placeholder: t("PROPERTY_CARD_ORIGIN_WEB_ROOT_PLACEHOLDER"),
          onChange: (newWebRoot) => {
            let webroot: string;

            if (!newWebRoot) {
              webroot = "/";
            } else {
              if (!isValidRootPath(newWebRoot)) {
                dispatch(
                  handleAddConfigurationError(
                    ConfigurationErrorLevel.ERROR,
                    ConfigurationErrorType.PROPERTY_ORIGIN_WEBROOT_INVALID,
                    { section: PropertySection.ORIGIN, index }
                  )
                );
              } else {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_WEBROOT_INVALID &&
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
              }
              webroot = newWebRoot;
            }

            onChange({
              members: originDefinition.members.map((m, i) =>
                i === index
                  ? { ...member, origin: { ...member.origin, webroot } }
                  : m
              ),
            });
          },
          dataTestId: `origin-${index}-webroot`,
        }}
        errorMessage={
          errors.some(
            (err) =>
              err.type ===
                ConfigurationErrorType.PROPERTY_ORIGIN_WEBROOT_INVALID &&
              err.data?.section === PropertySection.ORIGIN &&
              err.data?.index === index
          )
            ? t("ERROR_ORIGIN_WEBROOT_INVALID")
            : undefined
        }
      />
      <Accordion
        title="Authentication"
        icon={Icons.LOCK}
        mandatory
        description={{
          descriptionTitle: t(
            "PROPERTY_CARD_ORIGIN_AUTHENTICATION_DESCRIPTION_TITLE"
          ),
          descriptionContent: t(
            "PROPERTY_CARD_ORIGIN_AUTHENTICATION_DESCRIPTION_CONTENT"
          ),
        }}
      >
        <PropertyDefinitionCard
          divider={authDefined}
          title={t("PROPERTY_CARD_ORIGIN_AUTHENTICATION_TITLE")}
          fieldType={FieldType.RadioButtonsType}
          fieldProps={{
            inline: true,
            radioButtonsProps: [
              {
                value: "none",
                onClick: () => {
                  if (member.authentication !== undefined) {
                    dispatch(
                      handleRemoveConfigurationError(
                        (err) =>
                          !(
                            err.data?.section === PropertySection.ORIGIN &&
                            err.data?.index === index &&
                            (err.data?.authType === AuthenticationTypes.AWSV4 ||
                              err.data?.authType ===
                                AuthenticationTypes.HEADERS)
                          )
                      )
                    );

                    onChange({
                      members: originDefinition.members.map((m, i) =>
                        i === index
                          ? { ...member, authentication: undefined }
                          : m
                      ),
                    });
                  }
                },
                label: t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_RADIO_NONE_LABEL"
                ),
                name: `auth-type-${index}`,
                id: `none-${index}`,
                defaultChecked: member.authentication === undefined,
                disabled: isViewMode,
                dataTestId: `origin-${index}-authentication-none-radio`,
              },
              {
                value: "headers",
                onClick: () => {
                  if (
                    member.authentication?.type !== AuthenticationTypes.HEADERS
                  ) {
                    dispatch(
                      handleRemoveConfigurationError(
                        (err) =>
                          !(
                            err.data?.section === PropertySection.ORIGIN &&
                            err.data?.authType === AuthenticationTypes.AWSV4 &&
                            err.data?.index === index
                          )
                      )
                    );

                    onChange({
                      members: originDefinition.members.map((m, i) =>
                        i === index
                          ? {
                              ...member,
                              authentication: {
                                type: AuthenticationTypes.HEADERS,
                                parameters: [] as IHeadersAuthentication[],
                              },
                            }
                          : m
                      ),
                    });
                  }
                },
                label: t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_RADIO_HEADERS_LABEL"
                ),
                name: `auth-type-${index}`,
                id: `headers-${index}`,
                defaultChecked:
                  member.authentication?.type === AuthenticationTypes.HEADERS,
                disabled: isViewMode,
                dataTestId: `origin-${index}-authentication-headers-radio`,
              },
              {
                value: "awsv4",
                onClick: () => {
                  if (
                    member.authentication?.type !== AuthenticationTypes.AWSV4
                  ) {
                    dispatch(
                      handleRemoveConfigurationError(
                        (err) =>
                          !(
                            err.data?.section === PropertySection.ORIGIN &&
                            err.data?.authType ===
                              AuthenticationTypes.HEADERS &&
                            err.data?.index === index
                          )
                      )
                    );

                    onChange({
                      members: originDefinition.members.map((m, i) =>
                        i === index
                          ? {
                              ...member,
                              authentication: {
                                type: AuthenticationTypes.AWSV4,
                                parameters: {
                                  accessKeyId: "accessKeyId",
                                  secret: "secretValue",
                                } as IAwsv4,
                              },
                            }
                          : m
                      ),
                    });
                  }
                },
                label: t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_RADIO_AWSV4_LABEL"
                ),
                name: `auth-type-${index}`,
                id: `awsv4-${index}`,
                defaultChecked:
                  member.authentication?.type === AuthenticationTypes.AWSV4,
                disabled: isViewMode,
                dataTestId: `origin-${index}-authentication-awsv4-radio`,
              },
            ],
          }}
        />
        {authDefined ? (
          member.authentication?.type === AuthenticationTypes.AWSV4 ? (
            <>
              <PropertyDefinitionCard
                title={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_ACCESS_KEY_ID_TITLE"
                )}
                additionalInfoTitle={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_ACCESS_KEY_ID_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_ACCESS_KEY_ID_DESCRIPTION_CONTENT"
                )}
                divider
                required
                fieldType={FieldType.InputFieldType}
                fieldProps={{
                  placeholder: t(
                    "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_ACCESS_KEY_ID_PLACEHOLDER"
                  ),
                  onChange: (accessKeyId) => {
                    if (accessKeyId.length === 0) {
                      dispatch(
                        handleAddConfigurationError(
                          ConfigurationErrorLevel.ERROR,

                          ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_ACCESS_KEY_EMPTY,
                          {
                            section: PropertySection.ORIGIN,
                            subsection: PropertySubsection.ORIGIN,
                            authType: AuthenticationTypes.AWSV4,
                            index,
                          }
                        )
                      );
                    } else {
                      dispatch(
                        handleRemoveConfigurationError(
                          (err) =>
                            !(
                              err.type ===
                                ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_ACCESS_KEY_EMPTY &&
                              err.data?.section === PropertySection.ORIGIN &&
                              err.data?.subsection ===
                                PropertySubsection.ORIGIN &&
                              err.data?.authType ===
                                AuthenticationTypes.AWSV4 &&
                              err.data?.index === index
                            )
                        )
                      );
                    }

                    if (member.authentication) {
                      onChange({
                        members: originDefinition.members.map((m, i) =>
                          i === index
                            ? {
                                ...member,
                                authentication: {
                                  ...member.authentication,
                                  type: AuthenticationTypes.AWSV4,
                                  parameters: {
                                    ...member.authentication?.parameters,
                                    accessKeyId,
                                  } as IAwsv4,
                                },
                              }
                            : m
                        ),
                      });
                    }
                  },
                  value:
                    (member.authentication?.parameters as IAwsv4).accessKeyId ||
                    "",
                  disabled: isViewMode,
                  dataTestId: `origin-${index}-aws-access-key-id`,
                }}
                errorMessage={
                  errors.some(
                    (err) =>
                      err.type ===
                        ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_ACCESS_KEY_EMPTY &&
                      err.data?.section === PropertySection.ORIGIN &&
                      err.data?.subsection === PropertySubsection.ORIGIN &&
                      err.data?.authType === AuthenticationTypes.AWSV4 &&
                      err.data?.index === index
                  )
                    ? t("ERROR_ORIGIN_AUTHENTICATION_ACCESS_KEY_EMPTY")
                    : undefined
                }
                dataTestId={`origin-${index}-aws-access-key-id`}
              />
              <PropertyDefinitionCard
                title={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_SECRET_TITLE"
                )}
                additionalInfoTitle={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_SECRET_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_SECRET_DESCRIPTION_CONTENT"
                )}
                divider
                required
                fieldType={FieldType.InputFieldType}
                fieldProps={{
                  value:
                    (member.authentication?.parameters as IAwsv4).secret || "",
                  placeholder: t(
                    "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_SECRET_PLACEHOLDER"
                  ),
                  onChange: (secret) => {
                    if (secret.length === 0) {
                      dispatch(
                        handleAddConfigurationError(
                          ConfigurationErrorLevel.ERROR,
                          ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_SECRET_EMPTY,
                          {
                            section: PropertySection.ORIGIN,
                            subsection: PropertySubsection.ORIGIN,
                            authType: AuthenticationTypes.AWSV4,
                            index,
                          }
                        )
                      );
                    } else {
                      dispatch(
                        handleRemoveConfigurationError(
                          (err) =>
                            !(
                              err.type ===
                                ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_SECRET_EMPTY &&
                              err.data?.section === PropertySection.ORIGIN &&
                              err.data?.subsection ===
                                PropertySubsection.ORIGIN &&
                              err.data?.authType ===
                                AuthenticationTypes.AWSV4 &&
                              err.data?.index === index
                            )
                        )
                      );
                    }
                    if (member.authentication) {
                      onChange({
                        members: originDefinition.members.map((m, i) =>
                          i === index
                            ? {
                                ...member,
                                authentication: {
                                  ...member.authentication,
                                  type: AuthenticationTypes.AWSV4,
                                  parameters: {
                                    ...member.authentication?.parameters,
                                    secret,
                                  } as IAwsv4,
                                },
                              }
                            : m
                        ),
                      });
                    }
                  },
                  disabled: isViewMode,
                  dataTestId: `origin-${index}-aws-access-key-secret`,
                }}
                errorMessage={
                  errors.some(
                    (err) =>
                      err.type ===
                        ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_SECRET_EMPTY &&
                      err.data?.section === PropertySection.ORIGIN &&
                      err.data?.subsection === PropertySubsection.ORIGIN &&
                      err.data?.authType === AuthenticationTypes.AWSV4 &&
                      err.data?.index === index
                  )
                    ? t("ERROR_ORIGIN_AUTHENTICATION_SECRET_EMPTY")
                    : undefined
                }
                dataTestId={`origin-${index}-aws-access-key-secret`}
              />
              <PropertyDefinitionCard
                title={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_REGION_TITLE"
                )}
                additionalInfoTitle={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_REGION_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_REGION_DESCRIPTION_CONTENT"
                )}
                divider
                fieldType={FieldType.InputFieldType}
                fieldProps={{
                  value:
                    (member.authentication?.parameters as IAwsv4).region || "",
                  placeholder: t(
                    "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_REGION_PLACEHOLDER"
                  ),
                  onChange: (region) => {
                    if (member.authentication) {
                      onChange({
                        members: originDefinition.members.map((m, i) =>
                          i === index
                            ? {
                                ...member,
                                authentication: {
                                  ...member.authentication,
                                  type: AuthenticationTypes.AWSV4,
                                  parameters: {
                                    ...member.authentication?.parameters,
                                    region,
                                  } as IAwsv4,
                                },
                              }
                            : m
                        ),
                      });
                    }
                  },
                  disabled: isViewMode,
                  dataTestId: `origin-${index}-aws-region`,
                }}
                dataTestId={`origin-${index}-aws-region`}
              />
              <PropertyDefinitionCard
                title={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_HOSTNAME_TITLE"
                )}
                additionalInfoTitle={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_HOSTNAME_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_HOSTNAME_DESCRIPTION_CONTENT"
                )}
                fieldType={FieldType.InputFieldType}
                fieldProps={{
                  value:
                    (member.authentication?.parameters as IAwsv4).hostname ||
                    "",
                  placeholder: t(
                    "PROPERTY_CARD_ORIGIN_AUTHENTICATION_AWSV4_HOSTNAME_PLACEHOLDER"
                  ),
                  onChange: (hostname) => {
                    if (member.authentication) {
                      onChange({
                        members: originDefinition.members.map((m, i) =>
                          i === index
                            ? {
                                ...member,
                                authentication: {
                                  ...member.authentication,
                                  type: AuthenticationTypes.AWSV4,
                                  parameters: {
                                    ...member.authentication?.parameters,
                                    hostname,
                                  } as IAwsv4,
                                },
                              }
                            : m
                        ),
                      });
                    }
                  },
                  disabled: isViewMode,
                  dataTestId: `origin-${index}-aws-host-name`,
                }}
                dataTestId={`origin-${index}-aws-host-name`}
              />
            </>
          ) : (
            <>
              <PropertyDefinitionCard
                key={`name-${index}`}
                title={t("PROPERTY_CARD_ORIGIN_AUTHENTICATION_HEADERS_TITLE")}
                additionalInfoTitle={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_HEADERS_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "PROPERTY_CARD_ORIGIN_AUTHENTICATION_HEADERS_DESCRIPTION_CONTENT"
                )}
                fieldType={FieldType.OtherType}
                fieldProps={{
                  other: (
                    <HeadersTable
                      isHeadersNameEmptyError={isHeadersNameEmptyError}
                      onChange={onChange}
                      originDefinition={originDefinition}
                      member={member}
                      disabled={isViewMode}
                      originIndex={index}
                      dataTestId={`origin-${index}-headers-table`}
                    />
                  ),
                }}
                errorMessage={
                  isHeadersNameEmptyError
                    ? t("ERROR_ORIGIN_AUTHENTICATION_HEADERS_NAME_EMPTY")
                    : errors.some(
                        (err) =>
                          err.type ===
                            ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_DUPLICATE &&
                          err.data?.section === PropertySection.ORIGIN &&
                          err.data?.subsection === PropertySubsection.ORIGIN &&
                          err.data?.authType === AuthenticationTypes.HEADERS &&
                          err.data?.index === index
                      )
                    ? tConfigurationDefinitionsPage(
                        "ERROR_DEFINITION_DUPLICATE_HEADER_NAMES"
                      )
                    : undefined
                }
                dataTestId={`origin-${index}-headers-table`}
              />
            </>
          )
        ) : null}
      </Accordion>
      <br />
      <Accordion title="Advanced Settings" icon={Icons.GEAR}>
        <PropertyDefinitionCard
          key={`http-timeout-${index}`}
          title={t("PROPERTY_CARD_ORIGIN_HTTP_TIMEOUT_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ORIGIN_HTTP_TIMEOUT_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ORIGIN_HTTP_TIMEOUT_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.InputFieldType}
          fieldProps={{
            inputType: "number",
            min: 0,
            value: member.origin.httpTimeout,
            placeholder: t("PROPERTY_CARD_ORIGIN_HTTP_TIMEOUT_PLACEHOLDER"),
            onChange: (value) => {
              if (value < MINIMUM_HTTP_TIMEOUT) {
                dispatch(
                  handleAddConfigurationError(
                    ConfigurationErrorLevel.ERROR,
                    ConfigurationErrorType.PROPERTY_ORIGIN_HTTP_TIMEOUT_TOO_LOW,
                    {
                      section: PropertySection.ORIGIN,
                      subsection: PropertySubsection.ORIGIN,
                      index,
                    }
                  )
                );
              } else {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.data.section === PropertySection.ORIGIN &&
                        err.data.subsection === PropertySubsection.ORIGIN &&
                        err.data.index === index
                      )
                  )
                );
              }

              let httpTimeout: number | undefined;

              if (value !== undefined) {
                httpTimeout = parseFloat(parseFloat(value).toFixed(3));
              } else {
                httpTimeout = undefined;
              }

              onChange({
                members: originDefinition.members.map((m, i) =>
                  i === index
                    ? { ...member, origin: { ...member.origin, httpTimeout } }
                    : m
                ),
              });
            },
          }}
          divider
          errorMessage={
            errors.some(
              (err) =>
                err.type ===
                  ConfigurationErrorType.PROPERTY_ORIGIN_HTTP_TIMEOUT_TOO_LOW &&
                err.data.section === PropertySection.ORIGIN &&
                err.data.subsection === PropertySubsection.ORIGIN &&
                err.data.index === index
            )
              ? t("ERROR_ORIGIN_HTTP_TIMEOUT_TOO_LOW")
              : undefined
          }
        />
        <PropertyDefinitionCard
          key={`connection-timeout-${index}`}
          title={t("PROPERTY_CARD_ORIGIN_CONNECTION_TIMEOUT_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ORIGIN_CONNECTION_TIMEOUT_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ORIGIN_CONNECTION_TIMEOUT_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.InputFieldType}
          fieldProps={{
            inputType: "number",
            min: 0,
            value: member.origin.connectTimeout,
            placeholder: t(
              "PROPERTY_CARD_ORIGIN_CONNECTION_TIMEOUT_PLACEHOLDER"
            ),
            onChange: (value) => {
              if (value < 0.001) {
                dispatch(
                  handleAddConfigurationError(
                    ConfigurationErrorLevel.ERROR,
                    ConfigurationErrorType.PROPERTY_ORIGIN_CONNECTION_TIMEOUT_TOO_LOW,
                    {
                      section: PropertySection.ORIGIN,
                      subsection: PropertySubsection.ORIGIN,
                      index,
                    }
                  )
                );
              } else {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.subsection === PropertySubsection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
              }

              let connectTimeout: number | undefined;

              if (value !== undefined) {
                connectTimeout = parseFloat(parseFloat(value).toFixed(3));
              } else {
                connectTimeout = undefined;
              }

              onChange({
                members: originDefinition.members.map((m, i) =>
                  i === index
                    ? {
                        ...member,
                        origin: { ...member.origin, connectTimeout },
                      }
                    : m
                ),
              });
            },
          }}
          divider
          errorMessage={
            errors.some(
              (err) =>
                err.type ===
                  ConfigurationErrorType.PROPERTY_ORIGIN_CONNECTION_TIMEOUT_TOO_LOW &&
                err.data?.section === PropertySection.ORIGIN &&
                err.data?.subsection === PropertySubsection.ORIGIN &&
                err.data?.index === index
            )
              ? t("ERROR_ORIGIN_CONNECTION_TIMEOUT_TOO_LOW")
              : undefined
          }
        />
        <PropertyDefinitionCard
          key={`timeout-retries-${index}`}
          title={t("PROPERTY_CARD_ORIGIN_TIMEOUT_RETRIES_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ORIGIN_TIMEOUT_RETRIES_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ORIGIN_TIMEOUT_RETRIES_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.InputFieldType}
          fieldProps={{
            inputType: "number",
            min: 0,
            value: member.origin.timeoutRetries,
            placeholder: t("PROPERTY_CARD_ORIGIN_TIMEOUT_RETRIES_PLACEHOLDER"),
            onChange: (value) => {
              if (value < 0) {
                dispatch(
                  handleAddConfigurationError(
                    ConfigurationErrorLevel.ERROR,
                    ConfigurationErrorType.PROPERTY_ORIGIN_TIMEOUT_RETRIES_TOO_LOW,
                    {
                      section: PropertySection.ORIGIN,
                      index,
                    }
                  )
                );
              } else {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_TIMEOUT_RETRIES_TOO_LOW &&
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
              }

              onChange({
                members: originDefinition.members.map((m, i) =>
                  i === index
                    ? {
                        ...member,
                        origin: {
                          ...member.origin,
                          timeoutRetries: Number(value),
                        },
                      }
                    : m
                ),
              });
            },
            onBlur: (value) => {
              if (value >= 0) {
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_TIMEOUT_RETRIES_TOO_LOW &&
                        err.data?.section === PropertySection.ORIGIN &&
                        err.data?.index === index
                      )
                  )
                );
              }

              onChange({
                members: originDefinition.members.map((m, i) =>
                  i === index
                    ? {
                        ...member,
                        origin: {
                          ...member.origin,
                          timeoutRetries: Number(value) || 0,
                        },
                      }
                    : m
                ),
              });
            },
          }}
          errorMessage={
            errors.some(
              (err) =>
                err.type ===
                  ConfigurationErrorType.PROPERTY_ORIGIN_TIMEOUT_RETRIES_TOO_LOW &&
                err.data?.section === PropertySection.ORIGIN &&
                err.data.index === index
            )
              ? t("ERROR_ORIGIN_TIMEOUT_RETRIES_TOO_LOW")
              : undefined
          }
        />
      </Accordion>
    </Accordion>
  );
};
