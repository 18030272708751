import { FC } from "react";

import { WidgetsContainer } from "../../../../../components/atoms/WidgetsContainer/WidgetsContainer";
import { SingleNumber } from "../../../../../components/molecules/SingleNumber/SingleNumber";
import { SectionContainer } from "../../../report/styled";
import { useCurrentByMetro } from "../../../../../store/slices/rtm/hooks";

type ITotalMetrics = Record<"countries" | "mbPerSec" | "reqPerSec", number>;

export const TotalMetrics: FC = () => {
  const currentByMetro = useCurrentByMetro();

  const { countries, mbPerSec, reqPerSec } = (
    currentByMetro ?? []
  ).reduce<ITotalMetrics>(
    (totalMetrics, metro) => {
      totalMetrics.countries += 1;
      totalMetrics.mbPerSec += metro.mbPerSec;
      totalMetrics.reqPerSec += metro.reqPerSec;

      return totalMetrics;
    },
    {
      countries: 0,
      mbPerSec: 0,
      reqPerSec: 0,
    }
  );

  return (
    <SectionContainer>
      <WidgetsContainer>
        <SingleNumber title="Total Pop Locations" value={countries} />
        <SingleNumber title="Total Mb/sec" value={mbPerSec} />
        <SingleNumber title="Total Request/sec" value={reqPerSec} />
      </WidgetsContainer>
    </SectionContainer>
  );
};
