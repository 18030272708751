import { useLuaDefinitions } from "../../../store/slices/caching/hooks";
import { LuaCard, LuaType } from "./LuaCard";
import { FeatureComponent } from "./types";

export const Lua: FeatureComponent<LuaType> = ({ feature, dataTestId }) => {
  const luadefinitions = useLuaDefinitions();

  return (
    <>
      {feature.nameList.reduce((components, name) => {
        const def = luadefinitions.find((luaDef) => luaDef.name === name);

        if (def) {
          components.push(
            <LuaCard
              {...def}
              {...feature}
              index={components.length + 1}
              dataTestId={dataTestId}
            />
          );
        }

        return components;
      }, [] as JSX.Element[])}
    </>
  );
};
