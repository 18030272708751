import styled from "@emotion/styled";
import { animated, useSpring } from "react-spring";
import "react-toastify/dist/ReactToastify.css";

import { Toast } from "../../components/atoms/Toast/Toast";
import { Global } from "../../components/layers/Global/Global";
import { ErrorModal } from "../../components/molecules/ErrorModal/ErrorModal";
import { Feedback } from "../../components/organisms/Feedback/Feedback";
import { useHeaderContext } from "../../contexts/headerContext";
import { useThemeContext } from "../../contexts/themeContext";
import { AppRouter } from "./App.router";
import { Header } from "./header";

export const App: React.FC = () => {
  const { colorset } = useThemeContext();

  const appBackgroundColor = useSpring({
    backgroundColor: colorset.backgrounds.base,
    color: colorset.text.primary,
  });

  useHeaderContext();

  return (
    <AppContainer style={appBackgroundColor}>
      <Header />
      <Global>
        <AppRouter />
        <Feedback />
      </Global>
      <ErrorModal />
      <Toast
        enableMultiContainer
        colorset={colorset}
        autoClose={7500}
        position="bottom-right"
        limit={6}
      />
    </AppContainer>
  );
};

const AppContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
