export enum TypeDefinitions {
  GENERATED_RESPONSE = "generatedResponseDefinitions",
  HEADER = "headerDefinitions",
  LUA = "luaDefinitions",
  MATCH_LOGIC = "matchLogicDefinitions",
  ORIGIN = "originFillPolicyDefinitions",
  PROPERTY = "propertyDefinitions",
  SIMPLE_DEFINITION = "simpleListDefinitions",
  REPORTING_OVERRIDE = "reportingOverrideDefinitions",
  TOKEN = "tokenAuthenticationDefinitions",
}

export interface IDefinition {
  id?: string;
  type: TypeDefinitions;
  name: string;
  description?: string;
}
