import type { ReactElement } from "react";

import { HeadlessInputField } from "../../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { checkPositive } from "./helpers";
import type { FormProps } from "./types";

interface IntTTLProps extends Omit<FormProps, "unregister"> {
  errorMessage?: string;
}

export const IntTTL = ({
  disabled,
  errorMessage,
  maxWidth,
  register,
  t,
  tMisc,
}: IntTTLProps): ReactElement => (
  <PropertyDefinitionCard
    title={t("FEATURE_CARD_CCHO_ICP_TYPE_NUMBER_TITLE")}
    additionalInfoTitle={t(
      "FEATURE_CARD_CCHO_ICP_TYPE_NUMBER_DESCRIPTION_TITLE"
    )}
    additionalInfoContent={t(
      "FEATURE_CARD_CCHO_ICP_TYPE_NUMBER_DESCRIPTION_CONTENT"
    )}
    fieldType={FieldType.OtherType}
    fieldProps={{
      other: (
        <HeadlessInputField
          disabled={disabled}
          placeholder={t("FEATURE_CARD_CCHO_ICP_TYPE_NUMBER_PLACEHOLDER")}
          {...register("cacheControlHeaderOverride.int.cachePolicy", {
            required: tMisc("ERROR_REQUIRED_FIELD"),
            validate: checkPositive,
            valueAsNumber: true,
          })}
          data-testid="ccho-icp-type-number-input"
          type="number"
        />
      ),
    }}
    errorMessage={errorMessage}
    maxWidth={maxWidth}
    divider
    dataTestId="ccho-icp-type-number"
    asterisk
  />
);
