import { ReactElement } from "react";

import { Icon, Icons } from "../../atoms/Icon/Icon";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { HeadlessDropDown } from "../Dropdown/HeadlessDropdown";

interface FieldsSelectionDropdownProps<T> {
  selectedItems: IDropdownItem<T>[];
  onChange: (values: IDropdownItem<T>[]) => void;
  items: IDropdownItem<T>[];
}

export const FieldsSelectionDropdown = <T,>({
  onChange,
  selectedItems,
  items,
}: FieldsSelectionDropdownProps<T>): ReactElement => {
  return (
    <HeadlessDropDown<T>
      items={items}
      multiSelect
      selectedItems={selectedItems}
      setSelectedItems={onChange}
      button={(props) => (
        <Icon
          name={Icons.TRIPLE_DOT}
          onClick={props.onClick}
          size={{ width: 18, height: 12 }}
          color="secondary"
        />
      )}
      position="left"
    />
  );
};
