import { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Badge } from "../../../../components/atoms/Badge/Badge";
import { ReactComponent as ArrowSvg } from "../../../../assets/icons/long_arrow_left.svg";
import { Icon, Icons } from "../../../../components/atoms/Icon/Icon";
import { Protected } from "../../../../components/atoms/Protected/Protected";
import { UserRoles } from "../../../../models/permissions";
import { NamingModalContent } from "../../../../components/molecules/NamingModal/NamingModal";
import { Button } from "../../../../components/atoms/Button/Button";
import { Modal } from "../../../../components/atoms/Modal/Modal";
import { useCertificates } from "../../../../store/slices/caching/hooks";

interface ICertificateHeaderProps {
  submitCert: (isNew: boolean) => void;
  certificateName: string;
  onCertificateNameChange: (name: string) => void;
  isNewCert: boolean;
  hasUnsavedChanged: boolean;
  isNotCommited: boolean;
  handleNavBack: () => void;
}

export const CertificateHeader: React.FC<ICertificateHeaderProps> = ({
  submitCert,
  certificateName,
  onCertificateNameChange,
  isNewCert,
  hasUnsavedChanged,
  isNotCommited,
  handleNavBack,
}) => {
  const certificates = useCertificates();

  const [tCertificatePage] = useTranslation("certificatePage");
  const [tCertificatesPage] = useTranslation("certificatesPage");
  const [tMisc] = useTranslation("misc");

  const handleSubmitCert = () => {
    submitCert(isNewCert);
  };

  const handleRename = () => {
    setOpenNameModal(true);
  };

  const [openNameModal, setOpenNameModal] = useState(false);

  const checkErrors = useCallback(
    (value: string) => {
      const result: string[] = [];
      if (value.includes(" ")) {
        result.push(tCertificatesPage("NAME_MODAL_SPACE_ERROR"));
      }
      if (
        certificates &&
        certificates.some((cert) => cert.cert_name === value)
      ) {
        result.push(tCertificatesPage("NAME_MODAL_USED_NAME_ERROR"));
      }
      return result;
    },
    [certificates]
  );

  return (
    <CertificatePageHeaderContainer data-testid="certificate-page-header">
      <Header>
        <TitleContainer data-testid="certificate-name">
          <Arrow
            onClick={handleNavBack}
            data-testid="certificate-page-navback"
          />
          <label className="-text--h2">
            {certificateName ||
              tCertificatePage("CERTIFICATE_NAME_PLACEHOLDER")}
          </label>
          {isNewCert && (
            <Protected permissions={UserRoles.EDIT_CONFIG}>
              <EditIcon
                name={Icons.EDIT}
                onClick={handleRename}
                dataTestId="edit-certificate-name"
                size={14}
              />
            </Protected>
          )}
        </TitleContainer>
        <ButtonsContainer>
          {isNotCommited && (
            <Badge
              helpText={tMisc("UNSUBMITTED_CHANGES_DESCRIPTION")}
              label={tMisc("UNSUBMITTED_CHANGES_LABEL")}
              color="yellow40"
            />
          )}
          <Button
            onClick={handleSubmitCert}
            label={tCertificatePage("CERTIFICATE_SUBMIT_BUTTON")}
            dataTestId="certificate-page-commit-button"
          />
        </ButtonsContainer>
        <Modal
          open={openNameModal}
          customButton={<></>}
          onClose={() => {
            setOpenNameModal(false);
          }}
        >
          <NamingModalContent
            id="rename-certificate"
            title={tCertificatesPage("NAME_MODAL_TITLE")}
            description={tCertificatesPage("NAME_MODAL_DESCRIPTION")}
            inputPlaceholder={tCertificatesPage("NAME_MODAL_INPUT_PLACEHOLDER")}
            cancelButtonProps={{
              label: tMisc("CANCEL"),
            }}
            saveButtonProps={{
              label: tMisc("CONFIRM"),
              onClick: onCertificateNameChange,
              dataTestId: "rename-certificate-save",
            }}
            checkErrors={checkErrors}
          />
        </Modal>
      </Header>
    </CertificatePageHeaderContainer>
  );
};

const CertificatePageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: ${({ theme }) => `1rem ${theme.spacings.pageContainer}`};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Arrow = styled(ArrowSvg)`
  margin-right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  * {
    fill: ${({ theme }) => theme.icon.primary};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
`;

const EditIcon = styled(Icon)`
  margin-left: 16px;
  fill: ${({ theme }) => theme.icon.muted};
  cursor: pointer;
`;
