import styled from "@emotion/styled";
import { ReactElement } from "react";

import { Icon, Icons, IconProps } from "../Icon/Icon";

export const ArrowLeftIcon = (props: Omit<IconProps, "name">): ReactElement => (
  <ArrowLeftIconBase size={20} {...props} name={Icons.ARROW_LEFT} />
);

const ArrowLeftIconBase = styled(Icon)`
  margin-right: 15px;
  cursor: pointer;
  * {
    fill: ${({ theme }) => theme.icon.primary};
  }
`;
