import { FC } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AnchorWrapper } from "../../atoms/AnchorWrapper/AnchorWrapper";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { GeneratedResponseModal } from "../../molecules/GeneratedResponseModal/GeneratedResponseModal";
import { GeoBlockingModal } from "../../molecules/GeoBlockingModal/GeoBlockingModal";
import { HeaderDefinitionModal } from "../../molecules/HeaderDefinitionModal/HeaderDefinitionModal";
import { IpDefinitionModal } from "../../molecules/IpDefinitionModal/IpDefinitionModal";
import { LuaDefinitionModal } from "../../molecules/LuaDefinitionModal/LuaDefinitionModal";
import { OriginModal } from "../../molecules/OriginDefinitionModal/OriginDefinitionModal";
import { ReportingOverridesModal } from "../../molecules/ReportingOverridesModal/ReportingOverridesModal";
import { StringDefinitionModal } from "../../molecules/StringDefinitionModal/StringDefinitionModal";
import { TokenModal } from "../../molecules/TokenModal/TokenModal";
import { MenuItem } from "../../molecules/AnchorMenu/AnchorMenu";
import {
  CardsContainer,
  DefinitionListItem,
} from "../../molecules/DefinitionListItem/DefinitionListItem";
import { ExpandableContent } from "../../molecules/ExpandableContent/ExpandableContent";
import {
  handleCreateDefinition,
  handleDeleteDefinition,
  handleUpdateDefinition,
} from "../../../store/slices/caching/thunks";
import { useAppDispatch } from "../../../store/types";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import {
  ConfigurationDefinition,
  GeneratedResponseDefinitionType,
  HeaderDefinitionType,
  LuaDefinitionType,
  OriginDefinitionType,
  ReportingOverrideDefinitionType,
  SimpleDefinitionType,
  TokenDefinitionType,
} from "../../../store/slices/caching/types";

export const DefinitionList: FC<{
  menu: MenuItem;
}> = ({ menu }) => {
  const [
    geoRegionList,
    ipLists,
    stringLists,
    cannedResponse,
    header,
    luaScripts,
    originFillPolicies,
    reportingOverrides,
    tokenDefinitions,
  ] = menu.subItems!;

  const dispatch = useAppDispatch();

  const { t } = useTranslation("configurationDefinitionsPage");
  const selectedConfiguration = useSelectedConfiguration();
  const geoDefinitions = selectedConfiguration?.config?.simpleListDefinitions?.filter(
    (definition) => definition.listType === "geo"
  );

  const IPGroupDefinitions = selectedConfiguration?.config?.simpleListDefinitions?.filter(
    (definition) => definition.listType === "ip"
  );

  const stringDefintions = selectedConfiguration?.config?.simpleListDefinitions?.filter(
    (definition) => definition.listType === "string"
  );

  const generatedResponseDefinitions =
    selectedConfiguration?.config?.generatedResponseDefinitions;

  const headerDefinitions = selectedConfiguration?.config?.headerDefinitions;

  const luaDefinitions = selectedConfiguration?.config?.luaDefinitions;

  const originDefinitions =
    selectedConfiguration?.config?.originFillPolicyDefinitions;

  const reportingOverrideDefinitions =
    selectedConfiguration?.config?.reportingOverrideDefinitions;

  const tokenAuthenticationDefinitions =
    selectedConfiguration?.config?.tokenAuthenticationDefinitions;

  const handleDelete = (definition: ConfigurationDefinition) => {
    dispatch(handleDeleteDefinition(definition));
  };

  const handleUpdate = (definition: ConfigurationDefinition) => {
    dispatch(handleUpdateDefinition(definition));
  };

  const handleCreate = (definition: ConfigurationDefinition) => {
    dispatch(handleCreateDefinition(definition));
  };

  return (
    <AnchorWrapper ref={menu.ref} id={menu.id}>
      <Title>{menu.name}</Title>
      <ExpandableContent
        title={t("PAGE_GENERAL_DESCRIPTION_TITLE")}
        content={t("PAGE_GENERAL_DESCRIPTION_CONTENT")}
      />
      <CardsContainer>
        {selectedConfiguration && selectedConfiguration.config ? (
          <>
            <StyledAnchorWrapper
              id={geoRegionList.id}
              ref={geoRegionList.ref}
              key={geoRegionList.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_GEO_REGIONS_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_GEO_REGIONS_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_GEO_REGIONS_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <GeoBlockingModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={geoDefinitions}
                renderEditModal={(item) => (
                  <GeoBlockingModal
                    definition={item as SimpleDefinitionType}
                    onSubmit={handleUpdate}
                    parentConfig={selectedConfiguration.config!}
                  />
                )}
                dataTestId="geo-blocking"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={ipLists.id}
              ref={ipLists.ref}
              key={ipLists.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_IP_LIST_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_IP_LIST_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_IP_LIST_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <IpDefinitionModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={IPGroupDefinitions}
                renderEditModal={(item) => (
                  <IpDefinitionModal
                    definition={item as SimpleDefinitionType}
                    onSubmit={handleUpdate}
                    parentConfig={selectedConfiguration.config!}
                  />
                )}
                dataTestId="ip-list"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={stringLists.id}
              ref={stringLists.ref}
              key={stringLists.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_STRING_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_STRING_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_STRING_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <StringDefinitionModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={stringDefintions}
                renderEditModal={(item) => (
                  <StringDefinitionModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as SimpleDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                dataTestId="string-list"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={cannedResponse.id}
              ref={cannedResponse.ref}
              key={cannedResponse.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_GENERATED_RESPONSE_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_GENERATED_RESPONSE_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_GENERATED_RESPONSE_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <GeneratedResponseModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={generatedResponseDefinitions}
                renderEditModal={(item) => (
                  <GeneratedResponseModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as GeneratedResponseDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                dataTestId="generated-response"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={header.id}
              ref={header.ref}
              key={header.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_HEADER_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_HEADER_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_HEADER_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <HeaderDefinitionModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={headerDefinitions}
                renderEditModal={(item) => (
                  <HeaderDefinitionModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as HeaderDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                dataTestId="header"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={luaScripts.id}
              ref={luaScripts.ref}
              key={luaScripts.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_LUA_TITLE")}
                additionalInfoTitle={t("DEFINITION_CARD_LUA_DESCRIPTION_TITLE")}
                additionalInfoContent={t(
                  "DEFINITION_CARD_LUA_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => <></>}
                items={luaDefinitions}
                renderEditModal={(item) => (
                  <LuaDefinitionModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as LuaDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={originFillPolicies.id}
              ref={originFillPolicies.ref}
              key={originFillPolicies.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_ORIGIN_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_ORIGIN_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_ORIGIN_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <OriginModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={originDefinitions}
                renderEditModal={(item) => (
                  <OriginModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as OriginDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                dataTestId="origin"
                customDescription={(definition) => {
                  const description = selectedConfiguration?.config?.propertyDefinitions.reduce(
                    (acc, item) => {
                      if (item.originFillPolicy === definition.name) {
                        acc.push(item.name);
                      }
                      return acc;
                    },
                    [] as string[]
                  );
                  return description?.length ? description.join(", ") : "";
                }}
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={reportingOverrides.id}
              ref={reportingOverrides.ref}
              key={reportingOverrides.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_REPORTING_OVERRIDES_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_REPORTING_OVERRIDES_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_REPORTING_OVERRIDES_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <ReportingOverridesModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={reportingOverrideDefinitions}
                renderEditModal={(item) => (
                  <ReportingOverridesModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as ReportingOverrideDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                dataTestId="reporting-overrides"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
            <StyledAnchorWrapper
              id={tokenDefinitions.id}
              ref={tokenDefinitions.ref}
              key={tokenDefinitions.name}
            >
              <DefinitionListItem
                title={t("DEFINITION_CARD_TOKEN_TITLE")}
                additionalInfoTitle={t(
                  "DEFINITION_CARD_TOKEN_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "DEFINITION_CARD_TOKEN_DESCRIPTION_CONTENT"
                )}
                onDelete={handleDelete}
                renderAddModal={() => (
                  <TokenModal
                    parentConfig={selectedConfiguration.config!}
                    onSubmit={handleCreate}
                  />
                )}
                items={tokenAuthenticationDefinitions}
                renderEditModal={(item) => (
                  <TokenModal
                    parentConfig={selectedConfiguration.config!}
                    definition={item as TokenDefinitionType}
                    onSubmit={handleUpdate}
                  />
                )}
                dataTestId="token"
                parentConfig={selectedConfiguration.config}
              />
            </StyledAnchorWrapper>
          </>
        ) : (
          <EmptyDefinitions>{t("NO_DEFINITIONS_TEXT")}</EmptyDefinitions>
        )}
      </CardsContainer>
    </AnchorWrapper>
  );
};

const Title = styled.h3``;

const EmptyDefinitions = styled(CustomRemark)`
  text-align: center;
  margin-top: 16em;
`;

const StyledAnchorWrapper = styled(AnchorWrapper)`
  margin: 44px 16px 0 0;
  padding-bottom: 44px;
`;
