import styled from "@emotion/styled";

import { FlexBox } from "../FlexBox/FlexBox";
import { Icon, Icons } from "../Icon/Icon";

export interface UserTagProps {
  firstName: string;
  lastName: string;
  userPosition: string;
  onDelete: () => void;
}

export const UserTag = ({
  firstName,
  lastName,
  userPosition,
  onDelete,
}: UserTagProps): JSX.Element => (
  <TagContainer>
    <UserName>
      {firstName} {lastName}
    </UserName>
    <UserPosition>{userPosition}</UserPosition>
    <Icon name={Icons.CROSS} onClick={onDelete} color="secondary" size={12} />
  </TagContainer>
);

const TagContainer = styled(FlexBox)`
  justify-content: flex-start;
  padding: 8px;
  gap: 8px;
  background: ${({ theme }) => theme.backgrounds.highlightLight};
  border-radius: 4px;
`;

export const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

export const UserPosition = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text.secondary};
`;
