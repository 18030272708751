import { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/atoms/Button/Button";
import { IOption } from "../../../../components/molecules/SearchDropdown/SearchDropdown";
import { useConfigurations } from "../../../../store/slices/caching/hooks";
import {
  getConfigurationHistory,
  getConfigurationVersionDetailsToCompare,
} from "../../../../store/slices/caching/services";
import { ConfigurationType } from "../../../../store/slices/caching/types";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";
import {
  ComparisonModalContent,
  ConfigurationOpt,
  ModalFooter,
} from "../configuration_page/properties/property_page/CompareVersionModal";

export interface ComparisonModalProps {
  configuration: ConfigurationType;
  configurationOptions: ConfigurationOpt[];
  onCancelClick: () => void;
  setConfigToCompare: (configuration: ConfigurationType) => void;
}

export const ComparisonModal = ({
  configuration,
  configurationOptions,
  onCancelClick,
  setConfigToCompare,
}: ComparisonModalProps): ReactElement => {
  const [tMisc] = useTranslation("misc");
  const configurations = useConfigurations();
  const handleMediaPortalError = useDispatchAPIError();

  const mountedRef = useRef(false);

  const [loading, setLoading] = useState(false);
  const [latestVersions, setLatestVersions] = useState(
    configuration?.versions ?? []
  );
  const [selectedVersion, setSelectedVersion] = useState<IOption<number>>();
  const [selectedConfigurationOpt, setSelectedConfigurationOpt] = useState(
    configurationOptions.find(
      (configurationOption) => configurationOption.selected
    )
  );

  const getVersions = (c: ConfigurationType) => {
    setLoading(true);

    getConfigurationHistory(c)
      .then((res) => {
        if (mountedRef.current) {
          setLatestVersions(res);
        }
      })
      .catch(handleMediaPortalError)
      .finally(() => {
        if (mountedRef.current) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    mountedRef.current = true;
    getVersions(configuration);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <ComparisonModalContent
        disabled={loading}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedConfiguration={selectedConfigurationOpt}
        setSelectedConfiguration={setSelectedConfigurationOpt}
        options={latestVersions.reduce((options, version, index) => {
          if (version.versionId !== configuration.versionId) {
            options.push({
              index,
              text: `${version.versionId}`,
              value: version.versionId,
              selected: version.versionId === selectedVersion?.value,
            });
          }

          return options;
        }, [] as IOption<number>[])}
        configurationOptions={configurationOptions}
        onSelectedConfiguration={(configurationOption) => {
          setLoading(true);
          const selectedConfiguration = configurations.find(
            (c) => c.id === configurationOption.value
          )!;

          getConfigurationHistory(selectedConfiguration)
            .then(setLatestVersions)
            .catch(handleMediaPortalError)
            .finally(() => {
              setLoading(false);
            });
        }}
      />
      <ModalFooter>
        <Button label={tMisc("CANCEL")} onClick={onCancelClick} />
        <Button
          label={"Compare"}
          onClick={() => {
            setLoading(true);

            const configToGetDetails = configurations.find(
              (c) => c.id === selectedConfigurationOpt!.value
            )!;

            getConfigurationVersionDetailsToCompare(
              configToGetDetails,
              selectedVersion!.value
            )
              .then((_configuration) => {
                if (mountedRef.current) {
                  setConfigToCompare(_configuration);
                }
              })
              .catch(handleMediaPortalError)
              .finally(() => {
                if (mountedRef.current) {
                  setLoading(false);
                }
              });
          }}
          disabled={loading || !selectedVersion || !selectedConfigurationOpt}
        />
      </ModalFooter>
    </>
  );
};
