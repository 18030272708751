import type { ReactElement } from "react";
import type {
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import { CachePolicy } from "../../../../../../../../../models/configuration/definitions/matchlogic";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { isString } from "../../../../../../../../../utils/string";
import { ValidationErrors } from "../types";
import { checkPositive } from "./cchoFeatureBlock/helpers";
import { FeatureBlock } from "./FeatureBlock";
import { useInitOpen } from "./useInitOpen";

interface NegativeTTLFeatureBlockProps {
  negativeTTL: Exclude<IFeatures["negativeTTL"], undefined>;
  maxWidth: number;
  initIsOpen: boolean;
  errors: ValidationErrors;
  datatestId: string;
  register: UseFormRegister<IFeatures>;
  onDelete: () => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  unregister: UseFormUnregister<IFeatures>;
}

export const VALID_STRINGS = [CachePolicy.NO_CACHE, CachePolicy.NO_STORE];

export const NegativeTTLFeatureBlock = ({
  negativeTTL,
  maxWidth,
  initIsOpen,
  errors,
  datatestId,
  onDelete,
  handleCreateFeature,
  register,
  unregister,
}: NegativeTTLFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");

  const isViewMode = useIsViewMode();
  const isOpen = useInitOpen(initIsOpen);
  const nonTTLChecked = isString(negativeTTL.cachePolicy, VALID_STRINGS);

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_NEGATIVE_TTL_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_NEGATIVE_TTL_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_NEGATIVE_TTL_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_NEGATIVE_TTL_CP_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_NEGATIVE_TTL_CP_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_NEGATIVE_TTL_CP_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_NEGATIVE_TTL_CP_RADIO_NUMBER_LABEL"
                      ),
                      id: "negative-ttl-number",
                      dataTestId: "negative-ttl-number",
                      checked: !nonTTLChecked,
                      onChange: () => {
                        handleCreateFeature("negativeTTL.cachePolicy", "");
                      },
                    },
                    {
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_NEGATIVE_TTL_CP_RADIO_STRING_LABEL"
                      ),
                      id: "negative-ttl-string",
                      dataTestId: "negative-ttl-string",
                      checked: nonTTLChecked,
                      onChange: () => {
                        // "cachePolicy" registered as a number input field. So
                        // we need to unregister it before setting a string
                        // value.
                        unregister("negativeTTL.cachePolicy");
                        handleCreateFeature(
                          "negativeTTL.cachePolicy",
                          CachePolicy.NO_CACHE
                        );
                      },
                    },
                  ]}
                />
              ),
            }}
            divider
            dataTestId="negative-ttl-cache-policy"
          />

          {!nonTTLChecked ? (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_NEGATIVE_TTL_INT_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_NEGATIVE_TTL_INT_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_NEGATIVE_TTL_INT_DESCRIPTION_CONTENT"
              )}
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <HeadlessInputField
                    disabled={isViewMode}
                    placeholder={t("FEATURE_CARD_NEGATIVE_TTL_INT_PLACEHOLDER")}
                    {...register("negativeTTL.cachePolicy", {
                      required: tMisc("ERROR_REQUIRED_FIELD"),
                      validate: checkPositive,
                      valueAsNumber: true,
                    })}
                    data-testid="negative-ttl-cache-policy-int-input"
                    type="number"
                  />
                ),
              }}
              errorMessage={errors.negativeTTL?.cachePolicy?.message}
              maxWidth={maxWidth}
              dataTestId="negative-ttl-cache-policy-int"
              asterisk
            />
          ) : (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_NEGATIVE_TTL_STRING_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_NEGATIVE_TTL_STRING_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_NEGATIVE_TTL_STRING_DESCRIPTION_CONTENT"
              )}
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <RadioButtonsSelector
                    inline
                    headlessRadio={true}
                    radioButtonsProps={[
                      {
                        ...register("negativeTTL.cachePolicy"),
                        value: CachePolicy.NO_CACHE,
                        disabled: isViewMode,
                        label: t("CACHE_POLICY_STRING_NO_CACHE_LABEL"),
                        id: "negative-ttl-no-cache",
                        dataTestId: "negative-ttl-no-cache",
                      },
                      {
                        ...register("negativeTTL.cachePolicy"),
                        value: CachePolicy.NO_STORE,
                        disabled: isViewMode,
                        label: t("CACHE_POLICY_STRING_NO_STORE_LABEL"),
                        id: "negative-ttl-no-store",
                        dataTestId: "negative-ttl-no-store",
                      },
                    ]}
                  />
                ),
              }}
              dataTestId="negative-ttl-cache-policy-string"
            />
          )}
        </>
      }
      initIsOpen={isOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"negativeTTL"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="negative-ttl"
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};
