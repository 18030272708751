import { UserStatus } from "../users/users.interfaces";

export interface AccessGroupInfo {
  accessGroupId: number;
  parentAccessGroupId: number;
  parentAccessGroupName: string;
  domainAccessGroupId: number;
  name: string;
  busOrgId: string;
  childAccessGroupList: AccessGroupInfo[];
  description: string;
  modifiedDate: string;
  modifiedUser: string;
  createdDate: string;
  createdUser: unknown;
  serviceCount: number;
  userRole: string;
  direct: number;
  date_modifiedDate: number;
  date_createdDate: number;
}

export type AccessGroupMember = {
  userId: number;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  roleId: number;
  roleName: string;
  status: UserStatus;
  inherited: boolean;
  hide: boolean;
};

export enum Products {
  ITM = "ITM",
  OBJECT_STORAGE = "ObjectStorage",
  STORAGE = "STORAGE",
  CACHING = "CACHING",
  MESH_DELIVERY = "MeshDelivery",
}

export const ProductsLabel = new Map<Products, string>([
  [Products.CACHING, "Caching"],
  [Products.OBJECT_STORAGE, "Origin Storage"],
  [Products.ITM, "What is ITM?"],
  [Products.STORAGE, "Storage"],
  [Products.MESH_DELIVERY, "Mesh Delivery"],
]);

export interface CachingProperty {
  id: string;
  name: string;
  active: string;
}

export interface AccessGroupCreationData {
  desc: string;
  domain: number;
  name: string;
  parent: number;
}

export interface AccessGroupDeletionCheckData {
  apiKeys: {
    ownerName: string;
    name: string;
    ownerUserId: number;
    id: number;
  }[];
  users: number[];
}

export type AccessGroupProduct = {
  id: string;
  name: string;
  product: Products;
  type: Type;
  streamType: string;
  platform: string;
  scid: string;
  customerServiceName: string;
  selected: boolean;
  status: Status;
  childServiceList: AccessGroupProduct[] | null;
  enabledFeatureList: null;
};

export enum Status {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum Type {
  NI = "NI",
  SCID = "SCID",
}

export interface ExternalUserPayload {
  email: string;
  roleId: number;
  mailMessage: string;
}

export interface InternalUserPayload {
  ldapUsername: string;
  roleId: number;
  mailMessage: string;
}

export interface AccessGroupRole {
  id: number;
  name: string;
  description: string;
  permissionList: string[];
}

export interface InternalUserSearchResult {
  email: string;
  department: string;
  title: string;
  id: string;
  firstName: string;
  lastName: string;
  location: string;
  base: string;
  active: boolean;
}
