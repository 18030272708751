import { IFeatures } from "../../../models/configuration/definitions";
import { ICustomLogDataLiteral } from "../../../models/configuration/definitions/matchlogic";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type CustomLogDataType = Exclude<IFeatures["customLogData"], undefined>;

type CustomLogDataComponent = FeatureComponent<CustomLogDataType>;

const isLiteralString = (
  customLogData: CustomLogDataType[number]
): customLogData is ICustomLogDataLiteral =>
  Object.prototype.hasOwnProperty.call(customLogData, "literalString");

export const CustomLogData: CustomLogDataComponent = ({
  feature,
  tMatchRulesPage,
  tPropertyPage,
  dataTestId,
}) => (
  <>
    {feature.map((customLogData, i) => {
      const key = `${customLogData.keyName}-${i}`;

      return (
        <DetailedFeatureCardRow key={key}>
          <DetailedFeatureCardBlock
            title={tPropertyPage("FEATURE_CARD_CLD_KEY_TITLE")}
            dataTestId={`${dataTestId}-cld-key-${i}`}
          >
            {customLogData.keyName}
          </DetailedFeatureCardBlock>

          <DetailedFeatureCardBlock
            title={tPropertyPage("FEATURE_CARD_CLD_DATA_TITLE")}
            dataTestId={`${dataTestId}-cld-data-${i}`}
          >
            {tMatchRulesPage(
              isLiteralString(customLogData)
                ? "FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_RADIO_2_LABEL"
                : "FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_RADIO_1_LABEL"
            )}
          </DetailedFeatureCardBlock>

          <DetailedFeatureCardBlock
            title={tMatchRulesPage(
              isLiteralString(customLogData)
                ? "FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_RADIO_2_LABEL"
                : "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_TITLE"
            )}
            dataTestId={`${dataTestId}-cld-expression-${i}`}
          >
            {isLiteralString(customLogData)
              ? customLogData.literalString
              : customLogData.variableName}
          </DetailedFeatureCardBlock>
        </DetailedFeatureCardRow>
      );
    })}
  </>
);
