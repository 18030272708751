import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { isDefinitionNameValid } from "../../../utils/string";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  AddButton,
  AddButtonContainer,
  ErrorContainer,
  Field,
  FieldInput,
  FieldName,
  Remove,
  Table,
  TableBody,
  TableCell,
} from "../DefinitionModal/DefinitionModal.styled";
import { getDuplicateElements } from "../DefinitionModal/helpers";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { SimpleDefinitionType } from "../../../store/slices/caching/types";
import { createSimpleDefinition } from "../../../store/slices/caching/helpers/simple-definition/createSimpleDefinition";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";
import { getSimpleReferences } from "../../../store/slices/caching/helpers/simple-definition/getSimpleReferences";

type StringDefinitionModalProps = IDefaultDefinitionModalProps<SimpleDefinitionType>;

const getDefinitionElements: (
  definition: SimpleDefinitionType | undefined
) => string[] = (definition) =>
  definition?.elements?.[0] ? definition.elements : [""];

export const StringDefinitionModal = ({
  definition,
  parentConfig,
  onSubmit,
}: StringDefinitionModalProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationDefinitionsPage");

  const [name, setName] = useState(definition?.name ?? "");
  const [description, setDescription] = useState(
    definition?._schemaDescription || ""
  );
  const [elements, setElements] = useState<string[]>(
    getDefinitionElements(definition)
  );
  const [error, setError] = useState<string>("");

  const handleChange = (newString: string, index: number) => {
    setElements((_elements) =>
      _elements.map((str, i) => (i === index ? newString : str))
    );
  };

  const addString = () => {
    setElements([...elements, ""]);
  };

  const removeString = (index: number) => {
    setElements((_elements) => _elements.filter((_, i) => i !== index));
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.SIMPLE_DEFINITION]
      )
    ) {
      setError(t("ERROR_DEFINITION_STRING_ALREADY_EXISTS", { name }));
      return false;
    }

    if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    if (getDuplicateElements(elements).length > 0) {
      setError(t("ERROR_DEFINITION_DUPLICATE_STRINGS"));
      return false;
    }

    if (elements.every((element) => element.length > 0)) {
      return true;
    }

    setError(t("ERROR_DEFINITION_STRINGS_ARE_INVALID"));
    return false;
  };

  const initState = () => {
    setError("");
    setName(definition?.name ?? "");
    setDescription(definition?._schemaDescription || "");
    setElements(getDefinitionElements(definition));
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="stringdefinition-modal"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
          dataTestId="string-list-modal-header"
        />
      }
      references={
        definition ? getSimpleReferences(definition, parentConfig) : 0
      }
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        setError("");

        if (definition) {
          onSubmit({
            ...definition,
            name,
            _schemaDescription: description,
            elements: elements.filter((element) => element.length > 0),
          });
        } else {
          if (selectedConfiguration && selectedConfiguration.config) {
            onSubmit(
              createSimpleDefinition(name, {
                description,
                elements,
                listType: "string",
              })
            );
          }
        }

        initState();
      }}
      size="auto"
      dataTestId="string-list"
    >
      <Table>
        <TableBody>
          <Field>
            <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>

            <FieldInput
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
              data-testid="string-list-modal-definition-name-input"
            />
          </Field>

          <Field>
            <FieldName data-testid="string-list-modal-label">
              {t("EDIT_DEFINITION_STRING_LIST_LABEL", {
                count: elements.length,
              })}
            </FieldName>
            <TableCell bottomPadding="thin">
              <Table>
                <TableBody>
                  {elements.map((str, i) => (
                    <Field key={i}>
                      <TableCell bottomPadding="thin">
                        <FieldInput
                          placeholder="string"
                          value={str}
                          onChange={(event) =>
                            handleChange(event.target.value, i)
                          }
                          data-testid={`string-list-string-field-${i}`}
                        />
                      </TableCell>
                      {i > 0 && (
                        <TableCell centered bottomPadding="thin">
                          <Remove
                            onClick={() => removeString(i)}
                            data-testid={`string-list-string-field-delete-${i}`}
                          />
                        </TableCell>
                      )}
                    </Field>
                  ))}
                </TableBody>
              </Table>
              <AddButtonContainer>
                <AddButton
                  onClick={() => addString()}
                  data-testid="string-list-add-string-button"
                >
                  {t("ADD_STRING_BUTTON")}
                </AddButton>
              </AddButtonContainer>
            </TableCell>
          </Field>
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_STRING_DESCRIPTION_LABEL")}
            </FieldName>
            <FieldInput
              placeholder={t("EDIT_DEFINITION_STRING_DESCRIPTION_PLACEHOLDER")}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              data-testid="string-list-modal-description-field"
            />
          </Field>
        </TableBody>
      </Table>
      <ErrorContainer>
        {error.length > 0 && (
          <NotificationCard
            dataTestId="error-string-list-modal"
            theme="error"
            title={error}
          />
        )}
      </ErrorContainer>
    </DefinitionModal>
  );
};
