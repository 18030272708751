import { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { DetailsPage } from "./details_page";
import { CertificatesPage } from "./certificates_page";
import { EditPage } from "./edit_page";

export const CertificatesRouter: FC = () => (
  <Switch>
    <Route path={"/cmv3/certificates/add"}>
      <EditPage />
    </Route>
    <Route path={"/cmv3/certificates/:cert_name/edit"}>
      <EditPage />
    </Route>
    <Route path={"/cmv3/certificates/:cert_name"}>
      <DetailsPage />
    </Route>
    <Route path={"/cmv3/certificates"}>
      <CertificatesPage />
    </Route>
  </Switch>
);
