import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HeaderSlice } from "./types";

const initialState: HeaderSlice = {
  hide: false,
  deafaultHeaderHeight: 100,
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHide: (state, action: PayloadAction<HeaderSlice["hide"]>) => {
      state.hide = action.payload;
    },
  },
});

export const { setHide } = headerSlice.actions;

export default headerSlice.reducer;
