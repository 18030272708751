import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { PropertyDefinitionBlock } from "../../../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import { ColorSet } from "../../../../../../../../../contexts/themeContext";

export interface IFeatureBlock {
  title: string;
  fields: JSX.Element;
  badgeLabel?: string;
  badgeColor?: keyof ColorSet["colors"];
  initIsOpen?: boolean;
  hasErrors?: boolean;
  onDelete?: () => void;
  customDeleteButton?: JSX.Element;
  dataTestId?: string;
  additionalInfoTitle?: string;
  additionalInfoContent?: string;
  featureType?: FeatureTypes;
}

export const FeatureBlock: React.FC<IFeatureBlock> = ({
  title,
  fields,
  badgeLabel,
  badgeColor,
  initIsOpen,
  hasErrors,
  onDelete,
  customDeleteButton,
  dataTestId,
  additionalInfoTitle,
  additionalInfoContent,
  featureType,
}) => (
  <PropertyDefinitionBlock
    title={title}
    badgeLabel={badgeLabel}
    badgeColor={badgeColor}
    propertyCards={fields}
    alwaysOpen={false}
    onDelete={onDelete}
    customDeleteButton={customDeleteButton}
    dataTestId={dataTestId}
    initIsOpen={initIsOpen}
    displayArrow={!hasErrors}
    additionalInfoTitle={additionalInfoTitle}
    additionalInfoContent={additionalInfoContent}
    featureType={featureType}
  />
);
