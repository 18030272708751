import { FC } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import EmptyPageDarkSrc from "../../../assets/images/empty_configuration_page_dark.png";
import EmptyPageLightSrc from "../../../assets/images/empty_configuration_page_light.png";
import { EmptyPage } from "../../../components/atoms/EmptyPage/EmptyPage";
import { GuidanceModal } from "../../../components/organisms/GuidanceModal/GuidanceModal";
import { ProductionData } from "../../../components/organisms/ProductionData/ProductionData";
import { ProductionSlots } from "../../../components/organisms/ProductionSlots/ProductionSlots";
import { ModuleContent } from "../../../components/atoms/Module/Module";
import {
  useDispatchProduction,
  useProduction,
} from "../../../store/slices/caching/hooks";

const PLACEHOLDER_IMAGES = { light: EmptyPageLightSrc, dark: EmptyPageDarkSrc };

const SCROLL_ID = "production";

export const Production: FC = () => {
  const { isMigration, productionData, migrationData } = useProduction();
  const dispatchProduction = useDispatchProduction();
  const { t } = useTranslation("productionPage");

  return productionData !== undefined || migrationData !== undefined ? (
    <ModuleContent id={SCROLL_ID}>
      <ProductionContainer>
        {(isMigration || productionData?.slots.current !== undefined) && (
          <SlotsContainer>
            <ProductionSlots
              isMigration={isMigration}
              current={isMigration ? undefined : productionData?.slots.current}
              candidate={
                isMigration
                  ? migrationData?.slots.candidate
                  : productionData?.slots.candidate
              }
              onUpdateProductionData={dispatchProduction}
              deploymentState={
                isMigration
                  ? migrationData!.deploymentStatus
                  : productionData!.deploymentStatus
              }
            />
          </SlotsContainer>
        )}
        <ProductionData />
      </ProductionContainer>
    </ModuleContent>
  ) : (
    <EmptyPage
      images={PLACEHOLDER_IMAGES}
      title={t("EMPTY_PAGE_TITLE")}
      content={t("EMPTY_PAGE_DESCRIPTION")}
      dataTestId="production-empty-page"
    >
      <GuidanceModal
        buttonLabel={t("GUIDANCE_BUTTON_LABEL")}
        title={t("GUIDANCE_MODAL_TITLE")}
        content={t("GUIDANCE_MODAL_CONTENT")}
      />
    </EmptyPage>
  );
};

const ProductionContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer}`};
`;

const SlotsContainer = styled.div`
  margin-bottom: 32px;
`;
