import { ReactElement } from "react";

import { DetailedFeatureCardColContent } from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";

export const renderColContent = <Content extends string | number>(
  content: Content
): ReactElement => (
  <DetailedFeatureCardColContent key={content}>
    {content}
  </DetailedFeatureCardColContent>
);
