import { FC, useMemo } from "react";

import {
  MapSiteFieldsToLabel,
  Metrics,
  SiteByMetro,
  SiteByProperty,
} from "../../../modules/rtm/models/models";
import {
  useCurrentByMetro,
  useCurrentByProperty,
  useCurrentDataStatus,
  useCurrentPropertyStatus,
} from "../../../store/slices/rtm/hooks";
import { SetupStatus } from "../../../store/slices/rtm/types";
import { WidgetLayout } from "../../molecules/WidgetLayout/WidgetLayout";
import { LinkCTA } from "../../molecules/LinkCTA/LinkCTA";
import { BorderlessTable } from "../../atoms/BorderlessTable/BorderlessTable";
import {
  SortedTableBodyRow,
  SortedTableHeaderRow,
} from "../../atoms/SortedTableRows/SortedTableRows";
import { Loader } from "../../atoms/Loader/Loader";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";

export interface SortedTableWidgetProps {
  type: "region" | "property";
  metric: Metrics;
  title: string;
  dataTestId?: string;
}

interface SortedTableItem {
  name: string;
  value: number;
}

const formatCurrentByProperty = (
  data: SiteByProperty[] | undefined,
  metric: Metrics
) => {
  if (!data) {
    return [];
  }
  return data
    .map((site) => ({
      name: site.propertyName,
      value: site[metric],
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);
};

const formatCurrentByMetro = (
  data: SiteByMetro[] | undefined,
  metric: Metrics
) => {
  if (!data) {
    return [];
  }
  return data
    .map((site) => ({
      name: site.metro,
      value: site[metric],
    }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 5);
};

export const SortedTableWidget: FC<SortedTableWidgetProps> = ({
  title,
  metric,
  type,
  dataTestId,
}) => {
  const currentByMetro = useCurrentByMetro();
  const currentByProperty = useCurrentByProperty();

  const propertyStatus = useCurrentPropertyStatus();

  const metroStatus = useCurrentDataStatus();

  const shouldRenderLoader =
    type === "property"
      ? propertyStatus === SetupStatus.SETUP
      : metroStatus === SetupStatus.SETUP;

  const items = useMemo<SortedTableItem[]>(() => {
    if (type === "property") {
      return formatCurrentByProperty(currentByProperty, metric);
    } else {
      return formatCurrentByMetro(currentByMetro, metric);
    }
  }, [currentByMetro, currentByProperty, type, metric]);

  return (
    <WidgetLayout
      title={title}
      cta={
        <LinkCTA
          to={type === "property" ? "/rtm/properties" : "/rtm/locations"}
          message="View details"
        />
      }
    >
      {shouldRenderLoader ? (
        <FlexBox>
          <Loader size={40} />
        </FlexBox>
      ) : (
        <BorderlessTable>
          <thead>
            <SortedTableHeaderRow
              entry={type === "property" ? "Property" : "Location"}
              metric={MapSiteFieldsToLabel.get(metric) || "No entry"}
            />
          </thead>
          <tbody>
            {items.map((item, index) => (
              <SortedTableBodyRow
                key={item.name}
                {...item}
                dataTestId={`${dataTestId}-row-${index}`}
              />
            ))}
          </tbody>
        </BorderlessTable>
      )}
    </WidgetLayout>
  );
};
