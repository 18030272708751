import type { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import type { FeatureComponent } from "./types";

type CacheKeyComponent = FeatureComponent<
  Exclude<IFeatures["cacheKey"], undefined>
>;

export const CacheKey: CacheKeyComponent = ({ feature, tPropertyPage }) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_CACHE_KEY_URI_TITLE")}
    >
      {feature}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
