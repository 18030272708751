import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Reducer, useReducer } from "react";

import { HorizontalFieldName } from "../../../../../../components/atoms/HorizontalFieldName/HorizontalFieldName";
import { Checkbox } from "../../../../../../components/molecules/Checkbox/Checkbox";
import { Dropdown } from "../../../../../../components/organisms/Dropdown/Dropdown";
import { Button } from "../../../../../../components/atoms/Button/Button";
import {
  HorizontalFormBody,
  HorizontalFormContainer,
} from "../../../../../../components/atoms/HorizontalFormLayout/HorizontalFormLayout";
import {
  HorizontalInputField,
  HorizontalField,
} from "../../../../../../components/molecules/HorizontalInputField/HorizontalInputField";
import {
  FeatureFlagHeader,
  FeatureFlagModalChildProps,
  FeatureFlagsStatus,
} from "../../../models/models";
import { FlexBox } from "../../../../../../components/atoms/FlexBox/FlexBox";
import {
  featureFlagsReducer,
  FeatureFlagsReducerAction,
  defaultFeatureFlagHeader,
} from "./FeatureFlagsReducer";

const dropdownItems = [
  {
    label: FeatureFlagsStatus.ENABLED,
    value: FeatureFlagsStatus.ENABLED,
    default: false,
    dataTestId: "status-selector-0",
  },
  {
    label: FeatureFlagsStatus.RESTRICTED,
    value: FeatureFlagsStatus.RESTRICTED,
    default: false,
    dataTestId: "status-selector-1",
  },
  {
    label: FeatureFlagsStatus.DISABLED,
    value: FeatureFlagsStatus.DISABLED,
    default: false,
    dataTestId: "status-selector-2",
  },
];

export const FeatureFlagsModalContent: React.FC<FeatureFlagModalChildProps> = ({
  closeModal,
  handleSaveFeatureFlag,
  isUpdate,
  featureFlagsHeader,
  dataTestId,
}) => {
  const { t } = useTranslation("featureFlagsModalPage");

  const [_featureFlagHeader, dispatchFeatureFlags] = useReducer<
    Reducer<FeatureFlagHeader, FeatureFlagsReducerAction>
  >(featureFlagsReducer, featureFlagsHeader ?? defaultFeatureFlagHeader);

  const handleConfirm = () => {
    handleSaveFeatureFlag(_featureFlagHeader, isUpdate, _featureFlagHeader.id);
    closeModal && closeModal();
  };

  return (
    <NewPropertyModalContainer>
      <Title>
        {isUpdate
          ? t("TITLE_UPDATE_FEATURE_FLAG")
          : t("TITLE_CREATE_FEATURE_FLAG")}
      </Title>
      <HorizontalFormContainer>
        <HorizontalFormBody>
          <HorizontalInputField
            placeholder={t("NAME_FEATURE_FLAGS_FORM_INPUT_PLACEHOLDER")}
            value={_featureFlagHeader.name}
            label="Feature Flag Name"
            onChange={(event) => {
              dispatchFeatureFlags({
                type: "UPDATE_NAME",
                payload: { name: event.target.value },
              });
            }}
            dataTestId={`${dataTestId}-name`}
            required
          />
          <HorizontalField>
            <HorizontalFieldName>{t("RESTRICTIONS_LIST")}</HorizontalFieldName>
          </HorizontalField>
          {isUpdate && (
            <HorizontalField>
              <HorizontalFieldName>{t("STATUS_LIST")}</HorizontalFieldName>
              <DropdownContainer>
                <Dropdown
                  items={dropdownItems.map((statusDropdownItem) => ({
                    ...statusDropdownItem,
                    deafult:
                      statusDropdownItem.value === _featureFlagHeader.status,
                  }))}
                  id="status-selector"
                  placeholder={t("STATUS_FEATURE_FLAGS_FORM_INPUT_PLACEHOLDER")}
                  onSelect={(item) =>
                    dispatchFeatureFlags({
                      type: "UPDATE_STATUS",
                      payload: { status: item.value },
                    })
                  }
                  dataTestId={`${dataTestId}-status-selector`}
                />
              </DropdownContainer>
            </HorizontalField>
          )}
          {_featureFlagHeader.status !== "disabled" && (
            <>
              <HorizontalInputField
                placeholder={t("USERS_FEATURE_FLAGS_FORM_INPUT_PLACEHOLDER")}
                value={_featureFlagHeader.enabledFor?.users?.toString()}
                label="Users"
                onChange={(event) => {
                  dispatchFeatureFlags({
                    type: "UPDATE_USERS",
                    payload: { users: event.target.value },
                  });
                }}
                dataTestId={`${dataTestId}-users`}
              />
              <HorizontalInputField
                placeholder={t(
                  "ACCESSGROUP_FEATURE_FLAGS_FORM_INPUT_PLACEHOLDER"
                )}
                value={_featureFlagHeader.enabledFor?.accessGroups?.toString()}
                label="AccessGroup"
                onChange={(event) => {
                  dispatchFeatureFlags({
                    type: "UPDATE_ACCESS_GROUP",
                    payload: { accessGroups: event.target.value },
                  });
                }}
                dataTestId={`${dataTestId}-access-group`}
              />
              <HorizontalInputField
                placeholder={t("APIKEYS_FEATURE_FLAGS_FORM_INPUT_PLACEHOLDER")}
                value={_featureFlagHeader.enabledFor?.apiKeys?.toString()}
                label="API Keys"
                onChange={(event) => {
                  dispatchFeatureFlags({
                    type: "UPDATE_API_KEYS",
                    payload: { apiKeys: event.target.value },
                  });
                }}
                dataTestId={`${dataTestId}-api-keys`}
              />
              <HorizontalField>
                <HorizontalFieldName>{t("INTERNALS_ONLY")}</HorizontalFieldName>
                <Checkbox
                  id="Internals_Only"
                  value={_featureFlagHeader.enabledFor?.internalsOnly}
                  onClick={() => {}}
                  onChange={(_) => {
                    dispatchFeatureFlags({
                      type: "UPDATE_INTERNALS_ONLY",
                      payload: {
                        internalsOnly: !_featureFlagHeader.enabledFor
                          ?.internalsOnly,
                      },
                    });
                  }}
                  checked={_featureFlagHeader.enabledFor?.internalsOnly}
                  label={t(
                    "INTERNALS_ONLY_FEATURE_FLAGS_FORM_INPUT_PLACEHOLDER"
                  )}
                  dataTestId={`${dataTestId}-internals-only`}
                />
              </HorizontalField>
            </>
          )}
        </HorizontalFormBody>
      </HorizontalFormContainer>
      <Buttons>
        <Button
          backgroundColor="mutedDark"
          textColor="primaryAlt"
          label={t("FEATURE_FLAGS_FORM_CANCEL_BUTTON")}
          onClick={() => closeModal && closeModal()}
          dataTestId={`${dataTestId}-cancel`}
        />
        <ButtonContainer>
          <Button
            label={t("FEATURE_FLAGS_FORM_CONFIRM_BUTTON")}
            disabled={!_featureFlagHeader.name.length}
            onClick={handleConfirm}
            dataTestId={`${dataTestId}-confirm`}
          />
        </ButtonContainer>
      </Buttons>
    </NewPropertyModalContainer>
  );
};

const NewPropertyModalContainer = styled(FlexBox)`
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
`;

const Title = styled.h3`
  margin-bottom: 50px !important;
  text-align: center;
`;

const Buttons = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const DropdownContainer = styled.div`
  margin-left: -8px;
  margin-top: -10px;
`;
