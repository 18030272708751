import styled from "@emotion/styled";
import { ReactNode, InputHTMLAttributes } from "react";

import { HorizontalFieldName } from "../../atoms/HorizontalFieldName/HorizontalFieldName";

interface HorizontalInputFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode;
  className?: string;
  dataTestId?: string;
}
export const HorizontalInputField = ({
  label,
  required,
  className,
  dataTestId,
  ...inputProps
}: HorizontalInputFieldProps) => {
  return (
    <HorizontalField>
      <HorizontalFieldName required={required}>{label}</HorizontalFieldName>
      <HorizontalFieldInput
        {...inputProps}
        required={required}
        className={`chi-input ${className}`}
        data-testid={`${dataTestId}-field-input`}
      />
    </HorizontalField>
  );
};

export const HorizontalField = styled.div`
  display: table-row;
  padding-bottom: 8px;
`;

export const HorizontalFieldInput = styled.input`
  display: table-cell;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;
`;
