import styled from "@emotion/styled";
import { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { HintBox } from "../../../../../../../components/molecules/HintBox/HintBox";
import { IMatchRule } from "../../../../../../../models/configuration/definitions";
import {
  IValidateExpressionResponseObject,
  MatchBlocks,
  validateExpression,
} from "../../../../../../../models/configuration/definitions/matchlogic";
import { CMv3APIError, APIError } from "../../../../../../../models/error";
import { useDispatchAPIError } from "../../../../../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../../../../../store/slices/subscriber/hooks";
import { useIsViewMode } from "../../../../../../../store/slices/permissions/hooks";
import { MatchRuleEditor } from "./MatchRuleEditor";

interface IConditionsEditorProps {
  matchRule: IMatchRule;
  onCodeChange: (modifiedMatchRule: IMatchRule) => void;
  matchBlock: MatchBlocks;
  handleSave: () => void;
}

export const ConditionsEditor: FC<IConditionsEditorProps> = ({
  matchRule,
  onCodeChange,
  matchBlock,
  handleSave,
}) => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const subscriberId = useSubscriberId();
  const isViewMode = useIsViewMode();
  const handleMediaPortalError = useDispatchAPIError();
  const code = matchRule.expression || "";
  const [error, setError] = useState<IValidateExpressionResponseObject | null>(
    null
  );

  const onChange = (value: string) => {
    onCodeChange({ ...matchRule, expression: value });
  };

  const save = useCallback(async () => {
    try {
      const response = await validateExpression(subscriberId, matchBlock, code);
      if (response.valid) {
        setError(null);
        handleSave();
        toast.success(t("EXPRESSION_SAVED_LOCALLY"));
      } else {
        setError(response);
      }
    } catch (err) {
      handleMediaPortalError(err as APIError<CMv3APIError>);
    }
  }, [code, matchRule]);

  return (
    <>
      <MatchRuleEditor
        setCode={onChange}
        code={code}
        save={save}
        dataTestId="match-rule-editor"
      />
      <Hints>
        {error ? (
          <HintBox
            title={t("EDITOR_ERROR_TITLE")}
            body={t("EDITOR_ERROR_DESCRIPTION")}
            error={error.error}
          />
        ) : null}
        {!isViewMode ? (
          <>
            <HintBox
              title={t("EDITOR_HINTBOX_1_TITLE")}
              body={t("EDITOR_HINTBOX_1_DESCRIPTION")}
            />
          </>
        ) : null}
      </Hints>
    </>
  );
};

const Hints = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 20px;
  }
`;
