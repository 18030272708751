import styled from "@emotion/styled";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import {
  Dropdown,
  DropdownPosition,
} from "../../../../../components/organisms/Dropdown/Dropdown";
import {
  UserManagementUserData,
  UserStatus,
} from "../../../../../models/user-management/users/users.interfaces";
import { resetUser } from "../../../../../store/slices/user-management-users/slice";
import { useAppDispatch } from "../../../../../store/types";
import { ActionModal, ActionTypes } from "./modals/ActionModal";

enum AdditionalDropdownActions {
  SHOW_DETAILS = "show_details",
}

interface UserActionDropdownProps {
  userInfo: UserManagementUserData;
  canShowDetails?: boolean;
}

export const UserActionDropdown = ({
  userInfo,
  canShowDetails,
}: UserActionDropdownProps): ReactElement => {
  const [action, setAction] = useState<ActionTypes>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("usersPage");
  const history = useHistory();

  const dropdownItems: {
    label: string;
    value: ActionTypes | AdditionalDropdownActions;
  }[] = canShowDetails
    ? [
        {
          label: t("USERS_TABLE_ACTIONS_SHOW_DETAILS"),
          value: AdditionalDropdownActions.SHOW_DETAILS,
        },
      ]
    : [];
  switch (userInfo.status) {
    case UserStatus.ACTIVE:
    case UserStatus.PENDING:
      dropdownItems.push({
        label: t("USER_PAGE_ACTIONS_DISABLE"),
        value: ActionTypes.DISABLE,
      });
      break;
    case UserStatus.DISABLED:
    case UserStatus.EXPIRED:
    case UserStatus.INACTIVE:
      dropdownItems.push({
        label: t("USER_PAGE_ACTIONS_ENABLE"),
        value: ActionTypes.ENABLE,
      });
  }

  dropdownItems.push(
    {
      label: t("USER_PAGE_ACTIONS_RESET_PASSWORD"),
      value: ActionTypes.PASSWORD_RESET,
    },
    { label: t("USER_PAGE_ACTIONS_DELETE"), value: ActionTypes.DELETE }
  );

  return (
    <>
      <Dropdown
        id="user-actions-dropdown"
        placeholder=""
        position={DropdownPosition.LEFT}
        customButton={
          <IconContainer>
            <Icon
              name={Icons.TRIPLE_DOT}
              size={{ width: 18, height: 5 }}
              color={canShowDetails ? "secondary" : "highlight"}
            />
          </IconContainer>
        }
        onSelect={(item) => {
          if (item.value === AdditionalDropdownActions.SHOW_DETAILS) {
            dispatch(resetUser());
            history.push(`/userManagement/users/${userInfo.userId}`);
          } else {
            setAction(item.value);
          }
        }}
        items={dropdownItems}
      />
      <Modal
        customButton={<></>}
        open={action !== undefined}
        onClose={() => setAction(undefined)}
      >
        <ActionModal action={action} userInfo={userInfo} />
      </Modal>
    </>
  );
};

const IconContainer = styled.div`
  cursor: pointer;
`;
