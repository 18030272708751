import * as Sentry from "@sentry/react";

import { Status } from "../../../@types/status";
import { Configuration } from "../../../models/configuration";

import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../models/configuration/errors";
import {
  IActiveConfigurationsResponse,
  IProductionMigrationData,
} from "../../../models/configuration/production";
import { CMv3APIError, APIError } from "../../../models/error";
import { SubscriberDataNotFoundError } from "../../../models/error/SubscriberDataNotFoundError";
import { getProperties } from "../../../models/metadata/properties";
import { AppDispatch, AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import { cloneConfiguration } from "./helpers/cloneConfiguration";
import { generateDefaultConfiguration } from "./helpers/generateDefaultConfiguration";
import { handleLocalStorageConfiguration } from "./helpers/handleLocalStorageConfiguration";
import { parseConfiguration } from "./helpers/parseConfiguration";
import { parseConfigurationDetails } from "./helpers/parseConfigurationDetails";
import { prepareConfig } from "./helpers/prepareConfig";
import { saveConfigurationsInLocalStorageNew } from "./helpers/saveConfigurationsInLocalStorageNew";
import {
  deleteConfiguration,
  getCertificates,
  getConfigurationDetails,
  getConfigurationHistory,
  getConfigurations,
  getConfigurationVersionDetails,
  getLatestConfiguration,
  submitConfiguration,
} from "./services";
import {
  addConfiguration,
  addError,
  deleteDefinition,
  clearSelectedConfiguration,
  setCertificates,
  setConfigDetailsStatus,
  setConfigurations,
  setDefinition,
  setErrors,
  setProperties,
  setSelectedConfiguration,
  setStatus,
  updateDefinition,
  updateMatchrule,
  setProduction,
  setApiErrorCode,
} from "./slice";
import {
  ConfigDetailsResponseData,
  ConfigurationDefinition,
  ConfigurationDefinitions,
  ConfigurationType,
  MatchRulePayload,
  UpdateVersionActions,
} from "./types";

const fetchProperties = async (subscriberId: number, dispatch: AppDispatch) => {
  const properties = await getProperties(subscriberId);
  dispatch(setProperties(properties));
};

const fetchCertificates = async (
  subscriberId: number,
  dispatch: AppDispatch
) => {
  const certificates = await getCertificates(subscriberId);
  dispatch(setCertificates(certificates));
};

const fetchAndStoreConfigurations = async (
  subscriberId: number,
  dispatch: AppDispatch
) => {
  const rawConfigs = await getConfigurations(subscriberId.toString());
  const requestedConfigsNew = rawConfigs.map(parseConfiguration);

  let configs: ConfigurationType[] = [];

  const storedConfigurations = handleLocalStorageConfiguration(subscriberId);

  if (storedConfigurations) {
    const localConfigsNew = storedConfigurations.map((storedConfiguration) => {
      const { config, ...configurationData } = storedConfiguration;
      const newConfig = parseConfiguration(configurationData);
      newConfig.isLocalDraft = true;
      if (config) {
        newConfig.config = parseConfigurationDetails(
          config as ConfigDetailsResponseData
        );
      }
      return newConfig;
    });

    configs = requestedConfigsNew.map((requestedConfig) => {
      const localStorageConfig = localConfigsNew.find(
        (localConfig) => localConfig.configName === requestedConfig.configName
      );

      return localStorageConfig ? localStorageConfig : requestedConfig;
    });

    configs = configs
      .concat(
        localConfigsNew.filter(
          (localconf) =>
            configs.findIndex(
              (conf) => conf.configName === localconf.configName
            ) === -1 && localconf.versionId === 0
        )
      )
      .sort(
        (a, b) =>
          new Date(b.modifiedTime || b.createdTime).getTime() -
          new Date(a.modifiedTime || a.createdTime).getTime()
      );
  } else {
    configs = requestedConfigsNew;
  }

  dispatch(setConfigurations(configs));

  saveConfigurationsInLocalStorageNew(configs, subscriberId);
};

export const fetchProduction = async (
  subscriberId: number,
  dispatch: AppDispatch
): Promise<void> => {
  try {
    const data = await Configuration.production.getActive(
      subscriberId.toString()
    );

    if (!!(data as IProductionMigrationData).slots.current?.isLegacy) {
      dispatch(
        setProduction({
          migrationData: data as IProductionMigrationData,
          productionData: undefined,
          isMigration: true,
        })
      );
    } else {
      dispatch(
        setProduction({
          productionData: data as IActiveConfigurationsResponse,
          migrationData: undefined,
          isMigration: false,
        })
      );
    }
  } catch (err) {
    const isProductionNotFoundError =
      err instanceof SubscriberDataNotFoundError;

    if (isProductionNotFoundError) {
      dispatch(
        setProduction({
          productionData: undefined,
          migrationData: undefined,
          isMigration: false,
        })
      );
    } else {
      throw err;
    }
  }
};

export const handleFetchSubscriberData = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { subscriberId } = getState().subscriber;
  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "caching"');
  }

  try {
    dispatch(setStatus(Status.LOADING));
    await Promise.all([
      fetchProperties(subscriberId, dispatch),
      fetchCertificates(subscriberId, dispatch),
      fetchAndStoreConfigurations(subscriberId, dispatch),
      fetchProduction(subscriberId, dispatch),
    ]);
    dispatch(setStatus(Status.SUCCESS));
  } catch (err) {
    const error = err as APIError<{ data: CMv3APIError }>;
    dispatch(setStatus(Status.ERROR));
    if (error.data && error.data.data.errors.length > 0) {
      dispatch(setApiErrorCode(error.data?.data.errors[0].code));
    }
    dispatch(handleAPIError(error));
  }
};

export const handleFetchProduction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { subscriberId } = getState().subscriber;
  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "caching"');
  }

  try {
    await fetchProduction(subscriberId, dispatch);
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleFetchSelectedConfigDetails = (
  configName: string
): AppThunk => async (dispatch, getState) => {
  dispatch(setConfigDetailsStatus(Status.LOADING));
  const configurations = getState().caching.configurations;

  const selectedConfiguration = configurations.find(
    (conf) => conf.configName === configName
  );

  if (!selectedConfiguration) {
    dispatch(setConfigDetailsStatus(Status.ERROR));
    return;
  }

  if (selectedConfiguration.isLocalDraft) {
    try {
      const versions = await getConfigurationHistory(selectedConfiguration);
      dispatch(
        handleUpdateSelectedConfiguration({
          ...selectedConfiguration,
          versions,
        })
      );
      dispatch(setConfigDetailsStatus(Status.SUCCESS));
    } catch (error) {
      dispatch(handleAPIError(error as APIError));
      dispatch(setConfigDetailsStatus(Status.ERROR));
    }
  } else {
    try {
      const { versions, config } = await getConfigurationVersionDetails(
        selectedConfiguration,
        selectedConfiguration.versionId
      );

      dispatch(
        handleUpdateSelectedConfiguration({
          ...selectedConfiguration,
          config,
          versions,
        })
      );
      dispatch(setConfigDetailsStatus(Status.SUCCESS));
    } catch (error) {
      dispatch(handleAPIError(error as APIError));
      dispatch(setConfigDetailsStatus(Status.ERROR));
    }
  }
};

export const handleUpdateSelectedConfiguration = (
  newConfiguration: ConfigurationType
): AppThunk => (dispatch) => {
  const sentryContext: any = {
    selectedConfiguration: {},
  };

  sentryContext.selectedConfiguration = prepareConfig(true, newConfiguration);

  Sentry.setContext("configurationsContext", sentryContext);

  dispatch(setSelectedConfiguration(newConfiguration));
};

export const handleSaveSelectedConfiguration = (
  _newConfiguration: ConfigurationType,
  isLocalDraft?: boolean
): AppThunk => (dispatch, getState) => {
  const configurations = getState().caching.configurations;
  const newConfiguration = {
    ..._newConfiguration,
    isLocalDraft: isLocalDraft !== undefined ? isLocalDraft : true,
  };

  const newConfigurations = configurations.map((config) =>
    config.id === newConfiguration.id ? newConfiguration : config
  );

  dispatch(handleUpdateSelectedConfiguration(newConfiguration));
  dispatch(handleSaveConfigurations(newConfigurations));
};

export const handleSaveConfigurations = (
  configurations: ConfigurationType[]
): AppThunk => (dispatch, getState) => {
  const subscriberId = getState().subscriber.subscriberId;

  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "caching"');
  }

  dispatch(setConfigurations(configurations));

  saveConfigurationsInLocalStorageNew(configurations, subscriberId);
};

export const handleCreateNewConfiguration = (name: string): AppThunk => (
  dispatch,
  getState
) => {
  const subscriberId = getState().subscriber.subscriberId;

  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "caching"');
  }

  dispatch(addConfiguration(generateDefaultConfiguration(name, subscriberId)));

  saveConfigurationsInLocalStorageNew(
    getState().caching.configurations,
    subscriberId
  );
};

export const handleDupplicateConfiguration = (
  configuration: ConfigurationType,
  newConfigurationName: string
): AppThunk => (dispatch, getState) => {
  const newConfig = cloneConfiguration(configuration);
  newConfig.configName = newConfigurationName;
  newConfig.isLocalDraft = true;
  newConfig.modifiedTime = undefined;
  newConfig.createdTime = new Date().toISOString();
  newConfig.versionId = 0;

  dispatch(
    handleSaveConfigurations([...getState().caching.configurations, newConfig])
  );
};

export const handleSetSelectedConfigurationByName = (
  configName: string
): AppThunk => async (dispatch, getState) => {
  const configurationsNew = getState().caching.configurations;
  const selectedConfig = configurationsNew.find(
    (config) => config.configName === configName
  );

  if (selectedConfig && !selectedConfig.isLocalDraft) {
    try {
      const details = await getConfigurationDetails(selectedConfig);
      dispatch(
        handleUpdateSelectedConfiguration({ ...selectedConfig, ...details })
      );
    } catch (error) {
      dispatch(handleAPIError(error as APIError));
    }
  } else {
    if (selectedConfig) {
      try {
        const versions = await getConfigurationHistory(selectedConfig);
        dispatch(
          handleUpdateSelectedConfiguration({ ...selectedConfig, versions })
        );
      } catch (error) {
        dispatch(handleAPIError(error as APIError));
      }
    } else {
      dispatch(clearSelectedConfiguration());
    }
  }
};

export const handleDeleteConfiguration = (
  configuration: ConfigurationType
): AppThunk => (dispatch, getState) => {
  const { configurations, selectedConfiguration } = getState().caching;

  dispatch(
    handleSaveConfigurations(
      configurations.filter(
        (conf) => conf.configName !== configuration.configName
      )
    )
  );

  if (selectedConfiguration?.configName === configuration.configName) {
    dispatch(clearSelectedConfiguration());
  }

  if (configuration.versionId > 0) {
    try {
      deleteConfiguration(configuration);
    } catch (error) {
      dispatch(handleAPIError(error as APIError));
    }
  }
};

export const handleDiscardConfigurationChanges = (
  configuration: ConfigurationType
): AppThunk => async (dispatch, getState) => {
  const subscriberId = getState().subscriber.subscriberId;
  const { configurations, selectedConfiguration } = getState().caching;

  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "caching"');
  }

  if (!selectedConfiguration) {
    throw new Error('"selectedConfiguration" is undefined in "caching"');
  }

  saveConfigurationsInLocalStorageNew(
    configurations.filter(
      (config) =>
        config.configName !== configuration.configName && config.isLocalDraft
    ),
    subscriberId
  );

  if (selectedConfiguration && selectedConfiguration.versionId !== 0) {
    try {
      const details = await getConfigurationVersionDetails(
        selectedConfiguration,
        selectedConfiguration.versionId
      );
      dispatch(
        handleSaveSelectedConfiguration(
          { ...selectedConfiguration, ...details },
          false
        )
      );
    } catch (error) {
      dispatch(handleAPIError(error as APIError));
    }
  } else if (selectedConfiguration) {
    dispatch(
      handleSaveConfigurations(
        configurations.filter(
          (config) => config.configName !== configuration.configName
        )
      )
    );
    dispatch(clearSelectedConfiguration());
  }
};

export const handleCreateDefinition = (
  definition: ConfigurationDefinitions[number],
  updateLocalStorage = true
): AppThunk => (dispatch, getState) => {
  const selectedConfiguration = getState().caching.selectedConfiguration;

  if (!selectedConfiguration) {
    throw new Error('"selectedConfiguration" is undefined in "caching"');
  }

  dispatch(setDefinition(definition));

  if (updateLocalStorage) {
    dispatch(
      handleSaveSelectedConfiguration(getState().caching.selectedConfiguration!)
    );
  } else {
    dispatch(
      handleUpdateSelectedConfiguration(
        getState().caching.selectedConfiguration!
      )
    );
  }
};

export const handleUpdateDefinition = (
  definition: ConfigurationDefinition | ConfigurationDefinition[]
): AppThunk => (dispatch, getState) => {
  const selectedConfiguration = getState().caching.selectedConfiguration;

  if (!selectedConfiguration) {
    throw new Error('"selectedConfiguration" is undefined in "caching"');
  }

  dispatch(updateDefinition(definition));

  dispatch(
    handleSaveSelectedConfiguration(getState().caching.selectedConfiguration!)
  );
};

export const handleUpdateMatchRule = (payload: MatchRulePayload): AppThunk => (
  dispatch,
  getState
) => {
  const selectedConfiguration = getState().caching.selectedConfiguration;

  if (!selectedConfiguration) {
    throw new Error('"selectedConfiguration" is undefined in "caching"');
  }

  dispatch(updateMatchrule(payload));

  dispatch(
    handleSaveSelectedConfiguration(getState().caching.selectedConfiguration!)
  );
};

export const handleDeleteDefinition = (
  definition: ConfigurationDefinitions[number]
): AppThunk => (dispatch, getState) => {
  const selectedConfiguration = getState().caching.selectedConfiguration;

  if (!selectedConfiguration) {
    throw new Error('"selectedConfiguration" is undefined in "caching"');
  }

  if (selectedConfiguration.config) {
    dispatch(deleteDefinition(definition));
    dispatch(
      handleSaveSelectedConfiguration(getState().caching.selectedConfiguration!)
    );
  }
};

export const handleAddConfigurationError = (
  level: ConfigurationErrorLevel,
  type: ConfigurationErrorType,
  data: any
): AppThunk => (dispatch) => {
  dispatch(addError({ level, type, data }));
};

export const handleRemoveConfigurationError = (
  filterFunction: (err: IConfigurationError) => boolean
): AppThunk => (dispatch, getState) => {
  const errors = getState().caching.errors;
  dispatch(setErrors(errors.filter(filterFunction)));
};

export const handleUpdateConfigurationErrors = (
  newConfigurationErrors?: IConfigurationError[],
  filterFunctions?: ((err: IConfigurationError) => boolean)[]
): AppThunk => (dispatch, getState) => {
  let updatedErrors = getState().caching.errors;

  if (newConfigurationErrors) {
    updatedErrors = [...updatedErrors, ...newConfigurationErrors];
  }
  if (filterFunctions) {
    filterFunctions.forEach(
      (filterFunction) => (updatedErrors = updatedErrors.filter(filterFunction))
    );
  }

  dispatch(setErrors(updatedErrors));
};

export const handleUpdateSelectedConfigurationVersion = (
  _versionId?: number,
  action?: UpdateVersionActions
): AppThunk => async (dispatch, getState) => {
  const { configurations, selectedConfiguration } = getState().caching;
  const subscriberId = getState().subscriber.subscriberId;

  if (!subscriberId) {
    throw new Error('"subscriberId" is undefined in "caching"');
  }

  if (!selectedConfiguration) {
    throw new Error('"selectedConfiguration" is undefined in "caching"');
  }

  try {
    switch (action) {
      case UpdateVersionActions.DISCARD:
        saveConfigurationsInLocalStorageNew(
          configurations.filter(
            (config) =>
              config.configName !== selectedConfiguration?.configName &&
              config.isLocalDraft
          ),
          subscriberId
        );
        break;
      case UpdateVersionActions.COMMIT:
        const {
          versions,
          versionId,
          isLocalDraft,
          modifiedTime,
        } = await submitConfiguration(selectedConfiguration);

        dispatch(
          handleUpdateSelectedConfiguration({
            ...selectedConfiguration,
            versions,
            versionId,
            isLocalDraft,
            modifiedTime,
          })
        );
        break;
      default:
        break;
    }

    if (!_versionId) {
      const { versions, config, versionId } = await getLatestConfiguration(
        selectedConfiguration
      );

      dispatch(
        handleSaveSelectedConfiguration(
          { ...selectedConfiguration, versions, config, versionId },
          false
        )
      );
    } else {
      const {
        versions,
        config,
        versionId,
      } = await getConfigurationVersionDetails(
        selectedConfiguration,
        _versionId
      );

      dispatch(
        handleSaveSelectedConfiguration(
          { ...selectedConfiguration, versions, config, versionId },
          false
        )
      );
    }
  } catch (err) {
    const error = err as APIError<CMv3APIError>;
    dispatch(handleAPIError(error));
  }
};
