import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Status } from "../../../@types/status";
import { useAccessGroupStatus } from "../../../store/slices/user-management-access-group/hooks";
import {
  fetchAccessGroupData,
  fetchAvailableProducts,
} from "../../../store/slices/user-management-access-group/thunk";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";

export const AccessGroup: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const match = useRouteMatch<{ accessGroupId: string }>([
    "/userManagement/accessGroups/:accessGroupId",
  ]);

  const loadingStatus = useAccessGroupStatus();

  useEffect(() => {
    if (match?.params.accessGroupId) {
      const accessGroupId = parseInt(match.params.accessGroupId, 10);
      dispatch(fetchAccessGroupData(accessGroupId));
      dispatch(fetchAvailableProducts(accessGroupId));
    }
  }, [match?.params.accessGroupId]);

  const { t } = useTranslation("layers");

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("ACCESS_GROUP_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  return <>{children}</>;
};
