import { ReactElement } from "react";
import styled from "@emotion/styled";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { State } from "../../../models/configuration";
import { ITransactionHistory } from "../../../models/configuration/configuration_details.interfaces";
import { getLatestTime } from "../../../modules/caching/utils/getLatestTime";
import { Badge } from "../../atoms/Badge/Badge";
import { CardContainer } from "../../atoms/CardContainer/CardContainer";
import { ColorSet } from "../../../contexts/themeContext";

export interface EnvironmentHistoryTransactionCardProps {
  transactionHistory: ITransactionHistory;
}

export const EnvironmentHistoryTransactionCard = ({
  transactionHistory,
}: EnvironmentHistoryTransactionCardProps): ReactElement => {
  const { t } = useTranslation("environmentHistoryPage");

  let badgeColor: keyof ColorSet["colors"] | undefined;
  switch (transactionHistory.state) {
    case State.COMPLETED:
      badgeColor = "green50";
      break;
    case State.ERRORED:
      badgeColor = "red70";
      break;
    case State.QUEUED:
      badgeColor = "purple70";
      break;
    case State.STARTED:
      badgeColor = "yellow40";
      break;
  }

  return (
    <TransactionHistoryCardContainer
      data-testid={`environment-history-transaction-card-${transactionHistory.transactionId}`}
    >
      <Header>
        <Title>
          <Time className="-text--h5">
            {format(new Date(getLatestTime(transactionHistory)), "h:mm a")}
          </Time>
          <Badge
            label={
              transactionHistory.state[0].toUpperCase() +
              transactionHistory.state.slice(1)
            }
            color={badgeColor}
          />
        </Title>
      </Header>
      <MetaDataContainer>
        <MetaData>
          <Label className="-text--md">
            {t("TRANSACTION_CARD_TRANSACTION_ID_LABEL")}
          </Label>
          <Data>{transactionHistory.transactionId}</Data>
        </MetaData>

        <MetaData>
          <Label className="-text--md">
            {t("TRANSACTION_CARD_TYPE_LABEL")}
          </Label>
          <Data>{transactionHistory.operationData.type}</Data>
        </MetaData>

        {transactionHistory.operationData.slot && (
          <MetaData>
            <Label className="-text--md">
              {t("TRANSACTION_CARD_ENVT_NAME_LABEL")}
            </Label>
            <Data>
              {t("TRANSACTION_CARD_ENVT_CONTENT", {
                slot:
                  transactionHistory.operationData.slot[0].toUpperCase() +
                  transactionHistory.operationData.slot.slice(1),
              })}
            </Data>
          </MetaData>
        )}

        {transactionHistory.operationData.sourceConfigName && (
          <MetaData>
            <Label className="-text--md">
              {t("TRANSACTION_CARD_CONFIG_NAME_LABEL")}
            </Label>
            <Data>
              {t("TRANSACTION_CARD_CONFIG_CONTENT", {
                configName: transactionHistory.operationData.sourceConfigName,
                configVersion:
                  transactionHistory.operationData.sourceConfigVersionId,
              })}
            </Data>
          </MetaData>
        )}

        {transactionHistory.operationData.percentActive !== undefined && (
          <MetaData>
            <Label className="-text--md">
              {t("TRANSACTION_CARD_PERCENT_ACTIVE_LABEL")}
            </Label>
            <Data>
              {t("TRANSACTION_CARD_PERCENT_ACTIVE_CONTENT", {
                percentActive: transactionHistory.operationData.percentActive,
              })}
            </Data>
          </MetaData>
        )}
      </MetaDataContainer>
      {typeof transactionHistory.messages !== "undefined" && (
        <MessagesMetaDataContainer>
          <MetaData>
            <Label className="-text--md">
              {t("TRANSACTION_CARD_MESSAGES_LABEL")}
            </Label>
            <Data>
              {transactionHistory.messages.map((message) => (
                <>
                  {message}
                  <br />
                </>
              ))}
            </Data>
            <CopyToClipboard
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    JSON.stringify(transactionHistory.messages, null, 2)
                  )
                  .then(() => {
                    toast.success(
                      t("TRANSACTION_CARD_MESSAGES_COPY_TO_CLIPBOARD_SUCCESS")
                    );
                  })
                  .catch(() => {
                    toast.error(
                      t("TRANSACTION_CARD_MESSAGES_COPY_TO_CLIPBOARD_ERROR")
                    );
                  });
              }}
            >
              {t("TRANSACTION_CARD_MESSAGES_COPY_TO_CLIPBOARD")}
            </CopyToClipboard>
          </MetaData>
        </MessagesMetaDataContainer>
      )}
    </TransactionHistoryCardContainer>
  );
};

const TransactionHistoryCardContainer = styled(CardContainer)`
  padding: 20px;
  cursor: default;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const Time = styled.div`
  padding-right: 1rem;
`;

const MetaDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const Data = styled.h6`
  margin: 0 !important;
`;

const MessagesMetaDataContainer = styled(MetaDataContainer)`
  width: 100%;
`;

const CopyToClipboard = styled.div`
  color: ${({ theme }) => theme.text.highlight};
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 15px;
`;
