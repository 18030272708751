import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ProtectedRoute } from "../../components/atoms/Protected/ProtectedRoute";
import { UserRoles } from "../../models/permissions";
import { ExistingFeatures } from "../../store/slices/feature-flags/types";

import { Caching } from "../caching";
import { ContentInvalidation } from "../content-invalidation";
import { RealTimeMonitoring } from "../rtm";
import { PortalHub } from "../portal-hub/index";
import { UserManagement } from "../user-management";

interface IAppRouterProps {}

export const AppRouter: React.FC<IAppRouterProps> = () => {
  return (
    <Switch>
      <Route path="/cmv3" component={Caching} />
      <ProtectedRoute
        component={ContentInvalidation}
        flags={ExistingFeatures.CONTENT_INVALIDATION}
        path="/contentInvalidation"
        redirect="/cmv3"
      />
      <ProtectedRoute
        component={RealTimeMonitoring}
        permissions={UserRoles.REPORTING}
        flags={ExistingFeatures.RTM}
        path="/rtm"
        redirect="/cmv3"
      />
      <ProtectedRoute
        flags={ExistingFeatures.PORTAL_HUB}
        component={PortalHub}
        path={"/portalhub"}
        redirect="/cmv3"
      />
      <ProtectedRoute
        flags={ExistingFeatures.USER_MANAGEMENT}
        component={UserManagement}
        path="/userManagement"
        redirect="/cmv3"
      />
      <Route path="/">
        <Redirect to="/cmv3" />
      </Route>
    </Switch>
  );
};
