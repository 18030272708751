import { ReactElement, useMemo, useState } from "react";
import styled from "@emotion/styled";
import Markdown from "react-markdown";
import { animated, config, useSpring } from "react-spring";

import { CheckIcon } from "../../atoms/CheckIcon/CheckIcon";
import { SearchIcon } from "../../atoms/SearchIcon/SearchIcon";

export interface IOption<Value extends string | number> {
  /** index in the options array */
  index: number;
  /** text displayed in screen, accepts markdown */
  text: string;
  /** Value stored in the definition */
  value: Value;
  /** Boolean that indicate if the option is selected or not, should be update with the toggleSelection function */
  selected: boolean;
}

interface SearchDropwdownProps<Value extends string | number> {
  placeholder: string;
  options: IOption<Value>[];

  /** Update the `selected` prop of an option */
  toggleSelection: (option: IOption<Value>) => void;
  /** returns true if option respect search */
  filterfunction: (search: string, option: IOption<Value>) => boolean;
  dataTestId?: string;
  searchPlaceholder?: string;
  listWidth?: string;
}

export function SearchDropdown<Value extends string | number>({
  placeholder,
  options,
  toggleSelection,
  filterfunction,
  dataTestId,
  searchPlaceholder = "United States | US",
  listWidth = "400px",
}: SearchDropwdownProps<Value>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const dropdownOptions = useMemo(() => {
    if (search) {
      return options.filter((option) => filterfunction(search, option));
    } else {
      return options;
    }
  }, [options, search, filterfunction]);

  const style = useSpring({
    config: { ...config.stiff, mass: 0.5 },
    opacity: isOpen ? 1 : 0,
    height: isOpen ? "20em" : "0em",
  });

  return (
    <DropdownContainer className="chi_dropdown">
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        id="search-dropdown-button"
        className="chi-button chi-dropdown__trigger"
        type="button"
        data-testid={`search-dropdown-button-${dataTestId}`}
      >
        {placeholder}
      </DropdownButton>
      <DropwdownContent style={style}>
        <SearchBar className="chi-input__wrapper -icon--left" role="search">
          <SearchInput
            className="chi-input"
            type="text"
            placeholder={searchPlaceholder}
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
            data-testid={`search-dropdown-input-${dataTestId}`}
          />
          <StyledSearchIcon className="chi-icon" />
        </SearchBar>
        {
          <OptionsList width={listWidth}>
            {dropdownOptions.map((dropdownOption, i) => (
              <Option
                key={(dropdownOption.value as unknown) as string}
                onClick={() => toggleSelection(dropdownOption)}
                selected={dropdownOption.selected}
                data-testid={`search-dropdown-option-${dataTestId}-${i}`}
              >
                <Markdown>{dropdownOption.text}</Markdown>
                {dropdownOption.selected && <StyledCheckIcon />}
              </Option>
            ))}
          </OptionsList>
        }
      </DropwdownContent>
    </DropdownContainer>
  );
}

const DropdownContainer = styled.div`
  width: 100%;
`;

const DropwdownContent = styled(animated.div)``;

const DropdownButton = styled.button`
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: normal !important;
  line-height: normal !important;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;
`;

const OptionsList = styled(animated.div)<{
  width: string;
}>`
  overflow-y: scroll;
  height: 85%;
  margin-top: 24px;
  width: ${({ width }) => width};

  scrollbar-color: ${({ theme }) => theme.backgrounds.highlightLight};
  scrollbar-width: 120px;

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.backgrounds.highlightLight};
  }
`;

const Option = styled(animated.span)<{
  selected: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 24px;
  color: ${({ selected, theme }) =>
    `${
      selected
        ? `${theme.text.primary} !important`
        : `${theme.text.muted} !important`
    }`};
  cursor: pointer;
  font-size: 1rem !important;
  width: 90%;

  &:first-of-type {
    padding-top: 0;
    margin-top: 0;
  }
`;

const SearchBar = styled.div`
  height: 40px;
  margin-top: 8px;
`;

const SearchInput = styled.input`
  height: 100%;
  height: 40px !important;
  line-height: 1em;
  font-size: large;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;
`;

const StyledSearchIcon = styled(SearchIcon)`
  margin-left: 4px;
  margin-top: 4px;
  path {
    fill: ${({ theme }) => theme.icon.secondary};
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  padding-right: 0;
  path {
    fill: ${({ theme }) => theme.backgrounds.highlight};
  }
`;
