import { AxiosError } from "axios";

import {
  IActiveConfigurationsResponse,
  deployConfiguration,
  changeCandidatePercentActive,
  deleteCandidateConfiguration,
  getActive,
  getActiveCnames,
  promoteCandidateToCurrent,
} from "./production";
import { httpClient } from "../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../error";
import { IConfigDetailsData } from "./configuration_details";
import {
  IVersionHistory,
  ITransactionHistory,
} from "./configuration_details.interfaces";

export type ILocalStoredConfiguration = IConfigurationData & {
  config?: IConfigDetailsData;
};

export interface ILocalStoredData {
  [subscriberId: string]: ILocalStoredConfiguration[];
}

interface IConfigurationData {
  configName: string;
  subscriberId: number;
  transactionId?: string;
  comment?: string;
  createdTime: string;
  modifiedTime?: string;
  schemaVersion?: string;
  versionId: number;
}

export enum ProductionSlot {
  CURRENT = "current",
  CANDIDATE = "candidate",
}

export enum State {
  COMPLETED = "completed",
  ERRORED = "errored",
  QUEUED = "queued",
  STARTED = "started",
}

export enum TransactionType {
  DEPLOY_NEW_CONFIG = "deployNewConfig",
  CHANGE_PERCENT_ACTIVE = "changePercentActive",
  PROMOTE_TO_CURRENT = "promoteToCurrent",
  DELETE = "delete",
}

export class Configuration {
  static async getProductionHistory(
    subscriberId: string,
    slot: ProductionSlot
  ): Promise<IVersionHistory[]> {
    try {
      const response = await httpClient.get<{ configs: IVersionHistory[] }>(
        `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production/${slot}/history`
      );
      for (const version of response.data.configs) {
        version.slot = slot;
      }
      return response.data.configs;
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;
      throw new APIError(
        error.message,
        "getProductionHistory()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }

  static async getTransactions(
    subscriberId: string
  ): Promise<ITransactionHistory[]> {
    try {
      const response = await httpClient.get<{
        transactions: ITransactionHistory[];
      }>(`/serviceConfiguration/v3/subscribers/${subscriberId}/transactions`);
      return response.data.transactions;
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;
      throw new APIError(
        error.message,
        "getTransactions()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }

  static async getTransaction(
    subscriberId: string,
    transactionId: string
  ): Promise<ITransactionHistory | undefined> {
    try {
      const response = await httpClient.get<ITransactionHistory | undefined>(
        `/serviceConfiguration/v3/subscribers/${subscriberId}/transactions/${transactionId}`
      );
      return response.data;
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;
      throw new APIError(
        error.message,
        "getTransaction()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }

  public static async checkDeploymentStatus(
    subscriberId: string,
    slots: IActiveConfigurationsResponse["slots"],
    selectedSlot: ProductionSlot
  ): Promise<ITransactionHistory | void> {
    return new Promise((resolve) => {
      const intervalId = setInterval(async () => {
        if (slots[selectedSlot]) {
          const transaction = await Configuration.getTransaction(
            subscriberId,
            slots[selectedSlot]!.transactionId
          );

          if (
            transaction &&
            transaction.state !== "started" &&
            transaction.state !== "deploying"
          ) {
            clearInterval(intervalId);
            resolve(transaction);
          }
        }
      }, 15000);
    });
  }

  static production = {
    deployConfiguration,
    getActive,
    getActiveCnames,
    changeCandidatePercentActive,
    promoteCandidateToCurrent,
    deleteCandidateConfiguration,
  };
}
