import styled from "@emotion/styled";

export const BorderlessTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: right;
`;

export const BorderlessTableHeaderRow = styled.tr`
  background: ${({ theme }) => theme.backgrounds.base};
  border-radius: 8px;
  th {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  th:first-child {
    border-radius: 10px 0 0 10px;
    padding-left: 16px;
  }
  th:last-child {
    border-radius: 0 10px 10px 0;
    padding-right: 16px;
  }
`;

export const BorderlessTableBodyRow = styled.tr`
  td {
    padding-top: 16px;
  }
  td:first-child {
    padding-left: 16px;
  }
  td:last-child {
    padding-right: 16px;
  }
`;
