import { OPERATIONS } from "./constants";

/**
 * Class representing one diff tuple.
 * Attempts to look like a two-element array (which is what this used to be).
 * @param {number} op Operation, one of: DIFF_DELETE, DIFF_INSERT, DIFF_EQUAL.
 * @param {string} text Text to be deleted, inserted, or retained.
 * @constructor
 */
export class Diff extends Array {
  constructor(
    op: typeof OPERATIONS[keyof typeof OPERATIONS],
    text: string | undefined
  ) {
    super(2);
    this[0] = op;
    this[1] = text;
  }

  toString(): string {
    return this[0] + "," + this[1];
  }
}
