import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { OriginTypes } from "../../../../../../../../models/configuration/definitions/origin";
import { FailoverOrigin } from "./Failover";
import { UserRoles } from "../../../../../../../../models/permissions";
import { Protected } from "../../../../../../../../components/atoms/Protected/Protected";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../../../../../../models/configuration/errors";
import {
  PropertySection,
  PropertySubsection,
} from "../../../../../../../../models/configuration/definitions/property";
import { isDefinitionNameValid } from "../../../../../../../../utils/string";
import { OriginBlock } from "./Single/OriginBlock";
import { ExpandableContent } from "../../../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { AddButton } from "../../../../../../../../components/atoms/AddButton/AddButton";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import {
  useConfigurationsErrors,
  useSelectedConfiguration,
} from "../../../../../../../../store/slices/caching/hooks";
import { useAppDispatch } from "../../../../../../../../store/types";
import {
  handleRemoveConfigurationError,
  handleUpdateConfigurationErrors,
} from "../../../../../../../../store/slices/caching/thunks";
import { OriginDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { generateDefaultMember } from "../../../../../../../../store/slices/caching/helpers/generateDefaultMember";
import { setOriginType } from "../../../../../../../../store/slices/caching/helpers/origin-definition/setOriginType";

interface IOriginFieldsProps {
  originDefinition: OriginDefinitionType;
  onChange: (originDefinition: Partial<OriginDefinitionType>) => void;
}

export const OriginFields: React.FC<IOriginFieldsProps> = ({
  originDefinition: currentOriginDefinition,
  onChange,
}) => {
  const { t } = useTranslation("configurationPropertyPage");

  const isViewMode = useIsViewMode();

  const errors = useConfigurationsErrors();
  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();

  const failoverChecked =
    currentOriginDefinition?.originType === OriginTypes.FAILOVER;
  const addOriginLimit = failoverChecked ? 3 : 1;

  const isOriginDefinitionNameUnique = useCallback(
    (newName: string): boolean => {
      const originDefinitions =
        selectedConfiguration?.config?.originFillPolicyDefinitions;
      if (originDefinitions) {
        return !originDefinitions.some(
          (origin) =>
            origin.name === newName && origin.id !== currentOriginDefinition.id
        );
      }
      return true;
    },
    [selectedConfiguration, currentOriginDefinition.id]
  );

  return (
    <>
      <ExpandableContent
        title={t("ORIGIN_DESCRIPTION_TITLE")}
        content={t("ORIGIN_DESCRIPTION_CONTENT")}
        dataTestId="property-page-origin-section"
      />
      <br />
      <PropertyDefinitionCard
        title={t("PROPERTY_CARD_ORIGIN_TITLE")}
        additionalInfoTitle={t("PROPERTY_CARD_ORIGIN_DESCRIPTION_TITLE")}
        additionalInfoContent={t("PROPERTY_CARD_ORIGIN_DESCRIPTION_CONTENT")}
        divider
        required
        fieldType={FieldType.RadioButtonsType}
        fieldProps={{
          inline: true,
          radioButtonsProps: [
            {
              value: OriginTypes.SINGLE,
              onClick: (value) => {
                if (
                  currentOriginDefinition.originType === OriginTypes.FAILOVER
                ) {
                  dispatch(
                    handleRemoveConfigurationError(
                      (err) =>
                        !(
                          err.data?.section === PropertySection.ORIGIN &&
                          (err.data?.subsection ===
                            PropertySubsection.CONDITIONS ||
                            err.data?.index > 0)
                        )
                    )
                  );

                  onChange({
                    conditions: undefined,
                    ...setOriginType(
                      {
                        originType: currentOriginDefinition.originType,
                        members: [...currentOriginDefinition.members],
                      },
                      value as OriginTypes
                    ),
                  });
                }
              },
              label: t("PROPERTY_CARD_ORIGIN_RADIO_SINGLE_LABEL"),
              name: `originType-${currentOriginDefinition.name}`,
              id: `single-${currentOriginDefinition.name}`,
              disabled: isViewMode,
              defaultChecked:
                currentOriginDefinition.originType === OriginTypes.SINGLE,
              dataTestId: "origin-single-radio",
            },
            {
              value: OriginTypes.FAILOVER,
              onClick: (value) => {
                if (currentOriginDefinition.originType === OriginTypes.SINGLE) {
                  dispatch(
                    handleRemoveConfigurationError(
                      (err) =>
                        !(
                          err.data?.section === PropertySection.ORIGIN &&
                          (err.data?.subsection ===
                            PropertySubsection.CONDITIONS ||
                            err.data?.index > 0)
                        )
                    )
                  );

                  onChange({
                    conditions: { failoverOn4xx: false, failoverOn5xx: false },
                    ...setOriginType(
                      {
                        originType: currentOriginDefinition.originType,
                        members: [...currentOriginDefinition.members],
                      },
                      value as OriginTypes
                    ),
                  });
                }
              },
              label: t("PROPERTY_CARD_ORIGIN_RADIO_FAILOVER_LABEL"),
              id: `failover-${currentOriginDefinition.name}`,
              name: `originType-${currentOriginDefinition.name}`,
              disabled: isViewMode,
              defaultChecked:
                currentOriginDefinition.originType === OriginTypes.FAILOVER,
              dataTestId: "origin-failover-radio",
            },
          ],
        }}
      />
      <PropertyDefinitionCard
        required
        title={t("PROPERTY_CARD_ORIGIN_DEFINITION_NAME_TITLE")}
        additionalInfoTitle={t(
          "PROPERTY_CARD_ORIGIN_DEFINITION_NAME_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "PROPERTY_CARD_ORIGIN_DEFINITION_NAME_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.InputFieldType}
        fieldProps={{
          placeholder: t("PROPERTY_CARD_ORIGIN_DEFINITION_NAME_PLACEHOLDER"),
          disabled: isViewMode,
          value: currentOriginDefinition.name,
          onChange: (name) => {
            const errorsToAdd: IConfigurationError[] = [];
            const errorsToRemove: ((
              err: IConfigurationError
            ) => boolean)[] = [];

            if (name.length === 0) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type:
                  ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_EMPTY,
                data: { section: PropertySection.ORIGIN },
              });
            } else {
              errorsToRemove.push(
                (err) =>
                  !(
                    err.level === ConfigurationErrorLevel.ERROR &&
                    err.type ===
                      ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_EMPTY &&
                    err.data?.section === PropertySection.ORIGIN
                  )
              );
            }

            if (!isDefinitionNameValid(name)) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type:
                  ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_REGEX,
                data: { section: PropertySection.ORIGIN },
              });
            } else {
              errorsToRemove.push(
                (err) =>
                  !(
                    err.level === ConfigurationErrorLevel.ERROR &&
                    err.type ===
                      ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_REGEX &&
                    err.data?.section === PropertySection.ORIGIN
                  )
              );
            }

            if (!isOriginDefinitionNameUnique(name)) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type:
                  ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_DUPLICATE,
                data: { section: PropertySection.ORIGIN },
              });
            } else {
              errorsToRemove.push(
                (err) =>
                  !(
                    err.level === ConfigurationErrorLevel.ERROR &&
                    err.type ===
                      ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_DUPLICATE &&
                    err.data?.section === PropertySection.ORIGIN
                  )
              );
            }

            dispatch(
              handleUpdateConfigurationErrors(errorsToAdd, errorsToRemove)
            );

            onChange({ name });
          },
          dataTestId: "origin-name-input",
        }}
        errorMessage={
          errors.some(
            (err) =>
              err.type ===
                ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_EMPTY &&
              err.data?.section === PropertySection.ORIGIN
          )
            ? t("ERROR_ORIGIN_DEFINITION_NAME_EMPTY")
            : errors.some(
                (err) =>
                  err.type ===
                    ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_REGEX &&
                  err.data?.section === PropertySection.ORIGIN
              )
            ? t("ERROR_ORIGIN_DEFINITION_NAME_REGEX")
            : errors.some(
                (err) =>
                  err.type ===
                    ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_NAME_DUPLICATE &&
                  err.data?.section === PropertySection.ORIGIN
              )
            ? t("ERROR_ORIGIN_DEFINITION_NAME_DUPLICATE")
            : undefined
        }
      />
      <br />
      {currentOriginDefinition.originType === OriginTypes.FAILOVER && (
        <>
          <FailoverOrigin
            onChange={onChange}
            originDefinition={currentOriginDefinition}
          />
          <br />
        </>
      )}
      {currentOriginDefinition.members.map((member, i) => (
        <>
          <SectionWrapper key={i}>
            <OriginBlock
              index={i}
              member={member}
              onChange={onChange}
              originDefinition={currentOriginDefinition}
              isOptional={
                currentOriginDefinition.originType !== OriginTypes.SINGLE &&
                i > 0 &&
                currentOriginDefinition.members.length > 2
              }
            />
          </SectionWrapper>
          {i !== currentOriginDefinition.members.length - 1 && <br />}
        </>
      ))}
      {currentOriginDefinition?.members.length < addOriginLimit && (
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          <br />
          <AddButton
            label={t("PROPERTY_SECTION_ORIGIN_ADD_BUTTON")}
            onClick={() => {
              onChange({
                members: [
                  ...currentOriginDefinition.members,
                  generateDefaultMember(
                    currentOriginDefinition?.members.length
                  ),
                ],
              });
            }}
            dataTestId="origin-section-add-origin"
            outline
          />
        </Protected>
      )}
    </>
  );
};

const SectionWrapper = styled.div`
  width: 100%;
`;
