import { ReactElement } from "react";
import styled from "@emotion/styled";

import { AnchorWrapper } from "../../../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { MenuItem } from "../../../../../../components/molecules/AnchorMenu/AnchorMenu";
import {
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { AliasesSection } from "./sections/aliases/AliasesSection";
import { OverridesSection } from "./sections/aliases/OverridesSection";
import { OriginSection } from "./sections/origin/OriginSection";
import { TrafficTypeSection } from "./sections/traffic_type/TrafficTypeSection";

interface PropertyDefinitionSectionProps {
  menu: MenuItem;
  property: PropertyDefinitionType;
  updateProperty: (newDefinition: PropertyDefinitionType) => void;
  originDefinition: OriginDefinitionType | undefined;
  updateOrigin: (
    originDefinition: Partial<OriginDefinitionType> | undefined
  ) => void;
}

export const PropertyDefinitionSection = ({
  menu,
  property,
  updateProperty,
  originDefinition,
  updateOrigin,
}: PropertyDefinitionSectionProps): ReactElement => {
  const [origin, alias, overrides, trafficType] = menu.subItems!;

  return (
    <AnchorWrapper id={menu.id} ref={menu.ref}>
      <Title>{menu.name}</Title>
      <AnchorWrapper id={origin.id} ref={origin.ref} key={origin.name}>
        <OriginSection
          originDefinition={originDefinition}
          property={property}
          onChange={updateOrigin}
          onPropertyChange={updateProperty}
        />
      </AnchorWrapper>
      <AnchorWrapper id={alias.id} ref={alias.ref} key={alias.name}>
        <AliasesSection property={property} onPropertyChange={updateProperty} />
      </AnchorWrapper>
      <AnchorWrapper id={overrides.id} ref={overrides.ref} key={overrides.name}>
        <OverridesSection
          property={property}
          onPropertyChange={updateProperty}
        />
      </AnchorWrapper>
      <AnchorWrapper
        id={trafficType.id}
        ref={trafficType.ref}
        key={trafficType.name}
      >
        <TrafficTypeSection property={property} onChange={updateProperty} />
      </AnchorWrapper>
    </AnchorWrapper>
  );
};

const Title = styled.h3``;
