import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Card } from "../../atoms/Card/Card";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { FeatureType, FeatureTypes } from "../FeatureType/FeatureType";

export interface IAddFeatureCardProps {
  title: string;
  description?: string;
  warning?: ReactNode;
  onAdd?: () => void;
  dataTestId?: string;
  required?: boolean;
  customButton?: JSX.Element;
  errorMessage?: string;
  featureType?: FeatureTypes;
}

export const AddFeatureCard: FC<IAddFeatureCardProps> = ({
  title,
  description,
  warning,
  onAdd,
  dataTestId,
  required,
  customButton,
  errorMessage,
  featureType,
}) => {
  const { t } = useTranslation("misc");

  return (
    <AddFeatureCardContainer>
      <Content>
        <TitleContainer>
          <TitleContainer>
            <h4>{title}</h4>
            {required && (
              <Asterisk
                className="chi-label__required"
                title={t("ERROR_REQUIRED_FIELD")}
                data-testid={`property-definition-card-${dataTestId}-asterisk`}
              >
                *
              </Asterisk>
            )}
            {!!featureType && (
              <FeatureType featureType={featureType as FeatureTypes} />
            )}
          </TitleContainer>
        </TitleContainer>
        {!!warning && warning}
        <Description>
          {!!description && <CustomRemark>{description}</CustomRemark>}
        </Description>
        <ErrorContainer>{errorMessage}</ErrorContainer>
      </Content>
      {customButton ? (
        customButton
      ) : (
        <AddButton
          className="chi-button -primary"
          onClick={onAdd}
          data-testid={`add-feature-card-${dataTestId}-button`}
        >
          <PlusSign>+</PlusSign>
          <div>Add</div>
        </AddButton>
      )}
    </AddFeatureCardContainer>
  );
};

const AddFeatureCardContainer = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: 8px 24px 8px 16px;
`;

const Content = styled.div`
  padding: 0px 16px 16px 8px;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
`;

const PlusSign = styled.div`
  font-size: 32px;
  font-weight: 300;
  padding: 0px 4px 4px 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Asterisk = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.text.error};
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => `${theme.text.error}`};
  font-size: 14px;
  padding: 8px;
`;
