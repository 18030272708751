import { Children, FC } from "react";
import styled from "@emotion/styled";

import { FlexBox } from "../../atoms/FlexBox/FlexBox";

export interface IIconSliderProps {
  activeIndex: number;
  onRadioClick: (radioIndex: number) => void;
}

export const IconSlider: FC<IIconSliderProps> = ({
  activeIndex,
  children,
  onRadioClick,
}) => (
  <FlexBox>
    {Children.map(children, (child, i) => (
      <IconContainer
        active={i === activeIndex}
        onClick={() => {
          onRadioClick(i);
        }}
      >
        {child}
      </IconContainer>
    ))}
  </FlexBox>
);

const IconContainer = styled.div<{ active?: boolean }>`
  padding: 16px;
  background-color: ${({ theme, active }) =>
    active ? theme.backgrounds.highlightLight : theme.backgrounds.baseLight};

  & > svg > path {
    fill: ${({ theme, active }) =>
      active ? theme.icon.highlight : theme.icon.secondary};
  }
`;
