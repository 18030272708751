import { ILocalStoredConfiguration } from "../../../../models/configuration";
import { ILocalStoredData } from "../types";

export const handleLocalStorageConfiguration = (
  subscriberId: number
): ILocalStoredConfiguration[] => {
  const storedConfigurationsItem = window.localStorage.getItem("cmv3_configs");
  if (storedConfigurationsItem) {
    const storedConfigurationsBySubscriberId: ILocalStoredData = JSON.parse(
      storedConfigurationsItem
    );
    if (
      Object.keys(storedConfigurationsBySubscriberId).includes(
        subscriberId.toString()
      )
    ) {
      return storedConfigurationsBySubscriberId[subscriberId];
    }
  }
  return [];
};
