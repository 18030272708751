import { ReactElement } from "react";
import styled from "@emotion/styled";

import { ErrorIcon } from "../../atoms/ErrorIcon/ErrorIcon";
import { InfoIcon } from "../../atoms/InfoIcon/InfoIcon";
import { WarningIcon } from "../../atoms/WarningIcon/WarningIcon";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { ColorSet } from "../../../contexts/themeContext";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";

export type NotificationType = "info" | "warning" | "error";

export interface INotificationCardProps {
  title: string;
  content?: string;
  theme: NotificationType;
  dataTestId?: string;
}

interface INotificationTheme {
  border: string;
  text: string;
  background: string;
}

const getNotificationColor = (
  theme: NotificationType,
  colorset: ColorSet
): INotificationTheme => {
  switch (theme) {
    case "info":
      return {
        border: colorset.borders.info,
        text: colorset.text.primary,
        background: colorset.backgrounds.baseLight,
      };
    case "warning":
      return {
        border: colorset.borders.warning,
        text: colorset.text.warning,
        background: colorset.backgrounds.baseLight,
      };
    case "error":
      return {
        border: colorset.borders.error,
        text: colorset.text.error,
        background: colorset.backgrounds.baseLight,
      };
  }
};

const getNotificationIcon = (theme: NotificationType): ReactElement => {
  switch (theme) {
    case "info":
      return <InfoIcon />;
    case "warning":
      return <WarningIcon />;
    case "error":
      return <ErrorIcon />;
  }
};

export const NotificationCard = ({
  title,
  content,
  theme,
  dataTestId,
}: INotificationCardProps): ReactElement => (
  <NotificationContainer data-testid={`notification-card-${dataTestId}`}>
    <Border themeColor={theme} />
    <ContentContainer>
      <TitleContainer>
        <IconContainer>{getNotificationIcon(theme)}</IconContainer>
        <NotificationTitle
          className="notification-card-title"
          themeColor={theme}
        >
          <CustomRemark>{title}</CustomRemark>
        </NotificationTitle>
      </TitleContainer>
      {content && <BodyContainer>{content}</BodyContainer>}
    </ContentContainer>
  </NotificationContainer>
);

const NotificationContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`;

const BodyContainer = styled.div`
  padding: 8px;
  font-size: small;
`;

const Border = styled.div<{ themeColor: NotificationType }>`
  background-color: ${({ themeColor, theme }) =>
    getNotificationColor(themeColor, theme).border};
  height: 5em;
  width: 3px;
  margin: 8px 8px 8px 0px;
`;

const NotificationTitle = styled.span<{ themeColor: NotificationType }>`
  color: ${({ themeColor, theme }) =>
    getNotificationColor(themeColor, theme).text};
  margin-left: 8px;
`;

const IconContainer = styled(FlexBox)`
  width: 2em;
`;
