import { IMatchRule } from "../../../../../models/configuration/definitions";

export const updateReportingOverrideReferences = (
  oldName: string,
  newName: string
) => (matchRule: IMatchRule): IMatchRule => {
  matchRule.features.reportingOverride =
    matchRule.features.reportingOverride === oldName
      ? newName
      : matchRule.features.reportingOverride;

  return matchRule;
};
