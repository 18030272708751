import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../@types/status";
import { UserManagementApiKeysSlice } from "./types";

const initialState: UserManagementApiKeysSlice = {
  vyvxRoles: [],
  vyvxApiKeysStatus: Status.IDLE,
  vyvxApiKeys: undefined,
  vyvxApiKey: undefined,
  vyvxApiKeyStatus: Status.IDLE,
  vyvxApiKeysOwners: [],
  cdnRoles: undefined,
  cdnApiKeysStatus: Status.IDLE,
  cdnApiKeys: undefined,
  cdnApiKey: undefined,
  cdnApiKeyStatus: Status.IDLE,
  cdnApiKeysOwners: [],
};

export const userManagementApiKeysSlice = createSlice({
  name: "userManagementApiKeys",
  initialState,
  reducers: {
    setVyvxApiKeysRoles: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["vyvxRoles"]>
    ) => {
      state.vyvxRoles = action.payload;
    },
    setVyvxApiKeysStatus: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["vyvxApiKeysStatus"]>
    ) => {
      state.vyvxApiKeysStatus = action.payload;
    },
    setVyvxApiKeys: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["vyvxApiKeys"]>
    ) => {
      state.vyvxApiKeys = action.payload;
    },
    setVyvxApiKey: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["vyvxApiKey"]>
    ) => {
      state.vyvxApiKey = action.payload;
    },
    setVyvxApiKeyStatus: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["vyvxApiKeyStatus"]>
    ) => {
      state.vyvxApiKeyStatus = action.payload;
    },
    setVyvxApiKeysOwners: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["vyvxApiKeysOwners"]>
    ) => {
      state.vyvxApiKeysOwners = action.payload;
    },
    setCdnApiKeysRoles: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["cdnRoles"]>
    ) => {
      state.cdnRoles = action.payload;
    },
    setCdnApiKeysStatus: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["cdnApiKeysStatus"]>
    ) => {
      state.cdnApiKeysStatus = action.payload;
    },
    setCdnApiKeys: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["cdnApiKeys"]>
    ) => {
      state.cdnApiKeys = action.payload;
    },
    setCdnApiKey: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["cdnApiKey"]>
    ) => {
      state.cdnApiKey = action.payload;
    },
    setCdnApiKeyStatus: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["cdnApiKeyStatus"]>
    ) => {
      state.cdnApiKeyStatus = action.payload;
    },
    setCdnApiKeysOwners: (
      state,
      action: PayloadAction<UserManagementApiKeysSlice["cdnApiKeysOwners"]>
    ) => {
      state.cdnApiKeysOwners = action.payload;
    },
  },
});

export const {
  setVyvxApiKeysRoles,
  setVyvxApiKeysStatus,
  setVyvxApiKeys,
  setVyvxApiKey,
  setVyvxApiKeyStatus,
  setVyvxApiKeysOwners,
  setCdnApiKeysRoles,
  setCdnApiKeysStatus,
  setCdnApiKeys,
  setCdnApiKey,
  setCdnApiKeyStatus,
  setCdnApiKeysOwners,
} = userManagementApiKeysSlice.actions;

export default userManagementApiKeysSlice.reducer;
