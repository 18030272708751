import { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { FlexBox } from "../FlexBox/FlexBox";
import { Icon, Icons } from "../Icon/Icon";

export enum SelectionStatus {
  SELECTED = "selected",
  PARTIAL = "partial",
  NOT_SELECTED = "not_selected",
}

export interface CheckboxInputProps {
  selectionStatus: SelectionStatus;
  onChange: () => void;
  disabled?: boolean;
  dataTestId?: string;
}

export const CustomCheckboxInput = ({
  selectionStatus = SelectionStatus.NOT_SELECTED,
  disabled = false,
  dataTestId,
  onChange,
}: CheckboxInputProps): JSX.Element => {
  const [hover, setHover] = useState(false);
  return (
    <StyledInputDiv
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      selectionStatus={selectionStatus}
      onClick={() => {
        if (!disabled) {
          onChange();
        }
      }}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {selectionStatus === SelectionStatus.SELECTED ? (
        <Icon name={Icons.CHECK} color="white" size={11} />
      ) : selectionStatus === SelectionStatus.PARTIAL ? (
        <Icon name={Icons.DASH} color="white" size={10} />
      ) : (
        <HoverIcon hover={hover} name={Icons.CHECK} color="grey50" size={8} />
      )}
    </StyledInputDiv>
  );
};

const StyledInputDiv = styled(FlexBox)<{
  disabled: boolean;
  selectionStatus: SelectionStatus;
}>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  ${({ theme, selectionStatus, disabled }) => css`
    border: 1px solid
      ${disabled
        ? theme.backgrounds.muted
        : selectionStatus !== SelectionStatus.NOT_SELECTED
        ? theme.backgrounds.highlight
        : theme.borders.muted};
    background-color: ${disabled
      ? theme.backgrounds.muted
      : selectionStatus !== SelectionStatus.NOT_SELECTED
      ? theme.backgrounds.highlight
      : "inherited"};
  `}
`;

const HoverIcon = styled(Icon)<{ hover: boolean }>`
  transform: ${({ hover }) => (hover ? "scale(1)" : "scale(0)")};
  transition: transform 0.2s ease-in-out;
  width: 0.625rem;
`;
