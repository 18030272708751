import { DispatchWithoutAction, useReducer, useRef } from "react";

import { useUserId } from "../../../../../../store/slices/user/hooks";
import { toggleBool } from "../../../../../../utils/toggleBool";

export const useCompact = (): [boolean, DispatchWithoutAction] => {
  const userId = useUserId().toString();

  const [isCompact, toggleCompact] = useReducer(toggleBool, true, () => {
    let init = false;

    try {
      init = JSON.parse(
        window.localStorage.getItem(userId) ?? '{"isCompact":false}'
      ).isCompact;
    } catch (error) {
      console.error(`Failed to get compact state by user "${userId}"`);
      init = false;
    }

    return init;
  });

  const compactRef = useRef(isCompact);

  if (compactRef.current !== isCompact) {
    window.localStorage.setItem(userId, JSON.stringify({ isCompact }));
    compactRef.current = isCompact;
  }

  return [isCompact, toggleCompact];
};
