import { FC, LabelHTMLAttributes, useContext } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Theme, ThemeContext } from "../../../contexts/themeContext";

export interface ICheckboxLabelProps
  extends LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
}

export const CheckboxLabel: FC<ICheckboxLabelProps> = ({
  disabled = false,
  className = "",
  ...labelProps
}) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Label
      className={`chi-checkbox__label ${className}`}
      isDark={theme === Theme.DARK_MODE}
      disabled={disabled}
      {...labelProps}
    />
  );
};

const Label = styled.label<{
  isDark: boolean;
  disabled?: boolean;
}>`
  color: ${({ isDark }) => isDark && "#e3e3e3!important"};
  ::before {
    top: calc(50% - 0.5rem) !important;
    ${({ theme, disabled }) =>
      disabled &&
      css`
        background-color: ${theme.backgrounds.muted} !important;
        border-color: ${theme.borders.muted} !important;
      `}
  }
`;
