import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";
import { FeatureComponent } from "./types";

type QSHModeType = Exclude<IFeatures["qshMode"], undefined>;

type QSHModeComponent = FeatureComponent<QSHModeType>;

type AdvancedType = Exclude<QSHModeType, boolean>;

export const QSHMode: QSHModeComponent = ({
  feature,
  tPropertyPage,
  dataTestId,
}) => {
  const isAdvanced = typeof feature !== "boolean";

  return (
    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_QSH_MODE_TITLE")}
        dataTestId={`${dataTestId}-type`}
      >
        {isAdvanced
          ? tPropertyPage("FEATURE_CARD_QSH_MODE_ADVANCED")
          : tPropertyPage("FEATURE_CARD_QSH_MODE_QUERY", {
              mode: feature ? "Exclude" : "Include",
            })}
      </DetailedFeatureCardBlock>

      {isAdvanced && (
        <>
          <DetailedFeatureCardBlock
            title={tPropertyPage("FEATURE_CARD_QSH_MODE_PARAMETERS_TITLE")}
            dataTestId={`${dataTestId}-parameters`}
          >
            <ExpandableList dataTestId="qshMode">
              {(feature as AdvancedType).nameList.map(renderColContent)}
            </ExpandableList>
          </DetailedFeatureCardBlock>

          <DetailedFeatureCardBlock
            title={tPropertyPage("FEATURE_CARD_QSH_MODE_ACTION_TITLE")}
            dataTestId={`${dataTestId}-action`}
          >
            {(feature as AdvancedType).actionType}
          </DetailedFeatureCardBlock>
        </>
      )}
    </DetailedFeatureCardRow>
  );
};
