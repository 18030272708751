import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, IconProps, Icons } from "../Icon/Icon";

export const ChevronDownIcon = (
  props: Omit<IconProps, "name"> & ChevronDownProps
): ReactElement => <ChevronDown {...props} name={Icons.CHEVRON_DOWN} />;

interface ChevronDownProps {
  open: boolean;
}

const ChevronDown = styled(Icon)<ChevronDownProps>`
  transition: transform 0.2s;
  transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
  fill: ${({ theme }) => theme.backgrounds.mutedDark};
`;
