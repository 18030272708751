import styled from "@emotion/styled";
import { FC } from "react";

import { formatNumber } from "../../../utils/number";

export interface ISortedTableHeaderRowProps {
  entry: string;
  metric: string;
}

export const SortedTableHeaderRow: FC<ISortedTableHeaderRowProps> = ({
  entry,
  metric,
}) => (
  <tr>
    <EntryLabel>{entry}</EntryLabel>
    <ValueLabel>{metric}</ValueLabel>
  </tr>
);

const EntryLabel = styled.th`
  width: 70%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #acb0b5;
  padding-bottom: 16px;
`;

const ValueLabel = styled.th`
  font-size: 16px;
  line-height: 24px;
  color: #acb0b5;
  padding-bottom: 16px;
`;

export interface ISortedTableBodyRowProps {
  name: string;
  value: number;
  dataTestId?: string;
}

export const SortedTableBodyRow: FC<ISortedTableBodyRowProps> = ({
  name,
  value,
  dataTestId,
}) => (
  <BodyRow>
    <EntryName data-testid={dataTestId && `${dataTestId}-name`}>
      {name}
    </EntryName>
    <td>
      <MainValue data-testid={dataTestId && `${dataTestId}-value`}>
        {formatNumber(value)}
      </MainValue>
    </td>
  </BodyRow>
);

const BodyRow = styled.tr`
  border-top: 1px solid #edf0f2;
`;

const EntryName = styled.td`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 16px 0px;
`;

const MainValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
