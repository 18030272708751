import styled from "@emotion/styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Status } from "../../../../@types/status";

import { Button } from "../../../../components/atoms/Button/Button";
import { Divider } from "../../../../components/atoms/Divider/Divider";
import { FlexBox } from "../../../../components/atoms/FlexBox/FlexBox";
import { Loader } from "../../../../components/atoms/Loader/Loader";
import { IModalChildren } from "../../../../components/atoms/Modal/Modal";
import { QueryLoader } from "../../../../components/atoms/QueryLoader/QueryLoader";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { FormSection } from "../../../../components/molecules/FormSection/FormSection";
import { TextArea } from "../../../../components/molecules/TextArea/TextArea";
import { createAccessGroup } from "../../../../models/user-management/access-group";
import { AccessGroupCreationData } from "../../../../models/user-management/access-group/access-group.interfaces";
import { AccessGroup } from "../../../../models/user/user.interfaces";
import { handleUpdateAccessGroupInfos } from "../../../../store/slices/user-management-access-group/thunk";
import { useAppDispatch } from "../../../../store/types";

type ParentAccessGroupInfo = Pick<AccessGroup, "id" | "name">;
type ChildAccessGroupInfo = Pick<AccessGroup, "id" | "name" | "description">;

export const ChildAccessGroupModal = ({
  closeModal,
  current,
  parent,
  domain,
  isEdit = false,
}: IModalChildren & {
  current: ChildAccessGroupInfo;
  parent: ParentAccessGroupInfo;
  domain: Pick<AccessGroup, "id">;
  isEdit?: boolean;
}): JSX.Element => {
  const [name, setName] = useState(current.name);
  const [description, setDescription] = useState(current.description || "");
  const [creationStatus, setCreationStatus] = useState<Status>(Status.IDLE);

  const history = useHistory();

  const { t } = useTranslation("accessGroupsPage");

  const dispatch = useAppDispatch();

  const handleCreateChildAccessGroup = async () => {
    const body: AccessGroupCreationData = {
      desc: description.replace(/\n/g, ""),
      name,
      parent: parent.id,
      domain: domain.id,
    };
    try {
      setCreationStatus(Status.LOADING);
      const { accessGroupId } = await createAccessGroup(body);
      setCreationStatus(Status.SUCCESS);
      handleCloseModal();
      history.push(`/userManagement/accessGroups/${accessGroupId}`);
    } catch (err) {
      setCreationStatus(Status.ERROR);
    }
  };

  const handleSaveAccessGroupInfos = () => {
    dispatch(
      handleUpdateAccessGroupInfos(
        current.id,
        {
          desc: description.replace(/\n/g, ""),
          name,
          parent: parent.id,
          domain: domain.id,
        },
        () => {
          if (closeModal) closeModal();
        }
      )
    );
  };

  const handleCloseModal = () => {
    setName("");
    setDescription("");
    if (closeModal) closeModal();
  };

  return (
    <Container>
      <Title>{t("ACCESS_GROUP_CREATION_MODAL_TITLE")}</Title>
      <ParentContainer>
        <div>
          <ParentText>
            {t("ACCESS_GROUP_CREATION_MODAL_PARENT_MESSAGE")}
          </ParentText>
          <ExpandableContent
            title={t("ACCESS_GROUP_CREATION_MODAL_EXPANDABLE_TITLE")}
            content={t("ACCESS_GROUP_CREATION_MODAL_EXPANDABLE_CONTENT")}
          />
        </div>
        <ParentName>{parent.name}</ParentName>
      </ParentContainer>
      <FormSection label={t("ACCESS_GROUP_CREATION_MODAL_NAME_LABEL")}>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder={t("ACCESS_GROUP_CREATION_MODAL_NAME_PLACEHOLDER")}
        />
      </FormSection>
      <StyledDivider />
      <FormSection label={t("ACCESS_GROUP_CREATION_MODAL_DESCRIPTION_LABEL")}>
        <TextArea
          value={description}
          onChange={setDescription}
          placeholder={t("ACCESS_GROUP_CREATION_MODAL_DESCRIPTION_PLACEHOLDER")}
        />
      </FormSection>
      <ButtonsContainer>
        <LoaderContainer>
          <QueryLoader
            status={creationStatus}
            render={{
              [Status.IDLE]: <></>,

              [Status.LOADING]: <Loader size={20} />,

              [Status.SUCCESS]: <></>,

              [Status.ERROR]: <></>,
            }}
          />
        </LoaderContainer>
        {creationStatus === Status.IDLE && (
          <Button
            label={t("ACCESS_GROUP_CREATION_MODAL_CANCEL_BUTTON")}
            onClick={handleCloseModal}
            backgroundColor="baseLight"
            textColor="primary"
          />
        )}
        {creationStatus === Status.SUCCESS ? (
          <Button label="Close" onClick={handleCloseModal} />
        ) : (
          <Button
            label={
              isEdit
                ? t("ACCESS_GROUP_CREATION_MODAL_SAVE_BUTTON")
                : t("ACCESS_GROUP_CREATION_MODAL_CREATE_BUTTON")
            }
            onClick={
              isEdit ? handleSaveAccessGroupInfos : handleCreateChildAccessGroup
            }
            disabled={creationStatus === Status.LOADING}
          />
        )}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const ParentContainer = styled(FlexBox)`
  justify-content: space-between;
  padding: 16px;
  background: #e6f0ff;
  border: 1px solid #6099f1;
  border-radius: 2px;
  margin-bottom: 32px;
`;

const ParentText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

const ParentName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 24px !important;
  margin-bottom: 24px !important;
`;

const LoaderContainer = styled(FlexBox)`
  padding: 4px 8px;
  margin-right: 8px;
`;

const ButtonsContainer = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 32px;
  & > button:not(:last-child) {
    margin-right: 8px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid #edf0f2;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;
