import styled from "@emotion/styled";
import { ReactElement, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { APIError } from "../../../models/error";

import {
  getAvailableRoles,
  updateRole,
} from "../../../models/user-management/access-group";
import { AccessGroupRole } from "../../../models/user-management/access-group/access-group.interfaces";
import { handleAPIError } from "../../../store/slices/api-error/thunks";
import { Button } from "../../atoms/Button/Button";
import { FlexBox, FlexBoxBase } from "../../atoms/FlexBox/FlexBox";
import { LabelValueDropdownButton } from "../../atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { IModalChildren } from "../../atoms/Modal/Modal";
import { Dropdown, IDropdownItem } from "../Dropdown/Dropdown";
import { useAppDispatch } from "../../../store/types";

interface EditUserAccessGroupRoleModalProps extends IModalChildren {
  userId: number;
  accessGroupId: number;
  role?: string;
  onSave?: (role: AccessGroupRole) => void;
}

export const EditUserAccessGroupRoleModal = ({
  userId,
  accessGroupId,
  role,
  onSave,
  closeModal,
}: EditUserAccessGroupRoleModalProps): ReactElement => {
  const [selectedRole, setSelectedRole] = useState<AccessGroupRole>();
  const [availableRoles, setAvailableRoles] = useState<
    IDropdownItem<AccessGroupRole>[]
  >([]);
  const { t } = useTranslation("roleModal");

  const dispatch = useAppDispatch();

  useEffect(() => {
    getAvailableRoles(accessGroupId)
      .then((roles) => {
        setAvailableRoles(
          roles.map((r) => ({
            label: r.name,
            value: r,
          }))
        );
        const roleItem = roles.find((r) => r.name === role);
        if (roleItem) {
          setSelectedRole(roleItem);
        }
      })
      .catch((err) => dispatch(handleAPIError(err as APIError)));
  }, [accessGroupId]);

  return (
    <Container>
      <Title>{t("TITLE")}</Title>
      <Content>
        <Label>{t("CDN_ROLE")}</Label>
        <DropdownContainer>
          <Dropdown<AccessGroupRole | undefined>
            customButton={
              <LabelValueDropdownButton
                value={selectedRole?.name}
                defaultValue={t("ROLE_DROPDOWN_DEFAULT_VALUE")}
              />
            }
            id="user-accessgroup-role-dropdown"
            placeholder=""
            items={availableRoles}
            onSelect={(item) => {
              setSelectedRole(item.value);
            }}
          />
        </DropdownContainer>
      </Content>
      <ButtonsContainer>
        <CancelButton
          label={t("CANCEL_BUTTON")}
          backgroundColor="baseLight"
          textColor="primary"
          onClick={closeModal || (() => {})}
        />
        <ApproveButton
          label={t("APPROVE_BUTTON")}
          onClick={async () => {
            if (selectedRole) {
              try {
                await updateRole(accessGroupId, userId, selectedRole.id);
                if (onSave) onSave(selectedRole);
              } catch (err) {
                dispatch(handleAPIError(err as APIError));
              } finally {
                if (closeModal) closeModal();
              }
            }
          }}
          disabled={selectedRole === undefined}
        />
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 32px;
`;

const Content = styled(FlexBox)`
  padding-bottom: 32px;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  width: 60%;
  .chi-dropdown {
    width: 100%;

    .chi-dropdown__menu {
      max-height: 300px !important;
      div {
        overflow: scroll;
        max-height: 300px;
      }
    }
  }
  #dropdown-users-status-dropdown {
    justify-content: space-between;
  }
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ButtonsContainer = styled(FlexBoxBase)`
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
  padding: 12px 16px;
`;

const ApproveButton = styled(Button)`
  padding: 12px 16px;
`;
