import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { HeadlessDataTable } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { SearchBar } from "../../../../../components/molecules/SearchBar/SearchBar";
import { IDropdownItem } from "../../../../../components/organisms/Dropdown/Dropdown";
import {
  Products as ProductsEnum,
  ProductsLabel,
} from "../../../../../models/user-management/access-group/access-group.interfaces";
import { PageTitle } from "../../../../../components/atoms/PageTitle/PageTitle";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../../components/atoms/UserManagementTable/UserManagementTable";
import { AddProductModalContent } from "./AddProductModalContent";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import {
  useAccessGroupAvailableProducts,
  useAccessGroupInfos,
} from "../../../../../store/slices/user-management-access-group/hooks";
import {
  buildProductsToDeleteList,
  displayMultiSelectHeader,
  handleFilterProductsTable,
  toggleExpandRow,
} from "./helpers";
import { useAppDispatch } from "../../../../../store/types";
import { handleUpdateProducts } from "../../../../../store/slices/user-management-access-group/thunk";
import { Checkbox } from "../../../../../components/molecules/Checkbox/Checkbox";
import { LabelValueDropdownButton } from "../../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { HeadlessDropDown } from "../../../../../components/organisms/Dropdown/HeadlessDropdown";
import { Button } from "../../../../../components/atoms/Button/Button";
import { NoShrink } from "../../../../../components/atoms/NoShrink/NoShrink";
import { BulkRemoveToaster } from "./BulkRemoveToaster";

const productDropdownItems: IDropdownItem<ProductsEnum>[] = [
  {
    value: ProductsEnum.CACHING,
    label: "Caching",
  },
  {
    value: ProductsEnum.ITM,
    label: "ITM",
  },
  {
    value: ProductsEnum.MESH_DELIVERY,
    label: "Mesh Delivery",
  },
  {
    value: ProductsEnum.OBJECT_STORAGE,
    label: "Object Storage",
  },
  {
    value: ProductsEnum.STORAGE,
    label: "Storage",
  },
];

export const Products = (): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");

  const [productsFilter, setProductsFilter] = useState<
    IDropdownItem<ProductsEnum>[]
  >([]);

  const availableProducts = useAccessGroupAvailableProducts();

  const displayedProducts = useMemo(
    () =>
      availableProducts?.filter(
        handleFilterProductsTable(
          searchInput,
          productsFilter.map((item) => item.value)
        )
      ) || [],
    [availableProducts, searchInput, productsFilter]
  );

  const { t } = useTranslation("accessGroupsPage");

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const accessGroupInfos = useAccessGroupInfos();

  const dispatch = useAppDispatch();

  const handleDeleteProducts = (productsToRemove: string[]) => {
    if (accessGroupInfos) {
      dispatch(
        handleUpdateProducts(
          accessGroupInfos.accessGroupId,
          buildProductsToDeleteList(displayedProducts, productsToRemove)
        )
      );
    }
  };

  return (
    <div>
      <BulkRemoveToaster
        open={selectedRows.length > 0}
        text={
          selectedRows.length > 1
            ? t("ACCESS_GROUP_REMOVE_PRODUCTS_TOASTER_TEXT_PLURAL", {
                count: selectedRows.length,
              })
            : t("ACCESS_GROUP_REMOVE_PRODUCTS_TOASTER_TEXT_SINGULAR", {
                count: selectedRows.length,
              })
        }
        label="Remove"
        onClick={() => {
          handleDeleteProducts(selectedRows);
          setSelectedRows([]);
        }}
      />
      <PageTitle>{t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_TITLE")}</PageTitle>
      <ExpandableContent
        title={t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_EXPAND_TITLE")}
        content={t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_EXPAND_CONTENT")}
      />
      <TableHeaderContainer>
        <FilterContainer>
          <SearchBarContainer>
            <SearchBar
              placeholder={t(
                "ACCESS_GROUP_PAGE_PRODUCT_TABLE_SEARCH_PLACEHOLDER"
              )}
              onChange={setSearchInput}
            />
          </SearchBarContainer>
          <NoShrink>
            <HeadlessDropDown
              button={(props) => (
                <LabelValueDropdownButton
                  placeholder={t(
                    "ACCESS_GROUP_PAGE_PRODUCT_TABLE_PRODUCT_TYPE_FILTER_PLACEHOLDER"
                  )}
                  value={displayMultiSelectHeader(
                    productDropdownItems,
                    productsFilter
                  )}
                  {...props}
                />
              )}
              items={productDropdownItems}
              multiSelect
              selectedItems={productsFilter}
              setSelectedItems={setProductsFilter}
            />
          </NoShrink>
          <Modal
            customButton={
              <AddProductsButton
                label={t("ACCESS_GROUPS_TABLE_PRODUCT_ADD")}
                onClick={() => {}}
              />
            }
            size="large"
          >
            <AddProductModalContent />
          </Modal>
        </FilterContainer>
      </TableHeaderContainer>
      <TableContainer>
        <HeadlessDataTable
          data={displayedProducts}
          columns={[
            {
              dataField: "scid",
              name: t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_SCID_LABEL"),
              formatter: (row, rowIndex) => {
                if (
                  row.product !== ProductsEnum.CACHING ||
                  !row.childServiceList
                ) {
                  return (
                    <ScidCell>
                      <Checkbox
                        id={row.scid}
                        label=""
                        onChange={() => {
                          setSelectedRows(toggleExpandRow(row.scid));
                        }}
                        checked={selectedRows.includes(row.scid)}
                      />
                      <ScidText>{row.scid}</ScidText>
                    </ScidCell>
                  );
                }
                return (
                  <ScidCell>
                    <Checkbox
                      id={row.scid}
                      label=""
                      onChange={() => {
                        setSelectedRows(toggleExpandRow(row.scid));
                      }}
                      checked={selectedRows.includes(row.scid)}
                    />
                    <ScidText>{row.scid}</ScidText>
                    <ClickDescription
                      onClick={() => {
                        setExpandedRows(toggleExpandRow(rowIndex));
                      }}
                    >
                      <SpinningIcon
                        isOpen={expandedRows.includes(rowIndex)}
                        name={Icons.ARROW_FAT}
                        color="black"
                        size={8}
                        onClick={() => {
                          setExpandedRows(toggleExpandRow(rowIndex));
                        }}
                      />
                      <DescriptionText>
                        {t("ACCESS_GROUP_PROPERTIES_COUNT", {
                          partialCount: row.selected
                            ? row.childServiceList?.length || 0
                            : row.childServiceList?.filter(
                                (prop) => prop.selected
                              ).length || 0,
                          totalCount: row?.childServiceList?.length,
                        })}
                      </DescriptionText>
                    </ClickDescription>
                  </ScidCell>
                );
              },
              style: { width: "50%" },
            },
            {
              name: t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_TYPE_LABEL"),
              formatter: (row) => {
                if (row.product !== ProductsEnum.CACHING) {
                  return " ";
                }
                return row.selected ||
                  row.childServiceList?.filter((prop) => prop.selected)
                    .length === row.childServiceList?.length ? (
                  <ProductCell>
                    {t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_TYPE_FULL")}
                  </ProductCell>
                ) : (
                  <ProductCell>
                    {t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_TYPE_PARTIAL")}
                  </ProductCell>
                );
              },
            },
            {
              dataField: "product",
              name: t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_PRODUCT_LABEL"),
              formatter: (row) => (
                <ProductCell>
                  {ProductsLabel.get(row.product as ProductsEnum)}
                </ProductCell>
              ),
            },
            {
              name: "",
              formatter: (row) => (
                <ProductCell>
                  <Icon
                    name={Icons.TRASH}
                    color="grey40"
                    onClick={() => {
                      handleDeleteProducts([row.scid]);
                    }}
                  />
                </ProductCell>
              ),
            },
          ]}
          expandParams={{
            expandedRows,
            render: (row) => (
              <td colSpan={3}>
                <AliasesCell>
                  {row.childServiceList
                    ?.filter((property) =>
                      row.selected ? true : property.selected
                    )
                    .map(({ id, name }) => (
                      <AliasName key={id}>{name}</AliasName>
                    ))}
                </AliasesCell>
              </td>
            ),
          }}
          keyField="scid"
          TableWrapper={UserManagementTable}
          TableHeadRow={StyledHeaderTr}
          TableHeadRowCell={UserManagementTableHeaderCell}
          TableBodyRow={UserManagementTableBodyRow}
          TableBodyRowCell={StyledBodyCell}
        />
      </TableContainer>
    </div>
  );
};

const StyledBodyCell = styled.td`
  padding-top: 16px !important;
  padding-bottom: 16px;
`;

const StyledHeaderTr = styled(UserManagementTableHeaderRow)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;

const TableContainer = styled.div`
  max-height: 30vh;
  overflow-y: auto;
`;

const AliasesCell = styled.div`
  padding: 10px;
  margin-left: 20px;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ScidText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.primary};
`;

const ProductCell = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #${({ theme }) => theme.text.secondary};
`;

const AliasName = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.primary};
`;

const TableHeaderContainer = styled(FlexBox)`
  justify-content: space-between;
`;

const FilterContainer = styled(FlexBox)`
  gap: 8px;
  width: 100%;
`;

const SearchBarContainer = styled.div`
  width: 100%;
`;

const ScidCell = styled(FlexBox)`
  justify-content: flex-start;
`;

const ClickDescription = styled(FlexBox)`
  justify-content: flex-start;
  cursor: pointer;
`;

const SpinningIcon = styled(Icon)<{ isOpen: boolean }>`
  margin-left: 12px;
  margin-right: 12px;
  transform: rotate(${({ isOpen }) => (isOpen ? "90deg" : "-90deg")});
`;

const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.muted};
`;

const AddProductsButton = styled(Button)`
  padding: 12px 16px;
`;
