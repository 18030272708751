import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../../../../../components/atoms/Modal/Modal";
import { TrashIcon } from "../../../../../../../../components/atoms/TrashIcon/TrashIcon";
import { AddFeatureCard } from "../../../../../../../../components/molecules/AddFeatureCard/AddFeatureCard";
import { PresentationModal } from "../../../../../../../../components/molecules/PresentationModal/PresentationModal";
import { PropertyDefinitionBlock } from "../../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import { OriginTypes } from "../../../../../../../../models/configuration/definitions/origin";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
} from "../../../../../../../../models/configuration/errors";
import { useConfigurationsErrors } from "../../../../../../../../store/slices/caching/hooks";
import {
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../../../store/slices/caching/types";
import { AddOriginDefinitionModal } from "./AddOriginDefinitionModal";
import { OriginFields } from "./OriginFields";

interface OriginSectionProps {
  originDefinition: OriginDefinitionType | undefined;
  property: PropertyDefinitionType;
  onChange: (
    originDefinition: Partial<OriginDefinitionType> | undefined
  ) => void;
  onPropertyChange: (newDefinition: PropertyDefinitionType) => void;
}

export const OriginSection = ({
  originDefinition,
  property,
  onChange,
  onPropertyChange,
}: OriginSectionProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(true);
  const [currentOriginDefinition, setCurrentOriginDefinition] = useState(
    originDefinition
  );

  const { t } = useTranslation("configurationPropertyPage");

  const errors = useConfigurationsErrors();

  useEffect(() => {
    setCurrentOriginDefinition(originDefinition);
  }, [originDefinition, property.originFillPolicy]);

  return (
    <OriginSectionContainer>
      <Subsection>{t("PROPERTY_SECTION_ORIGIN_TITLE")}</Subsection>
      <OriginForm>
        {currentOriginDefinition ? (
          <PropertyDefinitionBlock
            dataTestId="origin"
            onArrowClick={setIsOpen}
            title={currentOriginDefinition.name || " "}
            badgeLabel={currentOriginDefinition.originType}
            badgeColor={
              currentOriginDefinition.originType === OriginTypes.FAILOVER
                ? "pink70"
                : "purple70"
            }
            initIsOpen
            customDeleteButton={
              <Modal customButton={<TrashIcon />}>
                <PresentationModal
                  title={t("ORIGIN_DELETE_MODAL_TITLE")}
                  onSubmit={() => {
                    setCurrentOriginDefinition(undefined);
                    onChange(undefined);
                    onPropertyChange({
                      ...property,
                      originFillPolicy: undefined,
                    });
                  }}
                  button={{
                    label: t("ORIGIN_DELETE_MODAL_REMOVE_BUTTON_LABEL"),
                    backgroundColor: "red60",
                    textColor: "primaryAlt",
                  }}
                >
                  {t("ORIGIN_DELETE_MODAL_CONTENT")}
                </PresentationModal>
              </Modal>
            }
            propertyCards={
              isOpen ? (
                currentOriginDefinition && (
                  <OriginFields
                    originDefinition={currentOriginDefinition}
                    onChange={onChange}
                  />
                )
              ) : (
                <></>
              )
            }
          />
        ) : (
          <PropertyDefinitionBlock
            dataTestId="add-origin-definition"
            propertyCards={
              <>
                <AddFeatureCard
                  title={t("PROPERTY_CARD_ORIGIN_DEFINITION_NAME_TITLE")}
                  customButton={
                    <AddOriginDefinitionModal
                      updateProperty={onPropertyChange}
                      updateOrigin={onChange}
                      currentProperty={property}
                    />
                  }
                  description={t(
                    "PROPERTY_CARD_ORIGIN_DEFINITION_NAME_DESCRIPTION_CONTENT"
                  )}
                  required
                  errorMessage={
                    errors.some(
                      (err) =>
                        err.level === ConfigurationErrorLevel.ERROR &&
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_EMPTY
                    )
                      ? t("ERROR_ORIGIN_DEFINITION_EMPTY")
                      : undefined
                  }
                />
              </>
            }
          />
        )}
      </OriginForm>
    </OriginSectionContainer>
  );
};

const OriginSectionContainer = styled.div`
  margin-bottom: 40px;
`;

const Subsection = styled.h4`
  margin-bottom: 16px !important;
`;

const OriginForm = styled.div``;
