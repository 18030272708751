import { ConfigurationType } from "../types";
import { prepareConfigDetails } from "./prepareConfigDetails";

export type PreparedConfiguration = ReturnType<typeof prepareConfig>;

export const prepareConfig = (
  isStorage: boolean,
  _config: ConfigurationType
) => {
  const { config, isLocalDraft, unhandledFields, ...configuration } = _config;
  if (isStorage) {
    return {
      ...Object.fromEntries(unhandledFields),
      ...configuration,
      config: config ? prepareConfigDetails(isStorage, config) : undefined,
    };
  } else {
    const { id, ...rest } = configuration;
    return {
      ...Object.fromEntries(unhandledFields),
      ...rest,
      config: config ? prepareConfigDetails(isStorage, config) : undefined,
    };
  }
};
