import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoaderContainer } from "../../atoms/LoaderContainer/LoaderContainer";
import { ExpandableContent } from "../../molecules/ExpandableContent/ExpandableContent";
import { Loader } from "../../atoms/Loader/Loader";
import { SearchBar } from "../../molecules/StructuredList/SearchBar";
import {
  Bold,
  Column,
  DataContainer,
  Line,
  Regular,
  Title,
} from "../../molecules/StructuredList/StructuredList";
import { useIsMounted } from "../../../hooks/use-is-mounted";
import { Configuration } from "../../../models/configuration";
import { ICnames } from "../../../models/configuration/production";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../store/slices/subscriber/hooks";
import { SubscriberDataNotFoundError } from "../../../models/error/SubscriberDataNotFoundError";
import { APIError } from "../../../models/error";

export const CnameListTab: FC = () => {
  const { t } = useTranslation("productionPage");
  const isMounted = useIsMounted();
  const [cnamesData, setCnamesData] = useState<ICnames>();
  const [searchValue, setSearchValue] = useState("");
  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();

  useEffect(() => {
    Configuration.production
      .getActiveCnames(subscriberId.toString())
      .then((data) => {
        if (isMounted) setCnamesData(data);
      })
      .catch((err) => {
        const is404Error = err instanceof SubscriberDataNotFoundError;
        if (is404Error) {
          if (isMounted) setCnamesData({ cnames: [] });
        } else {
          handleMediaPortalError(err as APIError);
        }
      });
  }, [subscriberId, isMounted]);

  const filteredCnames = useCallback(():
    | { cname: string; alias: string }[]
    | undefined => {
    if (cnamesData) {
      return cnamesData.cnames.filter(
        (item) =>
          item.alias.includes(searchValue.trim()) ||
          item.cname.includes(searchValue.trim())
      );
    }
  }, [searchValue, cnamesData]);

  return (
    <>
      <ExpandableContent
        title={t("CNAMES_DESCRIPTION_TITLE")}
        content={t("CNAMES_DESCRIPTION_CONTENT")}
      />
      <br />
      {cnamesData ? (
        <>
          <SearchBar
            onSearchChange={setSearchValue}
            searchPlaceholder={t("CNAME_LIST_SEARCH_BAR_PLACEHOLDER")}
            searchTestID="production-cnames-input"
          />
          <DataContainer>
            <Line>
              <Column width="50%">
                <Title>{t("CNAME_LIST_COLUMN_ALIAS")}</Title>
              </Column>
              <Column width="50%">
                <Title>{t("CNAME_LIST_COLUMN_CNAME")}</Title>
              </Column>
            </Line>
            {filteredCnames()?.map((data, index) => (
              <Line key={index} data-testid={`production-cnames-line-${index}`}>
                <Column width="50%">
                  <Bold>{data.alias}</Bold>
                </Column>
                <Column width="50%">
                  <Regular>{data.cname}</Regular>
                </Column>
              </Line>
            ))}
          </DataContainer>
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  );
};
