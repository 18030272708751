import styled from "@emotion/styled";
import { format } from "date-fns";
import { Point, SliceTooltipProps } from "@nivo/line";

import { FlexBox } from "../FlexBox/FlexBox";
import { formatNumber } from "../../../utils/number";

const handleSortPoints = (a: Point, b: Point): number => {
  return (b.data.y as number) - (a.data.y as number);
};

export const MetricsTooltip = ({ slice }: SliceTooltipProps): JSX.Element => (
  <TooltipContainer>
    <div>{format(new Date(slice.points[0].data.x), "PPpp")}</div>
    {slice.points.sort(handleSortPoints).map((point) => (
      <ToolipRow key={point.id}>
        <TooltipLegend>
          <Square color={point.serieColor} />
          <TooltipSerieId>{point.serieId}</TooltipSerieId>
        </TooltipLegend>
        <TooltipValue>{formatNumber(point.data.y as number)}</TooltipValue>
      </ToolipRow>
    ))}
  </TooltipContainer>
);

const Square = styled.div<{ color: string }>`
  width: 1rem;
  height: 1rem;
  background-color: ${({ color }) => color};
  margin-right: 10px;
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
`;

const TooltipSerieId = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ToolipRow = styled(FlexBox)`
  justify-content: space-between;
`;

const TooltipLegend = styled(FlexBox)`
  margin-right: 10px;
`;

const TooltipValue = styled.div`
  font-weight: 700;
`;

const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 5px;
  padding: 9px 12px;
`;
