import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../../../components/atoms/Button/Button";
import {
  IModalChildren,
  Modal,
} from "../../../../../../../../components/atoms/Modal/Modal";
import { InputField } from "../../../../../../../../components/molecules/InputField/InputField";
import { NotificationCard } from "../../../../../../../../components/molecules/NotificationCard/NotificationCard";
import { Dropdown } from "../../../../../../../../components/organisms/Dropdown/Dropdown";
import {
  IRoute,
  Tabs,
} from "../../../../../../../../components/organisms/Tabs/Tabs";
import { OriginTypes } from "../../../../../../../../models/configuration/definitions/origin";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
} from "../../../../../../../../models/configuration/errors";
import { createOriginDefinition } from "../../../../../../../../store/slices/caching/helpers/origin-definition/createOriginDefinition";
import { useSelectedConfiguration } from "../../../../../../../../store/slices/caching/hooks";
import { handleRemoveConfigurationError } from "../../../../../../../../store/slices/caching/thunks";
import {
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../../../store/types";
import { isDefinitionNameValid } from "../../../../../../../../utils/string";
import { generateDefaultMember } from "../../../../../../../../store/slices/caching/helpers/generateDefaultMember";
import { getOriginDefinitionPropertyReferences } from "../../../../../../../../store/slices/caching/helpers/getOriginDefinitionPropertyReferences";
import { getOriginReferences } from "../../../../../../../../store/slices/caching/helpers/origin-definition/getOriginReferences";

interface AddOriginDefinitionModalProps {
  updateProperty: (newDefinition: PropertyDefinitionType) => void;
  updateOrigin: (newOrigin: OriginDefinitionType | undefined) => void;
  currentProperty: PropertyDefinitionType;
}

enum ModeRoute {
  SELECT = "select",
  CREATE = "create",
}

export const AddOriginDefinitionModal = ({
  updateProperty,
  updateOrigin,
  currentProperty,
}: AddOriginDefinitionModalProps): ReactElement => {
  const { t } = useTranslation("misc");

  return (
    <Modal
      size="medium"
      customButton={
        <AddButton
          className="chi-button -primary"
          data-testid="add-origin-definition-button"
        >
          <PlusSign>+</PlusSign>
          <div>{t("ADD")}</div>
        </AddButton>
      }
    >
      <ChildrenModal
        updateProperty={updateProperty}
        updateOrigin={updateOrigin}
        currentProperty={currentProperty}
      />
    </Modal>
  );
};
interface ChildrenModalProps extends IModalChildren {
  updateProperty: (newDefinition: PropertyDefinitionType) => void;
  updateOrigin: (newOrigin: OriginDefinitionType | undefined) => void;
  currentProperty: PropertyDefinitionType;
}

const ChildrenModal = ({
  closeModal,
  updateProperty,
  updateOrigin,
  currentProperty,
}: ChildrenModalProps): ReactElement => {
  const [tMisc] = useTranslation("misc");
  const [tConfigPage] = useTranslation("configurationPropertyPage");
  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();

  const [
    selectedOriginDefinition,
    setSelectedOriginDefinition,
  ] = useState<OriginDefinitionType>();
  const routes: IRoute[] = [
    {
      label: tConfigPage("ORIGIN_DEFINITION_CREATE_SHORT"),
      route: ModeRoute.CREATE,
    },
    {
      label: tConfigPage("ORIGIN_DEFINITION_SELECT_SHORT"),
      route: ModeRoute.SELECT,
    },
  ];

  const [mode, setMode] = useState<IRoute>(routes[0]);
  const [
    newOriginDefinitionName,
    setNewOriginDefinitionName,
  ] = useState<string>("");

  const [error, setError] = useState<string>();

  const originDefinitionListItems = selectedConfiguration?.config?.originFillPolicyDefinitions.map(
    (od) => ({
      value: od,
      label: od.name,
    })
  );

  const currentIndex = routes.findIndex((tab) => tab.route === mode.route);

  const onNewNameChange = (value: string) => {
    setNewOriginDefinitionName(value);
    if (!isDefinitionNameValid(value)) {
      setError(tConfigPage("ERROR_ORIGIN_DEFINITION_NAME_REGEX"));
    } else if (
      selectedConfiguration?.config?.originFillPolicyDefinitions?.some(
        (originDefinition) => originDefinition.name === value
      )
    ) {
      setError(tConfigPage("ORIGIN_DEFINITION_NAME_ALREADY_USED"));
    } else {
      setError(undefined);
    }
  };

  useEffect(() => {
    if (mode.route === ModeRoute.CREATE) {
      setSelectedOriginDefinition(undefined);
    } else {
      setNewOriginDefinitionName("");
      setError(undefined);
    }
  }, [mode]);

  return (
    <ModalChildrenWRapper>
      <Title>
        {mode.route === ModeRoute.SELECT
          ? tConfigPage("ORIGIN_DEFINITION_SELECT_ONE")
          : tConfigPage("ORIGIN_DEFINITION_CREATE_ONE")}
      </Title>
      {selectedConfiguration?.config?.originFillPolicyDefinitions.length ? (
        <Tabs
          initialIndex={currentIndex}
          onSelectionChange={setMode}
          tabsList={routes}
        />
      ) : null}
      <ModalBody>
        {mode.route === ModeRoute.SELECT ? (
          <>
            <Dropdown
              items={originDefinitionListItems || []}
              id="origin-definition-selector"
              placeholder={
                selectedOriginDefinition?.name ||
                tConfigPage("ORIGIN_DEFINITION_SELECT_ONE")
              }
              onSelect={(item) => setSelectedOriginDefinition(item.value)}
            />
            {selectedOriginDefinition &&
              getOriginDefinitionPropertyReferences(
                selectedOriginDefinition.name,
                selectedConfiguration!.config!
              ) > 0 && (
                <NotificationCard
                  theme="warning"
                  title={tConfigPage(
                    "WARNING_ORIGIN_DEFINITION_ALREADY_USED_TITLE",
                    {
                      matchRulesCount: getOriginReferences(
                        selectedOriginDefinition,
                        selectedConfiguration!.config!
                      ),
                      propertyCount: getOriginDefinitionPropertyReferences(
                        selectedOriginDefinition.name,
                        selectedConfiguration!.config!
                      ),
                    }
                  )}
                  content={tConfigPage(
                    "WARNING_ORIGIN_DEFINITION_ALREADY_USED_DESCRIPTION"
                  )}
                />
              )}
          </>
        ) : (
          <>
            <InputField
              hasBorder
              onChange={onNewNameChange}
              value={newOriginDefinitionName}
              placeholder={tConfigPage("ORIGIN_DEFINITION_CREATE_NEW_NAME")}
              dataTestId="new-origin-definition-name-input"
            />
            {error && <Error>{error}</Error>}
          </>
        )}
      </ModalBody>
      <Buttons>
        <Button
          backgroundColor="baseLight"
          textColor="highlight"
          borderColor="highlight"
          label={tMisc("CANCEL")}
          onClick={() => {
            closeModal && closeModal();
          }}
        />
        <ButtonContainer>
          <Button
            disabled={
              (mode.route === ModeRoute.CREATE &&
                newOriginDefinitionName === "") ||
              (mode.route === ModeRoute.SELECT &&
                selectedOriginDefinition === undefined) ||
              error !== undefined
            }
            label={tMisc("CREATE")}
            onClick={() => {
              if (selectedConfiguration && selectedConfiguration.config) {
                if (mode.route === ModeRoute.CREATE) {
                  currentProperty.originFillPolicy = newOriginDefinitionName;
                  updateProperty(currentProperty);
                  updateOrigin(
                    createOriginDefinition(newOriginDefinitionName, {
                      type: OriginTypes.SINGLE,
                      members: [generateDefaultMember()],
                    })
                  );
                } else {
                  if (selectedOriginDefinition) {
                    updateProperty({
                      ...currentProperty,
                      originFillPolicy: selectedOriginDefinition.name,
                    });
                    updateOrigin(selectedOriginDefinition);
                  }
                }
                dispatch(
                  handleRemoveConfigurationError(
                    (err) =>
                      !(
                        err.level === ConfigurationErrorLevel.ERROR &&
                        err.type ===
                          ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_EMPTY
                      )
                  )
                );
                closeModal && closeModal();
              }
            }}
            dataTestId="new-origin-definition-submit-button"
          />
        </ButtonContainer>
      </Buttons>
    </ModalChildrenWRapper>
  );
};

const ModalBody = styled.div`
  padding: 40px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalChildrenWRapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 32px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const Title = styled.h3`
  margin-bottom: 20px !important;
  text-align: center;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.text.error};
  font-size: 12px;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
`;

const PlusSign = styled.div`
  font-size: 32px;
  font-weight: 300;
  padding: 0px 4px 4px 0px;
`;
