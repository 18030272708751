import { IConfigDetailsData } from "../models/configuration/configuration_details";
import { ORDERED_DEF_NAMES } from "../store/slices/caching/constants";
import { prepareConfigDetails } from "../store/slices/caching/helpers/prepareConfigDetails";
import { ConfigurationDetailsType } from "../store/slices/caching/types";

export const getConfigDetailsByDefinitions = (
  configDetails: ConfigurationDetailsType,
  definitionsToDisplay: typeof ORDERED_DEF_NAMES
): IConfigDetailsData =>
  prepareConfigDetails(false, {
    genSchemaVersion: configDetails.genSchemaVersion,
    subscriberId: configDetails.subscriberId,
    unhandledFields: configDetails.unhandledFields,

    ...definitionsToDisplay.reduce((_configDetails, defName) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      _configDetails[defName] = configDetails[defName] ?? {};
      return _configDetails;
    }, {} as Omit<ConfigurationDetailsType, "genSchemaVersion" | "subscriberId" | "unhandledFields">),
  });
