import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { ErrorLevel, APIError } from "../../../models/error";
import { Button } from "../../atoms/Button/Button";

interface ErrorToastProps {
  error: APIError;
  onViewMoreDetailsClick: () => void;
}

export const ErrorToast = ({
  error,
  onViewMoreDetailsClick,
}: ErrorToastProps): JSX.Element => {
  const { t } = useTranslation("misc");

  // In order to keep the behaviour with CMv3 errors
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const statusCode = error?.data?.status;

  return (
    <ErrorToastContainer>
      <ErrorMessageTitle errorLevel={error.errorLevel}>
        {t("ERROR_MODAL_FAILURE_TITLE")}
      </ErrorMessageTitle>
      <ErrorMessage>
        {statusCode
          ? t("ERROR_MODAL_FAILURE_DESCRIPTION", {
              status: statusCode,
            })
          : error.message}
      </ErrorMessage>
      {error.data && (
        <Button
          label={t("VIEW_MORE_DETAILS")}
          onClick={onViewMoreDetailsClick}
        />
      )}
    </ErrorToastContainer>
  );
};

const ErrorToastContainer = styled.div``;

const ErrorMessageTitle = styled.b<{ errorLevel: ErrorLevel }>`
  color: ${({ theme, errorLevel }) =>
    errorLevel === ErrorLevel.WARNING ? theme.text.warning : theme.text.error};
`;

const ErrorMessage = styled.p``;
