import { AxiosError } from "axios";

import { Status } from "../../../@types/status";
import { httpClient } from "../../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../../../models/error";
import { SubscriberInfo } from "../../../models/subscriber";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import { setStatus, setSubscriberSettings } from "./slice";

export const fetchSubscriberSettings = (
  subscriberId: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(setStatus(Status.LOADING));

    const res = await httpClient.get<SubscriberInfo>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}`
    );

    dispatch(setSubscriberSettings(res.data));

    dispatch(setStatus(Status.SUCCESS));
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    dispatch(
      handleAPIError(
        new APIError(
          error.message,
          "fetchSubscriberSettings()",
          ErrorLevel.ERROR,
          error.response
        )
      )
    );

    dispatch(setStatus(Status.ERROR));
  }
};
