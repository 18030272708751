import { cloneDeep } from "lodash";
import { ConfigurationDefinition } from "../store/slices/caching/types";

export const isObject = (obj: unknown): boolean => {
  return typeof obj === "object" && !Array.isArray(obj);
};

export const cloneObjectInstance = <T = any>(instanceToClone: T): T => {
  return instanceToClone !== undefined
    ? Object.assign(
        Object.create(Object.getPrototypeOf(instanceToClone)),
        instanceToClone
      )
    : instanceToClone;
};

export const cloneDefinition = <T extends ConfigurationDefinition>(
  def: Partial<T> | undefined
): T | undefined => {
  if (def === undefined) {
    return def;
  }
  const res = cloneDeep(def as T);
  return res;
};

// This function removes all attributes of an object that are an empty array, or an empty oject
export const removeDeadPaths = <T = any>(
  object: T,
  exceptions?: string[]
): T => {
  const entries = Object.entries(object);

  const newEntries = entries
    .map(([key, value]) => {
      if (isObject(value)) {
        return [
          key,
          removeDeadPaths(
            value as Record<string, Record<string, unknown>>,
            exceptions
          ),
        ];
      }

      if (Array.isArray(value)) {
        const newValue = value.map((element) => {
          if (isObject(element)) {
            return removeDeadPaths(element, exceptions);
          }

          return element;
        });

        return [key, newValue];
      }
      return [key, value];
    })
    .filter(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        if (exceptions && exceptions.includes(key)) {
          return true;
        }
        return false;
      }

      if (
        isObject(value) &&
        Object.keys(value as Record<string, unknown>).length === 0
      ) {
        if (exceptions) {
          if (!exceptions.includes(key)) {
            return false;
          }
        } else {
          return false;
        }
      }

      if (value === undefined || value === null) {
        return false;
      }

      return true;
    });

  return Object.fromEntries(newEntries);
};
