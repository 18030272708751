import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import {
  useProperties,
  useSelectedConfiguration,
} from "../../../store/slices/caching/hooks";
import { handleSaveSelectedConfiguration } from "../../../store/slices/caching/thunks";
import {
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../store/slices/caching/types";
import { useAppDispatch } from "../../../store/types";
import { PropertyListItem } from "../../molecules/PropertyListItem/PropertyListItem";

interface PropertyListProps {
  properties: PropertyDefinitionType[];
  originDefinitions?: OriginDefinitionType[];
}

export const PropertyList = ({
  properties,
  originDefinitions,
}: PropertyListProps): ReactElement => {
  const subscriberProperties = useProperties();

  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const handleDelete = (index: number) => {
    if (selectedConfiguration) {
      dispatch(
        handleSaveSelectedConfiguration({
          ...selectedConfiguration,
          config: {
            ...selectedConfiguration.config!,
            propertyDefinitions: selectedConfiguration.config!.propertyDefinitions.filter(
              (_, i) => i !== index
            ),
          },
        })
      );
    }
  };

  return (
    <PropertiesListContainer>
      {properties &&
        originDefinitions &&
        properties.map((property, i) => (
          <PropertyCardWrapper key={i + 1}>
            <PropertyListItem
              name={`${property.primaryAlias}`}
              property={property}
              originDefinition={
                originDefinitions.find(
                  (origin) => property.originFillPolicy === origin.name
                ) || ({} as OriginDefinitionType)
              }
              index={i + 1}
              isInRegistry={subscriberProperties.some(
                (p) => p.name === property.name
              )}
              onEdit={() =>
                history.push(
                  `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${property.name}`
                )
              }
              onDelete={() => handleDelete(i)}
              dataTestId={`property-card-${i}`}
            />
          </PropertyCardWrapper>
        ))}
    </PropertiesListContainer>
  );
};

const PropertiesListContainer = styled.div`
  padding-bottom: 2rem;
`;

const PropertyCardWrapper = styled.div`
  margin-bottom: 20px;
`;
