import { AccessGroup } from "./../../../models/user/user.interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status } from "../../../@types/status";
import { AccessGroupsSlice } from "./types";

const initialState: AccessGroupsSlice = {
  accessGroupId: undefined,
  accessGroups: [],
  accessGroupsQueryStatus: Status.IDLE,
};

export const accessGroupsSlice = createSlice({
  name: "accessGroups",
  initialState,
  reducers: {
    setAccessGroupId: (state, action: PayloadAction<number>) => {
      state.accessGroupId = action.payload;
    },
    setAccessGroups: (state, action: PayloadAction<AccessGroup[]>) => {
      state.accessGroups = action.payload;
    },
    setAccessGroupsQueryStatus: (state, action: PayloadAction<Status>) => {
      state.accessGroupsQueryStatus = action.payload;
    },
  },
});

export const {
  setAccessGroupId,
  setAccessGroups,
  setAccessGroupsQueryStatus,
} = accessGroupsSlice.actions;

export default accessGroupsSlice.reducer;
