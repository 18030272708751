import { Status } from "../../../@types/status";
import { APIError } from "../../../models/error";
import {
  getVyvxAvailableFiberCompanies,
  getVyvxAvailableRoles,
  getVyvxAvailableSatelliteCompanies,
} from "../../../models/vyvx";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import { setVyvxData, setVyvxStatus } from "./slice";

export const fetchVyvxData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setVyvxStatus(Status.LOADING));
    const [
      availableRoles,
      availableFiberCompanies,
      availableSatelliteCompanies,
    ] = await Promise.all([
      getVyvxAvailableRoles(),
      getVyvxAvailableFiberCompanies(),
      getVyvxAvailableSatelliteCompanies(),
    ]);

    dispatch(
      setVyvxData({
        availableRoles,
        availableFiberCompanies,
        availableSatelliteCompanies,
      })
    );
    dispatch(setVyvxStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setVyvxStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};
