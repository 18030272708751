import { ReactElement, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { UserRoles } from "../../../models/permissions";
import {
  AddCertificateModal,
  IAddCertificateModalProps,
} from "../../../modules/caching/cmv3_certificates/certificates_page/AddCertificateModal";
import { CertificateType } from "../../../store/slices/caching/types";
import { Protected } from "../../atoms/Protected/Protected";
import { ListCard } from "../../molecules/CertificateListItem/CertificateListItem";
import { ExpandableContent } from "../../molecules/ExpandableContent/ExpandableContent";
import { InputField } from "../../molecules/InputField/InputField";
import { getCertBadgeInfo } from "../../../store/slices/caching/helpers/getCertBadgeInfo";
import { getCertificateStatus } from "../../../store/slices/caching/helpers/getCertificateStatus";
import { getCertMetadata } from "../../../store/slices/caching/helpers/getCertMetadata";

export const CertificatesList = ({
  certificates,
}: IAddCertificateModalProps): ReactElement => {
  const { t } = useTranslation("certificatesPage");

  const [search, setSearch] = useState("");

  const filterCertificates = useCallback(
    (certificate: CertificateType) => {
      return [
        certificate.cert_name,
        getCertificateStatus(certificate.cert_states),
        certificate.type,
        getCertBadgeInfo(certificate).text,
      ]
        .flat()
        .some((fieldValue: string) =>
          fieldValue.toLocaleLowerCase().match(search.toLowerCase())
        );
    },
    [search]
  );

  const history = useHistory();

  const handleDisplayCertificate = (certName: string) => () => {
    history.push(`/cmv3/certificates/${certName}/edit`);
  };

  return (
    <>
      <Header>
        <StyledSearchBar
          hasBorder
          isSearchField
          placeholder={t("SEARCH_CERTIFICATE_PLACEHOLDER")}
          onChange={setSearch}
        />
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          <AddCertificateModal certificates={certificates} />
        </Protected>
      </Header>
      <ExpandableContentContainer>
        <ExpandableContent
          title={t("CERTIFICATES_DESCRIPTION_TITLE")}
          content={t("CERTIFICATES_DESCRIPTION_CONTENT")}
        />
      </ExpandableContentContainer>
      <ListContainer>
        {certificates &&
          certificates.filter(filterCertificates).map((certificate, i) => {
            return (
              <ListCard
                key={i}
                header={{
                  title: certificate.cert_name,
                  badge: getCertBadgeInfo(certificate),
                }}
                fields={getCertMetadata(certificate)}
                dropdownItems={[
                  {
                    item: {
                      label: t("CERTIFICATE_LIST_CARD_DROPDOWN_UPLOAD"),
                      value: "upload",
                    },
                    action: handleDisplayCertificate(certificate.cert_name),
                  },
                ]}
                onClick={() => {
                  history.push(`/cmv3/certificates/${certificate.cert_name}`);
                }}
                dataTestId={`certificates-card-${i}`}
              />
            );
          })}
      </ListContainer>
    </>
  );
};

const Header = styled.div`
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer}`};
  display: flex;
  align-items: center;
`;

const StyledSearchBar = styled(InputField)`
  flex-grow: 1;
`;

const ExpandableContentContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`};
`;

const ListContainer = styled.div`
  padding: ${({ theme }) =>
    `0 ${theme.spacings.pageContainer} 2rem ${theme.spacings.pageContainer}`};
`;
