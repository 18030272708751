import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { Badge } from "../../../../../components/atoms/Badge/Badge";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { HeadlessDataTable } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { AccessGroupMember } from "../../../../../models/user-management/access-group/access-group.interfaces";
import { PageTitle } from "../../../../../components/atoms/PageTitle/PageTitle";
import { UserStatus } from "../../../../../models/user-management/users/users.interfaces";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../../components/atoms/UserManagementTable/UserManagementTable";
import { ColorSet } from "../../../../../contexts/themeContext";
import { IDropdownItem } from "../../../../../components/molecules/DropdownOption/DropdownOption";
import {
  useAccessGroupInfos,
  useAccessGroupMembers,
} from "../../../../../store/slices/user-management-access-group/hooks";
import { SearchBar } from "../../../../../components/molecules/SearchBar/SearchBar";
import {
  displayMultiSelectHeader,
  handleFilterMembersTable,
  toggleExpandRow,
} from "./helpers";
import { Checkbox } from "../../../../../components/molecules/Checkbox/Checkbox";
import { useBool } from "../../../../../hooks/use-bool/useBool";
import { HeadlessDropDown } from "../../../../../components/organisms/Dropdown/HeadlessDropdown";
import { LabelValueDropdownButton } from "../../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { NoShrink } from "../../../../../components/atoms/NoShrink/NoShrink";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { Button } from "../../../../../components/atoms/Button/Button";
import { AddMemberModalContent } from "./AddMemberModalContent";
import { UserManagementUsersLayer } from "../../../../../components/layers/UserManagementUsers/UserManagementUsers";
import {
  Dropdown,
  DropdownPosition,
} from "../../../../../components/organisms/Dropdown/Dropdown";
import { BulkRemoveToaster } from "./BulkRemoveToaster";
import { useAppDispatch } from "../../../../../store/types";
import { handleDeleteUsers } from "../../../../../store/slices/user-management-access-group/thunk";
import { EditUserAccessGroupRoleModal } from "../../../../../components/organisms/EditUserAccessGroupRoleModal/EditUserAccessGroupRoleModal";

const getUserKey = (user: AccessGroupMember) => `${user.userId}-${user.roleId}`;

const statusDropdownItems: IDropdownItem<UserStatus>[] = [
  {
    value: UserStatus.ACTIVE,
    label: "Active",
  },
  {
    value: UserStatus.DISABLED,
    label: "Disabled",
  },
  {
    value: UserStatus.EXPIRED,
    label: "Expired",
  },
  {
    value: UserStatus.INACTIVE,
    label: "Inactive",
  },
  {
    value: UserStatus.LOCKED,
    label: "Locked",
  },
  {
    value: UserStatus.PENDING,
    label: "Pending",
  },
];

const TABLE_STATUS_COLUMN_STYLE = { width: "10%" };
const TABLE_ROLE_NAME_COLUMN_STYLE = { width: "20%" };
const TABLE_USERID_COLUMN_STYLE = { width: "5%" };

export interface MembersProps {
  members: AccessGroupMember[];
}

const getBadgeColorFromStatus = (
  status: UserStatus
): keyof ColorSet["colors"] => {
  switch (status) {
    case UserStatus.ACTIVE:
      return "green60";
    case UserStatus.PENDING:
      return "yellow40";
    case UserStatus.DISABLED:
    case UserStatus.LOCKED:
      return "grey40";
    case UserStatus.EXPIRED:
      return "red70";
    case UserStatus.INACTIVE:
      return "grey60";
    default:
      return "grey60";
  }
};

export const Members = (): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  const handleCopyEmail = (email: string) => () => {
    navigator.clipboard.writeText(email).then(() => {
      toast.success(t("ACCESS_GROUP_PAGE_COPY_EMAIL_MESSAGE", { email }));
    });
  };

  const accessGroupInfo = useAccessGroupInfos();

  const members = useAccessGroupMembers();

  const [searchInput, setSearchInput] = useState("");

  const [statusFilter, setStatusFilter] = useState<IDropdownItem<UserStatus>[]>(
    []
  );
  const [roleFilter, setRoleFilter] = useState<IDropdownItem<number>[]>([]);

  const [showInherited, toggleShowInherited] = useBool(false);

  const availableRoles = useMemo<{ value: number; label: string }[]>(
    () =>
      members?.reduce<{ value: number; label: string }[]>((prev, member) => {
        if (!!prev.find((m) => m.value === member.roleId)) {
          return prev;
        }
        return [
          ...prev,
          {
            value: member.roleId,
            label: member.roleName,
          },
        ];
      }, []) || [],
    [members]
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const displayedUsers = useMemo(() => {
    const result = members?.filter(
      handleFilterMembersTable(
        searchInput,
        statusFilter.map((item) => item.value),
        roleFilter.map((item) => item.value),
        showInherited
      )
    );
    return result || [];
  }, [members, searchInput, statusFilter, roleFilter, showInherited]);

  const dispatch = useAppDispatch();

  const handleRemoveUsersFromAccessGroup = (userIds: number[]) => {
    if (accessGroupInfo?.accessGroupId) {
      dispatch(
        handleDeleteUsers(accessGroupInfo.accessGroupId, userIds, () =>
          setSelectedRows([])
        )
      );
    }
  };

  const [editRoleModalData, setEditRoleModalData] = useState<{
    userId: number;
    role: string;
  }>();

  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);

  const history = useHistory();

  return (
    <div>
      <BulkRemoveToaster
        open={selectedRows.length > 0}
        text={
          selectedRows.length > 1
            ? t("ACCESS_GROUP_REMOVE_USERS_TOASTER_TEXT_PLURAL", {
                count: selectedRows.length,
              })
            : t("ACCESS_GROUP_REMOVE_USERS_TOASTER_TEXT_SINGULAR", {
                count: selectedRows.length,
              })
        }
        label={t("ACCESS_GROUP_REMOVE_USERS_BULK_BUTTON")}
        onClick={() => {
          handleRemoveUsersFromAccessGroup(selectedRows);
        }}
      />
      {accessGroupInfo && editRoleModalData && (
        <Modal
          customButton={<></>}
          open={isRoleModalOpen}
          onClose={() => {
            setIsRoleModalOpen(false);
          }}
          overflow="visible"
        >
          <EditUserAccessGroupRoleModal
            userId={editRoleModalData.userId}
            accessGroupId={accessGroupInfo.accessGroupId}
            role={editRoleModalData.role}
          />
        </Modal>
      )}
      <PageTitle>{t("ACCESS_GROUP_PAGE_MEMBER_TABLE_TITLE")}</PageTitle>
      <ExpandableContent
        title={t("ACCESS_GROUP_PAGE_MEMBER_TABLE_EXPAND_TITLE")}
        content={t("ACCESS_GROUP_PAGE_MEMBER_TABLE_EXPAND_CONTENT")}
      />
      <TableHeaderContainer>
        <FilterContainer>
          <SearchBarContainer>
            <SearchBar
              placeholder={t("ACCESS_GROUP_SEARCH_USERS_PLACEHOLDER")}
              onChange={setSearchInput}
            />
          </SearchBarContainer>
          <NoShrink>
            <Checkbox
              id="showParent"
              label={t("ACCESS_GROUP_SHOW_INHERITED_USERS_CHECKBOX_LABEL")}
              onChange={toggleShowInherited}
              checked={showInherited}
            />
          </NoShrink>
          <NoShrink>
            <HeadlessDropDown
              button={(props) => (
                <LabelValueDropdownButton
                  placeholder={t("ACCESS_GROUP_USERS_TABLE_FILTER_STATUS")}
                  value={displayMultiSelectHeader(
                    statusDropdownItems,
                    statusFilter
                  )}
                  {...props}
                />
              )}
              items={statusDropdownItems}
              multiSelect
              selectedItems={statusFilter}
              setSelectedItems={setStatusFilter}
            />
          </NoShrink>
          <NoShrink>
            <HeadlessDropDown
              button={(props) => (
                <LabelValueDropdownButton
                  placeholder={t("ACCESS_GROUP_USERS_TABLE_FILTER_ROLE")}
                  value={displayMultiSelectHeader(availableRoles, roleFilter)}
                  {...props}
                />
              )}
              items={availableRoles}
              multiSelect
              selectedItems={roleFilter}
              setSelectedItems={setRoleFilter}
            />
          </NoShrink>
          <UserManagementUsersLayer>
            <Modal
              customButton={
                <AddMembersButton
                  label={t("ACCESS_GROUP_ADD_USERS_MODAL_BUTTON_LABEL")}
                  onClick={() => {}}
                />
              }
              size="auto"
            >
              <AddMemberModalContent />
            </Modal>
          </UserManagementUsersLayer>
        </FilterContainer>
      </TableHeaderContainer>
      <TableContainer>
        <HeadlessDataTable
          data={displayedUsers}
          columns={[
            {
              dataField: "userName",
              name: t("ACCESS_GROUP_PAGE_MEMBER_TABLE_NAME_LABEL"),
              formatter: (row) => (
                <EmaiCell>
                  <Checkbox
                    id={row.userId.toString()}
                    label=""
                    onChange={() => {
                      setSelectedRows(toggleExpandRow(row.userId));
                    }}
                    checked={selectedRows.includes(row.userId)}
                  />
                  <UserNameText>
                    {row.firstName} {row.lastName}
                  </UserNameText>
                </EmaiCell>
              ),
            },
            {
              dataField: "email",
              name: t("ACCESS_GROUP_PAGE_MEMBER_TABLE_EMAIL_LABEL"),
              formatter: (row) => (
                <EmaiCell>
                  <EmailText>{row.email}</EmailText>
                  <Icon
                    name={Icons.COPY_OUTLINE}
                    color="secondary"
                    onClick={handleCopyEmail(row.email)}
                  />
                </EmaiCell>
              ),
            },
            {
              dataField: "status",
              name: t("ACCESS_GROUP_PAGE_MEMBER_TABLE_STATUS_LABEL"),
              formatter: (row) => (
                <Badge
                  label={row.status}
                  color={getBadgeColorFromStatus(row.status)}
                />
              ),
              style: TABLE_STATUS_COLUMN_STYLE,
            },
            {
              dataField: "roleName",
              name: t("ACCESS_GROUP_PAGE_MEMBER_TABLE_ROLE_LABEL"),
              formatter: (row) => row.roleName,
              style: TABLE_ROLE_NAME_COLUMN_STYLE,
            },
            {
              dataField: "userId",
              name: "",
              formatter: (row) => (
                <Dropdown
                  position={DropdownPosition.LEFT}
                  customButton={
                    <Icon
                      name={Icons.TRIPLE_DOT}
                      size={{ width: 18, height: 12 }}
                    />
                  }
                  items={[
                    {
                      label: t("ACCESS_GROUPS_TABLE_USERS_ACTION_EDIT_DETAILS"),
                      value: "edit_details",
                    },
                    {
                      label: t("ACCESS_GROUPS_TABLE_USERS_ACTION_EDIT_ROLES"),
                      value: "edit_roles",
                    },
                    {
                      label: t("ACCESS_GROUPS_TABLE_USERS_ACTION_REMOVE"),
                      value: "remove_user",
                    },
                  ]}
                  onSelect={(item) => {
                    switch (item.value) {
                      case "remove_user":
                        handleRemoveUsersFromAccessGroup([row.userId]);
                        break;
                      case "edit_roles":
                        setIsRoleModalOpen(true);
                        setEditRoleModalData({
                          userId: row.userId,
                          role: row.roleName,
                        });
                        break;
                      case "edit_details":
                        history.push(`/userManagement/users/${row.userId}`);
                        break;
                    }
                  }}
                  id="user-action-selection"
                  placeholder=""
                />
              ),
              style: TABLE_USERID_COLUMN_STYLE,
            },
          ]}
          keyField={getUserKey}
          TableWrapper={UserManagementTable}
          TableHeadRow={StyledHeaderTr}
          TableHeadRowCell={UserManagementTableHeaderCell}
          TableBodyRow={UserManagementTableBodyRow}
          TableBodyRowCell={StyledBodyCell}
        />
      </TableContainer>
    </div>
  );
};

const StyledBodyCell = styled.td`
  padding-top: 16px !important;
  padding-bottom: 16px;
`;

const StyledHeaderTr = styled(UserManagementTableHeaderRow)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;

const TableContainer = styled.div`
  max-height: 30vh;
  overflow-y: auto;
`;

const EmailText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.secondary};
`;

const EmaiCell = styled(FlexBox)`
  justify-content: flex-start;
  & > :first-child {
    margin-right: 5px;
  }
`;

const UserNameText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.primary};
`;

const TableHeaderContainer = styled(FlexBox)`
  justify-content: space-between;
`;

const FilterContainer = styled(FlexBox)`
  gap: 8px;
  width: 100%;
`;

const SearchBarContainer = styled.div`
  width: 100%;
`;

const AddMembersButton = styled(Button)`
  padding: 12px 16px;
`;
