import { forwardRef, InputHTMLAttributes } from "react";

import { getRandomID } from "../../../utils/getRandomID";

export interface CheckboxInputProps<Value> {
  value?: Value;
  onClick?: (value: Value) => void;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  checked?: boolean;
  id?: string;
  dataTestId?: string;
}

export const CheckboxInput = <Value extends unknown>({
  dataTestId,
  value,
  onChange,
  onClick,
  checked,
  disabled,
  id,
}: CheckboxInputProps<Value>): JSX.Element => (
  <CheckboxBase
    onChange={(e) => onChange(e.target.checked)}
    onClick={() => {
      if (value && typeof onClick !== "undefined") {
        onClick(value);
      }
    }}
    dataTestId={dataTestId}
    checked={checked}
    disabled={disabled}
    id={id}
  />
);

export interface CheckboxBaseProps<Value = any>
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "type" | "className" | "value"
  > {
  dataTestId?: string;
  value?: Value;
}

export const CheckboxBase = forwardRef<HTMLInputElement, CheckboxBaseProps>(
  ({ dataTestId, id = getRandomID(), ...props }, ref) => (
    <input
      type="checkbox"
      className="chi-checkbox__input"
      data-testid={dataTestId}
      id={id}
      ref={ref}
      {...props}
    />
  )
);
