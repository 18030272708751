import { Switch, Route } from "react-router-dom";

import { ConfigurationsList } from "./list";
import { ConfigurationPage } from "./configuration_page/ConfigurationPage";

export const ConfigurationsRouter = (): JSX.Element => (
  <Switch>
    <Route path={"/cmv3/configurations/:configName"}>
      <ConfigurationPage />
    </Route>
    <Route exact path={"/cmv3/configurations"}>
      <ConfigurationsList />
    </Route>
  </Switch>
);
