import { ReactElement } from "react";
import styled from "@emotion/styled";

import { useBool } from "../../../hooks/use-bool/useBool";
import { FlexBox } from "../FlexBox/FlexBox";
import { ArrowDownIcon } from "../ArrowDownIcon/ArrowDownIcon";
import {
  CustomCheckboxInput,
  SelectionStatus,
} from "../Checkbox/CustomCheckBoxInput";

export interface FilterMultiSelectDropdownItem<T> {
  label: string;
  status: SelectionStatus;
  value: T;
  subItems?: FilterMultiSelectDropdownItem<T>[];
}

interface FilterMultiSelectDropdownOptionProps<T> {
  item: FilterMultiSelectDropdownItem<T>;
  level?: number;
  onSelect: (item: FilterMultiSelectDropdownItem<T>) => void;
}

export const FilterMultiSelectDropdownOption = <T,>({
  item,
  level = 1,
  onSelect,
}: FilterMultiSelectDropdownOptionProps<T>): ReactElement => {
  const [open, setOpen] = useBool(false);

  return (
    <>
      <LineContainer level={level}>
        <CustomCheckboxInput
          selectionStatus={item.status}
          onChange={() => {
            onSelect({ ...item, subItems: undefined });
          }}
        />
        <LabelContainer onClick={setOpen}>
          {item.subItems !== undefined && (
            <ArrowDownIcon open={open} color="primary" />
          )}
          <Label>{item.label}</Label>
        </LabelContainer>
      </LineContainer>
      {open &&
        item.subItems?.map((i) => (
          <FilterMultiSelectDropdownOption
            item={i}
            key={i.label}
            level={level + 1}
            onSelect={(childItem: FilterMultiSelectDropdownItem<T>) => {
              onSelect({
                label: item.label,
                value: item.value,
                status: item.status,
                subItems: [childItem],
              });
            }}
          />
        ))}
    </>
  );
};

const LineContainer = styled(FlexBox)<{ level: number }>`
  padding: 16px 0 16px 18px;
  justify-content: flex-start;
  padding-left: ${({ level }) => `${level * 18}px`};
`;

const LabelContainer = styled(FlexBox)`
  padding-left: 10px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;
