import { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";

import { Production } from "./Production";

export const ProductionRouter = (): ReactElement => {
  return (
    <Switch>
      <Route path={"/cmv3/production"} component={Production} />
    </Switch>
  );
};
