import styled from "@emotion/styled";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useScrollLayout } from "../../../hooks/use-scroll-layout";
import { useDispatchHeaderHide } from "../../../store/slices/header/hooks";
import { FlexBox } from "../FlexBox/FlexBox";

export const ModuleHeader = styled.div`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
  padding: ${({ theme }) =>
    `1rem ${theme.spacings.pageContainer} 0 ${theme.spacings.pageContainer}`};
`;

export const ModuleContent: FC<{ id: string }> = ({ children, id }) => {
  const handleHideHeader = useDispatchHeaderHide();
  const { onScroll } = useScrollLayout(id);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    handleHideHeader({
      scrollTop: e.currentTarget.scrollTop,
      offsetTop: e.currentTarget.offsetTop,
    });
    onScroll(e);
  };

  return <Container onScroll={handleScroll}>{children}</Container>;
};

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const ModuleTitle = ({
  moduleTitle,
  isBeta = false,
  dataTestId,
}: {
  moduleTitle: string;
  isBeta?: boolean;
  dataTestId?: string;
}): JSX.Element => {
  const { t } = useTranslation("misc");
  return (
    <TitleContainer>
      <Title data-testid={dataTestId}>{moduleTitle}</Title>
      {isBeta && <BetaBadge>{t("BETA_BADGE")}</BetaBadge>}
    </TitleContainer>
  );
};

const Title = styled.h2`
  font-size: 2rem !important;
  line-height: 3rem;
  margin: 1rem 0 !important;
  font-weight: 600 !important;
`;

const TitleContainer = styled(FlexBox)`
  justify-content: flex-start;
  & > *:last-child {
    margin-left: 16px;
  }
`;

const BetaBadge = styled.div`
  color: ${({ theme }) => theme.text.primaryAlt};
  background: ${({ theme }) => theme.icon.primary};
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`;
