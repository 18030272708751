import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { IModalChildren } from "../../atoms/Modal/Modal";
import { Button } from "../../atoms/Button/Button";
import { NotificationCard } from "../NotificationCard/NotificationCard";

interface IModalChild extends IModalChildren {
  header?: React.ReactNode;
  /** definition.references */
  references: number;
  propertyReferences?: number;
  onSubmit: () => void;
  onCancel: () => void;
  /** Function returns true if all modal fields are valid. Print of errors should be handled in the function */
  validation: () => boolean;
  dataTestId?: string;
  className?: string;
}

export const ModalChild: React.FC<IModalChild> = ({
  children,
  references,
  propertyReferences,
  header,
  closeModal,
  onSubmit,
  onCancel,
  validation,
  dataTestId,
  className,
}) => {
  const { t } = useTranslation("configurationDefinitionsPage");

  return (
    <ModalChildContainer
      data-testid={`definition-modal-${dataTestId}-child-container`}
      className={className}
    >
      {header && (
        <ModalChildHeader>
          <HeaderTitle>{header}</HeaderTitle>
        </ModalChildHeader>
      )}
      <ContentContainer>
        <FormSection>{children}</FormSection>
        <Footer>
          {propertyReferences && (references > 0 || propertyReferences > 0) ? (
            <NotificationCard
              theme="warning"
              title={t("MODIFY_DEFINITION_WARNING_WITH_PROPERTIES", {
                references,
                rule: t(references === 1 ? "RULE" : "RULES"),
                propertyReferences,
                property: t(
                  propertyReferences === 1 ? "PROPERTY" : "PROPERTIES"
                ),
              })}
            />
          ) : (
            references > 0 && (
              <NotificationCard
                theme="warning"
                title={t("MODIFY_DEFINITION_WARNING", {
                  references,
                  rule: t(references === 1 ? "RULE" : "RULES"),
                })}
              />
            )
          )}
          <ButtonsContainer>
            <Button
              label={t("DEFINITION_CANCEL_BUTTON")}
              onClick={() => {
                onCancel();
                closeModal && closeModal();
              }}
              dataTestId={`definition-modal-${dataTestId}-cancel-button`}
              backgroundColor="baseLight"
              textColor="primary"
              borderColor="mutedLight"
            />
            <Button
              label={t("DEFINITION_SAVE_BUTTON")}
              onClick={() => {
                if (validation()) {
                  onSubmit();
                  closeModal && closeModal();
                }
              }}
              dataTestId={`definition-modal-${dataTestId}-submit-button`}
            />
          </ButtonsContainer>
        </Footer>
      </ContentContainer>
    </ModalChildContainer>
  );
};

const ModalChildContainer = styled.div`
  padding: 32px;
  overflow: auto;
  font-size: 1rem !important;
`;

const ModalChildHeader = styled.header`
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.backgrounds.highlightLight}`};
  margin-bottom: 30px;
`;

const HeaderTitle = styled.div`
  padding-bottom: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
`;

const FormSection = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.text.primary};
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;

  margin-top: 8px;
  & > *:not(:first-of-type) {
    margin-top: 20px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;
