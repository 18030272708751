import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddButton } from "../../../../../../../../components/atoms/AddButton/AddButton";
import { ErrorContainer } from "../../../../../../../../components/atoms/ErrorContainer/ErrorContainer";
import { Modal } from "../../../../../../../../components/atoms/Modal/Modal";
import { Protected } from "../../../../../../../../components/atoms/Protected/Protected";
import { ExpandableContent } from "../../../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { PresentationModal } from "../../../../../../../../components/molecules/PresentationModal/PresentationModal";
import { DragAndDropList } from "../../../../../../../../components/organisms/DragAndDropList/DragAndDropList";
import {
  IMatchGroup,
  IMatchRule,
} from "../../../../../../../../models/configuration/definitions";
import { PropertySection } from "../../../../../../../../models/configuration/definitions/property";
import { ConfigurationErrorType } from "../../../../../../../../models/configuration/errors";
import { UserRoles } from "../../../../../../../../models/permissions";
import { handleRemoveConfigurationError } from "../../../../../../../../store/slices/caching/thunks";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import { useAppDispatch } from "../../../../../../../../store/types";
import {
  DeleteModalContent,
  DNDContainer,
  Header,
  MatchBlockContainer,
  Title,
} from "./styles";
import { MatchBlockProps } from "./types";
import { useMatchBlockItems } from "./useMatchBlockItems";

export const MatchBlock = ({
  additionalInfoTitle,
  additionalInfoContent,
  header,
  matchBlock,
  matchBlockName,
  errorMessage,
  dataTestId,
  fromMatchRulePath,
  isCompact,
  setFromMatchRule,
  onChange,
  addMatchBlock,
}: MatchBlockProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();
  const dispatch = useAppDispatch();

  const [deleteMatchGroup, setDeleteMatchGroup] = useState<{
    groupIndex: number;
    callback?: (index: number) => void;
  }>();

  const [deleteMatchRule, setDeleteMatchRule] = useState<{
    group: IMatchGroup;
    groupIndex: number;
    ruleIndex: number;
    rulePath: string;
    callback?: (index: number) => void;
  }>();

  const items = useMatchBlockItems({
    dataTestId,
    fromMatchRulePath,
    isCompact,
    matchBlock,
    matchBlockName,
    onChange,
    setDeleteMatchRule,
  });

  return (
    <MatchBlockContainer id={matchBlockName}>
      <Header>
        <Title>{header}</Title>
        <ExpandableContent
          title={additionalInfoTitle}
          content={additionalInfoContent}
        />
        {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
      </Header>
      {matchBlock && (
        <DNDContainer>
          <DragAndDropList
            defaultOpen={"all"}
            onDeleteItem={
              !isViewMode
                ? (item, callback) => {
                    setDeleteMatchGroup({
                      groupIndex: item!.node.context.index,
                      callback,
                    });
                  }
                : undefined
            }
            onOrderChange={(newList) => {
              dispatch(
                handleRemoveConfigurationError(
                  (err) =>
                    !(
                      err.type ===
                        ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES &&
                      err.data?.matchBlock === matchBlockName
                    )
                )
              );
              // Must have timeout because of drag and drop animation
              setTimeout(
                () =>
                  onChange({
                    ...matchBlock,
                    matchGroups: newList.map((list) => list.context.obj),
                  }),
                1000
              );
            }}
            onAddItem={(context) => {
              dispatch(
                handleRemoveConfigurationError(
                  (err) =>
                    !(
                      err.type ===
                        ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES &&
                      err.data?.matchBlock === matchBlockName &&
                      err.data?.matchGroupIndex === context.index
                    )
                )
              );
              // Must have timeout because of drag and drop animation
              setTimeout(
                () =>
                  onChange({
                    ...matchBlock,
                    matchGroups: matchBlock.matchGroups.map((matchGroup, mI) =>
                      mI === context.index
                        ? {
                            ...matchGroup,
                            matchRules: [
                              ...matchGroup.matchRules,
                              { features: {} },
                            ],
                          }
                        : matchGroup
                    ),
                  }),
                1000
              );
              setFromMatchRule({
                matchBlock: matchBlockName,
                matchGroup: context.index,
                matchRule: `${
                  matchBlock.matchGroups[context.index].matchRules.length - 1
                }`,
              });
            }}
            dataTestId={`${dataTestId}-match-group`}
            items={items}
          />
        </DNDContainer>
      )}
      <Protected permissions={UserRoles.EDIT_CONFIG}>
        <AddButton
          onClick={() => {
            if (matchBlock) {
              onChange({
                ...matchBlock,
                matchGroups: [
                  ...matchBlock.matchGroups,
                  {
                    description: "",
                    matchRules: [],
                  },
                ],
              });
              dispatch(
                handleRemoveConfigurationError(
                  (err) =>
                    !(
                      err.type ===
                        ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS &&
                      err.data?.section === PropertySection.MATCH_LOGIC &&
                      err.data?.matchBlock === matchBlockName
                    )
                )
              );
            } else {
              addMatchBlock();
            }
          }}
          label={t("MATCH_LOGIC_SECTION_ADD_MATCH_GROUP")}
          dataTestId={`${dataTestId}-add-match-group-button`}
        />
      </Protected>
      <Modal
        customButton={<></>}
        open={deleteMatchGroup !== undefined}
        onClose={() => setDeleteMatchGroup(undefined)}
      >
        <PresentationModal
          title={t("MATCH_LOGIC_DELETE_MATCH_GROUP_MODAL_TITLE")}
          button={{
            label: tMisc("DELETE"),
            backgroundColor: "red60",
            textColor: "primaryAlt",
          }}
          dataTestId="delete-match-group"
          onSubmit={() => {
            if (deleteMatchGroup?.callback) {
              deleteMatchGroup.callback(deleteMatchGroup.groupIndex);
            }

            dispatch(
              handleRemoveConfigurationError(
                (err) =>
                  !(
                    err.type ===
                      ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES &&
                    err.data?.matchBlock === matchBlockName &&
                    err.data?.matchGroupIndex === deleteMatchGroup!.groupIndex
                  )
              )
            );

            if (
              deleteMatchGroup!.groupIndex === 0 &&
              matchBlock &&
              matchBlock.matchGroups.length === 1
            ) {
              onChange(undefined);
            } else if (matchBlock) {
              onChange({
                ...matchBlock,
                matchGroups: matchBlock.matchGroups.filter(
                  (_, i) => i !== deleteMatchGroup!.groupIndex
                ),
              });
            }
          }}
        >
          <DeleteModalContent>
            {t("MATCH_LOGIC_DELETE_MATCH_GROUP_MODAL_CONTENT")}
          </DeleteModalContent>
        </PresentationModal>
      </Modal>
      <Modal
        customButton={<></>}
        open={deleteMatchRule !== undefined}
        onClose={() => setDeleteMatchRule(undefined)}
      >
        <PresentationModal
          title={t("MATCH_LOGIC_DELETE_MATCH_RULE_MODAL_TITLE")}
          button={{
            label: tMisc("DELETE"),
          }}
          dataTestId="delete-match-rule"
          onSubmit={() => {
            if (deleteMatchRule && matchBlock) {
              deleteMatchRule.rulePath
                .split("-")
                .reduce<IMatchRule | IMatchRule[] | undefined>(
                  (prev, curr, level, queries) => {
                    if (level < queries.length - 1) {
                      if (level % 2 === 0) {
                        // Matchrule
                        return (prev as IMatchRule[])?.[Number(curr)];
                      }

                      return (prev as IMatchRule).matchGroups?.[Number(curr)]
                        .matchRules;
                    } else {
                      (prev as IMatchRule[]).splice(Number(curr), 1);
                      deleteMatchRule.callback?.(Number(curr));

                      const noMatchRulesInMatchBlock =
                        (prev as IMatchRule[]).length < 1;
                      if (noMatchRulesInMatchBlock) {
                        queries
                          .slice(0, level)
                          .reduce<IMatchRule | IMatchRule[] | undefined>(
                            (_prev, _curr, _level, _queries) => {
                              if (_level < _queries.length - 1) {
                                if (_level % 2 === 0) {
                                  // Matchrule
                                  return (_prev as IMatchRule[])?.[
                                    Number(_curr)
                                  ];
                                }

                                return (_prev as IMatchRule).matchGroups?.[
                                  Number(_curr)
                                ].matchRules;
                              } else {
                                (_prev as IMatchRule).matchGroups?.splice(
                                  Number(_curr),
                                  1
                                );
                              }
                            },
                            deleteMatchRule.group.matchRules
                          );
                      }
                    }
                  },
                  deleteMatchRule.group.matchRules
                );

              onChange({
                ...matchBlock,
                matchGroups: matchBlock.matchGroups.map((matchGroup, mI) => {
                  if (mI === deleteMatchRule.groupIndex) {
                    return {
                      ...deleteMatchRule.group,
                      matchRules: deleteMatchRule.group.matchRules,
                    };
                  }

                  return matchGroup;
                }),
              });
            }
          }}
        >
          <DeleteModalContent>
            {t("MATCH_LOGIC_DELETE_MATCH_RULE_MODAL_CONTENT")}
          </DeleteModalContent>
        </PresentationModal>
      </Modal>
    </MatchBlockContainer>
  );
};
