import { ReactElement } from "react";
import styled from "@emotion/styled";
import { animated, config, useTransition } from "react-spring";

import { IDropdownItem } from "../../organisms/Dropdown/Dropdown";

interface DropdownItemsProps<T> {
  isOpen: boolean;
  items: IDropdownItem<T>[];
  setIsOpen: (isOpen: boolean) => void;
  setSelectedItem: (i: IDropdownItem<T>) => void;
  onSelect: (i: IDropdownItem<T>) => void;
}

export function DropdownItems<T>({
  isOpen,
  items,
  setIsOpen,
  setSelectedItem,
  onSelect,
}: DropdownItemsProps<T>): ReactElement {
  const transitions = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0rem" },
    enter: { opacity: 1, height: "2rem" },
    leave: { opacity: 0, height: "0rem" },
  });

  return (
    <ItemContainer>
      {transitions(
        (styles, show) =>
          show &&
          items.map((selection, i) => (
            <Item
              style={styles}
              key={`${i}`}
              onClick={() => {
                setIsOpen(!isOpen);
                setSelectedItem(selection);
                onSelect(selection);
              }}
              className="chi-dropdown__menu-item"
              data-testid={selection.dataTestId}
            >
              {selection.label}
            </Item>
          ))
      )}
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
`;

const Item = styled(animated.a)`
  display: flex !important;
  color: ${({ theme }) => theme.text.primary}!important;
  cursor: pointer;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  &:hover:not(.-disabled):not(.-active) {
    color: #000000 !important;
    background-color: ${({ theme }) => theme.backgrounds.mutedLight}!important;
  }
`;
