/* eslint-disable import/named */
import type { CustomTypeOptions } from "react-i18next";
import type { DetailedFeatureCardProps, FeaturesAsString } from "./types";

export const FEATURE_NAME_TRANSLATES: Record<
  DetailedFeatureCardProps["name"],
  keyof CustomTypeOptions["resources"]["configurationMatchRulesPage"]
> = {
  allowCompress: "FEATURE_CARD_ALLOW_COMPRESS_TITLE",
  cacheControlHeaderOverride: "FEATURE_CARD_CCHO_TITLE",
  cacheKey: "FEATURE_CARD_CACHE_KEY_TITLE",
  customLogData: "FEATURE_CARD_CUSTOM_LOG_DATA_TITLE",
  defaultCache: "FEATURE_CARD_DEFAULT_CACHE_TITLE",
  denyPolicy: "FEATURE_CARD_DENY_POLICY_TITLE",
  disableIfNoneMatch: "FEATURE_CARD_USE_IF_NONE_MATCH_TITLE",
  failedRefreshTTL: "FEATURE_CARD_FAILED_REFRESH_TTL_TITLE",
  followRedirects: "FEATURE_CARD_FOLLOW_REDIRECTS_TITLE",
  generatedResponse: "FEATURE_CARD_GENERATED_RESPONSE_TITLE",
  geoRestrictions: "FEATURE_CARD_GEO_REGION_BLOCKING_TITLE",
  ipRestrictions: "FEATURE_CARD_IP_BLOCKING_TITLE",
  negativeTTL: "FEATURE_CARD_NEGATIVE_TTL_TITLE",
  originFillPolicy: "FEATURE_CARD_ORIGIN_TITLE",
  qshMode: "FEATURE_CARD_QSH_MODE_TITLE",
  reportingOverride: "FEATURE_CARD_REPORTING_OVERRIDE_TITLE",
  reqHeaders: "FEATURE_CARD_REQUEST_HEADERS_TITLE",
  reqLua: "FEATURE_CARD_REQUEST_LUA_TITLE",
  respHeaders: "FEATURE_CARD_RESPONSE_HEADERS_TITLE",
  respLua: "FEATURE_CARD_RESPONSE_LUA_TITLE",
  serve200For416: "FEATURE_CARD_SERVE_200_FOR_416_TITLE",
  staleContentControl: "FEATURE_CARD_SCC_TITLE",
  tokenAuthentication: "FEATURE_CARD_TOKEN_AUTHENTICATION_TITLE",
  uriRewrite: "FEATURE_CARD_URI_REWRITE_TITLE",
};
export const FEATURES_AS_STRING: FeaturesAsString[] = [
  "allowCompress",
  "disableIfNoneMatch",
  "serve200For416",
];
