import type { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import type { FeatureComponent } from "./types";

type StaleContentControlComponent = FeatureComponent<
  Exclude<IFeatures["staleContentControl"], undefined>
>;

export const StaleContentControl: StaleContentControlComponent = ({
  feature: { staleIfError, staleWhileRevalidate },
  dataTestId,
  tPropertyPage,
}) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      dataTestId={`${dataTestId}-staleWhileRevalidate`}
      title={tPropertyPage("FEATURE_CARD_SCC_TITLE")}
    >
      {`${staleWhileRevalidate.enabled}`}
    </DetailedFeatureCardBlock>

    <DetailedFeatureCardBlock
      dataTestId={`${dataTestId}-staleIfError`}
      title={tPropertyPage("FEATURE_CARD_SCC_SIE_TITLE")}
    >
      {`${staleIfError.enabled}`}
    </DetailedFeatureCardBlock>

    {staleIfError.enabled && (
      <>
        <DetailedFeatureCardBlock
          dataTestId={`${dataTestId}-http4xxErrors`}
          title={tPropertyPage("FEATURE_CARD_SCC_4XX_TITLE")}
        >
          {`${staleIfError.http4xxErrors}`}
        </DetailedFeatureCardBlock>

        <DetailedFeatureCardBlock
          dataTestId={`${dataTestId}-http5xxErrors`}
          title={tPropertyPage("FEATURE_CARD_SCC_5XX_TITLE")}
        >
          {`${staleIfError.http5xxErrors}`}
        </DetailedFeatureCardBlock>
      </>
    )}
  </DetailedFeatureCardRow>
);
