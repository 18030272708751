import { ReactElement } from "react";
import styled from "@emotion/styled";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { IVersionHistory } from "../../../models/configuration/configuration_details.interfaces";
import { CardContainer } from "../../atoms/CardContainer/CardContainer";

export interface EnvironmentHistoryVersionCardProps {
  versionHistory: IVersionHistory;
}

export const EnvironmentHistoryVersionCard = ({
  versionHistory,
}: EnvironmentHistoryVersionCardProps): ReactElement => {
  const { t } = useTranslation("environmentHistoryPage");

  return (
    <VersionHistoryCardContainer
      data-testid={`environment-history-version-card-${versionHistory.slot}-${versionHistory.versionId}`}
    >
      <Content>
        <Time className="-text--h5">
          {versionHistory.modifiedTime !== undefined
            ? `${format(new Date(versionHistory.modifiedTime), "h:mm a")} (${t(
                "ENVIRONMENT_HISTORY_VERSION_CARD_V",
                {
                  version: versionHistory.versionId,
                }
              )})`
            : null}
        </Time>
        <Description className="-text--md">
          {t("ENVIRONMENT_HISTORY_VERSION_CARD_SLOT", {
            slot:
              versionHistory.slot[0].toUpperCase() +
              versionHistory.slot.slice(1),
          })}{" "}
          &bull;{" "}
          <b>
            {versionHistory.sourceConfigName}{" "}
            {t("ENVIRONMENT_HISTORY_VERSION_CARD_V", {
              version: versionHistory.sourceConfigVersionId,
            })}
          </b>{" "}
          &bull;{" "}
          {t("ENVIRONMENT_HISTORY_VERSION_CARD_NOTES", {
            notes: versionHistory.comment
              ? versionHistory.comment
              : t("ENVIRONMENT_HISTORY_VERSION_CARD_NOTE_DEFAULT"),
          })}
        </Description>
      </Content>
    </VersionHistoryCardContainer>
  );
};

const VersionHistoryCardContainer = styled(CardContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  width: 100%;
  padding: 20px;

  cursor: default;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Time = styled.div`
  padding-right: 0.5rem;
`;

const Description = styled.div``;
