import { forwardRef, ReactElement, ReactNode } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  CheckboxBase,
  CheckboxBaseProps,
  CheckboxInput,
  CheckboxInputProps,
} from "../../atoms/Checkbox/CheckboxInput";
import { CheckboxLabel } from "../../atoms/Checkbox/CheckboxLabel";
import { HelpIconButton } from "../HelpIconButton/HelpIconButton";
import { CheckboxContainer } from "../../atoms/CheckboxContainer/CheckboxContainer";
import { Asterisk } from "../../atoms/Asterisk/Asterisk";
import { Tooltip } from "../Tooltip/Tooltip";

export interface CheckboxProps<Value>
  extends Omit<CheckboxInputProps<Value>, "id"> {
  label: ReactNode;
  helpMessage?: string;
  required?: boolean;
  danger?: boolean;
  id: string;
  popupText?: string;
}

export const Checkbox = <Value extends unknown>({
  value,
  onClick,
  onChange,
  label,
  helpMessage = "",
  disabled = false,
  checked = false,
  required = false,
  danger = false,
  id,
  dataTestId,
  popupText,
}: CheckboxProps<Value>): ReactElement => {
  const _Checkbox = (
    <CheckboxContainer className="chi-checkbox" disabled={disabled}>
      <CheckboxInput
        onChange={onChange}
        onClick={onClick}
        value={value}
        id={`checkbox-${id}`}
        checked={checked}
        disabled={disabled}
        dataTestId={dataTestId}
      />
      <CheckboxLabel
        className={`${danger && "-danger"}`}
        htmlFor={`checkbox-${id}`}
        disabled={disabled}
      >
        {label}
      </CheckboxLabel>
      {required && (
        <StyledAsterisk
          className="chi-label__required"
          title="Required field"
          disabled={disabled}
        >
          *
        </StyledAsterisk>
      )}
      {helpMessage.length > 0 && (
        <HelpIconButton helpMessage={helpMessage} disabled={disabled} />
      )}
    </CheckboxContainer>
  );

  return (
    <div className="chi-form__item checkbox-container">
      {popupText ? (
        <Tooltip popupText={popupText} id={id} dataTestId={dataTestId}>
          {_Checkbox}
        </Tooltip>
      ) : (
        _Checkbox
      )}
    </div>
  );
};

interface HeadlessCheckboxProps extends CheckboxBaseProps {
  label: ReactNode;
  helpMessage?: string;
  required?: boolean;
  danger?: boolean;
  description?: string;
}

export const HeadlessCheckbox = forwardRef<
  HTMLInputElement,
  HeadlessCheckboxProps
>(
  (
    {
      label,
      helpMessage = "",
      disabled,
      required,
      danger,
      id,
      description,
      ...props
    },
    ref
  ) => (
    <div className="chi-form__item checkbox-container">
      <CheckboxContainer className="chi-checkbox" disabled={disabled}>
        <CheckboxBase
          ref={ref}
          id={`checkbox-${id}`}
          disabled={disabled}
          {...props}
        />
        <CheckboxLabel
          className={`${danger && "-danger"}`}
          htmlFor={`checkbox-${id}`}
          disabled={disabled}
        >
          {label}
        </CheckboxLabel>
        {required && (
          <StyledAsterisk
            className="chi-label__required"
            title="Required field"
            disabled={disabled}
          >
            *
          </StyledAsterisk>
        )}
        {helpMessage.length > 0 && (
          <HelpIconButton helpMessage={helpMessage} disabled={disabled} />
        )}
      </CheckboxContainer>
      <div className={`chi-label -status ${danger && "-danger"}`}>
        {description}
      </div>
    </div>
  )
);

const StyledAsterisk = styled(Asterisk)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 50%;
    `}
`;
