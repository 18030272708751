import { ReactElement } from "react";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { ReportingOverridesModal } from "../../../../../../../../../components/molecules/ReportingOverridesModal/ReportingOverridesModal";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  ReportingOverrideDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { IValidationErrors } from "../FeaturesList";
import { FeatureBlock } from "./FeatureBlock";

interface ReportingOverrideFeatureBlockProps {
  reportingOverride: Exclude<IFeatures["reportingOverride"], undefined>;
  config: ConfigurationDetailsType;
  initIsOpen: boolean;
  maxWidth: number;
  errors: IValidationErrors;
  datatestId: string;
  onDelete: () => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  handleCreateDefinition: (definition: ReportingOverrideDefinitionType) => void;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<IFeatures>;
}

export const ReportingOverrideFeatureBlock = ({
  reportingOverride,
  config,
  initIsOpen,
  maxWidth,
  errors,
  datatestId,
  handleCreateFeature,
  handleCreateDefinition,
  onDelete,
  setError,
  clearErrors,
}: ReportingOverrideFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_REPORTING_OVERRIDE_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_REPORTING_OVERRIDE_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_REPORTING_OVERRIDE_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_REPORTING_OVERRIDE_DEFINITION_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_REPORTING_OVERRIDE_DEFINITION_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_REPORTING_OVERRIDE_DEFINITION_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          maxWidth={maxWidth}
          errorMessage={errors.reportingOverride?.message}
          fieldProps={{
            other: config.reportingOverrideDefinitions ? (
              <DefinitionDropdown
                id={"reportingOverride"}
                type={FeatureType.REPORTING_OVERRIDE}
                disabled={isViewMode}
                items={config.reportingOverrideDefinitions.map(
                  (definition) => ({ definition })
                )}
                selected={
                  config.reportingOverrideDefinitions.filter(
                    (def) => def.name === reportingOverride
                  ) as ReportingOverrideDefinitionType[]
                }
                allowMultiSelection={false}
                onSelect={(value) =>
                  handleCreateFeature("reportingOverride", value[0]?.name)
                }
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                dataTestId="reporting-override"
                parentConfig={config}
              />
            ) : (
              <ReportingOverridesModal
                parentConfig={config}
                onSubmit={handleCreateDefinition}
              />
            ),
          }}
          dataTestId="reporting-override"
        />
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"reportingOverride"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="reporting-override"
      featureType={FeatureTypes.LOGGING}
    />
  );
};
