import { ParsedConditions } from "./types";

export const parseConditions = (
  conditions: string,
  result: ParsedConditions
): void => {
  const match = / or | and /.exec(conditions);

  if (match) {
    const operator = match[0];
    const operatorStartsAt = match.index;

    result.push({
      expression: conditions.slice(0, operatorStartsAt),
      operator: operatorStartsAt > 0 ? "" : operator.trim(),
    });

    const restConditions = conditions.slice(
      operatorStartsAt + (operatorStartsAt > 0 ? 0 : operator.length)
    );

    parseConditions(restConditions, result);
  } else {
    result.push({
      expression: conditions,
      operator: "",
    });
  }
};

export const reduceOperators = (parsedConditions: ParsedConditions): void => {
  for (let i = 0; i < parsedConditions.length; i++) {
    const { expression, operator } = parsedConditions[i];

    if (!expression && operator) {
      parsedConditions[i + 1].operator = operator;
      parsedConditions.splice(i, 1);
      i--;
    }
  }
};
