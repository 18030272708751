import { BannerHeader } from "../../../models/models";

export type BannersReducerAction =
  | {
      type: "UPDATE_NAME";
      payload: { name: string };
    }
  | {
      type: "UPDATE_MESSAGE";
      payload: { message: string };
    }
  | {
      type: "UPDATE_SERVICE_TYPE";
      payload: { serviceType: string };
    }
  | {
      type: "UPDATE_PRIORITY";
      payload: { priority: boolean };
    }
  | {
      type: "UPDATE_USER_TYPE";
      payload: { userType: string };
    }
  | {
      type: "UPDATE_START_TIME";
      payload: { startTime: string };
    }
  | {
      type: "UPDATE_END_TIME";
      payload: { endTime: string };
    };

export const defaultBanner: BannerHeader = {
  name: "",
  message: "",
  serviceType: "",
  priority: false,
  userType: "",
  startTime: new Date().toISOString(),
  endTime: new Date().toISOString(),
};

export const bannersReducer = (
  state: BannerHeader,
  action: BannersReducerAction
): BannerHeader => {
  switch (action.type) {
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.payload.name,
      };
    case "UPDATE_MESSAGE":
      return {
        ...state,
        message: action.payload.message,
      };
    case "UPDATE_SERVICE_TYPE":
      return {
        ...state,
        serviceType: action.payload.serviceType,
      };
    case "UPDATE_PRIORITY":
      return {
        ...state,
        priority: action.payload.priority,
      };
    case "UPDATE_USER_TYPE":
      return {
        ...state,
        userType: action.payload.userType,
      };
    case "UPDATE_START_TIME":
      return {
        ...state,
        startTime: action.payload.startTime,
      };
    case "UPDATE_END_TIME":
      return {
        ...state,
        endTime: action.payload.endTime,
      };
    default:
      throw new Error(`Unknown action ${action}`);
  }
};
