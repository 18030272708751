import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AddButton } from "../../../../../../../../components/atoms/AddButton/AddButton";
import { Protected } from "../../../../../../../../components/atoms/Protected/Protected";
import { Accordion } from "../../../../../../../../components/molecules/Accordion/Accordion";
import { PropertyDefinitionBlock } from "../../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { Protocols } from "../../../../../../../../models/configuration/definitions/origin";
import { PropertySection } from "../../../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
} from "../../../../../../../../models/configuration/errors";
import { UserRoles } from "../../../../../../../../models/permissions";
import { useConfigurationsErrors } from "../../../../../../../../store/slices/caching/hooks";
import {
  handleAddConfigurationError,
  handleRemoveConfigurationError,
} from "../../../../../../../../store/slices/caching/thunks";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import { useAppDispatch } from "../../../../../../../../store/types";
import {
  autoFillAlias,
  getAliasAutoFill,
  getAliasWithoutAutoFill,
  getUpdatedProperty,
} from "./helpers";
import { HttpProtocolsCard } from "./HttpProtocolsCard";
import { HttpsConfigCard } from "./HttpsConfigCard";

interface ProtocolSettingsProps {
  index: number;
  property: PropertyDefinitionType;
  onPropertyChange: (property: PropertyDefinitionType) => void;
  onDelete: () => void;
  shouldBeOpen?: boolean;
  aliasInputErrors:
    | {
        index: number;
        msg: string;
      }[]
    | undefined;
  encryptionError?: string;
}

export const OverridesSettings = ({
  property,
  index,
  onPropertyChange,
  onDelete,
  shouldBeOpen,
  aliasInputErrors,
  encryptionError,
}: ProtocolSettingsProps): ReactElement | null => {
  const { t } = useTranslation("configurationPropertyPage");
  const errors = useConfigurationsErrors();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const isHttps = property.aliasOverrides![
    index
  ].protocolSettings.protocols.includes(Protocols.HTTPS);

  const isViewMode = useIsViewMode();

  return property &&
    property.aliasOverrides &&
    property.aliasOverrides[index].aliases ? (
    <>
      <PropertyDefinitionBlock
        dataTestId={`alias-override-block-${index}`}
        onArrowClick={setIsOpen}
        title={
          property.aliasOverrides[index].aliases !== undefined &&
          property.aliasOverrides[index].aliases!.length > 0 &&
          property.aliasOverrides[index].aliases![0].length > 0
            ? property.aliasOverrides[index].aliases![0]
            : t("PROPERTY_CARD_ALIASES_OVERRIDE_TITLE")
        }
        badgeLabel={t("PROPERTY_CARD_ALIASES_OVERRIDE_BADGE_LABEL")}
        badgeColor="yellow40"
        onDelete={onDelete}
        initIsOpen={shouldBeOpen}
        propertyCards={
          isOpen ? (
            <>
              {property.aliasOverrides[index].aliases?.map((override, i) => {
                let errorMessage = "";

                const secondaryAliasErr = aliasInputErrors?.find(
                  (e) => e.index === i
                );

                if (secondaryAliasErr) {
                  errorMessage = secondaryAliasErr.msg;
                } else if (
                  errors.some(
                    (err) =>
                      err.type ===
                        ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_ALIAS_EMPTY &&
                      err.data?.index === index &&
                      err.data?.aliasIndex === i
                  )
                ) {
                  errorMessage =
                    i === 0
                      ? t("ERROR_ALIAS_OVERRIDE_EMPTY_ALIAS_FIRST")
                      : t("ERROR_ALIAS_OVERRIDE_EMPTY_ALIAS_ADDITIONAL");
                }

                return (
                  <PropertyDefinitionCard
                    dataTestId={`alias-override-url-card-${index}-${i}`}
                    key={i}
                    required={i === 0}
                    className="-mt--2"
                    additionalInputInfo={getAliasAutoFill(
                      property.aliasOverrides![index]
                    )}
                    fieldType={FieldType.InputFieldType}
                    fieldProps={{
                      value: getAliasWithoutAutoFill(override),
                      dataTestId: `alias-override-url-field-${index}-${i}`,
                      placeholder: t(
                        "PROPERTY_CARD_ALIASES_OVERRIDE_URL_PLACEHOLDER"
                      ),
                      onChange: (newAlias) => {
                        if (property.aliasOverrides) {
                          if (newAlias.length === 0) {
                            dispatch(
                              handleAddConfigurationError(
                                ConfigurationErrorLevel.ERROR,
                                ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_ALIAS_EMPTY,
                                {
                                  section: PropertySection.ALIAS_OVERRIDES,
                                  index,
                                  aliasIndex: i,
                                }
                              )
                            );
                          } else {
                            dispatch(
                              handleRemoveConfigurationError(
                                (err) =>
                                  !(
                                    err.type ===
                                      ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_ALIAS_EMPTY &&
                                    err.data?.section ===
                                      PropertySection.ALIAS_OVERRIDES &&
                                    err.data?.index === index &&
                                    err.data?.aliasIndex === i
                                  )
                              )
                            );
                          }
                          onPropertyChange({
                            ...property,
                            aliasOverrides: property.aliasOverrides?.map(
                              (aliasOverride, aliasOverrideI) =>
                                aliasOverrideI === index
                                  ? {
                                      ...aliasOverride,
                                      aliases: aliasOverride.aliases?.map(
                                        (alias, aliasI) =>
                                          aliasI === i
                                            ? (autoFillAlias(
                                                property.aliasOverrides![index],
                                                newAlias
                                              ) as string)
                                            : alias
                                      ),
                                    }
                                  : aliasOverride
                            ),
                          });
                        }
                      },
                      onDelete:
                        i !== 0
                          ? () => {
                              onPropertyChange({
                                ...property,
                                aliasOverrides: property.aliasOverrides?.map(
                                  (aliasOverride, aliasOverrideI) =>
                                    aliasOverrideI === index
                                      ? {
                                          ...aliasOverride,
                                          aliases: aliasOverride.aliases?.filter(
                                            (_, aliasI) => aliasI !== i
                                          ),
                                        }
                                      : aliasOverride
                                ),
                              });
                              dispatch(
                                handleRemoveConfigurationError(
                                  (err) =>
                                    !(
                                      err.type ===
                                        ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_ALIAS_EMPTY &&
                                      err.data?.section ===
                                        PropertySection.ALIAS_OVERRIDES &&
                                      err.data?.index === index &&
                                      err.data?.aliasIndex === i
                                    )
                                )
                              );
                            }
                          : undefined,
                      disabled: isViewMode,
                    }}
                    errorMessage={errorMessage}
                  />
                );
              })}
              <br />
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <AddButton
                  label={t(
                    "PROPERTY_SECTION_ALIASES_ADD_OVERRIDE_ALIAS_BUTTON"
                  )}
                  onClick={() => {
                    onPropertyChange({
                      ...property,
                      aliasOverrides: property.aliasOverrides?.map(
                        (aliasOverride, aliasOverrideI) =>
                          aliasOverrideI === index
                            ? {
                                ...aliasOverride,
                                aliases: [...(aliasOverride.aliases ?? []), ""],
                              }
                            : aliasOverride
                      ),
                    });
                  }}
                  dataTestId={`add-alias-url-${index}`}
                  outline
                />
                <br />
              </Protected>
              <SectionContainer>
                <Accordion
                  title={t("PROPERTY_CARD_ALIASES_OVERRIDE_PROTOCOL_TITLE")}
                  description={t("PROPERTY_CARD_ALIASES_PROTOCOL_DESCRIPTION")}
                  mandatory
                >
                  <HttpProtocolsCard
                    cardDataTestId={`override-group-protocol-card-${index}`}
                    divider={isHttps}
                    errorMessage={
                      errors.some(
                        (err) =>
                          err.type ===
                            ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_PROTOCOL_EMPTY &&
                          err.data?.section ===
                            PropertySection.ALIAS_OVERRIDES &&
                          err.data?.index === index
                      )
                        ? t("ERROR_ALIAS_NO_PROTOCOL")
                        : undefined
                    }
                    httpDataTestId={`override-protocol-http-${index}`}
                    httpId={`override-protocol-http-${index}`}
                    httpLabel={t(
                      "PROPERTY_CARD_ALIASES_OVERRIDE_PROTOCOL_CHECKBOX_HTTP_LABEL"
                    )}
                    httpsDataTestId={`override-protocol-https-${index}`}
                    httpsId={`override-protocol-https-${index}`}
                    httpsLabel={t(
                      "PROPERTY_CARD_ALIASES_OVERRIDE_PROTOCOL_CHECKBOX_HTTPS_LABEL"
                    )}
                    httpDisabled={isViewMode}
                    httpsDisabled={isViewMode}
                    placeholder={t(
                      "PROPERTY_CARD_ALIASES_OVERRIDE_PROTOCOL_PLACEHOLDER"
                    )}
                    protocols={
                      property.aliasOverrides![index].protocolSettings.protocols
                    }
                    title={t("PROPERTY_CARD_ALIASES_PROTOCOL_TITLE")}
                    onHttpChange={(newProtocols) => {
                      onPropertyChange({
                        ...property,
                        aliasOverrides: property.aliasOverrides?.map(
                          (aliasOverride, aliasOverrideI) =>
                            aliasOverrideI === index
                              ? {
                                  ...aliasOverride,
                                  protocolSettings: {
                                    ...aliasOverride.protocolSettings,
                                    protocols: newProtocols,
                                  },
                                }
                              : aliasOverride
                        ),
                      });
                    }}
                    onHttpsChange={(protocols, httpsConfig) => {
                      let aliases = property.aliasOverrides![index].aliases;

                      if (protocols.includes(Protocols.HTTPS)) {
                        aliases = property.aliasOverrides![index].aliases?.map(
                          (alias) =>
                            autoFillAlias(
                              {
                                protocolSettings: {
                                  httpsConfig,
                                } as any,
                              },
                              alias
                            )
                        ) as string[] | undefined;
                      } else {
                        aliases = property.aliasOverrides![index].aliases?.map(
                          getAliasWithoutAutoFill
                        ) as string[] | undefined;
                      }

                      onPropertyChange({
                        ...property,
                        aliasOverrides: property.aliasOverrides?.map(
                          (aliasOverride, aliasOverrideI) =>
                            aliasOverrideI === index
                              ? {
                                  ...aliasOverride,
                                  aliases,
                                  protocolSettings: {
                                    ...aliasOverride.protocolSettings,
                                    protocols,
                                    httpsConfig,
                                  },
                                }
                              : aliasOverride
                        ),
                      });
                    }}
                  />
                  {isHttps && (
                    <HttpsConfigCard
                      protocolSettings={
                        property.aliasOverrides![index].protocolSettings
                      }
                      updateProtocolSettings={(protocolSettings) => {
                        onPropertyChange({
                          ...property,
                          aliasOverrides: property.aliasOverrides?.map(
                            (aliasOverride, aliasOverrideI) =>
                              aliasOverrideI === index
                                ? getUpdatedProperty(
                                    aliasOverride,
                                    protocolSettings
                                  )
                                : aliasOverride
                          ),
                        });
                      }}
                      id={`override-${index}`}
                      index={index}
                      encryptionError={encryptionError}
                    />
                  )}
                </Accordion>
              </SectionContainer>
            </>
          ) : (
            <></>
          )
        }
      />
    </>
  ) : null;
};

const SectionContainer = styled.div`
  width: 100%;
`;
