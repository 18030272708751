import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { AnchorWrapper } from "../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { MenuItem } from "../../../../components/molecules/AnchorMenu/AnchorMenu";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { useSubscriberSettingsReportingOverrides } from "../../../../store/slices/subscriber-settings/hooks";
import {
  H2,
  H3,
  LargeTableInfoContainer,
  TableInfo,
  TableInfoFlex,
} from "./SubscriberSettings.styled";

interface InfoSectionProps {
  menu: MenuItem;
}

export const ReportingOverridesSection = ({
  menu,
}: InfoSectionProps): ReactElement => {
  const [t] = useTranslation("subscribersSettingsPage");
  const [tmisc] = useTranslation("misc");
  const infos = useSubscriberSettingsReportingOverrides();

  return (
    <>
      <AnchorWrapper
        id={menu.id}
        ref={menu.ref}
        key={menu.name}
        data-testid="subscriber_settings-reportingOverrides"
      >
        <H2>{t("SUBSCRIBER_SETTINGS_ANCHOR_REPORTING_OVERRIDES")}</H2>
        <ExpandableContent
          title={t("SUBSCRIBER_SETTINGS_REPORTING_OVERRIDES_DESCRIPTION_TITLE")}
          content={t(
            "SUBSCRIBER_SETTINGS_REPORTING_OVERRIDES_DESCRIPTION_CONTENT"
          )}
        />
        {Object.entries(infos).map(
          ([reportingOverrideKey, reportingOverrideValue], index) => (
            <AnchorWrapper
              id={menu.subItems![index].id}
              ref={menu.subItems![index].ref}
              key={reportingOverrideKey}
            >
              <H3>{reportingOverrideKey}</H3>
              <LargeTableInfoContainer>
                <TableInfo>
                  <TableInfoFlex>
                    <span>
                      {t("SUBSCRIBER_SETTINGS_REPORTING_OVERRIDES_ALIAS")}
                    </span>
                    <b>{reportingOverrideValue.reportingName}</b>
                  </TableInfoFlex>
                </TableInfo>
                <TableInfo>
                  <TableInfoFlex>
                    <span>
                      {t("SUBSCRIBER_SETTINGS_REPORTING_OVERRIDES_ID")}
                    </span>
                    <b>{reportingOverrideValue.reportingId}</b>
                  </TableInfoFlex>
                </TableInfo>
                <TableInfo>
                  <TableInfoFlex>
                    <span>{tmisc("STATUS")}</span>
                    <b>{reportingOverrideValue.status}</b>
                  </TableInfoFlex>
                </TableInfo>
              </LargeTableInfoContainer>
            </AnchorWrapper>
          )
        )}
      </AnchorWrapper>
    </>
  );
};
