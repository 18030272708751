import { PropertyDefinitionType } from "../../../store/slices/caching/types";

export type Protocol = "http" | "https";

export enum ServiceTypes {
  PREMIUM = "premium",
  SNI = "sni",
  BASIC = "basic",
}

export enum EncryptionLevels {
  RISKY = "risky",
  DEFAULT = "default",
  ADVANCED = "advanced",
}

export interface HttpsConfig {
  serviceType: ServiceTypes;
  ocsp?: boolean;
  http2?: boolean;
  encryptionLevel: EncryptionLevels;
  certificate?: string;
}

export interface IProtocolSetting {
  protocols: Protocol[];
  httpsConfig?: HttpsConfig;
}

export interface IAliasOverride {
  aliases?: string[];
  signature?: string;
  protocolSettings: IProtocolSetting;
}

export interface Logging {
  enabled: boolean;
  logEndpoints: string[];
}

export enum TrafficType {
  VOD = "VOD",
  LIVE = "Live",
  DIRECT_FILL = "DirectFill",
  METRO_FILL = "MetroFill",
  PATCH = "Patch",
  REGIONAL_FILL = "RegionalFill",
  WHOLESITE = "Wholesite",
}

export enum PropertySection {
  ORIGIN = "origin",
  TRAFFIC_TYPE = "traffic_type",
  ALIASES = "aliases",
  ALIAS_OVERRIDES = "alias_overrides",
  MATCH_LOGIC = "match_logic",
}

export enum PropertySubsection {
  PRIMARY_ALIAS = "primary_alias",
  PROTOCOL_SETTINGS = "protocol_settings",
  SECONDARY_ALIASES = "secondary_aliases",
  CONDITIONS = "conditions",
  ORIGIN = "origin",
}

export interface IPropertyDefinition {
  signature?: string;
  matchLogic?: string;
  trafficType?: TrafficType;
  primaryAlias?: string;
  aliases?: string[];
  originFillPolicy?: string;
  aliasOverrides?: IAliasOverride[];
  protocolSettings: IProtocolSetting;
  logging?: Logging;
}

export const getPropertyDefinitionDescription = (
  def: PropertyDefinitionType
): string =>
  `${def.aliases?.length || 0} ${
    def.aliases?.length === 1 ? "alias" : "aliases"
  }`;
