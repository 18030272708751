import { FC, useMemo } from "react";
import { Serie } from "@nivo/line";
import { toDate } from "date-fns";

import { TimeFilter } from "../TimeFilter/TimeFilter";
import { WidgetHeader, WidgetTitle } from "../../atoms/WidgetTitle/WidgetTitle";
import { LineChart } from "../../atoms/LineChart/LineChart";
import { WidgetContainer } from "../../atoms/WidgetContainer/WidgetContainer";
import {
  FilterFields,
  PeriodFilter,
  useFiltersContext,
} from "../../../modules/rtm/context/filters";
import {
  MapSiteFieldsToLabel,
  Metrics,
} from "../../../modules/rtm/models/models";
import {
  useHistorical,
  useHistoryStatus,
} from "../../../store/slices/rtm/hooks";
import { SetupStatus } from "../../../store/slices/rtm/types";
import { GraphContainer } from "../../atoms/GraphContainer/GraphContainer";
import { Loader } from "../../atoms/Loader/Loader";

const filterTimestamp = (time: number, periodFilter: PeriodFilter): boolean => {
  switch (periodFilter) {
    case PeriodFilter.ALL_TIME:
      return true;
    case PeriodFilter.LAST_30MINS:
      return time >= Date.now() / 1000 - 1800;
    case PeriodFilter.LAST_HOUR:
      return time >= Date.now() / 1000 - 3600;
    default:
      throw Error(`Unknown period filter option: ${periodFilter}`);
  }
};

const formatAxisBottom = (timeFilter: PeriodFilter) => {
  switch (timeFilter) {
    case PeriodFilter.ALL_TIME:
      return {
        format: "%H:%M",
        tickValues: "every 30 minutes",
      };
    case PeriodFilter.LAST_HOUR:
      return {
        format: "%H:%M",
        tickValues: "every 10 minutes",
      };
    case PeriodFilter.LAST_30MINS:
      return {
        format: "%H:%M",
        tickValues: "every 5 minutes",
      };
  }
};

export const LineChartWidget: FC<{
  metrics: Metrics[];
  id: string;
  title?: string;
  dataTestId?: string;
}> = ({ title, metrics, dataTestId, id }) => {
  const historical = useHistorical();
  const { preferences, updateWidgetPreference } = useFiltersContext();

  const setPeriodFilter = (value: PeriodFilter) => {
    updateWidgetPreference(id, { key: FilterFields.PERIOD, value });
  };

  const periodFilter = preferences.get(id)?.period || PeriodFilter.ALL_TIME;

  const data = useMemo<Serie[]>(
    () =>
      metrics.map((metric) => {
        const newData = historical
          ?.filter(({ time }) => filterTimestamp(time, periodFilter))
          .map(({ time, site }) => ({
            x: toDate(time * 1000),
            y: site[metric],
          }));
        return {
          id: MapSiteFieldsToLabel.get(metric) || metric,
          data: newData || [],
        };
      }),
    [historical, metrics, preferences]
  );

  const historyStatus = useHistoryStatus();

  return (
    <WidgetContainer>
      {title && (
        <WidgetHeader>
          <WidgetTitle>{title}</WidgetTitle>
          <TimeFilter
            onChange={setPeriodFilter}
            value={periodFilter}
            dataTestId={dataTestId}
          />
        </WidgetHeader>
      )}
      {historyStatus === SetupStatus.SETUP ? (
        <GraphContainer height={250}>
          <Loader />
        </GraphContainer>
      ) : (
        <LineChart
          data={data}
          axisBottom={formatAxisBottom(periodFilter)}
          dataTestId={dataTestId}
        />
      )}
    </WidgetContainer>
  );
};
