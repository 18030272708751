import { OriginDefinitionType, ConfigurationDetailsType } from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getOriginReferences = (
  def: OriginDefinitionType,
  config: ConfigurationDetailsType
): number =>
  getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .filter((feature) => feature?.originFillPolicy === def.name).length;
