import {
  CSSProperties,
  ReactElement,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";

import { IMarker, split as SplitEditor } from "react-ace";
import SplitComponent from "react-ace/lib/split";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-clouds";
import "ace-builds/src-noconflict/theme-github";
import "brace/mode/json";

import { Theme, ThemeContext } from "../../../contexts/themeContext";
import { DiffMatchPatch } from "./diffMatchPatch";
import { OPERATIONS } from "./constants";
import { Diff } from "./diff";
import { useReRenderOnSizeChangeOrScroll } from "../../../hooks/use-rerender-on-size-change-or-scroll/useReRenderOnSizeChangeOrScroll";
import { useSplitEditorScroller } from "./useSplitEditorScroller";

interface DiffEditorProps {
  codes: string[];
  id: string;
  readOnly?: boolean;
  interactive?: boolean;
  autoScroll: boolean;
}

function getDiff(lhString: string, rhString: string) {
  const dmp = new DiffMatchPatch();

  if (lhString.length === 0 && rhString.length === 0) {
    return [];
  }

  const diff = dmp.diffMain(lhString, rhString);
  dmp.diffCleanupSemantic(diff);

  const codeEditorSettings = setCodeMarkers(...generateDiffedLines(diff));

  return codeEditorSettings;
}

type Lines = {
  startLine: number;
  endLine: number;
}[];

function generateDiffedLines(diff: Diff[]) {
  const diffedLines: Record<"left" | "right", Lines> = {
    left: [],
    right: [],
  };

  const cursor = {
    left: 1,
    right: 1,
  };

  diff.forEach((chunk: Diff) => {
    const chunkType = chunk[0];
    const text = chunk[1];
    let lines = text.split("\n").length - 1;

    // diff-match-patch sometimes returns empty strings at random
    if (text.length === 0) {
      return;
    }

    const firstChar = text[0];
    const lastChar = text[text.length - 1];
    let linesToHighlight = 0;

    switch (chunkType) {
      case OPERATIONS.DIFF_EQUAL:
        cursor.left += lines;
        cursor.right += lines;

        break;
      case OPERATIONS.DIFF_DELETE:
        // If the deletion starts with a newline, push the cursor down to that line
        if (firstChar === "\n") {
          cursor.left++;
          lines--;
        }

        linesToHighlight = lines;

        // If the deletion does not include a newline, highlight the same line on the right
        if (linesToHighlight === 0) {
          diffedLines.right.push({
            startLine: cursor.right,
            endLine: cursor.right,
          });
        }

        // If the last character is a newline, we don't want to highlight that line
        if (lastChar === "\n") {
          linesToHighlight -= 1;
        }

        diffedLines.left.push({
          startLine: cursor.left,
          endLine: cursor.left + linesToHighlight,
        });

        cursor.left += lines;
        break;
      case OPERATIONS.DIFF_INSERT:
        // If the insertion starts with a newline, push the cursor down to that line
        if (firstChar === "\n") {
          cursor.right++;
          lines--;
        }

        linesToHighlight = lines;

        // If the insertion does not include a newline, highlight the same line on the left
        if (linesToHighlight === 0) {
          diffedLines.left.push({
            startLine: cursor.left,
            endLine: cursor.left,
          });
        }

        // If the last character is a newline, we don't want to highlight that line
        if (lastChar === "\n") {
          linesToHighlight -= 1;
        }

        diffedLines.right.push({
          startLine: cursor.right,
          endLine: cursor.right + linesToHighlight,
        });

        cursor.right += lines;
        break;
      default:
        throw new Error("Diff type was not defined.");
    }
  });

  return [diffedLines.left, diffedLines.right] as const;
}

function setCodeMarkers(linesAdded: Lines, linesDeleted: Lines) {
  const codeEditorSettings = [];

  const newMarkerSet: Record<
    "left" | "right",
    {
      startRow: number;
      endRow: number;
      type: string;
      className: string;
    }[]
  > = {
    left: [],
    right: [],
  };

  for (const line of linesAdded) {
    const markerObj = {
      startRow: line.startLine - 1,
      endRow: line.endLine,
      type: "text",
      className: "code-marker-added",
    };
    newMarkerSet.left.push(markerObj);
  }

  for (const line of linesDeleted) {
    const markerObj = {
      startRow: line.startLine - 1,
      endRow: line.endLine,
      type: "text",
      className: "code-marker-deleted",
    };
    newMarkerSet.right.push(markerObj);
  }

  codeEditorSettings[0] = newMarkerSet.left;
  codeEditorSettings[1] = newMarkerSet.right;

  return codeEditorSettings;
}

export const DiffEditor = ({
  codes,
  id,
  readOnly,
  interactive,
  autoScroll,
}: DiffEditorProps): ReactElement => {
  const { colorset, theme } = useContext(ThemeContext);
  const [blurDimension] = useState<{
    width: number;
    height: number;
  }>();
  const aceEditorRef = useRef<SplitComponent | null>(null);
  const [code1, code2] = codes;

  const markers = useMemo(() => getDiff(code1, code2), [code1, code2]);

  useReRenderOnSizeChangeOrScroll();
  useSplitEditorScroller(aceEditorRef, autoScroll);

  const AceEditorStyles: CSSProperties = {
    border: `1px solid ${colorset.borders.mutedLight}`,
    height: window.innerHeight * 0.75,
  };

  return (
    <CodeEditorContainer dynamicHeight>
      {readOnly && !interactive && (
        <ReadonlyBlur style={{ ...blurDimension }} />
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SplitEditor
        mode="json"
        ref={aceEditorRef}
        value={codes}
        readOnly
        theme={theme === Theme.LIGHT_MODE ? "clouds" : "chaos"}
        wrapEnabled
        width="100%"
        name={id}
        editorProps={{ $blockScrolling: true }}
        highlightActiveLine={false}
        enableLiveAutocompletion
        showGutter
        showPrintMargin={false}
        style={AceEditorStyles}
        markers={markers as IMarker[][]}
        setOptions={{
          useWorker: false,
        }}
      />
    </CodeEditorContainer>
  );
};

const ReadonlyBlur = styled.div`
  position: absolute;
  opacity: 0.3;
  background-color: ${({ theme }) => theme.backgrounds.muted};
  z-index: 10;
`;

const CodeEditorContainer = styled.div<{
  dynamicHeight: boolean;
}>`
  height: 100%;
  width: 100%;
  position: relative;

  .code-marker-added {
    background: ${({ theme }) => theme.backgrounds.successLight};
    position: absolute;
    z-index: 20;
  }

  .code-marker-deleted {
    background: ${({ theme }) => theme.backgrounds.errorLight};
    position: absolute;
    z-index: 20;
  }

  .ace_gutter {
    background-color: ${({ theme }) => theme.backgrounds.info};
    .ace_gutter-active-line {
      background-color: ${({ theme }) =>
        `${theme.backgrounds.highlight} !important`};
    }

    .ace_gutter-cell {
      color: white;
      background-color: ${({ theme }) => `${theme.backgrounds.info}`};
    }
  }

  .ace_content {
    height: 100% !important;
  }
`;
