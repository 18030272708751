import type { CustomTypeOptions } from "react-i18next";

import { ErrorLevel, APIError } from "../../../../../../../models/error";

export enum ErrorNames {
  EXPRESSION_PARSING_ERROR = "expression_parsing_error",
  MISSING_FIELD = "missing_field",
}

export class LocalizedMediaPortalError<T> extends APIError<T> {
  constructor(
    public message: keyof CustomTypeOptions["resources"]["configurationMatchRulesPage"],
    public name: ErrorNames,
    errorLevel: ErrorLevel = ErrorLevel.WARNING,
    data: T
  ) {
    super(message, "Parsing Match Rule Expression", errorLevel, data, false);
  }
}

export type LocalizedExpressionError = LocalizedMediaPortalError<{
  expression: string;
  missingField?: string;
}>;
