import { useState } from "react";
import styled from "@emotion/styled";

import {
  IScidDropdownOption,
  ISubHeaderOption,
} from "../SubHeader/BasicSubHeader/types";
import { ChevronDownIcon } from "../../atoms/ChevronDownIcon/ChevronDownIcon";
import { SubHeaderDropdownState } from "./SubHeaderDropdown";

interface ISubHeaderDropdownOptionProps<T> {
  option: ISubHeaderOption<T>;
  state: SubHeaderDropdownState;
  isDefault?: boolean;
  onClick: (option: ISubHeaderOption<T>) => void;
}

export const SubHeaderDropdownOption = <T extends unknown>({
  option,
  state,
  isDefault = false,
  onClick,
}: ISubHeaderDropdownOptionProps<T>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(option.open || false);
  const valueAsScidOption = option.value as IScidDropdownOption;

  return (
    <>
      <Option onClick={() => onClick(option)} isDefault={isDefault}>
        {state === SubHeaderDropdownState.PRIMARY ? (
          <>
            <OptionLabel>{option.text}</OptionLabel>
            {option.children && (
              <ChevronContainer
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(!isOpen);
                }}
              >
                <ChevronDownIcon open={isOpen} />
              </ChevronContainer>
            )}
          </>
        ) : (
          <OptionContainer>
            <OptionLabel>{option.text}</OptionLabel>
            {valueAsScidOption && (
              <div>
                <div>
                  {`${
                    valueAsScidOption.csn !== undefined
                      ? valueAsScidOption.csn + " • "
                      : ""
                  }
                ${valueAsScidOption.ssVersion.toUpperCase()}`}
                </div>
                {valueAsScidOption.aliases?.map((alias) => (
                  <div key={alias}> • {alias}</div>
                ))}
              </div>
            )}
          </OptionContainer>
        )}
      </Option>
      {isOpen &&
        option.children &&
        option.children.map((item, index) => (
          <SubContainer key={index}>
            <SubHeaderDropdownOption
              option={item}
              onClick={onClick}
              state={state}
            />
          </SubContainer>
        ))}
    </>
  );
};

const SubContainer = styled.div`
  border-left: solid 1px ${({ theme }) => theme.borders.mutedLight};
  margin-left: 16px;
`;

const Option = styled.span<{
  isDefault: boolean;
}>`
  color: ${({ theme }) => `${theme.text.primary} !important`};
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  display: flex;
  align-items: center;
  cursor: ${({ isDefault }) => !isDefault && "pointer"};
  padding: 16px;
  :hover {
    background-color: ${({ theme, isDefault }) =>
      !isDefault && theme.backgrounds.base};
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionLabel = styled.label``;

const ChevronContainer = styled.div`
  padding-left: 8px;
`;
