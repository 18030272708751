import styled from "@emotion/styled";

export const CardContainerBase = styled.div`
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 8px;

  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  :hover {
    box-shadow: 0px 8px 16px rgba(36, 37, 38, 0.12);
  }
`;

export const CardContainer = styled(CardContainerBase)`
  display: flex;
  flex-wrap: wrap;
`;
