import { IHeaderDefinitionHeader } from "../../../models/configuration/definitions";

export const getDuplicatedHeaderNames: (
  headers: IHeaderDefinitionHeader[]
) => IHeaderDefinitionHeader[] = (headers) =>
  headers.filter(
    (header, i) => headers.findIndex((h) => h.name === header.name) !== i
  );

export const getDuplicateElements: (e: string[]) => string[] = (elements) =>
  elements.filter((element, i) => elements.indexOf(element) !== i);

export const getDuplicateElementsWithIndexes: (
  e: string[]
) => { element: string; index: number }[] = (elements) =>
  elements.reduce((prev, element, index) => {
    const dupIndex = elements.indexOf(element);
    const isDup = dupIndex !== index;

    if (isDup) {
      prev.push({ element, index });
    }

    return prev;
  }, [] as ReturnType<typeof getDuplicateElementsWithIndexes>);
