import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { HeadlessDataTable } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../../components/atoms/UserManagementTable/UserManagementTable";
import { SearchBar } from "../../../../../components/molecules/SearchBar/SearchBar";
import {
  AccessGroupProduct,
  Products as ProductsEnum,
  ProductsLabel,
} from "../../../../../models/user-management/access-group/access-group.interfaces";
import { Button } from "../../../../../components/atoms/Button/Button";
import { IModalChildren } from "../../../../../components/atoms/Modal/Modal";
import { handleUpdateProducts } from "../../../../../store/slices/user-management-access-group/thunk";
import {
  useAccessGroupAvailableProducts,
  useAccessGroupInfos,
} from "../../../../../store/slices/user-management-access-group/hooks";
import {
  CustomCheckboxInput,
  SelectionStatus,
} from "../../../../../components/atoms/Checkbox/CustomCheckBoxInput";
import { useAppDispatch } from "../../../../../store/types";
import {
  buildProductsToAddList,
  generateSelectionState,
  handleFilterProductsToAdd,
  handleToggleProperty,
  handleToggleScid,
  TableSelection,
  toggleExpandRow,
} from "./helpers";
import { useBool } from "../../../../../hooks/use-bool/useBool";
import { ButtonLink } from "../../../../../components/atoms/ButtonLink/ButtonLink";

export const AddProductModalContent = ({
  closeModal,
}: IModalChildren): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  const accessGroupInfos = useAccessGroupInfos();

  const availableProducts = useAccessGroupAvailableProducts();

  const dispatch = useAppDispatch();

  const [search, setSearch] = useState("");

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<TableSelection>(new Map());

  const [showSelectedOnly, toggleShowSelectedOnly] = useBool(false);

  const displayedProducts: AccessGroupProduct[] = useMemo(
    () =>
      availableProducts?.filter(
        handleFilterProductsToAdd(search, showSelectedOnly, selectedRows)
      ) || [],
    [availableProducts, search, showSelectedOnly, selectedRows]
  );

  return (
    <Container>
      <Title>{t("ACCESS_GROUP_ADD_PRODUCTS_MODAL_TITLE")}</Title>
      <SearchBar
        placeholder={t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_SEARCH_PLACEHOLDER")}
        onChange={setSearch}
      />
      <TableContainer>
        <HeadlessDataTable
          data={displayedProducts}
          columns={[
            {
              dataField: "scid",
              name: t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_SCID_LABEL"),
              formatter: (row, rowIndex) => {
                const status = generateSelectionState(
                  row,
                  selectedRows.get(row.scid)
                );
                return (
                  <ScidCell>
                    <CustomCheckboxInput
                      selectionStatus={status}
                      disabled={row.selected}
                      onChange={() => {
                        setSelectedRows(handleToggleScid(row));
                      }}
                    />
                    <ScidText>{row.scid}</ScidText>
                    {!!row.childServiceList && (
                      <ClickDescription
                        onClick={() => {
                          setExpandedRows(toggleExpandRow(rowIndex));
                        }}
                      >
                        <SpinningIcon
                          isOpen={expandedRows.includes(rowIndex)}
                          name={Icons.ARROW_FAT}
                          color="black"
                          size={8}
                        />
                        <DescriptionText>
                          {t("ACCESS_GROUP_PROPERTIES_COUNT", {
                            partialCount: row.selected
                              ? row.childServiceList?.length
                              : (row.childServiceList?.filter(
                                  (prop) => prop.selected
                                ).length || 0) +
                                (selectedRows.get(row.scid)?.length || 0),
                            totalCount: row.childServiceList.length,
                          })}
                        </DescriptionText>
                      </ClickDescription>
                    )}
                  </ScidCell>
                );
              },
              style: { width: "70%" },
            },
            {
              dataField: "product",
              name: t("ACCESS_GROUP_PAGE_PRODUCT_TABLE_PRODUCT_LABEL"),
              formatter: (row) => (
                <ProductCell>
                  {ProductsLabel.get(row.product as ProductsEnum)}
                </ProductCell>
              ),
            },
          ]}
          expandParams={{
            expandedRows,
            render: (row) => {
              return (
                <td colSpan={2}>
                  <AliasesCell>
                    {row.childServiceList?.map(({ id, name, selected }) => {
                      return (
                        <ScidCell key={id}>
                          <CustomCheckboxInput
                            selectionStatus={
                              selected ||
                              row.selected ||
                              selectedRows.get(row.scid)?.includes(id)
                                ? SelectionStatus.SELECTED
                                : SelectionStatus.NOT_SELECTED
                            }
                            disabled={selected || row.selected}
                            onChange={() => {
                              if (selectedRows.size === 1 && showSelectedOnly) {
                                toggleShowSelectedOnly();
                              }
                              setSelectedRows(handleToggleProperty(row, id));
                            }}
                          />
                          <AliasName key={id}>{name}</AliasName>
                        </ScidCell>
                      );
                    })}
                  </AliasesCell>
                </td>
              );
            },
          }}
          keyField="scid"
          TableWrapper={UserManagementTable}
          TableHeadRow={StyledHeaderTr}
          TableHeadRowCell={UserManagementTableHeaderCell}
          TableBodyRow={UserManagementTableBodyRow}
          TableBodyRowCell={StyledBodyCell}
        />
      </TableContainer>
      <ButtonRow>
        {selectedRows.size > 0 && (
          <ButtonLink
            label={
              showSelectedOnly
                ? t("ACCESS_GROUP_ADD_PRODUCTS_MODAL_SHOW_ALL_PRODUCTS")
                : t(
                    "ACCESS_GROUP_ADD_PRODUCTS_MODAL_SHOW_ONLY_SELECTED_PRODUCTS",
                    {
                      count: selectedRows.size,
                    }
                  )
            }
            onClick={toggleShowSelectedOnly}
          />
        )}
        <RightButtonsContainer>
          <Button
            label={t("ACCESS_GROUP_ADD_PRODUCTS_MODAL_CANCEL")}
            backgroundColor="baseLight"
            textColor="primary"
            borderColor="white"
            onClick={() => {
              if (closeModal) closeModal();
            }}
          />
          <Button
            label={t("ACCESS_GROUP_ADD_PRODUCTS_MODAL_ADD")}
            disabled={selectedRows.size === 0}
            onClick={() => {
              if (availableProducts && accessGroupInfos) {
                const newProducts = buildProductsToAddList(
                  availableProducts,
                  selectedRows
                );
                if (newProducts) {
                  dispatch(
                    handleUpdateProducts(
                      accessGroupInfos.accessGroupId,
                      newProducts,
                      () => {
                        if (closeModal) closeModal();
                      }
                    )
                  );
                }
              }
            }}
          />
        </RightButtonsContainer>
      </ButtonRow>
    </Container>
  );
};

const ButtonRow = styled(FlexBox)`
  justify-content: space-between;
`;

const RightButtonsContainer = styled(FlexBox)`
  margin-left: auto;
`;

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const StyledBodyCell = styled.td`
  padding-top: 16px !important;
  padding-bottom: 16px;
`;

const StyledHeaderTr = styled(UserManagementTableHeaderRow)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;

const AliasesCell = styled.div`
  padding: 10px;
  margin-left: 30px;
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ScidText = styled.div`
  margin-left: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.primary};
`;

const ProductCell = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #${({ theme }) => theme.text.secondary};
`;

const AliasName = styled.div`
  margin-left: 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.primary};
`;

const ScidCell = styled(FlexBox)`
  justify-content: flex-start;
`;

const ClickDescription = styled(FlexBox)`
  justify-content: flex-start;
  cursor: pointer;
`;

const SpinningIcon = styled(Icon)<{ isOpen: boolean }>`
  margin-left: 12px;
  margin-right: 12px;
  transform: rotate(${({ isOpen }) => (isOpen ? "90deg" : "-90deg")});
`;

const DescriptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.muted};
`;

const TableContainer = styled.div`
  height: 30vh;
  overflow-y: auto;
`;
