import { FC, useContext } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ReactComponent as LumenLogoSvg } from "../../../assets/icons/lumen-logo.svg";
import { HeaderContext } from "../../../contexts/headerContext";
import { OldNavigator } from "./old-navigator/OldNavigator";
import { RightHeader } from "./RightHeader";
import { useHeader } from "../../../store/slices/header/hooks";

interface IHeaderProps {}

export const Header: FC<IHeaderProps> = () => {
  const { headerRef } = useContext(HeaderContext);
  const { hide, deafaultHeaderHeight } = useHeader();

  return (
    <HeaderContainer
      ref={headerRef}
      hide={hide}
      height={headerRef?.current?.clientHeight ?? deafaultHeaderHeight}
    >
      <NewHeaderContainer id="header" className="chi-header">
        <NavHeader className="chi-header__content">
          <div className="chi-header__brand">
            <a className="chi-brand" href="/cmv3" aria-label="Lumen">
              <LumenLogo />
            </a>
            <Title className="chi-header__title -d--none -d-sm--flex">
              Media Portal
            </Title>
          </div>
          <div className="chi-header__start"></div>
          <div className="chi-header__end">
            <RightHeader />
          </div>
        </NavHeader>
      </NewHeaderContainer>
      <OldNavigator />
    </HeaderContainer>
  );
};

const HIDE_HEADER_SLOWLY = 0.25;
const SHOW_HEADER_QUICKLY = 0.1;

const HeaderContainer = styled.div<{
  hide: boolean;
  height: number;
}>`
  transition: all
    ${({ hide }) => (hide ? HIDE_HEADER_SLOWLY : SHOW_HEADER_QUICKLY)}s ease;
  ${({ hide, height }) =>
    hide &&
    css`
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(${height * -1}px);
      opacity: 0;
    `}
`;

const NewHeaderContainer = styled.header`
  width: 100%;
  height: 50px;
  min-height: 50px;
  box-sizing: border-box;

  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`} !important;

  box-shadow: none !important;
  border-bottom: 1px solid ${({ theme }) => `${theme.borders.mutedLight}`};
  background-color: ${({ theme }) =>
    `${theme.backgrounds.baseLight} !important`};
`;

const NavHeader = styled.nav`
  padding: 0rem !important;
`;

const Title = styled.div`
  color: ${({ theme }) => `${theme.text.primary} !important`};
`;

const LumenLogo = styled(LumenLogoSvg)`
  height: 45px;
  #font_color {
    fill: ${({ theme }) => theme.icon.primary};
  }
`;
