import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, IconProps, Icons } from "../Icon/Icon";

export const CheckIcon = (props: Omit<IconProps, "name">): ReactElement => (
  <CheckIconBase {...props} name={Icons.CHECK} />
);

const CheckIconBase = styled(Icon)`
  width: auto;
  * {
    fill: ${({ theme }) => theme.colors.green50};
  }
  padding-right: 8px;
`;
