import { Status } from "../../../@types/status";
import { APIError } from "../../../models/error";
import {
  addUserToAccessGroups,
  getUser,
  getUsers,
  removeUserFromAccessGroups,
} from "../../../models/user-management/users";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import {
  setUsers,
  setUser,
  setUserManagementUserStatus,
  setUserManagementUsersListStatus,
} from "./slice";

export const fetchUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserManagementUsersListStatus(Status.LOADING));
    const data = await getUsers();
    dispatch(setUsers(data));
    dispatch(setUserManagementUsersListStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setUserManagementUsersListStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchUser = (userId: number): AppThunk => async (dispatch) => {
  try {
    dispatch(setUserManagementUserStatus(Status.LOADING));
    const data = await getUser(userId);
    dispatch(setUser(data));
    dispatch(setUserManagementUserStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setUserManagementUserStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleUpdateUserAccessGroups = (
  userId: number,
  accessGroupsToAdd: number[],
  accessGroupsToDelete: number[],
  cb?: () => void
): AppThunk => async (dispatch) => {
  try {
    await addUserToAccessGroups(userId, accessGroupsToAdd);
    await removeUserFromAccessGroups(userId, accessGroupsToDelete);
    dispatch(fetchUser(userId));
    if (cb) cb();
  } catch (err) {
    handleAPIError(err as APIError);
  }
};
