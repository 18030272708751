import { useAppSelector } from "../../types";

export const useIsViewMode = () => {
  const isViewMode = useAppSelector((store) => store.permissions.isViewMode);
  return isViewMode;
};

export const useUserPermissions = () => {
  const userPermissions = useAppSelector(
    (store) => store.permissions.userPermissions
  );
  return userPermissions;
};

export const usePermissionsStatus = () => {
  const status = useAppSelector((store) => store.permissions.status);
  return status;
};

export const useHasCdn = () => {
  // An user has CDN access only if they have Reporting permission
  const userPermissions = useAppSelector(
    (store) => store.permissions.userPermissions
  );
  return userPermissions?.some((perm) => perm.name === "Reporting");
};
