import { useContext } from "react";

import { ThemeContext } from "../../../contexts/themeContext";

export interface ImageVariants {
  light: string;
  dark: string;
}

export const useImage = (images: ImageVariants): string => {
  const { theme } = useContext(ThemeContext);

  return images[theme];
};
