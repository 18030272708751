import type { ReactElement } from "react";
import type {
  FieldError,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeaderDefinitionModal } from "../../../../../../../../../components/molecules/HeaderDefinitionModal/HeaderDefinitionModal";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  HeaderDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { getDefsByHeader, selectedNames, toName } from "./helpers";

interface RequestHeadersFeatureBlockProps {
  reqHeaders: Exclude<IFeatures["reqHeaders"], undefined>;
  config: ConfigurationDetailsType;
  initIsOpen: boolean;
  maxWidth: number;
  errors: ValidationErrors;
  datatestId: string;
  onDelete: () => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  handleCreateDefinition: (definition: HeaderDefinitionType) => void;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<IFeatures>;
}

export const RequestHeadersFeatureBlock = ({
  reqHeaders,
  config,
  initIsOpen,
  maxWidth,
  errors,
  datatestId,
  onDelete,
  handleCreateFeature,
  handleCreateDefinition,
  setError,
  clearErrors,
}: RequestHeadersFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_REQUEST_HEADERS_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_REQUEST_HEADERS_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_REQUEST_HEADERS_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_REQUEST_HEADERS_DEFINITION_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_REQUEST_HEADERS_DEFINITION_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_REQUEST_HEADERS_DEFINITION_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          maxWidth={maxWidth}
          errorMessage={
            (errors.reqHeaders?.nameList as FieldError | undefined)?.message
          }
          fieldProps={{
            other: config.headerDefinitions ? (
              <DefinitionDropdown
                id="reqHeaders.nameList"
                type={FeatureType.HEADERS}
                disabled={isViewMode}
                items={getDefsByHeader(config.headerDefinitions)}
                selected={config.headerDefinitions.filter(
                  selectedNames(reqHeaders.nameList)
                )}
                onSelect={(selected) =>
                  handleCreateFeature(
                    "reqHeaders.nameList",
                    selected.map(toName)
                  )
                }
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                dataTestId="req-headers-definition"
                parentConfig={config}
              />
            ) : (
              <HeaderDefinitionModal
                parentConfig={config}
                onSubmit={handleCreateDefinition}
              />
            ),
          }}
          dataTestId="req-headers"
        />
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"reqHeaders"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="req-headers"
      featureType={FeatureTypes.REQUEST_MANIPULATION}
    />
  );
};
