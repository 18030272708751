import { ComponentType, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Status } from "../../../@types/status";
import { useSubscriberSettingsStatus } from "../../../store/slices/subscriber-settings/hooks";
import { fetchSubscriberSettings } from "../../../store/slices/subscriber-settings/thunks";
import { useSubscriberId } from "../../../store/slices/subscriber/hooks";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";

export function withSubscriberSettings<
  WrappedComponentProps extends Record<any, any>
>(
  WrappedComponent: ComponentType<WrappedComponentProps>
): {
  (props: WrappedComponentProps): ReactElement | null;
  displayName: string;
} {
  function WithSubscriberSettings(
    props: WrappedComponentProps
  ): ReactElement | null {
    const subscriberId = useSubscriberId();
    const status = useSubscriberSettingsStatus();
    const dispatch = useAppDispatch();
    const [t] = useTranslation("layers");

    useEffect(() => {
      dispatch(fetchSubscriberSettings(subscriberId));

      // Ignore dep dispatch.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriberId]);

    if (status === Status.LOADING) {
      return (
        <LoaderWithBackground>
          {t("SUBSCRIBER_SETTINGS_LAYER_LOADING_DESC")}
        </LoaderWithBackground>
      );
    }

    if (status === Status.SUCCESS) {
      return <WrappedComponent {...props} />;
    }

    if (status === Status.ERROR) {
      return <ErrorLayer dataTestId="subscriber-settings-layer-error" />;
    }

    return null;
  }

  WithSubscriberSettings.displayName = `withSubscriberSettings(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return WithSubscriberSettings;
}
