import { ISubHeaderOption } from "../SubHeader/BasicSubHeader/types";
import { SubHeaderDropdown, SubHeaderDropdownState } from "./SubHeaderDropdown";

interface SubHeaderCustomerDropdownProps<T> {
  options: ISubHeaderOption<T>[] | undefined;
  onSelect: (option: ISubHeaderOption<T>) => void;
  open: boolean;
  isVyvx?: boolean;
}

const onSearch = <T extends string | number>(
  search: string,
  optionsToFilter: ISubHeaderOption<T>[]
): ISubHeaderOption<T>[] => {
  const newArray: ISubHeaderOption<T>[] = [];
  optionsToFilter.reduce((previous, current) => {
    if (current.text.toLowerCase().includes(search.toLowerCase().trim())) {
      previous.push(current);
    } else {
      const newOption: ISubHeaderOption<T> = {
        ...current,
        children:
          current.children &&
          onSearch(search.toLowerCase().trim(), current.children),
      };
      if (newOption.children && newOption.children.length > 0) {
        previous.push(newOption);
      }
    }
    return previous;
  }, newArray);
  return newArray;
};

export const SubHeaderCustomerDropdown = <T extends string | number>({
  options,
  onSelect,
  open,
  isVyvx = false,
}: SubHeaderCustomerDropdownProps<T>) => {
  return (
    <SubHeaderDropdown
      searchPlaceholder={isVyvx ? "Search a Company" : "Search an Access Group"}
      options={options}
      onSelect={onSelect}
      open={open}
      state={SubHeaderDropdownState.PRIMARY}
      onSearch={onSearch}
    />
  );
};
