import styled from "@emotion/styled";

import { FlexBox } from "../FlexBox/FlexBox";

export const ButtonLink = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}): JSX.Element => (
  <Container onClick={onClick}>
    <LinkText>{label}</LinkText>
  </Container>
);

const Container = styled(FlexBox)`
  cursor: pointer;
  & > div:first-child {
    margin-right: 8px;
  }
`;

const LinkText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.text.highlight};
`;
