import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/atoms/Button/Button";
import { IDropdownItem } from "../../../../../components/molecules/DropdownOption/DropdownOption";
import { SearchInput } from "../../../../../components/molecules/SearchInput/SearchInput";
import { DropdownMultiSelect } from "../../../../../components/organisms/Dropdown/DropdownMultiSelect";
import {
  InvalidationScope,
  InvalidationStatus,
  SortByOption,
} from "./ContentInvalidation";

interface SearchBarProps {
  setModalOpen: (param: boolean) => void;
  onSearch: (search: string) => void;
  scope: InvalidationScope;
  setScope: (scope: InvalidationScope) => void;
  status: InvalidationStatus;
  setStatus: (status: InvalidationStatus) => void;
  sortOrder: SortByOption;
  setSortOder: (sortOrder: SortByOption) => void;
}

export const SearchBar = ({
  setModalOpen,
  onSearch,
  scope,
  setScope,
  status,
  setStatus,
  sortOrder,
  setSortOder,
}: SearchBarProps): ReactElement => {
  const [t] = useTranslation("contentInvalidationPage");
  const [tMisc] = useTranslation("misc");

  const VALIDATION_STATUS_ITEMS: IDropdownItem<InvalidationStatus>[] = [
    { label: t("ALL_STATUSES"), value: "all" },
    { label: tMisc("PENDING"), value: "pending" },
    { label: tMisc("IN_PROGRESS"), value: "in_progress" },
    { label: tMisc("COMPLETED"), value: "completed" },
  ];

  const VALIDATION_SCOPE_ITEMS: IDropdownItem<InvalidationScope>[] = [
    { label: t("ALL_INVALIDATIONS"), value: "all", default: true },
    {
      label: t("MY_INVALIDATIONS"),
      value: "current_user",
    },
  ];

  const SORT_BY_OPTION: IDropdownItem<SortByOption>[] = [
    { label: t("DATE_ASC"), value: "asc" },
    {
      label: t("DATE_DESC"),
      value: "desc",
      default: true,
    },
  ];

  const selectedScope = VALIDATION_SCOPE_ITEMS.find((i) => i.value === scope);
  const selectedStatus = VALIDATION_STATUS_ITEMS.find(
    (i) => i.value === status
  );
  const selectedSortOption = SORT_BY_OPTION.find((i) => i.value === sortOrder);

  return (
    <SearchBarWrapper>
      <SearchInput
        inputProps={{
          placeholder: t("SEARCHBAR_PLACEHOLDER"),
          onChange: (event) => onSearch(event.currentTarget.value),
        }}
        searchInputWrapperProps={{
          style: {
            width: "38%",
          },
        }}
      />
      <StyledDropdown
        placeholder={""}
        items={SORT_BY_OPTION}
        selectedItems={[selectedSortOption as IDropdownItem<SortByOption>]}
        setSelectedItems={(item) => setSortOder(item[0].value as SortByOption)}
      />
      <StyledDropdown
        placeholder={""}
        items={VALIDATION_SCOPE_ITEMS}
        selectedItems={[selectedScope as IDropdownItem<InvalidationScope>]}
        setSelectedItems={(item) =>
          setScope(item[0].value as InvalidationScope)
        }
      />
      <StyledDropdown
        placeholder={tMisc("STATUS")}
        items={VALIDATION_STATUS_ITEMS}
        selectedItems={[selectedStatus as IDropdownItem<InvalidationStatus>]}
        setSelectedItems={(item) =>
          setStatus(item[0].value as InvalidationStatus)
        }
        dataTestId="button-status"
      />
      <StyledButton
        label={t("NEW_REQUEST")}
        onClick={() => {
          setModalOpen(true);
        }}
        dataTestId={"button-new-request-CI"}
      />
    </SearchBarWrapper>
  );
};

const SearchBarWrapper = styled.div`
  display: flex;

  & > div,
  & > button {
    flex-grow: 1;
  }

  & > div {
    margin-right: 16px;
  }
`;

const StyledDropdown = styled(DropdownMultiSelect)`
  width: 16%;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  max-width: 200px;
`;
