import styled from "@emotion/styled";
import { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { AccessGroupRow } from "../../../../../../components/atoms/AccessGroupRow/AccessGroupRow";
import { Button } from "../../../../../../components/atoms/Button/Button";
import { ButtonLink } from "../../../../../../components/atoms/ButtonLink/ButtonLink";
import { CustomCheckboxInput } from "../../../../../../components/atoms/Checkbox/CustomCheckBoxInput";
import {
  FlexBox,
  FlexBoxBase,
} from "../../../../../../components/atoms/FlexBox/FlexBox";
import { HeadlessDataTable } from "../../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { IModalChildren } from "../../../../../../components/atoms/Modal/Modal";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../../../components/atoms/UserManagementTable/UserManagementTable";
import {
  NestedUserAccessGroupRow,
  NestedUserAccessGroupRowFields,
} from "../../../../../../components/molecules/NestedUserAccessGroupRow/NestedUserAccessGroupRow";
import { SearchBar } from "../../../../../../components/molecules/SearchBar/SearchBar";
import { useBool } from "../../../../../../hooks/use-bool/useBool";
import { UserAccessGroup } from "../../../../../../models/user-management/users/users.interfaces";
import { AccessGroup } from "../../../../../../models/user/user.interfaces";
import { useAccessGroups } from "../../../../../../store/slices/access-groups/hooks";
import { handleUpdateUserAccessGroups } from "../../../../../../store/slices/user-management-users/thunk";
import { useAppDispatch } from "../../../../../../store/types";
import {
  buildAccessGroupsUpdated,
  generateAccessGroupSelectionState,
  handleFilterAccessGroupsToAdd,
} from "../helpers";

interface UserEditAccessGroupsModalProps extends IModalChildren {
  userId: number;
  userAccessGroups: UserAccessGroup[];
}

export const UserEditAccessGroupsModal = ({
  userId,
  userAccessGroups,
  closeModal,
}: UserEditAccessGroupsModalProps): ReactElement => {
  const [search, setSearch] = useState("");
  const [showSelectedOnly, toggleShowSelectedOnly] = useBool(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState(
    userAccessGroups.map((group) => group.name)
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation("usersPage");
  const accessGroups = useAccessGroups();

  const displayedAccessGroups: AccessGroup[] = useMemo(
    () =>
      handleFilterAccessGroupsToAdd(
        search,
        showSelectedOnly,
        selectedRows
      )(accessGroups),
    [accessGroups, search, showSelectedOnly, selectedRows]
  );

  const onCheckboxSelect = (rowName: string) => {
    setSelectedRows((rows) => {
      if (rows.includes(rowName)) {
        return rows.filter((r) => r !== rowName);
      } else {
        return [...rows, rowName];
      }
    });
  };

  const onSubmitAccessGroups = () => {
    const {
      accessGroupsToAdd,
      accessGroupsToDelete,
    } = buildAccessGroupsUpdated(userAccessGroups, accessGroups, selectedRows);

    dispatch(
      handleUpdateUserAccessGroups(
        userId,
        accessGroupsToAdd,
        accessGroupsToDelete,
        () => {
          if (closeModal) closeModal();
          toast.success(t("EDIT_ACCESS_GROUP_MODAL_SUCCESS_TOAST"));
        }
      )
    );
  };

  return (
    <Container>
      <Title>{t("EDIT_ACCESS_GROUP_MODAL_TITLE")}</Title>
      <SearchBar
        placeholder={t("EDIT_ACCESS_GROUP_MODAL_SEARCH_PLACEHOLDER")}
        onChange={setSearch}
      />
      <TableContainer>
        <HeadlessDataTable
          data={displayedAccessGroups}
          columns={[
            {
              dataField: "name",
              name: t("EDIT_ACCESS_GROUP_MODAL_GROUP_COLUMN_TITLE"),
              formatter: (row, rowIndex) => {
                const status = generateAccessGroupSelectionState(
                  row,
                  selectedRows
                );

                return (
                  <AccessGroupCell>
                    <CustomBoxContainer>
                      <CustomCheckboxInput
                        selectionStatus={status}
                        onChange={() => onCheckboxSelect(row.name)}
                      />
                    </CustomBoxContainer>
                    <AccessGroupRow
                      id={row.id}
                      name={row.name}
                      childrenCount={row.list?.length || 0}
                      isOpen={expandedRows.includes(rowIndex)}
                      toggleIsOpen={() => {
                        setExpandedRows((eR) => {
                          if (eR.includes(rowIndex)) {
                            return eR.filter((rI) => rI !== rowIndex);
                          } else {
                            return [...eR, rowIndex];
                          }
                        });
                      }}
                    />
                  </AccessGroupCell>
                );
              },
            },
          ]}
          expandParams={{
            expandedRows,
            render: (row) => (
              <>
                {row.list?.map((ag: AccessGroup) => (
                  <NestedUserAccessGroupRow
                    key={ag.id}
                    {...ag}
                    nestedIndex={1}
                    fields={[
                      NestedUserAccessGroupRowFields.ACCESS_GROUP,
                      NestedUserAccessGroupRowFields.CUSTOM_CHECKBOX,
                    ]}
                    buildCheckboxProps={(item) => ({
                      selectionStatus: generateAccessGroupSelectionState(
                        item,
                        selectedRows
                      ),
                      onChange: () => onCheckboxSelect(item.name),
                    })}
                    userId={userId}
                  />
                ))}
              </>
            ),
          }}
          keyField="id"
          TableWrapper={UserManagementTable}
          TableHeadRow={UserManagementTableHeaderRow}
          TableHeadRowCell={UserManagementTableHeaderCell}
          TableBodyRow={UserManagementTableBodyRow}
        />
      </TableContainer>
      <ButtonsRow>
        <ButtonLink
          label={
            showSelectedOnly
              ? t("EDIT_ACCESS_GROUP_MODAL_SHOW_ALL")
              : t("EDIT_ACCESS_GROUP_MODAL_SHOW_SELECTED", {
                  counter: selectedRows.length,
                })
          }
          onClick={toggleShowSelectedOnly}
        />
        <FlexBox>
          <CancelButton
            label={t("EDIT_ACCESS_GROUP_MODAL_CANCEL_BUTTON")}
            backgroundColor="baseLight"
            textColor="primary"
            borderColor="white"
            onClick={() => {
              if (closeModal) closeModal();
            }}
          />
          <SubmitButton
            label={t("EDIT_ACCESS_GROUP_MODAL_SUBMIT_BUTTON")}
            disabled={selectedRows.length === 0}
            onClick={onSubmitAccessGroups}
          />
        </FlexBox>
      </ButtonsRow>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  max-width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const TableContainer = styled.div`
  padding: 32px 0;
  height: 45vh;
  overflow-y: auto;
`;

const AccessGroupCell = styled(FlexBox)`
  justify-content: flex-start;
`;

const CustomBoxContainer = styled.div`
  padding-right: 16px;
`;

const ButtonsRow = styled(FlexBoxBase)`
  justify-content: space-between;
`;

const CancelButton = styled(Button)`
  padding: 12px 16px;
  margin-right: 16px;
`;

const SubmitButton = styled(Button)`
  padding: 12px 16px;
`;
