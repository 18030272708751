import { Status } from "./../../../@types/status";
import { AccessGroup } from "./../../../models/user/user.interfaces";
import { useAppSelector } from "../../types";

export const useAccessGroupId = (): number => {
  const accessGroupId = useAppSelector(
    (store) => store.accessGroups.accessGroupId
  );

  if (!accessGroupId) {
    throw new Error('"accessGroupId" is undefined in "accessGroups"');
  }

  return accessGroupId;
};

export const useUnsafeAccessGroupId = (): number | undefined =>
  useAppSelector((store) => store.accessGroups.accessGroupId);

export const useAccessGroups = (): AccessGroup[] =>
  useAppSelector((store) => store.accessGroups.accessGroups);

export const useAccessGroupsQueryStatus = (): Status =>
  useAppSelector((store) => store.accessGroups.accessGroupsQueryStatus);
