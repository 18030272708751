import { createContext, useContext } from "react";

export interface AppEnvironmentContext {
  environment: string;
  version: string;
  isProd: boolean;
}

const AppEnvironmentContext = createContext<AppEnvironmentContext | undefined>(
  undefined
);

export const {
  Provider: AppEnvironmentContextProvider,
} = AppEnvironmentContext;

export const useAppEnvironmentContext = (): AppEnvironmentContext => {
  const appEnvironmentContext = useContext(AppEnvironmentContext);
  if (appEnvironmentContext === undefined) {
    throw new Error(
      '"appEnvironmentContext" is undefined. Use this hook inside "AppEnvironmentContextProvider".'
    );
  }
  return appEnvironmentContext;
};
