import styled from "@emotion/styled";

export const InputBase = styled.input`
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  :focus {
    outline: none;
  }
`;
