import { VyvxFiberCompany } from "../../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { ApiKeysCustomerDropdown } from "../ApiKeysCustomerDropdown";
import { ApiKeysCustomerFilter } from "../types";

interface VyvxApiKeysFilterProps {
  fiberCompanies: VyvxFiberCompany[];
  keyCompanyFilter: ApiKeysCustomerFilter;
  onCompnayFilterChange: (value: ApiKeysCustomerFilter) => void;
  isFilter?: boolean;
  isVyvx: boolean;
}

export const VyvxApiKeysCompanies = ({
  fiberCompanies,
  keyCompanyFilter,
  onCompnayFilterChange,
  isFilter = false,
  isVyvx,
}: VyvxApiKeysFilterProps): JSX.Element => {
  const companyDropdownItems = Object.values(fiberCompanies).map((company) => {
    return {
      text: company.companyName,
      value: company.companyAbbr,
      selected: false,
      open: true,
    };
  });

  return (
    <ApiKeysCustomerDropdown
      customerFilter={keyCompanyFilter}
      onCustomerChange={onCompnayFilterChange}
      isVyvx={isVyvx}
      isFilter={isFilter}
      customerDropdownItems={companyDropdownItems}
    />
  );
};
