import { ReactElement, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { format } from "date-fns-tz";
import { useTranslation } from "react-i18next";

import { ToggleTheme } from "../../../components/molecules/Toogle/ThemeToggle";
import { BackendSelector } from "../../../components/organisms/BackendSelector/BackendSelector";
import { httpClient } from "../../../core/http-client";
import {
  useDispatchExitMimick,
  useUserIsMimickUNSAFE,
  useUserFirstNameUNSAFE,
  useUserLastNameUNSAFE,
  useUserOriginalAuthenticatedFirstnameUNSAFE,
} from "../../../store/slices/user/hooks";
import { useAppEnvironmentContext } from "../../../contexts/appEnvironmentContext";

export const RightHeader = (): ReactElement => {
  const isMimick = useUserIsMimickUNSAFE();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { t } = useTranslation("header");
  const firstName = useUserFirstNameUNSAFE();
  const lastName = useUserLastNameUNSAFE();
  const originalAuthenticatedFirstname = useUserOriginalAuthenticatedFirstnameUNSAFE();

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentDate(new Date()), 6000);
    return () => clearInterval(intervalId);
  }, []);

  const dispatchExitMimick = useDispatchExitMimick();

  const { isProd } = useAppEnvironmentContext();

  return (
    <RightHeaderContainer>
      {!isProd && (
        <BackendSelectorContainer>
          <BackendSelector />
        </BackendSelectorContainer>
      )}
      <ToggleThemeContainer>
        <ToggleTheme />
      </ToggleThemeContainer>
      <InformationContainer>
        <UpperContainer>
          {isMimick && originalAuthenticatedFirstname ? (
            <NameContainer underline={false} className="-text--lg">
              ({t("RIGHT_HEADER_MIMICKING")} {firstName} {lastName} |{" "}
              <a onClick={dispatchExitMimick}>{t("RIGHT_HEADER_END_MIMICK")}</a>
              ) {t("RIGHT_HEADER_HELLO")} {originalAuthenticatedFirstname}
            </NameContainer>
          ) : (
            <NameContainer
              underline
              className="-text--lg"
              onClick={() => httpClient.goToOldMP("/ui/myProfile")}
            >
              Hello {firstName}
            </NameContainer>
          )}
          <Separator />
          <SignOut
            onClick={() => httpClient.goToOldMP("/j_spring_security_logout")}
            className="-text--lg"
          >
            Sign Out
          </SignOut>
        </UpperContainer>
        <LowerContainer>
          <DateContainer className="-text--sm">
            {format(currentDate, "MMM d, yyyy H:mm")}
          </DateContainer>
        </LowerContainer>
      </InformationContainer>
    </RightHeaderContainer>
  );
};

const RightHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
`;

const BackendSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const ToggleThemeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const NameContainer = styled.div<{ underline: boolean }>`
  text-align: center;
  margin-right: 5px;
  cursor: pointer;

  ${({ underline }) =>
    underline &&
    css`
      :hover {
        text-decoration: underline;
      }
    `}
`;

const SignOut = styled.div`
  text-align: center;
  margin-left: 5px;

  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;

  text-align: center;
  font-size: 12px;
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Separator = styled.hr`
  height: 100%;
  width: 1px;
  margin: 0px 10px;
  border: none;
  background-color: #bfbfbf;
`;
