import { FC, ReactElement } from "react";
import { WidgetContainer } from "../../atoms/WidgetContainer/WidgetContainer";
import { WidgetHeader, WidgetTitle } from "../../atoms/WidgetTitle/WidgetTitle";

export const WidgetLayout: FC<{
  title?: string;
  cta?: ReactElement | undefined;
}> = ({ children, title, cta }) => (
  <WidgetContainer>
    {(title || cta) && (
      <WidgetHeader>
        <WidgetTitle>{title}</WidgetTitle>
        {cta}
      </WidgetHeader>
    )}
    {children}
  </WidgetContainer>
);
