import { Children, FC, useState } from "react";

import { FontLink } from "../../atoms/Font/Font";

interface ExpandableListProps {
  limit?: number;
  dataTestId?: string;
}

export const ExpandableList: FC<ExpandableListProps> = ({
  children,
  limit = 1,
  dataTestId = "",
}) => {
  const [internalLimit, setInternalLimit] = useState(limit);
  const arrayChildren = Children.toArray(children);
  const size = arrayChildren.length;

  return (
    <>
      {Children.map(arrayChildren, (child, i) => {
        if (i < internalLimit) return <>{child}</>;
      })}

      {size > internalLimit ? (
        <FontLink
          onClick={() => {
            setInternalLimit(size);
          }}
          data-testid={`${dataTestId}-view-more`}
        >{`View more (${size - internalLimit})`}</FontLink>
      ) : size > 1 ? (
        <FontLink
          onClick={() => {
            setInternalLimit(limit);
          }}
          data-testid={`${dataTestId}-show-less`}
        >
          {"Show less"}
        </FontLink>
      ) : null}
    </>
  );
};
