import styled from "@emotion/styled";

export const WidgetsContainer = styled.div`
  display: flex;
  justify-content: center;
  & > div:not(:last-child) {
    margin-right: 24px;
  }
  margin-bottom: 16px;
`;
