import { css } from "@emotion/react";
import styled from "@emotion/styled";

export type TextSize = "xsmall" | "small" | "medium" | "large" | "xlarge";

interface ContentSwitcherTabProps {
  selected: boolean;
  size?: TextSize;
}

export const ContentSwitcherTab = styled.div<ContentSwitcherTabProps>`
  grid-row: 1;
  white-space: nowrap;

  display: flex;
  justify-content: center;

  padding: 12px;
  font-weight: 600;

  background-color: ${({ theme, selected }) =>
    selected ? theme.backgrounds.highlightLight : theme.backgrounds.baseLight};
  color: ${({ theme, selected }) =>
    selected ? theme.text.highlight : theme.text.secondary};

  :hover {
    background-color: ${({ theme, selected }) =>
      !selected && theme.backgrounds.base};
    color: ${({ theme, selected }) => !selected && theme.text.highlight};
  }

  cursor: ${({ selected }) => (selected ? "default" : "pointer")};

  ${({ size }) => {
    switch (size) {
      case "xsmall":
        return css`
          font-size: 0.75rem !important;
          line-height: 1rem !important;
        `;
      case "small":
        return css`
          font-size: 0.8125rem !important;
          line-height: 1.25rem !important;
        `;
      case "medium":
        return css`
          font-size: 0.875rem !important;
          line-height: 1.5rem !important;
        `;
      case "large":
        return css`
          font-size: 1rem !important;
          line-height: 1.5rem !important;
        `;
      case "xlarge":
        return css`
          font-size: 1.125rem !important;
          line-height: 1.75rem !important;
        `;
    }
  }}
`;
