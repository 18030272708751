import { format } from "date-fns";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { HeadlessDataTable } from "../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { AccessGroup } from "../../../../models/user/user.interfaces";
import { useAccessGroups } from "../../../../store/slices/access-groups/hooks";
import { AccessGroupRow } from "../../../../components/atoms/AccessGroupRow/AccessGroupRow";
import { AccessGroupTableFilter } from "./AccessGrouspTableFilter";
import { filterAccessGroups } from "./helpers";
import { NestedAccessGroupRow } from "../../../../components/molecules/NestedAccessGroupsRow/NestedAccessGroupRow";
import { DateFilter } from "./types";
import { Icons } from "../../../../components/atoms/Icon/Icon";
import { LinkArrow } from "../../../../components/molecules/LinkCTA/LinkCTA";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../components/atoms/UserManagementTable/UserManagementTable";

export const AccessGroupsTable = (): JSX.Element => {
  const [searchInput, setSearchInput] = useState("");
  const [creationDateFilter, setCreationDateFilter] = useState<DateFilter>(
    DateFilter.ALL_TIME
  );

  const accessGroups = useAccessGroups();

  const displayedAccessGroups = useMemo(
    () => filterAccessGroups(searchInput, creationDateFilter)(accessGroups),
    [accessGroups, creationDateFilter, searchInput]
  );

  const { t } = useTranslation("accessGroupsPage");

  const history = useHistory();

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  return (
    <>
      <AccessGroupTableFilter
        onSearchInputChange={setSearchInput}
        creationDateFilter={creationDateFilter}
        onCreationDateFilterChange={setCreationDateFilter}
      />
      <HeadlessDataTable
        data={displayedAccessGroups}
        columns={[
          {
            dataField: "name",
            name: t("ACCESS_GROUPS_TABLE_NAME_LABEL"),
            formatter: ({ id, name, createdDate, list }, rowIndex) => (
              <AccessGroupRow
                id={id}
                name={name}
                childrenCount={list?.length || 0}
                isOpen={expandedRows.includes(rowIndex)}
                toggleIsOpen={() => {
                  setExpandedRows((eR) => {
                    if (eR.includes(rowIndex)) {
                      return eR.filter((rI) => rI !== rowIndex);
                    } else {
                      return [...eR, rowIndex];
                    }
                  });
                }}
              />
            ),
            style: { width: "70%" },
          },
          {
            dataField: "createdDate",
            name: t("ACCESS_GROUPS_TABLE_CREATION_DATE_LABEL"),
            formatter: (row) => {
              return format(Date.parse(row.createdDate), "MMM d, yyyy H:mm");
            },
            style: { width: "25%" },
          },
          {
            dataField: "name",
            name: "",
            formatter: (row) =>
              !!row.list && (
                <LinkArrow
                  name={Icons.ARROW_LEFT}
                  onClick={() => {
                    history.push(`/userManagement/accessGroups/${row.id}`);
                  }}
                />
              ),
          },
        ]}
        expandParams={{
          expandedRows,
          render: (row) => (
            <>
              {row.list?.map((ag: AccessGroup) => (
                <NestedAccessGroupRow key={ag.id} {...ag} nestedIndex={1} />
              ))}
            </>
          ),
        }}
        keyField="id"
        TableWrapper={UserManagementTable}
        TableHeadRow={UserManagementTableHeaderRow}
        TableHeadRowCell={UserManagementTableHeaderCell}
        TableBodyRow={UserManagementTableBodyRow}
      />
    </>
  );
};
