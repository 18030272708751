import { AxiosError } from "axios";
import { httpClient } from "../../../core/http-client";
import { IConfigDetailsData } from "../../../models/configuration/configuration_details";
import { IVersionHistory } from "../../../models/configuration/configuration_details.interfaces";
import { ErrorLevel, CMv3APIError, APIError } from "../../../models/error";
import { parseConfigurationDetails } from "./helpers/parseConfigurationDetails";
import { parseCertificate } from "./helpers/parseCertificate";
import { getIsLocalCreated } from "./helpers/getIsLocalCreated";
import { parseConfiguration } from "./helpers/parseConfiguration";
import { prepareConfigDetails } from "./helpers/prepareConfigDetails";
import {
  CertificateResponseType,
  CertificateType,
  ConfigDetailsResponseData,
  ConfigurationDeleteResponseData,
  ConfigurationDetailsType,
  ConfigurationResponseData,
  ConfigurationType,
} from "./types";
import { preCommit } from "./helpers/preCommit";

const upgradeGenSchema = (config: IConfigDetailsData) => {
  if (config.genSchemaVersion === "3.0") {
    config.genSchemaVersion = "3.1";
  }

  return config;
};

export const getConfigurationHistory = async (
  configuration: ConfigurationType
): Promise<ConfigurationType["versions"]> => {
  try {
    if (getIsLocalCreated(configuration)) return [];

    const { data } = await httpClient.get<{
      configs: ConfigurationType["versions"];
    }>(
      `/serviceConfiguration/v3/subscribers/${configuration.subscriberId}/configs/stored/${configuration.configName}/history?limit=100`
    );

    return data.configs;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "getHistory()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const getConfigurationVersionDetails = async (
  configuration: ConfigurationType,
  versionId: number
): Promise<Pick<ConfigurationType, "versions" | "config" | "versionId">> => {
  try {
    const versions = await getConfigurationHistory(configuration);
    const { data } = await httpClient.get<{
      config: ConfigDetailsResponseData;
    }>(
      `/serviceConfiguration/v3/subscribers/${configuration.subscriberId}/configs/stored/${configuration.configName}/history/${versionId}`
    );
    const config = parseConfigurationDetails(data.config);

    return { versions, config, versionId };
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "getConfigurationVersionDetails()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const getConfigurationVersionDetailsToCompare = async (
  configuration: ConfigurationType,
  versionId: number
): Promise<ConfigurationType> => {
  try {
    const { data } = await httpClient.get<
      ConfigurationResponseData & {
        config: ConfigDetailsResponseData;
      }
    >(
      `/serviceConfiguration/v3/subscribers/${configuration.subscriberId}/configs/stored/${configuration.configName}/history/${versionId}`
    );
    const newConfiguration = parseConfiguration(data);
    newConfiguration.config = parseConfigurationDetails(data.config);
    newConfiguration.versions = await getConfigurationHistory(configuration);
    newConfiguration.versionId = versionId;

    return newConfiguration;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "getConfigurationVersionDetailsToCompare()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const getConfigurationDetails = async (
  configuration: ConfigurationType
): Promise<Pick<ConfigurationType, "versions" | "config">> => {
  try {
    const versions = await getConfigurationHistory(configuration);
    const { data } = await httpClient.get<{
      config: ConfigDetailsResponseData;
    }>(
      `/serviceConfiguration/v3/subscribers/${configuration.subscriberId}/configs/stored/${configuration.configName}`
    );
    const config = parseConfigurationDetails(data.config);

    return { versions, config };
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "getConfigurationDetails()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const deleteConfiguration = async ({
  subscriberId,
  configName,
}: ConfigurationType): Promise<ConfigurationDeleteResponseData> => {
  try {
    const deletedConfig = await httpClient.delete<ConfigurationDeleteResponseData>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/stored/${configName}`
    );

    return deletedConfig.data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "deleteConfiguration()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const submitConfiguration = async (
  configuration: ConfigurationType
): Promise<{
  message: string;
  versionId: number;
  versions: IVersionHistory[];
  isLocalDraft: boolean;
  modifiedTime?: string;
  genSchemaVersion?: string;
}> => {
  try {
    const { subscriberId, configName, config, comment } = configuration;
    let modifiedTime: string | undefined = undefined;

    const configToUpdate = config
      ? upgradeGenSchema(preCommit(prepareConfigDetails(false, config)))
      : undefined;

    const { data } = await httpClient.put<
      { config?: IConfigDetailsData; comment: string },
      { message: string; versionId: number }
    >(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/stored/${configName}`,
      {
        config: configToUpdate,
        comment: comment ?? "",
      }
    );

    const versions = await getConfigurationHistory(configuration);
    const versionId = data.versionId ? data.versionId : configuration.versionId;

    modifiedTime =
      versionId > 1 ? new Date().toISOString() : configuration.modifiedTime;

    return {
      ...data,
      versions,
      versionId,
      isLocalDraft: false,
      modifiedTime,
      genSchemaVersion: configToUpdate?.genSchemaVersion,
    };
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "submitConfiguration()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const getLatestConfiguration = async (
  configuration: ConfigurationType
): Promise<{
  versions: IVersionHistory[];
  config: ConfigurationDetailsType | undefined;
  versionId: number;
}> => {
  const { versions, config } = await getConfigurationDetails(configuration);
  const versionId = versions.reduce(
    (prev, curr) => (curr.versionId > prev ? curr.versionId : prev),
    0
  );

  return { versions, config, versionId };
};

export const getConfigurations = async (
  subscriberId: string
): Promise<ConfigurationResponseData[]> => {
  try {
    const { data } = await httpClient.get<{
      configs: ConfigurationResponseData[];
    }>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/stored?limit=100`
    );

    return data.configs;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "getLatestConfiguration()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

export const getCertificates = async (
  subscriberId: number
): Promise<CertificateType[]> => {
  try {
    const { data } = await httpClient.get<CertificateResponseType[]>(
      `/serviceConfiguration/v2/subscribers/${subscriberId}/certs`
    );

    return data.map(parseCertificate);
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    throw new APIError(
      error.message,
      "getCertificates()",
      ErrorLevel.WARNING,
      error.response
    );
  }
};
