import type { ChangeEvent, ReactElement } from "react";
import type {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { HorizontalFormContainer } from "../../../../../../../../../../components/atoms/HorizontalFormLayout/HorizontalFormLayout";
import {
  AddButton,
  AddButtonContainer,
  TableBody,
  TableHeader,
  TableHeaderWrapper,
} from "../../../../../../../../../../components/molecules/DefinitionModal/DefinitionModal.styled";
import type { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import type { ValidationErrors } from "../../types";
import { HeadersRow, HeadersRowProps } from "./HeadersRow";

export interface HeadersTableProps
  extends Pick<HeadersRowProps, "disabled" | "register"> {
  headers: Exclude<
    Exclude<IFeatures["denyPolicy"], undefined>["headers"],
    undefined
  >;
  clearErrors: UseFormClearErrors<IFeatures>;
  errors: Exclude<ValidationErrors["denyPolicy"], undefined>["headers"];
  handleCreateFeature: UseFormSetValue<IFeatures>;
  setError: UseFormSetError<IFeatures>;
}

export const HeadersTable = ({
  clearErrors,
  disabled,
  errors,
  handleCreateFeature,
  headers,
  register,
  setError,
}: HeadersTableProps): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const [tConfigurationDefinitionsPage] = useTranslation(
    "configurationDefinitionsPage"
  );

  const removeHeader = (index: number) => () => {
    handleCreateFeature(
      "denyPolicy.headers",
      headers.filter((_, i) => i !== index)
    );
  };

  const handleErrorDuplicates = (rowIndex: number) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const currentValue = e.target.value;
    const duplicatedMap: Record<
      string,
      { count: number; indexes: number[] }
    > = {};

    duplicatedMap[currentValue] = { count: 1, indexes: [rowIndex] };

    headers.forEach((header, i) => {
      if (i !== rowIndex) {
        const duplicated = duplicatedMap[header.name];

        if (duplicated) {
          duplicated.count += 1;
          duplicated.indexes.push(i);

          if (duplicated.count > 1) {
            duplicated.indexes.forEach((errIndex) => {
              setError(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `denyPolicy.headers[${errIndex}].name`,
                {
                  type: "validate",
                  message: "Name must be unique",
                }
              );
            });

            // Delete indexes which are already in error state.
            duplicated.indexes = [];
          }
        } else {
          duplicatedMap[header.name] = { count: 1, indexes: [i] };

          clearErrors(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            `denyPolicy.headers[${i}].name`
          );
        }
      }
    });

    if (duplicatedMap[currentValue]!.count < 2) {
      clearErrors(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `denyPolicy.headers[${rowIndex}].name`
      );
    }
  };

  const nameValidator = (value: string) => {
    if (headers.filter((header) => header.name === value).length > 1) {
      return tConfigurationDefinitionsPage(
        "ERROR_DEFINITION_DUPLICATE_HEADER_NAMES"
      );
    }

    return true;
  };

  const requiredMsg = tMisc("ERROR_REQUIRED_FIELD");

  return (
    <HorizontalFormContainer>
      <TableHeaderWrapper>
        <TableHeader>
          {t("FEATURE_CARD_DENY_POLICY_HEADERS_TH_NAME")}
        </TableHeader>

        <TableHeader>
          {t("FEATURE_CARD_DENY_POLICY_HEADERS_TH_VALUE")}
        </TableHeader>
        <TableHeader></TableHeader>
      </TableHeaderWrapper>

      <TableBody>
        {headers.map((_, i) => (
          <HeadersRow
            disabled={disabled}
            errorMsg={errors?.[i]?.name?.message}
            headerKey={`denyPolicy.headers[${i}]`}
            key={i}
            onChange={handleErrorDuplicates(i)}
            register={register}
            removeHeader={removeHeader(i)}
            requiredMsg={requiredMsg}
            rowIndex={i}
            validate={nameValidator}
          />
        ))}
      </TableBody>

      {!disabled && (
        <AddButtonContainer>
          <AddButton
            data-testid="deny-policy-header-add-button"
            onClick={() => {
              handleCreateFeature(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                `denyPolicy.headers[${headers.length}]`,
                { name: "", value: "" }
              );
            }}
          >
            + Add Header
          </AddButton>
        </AddButtonContainer>
      )}
    </HorizontalFormContainer>
  );
};
