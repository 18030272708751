import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Status } from "../../../@types/status";
import { useUserManagementUsersListStatus } from "../../../store/slices/user-management-users/hooks";
import { fetchUsers } from "../../../store/slices/user-management-users/thunk";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";

export const UserManagementUsersLayer: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const loadingStatus = useUserManagementUsersListStatus();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const { t } = useTranslation("layers");

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("USER_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (loadingStatus === Status.SUCCESS || loadingStatus === Status.ERROR) {
    return <>{children}</>;
  }

  return null;
};
