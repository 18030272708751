import { SelectionStatus } from "../../atoms/Checkbox/CustomCheckBoxInput";
import { FilterMultiSelectDropdownItem } from "../../atoms/FilterMultiSelectDropdownOption/FilterMultiSelectDropdownOption";

export const filterOnSearch = <T>(
  items: FilterMultiSelectDropdownItem<T>[],
  searchValue: string
): FilterMultiSelectDropdownItem<T>[] => {
  const newItems: FilterMultiSelectDropdownItem<T>[] = [];
  items.forEach((item) => {
    if (item.label.toLowerCase().includes(searchValue)) {
      newItems.push(item);
    } else {
      const newItem: FilterMultiSelectDropdownItem<T> = {
        ...item,
        subItems: item.subItems && filterOnSearch(item.subItems, searchValue),
      };
      if (newItem.subItems && newItem.subItems.length > 0) {
        newItems.push(newItem);
      }
    }
  });
  return newItems;
};

export const buildNewDropdownItems = <T>(
  oldItems: FilterMultiSelectDropdownItem<T>[],
  updatedItem: FilterMultiSelectDropdownItem<T>
): FilterMultiSelectDropdownItem<T>[] =>
  oldItems.map((item) => {
    if (item.value === updatedItem.value) {
      if (updatedItem.subItems === undefined) {
        const newStatus =
          updatedItem.status === SelectionStatus.SELECTED
            ? SelectionStatus.NOT_SELECTED
            : SelectionStatus.SELECTED;
        return {
          ...item,
          status: newStatus,
          subItems: item.subItems
            ? tickAll(item.subItems, newStatus)
            : undefined,
        };
      }

      const newItems = item.subItems
        ? buildNewDropdownItems(item.subItems, updatedItem.subItems[0])
        : undefined;

      const unselected = newItems?.filter(
        (i) => i.status === SelectionStatus.NOT_SELECTED
      );
      const selected = newItems?.filter(
        (i) => i.status === SelectionStatus.SELECTED
      );

      return {
        ...item,
        status:
          unselected?.length === newItems?.length
            ? SelectionStatus.NOT_SELECTED
            : selected?.length === newItems?.length
            ? SelectionStatus.SELECTED
            : SelectionStatus.PARTIAL,
        subItems: newItems,
      };
    }
    return item;
  });

export const tickAll = <T>(
  items: FilterMultiSelectDropdownItem<T>[],
  status: SelectionStatus
): FilterMultiSelectDropdownItem<T>[] =>
  items.map((item) => ({
    ...item,
    status,
    subItems: item.subItems ? tickAll(item.subItems, status) : undefined,
  }));

export const getSelectedItems = <T>(
  items: FilterMultiSelectDropdownItem<T>[]
): FilterMultiSelectDropdownItem<T>[] =>
  items
    .filter((item) => item.status !== SelectionStatus.NOT_SELECTED)
    .map((item) => ({
      ...item,
      subItems: item.subItems ? getSelectedItems(item.subItems) : undefined,
    }));
