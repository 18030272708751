import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { AnchorWrapper } from "../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { MenuItem } from "../../../../components/molecules/AnchorMenu/AnchorMenu";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { useSubscriberSettingsTrafficTypes } from "../../../../store/slices/subscriber-settings/hooks";
import { H2, InfoBadge, TableInfoContainer } from "./SubscriberSettings.styled";

interface InfoSectionProps {
  menu: MenuItem;
}

export const TrafficTypesSection = ({
  menu,
}: InfoSectionProps): ReactElement => {
  const { t } = useTranslation("subscribersSettingsPage");
  const infos = useSubscriberSettingsTrafficTypes();

  return (
    <>
      <AnchorWrapper
        id={menu.id}
        ref={menu.ref}
        key={menu.name}
        data-testid="subscriber_settings-trafficTypes"
      >
        <H2>{t("SUBSCRIBER_SETTINGS_ANCHOR_TRAFFIC_TYPES")}</H2>
        <ExpandableContent
          title={t("SUBSCRIBER_SETTINGS_TRAFFIC_TYPES_DESCRIPTION_TITLE")}
          content={t("SUBSCRIBER_SETTINGS_TRAFFIC_TYPES_DESCRIPTION_CONTENT")}
        />
        <TableInfoContainer>
          {infos.length
            ? infos.map((trafficType, index) => (
                <InfoBadge
                  key={index}
                  label={trafficType}
                  color="navy80"
                  dataTestId={`traffic-type-badge-${trafficType}`}
                />
              ))
            : t("SUBSCRIBER_SETTINGS_NO_TRAFFIC_TYPE")}
        </TableInfoContainer>
      </AnchorWrapper>
    </>
  );
};
