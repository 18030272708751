import { SelectionStatus } from "../../../../components/atoms/Checkbox/CustomCheckBoxInput";
import { FilterMultiSelectDropdownItem } from "../../../../components/atoms/FilterMultiSelectDropdownOption/FilterMultiSelectDropdownOption";
import {
  UserManagementUserData,
  UserStatus,
} from "../../../../models/user-management/users/users.interfaces";
import {
  DateDifferenceItem,
  dateDifferenceLabel,
  ProcessedUsers,
  UserType,
} from "./types";

export const processUsers = (
  search: string,
  roles: string[],
  status?: UserStatus,
  type?: UserType
) => (users: UserManagementUserData[]): ProcessedUsers[] => {
  const trimmedSearch = search.trim().toLowerCase();
  return users
    .map((user) => ({
      userId: user.userId,
      fullName: user.firstName + " " + user.lastName,
      userName: user.userName,
      status: user.status,
      lastLogin: user.lastLogin,
      createdAt: user.createdAt,
      parentAccessGroup:
        user.accessGroups.length > 0
          ? {
              name: user.accessGroups[0].name,
              roleName: user.accessGroups[0].roleName,
            }
          : {
              name: "",
              roleName: "",
            },
      accessGroupsCounter: user.accessGroups.length,
      rolesCounter:
        new Set(user.accessGroups.map((item) => item.roleName)).size +
        (user.vyvx?.role ? 1 : 0),
      accessGroups: user.accessGroups.map((group) => ({
        name: group.name,
        roleName: group.roleName,
      })),
      fiberCompanyName: user.vyvx?.fiberCompanyName,
      satelliteCompanyName: user.vyvx?.satelliteCompanyName,
      vyvxRole: user.vyvx?.role,
      originalData: user,
    }))
    .filter(
      (user) =>
        (status === undefined || status === user.status) &&
        (roles.length === 0 ||
          user.accessGroups.some((ag) => roles.includes(ag.roleName.trim())) ||
          (user.vyvxRole && roles.includes(user.vyvxRole.trim()))) &&
        (type === undefined ||
          (type === UserType.CDN && user.accessGroups.length > 0) ||
          (type === UserType.VYVX && !!user.vyvxRole)) &&
        (search === "" ||
          user.fullName.toLowerCase().includes(trimmedSearch) ||
          user.userName.includes(trimmedSearch) ||
          user.accessGroups.some(
            (ag) =>
              ag.name.toLowerCase().includes(trimmedSearch) ||
              ag.roleName.toLowerCase().includes(trimmedSearch)
          ))
    );
};

export const computeDateDifference = (
  date: number
): {
  dateValue: number;
  dateLabel: string;
} => {
  const now = Date.now();
  const diff = now - date;

  let dateLabel: DateDifferenceItem;
  let dateValue: number;
  if (diff < 7 * 24 * 3600 * 1000) {
    dateValue = Math.max(Math.floor(diff / (24 * 3600 * 1000)), 1);
    dateLabel = dateDifferenceLabel.get("days")!;
  } else if (diff < 30 * 24 * 3600 * 1000) {
    dateValue = Math.floor(diff / (7 * 24 * 3600 * 1000));
    dateLabel = dateDifferenceLabel.get("weeks")!;
  } else if (diff < 365 * 24 * 3600 * 1000) {
    dateValue = Math.floor(diff / (30 * 24 * 3600 * 1000));
    dateLabel = dateDifferenceLabel.get("months")!;
  } else {
    dateValue = Math.floor(diff / (365 * 24 * 3600 * 1000));
    dateLabel = dateDifferenceLabel.get("years")!;
  }
  return {
    dateValue,
    dateLabel: dateValue > 1 ? dateLabel.plural : dateLabel.singular,
  };
};

export const buildRolesDropdownItems = (
  cdnRoles: string[],
  vyvxRoles: string[],
  selectedRoles: string[],
  cdnLabel: string,
  vyvxLabel: string
): FilterMultiSelectDropdownItem<string>[] => {
  const cdnRolesSubItems = cdnRoles.map((role) => ({
    label: role,
    value: role,
    status: selectedRoles.includes(role)
      ? SelectionStatus.SELECTED
      : SelectionStatus.NOT_SELECTED,
  }));

  const cdnSelected = cdnRolesSubItems.filter(
    (item) => item.status === SelectionStatus.SELECTED
  );

  const vyvxRolesSubItems = vyvxRoles.map((role) => ({
    label: role,
    value: role,
    status: selectedRoles.includes(role)
      ? SelectionStatus.SELECTED
      : SelectionStatus.NOT_SELECTED,
  }));

  const vyvxSelected = vyvxRolesSubItems.filter(
    (item) => item.status === SelectionStatus.SELECTED
  );

  return [
    {
      label: cdnLabel,
      value: cdnLabel,
      status:
        cdnSelected.length === 0
          ? SelectionStatus.NOT_SELECTED
          : cdnSelected.length === cdnRolesSubItems.length
          ? SelectionStatus.SELECTED
          : SelectionStatus.PARTIAL,
      subItems: cdnRolesSubItems,
    },
    {
      label: vyvxLabel,
      value: vyvxLabel,
      status:
        vyvxSelected.length === 0
          ? SelectionStatus.NOT_SELECTED
          : vyvxSelected.length === vyvxRolesSubItems.length
          ? SelectionStatus.SELECTED
          : SelectionStatus.PARTIAL,
      subItems: vyvxRolesSubItems,
    },
  ];
};
