import { ReactNode } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SubHeaderAccessGroup } from "../../components/organisms/SubHeader/SubHeaderAccessGroup/SubHeaderAccessGroup";
import { IRoute, Tabs } from "../../components/organisms/Tabs/Tabs";
import {
  ModuleContent,
  ModuleHeader,
  ModuleTitle,
} from "../../components/atoms/Module/Module";
import { AccessGroupsRouter } from "./templates/accessGroups/AccessGroups.router";
import { UsersRouter } from "./templates/users/Users.router";
import { ApiKeys } from "./templates/apiKeys/";

export const UserManagement = (): JSX.Element => {
  const history = useHistory();

  const { t } = useTranslation("userManagementHeader");

  const routeList: (IRoute & { component: ReactNode })[] = [
    {
      route: "accessGroups",
      label: t("ACCESS_GROUPS_TAB_LABEL"),
      component: <AccessGroupsRouter />,
    },
    {
      route: "users",
      label: t("USERS_TAB_LABEL"),
      component: <UsersRouter />,
    },
    {
      route: "apiKeys",
      label: t("API_KEYS_TAB_LABEL"),
      component: <ApiKeys />,
    },
  ];

  const match = useRouteMatch<{
    module: string;
  }>(["/userManagement/:module", "/userManagement/:module/:ressource"]);

  const currentIndex = routeList.findIndex(
    (tab) => tab.route === match?.params.module
  );

  if (currentIndex === -1) {
    history.push(`/userManagement/${routeList[0].route}`);
  }

  const changeTab = (selection: IRoute) => {
    history.push(`/userManagement/${selection.route}`);
  };

  return (
    <>
      <SubHeaderAccessGroup />
      <ModuleHeader>
        <ModuleTitle moduleTitle={t("USER_MANAGEMENT_TITLE")} />
        <Tabs
          onSelectionChange={changeTab}
          tabsList={routeList.map(({ label, route }) => ({ label, route }))}
          initialIndex={currentIndex === -1 ? 0 : currentIndex}
        />
      </ModuleHeader>
      <ModuleContent id="user-management">
        <Switch>
          {routeList.map((tab) => (
            <Route key={tab.route} path={`/userManagement/${tab.route}`}>
              {tab.component}
            </Route>
          ))}
          <Redirect
            from="/userManagement/*"
            to={`/userManagement/${routeList[0].route}`}
          />
        </Switch>
      </ModuleContent>
    </>
  );
};
