import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status } from "../../../@types/status";
import { PermissionsSlice } from "./type";

const initialState: PermissionsSlice = {
  userPermissions: undefined,
  isViewMode: true,
  status: Status.IDLE,
};

export const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (
      state,
      action: PayloadAction<PermissionsSlice["userPermissions"]>
    ) => {
      state.userPermissions = action.payload;
    },
    setIsViewMode: (
      state,
      action: PayloadAction<PermissionsSlice["isViewMode"]>
    ) => {
      state.isViewMode = action.payload;
    },
    setStatus: (state, action: PayloadAction<PermissionsSlice["status"]>) => {
      state.status = action.payload;
    },
  },
});

export const {
  setPermissions,
  setIsViewMode,
  setStatus,
} = permissionSlice.actions;

export default permissionSlice.reducer;
