/* eslint-disable import/named */
import { TFunction, useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import {
  DropDownHeaderContainer,
  Selected,
} from "../../atoms/DropDownHeader/DropDownHeader";
import { DropdownMenu } from "../../atoms/DropDownMenu/DropDownMenu";
import { Icons } from "../../atoms/Icon/Icon";
import { SpinningIcon } from "../../atoms/SpinningIcon/SpinningIcon";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { DropDownContent } from "./DropdownContent";
import { useDropdown } from "./useDropdown";

export interface DropdownProps<Data = string> {
  items: IDropdownItem<Data>[];
  placeholder: string;
  onChange?: (selection: IDropdownItem<Data>[]) => void;
  multiSelect?: boolean;
  selectedItems: IDropdownItem<Data>[];
  setSelectedItems: (selection: IDropdownItem<Data>[]) => void;
  dataTestId?: string;
}

const addComma = <T extends unknown>(_selected: IDropdownItem<T>[]) =>
  _selected.map((item) => item.label).join(", ");

const displayHeader = <T extends unknown>(
  _items: IDropdownItem<T>[],
  _selected: IDropdownItem<T>[] | undefined,
  t: TFunction<"misc">
) => {
  if (_items && _selected && _items.length === _selected.length) {
    return t("ALL");
  }
  if (_selected && _selected.length > 0) {
    return addComma(_selected);
  }
  return t("NO_ITEM_SELECTED");
};

export const DropdownMultiSelect = <T extends unknown>({
  items,
  placeholder,
  multiSelect = false,
  selectedItems,
  setSelectedItems,
  dataTestId,
}: DropdownProps<T>): JSX.Element => {
  const {
    isOpen,
    setIsOpen,
    dropdownRef,
    opening,
    handleSelection,
  } = useDropdown({
    items,
    selectedItems,
    setSelectedItems,
    multiSelect,
  });
  const [t] = useTranslation("misc");

  return (
    <DropDownContainer className="chi-dropdown" ref={dropdownRef}>
      <DropDownHeaderContainer isOpen={isOpen}>
        <Selected data-testid={`${dataTestId}-title`}>
          {displayHeader(items, selectedItems, t)}
        </Selected>
        <SpinningIcon
          name={Icons.CHEVRON_DOWN}
          color="secondary"
          onClick={() => {
            setIsOpen((current) => !current);
          }}
          isOpen={isOpen}
          dataTestId={dataTestId}
        />
      </DropDownHeaderContainer>
      {opening(
        (styles, show) =>
          show && (
            <DropdownMenu style={styles} className="chi-dropdown__menu">
              <DropDownContent
                items={items}
                isOpen={isOpen}
                onSelect={handleSelection}
                selectedItems={selectedItems || []}
                multiSelect={multiSelect}
                dataTestId={dataTestId}
              />
            </DropdownMenu>
          )
      )}
    </DropDownContainer>
  );
};

export const DropDownContainer = styled.div`
  max-width: 200px;
`;
