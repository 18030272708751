import { isAfter, isBefore, sub } from "date-fns";

import { ColorSet } from "../../../../contexts/themeContext";
import { CertificateValidity } from "../../../../models/certificate";
import { CertificateType } from "../types";

export const getCertBadgeInfo = (
  cert: CertificateType
): {
  text: string;
  color: keyof ColorSet["colors"];
  validity: CertificateValidity;
} => {
  const currentDate = new Date();
  if (
    !cert.validity_not_before ||
    isBefore(currentDate, new Date(cert.validity_not_before))
  ) {
    return {
      text: "Not Valid yet",
      color: "grey60",
      validity: CertificateValidity.NOT_VALID_YET,
    };
  }

  if (isAfter(currentDate, new Date(cert.validity_not_after))) {
    return {
      text: "Expired",
      color: "red70",
      validity: CertificateValidity.EXPIRED,
    };
  } else if (
    isAfter(currentDate, sub(new Date(cert.validity_not_after), { days: 14 }))
  ) {
    return {
      text: "Expires Soon",
      color: "yellow40",
      validity: CertificateValidity.EXPIRES_SOON,
    };
  } else {
    return {
      text: "Valid",
      color: "green70",
      validity: CertificateValidity.VALID,
    };
  }
};
