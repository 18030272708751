import { AxiosError } from "axios";
import { httpClient } from "../../../../core/http-client";
import { APIError, MediaPortalAPIError, ErrorLevel } from "../../../error";

import {
  ApiKeyData,
  ApiKeysData,
  ApiKeysOwners,
  ApiKeysRoles,
} from "../apiKeys.interfaces";
import { CdnApiKeyRequestData } from "./cdnApiKeys.interfaces";

export const getCdnApiKeys = async (
  accessGroupId: number
): Promise<ApiKeysData[]> => {
  try {
    const { data } = await httpClient.get<ApiKeysData[]>(
      `/accessGroups/${accessGroupId}/apiKeys`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getCdnApiKeys()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getCdnApiKey = async (
  accessGroupId: number,
  apiKeyId: number
): Promise<ApiKeyData> => {
  try {
    const { data } = await httpClient.get<ApiKeyData>(
      `/accessGroups/${accessGroupId}/apiKeys/${apiKeyId}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const createCdnApiKey = async (
  accessGroupId: number,
  body: CdnApiKeyRequestData
): Promise<CdnApiKeyRequestData> => {
  try {
    const { data } = await httpClient.post(
      `/accessGroups/${accessGroupId}/apiKeys`,
      body
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "createCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const generateSecretCdnApiKey = async (
  accessGroupId: number,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.get<void>(
      `/accessGroups/${accessGroupId}/apiKeys/${apiKeyId}/refreshSecret`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "generateSecretCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const activateCdnApiKey = async (
  accessGroupId: number,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.put(
      `/accessGroups/${accessGroupId}/apiKeys/${apiKeyId}/activate`,
      undefined
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "activateCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const disableCdnApiKey = async (
  accessGroupId: number,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.put(
      `/accessGroups/${accessGroupId}/apiKeys/${apiKeyId}/disable`,
      undefined
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "disableCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const deleteCdnApiKey = async (
  accessGroupId: number,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.delete<void>(
      `/accessGroups/${accessGroupId}/apiKeys/${apiKeyId}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "deleteCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getCdnApiKeyOwners = async (
  accessGroupId: number
): Promise<ApiKeysOwners[]> => {
  try {
    const { data } = await httpClient.get<ApiKeysOwners[]>(
      `/accessGroups/${accessGroupId}/owners`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getCdnApiKeyOwners()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getCdnApiKeyRoles = async (): Promise<ApiKeysRoles[]> => {
  try {
    const { data } = await httpClient.get<ApiKeysRoles[]>(
      "/accessGroups/roles"
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getCdnApiKeyRoles()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const updateCdnApiKey = async (
  accessGroupId: number,
  apiKeyId: number,
  body: CdnApiKeyRequestData
): Promise<ApiKeyData> => {
  try {
    const { data } = await httpClient.put(
      `/accessGroups/${accessGroupId}/apiKeys/${apiKeyId}`,
      body
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateCdnApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
