import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";

import {
  ApiKeysData,
  ApiKeysRoles,
} from "../../../../models/user-management/apiKeys/apiKeys.interfaces";
import { Badge } from "../../../../components/atoms/Badge/Badge";
import { EditIcon } from "../../../../components/atoms/EditIcon/EditIcon";
import { ListItem } from "../../../../components/atoms/ListItem/ListItem";
import { FontLink } from "../../../../components/atoms/Font/Font";
import {
  FlexBox,
  FlexBoxBase,
} from "../../../../components/atoms/FlexBox/FlexBox";
import { ApiKeyActions, ApiKeysStatus } from "./types";
import { VyvxFiberCompany } from "../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { useAppDispatch } from "../../../../store/types";
import {
  setCdnApiKeyStatus,
  setVyvxApiKeyStatus,
} from "../../../../store/slices/user-management-api-keys/slice";
import { Status } from "../../../../@types/status";
import { ApiKeysActions } from "./ApiKeysActions";

interface ApiKeysRowProps {
  apiKey: ApiKeysData;
  roles: ApiKeysRoles[];
  fiberCompanies?: VyvxFiberCompany[];
  isVyvx: boolean;
}

export const ApiKeysRow = ({
  apiKey,
  roles,
  fiberCompanies,
  isVyvx,
}: ApiKeysRowProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const history = useHistory();
  const dispatch = useAppDispatch();

  const apiKeysActionsDropdownItems = [
    {
      item: {
        label: ApiKeyActions.SECRET,
        value: "Generate secret",
      },
    },
    {
      item: {
        label: ApiKeyActions.ENABLE,
        value: "Enable",
      },
    },
    {
      item: {
        label: ApiKeyActions.DISABLE,
        value: "Disable",
      },
    },
    {
      item: {
        label: ApiKeyActions.DELETE,
        value: "Delete",
      },
    },
  ];

  return (
    <>
      <Card spacing="m">
        <Header>
          <TitleContainer>
            <Title>{apiKey.id}</Title>
            <Badge
              label={apiKey.status}
              color={
                apiKey.status === ApiKeysStatus.ACTIVE ? "green50" : "grey60"
              }
            />
          </TitleContainer>
          <OptionContainer>
            <RedirectOption
              onClick={() => {
                if (isVyvx) {
                  dispatch(setVyvxApiKeyStatus(Status.IDLE));
                  history.push(`/userManagement/apiKeys/vyvx/${apiKey.id}`);
                } else {
                  dispatch(setCdnApiKeyStatus(Status.IDLE));
                  history.push(`/userManagement/apiKeys/cdn/${apiKey.id}`);
                }
              }}
            />
            <ApiKeysActions
              apiKeyId={Number(apiKey.id)}
              companyId={apiKey.vyvxCompanyAbbr}
              accessGroupId={Number(apiKey.accessGroupId)}
              apiKeyStatus={apiKey.status}
              apiKeysActionDropdownItems={apiKeysActionsDropdownItems}
              isVyvx={isVyvx}
            />
          </OptionContainer>
        </Header>
        <DataContainer>
          <DataColum>
            <Label className="-text--md">
              {t("API_KEYS_TABLE_NAME_LABEL")}
            </Label>
            <Data>{apiKey.name}</Data>
          </DataColum>
          <DataColum>
            <Label className="-text--md">
              <LabelSecret>{t("API_KEYS_TABLE_SECRET_LABEL")}</LabelSecret>
              <FontLink
                onClick={() => {
                  navigator.clipboard.writeText(apiKey.secret).then(() => {
                    toast.success(t("TOAST_COPY_SECRET"));
                  });
                }}
              >
                {t("COPY_TO_CLIPBOARD")}
              </FontLink>
            </Label>
            <Data>{"*".repeat(apiKey.secret?.length || 0)}</Data>
          </DataColum>
          <DataColum>
            <Label className="-text--md">
              {isVyvx
                ? t("API_KEYS_TABLE_COMPANY_LABEL")
                : t("API_KEYS_TABLE_ACCESS_GROUP_LABEL")}
            </Label>
            <Data>
              {isVyvx
                ? fiberCompanies?.find(
                    (elem) => elem.companyAbbr === apiKey.vyvxCompanyAbbr
                  )?.companyName
                : apiKey.accessGroupName}
            </Data>
          </DataColum>
          <DataColum>
            <Label className="-text--md">
              {t("API_KEYS_TABLE_ROLE_LABEL")}
            </Label>
            <Data>
              {isVyvx
                ? roles.find((elem) => Number(elem.id) === apiKey.vyvxRoleId)
                    ?.name
                : apiKey.role}
            </Data>
          </DataColum>
          <DataColum>
            <Label className="-text--md">
              {t("API_KEYS_TABLE_OWNER_LABEL")}
            </Label>
            <Data>{apiKey.contact}</Data>
          </DataColum>
          <DataColum>
            <Label className="-text--md">
              {t("API_KEYS_TABLE_MODIFIED_DATE_LABEL")}
            </Label>
            <Data>
              {apiKey.lastModifiedDate !== "" &&
                format(new Date(apiKey.lastModifiedDate), "LLL d, yyyy")}
            </Data>
          </DataColum>
        </DataContainer>
      </Card>
    </>
  );
};

const Card = styled(ListItem)`
  &:not(:first-child) {
    margin: 1em 0;
  }
  background: ${({ theme }) => theme.colors.blue10};
`;

const Header = styled(FlexBox)`
  justify-content: space-between;

  width: 100%;
`;

const Title = styled.h4`
  margin-right: 0.5em !important;
`;

const TitleContainer = styled(FlexBox)`
  display: flex;
`;

const OptionContainer = styled(FlexBox)``;

const RedirectOption = styled(EditIcon)`
  margin-right: 32px;
  path {
    fill: ${({ theme }) => theme.icon.highlight};
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;

const DataColum = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const Label = styled(FlexBoxBase)`
  color: ${({ theme }) => theme.text.secondary};
  align-items: center;
`;
const LabelSecret = styled.div`
  margin-right: 8px;
`;

const Data = styled.h6`
  margin: 0 !important;
`;
