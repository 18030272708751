import styled from "@emotion/styled";
import { animated } from "react-spring";

export const DropDownOptionContainer = styled(animated.a)<{
  selected: boolean;
}>`
  display: flex !important;
  color: ${({ theme }) => theme.text.primary}!important;
  cursor: pointer;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  &:hover:not(.-disabled):not(.-active) {
    color: #000000 !important;
    background-color: ${({ theme }) => theme.backgrounds.mutedLight}!important;
  }
  align-items: center;
  padding: 0.5rem 1rem;
`;
