import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const BaseDropdownSelect = styled.div<{ isOpen: boolean }>`
  ${({ isOpen, theme }) =>
    isOpen
      ? css`
          background: ${theme.colors.blue20};
          border: 1px solid ${theme.borders.highlight};
        `
      : css`
          background: ${theme.backgrounds.baseLight};
          border: 1px solid ${theme.borders.mutedLight};
        `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 4px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.text.primary};
`;

export const BaseLabel = styled.div<{ isOpen: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.text.primary};
  margin-right: 8px;
  ${({ isOpen }) =>
    isOpen &&
    css`
      font-weight: 600;
    `}
`;

export const BaseSelected = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.backgrounds.base};
  border-radius: 4px;
  font-weight: 600;
  margin-right: 8px;
`;
