import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
// eslint-disable-next-line import/named
import { CustomTypeOptions, TFunction, useTranslation } from "react-i18next";
import format from "date-fns/format";

import {
  Dropdown,
  IDropdownItem,
} from "../../../../components/organisms/Dropdown/Dropdown";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { ProductionSlot } from "../../../../models/configuration";
import { IVersionHistory } from "../../../../models/configuration/configuration_details.interfaces";
import { VersionsList } from "./VersionsList";
import { InputField } from "../../../../components/molecules/InputField/InputField";

export interface IVersionsProps {
  versionHistory: IVersionHistory[];
}

type TKey = keyof CustomTypeOptions["resources"]["environmentHistoryPage"];

const getDisplayString = (
  version: IVersionHistory,
  tfunction: TFunction<"environmentHistoryPage">
): string => {
  return `${
    version.modifiedTime && format(new Date(version.modifiedTime), "h:mm a")
  } (${tfunction("DISPLAY_STRING_VERSION", {
    version: version.versionId,
  })}) ${version.slot[0].toUpperCase() + version.slot.slice(1)} ${tfunction(
    "DISPLAY_STRING_SLOT",
    {
      slot: version.sourceConfigName,
    }
  )} (${tfunction("DISPLAY_STRING_VERSION", {
    version: version.sourceConfigVersionId,
  })}) ${tfunction("DISPLAY_STRING_NOTES", {
    note: version.comment ? version.comment : "None",
  })}`;
};

export const Versions: FC<IVersionsProps> = ({ versionHistory }) => {
  const { t } = useTranslation("environmentHistoryPage");
  const [filteredHistory, setFilteredHistory] = useState<IVersionHistory[]>(
    versionHistory
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchHistory, setSearchHistory] = useState<IVersionHistory[]>([]);

  const defaultDropdownItem = {
    label: t("ENVIRONMENT_VERSIONS_TAB_DROPDOWN_ALL"),
    value: "all",
    default: true,
  };

  const dropdownItems = [defaultDropdownItem].concat(
    Object.values(ProductionSlot).map((slot) => {
      return {
        label: t(
          `ENVIRONMENT_VERSIONS_TAB_DROPDOWN_${slot.toUpperCase()}` as TKey
        ),
        value: slot,
        default: false,
        dataTestId: `environment-history-versions-dropdown-${slot}`,
      };
    })
  );

  const [selectedSlot, setSelectedSlot] = useState<IDropdownItem<string>>(
    defaultDropdownItem
  );

  const handleSearch = useCallback(() => {
    setFilteredHistory(
      searchHistory &&
        searchHistory.filter((version) => {
          if (version) {
            const displayString = getDisplayString(version, t);

            return displayString
              .toLowerCase()
              .includes(searchValue.toLowerCase().trim());
          }
        })
    );
  }, [searchValue, searchHistory]);

  useEffect(() => {
    if (selectedSlot.value === "all") {
      setSearchHistory(versionHistory);
    } else {
      setSearchHistory(
        versionHistory.filter((h) => h.slot === selectedSlot.value)
      );
    }
  }, [versionHistory, selectedSlot]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <VersionsContainer>
      <SearchBarContainer>
        <InputField
          onChange={setSearchValue}
          hasBorder={true}
          isSearchField={true}
          placeholder={t("ENVIRONMENT_VERSIONS_TAB_SEARCH_BAR_PLACEHOLDER")}
          dataTestId="environment-history-versions-search-input"
        />
        <DropdownContainer>
          <Dropdown
            items={dropdownItems}
            id="slot-selector"
            placeholder={t("ENVIRONMENT_VERSIONS_TAB_DROPDOWN_PLACEHOLDER")}
            onSelect={(item) => setSelectedSlot(item)}
            dataTestId="environment-history-versions-dropdown"
          />
        </DropdownContainer>
      </SearchBarContainer>
      <ExpandableContentContainer>
        <ExpandableContent
          title={t("ENVIRONMENT_VERSIONS_DESCRIPTION_TITLE")}
          content={t("ENVIRONMENT_VERSIONS_DESCRIPTION_CONTENT")}
        />
      </ExpandableContentContainer>
      <ScrollableListContainer>
        {filteredHistory && filteredHistory.length > 0 && (
          <VersionsList versionHistory={filteredHistory} />
        )}
      </ScrollableListContainer>
    </VersionsContainer>
  );
};

const VersionsContainer = styled.div`
  overflow: auto;
`;

const ScrollableListContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`};
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`};
`;

const ExpandableContentContainer = styled.div`
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer} 0`};
`;

const DropdownContainer = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
`;
