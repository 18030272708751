import { toDate } from "date-fns";
import { TableState } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";

import { handleSortByMetric } from "../../../helpers";
import {
  Metrics,
  SiteByProperty,
  TableSiteWithPercentage,
} from "../../../models/models";
import { handleSortRegionsAndMetros } from "../Locations/helpers";
import { PropertyHistory, TableProperty } from "./types";

export const reduceProperties = (
  propertyList: SiteByProperty[]
): TableProperty[] =>
  propertyList.map((property) => {
    const {
      propertyName,
      propertyId,
      reqPerSec,
      mbPerSec,
      missMbPerSec,
      missPerSec,
      fourZeroFourPerSec,
      fiveZeroThreePerSec,
      fiveZeroFourPerSec,
      fiveXXPerSec,
      hitRatePercent,
    } = property;

    return {
      propertyName,
      propertyId,
      reqPerSec,
      mbPerSec,
      missMbPerSec,
      missPerSec,
      fourZeroFourPerSec,
      fiveZeroThreePerSec,
      fiveZeroFourPerSec,
      fiveXXPerSec,
      hitRatePercent,
    };
  });

export const historyToChartData = (metric: Metrics) => (
  pH: PropertyHistory
): { id: string; data: { x: Date; y: number }[] } => ({
  id: pH.propertyName,
  data: pH.points.map(({ time, site }) => ({
    x: toDate(time * 1000),
    y: site[metric],
  })),
});

export const handleSortProperties = (
  sortField: TableState<TableSiteWithPercentage>["sortField"],
  sortOrder: TableState<TableSiteWithPercentage>["sortOrder"],
  properties: TableProperty[]
): TableProperty[] => {
  const _properties = handleSortByMetric(sortField, sortOrder, properties);
  _properties.forEach((prop) => {
    if (prop.regions) {
      prop.regions = handleSortRegionsAndMetros(
        sortField,
        sortOrder,
        prop.regions
      );
    }
  });
  return _properties;
};
