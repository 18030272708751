/* eslint-disable import/named */
import {
  ComponentProps,
  forwardRef,
  HTMLProps,
  ReactElement,
  Ref,
} from "react";
import styled from "@emotion/styled";
import {
  ChangeHandler,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

import { InputBase } from "../../atoms/InputBase/InputBase";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { Popover } from "../../atoms/Popover/Popover";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";

export interface InputFieldProps
  extends Omit<HeadlessInputFieldProps, "onChange" | "onBlur" | "disabled"> {
  validation?: RegisterOptions;
  type?: HTMLProps<HTMLInputElement>["type"];
  min?: number;
  dataTestId?: string;
  disabled?: boolean;
  onChange: (e: string) => void;
  onBlur?: (e: string) => void;
  register?: UseFormRegister<FieldValues>;
}

export const InputField = ({
  hasBorder = true,
  placeholder,
  isSearchField = false,
  value,
  disabled = false,
  align = "center",
  id,
  validation,
  type = "text",
  min,
  required,
  dataTestId,
  className,
  autoFocus,
  info,
  onChange,
  register,
  onBlur: onBlurCallback,
}: InputFieldProps): ReactElement => {
  let onBlur: ChangeHandler | undefined;
  let ref: Ref<HTMLInputElement> | undefined;
  if (register && id && validation) {
    ({ onBlur, ref } = register(id, validation));
  }

  return (
    <InputFieldContainer
      hasBorder={hasBorder}
      align={align}
      disabled={disabled}
      className={className}
    >
      {isSearchField && <SearchIcon name={Icons.SEARCH} />}
      <Input
        name={id}
        disabled={disabled}
        placeholder={placeholder}
        className="-text--xs"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
          onBlurCallback && onBlurCallback(e.target.value);
        }}
        ref={ref}
        type={type}
        min={min}
        required={required}
        data-testid={dataTestId}
        autoFocus={autoFocus}
      />
      {info && (
        <Popover onHover info={info}>
          <PopoverTitle>{info}</PopoverTitle>
        </Popover>
      )}
    </InputFieldContainer>
  );
};

export interface HeadlessInputFieldProps extends ComponentProps<typeof Input> {
  hasBorder?: boolean;
  align?: string;
  isSearchField?: boolean;
  info?: string;
}

export const HeadlessInputField = forwardRef<
  HTMLInputElement,
  HeadlessInputFieldProps
>(
  (
    {
      hasBorder = true,
      align = "center",
      disabled = false,
      className,
      isSearchField = false,
      info,
      ...rest
    },
    ref
  ) => (
    <InputFieldContainer
      hasBorder={hasBorder}
      align={align}
      disabled={disabled}
      className={className}
    >
      {isSearchField && <SearchIcon name={Icons.SEARCH} />}

      <Input ref={ref} disabled={disabled} className="-text--xs" {...rest} />

      {info && (
        <Popover onHover info={info}>
          <PopoverTitle>{info}</PopoverTitle>
        </Popover>
      )}
    </InputFieldContainer>
  )
);

const InputFieldContainer = styled(FlexBox)<{
  hasBorder: boolean;
  align: string;
  disabled: boolean;
}>`
  align-items: ${({ align }) => align};
  height: 100%;
  width: 100%;
  padding: 12px 16px;
  border: ${({ theme, hasBorder }) =>
    hasBorder ? `1px solid ${theme.borders.mutedLight}` : "none"};
  border-radius: 4px;
  margin-right: 15px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.backgrounds.mutedLight : theme.backgrounds.baseLight};
`;

const SearchIcon = styled(Icon)`
  * {
    fill: #65686c;
  }
  margin-right: 12px;
`;

const Input = styled(InputBase)<{ disabled: boolean }>`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const PopoverTitle = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.backgrounds.mutedLight};
  color: ${({ theme }) => theme.colors.grey60};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 8px;
  width: max-content;
  position: relative;
`;
