import { IMatchRule } from "../../../../../models/configuration/definitions";

export const updateTokenReferences = (oldName: string, newName: string) => (
  matchRule: IMatchRule
): IMatchRule => {
  if (
    matchRule.features.tokenAuthentication &&
    matchRule.features.tokenAuthentication.name === oldName
  ) {
    matchRule.features.tokenAuthentication.name = newName;
  }

  return matchRule;
};
