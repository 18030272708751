import type { ReactElement } from "react";
import styled from "@emotion/styled";
import type { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Checkbox,
  HeadlessCheckbox,
} from "../../../../../../../../../components/molecules/Checkbox/Checkbox";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { FeatureBlock } from "./FeatureBlock";

interface StaleContentControlFeatureBlockProps {
  datatestId: string;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  initIsOpen: boolean;
  onDelete: () => void;
  register: UseFormRegister<IFeatures>;
  staleContentControl: Exclude<IFeatures["staleContentControl"], undefined>;
}

export const StaleContentControlFeatureBlock = ({
  datatestId,
  handleCreateFeature,
  initIsOpen,
  onDelete,
  register,
  staleContentControl,
}: StaleContentControlFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();
  const staleIfErrorEnabled = staleContentControl.staleIfError.enabled;

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_SCC_TITLE")}
      additionalInfoTitle={t("FEATURE_CARD_ADDED_SCC_DESCRIPTION_TITLE")}
      additionalInfoContent={t("FEATURE_CARD_ADDED_SCC_DESCRIPTION_CONTENT")}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_SCC_STALE_WHILE_REVALIDATE_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_SCC_STALE_WHILE_REVALIDATE_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_SCC_STALE_WHILE_REVALIDATE_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <HeadlessCheckbox
                  id="staleContentControl.staleWhileRevalidate.enabled"
                  disabled={isViewMode}
                  label={t(
                    "FEATURE_CARD_SCC_STALE_WHILE_REVALIDATE_CHECKBOX_LABEL"
                  )}
                  dataTestId="stale-while-revalidate-checkbox"
                  {...register(
                    "staleContentControl.staleWhileRevalidate.enabled"
                  )}
                />
              ),
            }}
            divider
            dataTestId="stale-while-revalidate"
          />
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_SCC_STALE_IF_ERROR_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_SCC_STALE_IF_ERROR_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_SCC_STALE_IF_ERROR_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <>
                  <Checkbox
                    disabled={isViewMode}
                    checked={staleIfErrorEnabled}
                    onChange={() => {
                      handleCreateFeature("staleContentControl.staleIfError", {
                        enabled: !staleIfErrorEnabled,
                        http4xxErrors: false,
                        http5xxErrors: false,
                      });
                    }}
                    id="stale-if-error"
                    label={t(
                      "FEATURE_CARD_SCC_STALE_IF_ERROR_CHECKBOX_ENABLED_LABEL"
                    )}
                    dataTestId="stale-if-error-checkbox"
                  />

                  {staleIfErrorEnabled && (
                    <IndentedCheckboxes>
                      <CheckboxContainer>
                        <HeadlessCheckbox
                          id="staleContentControl.staleIfError.http4xxErrors"
                          disabled={isViewMode}
                          label={t(
                            "FEATURE_CARD_SCC_STALE_IF_ERROR_CHECKBOX_4XX_LABEL"
                          )}
                          dataTestId="4xx-errors-checkbox"
                          {...register(
                            "staleContentControl.staleIfError.http4xxErrors"
                          )}
                        />
                      </CheckboxContainer>
                      <CheckboxContainer>
                        <HeadlessCheckbox
                          id="staleContentControl.staleIfError.http5xxErrors"
                          disabled={isViewMode}
                          label={t(
                            "FEATURE_CARD_SCC_STALE_IF_ERROR_CHECKBOX_5XX_LABEL"
                          )}
                          dataTestId="5xx-errors-checkbox"
                          {...register(
                            "staleContentControl.staleIfError.http5xxErrors"
                          )}
                        />
                      </CheckboxContainer>
                    </IndentedCheckboxes>
                  )}
                </>
              ),
            }}
            dataTestId="stale-if-error"
          />
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"staleContentControl"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="stale-content-control"
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};

const IndentedCheckboxes = styled.div`
  margin-left: 32px;
  margin-top: 16px;
`;

const CheckboxContainer = styled.div`
  padding: 8px 0px;
`;
