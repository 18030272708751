import { ReactElement } from "react";
import styled from "@emotion/styled";

import { useBool } from "../../../hooks/use-bool/useBool";
import { AccessGroupRow } from "../../atoms/AccessGroupRow/AccessGroupRow";
import { UserAccessGroupActionsDropdown } from "../../../modules/user-management/templates/users/user/UserAccessGroupActionsDropdown";
import {
  CheckboxInputProps,
  CustomCheckboxInput,
} from "../../atoms/Checkbox/CustomCheckBoxInput";

interface NestedAccessGroup {
  id: number;
  name: string;
  roleName?: string;
  list?: NestedAccessGroup[];
}

interface NestedUserAccessGroupRowProps extends NestedAccessGroup {
  userId: number;
  nestedIndex: number;
  buildCheckboxProps?: (row: NestedAccessGroup) => CheckboxInputProps;
  fields: NestedUserAccessGroupRowFields[];
}

export enum NestedUserAccessGroupRowFields {
  CUSTOM_CHECKBOX = "custom_checkbox",
  ACCESS_GROUP = "access_group",
  ROLE = "role",
  ACTIONS = "actions",
}

export const NestedUserAccessGroupRow = ({
  id,
  name,
  roleName,
  list,
  nestedIndex,
  userId,
  fields,
  buildCheckboxProps,
}: NestedUserAccessGroupRowProps): ReactElement => {
  const [isOpen, toggleIsOpen] = useBool(false);

  return (
    <>
      <StyledTableBodyRow key={id}>
        {fields.includes(NestedUserAccessGroupRowFields.ACCESS_GROUP) && (
          <VariablePaddingCell factor={nestedIndex}>
            {fields.includes(NestedUserAccessGroupRowFields.CUSTOM_CHECKBOX) &&
              buildCheckboxProps && (
                <CustomBoxContainer>
                  <CustomCheckboxInput
                    {...buildCheckboxProps({ id, name, roleName, list })}
                  />
                </CustomBoxContainer>
              )}
            <AccessGroupRow
              id={id}
              name={name}
              childrenCount={list?.length || 0}
              toggleIsOpen={toggleIsOpen}
              isOpen={isOpen}
            />
          </VariablePaddingCell>
        )}
        {fields.includes(NestedUserAccessGroupRowFields.ROLE) && (
          <td>{roleName}</td>
        )}
        {fields.includes(NestedUserAccessGroupRowFields.ACTIONS) && (
          <td>
            <UserAccessGroupActionsDropdown
              userId={userId}
              accessGroupId={id}
              role={roleName}
            />
          </td>
        )}
      </StyledTableBodyRow>
      {isOpen &&
        list?.map((child) => (
          <NestedUserAccessGroupRow
            key={child.id}
            {...child}
            nestedIndex={nestedIndex + 1}
            userId={userId}
            fields={fields}
            buildCheckboxProps={buildCheckboxProps}
          />
        ))}
    </>
  );
};

const VariablePaddingCell = styled.td<{ factor: number }>`
  display: flex;
  align-items: center;
  padding-left: ${({ factor }) => 16 * (factor + 1)}px;
`;

const StyledTableBodyRow = styled.tr`
  :hover {
    background: ${({ theme }) => theme.backgrounds.base};
    border-radius: 6px;
  }
  td {
    padding-top: 0;
    padding-bottom: 0;
  }
  td:first-child {
    border-radius: 6px 0 0 6px;
  }
  td:last-child {
    border-radius: 0 6px 6px 0;
    padding-right: 0;
  }
`;

const CustomBoxContainer = styled.div`
  padding-right: 16px;
`;
