import styled from "@emotion/styled";
import React from "react";

import {
  Field,
  TableCell,
  FieldInput,
  Remove,
} from "../../../../../../../../../components/molecules/DefinitionModal/DefinitionModal.styled";

interface IHeadersRowProps {
  header: { name: string; value?: string | undefined };
  rowIndex: number;
  disabled: boolean;
  canDelete: boolean;
  removeHeader: (index: number) => void;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataTestId: string;
}

export const HeadersRow: React.FC<IHeadersRowProps> = ({
  header,
  rowIndex,
  disabled,
  canDelete,
  removeHeader,
  onNameChange,
  onValueChange,
  dataTestId,
}) => {
  return (
    <Field key={`row-${rowIndex}`}>
      <TableCell rightPadding bottomPadding="thin">
        <HeaderNameContainer>
          <FieldInput
            disabled={disabled}
            value={header.name}
            placeholder="name"
            name={`denyPolicies-headerName-${rowIndex}`}
            onChange={onNameChange}
            data-testid={`${dataTestId}-header-name`}
          />
        </HeaderNameContainer>
      </TableCell>
      <TableCell leftPadding bottomPadding="thin">
        <FieldInput
          disabled={disabled}
          value={header.value || ""}
          placeholder="value"
          onChange={onValueChange}
          data-testid={`${dataTestId}-header-value`}
        />
      </TableCell>
      {!disabled && canDelete && (
        <TableCell>
          <Remove
            onClick={() => removeHeader(rowIndex)}
            data-testid={`${dataTestId}-header-remove`}
          />
        </TableCell>
      )}
    </Field>
  );
};

const HeaderNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
