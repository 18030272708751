import { IMatchRule } from "../../../../../models/configuration/definitions";

export const updateGeneratedResponseReferences = (
  oldName: string,
  newName: string
) => (matchRule: IMatchRule): IMatchRule => {
  matchRule.features.generatedResponse =
    matchRule.features.generatedResponse === oldName
      ? newName
      : matchRule.features.generatedResponse;

  return matchRule;
};
