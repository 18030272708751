import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import {
  CachePolicy,
  CCHOExt,
} from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { useIsViewMode } from "../../../../../../../../../../store/slices/permissions/hooks";
import { ExternalPolicyType } from "./ExternalPolicyType";
import type { PolicyProps } from "./types";

export const ExternalPolicy = ({
  errors,
  handleCreateFeature,
  maxWidth,
  policy,
  register,
  unregister,
}: PolicyProps<CCHOExt>): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();

  return (
    <>
      <PropertyDefinitionCard
        title={t("FEATURE_CARD_CCHO_EXTERNAL_TITLE")}
        additionalInfoTitle={t("FEATURE_CARD_CCHO_EXTERNAL_DESCRIPTION_TITLE")}
        additionalInfoContent={t(
          "FEATURE_CARD_CCHO_EXTERNAL_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.CheckboxType}
        fieldProps={{
          label: t("FEATURE_CARD_CCHO_EXTERNAL_CHECKBOX_LABEL"),
          disabled: isViewMode,
          checked: !!policy,
          onChange: () => {
            handleCreateFeature(
              "cacheControlHeaderOverride.ext",
              !!policy ? undefined : { cachePolicy: CachePolicy.NO_CACHE }
            );
          },
          dataTestId: "ccho-ecp",
        }}
        divider
        dataTestId="ccho-ecp"
      />

      {policy && (
        <ExternalPolicyType
          disabled={isViewMode}
          ext={policy}
          handleCreateFeature={handleCreateFeature}
          maxWidth={maxWidth}
          numberErrorMessage={
            errors.cacheControlHeaderOverride?.ext?.cachePolicy?.message
          }
          register={register}
          unregister={unregister}
          t={t}
          tMisc={tMisc}
        />
      )}
    </>
  );
};
