import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Icon, Icons } from "../Icon/Icon";
import { AccessGroup } from "../../../models/user/user.interfaces";

export const AccessGroupRow = ({
  name,
  description,
  childrenCount,
  toggleIsOpen,
  isOpen,
}: Omit<AccessGroup, "list" | "createdDate"> & {
  childrenCount: number;
  toggleIsOpen: () => void;
  isOpen: boolean;
}): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  return (
    <AccessGroupRowContainer>
      <AccessGroupName>{name}</AccessGroupName>
      {childrenCount > 0 ? (
        <PointerContainer onClick={toggleIsOpen}>
          {description && <AccessGroupInfo>{description},</AccessGroupInfo>}
          <SpinningIcon
            isOpen={isOpen}
            name={Icons.ARROW_FAT}
            color="secondary"
            size={8}
          />
          <AccessGroupInfo>
            {t("ACCESS_GROUP_ROW_CHILD_COUNT_TEXT", { count: childrenCount })}
          </AccessGroupInfo>
        </PointerContainer>
      ) : (
        description && <AccessGroupInfo>{description}</AccessGroupInfo>
      )}
    </AccessGroupRowContainer>
  );
};

const PointerContainer = styled.div`
  margin-left: 12px;
  cursor: pointer;
`;

const SpinningIcon = styled(Icon)<{ isOpen: boolean }>`
  margin-left: 4px;
  transform: rotate(${({ isOpen }) => (isOpen ? "-90deg" : "90deg")});
`;

const AccessGroupRowContainer = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const AccessGroupName = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
const AccessGroupInfo = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.muted};
  margin-left: 1rem;
`;
