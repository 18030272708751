import { ConfigurationType } from "../types";
import { parseConfiguration } from "./parseConfiguration";
import { parseConfigurationDetails } from "./parseConfigurationDetails";

export const generateDefaultConfiguration = (
  configName: string,
  subscriberId: number
): ConfigurationType => {
  const newConfig = parseConfiguration({
    configName,
    subscriberId,
    createdTime: new Date().toISOString(),
    versionId: 0,
    schemaVersion: "3.1",
  });

  newConfig.config = parseConfigurationDetails({
    genSchemaVersion: "3.1",
    subscriberId: subscriberId || 0,
  });

  newConfig.isLocalDraft = true;

  return newConfig;
};
