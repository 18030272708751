import styled from "@emotion/styled";

const LoaderContainer = styled.div<{ size: number }>`
  & {
    position: relative;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
  }
  & div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: ${({ size }) => size / 2}px ${({ size }) => size / 2}px;
  }
  & div:after {
    content: " ";
    display: block;
    position: absolute;
    width: ${({ size }) => size / 10}px;
    height: ${({ size }) => size / 10}px;
    border-radius: 50%;
    background: ${({ theme }) => theme.icon.primary};
    margin: -${({ size }) => size / 20}px 0 0 -${({ size }) => size / 20}px;
  }
  & div:nth-of-type(1) {
    animation-delay: -0.036s;
  }
  & div:nth-of-type(1):after {
    top: ${({ size }) => (size / 80) * 63}px;
    left: ${({ size }) => (size / 80) * 63}px;
  }
  & div:nth-of-type(2) {
    animation-delay: -0.072s;
  }
  & div:nth-of-type(2):after {
    top: ${({ size }) => (size / 80) * 68}px;
    left: ${({ size }) => (size / 80) * 56}px;
  }
  & div:nth-of-type(3) {
    animation-delay: -0.108s;
  }
  & div:nth-of-type(3):after {
    top: ${({ size }) => (size / 80) * 71}px;
    left: ${({ size }) => (size / 80) * 48}px;
  }
  & div:nth-of-type(4) {
    animation-delay: -0.144s;
  }
  & div:nth-of-type(4):after {
    top: ${({ size }) => (size / 80) * 72}px;
    left: ${({ size }) => (size / 80) * 40}px;
  }
  & div:nth-of-type(5) {
    animation-delay: -0.18s;
  }
  & div:nth-of-type(5):after {
    top: ${({ size }) => (size / 80) * 71}px;
    left: ${({ size }) => (size / 80) * 32}px;
  }
  & div:nth-of-type(6) {
    animation-delay: -0.216s;
  }
  & div:nth-of-type(6):after {
    top: ${({ size }) => (size / 80) * 68}px;
    left: ${({ size }) => (size / 80) * 24}px;
  }
  & div:nth-of-type(7) {
    animation-delay: -0.252s;
  }
  & div:nth-of-type(7):after {
    top: ${({ size }) => (size / 80) * 63}px;
    left: ${({ size }) => (size / 80) * 17}px;
  }
  & div:nth-of-type(8) {
    animation-delay: -0.288s;
  }
  & div:nth-of-type(8):after {
    top: ${({ size }) => (size / 80) * 56}px;
    left: ${({ size }) => (size / 80) * 12}px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader: React.FC<{ className?: string; size?: number }> = ({
  className,
  size = 80,
}) => (
  <LoaderContainer className={`lds-roller ${className}`} size={size}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </LoaderContainer>
);
