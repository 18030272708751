// You can find more informations about this file at this url:
// https://ctl.atlassian.net/wiki/spaces/STR/pages/671605326488/Match+Rule+Expressions+Conditions

export enum ConditionTypes {
  PATH_ALL_OPERATORS = "path_all_operators",
  REQUEST_HEADERS = "request_headers",
  PATH_QUERY_STRING = "path_query_string",
  METHOD = "method",
  URI = "uri",
  QUERY_STRING = "query_string",
  RESPONSE_HEADER = "response_header",
  RESPONSE_STATUS = "response_status",
}

export enum Operators {
  EQUALS = "equals",
  DOES_NOT_EQUAL = "does_not_equal",
  GREATER_THAN = "greater_than",
  LESS_THAN = "less_than",
  GREATER_THAN_OR_EQUAL_TO = "greater_than_or_equal_to",
  LESS_THAN_OR_EQUAL_TO = "less_than_or_equal_to",
  GLOBMATCH = "globmatch",
  NEGATED_GLOBMATCH = "negated_globmatch",
  REGEXP = "regexp",
  NEGATED_REGEXP = "negated_regexp",
}

export interface IOperator {
  sensitive: string;
  insensitive: string;
  englishOperatorSensitive?: string;
  englishOperatorInsensitive?: string;
}

export type OperatorsDictionnary = Map<Operators, IOperator>;

export const OPERATORS_DICTIONNARY: OperatorsDictionnary = new Map([
  [
    Operators.EQUALS,
    {
      sensitive: "==",
      insensitive: "%=",
    },
  ],
  [
    Operators.DOES_NOT_EQUAL,
    {
      sensitive: "!=",
      insensitive: "!%=",
    },
  ],
  [
    Operators.GREATER_THAN_OR_EQUAL_TO,
    {
      sensitive: ">=",
      insensitive: "%>=",
    },
  ],
  [
    Operators.LESS_THAN_OR_EQUAL_TO,
    {
      sensitive: "<=",
      insensitive: "%<=",
    },
  ],
  [
    Operators.LESS_THAN,
    {
      sensitive: "<",
      insensitive: "%<",
    },
  ],
  [
    Operators.GREATER_THAN,
    {
      sensitive: ">",
      insensitive: "%>",
    },
  ],
  [
    Operators.GLOBMATCH,
    {
      sensitive: "*=",
      insensitive: "%*=",
      englishOperatorSensitive: "globmatch",
      englishOperatorInsensitive: "globmatchi",
    },
  ],
  [
    Operators.NEGATED_GLOBMATCH,
    {
      sensitive: "!*=",
      insensitive: "!%*=",
      englishOperatorSensitive: "!globmatch",
      englishOperatorInsensitive: "!globmatchi",
    },
  ],
  [
    Operators.NEGATED_REGEXP,
    {
      sensitive: "!~=",
      insensitive: "!%~=",
      englishOperatorSensitive: "!regexmatch",
      englishOperatorInsensitive: "!regexmatchi",
    },
  ],
  [
    Operators.REGEXP,
    {
      sensitive: "~=",
      insensitive: "%~=",
      englishOperatorSensitive: "regexmatch",
      englishOperatorInsensitive: "regexmatchi",
    },
  ],
]);

interface IConditionType {
  cooked: string;
  raw: string;
  inputType: "string";
}

export const CONDITIONS_TYPES_DICTIONNARY = new Map<
  ConditionTypes,
  IConditionType
>([
  [
    ConditionTypes.PATH_QUERY_STRING,
    {
      cooked: "req.uri.pathquery",
      raw: "rrequ.uri.pathquery",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.PATH_ALL_OPERATORS,
    {
      cooked: "req.uri.path",
      raw: "rrequ.uri.path",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.REQUEST_HEADERS,
    {
      cooked: "req.h.%headerName",
      raw: "rreq.h.%headerName",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.METHOD,
    {
      cooked: "req.method",
      raw: "rrequ.method",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.QUERY_STRING,
    {
      cooked: "req.uri.query",
      raw: "rrequ.uri.query",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.URI,
    {
      cooked: "req.uri",
      raw: "rrequ.uri",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.RESPONSE_HEADER,
    {
      cooked: "resp.h.%headerName",
      raw: "rresp.h.%headerName",
      inputType: "string",
    },
  ],
  [
    ConditionTypes.RESPONSE_STATUS,
    {
      cooked: "resp.status",
      raw: "rresp.status",
      inputType: "string",
    },
  ],
]);
