import { ReactElement, useContext } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useRawJSON } from "../../../hooks/use-raw-json/useRawJSON";
import { comparisonContext } from "../../../modules/caching/configurations/configuration_page/comparisonContext";
import { Button } from "../../atoms/Button/Button";
import { Icons } from "../../atoms/Icon/Icon";

interface RawJsonHeaderProps {
  propertyName?: string;
}

const DownloadButtonIcon = { name: Icons.FILE_DOWNLOAD };

export const RawJsonHeader = ({
  propertyName,
}: RawJsonHeaderProps): ReactElement => {
  const history = useHistory();
  const [t] = useTranslation("rawJsonPage");

  const { configuration, rawJSON } = useRawJSON();
  const { isComparison } = useContext(comparisonContext);

  return (
    <RawJsonPageHeaderContainer>
      <Header>
        <LeftSection>
          <TitleContainer>
            <label
              className="-text--h2"
              data-testid="raw-json-header-property-name"
            >
              {t("PAGE_TITLE")}
            </label>
          </TitleContainer>
        </LeftSection>
        <ButtonsContainer>
          <Button
            onClick={() => {
              let path = `/cmv3/configurations/${configuration?.configName}/properties`;

              if (propertyName) {
                path += `/${propertyName}`;
              }

              history.push(path);
            }}
            label={t("VIEW_UI_BUTTON")}
            backgroundColor="baseLight"
            textColor="highlight"
            borderColor="highlight"
            dataTestId="raw-json-header-view-ui"
          />
          {!isComparison && (
            <DownloadButton
              icon={DownloadButtonIcon}
              onClick={() => {
                try {
                  const linkSource = `data:text/json;charset=utf-8,${encodeURIComponent(
                    rawJSON
                  )}`;
                  const downloadLink = document.createElement("a");
                  const fileName = `${configuration?.configName}.json`;

                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();

                  toast.success(t("DOWNLOAD_JSON_SUCCESS"));
                } catch (error) {
                  toast.error(t("DOWNLOAD_JSON_ERROR"));
                }
              }}
              label={t("DOWNLOAD_BUTTON")}
              dataTestId="raw-json-header-download-button"
            />
          )}
        </ButtonsContainer>
      </Header>
    </RawJsonPageHeaderContainer>
  );
};

const RawJsonPageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: ${({ theme }) => `1rem ${theme.spacings.pageContainer}`};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
`;

const DownloadButton = styled(Button)`
  & svg * {
    fill: ${({ theme }) => theme.text.primaryAlt};
  }
`;
