import styled from "@emotion/styled";
import { ReactElement } from "react";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";
import { InputField } from "../InputField/InputField";

interface ModalLineInputFieldProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
}

export const ModalLineInputField = ({
  label,
  value,
  onChange,
}: ModalLineInputFieldProps): ReactElement => {
  return (
    <Line>
      <Label>{label}</Label>
      <FieldInput onChange={onChange} value={value} />
    </Line>
  );
};

const Line = styled(FlexBox)`
  justify-content: space-between;
  padding-bottom: 16px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const FieldInput = styled(InputField)`
  width: 60%;
  border-color: ${({ theme }) => theme.borders.muted};
  margin-right: 0;
`;
