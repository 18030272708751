import { parseConfiguration } from "./parseConfiguration";
import { ConfigurationType } from "../types";

export const cloneConfiguration = (
  configuration: ConfigurationType,
  isCopy = false
): ConfigurationType => {
  const newConfig = parseConfiguration(configuration);

  if (configuration.config) {
    newConfig.config = { ...configuration.config };
  }

  if (isCopy) {
    newConfig.id = configuration.id;
  }

  newConfig.errors = configuration.errors;
  newConfig.isLocalDraft = configuration.isLocalDraft;

  return newConfig;
};
