import { ErrorLevel, APIError } from "../../../../models/error";
import { httpClient } from "../../../../core/http-client";

const baseURL =
  process.env.REACT_APP_FEATURE_FLAGS_ADMIN_HOST ||
  "https://lab-portal-int-edge-delivery.netauto.nsatc.net/dashboard";

export enum FeatureFlagsStatus {
  RESTRICTED = "restricted",
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export interface enabledForProps {
  users?: number[];
  subscribers?: number[];
  accessGroups?: number[];
  apiKeys?: number[];
  internalsOnly?: boolean;
}

export type FeatureFlagsData = {
  id: string;
  name: string;
  enabledFor: enabledForProps;
  status: FeatureFlagsStatus;
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
};

interface FeatureFlags {
  data: FeatureFlagsData[];
}

export interface FeatureFlagHeader {
  id?: string;
  name: string;
  status?: FeatureFlagsStatus;
  enabledFor?: enabledForProps;
}
export interface FeatureFlagModalChildProps {
  closeModal?: () => void;
  handleSaveFeatureFlag: (
    featureFlag: FeatureFlagHeader,
    isUpdate: boolean,
    featureFlagId?: string
  ) => Promise<void>;
  isUpdate: boolean;
  featureFlagsHeader?: FeatureFlagHeader;
  dataTestId?: string;
}

export const getFeatureFlags = async (): Promise<FeatureFlags> => {
  try {
    const { data } = await httpClient.get<FeatureFlags>(`/features`, {
      baseURL,
    });
    return data;
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "getFeatureFlags()",
      ErrorLevel.ERROR,
      "Failed to GET Feature Flags"
    );
  }
};

export const createFeatureFlag = async (
  featureFlag: FeatureFlagHeader
): Promise<FeatureFlags> => {
  try {
    const { data } = await httpClient.post<FeatureFlagHeader>(
      `/features`,
      featureFlag,
      {
        baseURL,
      }
    );
    return data;
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "createFeatureFlag()",
      ErrorLevel.ERROR,
      "Failed to CREATE Feature Flag"
    );
  }
};

export const updateFeatureFlag = async (
  featureFlag: FeatureFlagHeader,
  featureFlagId: string
): Promise<FeatureFlags> => {
  try {
    const { data } = await httpClient.put<FeatureFlagHeader>(
      `/features/${featureFlagId}`,
      featureFlag,
      {
        baseURL,
      }
    );
    return data;
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "updateFeatureFlag()",
      ErrorLevel.ERROR,
      "Failed to UPDATE Feature Flag"
    );
  }
};

export const deleteFeatureFlag = async (
  featureFlagId: string
): Promise<void> => {
  try {
    await httpClient.delete<FeatureFlags>(`/features/${featureFlagId}`, {
      baseURL,
    });
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "deleteFeatureFlag",
      ErrorLevel.ERROR,
      "Failed to DELETE Feature Flag"
    );
  }
};
