import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../../components/atoms/Button/Button";
import { LabelButton } from "../../../../../../../components/atoms/LabelButton/LabelButton";
import { Protected } from "../../../../../../../components/atoms/Protected/Protected";
import { DeleteModal } from "../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { PropertyDefinitionBlock } from "../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import {
  RadioSlider,
  RadioSliderThemes,
} from "../../../../../../../components/molecules/RadioSlider/RadioSlider";
import { useSaveValidatedMatchLogic } from "../../../../../../../hooks/use-save-validated-match-logic/useSaveValidatedMatchLogic";
import { IMatchRule } from "../../../../../../../models/configuration/definitions";
import { MatchBlocks } from "../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../models/permissions";
import { useIsViewMode } from "../../../../../../../store/slices/permissions/hooks";
import { Condition } from "./Condition";
import { ConditionError } from "./ConditionError";
import { ConditionTypes, Operators } from "./dictionnaries";
import { setNewExpression } from "./setNewExpression";
import { toast } from "react-toastify";

enum BooleanOptions {
  AND = "and",
  OR = "or",
}

interface ConditionsUIProps {
  matchRule: IMatchRule;
  matchBlock: MatchBlocks;
  onCodeChange: (modifiedMatchRule: IMatchRule) => void;
  handleSave: () => void;
}

export const ConditionsUI = ({
  matchRule,
  matchBlock,
  onCodeChange,
  handleSave,
}: ConditionsUIProps): ReactElement => {
  const isViewMode = useIsViewMode();
  const [unsavedMatchRule, setUnsavedMatchRule] = useState<boolean>();

  const [expressionError, setExpressionError] = useState(false);
  const expression = /\s(and|or)\s/g;

  const splittedExpressions = matchRule.expression
    ? matchRule.expression.split(expression).map((chunk) => chunk.trim())
    : [];
  const { t } = useTranslation("configurationMatchRulesPage");

  const updateMatchRule = (expressions: string[]) => {
    setUnsavedMatchRule(true);
    let newExpression: string | undefined = expressions.join(" ");
    if (!newExpression.length) {
      newExpression = undefined;
    }
    onCodeChange({ ...matchRule, expression: newExpression });
  };

  const generateNewExpression = () => {
    const newExpression = setNewExpression(
      ConditionTypes.REQUEST_HEADERS,
      "",
      Operators.EQUALS,
      "",
      true,
      false
    );

    if (splittedExpressions.length) {
      splittedExpressions.push(BooleanOptions.AND, newExpression);
    } else {
      splittedExpressions.push(newExpression);
    }
    updateMatchRule(splittedExpressions);
  };

  const onDelete = (indexToDelete: number) => {
    splittedExpressions.splice(indexToDelete, 2);
    if (
      splittedExpressions[splittedExpressions.length - 1] ===
        BooleanOptions.AND ||
      splittedExpressions[splittedExpressions.length - 1] === BooleanOptions.OR
    ) {
      splittedExpressions.pop();
    }
    updateMatchRule(splittedExpressions);
  };

  const onSave = useSaveValidatedMatchLogic(matchBlock, matchRule, () => {
    handleSave();
    setUnsavedMatchRule(false);
    toast.success(t("EXPRESSION_SAVED_LOCALLY"));
  });

  return !expressionError ? (
    <ConditionsUIContainer>
      {splittedExpressions.map((splittedExpression, key) => {
        if (
          splittedExpression.trim() === BooleanOptions.AND ||
          splittedExpression.trim() === BooleanOptions.OR
        ) {
          return (
            <RadioSliderContainer key={key}>
              <RadioSlider
                disabled={isViewMode}
                sliderTheme={RadioSliderThemes.BLUE}
                onChange={(result) => {
                  splittedExpressions[key] = result.value;
                  updateMatchRule(splittedExpressions);
                }}
                choices={[
                  {
                    label: t("EXPRESSION_CONDITION_AND_LABEL"),
                    value: BooleanOptions.AND,
                    dataTestId: `condition-slider-and-${key}`,
                  },
                  {
                    label: t("EXPRESSION_CONDITION_OR_LABEL"),
                    value: BooleanOptions.OR,
                    dataTestId: `condition-slider-or-${key}`,
                  },
                ]}
                defaultSelectedIndex={
                  splittedExpression.trim() === BooleanOptions.AND ? 0 : 1
                }
              />
            </RadioSliderContainer>
          );
        } else {
          return (
            <PropertyDefinitionBlock
              key={key}
              title={splittedExpression}
              displayArrow={false}
              customDeleteButton={
                isViewMode ? undefined : (
                  <DeleteModal
                    title={t("CONDITIONS_DELETE_MODAL_TITLE")}
                    deleteItemName={`${splittedExpression}`}
                    onDelete={() => {
                      onDelete(key);
                    }}
                    dataTestId={`conditions-${key}`}
                  />
                )
              }
              dataTestId={`condition-block-${key}`}
              propertyCards={
                <Condition
                  key={`${key}+${splittedExpressions.length}`}
                  index={key}
                  disabled={isViewMode}
                  onError={setExpressionError}
                  matchBlock={matchBlock}
                  onChange={(chunk) => {
                    splittedExpressions[key] = chunk;
                    updateMatchRule(splittedExpressions);
                  }}
                  expression={splittedExpression}
                />
              }
            />
          );
        }
      })}
      <Protected permissions={UserRoles.EDIT_CONFIG}>
        <LabelButton
          onClick={generateNewExpression}
          label={t("ADD_EXPRESSION_BUTTON")}
          dataTestId="add-condition-button"
        />
        {unsavedMatchRule ? (
          <FloattingButton>
            <Button
              label={t("SAVE_EXPRESSION_BUTTON")}
              onClick={onSave}
              dataTestId="save-condition-button"
            />
          </FloattingButton>
        ) : null}
      </Protected>
    </ConditionsUIContainer>
  ) : (
    <ConditionError />
  );
};

const ConditionsUIContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const RadioSliderContainer = styled.div`
  margin-bottom: 20px;
`;

const FloattingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 9rem;
`;
