import React from "react";
import styled from "@emotion/styled";
import { animated, config, useSpring, useTransition } from "react-spring";

import { Theme, useThemeContext } from "../../../contexts/themeContext";
import { Icon, Icons } from "../../atoms/Icon/Icon";

export const ToggleTheme: React.FC = () => {
  const { theme, toggleTheme } = useThemeContext();

  const backgroundColorAnimation = useSpring({
    backgroundColor: theme === Theme.DARK_MODE ? "#083176" : "#38C6F4",
  });

  const diskColorAnimation = useSpring({
    left: theme === Theme.DARK_MODE ? "1.625rem" : "0.125rem",
  });

  const themeTransition = useTransition(theme, {
    config: config.stiff,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <ToggleThemeContainer
      onClick={toggleTheme}
      style={backgroundColorAnimation}
      data-testid="toggle-theme"
    >
      <Disk style={diskColorAnimation}>
        {themeTransition((styles, animatedTheme) => {
          return animatedTheme === Theme.DARK_MODE ? (
            <IconContainer style={styles}>
              <Moon name={Icons.MOON} />
            </IconContainer>
          ) : (
            <IconContainer style={styles}>
              <Sun name={Icons.SUN} />
            </IconContainer>
          );
        })}
      </Disk>
    </ToggleThemeContainer>
  );
};

const ToggleThemeContainer = styled(animated.div)`
  position: relative;
  cursor: pointer;

  border: 0;
  border-radius: 0.75rem;
  line-height: 1.5rem;

  display: inline-block;
  height: 1.5rem;
  width: 3rem;

  margin-bottom: 0;
  margin-right: 0;
`;

const Disk = styled(animated.div)`
  position: absolute;

  border: 0;
  border-radius: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 1.25rem;
  width: 1.25rem;
  top: 0.125rem;

  margin: 0;
`;

const IconContainer = styled(animated.div)`
  position: absolute;
  display: flex;
`;

const Moon = styled(Icon)`
  transform: rotate(225deg);
`;

const Sun = styled(Icon)``;
