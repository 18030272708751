import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Integration } from "@sentry/types";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeContextProvider } from "./contexts/themeContext";
import { App } from "./modules/app";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";

import "./i18n";
import "./index.css";
import { AppEnvironmentContextProvider } from "./contexts/appEnvironmentContext";

const RootApplication = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT || "localhost";
  const version = process.env.REACT_APP_COMMIT_HASH || "0.0.0";

  // Second test to prevent Cypress (running with `yarn cypress:ci` starting the app on host http://localhost:3000)
  // to send events to Sentry
  const isProd =
    environment === "production" &&
    window.location.origin !== "http://localhost:3000";

  Sentry.init({
    dsn:
      "https://8276d134b1534cb59b9eaacf9f034c7b@o103104.ingest.sentry.io/6041270",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["edge-delivery-api.lumen.com"],
      }) as Integration,
    ].concat(
      process.env.NODE_ENV === "development"
        ? [
            new Sentry.Integrations.Breadcrumbs({
              console: false,
            }),
          ]
        : []
    ),
    environment,
    tracesSampleRate: 0.5,
    normalizeDepth: 15,
    enabled: isProd,
    release: version,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === "console") {
        return null;
      }
      return breadcrumb;
    },
  });

  Sentry.setContext("app", { commitHash: version, isProd });
  Sentry.setTags({ appVersion: version });

  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <ThemeContextProvider>
          <AppEnvironmentContextProvider
            value={{ version, environment, isProd }}
          >
            <Provider store={store}>
              <App />
            </Provider>
          </AppEnvironmentContextProvider>
        </ThemeContextProvider>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

RootApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
