import { useAppSelector } from "../../types";
import { RTMSlice } from "./types";

export const useCurrentByMetro = (): RTMSlice["currentByMetro"] =>
  useAppSelector((store) => store.rtm.currentByMetro);

export const useCurrentByRegion = (): RTMSlice["currentByRegion"] =>
  useAppSelector((store) => store.rtm.currentByRegion);

export const useCurrentByProperty = (): RTMSlice["currentByProperty"] =>
  useAppSelector((store) => store.rtm.currentByProperty);

export const useHistorical = (): RTMSlice["historical"] =>
  useAppSelector((store) => store.rtm.historical);

export const useRegionsHistory = (): RTMSlice["regionsHistory"] =>
  useAppSelector((store) => store.rtm.regionsHistory);

export const useMetrosHistory = (): RTMSlice["metrosHistory"] =>
  useAppSelector((store) => store.rtm.metrosHistory);

export const useProperties = (): RTMSlice["properties"] =>
  useAppSelector((store) => store.rtm.properties);

export const useCurrentAccessGroupData = (): RTMSlice["currentAccessGroupData"] =>
  useAppSelector((store) => store.rtm.currentAccessGroupData);

export const useCurrentDataStatus = (): RTMSlice["currentDataStatus"] =>
  useAppSelector((store) => store.rtm.currentDataStatus);

export const useCurrentPropertyStatus = (): RTMSlice["currentPropertyStatus"] =>
  useAppSelector((store) => store.rtm.currentPropertyStatus);
export const useHistoryStatus = (): RTMSlice["historyStatus"] =>
  useAppSelector((store) => store.rtm.historyStatus);
export const useRegionHistoryStatus = (): RTMSlice["regionHistoryStatus"] =>
  useAppSelector((store) => store.rtm.regionHistoryStatus);
export const useMetroHistoryStatus = (): RTMSlice["metroHistoryStatus"] =>
  useAppSelector((store) => store.rtm.metroHistoryStatus);
