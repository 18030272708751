import styled from "@emotion/styled";
import { FC, ReactNode } from "react";

import { SectionHeader } from "../../atoms/SectionHeader/SectionHeader";
import { SectionTitle } from "../../atoms/SectionTitle/SectionTitle";

export const SectionLayout: FC<{ title: string; headerRight?: ReactNode }> = ({
  children,
  title,
  headerRight,
}) => (
  <Container>
    <SectionHeader>
      <SectionTitle>{title}</SectionTitle>
      {headerRight}
    </SectionHeader>
    {children}
  </Container>
);

const Container = styled.div`
  padding-bottom: 16px;
`;
