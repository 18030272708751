import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { MatchBlocks } from "../../../../../../../models/configuration/definitions/matchlogic";
import { ConditionTypes } from "./dictionnaries";
import { splitExpression } from "./splitExpression";
import { useConditionUi } from "./useConditionUi";
import { useDropdownsItems } from "./useDropdownsItems";
import { useExpressionParsingError } from "./useExpressionParsingError";

export enum Fields {
  CONDITION_TYPE = "condition type",
  EXPECTED_VALUE = "expected value",
  OPERATOR = "operator",
}

interface ConditionProps {
  disabled: boolean;
  expression: string;
  index: number;
  matchBlock: MatchBlocks;
  onChange: (expressionChunk: string) => void;
  onError: (expressionError: boolean) => void;
}

export const Condition = ({
  disabled,
  expression,
  index,
  matchBlock,
  onChange,
  onError,
}: ConditionProps): ReactElement => {
  const { errors, ..._splittedExpression } = splitExpression(
    expression,
    matchBlock
  );

  const { t } = useTranslation("configurationMatchRulesPage");

  const {
    condition,
    conditionType,
    expectedValue,
    objectName,
    operator,
    operatorSensitive,
    setConditionsType,
    setExpectedValue,
    setObjectName,
    setOperator,
    setOperatorSensitive,
  } = useConditionUi(_splittedExpression);

  const { conditionsTypeList, operatorList } = useDropdownsItems(
    conditionType,
    operator,
    matchBlock
  );

  const localizedErrors = useExpressionParsingError(
    condition,
    errors,
    onError,
    onChange
  );
  const isParsingError = !localizedErrors;
  if (isParsingError) return <></>;

  const shouldRenderHeaderInput =
    conditionType === ConditionTypes.REQUEST_HEADERS ||
    conditionType === ConditionTypes.RESPONSE_HEADER;

  return (
    <>
      {
        <PropertyDefinitionCard
          divider
          required
          title={t("MATCH_RULE_CARD_SELECTED_TYPE_TITLE")}
          fieldType={FieldType.DropdownType}
          fieldProps={{
            disabled,
            items: conditionsTypeList,
            onSelect: setConditionsType,
            dataTestId: `condition-type-dropdown-${index}`,
          }}
        />
      }
      {shouldRenderHeaderInput && (
        <PropertyDefinitionCard
          divider
          required
          title={t("MATCH_RULE_CARD_HEADER_TITLE")}
          fieldType={FieldType.InputFieldType}
          fieldProps={{
            disabled,
            value: objectName,
            placeholder: t("MATCH_RULE_CARD_HEADER_PLACEHOLDER"),
            onChange: setObjectName,
            dataTestId: `header-condition-input-${index}`,
          }}
          errorMessage={
            !objectName.length
              ? t("ERROR_MATCH_RULE_CARD_HEADER_MISSING_VALUE")
              : undefined
          }
          dataTestId={`header-condition-${index}`}
        />
      )}
      {operatorList && (
        <PropertyDefinitionCard
          divider
          required
          title={t("MATCH_RULE_CARD_SELECT_OPERATION_TITLE")}
          fieldType={FieldType.DropdownWithCheckboxType}
          fieldProps={{
            disabled,
            items: operatorList,
            onCheckBoxChange: setOperatorSensitive,
            checkboxValue: operatorSensitive,
            label: t("MATCH_RULE_CARD_SELECT_OPERATION_CHECKBOX_LABEL"),
            onSelect: setOperator,
            dataTestId: `condition-operator-dropdown-${index}`,
          }}
        />
      )}
      <PropertyDefinitionCard
        required
        title={t("MATCH_RULE_CARD_EXPECTED_VALUE_TITLE")}
        additionalInfoTitle={t(
          "MATCH_RULE_CARD_EXPECTED_VALUE_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "MATCH_RULE_CARD_EXPECTED_VALUE_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.InputFieldType}
        fieldProps={{
          disabled,
          value: expectedValue,
          placeholder: t("MATCH_RULE_CARD_EXPECTED_VALUE_PLACEHOLDER"),
          onChange: setExpectedValue,
          dataTestId: `condition-expected-value-input-${index}`,
        }}
        errorMessage={localizedErrors![Fields.EXPECTED_VALUE]}
      />
    </>
  );
};
