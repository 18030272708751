import { useCallback, createRef } from "react";
import { MenuItem } from "../../components/molecules/AnchorMenu/AnchorMenu";

export interface IItemNames {
  name: string;
  value?: string;
  id: string;
  subItems?: IItemNames[];
}

export interface IUseAnchorMenu {
  itemNames: IItemNames[];
}

export const useAnchorMenu = ({ itemNames }: IUseAnchorMenu): MenuItem[] => {
  const buildMenuItem = useCallback((items: IItemNames[]): MenuItem[] => {
    return items.map((item) => ({
      name: item.name,
      value: item.value,
      id: item.id,
      ref: createRef(),
      subItems:
        item.subItems && item.subItems.length > 0
          ? buildMenuItem(item.subItems)
          : [],
    }));
  }, []);

  return buildMenuItem(itemNames);
};
