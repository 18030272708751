import {
  ICustomLogDataLiteral,
  ICustomLogDataVariable,
} from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { VariableExpression } from "./types";

export const getVariableNameSuffix = (variableName: string): string | false => {
  const isReqHeader = variableName.startsWith(VariableExpression.REQ_HEADER);
  const reqheader = variableName.substring(
    VariableExpression.REQ_HEADER.length
  );
  const isRespHeader = variableName.startsWith(VariableExpression.RESP_HEADER);
  const respHeader = variableName.substring(
    VariableExpression.RESP_HEADER.length
  );

  return isReqHeader ? reqheader : isRespHeader ? respHeader : false;
};

export const isOfTypeVariable = (entry: any): entry is ICustomLogDataVariable =>
  entry.variableName !== undefined;

export const getVariableName = (
  entry: ICustomLogDataLiteral | ICustomLogDataVariable
): string | undefined => {
  if (isOfTypeVariable(entry)) {
    return entry.variableName;
  } else {
    return undefined;
  }
};

export const getVariablePrefix = (
  entry: ICustomLogDataLiteral | ICustomLogDataVariable
): VariableExpression | undefined => {
  const variableName = getVariableName(entry);
  if (variableName) {
    if (variableName.startsWith(VariableExpression.REQ_HEADER)) {
      return VariableExpression.REQ_HEADER;
    } else if (variableName.startsWith(VariableExpression.RESP_HEADER)) {
      return VariableExpression.RESP_HEADER;
    } else {
      return variableName as VariableExpression;
    }
  }
};
