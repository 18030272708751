import { ComponentProps, ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, Icons } from "../Icon/Icon";

export interface EditOrViewIconProps
  extends Omit<ComponentProps<typeof StyledIcon>, "name" | "color"> {
  isViewMode: boolean;
}

export const EditOrViewIcon = ({
  isViewMode,
  ...rest
}: EditOrViewIconProps): ReactElement => {
  return (
    <StyledIcon
      {...rest}
      name={isViewMode ? Icons.EYE : Icons.EDIT}
      color="secondary"
    />
  );
};

const StyledIcon = styled(Icon)`
  height: 1em;
  margin-left: 16px;
  cursor: pointer;
`;
