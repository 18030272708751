import styled from "@emotion/styled";
import { ReactNode } from "react";

export const RowInfoDetailsWithOwner = ({
  title,
  value,
  owner,
}: {
  title: string;
  value: ReactNode;
  owner: string;
}) => (
  <InfoDisplay>
    <InfoField>{title}</InfoField>
    <InfoValue>
      {value || " "}
      {" by " + owner}
    </InfoValue>
  </InfoDisplay>
);

const InfoDisplay = styled.div``;

const InfoField = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.text.primary};
`;
