import { ReactElement, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { CMv3APIError, APIError } from "../../../models/error";
import { UserRoles } from "../../../models/permissions";
import { ConfigurationNameModalContent } from "../../../modules/caching/configurations/configuration_page/ConfigurationNameModal";
import { DeploymentModal } from "../../../modules/caching/configurations/deployment";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { Badge } from "../../atoms/Badge/Badge";
import { Button } from "../../atoms/Button/Button";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { Modal } from "../../atoms/Modal/Modal";
import { Protected } from "../../atoms/Protected/Protected";
import { ConfigurationHeaderVersionWarning } from "../../molecules/ConfigurationHeaderVersionWarning/ConfigurationHeaderVersionWarning";
import { InputField } from "../../molecules/InputField/InputField";
import { NamingModalContent } from "../../molecules/NamingModal/NamingModal";
import { PresentationModal } from "../../molecules/PresentationModal/PresentationModal";
import { Dropdown } from "../Dropdown/Dropdown";
import { IRoute, Tabs } from "../Tabs/Tabs";
import {
  useConfigurations,
  useIsLatestVersionConfig,
  useIsProductionDeployed,
  useSelectedConfiguration,
} from "../../../store/slices/caching/hooks";
import { useAppDispatch } from "../../../store/types";
import { clearSelectedConfiguration } from "../../../store/slices/caching/slice";
import {
  handleDiscardConfigurationChanges,
  handleSaveSelectedConfiguration,
} from "../../../store/slices/caching/thunks";
import { submitConfiguration } from "../../../store/slices/caching/services";
import {
  ConfigurationType,
  IIsDeployedData,
} from "../../../store/slices/caching/types";
import { getDefinitionsLength } from "../../../store/slices/caching/helpers/getDefinitionsLength";
import { getIsLocalCreated } from "../../../store/slices/caching/helpers/getIsLocalCreated";
import { SchemaVersionUpgradeInfo } from "../../molecules/SchemaVersionUpgradeInfo/SchemaVersionUpgradeInfo";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";

enum DropdownItemValues {
  DISCARD,
  RAW_JSON,
  SAVE_AS,
}

export const ConfigurationHeader = (): ReactElement => {
  const dispatch = useAppDispatch();

  const selectedConfiguration = useSelectedConfiguration();
  const isLatestVersionConfig = useIsLatestVersionConfig();

  const configurations = useConfigurations();

  const handleMediaPortalError = useDispatchAPIError();

  const deploymentData = useIsProductionDeployed(
    selectedConfiguration?.configName ?? ""
  );

  const history = useHistory();

  const [tHeader] = useTranslation("header");
  const [tConfigurationPage] = useTranslation("configurationPage");
  const [tConfigurationPropertyPage] = useTranslation(
    "configurationPropertyPage"
  );
  const { t } = useTranslation("configurationsPage");
  const [tMisc] = useTranslation("misc");

  const NoteErrors = {
    TOO_LONG: t("CONFIGURATION_NOTE_TOO_LONG"),
  };

  const [noteError, setNoteError] = useState<
    typeof NoteErrors[keyof typeof NoteErrors]
  >();

  const onCommentChange = (comment: string) => {
    if (selectedConfiguration) {
      if (comment.length > 200) {
        setNoteError(NoteErrors.TOO_LONG);
      } else {
        setNoteError(undefined);
        dispatch(
          handleSaveSelectedConfiguration(
            { ...selectedConfiguration, comment },
            true
          )
        );
      }
    }
  };

  const tabList = useMemo<IRoute[]>(() => {
    return [
      {
        route: "properties",
        label: tHeader("CONFIGURATION_PROPERTIES", {
          count: Object.keys(
            selectedConfiguration?.config?.propertyDefinitions || {}
          ).length,
        }),
      },
      {
        route: "versions",
        label: tHeader("CONFIGURATION_VERSIONS", {
          count: selectedConfiguration?.versions.length || 0,
        }),
      },
      {
        route: "definitions",
        label: tHeader("CONFIGURATION_DEFINITIONS", {
          count:
            ((selectedConfiguration?.config &&
              getDefinitionsLength(selectedConfiguration.config)) ||
              0) +
            (selectedConfiguration?.config?.matchLogicDefinitions.length || 0),
        }),
      },
    ];
  }, [
    selectedConfiguration?.versions.length,
    selectedConfiguration?.config?.propertyDefinitions.length,
    selectedConfiguration?.config &&
      getDefinitionsLength(selectedConfiguration.config),
  ]);

  const initialLocation = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );

  const initialTab = tabList.find((tab) => tab.route === initialLocation);

  const [configurationTab, setConfigurationTab] = useState<IRoute>(
    initialTab || tabList[0]
  );

  useEffect(() => {
    const splittedPath = history.location.pathname.split("/");
    if (splittedPath.length === 5) {
      setConfigurationTab(
        tabList.find((tab) => tab.route === splittedPath[4]) || tabList[0]
      );
    }
  }, [history.location, tabList]);

  useEffect(() => history.push(`${configurationTab.route}`), [
    configurationTab,
    history,
  ]);

  const [openSaveAsModal, setOpenSaveAsModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);

  let currentIndex = tabList.findIndex(
    (tab) => tab.route === configurationTab.route
  );

  currentIndex = currentIndex === -1 ? 0 : currentIndex;

  return (
    <ConfigurationHeaderContainer>
      <Header>
        <LeftSection>
          <TitleContainer>
            <IconWrapper data-testid="configuration-header-go-back-button">
              <Icon
                name={Icons.ARROW_LEFT}
                onClick={() => {
                  history.push("/cmv3/configurations");
                  dispatch(clearSelectedConfiguration());
                }}
              />
            </IconWrapper>
            <label
              className="-text--h3"
              data-testid="configuration-header-config-name"
            >
              {selectedConfiguration?.configName || ""}
            </label>
            {selectedConfiguration && getIsLocalCreated(selectedConfiguration) && (
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <EditIcon
                  name={Icons.EDIT}
                  onClick={() => {
                    setOpenRenameModal(true);
                  }}
                  size={14}
                />
              </Protected>
            )}
          </TitleContainer>
        </LeftSection>
        {selectedConfiguration && (
          <RightSection>
            <ButtonsContainer>
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                {selectedConfiguration?.isLocalDraft ? (
                  <Badge
                    label={tConfigurationPage(
                      "CONFIGURATION_DRAFT_WARNING_LABEL"
                    )}
                    helpText={tConfigurationPage(
                      "CONFIGURATION_DRAFT_WARNING_HELPER_TEXT"
                    )}
                    helpPosition="bottom"
                    color="yellow40"
                    dataTestId="configuration-header-changes-badge"
                  />
                ) : null}
                <Dropdown
                  items={[
                    {
                      label: tConfigurationPage(
                        "CONFIGURATION_HEADER_DROPDOWN_SAVE_AS"
                      ),
                      value: DropdownItemValues.SAVE_AS,
                      dataTestId: "configuration-header-save-as-button",
                    },
                    {
                      label: tConfigurationPropertyPage("JSON_EDITOR_BUTTON"),
                      value: DropdownItemValues.RAW_JSON,
                      dataTestId: "configuration-header-raw-json-button",
                    },
                  ].concat(
                    selectedConfiguration?.isLocalDraft
                      ? [
                          {
                            label: tConfigurationPage(
                              "CONFIGURATION_HEADER_DROPDOWN_DISCARD"
                            ),
                            value: DropdownItemValues.DISCARD,
                            dataTestId: "configuration-header-discard-button",
                          },
                        ]
                      : []
                  )}
                  id="configuratoni-header-actions"
                  placeholder=""
                  onSelect={(item) => {
                    switch (item.value) {
                      case DropdownItemValues.RAW_JSON:
                        history.push(
                          `/cmv3/configurations/${selectedConfiguration?.configName}/raw-json`
                        );
                        break;
                      case DropdownItemValues.SAVE_AS:
                        setOpenSaveAsModal(true);
                        break;
                      case DropdownItemValues.DISCARD:
                        setOpenDiscardModal(true);
                        break;
                    }
                  }}
                  customButton={
                    <DropdownButtonContainer>
                      <Icon
                        name={Icons.TRIPLE_DOT}
                        color="secondary"
                        size={{ width: 20, height: 5 }}
                        dataTestId="configuration-header-action-dropdown"
                      />
                    </DropdownButtonContainer>
                  }
                />
                <Button
                  onClick={() => {
                    if (selectedConfiguration) {
                      submitConfiguration(selectedConfiguration)
                        .then(
                          ({
                            versions,
                            versionId,
                            isLocalDraft,
                            modifiedTime,
                            genSchemaVersion,
                          }) => {
                            const newConfig: ConfigurationType = {
                              ...selectedConfiguration,
                              versions,
                              versionId,
                              isLocalDraft,
                              modifiedTime,
                              schemaVersion:
                                genSchemaVersion ??
                                selectedConfiguration.schemaVersion,
                            };

                            if (newConfig.config) {
                              newConfig.config = {
                                ...newConfig.config,
                                genSchemaVersion:
                                  genSchemaVersion ??
                                  newConfig.config.genSchemaVersion,
                              };
                            }

                            dispatch(
                              handleSaveSelectedConfiguration(newConfig, false)
                            );
                            toast.success(
                              `${tConfigurationPage(
                                "CONFIGURATION_SUBMIT_SUCCESS",
                                { id: versionId }
                              )}`
                            );
                          }
                        )
                        .catch((err) => {
                          const error = err as APIError<CMv3APIError>;
                          handleMediaPortalError(error);
                        });
                    }
                  }}
                  label={tConfigurationPage("CONFIGURATION_SUBMIT_BUTTON")}
                  backgroundColor="baseLight"
                  textColor="highlight"
                  borderColor="highlight"
                  dataTestId="configuration-header-submit-button"
                  disabled={!selectedConfiguration.isLocalDraft}
                />
                <DeploymentModal
                  configuration={selectedConfiguration}
                  openButtonProps={{
                    label: tConfigurationPage("CONFIGURATION_DEPLOY_BUTTON"),
                    dataTestId: "configuration-header-deploy-button",
                  }}
                  onError={handleMediaPortalError}
                />
              </Protected>
            </ButtonsContainer>

            <Protected permissions={UserRoles.EDIT_CONFIG}>
              <NoteInput
                onChange={onCommentChange}
                hasBorder
                value={selectedConfiguration?.comment}
                placeholder={tConfigurationPage(
                  "CONFIGURATION_SUBMIT_NOTE_INPUT_PLACEHOLDER"
                )}
              />
              {noteError && <Error>{noteError}</Error>}
            </Protected>
          </RightSection>
        )}
      </Header>
      <ConfigurationHeaderVersionWarning />
      <Infos>
        <FlexBox>
          <Info>
            <Label>{tConfigurationPage("CONFIGURATION_VERSION_LABEL")}</Label>
            <Value data-testid="configuration-header-version">
              {tConfigurationPage("CONFIGURATION_VERSION_VALUE", {
                version:
                  selectedConfiguration?.versionId === undefined
                    ? ""
                    : selectedConfiguration?.versionId,
              })}
            </Value>
          </Info>
          <Info>
            <Label>{tConfigurationPage("CONFIGURATION_STATE_LABEL")}</Label>
            <ConfigurationBadge
              deploymentData={deploymentData}
              selectedConfiguration={selectedConfiguration}
            />
          </Info>
          <Info>
            <Label>
              {tConfigurationPage("CONFIGURATION_CREATED_ON_LABEL")}
            </Label>
            <Value>
              {selectedConfiguration
                ? format(new Date(selectedConfiguration.createdTime), "PPPppp")
                : ""}
            </Value>
          </Info>
          <Info>
            <Label>Schema Version</Label>
            <Value>{selectedConfiguration?.schemaVersion}</Value>
          </Info>
        </FlexBox>
        {selectedConfiguration && !isLatestVersionConfig && (
          <SchemaVersionUpgradeInfo />
        )}
      </Infos>
      <Tabs
        onSelectionChange={setConfigurationTab}
        tabsList={tabList}
        initialIndex={currentIndex}
      />
      <Modal
        open={openSaveAsModal}
        customButton={<></>}
        onClose={() => {
          setOpenSaveAsModal(false);
        }}
      >
        <ConfigurationNameModalContent isRenaming />
      </Modal>
      <Modal
        open={openRenameModal}
        customButton={<></>}
        onClose={() => {
          setOpenRenameModal(false);
        }}
      >
        <NamingModalContent
          title={tConfigurationPage("CONFIGURATION_RENAME_MODAL_TITLE")}
          description={tConfigurationPage(
            "CONFIGURATION_RENAME_MODAL_DESCRIPTION"
          )}
          id="rename-configuration"
          cancelButtonProps={{
            label: tMisc("CANCEL"),
          }}
          saveButtonProps={{
            label: tMisc("CONFIRM"),
            onClick: (configName: string) => {
              if (selectedConfiguration) {
                dispatch(
                  handleSaveSelectedConfiguration(
                    { ...selectedConfiguration, configName },
                    true
                  )
                );
                toast.success(
                  tConfigurationPage("CONFIGURATION_RENAME_MODAL_SUCCESS")
                );
                history.push(`/cmv3/configurations/${configName}/properties`);
              }
            },
          }}
          inputPlaceholder={t("ADD_CONFIGURATION_FORM_INPUT_PLACEHOLDER")}
          checkErrors={(name: string) => {
            if (configurations?.some((config) => config.configName === name)) {
              return [t("CONFIGURATION_FORM_DUPLICATE_ERROR")];
            } else if (name.includes(" ")) {
              return [t("CONFIGURATION_FORM_SPACE_ERROR")];
            } else {
              return [];
            }
          }}
        />
      </Modal>
      <Modal
        open={openDiscardModal}
        customButton={<></>}
        onClose={() => {
          setOpenDiscardModal(false);
        }}
      >
        <PresentationModal
          title={tConfigurationPage("CONFIGURATION_MODAL_DISCARD_TITLE")}
          button={{ label: tMisc("DISCARD") }}
          onSubmit={() => {
            if (selectedConfiguration) {
              dispatch(
                handleDiscardConfigurationChanges(selectedConfiguration)
              );
              history.push("/cmv3/configurations");
            }
          }}
        >
          <DiscardModalContent>
            {tConfigurationPage("CONFIGURATION_MODAL_DISCARD_CONTENT")}
          </DiscardModalContent>
        </PresentationModal>
      </Modal>
    </ConfigurationHeaderContainer>
  );
};

const ConfigurationBadge = ({
  deploymentData,
  selectedConfiguration,
}: {
  deploymentData?: IIsDeployedData;
  selectedConfiguration?: ConfigurationType;
}) => {
  const [tConfigurationPage] = useTranslation("configurationPage");
  if (!deploymentData || !selectedConfiguration) {
    return <></>;
  }
  if (
    deploymentData.current &&
    deploymentData.current === selectedConfiguration.versionId
  ) {
    return (
      <Badge
        label={tConfigurationPage("CONFIGURATION_STATE_VALUE_CURRENT")}
        color="green60"
        dataTestId="configuration-state-value-current"
      />
    );
  }
  if (
    deploymentData.candidate &&
    deploymentData.candidate === selectedConfiguration.versionId
  ) {
    return (
      <Badge
        label={tConfigurationPage("CONFIGURATION_STATE_VALUE_CANDIDATE")}
        color="cyan40"
        dataTestId="configuration-state-value-candidate"
      />
    );
  }
  return (
    <Badge
      label={tConfigurationPage("CONFIGURATION_STATE_VALUE_STORED")}
      color="pink70"
      dataTestId="configuration-state-value-stored"
    />
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConfigurationHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};

  padding: ${({ theme }) =>
    `1rem ${theme.spacings.pageContainer} 0 ${theme.spacings.pageContainer}`};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightSection = styled.div``;

const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const IconWrapper = styled.div`
  margin: 1rem 8px 1rem 0;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-right: 25px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.text.secondary};
`;

const Value = styled.div`
  font-weight: 600;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  & > * {
    margin-left: 16px;
  }
`;

const NoteInput = styled(InputField)`
  margin-top: 8px;
  min-width: 20em;
`;

const DropdownButtonContainer = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditIcon = styled(Icon)`
  margin-left: 16px;
  fill: ${({ theme }) => theme.text.muted};
  cursor: pointer;
`;

const DiscardModalContent = styled.div`
  padding: 3px;
`;

const Error = styled.span`
  color: ${({ theme }) => `${theme.text.error}`};
  font-size: 14px;
  padding: 8px;
`;
