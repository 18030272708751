import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../atoms/Button/Button";
import { Card } from "../../atoms/Card/Card";

interface ModalBodyProps {
  title: string;
  submitButton?: {
    label: string;
    onSubmit: () => void;
  };
  children: React.ReactNode;
  onClose: () => void;
  dataTestId?: string;
}

export const ModalBody = ({
  children,
  title,
  submitButton,
  onClose,
  dataTestId,
}: ModalBodyProps) => {
  const [t] = useTranslation("misc");

  return (
    <ModalBodyWrapper>
      <h2>{title}</h2>
      {children}
      <ModalBodyFooter>
        <Button
          label={t("CANCEL")}
          backgroundColor="baseLight"
          textColor="primary"
          borderColor="mutedLight"
          onClick={onClose}
          dataTestId={`${dataTestId}-close`}
        />
        {submitButton && (
          <Button
            label={submitButton.label}
            onClick={submitButton.onSubmit}
            dataTestId={`${dataTestId}-submit`}
          />
        )}
      </ModalBodyFooter>
    </ModalBodyWrapper>
  );
};

const ModalBodyWrapper = styled(Card)`
  & > div:not(:last-child),
  & > h2 {
    margin-bottom: 24px;
  }
`;

const ModalBodyFooter = styled.div`
  display: flex;
  justify-content: end;
  & > button:not(:last-child) {
    margin-right: 8px;
  }
`;
