import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { IFromMatchRule } from "../../../@types/matchRules";
import { useQuery } from "../../../hooks/use-query";
import { MatchBlocks } from "../../../models/configuration/definitions/matchlogic";
import { ArrowLeftIcon } from "../../atoms/ArrowLeftIcon/ArrowLeftIcon";
import { IRoute, Tabs } from "../Tabs/Tabs";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import {
  FOCUS_FEATURE_QUERY,
  MODAL_QUERY,
} from "../../../modules/caching/configurations/configuration_page/constants";
import { getQueryParams } from "../../../utils/getQueryParams";
import { Protected } from "../../atoms/Protected/Protected";
import { UserRoles } from "../../../models/permissions";
import { Button } from "../../atoms/Button/Button";
import { submitConfiguration } from "../../../store/slices/caching/services";
import { ConfigurationType } from "../../../store/slices/caching/types";
import { useAppDispatch } from "../../../store/types";
import { handleSaveSelectedConfiguration } from "../../../store/slices/caching/thunks";
import { handleAPIError } from "../../../store/slices/api-error/thunks";
import { APIError } from "../../../models/error";
import { EditionModes } from "../../../modules/caching/configurations/configuration_page/match_rules/match_rules_page/Conditions";

export interface MatchRulesProps {
  propertyName: string;
}

export enum MatchRulesLocations {
  CONDITIONS = "conditions",
  FEATURES = "features",
}

export const MatchRulesHeader = ({
  propertyName,
}: MatchRulesProps): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("configurationMatchRulesPage");

  const selectedConfiguration = useSelectedConfiguration();

  const [matchBlockQueried] = useQuery("matchBlock");
  const [matchGroupQueried] = useQuery<MatchBlocks>("matchGroup");
  const [matchRuleQueried] = useQuery("matchRule");
  const [editionModeQueried] = useQuery("editionMode");
  const [matchRuleLocation] = useQuery("matchRulelocation");

  const tabList: IRoute[] = [
    { route: MatchRulesLocations.CONDITIONS, label: "Conditions" },
    { route: MatchRulesLocations.FEATURES, label: "Features" },
  ];

  const [currentTab, setCurrentTab] = useState<IRoute>(
    tabList.find((tab) => tab.route === matchRuleLocation) ?? tabList[0]
  );

  useEffect(() => {
    history.push(
      `${location.pathname}` +
        `?matchBlock=${matchBlockQueried}` +
        `&matchGroup=${matchGroupQueried}` +
        `&matchRule=${matchRuleQueried}` +
        `&matchRulelocation=${currentTab.route}` +
        `&editionMode=${editionModeQueried || EditionModes.UI}` +
        `&${MODAL_QUERY}=${getQueryParams(location)
          .get(MODAL_QUERY)
          ?.toString()}` +
        `&${FOCUS_FEATURE_QUERY}=${getQueryParams(location)
          .get(FOCUS_FEATURE_QUERY)
          ?.toString()}`
    );
  }, [currentTab, matchGroupQueried, matchBlockQueried, matchRuleQueried]);

  return (
    <Container>
      <Header>
        <TitleContainer>
          <ArrowLeftIcon
            dataTestId="match-rule-arrow-nav-back"
            onClick={() =>
              history.push(
                `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${propertyName}`,
                {
                  fromMatchRule: {
                    matchBlock: matchBlockQueried,
                    matchGroup: Number(matchGroupQueried),
                    matchRule: matchRuleQueried,
                  },
                } as IFromMatchRule
              )
            }
          />
          <Title>
            {location.pathname.includes("/create") ? "Create" : "Edit"} Match
            Rules
          </Title>
        </TitleContainer>
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          <Button
            onClick={() => {
              if (selectedConfiguration) {
                submitConfiguration(selectedConfiguration)
                  .then(
                    ({
                      versions,
                      versionId,
                      isLocalDraft,
                      modifiedTime,
                      genSchemaVersion,
                    }) => {
                      const newConfig: ConfigurationType = {
                        ...selectedConfiguration,
                        versions,
                        versionId,
                        isLocalDraft,
                        modifiedTime,
                        schemaVersion:
                          genSchemaVersion ??
                          selectedConfiguration.schemaVersion,
                      };

                      if (newConfig.config) {
                        newConfig.config = {
                          ...newConfig.config,
                          genSchemaVersion:
                            genSchemaVersion ??
                            newConfig.config.genSchemaVersion,
                        };
                      }

                      dispatch(
                        handleSaveSelectedConfiguration(newConfig, false)
                      );
                      toast.success(
                        `${t("CONFIGURATION_SUBMIT_SUCCESS", {
                          id: versionId,
                        })}`
                      );
                    }
                  )
                  .catch((err) => {
                    dispatch(handleAPIError(err as APIError));
                  });
              }
            }}
            label={t("CONFIGURATION_SUBMIT_BUTTON")}
            backgroundColor="baseLight"
            textColor="highlight"
            borderColor="highlight"
            dataTestId="match-rules-header-submit-button"
            disabled={!selectedConfiguration?.isLocalDraft}
          />
        </Protected>
      </Header>
      <Tabs
        tabsList={tabList}
        onSelectionChange={setCurrentTab}
        initialIndex={tabList.findIndex(
          (tab) => tab.route === currentTab.route
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: ${({ theme }) =>
    `1rem ${theme.spacings.pageContainer} 0 ${theme.spacings.pageContainer}`};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px;
  line-height: 40px;
`;
