import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = <T = string>(
  searchedKey: string,
  callback?: (value: string) => T
): [T | undefined, string] => {
  const location = useLocation();
  const [value, setValue] = useState<T | undefined>(() => {
    const queries: any = qs.parse(location.search.substring(1));
    if (queries[searchedKey]) {
      return callback ? callback(queries[searchedKey]) : queries[searchedKey];
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    const queries: any = qs.parse(location.search.substring(1));
    if (queries[searchedKey]) {
      setValue(
        callback ? callback(queries[searchedKey]) : queries[searchedKey]
      );
    } else {
      setValue(undefined);
    }
  }, [location, searchedKey, callback]);

  return [value, searchedKey];
};
