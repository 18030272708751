import type { ReactElement } from "react";
import styled from "@emotion/styled";
import type { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CustomRemark } from "../../../../../../../../../components/atoms/CustomRemark/CustomRemark";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";

interface CacheKeyFeatureBlockProps {
  datatestId: string;
  errors: ValidationErrors;
  initIsOpen: boolean;
  maxWidth: number;
  onDelete: () => void;
  register: UseFormRegister<IFeatures>;
}

export const CacheKeyFeatureBlock = ({
  datatestId,
  errors,
  initIsOpen,
  maxWidth,
  onDelete,
  register,
}: CacheKeyFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_CACHE_KEY_TITLE")}
      additionalInfoTitle={t("FEATURE_CARD_ADDED_CACHE_KEY_DESCRIPTION_TITLE")}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_CACHE_KEY_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_CACHE_KEY_VALUE_TITLE")}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <HeadlessInputField
                  disabled={isViewMode}
                  placeholder={t("FEATURE_CARD_CACHE_KEY_INPUT_PLACEHOLDER")}
                  {...register("cacheKey", {
                    required: tMisc("ERROR_REQUIRED_FIELD"),
                  })}
                  data-testid="cache-key-input"
                />
              ),
            }}
            errorMessage={errors.cacheKey?.message}
            maxWidth={maxWidth}
            dataTestId="cache-key-input"
          />
          <PropertyDefinitionCard
            // White space is on purpose
            title=" "
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <Description>
                  <CustomRemark>
                    {t("FEATURE_CARD_CACHE_KEY_VALUE_DESCRIPTION_TITLE")}
                  </CustomRemark>
                </Description>
              ),
            }}
            dataTestId="cache-key-input"
          />
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName="cacheKey"
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="cache-key"
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};

const Description = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;
