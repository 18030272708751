import { TypeDefinitions } from "../../../../models/configuration/definitions/definition";
import { getGeneratedResponseDefinitionDescription } from "../../../../models/configuration/definitions/generatedResponse";
import { getHeaderDefinitionDescription } from "../../../../models/configuration/definitions/header";
import { getLuaDefinitionDescription } from "../../../../models/configuration/definitions/lua";
import { getMatchLogicDefinitionDescription } from "../../../../models/configuration/definitions/matchlogic";
import { getOriginDefinitionDescription } from "../../../../models/configuration/definitions/origin";
import { getPropertyDefinitionDescription } from "../../../../models/configuration/definitions/property";
import { getReportingOverrideDefinitionDescription } from "../../../../models/configuration/definitions/reportingOverride";
import { getSimpleDefinitionDescription } from "../../../../models/configuration/definitions/simpleDefinition";
import { getTokenDefinitionDescription } from "../../../../models/configuration/definitions/token";
import {
  ConfigurationDefinition,
  GeneratedResponseDefinitionType,
  HeaderDefinitionType,
  LuaDefinitionType,
  MatchLogicDefinitionType,
  OriginDefinitionType,
  PropertyDefinitionType,
  ReportingOverrideDefinitionType,
  SimpleDefinitionType,
  TokenDefinitionType,
} from "../types";

export const getDefinitionDescription = (
  def: ConfigurationDefinition
): string => {
  switch (def.type) {
    case TypeDefinitions.GENERATED_RESPONSE:
      return getGeneratedResponseDefinitionDescription(
        def as GeneratedResponseDefinitionType
      );

    case TypeDefinitions.HEADER:
      return getHeaderDefinitionDescription(def as HeaderDefinitionType);

    case TypeDefinitions.LUA:
      return getLuaDefinitionDescription(def as LuaDefinitionType);

    case TypeDefinitions.MATCH_LOGIC:
      return getMatchLogicDefinitionDescription(
        def as MatchLogicDefinitionType
      );

    case TypeDefinitions.ORIGIN:
      return getOriginDefinitionDescription(def as OriginDefinitionType);

    case TypeDefinitions.PROPERTY:
      return getPropertyDefinitionDescription(def as PropertyDefinitionType);

    case TypeDefinitions.REPORTING_OVERRIDE:
      return getReportingOverrideDefinitionDescription(
        def as ReportingOverrideDefinitionType
      );

    case TypeDefinitions.SIMPLE_DEFINITION:
      return getSimpleDefinitionDescription(def as SimpleDefinitionType);

    case TypeDefinitions.TOKEN:
      return getTokenDefinitionDescription(def as TokenDefinitionType);

    default:
      return "";
  }
};
