import { Status } from "../../../@types/status";
import { useAppDispatch, useAppSelector } from "../../types";
import { fetchFeatureFlags, RENDER_LOADER } from "./thunks";
import { ExistingFeatures } from "./types";

export const useDispatchFeatureFlags = (): ((
  loading?: typeof RENDER_LOADER
) => void) => {
  const dispatch = useAppDispatch();

  return (loading) => {
    dispatch(fetchFeatureFlags(loading));
  };
};

export const useFeatureFlags = (): {
  featureFlagsInitialized: boolean;
  isFeatureEnabled: (flag: ExistingFeatures) => boolean;
} => {
  const { value } = useAppSelector((store) => store.featureFlags);

  return {
    featureFlagsInitialized: value !== undefined,
    isFeatureEnabled: (flag: ExistingFeatures) =>
      value !== undefined && value[flag] !== undefined,
  };
};

export const useFeatureFlagsStatus = (): Status =>
  useAppSelector((store) => store.featureFlags.status);
