import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { animated, config, useSpring, useTransition } from "react-spring";
import { Icon, Icons } from "../Icon/Icon";

export interface IToggleProps {
  defaultValue?: boolean;
  onClick: (checked: boolean) => void;
}

export const Toggle: React.FC<IToggleProps> = ({
  defaultValue = false,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const diskAnimation = useSpring({
    left: isOpen ? "1.625rem" : "0.125rem",
  });

  const themeTransition = useTransition(isOpen, {
    config: config.stiff,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    onClick && onClick(isOpen);
  }, [isOpen]);

  return (
    <ToggleThemeContainer
      onClick={() => {
        setIsOpen((previousValue) => !previousValue);
      }}
      data-testid="toggle-theme"
      isOpen={isOpen}
    >
      <Disk style={diskAnimation}>
        {themeTransition((styles) => {
          return (
            <IconContainer style={styles}>
              <Icon color="white" size={20} name={Icons.CIRCLE} />
            </IconContainer>
          );
        })}
      </Disk>
    </ToggleThemeContainer>
  );
};

const ToggleThemeContainer = styled(animated.div)<{ isOpen: boolean }>`
  position: relative;
  cursor: pointer;

  border: 0;
  border-radius: 0.75rem;
  line-height: 1.5rem;

  display: inline-block;
  height: 1.5rem;
  width: 3rem;

  margin-bottom: 0;
  margin-right: 0;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.backgrounds.highlight : theme.backgrounds.muted};
`;

const Disk = styled(animated.div)`
  position: absolute;

  border: 0;
  border-radius: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 1.25rem;
  width: 1.25rem;
  top: 0.125rem;

  margin: 0;
`;

const IconContainer = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
`;
