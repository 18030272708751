import { forwardRef, useContext, useEffect, useState } from "react";
import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { animated, Interpolation, SpringValue } from "react-spring";
import { GestureEvents } from "react-with-gesture";

import { ReactComponent as ChevronDownSVG } from "../../../assets/icons/chevron_down.svg";
import { ColorSet, Theme, ThemeContext } from "../../../contexts/themeContext";
import { UserRoles } from "../../../models/permissions";
import { useIsViewMode } from "../../../store/slices/permissions/hooks";
import {
  IDNDItem,
  IItem,
} from "../../organisms/DragAndDropList/useDragAndDrop";
import { AddButton } from "../AddButton/AddButton";
import { EditIcon } from "../EditIcon/EditIcon";
import { FlexBox } from "../FlexBox/FlexBox";
import { Icon, Icons } from "../Icon/Icon";
import { Protected } from "../Protected/Protected";

export interface DNDItemProps {
  item: IDNDItem;
  springStyle: {
    boxShadow: Interpolation<number, string>;
    transform: Interpolation<string, any>;
    zIndex: SpringValue<number>;
  };
  index: number;
  gestureEvents: GestureEvents;
  expandable?: boolean;
  toggleOpen: () => void;
  onAddItem?: (context: IItem<any>["context"]) => void;
  onEditItem?: (titleIndex?: number) => void;
  onDeleteItem?: () => void;
  onResize: () => void;
  openByDefault: boolean;
  dataTestId?: string;
  handleDelete: (currentIndex: number) => void;
}

export const DragAndDropItem = forwardRef<HTMLDivElement, DNDItemProps>(
  (
    {
      index,
      item,
      springStyle,
      gestureEvents,
      expandable = false,
      toggleOpen,
      onEditItem,
      onDeleteItem,
      onResize,
      onAddItem,
      openByDefault,
      dataTestId,
      handleDelete,
    },
    ref
  ) => {
    const { theme } = useContext(ThemeContext);
    const isViewMode = useIsViewMode();

    const { t } = useTranslation("configurationPropertyPage");

    const [isOpen, setIsOpen] = useState(!!item.node.errorMessage);

    useEffect(() => {
      if (item.node.errorMessage) {
        setIsOpen(true);
      }
    }, [item.node.errorMessage]);

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        onResize();
      });
      const dndItemElement = document.querySelector(`#dnd-item-${index}`);
      if (dndItemElement) {
        resizeObserver.observe(dndItemElement);
      }

      return () => {
        if (dndItemElement) {
          resizeObserver.unobserve(dndItemElement);
        }
        resizeObserver.disconnect();
      };
    }, [index, onResize]);

    useEffect(() => {
      toggleOpen();
    }, [isOpen]);

    useEffect(() => {
      if (openByDefault) {
        setIsOpen(true);
      }
    }, [openByDefault]);

    useEffect(() => {
      const timer = setTimeout(() => {
        if (item.node.highlight && item.ref) {
          item.ref.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }, 100);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    const isTitleArray = Array.isArray(item.node.title);

    return (
      <DragAndDropItemWrapper
        id={`dnd-item-${index}`}
        className="dnd-item"
        ref={ref}
        style={springStyle}
        expandable={expandable ? 1 : 0}
        highlight={item.node.highlight ? 1 : 0}
        lineheight={isTitleArray ? 30 : 70}
        isdarkmode={theme === Theme.DARK_MODE}
      >
        <DNDItemWrapper data-testid={dataTestId}>
          <DNDHeader>
            <LeftSection>
              <TopLeftSection>
                {isViewMode ? (
                  <ViewModeTitleContainer>
                    <Index>{item.node.index ?? `${index + 1}.`}</Index>
                  </ViewModeTitleContainer>
                ) : (
                  <GestureZoneWrapper {...gestureEvents}>
                    <SelectorWrapper data-testid={`${dataTestId}-drag-handle`}>
                      <Selector name={Icons.DND} />
                    </SelectorWrapper>
                    <TitleContainer>
                      <Index>{item.node.index ?? `${index + 1}.`}</Index>
                    </TitleContainer>
                  </GestureZoneWrapper>
                )}
                <TitleContainer>
                  {isTitleArray ? (
                    (item.node.title as string[]).map((_t, i) => {
                      return (
                        <TitleContainer key={`${_t}-${i}`}>
                          <div>{_t}</div>
                          {onEditItem && (
                            <EditIcon
                              onClick={() => {
                                onEditItem(i);
                              }}
                              dataTestId={`${dataTestId}-edit-${i}`}
                            />
                          )}
                        </TitleContainer>
                      );
                    })
                  ) : (
                    <TitleContainer>
                      <Title>{item.node.title}</Title>
                      {onEditItem && (
                        <EditIcon
                          onClick={() => onEditItem()}
                          dataTestId={`${dataTestId}-edit`}
                        />
                      )}
                    </TitleContainer>
                  )}
                </TitleContainer>
              </TopLeftSection>
              <ErrorContainer>{item.node.errorMessage}</ErrorContainer>
            </LeftSection>
            <RightSection>
              {onDeleteItem && (
                <Protected permissions={UserRoles.EDIT_CONFIG}>
                  <Icon
                    name={Icons.TRASH}
                    onClick={onDeleteItem}
                    color="secondary"
                    dataTestId={`${dataTestId}-delete`}
                  />
                  {expandable && <Divider />}
                </Protected>
              )}
              {item.node.nestedMenu?.(handleDelete)}
              {expandable && (
                <ChevronDown
                  isopen={isOpen ? 1 : 0}
                  onClick={() => setIsOpen(!isOpen)}
                  data-testid={`${dataTestId}-chevron-expand`}
                />
              )}
            </RightSection>
          </DNDHeader>
          {!expandable && <Subtitle>{item.node.content}</Subtitle>}
          {isOpen && (
            <DNDContent className="dnd-content">{item.node.content}</DNDContent>
          )}
          {expandable && isOpen && onAddItem && (
            <Protected permissions={UserRoles.EDIT_CONFIG}>
              <AddButtonContainer>
                <AddButton
                  onClick={() => onAddItem(item.node.context)}
                  label={t("MATCH_LOGIC_SECTION_ADD_MATCH_RULE")}
                  dataTestId={`${dataTestId}-add-match-rule`}
                />
              </AddButtonContainer>
            </Protected>
          )}
        </DNDItemWrapper>
      </DragAndDropItemWrapper>
    );
  }
);

const fadeInFadeOut = (colorset: ColorSet) => keyframes`
  0%   { background-color: ${colorset.backgrounds.baseLight}; }
  20%  { background-color: ${colorset.backgrounds.highlightLight}; border-color: ${colorset.colors.cyan30} }
  100% { background-color: ${colorset.backgrounds.baseLight}; }
`;

const DragAndDropItemWrapper = styled(animated.div)<{
  expandable: number;
  highlight?: number;
  lineheight: number;
  isdarkmode: boolean;
}>`
  background-color: ${({ expandable, isdarkmode, theme }) =>
    theme.backgrounds[isdarkmode && expandable ? "mutedDark" : "baseLight"]};
  background-image: none !important;
  animation: ${({ highlight, theme }) =>
    highlight
      ? css`
          ${fadeInFadeOut(theme)} 3s ease-in-out
        `
      : undefined};
  color: ${({ theme }) => theme.text.primary};
  padding-left: 32px;
  padding-top: ${({ expandable }) => !expandable && "32px"};
  padding-bottom: ${({ expandable }) => !expandable && "32px"};
  padding-right: 32px;
  position: absolute;
  width: ${({ expandable }) => (expandable ? "100%" : "95%")};
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  border: ${({ expandable, theme }) =>
    !expandable && `1px solid ${theme.borders.mutedLight}`};
  line-height: ${({ lineheight }) => `${lineheight}px`};
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
`;

const DNDItemWrapper = styled.div``;

const DNDContent = styled(animated.div)`
  transform-origin: top;
`;

const DNDHeader = styled(FlexBox)`
  justify-content: space-between;
  align-items: start;
`;

const ViewModeTitleContainer = styled.div`
  margin-right: 8px;
`;

const TitleContainer = styled.div`
  width: 100%;
`;

const Index = styled.span`
  font-weight: 600;
`;

const Title = styled.b`
  line-height: 1em;
  white-space: pre;
`;

const Subtitle = styled.div`
  line-height: 1em;
  white-space: pre;
`;

const SelectorWrapper = styled.div`
  height: 100%;
`;

const Selector = styled(Icon)`
  margin-right: 16px;
  cursor: grab;

  * {
    fill: ${({ theme }) => theme.icon.muted};
  }
`;

const ChevronDown = styled(ChevronDownSVG)<{ isopen: number }>`
  transition: transform 0.2s;
  transform: rotate(${({ isopen }) => (isopen ? "180deg" : "0deg")});
  height: 16px;
  fill: ${({ theme }) => theme.backgrounds.highlight};
  cursor: pointer;
`;

const TopLeftSection = styled.div`
  display: flex;
  align-items: start;
  height: 100%;
  width: 100%;
`;

const LeftSection = styled.div`
  width: 100%;
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.colors.red60};
`;

const RightSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 5rem;
  justify-content: space-between;
`;

const GestureZoneWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  cursor: grab;
  :active {
    cursor: grabbing;
  }
`;

const AddButtonContainer = styled.div`
  line-height: 1.25rem !important;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  width: 2px;
  height: 1rem;
  border-left: 2px solid
    ${({ theme }) => `${theme.borders.highlightLight} !important`};
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
`;
