import { FC } from "react";
import styled from "@emotion/styled";

import { SortOrder } from "../../atoms/HeadlessDataTable/HeadlessDataTable";
import { PointerIcon } from "../../atoms/PointerIcon/PointerIcon";
import { Icons } from "../../atoms/Icon/Icon";

interface SortColumnProps {
  isSorted: boolean;
  sortOrder: SortOrder;
}

export const SortColumn: FC<SortColumnProps> = ({
  children,
  isSorted,
  sortOrder,
}) => (
  <SortLabelContainer>
    <FieldName isActive={isSorted}>{children}</FieldName>
    {isSorted && (
      <SortIcon
        isDown={sortOrder === "asc"}
        name={Icons.ARROW_SLIM}
        color="secondary"
      />
    )}
  </SortLabelContainer>
);

const SortLabelContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SortIcon = styled(PointerIcon)<{ isDown: boolean }>`
  transition: transform 0.2s;
  transform: rotate(${({ isDown }) => (isDown ? "180deg" : "0deg")});
`;

export const FieldName = styled.div<{ isActive: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.text.primary : theme.text.muted};
  margin-right: ${({ isActive }) => (isActive ? 6 : 0)}px;
`;
