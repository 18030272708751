import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";
import { FeatureComponent } from "./types";

type TokenAuthenticationComponent = FeatureComponent<
  Exclude<IFeatures["tokenAuthentication"], undefined>
>;

export const TokenAuthentication: TokenAuthenticationComponent = ({
  feature: { denial, ipWhitelist, name },
  tPropertyPage,
}) => (
  <>
    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_TOKEN_AUTH_TITLE")}
      >
        {name}
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_TOKEN_AUTH_AOD_TITLE")}
      >
        {denial?.action}
      </DetailedFeatureCardBlock>

      {!!(denial.action === "redirect" && denial.redirectUrl) && (
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_TOKEN_AUTH_REDIRECT_TITLE")}
        >
          {denial.redirectUrl}
        </DetailedFeatureCardBlock>
      )}
    </DetailedFeatureCardRow>

    {!!(ipWhitelist && ipWhitelist.length > 0) && (
      <DetailedFeatureCardRow>
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_TOKEN_AUTH_IP_TITLE")}
        >
          <ExpandableList dataTestId="tokenAuthentication">
            {ipWhitelist.map(renderColContent)}
          </ExpandableList>
        </DetailedFeatureCardBlock>
      </DetailedFeatureCardRow>
    )}
  </>
);
