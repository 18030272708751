import { FC } from "react";

import { useConfigurations } from "../../../../store/slices/caching/hooks";
import { ComparisonModal, ComparisonModalProps } from "./ComparisonModal";
import { getConfigurationOptions } from "./helpers";

type PreComparisonModalProps = Omit<
  ComparisonModalProps,
  "configurationOptions"
>;

export const PreComparisonModal: FC<PreComparisonModalProps> = ({
  configuration,
  ...comparisonModalProps
}) => {
  const configurations = useConfigurations();

  const configurationOptions = getConfigurationOptions(
    configurations,
    (c) => c.id === configuration.id
  );

  return (
    <ComparisonModal
      {...comparisonModalProps}
      configuration={configuration}
      configurationOptions={configurationOptions}
    />
  );
};
