import {
  ORIGIN_USE_CLIENT_HOST_VALUE,
  AuthenticationTypes,
  IHeadersAuthentication,
} from "../../../../../models/configuration/definitions/origin";
import {
  PropertySection,
  PropertySubsection,
} from "../../../../../models/configuration/definitions/property";
import {
  IConfigurationError,
  ConfigurationErrorLevel,
  ConfigurationErrorType,
} from "../../../../../models/configuration/errors";
import { getHeadersErrors } from "../../../../../utils/getHeadersErrors";
import { OriginDefinitionType } from "../../types";

export const validateOriginDefinition = (
  def: OriginDefinitionType
): IConfigurationError[] => {
  const errorsToAdd: IConfigurationError[] = [];

  def.members.forEach((member, index) => {
    if (member.origin.host?.length === 0) {
      errorsToAdd.push({
        level: ConfigurationErrorLevel.ERROR,
        type: ConfigurationErrorType.PROPERTY_ORIGIN_HOST_EMPTY,
        data: {
          section: PropertySection.ORIGIN,
          index,
        },
      });
    }

    if (
      member.origin.host === ORIGIN_USE_CLIENT_HOST_VALUE &&
      !member.origin.address?.length
    ) {
      errorsToAdd.push({
        level: ConfigurationErrorLevel.ERROR,
        type: ConfigurationErrorType.PROPERTY_ORIGIN_HOST_ADDRESS_EMPTY,
        data: {
          section: PropertySection.ORIGIN,
          index,
        },
      });
    }

    if (member.authentication?.type === AuthenticationTypes.HEADERS) {
      const headers = member.authentication
        ?.parameters as IHeadersAuthentication[];

      if (getHeadersErrors(headers)) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type:
            ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_EMPTY,
          data: {
            section: PropertySection.ORIGIN,
            subsection: PropertySubsection.ORIGIN,
            authType: AuthenticationTypes.HEADERS,
            index,
          },
        });
      }
    }
  });

  return errorsToAdd;
};
