import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AnchorWrapper } from "../../../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { MenuItem } from "../../../../../../components/molecules/AnchorMenu/AnchorMenu";
import { DefinitionListItem } from "../../../../../../components/molecules/DefinitionListItem/DefinitionListItem";
import { ExpandableContent } from "../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { getMatchLogicReferences } from "../../../../../../store/slices/caching/helpers/match-logic-definition/getMatchLogicReferences";
import { useSelectedConfiguration } from "../../../../../../store/slices/caching/hooks";
import { handleDeleteDefinition } from "../../../../../../store/slices/caching/thunks";
import { useAppDispatch } from "../../../../../../store/types";

export const MatchLogics = ({ menu }: { menu: MenuItem }): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("configurationDefinitionsPage");

  const matchLogicDefinitions =
    selectedConfiguration?.config?.matchLogicDefinitions;

  return (
    <AnchorWrapper id={menu.id} ref={menu.ref}>
      <Title>
        {menu.name} ({matchLogicDefinitions?.length ?? 0})
      </Title>
      <ExpandableContent
        title={t("DEFINITION_CARD_MATCH_LOGIC_DESCRIPTION_TITLE")}
        content={t("DEFINITION_CARD_MATCH_LOGIC_DESCRIPTION_CONTENT")}
      />
      <DefinitionListItem
        onDelete={(definition) => {
          dispatch(handleDeleteDefinition(definition));
        }}
        renderAddModal={() => null}
        items={matchLogicDefinitions}
        renderEditModal={() => null}
        referenceObjectName={{
          singular: "property",
          plural: "properties",
        }}
        forceShowDelete={(definition) =>
          getMatchLogicReferences(
            definition,
            selectedConfiguration!.config!
          ) === 0
        }
        dataTestId="matchLogic"
        customDescription={(definition) => {
          const description = selectedConfiguration?.config?.propertyDefinitions.reduce(
            (acc, item) => {
              if (item.matchLogic === definition.name) {
                acc.push(item.name);
              }
              return acc;
            },
            [] as string[]
          );
          return description?.length ? description.join(", ") : "";
        }}
        parentConfig={selectedConfiguration!.config!}
      />
    </AnchorWrapper>
  );
};

const Title = styled.h3`
  border-top: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`};
  margin: 0 !important;
  padding: 1rem 0;
`;
