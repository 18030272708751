import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { TokenDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createTokenDefinition = (
  name: string,
  rest: Pick<
    TokenDefinitionType,
    | "description"
    | "sharedSecrets"
    | "queryParameterNames"
    | "hash"
    | "nva"
    | "nvb"
    | "datePreference"
    | "queryParameterControl"
  >
): TokenDefinitionType => ({
  ...parseDefinition({
    name,
    description: rest.description,
    type: TypeDefinitions.TOKEN,
  }),
  sharedSecrets: rest.sharedSecrets,
  queryParameterNames: rest.queryParameterNames,
  hash: rest.hash,
  nva: rest.nva,
  nvb: rest.nvb,
  datePreference: rest.datePreference,
  queryParameterControl: rest.queryParameterControl,
});
