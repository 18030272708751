import type { ChangeEventHandler, ReactElement } from "react";
import styled from "@emotion/styled";
import type { UseFormRegister } from "react-hook-form";

import { ErrorContainer } from "../../../../../../../../../../components/atoms/ErrorContainer/ErrorContainer";
import {
  Field,
  FieldInput,
  Remove,
  TableCell,
} from "../../../../../../../../../../components/molecules/DefinitionModal/DefinitionModal.styled";
import type { IFeatures } from "../../../../../../../../../../models/configuration/definitions";

export interface HeadersRowProps {
  disabled: boolean;
  errorMsg?: string;
  headerKey: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  register: UseFormRegister<IFeatures>;
  removeHeader: () => void;
  requiredMsg: string;
  rowIndex: number;
  validate: (value: string) => string | boolean;
}

export const HeadersRow = ({
  disabled,
  errorMsg,
  headerKey,
  onChange,
  register,
  removeHeader,
  requiredMsg,
  rowIndex,
  validate,
}: HeadersRowProps): ReactElement => (
  <Field key={`row-${rowIndex}`}>
    <TableCell rightPadding bottomPadding="thin">
      <HeaderNameContainer>
        <FieldInput
          disabled={disabled}
          placeholder="name"
          data-testid={`deny-policy-header-name-${rowIndex}`}
          {...register(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            `${headerKey}.name`,
            { required: requiredMsg, validate }
          )}
          onChange={onChange}
        />

        {errorMsg && (
          <ErrorContainer
            data-testid={`deny-policy-header-name-${rowIndex}-error`}
          >
            {errorMsg}
          </ErrorContainer>
        )}
      </HeaderNameContainer>
    </TableCell>

    <TableCell leftPadding bottomPadding="thin">
      <FieldInput
        disabled={disabled}
        placeholder="value"
        data-testid={`deny-policy-header-value-${rowIndex}`}
        {...register(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          `${headerKey}.value`,
          { required: requiredMsg }
        )}
      />
    </TableCell>

    {!disabled && (
      <TableCell>
        <Remove
          data-testid={`deny-policy-header-delete-${rowIndex}`}
          onClick={removeHeader}
        />
      </TableCell>
    )}
  </Field>
);

const HeaderNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
