import { FC } from "react";

import { AccessGroups } from "../AccessGroups/AccessGroups";
import { AccessToken } from "../AccessToken/AccessToken";
import { Permissions } from "../Permissions/Permissions";
import { RefreshToken } from "../RefreshToken/RefreshToken";
import { User } from "../User/User";

export const Authentication: FC = ({ children }) => (
  <RefreshToken>
    <AccessToken>
      <AccessGroups>
        <User>
          <Permissions>{children}</Permissions>
        </User>
      </AccessGroups>
    </AccessToken>
  </RefreshToken>
);
