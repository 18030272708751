import { ReactElement } from "react";
import styled from "@emotion/styled";

import { ErrorIcon } from "../../atoms/ErrorIcon/ErrorIcon";
import { InfoIcon } from "../../atoms/InfoIcon/InfoIcon";
import { WarningIcon } from "../../atoms/WarningIcon/WarningIcon";

type InfoCardTheme = "muted" | "warning" | "error";

export interface InfoCardProps {
  message: string;
  theme?: InfoCardTheme;
}

export const InfoCard = ({
  message,
  theme = "muted",
}: InfoCardProps): ReactElement => {
  const Icon =
    theme === "muted" ? (
      <InfoIcon />
    ) : theme === "warning" ? (
      <WarningIcon />
    ) : (
      <ErrorIcon />
    );

  return (
    <CardContainer cardTheme={theme}>
      {Icon}
      <span className="-text--xs">{message}</span>
    </CardContainer>
  );
};

const CardContainer = styled.div<{
  cardTheme: InfoCardTheme;
}>`
  border: ${({ theme }) => `1px ${theme.borders.info} solid`};
  border-radius: 4px;
  text-align: center;
  align-self: center;
  padding: 0.4em;
  background-color: ${({ theme }) => `${theme.backgrounds.base}`};
  color: ${({ cardTheme, theme }) => `${theme.text[cardTheme]}`};

  & > svg {
    width: 16px;
    margin-right: 8px;
    margin-bottom: -2px;
  }
`;
