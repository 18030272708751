import { ReactElement, useCallback, useEffect, useState } from "react";

import { httpClient } from "../../../core/http-client";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { Dropdown } from "../Dropdown/Dropdown";

export interface IBackend {
  label: string;
  value: string;
  default?: boolean;
}

export const BackendSelector = (): ReactElement | null => {
  const [selectedBackend, setSelectedBackend] = useLocalStorage<IBackend>(
    "selectedBackend"
  );

  const [availableBackends, setAvailableBackends] = useState<IBackend[]>([]);

  const fetchAvailableBackends = useCallback<
    () => Promise<{ label: string; url: string }[]>
  >(async () => {
    const response = await fetch(
      "https://lab-multistage-server.netauto.nsatc.net/backends"
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const json = (await response.json()) as {
      data: { label: string; url: string }[];
    };

    return json.data;
  }, []);

  useEffect(() => {
    fetchAvailableBackends()
      .then((backends) =>
        backends.map((backend, idx) => ({
          label: backend.label,
          value: backend.url,
          default:
            (!selectedBackend && idx === 0) ||
            (selectedBackend && backend.label === selectedBackend.label),
        }))
      )
      .then(setAvailableBackends)
      .catch((error) => {
        console.error(error);
      });
  }, [selectedBackend]);

  useEffect(() => {
    if (
      selectedBackend &&
      availableBackends.findIndex(
        ({ label, value }) =>
          selectedBackend.label === label && selectedBackend.value === value
      ) !== -1
    ) {
      httpClient.baseURL = selectedBackend.value;
    }
  }, [selectedBackend, availableBackends]);

  return availableBackends.length ? (
    <Dropdown
      id="backend-selector"
      placeholder="Backend"
      outline={false}
      onSelect={(item) => setSelectedBackend(item)}
      items={availableBackends}
    />
  ) : null;
};
