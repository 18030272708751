import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { Status } from "../../../@types/status";
import { useUserManagementUserStatus } from "../../../store/slices/user-management-users/hooks";
import { fetchUser } from "../../../store/slices/user-management-users/thunk";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";

export const UserManagementUser: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const match = useRouteMatch<{ userId: string }>([
    "/userManagement/users/:userId",
  ]);

  const loadingStatus = useUserManagementUserStatus();

  useEffect(() => {
    if (match?.params.userId) {
      dispatch(fetchUser(parseInt(match.params.userId, 10)));
    }
  }, [match?.params.userId]);

  const { t } = useTranslation("layers");

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("USER_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  return <>{children}</>;
};
