import { cloneElement, FC, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Button, ButtonProps } from "../Button/Button";

export interface IModalChildren {
  closeModal?: () => void;
}

interface IDefaultModalProps {
  openButtonProps?: Omit<ButtonProps, "onClick">;
  customButton?: JSX.Element;
  children: JSX.Element & IModalChildren;
  size?: "auto" | "extra-large" | "large" | "small" | "medium";
  onBlur?: () => void;
  open?: boolean;
  onClose?: () => void;
  overflow?: string;
}

interface ICustomeButtonProps extends IDefaultModalProps {
  customButton: JSX.Element;
}

interface IDefaultButtonProps extends IDefaultModalProps {
  openButtonProps: Omit<ButtonProps, "onClick">;
}

export type IModalProps = ICustomeButtonProps | IDefaultButtonProps;

export const Modal: FC<IModalProps> = ({
  customButton,
  openButtonProps,
  children,
  size = "auto",
  open = false,
  onBlur,
  onClose,
  overflow,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <>
      {customButton ? (
        cloneElement(customButton, {
          onClick: () => setIsOpen(true),
        })
      ) : (
        <Button
          {...(openButtonProps as ButtonProps)}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      )}

      {isOpen && (
        <>
          <ModalBackground />
          <Container
            onClick={(e) => {
              setIsOpen(false);
              onClose && onClose();
              onBlur && onBlur();
            }}
          >
            <ModalContainer
              onClick={(e) => e.stopPropagation()}
              size={size}
              overflow={overflow}
            >
              {cloneElement(children, {
                closeModal: () => {
                  setIsOpen(false);
                  onClose && onClose();
                },
              })}
            </ModalContainer>
          </Container>
        </>
      )}
    </>
  );
};

export const ModalBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99998;
  opacity: 0.75;
  background-color: ${({ theme }) => theme.backgrounds.mutedDark};
`;

export const Container = styled.div<{ overflow?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: ${({ overflow }) => overflow || "auto"};
`;

export const ModalContainer = styled.div<{
  size?: string;
  overflow?: string;
}>`
  display: flex;
  flex-direction: column;

  border-radius: 7px;
  min-width: 300px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: ${({ overflow }) => overflow || "auto"};

  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          width: 20%;
        `;
      case "medium":
        return css`
          width: 30%;
        `;
      case "large":
        return css`
          width: 60%;
        `;
      case "auto":
        return css`
          width: auto;
        `;
      case "extra-large":
        return css`
          width: 90%;
        `;
      default:
        return css`
          width: 40%;
        `;
    }
  }}

  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;
