import { ReactElement } from "react";
import type {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  OriginDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { toItem } from "./helpers";

interface OriginFeatureBlockProps {
  clearErrors: UseFormClearErrors<IFeatures>;
  config: ConfigurationDetailsType;
  datatestId: string;
  errors: ValidationErrors;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  initIsOpen: boolean;
  maxWidth: number;
  onDelete: () => void;
  originFillPolicy: Exclude<IFeatures["originFillPolicy"], undefined>;
  setError: UseFormSetError<IFeatures>;
}

export const OriginFeatureBlock = ({
  clearErrors,
  config,
  datatestId,
  errors,
  handleCreateFeature,
  initIsOpen,
  maxWidth,
  onDelete,
  originFillPolicy,
  setError,
}: OriginFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_ORIGIN_TITLE")}
      additionalInfoTitle={t("FEATURE_CARD_ADDED_ORIGIN_DESCRIPTION_TITLE")}
      additionalInfoContent={t("FEATURE_CARD_ADDED_ORIGIN_DESCRIPTION_CONTENT")}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_ORIGIN_DEFINITION_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_ORIGIN_DEFINITION_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_ORIGIN_DEFINITION_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          maxWidth={maxWidth}
          errorMessage={errors.originFillPolicy?.message}
          fieldProps={{
            other: config.originFillPolicyDefinitions ? (
              <DefinitionDropdown
                id="originFillPolicy"
                disabled={isViewMode}
                type={FeatureType.ORIGIN}
                items={config.originFillPolicyDefinitions.map(toItem)}
                selected={
                  config.originFillPolicyDefinitions.filter(
                    (def) => def.name === originFillPolicy
                  ) as OriginDefinitionType[]
                }
                allowEdit={true}
                allowMultiSelection={false}
                onSelect={(selected) => {
                  handleCreateFeature(
                    "originFillPolicy",
                    selected[0]?.name ?? ""
                  );
                }}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                dataTestId="origin"
                parentConfig={config}
              />
            ) : (
              <>{t("FEATURE_CARD_ORIGIN_DEFINITION_NO_ORIGIN_DEFINITIONS")}</>
            ),
          }}
          dataTestId="origin"
        />
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"originFillPolicy"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="origin"
      featureType={FeatureTypes.ORIGIN_SELECTION}
    />
  );
};
