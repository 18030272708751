import { FC } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DetailedFeatureCardContainer } from "../../atoms/DetailedFeatureCardContainer/DetailedFeatureCardContainer";
import {
  EditOrViewIcon,
  EditOrViewIconProps,
} from "../../atoms/EditOrViewIcon/EditOrViewIcon";
import { FlexBoxBase } from "../../atoms/FlexBox/FlexBox";
import { FontBase, FontTitle } from "../../atoms/Font/Font";

export interface DetailedFeatureCardProps extends EditOrViewIconProps {
  title: string;
  subTitle?: string;
}

const Header = ({
  title,
  subTitle,
  ...iconProps
}: DetailedFeatureCardProps) => (
  <HeaderContainer data-testid={(iconProps.dataTestId ?? "").concat("-header")}>
    <MainTitle subTitle={!!subTitle}>{title}</MainTitle>

    {subTitle && (
      <SubTitle
        data-testid={(iconProps.dataTestId ?? "").concat("-sub-header")}
      >{`- ${subTitle}`}</SubTitle>
    )}

    <EditOrViewIcon className="header-edit-view-icon" {...iconProps} />
  </HeaderContainer>
);

export const DetailedFeatureCard: FC<DetailedFeatureCardProps> = ({
  children,
  ...headerProps
}) => (
  <DetailedFeatureCardContainer>
    <Header {...headerProps} />
    {children}
  </DetailedFeatureCardContainer>
);

export const DetailedFeatureCardBlock: FC<
  Pick<DetailedFeatureCardProps, "title" | "dataTestId">
> = ({ children, dataTestId, title }) => (
  <DetailedFeatureCardCol data-testid={dataTestId}>
    <DetailedFeatureCardColTitle>{title}</DetailedFeatureCardColTitle>
    <DetailedFeatureCardColContent>{children}</DetailedFeatureCardColContent>
  </DetailedFeatureCardCol>
);

export const DetailedFeatureCardRow = styled(FlexBoxBase)`
  &:not(:last-child) {
    padding-bottom: 16px;
  }
`;

export const DetailedFeatureCardCol = styled.div`
  width: 33%;
`;

export const DetailedFeatureCardColTitle = styled(FontBase)`
  color: ${({ theme }) => theme.text.secondary};
  padding-bottom: 8px;
`;

export const DetailedFeatureCardColContent = styled(FontBase)`
  & > div:not(:last-child) {
    padding-bottom: 8px;
  }
`;

const HeaderContainer = styled(FlexBoxBase)`
  & .header-edit-view-icon {
    display: none;
  }

  &:hover .header-edit-view-icon {
    display: block;
  }
`;

const MainTitle = styled(FontTitle)<{ subTitle: boolean }>`
  ${({ subTitle }) =>
    !subTitle &&
    css`
      padding-bottom: 8px;
    `}
`;

const SubTitle = styled(FontBase)`
  margin-left: 8px;
`;
