import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";

import { AppThunk } from "../../store";
import { APIError, ErrorLevel } from "../../../models/error/index";
import {
  initUser,
  exitUserMimick,
  UserData,
  User,
  enterUserMimick,
} from "../../../models/user/index";
import { httpClient } from "../../../core/http-client";
import { setStatus, setUser } from "./slice";
import { setAccessToken, setRefreshToken } from "../auth/slice";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { Status } from "../../../@types/status";
import { handleAPIError } from "../api-error/thunks";

export const handleSetUser = (user: User): AppThunk => (dispatch) => {
  dispatch(setUser(user));
  Sentry.setContext("UserContext", { user });
  Sentry.setUser({ email: user.email });
};

export const loginByAccessToken = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setStatus(Status.LOADING));
    const { data } = await httpClient.get<UserData>("/auth/user/info");
    const user = initUser(data);
    dispatch(handleSetUser(user));
    dispatch(setStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setStatus(Status.ERROR));
    const error = err as AxiosError;
    const mediaPortalError = new APIError(
      error.message,
      "loginByAccessToken()",
      ErrorLevel.ERROR,
      error.response
    );
    dispatch(handleAPIError(mediaPortalError));
  }
};

export const enterMimick = (newUsername: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const { user } = getState();
    const oldRefreshToken = getRefreshToken();
    const { access_token, refresh_token } = await enterUserMimick(
      user.value!,
      newUsername,
      oldRefreshToken
    );

    dispatch(setAccessToken(access_token));
    dispatch(setRefreshToken(refresh_token));
    httpClient.accessToken = access_token;
    dispatch(loginByAccessToken());
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const exitMimick = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const oldRefreshToken = getRefreshToken();
    const { access_token, refresh_token } = await exitUserMimick(
      user.value!,
      oldRefreshToken
    );

    dispatch(setAccessToken(access_token));
    dispatch(setRefreshToken(refresh_token));
    httpClient.accessToken = access_token;
    dispatch(loginByAccessToken());
  } catch (err) {
    const error = err as APIError;
    dispatch(handleAPIError(error));
  }
};
