import { ILocalStoredData } from "../../../../models/configuration";
import { ConfigurationType } from "../types";
import { handleLocalStorageConfiguration } from "./handleLocalStorageConfiguration";
import { prepareConfig } from "./prepareConfig";

export const saveConfigurationsInLocalStorageNew = (
  configurations: ConfigurationType[],
  subscriberId: number
): void => {
  let newConfigs: ILocalStoredData = {};
  const storedConfiguration = handleLocalStorageConfiguration(subscriberId);

  if (
    typeof storedConfiguration === "object" &&
    !Array.isArray(storedConfiguration)
  ) {
    newConfigs = storedConfiguration;
  }

  newConfigs[subscriberId] = configurations
    .filter((config) => config.isLocalDraft)
    .map((config) => prepareConfig(true, config));

  window.localStorage.setItem("cmv3_configs", JSON.stringify(newConfigs));
};
