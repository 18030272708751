import { ReactElement, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { CountryCode, getCountryFromCode } from "../../../utils/countries";
import { countries } from "../../../utils/countries/countries";
import { isDefinitionNameValid } from "../../../utils/string";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { IOption, SearchDropdown } from "../SearchDropdown/SearchDropdown";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  Field,
  FieldInput,
  FieldName,
  Table,
  TableBody,
  TableCell,
} from "../DefinitionModal/DefinitionModal.styled";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { SimpleDefinitionType } from "../../../store/slices/caching/types";
import { createSimpleDefinition } from "../../../store/slices/caching/helpers/simple-definition/createSimpleDefinition";
import { getDefinitionReferences } from "../../../store/slices/caching/helpers/getDefinitionReferences";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";

type GeoBlockingProps = IDefaultDefinitionModalProps<SimpleDefinitionType>;

const sortOptionsByName: (
  options: IOption<CountryCode>[]
) => IOption<CountryCode>[] = (options) => {
  options.sort((opt1, opt2) => {
    const opt1Name = opt1.text;
    const opt2Name = opt2.text;

    // Some country names include special letters which don't work well when we
    // want to sort in English. That's why we use the "localeCompare" method
    // here.
    const compareResult = opt1Name.localeCompare(opt2Name, opt1.value);

    // The "compareResult" value can be any positive value other than "1" in
    // some browsers or vice versa for the negative case. So, we need to
    // explicitly return the numeric value.
    if (compareResult > 0) return 1;
    if (compareResult < 0) return -1;
    return 0;
  });

  return options;
};

const handleOptionsOrder: (
  options: IOption<CountryCode>[],
  cb?: (opt: IOption<CountryCode>) => unknown
) => IOption<CountryCode>[] = (options, cb) => {
  const selectedOptions: typeof options = [];
  const unSelectedOptions: typeof options = [];

  for (const option of options) {
    if (typeof cb !== "undefined") cb(option);

    if (option.selected) {
      selectedOptions.push(option);
    } else {
      unSelectedOptions.push(option);
    }
  }

  const sortedSelectedOptions = sortOptionsByName(selectedOptions);
  const sortedOptions = sortOptionsByName(unSelectedOptions);

  sortedOptions.unshift(...sortedSelectedOptions);

  return sortedOptions;
};

const setInitOptions: (
  definition: SimpleDefinitionType | undefined
) => IOption<CountryCode>[] = (definition) =>
  handleOptionsOrder(
    countries.map<IOption<CountryCode>>((country, index) => ({
      value: country.Code,
      text: `${country.Name} **${country.Code}**`,
      selected: definition ? definition.elements.includes(country.Code) : false,
      index,
    }))
  );

export const GeoBlockingModal = ({
  definition,
  onSubmit,
}: GeoBlockingProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationDefinitionsPage");

  const [error, setError] = useState("");
  const [name, setName] = useState(definition?.name ?? "");
  const [options, setOptions] = useState(setInitOptions(definition));

  const toggleSelection = (selectedOption: IOption<CountryCode>) => {
    const sortedOptions = handleOptionsOrder(options, (option) => {
      if (option.index === selectedOption.index) {
        option.selected = !selectedOption.selected;
      }
    });

    setOptions(sortedOptions);
  };

  const selectedOptions = useMemo(
    () => sortOptionsByName(options.filter((option) => option.selected)),
    [options]
  );

  const placeholder = useMemo(() => {
    if (selectedOptions.length > 0) {
      let formattedPlaceholder = getCountryFromCode(selectedOptions[0].value)
        .Name;

      if (selectedOptions.length > 1) {
        formattedPlaceholder = formattedPlaceholder.concat(", ...");
      }

      return formattedPlaceholder;
    }

    return t("EDIT_DEFINITION_GEO_COUNTRIES_PLACEHOLDER");
  }, [
    selectedOptions[0],
    selectedOptions.length === 1,
    selectedOptions.length === 2,
  ]);

  const filterFunction = (search: string, option: IOption<CountryCode>) => {
    return (
      option.text.toLowerCase().startsWith(search.toLocaleLowerCase()) ||
      option.value.toLowerCase().startsWith(search.toLowerCase())
    );
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.SIMPLE_DEFINITION]
      )
    ) {
      setError(
        t("ERROR_DEFINITION_GEO_ALREADY_EXISTS", {
          name,
        })
      );
      return false;
    }

    if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    if (selectedOptions.length < 1) {
      setError(t("ERROR_DEFINITION_GEO_EMPTY"));
      return false;
    }

    return true;
  };

  const initState = (_definition = definition) => {
    setName(_definition?.name ?? "");
    setOptions(setInitOptions(_definition));
    setError("");
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="geoblocking-name"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
          dataTestId="geo-blocking-modal-header"
        />
      }
      references={
        definition && selectedConfiguration?.config
          ? getDefinitionReferences(definition, selectedConfiguration.config)
          : 0
      }
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        let newDefinition: SimpleDefinitionType | undefined;

        if (definition) {
          newDefinition = {
            ...definition,
            name,
            elements: options
              .filter((option) => option.selected)
              .map((option) => option.value),
          };
          onSubmit(newDefinition);
        } else {
          if (selectedConfiguration && selectedConfiguration.config) {
            newDefinition = createSimpleDefinition(name, {
              description: "",
              elements: options
                .filter((option) => option.selected)
                .map((option) => option.value),
              listType: "geo",
            });
            onSubmit(newDefinition);
          }
        }

        const isAddModal = typeof definition === "undefined";

        initState(isAddModal ? undefined : newDefinition);
      }}
      dataTestId="geo-blocking"
    >
      <Table>
        <TableBody>
          <Field>
            <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>

            <FieldInput
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
              data-testid="geo-blocking-modal-definition-name-input"
            />
          </Field>
          <Field>
            <FieldName data-testid="geo-blocking-modal-label">
              {t("EDIT_DEFINITION_GEO_COUNTRIES_LABEL", {
                count: selectedOptions.length,
              })}
            </FieldName>

            <TableCell>
              <SearchDropdown
                placeholder={placeholder}
                options={options}
                toggleSelection={toggleSelection}
                filterfunction={filterFunction}
                dataTestId="geo-blocking"
              />
            </TableCell>
          </Field>
        </TableBody>
      </Table>
      <ErrorContainer>
        {error.length > 0 && (
          <NotificationCard
            dataTestId="error-geo-blocking-modal"
            theme="error"
            title={error}
          />
        )}
      </ErrorContainer>
    </DefinitionModal>
  );
};

const ErrorContainer = styled.div`
  min-height: 40px;
  margin-bottom: 10px;
`;
