import { FC, useMemo, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import styled from "@emotion/styled";

import { useCurrentByMetro } from "../../../store/slices/rtm/hooks";
import metros from "../../atoms/WorldMap/metros.json";
import {
  MapSiteFieldsToLabel,
  Metrics,
} from "../../../modules/rtm/models/models";
import { FlexBox } from "../FlexBox/FlexBox";
import { isMetricPositive } from "../../../modules/rtm/helpers";
import { formatNumber } from "../../../utils/number";

const geoUrl = "/countries.json";

const REGION_COLOR_DEFAULT = "#d9dcdf";

const getMetroCoordinates = (metro: string): [number, number] => {
  const foundMetro = metros.find((m) => m.name === metro);
  if (foundMetro) {
    return [foundMetro.longitude, foundMetro.latitude];
  }
  return [0, 0];
};

interface IWorldMapProps {
  metric: Metrics;
}

const computeMarkerSize = (value: number, min: number, max: number) => {
  return (value * (30 - 6)) / (max - min) + 6 - (min * (30 - 6)) / (max - min);
};

const defaultTranslateExtent: [[number, number], [number, number]] = [
  [0, -500],
  [800, 500],
];

const defaultGeoStyle = {
  default: { outline: "none" },
  hover: { outline: "none" },
  pressed: { outline: "none" },
};

export const BubbleMap: FC<IWorldMapProps> = ({ metric }) => {
  const currentByMetro = useCurrentByMetro();

  const extremums = useMemo(
    () =>
      currentByMetro
        ? currentByMetro.reduce(
            (prev, curr) => {
              const newValue = { ...prev };
              if (curr[metric] < prev.min) {
                newValue.min = curr[metric];
              } else if (curr[metric] > prev.max) {
                newValue.max = curr[metric];
              }
              return newValue;
            },
            { min: Infinity, max: -Infinity }
          )
        : { min: 0, max: 0 },
    [currentByMetro, metric]
  );

  function handleZoomIn() {
    if (position.zoom >= 9) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom + 1 }));
    setScaleFactor((curr) => (curr += 1));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom - 1 }));
    setScaleFactor((curr) => (curr -= 1));
  }

  const [position, setPosition] = useState<{
    coordinates: [number, number];
    zoom: number;
  }>({ coordinates: [0, 0], zoom: 1 });

  const [scaleFactor, setScaleFactor] = useState(1);

  const [tooltipContent, setTooltipContent] = useState<{
    metro: string;
    value: number;
  }>();

  return (
    <>
      <ReactTooltip place="right" type="light">
        {tooltipContent && (
          <span>
            <b>{tooltipContent.metro}:</b> {formatNumber(tooltipContent.value)}{" "}
            {MapSiteFieldsToLabel.get(metric)}
          </span>
        )}
      </ReactTooltip>
      <Container data-tip="">
        <ComposableMap
          height={500}
          projection="geoMercator"
          projectionConfig={{
            rotate: [-10, 0, 0],
            parallels: [0, 20],
          }}
        >
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={setPosition}
            onMove={({ k }) => setScaleFactor(k)}
            translateExtent={defaultTranslateExtent}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.reduce((geoComponents, geo) => {
                  if (geo.properties.NAME === "Antarctica") {
                    return geoComponents;
                  }
                  geoComponents.push(
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={REGION_COLOR_DEFAULT}
                      strokeWidth={0.25}
                      stroke="white"
                      style={defaultGeoStyle}
                    />
                  );

                  return geoComponents;
                }, [])
              }
            </Geographies>
            {currentByMetro?.map((site) => (
              <Marker
                key={site.metro}
                coordinates={getMetroCoordinates(site.metro)}
                onMouseEnter={() =>
                  setTooltipContent({
                    metro: site.metro,
                    value: site[metric],
                  })
                }
                onMouseLeave={() => setTooltipContent(undefined)}
              >
                <circle
                  r={
                    computeMarkerSize(
                      site[metric],
                      extremums.min,
                      extremums.max
                    ) / scaleFactor
                  }
                  fill={isMetricPositive(metric) ? "#006eff99" : "#d61f15a5"}
                  stroke={isMetricPositive(metric) ? "#004cb099" : "#9c1710a4"}
                  strokeWidth={0.5 / scaleFactor}
                />
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
        <ButtonsContainer>
          <div onClick={handleZoomIn}>+</div>
          <div onClick={handleZoomOut}>-</div>
        </ButtonsContainer>
      </Container>
    </>
  );
};

const Container = styled(FlexBox)`
  align-items: start;
`;

const ButtonsContainer = styled.div`
  margin-left: 10px;
  border: 1px solid ${({ theme }) => theme.borders.highlight};
  border-radius: 4px;
  & > div {
    padding: 10px 20px;
    color: ${({ theme }) => theme.text.highlight};
    cursor: pointer;
    text-align: center;
    font-weight: 500;
  }
  & > div:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.borders.highlight};
  }
`;
