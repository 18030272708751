import { FC, ReactNode } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { IRoute, Tabs } from "../../components/organisms/Tabs/Tabs";
import { Locations } from "./components/templates/Locations/Locations";
import { Overview } from "./components/templates/Overview/Overview";
import { Properties } from "./components/templates/Properties/Properties";
import { RTMLayer } from "../../components/layers/RTM/RTM";
import { SubHeaderAccessGroup } from "../../components/organisms/SubHeader/SubHeaderAccessGroup/SubHeaderAccessGroup";
import {
  ModuleContent,
  ModuleHeader,
  ModuleTitle,
} from "../../components/atoms/Module/Module";

const SCROLL_ID = "RTM";

export const RealTimeMonitoring: FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{
    version: string;
    module: string;
    name: string;
    submodule: string;
    subname: string;
  }>([
    "/:version/:module/:name/:submodule/:subname",
    "/:version/:module/:name/:submodule",
    "/:version/:module/:name",
    "/:version/:module",
  ]);

  const tabList: (IRoute & { component: ReactNode })[] = [
    { route: "overview", label: "Overview", component: <Overview /> },
    { route: "locations", label: "Locations", component: <Locations /> },
    { route: "properties", label: "Properties", component: <Properties /> },
  ];

  const currentIndex = tabList.findIndex(
    (tab) => tab.route === match?.params.module
  );
  if (currentIndex === -1) {
    history.push(`/rtm/${tabList[0].route}`);
  }

  const changeReport = (selection: IRoute) => {
    history.push(`/rtm/${selection.route}`);
  };

  return (
    <>
      <SubHeaderAccessGroup />
      <RTMLayer>
        <ModuleHeader>
          <ModuleTitle
            moduleTitle="Caching Realtime Monitoring"
            isBeta
            dataTestId="subheader-title"
          />
          <Tabs
            onSelectionChange={changeReport}
            tabsList={tabList.map(({ label, route }) => ({ label, route }))}
            initialIndex={currentIndex === -1 ? 0 : currentIndex}
          />
        </ModuleHeader>
        <ModuleContent id={SCROLL_ID}>
          <Switch>
            {tabList.map((tab) => (
              <Route key={tab.route} path={`/rtm/${tab.route}`}>
                {tab.component}
              </Route>
            ))}
            <Redirect from="/rtm/*" to={`/rtm/${tabList[0].route}`} />
          </Switch>
        </ModuleContent>
      </RTMLayer>
    </>
  );
};
