import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { animated } from "react-spring";

import { ReactComponent as FileIcon } from "../../../../assets/icons/file.svg";
import { Button } from "../../../../components/atoms/Button/Button";
import { TextDelimiter } from "../../../../components/atoms/TextDelimiter/TextDelimiter";
import { FileDropzoneChildrenProps } from "../../../../components/organisms/FileDropzone/FileDropzone";

export const UploadContent = ({
  open,
  isOver,
  isRejected,
  ...styleProps
}: FileDropzoneChildrenProps): JSX.Element => {
  const { t } = useTranslation("misc");

  return (
    <>
      <FileIcon />
      <h5>{t("FILE_DROPZONE_TITLE")}</h5>
      <TextDelimiter text="or" />
      {isOver && (
        <DropCacheLayer style={{ ...styleProps }} isRejected={isRejected} />
      )}
      <ButtonContainer>
        <Button label={t("FILE_DROPZONE_BROWSE_BUTTON")} onClick={open} />
      </ButtonContainer>
    </>
  );
};

const DropCacheLayer = styled(animated.div)<{
  isRejected: boolean;
}>`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: ${({ isRejected }) => (isRejected ? "75%" : "100%")};
  background-color: ${({ theme, isRejected }) =>
    isRejected ? theme.backgrounds.error : theme.backgrounds.highlightLight};
  top: 0;
  left: 0;
`;

const ButtonContainer = styled.div`
  z-index: 7;
`;
