import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status } from "../../../@types/status";
import { IPremiumFeatures, ISubscriber } from "../../../models/subscriber";
import { SubscriberSlice } from "./types";

const initialState: SubscriberSlice = {
  scid: undefined,
  subscriberId: undefined,
  premiumFeatures: undefined,
  subscriberStatus: Status.IDLE,
};

export const subscriberSlice = createSlice({
  name: "subscriber",
  initialState,
  reducers: {
    setSubscriber: (state, action: PayloadAction<ISubscriber>) => {
      state.scid = action.payload.scid;
      state.subscriberId = action.payload.subscriberId;
    },
    setPremiumFeature: (state, action: PayloadAction<IPremiumFeatures>) => {
      state.premiumFeatures = action.payload;
    },
    setSubscriberStatus: (state, action: PayloadAction<Status>) => {
      state.subscriberStatus = action.payload;
    },
    clearSubscriberSlice: (state) => {
      state.scid = undefined;
      state.subscriberId = undefined;
      state.premiumFeatures = undefined;
      state.subscriberStatus = Status.IDLE;
    },
  },
});

export const {
  setSubscriber,
  setPremiumFeature,
  setSubscriberStatus,
  clearSubscriberSlice,
} = subscriberSlice.actions;

export default subscriberSlice.reducer;
