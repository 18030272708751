import styled from "@emotion/styled";

export const MatchRuleConditionText = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.text.highlight};
  margin-right: 4px;
`;

export const MatchRuleExpressionText = styled.span``;

export const MatchBlockContainer = styled.div`
  margin-bottom: 1em;
`;

export const Header = styled.div``;

export const Title = styled.h5`
  margin: 1rem 0 !important;
`;

export const DNDContainer = styled.div`
  position: relative;
`;

export const DeleteModalContent = styled.div`
  padding: 3px;
`;

export const ExpressionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MatchRuleFeatureText = styled(MatchRuleExpressionText)`
  font-weight: 600;
`;
