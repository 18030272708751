import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

import { Banner } from "../../../models/banner";
import { Button } from "../../atoms/Button/Button";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useUserId } from "../../../store/slices/user/hooks";

interface BannerModalProps {
  id: string;
  notificationId: number;
  name?: string;
  body: string;
  onAcknowledge: () => void;
}

export const BannerModal = ({
  id,
  notificationId,
  name,
  body,
  onAcknowledge,
}: BannerModalProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleMediaPortalError = useDispatchAPIError();
  const userId = useUserId();
  const { t } = useTranslation("misc");

  return (
    <>
      {isOpen && (
        <>
          <Background />
          <Container>
            <BannerContainer onClick={(e) => e.stopPropagation()}>
              <ContentContainer>
                <Title data-testid="banner-title">
                  {name || t("BANNER_TITLE")}
                </Title>
                <Body data-testid="banner-content">
                  <Markdown>{body}</Markdown>
                </Body>
                <Buttons>
                  <Button
                    dataTestId="banner-acknowledge-button"
                    label="OK"
                    onClick={() => {
                      Banner.acknowledge(id, notificationId, userId).catch(
                        handleMediaPortalError
                      );
                      setIsOpen(false);
                      onAcknowledge();
                    }}
                  />
                </Buttons>
              </ContentContainer>
            </BannerContainer>
          </Container>
        </>
      )}
    </>
  );
};

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99998;
  opacity: 0.75;
  background-color: ${({ theme }) => theme.backgrounds.mutedDark};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 7px;
  min-width: 300px;
  max-width: 1000px;
  max-height: 90vh;
  width: 60%;

  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;

const ContentContainer = styled.div`
  padding: 32px;
`;

const Title = styled.h3`
  margin-bottom: 50px !important;
  text-align: center;
`;

const Body = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
