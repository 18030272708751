import styled from "@emotion/styled";
import { animated, useSpring, config } from "react-spring";
import { useEffect, useRef, useState, useContext } from "react";

import { ThemeContext } from "../../../contexts/themeContext/index";

export enum RadioSliderThemes {
  BLUE = "blue",
  PRIME_AND_BLUE = "whiteAndBlue",
  DISABLED = "disabled",
}

export interface IChoice<T> {
  label: string;
  value: T;
  dataTestId?: string;
}

export interface IRadioSliderProps<T> {
  choices: IChoice<T>[];
  sliderTheme?: RadioSliderThemes;
  defaultSelectedIndex?: number;
  disabled?: boolean;
  onChange: (choice: IChoice<T>) => void;
}

export const RadioSlider = <T extends unknown>({
  onChange,
  choices,
  disabled,
  defaultSelectedIndex = 0,
  ...props
}: IRadioSliderProps<T>) => {
  const { colorset } = useContext(ThemeContext);

  const themes: {
    [key in RadioSliderThemes]: {
      color: string;
      sliderBackgroundColor: string;
      backgroundColor: string;
    };
  } = {
    blue: {
      color: colorset.text.highlight,
      sliderBackgroundColor: colorset.backgrounds.highlightLight,
      backgroundColor: "transparent",
    },
    disabled: {
      color: colorset.text.mutedAlt,
      sliderBackgroundColor: colorset.backgrounds.mutedLight,
      backgroundColor: colorset.backgrounds.muted,
    },
    whiteAndBlue: {
      color: colorset.text.highlight,
      sliderBackgroundColor: colorset.backgrounds.highlightLight,
      backgroundColor: colorset.backgrounds.baseLight,
    },
  };

  const [selectedIndex, setSelectedIndex] = useState<number>(
    defaultSelectedIndex
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const sliderTheme = disabled
    ? "disabled"
    : props.sliderTheme ?? RadioSliderThemes.BLUE;
  const sliderPosition = useSpring({
    config: { ...config.stiff, mass: 0.5 },
    left: 50 * selectedIndex + "px",
    backgroundColor: themes[sliderTheme].sliderBackgroundColor,
  });

  useEffect(() => {
    onChange(choices[selectedIndex]);
  }, [selectedIndex]);

  return (
    <RadioSliderContainer ref={containerRef}>
      <Slider style={sliderPosition} />
      {choices.map((choice: IChoice<T>, i) => (
        <Choice
          key={i}
          disabled={disabled}
          onClick={() => !disabled && setSelectedIndex(i)}
          selected={selectedIndex === i}
          style={{
            backgroundColor:
              selectedIndex !== i
                ? themes[sliderTheme].backgroundColor
                : "transparent",
            color:
              selectedIndex === i
                ? themes[sliderTheme].color
                : colorset.text.secondary,
          }}
          data-testid={choice.dataTestId}
        >
          {choice.label}
        </Choice>
      ))}
    </RadioSliderContainer>
  );
};

const RadioSliderContainer = styled.div`
  display: flex;
  position: relative;
`;

const Choice = styled.div<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  z-index: 2;
  transition: background-color 0.8s;
  transition: color 0.8s;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const Slider = styled(animated.div)`
  background-color: #e0f3ff;
  border-radius: 4px;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;
`;
