import { Children, FC } from "react";

import { UserRoles } from "../../../models/permissions";
import { ExistingFeatures } from "../../../store/slices/feature-flags/types";
import { PermissionStatus, usePermissions } from "./usePermissions";

export interface IProtectedProps {
  permissions?: UserRoles | UserRoles[];
  flags?: ExistingFeatures;
  alternate?: JSX.Element;
}

export const Protected: FC<IProtectedProps> = ({
  children,
  permissions,
  flags,
  alternate,
}) => {
  if (Children.count(children) < 1) {
    throw new Error("Protected Component should have at least one child.");
  }

  const status = usePermissions({
    permissions,
    flags,
  });

  switch (status) {
    case PermissionStatus.AUTHORIZED:
      return <>{children}</>;
    case PermissionStatus.AWAITING:
    case PermissionStatus.DENIED:
      return alternate || null;
    default:
      throw new Error(`Unknown protection status: ${status}`);
  }
};
