import {
  AccessGroupMember,
  AccessGroupProduct,
  Products,
} from "./../../../../../models/user-management/access-group/access-group.interfaces";
import { UserStatus } from "./../../../../../models/user-management/users/users.interfaces";
import { SelectionStatus } from "../../../../../components/atoms/Checkbox/CustomCheckBoxInput";
import { IDropdownItem } from "../../../../../components/organisms/Dropdown/Dropdown";

export type TableSelection = Map<string, string[]>;

export enum UserType {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export const handleFilterProductsTable = (
  search: string,
  filter: Products[]
) => (product: AccessGroupProduct): boolean => {
  let testSearch = false;
  let testFilter = false;
  if (search === "") {
    testSearch = true;
  } else {
    testSearch =
      !!product.scid.match(search.toUpperCase()) ||
      !!product.childServiceList?.some((alias) => alias.name.match(search));
  }
  if (filter.length === 0) {
    testFilter = true;
  } else {
    testFilter = filter.includes(product.product);
  }
  const selectedFilter =
    product.selected ||
    !!product.childServiceList?.some((property) => property.selected);
  return testSearch && testFilter && selectedFilter;
};

export const handleFilterMembersTable = (
  search: string,
  statusFilter: UserStatus[],
  roleFilter: number[],
  showInherited: boolean
) => (member: AccessGroupMember): boolean => {
  let testSearch = false;
  let testStatus = false;
  let testRole = false;
  const testInherited = showInherited ? true : !member.inherited;
  if (search === "") {
    testSearch = true;
  } else {
    testSearch =
      !!member.email.toLowerCase().match(search.toLowerCase()) ||
      !!member.userName.toLowerCase().match(search.toLowerCase()) ||
      !!member.firstName.toLowerCase().match(search.toLowerCase()) ||
      !!member.lastName.toLowerCase().match(search.toLowerCase());
  }
  if (roleFilter.length === 0) {
    testRole = true;
  } else {
    testRole = roleFilter.includes(member.roleId);
  }
  if (statusFilter.length === 0) {
    testStatus = true;
  } else {
    testStatus = statusFilter.includes(member.status);
  }
  return testSearch && testStatus && testRole && testInherited;
};

export const handleFilterProductsToAdd = (
  search: string,
  selectedOnly: boolean,
  selection: TableSelection
) => (product: AccessGroupProduct): boolean => {
  let testSearch = false;
  let testSelectionOnly = false;
  if (search === "") {
    testSearch = true;
  } else {
    testSearch =
      !!product.scid.match(search.toUpperCase()) ||
      !!product.childServiceList?.some((alias) => alias.name.match(search));
  }
  if (selectedOnly) {
    testSelectionOnly = selection.has(product.scid);
  } else {
    testSelectionOnly = true;
  }
  return testSearch && testSelectionOnly;
};

export const generateSelectionState = (
  product: AccessGroupProduct,
  selectedProperties: string[] | undefined
): SelectionStatus => {
  if (product.selected) {
    return SelectionStatus.SELECTED;
  }
  if (product.childServiceList) {
    if (selectedProperties) {
      if (
        product.childServiceList.filter((property) => !property.selected)
          .length === selectedProperties.length
      ) {
        return SelectionStatus.SELECTED;
      }
      return SelectionStatus.PARTIAL;
    }
    if (product.childServiceList.some((property) => property.selected)) {
      return SelectionStatus.PARTIAL;
    }
  }
  return SelectionStatus.NOT_SELECTED;
};

export const handleToggleScid = (row: AccessGroupProduct) => (
  prev: TableSelection
) => {
  if (prev.has(row.scid)) {
    const newState = new Map(prev);
    newState.delete(row.scid);
    return newState;
  } else {
    return new Map(prev).set(
      row.scid,
      row.childServiceList
        ? row.childServiceList
            ?.filter((property) => !property.selected)
            .map((property) => property.id)
        : []
    );
  }
};

export const handleToggleProperty = (
  row: AccessGroupProduct,
  propertyId: string
) => (prev: TableSelection) => {
  const props = prev.get(row.scid);
  if (props === undefined) {
    return new Map(prev).set(row.scid, [propertyId]);
  } else {
    if (props.includes(propertyId)) {
      if (props.length === 1) {
        const newState = new Map(prev);
        newState.delete(row.scid);
        return newState;
      }
      return new Map(prev).set(
        row.scid,
        props.filter((property) => property !== propertyId)
      );
    } else {
      return new Map(prev).set(row.scid, [...props, propertyId]);
    }
  }
};

export const toggleExpandRow = <T = number | string>(rowIndex: T) => (
  prev: T[]
) => {
  if (prev.includes(rowIndex)) {
    return prev.filter((index) => index !== rowIndex);
  } else {
    return [...prev, rowIndex];
  }
};

export const buildProductsToAddList = (
  products: AccessGroupProduct[],
  selectedRows: TableSelection
): {
  scids: string[];
  nis: string[];
} => {
  return products.reduce<{
    scids: string[];
    nis: string[];
  }>(
    (prev, product) => {
      if (product.selected) {
        return {
          ...prev,
          scids: [...prev.scids, `${product.scid}:${product.product}`],
        };
      }
      const selection = selectedRows.get(product.scid);
      if (selection === undefined) {
        if (product.childServiceList) {
          return {
            ...prev,
            nis: [
              ...prev.nis,
              ...product.childServiceList
                .filter((property) => property.selected)
                .map((property) => `${property.id}:${product.product}`),
            ],
          };
        }
      } else {
        if (!!product.childServiceList) {
          const shouldAddScid =
            product.childServiceList.filter((property) => property.selected)
              .length +
              selection.length ===
            product.childServiceList.length;
          if (shouldAddScid) {
            return {
              ...prev,
              scids: [...prev.scids, `${product.scid}:${product.product}`],
            };
          }
          return {
            ...prev,
            nis: [
              ...prev.nis,
              ...product.childServiceList
                .filter((property) => property.selected)
                .map((property) => `${property.id}:${product.product}`),
              ...selection.map((property) => `${property}:${product.product}`),
            ],
          };
        }
      }
      return prev;
    },
    { scids: [], nis: [] }
  );
};

export const buildProductsToDeleteList = (
  products: AccessGroupProduct[],
  productsToRemove: string[]
): {
  scids: string[];
  nis: string[];
} => {
  return products.reduce<{
    scids: string[];
    nis: string[];
  }>(
    (prev, product) => {
      if (productsToRemove.includes(product.scid)) {
        return prev;
      }
      if (product.selected) {
        return {
          ...prev,
          scids: [...prev.scids, `${product.scid}:${product.product}`],
        };
      }
      if (product.childServiceList) {
        return {
          ...prev,
          nis: [
            ...prev.nis,
            ...product.childServiceList
              .filter((property) => property.selected)
              .map((property) => `${property.id}:${product.product}`),
          ],
        };
      }
      return prev;
    },
    { scids: [], nis: [] }
  );
};

export const displayMultiSelectHeader = <T extends unknown>(
  items: IDropdownItem<T>[],
  selected: IDropdownItem<T>[]
): string => {
  if (items.length === selected.length || selected.length === 0) {
    return "All";
  }
  if (selected.length === 1) {
    return selected[0].label;
  }
  return selected.length + " selected";
};

export const validateEmail = (value: string): boolean =>
  !!value
    .toLowerCase()
    .match(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/);

const INTERNAL_EMAIL_PATTERN = /^.*@lumen.com$/;

export const isUserInternal = <T extends { email: string }>(user: T): boolean =>
  !!user.email.match(INTERNAL_EMAIL_PATTERN);

export interface AddUserData {
  firstName: string;
  lastName: string;
  description: string;
  userId: string;
}

export interface AddUserDataWithInternal extends AddUserData {
  internal: boolean;
}
