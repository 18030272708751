import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../atoms/Button/Button";
import { IModalChildren, Modal } from "../../atoms/Modal/Modal";

export enum DiscardCommitModalMode {
  EDIT_OLD_VERSION = "edit_older_version",
  RETURN_TO_LATEST_VERSION = "return_to_latest_version",
}
interface IDiscardCommitModalProps {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  onSubmit: () => void;
  mode: DiscardCommitModalMode;
}

interface IDiscardCommitModalChildProps extends IModalChildren {
  onDiscard: () => void;
  onSubmit: () => void;
  mode: DiscardCommitModalMode;
}

export const DiscardCommitModal = ({
  open,
  onClose,
  onDiscard,
  onSubmit,
  mode,
}: IDiscardCommitModalProps): ReactElement => (
  <Modal customButton={<></>} open={open} onClose={onClose}>
    <DiscardCommitModalChild
      onDiscard={onDiscard}
      onSubmit={onSubmit}
      mode={mode}
    />
  </Modal>
);

const DiscardCommitModalChild = ({
  closeModal,
  onDiscard,
  onSubmit,
  mode,
}: IDiscardCommitModalChildProps): ReactElement => {
  const { t } = useTranslation("configurationVersionsPage");

  return (
    <Container>
      <Header>
        {t(
          mode === DiscardCommitModalMode.EDIT_OLD_VERSION
            ? "EDIT_VERSION_CHANGES_MODAL_HEADER"
            : "EDIT_VERSION_CHANGES_MODAL_RETURN_TO_LATEST_VERSION_HEADER"
        )}
      </Header>
      <Label>
        {t(
          mode === DiscardCommitModalMode.RETURN_TO_LATEST_VERSION
            ? "EDIT_VERSION_CHANGES_MODAL_TEXT"
            : "EDIT_VERSION_CHANGES_MODAL_RETURN_TO_LATEST_VERSION_TEXT"
        )}
      </Label>
      <ButtonsContainer>
        <CancelButtonContainer></CancelButtonContainer>
        {closeModal && (
          <Button
            flat
            onClick={() => {
              closeModal();
            }}
            label={t("EDIT_VERSION_CHANGES_MODAL_CANCEL_BUTTON")}
            size="lg"
            backgroundColor="baseLight"
            textColor="highlight"
            borderColor="highlight"
            dataTestId="discard-commit-modal-cancel-button"
          />
        )}
        <DiscardButtonContainer>
          {closeModal && (
            <Button
              flat
              onClick={() => {
                closeModal();
                onDiscard();
              }}
              label={t("EDIT_VERSION_CHANGES_MODAL_DISCARD_BUTTON")}
              size="lg"
              dataTestId="discard-commit-modal-discard-button"
            />
          )}
        </DiscardButtonContainer>
        <SubmitButtonContainer>
          {closeModal && (
            <Button
              onClick={() => {
                closeModal();
                onSubmit();
              }}
              label={t("EDIT_VERSION_CHANGES_MODAL_COMMIT_BUTTON")}
              size="lg"
              dataTestId="discard-commit-modal-submit-button"
            />
          )}
        </SubmitButtonContainer>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border-radius: 7px;
  width: 664px;
  padding: 32px;
  cursor: default;
`;

const Header = styled.h3`
  margin: 0 !important;
`;

const Label = styled.div`
  padding: 32px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButtonContainer = styled.div``;

const DiscardButtonContainer = styled.div`
  padding: 0 16px;
`;

const SubmitButtonContainer = styled.div``;
