import { VyvxCompany } from "../../vyvx/vyvx.interfaces";

export enum UserStatus {
  ACTIVE = "Active",
  DISABLED = "Disabled",
  EXPIRED = "Expired",
  INACTIVE = "Inactive",
  LOCKED = "Locked",
  PENDING = "Pending",
}

export enum Timezone {
  ALASKA = "Alaska",
  CENTRAL = "Central",
  EASTERN = "Eastern",
  GMT = "GMT",
  HAWAII = "Hawaii",
  MOUNTAIN = "Mountain",
  PACIFIC = "Pacific",
}

export interface UserManagementUserData {
  userId: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
  lastLogin: string;
  createdAt: string;
  accessGroups: UserAccessGroup[];
  vyvx?: UserVyvxData;
}

export type UserAccessGroup = {
  id: number;
  name: string;
  roleName: string;
};

export interface UserVyvxData {
  fiberCompanyAbbr?: string;
  fiberCompanyName?: string;
  fiberAssociatedCompanies?: VyvxCompany[];
  satelliteCompanyAbbr?: string;
  satelliteCompanyName?: string;
  satelliteAssociatedCompanies?: VyvxCompany[];
  role?: string;
  timezone?: Timezone;
  preferences?: UserVyvxPreferences[];
}

export enum UserVyvxPreferences {
  EMAIL_NOTIFICATIONS = "Confirmation Letter",
  DEFAULT_IN_NETWORK_CHECKED = "InNetworkOnly",
}

export interface UserPreferencesUpdateData {
  email: string;
  vyvx?: {
    timezone?: Timezone;
    preferences: UserVyvxPreferences[];
  };
}

export interface AddUserAccessGroupsData {
  accessGroups: {
    id: number;
    roleId: number;
  }[];
  mailMessage: string;
}

export interface UpdateUserInfoData {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  status: UserStatus;
}

export interface UpdateVyvxData {
  fiberCompanyAbbr?: string;
  fiberAssociatedCompanies?: string[];
  satelliteCompanyAbbr?: string;
  satelliteAssociatedCompanies?: string[];
  role?: string;
  timezone?: Timezone;
  preferences?: UserVyvxPreferences[];
}
