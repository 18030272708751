import styled from "@emotion/styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../@types/status";

import { Button } from "../../../../components/atoms/Button/Button";
import { FlexBox } from "../../../../components/atoms/FlexBox/FlexBox";
import { HorizontalFieldName } from "../../../../components/atoms/HorizontalFieldName/HorizontalFieldName";
import {
  HorizontalFormBody,
  HorizontalFormContainer,
} from "../../../../components/atoms/HorizontalFormLayout/HorizontalFormLayout";
import { Loader } from "../../../../components/atoms/Loader/Loader";
import { IModalChildren } from "../../../../components/atoms/Modal/Modal";
import { QueryLoader } from "../../../../components/atoms/QueryLoader/QueryLoader";
import {
  HorizontalField,
  HorizontalInputField,
} from "../../../../components/molecules/HorizontalInputField/HorizontalInputField";
import { TextArea } from "../../../../components/molecules/TextArea/TextArea";
import { Dropdown } from "../../../../components/organisms/Dropdown/Dropdown";
import { APIError } from "../../../../models/error";
import {
  ApiKeysOwners,
  ApiKeysRoles,
} from "../../../../models/user-management/apiKeys/apiKeys.interfaces";
import { createCdnApiKey } from "../../../../models/user-management/apiKeys/cdn";
import { createVyvxApiKey } from "../../../../models/user-management/apiKeys/vyvx";
import { VyvxFiberCompany } from "../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { handleAPIError } from "../../../../store/slices/api-error/thunks";
import { useAppDispatch } from "../../../../store/types";
import { CdnApikeysDropdown } from "./cdn/CdnApiKeysDropdown";
import { ApiKeysCustomerFilter } from "./types";
import { VyvxApiKeysCompanies } from "./vyvx/VyvxApiKeysCompanies";
interface ApiKeyModalProps {
  roles: ApiKeysRoles[];
  owners: ApiKeysOwners[];
  isVyvx: boolean;
  fiberCompanies: VyvxFiberCompany[];
  customerFilter: ApiKeysCustomerFilter;
}
export const ApiKeyModal = ({
  closeModal,
  roles,
  owners,
  isVyvx,
  fiberCompanies,
  customerFilter,
}: IModalChildren & ApiKeyModalProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");

  const [name, setName] = useState("");
  const [contactId, setContactId] = useState<number | undefined>(undefined);
  const [roleId, setRoleId] = useState<number | undefined>(undefined);
  const [notes, setNotes] = useState("");
  const [customerInfos, setCustomerInfos] = useState(customerFilter);
  const [creationStatus, setCreationStatus] = useState<Status>(Status.IDLE);

  const dispatch = useAppDispatch();

  const handleCreateApiKey = async () => {
    try {
      const companyAbbr = fiberCompanies.find(
        (elem) => elem.companyName === customerInfos.customerName
      )?.companyAbbr;
      if (isVyvx && companyAbbr && contactId && roleId) {
        const body = {
          name,
          contactId,
          vyvxRoleId: roleId,
          notes,
        };
        setCreationStatus(Status.LOADING);
        await createVyvxApiKey(companyAbbr, body);
        setCreationStatus(Status.SUCCESS);
        handleCloseModal();
      } else if (contactId && roleId) {
        const body = {
          name,
          contactId,
          accessGroupId: Number(customerInfos.customerId),
          roleId,
          notes,
        };
        setCreationStatus(Status.LOADING);
        await createCdnApiKey(Number(customerInfos.customerId), body);
        setCreationStatus(Status.SUCCESS);
        handleCloseModal();
      }
    } catch (err) {
      setCreationStatus(Status.ERROR);
      dispatch(handleAPIError(err as APIError));
    }
  };

  const handleCloseModal = () => {
    setName("");
    setContactId(undefined);
    setRoleId(undefined);
    setNotes("");
    setCustomerInfos({ customerId: "0", customerName: "all" });
    if (closeModal) closeModal();
  };

  const roleDropdownItems = Object.values(roles).map((roleItem) => {
    return {
      label: roleItem.name,
      value: roleItem.id,
      default: false,
    };
  });

  const ownerDropdownItems = Object.values(owners).map((ownerItem) => {
    return {
      label: ownerItem.name,
      value: ownerItem.id,
      default: false,
    };
  });

  return (
    <Container>
      <Title>{t("API_KEY_CREATION_MODAL_TITLE")}</Title>
      <HorizontalFormContainer>
        <FieldSection>
          <HorizontalField>
            <HorizontalFieldName required>
              {isVyvx
                ? t("API_KEY_CREATION_MODAL_COMPANY_LABEL")
                : t("API_KEYS_TABLE_ACCESS_GROUP_LABEL")}
            </HorizontalFieldName>
            {isVyvx ? (
              <VyvxApiKeysCompanies
                fiberCompanies={fiberCompanies}
                keyCompanyFilter={customerInfos}
                onCompnayFilterChange={setCustomerInfos}
                isVyvx={isVyvx}
              />
            ) : (
              <CdnApikeysDropdown
                accessGroupFilter={customerInfos}
                handleSelectAccessGroup={setCustomerInfos}
                isVyvx={isVyvx}
              />
            )}
          </HorizontalField>
          <HorizontalInputField
            placeholder={t("API_KEY_CREATION_MODAL_NAME_PLACEHOLDER")}
            value={name}
            label={t("API_KEY_CREATION_MODAL_NAME_LABEL")}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required
          />
          <HorizontalField>
            <HorizontalFieldName required>
              {t("API_KEY_CREATION_MODAL_ROLE_LABEL")}
            </HorizontalFieldName>
            <DropdownContainer>
              <Dropdown
                items={roleDropdownItems}
                id="service-selector"
                placeholder={t("API_KEY_CREATION_MODAL_ROLE_PLACEHOLDER")}
                onSelect={(e) => setRoleId(Number(e.value))}
              />
            </DropdownContainer>
          </HorizontalField>
          <HorizontalField>
            <HorizontalFieldName required>
              {t("API_KEY_CREATION_MODAL_OWNER_LABEL")}
            </HorizontalFieldName>
            <DropdownContainer>
              <Dropdown
                items={ownerDropdownItems}
                id="service-selector"
                placeholder={t("API_KEY_CREATION_MODAL_OWNER_PLACEHOLDER")}
                onSelect={(e) => setContactId(Number(e.value))}
              />
            </DropdownContainer>
          </HorizontalField>
          <HorizontalField>
            <HorizontalFieldName>
              {t("API_KEY_CREATION_MODAL_NOTES_LABEL")}
            </HorizontalFieldName>
            <TextArea
              value={notes}
              onChange={setNotes}
              placeholder={t("API_KEY_CREATION_MODAL_NOTES_PLACEHOLDER")}
            />
          </HorizontalField>
        </FieldSection>
      </HorizontalFormContainer>

      <ButtonsContainer>
        <LoaderContainer>
          <QueryLoader
            status={creationStatus}
            render={{
              [Status.IDLE]: <></>,

              [Status.LOADING]: <Loader size={20} />,

              [Status.SUCCESS]: <></>,

              [Status.ERROR]: <></>,
            }}
          />
        </LoaderContainer>
        {creationStatus === Status.IDLE && (
          <Button
            label={t("API_KEY_CREATION_MODAL_CANCEL_BUTTON")}
            onClick={handleCloseModal}
            backgroundColor="baseLight"
            textColor="primary"
          />
        )}
        {creationStatus === Status.SUCCESS ? (
          <Button label="Close" onClick={handleCloseModal} />
        ) : (
          <Button
            label={t("API_KEY_CREATION_MODAL_CREATE_BUTTON")}
            onClick={handleCreateApiKey}
            disabled={creationStatus === Status.LOADING}
          />
        )}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const DropdownContainer = styled.div`
  margin-left: -8px;
  margin-top: -10px;
`;

const LoaderContainer = styled(FlexBox)`
  padding: 4px 8px;
  margin-right: 8px;
`;

const ButtonsContainer = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 32px;
  & > button:not(:last-child) {
    margin-right: 8px;
  }
`;

const FieldSection = styled(HorizontalFormBody)`
  margin-botton: 10px;
`;
