import { SimpleDefinitionType } from "../../../store/slices/caching/types";
import { CountryCode, getCountryFromCode } from "../../../utils/countries";

export type ListType = "geo" | "ip" | "string";

export interface ISimpleDefinition {
  description?: string;
  elements: string[];
  listType: ListType;
}

export const getSimpleDefinitionDescription = (
  def: SimpleDefinitionType
): string => {
  switch (def.listType) {
    case "geo":
      return (def.elements as CountryCode[])
        .map((element) => {
          const country = getCountryFromCode(element);
          return country && country.Name;
        })
        .filter((country) => !!country)
        .join(", ");
    case "ip":
    case "string":
      return def.elements.join(", ");
    default:
      return "";
  }
};
