import { FC, useState } from "react";
import styled from "@emotion/styled";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { SpringValue, useSpring } from "react-spring";
import { Theme } from "@emotion/react";

export type GetBorderStyle = (theme: Theme, isRejected: boolean) => string;

const getBorderStyleDefault: GetBorderStyle = (theme, isRejected) =>
  `2px dashed ${isRejected ? theme.colors.red60 : theme.borders.info}`;

export interface FileDropzoneChildrenProps {
  open: () => void;
  isOver: boolean;
  opacity: SpringValue<number>;
  isRejected: boolean;
}

export interface FileDropzoneProps
  extends Partial<Pick<DropzoneOptions, "accept" | "maxFiles">> {
  onFileChange: (files: File[]) => void;
  children: (props: FileDropzoneChildrenProps) => JSX.Element;
  getBorderStyle?: GetBorderStyle;
}

export const FileDropZone: FC<FileDropzoneProps> = ({
  onFileChange,
  children,
  getBorderStyle = getBorderStyleDefault,
  accept = [".txt", ".pem"],
  maxFiles = 1,
}) => {
  const [isOver, setIsOver] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const [props, api] = useSpring(() => ({ opacity: 0 }));

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    maxFiles,
    noClick: true,
    noKeyboard: true,
    multiple: maxFiles > 1,
    onDrop: (files) => {
      if (files?.length > 0) {
        onFileChange(files.slice(0, maxFiles));
        api.start({
          to: async (next) => {
            await next({ opacity: 0 });
            setIsOver(false);
          },
        });
      }
    },
    onDropRejected: () => {
      setIsRejected(true);
    },
    onDragEnter: () => {
      setIsRejected(false);
      api.start({
        to: async (next) => {
          setIsOver(true);
          await next({ opacity: 0.75 });
        },
      });
    },
    onDragLeave: () => {
      api.start({
        to: async (next) => {
          await next({ opacity: 0 });
          setIsOver(false);
        },
      });
    },
  });

  return (
    <DropZone
      {...getRootProps({ className: "dropzone" })}
      isRejected={isRejected}
      getBorderStyle={getBorderStyle}
    >
      <input {...getInputProps()} />
      {children({
        ...props,
        isRejected,
        open,
        isOver,
      })}
    </DropZone>
  );
};

const DropZone = styled.div<{
  isRejected: boolean;
  getBorderStyle: GetBorderStyle;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 5em;
  border: ${({ theme, isRejected, getBorderStyle }) =>
    getBorderStyle(theme, isRejected)};
  border-radius: 3px;
`;
