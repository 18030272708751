import { createContext, RefObject, useContext, useRef } from "react";

interface IHeaderContext {
  headerRef: RefObject<HTMLDivElement> | null;
}

const initialState: IHeaderContext = {
  headerRef: null,
};

export const HeaderContext = createContext(initialState);

export const useHeaderContext = (): void => {
  const headerContext = useContext(HeaderContext);
  const headerRef = useRef<HTMLDivElement>(null);

  headerContext.headerRef = headerRef;
};
