import type { ReactElement } from "react";
import type {
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { StringDefinitionModal } from "../../../../../../../../../components/molecules/StringDefinitionModal/StringDefinitionModal";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  SimpleDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { getDefsByType, selectedNames, toName } from "./helpers";

interface QSHModeFeatureBlockProps {
  clearErrors: UseFormClearErrors<IFeatures>;
  config: ConfigurationDetailsType;
  datatestId: string;
  errors: ValidationErrors;
  handleCreate: (d: SimpleDefinitionType) => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  initIsOpen: boolean;
  maxWidth: number;
  onDelete: () => void;
  qshMode: Exclude<IFeatures["qshMode"], undefined>;
  register: UseFormRegister<IFeatures>;
  setError: UseFormSetError<IFeatures>;
}

export const QSHModeFeatureBlock = ({
  clearErrors,
  config,
  datatestId,
  errors,
  handleCreate,
  handleCreateFeature,
  initIsOpen,
  maxWidth,
  onDelete,
  qshMode,
  register,
  setError,
}: QSHModeFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  const isAdvanced = typeof qshMode !== "boolean";

  const setQshMode = (mode: typeof qshMode) => () => {
    handleCreateFeature("qshMode", mode);
  };

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_QSH_MODE_TITLE")}
      additionalInfoTitle={t("FEATURE_CARD_ADDED_QSH_MODE_DESCRIPTION_TITLE")}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_QSH_MODE_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_QSH_MODE_DEFINITION_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_QSH_MODE_DEFINITION_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_QSH_MODE_DEFINITION_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_QSH_MODE_DEFINITION_EXCLUDE_ENTIRE_STRING"
                      ),
                      id: "exclude-qsh-mode",
                      dataTestId: "exclude-qsh-mode",
                      checked: qshMode === true,
                      onChange: setQshMode(true),
                    },
                    {
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_QSH_MODE_DEFINITION_INCLUDE_ENTIRE_STRING"
                      ),
                      id: "include-qsh-mode",
                      dataTestId: "include-qsh-mode",
                      checked: qshMode === false,
                      onChange: setQshMode(false),
                    },
                    {
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_QSH_MODE_DEFINITION_RADIO_ADVANCED"
                      ),
                      id: "advanced-qsh-mode",
                      dataTestId: "advanced-qsh-mode",
                      checked: isAdvanced,
                      onChange: setQshMode({
                        nameList: [],
                        actionType: "include",
                      }),
                    },
                  ]}
                />
              ),
            }}
            divider={isAdvanced}
            dataTestId="qsh-mode"
          />

          {isAdvanced && (
            <>
              <PropertyDefinitionCard
                title={t("FEATURE_CARD_QSH_MODE_STRING_TITLE")}
                additionalInfoTitle={t(
                  "FEATURE_CARD_QSH_MODE_STRING_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "FEATURE_CARD_QSH_MODE_STRING_DESCRIPTION_CONTENT"
                )}
                fieldType={FieldType.OtherType}
                maxWidth={maxWidth}
                errorMessage={
                  ((errors.qshMode as Exclude<
                    ValidationErrors["qshMode"],
                    FieldError
                  >)?.nameList as FieldError | undefined)?.message
                }
                fieldProps={{
                  other: config.simpleListDefinitions ? (
                    <DefinitionDropdown
                      id="qshMode.nameList"
                      disabled={isViewMode}
                      type={FeatureType.STRING}
                      items={getDefsByType(
                        "string",
                        config.simpleListDefinitions
                      )}
                      selected={config.simpleListDefinitions.filter(
                        selectedNames(
                          (qshMode as Exclude<typeof qshMode, boolean>).nameList
                        )
                      )}
                      onSelect={(selected) => {
                        handleCreateFeature(
                          "qshMode.nameList",
                          selected.map(toName)
                        );
                      }}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      dataTestId="qsh-mode-advanced"
                      parentConfig={config}
                    />
                  ) : (
                    <StringDefinitionModal
                      parentConfig={config}
                      onSubmit={handleCreate}
                    />
                  ),
                }}
                divider
                dataTestId="qsh-mode-advanced"
              />

              <PropertyDefinitionCard
                title={t("FEATURE_CARD_QSH_MODE_ACTION_TITLE")}
                additionalInfoTitle={t(
                  "FEATURE_CARD_QSH_MODE_ACTION_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "FEATURE_CARD_QSH_MODE_ACTION_DESCRIPTION_CONTENT"
                )}
                fieldType={FieldType.OtherType}
                fieldProps={{
                  other: (
                    <RadioButtonsSelector
                      inline
                      headlessRadio={true}
                      radioButtonsProps={[
                        {
                          ...register("qshMode.actionType"),
                          value: "include",
                          disabled: isViewMode,
                          label: t(
                            "FEATURE_CARD_QSH_MODE_ACTION_RADIO_1_LABEL"
                          ),
                          id: "qsh-action-include",
                          dataTestId: "qsh-action-include",
                        },
                        {
                          ...register("qshMode.actionType"),
                          value: "exclude",
                          disabled: isViewMode,
                          label: t(
                            "FEATURE_CARD_QSH_MODE_ACTION_RADIO_2_LABEL"
                          ),
                          id: "qsh-action-exclude",
                          dataTestId: "qsh-action-exclude",
                        },
                      ]}
                    />
                  ),
                }}
                dataTestId="qsh-mode-advanced-action"
              />
            </>
          )}
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"qshMode"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId={"qsh"}
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};
