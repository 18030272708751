import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { UserRoles } from "../../../models/permissions";
import { NewPropertyModalChild } from "../../../modules/caching/configurations/configuration_page/properties/NewPropertyModal";
import {
  useProperties,
  useSelectedConfiguration,
} from "../../../store/slices/caching/hooks";
import {
  ConfigurationType,
  PropertyDefinitionType,
} from "../../../store/slices/caching/types";
import { ArrowLeftIcon } from "../../atoms/ArrowLeftIcon/ArrowLeftIcon";
import { Button } from "../../atoms/Button/Button";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { Modal } from "../../atoms/Modal/Modal";
import { Protected } from "../../atoms/Protected/Protected";
import { Dropdown } from "../Dropdown/Dropdown";
import { submitConfiguration } from "../../../store/slices/caching/services";
import { useAppDispatch } from "../../../store/types";
import { handleSaveSelectedConfiguration } from "../../../store/slices/caching/thunks";
import { toast } from "react-toastify";
import { APIError } from "../../../models/error";
import { handleAPIError } from "../../../store/slices/api-error/thunks";

export interface PropertyHeaderProps {
  property: PropertyDefinitionType;
  configInModification?: ConfigurationType;
  onSave: () => void;
}

export const PropertyHeader = ({
  property,
  configInModification,
  onSave,
}: PropertyHeaderProps): ReactElement => {
  const properties = useProperties();
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationPropertyPage");
  const [tConfigurationPage] = useTranslation("configurationPage");
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [openRenameModal, setOpenRenameModal] = useState(false);

  const renameProperty = (name: string, primaryAlias?: string) => {
    const event = new CustomEvent<{ name: string; primaryAlias?: string }>(
      "rename_property_event",
      {
        detail: {
          name,
          primaryAlias,
        },
      }
    );
    document.dispatchEvent(event);
  };

  return (
    <PropertyPageHeaderContainer>
      <Header>
        <LeftSection>
          <TitleContainer>
            <ArrowLeftIcon
              onClick={() => {
                const event = new CustomEvent("navback_property_event");
                document.dispatchEvent(event);
              }}
              dataTestId="property-header-navback-arrow"
            />
            <label
              className="-text--h2"
              data-testid="property-header-property-name"
            >
              {property.name}
            </label>
            {!properties.some((p) => p.name === property.name) && (
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <EditIcon
                  name={Icons.EDIT}
                  onClick={() => {
                    setOpenRenameModal(true);
                  }}
                  dataTestId="property-header-property-rename-button"
                  size={14}
                />
              </Protected>
            )}
          </TitleContainer>
        </LeftSection>
        <ButtonsContainer>
          <Dropdown
            items={[
              {
                label: t("JSON_EDITOR_BUTTON"),
                value: "JSON",
                dataTestId: "property-header-raw-json",
              },
            ]}
            id="property-json-editor-dropdown"
            placeholder=""
            onSelect={(item) => {
              switch (item.value) {
                case "JSON":
                  history.push(
                    `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${property.name}/raw-json`
                  );
                  break;
              }
            }}
            customButton={
              <Icon
                name={Icons.TRIPLE_DOT}
                color="secondary"
                size={{ width: 20, height: 5 }}
                dataTestId="property-header-raw-json"
              />
            }
          />
          <Protected permissions={UserRoles.EDIT_CONFIG}>
            <Button
              onClick={() => {
                if (selectedConfiguration) {
                  submitConfiguration(selectedConfiguration)
                    .then(
                      ({
                        versions,
                        versionId,
                        isLocalDraft,
                        modifiedTime,
                        genSchemaVersion,
                      }) => {
                        const newConfig: ConfigurationType = {
                          ...selectedConfiguration,
                          versions,
                          versionId,
                          isLocalDraft,
                          modifiedTime,
                          schemaVersion:
                            genSchemaVersion ??
                            selectedConfiguration.schemaVersion,
                        };

                        if (newConfig.config) {
                          newConfig.config = {
                            ...newConfig.config,
                            genSchemaVersion:
                              genSchemaVersion ??
                              newConfig.config.genSchemaVersion,
                          };
                        }

                        dispatch(
                          handleSaveSelectedConfiguration(newConfig, false)
                        );
                        toast.success(
                          `${tConfigurationPage(
                            "CONFIGURATION_SUBMIT_SUCCESS",
                            { id: versionId }
                          )}`
                        );
                      }
                    )
                    .catch((err) => {
                      dispatch(handleAPIError(err as APIError));
                    });
                }
              }}
              label={tConfigurationPage("CONFIGURATION_SUBMIT_BUTTON")}
              backgroundColor="baseLight"
              textColor="highlight"
              borderColor="highlight"
              dataTestId="property-header-submit-button"
              disabled={!selectedConfiguration?.isLocalDraft}
            />
            <Button
              disabled={!configInModification}
              onClick={onSave}
              label={t("SAVE_BUTTON")}
              dataTestId="property-header-save-button"
            />
          </Protected>
        </ButtonsContainer>
        <Modal
          open={openRenameModal}
          customButton={<></>}
          onClose={() => {
            setOpenRenameModal(false);
          }}
        >
          <NewPropertyModalChild onRenameProperty={renameProperty} />
        </Modal>
      </Header>
    </PropertyPageHeaderContainer>
  );
};

const PropertyPageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: ${({ theme }) => `1rem ${theme.spacings.pageContainer}`};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
`;

const EditIcon = styled(Icon)`
  margin-left: 16px;
  fill: ${({ theme }) => theme.icon.muted};
  cursor: pointer;
`;
