import { ReactElement } from "react";
import styled from "@emotion/styled";

import { MenuItem } from "../../molecules/AnchorMenu/AnchorMenu";

export interface AnchorMenuItemProps {
  item: MenuItem;
  active: boolean;
  isChild: boolean;
}

export const AnchorMenuItem = ({
  item,
  active,
  isChild,
}: AnchorMenuItemProps): ReactElement => (
  <li>
    <Link
      href={`#${item.id}`}
      data-testid={`anchor_menu_item-${item.id}`}
      className="chi-label"
      aria-label={`Scroll to ${item.name}`}
      active={active}
      isChild={isChild}
    >
      {item.name}
    </Link>
  </li>
);

const Link = styled.a<{
  active: boolean;
  isChild: boolean;
}>`
  color: ${({ active, theme }) =>
    active
      ? `${theme.text.highlight} !important`
      : `${theme.text.primary} !important`};
  font-weight: ${({ isChild, active }) =>
    active || !isChild ? "600 !important" : "400 !important"};
  text-decoration: none !important;
  font-size: ${({ isChild }) => !isChild && "1rem !important"};
  padding: 5px 10px;
  border-left: ${({ active, theme, isChild }) =>
    active && !isChild && `2px solid ${theme.borders.highlight}`};
`;
