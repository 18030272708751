import { AxiosError } from "axios";
import { httpClient } from "../../core/http-client";

import { ErrorLevel, CMv3APIError, APIError } from "../error";

export interface IBanner {
  id: string;
  notificationId: number;
  name: string;
  message: string;
  priority: boolean;
}

interface IBannerData {
  data: IBanner[];
}

const baseURL =
  process.env.REACT_APP_BANNERS_HOST ||
  "https://lab-edge-delivery.netauto.nsatc.net/api";

export class Banner {
  public id: string;
  public notificationId: number;
  public name: string;
  public message: string;
  public priority: boolean;

  constructor(banner: IBanner) {
    this.id = banner.id;
    this.notificationId = banner.notificationId;
    this.name = banner.name;
    this.message = banner.message;
    this.priority = banner.priority;
  }

  public static async getAll(userId: number): Promise<Banner[]> {
    try {
      const { data } = await httpClient.get<IBannerData>("/banners", {
        baseURL,
        params: {
          acknowledge: false,
          userId,
          active: true,
        },
      });

      return data.data.map((banner) => new Banner(banner));
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;
      throw new APIError(
        error.message,
        "Banner.getAll()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }

  public static async acknowledge(
    id: string,
    notificationId: number,
    userId: number
  ): Promise<boolean> {
    try {
      const { data } = await httpClient.post<Record<string, never>>(
        "/banners/acknowledge",
        {},
        {
          baseURL,
          params:
            notificationId > 0
              ? {
                  userId,
                  notificationId,
                }
              : {
                  userId,
                  bannerId: id,
                },
        }
      );
      return data;
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;
      throw new APIError(
        error.message,
        "Banner.acknowledge()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }
}
