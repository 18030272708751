import { Status } from "../../../@types/status";
import { MetroHistory } from "../../../modules/rtm/components/templates/Locations/types";
import {
  IHistorical,
  Property,
  RegionHistory,
  Site,
  SiteByMetro,
  SiteByProperty,
  SiteByRegion,
} from "../../../modules/rtm/models/models";

export enum SetupStatus {
  SETUP = "setup",
}

export type RTMStatus = Status | SetupStatus;

export interface RTMSlice {
  currentByMetro?: SiteByMetro[];
  currentByRegion?: SiteByRegion[];
  currentByProperty?: SiteByProperty[];
  currentAccessGroupData?: Site;
  currentDataStatus: RTMStatus;
  currentPropertyStatus: RTMStatus;
  historical?: IHistorical;
  historyStatus: RTMStatus;
  properties?: Property[];
  regionsHistory: RegionHistory[];
  regionHistoryStatus: RTMStatus;
  metrosHistory: MetroHistory[];
  metroHistoryStatus: RTMStatus;
}
