import { useAppSelector } from "../../types";
import { VyvxSlice } from "./types";

export const useVyvxAvailableRoles = (): VyvxSlice["availableRoles"] =>
  useAppSelector((store) => store.vyvx.availableRoles);

export const useVyvxAvailableFiberCompanies = (): VyvxSlice["availableFiberCompanies"] =>
  useAppSelector((store) => store.vyvx.availableFiberCompanies);

export const useVyvxAvailableSatelliteCompanies = (): VyvxSlice["availableSatelliteCompanies"] =>
  useAppSelector((store) => store.vyvx.availableSatelliteCompanies);

export const useVyvxStatus = (): VyvxSlice["vyvxStatus"] =>
  useAppSelector((store) => store.vyvx.vyvxStatus);
