import { useState } from "react";

import { ORDERED_DEF_NAMES } from "../../store/slices/caching/constants";
import { getOrderedDefNames } from "./helpers";

export const useOrderedDefinitions = (
  initialValue = ORDERED_DEF_NAMES
): {
  definitionsToDisplay: typeof ORDERED_DEF_NAMES;
  setDefinitionsToDisplay: (defNames: typeof ORDERED_DEF_NAMES) => void;
} => {
  const [definitionsToDisplay, setDefinitionsToDisplay] = useState(
    initialValue
  );

  return {
    definitionsToDisplay,
    setDefinitionsToDisplay: (definitions) => {
      setDefinitionsToDisplay(getOrderedDefNames(definitions));
    },
  };
};
