import { AxiosError } from "axios";

import { httpClient } from "../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../error";

export const sendFeedback = async (
  subject: string,
  message: string,
  files?: File[]
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message", message);
    files?.forEach((file) => {
      formData.append(file.name, file);
    });

    const { data } = await httpClient.post<FormData>("/feedbacks", formData);
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "Feedback.send()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
