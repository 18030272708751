import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Status } from "../../../@types/status";
import {
  useDispatchFeatureFlags,
  useFeatureFlags,
  useFeatureFlagsStatus,
} from "../../../store/slices/feature-flags/hooks";
import { RENDER_LOADER } from "../../../store/slices/feature-flags/thunks";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";

export const FeatureFlags: FC = ({ children }) => {
  const dispatchFeatureFlags = useDispatchFeatureFlags();
  const featureFlagsStatus = useFeatureFlagsStatus();
  const { featureFlagsInitialized } = useFeatureFlags();

  const intervalTimeoutRef = useRef<number>();
  const [t] = useTranslation("layers");

  useEffect(() => {
    dispatchFeatureFlags(featureFlagsInitialized ? undefined : RENDER_LOADER);

    intervalTimeoutRef.current = setInterval(dispatchFeatureFlags, 30000);

    return () => {
      clearInterval(intervalTimeoutRef.current);
    };

    // Ignore deps dispatchFeatureFlags.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (featureFlagsStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("FEATURE_FLAGS_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (
    featureFlagsStatus === Status.SUCCESS ||
    featureFlagsStatus === Status.ERROR
  ) {
    return <>{children}</>;
  }

  return null;
};
