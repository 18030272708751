import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useOldNavigator } from "./useOldNavigator";
import { OldItems } from "./OldItems";
import { httpClient } from "../../../../core/http-client";
import { Theme, ThemeContext } from "../../../../contexts/themeContext";
import { getHeader, IUserHeader } from "./getHeader";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";

export const OldNavigator = () => {
  const { theme } = useContext(ThemeContext);
  const [header, setHeader] = useState<IUserHeader | null>(null);
  const [generatedMenu] = useOldNavigator({ userHeader: header });
  const handleMediaPortalError = useDispatchAPIError();

  useEffect(() => {
    getHeader().then(setHeader).catch(handleMediaPortalError);
  }, []);

  return (
    <OldNavigatorContainer isdarkmode={theme === Theme.DARK_MODE}>
      <OldNavigatorPart>
        <OldItems menu={{ title: "Home", href: "", items: [] }} />
        {generatedMenu?.length
          ? generatedMenu.map((menu, i) => <OldItems menu={menu} key={i} />)
          : null}
      </OldNavigatorPart>
      <OldNavigatorPart>
        <OldNavigatorLink
          onClick={() => window.open("https://www.lumen.com/en-us/home.html")}
        >
          EXPLORE LUMEN
        </OldNavigatorLink>
        <Separator />
        <OldNavigatorLink
          onClick={() =>
            window.open(
              "https://www.lumen.com/help/en-us/cdn/content-delivery-network.html"
            )
          }
        >
          HELP
        </OldNavigatorLink>
        <Separator />
        <OldNavigatorLink onClick={() => httpClient.goToOldMP("/ui/contactUs")}>
          CONTACT US
        </OldNavigatorLink>
      </OldNavigatorPart>
    </OldNavigatorContainer>
  );
};

const OldNavigatorContainer = styled.div<{ isdarkmode: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 30px;
  background-color: ${({ isdarkmode }) => (isdarkmode ? "#083176" : "#0175c9")};
  color: #ffffff;
  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`};

  & hr {
    background-color: #ffffff;
  }
`;

const OldNavigatorPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const OldNavigatorLink = styled.div`
  font-weight: 600;
  cursor: pointer;
  font-size: 11px;
`;

const Separator = styled.hr`
  height: 15px;
  width: 1px;
  margin: 0px 10px;
  border: none;
`;
