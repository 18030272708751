import { useState } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ArrowLeftIcon } from "../../../../../components/atoms/ArrowLeftIcon/ArrowLeftIcon";
import { Button } from "../../../../../components/atoms/Button/Button";
import { EditIcon } from "../../../../../components/atoms/EditIcon/EditIcon";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";
import { Products } from "./Products";
import { Members } from "./Members";
import { AccessGroup } from "../../../../../components/layers/AccessGroup/AccessGroup";
import {
  useAccessGroupInfos,
  useAccessGroupMembers,
} from "../../../../../store/slices/user-management-access-group/hooks";
import { AccessGroupDetails } from "./AccessGroupDetails";
import { Divider } from "../../../../../components/atoms/Divider/Divider";
import { PageTitle } from "../../../../../components/atoms/PageTitle/PageTitle";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import {
  DeletionConfirmationModalContent,
  DeletionConfirmationModalContentProps,
} from "./DeletionConfirmationModalContent";
import { useBool } from "../../../../../hooks/use-bool/useBool";
import { ChildAccessGroupModal } from "../ChildAccessGroupModal";
import { ErrorLayer } from "../../../../../components/molecules/ErrorLayer/ErrorLayer";
import { EmptyPageDescription } from "../../../../../components/atoms/EmptyPage/EmptyPageWrapper";
import { checkAccessGroupDeletion } from "../../../../../models/user-management/access-group";
import { useAppDispatch } from "../../../../../store/types";
import { handleAPIError } from "../../../../../store/slices/api-error/thunks";
import { APIError, ErrorLevel } from "../../../../../models/error";

export const AccessGroupPage = (): JSX.Element => {
  const history = useHistory();

  const accessGroupInfo = useAccessGroupInfos();

  const { t } = useTranslation("accessGroupsPage");

  const [openDeletionModal, toggleOpenDeletionModal] = useBool(false);
  const [resourcesToBeDeleted, setResourcesToBeDeleted] = useState<
    DeletionConfirmationModalContentProps["resourcesToBeDeleted"]
  >();

  const members = useAccessGroupMembers();

  const dispatch = useAppDispatch();

  const handleCheckDeletion = async () => {
    const membersIds: number[] =
      members
        ?.filter((member) => !member.inherited)
        .map((member) => member.userId) || [];
    if (membersIds.length === 0) {
      toggleOpenDeletionModal();
    } else {
      if (accessGroupInfo) {
        try {
          const data = await checkAccessGroupDeletion(
            accessGroupInfo.accessGroupId
          );
          const toBeDeleted = members
            ?.filter((member) => data.users.includes(member.userId))
            .map(({ email }) => ({ email }));
          setResourcesToBeDeleted(toBeDeleted);
          toggleOpenDeletionModal();
        } catch (err) {
          const error = err as { message: string; reponse: unknown };
          dispatch(
            handleAPIError(
              new APIError(
                error.message,
                "checkAccessGroupDeletion",
                ErrorLevel.ERROR,
                error.reponse
              )
            )
          );
        }
      }
    }
  };

  const handleCloseDeletionModal = () => {
    setResourcesToBeDeleted(undefined);
    toggleOpenDeletionModal();
  };

  return (
    <AccessGroup>
      <Container>
        {!!accessGroupInfo ? (
          <>
            <Modal
              size="large"
              open={openDeletionModal}
              customButton={<></>}
              onClose={handleCloseDeletionModal}
            >
              <DeletionConfirmationModalContent
                accessGroupInfo={accessGroupInfo}
                resourcesToBeDeleted={resourcesToBeDeleted}
              />
            </Modal>
            <HeaderContainer>
              <FlexBox>
                <ArrowLeftIcon
                  onClick={() => {
                    history.push("/userManagement/accessGroups");
                  }}
                />
                <PageTitle>{accessGroupInfo?.name}</PageTitle>
                <Modal customButton={<EditIcon />} size="large">
                  <ChildAccessGroupModal
                    current={{
                      id: accessGroupInfo.accessGroupId,
                      name: accessGroupInfo.name,
                      description: accessGroupInfo.description,
                    }}
                    parent={{
                      id: accessGroupInfo.parentAccessGroupId,
                      name: accessGroupInfo.parentAccessGroupName,
                    }}
                    domain={{
                      id: accessGroupInfo.domainAccessGroupId,
                    }}
                    isEdit
                  />
                </Modal>
              </FlexBox>
              <ButtonsContainer>
                <Button
                  onClick={handleCheckDeletion}
                  label={t("ACCESS_GROUP_DELETION_DELETE")}
                  disabled={
                    accessGroupInfo.accessGroupId ===
                    accessGroupInfo.domainAccessGroupId
                  }
                  borderColor="red50"
                  backgroundColor="baseLight"
                  textColor="red50"
                />
                <Modal
                  openButtonProps={{
                    label: t("ACCESS_GROUPS_CREATE_CHILD_BUTTON"),
                  }}
                  size="large"
                >
                  <ChildAccessGroupModal
                    current={{
                      id: 0,
                      name: "",
                      description: "",
                    }}
                    parent={{
                      id: accessGroupInfo.accessGroupId,
                      name: accessGroupInfo.name,
                    }}
                    domain={{
                      id: accessGroupInfo.domainAccessGroupId,
                    }}
                  />
                </Modal>
              </ButtonsContainer>
            </HeaderContainer>
            <AccessGroupDetails accessGroupInfo={accessGroupInfo} />
            <StyledDivider />
            <Products />
            <br />
            <Members />
          </>
        ) : (
          <ErrorLayer
            title="Missing access group data"
            content={
              <EmptyPageDescription>
                <div>{t("ACCESS_GROUP_NOT_FOUND")}</div>
                <Button
                  onClick={() => {
                    history.push("/userManagement/accessGroups");
                  }}
                  label="Back to access groups list"
                />
              </EmptyPageDescription>
            }
          />
        )}
      </Container>
    </AccessGroup>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
`;

const HeaderContainer = styled(FlexBox)`
  justify-content: space-between;
`;

const ButtonsContainer = styled(FlexBox)`
  & > * {
    margin-right: 16px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 40px !important;
  margin-bottom: 40px !important;
`;
