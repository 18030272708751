import { Dispatch, Ref, SetStateAction, useRef, useState } from "react";
import { useTransition, config, TransitionFn } from "react-spring";

import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { useClickOutsideRef } from "../../../hooks/use-detect-click-outside-ref";

export type UseDropDownReturnType<Data> = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  dropdownRef: Ref<HTMLDivElement>;
  opening: TransitionFn<
    boolean,
    {
      opacity: number;
      height: string;
    }
  >;
  handleSelection: (selected: IDropdownItem<Data>) => void;
};

export const useDropdown = <T>({
  items,
  multiSelect = false,
  selectedItems,
  setSelectedItems,
  onClose,
}: {
  items: IDropdownItem<T>[];
  multiSelect?: boolean;
  selectedItems: IDropdownItem<T>[];
  setSelectedItems: (values: IDropdownItem<T>[]) => void;
  onClose?: () => void;
}): UseDropDownReturnType<T> => {
  const [isOpen, setIsOpen] = useState(false);

  const opening = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0px" },
    enter: { opacity: 1, height: `${items.length * 2}rem` },
    leave: { opacity: 0, height: "0px" },
  });

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideRef(dropdownRef, () => {
    setIsOpen(false);
    if (onClose) onClose();
  });

  const handleSelection = (selected: IDropdownItem<T>) => {
    if (multiSelect) {
      let newValue: IDropdownItem<T>[] = [];
      if (selectedItems?.find((element) => element.value === selected.value)) {
        newValue = selectedItems?.filter(
          (item) => item.value !== selected.value
        );
      } else {
        newValue = selectedItems ? [...selectedItems, selected] : [selected];
      }
      setSelectedItems(newValue);
    } else {
      setSelectedItems([selected]);
    }
  };

  return {
    isOpen,
    setIsOpen,
    dropdownRef,
    opening,
    handleSelection,
  };
};
