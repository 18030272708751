export const clamp = (n: number, min: number, max: number): number => {
  return Math.max(Math.min(n, max), min);
};

export const isNumber = (input: unknown): boolean => {
  return !isNaN(Number(input));
};

export const formatNumber = (n: number): string =>
  n === 0
    ? "0"
    : n.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
