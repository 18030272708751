import {
  Fragment,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  OnScrollEventDetails,
  useScrollLayoutListener,
} from "../../../hooks/use-scroll-layout";
import { AnchorMenuItem } from "../../atoms/AnchorMenuItem/AnchorMenuItem";

export interface MenuItem {
  name: string;
  id: string;
  ref: MutableRefObject<any | null>;
  subItems?: MenuItem[];
  value?: string;
}

export interface AnchorMenuProps {
  menuItems: MenuItem[];
  scrollMargin?: number;
  hasPadding?: boolean;
  scrollId: string;
  defaultActive?: number;
  isChild?: boolean;
  footer?: ReactNode;
}

export const AnchorMenu = ({
  hasPadding = true,
  menuItems,
  scrollId,
  defaultActive,
  isChild = false,
  footer,
}: AnchorMenuProps): ReactElement => {
  const [activeItem, setActiveItem] = useState<MenuItem | undefined>(
    defaultActive !== undefined ? menuItems[defaultActive] : undefined
  );

  const itemRefs = menuItems.map((item) => ({
    name: item.name,
    id: item.id,
    subItems: item.subItems,
    ref: item.ref,
  }));

  useScrollLayoutListener(
    scrollId,
    (element: CustomEvent<OnScrollEventDetails>) => {
      const { offsetTop } = element.detail;
      const selected = itemRefs.find(({ ref, name }, i) => {
        const elem = ref.current;
        if (elem) {
          const bounds = elem.getBoundingClientRect();

          const top = Math.round(bounds.top);
          const bottom = bounds.bottom;

          return i === 0
            ? bottom > offsetTop
            : top <= offsetTop && bottom > offsetTop;
        }
      });

      if (selected && selected.id !== activeItem?.id) {
        setActiveItem(selected);
      }
      if (isChild) {
        const lastItem = itemRefs
          .slice(0)
          .reverse()
          .find((item) => item.ref.current);
        if (lastItem) {
          if (
            lastItem.ref.current &&
            lastItem.ref.current.getBoundingClientRect().bottom < offsetTop
          ) {
            setActiveItem(undefined);
          }
        }
        const firstItem = itemRefs.find((item) => item.ref.current);
        if (firstItem) {
          if (
            firstItem.ref.current &&
            firstItem.ref.current.getBoundingClientRect().top > offsetTop
          ) {
            setActiveItem(undefined);
          }
        }
      }
    }
  );

  return (
    <MenuContainer>
      <MenuList hasPadding={hasPadding}>
        {menuItems.map((item, i) => (
          <Fragment key={i}>
            <AnchorMenuItem
              item={item}
              key={`menuitem_${item}`}
              active={!!activeItem && item.id === activeItem.id}
              isChild={isChild}
            />
            {item.subItems && item.subItems.length > 0 && (
              <AnchorMenu
                menuItems={item.subItems}
                scrollId={scrollId}
                isChild={true}
              />
            )}
          </Fragment>
        ))}
      </MenuList>
      {footer && <Footer>{footer}</Footer>}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: sticky;
  top: 30px;
  align-self: flex-start;
  margin-right: 50px;
`;

const MenuList = styled.ul<{ hasPadding: boolean }>`
  width: 200px;
  list-style-position: outside;
  list-style-type: none;
  ${({ hasPadding }) =>
    !hasPadding
      ? css`
          padding-left: 0px;
        `
      : null};
`;

const Footer = styled.footer`
  margin-left: 2em;
  width: 100%;
`;
