import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ConfigurationErrorLevel } from "../../../models/configuration/errors";
import { UnsavedChangesToast } from "../../../modules/caching/configurations/configuration_page/properties/property_page/UnsavedChangesToast";
import { handleRemoveConfigurationError } from "../../../store/slices/caching/thunks";
import { useAppDispatch } from "../../../store/types";

export interface CustomLocation {
  pathname: string;
  search: string;
}

export enum BlockingType {
  STANDARD = "standard",
  BLOCK_LEAVING = "block_leaving",
  VALIDATION_ERROR = "validation_error",
}

export interface BlockingNavModalProps {
  when?: boolean | undefined;
  shouldBlockNavigation: (location: CustomLocation) => boolean | BlockingType;
  onSave: () => Promise<void | boolean>;
  dataTestId?: string;
  customNavigator?: () => unknown;
}

export const BlockingNavModal = ({
  when,
  shouldBlockNavigation,
  onSave,
  dataTestId,
  customNavigator = undefined,
}: BlockingNavModalProps): ReactElement => {
  const history = useHistory();

  const { t } = useTranslation("configurationPropertyPage");

  const dispatch = useAppDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<CustomLocation>();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [blockingType, setBlockingType] = useState<BlockingType>(
    BlockingType.STANDARD
  );

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: {
    pathname: string;
    search: string;
  }): boolean => {
    if (!confirmedNavigation) {
      const res = shouldBlockNavigation(nextLocation);
      if (typeof res === "string") {
        setBlockingType(res);
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      } else {
        if (res) {
          setBlockingType(BlockingType.STANDARD);
          setModalVisible(true);
          setLastLocation(nextLocation);
          return false;
        }
      }
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    dispatch(
      handleRemoveConfigurationError(
        (err) => !(err.level === ConfigurationErrorLevel.ERROR)
      )
    );
  };

  const handleSaveClicked = async () => {
    if ((await onSave()) !== false) {
      handleConfirmNavigationClick();
    }
  };

  useEffect(() => {
    if (modalVisible) {
      switch (blockingType) {
        case BlockingType.STANDARD:
          toast(
            <UnsavedChangesToast
              description={t("WARNING_UNSAVED_CHANGES_DESCRIPTION")}
              buttons={[
                {
                  label: t("WARNING_UNSAVED_CHANGES_LEAVE"),
                  dataTestId: "property-toast-discard-changes-button",
                  backgroundColor: "baseLight",
                  textColor: "primary",
                  borderColor: "mutedLight",
                  onClick: handleConfirmNavigationClick,
                },
                {
                  onClick: handleSaveClicked,
                  label: t("WARNING_UNSAVED_CHANGES_SAVE"),
                  dataTestId: "property-toast-save-button",
                },
              ]}
            />,
            {
              autoClose: false,
              progress: 1,
              progressStyle: {
                background: "orange",
              },
              onClose: closeModal,
            }
          );
          break;
        case BlockingType.BLOCK_LEAVING:
          toast(
            <UnsavedChangesToast
              description={t("WARNING_UNSAVED_CHANGES_DESCRIPTION_FORCED")}
              title={t("WARNING_UNSAVED_CHANGES_TITLE_FORCED")}
              buttons={[
                {
                  onClick: handleSaveClicked,
                  label: t("SAVE_BUTTON"),
                },
              ]}
            />,
            {
              autoClose: false,
              progress: 1,
              progressStyle: {
                background: "orange",
              },
              onClose: closeModal,
              style: {
                width: 400,
                right: "30%",
              },
              toastId: `${dataTestId}-save-error-block-leaving`,
            }
          );
          break;
        case BlockingType.VALIDATION_ERROR:
          toast.error(t("ERROR_INCORRECT_FIELDS"), {
            autoClose: false,
            progress: 1,
            onClose: closeModal,
          });
          break;
      }
    }
  }, [modalVisible]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (typeof customNavigator !== "undefined") {
        customNavigator();
      } else {
        history.push(
          `${lastLocation.pathname}${
            lastLocation.search ? lastLocation.search : ""
          }`
        );
      }
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <Prompt
      when={when}
      message={({ pathname, search }) =>
        handleBlockedNavigation({ pathname, search })
      }
    />
  );
};
