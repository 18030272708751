import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Status } from "../../../@types/status";
import { useVyvxStatus } from "../../../store/slices/vyvx/hooks";
import { fetchVyvxData } from "../../../store/slices/vyvx/thunk";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";

export const VyvxLayer: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVyvxData());
  }, []);

  const vyvxStatus = useVyvxStatus();

  const { t } = useTranslation("layers");

  if (vyvxStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("VYVX_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  return <>{children}</>;
};
