import { useEffect, useState, FC } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { WidgetLayout } from "../../../components/molecules/WidgetLayout/WidgetLayout";
import { BannersDataTable } from "./components/templates/Table/BannerDataTable";
import { BannersModal } from "./components/templates/Table/BannersModal";
import {
  BannerHeader,
  BannersData,
  createBanners,
  updateBanners,
  deleteBanners,
  expiredBanners,
  getBanners,
} from "./models/models";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { LoaderContainer } from "../../../components/atoms/LoaderContainer/LoaderContainer";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { APIError } from "../../../models/error";

export const Banners: FC = () => {
  const { t } = useTranslation("bannersIndexPage");

  const [banners, setBanners] = useState<BannersData[]>([]);
  const [loadingBanners, setLoadingBanners] = useState<boolean>(false);
  const handleMediaPortalError = useDispatchAPIError();

  const handleDeleteBanners = async (bannerId: string) => {
    try {
      await deleteBanners(bannerId);
      toast.success(t("DELETE_SUCCEED"));
      fetchBanners();
    } catch (error) {
      handleMediaPortalError(error as APIError);
    }
  };

  const handleExpiredBanners = async (
    bannerId: string,
    notificationId: number
  ) => {
    try {
      await expiredBanners(bannerId, notificationId);
      toast.success(t("EXPIRED_SUCCEED"));
      fetchBanners();
    } catch (error) {
      handleMediaPortalError(error as APIError);
    }
  };

  const fetchBanners = async () => {
    setLoadingBanners(true);
    try {
      const response = await getBanners();
      setBanners(response.data);
    } catch (error) {
      handleMediaPortalError(error as APIError);
      setBanners([]);
    }
    setLoadingBanners(false);
  };

  const handleSaveBanners = async (
    banner: BannerHeader,
    isUpdate: boolean,
    bannerId?: string
  ) => {
    try {
      if (!isUpdate) {
        await createBanners(banner);
        toast.success(t("CREATE_SUCCEED"));
      } else if (isUpdate && bannerId) {
        await updateBanners(banner, bannerId);
        toast.success(t("UPDATE_SUCCEED"));
      }
      fetchBanners();
    } catch (error) {
      handleMediaPortalError(error as APIError);
    }
  };
  useEffect(() => {
    fetchBanners();
  }, []);

  return loadingBanners ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <WidgetLayout title={t("LIST_OF_BANNERS")}>
        <BannersModal
          handleSaveBanners={handleSaveBanners}
          isUpdate={false}
          dataTestId="banners-modal"
        />
        <BannersDataTable
          banners={banners}
          handleDeleteBanners={handleDeleteBanners}
          handleSaveBanners={handleSaveBanners}
          handleExpiredBanners={handleExpiredBanners}
          dataTestId="banners"
        />
      </WidgetLayout>
    </>
  );
};
