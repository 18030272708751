import { FC } from "react";
import styled from "@emotion/styled";

import { Dropdown, IDropdownItem } from "../../organisms/Dropdown/Dropdown";
import { InputField, InputFieldProps } from "../InputField/InputField";

interface SearchBarProps<T = string> {
  dropdownID?: string;
  dropdownItems?: IDropdownItem<T>[];
  dropdownPlaceholder?: string;
  dropdownTestID?: string;
  onDropdownSelect?: (selectedItem: IDropdownItem<T>) => void;
  onSearchChange: InputFieldProps["onChange"];
  searchPlaceholder: InputFieldProps["placeholder"];
  searchTestID: string;
}

export const SearchBar: FC<SearchBarProps> = ({
  dropdownID,
  dropdownItems,
  dropdownPlaceholder = "",
  dropdownTestID = "",
  onDropdownSelect,
  onSearchChange,
  searchPlaceholder,
  searchTestID,
}) => {
  const shouldRenderDropdown =
    !!dropdownItems && typeof onDropdownSelect !== "undefined";

  return (
    <SearchContainer>
      <InputField
        isSearchField
        hasBorder
        onChange={onSearchChange}
        placeholder={searchPlaceholder}
        dataTestId={searchTestID}
      />
      {shouldRenderDropdown && (
        <DropdownContainer data-testid={dropdownID}>
          <Dropdown
            items={dropdownItems!}
            id={dropdownTestID}
            placeholder={dropdownPlaceholder}
            onSelect={onDropdownSelect!}
          />
        </DropdownContainer>
      )}
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  height: 40px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
`;
