import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type OriginFillPolicyComponent = FeatureComponent<
  Exclude<IFeatures["originFillPolicy"], undefined>
>;

export const OriginFillPolicy: OriginFillPolicyComponent = ({
  feature,
  tPropertyPage,
  dataTestId,
}) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_OFP_TITLE")}
      dataTestId={`${dataTestId}-name`}
    >
      {feature}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
