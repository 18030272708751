import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { Button, ButtonProps } from "../../atoms/Button/Button";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { IModalChildren, Modal } from "../../atoms/Modal/Modal";
import { InputField } from "../InputField/InputField";

interface CustomSaveButton extends Omit<ButtonProps, "onClick"> {
  onClick: (name: string) => void;
}

interface CustomCancelButton extends Omit<ButtonProps, "onClick"> {
  onClick?: () => void;
}

export interface NamingModalProps {
  id: string;
  openButtonProps?: Omit<ButtonProps, "onClick">;
  title: string;
  description: string;
  info?: string;
  inputPlaceholder: string;
  saveButtonProps: CustomSaveButton;
  cancelButtonProps: CustomCancelButton;
  checkErrors?: (value: string) => string[];
}

export const NamingModal = ({
  id,
  openButtonProps = {
    label: "Open Modal",
  },
  title,
  description,
  info,
  inputPlaceholder,
  saveButtonProps,
  cancelButtonProps,
  checkErrors,
}: NamingModalProps): ReactElement => (
  <Modal openButtonProps={openButtonProps} size="large">
    <NamingModalContent
      id={id}
      title={title}
      description={description}
      info={info}
      inputPlaceholder={inputPlaceholder}
      saveButtonProps={saveButtonProps}
      cancelButtonProps={cancelButtonProps}
      checkErrors={checkErrors}
    />
  </Modal>
);

interface NewConfigurationModalChildProps extends IModalChildren {
  id: string;
  title: string;
  description: string;
  info?: string;
  inputPlaceholder: string;
  saveButtonProps: CustomSaveButton;
  cancelButtonProps: CustomCancelButton;
  checkErrors?: (value: string) => string[];
}

export const NamingModalContent = ({
  id,
  title,
  description,
  info,
  inputPlaceholder,
  saveButtonProps,
  cancelButtonProps,
  checkErrors,
  closeModal,
}: NewConfigurationModalChildProps): ReactElement => {
  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === 13 && errors.length === 0) {
        saveButtonProps.onClick(name);
        closeModal && closeModal();
      }
    };

    modalRef.current?.addEventListener("keydown", onKeyDown);

    return () => modalRef.current?.removeEventListener("keydown", onKeyDown);
  }, [errors, name, modalRef]);

  return (
    <NewPropertyModalContainer ref={modalRef} data-testid={`${id}-container`}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <InputContainer>
        <InputField
          placeholder={inputPlaceholder}
          value={name}
          hasBorder
          onChange={(value: string) => {
            if (checkErrors) setErrors(checkErrors(value));
            setName(value);
          }}
          dataTestId={`${id}-input`}
        />
        {errors.map((err, i) => (
          <Error key={i} data-testid={`${id}-error`}>
            {err}
          </Error>
        ))}
      </InputContainer>
      {info && (
        <InfoCard>
          <InfoIcon name={Icons.INFO} size={32} />
          {info}
        </InfoCard>
      )}
      <Buttons>
        <Button
          backgroundColor={cancelButtonProps.backgroundColor || "baseLight"}
          textColor={cancelButtonProps.textColor || "highlight"}
          borderColor={cancelButtonProps.borderColor || "highlight"}
          size={cancelButtonProps.size || "lg"}
          label={cancelButtonProps.label}
          onClick={() => {
            cancelButtonProps.onClick && cancelButtonProps.onClick();
            closeModal && closeModal();
          }}
        />
        <ButtonContainer>
          <Button
            backgroundColor={saveButtonProps.backgroundColor}
            textColor={saveButtonProps.textColor}
            borderColor={saveButtonProps.borderColor}
            size={saveButtonProps.size || "lg"}
            label={saveButtonProps.label}
            onClick={() => {
              saveButtonProps.onClick(name);
              closeModal && closeModal();
            }}
            disabled={name.length === 0 || errors.length > 0}
            dataTestId={`${id}-submit-button`}
          />
        </ButtonContainer>
      </Buttons>
    </NewPropertyModalContainer>
  );
};

const NewPropertyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 32px;
`;

const Title = styled.h3`
  margin-bottom: 50px !important;
  text-align: center;
`;

const Description = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const InputContainer = styled.div`
  margin-bottom: 50px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const Error = styled.div`
  margin-top: 0.5em;
  font-size: 14px;
  color: ${({ theme }) => theme.text.error};
`;

const InfoIcon = styled(Icon)`
  margin-right: 16px;
`;

const InfoCard = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-top: 4px solid ${({ theme }) => theme.borders.info};
  display: flex;
  padding: 24px;
  margin-bottom: 50px;
`;
