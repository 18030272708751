import styled from "@emotion/styled";

export const Link = styled.a`
  color: ${({ theme }) => theme.interactive.linkHover} !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
