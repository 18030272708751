import { ErrorLevel, APIError } from "../../../../models/error";
import { httpClient } from "../../../../core/http-client";

const baseURL =
  process.env.REACT_APP_BANNERS_ADMIN_HOST ||
  "https://lab-portal-int-edge-delivery.netauto.nsatc.net/dashboard";

export enum BannerServiceTypes {
  CACHING = "caching",
  EDGE = "edge compute",
  ITM = "itm",
  MESH = "mesh delivery",
  OBJECT = "object storage",
  ORCHESTRATOR = "orchestrator",
  ORIGIN = "origin storage",
}

export enum BannerUserTypes {
  ALL = "all",
  INTERNAL_ALL = "internals only all",
  INTERNAL_Vyvx = "internals only vyvx",
}

export type BannersData = {
  id: string;
  name: string;
  message: string;
  userType: string;
  serviceType: string;
  priority: boolean;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
  notificationId: number;
};

interface Banners {
  data: BannersData[];
}

export interface BannerHeader {
  name: string;
  message: string;
  serviceType: string;
  priority: boolean;
  userType: string;
  startTime: string;
  endTime: string;
  id?: string;
  notificationId?: number;
}

export interface NewBannersModalChildProps {
  closeModal?: () => void;
  handleSaveBanners: (
    banner: BannerHeader,
    isUpdate: boolean,
    bannerId?: string
  ) => Promise<void>;
  isUpdate: boolean;
  bannerHeader?: BannerHeader;
  dataTestId?: string;
}

export const getBanners = async (): Promise<Banners> => {
  try {
    const { data } = await httpClient.get<Banners>(`/banners`, {
      baseURL,
    });
    return data;
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "getBanners",
      ErrorLevel.ERROR,
      "Failed to GET Banners"
    );
  }
};

export const createBanners = async (
  banners: BannerHeader
): Promise<Banners> => {
  try {
    const { data } = await httpClient.post<BannerHeader>(`/banners`, banners, {
      baseURL,
    });
    return data;
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "createBanners",
      ErrorLevel.ERROR,
      "Failed to CREATE Banner"
    );
  }
};

export const updateBanners = async (
  banners: BannerHeader,
  bannerId: string
): Promise<Banners> => {
  try {
    const { data } = await httpClient.put<BannerHeader>(
      `/banners/${bannerId}`,
      banners,
      {
        baseURL,
      }
    );
    return data;
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "updateBanners",
      ErrorLevel.ERROR,
      "Failed to UPDATE Banner"
    );
  }
};

export const deleteBanners = async (bannerId: string): Promise<void> => {
  try {
    await httpClient.delete<Banners>(`/banners/${bannerId}`, {
      baseURL,
    });
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "deleteBanners",
      ErrorLevel.ERROR,
      "Failed to DELETE Banner"
    );
  }
};

export const expiredBanners = async (
  bannerId: string,
  notificationId: number
): Promise<void> => {
  try {
    notificationId !== 0
      ? await httpClient.post<any>(`/banners/${notificationId}/expire`, true, {
          baseURL,
        })
      : await httpClient.post<any>(`/banners/${bannerId}/expire`, false, {
          baseURL,
        });
  } catch (err) {
    const error = err as any;
    throw new APIError(
      error.message,
      "expiredBanner",
      ErrorLevel.ERROR,
      "Failed to EXPIRED Banner"
    );
  }
};
