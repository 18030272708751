import { TokenDefinitionType } from "../../../store/slices/caching/types";

export interface ITokenDefinition {
  sharedSecrets: string[];
  queryParameterNames?: string[];
  hash?: string;
  nva?: string;
  nvb?: string;
  datePreference: "gmt" | "epoch";
  queryParameterControl: "include" | "exclude";
  description?: string;
}

export const getTokenDefinitionDescription = (
  def: TokenDefinitionType
): string =>
  `nva: ${def.nva ? def.nva : "N/A"}, nvb: ${def.nvb ? def.nvb : "N/A"}`;
