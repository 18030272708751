import { ReactNode, useCallback, useEffect, useState } from "react";
import { UseSpringProps, useSprings } from "react-spring";
import { useGesture } from "react-with-gesture";
import { useIsMounted } from "../../../hooks/use-is-mounted";

import { useRefState } from "../../../hooks/use-ref-state";
import { move } from "../../../utils/array";
import { clamp } from "../../../utils/number";

export interface IItem<T = any> {
  highlight?: boolean;
  title: React.ReactNode | React.ReactNode[] | IItem;
  content: React.ReactNode;
  context: { index: number; obj: T; parentPath?: string };
  errorMessage?: string;
  index?: ReactNode;
  nestedMenu?: (handleDelete: (currentIndex: number) => void) => ReactNode;
}

export interface IDNDItem {
  originalIndex: number;
  node: IItem;
  ref: HTMLDivElement | null;
}

/** Calculate the `y` axis of an item depending on its position of dragged item */
const getDraggedItemPosition = (
  list: IDNDItem[],
  curIndex: number,
  translationY: number,
  margin: number
): UseSpringProps => {
  // TODO: When changing order of large DND, the DND jumps to the index it comes from
  const offsetTop = list.reduce((acc, curr, i) => {
    if (i < curIndex) {
      acc += curr.ref ? curr.ref.offsetHeight + margin : 0;
    }

    return acc;
  }, 0);

  return {
    y: offsetTop + translationY,
    scale: 1.1,
    zIndex: list.length,
    shadow: 15,
    immediate: (n: string) => n === "y" || n === "zIndex",
  };
};

/** Calculate the `y` axis of an item depending on its position of not dragged item */
const getUndraggedItemPosition = (
  list: IDNDItem[],
  originalIndex: number,
  margin: number,
  immediate = true
): UseSpringProps => {
  const numOfElementsBefore = list.findIndex(
    (item) => item.originalIndex === originalIndex
  );

  const offsetTop = list.reduce((acc, curr, i) => {
    if (i < numOfElementsBefore) {
      acc += curr.ref ? curr.ref.offsetHeight + margin : 0;
    }

    return acc;
  }, 0);

  return {
    y: offsetTop,
    scale: 1,
    zIndex: list.length - numOfElementsBefore,
    shadow: 1,
    immediate,
  };
};

/** Check if an item number `index` is being dragged or not */
const isDraggedItem = (
  down?: boolean,
  index?: number,
  originalIndex?: number,
  curIndex?: number,
  y?: number
) => {
  return (
    down && index === originalIndex && curIndex !== undefined && y !== undefined
  );
};

/** This function is called everytime we try to drag an element
 * Applies react-spring style to each item depending on if it's being dragged or not
 */
const handleGestureStyle = (
  list: IDNDItem[],
  margin: number,
  immediate = true,
  down?: boolean,
  originalIndex?: number,
  curIndex?: number,
  y?: number
) => (index: number): UseSpringProps => {
  return isDraggedItem(down, index, originalIndex, curIndex, y)
    ? getDraggedItemPosition(list, curIndex!, y!, margin)
    : getUndraggedItemPosition(list, index, margin, immediate);
};

/** Calculate the current position of an item */
export const getDraggedCurrentPosition = (
  list: IDNDItem[],
  draggedIndexItem: number,
  y: number
): number => {
  return clamp(
    Math.round((draggedIndexItem * 100 + y) / 100),
    0,
    list.length - 1
  );
};

export const useDragAndDrop = (
  items: IItem[],
  itemsMargin: number,
  onOrderChange: (list: IItem[]) => void
) => {
  const isMounted = useIsMounted();
  const [listValue, listRef, setListValue] = useRefState<
    (IDNDItem | undefined)[]
  >(
    items.map((node, i) => ({
      originalIndex: i,
      node,
      ref: null,
    }))
  );

  useEffect(() => {
    const newList: IDNDItem[] = items.map((node, i) => {
      const curIndex = listRef.current.findIndex(
        (item) => item && item.originalIndex === i
      );

      return {
        originalIndex: curIndex !== -1 ? curIndex : i,
        node,
        ref: curIndex !== -1 ? listRef.current[curIndex]!.ref : null,
      };
    });

    setListValue(newList);

    setSprings(handleGestureStyle(newList, itemsMargin));
  }, [items]);

  const [springs, setSprings] = useSprings(
    listRef.current.length,
    handleGestureStyle(
      listRef.current.filter((item) => item !== undefined) as IDNDItem[],
      itemsMargin
    )
  );

  /** This function initialize the refs for each drag and drop item */
  const refCallback = useCallback(
    (ref, originalIndex: number) => {
      const curIndex = listRef.current.findIndex(
        (item) => item && item.originalIndex === originalIndex
      );

      if (curIndex !== -1) {
        listRef.current[curIndex]!.ref = ref;
        if (!listRef.current.some((item) => item && item.ref === null)) {
          setSprings(
            handleGestureStyle(
              listRef.current.filter(
                (item) => item !== undefined
              ) as IDNDItem[],
              itemsMargin
            )
          );
        }
      }
    },
    [listRef, itemsMargin, setSprings]
  );

  const [containerDimensions, setContainerDimensions] = useState({
    height: 0,
    width: 0,
  });

  const bind = useGesture(({ args: [originalIndex], down, delta: [, y] }) => {
    const currentItemIndex = listRef.current.findIndex(
      (item) => item && item.originalIndex === originalIndex
    );

    const curRow = getDraggedCurrentPosition(
      listRef.current.filter((item) => item !== undefined) as IDNDItem[],
      currentItemIndex,
      y
    );

    const newList = move(listRef.current, currentItemIndex, curRow);

    setSprings(
      handleGestureStyle(
        newList.filter((item) => item !== undefined) as IDNDItem[],
        itemsMargin,
        false,
        down,
        originalIndex,
        currentItemIndex,
        y
      )
    );

    if (!down) {
      // adding a timeout to have a smoother transition
      setTimeout(() => {
        if (isMounted.current) {
          setListValue(newList);
        }
      }, 600);

      onOrderChange(
        newList.filter((item) => item !== undefined).map((item) => item!.node)
      );
    }
  });

  /** This function updates the height of the container of the drag and drop items
   * to the height of all items inside
   */
  const handleContainerDimensions = useCallback(() => {
    const height =
      listRef.current
        .map((item) => item?.ref?.offsetHeight || 0)
        .reduce((acc, curr) => acc + curr, 0) +
      listRef.current.filter((item) => item !== undefined).length * itemsMargin;

    const width = Math.max(
      ...listRef.current.map((item) => item?.ref?.offsetWidth || 0)
    );

    if (
      height !== containerDimensions.height ||
      width !== containerDimensions.width
    ) {
      setContainerDimensions({ height, width });
    }
  }, [
    itemsMargin,
    containerDimensions.height,
    containerDimensions.width,
    listRef,
  ]);

  const handleDelete = useCallback(
    (currentIndex: number) => {
      // When deleting we set the item as undefined, to still have the same amount of elements in the list
      // That will avoid having to reset the originalIndex, and the weird animations that ensue
      // We also put it at the end, to avoid having it impact the other items
      const newValues = listValue.filter((_, i) => i !== currentIndex);
      newValues.push(undefined);
      setListValue(newValues);
      handleContainerDimensions();
    },
    [handleContainerDimensions, listValue, setListValue]
  );

  const resize = useCallback(() => {
    setSprings(
      handleGestureStyle(
        listRef.current.filter((item) => item !== undefined) as IDNDItem[],
        itemsMargin
      )
    );
    setTimeout(() => {
      // We need to delay the resize of the container to avoid weird animations if it happened
      // at the same time as the component reorganization
      if (isMounted.current) {
        handleContainerDimensions();
      }
    }, 600);
  }, [listRef, itemsMargin, setSprings, handleContainerDimensions]);

  useEffect(() => {
    if (items.length !== listValue.length) {
      const newList: IDNDItem[] = items.map((node, i) => {
        const curIndex = listValue.findIndex(
          (item) => item && item.originalIndex === i
        );

        return {
          originalIndex: curIndex !== -1 ? curIndex : i,
          node,
          ref: curIndex !== -1 ? listValue[curIndex]!.ref : null,
        };
      });

      setListValue(newList);

      setSprings(handleGestureStyle(newList, itemsMargin));
    }

    setTimeout(() => {
      handleContainerDimensions();
    });
  }, [
    handleContainerDimensions,
    items,
    itemsMargin,
    listValue,
    setListValue,
    setSprings,
  ]);

  return [
    listValue,
    springs,
    containerDimensions,
    handleContainerDimensions,
    bind,
    refCallback,
    handleDelete,
    resize,
  ] as const;
};
