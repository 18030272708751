import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { CreateOriginDefinitionType, OriginDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createOriginDefinition = (
  name: string,
  rest: Pick<CreateOriginDefinitionType, "type" | "members" | "conditions">
): OriginDefinitionType => ({
  ...parseDefinition({
    name,
    type: TypeDefinitions.ORIGIN,
  }),
  originType: rest.type,
  members: rest.members,
  conditions: rest.conditions,
});
