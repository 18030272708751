import { OriginTypes } from "../../../../models/configuration/definitions/origin";
import { OriginDefinitionType } from "../types";

export const removeOriginMember = (
  index: number,
  def: OriginDefinitionType
): OriginDefinitionType["members"] => {
  if (def.originType === OriginTypes.FAILOVER && def.members.length < 2) {
    throw new Error(
      `You can not delete a member when the origin type is failover and the total amount of members is 2. The current state of members is: ${def.members}`
    );
  } else if (def.originType === OriginTypes.SINGLE) {
    throw new Error(
      "You can not delete a member when the origin type is single."
    );
  } else {
    return def.members.filter((_, i) => i !== index);
  }
};
