import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { IModalChildren } from "../../atoms/Modal/Modal";
import { FlexBoxBase } from "../../atoms/FlexBox/FlexBox";
import { Button } from "../../atoms/Button/Button";
import { removeUserFromAccessGroups } from "../../../models/user-management/users";
import { handleAPIError } from "../../../store/slices/api-error/thunks";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../store/types";
import { fetchUser } from "../../../store/slices/user-management-users/thunk";
import { APIError } from "../../../models/error";

interface RemoveUserFromAccessGroupModalProps extends IModalChildren {
  userId: number;
  accessGroupId: number;
}

export const RemoveUserFromAccessGroupModal = ({
  closeModal,
  userId,
  accessGroupId,
}: RemoveUserFromAccessGroupModalProps): ReactElement => {
  const { t } = useTranslation("usersPage");
  const dispatch = useAppDispatch();

  return (
    <Container>
      <Title>{t("REMOVE_GROUP_TITLE")}</Title>
      <Content>{t("REMOVE_GROUP_CONTENT")}</Content>
      <ButtonsContainer>
        <CancelButton
          label={t("REMOVE_GROUP_CANCEL_BUTTON")}
          backgroundColor="baseLight"
          textColor="primary"
          borderColor="mutedLight"
          onClick={closeModal || (() => {})}
        />
        <ApproveButton
          label={t("REMOVE_GROUP_SUBMIT_BUTTON")}
          backgroundColor="error"
          onClick={() => {
            removeUserFromAccessGroups(userId, [accessGroupId])
              .then(() => {
                dispatch(fetchUser(userId));
                toast.success(t("REMOVE_GROUP_SUCCESS_TOAST_MESSAGE"));
              })
              .catch((err) => dispatch(handleAPIError(err as APIError)))
              .finally(closeModal);
          }}
        />
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 16px;
`;

const Content = styled.div`
  padding-bottom: 32px;
`;

const ButtonsContainer = styled(FlexBoxBase)`
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
  padding: 12px 16px;
`;

const ApproveButton = styled(Button)`
  padding: 12px 16px;
`;
