import { useCallback } from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import { ConfigurationListItem } from "../../molecules/ConfigurationListItem/ConfigurationListItem";
import { ConfigurationType } from "../../../store/slices/caching/types";
import { useAppDispatch } from "../../../store/types";
import { clearSelectedConfiguration } from "../../../store/slices/caching/slice";

interface IConfigurationListProps {
  configurations: ConfigurationType[];
  searchedValue: string;
}

export const ConfigurationList: React.FC<IConfigurationListProps> = ({
  configurations,
  searchedValue,
}) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const appearsAnimation = useSpring({
    opacity: configurations?.length ? 1 : 0,
  });

  const filteredConfigurations = useCallback(() => {
    if (configurations && configurations.length && searchedValue.length) {
      return configurations.filter((conf) =>
        conf.configName
          .toLowerCase()
          .includes(searchedValue.toLowerCase().trim())
      );
    } else {
      return configurations!;
    }
  }, [searchedValue, configurations]);

  return (
    <ConfigurationsListContainer style={appearsAnimation}>
      {filteredConfigurations().map((configuration: ConfigurationType, i) => (
        <ConfigurationListItemWrapper key={i}>
          <ConfigurationListItem
            onEdit={() => {
              history.push(
                `/cmv3/configurations/${configuration.configName}/properties`
              );
              dispatch(clearSelectedConfiguration());
            }}
            configuration={configuration}
          />
        </ConfigurationListItemWrapper>
      ))}
    </ConfigurationsListContainer>
  );
};

const ConfigurationsListContainer = styled(animated.div)``;

const ConfigurationListItemWrapper = styled.div`
  margin-bottom: 20px;
`;
