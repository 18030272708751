import styled from "@emotion/styled";
import { FC } from "react";

export const SelectionCircle: FC<{ selected: boolean }> = ({ selected }) => (
  <Round selected={selected}>{selected && <SelectionMarker />}</Round>
);

const Round = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.borders.muted};
  box-sizing: border-box;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ selected }) => (selected ? "white" : "inherit")};
`;

const SelectionMarker = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.backgrounds.highlight};
`;
