import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../components/atoms/Button/Button";
import {
  IModalChildren,
  Modal,
} from "../../../../../../components/atoms/Modal/Modal";
import { InputField } from "../../../../../../components/molecules/InputField/InputField";
import { Dropdown } from "../../../../../../components/organisms/Dropdown/Dropdown";
import { IRoute, Tabs } from "../../../../../../components/organisms/Tabs/Tabs";
import { createMatchLogicDefinition } from "../../../../../../store/slices/caching/helpers/match-logic-definition/createMatchLogicDefinition";
import { useSelectedConfiguration } from "../../../../../../store/slices/caching/hooks";
import {
  MatchLogicDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { isDefinitionNameValid } from "../../../../../../utils/string";

interface IAddMatchLogicModalProps {
  updateMatchLogic: (newMatchLogic: MatchLogicDefinitionType) => void;
  updateProperty: (newDefinition: PropertyDefinitionType) => void;
  currentProperty: PropertyDefinitionType;
}

enum ModeRoute {
  SELECT = "select",
  CREATE = "create",
}

export const AddMatchLogicModal = ({
  updateMatchLogic,
  updateProperty,
  currentProperty,
}: IAddMatchLogicModalProps): ReactElement => {
  const { t } = useTranslation("misc");

  return (
    <Modal
      customButton={
        <AddButton
          className="chi-button -primary"
          data-testid="add-match-logic-button"
        >
          <PlusSign>+</PlusSign>
          <div>{t("ADD")}</div>
        </AddButton>
      }
    >
      <ChildrenModal
        updateMatchLogic={updateMatchLogic}
        updateProperty={updateProperty}
        currentProperty={currentProperty}
      />
    </Modal>
  );
};
interface IChildrenModalProps extends IModalChildren {
  updateMatchLogic: (newMatchLogic: MatchLogicDefinitionType) => void;
  updateProperty: (newDefinition: PropertyDefinitionType) => void;
  currentProperty: PropertyDefinitionType;
}

const ChildrenModal = ({
  closeModal,
  updateMatchLogic,
  updateProperty,
  currentProperty,
}: IChildrenModalProps): ReactElement => {
  const [tMisc] = useTranslation("misc");
  const [tConfigPage] = useTranslation("configurationPropertyPage");
  const selectedConfiguration = useSelectedConfiguration();
  const [
    selectedMatchLogic,
    setSelectedMatchLogic,
  ] = useState<MatchLogicDefinitionType>();
  const routes: IRoute[] = [
    { label: tConfigPage("MATCH_LOGIC_CREATE_SHORT"), route: ModeRoute.CREATE },
    { label: tConfigPage("MATCH_LOGIC_SELECT_SHORT"), route: ModeRoute.SELECT },
  ];

  const [mode, setMode] = useState<IRoute>(routes[0]);
  const [newMatchLogicName, setNewMatchLogicName] = useState<string>("");

  const [error, setError] = useState<string>();

  const matchLogicListItems = selectedConfiguration?.config?.matchLogicDefinitions.map(
    (mld, i) => ({
      value: mld,
      label: mld.name,
      dataTestId: `match-logic-selector-${i}`,
    })
  );

  const currentIndex = routes.findIndex((tab) => tab.route === mode.route);

  const onNewNameChange = (value: string) => {
    setNewMatchLogicName(value);
    if (
      selectedConfiguration?.config?.matchLogicDefinitions.some(
        (matchLogic) => matchLogic.name === value
      )
    ) {
      setError(tConfigPage("MATCH_LOGIC_SECTION_NAME_ALREADY_USED"));
    } else if (!isDefinitionNameValid(value)) {
      setError(tConfigPage("MATCH_LOGIC_SECTION_NAME_INVALID"));
    } else {
      setError(undefined);
    }
  };

  useEffect(() => {
    if (mode.route === ModeRoute.CREATE) {
      setSelectedMatchLogic(undefined);
    } else {
      setNewMatchLogicName("");
      setError(undefined);
    }
  }, [mode]);

  return (
    <ModalChildrenWRapper>
      <Title>
        {mode.route === ModeRoute.SELECT
          ? tConfigPage("MATCH_LOGIC_SELECT_ONE")
          : tConfigPage("MATCH_LOGIC_CREATE_ONE")}
      </Title>
      {selectedConfiguration?.config?.matchLogicDefinitions.length ? (
        <Tabs
          initialIndex={currentIndex}
          onSelectionChange={setMode}
          tabsList={routes}
        />
      ) : null}
      <ModalBody>
        {mode.route === ModeRoute.SELECT ? (
          <Dropdown
            items={matchLogicListItems || []}
            id="match-logic-selector"
            dataTestId="match-logic-selector"
            placeholder={
              selectedMatchLogic?.name || tConfigPage("MATCH_LOGIC_SELECT_ONE")
            }
            onSelect={(item) => setSelectedMatchLogic(item.value)}
          />
        ) : (
          <>
            <InputField
              hasBorder
              onChange={onNewNameChange}
              value={newMatchLogicName}
              placeholder={tConfigPage("MATCH_LOGIC_CREATE_NEW_NAME")}
              dataTestId="new-match-logic-name-input"
            />
            {error && <Error>{error}</Error>}
          </>
        )}
      </ModalBody>
      <Buttons>
        <Button
          backgroundColor="baseLight"
          textColor="highlight"
          borderColor="highlight"
          label={tMisc("CANCEL")}
          onClick={() => {
            closeModal && closeModal();
          }}
        />
        <ButtonContainer>
          <Button
            disabled={
              (mode.route === ModeRoute.CREATE && newMatchLogicName === "") ||
              (mode.route === ModeRoute.SELECT &&
                selectedMatchLogic === undefined) ||
              error !== undefined
            }
            label={tMisc("CREATE")}
            onClick={() => {
              if (selectedConfiguration && selectedConfiguration.config) {
                if (mode.route === ModeRoute.CREATE) {
                  updateMatchLogic(
                    createMatchLogicDefinition(newMatchLogicName, {
                      matchBlocks: {},
                    })
                  );
                  updateProperty({
                    ...currentProperty,
                    matchLogic: newMatchLogicName,
                  });
                } else {
                  if (selectedMatchLogic) {
                    updateProperty({
                      ...currentProperty,
                      matchLogic: selectedMatchLogic.name,
                    });
                  }
                }
                closeModal && closeModal();
              }
            }}
            dataTestId="new-match-logic-submit-button"
          />
        </ButtonContainer>
      </Buttons>
    </ModalChildrenWRapper>
  );
};

const ModalBody = styled.div`
  padding: 40px 0px;
  width: 100%;
`;

const ModalChildrenWRapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  width: 400px;
  padding: 32px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const Title = styled.h3`
  margin-bottom: 20px !important;
  text-align: center;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.text.error};
  font-size: 12px;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
`;

const PlusSign = styled.div`
  font-size: 32px;
  font-weight: 300;
  padding: 0px 4px 4px 0px;
`;
