import { FC } from "react";

import { FiltersContext } from "../../../modules/rtm/context/filters";
import { useGenerateFilterContext } from "../../../modules/rtm/context/filters/useGenerateFilterContext";
import { PageContainer } from "../../atoms/PageContainer/PageContainer";

export const Report: FC<{ id: string }> = ({ children, id }) => {
  const filtersContext = useGenerateFilterContext(id);
  return (
    <FiltersContext.Provider value={filtersContext}>
      <PageContainer>{children}</PageContainer>
    </FiltersContext.Provider>
  );
};
