import { IMatchRule } from "../../../../../models/configuration/definitions";

export const updateOriginReferences = (oldName: string, newName: string) => (
  matchRule: IMatchRule
): IMatchRule => {
  matchRule.features.originFillPolicy =
    matchRule.features.originFillPolicy === oldName
      ? newName
      : matchRule.features.originFillPolicy;

  return matchRule;
};
