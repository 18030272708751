import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";
import { Configuration, ProductionSlot } from "../../../models/configuration";
import { Button } from "../../atoms/Button/Button";
import { IModalChildren } from "../../atoms/Modal/Modal";
import { Slider } from "../../atoms/Slider/Slider";
import { DeploymentCard } from "../DeploymentCard/DeploymentCard";
import { ExpandableContent } from "../ExpandableContent/ExpandableContent";
import { Tooltip } from "../Tooltip/Tooltip";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../store/slices/subscriber/hooks";

interface EditAllocationModalProps extends IModalChildren {
  slot: ProductionSlot;
  current: string | undefined;
  candidate: string | undefined;
  activationPercent: number;
  onSubmit: () => void;
  isMigration: boolean;
}

export const EditAllocationModal = ({
  slot,
  current,
  candidate,
  closeModal,
  activationPercent,
  onSubmit,
  isMigration,
}: EditAllocationModalProps): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation("productionPage");

  const [selectedSlot, setSelectedSlot] = useState<ProductionSlot>(slot);
  const [candidatePercentage, setCandidatePercentage] = useState<number>(
    slot === ProductionSlot.CURRENT
      ? 100 - activationPercent
      : activationPercent
  );
  const [initialized, setInitialized] = useState<boolean>(false);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);

  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    } else {
      setDisplayPopup(true);
    }
  }, [candidatePercentage]);

  return (
    <Container>
      <Title className="-text--h2">
        {t("PRODUCTION_MODAL_EDIT_ALLOCATION_TITLE")}
      </Title>
      <ExpandableContent
        title={t("TRAFFIC_ALLOCATION_DESCRIPTION_TITLE")}
        content={t("TRAFFIC_ALLOCATION_DESCRIPTION_CONTENT")}
      />
      <br />
      <ConfigurationContainer>
        {!isMigration && (
          <SlotContainer data-testid="edit-allocation-current-slot">
            <ConfigurationHeader className="-text--h4">
              {t("PRODUCTION_MODAL_EDIT_ALLOCATION_CURRENT_TITLE")}
            </ConfigurationHeader>
            <DeploymentCard
              selected={selectedSlot === ProductionSlot.CURRENT}
              onClick={() => {
                setSelectedSlot(ProductionSlot.CURRENT);
              }}
              value={100 - candidatePercentage}
              configurationName={current || ""}
              isNewConfig={false}
            />
          </SlotContainer>
        )}
        <SlotContainer
          fullWidth={isMigration}
          data-testid="edit-allocation-candidate-slot"
        >
          <ConfigurationHeader className="-text--h4">
            {isMigration && "Migration "}&nbsp;
            {t("PRODUCTION_MODAL_EDIT_ALLOCATION_CANDIDATE_TITLE")}
          </ConfigurationHeader>
          <DeploymentCard
            selected={selectedSlot === ProductionSlot.CANDIDATE}
            onClick={() => {
              setSelectedSlot(ProductionSlot.CANDIDATE);
            }}
            value={candidatePercentage}
            configurationName={candidate || ""}
            isNewConfig={candidate === undefined}
          />
        </SlotContainer>
      </ConfigurationContainer>
      <SliderContainer>
        <Slider
          initialValue={
            selectedSlot === ProductionSlot.CURRENT
              ? 100 - candidatePercentage
              : candidatePercentage
          }
          onChange={
            selectedSlot === ProductionSlot.CURRENT
              ? (value: number) => setCandidatePercentage(100 - value)
              : setCandidatePercentage
          }
        />
      </SliderContainer>
      {displayPopup && !isMigration && (
        <DeploymentAlertContainer>
          <StyledWarningIcon />
          <DeploymentText>
            {t("PRODUCTION_MODAL_EDIT_ALLOCATION_MODIFY_ALERT")}
          </DeploymentText>
        </DeploymentAlertContainer>
      )}
      {isMigration && (
        <DeploymentAlertContainer>
          <StyledWarningIcon />
          <DeploymentText>
            {t("PRODUCTION_MODAL_EDIT_ALLOCATION_MIGRATION_INFO")}
          </DeploymentText>
        </DeploymentAlertContainer>
      )}
      <ButtonsContainer>
        <CancelButtonContainer>
          {closeModal && (
            <Button
              onClick={() => {
                closeModal();
              }}
              label={t("PRODUCTION_MODAL_EDIT_ALLOCATION_CANCEL_BUTTON")}
              size="lg"
              backgroundColor="baseLight"
              textColor="highlight"
              borderColor="highlight"
            />
          )}
        </CancelButtonContainer>
        <Tooltip
          popupText={t("PRODUCTION_MODAL_EDIT_ALLOCATION_POPUP_TEXT")}
          active={!candidate}
          id="edit-allocation-popup"
        >
          <SubmitButtonContainer data-testid="edit-allocation-submit-button">
            {closeModal && (
              <Button
                onClick={() => {
                  Configuration.production
                    .changeCandidatePercentActive(
                      subscriberId.toString(),
                      candidatePercentage
                    )
                    .then((deployment) => {
                      onSubmit();

                      Configuration.checkDeploymentStatus(
                        subscriberId.toString(),
                        deployment.slots,
                        ProductionSlot.CANDIDATE
                      ).then((transaction) => {
                        if (transaction && transaction.state === "errored") {
                          toast.error(
                            <div>
                              <h5>{t("EDIT_ALLOCATION_DEPLOYMENT_FAILED")}</h5>
                              <p>Type: {transaction.operationData.type}</p>
                              <ul>
                                {(transaction.messages || []).map((message) => {
                                  return <li key={message}>{message}</li>;
                                })}
                              </ul>
                              <Button
                                label={t("CONFIGURATION_DEPLOYMENT_FAILED_CTA")}
                                onClick={() => {
                                  history.push(
                                    "/cmv3/environment_history#deployment_history"
                                  );
                                }}
                              />
                            </div>,
                            {
                              autoClose: false,
                            }
                          );
                        }
                      });
                    })
                    .catch(handleMediaPortalError);

                  closeModal();
                }}
                label={t("PRODUCTION_MODAL_EDIT_ALLOCATION_VALIDATE_BUTTON")}
                size="lg"
                disabled={!candidate}
              />
            )}
          </SubmitButtonContainer>
        </Tooltip>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: 32px;
  width: 800px;
`;

const Title = styled.h2``;

const DeploymentAlertContainer = styled.div`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.borders.mutedLight};
  border-left: solid 4px ${({ theme }) => theme.borders.warning};
  border-radius: 4px;
  display: flex;
  align-items: top;
  padding: 16px 24px;
  box-shadow: 0 5px 10px -10px;
  margin: 32px 0;
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.colors.yellow40};
  margin-right: 16px;
`;

const DeploymentText = styled.div`
  width: 100%;
`;

const ConfigurationContainer = styled.div`
  display: flex;
`;

const SlotContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${(props) => (props.fullWidth ? "100%" : "50%")};
  margin: 0 12px;
  display: flex;
  flex-direction: column;
`;

const ConfigurationHeader = styled.h4``;

const SliderContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 32px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButtonContainer = styled.div`
  padding-right: 15px;
`;

const SubmitButtonContainer = styled.div``;
