import styled from "@emotion/styled";
import { ReactElement, useState } from "react";
// eslint-disable-next-line import/named
import { CustomTypeOptions, useTranslation } from "react-i18next";

import { Divider } from "../../../../../components/atoms/Divider/Divider";
import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";
import { ContentSwitcher } from "../../../../../components/molecules/ContentSwitcher/ContentSwitcher";
import { useHasCdn } from "../../../../../store/slices/permissions/hooks";
import { useUser } from "../../../../../store/slices/user-management-users/hooks";
import { useUserVyvxType } from "../../../../../store/slices/user/hooks";
import { UserType } from "../types";
import { UserPreferences } from "./UserPreferences";
import { UserHeader } from "./UserHeader";
import { UserAccessGroupData } from "./UserAccessGroupData";
import { UserVyvxCompaniesData } from "./UserVyvxCompaniesData";

const UserPageTypeTab: Record<
  UserType,
  keyof Pick<
    CustomTypeOptions["resources"]["usersPage"],
    "USER_PAGE_TAB_CDN" | "USER_PAGE_TAB_VYVX"
  >
> = {
  CDN: "USER_PAGE_TAB_CDN",
  VYVX: "USER_PAGE_TAB_VYVX",
};

export const UserPage = (): ReactElement => {
  const { t } = useTranslation("usersPage");
  const userInfo = useUser();
  const hasCdn = useHasCdn();
  const hasVyvx = !!useUserVyvxType();

  const tabs = [];
  if (userInfo !== undefined) {
    if (userInfo.accessGroups.length > 0 && hasCdn) {
      tabs.push({
        label: t(UserPageTypeTab.CDN),
        value: UserType.CDN,
      });
    }

    if (userInfo.vyvx?.role && hasVyvx) {
      tabs.push({
        label: t(UserPageTypeTab.VYVX),
        value: UserType.VYVX,
      });
    }
  }

  const [selectedTab, setSelectedTab] = useState<UserType | undefined>(
    tabs[0]?.value
  );

  return (
    <Container>
      {userInfo && (
        <>
          <UserHeader userInfo={userInfo} />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <ContentSwitcherContainer>
            <ContentSwitcher
              tabs={tabs}
              onChange={(tab) => setSelectedTab(tab.value)}
              value={selectedTab}
            />
          </ContentSwitcherContainer>
          <UserPreferences type={selectedTab} userInfo={userInfo} />
          <CompanyContainer>
            {selectedTab === UserType.CDN ? (
              <UserAccessGroupData
                accessGroups={userInfo.accessGroups}
                userId={userInfo.userId}
              />
            ) : (
              selectedTab === UserType.VYVX &&
              userInfo.vyvx && (
                <UserVyvxCompaniesData
                  vyvxData={userInfo.vyvx}
                  userId={userInfo.userId}
                />
              )
            )}
          </CompanyContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
`;

const DividerContainer = styled.div`
  margin: 16px 0;
`;

const ContentSwitcherContainer = styled.div`
  padding-bottom: 32px;
`;

const CompanyContainer = styled.div`
  padding-top: 32px;
`;
