import type { ReactElement } from "react";
import type {
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { HeadlessCheckbox } from "../../../../../../../../../components/molecules/Checkbox/Checkbox";
import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { GeoBlockingModal } from "../../../../../../../../../components/molecules/GeoBlockingModal/GeoBlockingModal";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import { IpDefinitionModal } from "../../../../../../../../../components/molecules/IpDefinitionModal/IpDefinitionModal";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import type { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  SimpleDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { uriRegex } from "../../../../../../../../../utils/regex";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { getDefsByType, selectedNames, toName } from "./helpers";

interface GeoFeatureBlockProps {
  handleCreateDefinition: (d: SimpleDefinitionType) => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  onDelete: () => void;
  register: UseFormRegister<IFeatures>;
  setError: UseFormSetError<IFeatures>;
  clearErrors: UseFormClearErrors<IFeatures>;
  initIsOpen: boolean;
  config: ConfigurationDetailsType;
  maxWidth?: number;
  errors: ValidationErrors;
  datatestId: string;
  geoRestrictions: Exclude<IFeatures["geoRestrictions"], undefined>;
}

const ID_WHITELIST_IP = "whitelist-ip-dropdown";

export const GeoFeatureBlock = ({
  clearErrors,
  config,
  datatestId,
  errors,
  geoRestrictions,
  handleCreateDefinition,
  handleCreateFeature,
  initIsOpen,
  maxWidth,
  onDelete,
  register,
  setError,
}: GeoFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const disabled = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_GEO_REGION_BLOCKING_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_GEO_REGION_BLOCKING_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_GEO_REGION_BLOCKING_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_GEO_REGION_DEFINITION_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_GEO_REGION_DEFINITION_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_GEO_REGION_DEFINITION_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            maxWidth={maxWidth}
            errorMessage={
              (errors.geoRestrictions?.nameList as FieldError | undefined)
                ?.message
            }
            fieldProps={{
              other: config.simpleListDefinitions ? (
                <DefinitionDropdown
                  id="geoRestrictions.nameList"
                  disabled={disabled}
                  type={FeatureType.GEO}
                  items={getDefsByType("geo", config.simpleListDefinitions)}
                  selected={config.simpleListDefinitions.filter(
                    selectedNames(geoRestrictions.nameList)
                  )}
                  onSelect={(selected) =>
                    handleCreateFeature(
                      "geoRestrictions.nameList",
                      selected.map(toName)
                    )
                  }
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  dataTestId="geo-region-definition"
                  parentConfig={config}
                />
              ) : (
                <GeoBlockingModal
                  parentConfig={config}
                  onSubmit={handleCreateDefinition}
                />
              ),
            }}
            divider
            dataTestId="geo-region-definition"
            required
          />
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_GEO_REGION_ACTION_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_GEO_REGION_ACTION_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_GEO_REGION_ACTION_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      ...register("geoRestrictions.actionType"),
                      value: "allow",
                      disabled,
                      label: t("FEATURE_CARD_GEO_REGION_ACTION_RADIO_1_LABEL"),
                      id: "geo-action-allow",
                      dataTestId: "geo-action-allow-radio",
                    },
                    {
                      ...register("geoRestrictions.actionType"),
                      value: "deny",
                      disabled,
                      label: t("FEATURE_CARD_GEO_REGION_ACTION_RADIO_2_LABEL"),
                      id: "geo-action-deny",
                      dataTestId: "geo-action-deny-radio",
                    },
                  ]}
                />
              ),
            }}
            divider
          />
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_GEO_REGION_MATCH_ANONYMIZER_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_GEO_REGION_MATCH_ANONYMIZER_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_GEO_REGION_MATCH_ANONYMIZER_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <HeadlessCheckbox
                  id="geoRestrictions.matchAnonymizers"
                  disabled={disabled}
                  label={t(
                    "FEATURE_CARD_GEO_REGION_MATCH_ANONYMIZER_CHECKBOX_LABEL"
                  )}
                  dataTestId="match-ananomyzer"
                  {...register("geoRestrictions.matchAnonymizers")}
                />
              ),
            }}
            divider
          />
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_GEO_REGION_DENIAL_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_GEO_REGION_DENIAL_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_GEO_REGION_DENIAL_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      ...register("geoRestrictions.denial.action"),
                      value: "redirect",
                      disabled,
                      label: t("FEATURE_CARD_GEO_REGION_DENIAL_RADIO_1_LABEL"),
                      id: "geo-action-redirect",
                      dataTestId: "geo-action-redirect-radio",
                    },
                    {
                      ...register("geoRestrictions.denial.action"),
                      value: "error",
                      disabled,
                      label: t("FEATURE_CARD_GEO_REGION_DENIAL_RADIO_2_LABEL"),
                      id: "geo-action-error",
                      dataTestId: "geo-action-error-radio",
                      onChange: () => {
                        handleCreateFeature("geoRestrictions.denial", {
                          action: "error",
                        });
                      },
                    },
                  ]}
                />
              ),
            }}
            divider
          />

          {geoRestrictions.denial?.action === "redirect" && (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_GEO_REGION_REDIRECT_URL_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_GEO_REGION_REDIRECT_URL_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_GEO_REGION_REDIRECT_URL_DESCRIPTION_CONTENT"
              )}
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <HeadlessInputField
                    disabled={disabled}
                    placeholder={t(
                      "FEATURE_CARD_GEO_REGION_REDIRECT_URL_PLACEHOLDER"
                    )}
                    {...register("geoRestrictions.denial.redirectUrl", {
                      required: tMisc("ERROR_REQUIRED_FIELD"),
                      pattern: {
                        value: new RegExp(uriRegex),
                        message: tMisc("ERROR_MUST_BE_URL"),
                      },
                    })}
                    data-testid="geo-region-redirect-url-input"
                  />
                ),
              }}
              errorMessage={
                (errors.geoRestrictions?.denial as
                  | {
                      action?: FieldError | undefined;
                      redirectUrl?: FieldError | undefined;
                    }
                  | undefined)?.redirectUrl?.message
              }
              maxWidth={maxWidth}
              divider
              dataTestId="geo-region-redirect-url"
              asterisk
            />
          )}

          <PropertyDefinitionCard
            title={t("FEATURE_CARD_GEO_REGION_WHITELIST_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_GEO_REGION_WHITELIST_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_GEO_REGION_WHITELIST_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            maxWidth={maxWidth}
            fieldProps={{
              other: config.simpleListDefinitions ? (
                <DefinitionDropdown
                  id={ID_WHITELIST_IP}
                  disabled={disabled}
                  type={FeatureType.IP}
                  items={getDefsByType("ip", config.simpleListDefinitions)}
                  selected={config.simpleListDefinitions.filter(
                    selectedNames(geoRestrictions.ipWhitelist ?? [])
                  )}
                  onSelect={(selected) =>
                    handleCreateFeature(
                      "geoRestrictions.ipWhitelist",
                      selected.map(toName)
                    )
                  }
                  dataTestId="geo-region-whitelist"
                  parentConfig={config}
                />
              ) : (
                <IpDefinitionModal
                  parentConfig={config}
                  onSubmit={handleCreateDefinition}
                />
              ),
            }}
            dataTestId="geo-region-whitelist"
          />
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"geoRestrictions"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="geo-restrictions"
      featureType={FeatureTypes.ACCESS_CONTROL}
    />
  );
};
