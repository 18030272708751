import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import { CertificateMetadata } from "../../../../components/molecules/CertificateMetadata/CertificateMetadata";
import { ReactComponent as ArrowSvg } from "../../../../assets/icons/long_arrow_left.svg";
import { Certificate } from "../../../../models/certificate";

interface IDetailsHeaderProps {
  certificate: Certificate | undefined;
}

export const DetailsHeader: React.FC<IDetailsHeaderProps> = ({
  certificate,
}) => {
  const history = useHistory();

  return (
    <CertificatePageHeaderContainer>
      {certificate && (
        <Header>
          <TitleContainer data-testid="certificate-name">
            <Arrow
              onClick={() => {
                history.push("/cmv3/certificates");
              }}
              data-testid="certificate-page-navback"
            />
            <p className="-text--h2">{certificate?.cert_name || ""}</p>
          </TitleContainer>
          <InfoContainer>
            <CertificateMetadata fields={certificate?.metadata || []} />
          </InfoContainer>
        </Header>
      )}
    </CertificatePageHeaderContainer>
  );
};

const CertificatePageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: ${({ theme }) => `1rem ${theme.spacings.pageContainer}`};
`;

const Header = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Arrow = styled(ArrowSvg)`
  margin-right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  * {
    fill: ${({ theme }) => theme.icon.primary};
  }
`;

const InfoContainer = styled.div``;
