import styled from "@emotion/styled";
import { ReactElement } from "react";

import { ChevronDownIcon } from "../ChevronDownIcon/ChevronDownIcon";
import { FlexBox } from "../FlexBox/FlexBox";

interface LabelValueDropdownButtonProps {
  placeholder?: string;
  value?: string;
  open?: boolean;
  defaultValue?: string;
  onClick?: () => void;
}
export const LabelValueDropdownButton = ({
  placeholder,
  value,
  defaultValue = "All",
  open = false,
  onClick = () => {},
}: LabelValueDropdownButtonProps): ReactElement => {
  const displayedValue = value || defaultValue;

  return (
    <Container id="dropdown-users-status-dropdown">
      {placeholder && <Label>{placeholder}</Label>}
      <Value>
        {displayedValue[0].toUpperCase() + displayedValue.substring(1)}
      </Value>
      <ChevronIcon open={open} color="secondary" onClick={onClick} />
    </Container>
  );
};

const Container = styled(FlexBox)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  padding: 4px;
`;

const Label = styled.div`
  padding: 8px;
`;

const Value = styled.div`
  background: ${({ theme }) => theme.backgrounds.base};
  padding: 8px;
`;

const ChevronIcon = styled(ChevronDownIcon)`
  margin-left: 10px;
  margin-right: 8px;
`;
