import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, IconProps, Icons } from "../Icon/Icon";

export const TrashIcon = (props: Omit<IconProps, "name">): ReactElement => (
  <TrashBase {...props} name={Icons.TRASH} color="secondary" />
);

const TrashBase = styled(Icon)`
  height: 1em;
  cursor: pointer;
  margin-left: 8px;
`;
