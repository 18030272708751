import { isEqual } from "lodash";
import { MatchBlocks } from "../../../../../../models/configuration/definitions/matchlogic";
import {
  PropertySection,
  PropertySubsection,
} from "../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../../../../models/configuration/errors";
import { validateOriginDefinition } from "../../../../../../store/slices/caching/helpers/origin-definition/validateOriginDefinition";
import {
  MatchLogicDefinitionType,
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { DiffKeyword, GetErrorsToAddParams } from "./types";

export const getErrorsToAdd = ({
  currentMatchLogic,
  originDefinition,
  property,
}: GetErrorsToAddParams): IConfigurationError[] => {
  const errorsToAdd: IConfigurationError[] = [];

  // origin
  if (!property?.originFillPolicy) {
    errorsToAdd.push({
      level: ConfigurationErrorLevel.ERROR,
      type: ConfigurationErrorType.PROPERTY_ORIGIN_DEFINITION_EMPTY,
      data: {},
    });
  }

  const originErrors = originDefinition
    ? validateOriginDefinition(originDefinition)
    : undefined;
  if (originErrors) {
    errorsToAdd.push(...originErrors);
  }

  // match logic
  if (currentMatchLogic) {
    if (
      currentMatchLogic.matchBlocks.clientReq === undefined &&
      currentMatchLogic.matchBlocks.originReq === undefined &&
      currentMatchLogic.matchBlocks.originResp === undefined
    ) {
      errorsToAdd.push({
        level: ConfigurationErrorLevel.ERROR,
        type: ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_BLOCKS,
        data: { section: PropertySection.MATCH_LOGIC },
      });
    }

    if (currentMatchLogic.matchBlocks.clientReq) {
      if (currentMatchLogic.matchBlocks.clientReq.matchGroups.length === 0) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type: ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS,
          data: {
            section: PropertySection.MATCH_LOGIC,
            matchBlock: MatchBlocks.CLIENT_REQ,
          },
        });
      } else {
        currentMatchLogic.matchBlocks.clientReq.matchGroups.forEach(
          (matchGroup, index) => {
            if (matchGroup.matchRules.length === 0) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type:
                  ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES,
                data: {
                  section: PropertySection.MATCH_LOGIC,
                  matchBlock: MatchBlocks.CLIENT_REQ,
                  matchGroupIndex: index,
                },
              });
            }
          }
        );
      }
    }

    if (currentMatchLogic.matchBlocks.originReq) {
      if (currentMatchLogic.matchBlocks.originReq.matchGroups.length === 0) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type: ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS,
          data: {
            section: PropertySection.MATCH_LOGIC,
            matchBlock: MatchBlocks.ORIGIN_REQ,
          },
        });
      } else {
        currentMatchLogic.matchBlocks.originReq.matchGroups.forEach(
          (matchGroup, index) => {
            if (matchGroup.matchRules.length === 0) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type:
                  ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES,
                data: {
                  section: PropertySection.MATCH_LOGIC,
                  matchBlock: MatchBlocks.ORIGIN_REQ,
                  matchGroupIndex: index,
                },
              });
            }
          }
        );
      }
    }

    if (currentMatchLogic.matchBlocks.originResp) {
      if (currentMatchLogic.matchBlocks.originResp.matchGroups.length === 0) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type: ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS,
          data: {
            section: PropertySection.MATCH_LOGIC,
            matchBlock: MatchBlocks.ORIGIN_RESP,
          },
        });
      } else {
        currentMatchLogic.matchBlocks.originResp.matchGroups.forEach(
          (matchGroup, index) => {
            if (matchGroup.matchRules.length === 0) {
              errorsToAdd.push({
                level: ConfigurationErrorLevel.ERROR,
                type:
                  ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES,
                data: {
                  section: PropertySection.MATCH_LOGIC,
                  matchBlock: MatchBlocks.ORIGIN_RESP,
                  matchGroupIndex: index,
                },
              });
            }
          }
        );
      }
    }
  }

  // Aliases
  if (!property?.primaryAlias) {
    errorsToAdd.push({
      level: ConfigurationErrorLevel.ERROR,
      type: ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_EMPTY,
      data: {
        section: PropertySection.ALIASES,
        subsection: PropertySubsection.PRIMARY_ALIAS,
      },
    });
  }

  // Secondary Alias
  property?.aliases?.forEach((alias, index) => {
    if (alias.length === 0) {
      errorsToAdd.push({
        level: ConfigurationErrorLevel.ERROR,
        type: ConfigurationErrorType.PROPERTY_SECONDARY_ALIAS_EMPTY,
        data: {
          section: PropertySection.ALIASES,
          subsection: PropertySubsection.SECONDARY_ALIASES,
          index,
        },
      });
    }
  });

  // Alias Overrides
  property?.aliasOverrides?.forEach((group, index) => {
    group.aliases?.forEach((alias, i) => {
      if (alias.length === 0) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type: ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_ALIAS_EMPTY,
          data: {
            section: PropertySection.ALIAS_OVERRIDES,
            index,
            aliasIndex: i,
          },
        });
      }
    });
  });

  return errorsToAdd;
};

export const computeDiff = (
  prop: PropertyDefinitionType | undefined,
  configProperty: PropertyDefinitionType | undefined,
  matchLogic: MatchLogicDefinitionType | undefined,
  configMatchLogic: MatchLogicDefinitionType | undefined,
  originDef: OriginDefinitionType | undefined,
  configOriginDefinition: OriginDefinitionType | undefined
): DiffKeyword[] => {
  const result: DiffKeyword[] = [];
  if (prop && configProperty) {
    Object.keys(prop).forEach((key) => {
      if (
        !isEqual(
          prop[key as keyof PropertyDefinitionType],
          configProperty[key as keyof PropertyDefinitionType]
        )
      ) {
        if (key === "matchLogic") {
          result.push("matchLogic");
        } else if (
          !result.includes("property") &&
          key !== "parentConfiguration"
        ) {
          result.push("property");
        } else if (!result.includes("origin") && key === "origin") {
          result.push("origin");
        } else if (!result.includes("name") && key === "name") {
          result.push("name");
        }
      }
    });
  }
  if (matchLogic) {
    if (configMatchLogic) {
      Object.keys(matchLogic.matchBlocks).forEach((key) => {
        if (
          !isEqual(
            matchLogic.matchBlocks[key as MatchBlocks],
            configMatchLogic.matchBlocks[key as MatchBlocks]
          )
        ) {
          result.push(key as MatchBlocks);
        }
      });
    } else {
      Object.keys(matchLogic.matchBlocks).forEach((key) => {
        result.push(key as MatchBlocks);
      });
    }
  }
  if (configOriginDefinition) {
    if (!isEqual(originDef, configOriginDefinition)) {
      result.push("origin");
    }
  } else {
    result.push("origin");
  }
  return result;
};
