import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "../../../../../components/atoms/Button/Button";
import { CustomRemark } from "../../../../../components/atoms/CustomRemark/CustomRemark";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { PropertyMetaData } from "../../../../../models/metadata/properties";
import { isDefinitionNameValid } from "../../../../../utils/string";
import { InputField } from "../../../../../components/molecules/InputField/InputField";
import {
  IModalChildren,
  Modal,
} from "../../../../../components/atoms/Modal/Modal";
import {
  useProperties,
  useSelectedConfiguration,
} from "../../../../../store/slices/caching/hooks";

interface INewPropertyModalProps {
  onRenameProperty?: (name: string, primaryAlias?: string) => void;
}

export const NewPropertyModal: React.FC<INewPropertyModalProps> = ({
  onRenameProperty,
}) => {
  const { t } = useTranslation("configurationPropertiesPage");

  return (
    <Modal
      openButtonProps={{
        label: t("ADD_PROPERTY_BUTTON"),
        dataTestId: "property-list-add-property",
      }}
      size="auto"
    >
      <NewPropertyModalChild onRenameProperty={onRenameProperty} />
    </Modal>
  );
};

export const NewPropertyModalChild: React.FC<
  IModalChildren & INewPropertyModalProps
> = ({ onRenameProperty = undefined, closeModal }) => {
  const history = useHistory();
  const { t } = useTranslation("configurationPropertiesPage");

  const [propertyName, setPropertyName] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);

  const selectedConfiguration = useSelectedConfiguration();
  const properties = useProperties();

  const [isAlias, setIsAlias] = useState(false);

  const [
    alreadyUsedProperty,
    setAlreadyUsedProperty,
  ] = useState<PropertyMetaData>();

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.keyCode === 13) {
        if (propertyName.length > 1 && errors.length === 0) {
          if (onRenameProperty) {
            onRenameProperty(
              propertyName,
              isAlias ? alreadyUsedProperty?.primaryAlias : undefined
            );
          } else {
            history.push(
              `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${propertyName}`
            );
          }
          closeModal && closeModal();
        }
      }
    };

    const newPropertyModal = document.getElementsByClassName(
      "newproperty-modal"
    )[0];

    newPropertyModal.addEventListener("keydown", onKeyDown);

    return () => newPropertyModal.removeEventListener("keydown", onKeyDown);
  }, [selectedConfiguration?.configName, propertyName]);

  return (
    <NewPropertyModalContainer
      className="newproperty-modal"
      data-testid="new-property-modal"
    >
      {isAlias ? (
        <AliasContainer>
          <Title>{t("PRIMARY_ALIAS_MODAL_TITLE", { propertyName })}</Title>
          <CustomRemark>
            {t("PRIMARY_ALIAS_MODAL_DESCRIPTION", {
              status:
                alreadyUsedProperty?.status === "active"
                  ? "active"
                  : "inactive",
            })}
          </CustomRemark>
          <br />
          <CustomRemark>{t("PRIMARY_ALIAS_MODAL_PROCEED")}</CustomRemark>
          <br />
          <InfoCard data-testid="new-property-modal-primary-alias-name">
            <InfoIcon name={Icons.INFO} size={32} />
            <CustomRemark>
              {t("PRIMARY_ALIAS_MODAL_ALIAS_DISPLAY", {
                alias: alreadyUsedProperty?.primaryAlias,
              })}
            </CustomRemark>
          </InfoCard>
          <Buttons>
            <Button
              backgroundColor="baseLight"
              textColor="highlight"
              borderColor="highlight"
              label={t("PRIMARY_ALIAS_MODAL_CHOOSE_NAME")}
              onClick={() => {
                setIsAlias(false);
                setPropertyName("");
              }}
              dataTestId="new-property-modal-choose-another-name-button"
            />
            <ButtonContainer>
              <Button
                label={t("PRIMARY_ALIAS_MODAL_SET_UP_ALIAS")}
                disabled={!propertyName.length || errors.length > 0}
                onClick={() => {
                  if (onRenameProperty) {
                    onRenameProperty(
                      propertyName,
                      alreadyUsedProperty?.primaryAlias
                    );
                  } else {
                    history.push(
                      `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${propertyName}`
                    );
                  }
                  closeModal && closeModal();
                }}
                dataTestId="new-property-modal-primary-alias-submit"
              />
            </ButtonContainer>
          </Buttons>
        </AliasContainer>
      ) : (
        <>
          <Title>
            {onRenameProperty
              ? t("RENAME_PROPERTY_FORM_TITLE")
              : t("ADD_PROPERTY_FORM_TITLE")}
          </Title>
          <InputField
            placeholder={t("ADD_PROPERTY_FORM_INPUT_PLACEHOLDER")}
            value={propertyName}
            hasBorder
            onChange={(name) => {
              const err: string[] = [];

              // if the name is already in the config
              if (
                selectedConfiguration?.config?.propertyDefinitions.some(
                  (prop) => prop.name.toLowerCase() === name.toLowerCase()
                )
              ) {
                err.push(t("ERROR_PROPERTY_NAME_TAKEN"));
              }
              if (!isDefinitionNameValid(name)) {
                err.push(t("ERROR_PROPERTY_NAME_INVALID"));
              }
              setErrors(name.length <= 1 ? [] : err);
              setPropertyName(name);
            }}
            dataTestId="new-property-modal-name-input"
          />
          {errors &&
            errors.map((error, i) => (
              <Error key={i}>
                <CustomRemark>{error}</CustomRemark>
              </Error>
            ))}
          <Buttons>
            <Button
              backgroundColor="baseLight"
              textColor="highlight"
              borderColor="highlight"
              label={t("ADD_PROPERTY_FORM_CANCEL_BUTTON")}
              onClick={() => closeModal && closeModal()}
              dataTestId="new-property-modal-cancel-button"
            />
            <ButtonContainer>
              <Button
                label={t("ADD_PROPERTY_FORM_SUBMIT_BUTTON")}
                disabled={propertyName.length <= 1 || errors.length > 0}
                onClick={() => {
                  const propIndex = properties.findIndex(
                    (p) => p.name.toLowerCase() === propertyName.toLowerCase()
                  );
                  const isAlreadyUsedInOtherConfigProperties = propIndex !== -1;

                  if (isAlreadyUsedInOtherConfigProperties) {
                    setIsAlias(true);
                    setAlreadyUsedProperty(properties[propIndex]);
                  } else {
                    if (onRenameProperty) {
                      onRenameProperty(propertyName);
                    } else {
                      history.push(
                        `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${propertyName}`
                      );
                    }

                    closeModal && closeModal();
                  }
                }}
                dataTestId="new-property-modal-submit-button"
              />
            </ButtonContainer>
          </Buttons>
        </>
      )}
    </NewPropertyModalContainer>
  );
};

const NewPropertyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 32px;
`;

const AliasContainer = styled.div``;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 100px !important;
`;

const Buttons = styled.div`
  width: 100%;

  margin-top: 72px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const Error = styled.div`
  margin-top: 2em;
  color: ${({ theme }) => theme.text.error};
`;

const InfoIcon = styled(Icon)`
  margin-right: 16px;
`;

const InfoCard = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-top: 4px solid ${({ theme }) => theme.borders.info};
  display: flex;
  align-items: center;
  padding: 24px;
  margin-bottom: 50px;
`;
