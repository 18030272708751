import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { PropertyList } from "../../../../../components/organisms/PropertyList/PropertyList";
import { NewPropertyModal } from "./NewPropertyModal";
import { UserRoles } from "../../../../../models/permissions";
import { Protected } from "../../../../../components/atoms/Protected/Protected";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { Loader } from "../../../../../components/atoms/Loader/Loader";
import { EmptyPage } from "../../../../../components/atoms/EmptyPage/EmptyPage";
import EmptyPageLightSrc from "../../../../../assets/images/empty_page_light.png";
import EmptyPageDarkSrc from "../../../../../assets/images/empty_page_dark.png";
import { GuidanceModal } from "../../../../../components/organisms/GuidanceModal/GuidanceModal";
import { InputField } from "../../../../../components/molecules/InputField/InputField";
import { useSelectedConfiguration } from "../../../../../store/slices/caching/hooks";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";

const PLACEHOLDER_IMAGES = { light: EmptyPageLightSrc, dark: EmptyPageDarkSrc };

export interface IPropertiesProps {}

export const Properties: React.FC<IPropertiesProps> = () => {
  const [searchValue, setSearchValue] = useState("");
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationPropertiesPage");

  const propertyDefinitions =
    selectedConfiguration?.config?.propertyDefinitions;

  const filteredProperties = useCallback(() => {
    if (
      selectedConfiguration &&
      selectedConfiguration.config &&
      selectedConfiguration.config.propertyDefinitions
    ) {
      return selectedConfiguration.config.propertyDefinitions.filter(
        (property) =>
          [
            property.name,
            property.primaryAlias || "",
            selectedConfiguration.config?.originFillPolicyDefinitions
              .find((origin) => origin.name === property.originFillPolicy)
              ?.members.map((member) => member.origin.host) || [],
          ]
            .flat()
            .some((field) => {
              return field
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase());
            })
      );
    }
  }, [searchValue, selectedConfiguration]);

  const properties = !searchValue.length
    ? propertyDefinitions
    : filteredProperties();

  return !properties ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : propertyDefinitions && propertyDefinitions.length > 0 ? (
    <PropertiesContainer>
      <FlexBox>
        <InputContainer>
          <InputField
            hasBorder
            onChange={setSearchValue}
            placeholder={t("SEARCH_PROPERTY_PLACEHOLDER")}
            isSearchField
          />
        </InputContainer>
        {selectedConfiguration && (
          <Protected permissions={UserRoles.EDIT_CONFIG}>
            <NewPropertyModal />
          </Protected>
        )}
      </FlexBox>
      <br />
      <ExpandableContent
        title={t("PROPERTY_DESCRIPTION_TITLE")}
        content={t("PROPERTY_DESCRIPTION_CONTENT")}
      />
      {properties && (
        <PropertyList
          originDefinitions={
            selectedConfiguration?.config?.originFillPolicyDefinitions
          }
          properties={properties}
        />
      )}
    </PropertiesContainer>
  ) : (
    <EmptyPage
      dataTestId="properties-list-no-property"
      images={PLACEHOLDER_IMAGES}
      title={t("EMPTY_PROPERTY_LIST_TITLE")}
      content={t("EMPTY_PROPERTY_LIST_CONTENT")}
    >
      <GuidanceModal
        buttonLabel={t("GUIDANCE_BUTTON_LABEL")}
        title={t("GUIDANCE_MODAL_TITLE")}
        content={t("GUIDANCE_MODAL_CONTENT")}
      />
      {selectedConfiguration && (
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          <Margin />
          <NewPropertyModal />
        </Protected>
      )}
    </EmptyPage>
  );
};

const PropertiesContainer = styled.div`
  padding-bottom: 10rem;
`;

const LoaderContainer = styled(FlexBox)`
  margin-top: 10em;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const Margin = styled.span`
  margin: 1rem;
`;
