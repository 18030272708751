import { ReactElement, useState } from "react";

import { OriginTypes } from "../../../models/configuration/definitions/origin";
import { ConfigurationErrorLevel } from "../../../models/configuration/errors";
import { OriginFields } from "../../../modules/caching/configurations/configuration_page/properties/property_page/sections/origin/OriginFields";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  useConfigurationsErrors,
  useSelectedConfiguration,
} from "../../../store/slices/caching/hooks";
import { useAppDispatch } from "../../../store/types";
import { handleUpdateConfigurationErrors } from "../../../store/slices/caching/thunks";
import { OriginDefinitionType } from "../../../store/slices/caching/types";
import { createOriginDefinition } from "../../../store/slices/caching/helpers/origin-definition/createOriginDefinition";
import { generateDefaultMember } from "../../../store/slices/caching/helpers/generateDefaultMember";
import { getOriginDefinitionPropertyReferences } from "../../../store/slices/caching/helpers/getOriginDefinitionPropertyReferences";
import { getOriginReferences } from "../../../store/slices/caching/helpers/origin-definition/getOriginReferences";
import { validateOriginDefinition } from "../../../store/slices/caching/helpers/origin-definition/validateOriginDefinition";

type OriginModalProps = IDefaultDefinitionModalProps<OriginDefinitionType>;

const ORIGIN_DEFAULT_NAME = "New_Origin_Definition";

export const OriginModal = ({
  definition,
  parentConfig,
  onSubmit,
}: OriginModalProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const errors = useConfigurationsErrors();
  const dispatch = useAppDispatch();

  const [originDefinition, setOriginDefinition] = useState(
    definition ??
      (selectedConfiguration &&
        selectedConfiguration.config &&
        createOriginDefinition(ORIGIN_DEFAULT_NAME, {
          type: OriginTypes.SINGLE,
          members: [generateDefaultMember()],
        }))
  );

  const initState = () => {
    if (selectedConfiguration && selectedConfiguration.config) {
      setOriginDefinition(
        definition ??
          createOriginDefinition(ORIGIN_DEFAULT_NAME, {
            type: OriginTypes.SINGLE,
            members: [generateDefaultMember()],
          })
      );
    }
  };

  const validation = () => {
    const originErrors =
      !!originDefinition && validateOriginDefinition(originDefinition);

    if (originErrors && originErrors.length > 0) {
      dispatch(handleUpdateConfigurationErrors(originErrors));
      return false;
    }

    if (selectedConfiguration) {
      if (
        errors.filter((err) => err.level === ConfigurationErrorLevel.ERROR)
          .length !== 0
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="origindefinition-name"
          className="-text--h4"
          text={originDefinition!.name}
          dataTestId="origin-modal-header"
        />
      }
      references={
        definition ? getOriginReferences(definition, parentConfig) : 0
      }
      propertyReferences={
        definition
          ? getOriginDefinitionPropertyReferences(definition.name, parentConfig)
          : undefined
      }
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        if (originDefinition) {
          onSubmit(originDefinition);
        }
      }}
      dataTestId="origin"
    >
      {originDefinition && (
        <OriginFields
          originDefinition={originDefinition}
          onChange={(newValue) => {
            setOriginDefinition({ ...originDefinition, ...newValue });
          }}
        />
      )}
    </DefinitionModal>
  );
};
