import { ReactElement, useState } from "react";
import styled from "@emotion/styled";

import { Icon, Icons } from "../../atoms/Icon/Icon";

interface SearchBarProps {
  onChange?: (search: string) => void;
  placeholder: string;
}

export const SearchBar = ({
  placeholder,
  onChange,
}: SearchBarProps): ReactElement => {
  const [search, setSearch] = useState("");

  return (
    <SearchBarWrapper className="chi-input__wrapper -icon--left" role="search">
      <SearchInput
        className="chi-input"
        type="text"
        placeholder={placeholder}
        aria-label="Search"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
          onChange && onChange(event.target.value);
        }}
      />
      <SearchIcon className="chi-icon" name={Icons.SEARCH} />
    </SearchBarWrapper>
  );
};

const SearchBarWrapper = styled.div`
  height: 40px;
`;

const SearchInput = styled.input`
  height: 100%;
  height: 40px !important;
  line-height: 1em;
  font-size: large;
`;

const SearchIcon = styled(Icon)`
  margin-left: 4px;
  margin-top: 4px;
  path {
    fill: #8e9399;
  }
`;
