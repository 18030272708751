import { useReducer } from "react";

import { IDropdownItem } from "../../../../../../../components/molecules/DropdownOption/DropdownOption";
import { ConditionTypes, Operators } from "./dictionnaries";
import { setNewExpression } from "./setNewExpression";
import { ISplittedExpression } from "./types";

type State = {
  condition: string;
  conditionType: ConditionTypes | undefined;
  operator: Operators | undefined;
  operatorSensitive: boolean;
  englishOperator: boolean;
  objectName: string;
  expectedValue: string;
};

type Action =
  | { type: "SET_CONDITION_TYPE"; payload: ConditionTypes | undefined }
  | { type: "SET_OPERATOR"; payload: Operators | undefined }
  | { type: "SET_OPERATOR_SENSITIVE"; payload: boolean }
  | { type: "SET_OBJECT_NAME"; payload: string }
  | { type: "SET_EXPECTED_VALUE"; payload: string };

const INITIAL_STATE: State = {
  condition: "",
  conditionType: undefined,
  operator: undefined,
  operatorSensitive: false,
  englishOperator: false,
  objectName: "",
  expectedValue: "",
};

function getInitialState(
  splittedExpression: Omit<ISplittedExpression, "errors">
): State {
  return {
    ...INITIAL_STATE,
    conditionType: splittedExpression.conditionType?.trim() as ConditionTypes,
    operator: splittedExpression.operator?.trim() as Operators,
    objectName: splittedExpression.objectName.trim(),
    expectedValue: splittedExpression.expectedValue.trim(),
    operatorSensitive: splittedExpression.sensitive,
    englishOperator: splittedExpression.englishOperator,
  };
}

function getNewCondition(
  conditionType: ConditionTypes | undefined,
  operator: Operators | undefined,
  objectName: string,
  newValue: string,
  operatorSensitive: boolean,
  englishOperator: boolean
): string {
  if (!operator || !conditionType) return "";

  return setNewExpression(
    conditionType,
    objectName,
    operator,
    newValue,
    operatorSensitive,
    englishOperator
  );
}

function reducer(state: State, action: Action): State {
  const {
    conditionType,
    operator,
    objectName,
    expectedValue,
    operatorSensitive,
  } = state;

  switch (action.type) {
    case "SET_CONDITION_TYPE":
      return {
        ...state,
        conditionType: action.payload,
        objectName:
          action.payload === ConditionTypes.REQUEST_HEADERS
            ? ""
            : state.objectName,
        condition: getNewCondition(
          action.payload,
          operator,
          objectName,
          expectedValue,
          operatorSensitive,
          false
        ),
      };
    case "SET_OPERATOR":
      return {
        ...state,
        operator: action.payload,
        condition: getNewCondition(
          conditionType,
          action.payload,
          objectName,
          expectedValue,
          operatorSensitive,
          false
        ),
      };
    case "SET_OPERATOR_SENSITIVE":
      return {
        ...state,
        operatorSensitive: action.payload,
        condition: getNewCondition(
          conditionType,
          operator,
          objectName,
          expectedValue,
          action.payload,
          false
        ),
      };
    case "SET_OBJECT_NAME":
      return {
        ...state,
        objectName: action.payload,
        condition: getNewCondition(
          conditionType,
          operator,
          action.payload,
          expectedValue,
          operatorSensitive,
          false
        ),
      };
    case "SET_EXPECTED_VALUE":
      return {
        ...state,
        expectedValue: action.payload,
        condition: getNewCondition(
          conditionType,
          operator,
          objectName,
          action.payload,
          operatorSensitive,
          false
        ),
      };
    default:
      return state;
  }
}

export const useConditionUi = (
  splittedExpression: Omit<ISplittedExpression, "errors">
) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(splittedExpression)
  );

  const {
    condition,
    conditionType,
    operator,
    operatorSensitive,
    objectName,
    expectedValue,
  } = state;

  return {
    condition,
    conditionType,
    expectedValue,
    objectName,
    operator,
    operatorSensitive,
    setConditionsType: (dropdownItem: IDropdownItem<ConditionTypes>) =>
      dispatch({ type: "SET_CONDITION_TYPE", payload: dropdownItem.value }),
    setExpectedValue: (payload: string) =>
      dispatch({ type: "SET_EXPECTED_VALUE", payload }),
    setObjectName: (payload: string) =>
      dispatch({ type: "SET_OBJECT_NAME", payload }),
    setOperator: (dropdownItem: IDropdownItem<Operators>) =>
      dispatch({ type: "SET_OPERATOR", payload: dropdownItem.value }),
    setOperatorSensitive: (payload: boolean) =>
      dispatch({ type: "SET_OPERATOR_SENSITIVE", payload }),
  };
};
