import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { HeaderDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createHeaderDefinition = (
  name: string,
  rest: Pick<HeaderDefinitionType, "description" | "headers">
): HeaderDefinitionType => ({
  ...parseDefinition({
    name,
    description: rest.description,
    type: TypeDefinitions.HEADER,
  }),
  headers: rest.headers,
});
