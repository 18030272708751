import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

import { ColorSet } from "../../../contexts/themeContext";

export const Toast = styled(ToastContainer)<{ colorset: ColorSet }>`
  .Toastify__toast--success {
    .Toastify__toast-icon {
      margin-right: 16px;
      * {
        fill: ${({ colorset }) => colorset.backgrounds.success};
      }
    }
  }
  .Toastify__toast--warning {
    .Toastify__toast-icon {
      margin-right: 16px;
      * {
        fill: ${({ colorset }) => colorset.backgrounds.warning};
      }
    }
  }
  .Toastify__toast--error {
    .Toastify__toast-icon {
      margin-right: 16px;
      * {
        fill: ${({ colorset }) => colorset.backgrounds.error};
      }
    }
  }
  .Toastify__toast--info {
    .Toastify__toast-icon {
      margin-right: 16px;
      * {
        fill: ${({ colorset }) => colorset.backgrounds.info};
      }
    }
  }
  .Toastify__toast {
    background: ${({ colorset }) => colorset.backgrounds.baseLight};
    color: ${({ colorset }) => colorset.text.primary};
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
    border-radius: 0px;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    top: 0;
    height: 4px;
  }
  .Toastify__progress-bar--success {
    background: ${({ colorset }) => colorset.backgrounds.success};
  }
  .Toastify__progress-bar--error {
    background: ${({ colorset }) => colorset.backgrounds.error};
  }
  .Toastify__progress-bar--info {
    background: ${({ colorset }) => colorset.backgrounds.info};
  }
  .Toastify__progress-bar--warning {
    background: ${({ colorset }) => colorset.backgrounds.warning};
  }
  .Toastify__close-button--light {
    color: ${({ colorset }) => colorset.text.primary};
  }
`;
