import { ConfigurationDetailsType, LuaDefinitionType } from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getLuaReferences = (
  def: LuaDefinitionType,
  config: ConfigurationDetailsType
): number => {
  const reqLuaRef = getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .map((feature) => feature?.reqLua)
    .filter((reqLua) => reqLua?.nameList.includes(def.name)).length;

  const respLuaRef = getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .map((feature) => feature?.respLua)
    .filter((respLua) => respLua?.nameList.includes(def.name)).length;

  return reqLuaRef + respLuaRef;
};
