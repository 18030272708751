import type { ReactElement } from "react";
import type { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import type { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { useSubscriberSettingsSystemLimits } from "../../../../../../../../../store/slices/subscriber-settings/hooks";
import type { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { useInitOpen } from "./useInitOpen";

interface IFailedRefreshTTLFeatureBlockProps {
  initIsOpen: boolean;
  maxWidth: number;
  errMsg: Exclude<ValidationErrors["failedRefreshTTL"], undefined>["ttl"];
  datatestId: string;
  onDelete: () => void;
  register: UseFormRegister<IFeatures>;
}

export const FailedRefreshTTLFeatureBlock = ({
  initIsOpen,
  maxWidth,
  errMsg,
  datatestId,
  onDelete,
  register,
}: IFailedRefreshTTLFeatureBlockProps): ReactElement => {
  const isViewMode = useIsViewMode();
  const isOpen = useInitOpen(initIsOpen);
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const systemLimits = useSubscriberSettingsSystemLimits();
  const failedRefreshLimits = systemLimits.failedRefreshTTL;

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_FAILED_REFRESH_TTL_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_FAILED_REFRESH_TTL_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_FAILED_REFRESH_TTL_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_FAILED_REFRESH_TTL_SECONDS_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_FAILED_REFRESH_TTL_SECONDS_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_FAILED_REFRESH_TTL_SECONDS_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          fieldProps={{
            other: (
              <HeadlessInputField
                disabled={isViewMode}
                placeholder={t(
                  "FEATURE_CARD_FAILED_REFRESH_TTL_SECONDS_PLACEHOLDER"
                )}
                {...register("failedRefreshTTL.ttl", {
                  required: tMisc("ERROR_REQUIRED_FIELD"),
                  min: {
                    value: failedRefreshLimits.min,
                    message: `Must be min ${failedRefreshLimits.min}`,
                  },
                  max: {
                    value: failedRefreshLimits.max,
                    message: `Must be max ${failedRefreshLimits.max}`,
                  },
                  validate: (value) => value >= 0 || "Must be positive",
                  valueAsNumber: true,
                })}
                data-testid="failed-refresh-ttl-input"
                type="number"
              />
            ),
          }}
          errorMessage={errMsg?.message}
          maxWidth={maxWidth}
          dataTestId="failed-refresh-ttl"
        />
      }
      initIsOpen={isOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName="failedRefreshTTL"
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="failed-refresh-ttl"
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};
