import styled from "@emotion/styled";
import { MouseEventHandler } from "react";

export interface HeadlessModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onBlur?: () => void;
}

const handlePropagation: MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

export const HeadlessModal: React.FC<HeadlessModalProps> = ({
  children,
  isOpen,
  onClose,
  onBlur,
}) => {
  return isOpen ? (
    <>
      <ModalBackground />
      <Container
        onClick={() => {
          onClose && onClose();
          onBlur && onBlur();
        }}
      >
        <ModalContent onClick={handlePropagation}>{children}</ModalContent>
      </Container>
    </>
  ) : null;
};

const ModalBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99998;
  opacity: 0.75;
  background-color: ${({ theme }) => theme.backgrounds.mutedDark};
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
  overflow: auto;
`;

const ModalContent = styled.div`
  margin-top: 10rem;
  margin-bottom: 10rem;
  margin-right: auto;
  margin-left: auto;

  width: 50%;
  max-width: 800px;
`;
