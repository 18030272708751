import type { ReactElement } from "react";
import styled from "@emotion/styled";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Field,
  FieldInput,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderWrapper,
} from "../../../../../../../../../../components/molecules/DefinitionModal/DefinitionModal.styled";
import { RadioButtonsSelector } from "../../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import { LuaVariable } from "../../../../../../../../../../models/configuration/definitions/lua";
import { IVariableValueList } from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { LuaDefinitionType } from "../../../../../../../../../../store/slices/caching/types";
import { IValidationErrors } from "../../FeaturesList";
import { ValidatedFieldInput } from "./ValidatedFieldInput";

interface VariablesTableProps {
  dataTestId: string;
  disabled: boolean;
  luaType: "reqLua" | "respLua";
  variables: LuaVariable[];
  selectedDefinition: LuaDefinitionType;
  errors: IValidationErrors;
  rowIndex: number;
  variableValueList: IVariableValueList[];
  handleCreateFeature: UseFormSetValue<IFeatures>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
}

const getVariableValue = (
  variableValueList: IVariableValueList[],
  variable: LuaVariable,
  selectedDefinition: LuaDefinitionType
) => {
  // Find the values in `variableValueList` that match the name of `selectedDefinition`
  const values = variableValueList.find(
    (v) => v.luaName === selectedDefinition.name
  );

  // If `values` are found, return the value in `variableValues` that matches the name of `variable`
  return values?.variableValues.find((v) => v.name === variable.name)?.value;
};

const replaceVariable = (
  variableValueList: IVariableValueList[],
  name: string,
  newValue: string | number | boolean,
  selectedDefinition: LuaDefinitionType
) => {
  // Find the variable values for the selected definition
  const variableList = variableValueList.find(
    (v) => v.luaName === selectedDefinition.name
  )?.variableValues;

  // If there are variables for the selected definition
  if (!variableList) return variableValueList;

  // Map through the variables and update the one with the specified name
  const updatedVariableList = variableList.map((variable) =>
    variable.name === name ? { ...variable, value: newValue } : variable
  );

  // Replace the variable list of the selected definition with the updated one
  return variableValueList.map((v) =>
    v.luaName === selectedDefinition.name
      ? { ...v, variableValues: updatedVariableList }
      : v
  );
};

export const VariablesTable = ({
  disabled,
  luaType,
  variables,
  selectedDefinition,
  errors,
  rowIndex,
  dataTestId,
  variableValueList,
  handleCreateFeature,
  setError,
  clearErrors,
}: VariablesTableProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");

  const handleVariableChange = (variable: LuaVariable) => (
    value: string | number | boolean
  ) => {
    handleCreateFeature(
      `${luaType}.variableValueList` as Parameters<
        typeof handleCreateFeature
      >[0],
      replaceVariable(
        variableValueList,
        variable.name,
        variable.type === "number" ? Number(value) : value,
        selectedDefinition
      )
    );
  };

  return (
    <Table>
      <TableHeaderWrapper>
        <TableHeader>{t("FEATURE_CARD_LUA_ITEM_VARIABLES_TH_1")}</TableHeader>
        <TableHeader>{t("FEATURE_CARD_LUA_ITEM_VARIABLES_TH_2")}</TableHeader>
        <TableHeader>{t("FEATURE_CARD_LUA_ITEM_VARIABLES_TH_3")}</TableHeader>
      </TableHeaderWrapper>
      <TableBody>
        {variables.map((variable, i) => {
          const onClick = handleVariableChange(variable);

          return (
            <Field key={`row-${i}`}>
              <TableCell rightPadding bottomPadding="thin">
                <FieldInput value={variable.name} disabled />
              </TableCell>
              <TableCell leftPadding bottomPadding="thin">
                <FieldInput value={variable.type} disabled />
              </TableCell>
              <TableCell leftPadding bottomPadding="thin">
                {variable.type === "boolean" ? (
                  <RadioButtonContainer>
                    <RadioButtonsSelector
                      inline
                      headlessRadio={false}
                      radioButtonsProps={[
                        {
                          disabled,
                          value: true,
                          onClick,
                          label: t(
                            "FEATURE_CARD_LUA_ITEM_VARIABLES_TC_RADIO_1_LABEL"
                          ),
                          name: `${variable.name}-${luaType}-boolean`,
                          id: `${variable.name}-${luaType}-true`,
                          defaultChecked:
                            getVariableValue(
                              variableValueList || [],
                              variable,
                              selectedDefinition
                            ) === true,
                        },
                        {
                          disabled,
                          value: false,
                          onClick,
                          label: t(
                            "FEATURE_CARD_LUA_ITEM_VARIABLES_TC_RADIO_2_LABEL"
                          ),
                          name: `${variable.name}-${luaType}-boolean`,
                          id: `${variable.name}-${luaType}-false`,
                          defaultChecked:
                            getVariableValue(
                              variableValueList || [],
                              variable,
                              selectedDefinition
                            ) === false,
                        },
                      ]}
                    />
                  </RadioButtonContainer>
                ) : (
                  <>
                    <ValidatedFieldInput
                      disabled={disabled}
                      placeholder={variable.type === "number" ? "5" : "value"}
                      value={
                        getVariableValue(
                          variableValueList || [],
                          variable,
                          selectedDefinition
                        ) as number | string
                      }
                      required
                      onChange={onClick}
                      type={variable.type === "string" ? "text" : "number"}
                      id={`${luaType}-${rowIndex}-${selectedDefinition.name}-${variable.name}-input`}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                    {errors[
                      `${luaType}-${rowIndex}-${selectedDefinition.name}-${variable.name}-input`
                    ] && (
                      <ErrorContainer data-testid={`${dataTestId}-error`}>
                        {
                          errors[
                            `${luaType}-${rowIndex}-${selectedDefinition.name}-${variable.name}-input`
                          ].message
                        }
                      </ErrorContainer>
                    )}
                  </>
                )}
              </TableCell>
            </Field>
          );
        })}
      </TableBody>
    </Table>
  );
};

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => `${theme.text.error}`};
  font-size: 12px;
  padding: 4px;
`;
