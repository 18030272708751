import styled from "@emotion/styled";

export interface AsteriskProps {
  title?: string;
  dataTestId?: string;
  className?: string;
}

export const Asterisk: React.FC<AsteriskProps> = ({ title, dataTestId }) => (
  <Container
    title={title}
    data-testid={`property-definition-card-${dataTestId}-asterisk`}
  >
    *
  </Container>
);

const Container = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.text.error};
`;
