import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { animated, SpringValue } from "react-spring";

import { Icon, Icons } from "../Icon/Icon";
import { handleUpdateDefinition } from "../../../store/slices/caching/thunks";
import { useAppDispatch } from "../../../store/types";
import { ConfigurationDefinition } from "../../../store/slices/caching/types";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { getDefinitionReferences } from "../../../store/slices/caching/helpers/getDefinitionReferences";

interface DefinitionDropdownItemProps {
  allowEdit: boolean;
  isSelected: boolean;
  item: ConfigurationDefinition;
  propertyReferences: number;
  index: number;
  dataTestId?: string;
  disabled?: boolean;
  style?: {
    opacity: SpringValue<number>;
    height: SpringValue<string>;
  };
  onClick: () => void;
  renderModal: (
    onSubmit: (i: ConfigurationDefinition) => void,
    definition?: ConfigurationDefinition
  ) => JSX.Element | undefined;
}

export const DefinitionDropdownItem: React.FC<DefinitionDropdownItemProps> = ({
  allowEdit,
  isSelected,
  item,
  propertyReferences,
  index,
  dataTestId,
  style,
  disabled = false,
  onClick,
  renderModal,
}) => {
  const dispatch = useAppDispatch();
  const selectedConfiguration = useSelectedConfiguration();

  const [
    showDropdownReferenceMessage,
    setShowDropdownReferenceMessage,
  ] = useState(false);
  const [showDisabledMessage, setShowDisabledMessage] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [viewModePopoverPosition, setViewModePopoverPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [
    itemContainerRef,
    setItemContainerRef,
  ] = useState<HTMLDivElement | null>(null);
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [
    viewModePopoverRef,
    setViewModePopoverRef,
  ] = useState<HTMLElement | null>(null);

  const { t } = useTranslation("configurationDefinitionsPage");

  const handleUpdate = (definition: ConfigurationDefinition) => {
    dispatch(handleUpdateDefinition(definition));
  };

  useEffect(() => {
    if (itemContainerRef && popoverRef) {
      setPopoverPosition({
        x: itemContainerRef.getBoundingClientRect().width + 20,
        y: -popoverRef.getBoundingClientRect().height + 10,
      });
    }
  }, [itemContainerRef, popoverRef]);

  useEffect(() => {
    if (itemContainerRef && viewModePopoverRef) {
      setViewModePopoverPosition({
        x: itemContainerRef.getBoundingClientRect().width + 20,
        y: -viewModePopoverRef.getBoundingClientRect().height + 10,
      });
    }
  }, [itemContainerRef, viewModePopoverRef]);

  return (
    <ItemContainer ref={setItemContainerRef} isSelected={isSelected}>
      <Item
        onClick={() => !disabled && onClick()}
        className="chi-dropdown__menu-item"
        data-testid={`definition-dropdown-item-${dataTestId}-${index}`}
        style={style}
        isdisabled={disabled}
        isselected={isSelected}
      >
        {item.name}
      </Item>

      {allowEdit && (
        <ButtonsContainer>
          {!disabled &&
            ((item &&
              selectedConfiguration?.config &&
              getDefinitionReferences(item, selectedConfiguration.config) >
                0) ||
              propertyReferences > 0) && (
              <InfoIconContainer>
                <div
                  onMouseOver={() => setShowDropdownReferenceMessage(true)}
                  onMouseLeave={() => setShowDropdownReferenceMessage(false)}
                >
                  <InfoIcon
                    name={Icons.EYE}
                    className="chi-icon icon-circle-info-outline"
                  />
                </div>
              </InfoIconContainer>
            )}
          <EditButtonContainer
            data-testid={`definition-dropdown-item-${dataTestId}-${index}-edit-icon`}
            onMouseOver={() => disabled && setShowDisabledMessage(true)}
            onMouseLeave={() => disabled && setShowDisabledMessage(false)}
          >
            {renderModal(handleUpdate, item)}
          </EditButtonContainer>
        </ButtonsContainer>
      )}
      {showDropdownReferenceMessage && (
        <Popover popoverPosition={popoverPosition} ref={setPopoverRef}>
          <MessageContainer className="-px--2">
            <Message>
              {propertyReferences > 0
                ? t("DEFINITION_DROPDOWN_MATCH_RULES_ORIGIN_INFO", {
                    count: selectedConfiguration?.config
                      ? getDefinitionReferences(
                          item,
                          selectedConfiguration.config
                        )
                      : 0,
                    propertyCount: propertyReferences,
                  })
                : t("DEFINITION_DROPDOWN_MATCH_RULES_INFO", {
                    count: selectedConfiguration?.config
                      ? getDefinitionReferences(
                          item,
                          selectedConfiguration.config
                        )
                      : 0,
                  })}
            </Message>
          </MessageContainer>
        </Popover>
      )}
      {showDisabledMessage && (
        <Popover
          popoverPosition={viewModePopoverPosition}
          ref={setViewModePopoverRef}
        >
          <MessageContainer className="-px--2">
            <Message>{t("CANNOT_REUSE_DEFINITION_POPOVER")}</Message>
          </MessageContainer>
        </Popover>
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      background-color: ${theme.backgrounds.highlightLight};
      border-left: 1px solid ${theme.borders.highlight};
      color: ${theme.text.highlight};
    `}
`;

const Item = styled(animated.a)<{ isselected: boolean; isdisabled: boolean }>`
  cursor: pointer;
  padding: 22px 12px !important;
  width: 100%;
  :hover {
    background-color: transparent !important;
  }

  ${({ theme, isselected, isdisabled }) =>
    !isselected &&
    isdisabled &&
    css`
      opacity: 0.5;
      border-left: 1px solid ${theme.borders.muted};
      color: ${theme.text.muted};
    `}
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const InfoIconContainer = styled.div``;

const InfoIcon = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.backgrounds.info};
  }
`;

const Popover = styled.section<{
  popoverPosition: { x: number; y: number };
}>`
  position: absolute;
  width: 320px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: ${({ popoverPosition }) => `${popoverPosition.x}px`};
  top: ${({ popoverPosition }) => `${popoverPosition.y}px`};

  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
  border-radius: 0.25rem;
  background-color: white;

  ::after {
    content: "";
    border-left-color: transparent;
    border-top-color: transparent;
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    margin: 0;
    position: absolute;

    transform: rotate(45deg);
    border: 0.375rem solid #fff;
    top: 27px;
    left: -5px;
  }
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.grey100};
`;

const MessageContainer = styled.div``;

const EditButtonContainer = styled.div`
  padding: 0px 8px;
  cursor: pointer;
`;
