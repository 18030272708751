import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { IVersionHistory } from "../../models/configuration/configuration_details.interfaces";
import { useSelectedConfiguration } from "../../store/slices/caching/hooks";
import { handleUpdateSelectedConfigurationVersion } from "../../store/slices/caching/thunks";
import { useAppDispatch } from "../../store/types";
import { VersionActions } from "../../components/organisms/VersionHistoryCard/VersionHistoryCard";

export interface UseVersionActionParams {
  activeAction: VersionActions | undefined;
  resetActiveAction: () => void;
  versionHistory: IVersionHistory;
}

export const useVersionAction = ({
  activeAction,
  resetActiveAction,
  versionHistory,
}: UseVersionActionParams): void => {
  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (
      activeAction === VersionActions.EDIT &&
      selectedConfiguration &&
      !selectedConfiguration.isLocalDraft &&
      selectedConfiguration.versionId !== versionHistory.versionId
    ) {
      dispatch(
        handleUpdateSelectedConfigurationVersion(versionHistory.versionId)
      );
      resetActiveAction();
      history.push("properties");
    }
  }, [activeAction, selectedConfiguration, versionHistory.versionId]);
};
