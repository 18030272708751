import styled from "@emotion/styled";

export const FontBase = styled.div`
  font-size: 14px;
  line-height: 16px;

  color: ${({ theme }) => theme.text.primary};
`;

export const FontTitle = styled(FontBase)`
  font-weight: 700;
`;

export const FontLink = styled(FontBase)`
  color: ${({ theme }) => theme.interactive.linkHover};
  cursor: pointer;
`;
