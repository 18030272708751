import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { isDefinitionNameValid } from "../../../utils/string";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  Field,
  FieldInput,
  FieldName,
  Table,
  TableBody,
  TableCell,
} from "../DefinitionModal/DefinitionModal.styled";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { LuaDefinitionType } from "../../../store/slices/caching/types";
import { Checkbox } from "../Checkbox/Checkbox";
import { checkUserPermissions, UserRoles } from "../../../models/permissions";
import { useUserPermissions } from "../../../store/slices/permissions/hooks";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";
import { getLuaReferences } from "../../../store/slices/caching/helpers/lua-definition/getLuaReferences";

type LuaDefinitionModalProps = IDefaultDefinitionModalProps<LuaDefinitionType>;

export const LuaDefinitionModal = ({
  definition,
  canEdit,
  parentConfig,
  onSubmit,
}: LuaDefinitionModalProps): ReactElement => {
  const [name, setName] = useState(definition?.name ?? "");
  const [description, setDescription] = useState(
    definition?._schemaDescription ?? ""
  );
  const [error, setError] = useState("");
  const { t } = useTranslation("configurationDefinitionsPage");
  const permissions = useUserPermissions();

  const selectedConfiguration = useSelectedConfiguration();

  const initState = () => {
    setError("");
    setName(definition?.name ?? "");
    setDescription(definition?._schemaDescription ?? "");
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.LUA]
      )
    ) {
      setError(t("ERROR_DEFINITION_LUA_ALREADY_EXISTS", { name }));
      return false;
    } else if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    return true;
  };

  return (
    <LuaDefinitionModalWrapper
      header={
        <TextField
          id="lua-modal-name"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
        />
      }
      references={
        definition
          ? getLuaReferences(definition as LuaDefinitionType, parentConfig)
          : 0
      }
      onCancel={initState}
      validation={validation}
      onSubmit={() => {
        if (definition) {
          onSubmit({ ...definition, name, _schemaDescription: description });
        }
      }}
      isEdit={!!definition}
      dataTestId="lua"
      canEdit={checkUserPermissions(UserRoles.EDIT_CONFIG, permissions)}
    >
      <Table>
        <TableBody>
          <Field>
            <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>
            <FieldInput
              disabled={
                !checkUserPermissions(UserRoles.EDIT_CONFIG, permissions)
              }
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
            />
          </Field>
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_LUA_DESCRIPTION_FIELD_LABEL")}
            </FieldName>
            <FieldInput
              disabled={
                !checkUserPermissions(UserRoles.EDIT_CONFIG, permissions)
              }
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Field>
          {definition?.script && (
            <>
              <Field>
                <FieldName align="top">
                  {t("EDIT_DEFINITION_LUA_SCRIPT_FIELD_LABEL")}
                </FieldName>
                <TableCell>
                  <TextArea
                    disabled
                    value={definition.script}
                    lines={definition.script.split("\n").length}
                  />
                </TableCell>
              </Field>
              <Field>
                <FieldName align="top"></FieldName>
                <TableCell>
                  <Checkbox
                    id="heavy"
                    label="Heavy"
                    onChange={() => {}}
                    checked={definition.heavy}
                    disabled
                  />
                </TableCell>
              </Field>
            </>
          )}
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_LUA_VARIABLES_FIELD_LABEL")}
            </FieldName>
            <TableCell>
              <List>
                {definition?.variables?.map((variable, i) => (
                  <ListItem key={i}>{variable.name}</ListItem>
                ))}
              </List>
            </TableCell>
          </Field>
        </TableBody>
      </Table>
      {error && <NotificationCard theme="error" title={error} />}
    </LuaDefinitionModalWrapper>
  );
};

const LuaDefinitionModalWrapper = styled(DefinitionModal)`
  min-width: 60em;
`;

const TextArea = styled.textarea<{ lines: number }>`
  white-space: pre-line;
  resize: none;
  height: ${({ lines }) => `${lines + 2}em`};
  max-height: 40vh;
  width: 100%;
  padding-top: 0.5em;
  padding-left: 0.5em;
`;

const List = styled.div``;

const ListItem = styled.div``;
