import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Dropdown } from "../../../../../components/organisms/Dropdown/Dropdown";
import { InputField } from "../../../../../components/molecules/InputField/InputField";
import { ErrorContainer } from "../../../../../components/molecules/DefinitionModal/DefinitionModal.styled";
import { CustomRemark } from "../../../../../components/atoms/CustomRemark/CustomRemark";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { Divider } from "../../../../../components/atoms/Divider/Divider";
import { ApiKeyFieldProps, ApiKeyFieldType } from "../types";
import { FontLink } from "../../../../../components/atoms/Font/Font";
import { useBool } from "../../../../../hooks/use-bool/useBool";
import { FlexBoxBase } from "../../../../../components/atoms/FlexBox/FlexBox";
import { TextArea } from "../../../../../components/molecules/TextArea/TextArea";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { VyvxApiKeysCompanies } from "../vyvx/VyvxApiKeysCompanies";
import { CdnApikeysDropdown } from "../cdn/CdnApiKeysDropdown";
import { VyvxFiberCompany } from "../../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
export interface ApiKeyDetailCardProps {
  title?: string;
  additionalInfoTitle?: string;
  additionalInfoContent?: string;
  required?: boolean;
  fieldType: ApiKeyFieldType;
  fieldProps: ApiKeyFieldProps;
  divider?: boolean;
  className?: string;
  maxWidth?: number;
  errorMessage?: string;
  asterisk?: boolean;
  itemToCopy?: string;
  handleSecretGeneration?: () => void;
  isVyvx: boolean;
  fiberCompanies?: VyvxFiberCompany[];
}

export const ApiKeyDetailCard = ({
  title,
  additionalInfoTitle,
  additionalInfoContent,
  required,
  fieldType,
  fieldProps,
  divider = false,
  className,
  maxWidth = 300,
  errorMessage,
  asterisk = false,
  itemToCopy,
  handleSecretGeneration,
  isVyvx,
  fiberCompanies,
}: ApiKeyDetailCardProps): ReactElement => {
  const { t } = useTranslation("apiKeysPage");
  const [displaySecret, toggleDisplaySecret] = useBool(false);

  return (
    <>
      <ApiKeyDetailCardContainer className={className}>
        {title && (
          <LeftContainer>
            <TitleContainer>
              <Title>{title}</Title>
              {(required || asterisk) && (
                <Asterisk
                  className="chi-label__required"
                  title="Required field"
                >
                  *
                </Asterisk>
              )}
            </TitleContainer>
          </LeftContainer>
        )}
        <RightContainer>
          <InputContainer>
            {(() => {
              switch (fieldType) {
                case ApiKeyFieldType.InputFieldType:
                  return (
                    <FieldContainer maxWidth={maxWidth}>
                      <InputField
                        onChange={(value) => {
                          fieldProps.onChange
                            ? fieldProps.onChange(
                                fieldProps.inputType === "number"
                                  ? value.length > 0
                                    ? Number(value)
                                    : undefined
                                  : value
                              )
                            : () => {};
                        }}
                        info={fieldProps.hoverMessage}
                        hasBorder={true}
                        onBlur={fieldProps.onBlur}
                        placeholder={fieldProps.placeholder}
                        value={fieldProps.value}
                        disabled={fieldProps.disabled}
                        id={fieldProps.id}
                        register={fieldProps.register}
                        validation={fieldProps.validation}
                        type={fieldProps.inputType || "text"}
                        min={fieldProps.min}
                        required={required || false}
                      />
                    </FieldContainer>
                  );
                case ApiKeyFieldType.DropdownType:
                  return (
                    <>
                      <Dropdown
                        items={fieldProps.items || []}
                        placeholder={fieldProps.placeholder || ""}
                        id={fieldProps.id || ""}
                        onSelect={fieldProps.onSelect || (() => {})}
                        bold={false}
                        disabled={fieldProps.disabled}
                        outline={fieldProps.outline}
                        type={fieldProps.dropdownType}
                        setError={fieldProps.setError}
                        clearErrors={fieldProps.clearErrors}
                        onBlur={fieldProps.onBlur}
                      />
                      <div>{fieldProps.other}</div>
                    </>
                  );
                case ApiKeyFieldType.SecretType:
                  return (
                    <SecretFieldContainer maxWidth={maxWidth}>
                      {displaySecret ? (
                        <SecretField>{fieldProps.value}</SecretField>
                      ) : (
                        <SecretField>********************</SecretField>
                      )}
                      {itemToCopy && (
                        <ActionsField>
                          <FontLink
                            style={{ marginRight: "16px" }}
                            onClick={toggleDisplaySecret}
                          >
                            Show
                          </FontLink>
                          <FontLink
                            onClick={() => {
                              navigator.clipboard
                                .writeText(itemToCopy)
                                .then(() => {
                                  toast.success(t("TOAST_COPY_SECRET"));
                                });
                            }}
                          >
                            Copy
                          </FontLink>
                        </ActionsField>
                      )}
                    </SecretFieldContainer>
                  );
                case ApiKeyFieldType.TextArea:
                  return (
                    <TextArea
                      onChange={(value) => {
                        fieldProps.onChange
                          ? fieldProps.onChange(
                              fieldProps.inputType === "number"
                                ? value.length > 0
                                  ? Number(value)
                                  : undefined
                                : value
                            )
                          : () => {};
                      }}
                      value={fieldProps.value}
                    />
                  );
                case ApiKeyFieldType.CustomerDropdown:
                  return (
                    <>
                      {isVyvx && fiberCompanies ? (
                        <VyvxApiKeysCompanies
                          fiberCompanies={fiberCompanies}
                          keyCompanyFilter={fieldProps.value}
                          onCompnayFilterChange={
                            fieldProps.onSelect || (() => {})
                          }
                          isVyvx={isVyvx}
                        />
                      ) : (
                        <CdnApikeysDropdown
                          accessGroupFilter={fieldProps.value}
                          handleSelectAccessGroup={
                            fieldProps.onSelect || (() => {})
                          }
                          isVyvx={isVyvx}
                        />
                      )}
                      <div>{fieldProps.other}</div>
                    </>
                  );
              }
            })()}
          </InputContainer>
          {errorMessage && (
            <ErrorContainer>
              <CustomRemark>{errorMessage}</CustomRemark>
            </ErrorContainer>
          )}
        </RightContainer>
        {fieldType === ApiKeyFieldType.SecretType && (
          <GenerateSecretButton>
            <FontLink onClick={handleSecretGeneration}>
              Generate new secret
            </FontLink>
          </GenerateSecretButton>
        )}
      </ApiKeyDetailCardContainer>
      {additionalInfoTitle && (
        <Footer>
          {additionalInfoTitle && (
            <AdditionalInfoContainer>
              <ExpandableContent
                title={additionalInfoTitle}
                content={additionalInfoContent}
              />
            </AdditionalInfoContainer>
          )}
        </Footer>
      )}
      {divider ? <Divider className="chi-divider" /> : null}
    </>
  );
};

const ApiKeyDetailCardContainer = styled(FlexBoxBase)`
  align-items: center;

  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  font-weight: 600;
  line-height: 1.5rem;
  margin-right: 24px;
`;

const TitleContainer = styled(FlexBoxBase)``;

const Title = styled.div`
  margin: 0 !important;
  font-size: 1rem;
`;

const Asterisk = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.text.error};
`;

const InputContainer = styled.div`
  width: 100%;
  align-items: center;
`;

const RightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FieldContainer = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  min-width: 300px;
  width: 100%;
`;

const Footer = styled.div`
  margin-top: 10px;
  width: 100%; ;
`;

const AdditionalInfoContainer = styled.div``;

const SecretFieldContainer = styled(FlexBoxBase)<{ maxWidth?: number }>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  min-width: 300px;
  width: 100%;
  margin-left: 30px;

  justify-content: space-between;
`;

const SecretField = styled.div`
  margin-right: 16px;
`;

const ActionsField = styled(FlexBoxBase)``;

const GenerateSecretButton = styled.div``;
