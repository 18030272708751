import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "../../../../../components/atoms/Button/Button";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { IModalChildren } from "../../../../../components/atoms/Modal/Modal";
import { useAppDispatch } from "../../../../../store/types";
import { handleDeleteAccessGroup } from "../../../../../store/slices/user-management-access-group/thunk";
import { AccessGroupInfo } from "../../../../../models/user-management/access-group/access-group.interfaces";
import { CustomRemark } from "../../../../../components/atoms/CustomRemark/CustomRemark";

export interface DeletionConfirmationModalContentProps extends IModalChildren {
  accessGroupInfo: AccessGroupInfo;
  resourcesToBeDeleted?: {
    email: string;
    apiKeyName?: string;
  }[];
}

export const DeletionConfirmationModalContent = ({
  closeModal,
  accessGroupInfo,
  resourcesToBeDeleted,
}: DeletionConfirmationModalContentProps): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  const dispatch = useAppDispatch();

  const history = useHistory();

  const deleteAccessGroup = () => {
    dispatch(
      handleDeleteAccessGroup(accessGroupInfo.accessGroupId, () => {
        history.push("/userManagement/accessGroups");
      })
    );
  };

  const shouldBlockDeletion = resourcesToBeDeleted?.some(
    (ressource) => !!ressource.apiKeyName
  );

  const willResourcesBeDeleted =
    resourcesToBeDeleted && resourcesToBeDeleted.length > 0;

  return (
    <Container>
      <Title>{t("ACCESS_GROUP_DELETION_TITLE")}</Title>
      <CustomRemark>
        {willResourcesBeDeleted
          ? t("ACCESS_GROUP_DELETION_DESCRIPTION_1")
          : t("ACCESS_GROUP_DELETION_DESCRIPTION_NO_RESSOURCES", {
              accessGroupName: accessGroupInfo.name,
            })}
      </CustomRemark>
      {willResourcesBeDeleted && (
        <>
          <ul>
            {resourcesToBeDeleted?.map(({ email, apiKeyName }) => (
              <li key={email}>
                {email}
                {!!apiKeyName && (
                  <>
                    :{" "}
                    <KeyNameText>
                      {t("ACCESS_GROUP_DELETION_API_KEY_OWNER")}
                    </KeyNameText>
                    <KeyName>{apiKeyName}</KeyName>
                  </>
                )}
              </li>
            ))}
          </ul>
          <CustomRemark>
            {shouldBlockDeletion
              ? t("ACCESS_GROUP_DELETION_DESCRIPTION_DISABLED", {
                  accessGroupName: accessGroupInfo.name,
                })
              : t("ACCESS_GROUP_DELETION_DESCRIPTION_2", {
                  accessGroupName: accessGroupInfo.name,
                })}
          </CustomRemark>
        </>
      )}
      <ButtonContainer>
        <Button
          onClick={() => {
            if (closeModal) closeModal();
          }}
          label={t("ACCESS_GROUP_DELETION_CANCEL")}
          backgroundColor="baseLight"
          borderColor="white"
          textColor="primary"
        />
        <Button
          onClick={() => {
            deleteAccessGroup();
            if (closeModal) closeModal();
          }}
          label={t("ACCESS_GROUP_DELETION_DELETE")}
          backgroundColor="red50"
          disabled={shouldBlockDeletion}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ButtonContainer = styled(FlexBox)`
  justify-content: flex-end;
  margin-top: 32px;
  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

const KeyNameText = styled.span`
  color: ${({ theme }) => theme.text.error};
`;

const KeyName = styled.span`
  font-weight: 800;
`;
