import {
  createContext,
  useState,
  useContext,
  useLayoutEffect,
  FC,
} from "react";
import { useTheme, ThemeProvider } from "@emotion/react";

export enum Theme {
  LIGHT_MODE = "light",
  DARK_MODE = "dark",
}

enum Color {
  GREY10 = "#F8F9F9",
  GREY20 = "#EDF0F2",
  GREY30 = "#D0D4D9",
  GREY40 = "#ACB0B5",
  GREY50 = "#8E9399",
  GREY60 = "#65686C",
  GREY70 = "#53565A",
  GREY80 = "#3F4145",
  GREY90 = "#313336",
  GREY100 = "#242526",
  RED10 = "#FFF5F5",
  RED20 = "#FFE8E5",
  RED30 = "#FFCEBD",
  RED40 = "#FF9086",
  RED50 = "#FF645C",
  RED60 = "#EE3026",
  RED70 = "#D62015",
  RED80 = "#A21810",
  RED90 = "#80130C",
  RED100 = "#600D08",
  PINK10 = "#FCF5F7",
  PINK20 = "#FCE8EF",
  PINK30 = "#FCC7D9",
  PINK40 = "#FA8EB2",
  PINK50 = "#F74F87",
  PINK60 = "#DB1A5B",
  PINK70 = "#B30C44",
  PINK80 = "#8C0E38",
  PINK90 = "#661430",
  PINK100 = "#451726",
  PURPLE10 = "#FAF7FC",
  PURPLE20 = "#F2EBFA",
  PURPLE30 = "#E2CDF7",
  PURPLE40 = "#CDA3F7",
  PURPLE50 = "#B277ED",
  PURPLE60 = "#9557D4",
  PURPLE70 = "#7E40BD",
  PURPLE80 = "#642B9E",
  PURPLE90 = "#4D2378",
  PURPLE100 = "#371C52",
  INDIGO10 = "#F7F7FF",
  INDIGO20 = "#EDEDFF",
  INDIGO30 = "#CFCFFF",
  INDIGO40 = "#A8A8FF",
  INDIGO50 = "#8484FA",
  INDIGO60 = "#5959E0",
  INDIGO70 = "#4646DA",
  INDIGO80 = "#3434AC",
  INDIGO90 = "#29298C",
  INDIGO100 = "#1E1E57",
  NAVY10 = "#F5F8FC",
  NAVY20 = "#E6F0FF",
  NAVY30 = "#C0D7FA",
  NAVY40 = "#88B6F9",
  NAVY50 = "#6099F1",
  NAVY60 = "#2072F8",
  NAVY70 = "#0A60F0",
  NAVY80 = "#0047BB",
  NAVY90 = "#08379B",
  NAVY100 = "#083176",
  BLUE10 = "#F5F9FC",
  BLUE20 = "#E0F3FF",
  BLUE30 = "#AEDEFF",
  BLUE40 = "#70C3FF",
  BLUE50 = "#38ABFF",
  BLUE60 = "#0080DC",
  BLUE70 = "#0262B9",
  BLUE80 = "#00529A",
  BLUE90 = "#004078",
  BLUE100 = "#002D54",
  CYAN10 = "#EAF7FB",
  CYAN20 = "#DDF5FD",
  CYAN30 = "#A3E5FA",
  CYAN40 = "#38C6F4",
  CYAN50 = "#0C9ED9",
  CYAN60 = "#0986B6",
  CYAN70 = "#0073A5",
  CYAN80 = "#00557E",
  CYAN90 = "#004368",
  CYAN100 = "#002E4D",
  TEAL10 = "#F2FCFD",
  TEAL20 = "#DCF7FA",
  TEAL30 = "#9DE9F0",
  TEAL40 = "#4ED8E4",
  TEAL50 = "#0DC7D8",
  TEAL60 = "#038B98",
  TEAL70 = "#027786",
  TEAL80 = "#08575E",
  TEAL90 = "#07454B",
  TEAL100 = "#093134",
  GREEN10 = "#F1FEF8",
  GREEN20 = "#DBFAEE",
  GREEN30 = "#95E9C9",
  GREEN40 = "#76EAB8",
  GREEN50 = "#26D98F",
  GREEN60 = "#009054",
  GREEN70 = "#007E44",
  GREEN80 = "#056234",
  GREEN90 = "#044D28",
  GREEN100 = "#03381E",
  YELLOW10 = "#FFF8ED",
  YELLOW20 = "#FAECD9",
  YELLOW30 = "#FFCC89",
  YELLOW40 = "#F1A13F",
  YELLOW50 = "#D88931",
  YELLOW60 = "#B96B00",
  YELLOW70 = "#A15C00",
  YELLOW80 = "#864D00",
  YELLOW90 = "#663B00",
  YELLOW100 = "#4E2E00",
  ORANGE10 = "#FDE7D8",
  ORANGE20 = "#FDE7D8",
  ORANGE30 = "#FACAA8",
  ORANGE40 = "#F79950",
  ORANGE50 = "#E77528",
  ORANGE60 = "#C96010",
  ORANGE70 = "#B35009",
  ORANGE80 = "#974308",
  ORANGE90 = "#723204",
  ORANGE100 = "#5B2600",
  BLACK = "#000000",
  WHITE = "#FFFFFF",
}

export interface ColorSet {
  colors: {
    // Naming here will be the color in light mode, dark mode might differ
    black: Color;
    blue10: Color;
    blue20: Color;
    blue60: Color;
    cyan20: Color;
    cyan30: Color;
    cyan40: Color;
    cyan50: Color;
    cyan100: Color;
    green20: Color;
    green50: Color;
    green60: Color;
    green70: Color;
    grey20: Color;
    grey40: Color;
    grey50: Color;
    grey60: Color;
    grey80: Color;
    grey100: Color;
    navy20: Color;
    navy30: Color;
    navy50: Color;
    navy60: Color;
    navy70: Color;
    navy80: Color;
    navy100: Color;
    orange50: Color;
    pink20: Color;
    pink50: Color;
    pink70: Color;
    purple20: Color;
    purple70: Color;
    red20: Color;
    red30: Color;
    red40: Color;
    red50: Color;
    red60: Color;
    red70: Color;
    red80: Color;
    white: Color;
    yellow20: Color;
    yellow40: Color;
    yellow50: Color;
  };
  backgrounds: {
    base: Color;
    baseLight: Color;
    muted: Color;
    mutedLight: Color;
    mutedDark: Color;
    highlight: Color;
    highlightLight: Color;
    highlightDark: Color;
    info: Color;
    infoLight: Color;
    infoDark: Color;
    success: Color;
    successLight: Color;
    successDark: Color;
    warning: Color;
    warningLight: Color;
    warningDark: Color;
    error: Color;
    errorLight: Color;
    errorDark: Color;
  };
  borders: {
    base: Color;
    black: Color;
    muted: Color;
    mutedLight: Color;
    mutedDark: Color;
    highlight: Color;
    highlightLight: Color;
    highlightDark: Color;
    info: Color;
    infoLight: Color;
    infoDark: Color;
    success: Color;
    successLight: Color;
    successDark: Color;
    warning: Color;
    warningLight: Color;
    warningDark: Color;
    error: Color;
    errorLight: Color;
    errorDark: Color;
  };
  text: {
    primary: Color;
    primaryAlt: Color;
    secondary: Color;
    secondaryAlt: Color;
    muted: Color;
    mutedAlt: Color;
    highlight: Color;
    highlightAlt: Color;
    success: Color;
    successAlt: Color;
    warning: Color;
    warningAlt: Color;
    error: Color;
    errorAlt: Color;
  };
  interactive: {
    buttonPrimary: Color;
    buttonPrimaryHover: Color;
    buttonPrimaryDisabled: Color;
    buttonSecondary: Color;
    buttonSecondaryHover: Color;
    buttonSecondaryDisabled: Color;
    link: Color;
    linkHover: Color;
    linkDisabled: Color;
  };
  icon: {
    primary: Color;
    secondary: Color;
    muted: Color;
    highlight: Color;
    white: Color;
  };
}

export const lightColorset: ColorSet = {
  colors: {
    black: Color.BLACK,
    blue10: Color.BLUE10,
    blue20: Color.BLUE20,
    blue60: Color.BLUE60,
    cyan20: Color.CYAN20,
    cyan30: Color.CYAN30,
    cyan40: Color.CYAN40,
    cyan50: Color.CYAN50,
    cyan100: Color.CYAN100,
    green20: Color.GREEN20,
    green50: Color.GREEN50,
    green60: Color.GREEN60,
    green70: Color.GREEN70,
    grey20: Color.GREY20,
    grey40: Color.GREY40,
    grey50: Color.GREY50,
    grey60: Color.GREY60,
    grey80: Color.GREY80,
    grey100: Color.GREY100,
    navy20: Color.NAVY20,
    navy30: Color.NAVY30,
    navy50: Color.NAVY50,
    navy60: Color.NAVY60,
    navy70: Color.NAVY70,
    navy80: Color.NAVY80,
    navy100: Color.NAVY100,
    orange50: Color.ORANGE50,
    pink20: Color.PINK20,
    pink50: Color.PINK50,
    pink70: Color.PINK70,
    purple20: Color.PURPLE20,
    purple70: Color.PURPLE70,
    red20: Color.RED20,
    red30: Color.RED30,
    red40: Color.RED40,
    red50: Color.RED50,
    red60: Color.RED60,
    red70: Color.RED70,
    red80: Color.RED80,
    white: Color.WHITE,
    yellow20: Color.YELLOW20,
    yellow40: Color.YELLOW40,
    yellow50: Color.YELLOW50,
  },
  backgrounds: {
    base: Color.BLUE10,
    baseLight: Color.WHITE,
    muted: Color.GREY50,
    mutedLight: Color.GREY20,
    mutedDark: Color.GREY100,
    highlight: Color.BLUE60,
    highlightLight: Color.BLUE20,
    highlightDark: Color.BLUE80,
    info: Color.NAVY50,
    infoLight: Color.NAVY20,
    infoDark: Color.NAVY80,
    success: Color.GREEN50,
    successLight: Color.GREEN20,
    successDark: Color.GREEN70,
    warning: Color.YELLOW40,
    warningLight: Color.YELLOW20,
    warningDark: Color.YELLOW60,
    error: Color.RED50,
    errorLight: Color.RED20,
    errorDark: Color.RED70,
  },
  borders: {
    base: Color.BLUE10,
    black: Color.BLACK,
    muted: Color.GREY50,
    mutedLight: Color.GREY20,
    mutedDark: Color.GREY100,
    highlight: Color.BLUE60,
    highlightLight: Color.BLUE30,
    highlightDark: Color.BLUE80,
    info: Color.NAVY50,
    infoLight: Color.NAVY30,
    infoDark: Color.NAVY70,
    success: Color.GREEN50,
    successLight: Color.GREEN30,
    successDark: Color.GREEN70,
    warning: Color.YELLOW40,
    warningLight: Color.YELLOW30,
    warningDark: Color.YELLOW60,
    error: Color.RED50,
    errorLight: Color.RED30,
    errorDark: Color.RED70,
  },
  text: {
    primary: Color.GREY100,
    primaryAlt: Color.WHITE,
    secondary: Color.GREY60,
    secondaryAlt: Color.GREY20,
    muted: Color.GREY40,
    mutedAlt: Color.GREY30,
    highlight: Color.BLUE60,
    highlightAlt: Color.BLUE20,
    success: Color.GREEN50,
    successAlt: Color.GREEN20,
    warning: Color.YELLOW40,
    warningAlt: Color.YELLOW20,
    error: Color.RED60,
    errorAlt: Color.RED20,
  },
  interactive: {
    buttonPrimary: Color.BLUE60,
    buttonPrimaryHover: Color.BLUE80,
    buttonPrimaryDisabled: Color.BLUE30,
    buttonSecondary: Color.GREY100,
    buttonSecondaryHover: Color.BLACK,
    buttonSecondaryDisabled: Color.GREY30,
    link: Color.BLUE60,
    linkHover: Color.BLUE70,
    linkDisabled: Color.BLUE30,
  },
  icon: {
    primary: Color.GREY100,
    secondary: Color.GREY50,
    muted: Color.GREY30,
    highlight: Color.BLUE60,
    white: Color.WHITE,
  },
};

export const darkColorset: ColorSet = {
  colors: {
    black: Color.BLACK,
    blue10: Color.BLUE10,
    blue20: Color.GREY90,
    blue60: Color.BLUE60,
    cyan20: Color.CYAN20,
    cyan30: Color.CYAN30,
    cyan40: Color.CYAN40,
    cyan50: Color.CYAN50,
    cyan100: Color.CYAN100,
    green20: Color.GREEN20,
    green50: Color.GREEN50,
    green60: Color.GREEN60,
    green70: Color.GREEN70,
    grey20: Color.GREY20,
    grey40: Color.GREY40,
    grey50: Color.GREY50,
    grey60: Color.GREY60,
    grey80: Color.GREY80,
    grey100: Color.GREY100,
    navy20: Color.NAVY20,
    navy30: Color.NAVY30,
    navy50: Color.NAVY50,
    navy60: Color.NAVY60,
    navy70: Color.NAVY70,
    navy80: Color.NAVY80,
    navy100: Color.NAVY100,
    orange50: Color.ORANGE50,
    pink20: Color.PINK20,
    pink50: Color.PINK50,
    pink70: Color.PINK70,
    purple20: Color.PURPLE20,
    purple70: Color.PURPLE70,
    red20: Color.RED20,
    red30: Color.RED30,
    red40: Color.RED40,
    red50: Color.RED40,
    red60: Color.RED40,
    red70: Color.RED70,
    red80: Color.RED80,
    white: Color.WHITE,
    yellow20: Color.YELLOW20,
    yellow40: Color.YELLOW40,
    yellow50: Color.YELLOW50,
  },
  backgrounds: {
    base: Color.GREY100,
    baseLight: Color.GREY90,
    muted: Color.GREY50,
    mutedLight: Color.GREY20,
    mutedDark: Color.GREY100,
    highlight: Color.CYAN40,
    highlightLight: Color.CYAN20,
    highlightDark: Color.CYAN70,
    info: Color.NAVY50,
    infoLight: Color.NAVY20,
    infoDark: Color.NAVY70,
    success: Color.GREEN40,
    successLight: Color.GREEN20,
    successDark: Color.GREEN60,
    warning: Color.YELLOW30,
    warningLight: Color.YELLOW20,
    warningDark: Color.YELLOW50,
    error: Color.RED40,
    errorLight: Color.RED20,
    errorDark: Color.RED60,
  },
  borders: {
    base: Color.BLUE10,
    black: Color.BLACK,
    muted: Color.GREY80,
    mutedLight: Color.GREY70,
    mutedDark: Color.GREY100,
    highlight: Color.BLUE40,
    highlightLight: Color.BLUE20,
    highlightDark: Color.BLUE70,
    info: Color.NAVY50,
    infoLight: Color.NAVY20,
    infoDark: Color.NAVY70,
    success: Color.GREEN40,
    successLight: Color.GREEN20,
    successDark: Color.GREEN60,
    warning: Color.YELLOW30,
    warningLight: Color.YELLOW20,
    warningDark: Color.YELLOW50,
    error: Color.RED40,
    errorLight: Color.RED20,
    errorDark: Color.RED60,
  },
  text: {
    primary: Color.WHITE,
    primaryAlt: Color.GREY100,
    secondary: Color.GREY30,
    secondaryAlt: Color.GREY80,
    muted: Color.GREY70,
    mutedAlt: Color.GREY80,
    highlight: Color.CYAN40,
    highlightAlt: Color.CYAN30,
    success: Color.GREEN40,
    successAlt: Color.GREEN20,
    warning: Color.YELLOW30,
    warningAlt: Color.YELLOW20,
    error: Color.RED40,
    errorAlt: Color.RED20,
  },
  interactive: {
    buttonPrimary: Color.CYAN40,
    buttonPrimaryHover: Color.CYAN60,
    buttonPrimaryDisabled: Color.CYAN80,
    buttonSecondary: Color.WHITE,
    buttonSecondaryHover: Color.GREY30,
    buttonSecondaryDisabled: Color.GREY70,
    link: Color.CYAN40,
    linkHover: Color.CYAN60,
    linkDisabled: Color.CYAN80,
  },
  icon: {
    primary: Color.WHITE,
    secondary: Color.GREY40,
    muted: Color.GREY70,
    highlight: Color.CYAN40,
    white: Color.WHITE,
  },
};

export interface Spacings {
  pageContainer: string;
  card: Record<"sm" | "m" | "lg", string>;
}

const spacings: Spacings = {
  pageContainer: "8rem",
  card: { sm: "8px", m: "16px", lg: "32px" },
};

interface IThemeContext {
  theme: Theme;
  colorset: ColorSet;
  spacings: Spacings;
  toggleTheme: () => void;
}

const LOCAL_THEME_KEY = "theme";
const LOCAL_THEME = window.localStorage.getItem(LOCAL_THEME_KEY);

const initialState: IThemeContext = {
  theme: LOCAL_THEME === Theme.DARK_MODE ? Theme.DARK_MODE : Theme.LIGHT_MODE,
  colorset: lightColorset,
  spacings,
  toggleTheme: () => {},
};

export const ThemeContext: React.Context<IThemeContext> = createContext(
  initialState
);

export const useThemeContext = () => {
  const { toggleTheme, theme } = useContext(ThemeContext);
  const colorset = useTheme();
  return { toggleTheme, theme, colorset };
};

export const ThemeContextProvider: FC = ({ children }) => {
  const themeContext = useContext(ThemeContext);
  const [theme, setTheme] = useState(themeContext.theme);
  const [colorset, setColorset] = useState<ColorSet>(themeContext.colorset);

  themeContext.colorset =
    themeContext.theme === Theme.LIGHT_MODE ? lightColorset : darkColorset;

  const toggleTheme = () => {
    const newTheme =
      theme === Theme.LIGHT_MODE ? Theme.DARK_MODE : Theme.LIGHT_MODE;
    window.localStorage.setItem(LOCAL_THEME_KEY, newTheme);
    setTheme(newTheme);
  };

  useLayoutEffect(() => {
    themeContext.theme = theme;
    themeContext.colorset =
      theme === Theme.LIGHT_MODE ? lightColorset : darkColorset;
    setColorset(theme === Theme.LIGHT_MODE ? lightColorset : darkColorset);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, colorset, spacings }}>
      <ThemeProvider theme={{ ...colorset, spacings }}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
