import styled from "@emotion/styled";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FlexBoxBase } from "../../../../../components/atoms/FlexBox/FlexBox";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { APIError } from "../../../../../models/error";
import {
  updateUserInfo,
  updateUserVyvxData,
} from "../../../../../models/user-management/users";
import {
  Timezone,
  UserManagementUserData,
  UserVyvxPreferences,
} from "../../../../../models/user-management/users/users.interfaces";
import { handleAPIError } from "../../../../../store/slices/api-error/thunks";
import { fetchUser } from "../../../../../store/slices/user-management-users/thunk";
import { useAppDispatch } from "../../../../../store/types";
import { UserType } from "../types";
import { EditUserPreferencesModal } from "./modals/EditUserPreferencesModal";

interface UserPreferencesProps {
  type?: UserType;
  userInfo: UserManagementUserData;
}

export const UserPreferences = ({
  type,
  userInfo,
}: UserPreferencesProps): ReactElement => {
  const [openEditPreferencesModal, setOpenEditPreferencesModal] = useState(
    false
  );
  const { t } = useTranslation("usersPage");
  const dispatch = useAppDispatch();

  const savePreferencesInfo = (
    email: string,
    timezone?: Timezone,
    confirmationLetterEmail?: boolean,
    inNetwork?: boolean
  ) => {
    let preferences: UserVyvxPreferences[] = [];
    if (userInfo.vyvx?.preferences) {
      preferences = userInfo.vyvx.preferences.filter(
        (item) =>
          item !== UserVyvxPreferences.EMAIL_NOTIFICATIONS &&
          item !== UserVyvxPreferences.DEFAULT_IN_NETWORK_CHECKED
      );
    }
    if (
      confirmationLetterEmail &&
      !preferences.includes(UserVyvxPreferences.EMAIL_NOTIFICATIONS)
    ) {
      preferences.push(UserVyvxPreferences.EMAIL_NOTIFICATIONS);
    }
    if (
      inNetwork &&
      !preferences.includes(UserVyvxPreferences.DEFAULT_IN_NETWORK_CHECKED)
    ) {
      preferences.push(UserVyvxPreferences.DEFAULT_IN_NETWORK_CHECKED);
    }

    const promises = [
      updateUserInfo(userInfo.userId, {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        userName: userInfo.userName,
        email,
        status: userInfo.status,
      }),
    ];
    if (type === UserType.VYVX) {
      promises.push(
        updateUserVyvxData(userInfo.userId, {
          fiberCompanyAbbr: userInfo.vyvx?.fiberCompanyAbbr,
          fiberAssociatedCompanies: userInfo.vyvx?.fiberAssociatedCompanies?.map(
            (company) => company.companyAbbr
          ),
          satelliteCompanyAbbr: userInfo.vyvx?.satelliteCompanyAbbr,
          satelliteAssociatedCompanies: userInfo.vyvx?.satelliteAssociatedCompanies?.map(
            (company) => company.companyAbbr
          ),
          role: userInfo.vyvx?.role,
          timezone,
          preferences,
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        dispatch(fetchUser(userInfo.userId));
        toast.success(t("EDIT_USER_INFO_SUCCESS_TOAST_LABEL"));
      })
      .catch((err) => {
        dispatch(handleAPIError(err as APIError));
      });
  };

  return (
    <>
      <Container>
        <PreferencesContainer>
          <TitleContainer>
            <Title>{t("USER_PAGE_PREFERENCES_TITLE")}</Title>
            <EditIcon
              name={Icons.EDIT}
              onClick={() => setOpenEditPreferencesModal(true)}
            />
          </TitleContainer>
          <DataContainer>
            <Column>
              <Label>{t("USER_PAGE_PREFERENCES_EMAIL_LABEL")}</Label>
              <Value>{userInfo.email}</Value>
            </Column>
            {type === UserType.VYVX && (
              <>
                <Column>
                  <Label>{t("USER_PAGE_PREFERENCES_TIMEZONE_LABEL")}</Label>
                  <Value>{userInfo.vyvx?.timezone}</Value>
                </Column>
                <Column>
                  <Label>
                    {t("USER_PAGE_PREFERENCES_CONFIRMATION_LETTER_EMAIL_LABEL")}
                  </Label>
                  <Value>
                    {t(
                      userInfo.vyvx?.preferences?.includes(
                        UserVyvxPreferences.EMAIL_NOTIFICATIONS
                      )
                        ? "USER_PAGE_PREFERENCES_BOOLEAN_TRUE"
                        : "USER_PAGE_PREFERENCES_BOOLEAN_FALSE"
                    )}
                  </Value>
                </Column>
                <Column>
                  <Label>
                    {t(
                      "USER_PAGE_PREFERENCES_DEFAULT_IN_NETWORK_CHECKED_LABEL"
                    )}
                  </Label>
                  <Value>
                    {t(
                      userInfo.vyvx?.preferences?.includes(
                        UserVyvxPreferences.DEFAULT_IN_NETWORK_CHECKED
                      )
                        ? "USER_PAGE_PREFERENCES_BOOLEAN_TRUE"
                        : "USER_PAGE_PREFERENCES_BOOLEAN_FALSE"
                    )}
                  </Value>
                </Column>
              </>
            )}
          </DataContainer>
        </PreferencesContainer>
      </Container>
      <Modal
        customButton={<></>}
        open={openEditPreferencesModal}
        onClose={() => setOpenEditPreferencesModal(false)}
        overflow="visible"
      >
        <EditUserPreferencesModal
          userPreferences={{
            email: userInfo.email,
            timezone: userInfo.vyvx?.timezone,
            confirmationLetterEmail: userInfo.vyvx?.preferences?.includes(
              UserVyvxPreferences.EMAIL_NOTIFICATIONS
            ),
            inNetwork: userInfo.vyvx?.preferences?.includes(
              UserVyvxPreferences.DEFAULT_IN_NETWORK_CHECKED
            ),
          }}
          save={savePreferencesInfo}
          isVyvx={type === UserType.VYVX}
        />
      </Modal>
    </>
  );
};

const Container = styled.div``;

const PreferencesContainer = styled.div``;

const TitleContainer = styled(FlexBoxBase)`
  align-items: center;
  padding-bottom: 32px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-right: 16px;
`;

const EditIcon = styled(Icon)`
  cursor: pointer;
`;

const DataContainer = styled(FlexBoxBase)``;

const Column = styled.div`
  padding-right: 32px;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 4px;
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
