import React from "react";
import styled from "@emotion/styled";

export interface ITextDelimiterProps {
  text?: string;
}

export const TextDelimiter: React.FC<ITextDelimiterProps> = ({ text }) => {
  return (
    <DelimiterContainer>
      <Line />
      {text && (
        <>
          <DelimiterText>{text}</DelimiterText>
          <Line />
        </>
      )}
    </DelimiterContainer>
  );
};

const DelimiterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em 0;
`;

const Line = styled.hr`
  width: 100%;
  border-color: ${({ theme }) => theme.borders.info};
`;

const DelimiterText = styled.div`
  margin: 0 2em;
`;
