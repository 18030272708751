import { FC, ReactElement, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../atoms/Button/Button";
import { Modal } from "../../atoms/Modal/Modal";

export interface IFilePreviewProps {
  content: string;
}

export const FilePreviewModal = ({
  content,
}: IFilePreviewProps): ReactElement => {
  const { t } = useTranslation("misc");

  return (
    <Modal
      openButtonProps={{
        label: t("FILE_PREVIEW_MODAL_TITLE"),
      }}
      size="auto"
    >
      <FilePreviewContent content={content} />
    </Modal>
  );
};

interface FilePreviewContentProps {
  content: string;
  closeModal?: () => void;
}

const FilePreviewContent = ({
  content,
  closeModal,
}: FilePreviewContentProps): ReactElement => {
  const { t } = useTranslation("misc");

  return (
    <FilePreviewContainer>
      <h4>{t("FILE_PREVIEW_TITLE")}</h4>
      <ContentDisplay>{content}</ContentDisplay>
      <BottomButtonsContainer>
        <Button
          label={t("FILE_PREVIEW_BACK_BUTTON")}
          onClick={closeModal || (() => {})}
        />
      </BottomButtonsContainer>
    </FilePreviewContainer>
  );
};

const ContentDisplay: FC = ({ children }) => {
  const { t } = useTranslation("misc");

  const [displayAll, setDisplayAll] = useState(false);

  const pRef = useRef<HTMLParagraphElement>(null);

  return (
    <ContentContainer>
      <StyledContentDisplay displayAll={displayAll} ref={pRef}>
        {children}
      </StyledContentDisplay>
      <Button
        label={t(displayAll ? "SEE_LESS" : "SEE_MORE")}
        onClick={() => {
          if (displayAll) {
            if (pRef.current) pRef.current.scrollTop = 0;
            setDisplayAll(false);
          } else {
            setDisplayAll(true);
          }
        }}
      />
    </ContentContainer>
  );
};

const FilePreviewContainer = styled.div`
  padding: 2em;
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
`;

const ContentContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 5px;
  padding: 1em;
`;

const StyledContentDisplay = styled.p<{ displayAll: boolean }>`
  max-height: 50vh;
  overflow-wrap: break-word;
  white-space: break-spaces;
  ${({ displayAll }) =>
    displayAll
      ? css`
          overflow-y: scroll;
        `
      : css`
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `}
`;
