import { useEffect, useState } from "react";

// Some inputs give a required error although they have initial values. To
// prevent this, we mount it for once.
export const useInitOpen = (initIsOpen: boolean): boolean => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(initIsOpen);
  }, [initIsOpen]);

  return isOpen;
};
