import { useState, useEffect } from "react";
import styled from "@emotion/styled";

import { Loader } from "../../../../components/atoms/Loader/Loader";
import { EmptyList } from "./EmptyList";
import { CertificatesList } from "../../../../components/organisms/CertificateList/CertificateList";
import { useCertificates } from "../../../../store/slices/caching/hooks";
import { ModuleContent } from "../../../../components/atoms/Module/Module";

const SCROLL_ID = "certificates_list";

enum RequestStatus {
  LOADING = "LOADING",
  DONE = "DONE",
  EMPTY = "EMPTY",
  FAILED = "FAILED",
}

export interface ICertificateListProps {}

export const CertificatesPage: React.FC<ICertificateListProps> = () => {
  const certificates = useCertificates();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.LOADING
  );

  useEffect(() => {
    if (certificates) {
      if (certificates.length === 0) {
        setRequestStatus(RequestStatus.EMPTY);
      } else {
        setRequestStatus(RequestStatus.DONE);
      }
    } else {
      setRequestStatus(RequestStatus.LOADING);
    }
    // TODO: handle infinite loading case
  }, [certificates]);

  switch (requestStatus) {
    case RequestStatus.LOADING:
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      );
    case RequestStatus.DONE:
      return (
        <ModuleContent id={SCROLL_ID}>
          <CertificatesList certificates={certificates} />
        </ModuleContent>
      );
    case RequestStatus.EMPTY:
      return (
        <ModuleContent id={SCROLL_ID}>
          <EmptyList />
        </ModuleContent>
      );
    default:
      return <></>;
  }
};

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
