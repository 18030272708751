import styled from "@emotion/styled";
import { FC } from "react";

import { Asterisk } from "../../atoms/Asterisk/Asterisk";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";

export const FormSection: FC<{ label: string }> = ({ label, children }) => {
  return (
    <TextInputContainer>
      <LabelContainer>
        <Label>{label}</Label>
        <Asterisk />
      </LabelContainer>
      {children}
    </TextInputContainer>
  );
};

const TextInputContainer = styled.div``;

const LabelContainer = styled(FlexBox)`
  justify-content: flex-start;
  margin-bottom: 12px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
