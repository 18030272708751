import { useTranslation } from "react-i18next";

import { Modal } from "../../../../../../components/atoms/Modal/Modal";
import { Icon, Icons } from "../../../../../../components/atoms/Icon/Icon";
import { NewBannersModalChildProps } from "../../../models/models";
import { BannersModalContent } from "./BannersModalContent";

export const BannersModal: React.FC<NewBannersModalChildProps> = ({
  handleSaveBanners,
  isUpdate,
  bannerHeader,
  dataTestId,
}) => {
  const { t } = useTranslation("bannersModalPage");

  return (
    <Modal
      openButtonProps={{
        label: t("CREATE_BANNERS_BUTTON"),
        dataTestId: `${dataTestId}-create-button`,
      }}
      customButton={
        isUpdate ? (
          <Icon name={Icons.EDIT} color="highlight" dataTestId={dataTestId} />
        ) : undefined
      }
      size="large"
    >
      <BannersModalContent
        handleSaveBanners={handleSaveBanners}
        isUpdate={isUpdate}
        bannerHeader={bannerHeader}
        dataTestId={dataTestId}
      />
    </Modal>
  );
};
