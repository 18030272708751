import { Fragment, ReactElement } from "react";
import { to } from "react-spring";
import styled from "@emotion/styled";

import { DragAndDropItem } from "../../atoms/DragAndDropItem/DragAndDropItem";
import { IDNDItem, IItem, useDragAndDrop } from "./useDragAndDrop";

interface DefaultOpen {
  indexOpen: number;
  defaultOpenSub?: DefaultOpen;
}

export interface DragAndDropProps<T = any> {
  // Items should not be created using useState
  // Go to DragAndDrop.stories.tsx for an example
  items: IItem<T>[];
  isChild?: boolean;
  itemsMargin?: number;
  onEditItem?: (currentIndex: number, titleIndex?: number) => void;
  onAddItem?: (context: IItem<T>["context"]) => void;
  onDeleteItem?: (
    item: IDNDItem | undefined,
    callback?: (index: number) => void
  ) => void;
  onOrderChange: (list: IItem<T>[]) => void;
  defaultOpen?: "all" | DefaultOpen;
  dataTestId?: string;
}

/**
 * Drag and drop list is is component to create a list of drag and drop
 * Each `item` has a title and content that is going to be displayed as a DropdownItem
 */
export function DragAndDropList<T>({
  items,
  isChild = false,
  itemsMargin = 16,
  onEditItem,
  onDeleteItem,
  onOrderChange,
  onAddItem,
  defaultOpen,
  dataTestId,
}: DragAndDropProps<T>): ReactElement {
  const [
    list,
    springs,
    containerDimensions,
    handleContainerDimensions,
    bind,
    refCallback,
    handleDelete,
    resize,
  ] = useDragAndDrop(items, itemsMargin, onOrderChange);

  return (
    <DNDContainer
      className={`dnd-container-${items.length}`}
      dimensions={containerDimensions}
    >
      {springs.map(({ zIndex, shadow, y, scale }, i) => {
        return (
          <Fragment key={i}>
            {list.findIndex((item) => item?.originalIndex === i) !== -1 && (
              <DragAndDropItem
                dataTestId={dataTestId && `${dataTestId}-${i}`}
                index={list
                  .filter((item) => item !== undefined)
                  .findIndex((item) => item!.originalIndex === i)}
                item={list.find((item) => item?.originalIndex === i)!}
                onAddItem={onAddItem}
                openByDefault={
                  defaultOpen === "all" ? true : defaultOpen?.indexOpen === i
                }
                onEditItem={
                  onEditItem
                    ? (titleIndex) => {
                        const currentIndex = list.findIndex(
                          (item) => item?.originalIndex === i
                        );

                        onEditItem(currentIndex, titleIndex);
                      }
                    : undefined
                }
                onDeleteItem={
                  onDeleteItem
                    ? () => {
                        onDeleteItem(
                          list.find((item) => item?.originalIndex === i),
                          handleDelete
                        );
                      }
                    : undefined
                }
                onResize={resize}
                expandable={!isChild}
                gestureEvents={bind(i)}
                ref={(ref) => refCallback(ref, i)}
                toggleOpen={() => setTimeout(() => handleContainerDimensions())}
                springStyle={{
                  boxShadow: shadow.to(
                    (s: number) =>
                      `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s + 2}px 0px`
                  ),
                  transform: to(
                    [y, scale],
                    (height, s) => `translate3d(0,${height}px,0) scale(${s})`
                  ),
                  zIndex,
                }}
                handleDelete={handleDelete}
              />
            )}
          </Fragment>
        );
      })}
    </DNDContainer>
  );
}

const DNDContainer = styled.div<{
  dimensions: { height: number; width: number };
}>`
  border: 1px solid transparent;
  border-radius: 4px;
  height: ${({ dimensions }) => `${dimensions.height}px`};
  width: ${({ dimensions }) => `${dimensions.width}px`};
`;
