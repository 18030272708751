import { IConfigDetailsData } from "../../../models/configuration/configuration_details";

export const ORDERED_DEF_NAMES: (keyof Omit<
  IConfigDetailsData,
  "genSchemaVersion" | "subscriberId"
>)[] = [
  "headerDefinitions",
  "generatedResponseDefinitions",
  "luaDefinitions",
  "matchLogicDefinitions",
  "originFillPolicyDefinitions",
  "propertyDefinitions",
  "reportingOverrideDefinitions",
  "simpleListDefinitions",
  "tokenAuthenticationDefinitions",
];

const LUA_EXCEPTIONS = ["variableValues", "nameList"];
const MATCH_LOGIC_EXCEPTIONS = ["features"];
export const EXCEPTIONS_WHEN_EMPTY = [
  ...LUA_EXCEPTIONS,
  ...MATCH_LOGIC_EXCEPTIONS,
];
