import styled from "@emotion/styled";
import { ComponentProps } from "react";

import { ColorSet } from "../../../contexts/themeContext";
import { Icon, Icons } from "../../atoms/Icon/Icon";

type ButtonSize = "sm" | "md" | "lg" | "xl";

const StyledButton = styled.button<{
  backgroundColor:
    | keyof ColorSet["interactive"]
    | keyof ColorSet["backgrounds"]
    | keyof ColorSet["colors"];
  borderColor: keyof ColorSet["borders"] | keyof ColorSet["colors"];
  textColor: keyof ColorSet["text"] | keyof ColorSet["colors"];
  outline?: boolean;
}>`
  color: ${({ theme, textColor }) =>
    ({ ...theme.text, ...theme.colors }[textColor])};
  align-items: center;
  border: 1px solid
    ${({ borderColor, theme }) =>
      ({ ...theme.borders, ...theme.colors }[borderColor])};
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 8px 16px;
  text-align: center;
  transition: background-color 0.12s ease-in, border-color 0.1s ease-in,
    color 0.12s ease-in;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  transition: 0.3s;

  &:disabled {
    background-color: ${({ theme }) => theme.backgrounds.mutedLight};
    color: ${({ theme }) => theme.text.secondary};
    border: 1px solid ${({ theme }) => theme.borders.mutedLight};
    pointer-events: none;
  }

  background-color: ${({ theme, backgroundColor }) =>
    ({ ...theme.interactive, ...theme.backgrounds, ...theme.colors }[
      backgroundColor
    ])};

  &:focus {
    outline: 0.125rem solid ${({ theme }) => theme.colors.cyan50};
    outline-offset: 0.0625rem;
    z-index: 1;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

export interface ButtonProps {
  id?: string;
  label: string;
  borderColor?: keyof ColorSet["borders"] | keyof ColorSet["colors"];
  textColor?: keyof ColorSet["text"] | keyof ColorSet["colors"];
  backgroundColor?:
    | keyof ColorSet["interactive"]
    | keyof ColorSet["backgrounds"]
    | keyof ColorSet["colors"];
  size?: ButtonSize;
  dataTestId?: string;
  type?: "button" | "submit" | "reset";
  className?: string;
  icon?: { name: Icons; props?: Partial<ComponentProps<typeof StyledIcon>> };
  disabled?: boolean;
  flat?: boolean;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  dataTestId,
  disabled,
  icon,
  id,
  textColor = "primaryAlt",
  borderColor = "mutedLight",
  backgroundColor = "buttonPrimary",
  type = "button",
  className,
  // These props need to be implemented on the buttons
  size,
  flat,
  onClick,
}: ButtonProps) => (
  <StyledButton
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    textColor={textColor}
    id={id}
    className={`button ${className}`}
    data-testid={dataTestId}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {icon && (
      <StyledIcon
        name={icon.name}
        color={disabled ? "secondary" : "primary"}
        {...icon.props}
      />
    )}
    {label}
  </StyledButton>
);
