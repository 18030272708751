import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ChartMapTable } from "./ChartMapTable";
import { TotalMetrics } from "./TotalMetrics";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { Report } from "../../../../../components/organisms/Report/Report";

export const Locations: FC = () => {
  const { t } = useTranslation("rtmReportPage");
  return (
    <Report id="rtm.locations">
      <ExpandableContent
        title={t("LOCATIONS_REPORT_EXPANDABLE_TITLE")}
        content={t("LOCATIONS_REPORT_EXPANDABLE_CONTENT")}
      />
      <TotalMetrics />
      <ChartMapTable />
    </Report>
  );
};
