/* eslint-disable import/named */
import styled from "@emotion/styled";
import { HTMLProps, useEffect, useRef } from "react";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

interface ValidatedFieldInputProps {
  id: string;
  disabled?: boolean;
  placeholder: string;
  value?: string | number;
  type: HTMLProps<HTMLInputElement>["type"];
  required?: boolean;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  onChange: (value: string) => void;
}

export const ValidatedFieldInput: React.FC<ValidatedFieldInputProps> = ({
  id,
  disabled,
  placeholder,
  value,
  type,
  required,
  onChange,
  setError,
  clearErrors,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (value?.toString().length === 0) {
      setError(id, {
        type: "manual",
        message: "This is a required field",
      });
    } else {
      clearErrors(id);
    }
  }, [value, id]);

  return (
    <FieldInputContainer
      ref={ref}
      id={id}
      className="chi-input"
      disabled={disabled}
      placeholder={placeholder}
      value={value?.toString()}
      type={type}
      required={required}
      onChange={(event) => {
        onChange(event.currentTarget.value);
      }}
    />
  );
};

const FieldInputContainer = styled.input``;
