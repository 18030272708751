import { HeaderDefinitionType } from "../../../store/slices/caching/types";

export interface IHeaderDefinitionHeader {
  name: string;
  value: string;
}

export interface IHeaderDefinition {
  description?: string;
  headers: IHeaderDefinitionHeader[];
}

export const getHeaderDefinitionDescription = (
  def: HeaderDefinitionType
): string =>
  def.headers
    .map((h) => `{ name: "${h.name}", value: "${h.value}" }`)
    .join(", ");
