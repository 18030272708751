import styled from "@emotion/styled";
import { animated, SpringValue } from "react-spring";

import { FlexBoxBase } from "../../../../../components/atoms/FlexBox/FlexBox";
import {
  UserName,
  UserPosition,
} from "../../../../../components/atoms/UserTag/UserTag";

interface UserOptionProps {
  firstName: string;
  lastName: string;
  userPosition: string;
  styles: {
    opacity: SpringValue<number>;
  };
  index: number;
  onClick: () => void;
}

export const UserOption = ({
  firstName,
  lastName,
  userPosition,
  styles,
  index,
  onClick,
}: UserOptionProps): JSX.Element => (
  <UserDropdownOption style={styles} index={index} onClick={onClick}>
    <UserDisplayContainer>
      {(firstName || lastName) && (
        <UserName>
          {firstName} {lastName}
        </UserName>
      )}
      <UserPosition>{userPosition}</UserPosition>
    </UserDisplayContainer>
  </UserDropdownOption>
);

export const UserDisplayContainer = styled(FlexBoxBase)`
  gap: 8px;
`;

export const UserDropdownOption = styled(animated.div)<{ index: number }>`
  display: flex !important;
  color: ${({ theme }) => theme.text.primary}!important;
  cursor: pointer;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: ${({ theme, index }) =>
    index % 2 === 0
      ? theme.backgrounds.baseLight
      : theme.backgrounds.base}!important;

  &:hover:not(.-disabled):not(.-active) {
    color: #000000 !important;
    background-color: ${({ theme }) => theme.backgrounds.mutedLight}!important;
  }
  align-items: center;
  padding: 12px;
`;
