import { useEffect, useState } from "react";

import { menu } from "./menu.json";
import { IUserHeader } from "./getHeader";

export interface IGeneratedMenu {
  title: string;
  href: string;
  items: { title: string; href: string; isNewPortal?: boolean }[];
}

export interface IUseOldNavigator {
  userHeader: IUserHeader | null;
}

export const useOldNavigator: (
  args: IUseOldNavigator
) => [generatedMenu: IGeneratedMenu[] | null] = ({ userHeader }) => {
  const [generatedMenu, setGeneratedMenu] = useState<IGeneratedMenu[]>([]);

  useEffect(() => {
    if (userHeader) {
      const menuToGenerate: IGeneratedMenu[] = [];
      setGeneratedMenu(
        menu.reduce((acc, menuToCheck) => {
          if (userHeader.menuIdList.indexOf(menuToCheck.value) !== -1) {
            const newItems: {
              title: string;
              href: string;
              isNewPortal?: boolean;
            }[] = [];
            acc.push({
              title: menuToCheck.title,
              href: menuToCheck.href,
              items: menuToCheck.items
                ? menuToCheck.items.reduce((acc2, menuItem) => {
                    if (userHeader.menuIdList.indexOf(menuItem.value) !== -1) {
                      acc2.push({
                        href: menuItem.href,
                        title: menuItem.title,
                        isNewPortal: menuItem.isNewPortal,
                      });
                    }
                    return acc2;
                  }, newItems)
                : newItems,
            });
          }
          return acc;
        }, menuToGenerate)
      );
    }
  }, [userHeader]);

  return [generatedMenu];
};
