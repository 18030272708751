import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { TooltipText } from "../../atoms/TooltipText/TooltipText";

export interface ITooltipProps {
  id: string;
  active?: boolean;
  popupText: string;
  dataTestId?: string;
}

export const Tooltip: FC<ITooltipProps> = ({
  children,
  active = true,
  popupText,
  dataTestId,
  id,
}) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [popupPosition, setPopupPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const popupContainer = document.querySelector(`#popup-container-${id}`);
    if (popupContainer && openPopup) {
      const { bottom, x } = popupContainer.getBoundingClientRect();
      setPopupPosition({ x: x - 10, y: bottom + 10 });
    }
  }, [openPopup]);

  return (
    <Container
      onMouseEnter={(e) => {
        setOpenPopup(true);
      }}
      onMouseLeave={() => setOpenPopup(false)}
      id={`popup-container-${id}`}
    >
      {children}
      {active && (
        <TooltipText
          text={popupText}
          x={popupPosition.x}
          y={popupPosition.y}
          open={openPopup}
          dataTestId={dataTestId}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;
