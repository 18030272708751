/* eslint-disable complexity */
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AddFeatureCard } from "../../../../../../../../components/molecules/AddFeatureCard/AddFeatureCard";
import { FeatureTypes } from "../../../../../../../../components/molecules/FeatureType/FeatureType";
import { IFeatures } from "../../../../../../../../models/configuration/definitions";
import { CachePolicy } from "../../../../../../../../models/configuration/definitions/matchlogic";
import { FilterFeatureTypeValue } from "../../types";
import { FEATURES_FILTER_CONFIGS } from "../featuresConfigs";
import { IAddFeaturesProps } from "./AddFeaturesModal";
import { useIsLatestVersionConfig } from "../../../../../../../../store/slices/caching/hooks";

interface IAddFeaturesListProps extends IAddFeaturesProps {
  closeModal?: () => void;
  setFocusFeature: (focusFeature: keyof IFeatures) => void;
  selectedFeatureType: FilterFeatureTypeValue;
  searchValue: string;
  dataTestId?: string;
}

export const AddFeaturesList: React.FC<IAddFeaturesListProps> = ({
  closeModal,
  dataTestId,
  handleCreateFeature,
  matchBlock,
  searchValue,
  selectedFeatureType,
  setFocusFeature,
  watch,
}) => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isLatestVersionConfig = useIsLatestVersionConfig();

  return (
    <CardsContainer data-testid={`add-feature-${dataTestId}`}>
      {matchBlock === "clientReq" &&
        watch("denyPolicy") === undefined &&
        FEATURES_FILTER_CONFIGS.denyPolicy(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_DENY_POLICY_TITLE"),
          t("FEATURE_CARD_DENY_POLICY_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_DENY_POLICY_TITLE")}
            description={t("FEATURE_CARD_DENY_POLICY_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("denyPolicy.denial.action", "redirect");
              if (closeModal) closeModal();
              setFocusFeature("denyPolicy");
            }}
            dataTestId="deny-policy"
            featureType={FeatureTypes.ACCESS_CONTROL}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("geoRestrictions") === undefined &&
        FEATURES_FILTER_CONFIGS.geoRestrictions(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_GEO_REGION_BLOCKING_TITLE"),
          t("FEATURE_CARD_GEO_REGION_BLOCKING_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_GEO_REGION_BLOCKING_TITLE")}
            description={t("FEATURE_CARD_GEO_REGION_BLOCKING_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("geoRestrictions", {
                nameList: [],
                actionType: "allow",
                matchAnonymizers: true,
                denial: { action: "redirect" },
              });
              if (closeModal) closeModal();
              setFocusFeature("geoRestrictions");
            }}
            dataTestId="geo-restrictions"
            featureType={FeatureTypes.ACCESS_CONTROL}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("ipRestrictions") === undefined &&
        FEATURES_FILTER_CONFIGS.ipRestrictions(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_IP_BLOCKING_TITLE"),
          t("FEATURE_CARD_IP_BLOCKING_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_IP_BLOCKING_TITLE")}
            description={t("FEATURE_CARD_IP_BLOCKING_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("ipRestrictions", {
                nameList: [""],
                actionType: "allow",
                denial: undefined,
              });
              if (closeModal) closeModal();
              setFocusFeature("ipRestrictions");
            }}
            dataTestId="ip-list"
            featureType={FeatureTypes.ACCESS_CONTROL}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("tokenAuthentication") === undefined &&
        FEATURES_FILTER_CONFIGS.tokenAuthentication(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_TOKEN_AUTHENTICATION_TITLE"),
          t("FEATURE_CARD_TOKEN_AUTHENTICATION_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_TOKEN_AUTHENTICATION_TITLE")}
            description={t("FEATURE_CARD_TOKEN_AUTHENTICATION_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("tokenAuthentication", {
                name: "",
                denial: { action: "error" },
              });
              if (closeModal) closeModal();
              setFocusFeature("tokenAuthentication");
            }}
            dataTestId="token"
            featureType={FeatureTypes.ACCESS_CONTROL}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("cacheControlHeaderOverride") === undefined &&
        FEATURES_FILTER_CONFIGS.cacheControlHeaderOverride(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_CCHO_TITLE"),
          t("FEATURE_CARD_CCHO_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_CCHO_TITLE")}
            description={t("FEATURE_CARD_CCHO_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("cacheControlHeaderOverride", {});
              if (closeModal) closeModal();
              setFocusFeature("cacheControlHeaderOverride");
            }}
            dataTestId="ccho"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {isLatestVersionConfig &&
        matchBlock === "clientReq" &&
        watch("cacheKey") === undefined &&
        FEATURES_FILTER_CONFIGS.cacheKey(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_CACHE_KEY_TITLE"),
          t("FEATURE_CARD_CACHE_KEY_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_CACHE_KEY_TITLE")}
            description={t("FEATURE_CARD_CACHE_KEY_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("cacheKey", "");
              if (closeModal) closeModal();
              setFocusFeature("cacheKey");
            }}
            dataTestId="cache-key"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("defaultCache") === undefined &&
        FEATURES_FILTER_CONFIGS.defaultCache(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_DEFAULT_CACHE_TITLE"),
          t("FEATURE_CARD_DEFAULT_CACHE_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_DEFAULT_CACHE_TITLE")}
            description={t("FEATURE_CARD_DEFAULT_CACHE_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("defaultCache", { cachePolicy: "" });
              if (closeModal) closeModal();
              setFocusFeature("defaultCache");
            }}
            dataTestId="default-cache"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("failedRefreshTTL") === undefined &&
        FEATURES_FILTER_CONFIGS.failedRefreshTTL(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_FAILED_REFRESH_TTL_TITLE"),
          t("FEATURE_CARD_FAILED_REFRESH_TTL_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_FAILED_REFRESH_TTL_TITLE")}
            description={t("FEATURE_CARD_FAILED_REFRESH_TTL_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("failedRefreshTTL.ttl", "");
              if (closeModal) closeModal();
              setFocusFeature("failedRefreshTTL");
            }}
            dataTestId="failed-refresh-ttl"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("negativeTTL") === undefined &&
        FEATURES_FILTER_CONFIGS.negativeTTL(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_NEGATIVE_TTL_TITLE"),
          t("FEATURE_CARD_NEGATIVE_TTL_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_NEGATIVE_TTL_TITLE")}
            description={t("FEATURE_CARD_NEGATIVE_TTL_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature(
                "negativeTTL.cachePolicy",
                CachePolicy.NO_CACHE
              );
              if (closeModal) closeModal();
              setFocusFeature("negativeTTL");
            }}
            dataTestId="negative-ttl"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("disableIfNoneMatch") === undefined &&
        FEATURES_FILTER_CONFIGS.disableIfNoneMatch(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_USE_IF_NONE_MATCH_TITLE"),
          t("FEATURE_CARD_USE_IF_NONE_MATCH_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_USE_IF_NONE_MATCH_TITLE")}
            description={t("FEATURE_CARD_USE_IF_NONE_MATCH_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("disableIfNoneMatch", true);
              if (closeModal) closeModal();
              setFocusFeature("disableIfNoneMatch");
            }}
            dataTestId="disable-if-none-match"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("qshMode") === undefined &&
        FEATURES_FILTER_CONFIGS.qshMode(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_QSH_MODE_TITLE"),
          t("FEATURE_CARD_QSH_MODE_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_QSH_MODE_TITLE")}
            description={t("FEATURE_CARD_QSH_MODE_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("qshMode", true);
              if (closeModal) closeModal();
              setFocusFeature("qshMode");
            }}
            dataTestId="qsh"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("staleContentControl") === undefined &&
        FEATURES_FILTER_CONFIGS.staleContentControl(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_SCC_TITLE"),
          t("FEATURE_CARD_SCC_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_SCC_TITLE")}
            description={t("FEATURE_CARD_SCC_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("staleContentControl", {
                staleWhileRevalidate: { enabled: false },
                staleIfError: {
                  enabled: false,
                  http4xxErrors: false,
                  http5xxErrors: false,
                },
              });
              if (closeModal) closeModal();
              setFocusFeature("staleContentControl");
            }}
            dataTestId="stale-content-control"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("generatedResponse") === undefined &&
        FEATURES_FILTER_CONFIGS.generatedResponse(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_GENERATED_RESPONSE_TITLE"),
          t("FEATURE_CARD_GENERATED_RESPONSE_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_GENERATED_RESPONSE_TITLE")}
            description={t("FEATURE_CARD_GENERATED_RESPONSE_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("generatedResponse", "");
              if (closeModal) closeModal();
              setFocusFeature("generatedResponse");
            }}
            dataTestId="generated-response"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {(matchBlock === "clientReq" || matchBlock === "originReq") &&
        watch("reqHeaders") === undefined &&
        FEATURES_FILTER_CONFIGS.reqHeaders(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_REQUEST_HEADERS_TITLE"),
          t("FEATURE_CARD_REQUEST_HEADERS_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_REQUEST_HEADERS_TITLE")}
            description={t("FEATURE_CARD_REQUEST_HEADERS_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("reqHeaders.nameList", []);
              if (closeModal) closeModal();
              setFocusFeature("reqHeaders");
            }}
            dataTestId="req-headers"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("respHeaders") === undefined &&
        FEATURES_FILTER_CONFIGS.respHeaders(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_RESPONSE_HEADERS_TITLE"),
          t("FEATURE_CARD_RESPONSE_HEADERS_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_RESPONSE_HEADERS_TITLE")}
            description={t("FEATURE_CARD_RESPONSE_HEADERS_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("respHeaders.nameList", []);
              if (closeModal) closeModal();
              setFocusFeature("respHeaders");
            }}
            dataTestId="resp-headers"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("serve200For416") === undefined &&
        FEATURES_FILTER_CONFIGS.serve200For416(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_SERVE_200_FOR_416_TITLE"),
          t("FEATURE_CARD_SERVE_200_FOR_416_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_SERVE_200_FOR_416_TITLE")}
            description={t("FEATURE_CARD_SERVE_200_FOR_416_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("serve200For416", true);
              if (closeModal) closeModal();
              setFocusFeature("serve200For416");
            }}
            dataTestId="serve-200-for-416"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {(matchBlock === "clientReq" || matchBlock === "originReq") &&
        watch("uriRewrite") === undefined &&
        FEATURES_FILTER_CONFIGS.uriRewrite(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_URI_REWRITE_TITLE"),
          t("FEATURE_CARD_URI_REWRITE_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_URI_REWRITE_TITLE")}
            description={t("FEATURE_CARD_URI_REWRITE_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("uriRewrite", "");
              if (closeModal) closeModal();
              setFocusFeature("uriRewrite");
            }}
            dataTestId="uri-rewrite"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {matchBlock === "originReq" &&
        watch("followRedirects") === undefined &&
        FEATURES_FILTER_CONFIGS.followRedirects(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_FOLLOW_REDIRECTS_TITLE"),
          t("FEATURE_CARD_FOLLOW_REDIRECTS_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_FOLLOW_REDIRECTS_TITLE")}
            description={t("FEATURE_CARD_FOLLOW_REDIRECTS_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("followRedirects", "never");
              if (closeModal) closeModal();
              setFocusFeature("followRedirects");
            }}
            dataTestId="follow-redirects"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("allowCompress") === undefined &&
        FEATURES_FILTER_CONFIGS.allowCompress(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_ALLOW_COMPRESS_TITLE"),
          t("FEATURE_CARD_ALLOW_COMPRESS_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_ALLOW_COMPRESS_TITLE")}
            description={t("FEATURE_CARD_ALLOW_COMPRESS_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("allowCompress", true);
              if (closeModal) closeModal();
              setFocusFeature("allowCompress");
            }}
            dataTestId="allow-compress"
            featureType={FeatureTypes.CONTENT_MANIPULATION}
          />
        )}
      {matchBlock === "originReq" &&
        watch("originFillPolicy") === undefined &&
        FEATURES_FILTER_CONFIGS.originFillPolicy(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_ORIGIN_TITLE"),
          t("FEATURE_CARD_ORIGIN_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_ORIGIN_TITLE")}
            description={t("FEATURE_CARD_ORIGIN_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("originFillPolicy", "");
              if (closeModal) closeModal();
              setFocusFeature("originFillPolicy");
            }}
            dataTestId="origin"
            featureType={FeatureTypes.ORIGIN_SELECTION}
          />
        )}
      {watch("customLogData") === undefined &&
        FEATURES_FILTER_CONFIGS.customLogData(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_CUSTOM_LOG_DATA_TITLE"),
          t("FEATURE_CARD_CUSTOM_LOG_DATA_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_CUSTOM_LOG_DATA_TITLE")}
            description={t("FEATURE_CARD_CUSTOM_LOG_DATA_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("customLogData", [
                {
                  keyName: "",
                  literalString: "",
                },
              ]);

              if (closeModal) closeModal();
              setFocusFeature("customLogData");
            }}
            dataTestId="custom-log-data"
            featureType={FeatureTypes.LOGGING}
          />
        )}
      {matchBlock === "clientReq" &&
        watch("reportingOverride") === undefined &&
        FEATURES_FILTER_CONFIGS.reportingOverride(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_REPORTING_OVERRIDE_TITLE"),
          t("FEATURE_CARD_REPORTING_OVERRIDE_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_REPORTING_OVERRIDE_TITLE")}
            description={t("FEATURE_CARD_REPORTING_OVERRIDE_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("reportingOverride", "");
              if (closeModal) closeModal();
              setFocusFeature("reportingOverride");
            }}
            dataTestId="reporting-override"
            featureType={FeatureTypes.LOGGING}
          />
        )}
      {(matchBlock === "clientReq" || matchBlock === "originReq") &&
        watch("reqLua") === undefined &&
        FEATURES_FILTER_CONFIGS.reqLua(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_REQUEST_LUA_TITLE"),
          t("FEATURE_CARD_REQUEST_LUA_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_REQUEST_LUA_TITLE")}
            description={t("FEATURE_CARD_REQUEST_LUA_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("reqLua", { nameList: [] });
              if (closeModal) closeModal();
              setFocusFeature("reqLua");
            }}
            dataTestId="req-lua"
            featureType={FeatureTypes.LUA}
          />
        )}
      {(matchBlock === "clientReq" ||
        matchBlock === "originReq" ||
        matchBlock === "originResp") &&
        watch("respLua") === undefined &&
        FEATURES_FILTER_CONFIGS.respLua(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_RESPONSE_LUA_TITLE"),
          t("FEATURE_CARD_RESPONSE_LUA_DESCRIPTION")
        ) && (
          <AddFeatureCard
            title={t("FEATURE_CARD_RESPONSE_LUA_TITLE")}
            description={t("FEATURE_CARD_RESPONSE_LUA_DESCRIPTION")}
            onAdd={() => {
              handleCreateFeature("respLua", { nameList: [] });
              if (closeModal) closeModal();
              setFocusFeature("respLua");
            }}
            dataTestId="resp-lua"
            featureType={FeatureTypes.LUA}
          />
        )}
    </CardsContainer>
  );
};

const CardsContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;
