import { TokenDefinitionType, ConfigurationDetailsType } from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getTokenReferences = (
  def: TokenDefinitionType,
  config: ConfigurationDetailsType
): number =>
  getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .map((feature) => feature?.tokenAuthentication)
    .filter((token) => token?.name === def.name).length;
