import { FC } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button as B, ButtonProps } from "../../atoms/Button/Button";
import { Icons } from "../../atoms/Icon/Icon";

export const Button: FC<Pick<ButtonProps, "onClick">> = (props) => {
  const { t } = useTranslation("misc");

  return (
    <StyledButton
      label={t("FEEDBACK_BUTTON")}
      icon={{
        name: Icons.FEEDBACK,
        props: { color: "highlight" },
      }}
      dataTestId="feedback-button"
      backgroundColor="baseLight"
      textColor="highlight"
      borderColor="highlight"
      {...props}
    />
  );
};

const StyledButton = styled(B)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  padding: 12px !important;
  border: 1px solid #00529a !important;
  border-radius: 100px !important;
`;
