import { ErrorLevel, APIError } from "../error/index";
import { AxiosError } from "axios";
import { httpClient } from "../../core/http-client";

export type UserPermissions =
  | { id: 1; name: "Reporting" }
  | { id: 5; name: "Invalidation" }
  | { id: 11; name: "View Configuration" }
  | { id: 13; name: "Edit Configuration" }
  | { id: 9999; name: "Non Existing Test Role" };

export enum UserRoles {
  EDIT_CONFIG = "Edit Configuration",
  INVALIDATION = "Invalidation",
  REPORTING = "Reporting",
  VIEW_CONFIG = "View Configuration",
  MOCK_ROLE = "Non Existing Test Role",
}

export const checkUserPermissions = (
  authorities: UserRoles | UserRoles[],
  userPermissions: UserPermissions[] = [],
  checkEvery = true
): boolean => {
  if (Array.isArray(authorities)) {
    if (checkEvery) {
      return authorities.every((auth) =>
        userPermissions?.some(({ name }) => name === auth)
      );
    }

    return authorities.some((auth) =>
      userPermissions?.some(({ name }) => name === auth)
    );
  } else {
    return userPermissions?.some(({ name }) => name === authorities) || false;
  }
};

export const getUserPermissions = async (
  accessGroupId: number
): Promise<UserPermissions[]> => {
  try {
    const { data } = await httpClient.get<UserPermissions[]>(
      "/auth/permissions",
      {
        params: { accessGroupId },
      }
    );

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new APIError(
      error.message,
      "getUserPermissions()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
