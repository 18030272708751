import styled from "@emotion/styled";
import { Popover, PopoverPosition } from "../../atoms/Popover/Popover";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";

export interface IHelpIconProps {
  message: string;
  title?: string;
  position?: PopoverPosition;
}

export const HelpIcon: React.FunctionComponent<IHelpIconProps> = ({
  message,
  title,
  position = "bottom",
}) => {
  return (
    <Popover position={position}>
      <HelpContainer>
        {title && <div>{title}</div>}
        <CustomRemark>{message}</CustomRemark>
      </HelpContainer>
    </Popover>
  );
};

const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: 8px;
  min-width: 200px;
  color: ${({ theme }) => theme.text.primary};
`;
