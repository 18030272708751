import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Remark } from "react-remark";

import { FlexBoxBase } from "../../../../../../components/atoms/FlexBox/FlexBox";
import { Button } from "../../../../../../components/atoms/Button/Button";
import { IModalChildren } from "../../../../../../components/atoms/Modal/Modal";

interface DeleteUserWarningModalProps extends IModalChildren {
  apiKeys: {
    name: string;
    id: number;
  }[];
  userName: string;
}

export const DeleteUserWarningModal = ({
  apiKeys,
  userName,
  closeModal,
}: DeleteUserWarningModalProps): ReactElement => {
  const { t } = useTranslation("usersPage");

  return (
    <Container>
      <Title>{t("DELETE_USER_MODAL_TITLE")}</Title>
      {apiKeys.length === 1 ? (
        <Line>
          <Text>
            <Remark>
              {t("DELETE_USER_MODAL_CONTENT_WITH_ONE_API_KEY_BLACK_TEXT", {
                userName,
              })}
            </Remark>
          </Text>
          <RedText>
            {t("DELETE_USER_MODAL_CONTENT_WITH_ONE_API_KEY_RED_TEXT")}
          </RedText>
          <Text>{apiKeys[0].name}</Text>
        </Line>
      ) : (
        <>
          <Line>
            <Text>
              <Remark>
                {t(
                  "DELETE_USER_MODAL_CONTENT_WITH_MULTIPLE_API_KEYS_BLACK_TEXT",
                  { userName }
                )}
              </Remark>
            </Text>
            <RedText>
              {t("DELETE_USER_MODAL_CONTENT_WITH_MULTIPLE_API_KEYS_RED_TEXT")}
            </RedText>
          </Line>
          {apiKeys.map((key) => (
            <KeyLine key={key.id}>
              {"- "}
              {key.name}
            </KeyLine>
          ))}
        </>
      )}
      <BottomText>
        {t("DELETE_USER_MODAL_CONTENT_WITH_API_KEYS_BOTTOM_TEXT")}
      </BottomText>

      <ButtonsContainer>
        <CancelButton
          label={t("DELETE_USER_MODAL_CANCEL_BUTTON")}
          backgroundColor="baseLight"
          textColor="primary"
          onClick={closeModal || (() => {})}
        />
        <ApproveButton
          label={t("DELETE_USER_MODAL_APPROVE_BUTTON")}
          disabled
          onClick={() => {}}
        />
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 16px;
`;

const Line = styled(FlexBoxBase)`
  align-items: center;
  justify-content: start;
`;

const Text = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const RedText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${({ theme }) => theme.text.error};
  padding-left: 3px;
`;

const KeyLine = styled.div`
  padding-bottom: 8px;
  padding-left: 8px;
`;

const BottomText = styled.div`
  padding-top: 24px;
`;

const ButtonsContainer = styled(FlexBoxBase)`
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
  padding: 12px 16px;
`;

const ApproveButton = styled(Button)`
  padding: 12px 16px;
`;
