import { ReactElement } from "react";
import styled from "@emotion/styled";

import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { Icon, Icons } from "../../atoms/Icon/Icon";

interface HintBoxProps {
  title: string;
  body: string;
  error?: string;
}

export const HintBox = ({
  title,
  body,
  error = "",
}: HintBoxProps): ReactElement => (
  <HintBoxContainer>
    <Leftine error={!!error} />
    <InfoIcon error={!!error} name={Icons.PLAIN_INFORMATION} />
    <SubContainer>
      <Title error={!!error}>{title}</Title>
      <Body>
        <CustomRemark>{body}</CustomRemark>
      </Body>
      {error ? <Body>{error}</Body> : null}
    </SubContainer>
  </HintBoxContainer>
);

const HintBoxContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};

  width: 400px;
  min-height: 10px;
  display: flex;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Leftine = styled.div<{ error: boolean }>`
  background-color: ${({ theme, error }) =>
    error ? theme.backgrounds.errorDark : theme.backgrounds.info};
  min-width: 4px;
  min-height: 100%;
`;

const SubContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  display: flex;
  flex-direction: column;
`;

const Title = styled.div<{ error: boolean }>`
  display: flex;
  margin: 16px;
  margin-bottom: 8px;
  color: ${({ theme, error }) =>
    error ? theme.colors.red80 : theme.colors.navy50};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  justify-content: space-between;
  align-items: start;
`;

const Body = styled.div`
  display: flex;
  margin: 16px;
  margin-top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.02em;
`;

const InfoIcon = styled(Icon)<{
  error: boolean;
}>`
  margin-top: 16px;
  margin-left: 16px;
  min-width: 16px;
  min-height: 16px;
  * {
    fill: ${({ error, theme }) =>
      error ? theme.colors.red80 : theme.colors.navy50} !important;
  }
`;
