import React from "react";
import { Route, Switch } from "react-router-dom";

import { CertificatesRouter } from "./cmv3_certificates";
import { ConfigurationsRouter } from "./configurations";
import { EnvironmentHistoryRouter } from "./environment_history";
import { ProductionRouter } from "./production";
import { SubscriberSettingsRouter } from "./subscriber_settings";

interface ICachingRouterProps {}

export const CachingRouter: React.FC<ICachingRouterProps> = () => {
  return (
    <Switch>
      <Route path={"/cmv3/production"}>
        <ProductionRouter />
      </Route>
      <Route path={"/cmv3/configurations"}>
        <ConfigurationsRouter />
      </Route>
      <Route path={"/cmv3/environment_history"}>
        <EnvironmentHistoryRouter />
      </Route>
      <Route path={"/cmv3/certificates"}>
        <CertificatesRouter />
      </Route>
      <Route path={"/cmv3/subscriber_settings"}>
        <SubscriberSettingsRouter />
      </Route>
    </Switch>
  );
};
