import styled from "@emotion/styled";
import { config, useTransition } from "react-spring";

import {
  DropDownOption,
  IDropdownItem,
} from "../../molecules/DropdownOption/DropdownOption";

export interface IDropDownContentProps<Data = string> {
  isOpen: boolean;
  items: IDropdownItem<Data>[];
  onSelect: (selection: IDropdownItem<Data>) => void;
  selectedItems: IDropdownItem<Data>[];
  multiSelect?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  dataTestId?: string;
}

export const DropDownContent = <T extends unknown>({
  isOpen,
  items,
  onSelect,
  selectedItems,
  multiSelect = false,
  header,
  footer,
  dataTestId,
}: IDropDownContentProps<T>): JSX.Element => {
  const transitions = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0rem" },
    enter: { opacity: 1, height: "2rem" },
    leave: { opacity: 0, height: "0rem" },
  });
  return (
    <OptionsContainer>
      {header}
      {transitions(
        (styles, show) =>
          show &&
          items.map((selection) => (
            <DropDownOption
              key={selection.value as string}
              styles={styles}
              selection={selection}
              onSelect={onSelect}
              selected={
                selectedItems.find((item) => item.value === selection.value) !==
                undefined
              }
              multiSelect={multiSelect}
              dataTestId={dataTestId}
            />
          ))
      )}
      {footer}
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  overflow-y: scroll;
  max-height: 250px;
`;
