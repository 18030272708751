import { ReactElement } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ButtonProps } from "../../atoms/Button/Button";
import { HelpIconButton } from "../../molecules/HelpIconButton/HelpIconButton";
import { ColorSet } from "../../../contexts/themeContext";

export type BadgeSize = "sm" | "md" | "lg" | "xl";

export interface BadgeProps {
  label: string;
  color?: keyof ColorSet["colors"];
  outline?: boolean;
  textColor?: keyof ColorSet["colors"] | keyof ColorSet["text"];
  flat?: boolean;
  id?: string;
  size?: BadgeSize;
  helpText?: string;
  customHelpIcon?: JSX.Element;
  cta?: ButtonProps[];
  helpPosition?: "top" | "bottom";
  dataTestId?: string;
  className?: string;
}

export const Badge = ({
  label,
  color,
  textColor,
  id,
  outline = false,
  flat = false,
  size,
  helpText,
  customHelpIcon,
  cta,
  helpPosition = "top",
  dataTestId,
  className,
}: BadgeProps): ReactElement => {
  const sizeClassName = size ? `-${size}` : "";

  return (
    <BadgeContainer
      id={id}
      className={`chi-badge ${
        outline ? "-outline" : flat && "-flat"
      } ${sizeClassName} ${className}`}
      badgeColor={color}
      textColor={textColor}
      outline={outline}
      flat={flat}
      data-testid={dataTestId}
    >
      {customHelpIcon ? (
        customHelpIcon
      ) : helpText ? (
        <>
          <HelpIconButton
            helpMessage={helpText}
            color="white"
            cta={cta}
            position={helpPosition}
          />
          &nbsp;
        </>
      ) : null}
      {label}
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div<{
  badgeColor?: keyof ColorSet["colors"];
  textColor?: keyof ColorSet["colors"] | keyof ColorSet["text"];
  outline?: boolean;
  flat?: boolean;
}>`
  position: relative;
  height: 24px !important;
  transition: background-color 0.2s;
  ${({ badgeColor, textColor, outline, flat, theme }) => {
    const textColors = { ...theme.colors, ...theme.text };
    if (badgeColor) {
      return outline
        ? css`
            background-color: rgb(0, 0, 0, 0) !important;
            border-color: ${theme.colors[badgeColor]} !important;
            color: ${textColors[textColor || "white"]} !important;
          `
        : flat
        ? css`
            background-color: rgb(0, 0, 0, 0) !important;
            border-color: rgb(0, 0, 0, 0) !important;
            color: ${textColors[textColor || "white"]} !important;
          `
        : css`
            background-color: ${theme.colors[badgeColor]} !important;
            border-color: ${theme.colors[badgeColor]} !important;
            color: ${textColors[textColor || "white"]};
          `;
    } else if (!badgeColor && !flat && !outline) {
      return css`
        color: ${textColors["black"]};
      `;
    }
  }}
`;
