import { PropertyDefinitionType } from "../types";

export const updatePropertiesReference = (oldName: string, newName: string) => (
  property: PropertyDefinitionType
): PropertyDefinitionType => {
  property.originFillPolicy =
    property.originFillPolicy === oldName ? newName : property.originFillPolicy;

  return property;
};
