import styled from "@emotion/styled";

export const HorizontalFormContainer = styled.div`
  display: table;
  width: 100%;

  .addbutton {
    display: inline-block;
    margin: 16px 0;
  }
`;

export const HorizontalFormBody = styled.div`
  display: table-row-group;
  width: 100%;
`;
