import { KeyboardEvent, ReactElement, useState } from "react";
import styled from "@emotion/styled";
import type {
  UseFormReset,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Button } from "../../../../../../../components/atoms/Button/Button";
import { Protected } from "../../../../../../../components/atoms/Protected/Protected";
import { ExpandableContent } from "../../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { IFeatures } from "../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../models/permissions";
import { AddFeaturesList } from "./add/AddFeaturesList";
import { AddFeaturesModal } from "./add/AddFeaturesModal";
import { FeaturesSearchBar } from "./FeaturesSearchBar";
import { FeaturesList, FeaturesListProps } from "./list/FeaturesList";
import { useFeatureType } from "./useFeatureType";
import { getQueryParams } from "../../../../../../../utils/getQueryParams";
import { FocusFeature } from "../types";
import { FOCUS_FEATURE_QUERY } from "../../../constants";

export interface FeaturesProps
  extends Pick<
    FeaturesListProps,
    "errors" | "register" | "setError" | "clearErrors"
  > {
  matchBlock: string;
  setValue: UseFormSetValue<IFeatures>;
  onSubmit: () => unknown;
  watch: UseFormWatch<IFeatures>;
  reset: UseFormReset<IFeatures>;

  unregister: UseFormUnregister<IFeatures>;
}

const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
  if (e.code === "Enter") {
    e.preventDefault();
  }
};

export const Features = ({
  clearErrors,
  errors,
  matchBlock,
  onSubmit,
  register,
  setError,
  setValue,
  unregister,
  watch,
  reset,
}: FeaturesProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");

  const [focusFeature, setFocusFeature] = useState(
    getQueryParams(useLocation()).get(FOCUS_FEATURE_QUERY) as FocusFeature
  );

  const {
    dropdownItems,
    searchValue,
    selectedFeatureType,
    setSearchValue,
    setSelectedFeatureType,
  } = useFeatureType(matchBlock);
  const formValues = watch();

  const handleDeleteFeature = (featureName: keyof IFeatures) => {
    const newForm = { ...formValues };
    delete newForm[featureName];

    reset(newForm);
    unregister(featureName);

    onSubmit();
  };

  const formFilled = Object.values(formValues).some((el) => el !== undefined);
  const shouldRenderAddFeatures = !formFilled;

  return (
    <FeatureWrapper>
      <FeaturesSearchBar
        dropdownItems={dropdownItems}
        setSearchValue={setSearchValue}
        setSelectedFeatureType={setSelectedFeatureType}
        searchValue={searchValue}
      />
      <ExpandableContent
        dataTestId="features-page-description"
        title={t("FEATURES_DESCRIPTION_TITLE")}
        content={t("FEATURES_DESCRIPTION_CONTENT")}
      />
      <form
        name="featuresForm"
        onSubmit={onSubmit}
        onKeyDown={(event) => checkKeyDown(event)}
      >
        {shouldRenderAddFeatures ? (
          <Protected permissions={UserRoles.EDIT_CONFIG}>
            <AddFeaturesList
              matchBlock={matchBlock}
              handleCreateFeature={setValue}
              setFocusFeature={setFocusFeature}
              selectedFeatureType={selectedFeatureType.value}
              searchValue={searchValue}
              watch={watch}
            />
          </Protected>
        ) : (
          <>
            <FeaturesList
              focusFeature={focusFeature}
              errors={errors}
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              handleDeleteFeature={handleDeleteFeature}
              handleCreateFeature={setValue}
              selectedFeatureType={selectedFeatureType.value}
              searchValue={searchValue}
              watch={watch}
              unregister={unregister}
            />
            <Footer>
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                {formFilled && (
                  <AddFeaturesModal
                    matchBlock={matchBlock}
                    handleCreateFeature={setValue}
                    setFocusFeature={setFocusFeature}
                    watch={watch}
                  />
                )}
                <Button
                  type="submit"
                  label="Save"
                  onClick={() => {}}
                  dataTestId="match-rules-features-save-button"
                />
              </Protected>
            </Footer>
          </>
        )}
      </form>
    </FeatureWrapper>
  );
};

const FeatureWrapper = styled.div`
  padding-bottom: 2rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0px 0px;
`;
