import { isBefore, sub } from "date-fns";
import { AccessGroup } from "../../../../models/user/user.interfaces";

import { DateFilter } from "./types";

const generateDateToCompare = (
  dateFilter: Omit<DateFilter, DateFilter.ALL_TIME>
) => {
  switch (dateFilter) {
    case DateFilter.LAST_24_HOURS:
      return sub(new Date(), { days: 1 });
    case DateFilter.LAST_7_DAYS:
      return sub(new Date(), { days: 7 });
    case DateFilter.LAST_30_DAYS:
      return sub(new Date(), { days: 30 });
    default:
      throw new Error(`Unknown date filter option "${dateFilter}"`);
  }
};

export const filterAccessGroups = (
  input: string,
  creationDate: DateFilter | undefined
) => (accessGroups: AccessGroup[]): AccessGroup[] => {
  const result: AccessGroup[] = [];
  accessGroups.forEach((accessGroup) => {
    // Test is access group matches the input
    let shouldDisplayAccessGroup = false;
    if (input === "") {
      shouldDisplayAccessGroup = true;
    }
    if (accessGroup.id === parseInt(input, 10)) {
      shouldDisplayAccessGroup = true;
    }
    if (accessGroup.name.toLocaleLowerCase().match(input.toLocaleLowerCase())) {
      shouldDisplayAccessGroup = true;
    }
    if (
      accessGroup.description &&
      accessGroup.description
        .toLocaleLowerCase()
        .match(input.toLocaleLowerCase())
    ) {
      shouldDisplayAccessGroup = true;
    }

    if (
      creationDate &&
      creationDate !== DateFilter.ALL_TIME &&
      isBefore(
        Date.parse(accessGroup.createdDate),
        generateDateToCompare(creationDate)
      )
    ) {
      shouldDisplayAccessGroup = false;
    }

    // If AG has no children
    if (!accessGroup.list && shouldDisplayAccessGroup) {
      result.push(accessGroup);
    }

    if (!!accessGroup.list) {
      const newList = filterAccessGroups(input, creationDate)(accessGroup.list);
      if (newList.length > 0) {
        result.push({
          ...accessGroup,
          list: newList,
        });
      } else if (shouldDisplayAccessGroup) {
        const newAG = { ...accessGroup };
        delete newAG.list;
        result.push(newAG);
      }
    }
  });
  return result;
};

export const countAccessGroups = (accessGroups: AccessGroup[]): number =>
  accessGroups.reduce((count, accessGroup) => {
    if (!accessGroup.list) {
      return count + 1;
    }
    return count + 1 + countAccessGroups(accessGroup.list);
  }, 0);
