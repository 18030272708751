import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useRefState } from "../../../hooks/use-ref-state";

export interface ISliderProps {
  initialValue?: number;
  onChange: (value: number) => void;
}

export const Slider: FC<ISliderProps> = ({ initialValue, onChange }) => {
  const [value, valueRef, setValue] = useRefState<number>(initialValue || 0);
  const [updatingSlider, setUpdatingSlider] = useState<boolean>(false);

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const smoothValueUpdate = (targetValue: number) => {
    setTimeout(() => {
      if (valueRef.current !== targetValue) {
        setUpdatingSlider(true);
        if (valueRef.current < targetValue) {
          setValue(valueRef.current + 1);
        } else {
          setValue(valueRef.current - 1);
        }
        smoothValueUpdate(targetValue);
      } else {
        setUpdatingSlider(false);
        onChange(valueRef.current);
      }
    }, 100 / Math.abs(targetValue - valueRef.current));
  };

  return (
    <>
      <SliderContainer>
        <SliderProgress progress={value} />
        <SliderRange
          type="range"
          min="0"
          max="100"
          value={value}
          onChange={(e) => {
            if (!updatingSlider) {
              setValue(parseInt(e.target.value, 10));
              onChange(valueRef.current);
            }
          }}
        />
        <SliderBackground />
      </SliderContainer>
      <LabelsContainer>
        {Array.from(Array(11).keys()).map((v) => (
          <SliderLabel
            data-testid={`slider-label-${v * 10}`}
            key={v}
            onClick={() => {
              if (!updatingSlider) {
                smoothValueUpdate(v * 10);
              }
            }}
            state={
              v * 10 < value ? "before" : v * 10 === value ? "active" : "after"
            }
          >
            {`${v * 10}%`}
          </SliderLabel>
        ))}
      </LabelsContainer>
    </>
  );
};

const SliderContainer = styled.div`
  position: relative;
  margin: 0 12px;
`;

const SliderBackground = styled.div`
  position: absolute;
  top: 8px;
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.backgrounds.base};
  border-radius: 4px;
  z-index: 1;
`;

const SliderProgress = styled.div<{
  progress: number;
}>`
  position: absolute;
  top: 8px;
  width: ${(props) => `${Math.min(props.progress, 100)}%`};
  background-color: ${({ theme }) => theme.icon.highlight};
  height: 8px;
  border-radius: 4px;
  z-index: 2;
`;

const SliderRange = styled.input`
  width: 100%;
  height: 8px;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: ${({ theme }) => theme.icon.highlight};
    border: solid 4px ${({ theme }) => theme.backgrounds.base};
    border-radius: 50%;
    cursor: pointer;
  }
`;

const LabelsContainer = styled.div`
  padding-top: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SliderLabel = styled.div<{
  state: "before" | "active" | "after";
}>`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ state, theme }) =>
    state === "active" && theme.icon.highlight};
  color: ${({ state, theme }) => {
    switch (state) {
      case "before":
        return theme.text.highlight;
      case "active":
        return theme.text.primaryAlt;
      default:
        return theme.text.secondary;
    }
  }};
  cursor: pointer;
`;
