/* eslint-disable import/named */
import { useEffect, useMemo, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";
import { animated, config, useTransition } from "react-spring";

import { useClickOutsideRef } from "../../../hooks/use-detect-click-outside-ref";
import { ValidationErrors } from "../../../modules/caching/configurations/configuration_page/match_rules/match_rules_page/features/list/types";
import {
  ConfigurationDefinition,
  ConfigurationDefinitions,
  ConfigurationDetailsType,
} from "../../../store/slices/caching/types";
import {
  DefinitionDropdownItems,
  FeatureType,
} from "../../molecules/DefinitionDropdownItems/DefinitionDropdownItems";

export type DefinitionDropdownItem = {
  definition: ConfigurationDefinition;
  disabled?: boolean;
};

export interface DefinitionDropdownProps {
  id: string;
  items: DefinitionDropdownItem[];
  parentConfig: ConfigurationDetailsType;
  type: FeatureType;
  selected?: ConfigurationDefinitions;
  allowMultiSelection?: boolean;
  allowEdit?: boolean;
  allowAdd?: boolean;
  errors?: ValidationErrors;
  disabled?: boolean;
  dataTestId?: string;
  onSelect: (selectedItems: ConfigurationDefinition[]) => void;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

export const DefinitionDropdown: React.FC<DefinitionDropdownProps> = ({
  id,
  items,
  type,
  selected,
  allowMultiSelection = true,
  allowEdit = true,
  allowAdd = true,
  disabled = false,
  dataTestId,
  parentConfig,
  onSelect,
  setError,
  clearErrors,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMount, setIsMount] = useState<boolean>(true);

  const opening = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0px" },
    enter: { opacity: 1, height: `${items.length * 2}rem` },
    leave: { opacity: 0, height: "0px" },
  });

  const placeholder = useMemo(() => {
    if (selected && selected.length > 0) {
      let formattedPlaceholder = selected[0].name;

      if (selected.length > 1) {
        formattedPlaceholder = formattedPlaceholder.concat(
          `, ${selected[1].name}`
        );
      }

      if (selected.length > 2) {
        formattedPlaceholder = formattedPlaceholder.concat(", ...");
      }

      return formattedPlaceholder;
    }

    return allowMultiSelection ? "Select definitions" : "Select a definition";
  }, [selected]);

  const updateErrors = () => {
    if (setError && clearErrors) {
      if (selected?.length === 0) {
        setError(id, { type: "manual", message: "This is a required field" });
      } else {
        clearErrors(id);
      }
    }
  };

  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    updateErrors();
  }, [selected === undefined, selected?.length === 0]);

  useEffect(() => {
    if (clearErrors && id && selected && selected.length > 0) {
      clearErrors(id);
    }
  }, []);

  const dropdownRef = useRef(null);
  useClickOutsideRef(dropdownRef, () => {
    setIsOpen(false);
    updateErrors();
  });

  return (
    <DropdownContainer className="chi-dropdown" ref={dropdownRef}>
      <DropdownButton
        onClick={(event) => {
          setIsOpen(!isOpen);
          event.stopPropagation();
        }}
        disabled={disabled}
        id={`dropdown-definitions-${id}`}
        className="chi-button chi-dropdown__trigger"
        isopen={isOpen ? 1 : 0}
        type="button"
        data-testid={`dropdown-definitions-${dataTestId}`}
      >
        <Placeholder>{placeholder}</Placeholder>
      </DropdownButton>
      {opening(
        (styles, show) =>
          show && (
            <DropdownMenu style={styles} className="chi-dropdown__menu">
              <DefinitionDropdownItems
                isOpen={isOpen}
                items={items}
                type={type}
                setIsOpen={setIsOpen}
                selectedItems={selected}
                allowMultiSelection={allowMultiSelection}
                allowEdit={allowEdit}
                allowAdd={allowAdd}
                onSelect={onSelect}
                dataTestId={dataTestId}
                parentConfig={parentConfig}
              />
            </DropdownMenu>
          )
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  padding: 0px;
  height: 100%;
  width: 100%;
  border: none !important;
`;

const DropdownButton = styled(animated.button)<{
  isopen: number;
}>`
  box-sizing: border-box;
  height: 44px;
  width: 100%;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;

  ${({ theme, isopen }) =>
    css`
      border: 1px solid
        ${isopen ? theme.borders.highlight : theme.borders.mutedLight};
      background-color: ${isopen
        ? theme.backgrounds.highlightLight
        : "transparent"};
      color: ${isopen ? "black" : theme.text.primary};
    `}
  box-shadow: none !important;
`;

const DropdownMenu = styled(animated.div)`
  display: table !important;
  border-radius: 0px !important;
  width: inherit;
  height: inherit !important;
  padding: 4px !important;
  background-color: ${({ theme }) => theme.backgrounds.baseLight}!important;
`;

const Placeholder = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
`;
