import {
  MatchLogicDefinitionType,
  ConfigurationDetailsType,
} from "../../types";

export const getMatchLogicReferences = (
  def: MatchLogicDefinitionType,
  config: ConfigurationDetailsType
): number =>
  config.propertyDefinitions.reduce(
    (acc, curr) => (curr.matchLogic === def.name ? acc + 1 : acc),
    0
  );
