import { ReactElement, useState } from "react";
import styled from "@emotion/styled";

import { IGeneratedResponseHeader } from "../../../models/configuration/definitions";
import {
  isDefinitionNameValid,
  isValidStatusCode,
} from "../../../utils/string";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  AddButton,
  AddButtonContainer,
  Field,
  FieldInput,
  FieldName,
  Remove,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderWrapper,
} from "../DefinitionModal/DefinitionModal.styled";

import { useTranslation } from "react-i18next";
import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { TextField } from "../TextField/TextField";
import { getDuplicatedHeaderNames } from "../DefinitionModal/helpers";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { GeneratedResponseDefinitionType } from "../../../store/slices/caching/types";
import { createGeneratedResponseDefinition } from "../../../store/slices/caching/helpers/generated-response-definition/createGeneratedResponseDefinition";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";
import { getGeneratedResponseReferences } from "../../../store/slices/caching/helpers/generated-response-definition/getGeneratedResponseReferences";

type GeneratedResponseModalProps = IDefaultDefinitionModalProps<GeneratedResponseDefinitionType>;

export const GeneratedResponseModal = ({
  definition,
  parentConfig,
  onSubmit,
}: GeneratedResponseModalProps): ReactElement => {
  const { t } = useTranslation("configurationDefinitionsPage");

  const selectedConfiguration = useSelectedConfiguration();

  const [name, setName] = useState(definition?.name ?? "");
  const [schemaDescription, setSchemaDescription] = useState(
    definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
  );
  const [status, setStatus] = useState<number | undefined>(
    definition?.statusCode || undefined
  );
  const [reason, setReason] = useState<string>(definition?.reason || "");
  const [payload, setPayload] = useState<string>(definition?.payload || "");
  const [mime, setMime] = useState<string>(definition?.mimeType || "");
  const [headers, setHeaders] = useState<IGeneratedResponseHeader[]>(
    definition?.headers || []
  );

  const [error, setError] = useState("");

  const handleHeaderUpdate = (header: IGeneratedResponseHeader, i: number) => {
    setHeaders((_headers) =>
      _headers.map((h, index) => (index === i ? header : h))
    );
  };

  const addHeader = () => {
    setHeaders((_headers) => [..._headers, { name: "", value: "" }]);
  };

  const removeHeader = (index: number) => {
    headers.splice(index, 1);
    setHeaders([...headers]);
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.GENERATED_RESPONSE]
      )
    ) {
      setError(
        t("ERROR_DEFINITION_GENERATED_RESPONSE_ALREADY_EXISTS", { name })
      );
      return false;
    }

    if (!status) {
      setError(t("ERROR_DEFINITION_GENERATED_RESPONSE_STATUS_MISSING"));
      return false;
    }

    if (status && !isValidStatusCode(status.toString())) {
      setError(t("ERROR_DEFINITION_GENERATED_RESPONSE_STATUS_INVALID"));
      return false;
    }

    if (payload.length > 0 && !mime) {
      setError(t("ERROR_DEFINITION_GENERATED_RESPONSE_MIME_TYPE_MISSING"));
      return false;
    }

    if (headers.length > 0) {
      if (!headers.every((header) => header.name.length > 0)) {
        setError(t("ERROR_DEFINITION_HEADER_NAME_NOT_EXISTS"));
        return false;
      }

      if (getDuplicatedHeaderNames(headers).length > 0) {
        setError(t("ERROR_DEFINITION_DUPLICATE_HEADER_NAMES"));
        return false;
      }
    }

    if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    return true;
  };

  const initState = () => {
    setError("");
    setName(definition?.name ?? "");
    setSchemaDescription(
      definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
    );
    setStatus(definition?.statusCode || undefined);
    setReason(definition?.reason || "");
    setPayload(definition?.payload || "");
    setMime(definition?.mimeType || "");
    setHeaders(definition?.headers || []);
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="generatedresponse-name"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
          dataTestId="generated-response-modal-header"
        />
      }
      references={
        definition
          ? getGeneratedResponseReferences(definition, parentConfig)
          : 0
      }
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        if (definition && status) {
          setError("");
          onSubmit({
            ...definition,
            name,
            _schemaDescription: schemaDescription,
            statusCode: status,
            payload,
            mimeType: mime,
            headers,
          });
        } else {
          if (selectedConfiguration && selectedConfiguration.config && status) {
            onSubmit(
              createGeneratedResponseDefinition(name, {
                description: schemaDescription,
                statusCode: status,
                reason,
                payload,
                mimeType: mime,
                headers,
              })
            );
          }
        }

        initState();
      }}
      dataTestId="generated-response"
    >
      <ModalContainer>
        <Table>
          <TableBody>
            <Field>
              <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>
              <FieldInput
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
                data-testid="generated-response-modal-definition-name-input"
              />
            </Field>
            <Field>
              <FieldName required>
                {t("EDIT_DEFINITION_GENERATED_RESPONSE_STATUS_FIELD_LABEL")}
              </FieldName>
              <FieldInput
                value={status}
                placeholder={t(
                  "EDIT_DEFINITION_GENERATED_RESPONSE_STATUS_FIELD_PLACEHOLDER"
                )}
                onChange={(event) => {
                  if (Number.isInteger(Number(event.target.value))) {
                    setStatus(Number(event.target.value));
                  }
                }}
                data-testid="generated-response-modal-status-field"
              />
            </Field>
            <Field>
              <FieldName>
                {t("EDIT_DEFINITION_GENERATED_RESPONSE_REASON_FIELD_LABEL")}
              </FieldName>
              <FieldInput
                value={reason}
                placeholder={t(
                  "EDIT_DEFINITION_GENERATED_RESPONSE_REASON_FIELD_PLACEHOLDER"
                )}
                onChange={(event) => setReason(event.target.value)}
                data-testid="generated-response-modal-reason-field"
              />
            </Field>
            <Field>
              <FieldName>
                {t("EDIT_DEFINITION_GENERATED_RESPONSE_PAYLOAD_FIELD_LABEL")}
              </FieldName>
              <FieldInput
                value={payload}
                placeholder={t(
                  "EDIT_DEFINITION_GENERATED_RESPONSE_PAYLOAD_FIELD_PLACEHOLDER"
                )}
                onChange={(event) => setPayload(event.target.value)}
                data-testid="generated-response-modal-payload-field"
              />
            </Field>
            <Field>
              <FieldName>
                {t("EDIT_DEFINITION_GENERATED_RESPONSE_MIME_TYPE_FIELD_LABEL")}
              </FieldName>
              <FieldInput
                value={mime}
                placeholder={t(
                  "EDIT_DEFINITION_GENERATED_RESPONSE_MIME_TYPE_FIELD_PLACEHOLDER"
                )}
                onChange={(event) => setMime(event.target.value)}
                data-testid="generated-response-modal-mime-type-field"
              />
            </Field>
            <Field>
              <FieldName>
                {t("EDIT_DEFINITION_GENERATED_RESPONSE_HEADERS_FIELD_LABEL")}
              </FieldName>

              {headers.length > 0 && (
                <Table>
                  <TableHeaderWrapper>
                    <TableHeader>
                      {t(
                        "EDIT_DEFINITION_GENERATED_RESPONSE_HEADER_NAME_COLUMN"
                      )}
                    </TableHeader>
                    <TableHeader>
                      {t(
                        "EDIT_DEFINITION_GENERATED_RESPONSE_HEADER_VALUE_COLUMN"
                      )}
                    </TableHeader>
                    <TableHeader></TableHeader>
                  </TableHeaderWrapper>
                  <TableBody>
                    {headers.map((header, i) => (
                      <Field key={`row-${i}`}>
                        <TableCell rightPadding bottomPadding="thin">
                          <FieldInput
                            value={header.name}
                            placeholder={t(
                              "EDIT_DEFINITION_GENERATED_RESPONSE_HEADER_NAME_COLUMN_PLACEHOLDER"
                            )}
                            onChange={(event) =>
                              handleHeaderUpdate(
                                {
                                  name: event.target.value,
                                  value: header.value,
                                },
                                i
                              )
                            }
                            data-testid={`generated-response-header-name-field-${i}`}
                          />
                        </TableCell>
                        <TableCell leftPadding bottomPadding="thin">
                          <FieldInput
                            value={header.value || ""}
                            placeholder={t(
                              "EDIT_DEFINITION_GENERATED_RESPONSE_HEADER_VALUE_COLUMN_PLACEHOLDER"
                            )}
                            onChange={(event) =>
                              handleHeaderUpdate(
                                {
                                  name: header.name,
                                  value: event.target.value,
                                },
                                i
                              )
                            }
                            data-testid={`generated-response-header-value-field-${i}`}
                          />
                        </TableCell>
                        <TableCell centered>
                          <Remove onClick={() => removeHeader(i)} />
                        </TableCell>
                      </Field>
                    ))}
                  </TableBody>
                </Table>
              )}

              <AddButtonContainer>
                <AddButton
                  onClick={() => addHeader()}
                  data-testid="generated-response-add-header-button"
                >
                  {t("ADD_HEADER_BUTTON")}
                </AddButton>
              </AddButtonContainer>
            </Field>
            <Field>
              <FieldName>
                {t(
                  "EDIT_DEFINITION_GENERATED_RESPONSE_DESCRIPTION_FIELD_LABEL"
                )}
              </FieldName>
              <FieldInput
                value={schemaDescription}
                placeholder={t(
                  "EDIT_DEFINITION_GENERATED_RESPONSE_DESCRIPTION_FIELD_PLACEHOLDER"
                )}
                onChange={(event) => setSchemaDescription(event.target.value)}
                data-testid="generated-response-modal-description-field"
              />
            </Field>
          </TableBody>
        </Table>
        {error.length > 0 && (
          <NotificationCard
            dataTestId="error-generated-response-modal"
            theme="error"
            title={error}
          />
        )}
      </ModalContainer>
    </DefinitionModal>
  );
};

const ModalContainer = styled.div``;
