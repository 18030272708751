import { Dispatch, SetStateAction, useEffect } from "react";

import { Protocols } from "../../../../../../models/configuration/definitions/origin";
import {
  EncryptionLevels,
  ServiceTypes,
  TrafficType,
} from "../../../../../../models/configuration/definitions/property";
import { createPropertyDefinition } from "../../../../../../store/slices/caching/helpers/property-definition/createPropertyDefinition";
import {
  useProperties,
  useSelectedConfiguration,
} from "../../../../../../store/slices/caching/hooks";
import {
  MatchLogicDefinitionType,
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { cloneDefinition } from "../../../../../../utils/object";

interface userPropertyResult {
  updateProperty: (prop: PropertyDefinitionType) => void;
  updateOrigin: (origin: Partial<OriginDefinitionType> | undefined) => void;
}

export const useProperty = (
  propertyName: string,
  property: PropertyDefinitionType | undefined,
  setProperty: (value: PropertyDefinitionType | undefined) => void,
  setCurrentMatchLogic: (value: MatchLogicDefinitionType | undefined) => void,
  setOriginDefinition: Dispatch<
    SetStateAction<OriginDefinitionType | undefined>
  >
): userPropertyResult => {
  const selectedConfiguration = useSelectedConfiguration();
  const properties = useProperties();

  const updateProperty = (prop: PropertyDefinitionType) => {
    setProperty(cloneDefinition(prop));
  };

  const updateOrigin = (
    newOrigin: Partial<OriginDefinitionType> | undefined
  ) => {
    setOriginDefinition((_prevOrigin) =>
      typeof newOrigin !== "undefined"
        ? cloneDefinition({ ..._prevOrigin, ...newOrigin })
        : newOrigin
    );
  };

  useEffect(() => {
    const prop = selectedConfiguration?.config?.propertyDefinitions?.find(
      (p) => p.name === propertyName
    );

    if (!prop) {
      const propIndex = properties.findIndex((p) => p.name === propertyName);

      if (selectedConfiguration && selectedConfiguration.config) {
        setProperty(
          cloneDefinition(
            createPropertyDefinition(propertyName, {
              primaryAlias:
                propIndex === -1 ? "" : properties[propIndex].primaryAlias,
              aliasOverrides: [],
              protocolSettings: {
                protocols: [Protocols.HTTP, Protocols.HTTPS],
                httpsConfig: {
                  serviceType: ServiceTypes.BASIC,
                  encryptionLevel: EncryptionLevels.DEFAULT,
                },
              },
              trafficType: TrafficType.VOD,
            })
          )
        );
      }
    } else {
      if (!property) {
        setProperty(cloneDefinition(prop));
      }
    }
  }, [properties, selectedConfiguration]);

  // Reset Match Logic and Origin Definition
  useEffect(() => {
    if (property) {
      const matchLogic =
        selectedConfiguration?.config?.matchLogicDefinitions[
          selectedConfiguration?.config?.matchLogicDefinitions.findIndex(
            (mld) => mld.name === property.matchLogic
          )
        ];
      if (matchLogic) {
        setCurrentMatchLogic(cloneDefinition(matchLogic));
      }
    }
  }, [property?.matchLogic]);

  useEffect(() => {
    if (property) {
      const foundDefinition = selectedConfiguration?.config
        ?.originFillPolicyDefinitions
        ? selectedConfiguration?.config?.originFillPolicyDefinitions.find(
            (od) => od.name === property.originFillPolicy
          )
        : undefined;
      if (foundDefinition) {
        setOriginDefinition(cloneDefinition(foundDefinition));
      }
    }
  }, [property?.originFillPolicy]);

  return { updateProperty, updateOrigin };
};
