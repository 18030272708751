import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

import { IDropdownItem } from "../../../../../../../components/organisms/Dropdown/Dropdown";
import { ConditionTypes, Operators } from "./dictionnaries";
import { MatchBlocks } from "../../../../../../../models/configuration/definitions/matchlogic";

interface ConditionDropdownItem
  extends Omit<IDropdownItem<ConditionTypes>, "label"> {
  label: Parameters<TFunction<"configurationMatchRulesPage">>[0];
}

const CONDITIONS_TYPES_LIST_TEMPLATE: ConditionDropdownItem[] = [
  {
    label: "CONDITION_TYPE_PATH_LABEL",
    value: ConditionTypes.PATH_ALL_OPERATORS,
    dataTestId: "condition-type-path-all",
  },
  {
    label: "CONDITION_TYPE_REQUEST_HEADER_LABEL",
    value: ConditionTypes.REQUEST_HEADERS,
    dataTestId: "condition-type-request-header",
  },
  {
    label: "CONDITION_TYPE_PATH_QUERY_STRING_LABEL",
    value: ConditionTypes.PATH_QUERY_STRING,
    dataTestId: "condition-type-path-query-string",
  },
  {
    label: "CONDITION_TYPE_METHOD_LABEL",
    value: ConditionTypes.METHOD,
    dataTestId: "condition-type-method",
  },
  {
    label: "CONDITION_TYPE_URI_LABEL",
    value: ConditionTypes.URI,
    dataTestId: "condition-type-uri",
  },
  {
    label: "CONDITION_TYPE_QUERY_STRING_LABEL",
    value: ConditionTypes.QUERY_STRING,
    dataTestId: "condition-type-query-string",
  },
  {
    label: "CONDITION_TYPE_RESPONSE_HEADER_LABEL",
    value: ConditionTypes.RESPONSE_HEADER,
    dataTestId: "condition-type-response-header",
  },
  {
    label: "CONDITION_TYPE_RESPONSE_STATUS_LABEL",
    value: ConditionTypes.RESPONSE_STATUS,
    dataTestId: "condition-type-response-status",
  },
];

interface OperatorDropdownItem extends Omit<IDropdownItem<Operators>, "label"> {
  label: Parameters<TFunction<"configurationMatchRulesPage">>[0];
}

const OPERATORS_LIST_TEMPLATE: Record<Operators, OperatorDropdownItem> = {
  equals: {
    label: "OPERATOR_EQUALS_LABEL",
    value: Operators.EQUALS,
    dataTestId: "condition-operator-equals",
  },
  does_not_equal: {
    label: "OPERATOR_NOT_EQUALS_LABEL",
    value: Operators.DOES_NOT_EQUAL,
    dataTestId: "condition-operator-not-equals",
  },
  greater_than: {
    label: "OPERATOR_GREATER_THAN_LABEL",
    value: Operators.GREATER_THAN,
    dataTestId: "condition-operator-greater-than",
  },
  less_than: {
    label: "OPERATOR_LESS_THAN_LABEL",
    value: Operators.LESS_THAN,
    dataTestId: "condition-operator-less-than",
  },
  greater_than_or_equal_to: {
    label: "OPERATOR_GREATER_THAN_OR_EQUAL_TO_LABEL",
    value: Operators.GREATER_THAN_OR_EQUAL_TO,
    dataTestId: "condition-operator-greater-than-or-equal",
  },
  less_than_or_equal_to: {
    label: "OPERATOR_LESS_THAN_OR_EQUAL_TO_LABEL",
    value: Operators.LESS_THAN_OR_EQUAL_TO,
    dataTestId: "condition-operator-less-than-or-equal",
  },
  globmatch: {
    label: "OPERATOR_GLOBMATCH_LABEL",
    value: Operators.GLOBMATCH,
    dataTestId: "condition-operator-globmatch",
  },
  negated_globmatch: {
    label: "OPERATOR_NEGATED_GLOBMATCH_LABEL",
    value: Operators.NEGATED_GLOBMATCH,
    dataTestId: "condition-operator-negated-globmatch",
  },
  regexp: {
    label: "OPERATOR_REGEXP_MATCH_LABEL",
    value: Operators.REGEXP,
    dataTestId: "condition-operator-regexp",
  },
  negated_regexp: {
    label: "OPERATOR_NEGATED_REGEXP_MATCH_LABEL",
    value: Operators.NEGATED_REGEXP,
    dataTestId: "condition-operator-negated-regexp",
  },
};

const CONDITIONS_WITH_OPERATORS: Record<ConditionTypes, Operators[]> = {
  method: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  path_all_operators: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  path_query_string: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  query_string: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  request_headers: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  uri: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  response_header: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GLOBMATCH,
    Operators.NEGATED_GLOBMATCH,
    Operators.REGEXP,
    Operators.NEGATED_REGEXP,
  ],
  response_status: [
    Operators.EQUALS,
    Operators.DOES_NOT_EQUAL,
    Operators.GREATER_THAN,
    Operators.LESS_THAN,
    Operators.GREATER_THAN_OR_EQUAL_TO,
    Operators.LESS_THAN_OR_EQUAL_TO,
  ],
};

type UseDropdownsItems = (
  conditionType: ConditionTypes | undefined,
  operator: Operators | undefined,
  matchBlock: MatchBlocks
) => {
  conditionsTypeList: IDropdownItem<ConditionTypes>[];
  operatorList: IDropdownItem<Operators>[] | undefined;
};

export const useDropdownsItems: UseDropdownsItems = (
  conditionType,
  operator,
  matchBlock
) => {
  const { t } = useTranslation("configurationMatchRulesPage");

  const conditionsTypeList = CONDITIONS_TYPES_LIST_TEMPLATE.reduce(
    (list, obj) => {
      if (
        matchBlock === MatchBlocks.ORIGIN_RESP ||
        (obj.value !== ConditionTypes.RESPONSE_HEADER &&
          obj.value !== ConditionTypes.RESPONSE_STATUS)
      ) {
        list.push({
          ...obj,
          default: obj.value === conditionType,
          label: t(obj.label),
        });

        return list;
      } else {
        return list;
      }
    },
    [] as IDropdownItem<ConditionTypes>[]
  );

  const operatorList =
    conditionType &&
    CONDITIONS_WITH_OPERATORS[conditionType].map((_operator) => {
      const operatorDropdownItem = OPERATORS_LIST_TEMPLATE[_operator];

      return {
        ...operatorDropdownItem,
        label: t(operatorDropdownItem.label),
        default: operatorDropdownItem.value === operator,
      };
    });

  return { conditionsTypeList, operatorList };
};
