import { FC } from "react";
import { useLocation } from "react-router-dom";

import { WidgetsContainer } from "../../../../../components/atoms/WidgetsContainer/WidgetsContainer";
import { Report } from "../../../../../components/organisms/Report/Report";
import { SingleNumberWidget } from "../../../../../components/organisms/SingleNumberWidget/SingleNumberWidget";
import { PropertyFilter } from "../../../../../components/organisms/Filters/PropertyFilter";
import { ChartPropertiesTable } from "./ChartPropertiesTable";
import { FiltersContainer, SectionContainer } from "../../../report/styled";

import { Metrics, NonSiteMetrics } from "../../../models/models";

export const Properties: FC = () => {
  const location = useLocation<{ propertyId?: number }>();
  return (
    <Report id="rtm.properties">
      <FiltersContainer>
        <PropertyFilter
          propertyIDs={
            location.state?.propertyId ? [location.state.propertyId] : undefined
          }
        />
      </FiltersContainer>
      <SectionContainer>
        <WidgetsContainer>
          <SingleNumberWidget metric={Metrics.reqPerSec} />
          <SingleNumberWidget metric={Metrics.mbPerSec} />
          <SingleNumberWidget metric={NonSiteMetrics.totalProperties} />
        </WidgetsContainer>
      </SectionContainer>
      <ChartPropertiesTable />
    </Report>
  );
};
