import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FeatureTypes } from "../../../../../../../components/molecules/FeatureType/FeatureType";
import { IDropdownItem } from "../../../../../../../components/organisms/Dropdown/Dropdown";
import { FilterFeatureTypeValue } from "../types";

const getFeatureTypes = (matchBlock: string) => {
  // The order of the FeatureTypes matters on the UI, so that's why we use
  // inline conditions through the ".filter" chain.
  const featureTypes = [
    (matchBlock === "clientReq" || matchBlock === "originReq") &&
      FeatureTypes.ACCESS_CONTROL,
    FeatureTypes.CACHE_CONTROL,
    FeatureTypes.REQUEST_MANIPULATION,
    FeatureTypes.CONTENT_MANIPULATION,
    matchBlock === "originReq" && FeatureTypes.ORIGIN_SELECTION,
    (matchBlock === "clientReq" || matchBlock === "originReq") &&
      FeatureTypes.LOGGING,
    FeatureTypes.LUA,
  ].filter(Boolean) as FeatureTypes[];

  return featureTypes;
};

export const useFeatureType = (matchBlock: string) => {
  const { t } = useTranslation("configurationMatchRulesPage");

  const [searchValue, setSearchValue] = useState("");

  const defaultDropdownItem: IDropdownItem<FilterFeatureTypeValue> = {
    label: t("FEATURES_TAB_FILTER_DROPDOWN_ALL"),
    value: "all",
    default: true,
    dataTestId: "match-rule-features-dropdown-all",
  };

  const dropdownItems = [defaultDropdownItem].concat(
    getFeatureTypes(matchBlock).map((featureType) => {
      return {
        label: featureType,
        value: featureType,
        default: false,
        dataTestId: `match-rule-features-dropdown-${featureType
          .toLowerCase()
          .replace(" ", "-")}`,
      };
    })
  );

  const [selectedFeatureType, setSelectedFeatureType] = useState<
    IDropdownItem<FilterFeatureTypeValue>
  >(defaultDropdownItem);

  return {
    dropdownItems,
    searchValue,
    selectedFeatureType,
    setSearchValue,
    setSelectedFeatureType,
  };
};
