import { useContext } from "react";

import { HeaderContext } from "../../../contexts/headerContext";
import { OnScrollEventDetails } from "../../../hooks/use-scroll-layout";
import { useAppDispatch, useAppSelector } from "../../types";
import { handleToggleHeader } from "./thunks";
import { HeaderSlice } from "./types";

export const useHeader = (): HeaderSlice =>
  useAppSelector((store) => store.header);

export const useDispatchHeaderHide = (): ((
  event: OnScrollEventDetails
) => void) => {
  const dispatch = useAppDispatch();
  const { headerRef } = useContext(HeaderContext);

  return (event: OnScrollEventDetails) => {
    dispatch(handleToggleHeader(event, headerRef));
  };
};
