import { buildAccessGroupOptions } from "../../../../../components/organisms/SubHeader/BasicSubHeader/helpers";

import { useAccessGroups } from "../../../../../store/slices/access-groups/hooks";
import { ApiKeysCustomerDropdown } from "../ApiKeysCustomerDropdown";
import { ApiKeysCustomerFilter } from "../types";

interface CdnApikeysDropdownProps {
  accessGroupFilter: ApiKeysCustomerFilter;
  handleSelectAccessGroup: (value: ApiKeysCustomerFilter) => void;
  isFilter?: boolean;
  isVyvx: boolean;
}
export const CdnApikeysDropdown = ({
  accessGroupFilter,
  handleSelectAccessGroup,
  isFilter = false,
  isVyvx,
}: CdnApikeysDropdownProps): JSX.Element => {
  const accessGroups = useAccessGroups();

  const accessGroupDropdownItems = Object.values(accessGroups).map(
    (accessGroupItem) => {
      return {
        text: accessGroupItem.name,
        value: accessGroupItem.id,
        selected: false,
        open: true,
        children:
          accessGroupItem.list && buildAccessGroupOptions(accessGroupItem.list),
      };
    }
  );

  return (
    <ApiKeysCustomerDropdown
      customerFilter={accessGroupFilter}
      onCustomerChange={handleSelectAccessGroup}
      customerDropdownItems={accessGroupDropdownItems}
      isVyvx={isVyvx}
      isFilter={isFilter}
    />
  );
};
