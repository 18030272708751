import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, IconProps, Icons } from "../Icon/Icon";

export const WarningIcon = (props: Omit<IconProps, "name">): ReactElement => (
  <WarningIconBase {...props} name={Icons.WARNING} />
);
const WarningIconBase = styled(Icon)`
  * {
    fill: ${({ theme }) => theme.text.warning};
  }
`;
