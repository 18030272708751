import styled from "@emotion/styled";
import { ReactElement } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { IFromMatchRule } from "../../../../@types/matchRules";
import { useQuery } from "../../../../hooks/use-query";
import { MatchBlocks } from "../../../../models/configuration/definitions/matchlogic";
import { BreadcrumbArrow } from "../../../atoms/BreadcrumbArrow/BreadcrumbArrow";
import { BreadcrumbLabel } from "../../../atoms/BreadcrumbLabel/BreadcrumbLabel";
import { FlexBox } from "../../../atoms/FlexBox/FlexBox";
import { Breadcrumbs } from "../../../molecules/Breadcrumbs/Breadcrumbs";
import { BasicSubHeader } from "../BasicSubHeader/BasicSubHeader";

enum CMV3Modules {
  CONFIGURATIONS = "configurations",
  CERTIFICATES = "certificates",
  PRODUCTION = "production",
  ENVIRONMENT_HISTORY = "environment_history",
  SUBSCRIBER_SETTINGS = "subscriber_settings",
}

const MapMatchBlockToDisplay: Record<MatchBlocks, string> = {
  [MatchBlocks.CLIENT_REQ]: "Client Request",
  [MatchBlocks.ORIGIN_REQ]: "Origin Request",
  [MatchBlocks.ORIGIN_RESP]: "Origin Response",
};

const DISPLAY_BREADCRUMB_MODULE = [CMV3Modules.CONFIGURATIONS];

export const SubHeaderCaching = (): ReactElement => {
  const match = useRouteMatch<{
    version: string;
    module: CMV3Modules;
    name: string;
    submodule: string;
    subname: string;
  }>([
    "/:version/:module/:name/:submodule/:subname",
    "/:version/:module/:name/:submodule",
    "/:version/:module/:name",
  ]);

  let version = "",
    module = "",
    name = "",
    submodule = "",
    subname = "";

  if (match) {
    version = match.params.version;
    module = match.params.module;
    name = match.params.name;
    submodule = match.params.submodule;
    subname = match.params.subname;
  }

  const [matchBlock] = useQuery<MatchBlocks>("matchBlock");
  const [matchGroup] = useQuery("matchGroup");
  const [matchRule] = useQuery("matchRule");

  const history = useHistory();

  return (
    <Container>
      <BasicSubHeader redirectPath="/cmv3/production" />
      {module && DISPLAY_BREADCRUMB_MODULE.includes(module as CMV3Modules) && (
        <BreadcrumbsContainer>
          <BreadcrumbArrow />
          <Breadcrumbs>
            <BreadcrumbLabel
              onClick={() => {
                history.push(`/${version}/${module}/${name}/${submodule}`);
              }}
              data-testid="sub-header-name"
            >
              {name}
            </BreadcrumbLabel>
            {subname && (
              <BreadcrumbLabel
                onClick={() => {
                  history.push(
                    `/${version}/${module}/${name}/${submodule}/${subname}`,
                    matchBlock
                      ? ({
                          fromMatchRule: {
                            matchBlock,
                            matchGroup: Number(matchGroup),
                            matchRule,
                          },
                        } as IFromMatchRule)
                      : undefined
                  );
                }}
              >
                {subname}
              </BreadcrumbLabel>
            )}
            {matchBlock && (
              <BreadcrumbLabel
                onClick={() => {
                  history.push(
                    `/${version}/${module}/${name}/${submodule}/${subname}`,
                    {
                      fromMatchRule: {
                        matchBlock,
                        matchGroup: Number(matchGroup),
                        matchRule,
                      },
                    } as IFromMatchRule
                  );
                }}
              >
                {MapMatchBlockToDisplay[matchBlock]}
              </BreadcrumbLabel>
            )}
            {[
              { data: matchGroup, label: "Group" },
              { data: matchRule, label: "Match Rule" },
            ].map(({ data, label }) => {
              let display: number | undefined;
              if (data) {
                display = parseInt(data, 10);
                if (!Number.isNaN(display)) {
                  display += 1;
                }
                return (
                  <BreadcrumbLabel clickable={false}>
                    {`${label} ${display}` || label}
                  </BreadcrumbLabel>
                );
              }
            })}
          </Breadcrumbs>
        </BreadcrumbsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
`;

const BreadcrumbsContainer = styled(FlexBox)`
  padding: ${({ theme }) => `1rem ${theme.spacings.pageContainer} 0 0`};
`;
