import { FeatureFlagHeader, FeatureFlagsStatus } from "../../../models/models";

const setEnableForFormat = (list: string) => list.split(",").map(Number);

export type FeatureFlagsReducerAction =
  | {
      type: "UPDATE_NAME";
      payload: { name: string };
    }
  | {
      type: "UPDATE_STATUS";
      payload: { status: FeatureFlagsStatus };
    }
  | {
      type: "UPDATE_USERS";
      payload: { users: string };
    }
  | {
      type: "UPDATE_SUBSCRIBERS";
      payload: { subscribers: string };
    }
  | {
      type: "UPDATE_ACCESS_GROUP";
      payload: { accessGroups: string };
    }
  | {
      type: "UPDATE_API_KEYS";
      payload: { apiKeys: string };
    }
  | {
      type: "UPDATE_INTERNALS_ONLY";
      payload: { internalsOnly: boolean };
    };

export const defaultFeatureFlagHeader: FeatureFlagHeader = {
  name: "",
  status: FeatureFlagsStatus.ENABLED,
  enabledFor: {},
};

export const featureFlagsReducer = (
  state: FeatureFlagHeader,
  action: FeatureFlagsReducerAction
): FeatureFlagHeader => {
  switch (action.type) {
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.payload.name,
      };
    case "UPDATE_STATUS":
      return {
        ...state,
        status: action.payload.status,
      };
    case "UPDATE_USERS":
      return {
        ...state,
        enabledFor: {
          ...state.enabledFor,
          users: setEnableForFormat(action.payload.users),
        },
      };
    case "UPDATE_SUBSCRIBERS":
      return {
        ...state,
        enabledFor: {
          ...state.enabledFor,
          subscribers: setEnableForFormat(action.payload.subscribers),
        },
      };
    case "UPDATE_ACCESS_GROUP":
      return {
        ...state,
        enabledFor: {
          ...state.enabledFor,
          accessGroups: setEnableForFormat(action.payload.accessGroups),
        },
      };
    case "UPDATE_API_KEYS":
      return {
        ...state,
        enabledFor: {
          ...state.enabledFor,
          apiKeys: setEnableForFormat(action.payload.apiKeys),
        },
      };
    case "UPDATE_INTERNALS_ONLY":
      return {
        ...state,
        enabledFor: {
          ...state.enabledFor,
          internalsOnly: action.payload.internalsOnly,
        },
      };
    default:
      throw new Error(`Unknown action ${action}`);
  }
};
