import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { ProductionSlotCard } from "../../molecules/ProductionSlotCard/ProductionSlotCard";
import { ProductionSlot } from "../../../models/configuration";
import { IProductionConfigurationData } from "../../../models/configuration/production";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { ExpandableContent } from "../../molecules/ExpandableContent/ExpandableContent";
import {
  IProductionHeaderProps,
  ProductionHeader,
} from "../../molecules/ProductionHeader/ProductionHeader";
import { Icon, Icons } from "../../atoms/Icon/Icon";

interface IProductionSlot extends IProductionHeaderProps {
  current?: IProductionConfigurationData;
  candidate?: IProductionConfigurationData & { percentActive: number };
  onUpdateProductionData: () => void;
  isMigration: boolean;
}

export const ProductionSlots: React.FC<IProductionSlot> = ({
  current,
  candidate,
  deploymentState,
  onUpdateProductionData,
  isMigration,
}) => {
  const { t } = useTranslation("productionPage");

  return (
    <Container data-testid="production-slots">
      <ProductionHeader deploymentState={deploymentState} />
      <ExpandableContent
        title={t("PRODUCTION_SLOTS_DESCRIPTION_TITLE")}
        content={t("PRODUCTION_SLOTS_DESCRIPTION_CONTENT")}
      />
      {isMigration && (
        <MigrationContainer data-testid="production-migration-container">
          <Line>
            <InfoIcon name={Icons.INFO} />
            <MigrationHelperLabel>
              {t("PRODUCTION_SLOTS_CARD_TITLE")}
            </MigrationHelperLabel>
          </Line>
          <Line>
            <CustomRemark>
              {t("PRODUCTION_SLOTS_CARD_DESCRIPTION")}
            </CustomRemark>
          </Line>
        </MigrationContainer>
      )}
      <SlotsContainer>
        <Slot
          width={candidate && !isMigration ? "50%" : "100%"}
          data-testid="production-slots-current"
        >
          <ProductionSlotCard
            isMigration={isMigration}
            slot={
              isMigration ? ProductionSlot.CANDIDATE : ProductionSlot.CURRENT
            }
            currentConfigData={current}
            candidateConfigData={candidate}
            activationPercent={
              candidate
                ? isMigration
                  ? candidate.percentActive
                  : 100 - candidate.percentActive
                : 100
            }
            onUpdate={onUpdateProductionData}
            version={
              (isMigration ? candidate?.versionId : current?.versionId) ?? 0
            }
          />
        </Slot>
        {candidate && !isMigration && (
          <Slot
            marginLeft="24px"
            width="50%"
            data-testid="production-slots-candidate"
          >
            <ProductionSlotCard
              slot={ProductionSlot.CANDIDATE}
              currentConfigData={current}
              candidateConfigData={candidate}
              activationPercent={candidate.percentActive}
              onUpdate={onUpdateProductionData}
              version={candidate.versionId}
            />
          </Slot>
        )}
      </SlotsContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: solid 1px ${({ theme }) => theme.borders.mutedLight};
  border-radius: 7px;
  padding: 32px;
`;

const MigrationContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.infoLight};
  color: ${({ theme }) => theme.colors.grey100};
  padding: 12px 24px;
  margin-top: 24px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
`;

const InfoIcon = styled(Icon)`
  fill: ${({ theme }) => theme.colors.navy50};
`;

const MigrationHelperLabel = styled.h5`
  margin: 0 !important;
  padding-left: 8px;
`;

const SlotsContainer = styled.div`
  display: flex;
  margin-top: 28px;
`;

const Slot = styled.div<{ marginLeft?: string; width: string }>`
  width: ${(props) => props.width};
  margin-left: ${(props) => props.marginLeft};
`;
