import { ReactElement } from "react";
import { ORDERED_DEF_NAMES } from "../../../store/slices/caching/constants";
import { DropdownButton } from "../../atoms/DropdownButton/DropdownButton";
import { Checkbox } from "../../molecules/Checkbox/Checkbox";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { HeadlessDropDown } from "../Dropdown/HeadlessDropdown";
import { ConfigName } from "./ConfigName";

interface ComparisonHeaderProps {
  autoScroll: boolean;
  config1Name: string | undefined;
  config1Version: number | undefined;
  config2Name: string | undefined;
  config2Version: number | string | undefined;
  definitionsToDisplay: typeof ORDERED_DEF_NAMES;
  setDefinitionsToDisplay: (defNames: typeof ORDERED_DEF_NAMES) => void;
  toggleAutoScroll: () => void;
}

export const ComparisonHeader = ({
  autoScroll,
  config1Name,
  config1Version,
  config2Name,
  config2Version,
  definitionsToDisplay,
  setDefinitionsToDisplay,
  toggleAutoScroll,
}: ComparisonHeaderProps): ReactElement => (
  <>
    <ConfigName>{`${config1Name} version (${config1Version})`}</ConfigName>

    <Checkbox
      id="auto-scroll"
      onChange={toggleAutoScroll}
      checked={autoScroll}
      label="Auto Scroll"
      dataTestId="auto-scroll-checkbox"
    />

    <HeadlessDropDown
      items={ORDERED_DEF_NAMES.map((def) => ({
        label: def,
        value: def,
        dataTestId: def,
      }))}
      multiSelect
      selectedItems={definitionsToDisplay.map((def) => ({
        label: def,
        value: def,
        dataTestId: def,
      }))}
      setSelectedItems={(selectedDefs) => {
        setDefinitionsToDisplay(
          (selectedDefs as IDropdownItem<
            typeof ORDERED_DEF_NAMES[number]
          >[]).map(({ value }) => value)
        );
      }}
      button={(props) => (
        <DropdownButton
          buttonprops={{ outline: true, bold: true }}
          id="filter-by-defs-dropdown-btn"
          className="chi-button chi-dropdown__trigger"
          data-testid="filter-by-defs-dropdown-btn"
          onClick={props.onClick}
        >
          Definitions
        </DropdownButton>
      )}
      position="left"
    />

    <ConfigName>{`${config2Name} version (${config2Version})`}</ConfigName>
  </>
);
