import React from "react";
import {
  IScidDropdownOption,
  ISubHeaderOption,
} from "../SubHeader/BasicSubHeader/types";
import { SubHeaderDropdown, SubHeaderDropdownState } from "./SubHeaderDropdown";

interface ISubHeaderScidDropdown {
  options: ISubHeaderOption<IScidDropdownOption>[] | undefined;
  onSelect: (option: ISubHeaderOption<IScidDropdownOption>) => void;
  open: boolean;
  isPrimaryDropdownOpen: boolean;
}

const onSearch = (
  search: string,
  optionsToFilter: ISubHeaderOption<IScidDropdownOption>[]
): ISubHeaderOption<IScidDropdownOption>[] => {
  const newArray: ISubHeaderOption<IScidDropdownOption>[] = [];
  optionsToFilter.forEach((option) => {
    if (
      option.text.toLowerCase().includes(search.toLowerCase().trim()) ||
      (option.value.csn !== undefined &&
        option.value.csn
          ?.toLowerCase()
          .includes(search.toLowerCase().trim())) ||
      option.value.ssVersion.toLowerCase().includes(search.toLowerCase().trim())
    ) {
      newArray.push(option);
      return;
    }

    const newOption = {
      ...option,
      value: {
        ...option.value,
        aliases: option.value.aliases?.filter((alias) =>
          alias.toLowerCase().includes(search.toLowerCase().trim())
        ),
      },
    };

    if (
      newOption.value.aliases !== undefined &&
      newOption.value.aliases.length > 0
    ) {
      newArray.push(newOption);
    }
  });
  return newArray;
};

export const SubHeaderScidDropdown: React.FC<ISubHeaderScidDropdown> = ({
  options,
  onSelect,
  open,
  isPrimaryDropdownOpen,
}) => {
  const offset =
    document.getElementById("sub-header-dropdown-selected-access-group") !==
    null
      ? document.getElementById("sub-header-dropdown-selected-access-group")!
          .clientWidth + 44
      : undefined;

  return (
    <SubHeaderDropdown
      searchPlaceholder="Search a property, SCID or version"
      options={options}
      onSelect={onSelect}
      open={open}
      state={SubHeaderDropdownState.SECONDARY}
      onSearch={onSearch}
      marginLeft={isPrimaryDropdownOpen ? undefined : offset + "px"}
    />
  );
};
