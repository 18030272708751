import { ConfigurationDetailsType } from "../types";

export const getMatchRules = (config: ConfigurationDetailsType) =>
  Object.entries(config.matchLogicDefinitions || {})
    .map(([, matchLogicDefinition]) => matchLogicDefinition.matchBlocks)
    .map((matchBlocks) =>
      Object.entries(matchBlocks).map(
        ([, matchBlock]) => matchBlock?.matchGroups
      )
    )
    .flat(2)
    .map((matchGroups) => matchGroups?.matchRules)
    .flat(2);
