import { AxiosError } from "axios";

import { httpClient } from "../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../error";
import { SubscriberDataNotFoundError } from "../error/SubscriberDataNotFoundError";

export enum PropertyStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
}

export interface IProperty {
  status: string;
  primaryAlias: string;
  propertyId: number;
}

export interface PropertyMetaData {
  name: string;
  status: PropertyStatus;
  primaryAlias: string;
  propertyId: number;
}

export const initPropertyMetadata = (
  name: string,
  propertyMetataData: IProperty
): PropertyMetaData => {
  return {
    name,
    status: propertyMetataData.status as PropertyStatus,
    primaryAlias: propertyMetataData.primaryAlias,
    propertyId: propertyMetataData.propertyId,
  };
};

export const getProperties = async (
  subscriberId: number
): Promise<PropertyMetaData[]> => {
  try {
    const { data } = await httpClient.get<{
      properties: { [name: string]: IProperty };
    }>(`/serviceConfiguration/v3/subscribers/${subscriberId}/properties`);
    return Object.entries(data.properties).map(([name, property]) =>
      initPropertyMetadata(name, property)
    );
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;

    // CMv3 returns a 400 with status 400015
    // when subscriber has no properties.
    if (error.response?.data.errors.some((e) => e.code === 400015)) {
      throw new SubscriberDataNotFoundError(error);
    } else {
      throw new APIError(
        error.message,
        "getProperties()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }
};
