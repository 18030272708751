import {
  IMember,
  OriginTypes,
} from "../../../../../models/configuration/definitions/origin";
import { OriginDefinitionType } from "../../types";
import { generateDefaultMember } from "../generateDefaultMember";

const addOriginMember = (
  member: IMember,
  def: Pick<OriginDefinitionType, "originType" | "members">
) => {
  if (def.originType === OriginTypes.FAILOVER && def.members.length < 3) {
    def.members.push(member);
  } else if (def.originType === OriginTypes.SINGLE) {
    throw new Error(
      "You can not add new member when the origin type is single."
    );
  }
};

export const setOriginType = (
  def: Pick<OriginDefinitionType, "originType" | "members">,
  newOriginType: OriginTypes
): Pick<OriginDefinitionType, "originType" | "members"> => {
  if (
    def.originType === OriginTypes.SINGLE &&
    newOriginType === OriginTypes.FAILOVER
  ) {
    def.originType = newOriginType;
    addOriginMember(generateDefaultMember(def.members.length), def);
  } else if (
    def.originType === OriginTypes.FAILOVER &&
    newOriginType === OriginTypes.SINGLE
  ) {
    def.originType = newOriginType;
    def.members.splice(1, def.members.length - 1);
  }

  return def;
};
