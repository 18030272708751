import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ReactComponent as RemoveSVG } from "../../../assets/icons/circle_minus_outline.svg";

export const Table = styled.div`
  display: table;
  width: 100%;
  .addbutton {
    display: inline-block;
    margin: 16px 0;
  }
`;

export const TableHeaderWrapper = styled.div`
  display: table-header-group;
`;

export const TableHeader = styled.header<{ required?: boolean }>`
  display: table-cell;
  padding: 0px 8px 8px 8px;
  text-align: center;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

export const TableBody = styled.div`
  display: table-row-group;
  width: 100%;
`;

export const Field = styled.div`
  display: table-row;
  padding-bottom: 8px;
`;

export const FieldName = styled.h6<{
  required?: boolean;
  align?: "top" | "center";
}>`
  display: table-cell;
  font-weight: 500 !important;
  padding: 0px 15px 15px 0px;
  width: 30% !important;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `};
  ${({ align }) =>
    align === "top" &&
    css`
      vertical-align: top;
    `}
`;

FieldName.defaultProps = { align: "center" };

export const FieldInput = styled.input<{
  wide?: boolean;
  centered?: boolean;
}>`
  display: table-cell;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;
  ${({ centered }) =>
    centered &&
    css`
      vertical-align: middle;
    `}
`;

FieldInput.defaultProps = { className: "chi-input" };

export const TableCell = styled.div<{
  centered?: boolean;
  bottomPadding?: "thin" | "thick";
  leftPadding?: boolean;
  rightPadding?: boolean;
}>`
  display: table-cell;
  ${({ centered }) =>
    centered &&
    css`
      vertical-align: middle;
    `}
  ${({ bottomPadding }) =>
    bottomPadding === "thin"
      ? css`
          padding-bottom: 8px;
        `
      : css`
          padding-bottom: 15px;
        `};
  ${({ leftPadding }) =>
    leftPadding &&
    css`
      padding-left: 4px;
    `};
  ${({ rightPadding }) =>
    rightPadding &&
    css`
      padding-right: 4px;
    `};
`;

export const Remove = styled(RemoveSVG)`
  display: flex;
  align-items: center;
  margin-left: 8px;
  width: 1em !important;
  height: 1em !important;
  path {
    fill: ${({ theme }) => theme.text.error};
  }
`;

export const AddButtonContainer = styled.div`
  margin-bottom: 15px;
`;

export const AddButton = styled.a`
  margin: 5px 0px !important;
  color: ${({ theme }) => theme.text.highlight};
  cursor: pointer;
`;

AddButton.defaultProps = { className: "addbutton" };

export const BodyContainer = styled.div`
  display: flex;
`;

export const LeftSection = styled.h5`
  margin: 5px 30px 0px 0px !important;
  white-space: nowrap;
`;

export const ErrorContainer = styled.div`
  margin-bottom: 10px;
`;
