import {
  ConfigDetailsResponseData,
  ConfigurationDetailsType,
  CreateOriginDefinitionType,
} from "../types";
import { createGeneratedResponseDefinition } from "./generated-response-definition/createGeneratedResponseDefinition";
import { createHeaderDefinition } from "./header-definition/createHeaderDefinition";
import { createLuaDefinition } from "./lua-definition/createLuaDefinition";
import { createMatchLogicDefinition } from "./match-logic-definition/createMatchLogicDefinition";
import { createOriginDefinition } from "./origin-definition/createOriginDefinition";
import { createPropertyDefinition } from "./property-definition/createPropertyDefinition";
import { createReportingOverrideDefinition } from "./reporting-override-definition/createReportingOverrideDefinition";
import { createSimpleDefinition } from "./simple-definition/createSimpleDefinition";
import { createTokenDefinition } from "./token-definition/createTokenDefinition";

export const parseConfigurationDetails = (
  configuration: ConfigDetailsResponseData
): ConfigurationDetailsType => {
  const parsedConfigDetail: ConfigurationDetailsType = {
    genSchemaVersion: configuration.genSchemaVersion,
    subscriberId: configuration.subscriberId,
    generatedResponseDefinitions: [],
    headerDefinitions: [],
    luaDefinitions: [],
    matchLogicDefinitions: [],
    originFillPolicyDefinitions: [],
    propertyDefinitions: [],
    reportingOverrideDefinitions: [],
    simpleListDefinitions: [],
    tokenAuthenticationDefinitions: [],
    unhandledFields: [],
  };

  if (configuration.generatedResponseDefinitions) {
    parsedConfigDetail.generatedResponseDefinitions = Object.entries(
      configuration.generatedResponseDefinitions
    ).map(([name, definition]) =>
      createGeneratedResponseDefinition(name, definition)
    );
  }

  if (configuration.headerDefinitions) {
    parsedConfigDetail.headerDefinitions = Object.entries(
      configuration.headerDefinitions
    ).map(([name, definition]) => createHeaderDefinition(name, definition));
  }

  if (configuration.luaDefinitions) {
    parsedConfigDetail.luaDefinitions = Object.entries(
      configuration.luaDefinitions
    ).map(([name, definition]) => createLuaDefinition(name, definition));
  }

  if (configuration.matchLogicDefinitions) {
    parsedConfigDetail.matchLogicDefinitions = Object.entries(
      configuration.matchLogicDefinitions
    ).map(([name, definition]) => createMatchLogicDefinition(name, definition));
  }

  if (configuration.originFillPolicyDefinitions) {
    parsedConfigDetail.originFillPolicyDefinitions = Object.entries(
      configuration.originFillPolicyDefinitions
    ).map(([name, definition]) =>
      createOriginDefinition(
        name,
        (definition as unknown) as CreateOriginDefinitionType
      )
    );
  }

  if (configuration.propertyDefinitions) {
    parsedConfigDetail.propertyDefinitions = Object.entries(
      configuration.propertyDefinitions
    ).map(([name, definition]) => createPropertyDefinition(name, definition));
  }

  if (configuration.reportingOverrideDefinitions) {
    parsedConfigDetail.reportingOverrideDefinitions = Object.entries(
      configuration.reportingOverrideDefinitions
    ).map(([name, definition]) =>
      createReportingOverrideDefinition(name, definition)
    );
  }

  if (configuration.simpleListDefinitions) {
    parsedConfigDetail.simpleListDefinitions = Object.entries(
      configuration.simpleListDefinitions
    ).map(([name, definition]) => createSimpleDefinition(name, definition));
  }

  if (configuration.tokenAuthenticationDefinitions) {
    parsedConfigDetail.tokenAuthenticationDefinitions = Object.entries(
      configuration.tokenAuthenticationDefinitions
    ).map(([name, definition]) => createTokenDefinition(name, definition));
  }

  Object.entries(configuration).map(([key, value]) => {
    if (
      !Array.from(Object.keys(parsedConfigDetail)).find((item) => item === key)
    ) {
      parsedConfigDetail.unhandledFields.push([key, value]);
    }
  });

  return parsedConfigDetail;
};
