import { ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, IconProps, Icons } from "../Icon/Icon";

export const InfoIcon = (props: Omit<IconProps, "name">): ReactElement => (
  <InfoIconBase {...props} name={Icons.INFO_OUTLINE} />
);
const InfoIconBase = styled(Icon)`
  * {
    fill: ${({ theme }) => theme.borders.info};
  }
`;
