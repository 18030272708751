import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { GeneratedResponseDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createGeneratedResponseDefinition = (
  name: string,
  rest: Pick<
    GeneratedResponseDefinitionType,
    "description" | "headers" | "mimeType" | "payload" | "reason" | "statusCode"
  >
): GeneratedResponseDefinitionType => ({
  ...parseDefinition({
    name,
    description: rest.description,
    type: TypeDefinitions.GENERATED_RESPONSE,
  }),
  headers: rest.headers,
  mimeType: rest.mimeType,
  payload: rest.payload,
  reason: rest.reason,
  statusCode: rest.statusCode,
});
