import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useAnchorMenu } from "../../../../../../hooks/use-anchor-menu";
import { DefinitionList } from "../../../../../../components/organisms/DefinitionList/DefinitionList";
import { MatchLogics } from "./MatchLogics";
import { AnchorMenu } from "../../../../../../components/molecules/AnchorMenu/AnchorMenu";

export const DefinitionsPage: React.FC = () => {
  const { t } = useTranslation("configurationDefinitionsPage");

  const config = {
    itemNames: [
      {
        name: t("DEFINITIONS_MAIN_ANCHOR"),
        id: "definitions",
        subItems: [
          {
            name: t("DEFINITIONS_SUB_ANCHOR_GEO_REGION_LISTS"),
            id: "geo",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_IP_LISTS"),
            id: "ip",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_STRING_LISTS"),
            id: "strings",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_CANNED_RESPONSE"),
            id: "canned_response",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_HEADER"),
            id: "headers",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_LUA_SCRIPTS"),
            id: "lua",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_ORIGIN_FILL_POLICIES"),
            id: "origin",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_REPORTING_OVERRIDES"),
            id: "overrides",
          },
          {
            name: t("DEFINITIONS_SUB_ANCHOR_TOKEN_DEFINITIONS"),
            id: "token",
          },
        ],
      },
      {
        name: t("MATCH_LOGIC_LISTS_MAIN_ANCHOR"),
        id: "match_logic",
      },
    ],
  };

  const menu = useAnchorMenu(config);

  return (
    <DefinitionsContainer>
      <AnchorMenu
        menuItems={menu}
        hasPadding={false}
        scrollId="configuration_page"
        defaultActive={0}
      />
      <Sections>
        <DefinitionList menu={menu[0]} />
        <MatchLogics menu={menu[1]} />
      </Sections>
    </DefinitionsContainer>
  );
};

const DefinitionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 2rem;

  & > div:first-child {
    top: 0;
  }
`;

const Sections = styled.div`
  width: calc(100% - 250px);
`;
