import { ReactElement } from "react";
import styled from "@emotion/styled";
import { Remark, UseRemarkOptions } from "react-remark";
import remarkBreaks from "remark-breaks";

import { Link } from "../Link/Link";

interface CustomRemarkProps extends UseRemarkOptions {
  children: string;
}

export const CustomRemark = ({
  children,
  rehypeReactOptions = { components: {} },
}: CustomRemarkProps): ReactElement => (
  <Remark
    remarkPlugins={[
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      remarkBreaks,
    ]}
    remarkToRehypeOptions={{ allowDangerousHtml: true }}
    rehypeReactOptions={{
      components: {
        a: (props: any) => <Link {...props} target="_blank" />,
        p: (props: any) => <RemarkP {...props} />,
        ul: (props: any) => <RemarkUl {...props} />,
        ...rehypeReactOptions.components,
      },
    }}
  >
    {children}
  </Remark>
);

const RemarkP = styled.p`
  font-size: 14px !important;
  margin: 4px 0 !important;

  strong {
    font-size: 14px !important;
  }
`;

const RemarkUl = styled.ul`
  font-size: 14px !important;
`;
