import { Dispatch, FC, useState } from "react";
import styled from "@emotion/styled";

import {
  FixedWidgetIds,
  MapSiteFieldsToLabel,
  Region as RegionEnum,
} from "../../../models/models";
import { Checkbox } from "../../../../../components/molecules/Checkbox/Checkbox";
import {
  BorderlessTable,
  BorderlessTableBodyRow,
  BorderlessTableHeaderRow,
} from "../../../../../components/atoms/BorderlessTable/BorderlessTable";
import {
  HeadlessDataTable,
  TableState,
} from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import {
  FieldName,
  SortColumn,
} from "../../../../../components/molecules/SortColumn/SortColumn";
import { Badge } from "../../../../../components/atoms/Badge/Badge";
import { mapOutRegion } from "./helpers";
import { TableRegion, RegionColor, TableMetrics } from "./types";
import { formatNumber } from "../../../../../utils/number";
import { MapContentActions } from "./reducer";
import { SpinningIcon } from "../../../../../components/atoms/SpinningIcon/SpinningIcon";
import { Icons } from "../../../../../components/atoms/Icon/Icon";
import { useAppDispatch } from "../../../../../store/types";
import { handleFetchHistoryForMetro } from "../../../../../store/slices/rtm/thunks";
import { removeMetroFromHistory } from "../../../../../store/slices/rtm/slice";
import { FilterFields, useFiltersContext } from "../../../context/filters";

interface DataTableProps {
  regions: TableRegion[];
  selectedRegions: RegionEnum[];
  selectedMetros: string[];
  handleUpdate: Dispatch<MapContentActions>;
  regionColors: Record<RegionEnum, RegionColor>;
  selectedMetrics: (keyof TableMetrics)[];
  tableState: TableState<TableMetrics>;
}

const MetricAndPercentage: FC<{ metric: number; percentage?: number }> = ({
  metric,
  percentage,
}) => (
  <>
    {formatNumber(metric)}
    <br />
    {percentage !== undefined && (
      <Percentage>{`${formatNumber(percentage)}%`}</Percentage>
    )}
  </>
);

export const RegionsDataTable: FC<DataTableProps> = ({
  regions,
  selectedRegions,
  handleUpdate,
  selectedMetros,
  regionColors,
  selectedMetrics,
  tableState,
}) => {
  const { sortField, sortOrder } = tableState;
  const { preferences, updateWidgetPreference } = useFiltersContext();

  const [expandedRows, setExpandedRows] = useState<number[]>(
    preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)?.openedRegions || []
  );

  const dispatch = useAppDispatch();

  return (
    <HeadlessDataTable
      data={regions}
      columns={[
        {
          dataField: "region",
          name: (
            <Checkbox
              label={<FieldName isActive={false}>Region</FieldName>}
              id=""
              onChange={(value) => {
                const _selectedRegions = value ? regions.map(mapOutRegion) : [];
                updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                  key: FilterFields.SELECTED_REGION,
                  value: _selectedRegions,
                });
                handleUpdate({
                  type: "UPDATE_SELECTED_REGIONS",
                  payload: { regions: _selectedRegions },
                });
              }}
              checked={selectedRegions.length === regions.length}
              onClick={() => {}}
            />
          ),
          formatter: (row) => {
            const { color, backgroundColor } = regionColors[row.region];
            return (
              <Checkbox
                label={
                  <Badge
                    label={row.region}
                    textColor={color}
                    color={backgroundColor}
                  />
                }
                id={`${row.region}-checkbox`}
                onChange={(value) => {
                  if (value && !selectedRegions.includes(row.region)) {
                    handleUpdate({
                      type: "ADD_SELECTED_REGION",
                      payload: { region: row.region },
                    });
                    const selectedRegionsPreferences = preferences.get(
                      FixedWidgetIds.LOCATIONS_SPLIT
                    )?.selectedRegion;
                    updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                      key: FilterFields.SELECTED_REGION,
                      value: selectedRegionsPreferences
                        ? [...selectedRegionsPreferences, row.region]
                        : [row.region],
                    });
                  } else {
                    handleUpdate({
                      type: "REMOVE_SELECTED_REGION",
                      payload: {
                        region: row.region,
                        metroList: row.metroList.map((m) => m.metro),
                      },
                    });
                    const selectedRegionsPreferences = preferences.get(
                      FixedWidgetIds.LOCATIONS_SPLIT
                    )?.selectedRegion;
                    updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                      key: FilterFields.SELECTED_REGION,
                      value: selectedRegionsPreferences
                        ? selectedRegionsPreferences.filter(
                            (region) => region !== row.region
                          )
                        : [],
                    });
                  }
                }}
                checked={selectedRegions.includes(row.region)}
                onClick={() => {}}
              />
            );
          },
        },
        ...selectedMetrics.map((metric) => ({
          sortable: true,
          dataField: metric as keyof TableMetrics,
          name: (
            <SortColumn isSorted={sortField === metric} sortOrder={sortOrder}>
              {MapSiteFieldsToLabel.get(metric)}
            </SortColumn>
          ),
          formatter: (region: TableRegion) =>
            region[metric].percentage !== undefined ? (
              <MetricAndPercentage
                metric={region[metric].value}
                percentage={region[metric].percentage}
              />
            ) : (
              formatNumber(region[metric].value)
            ),
        })),
        {
          name: "",
          formatter: (_, rowIndex) => (
            <SpinningIcon
              name={Icons.CHEVRON_DOWN}
              color="secondary"
              onClick={() => {
                setExpandedRows((eR) => {
                  if (eR.includes(rowIndex)) {
                    const newValue = eR.filter((rI) => rI !== rowIndex);
                    updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                      key: FilterFields.OPENED_REGIONS,
                      value: newValue,
                    });
                    return newValue;
                  } else {
                    const newValue = [...eR, rowIndex];
                    updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                      key: FilterFields.OPENED_REGIONS,
                      value: newValue,
                    });
                    return newValue;
                  }
                });
              }}
              isOpen={expandedRows.includes(rowIndex)}
            />
          ),
        },
      ]}
      defaultSorted={{ dataField: sortField, order: sortOrder }}
      keyField="region"
      onTableStateChanged={(ts) => {
        updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
          key: FilterFields.TABLE_STATE,
          value: ts,
        });
        handleUpdate({
          type: "UPDATE_TABLE_STATE",
          payload: {
            sortField: ts.sortField as keyof TableMetrics,
            sortOrder: ts.sortOrder,
          },
        });
      }}
      expandParams={{
        expandedRows,
        render: (row) => {
          const region = row as TableRegion;
          const regionMetroNames = region.metroList.map((rM) => rM.metro);

          return region.metroList.map((metro) => (
            <StyledTableBodyRow key={metro.metro}>
              <td>
                <Checkbox
                  label={metro.metro}
                  id={`${metro.metro}-checkbox`}
                  onChange={(value) => {
                    if (value && !selectedMetros.includes(metro.metro)) {
                      dispatch(
                        handleFetchHistoryForMetro({
                          metro: metro.metro,
                          region: region.region,
                        })
                      );
                      const selectedRegionsPreferences = preferences.get(
                        FixedWidgetIds.LOCATIONS_SPLIT
                      )?.selectedMetros;
                      updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                        key: FilterFields.SELECTED_METRO,
                        value: selectedRegionsPreferences
                          ? [...selectedRegionsPreferences, metro.metro]
                          : [metro.metro],
                      });
                      handleUpdate({
                        type: "ADD_SELECTED_METRO",
                        payload: {
                          selectedMetro: metro.metro,
                        },
                      });

                      // If all metros of this region selected, then check the
                      // region checkbox.
                      const regionSelectedMetroNames = selectedMetros.filter(
                        (m) => regionMetroNames.includes(m)
                      );
                      const shouldSelectRegion =
                        regionSelectedMetroNames.length + 1 ===
                        regionMetroNames.length;
                      if (shouldSelectRegion) {
                        handleUpdate({
                          type: "ADD_SELECTED_REGION",
                          payload: {
                            region: region.region,
                          },
                        });
                      }
                    } else {
                      dispatch(removeMetroFromHistory(metro.metro));
                      const selectedRegionsPreferences = preferences.get(
                        FixedWidgetIds.LOCATIONS_SPLIT
                      )?.selectedMetros;
                      updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                        key: FilterFields.SELECTED_METRO,
                        value: selectedRegionsPreferences
                          ? selectedRegionsPreferences.filter(
                              (m) => m !== metro.metro
                            )
                          : [],
                      });
                      handleUpdate({
                        type: "REMOVE_SELECTED_METRO",
                        payload: {
                          selectedMetro: metro.metro,
                        },
                      });
                    }
                  }}
                  checked={selectedMetros.includes(metro.metro)}
                  onClick={() => {}}
                />
              </td>
              {selectedMetrics.map((metric) => (
                <td key={metric}>
                  <MetricAndPercentage
                    metric={metro[metric].value}
                    percentage={metro[metric].percentage}
                  />
                </td>
              ))}
            </StyledTableBodyRow>
          ));
        },
      }}
      TableWrapper={BorderlessTable}
      TableHeadRow={BorderlessTableHeaderRow}
      TableBodyRow={BorderlessTableBodyRow}
    />
  );
};

const StyledTableBodyRow = styled(BorderlessTableBodyRow)`
  td {
    padding-top: 16px;
  }
  td:first-child {
    padding-left: 32px;
  }
  td:last-child {
    padding-right: 0;
  }
`;

const Percentage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.text.secondary};
  margin-top: 4px;
`;
