import { ReactElement, useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckSVG } from "../../../assets/icons/ringed-check.svg";
import { ReactComponent as CrossSVG } from "../../../assets/icons/ringed-cross.svg";

import {
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../store/slices/caching/types";
import { ListItem } from "../../atoms/ListItem/ListItem";
import { Infos, InfosProps } from "./Infos";

export interface PropertyListItemProps
  extends Omit<InfosProps, "propertyName"> {
  index?: number;
  name: string;
  property: PropertyDefinitionType;
  originDefinition: OriginDefinitionType;
}

export const PropertyListItem = ({
  name,
  index: titleIndex,
  property,
  originDefinition,
  info,
  onEdit,
  onDelete,
  dataTestId,
  isInRegistry,
}: PropertyListItemProps): ReactElement => {
  const [title, setTitle] = useState(name);
  const [index, setIndex] = useState(titleIndex);
  const [hover, setHover] = useState(false);
  const { t } = useTranslation("configurationPropertiesPage");

  const properties = [
    {
      name: t("PROPERTY_CARD_PROPERTY_NAME_LABEL"),
      value: `${property.name}`,
    },
    {
      name: t("PROPERTY_CARD_ORIGIN_HOST_NAME_LABEL"),
      value: originDefinition.members
        ? originDefinition.members[0].origin.host
        : "N/A",
    },
    {
      name: t("PROPERTY_CARD_DETAILS_LABEL"),
      value: [
        {
          text: t("PROPERTY_CARD_DETAILS_MATCH_RULES_LABEL"),
          activated: property.matchLogic
            ? property.matchLogic?.length !== 0
            : false,
        },

        {
          text: t("PROPERTY_CARD_DETAILS_ALIAS_OVERRIDES_LABEL"),
          activated:
            property.aliasOverrides && property.aliasOverrides.length > 0
              ? true
              : false,
        },
      ],
    },
  ];

  useEffect(() => {
    setTitle(name);
  }, [name]);

  useEffect(() => {
    setIndex(titleIndex);
  }, [titleIndex]);

  return (
    <PropertyCardContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-testid={dataTestId}
    >
      <Header>
        <TitleContainer>
          {index && <Index>{index}.</Index>}
          <Title>{title}</Title>
        </TitleContainer>
        {hover && (
          <Infos
            dataTestId={dataTestId}
            info={info}
            isInRegistry={isInRegistry}
            propertyName={property.name}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )}
      </Header>
      <Properties>
        {properties.map((p) => (
          <Col key={p.name}>
            <PropertyName>{p.name}</PropertyName>
            {Array.isArray(p.value) ? (
              <DetailsContainer>
                {p.value.map((detail) => (
                  <Detail activated={detail.activated} key={detail.text}>
                    <ActivationIcon className="-text--bold">
                      {detail.activated ? <Check /> : <Cross />}
                    </ActivationIcon>
                    <DetailName className="-text--bold">
                      {detail.text}
                    </DetailName>
                  </Detail>
                ))}
              </DetailsContainer>
            ) : (
              <PropertyValue className="-text--bold">{p.value}</PropertyValue>
            )}
          </Col>
        ))}
      </Properties>
    </PropertyCardContainer>
  );
};

const PropertyCardContainer = styled(ListItem)`
  padding: 24px;
  cursor: default;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h5``;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
`;

const Detail = styled.div<{ activated: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  ${({ activated, theme }) => css`
    fill: ${activated ? "inherit" : theme.icon.secondary};
    color: ${activated ? "inherit" : theme.text.muted};
  `}
`;

const Index = styled.h5`
  display: inline-block;
  margin-right: 16px !important;
  color: ${({ theme }) => theme.text.highlight};
`;

const Properties = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-wrap: wrap;
`;

const PropertyName = styled.span`
  color: #65686c;
`;

const PropertyValue = styled.span`
  display: inline-block;
  margin-top: 6px;
  color: ${({ theme }) => theme.text.primary};
`;

const Check = styled(CheckSVG)`
  height: 1em;

  * {
    fill: ${({ theme }) => theme.text.primary};
  }
`;

const Cross = styled(CrossSVG)`
  height: 1em;
`;

const ActivationIcon = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DetailName = styled.span`
  margin-left: 0.4em;
`;
