import type { ReactElement } from "react";
import type { UseFormSetValue } from "react-hook-form";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import {
  CachePolicy,
  CCHOExt,
} from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { isString } from "../../../../../../../../../../utils/string";
import { VALID_STRINGS } from "./constants";
import { ExtNonTTL } from "./ExtNonTTL";
import { ExtTTL } from "./ExtTTL";
import type { FormProps } from "./types";

interface ExternalPolicyTypeProps extends FormProps {
  ext: CCHOExt;
  numberErrorMessage?: string;
  handleCreateFeature: UseFormSetValue<IFeatures>;
}

export const ExternalPolicyType = ({
  disabled,
  ext,
  handleCreateFeature,
  maxWidth,
  numberErrorMessage,
  register,
  unregister,
  t,
  tMisc,
}: ExternalPolicyTypeProps): ReactElement => {
  const nonTTLChecked = isString(ext.cachePolicy, VALID_STRINGS);

  return (
    <>
      <PropertyDefinitionCard
        title={t("FEATURE_CARD_CCHO_ECP_TYPE_TITLE")}
        additionalInfoTitle={t("FEATURE_CARD_CCHO_ECP_TYPE_DESCRIPTION_TITLE")}
        additionalInfoContent={t(
          "FEATURE_CARD_CCHO_ECP_TYPE_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.OtherType}
        fieldProps={{
          other: (
            <RadioButtonsSelector
              inline
              headlessRadio={true}
              radioButtonsProps={[
                {
                  disabled,
                  label: t("FEATURE_CARD_CCHO_CP_TYPE_RADIO_NUMBER_LABEL"),
                  id: "ccho-ext-number",
                  dataTestId: "ccho-ecp-type-radio-number",
                  checked: !nonTTLChecked,
                  onChange: () => {
                    handleCreateFeature(
                      "cacheControlHeaderOverride.ext.cachePolicy",
                      ""
                    );
                  },
                },
                {
                  disabled,
                  label: t("FEATURE_CARD_CCHO_CP_TYPE_RADIO_STRING_LABEL"),
                  id: "ccho-ext-string",
                  dataTestId: "ccho-ecp-type-radio-string",
                  checked: nonTTLChecked,
                  onChange: () => {
                    // "cachePolicy" registered as a number input field. So we
                    // need to unregister it before setting a string value.
                    unregister("cacheControlHeaderOverride.ext.cachePolicy");
                    handleCreateFeature(
                      "cacheControlHeaderOverride.ext.cachePolicy",
                      CachePolicy.NO_CACHE
                    );
                  },
                },
              ]}
            />
          ),
        }}
        divider
        dataTestId="ccho-ecp-type"
        required
      />

      {nonTTLChecked ? (
        <ExtNonTTL register={register} disabled={disabled} t={t} />
      ) : (
        <ExtTTL
          disabled={disabled}
          errorMessage={numberErrorMessage}
          maxWidth={maxWidth}
          register={register}
          t={t}
          tMisc={tMisc}
        />
      )}

      <PropertyDefinitionCard
        title={t("FEATURE_CARD_CCHO_FORCE_TITLE")}
        additionalInfoTitle={t("FEATURE_CARD_CCHO_FORCE_DESCRIPTION_TITLE")}
        additionalInfoContent={t("FEATURE_CARD_CCHO_FORCE_DESCRIPTION_CONTENT")}
        fieldType={FieldType.CheckboxType}
        fieldProps={{
          disabled,
          label: t("FEATURE_CARD_CCHO_FORCE_CHECKBOX_LABEL"),
          checked: ext.force,
          onChange: (force) => {
            handleCreateFeature("cacheControlHeaderOverride.ext.force", force);
          },
          dataTestId: "ccho-force",
        }}
        dataTestId="ccho-force"
      />
    </>
  );
};
