import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { HeadlessDataTable } from "../../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { FieldName } from "../../../../../../components/molecules/SortColumn/SortColumn";
import { FeatureFlagsData, FeatureFlagHeader } from "../../../models/models";
import { DeleteFeatureFlagsModal } from "./DeleteFeatureFlagsModal";
import { FeatureFlagsModal } from "./FeatureFlagsModal";

interface FeatureFlagsDataTableProps {
  featureFlags: FeatureFlagsData[];
  handleDeleteFeatureFlag: (
    featureFlagId: string,
    featureFlagName: string
  ) => Promise<void>;
  handleSaveFeatureFlag: (
    featureFlag: FeatureFlagHeader,
    isUpdate: boolean
  ) => Promise<void>;
  dataTestId?: string;
}

export const FeatureFlagsDataTable: React.FC<FeatureFlagsDataTableProps> = ({
  featureFlags,
  handleDeleteFeatureFlag,
  handleSaveFeatureFlag,
  dataTestId,
}) => {
  const { t } = useTranslation("featureFlagsDataTablePage");

  return (
    <HeadlessDataTable
      data={featureFlags}
      columns={[
        {
          dataField: "created_at",
          formatter: (_featureFlags, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-createdat`}>
                {new Date(_featureFlags.created_at).toLocaleString("en-GB", {
                  timeStyle: "long",
                  dateStyle: "medium",
                })}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("CREATED_DATE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "name",
          formatter: (_featureFlags, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-name`}>
                {_featureFlags.name}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("NAME_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "status",
          formatter: (_featureFlags, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-status`}>
                {_featureFlags.status}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("STATUS_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "enabledFor",
          formatter: (_featureFlags, index) => {
            return (
              <SingleLineLabel
                data-testid={`${dataTestId}-${index}-enabledfor`}
              >
                {_featureFlags.enabledFor.internalsOnly ? "Yes" : "No"}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("INTERN_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },

        {
          dataField: "updated_at",
          formatter: (_featureFlags, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-updatedat`}>
                {_featureFlags.updated_at
                  ? new Date(_featureFlags.updated_at).toLocaleString("en-GB", {
                      timeStyle: "long",
                      dateStyle: "medium",
                    })
                  : t("NO_UPDATE")}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("LAST_UPDATE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "deleted_at",
          formatter: (_featureFlags, index) => {
            return (
              <IconLineLabel>
                <FeatureFlagsModal
                  handleSaveFeatureFlag={handleSaveFeatureFlag}
                  featureFlagsHeader={_featureFlags}
                  isUpdate
                  dataTestId={`${dataTestId}-${index}`}
                />
                <IconWrapper>
                  <DeleteFeatureFlagsModal
                    featureFlagName={_featureFlags.name}
                    onDelete={() =>
                      handleDeleteFeatureFlag(
                        _featureFlags.id,
                        _featureFlags.name
                      )
                    }
                    dataTestId={`${dataTestId}-${index}`}
                  />
                </IconWrapper>
              </IconLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("EDIT_DELETE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
      ]}
      keyField="name"
    />
  );
};

const SingleLineLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 12px;
  padding: 4px 18px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

const StyledFieldName = styled(FieldName)`
  padding: 4px 18px;
  display: flex;
`;

const IconLineLabel = styled.div`
  display: flex;
  padding: 4px 7px;
  line-height: 16px;
  margin-left: 15px;
`;

const IconWrapper = styled.div`
  margin-left: 15px;
`;
