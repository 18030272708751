import styled from "@emotion/styled";

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const WidgetTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;
