import { APIError } from "../../../models/error";
import { useAppDispatch, useAppSelector } from "../../types";
import { setShowErrorModal } from "./slice";
import { handleAPIError } from "./thunks";
import { APIErrorState } from "./types";

export const useDispatchAPIError = (): ((error: APIError) => void) => {
  const dispatch = useAppDispatch();

  return (error: APIError) => {
    dispatch(handleAPIError(error));
  };
};

export const useDispatchSetShowErrorModal = (): (() => void) => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(setShowErrorModal(false));
  };
};

export const useAPIError = (): APIErrorState =>
  useAppSelector((store) => store.apiError);
