import { ReactElement } from "react";
import styled from "@emotion/styled";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Protected } from "../../../../../../../../../../components/atoms/Protected/Protected";
import { DeleteModal } from "../../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import {
  ICustomLogDataLiteral,
  ICustomLogDataVariable,
} from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../../../../models/permissions";
import { useIsViewMode } from "../../../../../../../../../../store/slices/permissions/hooks";
import { IValidationErrors } from "../../FeaturesList";
import { FeatureBlock } from "../FeatureBlock";
import {
  ICustomLogDataEntryProps,
  CustomLogDataEntry,
} from "./CustomLogDataEntry";

interface ICustomLogDataFeatureBlockProps
  extends Pick<ICustomLogDataEntryProps, "customLogData"> {
  initIsOpen: boolean;
  errors: IValidationErrors;
  datatestId: string;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  handleDeleteFeature: (featureName: any) => void;
  onDelete: () => void;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  maxWidth: number;
}

export const CustomLogDataFeatureBlock = ({
  initIsOpen,
  errors,
  datatestId,
  handleCreateFeature,
  onDelete,
  register,
  setError,
  clearErrors,
  maxWidth,
  customLogData,
  handleDeleteFeature,
}: ICustomLogDataFeatureBlockProps): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  const addEntry = () => {
    const newEntry: ICustomLogDataLiteral = {
      keyName: "",
      literalString: "",
    };
    let newEntries: (ICustomLogDataVariable | ICustomLogDataLiteral)[] = [];

    if (customLogData.length > 0) {
      newEntries = [...customLogData];
    }

    newEntries.push(newEntry);

    handleCreateFeature("customLogData", newEntries);
  };

  const updateEntry = (
    entry: ICustomLogDataLiteral | ICustomLogDataVariable,
    index: number
  ) => {
    handleCreateFeature(
      "customLogData",
      customLogData.map((clg, i) => (i === index ? entry : clg))
    );
  };

  const removeEntry = (index: number) => {
    handleCreateFeature(
      "customLogData",
      customLogData.filter((_, i) => i !== index)
    );

    clearErrors(`customLogData[${index}]`);
  };

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_CUSTOM_LOG_DATA_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_CUSTOM_LOG_DATA_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_CUSTOM_LOG_DATA_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          {customLogData.map((_, i) => (
            <CustomLogDataEntry
              disabled={isViewMode}
              customLogData={customLogData}
              entriesCount={customLogData.length}
              updateEntry={updateEntry}
              removeEntry={removeEntry}
              register={register}
              clearErrors={clearErrors}
              setError={setError}
              errors={errors}
              key={i}
              i={i}
              t={t}
              maxWidth={maxWidth}
              handleDeleteFeature={handleDeleteFeature}
            />
          ))}

          <Protected permissions={UserRoles.EDIT_CONFIG}>
            <AddButton
              className="chi-button -primary -flat"
              onClick={addEntry}
              type="button"
              data-testid="add-custom-log-data-details-button"
            >
              <PlusSign>+</PlusSign>
              <div>{t("FEATURE_CARD_CUSTOM_LOG_DATA_ADD_BUTTON")}</div>
            </AddButton>
          </Protected>
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName="customLogData"
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="custom-log-data"
      featureType={FeatureTypes.LOGGING}
    />
  );
};

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  border: none !important;
  cursor: pointer;
  font-size: 14px !important;

  margin: 32px 0px 0px 0px !important;
  padding: 0px 4px 0px 0px !important;

  :hover {
    opacity: 0.5;
  }
`;

const PlusSign = styled.div`
  font-size: 30px;
  font-weight: 300;
  padding: 0px 4px 4px 0px;
`;
