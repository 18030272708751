import { checkUserPermissions, UserRoles } from "../../../models/permissions";
import { useFeatureFlags } from "../../../store/slices/feature-flags/hooks";
import { ExistingFeatures } from "../../../store/slices/feature-flags/types";
import { useUserPermissions } from "../../../store/slices/permissions/hooks";

export enum PermissionStatus {
  AWAITING = "awaiting",
  AUTHORIZED = "authorized",
  DENIED = "denied",
}

export const usePermissions = ({
  permissions,
  flags,
}: {
  permissions?: UserRoles | UserRoles[];
  flags?: ExistingFeatures;
}): PermissionStatus => {
  const userPermissions = useUserPermissions();
  const { isFeatureEnabled, featureFlagsInitialized } = useFeatureFlags();

  if (
    (!!permissions && userPermissions === undefined) ||
    (!!flags && !featureFlagsInitialized)
  ) {
    return PermissionStatus.AWAITING;
  }

  if (!!permissions && !checkUserPermissions(permissions, userPermissions)) {
    return PermissionStatus.DENIED;
  }

  if (!!flags && !isFeatureEnabled(flags)) {
    return PermissionStatus.DENIED;
  }

  return PermissionStatus.AUTHORIZED;
};
