import { countries } from "./countries";

export type CountryCode = typeof countries[number]["Code"];

export interface Country {
  Code: CountryCode;
  Name: string;
}

export const getCountryFromCode = (code: CountryCode): Country => {
  return countries.find(
    (country) => country.Code.toLowerCase() === code.toLocaleLowerCase()
  )!;
};
