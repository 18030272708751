import { Dispatch, FC, useState } from "react";
import styled from "@emotion/styled";

import { FixedWidgetIds, MapSiteFieldsToLabel } from "../../../models/models";
import {
  BorderlessTable,
  BorderlessTableBodyRow,
  BorderlessTableHeaderRow,
} from "../../../../../components/atoms/BorderlessTable/BorderlessTable";
import { CheckboxLabel } from "../../../../../components/atoms/Checkbox/CheckboxLabel";
import {
  PropertySplit,
  RegionSplit,
  TableMetrics,
  TableProperty,
} from "./types";
import {
  HeadlessDataTable,
  TableState,
} from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import {
  FieldName,
  SortColumn,
} from "../../../../../components/molecules/SortColumn/SortColumn";
import { formatNumber } from "../../../../../utils/number";
import { SpinningIcon } from "../../../../../components/atoms/SpinningIcon/SpinningIcon";
import { Icons } from "../../../../../components/atoms/Icon/Icon";
import { PropertyContentActions } from "./reducer";
import { httpClient } from "../../../../../core/http-client";
import { useDispatchAPIError } from "../../../../../store/slices/api-error/hooks";
import { Checkbox } from "../../../../../components/molecules/Checkbox/Checkbox";
import { FlexBoxBase } from "../../../../../components/atoms/FlexBox/FlexBox";
import { FilterFields, useFiltersContext } from "../../../context/filters";

interface DataTableProps {
  properties: TableProperty[];
  selectedMetrics: (keyof TableMetrics)[];
  selectedRows: number[];
  tableState: TableState<TableMetrics>;
  handleUpdate: Dispatch<PropertyContentActions>;
}

const MetricAndPercentage: FC<{ metric: number; percentage?: number }> = ({
  metric,
  percentage,
}) => (
  <>
    {formatNumber(metric)}
    <br />
    {percentage !== undefined && (
      <Percentage>{`${formatNumber(percentage)}%`}</Percentage>
    )}
  </>
);

export const PropertiesDataTable: FC<DataTableProps> = ({
  properties,
  selectedMetrics,
  selectedRows,
  tableState,
  handleUpdate,
}) => {
  const { preferences, updateWidgetPreference } = useFiltersContext();
  const { sortField, sortOrder } = tableState;
  const handleMediaPortalError = useDispatchAPIError();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  return (
    <HeadlessDataTable
      data={properties}
      columns={[
        {
          dataField: "propertyName",
          name: <StyledFieldName isActive={false}>Property</StyledFieldName>,
          formatter: (property) => (
            <FlexBoxBase>
              <Checkbox
                id={property.propertyName}
                label=""
                onChange={(value) => {
                  if (value && !selectedRows.includes(property.propertyId)) {
                    handleUpdate({
                      type: "ADD_SELECTED_ROW",
                      payload: {
                        propertyId: property.propertyId,
                      },
                    });
                    const currentPreferences = preferences.get(
                      FixedWidgetIds.PROPERTIES_SPLIT
                    )?.selectedProperties;
                    updateWidgetPreference(FixedWidgetIds.PROPERTIES_SPLIT, {
                      key: FilterFields.SELECTED_PROPERTIES,
                      value: currentPreferences
                        ? [...currentPreferences, property.propertyId]
                        : [property.propertyId],
                    });
                  } else {
                    handleUpdate({
                      type: "REMOVE_SELECTED_ROW",
                      payload: {
                        propertyId: property.propertyId,
                      },
                    });
                    const currentPreferences = preferences.get(
                      FixedWidgetIds.PROPERTIES_SPLIT
                    )?.selectedProperties;
                    updateWidgetPreference(FixedWidgetIds.PROPERTIES_SPLIT, {
                      key: FilterFields.SELECTED_PROPERTIES,
                      value: currentPreferences
                        ? currentPreferences.filter(
                            (p) => p !== property.propertyId
                          )
                        : [],
                    });
                  }
                }}
                checked={selectedRows.includes(property.propertyId)}
              />
              <StyledCheckboxLabel>
                <SingleLineLabel>{property.propertyName}</SingleLineLabel>
              </StyledCheckboxLabel>
            </FlexBoxBase>
          ),
        },
        ...selectedMetrics.map((metric) => ({
          sortable: true,
          dataField: metric,
          name: (
            <SortColumn isSorted={sortField === metric} sortOrder={sortOrder}>
              {MapSiteFieldsToLabel.get(metric)}
            </SortColumn>
          ),
          formatter: (property: TableProperty) =>
            formatNumber(property[metric]),
        })),
        {
          dataField: "propertyId",
          name: "",
          formatter: (row, rowIndex) => (
            <SpinningIcon
              name={Icons.CHEVRON_DOWN}
              color="secondary"
              onClick={() => {
                if (expandedRows.includes(rowIndex)) {
                  handleUpdate({
                    type: "REMOVE_PROPERTY_SPLIT",
                    payload: { propertyID: row.propertyId },
                  });
                  setExpandedRows((eR) => eR.filter((rI) => rI !== rowIndex));
                } else {
                  httpClient
                    .get<PropertySplit>(
                      `/realTimeMonitoring/v1/properties/${row.propertyId}/current`
                    )
                    .then((res) => {
                      handleUpdate({
                        type: "UPDATE_PROPERTIES_DATA",
                        payload: {
                          propertySplits: [
                            { propertyId: row.propertyId, data: res.data },
                          ],
                        },
                      });
                    })
                    .catch(handleMediaPortalError);
                  handleUpdate({
                    type: "ADD_PROPERTY_SPLIT",
                    payload: { propertyID: row.propertyId },
                  });
                  setExpandedRows((eR) => [...eR, rowIndex]);
                }
              }}
              isOpen={expandedRows.includes(rowIndex)}
            />
          ),
        },
      ]}
      expandParams={{
        render: (row) => {
          const property = row as TableProperty;

          if (property.regions !== undefined) {
            return property.regions.map((region) => (
              <RegionAndMetrosDisplay
                key={region.region}
                region={region}
                selectedMetrics={selectedMetrics}
              />
            ));
          }
        },
        expandedRows,
      }}
      defaultSorted={{ dataField: sortField, order: sortOrder }}
      keyField="propertyName"
      onTableStateChanged={(ts) => {
        handleUpdate({
          type: "UPDATE_TABLE_STATE",
          payload: {
            sortField: ts.sortField as keyof TableMetrics,
            sortOrder: ts.sortOrder,
          },
        });
        updateWidgetPreference(FixedWidgetIds.PROPERTIES_SPLIT, {
          key: FilterFields.TABLE_STATE,
          value: ts,
        });
      }}
      TableWrapper={BorderlessTable}
      TableHeadRow={BorderlessTableHeaderRow}
      TableBodyRow={BorderlessTableBodyRow}
    />
  );
};

const RegionAndMetrosDisplay: FC<{
  region: RegionSplit;
  selectedMetrics: (keyof TableMetrics)[];
}> = ({ region, selectedMetrics }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <StyledTableBodyRow key={region.region}>
        <td>
          <CheckboxLabel>{region.region}</CheckboxLabel>
        </td>
        {selectedMetrics.map((metric) => (
          <td key={metric}>
            <MetricAndPercentage
              metric={region[metric].value}
              percentage={region[metric].percentage}
            />
          </td>
        ))}
        <td style={{ paddingRight: 16 }}>
          <SpinningIcon
            name={Icons.CHEVRON_DOWN}
            color="secondary"
            onClick={() => {
              setIsOpen((_current) => !_current);
            }}
            isOpen={isOpen}
          />
        </td>
      </StyledTableBodyRow>
      {isOpen &&
        region.metroList.map((metro) => (
          <StyledTableBodyRow key={metro.metro}>
            <td style={{ paddingLeft: 64 }}>
              <CheckboxLabel>{metro.metro}</CheckboxLabel>
            </td>
            {selectedMetrics.map((metric) => (
              <td key={metric}>
                <MetricAndPercentage
                  metric={metro[metric].value}
                  percentage={metro[metric].percentage}
                />
              </td>
            ))}
          </StyledTableBodyRow>
        ))}
    </>
  );
};

const StyledCheckboxLabel = styled(CheckboxLabel)`
  flex: 1;
  min-width: 0;
`;

const SingleLineLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 12px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

const StyledFieldName = styled(FieldName)`
  display: flex;
`;

const StyledTableBodyRow = styled(BorderlessTableBodyRow)`
  td {
    padding-top: 16px;
  }
  td:first-child {
    padding-left: 32px;
  }
  td:last-child {
    padding-right: 0;
  }
`;

const Percentage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.text.secondary};
  margin-top: 4px;
`;
