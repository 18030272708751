import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProductionData } from "../../store/slices/caching/hooks";

import { useIsMounted } from "../use-is-mounted";

export type IDeploymentData = readonly [
  string,
  string,
  string,
  string,
  number | undefined,
  (value: string) => void,
  (value: string) => void
];

export const useDeploymentConfigs = (): IDeploymentData => {
  const isMounted = useIsMounted();
  const [current, _setCurrent] = useState<string>("");
  const [candidate, _setCandidate] = useState<string>("");
  const [
    initialCurrentPercentage,
    setInitialCurrentPercentage,
  ] = useState<number>();
  const [initialCurrent, setInitialCurrent] = useState<string>("");
  const [initialCandidate, setInitialCandidate] = useState<string>("");
  const [tmisc] = useTranslation("misc");

  const productionData = useProductionData();

  const setCurrent = (value: string) => {
    _setCurrent(value);
    _setCandidate(initialCandidate);
  };

  const setCandidate = (value: string) => {
    _setCandidate(value);
    _setCurrent(initialCurrent);
  };

  useEffect(() => {
    if (productionData && isMounted.current) {
      if (productionData.slots.current) {
        _setCurrent(
          `${productionData.slots.current.sourceConfigName}
          ${tmisc("VERSION", {
            version: productionData.slots.current.sourceConfigVersionId,
          })}
         `
        );
        setInitialCurrent(
          `${productionData.slots.current.sourceConfigName}
          ${tmisc("VERSION", {
            version: productionData.slots.current.sourceConfigVersionId,
          })}`
        );
      }
      if (productionData.slots.candidate) {
        _setCandidate(
          `${productionData.slots.candidate.sourceConfigName}
               ${tmisc("VERSION", {
                 version: productionData.slots.candidate.sourceConfigVersionId,
               })}`
        );
        setInitialCandidate(
          `${productionData.slots.candidate.sourceConfigName}
                 ${tmisc("VERSION", {
                   version:
                     productionData.slots.candidate.sourceConfigVersionId,
                 })}`
        );
        setInitialCurrentPercentage(
          100 - productionData.slots.candidate.percentActive
        );
      } else {
        setInitialCurrentPercentage(100);
      }
    }
  }, [productionData, isMounted]);

  return [
    current,
    candidate,
    initialCurrent,
    initialCandidate,
    initialCurrentPercentage,
    setCurrent,
    setCandidate,
  ] as const;
};
