/* eslint-disable complexity */
import styled from "@emotion/styled";
import { ButtonHTMLAttributes, InputHTMLAttributes, ReactElement } from "react";
import type {
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";

import { UserRoles } from "../../../models/permissions";
import { getRandomID } from "../../../utils/getRandomID";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { Divider } from "../../atoms/Divider/Divider";
import { ErrorContainer } from "../../atoms/ErrorContainer/ErrorContainer";
import { Protected } from "../../atoms/Protected/Protected";
import { TrashIcon } from "../../atoms/TrashIcon/TrashIcon";
import { Dropdown, IDropdownItem } from "../../organisms/Dropdown/Dropdown";
import { Checkbox, CheckboxProps } from "../Checkbox/Checkbox";
import { ExpandableContent } from "../ExpandableContent/ExpandableContent";
import { InfoCard, InfoCardProps } from "../InfoCard/InfoCard";
import { InputField } from "../InputField/InputField";
import {
  RadioButtonsSelector,
  RadioProps,
} from "../RadioButtonsSelector/RadioButtonsSelector";
import { AdditionalInputInfo } from "./AdditionalInputInfo";

export enum FieldType {
  InputFieldType,
  CheckboxType,
  CheckboxSelectorType,
  InputWithCheckboxType,
  DropdownWithCheckboxType,
  RadioButtonsType,
  RadioWithInputType,
  DropdownType,
  OtherType,
}

export interface FieldProps {
  key?: any;
  placeholder?: string;
  label?: string;
  legend?: string;
  inline?: boolean;
  radioButtonsProps?: RadioProps<unknown>[];
  checkboxProps?: Omit<CheckboxProps<any>, "onClick">[];
  value?: any;
  hoverMessage?: string;
  checkboxValue?: boolean;
  name?: string;
  disabled?: boolean;
  checkboxDisabled?: boolean;
  inputDisabled?: boolean;
  items?: IDropdownItem<any>[];
  id?: string;
  checked?: boolean;
  other?: JSX.Element;
  info?: InfoCardProps;
  validation?: RegisterOptions;
  inputType?: InputHTMLAttributes<any>["type"];
  dropdownType?: ButtonHTMLAttributes<any>["type"];
  min?: number;
  outline?: boolean;
  dataTestId?: string;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onCheckBoxChange?: (value: boolean) => void;
  onClick?: (value: any) => void;
  onSelect?: (value: any) => void;
  onDelete?: () => void;
  register?: UseFormRegister<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

export interface PropertyDefinitionCardProps {
  title?: string;
  additionalInfoTitle?: string;
  additionalInfoContent?: string;
  additionalInputInfo?: string;
  required?: boolean;
  fieldType: FieldType;
  fieldProps: FieldProps;
  divider?: boolean;
  className?: string;
  maxWidth?: number;
  errorMessage?: string;
  dataTestId?: string;

  // Sometimes we may want to make the field "required" without showing the
  // native HTML required popup.
  asterisk?: boolean;
}

export const PropertyDefinitionCard = ({
  title,
  additionalInfoTitle,
  additionalInfoContent,
  additionalInputInfo,
  required,
  fieldType,
  fieldProps,
  divider = false,
  className,
  maxWidth,
  errorMessage,
  dataTestId,
  asterisk = false,
}: PropertyDefinitionCardProps): ReactElement => {
  const id = getRandomID();

  return (
    <>
      <PropertyDefinitionCardContainer
        className={className}
        data-testid={`property-definition-card-${dataTestId}`}
      >
        {title && (
          <LeftContainer>
            <TitleContainer>
              <Title>{title}</Title>
              {(required || asterisk) && (
                <Asterisk
                  className="chi-label__required"
                  title="Required field"
                  data-testid={`property-definition-card-${dataTestId}-asterisk`}
                >
                  *
                </Asterisk>
              )}
            </TitleContainer>
          </LeftContainer>
        )}
        <RightContainer>
          <InputContainer>
            {(() => {
              switch (fieldType) {
                case FieldType.InputFieldType:
                  return (
                    <FieldContainer maxWidth={maxWidth}>
                      <InputField
                        onChange={(value) => {
                          fieldProps.onChange
                            ? fieldProps.onChange(
                                fieldProps.inputType === "number"
                                  ? value.length > 0
                                    ? Number(value)
                                    : undefined
                                  : value
                              )
                            : () => {};
                        }}
                        info={fieldProps.hoverMessage}
                        hasBorder={true}
                        onBlur={fieldProps.onBlur}
                        placeholder={fieldProps.placeholder}
                        value={fieldProps.value}
                        disabled={fieldProps.disabled}
                        id={fieldProps.id}
                        register={fieldProps.register}
                        validation={fieldProps.validation}
                        type={fieldProps.inputType || "text"}
                        min={fieldProps.min}
                        required={required || false}
                        dataTestId={fieldProps.dataTestId}
                      />
                    </FieldContainer>
                  );
                case FieldType.CheckboxType:
                  return (
                    <Checkbox
                      id={id}
                      value={fieldProps.value}
                      onClick={() => {}}
                      onChange={fieldProps.onChange || (() => {})}
                      checked={fieldProps.checked || false}
                      disabled={fieldProps.disabled || false}
                      label={fieldProps.label || ""}
                      dataTestId={`${fieldProps.dataTestId}-checkbox`}
                    />
                  );
                case FieldType.CheckboxSelectorType:
                  return (
                    <CheckBoxFieldContainer>
                      {fieldProps.checkboxProps?.map((prop, i) => (
                        <Checkbox
                          key={i}
                          id={prop.id}
                          value={prop.value}
                          onClick={() => {}}
                          onChange={prop.onChange || (() => {})}
                          checked={prop.checked}
                          label={prop.label}
                          disabled={prop.disabled}
                          dataTestId={`${prop.dataTestId}-checkbox`}
                          popupText={prop.popupText}
                        />
                      ))}
                    </CheckBoxFieldContainer>
                  );
                case FieldType.RadioButtonsType:
                  return (
                    <RadioButtonsSelector
                      legend={fieldProps.legend || ""}
                      inline={fieldProps.inline}
                      radioButtonsProps={fieldProps.radioButtonsProps || []}
                      headlessRadio={false}
                    />
                  );
                case FieldType.InputWithCheckboxType:
                  return (
                    <ColumnContainer>
                      <InputField
                        onChange={fieldProps.onChange || (() => {})}
                        hasBorder={true}
                        placeholder={fieldProps.placeholder}
                        value={fieldProps.value}
                        disabled={fieldProps.inputDisabled}
                        dataTestId={`${fieldProps.dataTestId}-input`}
                      />
                      <Padding />
                      <Checkbox
                        id={id}
                        value={fieldProps.checkboxValue}
                        checked={fieldProps.checkboxValue}
                        onClick={() => {}}
                        onChange={() =>
                          fieldProps.onCheckBoxChange &&
                          fieldProps.onCheckBoxChange(!fieldProps.checkboxValue)
                        }
                        label={fieldProps.label || ""}
                        disabled={fieldProps.checkboxDisabled}
                        dataTestId={`${fieldProps.dataTestId}-checkbox`}
                      />
                    </ColumnContainer>
                  );
                case FieldType.DropdownType:
                  return (
                    <>
                      <Dropdown
                        key={fieldProps.key}
                        items={fieldProps.items || []}
                        placeholder={fieldProps.placeholder || ""}
                        id={fieldProps.id || ""}
                        onSelect={fieldProps.onSelect || (() => {})}
                        bold={false}
                        disabled={fieldProps.disabled}
                        outline={fieldProps.outline}
                        type={fieldProps.dropdownType}
                        setError={fieldProps.setError}
                        clearErrors={fieldProps.clearErrors}
                        onBlur={fieldProps.onBlur}
                        dataTestId={fieldProps.dataTestId}
                      />
                      <div>{fieldProps.other}</div>
                    </>
                  );
                case FieldType.OtherType:
                  return (
                    <FieldContainer maxWidth={maxWidth}>
                      {fieldProps.other}
                    </FieldContainer>
                  );
                case FieldType.DropdownWithCheckboxType:
                  return (
                    <FieldsContainer>
                      <Dropdown
                        items={fieldProps.items || []}
                        placeholder={fieldProps.placeholder || ""}
                        id={fieldProps.id || ""}
                        onSelect={fieldProps.onSelect || (() => {})}
                        bold={false}
                        disabled={fieldProps.disabled}
                        dataTestId={fieldProps.dataTestId}
                      />
                      <Padding />
                      <Checkbox
                        id={id}
                        value={fieldProps.checkboxValue}
                        checked={fieldProps.checkboxValue}
                        onClick={() => {}}
                        onChange={() =>
                          fieldProps.onCheckBoxChange &&
                          fieldProps.onCheckBoxChange(!fieldProps.checkboxValue)
                        }
                        label={fieldProps.label || ""}
                        disabled={fieldProps.disabled}
                        dataTestId={`${fieldProps.dataTestId}-checkbox`}
                      />
                    </FieldsContainer>
                  );
                case FieldType.RadioWithInputType:
                  return (
                    <InlineFieldsContainer>
                      <RadioButtonsSelector
                        legend={fieldProps.legend || ""}
                        inline={fieldProps.inline}
                        radioButtonsProps={fieldProps.radioButtonsProps || []}
                        headlessRadio={false}
                      />
                      <InlineFieldsRightPart>
                        <InputField
                          onChange={(value) => {
                            fieldProps.onChange
                              ? fieldProps.onChange(
                                  fieldProps.inputType === "number"
                                    ? value.length > 0
                                      ? Number(value)
                                      : undefined
                                    : value
                                )
                              : () => {};
                          }}
                          hasBorder={true}
                          onBlur={fieldProps.onBlur}
                          placeholder={fieldProps.placeholder}
                          value={fieldProps.value}
                          disabled={fieldProps.disabled}
                          id={fieldProps.id}
                          register={fieldProps.register}
                          validation={fieldProps.validation}
                          type={fieldProps.inputType || "text"}
                          min={fieldProps.min}
                          required={required || false}
                          dataTestId={fieldProps.dataTestId}
                          maxLength={4}
                        />
                      </InlineFieldsRightPart>
                    </InlineFieldsContainer>
                  );
              }
            })()}
            <AdditionalInputInfo>{additionalInputInfo}</AdditionalInputInfo>

            {fieldProps.onDelete && (
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <TrashIcon
                  onClick={() => fieldProps.onDelete && fieldProps.onDelete()}
                  dataTestId={`property-definition-card-${dataTestId}-delete-button`}
                />
              </Protected>
            )}
          </InputContainer>
          {errorMessage && (
            <ErrorContainer
              data-testid={`property-definition-card-${dataTestId}-error`}
            >
              <CustomRemark>{errorMessage}</CustomRemark>
            </ErrorContainer>
          )}
        </RightContainer>
      </PropertyDefinitionCardContainer>
      {(additionalInfoTitle || fieldProps.info) && (
        <Footer>
          {additionalInfoTitle && (
            <AdditionalInfoContainer>
              <ExpandableContent
                title={additionalInfoTitle}
                content={additionalInfoContent}
              />
            </AdditionalInfoContainer>
          )}
          {fieldProps.info && (
            <InfoCard
              message={fieldProps.info.message}
              theme={fieldProps.info.theme}
            />
          )}
        </Footer>
      )}
      {divider ? <Divider className="chi-divider" /> : null}
    </>
  );
};

const PropertyDefinitionCardContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;

  width: 100%;

  background-color: ${({ theme }) =>
    `${theme.backgrounds.baseLight} !important`};
`;

const CheckBoxFieldContainer = styled.div`
  display: flex;
  .checkbox-container {
    margin-right: 8px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  font-weight: 600;
  line-height: 1.5rem;
  margin-right: 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  margin: 0 !important;
  font-size: 1rem;
`;

const Asterisk = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.text.error};
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .-m--1 {
    margin-left: 0px !important;
  }
`;

const InlineFieldsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InlineFieldsRightPart = styled.div`
  margin-left: 32px;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Padding = styled.div`
  padding: 8px;
`;

Divider.defaultProps = { className: "chi-divider" };

const FieldContainer = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
  width: 100%;
`;

const Footer = styled.div`
  margin-top: 10px;
  width: 100%; ;
`;

const AdditionalInfoContainer = styled.div``;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
