import { FC } from "react";
import { animated, useSpring, config } from "react-spring";
import styled from "@emotion/styled";

import { ButtonsContainer } from "../../atoms/ButtonContainer/ButtonContainer";
import { HelpIcon } from "../HelpIcon/HelpIcon";
import {
  CardBody,
  CardHeader,
  CardTitle,
  WidgetCard,
} from "../../atoms/Card/Card";
import { usePrevious } from "../../../hooks/use-previous-value";
import { formatNumber } from "../../../utils/number";
import { useCurrentDataStatus } from "../../../store/slices/rtm/hooks";
import { SetupStatus } from "../../../store/slices/rtm/types";
import { Loader } from "../../atoms/Loader/Loader";

export interface ISingleNumberProps {
  title: string;
  value: number | undefined;
  helpMessage?: string;
  dataTestId?: string;
}

export const SingleNumber: FC<ISingleNumberProps> = ({
  title,
  value,
  helpMessage,
  dataTestId,
}) => {
  const previousNumber = usePrevious(value);
  const spring = useSpring({
    from: { val: previousNumber || 0 },
    to: { val: value },
    config: config.slow,
  });
  const currentDataStatus = useCurrentDataStatus();

  return (
    <WidgetCard data-testid={dataTestId && dataTestId}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {helpMessage && (
          <ButtonsContainer>
            <HelpIcon message={helpMessage} position="right" />
          </ButtonsContainer>
        )}
      </CardHeader>
      <CardBody as={animated.div}>
        {currentDataStatus === SetupStatus.SETUP ? (
          <LoaderContainer>
            <Loader size={20} />
          </LoaderContainer>
        ) : spring.val ? (
          spring.val.to((v) => formatNumber(v))
        ) : (
          <EmptyNumber>No data</EmptyNumber>
        )}
      </CardBody>
    </WidgetCard>
  );
};

const EmptyNumber = styled.div`
  font-weight: 800;
  color: ${({ theme }) => theme.text.mutedAlt};
`;

const LoaderContainer = styled.div`
  height: 1em;
`;
