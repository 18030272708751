import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AddButton } from "../../../../../../../../components/atoms/AddButton/AddButton";
import { Protected } from "../../../../../../../../components/atoms/Protected/Protected";
import { getDuplicateElementsWithIndexes } from "../../../../../../../../components/molecules/DefinitionModal/helpers";
import { ExpandableContent } from "../../../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { PropertyDefinitionBlock } from "../../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import { Protocols } from "../../../../../../../../models/configuration/definitions/origin";
import { PropertySection } from "../../../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../../../../../../models/configuration/errors";
import { UserRoles } from "../../../../../../../../models/permissions";
import {
  handleRemoveConfigurationError,
  handleUpdateConfigurationErrors,
} from "../../../../../../../../store/slices/caching/thunks";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../../../store/types";
import {
  getInvalidBasicHttp2EncryptConfigTranslate,
  isInvalidBasicHttp2EncryptConfig,
} from "./helpers";
import { OverridesSettings } from "./OverridesSettings";

interface AliasesSectionProps {
  property: PropertyDefinitionType;
  onPropertyChange: (property: PropertyDefinitionType) => void;
}

export const OverridesSection = ({
  property,
  onPropertyChange,
}: AliasesSectionProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");
  const dispatch = useAppDispatch();

  const isInit = useRef(true);
  const [overridesErrors, setOverridesErrors] = useState<
    Record<number, { index: number; msg: string }[]>
  >({});
  const [encryptionErrors, setEncryptionErrors] = useState<
    Record<number, string>
  >({});

  useEffect(() => {
    const errorsToAdd: IConfigurationError[] = [];
    const filterFunctions: ((err: IConfigurationError) => boolean)[] = [];

    if (property.aliasOverrides && property.aliasOverrides.length > 0) {
      property.aliasOverrides.forEach((group, index) => {
        if (group.protocolSettings.protocols.length === 0) {
          errorsToAdd.push({
            level: ConfigurationErrorLevel.ERROR,
            type: ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_PROTOCOL_EMPTY,
            data: { section: PropertySection.ALIAS_OVERRIDES, index },
          });
        } else {
          filterFunctions.push(
            (err) =>
              !(
                err.type ===
                  ConfigurationErrorType.PROPERTY_ALIAS_OVERRIDE_PROTOCOL_EMPTY &&
                err.data?.section === PropertySection.ALIAS_OVERRIDES &&
                err.data?.index === index
              )
          );
        }
      });
      dispatch(handleUpdateConfigurationErrors(errorsToAdd, filterFunctions));
      if (isInit.current) {
        isInit.current = false;
      }
    }
  }, [property.aliasOverrides]);

  const [addedBlockIndex, setAddedBlockIndex] = useState<number>();

  const addAliasOverride = () => {
    let newAliasOverrides: PropertyDefinitionType["aliasOverrides"] = [];

    const newOverride = {
      aliases: [""],
      protocolSettings: { protocols: [Protocols.HTTP] },
    };

    if (property.aliasOverrides) {
      newAliasOverrides = [...property.aliasOverrides, newOverride];
    } else {
      newAliasOverrides = [newOverride];
    }

    setAddedBlockIndex(newAliasOverrides.length - 1);

    onPropertyChange({ ...property, aliasOverrides: newAliasOverrides });
  };

  const deleteAliasOverride = (index: number) => {
    dispatch(
      handleRemoveConfigurationError(
        (err) =>
          !(
            err.data?.section === PropertySection.ALIAS_OVERRIDES &&
            err.data?.index === index
          )
      )
    );
    if (addedBlockIndex !== undefined) {
      if (addedBlockIndex === index) {
        setAddedBlockIndex(undefined);
      } else if (addedBlockIndex > index) {
        setAddedBlockIndex((value) => (value ? value - 1 : 0));
      }
    }
    onPropertyChange({
      ...property,
      aliasOverrides: property.aliasOverrides?.filter((_, i) => i !== index),
    });
  };

  useEffect(() => {
    if (property.primaryAlias && property.aliasOverrides) {
      const errorsToAdd: IConfigurationError[] = [];
      const errorsToFilter: ((config: IConfigurationError) => boolean)[] = [];
      const _overridesErrors: typeof overridesErrors = {};
      const _encryptionErrors: typeof encryptionErrors = {};

      property.aliasOverrides.forEach((aO, groupIndex) => {
        _overridesErrors[groupIndex] = _overridesErrors[groupIndex] ?? [];

        aO.aliases?.forEach((alias, aliasIndex) => {
          if (alias === property.primaryAlias) {
            _overridesErrors[groupIndex].push({
              index: aliasIndex,
              msg: t("ERROR_DUPLICATE_ALIAS"),
            });
          } else {
            const duplicates = getDuplicateElementsWithIndexes(aO.aliases!);

            if (duplicates.length > 0) {
              duplicates.forEach((duplicate) => {
                _overridesErrors[groupIndex].push({
                  index: duplicate.index,
                  msg: t("ERROR_DUPLICATE_ALIAS"),
                });
              });
            }

            // Check if the current alias is duplicate of any aliases from the prior alias groups.
            if (groupIndex > 0) {
              for (let gI = 0; gI < groupIndex; gI++) {
                const dupIndex = property.aliasOverrides?.[
                  gI
                ].aliases?.findIndex((a) => a === alias);

                if (typeof dupIndex === "number" && dupIndex > -1) {
                  _overridesErrors[groupIndex].push({
                    index: aliasIndex,
                    msg: t("ERROR_DUPLICATE_ALIAS"),
                  });
                }
              }
            }

            if (isInvalidBasicHttp2EncryptConfig(aO.protocolSettings)) {
              _encryptionErrors[groupIndex] = t(
                getInvalidBasicHttp2EncryptConfigTranslate(aO.protocolSettings)
              );
            }
          }
        });
      });

      const hasAliasErr = property.aliasOverrides.some(
        (_, groupIndex) => _overridesErrors[groupIndex]?.length > 0
      );
      if (hasAliasErr) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type:
            ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_AND_ALIAS_OVERRIDES_ARE_NOT_UNIQUE,
          data: {
            section: PropertySection.ALIAS_OVERRIDES,
          },
        });
      } else {
        errorsToFilter.push(
          (err) =>
            !(
              err.level === ConfigurationErrorLevel.ERROR &&
              err.type ===
                ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_AND_ALIAS_OVERRIDES_ARE_NOT_UNIQUE &&
              err.data?.section === PropertySection.ALIAS_OVERRIDES
            )
        );
      }

      const hasEncryptionErr = property.aliasOverrides.some(
        (_, groupIndex) => _encryptionErrors[groupIndex]?.length > 0
      );
      if (hasEncryptionErr) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type: ConfigurationErrorType.PROPERTY_ALIAS_ENCRYPTION_LEVEL_INVALID,
          data: {
            section: PropertySection.ALIAS_OVERRIDES,
          },
        });
      } else {
        errorsToFilter.push(
          (err) =>
            !(
              err.level === ConfigurationErrorLevel.ERROR &&
              err.type ===
                ConfigurationErrorType.PROPERTY_ALIAS_ENCRYPTION_LEVEL_INVALID &&
              err.data?.section === PropertySection.ALIAS_OVERRIDES
            )
        );
      }

      setOverridesErrors(_overridesErrors);
      setEncryptionErrors(_encryptionErrors);
      dispatch(handleUpdateConfigurationErrors(errorsToAdd, errorsToFilter));
    }
  }, [property.aliasOverrides, property.primaryAlias]);

  return (
    <AliasesSectionContainer>
      <Subsection>
        {t("PROPERTY_CARD_ALIASES_OVERRIDE_SECTION_TITLE")}
      </Subsection>
      <ExpandableContent
        title={t("ALIAS_OVERRIDE_GROUP_DESCRIPTION_TITLE")}
        content={t("ALIAS_OVERRIDE_GROUP_DESCRIPTION_CONTENT")}
      />
      <br />
      <AliasesForm>
        {property.aliasOverrides && property.aliasOverrides.length > 0 ? (
          <PropertyDefinitionBlock
            title={t("PROPERTY_CARD_ALIASES_OVERRIDE_GROUPS_COUNT", {
              count: property.aliasOverrides?.length || 0,
            })}
            initIsOpen
            propertyCards={
              <>
                {property.aliasOverrides?.map((_, i) => (
                  <OverridesSettings
                    key={i}
                    index={i}
                    shouldBeOpen={addedBlockIndex === i}
                    property={property}
                    onPropertyChange={onPropertyChange}
                    onDelete={() => deleteAliasOverride(i)}
                    aliasInputErrors={overridesErrors[i]}
                    encryptionError={encryptionErrors[i]}
                  />
                ))}
                <Protected permissions={UserRoles.EDIT_CONFIG}>
                  <AddButton
                    label={t(
                      "PROPERTY_SECTION_ALIASES_ADD_OVERRIDE_GROUP_BUTTON"
                    )}
                    dataTestId="add-alias-override"
                    onClick={addAliasOverride}
                    outline
                  />
                </Protected>
              </>
            }
          />
        ) : (
          <Protected permissions={UserRoles.EDIT_CONFIG}>
            <AddButton
              label={t("PROPERTY_SECTION_ALIASES_ADD_OVERRIDE_GROUP_BUTTON")}
              dataTestId="add-alias-override"
              onClick={addAliasOverride}
              outline
            />
          </Protected>
        )}
      </AliasesForm>
    </AliasesSectionContainer>
  );
};

const AliasesSectionContainer = styled.div``;

const Subsection = styled.h4``;

const AliasesForm = styled.div``;
