import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Status } from "../../../@types/status";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { useQuery } from "../../../hooks/use-query";
import {
  useAccessGroupsQueryStatus,
  useUnsafeAccessGroupId,
} from "../../../store/slices/access-groups/hooks";
import { handleAccessGroups } from "../../../store/slices/access-groups/thunks";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";

export const AccessGroups: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const [t] = useTranslation("layers");

  const [queryAccessGroupId] = useQuery("access_group_id");
  const [localStorageAccessGroupId] = useLocalStorage<string>(
    "cmv3.access_group_id"
  );

  const accessGroupsQueryStatus = useAccessGroupsQueryStatus();

  const accessGroupId = useUnsafeAccessGroupId();

  useEffect(() => {
    dispatch(
      handleAccessGroups(
        queryAccessGroupId
          ? parseInt(queryAccessGroupId, 10) || undefined
          : parseInt(localStorageAccessGroupId, 10) || undefined
      )
    );
  }, []);

  if (accessGroupId && accessGroupsQueryStatus === Status.SUCCESS) {
    return <>{children}</>;
  }

  if (accessGroupsQueryStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("ACCESS_GROUP_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (accessGroupsQueryStatus === Status.ERROR) {
    return <ErrorLayer />;
  }

  return null;
};
