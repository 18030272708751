import type { ReactElement } from "react";
import type {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import { FeatureBlock } from "../FeatureBlock";
import { ExternalPolicy } from "./ExternalPolicy";
import { InternalPolicy } from "./InternalPolicy";
import { CCHOProps } from "./types";

interface ICCHOFeatureBlockProps
  extends Pick<CCHOProps, "errors" | "maxWidth" | "register" | "unregister"> {
  cacheControlHeaderOverride: Exclude<
    IFeatures["cacheControlHeaderOverride"],
    undefined
  >;
  initIsOpen: boolean;
  datatestId: string;
  onDelete: () => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  setError: UseFormSetError<IFeatures>;
  clearErrors: UseFormClearErrors<IFeatures>;
}

export const CCHOFeatureBlock = ({
  cacheControlHeaderOverride,
  maxWidth,
  initIsOpen,
  errors,
  datatestId,
  onDelete,
  handleCreateFeature,
  register,
  unregister,
  setError,
  clearErrors,
}: ICCHOFeatureBlockProps): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");

  const int = cacheControlHeaderOverride.int;
  const ext = cacheControlHeaderOverride.ext;
  const isErr = (errors.cacheControlHeaderOverride as { message?: string })
    ?.message;

  if (!int && !ext && !isErr) {
    setError("cacheControlHeaderOverride", {
      message: t("FEATURE_CARD_CCHO_POLICY_MISSING"),
    });
  } else if ((int || ext) && isErr) {
    clearErrors("cacheControlHeaderOverride");
  }

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_CCHO_TITLE")}
      additionalInfoTitle={t("FEATURE_CARD_ADDED_CCHO_DESCRIPTION_TITLE")}
      additionalInfoContent={t("FEATURE_CARD_ADDED_CCHO_DESCRIPTION_CONTENT")}
      fields={
        <>
          <InternalPolicy
            errors={errors}
            handleCreateFeature={handleCreateFeature}
            maxWidth={maxWidth}
            policy={int}
            register={register}
            unregister={unregister}
          />

          <ExternalPolicy
            errors={errors}
            handleCreateFeature={handleCreateFeature}
            maxWidth={maxWidth}
            policy={ext}
            register={register}
            unregister={unregister}
          />
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"cacheControlHeaderOverride"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      hasErrors={!!isErr}
      dataTestId="ccho"
      featureType={FeatureTypes.CACHE_CONTROL}
    />
  );
};
