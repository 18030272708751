import { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";

import { Tag as TagDefault, TagProps } from "../../atoms/Tag/Tag";

interface HeadlessInputTagsProps {
  /** Additional keys that shoud create a tag. Space should be an empty string. */
  additonalSeperators?: string[];
  tags?: string[];
  Container?: FC;
  TagsWrapper?: FC;
  Tag?: FC<TagProps>;
  Input?: FC;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  onTagsChange: (tags: string[]) => void;
  dataTestId?: string;
}

export const HeadlessInputTag: React.FC<HeadlessInputTagsProps> = ({
  tags = [],
  additonalSeperators = [],
  Container = StyledContainer,
  TagsWrapper = StyledTagsWrapper,
  Tag = TagDefault,
  Input = StyledInput,
  placeholder,
  disabled = false,
  id,
  onTagsChange,
  dataTestId,
}) => {
  const _additionalKeys = additonalSeperators.map((key) => key.trim());
  const [_tags, setTags] = useState<string[]>(tags);
  const [inputVal, setInputVal] = useState<string>("");

  useEffect(() => {
    onTagsChange(_tags);

    // Ignore dep "onTagsChange"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_tags]);

  return (
    <Container>
      <TagsWrapper>
        {_tags.map((tag, index) => (
          <Tag
            key={tag}
            text={tag}
            onDelete={() =>
              setTags((t) =>
                t.filter((_, currentIndex) => currentIndex !== index)
              )
            }
            dataTestId={`${dataTestId}-tag`}
          />
        ))}
        <Input
          className="tag-input"
          id={id}
          type="text"
          disabled={disabled}
          onKeyUp={(e) => {
            if (
              ["Enter"].concat(_additionalKeys).includes(e.key.trim()) &&
              !!inputVal &&
              !_tags.includes(inputVal)
            ) {
              const re = new RegExp(",|;", "g");
              const newTags = inputVal.split(re);
              setTags((t) => [...t, ...newTags]);
              setInputVal("");
            }
          }}
          onChange={(e) => {
            if (
              !_additionalKeys.includes(e.target.value.slice(-1)) ||
              e.target.value === ""
            ) {
              setInputVal(
                !_additionalKeys.includes("")
                  ? e.target.value
                  : e.target.value.trim()
              );
            }
          }}
          value={inputVal}
          placeholder={placeholder || "Enter a tag name"}
          data-testid={`${dataTestId}-input-field`}
        />
      </TagsWrapper>
    </Container>
  );
};

const StyledTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div,
  & > .tag-input {
    margin-bottom: 16px;
  }

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledContainer = styled.div``;

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  color: ${({ theme }) => theme.text.primary};
`;
