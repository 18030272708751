import { httpClient } from "../../../core/http-client";
import { APIError } from "../../../models/error";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import { auth_page_link, setAccessToken, setLoading } from "./slice";

export const handleAccessToken = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));

    const refreshToken = getState().auth.refeshToken;

    if (refreshToken) {
      await httpClient
        .getAccessToken(refreshToken)
        .then((token) => dispatch(setAccessToken(token)))
        .catch((err) => {
          window.location.replace(auth_page_link);
        });
    } else {
      window.location.replace(auth_page_link);
    }
  } catch (error) {
    dispatch(
      handleAPIError(
        new APIError(
          "Unexpected error on handleAccessToken",
          "handleAccessToken"
        )
      )
    );
  } finally {
    dispatch(setLoading(false));
  }
};
