import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated } from "react-spring";

export const DropdownMenu = styled(animated.div)<{
  position?: "left" | "right";
}>`
  display: table !important;
  max-width: 200px !important;
  background-color: ${({ theme }) => theme.backgrounds.baseLight}!important;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 4px !important;
  ${({ position = "right" }) =>
    position === "left" &&
    css`
      right: 0 !important;
      left: auto !important;
    `}
`;
