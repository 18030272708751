import { ReactElement } from "react";

import {
  useDispatchSetShowErrorModal,
  useAPIError,
} from "../../../store/slices/api-error/hooks";
import { Modal } from "../../atoms/Modal/Modal";
import { ErrorModalContent } from "../ErrorModalContent/ErrorModalContent";

export const ErrorModal = (): ReactElement | null => {
  const { errorData, showErrorModal } = useAPIError();
  const dispatchSetShowErrorModal = useDispatchSetShowErrorModal();

  if (errorData) {
    return (
      <Modal
        open={showErrorModal}
        customButton={<></>}
        onClose={dispatchSetShowErrorModal}
      >
        <ErrorModalContent errorDetails={errorData} />
      </Modal>
    );
  }

  return null;
};
