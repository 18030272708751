import type { IFeatures } from "../../../../../../../../models/configuration/definitions";

export const FEATURE_NAMES: (keyof IFeatures)[] = [
  "allowCompress",
  "cacheControlHeaderOverride",
  "cacheKey",
  "customLogData",
  "defaultCache",
  "denyPolicy",
  "disableIfNoneMatch",
  "failedRefreshTTL",
  "followRedirects",
  "generatedResponse",
  "geoRestrictions",
  "ipRestrictions",
  "negativeTTL",
  "originFillPolicy",
  "qshMode",
  "reportingOverride",
  "reqHeaders",
  "reqLua",
  "respHeaders",
  "respLua",
  "serve200For416",
  "staleContentControl",
  "tokenAuthentication",
  "uriRewrite",
];
