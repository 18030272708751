import { AxiosError } from "axios";
import { httpClient } from "../../../core/http-client";
import { APIError, ErrorLevel, MediaPortalAPIError } from "../../error";
import {
  AccessGroupCreationData,
  AccessGroupDeletionCheckData,
  AccessGroupInfo,
  AccessGroupMember,
  AccessGroupProduct,
  ExternalUserPayload,
  InternalUserPayload,
  AccessGroupRole,
  InternalUserSearchResult,
} from "./access-group.interfaces";

export const getAccessGroupInfos = async (
  accessGroupId: number
): Promise<AccessGroupInfo> => {
  try {
    const response = await httpClient.get<AccessGroupInfo>(
      `/accessGroups/${accessGroupId}`
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getAccessGroupInfos()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getAccessGroupProducts = async (
  accessGroupId: number
): Promise<AccessGroupProduct[]> => {
  const response = await httpClient.get<AccessGroupProduct[]>(
    `/accessGroups/${accessGroupId}/products`
  );
  return response.data;
};

export const getAccessGroupMembers = async (
  accessGroupId: number
): Promise<AccessGroupMember[]> => {
  try {
    const response = await httpClient.get<AccessGroupMember[]>(
      `/accessGroups/${accessGroupId}/users`
    );
    return response.data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getAccessGroupMembers()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const createAccessGroup = async (
  body: AccessGroupCreationData
): Promise<AccessGroupInfo> => {
  const response = await httpClient.post("/accessGroups", body);
  return response.data;
};

export const updateAccessGroupInfo = async (
  id: number,
  body: AccessGroupCreationData
): Promise<AccessGroupInfo> => {
  try {
    const response = await httpClient.put(`/accessGroups/${id}`, body);
    return response.data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateAccessGroupInfo()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const checkAccessGroupDeletion = async (
  accessGroupId: number
): Promise<AccessGroupDeletionCheckData> => {
  const response = await httpClient.get<AccessGroupDeletionCheckData>(
    `/accessGroups/${accessGroupId}/checkDeletion`
  );
  return response.data;
};

export const deleteAccessGroup = async (id: number): Promise<void> => {
  try {
    httpClient.delete(`/accessGroups/${id}`);
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "deleteAccessGroup()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getAvailableProducts = async (
  id: number
): Promise<AccessGroupProduct[]> => {
  try {
    const { data } = await httpClient.get<AccessGroupProduct[]>(
      `/accessGroups/${id}/availableProducts`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getAvailableProducts()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const updateAccessGroupProducts = async (
  id: number,
  payload: {
    nis: string[];
    scids: string[];
  }
): Promise<void> => {
  try {
    const { scids, nis } = payload;
    httpClient.put(`/accessGroups/${id}/products`, {
      accessGroupId: id,
      scids: scids.join(","),
      nis: nis.join(","),
    });
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateAccessGroupProducts()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const addAccessGroupsUsers = async (
  id: number,
  externalUsers: ExternalUserPayload[],
  internalUsers: InternalUserPayload[]
): Promise<void> => {
  try {
    httpClient.put(`/accessGroups/${id}/users/add`, {
      internalUsers,
      externalUsers,
    });
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "addAccessGroupsUsers()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const removeAccessGroupsUsers = async (
  id: number,
  usersToRemove: number[]
): Promise<void> => {
  try {
    httpClient.put(`/accessGroups/${id}/users/delete`, {
      usersList: usersToRemove,
    });
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "removeAccessGroupsUsers()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getAvailableRoles = async (
  id: number
): Promise<AccessGroupRole[]> => {
  try {
    const { data } = await httpClient.get<AccessGroupRole[]>(
      `/accessGroups/${id}/availableRoles`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getAvailableRoles()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const updateRole = async (
  accessGroupId: number,
  userId: number,
  roleId: number
): Promise<void> => {
  try {
    httpClient.put(`/accessGroups/${accessGroupId}/users/${userId}/role`, {
      roleId,
    });
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateRole()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const searchInternalUsers = async (
  searchString: string
): Promise<InternalUserSearchResult[]> => {
  try {
    const { data } = await httpClient.get<InternalUserSearchResult[]>(
      `/users/searchInternal?search=${searchString}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "searchInternalUsers()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
