import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Status } from "../../../@types/status";

import { useConfigurationDetailsStatus } from "../../../store/slices/caching/hooks";
import { handleFetchSelectedConfigDetails } from "../../../store/slices/caching/thunks";
import { useAppDispatch } from "../../../store/types";
import { Loader } from "../../atoms/Loader/Loader";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";
import { EmptyPageDescription } from "../../atoms/EmptyPage/EmptyPageWrapper";
import { Link } from "../../atoms/Link/Link";
import { Button } from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

enum CMV3Modules {
  CONFIGURATIONS = "configurations",
  CERTIFICATES = "certificates",
  PRODUCTION = "production",
  ENVIRONMENT_HISTORY = "environment_history",
  SUBSCRIBER_SETTINGS = "subscriber_settings",
}

export const Configuration: FC = ({ children }) => {
  const status = useConfigurationDetailsStatus();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const match = useRouteMatch<{
    version: string;
    module: CMV3Modules;
    name: string;
    submodule: string;
    subname: string;
  }>([
    "/:version/:module/:name/:submodule/:subname",
    "/:version/:module/:name/:submodule",
    "/:version/:module/:name",
  ]);

  useEffect(() => {
    if (match?.params.name) {
      dispatch(handleFetchSelectedConfigDetails(match.params.name));
    }
  }, [match?.params.name]);

  const { t } = useTranslation("configurationPage");

  if (status === Status.LOADING) {
    return (
      <LoaderContainer>
        <OverridenLoader />
      </LoaderContainer>
    );
  }
  if (status === Status.ERROR) {
    return (
      <ErrorLayer
        title="Configuration not found"
        content={
          <EmptyPageDescription>
            {t("CONFIGURATION_NOT_FOUND", { name: match?.params.name })}{" "}
            <Link href="mailto:CDNsupport@lumen.com">CDNsupport@lumen.com</Link>
            <Button
              onClick={() => {
                history.push("/cmv3/configurations");
              }}
              label="Back to configurations list"
            />
          </EmptyPageDescription>
        }
      />
    );
  }

  return (
    <RoutesContainer>
      {status === Status.SUCCESS
        ? children
        : "Something went wrong on loading configuration details"}
    </RoutesContainer>
  );
};

const RoutesContainer = styled.div`
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OverridenLoader = styled(Loader)`
  position: absolute;
`;
