import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../@types/status";
import { SubscriberSettingsSlice } from "./types";

const initialState: SubscriberSettingsSlice = {
  status: Status.IDLE,
  value: null,
};

export const subscriberSettingsSlice = createSlice({
  name: "subscriberSettings",
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<SubscriberSettingsSlice["status"]>
    ) => {
      state.status = action.payload;
    },
    setSubscriberSettings: (
      state,
      action: PayloadAction<SubscriberSettingsSlice["value"]>
    ) => {
      state.value = action.payload;
    },
  },
});

export const {
  setStatus,
  setSubscriberSettings,
} = subscriberSettingsSlice.actions;

export default subscriberSettingsSlice.reducer;
