import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";
import { FeatureComponent } from "./types";

type GeoRestrictionsComponent = FeatureComponent<
  Exclude<IFeatures["geoRestrictions"], undefined>
>;

export const GeoRestrictions: GeoRestrictionsComponent = ({
  feature: { actionType, denial, ipWhitelist, matchAnonymizers, nameList },
  tPropertyPage,
  dataTestId,
}) => (
  <>
    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_GEO_REGION_TITLE")}
        dataTestId={`${dataTestId}-name-list`}
      >
        <ExpandableList dataTestId="geoRestrictions-def-names">
          {nameList.map(renderColContent)}
        </ExpandableList>
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_GEO_REGION_RESTRICTION_TITLE")}
        dataTestId={`${dataTestId}-restriction`}
      >
        {actionType}
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_GEO_REGION_MAB_TITLE")}
        dataTestId={`${dataTestId}-mab`}
      >
        {`${matchAnonymizers}`}
      </DetailedFeatureCardBlock>
    </DetailedFeatureCardRow>

    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_GEO_REGION_AOD_TITLE")}
        dataTestId={`${dataTestId}-aod`}
      >
        {denial?.action}
      </DetailedFeatureCardBlock>

      {denial?.action === "redirect" && (
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_GEO_REGION_REDIRECT_TITLE")}
          dataTestId={`${dataTestId}-redirect`}
        >
          {denial?.redirectUrl}
        </DetailedFeatureCardBlock>
      )}

      {!!(ipWhitelist && ipWhitelist.length > 0) && (
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_GEO_REGION_IP_TITLE")}
          dataTestId={`${dataTestId}-ip-list`}
        >
          <ExpandableList dataTestId="geoRestrictions">
            {ipWhitelist!.map(renderColContent)}
          </ExpandableList>
        </DetailedFeatureCardBlock>
      )}
    </DetailedFeatureCardRow>
  </>
);
