import { ReactElement, useContext, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Theme, ThemeContext } from "../../../contexts/themeContext";
import {
  DiscardCommitModal,
  DiscardCommitModalMode,
} from "../DiscardCommitModal/DiscardCommitModal";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { useAppDispatch } from "../../../store/types";
import { handleUpdateSelectedConfigurationVersion } from "../../../store/slices/caching/thunks";
import { UpdateVersionActions } from "../../../store/slices/caching/types";
import { isConfigurationLatestVersion } from "../../../store/slices/caching/helpers/isConfigurationLatestVersion";

export const ConfigurationHeaderVersionWarning = (): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const themeContext = useContext(ThemeContext);
  const [tConfigurationPage] = useTranslation("configurationPage");

  const [shouldDisplayModal, setShouldDisplayModal] = useState<boolean>(false);

  const getBackToLatestVersion = () => {
    if (selectedConfiguration) {
      if (!selectedConfiguration.isLocalDraft) {
        dispatch(handleUpdateSelectedConfigurationVersion());
        history.push("properties");
      } else {
        setShouldDisplayModal(true);
      }
    }
  };

  return (
    <>
      {selectedConfiguration &&
        !isConfigurationLatestVersion(selectedConfiguration) && (
          <VersionWarningContainer
            data-testid="configuration-header-version-warning"
            isdarkmode={themeContext.theme === Theme.DARK_MODE}
          >
            <VersionWarning>
              <AlertIcon name={Icons.ALERT} />
              {tConfigurationPage("CONFIGURATION_VERSION_WARNING_LABEL", {
                version: selectedConfiguration.versionId,
              })}
              <GetBackContainer
                onClick={getBackToLatestVersion}
                data-testid="configuration-header-version-warning-go-back"
              >
                {tConfigurationPage(
                  "CONFIGURATION_VERSION_WARNING_GET_BACK_LABEL"
                )}
              </GetBackContainer>
            </VersionWarning>
          </VersionWarningContainer>
        )}
      {shouldDisplayModal && (
        <DiscardCommitModal
          open
          onClose={() => setShouldDisplayModal(false)}
          onDiscard={() => {
            dispatch(
              handleUpdateSelectedConfigurationVersion(
                undefined,
                UpdateVersionActions.DISCARD
              )
            );
            history.push("properties");
          }}
          onSubmit={() => {
            dispatch(
              handleUpdateSelectedConfigurationVersion(
                undefined,
                UpdateVersionActions.COMMIT
              )
            );
            history.push("properties");
          }}
          mode={DiscardCommitModalMode.RETURN_TO_LATEST_VERSION}
        />
      )}
    </>
  );
};

const VersionWarningContainer = styled.div<{ isdarkmode: boolean }>`
  background-color: ${({ theme }) => theme.backgrounds.warningLight};
  padding-left: 24px;
  margin-top: 24px;
  ${({ isdarkmode }) =>
    isdarkmode &&
    css`
      color: #000000;
    `}
`;

const VersionWarning = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

const AlertIcon = styled(Icon)`
  margin-right: 16px;

  * {
    fill: ${({ theme }) => theme.colors.yellow40};
  }
`;

const GetBackContainer = styled.div`
  padding-left: 4px;
  color: ${({ theme }) => theme.text.highlight};
  cursor: pointer;
`;
