import { FC, useState } from "react";

import { Icons } from "../../../../../components/atoms/Icon/Icon";
import { Radios } from "./types";
import { ChartMapTableContent } from "./ChartMapTableContent";
import {
  IconSlider,
  IIconSliderProps,
} from "../../../../../components/molecules/IconSlider/IconSlider";
import { PointerIcon } from "../../../../../components/atoms/PointerIcon/PointerIcon";
import { SectionLayout } from "../../../../../components/molecules/SectionLayout/SectionLayout";
import {
  useCurrentByMetro,
  useCurrentByRegion,
  useCurrentDataStatus,
  useMetrosHistory,
  useRegionHistoryStatus,
  useRegionsHistory,
} from "../../../../../store/slices/rtm/hooks";
import { SetupStatus } from "../../../../../store/slices/rtm/types";
import { FilterFields, useFiltersContext } from "../../../context/filters";
import { FixedWidgetIds } from "../../../models/models";
import { WidgetLayout } from "../../../../../components/molecules/WidgetLayout/WidgetLayout";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { Loader } from "../../../../../components/atoms/Loader/Loader";

const ChartMapSwitcher: FC<IIconSliderProps> = (props) => (
  <IconSlider {...props}>
    <PointerIcon name={Icons.GRAPH} size={20} />
    <PointerIcon name={Icons.MAP} size={20} />
  </IconSlider>
);

export const ChartMapTable: FC = () => {
  const currentByMetro = useCurrentByMetro();
  const currentByRegion = useCurrentByRegion();
  const regionsHistory = useRegionsHistory();
  const metrosHistory = useMetrosHistory();

  const { preferences, updateWidgetPreference } = useFiltersContext();

  const [activeRadio, setActiveRadio] = useState<Radios>(
    preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)?.chartOrMapDisplay ===
      Radios.CHART
      ? Radios.CHART
      : Radios.MAP
  );

  const handleChangeRadio = (radioIndex: number) => {
    updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
      key: FilterFields.CHART_OR_MAP_DISPLAY,
      value: radioIndex,
    });
    setActiveRadio(radioIndex);
  };

  const currentStatus = useCurrentDataStatus();
  const regionHistoryStatus = useRegionHistoryStatus();
  const metroHistoryStatus = useRegionHistoryStatus();

  const shouldRenderLoader =
    activeRadio === Radios.CHART
      ? regionHistoryStatus === SetupStatus.SETUP ||
        metroHistoryStatus === SetupStatus.SETUP
      : currentStatus === SetupStatus.SETUP;

  return (
    <SectionLayout
      title=""
      headerRight={
        <ChartMapSwitcher
          activeIndex={activeRadio}
          onRadioClick={handleChangeRadio}
        />
      }
    >
      {shouldRenderLoader ? (
        <WidgetLayout>
          <FlexBox>
            <Loader size={40} />
          </FlexBox>
        </WidgetLayout>
      ) : (
        <ChartMapTableContent
          showChart={activeRadio === Radios.CHART}
          currentByMetro={currentByMetro ?? []}
          currentByRegion={currentByRegion ?? []}
          regionsHistory={regionsHistory ?? []}
          metrosHistory={metrosHistory ?? []}
        />
      )}
    </SectionLayout>
  );
};
