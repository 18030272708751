import { FunctionComponent, SVGProps } from "react";
import styled from "@emotion/styled";

import { ColorSet } from "../../../contexts/themeContext";
import { ReactComponent as alert } from "../../../assets/icons/alert.svg";
import { ReactComponent as arrow_fat } from "../../../assets/icons/arrow_fat.svg";
import { ReactComponent as arrow_right_to_bracket } from "../../../assets/icons/arrow_right_to_bracket.svg";
import { ReactComponent as arrow_simple } from "../../../assets/icons/arrow_simple.svg";
import { ReactComponent as arrow_slim } from "../../../assets/icons/arrow_slim.svg";
import { ReactComponent as arrow_small } from "../../../assets/icons/arrow_small.svg";
import { ReactComponent as check } from "../../../assets/icons/check.svg";
import { ReactComponent as chevron_down } from "../../../assets/icons/chevron_down.svg";
import { ReactComponent as circle } from "../../../assets/icons/circle.svg";
import { ReactComponent as circle_minus_outline } from "../../../assets/icons/circle_minus_outline.svg";
import { ReactComponent as copy } from "../../../assets/icons/copy.svg";
import { ReactComponent as copy_outline } from "../../../assets/icons/copy_outline.svg";
import { ReactComponent as cross } from "../../../assets/icons/cross.svg";
import { ReactComponent as dnd } from "../../../assets/icons/dnd.svg";
import { ReactComponent as edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as expand } from "../../../assets/icons/expand.svg";
import { ReactComponent as eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as feedback } from "../../../assets/icons/feedback.svg";
import { ReactComponent as file_download } from "../../../assets/icons/file_download.svg";
import { ReactComponent as gear } from "../../../assets/icons/gear.svg";
import { ReactComponent as graph } from "../../../assets/icons/graph.svg";
import { ReactComponent as hamburger } from "../../../assets/icons/hamburger.svg";
import { ReactComponent as info } from "../../../assets/icons/info.svg";
import { ReactComponent as info_outline } from "../../../assets/icons/info_outline.svg";
import { ReactComponent as lock } from "../../../assets/icons/lock.svg";
import { ReactComponent as long_arrow_left } from "../../../assets/icons/long_arrow_left.svg";
import { ReactComponent as map } from "../../../assets/icons/map.svg";
import { ReactComponent as meatballs } from "../../../assets/icons/meatballs.svg";
import { ReactComponent as pen } from "../../../assets/icons/pen.svg";
import { ReactComponent as pile } from "../../../assets/icons/pile.svg";
import { ReactComponent as plain_information } from "../../../assets/icons/plain_information.svg";
import { ReactComponent as plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as question } from "../../../assets/icons/question.svg";
import { ReactComponent as search } from "../../../assets/icons/search.svg";
import { ReactComponent as server } from "../../../assets/icons/server.svg";
import { ReactComponent as sun } from "../../../assets/icons/sun.svg";
import { ReactComponent as moon } from "../../../assets/icons/moon.svg";
import { ReactComponent as trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as triple_dot } from "../../../assets/icons/triple_dot.svg";
import { ReactComponent as user } from "../../../assets/icons/user.svg";
import { ReactComponent as warning } from "../../../assets/icons/warning.svg";
import { ReactComponent as open_triangle } from "../../../assets/icons/open_triangle.svg";
import { ReactComponent as dash } from "../../../assets/icons/dash.svg";

// NOTE: complete while coding & move to isolated file when too big
export enum Icons {
  ALERT = "alert",
  ARROW_FAT = "arrow_fat",
  ARROW_LEFT = "long_arrow_left",
  ARROW_RIGHT_TO_BRACKET = "arrow_right_to_bracket",
  ARROW_SIMPLE = "arrow_simple",
  ARROW_SLIM = "arrow_slim",
  ARROW_SMALL = "small-arrow",
  CHECK = "check",
  CHEVRON_DOWN = "chevron_down",
  CIRCLE = "circle",
  COPY = "copy",
  COPY_OUTLINE = "copy_outline",
  CROSS = "cross",
  DND = "dnd",
  DASH = "dash",
  EDIT = "edit",
  EXPAND = "expand",
  EYE = "eye",
  FEEDBACK = "feedback",
  FILE_DOWNLOAD = "file-download",
  GEAR = "gear",
  GRAPH = "graph",
  HAMBURGER = "hamburger",
  INFO = "info",
  INFO_OUTLINE = "info_outline",
  LOCK = "lock",
  MAP = "map",
  MEATBALLS = "meatballs",
  PEN = "pen",
  PILE = "pile",
  PLAIN_INFORMATION = "plain-information",
  PLUS = "plus",
  QUESTION = "question",
  REMOVE = "circle_minus_outline",
  SEARCH = "search",
  SERVER = "server",
  SUN = "sun",
  MOON = "moon",
  TRASH = "trash",
  TRIPLE_DOT = "triple_dot",
  USER = "user",
  WARNING = "warning",
  OPEN_TRIANGLE = "open_triangle",
}

const iconsList: [
  Icons,
  FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
][] = [
  [Icons.ALERT, alert],
  [Icons.ARROW_FAT, arrow_fat],
  [Icons.ARROW_LEFT, long_arrow_left],
  [Icons.ARROW_RIGHT_TO_BRACKET, arrow_right_to_bracket],
  [Icons.ARROW_SIMPLE, arrow_simple],
  [Icons.ARROW_SLIM, arrow_slim],
  [Icons.ARROW_SMALL, arrow_small],
  [Icons.CHECK, check],
  [Icons.CHEVRON_DOWN, chevron_down],
  [Icons.CIRCLE, circle],
  [Icons.COPY, copy],
  [Icons.COPY_OUTLINE, copy_outline],
  [Icons.CROSS, cross],
  [Icons.DND, dnd],
  [Icons.DASH, dash],
  [Icons.EDIT, edit],
  [Icons.EXPAND, expand],
  [Icons.EYE, eye],
  [Icons.FEEDBACK, feedback],
  [Icons.FILE_DOWNLOAD, file_download],
  [Icons.GEAR, gear],
  [Icons.GRAPH, graph],
  [Icons.HAMBURGER, hamburger],
  [Icons.INFO, info],
  [Icons.INFO_OUTLINE, info_outline],
  [Icons.LOCK, lock],
  [Icons.MAP, map],
  [Icons.MEATBALLS, meatballs],
  [Icons.PILE, pen],
  [Icons.PILE, pile],
  [Icons.PLAIN_INFORMATION, plain_information],
  [Icons.PLUS, plus],
  [Icons.QUESTION, question],
  [Icons.REMOVE, circle_minus_outline],
  [Icons.SEARCH, search],
  [Icons.SERVER, server],
  [Icons.SUN, sun],
  [Icons.MOON, moon],
  [Icons.TRASH, trash],
  [Icons.TRIPLE_DOT, triple_dot],
  [Icons.USER, user],
  [Icons.WARNING, warning],
  [Icons.OPEN_TRIANGLE, open_triangle],
];

const iconsMap = new Map(
  iconsList.map(([icon, comp]) => [
    icon,
    styled(comp)<{ color: IconColor }>`
      * {
        fill: ${({ theme, color }) => {
          const iconColors = { ...theme.icon, ...theme.colors };
          return iconColors[color];
        }};
      }
      cursor: ${({ onClick }) =>
        onClick !== undefined ? "pointer" : "default"};
    `,
  ])
);

type IconColor = keyof ColorSet["icon"] | keyof ColorSet["colors"];

export interface IconProps {
  name: Icons;
  color?: IconColor;
  size?: number | { width: number; height: number };
  className?: string;
  dataTestId?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export const Icon: React.FC<IconProps> = ({
  name,
  color = "highlight",
  size = 16,
  className,
  dataTestId,
  onClick,
}) => {
  const IconComp = iconsMap.get(name);

  if (IconComp) {
    return (
      <IconComp
        width={typeof size === "number" ? size : size.width}
        height={typeof size === "number" ? size : size.height}
        className={className}
        color={color}
        onClick={onClick}
        data-testid={`${dataTestId}-${name}`}
      />
    );
  }

  return null;
};
