import { Children, FC } from "react";
import { BreadcrumbArrow } from "../../atoms/BreadcrumbArrow/BreadcrumbArrow";

export const Breadcrumbs: FC = ({ children }) => {
  const arrayChildren = Children.toArray(children);
  const indexOfLast = arrayChildren.length - 1;

  return (
    <>
      {Children.map(arrayChildren, (child, i) => {
        const isLast = i === indexOfLast;

        return (
          <>
            {child}
            {!isLast && <BreadcrumbArrow />}
          </>
        );
      })}
    </>
  );
};
