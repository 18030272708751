import { MouseEvent, ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import type { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Button } from "../../../../../../../../components/atoms/Button/Button";
import {
  Container,
  ModalBackground,
  ModalContainer,
} from "../../../../../../../../components/atoms/Modal/Modal";
import { IFeatures } from "../../../../../../../../models/configuration/definitions";
import { getQueryParams } from "../../../../../../../../utils/getQueryParams";
import { MODAL_QUERY } from "../../../../constants";
import { FeaturesSearchBar } from "../FeaturesSearchBar";
import { FeaturesBase } from "../types";
import { useFeatureType } from "../useFeatureType";
import { AddFeaturesList } from "./AddFeaturesList";

export interface IAddFeaturesProps extends FeaturesBase {
  matchBlock: string;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  setFocusFeature: (focusFeature: keyof IFeatures) => void;
}

const useModal = () => {
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(
    getQueryParams(location).get(MODAL_QUERY) === "true"
  );

  const setIsOpen = (newValue: typeof isModalOpen) => {
    if (newValue) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
      const queryParams = getQueryParams(location);
      queryParams.set(MODAL_QUERY, "false");
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${queryParams.toString()}`
      );
    }
  };

  return [isModalOpen, setIsOpen] as const;
};

const handleModalClick = (e: MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export const AddFeaturesModal = ({
  handleCreateFeature,
  matchBlock,
  setFocusFeature,
  watch,
}: IAddFeaturesProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [isOpen, setIsOpen] = useModal();

  const {
    dropdownItems,
    searchValue,
    selectedFeatureType,
    setSearchValue,
    setSelectedFeatureType,
  } = useFeatureType(matchBlock);

  useEffect(() => {
    // Reset selected feature type filer on modal closed.
    if (!isOpen) setSelectedFeatureType(dropdownItems.find((i) => i.default)!);
  }, [isOpen]);

  return (
    <>
      <AddButton
        className="chi-button -primary -flat"
        onMouseDown={() => {
          /*
            Why onMouseDown and not onClick ?
            This is a very good question. Actually, when clicking this button,
            the form with the features on top looses focus and triggers the onBlur
            function which display the potential errors, making the form bigger and
            making the button slide down below where the user clicked in the first
            place, thus not opening the modal. To avoid this behaviour, we trigger
            the opening when the mouse is down, and not after the click.
          */
          setIsOpen(true);
        }}
        type="button"
        data-testid="add-feature-modal-add-button"
      >
        <PlusSign>+</PlusSign>
        <div>{t("FEATURE_MODAL_ADD_BUTTON")}</div>
      </AddButton>

      {isOpen && (
        <>
          <ModalBackground />
          <Container
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <ModalContainer onClick={handleModalClick} size="large">
              <ModalChildContainer>
                <HeaderContainer>
                  <Header>{t("FEATURE_MODAL_TITLE")}</Header>
                </HeaderContainer>
                <FeaturesSearchBar
                  dropdownItems={dropdownItems}
                  setSearchValue={setSearchValue}
                  setSelectedFeatureType={setSelectedFeatureType}
                  searchValue={searchValue}
                />
                <ListContainer>
                  <AddFeaturesList
                    matchBlock={matchBlock}
                    handleCreateFeature={handleCreateFeature}
                    closeModal={() => setIsOpen(false)}
                    setFocusFeature={setFocusFeature}
                    selectedFeatureType={selectedFeatureType.value}
                    searchValue={searchValue}
                    dataTestId="modal"
                    watch={watch}
                  />
                </ListContainer>
                <ButtonContainer>
                  <Button
                    label={t("FEATURE_MODAL_CANCEL_BUTTON")}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    dataTestId="feature-modal-cancel-button"
                    backgroundColor="baseLight"
                    textColor="primary"
                    borderColor="mutedLight"
                  />
                </ButtonContainer>
              </ModalChildContainer>
            </ModalContainer>
          </Container>
        </>
      )}
    </>
  );
};

const HeaderContainer = styled.div`
  margin-bottom: 16px;
`;

const Header = styled.h4`
  margin: 0px !important;
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  border: none !important;
  cursor: pointer;
  font-size: 16px !important;

  :hover {
    opacity: 0.5;
  }
`;

const PlusSign = styled.div`
  font-size: 30px;
  font-weight: 300;
  padding: 0px 4px 4px 0px;
`;

const ModalChildContainer = styled.div`
  padding: 32px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ListContainer = styled.div`
  padding: 32px 0px;
`;
