import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ModuleHeader } from "../../../../components/atoms/Module/Module";

import { IRoute, Tabs } from "../../../../components/organisms/Tabs/Tabs";
import { useUserVyvxId } from "../../../../store/slices/user/hooks";
import { ApiKeysRouter } from "./ApiKeys.router";

export const ApiKeys = (): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const history = useHistory();
  const vyvxAbr = useUserVyvxId();
  const match = useRouteMatch<{
    version: string;
    module: string;
    name: string;
    submodule: string;
  }>([
    "/:version/:module/:name/:submodule",
    "/:version/:module/:name",
    "/:version/:module",
  ]);

  const tabList = [
    { route: "cdn", label: t("API_KEYS_TAB_CDN") },
    { route: "vyvx", label: t("API_KEYS_TAB_VYVX") },
  ];

  const currentIndex = tabList.findIndex(
    (tab) => tab.route === match?.params.name
  );

  const changeKeyType = (selection: IRoute) => {
    history.push(`/userManagement/apiKeys/${selection.route}`);
  };

  return (
    <>
      {vyvxAbr && !match?.params.submodule && (
        <ModuleHeader>
          <Tabs
            onSelectionChange={changeKeyType}
            tabsList={tabList}
            initialIndex={currentIndex === -1 ? 0 : currentIndex}
          />
        </ModuleHeader>
      )}
      <ApiKeysRouter />
    </>
  );
};
