/* eslint-disable complexity */
import type { ReactElement } from "react";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../components/molecules/FeatureType/FeatureType";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IFeatures } from "../../../../../../../../models/configuration/definitions";
import {
  useIsLatestVersionConfig,
  useSelectedConfiguration,
} from "../../../../../../../../store/slices/caching/hooks";
import { handleCreateDefinition } from "../../../../../../../../store/slices/caching/thunks";
import { ConfigurationDefinition } from "../../../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../../../store/types";
import { FilterFeatureTypeValue, FocusFeature } from "../../types";
import { FEATURES_FILTER_CONFIGS } from "../featuresConfigs";
import { FeaturesBase } from "../types";
import { CacheKeyFeatureBlock } from "./feature_block/CacheKeyFeatureBlock";
import { CCHOFeatureBlock } from "./feature_block/cchoFeatureBlock/CCHOFeatureBlock";
import { CustomLogDataFeatureBlock } from "./feature_block/customLogDataFeatureBlock/CustomLogDataFeatureBlock";
import { DefaultCacheFeatureBlock } from "./feature_block/DefaultCacheFeatureBlock";
import { DenyPolicyFeatureBlock } from "./feature_block/denyPolicyFeatureBlock/DenyPolicyFeatureBlock";
import { FailedRefreshTTLFeatureBlock } from "./feature_block/FailedRefreshTTL";
import { FeatureBlock } from "./feature_block/FeatureBlock";
import { FollowRedirects } from "./feature_block/FollowRedirects";
import { GeneratedResponseFeatureBlock } from "./feature_block/GeneratedResponseFeatureBlock";
import { GeoFeatureBlock } from "./feature_block/GeoFeatureBlock";
import { IpFeatureBlock } from "./feature_block/IpFeatureBlock";
import { RequestLuaFeatureBlock } from "./feature_block/luaFeatureBlocks/RequestLuaFeatureBlock";
import { ResponseLuaFeatureBlock } from "./feature_block/luaFeatureBlocks/ResponseLuaFeatureBlock";
import { NegativeTTLFeatureBlock } from "./feature_block/NegativeTTLFeatureBlock";
import { OriginFeatureBlock } from "./feature_block/OriginFeatureBlock";
import { QSHModeFeatureBlock } from "./feature_block/QSHModeFeatureBlock";
import { ReportingOverrideFeatureBlock } from "./feature_block/ReportingOverrideFeatureBlock";
import { RequestHeadersFeatureBlock } from "./feature_block/RequestHeadersFeatureBlock";
import { ResponseHeadersFeatureBlock } from "./feature_block/ResponseHeadersFeatureBlock";
import { StaleContentControlFeatureBlock } from "./feature_block/StaleContentControlFeatureBlock";
import { TokenFeatureBlock } from "./feature_block/TokenFeatureBlock";
import { URIRewriteFeatureBlock } from "./feature_block/URIRewriteFeatureBlock";

export interface IValidationErrors {
  [fieldName: string]: { type: string; message?: string };
}

export interface FeaturesListProps extends FeaturesBase {
  focusFeature: FocusFeature;
  // TODO: add a typed errors later
  errors: any;
  selectedFeatureType: FilterFeatureTypeValue;
  searchValue: string;
  register: UseFormRegister<IFeatures>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  handleCreateFeature: (featureName: keyof IFeatures, newFeature: any) => void;
  handleDeleteFeature: (
    featureName: keyof IFeatures,
    fieldName?: string
  ) => void;
  unregister: UseFormUnregister<IFeatures>;
}

export const FeaturesList = ({
  clearErrors,
  errors,
  focusFeature,
  handleCreateFeature,
  handleDeleteFeature,
  register,
  searchValue,
  selectedFeatureType,
  setError,
  watch,
  unregister,
}: FeaturesListProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationMatchRulesPage");
  const isLatestVersionConfig = useIsLatestVersionConfig();

  const dispatch = useAppDispatch();

  const config = selectedConfiguration!.config;

  const handleCreate = (definition: ConfigurationDefinition) => {
    dispatch(handleCreateDefinition(definition));
  };

  const maxWidth = 380;

  const generatedResponse = watch("generatedResponse");
  const customLogData = watch("customLogData");
  const denyPolicy = watch("denyPolicy");
  const geoRestrictions = watch("geoRestrictions");
  const uriRewrite = watch("uriRewrite");
  const cacheControlHeaderOverride = watch("cacheControlHeaderOverride");
  const ipRestrictions = watch("ipRestrictions");
  const reqLua = watch("reqLua");
  const respLua = watch("respLua");
  const qshMode = watch("qshMode");
  const originFillPolicy = watch("originFillPolicy");
  const negativeTTL = watch("negativeTTL");
  const staleContentControl = watch("staleContentControl");
  const respHeaders = watch("respHeaders");
  const tokenAuthentication = watch("tokenAuthentication");
  const reqHeaders = watch("reqHeaders");
  const reportingOverride = watch("reportingOverride");

  return (
    <>
      {watch("allowCompress") !== undefined &&
        FEATURES_FILTER_CONFIGS.allowCompress(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_ALLOW_COMPRESS_TITLE"),
          t("FEATURE_CARD_ADDED_ALLOW_COMPRESS_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_ALLOW_COMPRESS_DESCRIPTION_TITLE")
        ) && (
          <FeatureBlock
            title={t("FEATURE_CARD_ALLOW_COMPRESS_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_ADDED_ALLOW_COMPRESS_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_ADDED_ALLOW_COMPRESS_DESCRIPTION_CONTENT"
            )}
            fields={
              <PropertyDefinitionCard
                title={t("FEATURE_CARD_ALLOW_COMPRESS_DEFINITION_TITLE")}
                additionalInfoTitle={t(
                  "FEATURE_CARD_ALLOW_COMPRESS_DEFINITION_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "FEATURE_CARD_ALLOW_COMPRESS_DEFINITION_DESCRIPTION_CONTENT"
                )}
                fieldType={FieldType.CheckboxType}
                fieldProps={{
                  disabled: true,
                  checked: true,
                  label: t(
                    "FEATURE_CARD_ALLOW_COMPRESS_DEFINITION_CHECKBOX_LABEL"
                  ),
                  dataTestId: "allow-compress",
                }}
              />
            }
            initIsOpen={focusFeature === "allowCompress"}
            customDeleteButton={
              <DeleteModal
                title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
                deleteItemName={"allowCompress"}
                onDelete={() => {
                  handleDeleteFeature("allowCompress");
                }}
                dataTestId={"allowCompress"}
              />
            }
            dataTestId="allow-compress"
            featureType={FeatureTypes.CONTENT_MANIPULATION}
          />
        )}
      {isLatestVersionConfig &&
        watch("cacheKey") !== undefined &&
        FEATURES_FILTER_CONFIGS.cacheKey(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_CACHE_KEY_TITLE"),
          t("FEATURE_CARD_ADDED_CACHE_KEY_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_CACHE_KEY_DESCRIPTION_TITLE")
        ) && (
          <CacheKeyFeatureBlock
            datatestId="cacheKey"
            errors={errors}
            initIsOpen={focusFeature === "cacheKey"}
            maxWidth={maxWidth}
            onDelete={() => handleDeleteFeature("cacheKey")}
            register={register}
          />
        )}
      {cacheControlHeaderOverride !== undefined &&
        FEATURES_FILTER_CONFIGS.cacheControlHeaderOverride(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_CCHO_TITLE"),
          t("FEATURE_CARD_ADDED_CCHO_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_CCHO_DESCRIPTION_TITLE")
        ) && (
          <CCHOFeatureBlock
            cacheControlHeaderOverride={cacheControlHeaderOverride}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "cacheControlHeaderOverride"}
            errors={errors}
            register={register}
            unregister={unregister}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            onDelete={() => handleDeleteFeature("cacheControlHeaderOverride")}
            datatestId={"cacheControlHeaderOverride"}
            setError={setError}
            clearErrors={clearErrors}
          />
        )}
      {generatedResponse !== undefined &&
        FEATURES_FILTER_CONFIGS.generatedResponse(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_GENERATED_RESPONSE_TITLE"),
          t("FEATURE_CARD_ADDED_GENERATED_RESPONSE_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_GENERATED_RESPONSE_DESCRIPTION_TITLE")
        ) && (
          <GeneratedResponseFeatureBlock
            handleCreateDefinition={handleCreate}
            initIsOpen={focusFeature === "generatedResponse"}
            handleCreateFeature={handleCreateFeature}
            onDelete={() => handleDeleteFeature("generatedResponse")}
            config={config!}
            maxWidth={maxWidth}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            generatedResponseName={generatedResponse}
          />
        )}
      {customLogData !== undefined &&
        FEATURES_FILTER_CONFIGS.customLogData(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_CUSTOM_LOG_DATA_TITLE"),
          t("FEATURE_CARD_ADDED_CUSTOM_LOG_DATA_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_CUSTOM_LOG_DATA_DESCRIPTION_TITLE")
        ) && (
          <CustomLogDataFeatureBlock
            errors={errors}
            register={register}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            handleDeleteFeature={(name) => {
              unregister(name);
            }}
            onDelete={() => handleDeleteFeature("customLogData")}
            clearErrors={clearErrors}
            setError={setError}
            initIsOpen={focusFeature === "customLogData"}
            maxWidth={maxWidth}
            datatestId="customLogData"
            customLogData={customLogData}
          />
        )}
      {watch("defaultCache") !== undefined &&
        FEATURES_FILTER_CONFIGS.defaultCache(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_DEFAULT_CACHE_TITLE"),
          t("FEATURE_CARD_ADDED_DEFAULT_CACHE_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_DEFAULT_CACHE_DESCRIPTION_TITLE")
        ) && (
          <DefaultCacheFeatureBlock
            datatestId="defaultCache"
            errors={errors}
            initIsOpen={focusFeature === "defaultCache"}
            maxWidth={maxWidth}
            onDelete={() => handleDeleteFeature("defaultCache")}
            register={register}
          />
        )}
      {denyPolicy !== undefined &&
        FEATURES_FILTER_CONFIGS.denyPolicy(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_DENY_POLICY_TITLE"),
          t("FEATURE_CARD_ADDED_DENY_POLICY_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_DENY_POLICY_DESCRIPTION_TITLE")
        ) && (
          <DenyPolicyFeatureBlock
            clearErrors={clearErrors}
            datatestId="denyPolicy"
            denyPolicy={denyPolicy}
            errors={errors.denyPolicy}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            initIsOpen={focusFeature === "denyPolicy"}
            maxWidth={maxWidth}
            onDelete={() => handleDeleteFeature("denyPolicy")}
            register={register}
            setError={setError}
          />
        )}
      {watch("failedRefreshTTL") !== undefined &&
        FEATURES_FILTER_CONFIGS.failedRefreshTTL(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_FAILED_REFRESH_TTL_TITLE"),
          t("FEATURE_CARD_ADDED_FAILED_REFRESH_TTL_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_FAILED_REFRESH_TTL_DESCRIPTION_TITLE")
        ) && (
          <FailedRefreshTTLFeatureBlock
            errMsg={errors.failedRefreshTTL?.ttl}
            register={register}
            initIsOpen={focusFeature === "failedRefreshTTL"}
            onDelete={() => handleDeleteFeature("failedRefreshTTL")}
            maxWidth={maxWidth}
            datatestId="failedRefreshTTL"
          />
        )}
      {watch("followRedirects") !== undefined &&
        FEATURES_FILTER_CONFIGS.followRedirects(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_FOLLOW_REDIRECTS_TITLE"),
          t("FEATURE_CARD_ADDED_FOLLOW_REDIRECTS_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_FOLLOW_REDIRECTS_DESCRIPTION_TITLE")
        ) && (
          <FollowRedirects
            register={register}
            initIsOpen={focusFeature === "followRedirects"}
            onDelete={() => {
              handleDeleteFeature("followRedirects");
            }}
          />
        )}
      {geoRestrictions !== undefined &&
        FEATURES_FILTER_CONFIGS.geoRestrictions(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_GEO_REGION_BLOCKING_TITLE"),
          t("FEATURE_CARD_ADDED_GEO_REGION_BLOCKING_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_GEO_REGION_BLOCKING_DESCRIPTION_TITLE")
        ) && (
          <GeoFeatureBlock
            handleCreateDefinition={handleCreate}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            geoRestrictions={geoRestrictions}
            config={config!}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "geoRestrictions"}
            onDelete={() => handleDeleteFeature("geoRestrictions")}
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
            datatestId="geoRestrictions"
          />
        )}
      {reqHeaders !== undefined &&
        FEATURES_FILTER_CONFIGS.reqHeaders(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_REQUEST_HEADERS_TITLE"),
          t("FEATURE_CARD_ADDED_REQUEST_HEADERS_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_REQUEST_HEADERS_DESCRIPTION_TITLE")
        ) && (
          <RequestHeadersFeatureBlock
            handleCreateDefinition={handleCreate}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            reqHeaders={reqHeaders}
            config={config!}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "reqHeaders"}
            onDelete={() => handleDeleteFeature("reqHeaders")}
            datatestId="reqHeaders"
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
          />
        )}
      {respHeaders !== undefined &&
        FEATURES_FILTER_CONFIGS.respHeaders(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_RESPONSE_HEADERS_TITLE"),
          t("FEATURE_CARD_ADDED_RESPONSE_HEADERS_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_RESPONSE_HEADERS_DESCRIPTION_TITLE")
        ) && (
          <ResponseHeadersFeatureBlock
            handleCreateDefinition={handleCreate}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            onDelete={() => handleDeleteFeature("respHeaders")}
            datatestId="respHeaders"
            respHeaders={respHeaders}
            config={config!}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "respHeaders"}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
          />
        )}
      {ipRestrictions !== undefined &&
        FEATURES_FILTER_CONFIGS.ipRestrictions(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_IP_BLOCKING_TITLE"),
          t("FEATURE_CARD_ADDED_IP_BLOCKING_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_IP_BLOCKING_DESCRIPTION_TITLE")
        ) && (
          <IpFeatureBlock
            handleCreateDefinition={handleCreate}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            onDelete={() => handleDeleteFeature("ipRestrictions")}
            datatestId="ipRestrictions"
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
            ipRestrictions={ipRestrictions}
            config={config!}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "ipRestrictions"}
          />
        )}
      {reqLua !== undefined &&
        FEATURES_FILTER_CONFIGS.reqLua(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_REQUEST_LUA_TITLE"),
          t("FEATURE_CARD_ADDED_REQUEST_LUA_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_REQUEST_LUA_DESCRIPTION_TITLE")
        ) && (
          <RequestLuaFeatureBlock
            config={config!}
            reqLua={reqLua}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "reqLua"}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            onDelete={() => handleDeleteFeature("reqLua")}
            datatestId={"reqLua"}
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
          />
        )}
      {respLua !== undefined &&
        FEATURES_FILTER_CONFIGS.respLua(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_RESPONSE_LUA_TITLE"),
          t("FEATURE_CARD_ADDED_RESPONSE_LUA_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_RESPONSE_LUA_DESCRIPTION_TITLE")
        ) && (
          <ResponseLuaFeatureBlock
            config={config!}
            respLua={respLua}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "respLua"}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            onDelete={() => handleDeleteFeature("respLua")}
            datatestId={"respLua"}
            setError={setError}
            clearErrors={clearErrors}
            errors={errors}
          />
        )}
      {negativeTTL !== undefined &&
        FEATURES_FILTER_CONFIGS.negativeTTL(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_NEGATIVE_TTL_TITLE"),
          t("FEATURE_CARD_ADDED_NEGATIVE_TTL_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_NEGATIVE_TTL_DESCRIPTION_TITLE")
        ) && (
          <NegativeTTLFeatureBlock
            negativeTTL={negativeTTL}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "negativeTTL"}
            errors={errors}
            register={register}
            unregister={unregister}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            onDelete={() => handleDeleteFeature("negativeTTL")}
            datatestId={"negativeTTL"}
          />
        )}
      {originFillPolicy !== undefined &&
        FEATURES_FILTER_CONFIGS.originFillPolicy(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_ORIGIN_TITLE"),
          t("FEATURE_CARD_ADDED_ORIGIN_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_ORIGIN_DESCRIPTION_TITLE")
        ) && (
          <OriginFeatureBlock
            clearErrors={clearErrors}
            config={config!}
            datatestId="originFillPolicy"
            errors={errors}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            initIsOpen={focusFeature === "originFillPolicy"}
            maxWidth={maxWidth}
            onDelete={() => handleDeleteFeature("originFillPolicy")}
            originFillPolicy={originFillPolicy}
            setError={setError}
          />
        )}
      {qshMode !== undefined &&
        FEATURES_FILTER_CONFIGS.qshMode(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_QSH_MODE_TITLE"),
          t("FEATURE_CARD_ADDED_QSH_MODE_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_QSH_MODE_DESCRIPTION_TITLE")
        ) && (
          <QSHModeFeatureBlock
            clearErrors={clearErrors}
            config={config!}
            datatestId="qshMode"
            errors={errors}
            handleCreate={handleCreate}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            initIsOpen={focusFeature === "qshMode"}
            maxWidth={maxWidth}
            onDelete={() => handleDeleteFeature("qshMode")}
            qshMode={qshMode}
            register={register}
            setError={setError}
          />
        )}
      {reportingOverride !== undefined &&
        FEATURES_FILTER_CONFIGS.reportingOverride(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_REPORTING_OVERRIDE_TITLE"),
          t("FEATURE_CARD_ADDED_REPORTING_OVERRIDE_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_REPORTING_OVERRIDE_DESCRIPTION_TITLE")
        ) && (
          <ReportingOverrideFeatureBlock
            handleCreateDefinition={handleCreate}
            initIsOpen={focusFeature === "reportingOverride"}
            onDelete={() => handleDeleteFeature("reportingOverride")}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            config={config!}
            reportingOverride={reportingOverride}
            maxWidth={maxWidth}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            datatestId={"reportingOverride"}
          />
        )}
      {watch("serve200For416") !== undefined &&
        FEATURES_FILTER_CONFIGS.serve200For416(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_SERVE_200_FOR_416_TITLE"),
          t("FEATURE_CARD_ADDED_SERVE_200_FOR_416_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_SERVE_200_FOR_416_DESCRIPTION_TITLE")
        ) && (
          <FeatureBlock
            title={t("FEATURE_CARD_SERVE_200_FOR_416_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_ADDED_SERVE_200_FOR_416_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_ADDED_SERVE_200_FOR_416_DESCRIPTION_CONTENT"
            )}
            fields={
              <PropertyDefinitionCard
                title={t("FEATURE_CARD_SERVE_200_FOR_416_DEFINITION_TITLE")}
                additionalInfoTitle={t(
                  "FEATURE_CARD_SERVE_200_FOR_416_DEFINITION_DESCRIPTION_TITLE"
                )}
                additionalInfoContent={t(
                  "FEATURE_CARD_SERVE_200_FOR_416_DEFINITION_DESCRIPTION_CONTENT"
                )}
                fieldType={FieldType.CheckboxType}
                fieldProps={{
                  disabled: true,
                  checked: true,
                  label: t(
                    "FEATURE_CARD_SERVE_200_FOR_416_DEFINITION_CHECKBOX_LABEL"
                  ),
                  dataTestId: "serve-200-for-416",
                }}
              />
            }
            initIsOpen={focusFeature === "serve200For416"}
            customDeleteButton={
              <DeleteModal
                title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
                deleteItemName={"followRedirects"}
                onDelete={() => {
                  handleDeleteFeature("serve200For416");
                }}
                dataTestId={"serve200For416"}
              />
            }
            dataTestId="serve-200-for-416"
            featureType={FeatureTypes.REQUEST_MANIPULATION}
          />
        )}
      {staleContentControl !== undefined &&
        FEATURES_FILTER_CONFIGS.staleContentControl(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_SCC_TITLE"),
          t("FEATURE_CARD_ADDED_SCC_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_SCC_DESCRIPTION_TITLE")
        ) && (
          <StaleContentControlFeatureBlock
            datatestId="staleContentControl"
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            initIsOpen={focusFeature === "staleContentControl"}
            onDelete={() => handleDeleteFeature("staleContentControl")}
            register={register}
            staleContentControl={staleContentControl}
          />
        )}
      {tokenAuthentication !== undefined &&
        FEATURES_FILTER_CONFIGS.tokenAuthentication(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_TOKEN_AUTHENTICATION_TITLE"),
          t("FEATURE_CARD_ADDED_TOKEN_AUTHENTICATION_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_TOKEN_AUTHENTICATION_DESCRIPTION_TITLE")
        ) && (
          <TokenFeatureBlock
            handleCreate={handleCreate}
            initIsOpen={focusFeature === "tokenAuthentication"}
            onDelete={() => handleDeleteFeature("tokenAuthentication")}
            datatestId={"tokenAuthentication"}
            handleCreateFeature={
              handleCreateFeature as UseFormSetValue<IFeatures>
            }
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            config={config!}
            tokenAuthentication={tokenAuthentication}
            maxWidth={maxWidth}
            errors={errors}
          />
        )}
      {uriRewrite !== undefined &&
        FEATURES_FILTER_CONFIGS.uriRewrite(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_URI_REWRITE_TITLE"),
          t("FEATURE_CARD_ADDED_URI_REWRITE_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_URI_REWRITE_DESCRIPTION_TITLE")
        ) && (
          <URIRewriteFeatureBlock
            uriRewrite={uriRewrite}
            maxWidth={maxWidth}
            initIsOpen={focusFeature === "uriRewrite"}
            onDelete={() => handleDeleteFeature("uriRewrite")}
            datatestId={"uriRewrite"}
            handleCreateFeature={handleCreateFeature}
            register={register}
            errors={errors}
          />
        )}
      {watch("disableIfNoneMatch") !== undefined &&
        FEATURES_FILTER_CONFIGS.disableIfNoneMatch(
          selectedFeatureType,
          searchValue,
          t("FEATURE_CARD_USE_IF_NONE_MATCH_TITLE"),
          t("FEATURE_CARD_ADDED_USE_IF_NONE_MATCH_DESCRIPTION_CONTENT") ||
            t("FEATURE_CARD_ADDED_USE_IF_NONE_MATCH_DESCRIPTION_TITLE")
        ) && (
          <FeatureBlock
            title={t("FEATURE_CARD_USE_IF_NONE_MATCH_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_ADDED_USE_IF_NONE_MATCH_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_ADDED_USE_IF_NONE_MATCH_DESCRIPTION_CONTENT"
            )}
            fields={
              <PropertyDefinitionCard
                fieldType={FieldType.CheckboxType}
                fieldProps={{
                  disabled: true,
                  checked: true,
                  label: t(
                    "FEATURE_CARD_USE_IF_NONE_MATCH_DEFINITION_CHECKBOX_LABEL"
                  ),
                  dataTestId: "disable-if-none-match",
                }}
                dataTestId="disable-if-none-match"
              />
            }
            initIsOpen={focusFeature === "disableIfNoneMatch"}
            customDeleteButton={
              <DeleteModal
                title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
                deleteItemName={"disableIfNoneMatch"}
                onDelete={() => {
                  handleDeleteFeature("disableIfNoneMatch");
                }}
                dataTestId={"disableIfNoneMatch"}
              />
            }
            dataTestId="disable-if-none-match"
            featureType={FeatureTypes.CACHE_CONTROL}
          />
        )}
    </>
  );
};
