import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../components/molecules/DeleteModal/DeleteModal";

interface DeleteFeatureFlagModalProps {
  featureFlagName: string;
  onDelete: () => void;
  dataTestId?: string;
}

export const DeleteFeatureFlagsModal = (
  deleteFeatureFlagModal: DeleteFeatureFlagModalProps
) => {
  const { t } = useTranslation("featureFlagsModalPage");

  return (
    <DeleteModal
      title={t("TITLE_DELETE_MODAL")}
      deleteItemName={deleteFeatureFlagModal.featureFlagName}
      onDelete={deleteFeatureFlagModal.onDelete}
      dataTestId={deleteFeatureFlagModal.dataTestId}
    />
  );
};
