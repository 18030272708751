import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import {
  Dropdown,
  DropdownPosition,
} from "../../../../../components/organisms/Dropdown/Dropdown";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { EditUserAccessGroupRoleModal } from "../../../../../components/organisms/EditUserAccessGroupRoleModal/EditUserAccessGroupRoleModal";
import { RemoveUserFromAccessGroupModal } from "../../../../../components/organisms/RemoveUserFromAccessGroupModal/RemoveUserFromAccessGroupModal";
import { fetchUser } from "../../../../../store/slices/user-management-users/thunk";
import { useAppDispatch } from "../../../../../store/types";

interface UserAccessGroupActionsDropdownProps {
  userId: number;
  accessGroupId: number;
  role?: string;
}

enum UserAccessGroupActions {
  EDIT_ROLES = "edit_roles",
  REMOVE_GROUP = "remove_group",
}

export const UserAccessGroupActionsDropdown = ({
  userId,
  accessGroupId,
  role,
}: UserAccessGroupActionsDropdownProps): ReactElement => {
  const { t } = useTranslation("usersPage");
  const [action, setAction] = useState<UserAccessGroupActions>();

  const dispatch = useAppDispatch();

  const dropdownItems: {
    label: string;
    value: UserAccessGroupActions;
  }[] = [
    {
      label: t("ACCESS_GROUPS_SECTION_EDIT_ROLES"),
      value: UserAccessGroupActions.EDIT_ROLES,
    },
    {
      label: t("ACCESS_GROUPS_SECTION_REMOVE_GROUP"),
      value: UserAccessGroupActions.REMOVE_GROUP,
    },
  ];

  return (
    <>
      <Dropdown
        id="user-accessgroup-actions-dropdown"
        placeholder=""
        position={DropdownPosition.LEFT}
        customButton={
          <IconContainer>
            <Icon
              name={Icons.TRIPLE_DOT}
              size={{ width: 18, height: 5 }}
              color="secondary"
            />
          </IconContainer>
        }
        onSelect={(item) => {
          setAction(item.value);
        }}
        items={dropdownItems}
      />
      <Modal
        customButton={<></>}
        open={action !== undefined}
        onClose={() => setAction(undefined)}
        overflow="visible"
      >
        {action === UserAccessGroupActions.EDIT_ROLES ? (
          <EditUserAccessGroupRoleModal
            userId={userId}
            accessGroupId={accessGroupId}
            role={role}
            onSave={() => {
              dispatch(fetchUser(userId));
            }}
          />
        ) : (
          <RemoveUserFromAccessGroupModal
            userId={userId}
            accessGroupId={accessGroupId}
          />
        )}
      </Modal>
    </>
  );
};

const IconContainer = styled.div`
  cursor: pointer;
`;
