import { Status } from "../../../@types/status";

export interface FeatureFlagsSlice {
  status: Status;
  value: FeatureFlags | undefined;
}

export interface FeatureFlag {
  id: string;
  name: ExistingFeatures;
  status: FeatureFlagStatus;
  enabledFor?: FeatureFlagEnabledFor;
}

export type FeatureFlags = Partial<Record<ExistingFeatures, FeatureFlag>>;

export enum FeatureFlagStatus {
  ENABLED = "enabled",
  DISABLED = "disabled",
  RESTRICTED = "restricted",
}

export interface FeatureFlagEnabledFor {
  users?: number[];
  subscribers?: number[];
  accessGroups?: number[];
  apiKeys?: number[];
  internalsOnly?: boolean;
}

export enum ExistingFeatures {
  RTM = "RTM",
  CONTENT_INVALIDATION = "content_invalidation",
  PORTAL_HUB = "portal_hub",
  USER_MANAGEMENT = "user_management",
}
