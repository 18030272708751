import { ColorSet } from "../../../../contexts/themeContext";
import {
  UserManagementUserData,
  UserStatus,
} from "../../../../models/user-management/users/users.interfaces";

export type ProcessedUsers = {
  userId: number;
  fullName: string;
  userName: string;
  status: UserStatus;
  lastLogin: string;
  createdAt: string;
  parentAccessGroup: {
    name: string;
    roleName: string;
  };
  accessGroupsCounter: number;
  rolesCounter: number;
  accessGroups: {
    name: string;
    roleName: string;
  }[];
  fiberCompanyName?: string;
  satelliteCompanyName?: string;
  vyvxRole?: string;
  originalData: UserManagementUserData;
};

export const statusBadgeColors = new Map<UserStatus, keyof ColorSet["colors"]>([
  [UserStatus.ACTIVE, "green60"],
  [UserStatus.DISABLED, "grey50"],
  [UserStatus.EXPIRED, "red50"],
  [UserStatus.INACTIVE, "cyan100"],
  [UserStatus.LOCKED, "grey50"],
  [UserStatus.PENDING, "yellow40"],
]);

export interface DateDifferenceItem {
  singular: string;
  plural: string;
}

export const dateDifferenceLabel = new Map<string, DateDifferenceItem>([
  ["days", { singular: "day", plural: "days" }],
  ["weeks", { singular: "week", plural: "weeks" }],
  ["months", { singular: "month", plural: "months" }],
  ["years", { singular: "year", plural: "years" }],
]);

export enum UserType {
  CDN = "CDN",
  VYVX = "VYVX",
}
