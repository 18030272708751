import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/atoms/Button/Button";
import { TextDelimiter } from "../../../../components/atoms/TextDelimiter/TextDelimiter";
import { FilePreviewModal } from "../../../../components/molecules/FilePreviewModal/FilePreviewModal";
import { TextArea } from "../../../../components/molecules/TextArea/TextArea";
import { FileDropZone } from "../../../../components/organisms/FileDropzone/FileDropzone";
import { UploadContent } from "./UploadContent";
import { Asterisk } from "../../../../components/atoms/Asterisk/Asterisk";
import { FlexBox } from "../../../../components/atoms/FlexBox/FlexBox";

export interface UploadSectionProps {
  title: string;
  description: string;
  required?: boolean;
  hasFileUpload?: boolean;
  onContentChange: (content: string) => void;
  content: string;
  dataTestId?: string;
}

export const UploadSection = ({
  title,
  description,
  required,
  hasFileUpload = true,
  onContentChange,
  content,
  dataTestId,
}: UploadSectionProps): ReactElement => {
  const [file, setFile] = useState<File>();
  const { t } = useTranslation("certificatePage");

  useEffect(() => {
    file?.text().then((res) => {
      onContentChange(res);
    });
  }, [file]);

  return (
    <Container id={title.toLowerCase()}>
      <SectionTitleContainer>
        <SectionTitle>{title}</SectionTitle>
        {required && <Asterisk />}
      </SectionTitleContainer>
      <SectionDescription>{description}</SectionDescription>
      <UploadContentContainer>
        {file ? (
          <FileContainer>
            <FileName>{file.name}</FileName>
            <ButtonsContainer>
              <ViewFileButtonContainer>
                <FilePreviewModal
                  content={content || t("UPLOAD_SECTION_FILE_PLACEHOLDER")}
                />
              </ViewFileButtonContainer>
              <Button
                onClick={() => {
                  setFile(undefined);
                  onContentChange("");
                }}
                backgroundColor="red60"
                textColor="primaryAlt"
                label={t("UPLOAD_SECTION_FILE_DELETE_BUTTON")}
              />
            </ButtonsContainer>
          </FileContainer>
        ) : (
          <>
            <TextArea
              onChange={onContentChange}
              value={content}
              placeholder={t("UPLOAD_SECTION_TEXT_PLACEHOLDER")}
              testID={dataTestId}
            />
            {hasFileUpload && (
              <>
                <TextDelimiter text={t("UPLOAD_SECTION_TEXT_DIVIDER")} />
                <FileDropZone
                  onFileChange={(files) => {
                    setFile(files[0]);
                  }}
                >
                  {UploadContent}
                </FileDropZone>
              </>
            )}
          </>
        )}
      </UploadContentContainer>
    </Container>
  );
};

const Container = styled.div``;

const SectionTitle = styled.h4``;

const FileContainer = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.base};
  border-radius: 4px;
  padding: 0.5em 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileName = styled.h5``;

const ButtonsContainer = styled.div`
  display: flex;
`;

const ViewFileButtonContainer = styled.div`
  margin-right: 1em;
`;

const SectionDescription = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

const UploadContentContainer = styled.div`
  margin-top: 1em;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border-radius: 5px;
  border-color: ${({ theme }) => theme.borders.mutedLight};
  padding: 1.5em;
`;

const SectionTitleContainer = styled(FlexBox)`
  justify-content: flex-start;
`;
