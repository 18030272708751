import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Loader } from "../../../components/atoms/Loader/Loader";
import { ContentSwitcher } from "../../../components/molecules/ContentSwitcher/ContentSwitcher";
import { Transactions } from "./transactions";
import { useEnvironmentHistory } from "./useEnvironmentHistory";
import { Versions } from "./versions";
import { ModuleContent } from "../../../components/atoms/Module/Module";

const SCROLL_ID = "env_history";

export const EnvironmentHistoryRouter = (): ReactElement => {
  const { t } = useTranslation("environmentHistoryPage");
  const [versionHistory, transactionHistory] = useEnvironmentHistory();
  const history = useHistory();

  const tabs = [
    {
      label: t("ENVIRONMENT_VERSIONS_TAB"),
      value: "VERSIONS",
      hash: "#slots_history",
      dataTestId: "slots_history",
    },
    {
      label: t("TRANSACTIONS"),
      value: "TRANSACTIONS",
      hash: "#deployment_history",
      dataTestId: "deployment_history",
    },
  ];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].value);

  useEffect(() => {
    tabs.map((tab) => {
      if (tab.hash === history.location.hash) {
        setSelectedTab(tab.value);
      }
    });
  }, [history.location.hash]);

  return (
    <ModuleContent id={SCROLL_ID}>
      {versionHistory && transactionHistory ? (
        <>
          <ContentSwitcherContainer>
            <ContentSwitcher
              tabs={tabs}
              onChange={(tab) => setSelectedTab(tab.value)}
              size="xlarge"
              value={selectedTab}
            />
          </ContentSwitcherContainer>
          <RouterContainer>
            {selectedTab === tabs[0].value ? (
              <Versions versionHistory={versionHistory} />
            ) : (
              <Transactions transactionHistory={transactionHistory} />
            )}
          </RouterContainer>
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </ModuleContent>
  );
};

const RouterContainer = styled.div`
  height: 100%;
`;

const ContentSwitcherContainer = styled.div`
  margin-bottom: 2rem;
  padding: ${({ theme }) =>
    `2rem ${theme.spacings.pageContainer} 0 ${theme.spacings.pageContainer}`};
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
