import { APIError, ErrorLevel } from "../../../../models/error";
import { PropertyMetaData } from "../../../../models/metadata/properties";
import { PropertyDefinitionType, ConfigurationDetailsType } from "../types";

export const setPropertyName = (
  def: PropertyDefinitionType,
  configuration: ConfigurationDetailsType,
  newName: string,
  registryProperties: PropertyMetaData[]
): string => {
  const propIndex = registryProperties.findIndex((p) => p.name === newName);
  let _newName = "";

  // if newName is not the name of a property in the selected configuration
  if (configuration.propertyDefinitions.every((p) => p.name !== newName)) {
    if (
      propIndex !== -1 &&
      registryProperties[propIndex].primaryAlias !== def.primaryAlias
    ) {
      throw new APIError(
        "A property using the same name is currently in production.",
        "property.ts: setName",
        ErrorLevel.WARNING
      );
    } else {
      _newName = newName;
    }
  } else if (newName !== def.name) {
    // throw error name already used in config
    throw new APIError(
      "This property name is already used in this configuration",
      "property.ts: setName",
      ErrorLevel.FATAL
    );
  }

  return _newName;
};
