import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type FailedRefreshTTLComponent = FeatureComponent<
  Exclude<IFeatures["failedRefreshTTL"], undefined>
>;

export const FailedRefreshTTL: FailedRefreshTTLComponent = ({
  feature: { ttl },
  tPropertyPage,
  dataTestId,
}) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_FAILED_REFRESH_TITLE")}
      dataTestId={dataTestId}
    >
      {ttl}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
