import { TypeDefinitions } from "../../../../models/configuration/definitions/definition";
import {
  ConfigurationDefinition,
  ConfigurationDetailsType,
  GeneratedResponseDefinitionType,
  LuaDefinitionType,
  MatchLogicDefinitionType,
  OriginDefinitionType,
  ReportingOverrideDefinitionType,
  SimpleDefinitionType,
  TokenDefinitionType,
} from "../types";
import { getGeneratedResponseReferences } from "./generated-response-definition/getGeneratedResponseReferences";
import { getMatchRules } from "./getMatchRules";
import { getLuaReferences } from "./lua-definition/getLuaReferences";
import { getMatchLogicReferences } from "./match-logic-definition/getMatchLogicReferences";
import { getOriginReferences } from "./origin-definition/getOriginReferences";
import { getReportingOverrideReferences } from "./reporting-override-definition/getReportingOverrideReferences";
import { getSimpleReferences } from "./simple-definition/getSimpleReferences";
import { getTokenReferences } from "./token-definition/getTokenReferences";

export const getDefinitionReferences = (
  def: ConfigurationDefinition,
  config: ConfigurationDetailsType
): number => {
  switch (def.type) {
    case TypeDefinitions.GENERATED_RESPONSE:
      return getGeneratedResponseReferences(
        def as GeneratedResponseDefinitionType,
        config
      );

    case TypeDefinitions.HEADER:
      return getMatchRules(config)
        .map((matchRule) => matchRule?.features)
        .map((feature) => feature?.reqHeaders)
        .filter((reqHeader) => reqHeader?.nameList.includes(def.name)).length;

    case TypeDefinitions.LUA:
      return getLuaReferences(def as LuaDefinitionType, config);

    case TypeDefinitions.MATCH_LOGIC:
      return getMatchLogicReferences(def as MatchLogicDefinitionType, config);

    case TypeDefinitions.ORIGIN:
      return getOriginReferences(def as OriginDefinitionType, config);

    case TypeDefinitions.PROPERTY:
      return 0;

    case TypeDefinitions.REPORTING_OVERRIDE:
      return getReportingOverrideReferences(
        def as ReportingOverrideDefinitionType,
        config
      );

    case TypeDefinitions.SIMPLE_DEFINITION:
      return getSimpleReferences(def as SimpleDefinitionType, config);

    case TypeDefinitions.TOKEN:
      return getTokenReferences(def as TokenDefinitionType, config);

    default:
      throw new Error(`Invalid definition type. Received "${def.type}"`);
  }
};
