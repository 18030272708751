import * as Sentry from "@sentry/react";
import { Status } from "../../../@types/status";
import { IPremiumFeatures } from "../../../models/subscriber";
import { AppThunk } from "../../store";
import {
  clearSubscriberSlice,
  setPremiumFeature,
  setSubscriberStatus,
  setSubscriber,
} from "./slice";

const DEFAULT_PREMIUM_FEATURE: IPremiumFeatures = {
  premiumFeatures: {
    services: [],
    overrides: [
      "Log Collection and Delivery",
      "Log Streaming",
      "SSL Basic",
      "SSL Premium",
    ],
  },
};

export const handleSubscriber = (
  scid: string,
  subscriberId: number
): AppThunk => async (dispatch) => {
  dispatch(setSubscriberStatus(Status.LOADING));

  dispatch(
    setSubscriber({
      scid,
      subscriberId,
    })
  );

  window.localStorage.setItem("cmv3.scid", JSON.stringify(scid));
  window.localStorage.setItem("cmv3.subscriber_id", subscriberId.toString());

  dispatch(setPremiumFeature(DEFAULT_PREMIUM_FEATURE));
  dispatch(setSubscriberStatus(Status.SUCCESS));

  Sentry.setContext("subscriberContext", {
    subscriberId,
    scid,
  });
};

export const handleClearSubscriber = (): AppThunk => async (dispatch) => {
  dispatch(clearSubscriberSlice());
  window.localStorage.removeItem("cmv3.scid");
  window.localStorage.removeItem("cmv3.subscriber_id");
};
