import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { PageTitle } from "../../../../../components/atoms/PageTitle/PageTitle";
import {
  ApiKeysOwners,
  ApiKeysRoles,
} from "../../../../../models/user-management/apiKeys/apiKeys.interfaces";
import { VyvxFiberCompany } from "../../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import {
  handleGenerateSecretCdnApiKey,
  handleGenerateSecretVyvxApiKey,
} from "../../../../../store/slices/user-management-api-keys/thunk";
import { useAppDispatch } from "../../../../../store/types";
import { ApiKeyFieldType, ApiKeysCustomerFilter } from "../types";
import { ApiKeyDetailCard } from "./ApiKeyDetailCard";

interface ApiKeyDetailsProps {
  isVyvx: boolean;

  roles: ApiKeysRoles[];
  owners: ApiKeysOwners[];
  apiKeyId: number;
  keyRole: string;
  setKeyRole: (value: string) => void;
  keyOwner: string;
  setKeyOwner: (value: string) => void;
  keyCustomer: ApiKeysCustomerFilter;
  setKeyCustomer: (value: ApiKeysCustomerFilter) => void;
  keyName: string;
  setKeyName: (value: string) => void;
  keyNotes: string;
  setKeyNotes: (value: string) => void;
  keySecret: string;
  fiberCompanies?: VyvxFiberCompany[];
}
export const ApiKeyDetails = ({
  isVyvx,
  roles,
  owners,
  apiKeyId,
  keyRole,
  setKeyRole,
  keyOwner,
  setKeyOwner,
  keyCustomer,
  setKeyCustomer,
  keyName,
  setKeyName,
  keyNotes,
  setKeyNotes,
  keySecret,
  fiberCompanies,
}: ApiKeyDetailsProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const dispatch = useAppDispatch();

  const roleDropdownItems = Object.values(roles).map((roleItem) => {
    return {
      label: roleItem.name,
      value: roleItem.id,
      default: keyRole === roleItem.name ? true : false,
    };
  });

  const ownersDropdownItems = Object.values(owners).map((ownerItem) => {
    return {
      label: ownerItem.name,
      value: ownerItem.id,
      default: keyOwner === ownerItem.name ? true : false,
    };
  });

  const generateSecretApiKey = () => {
    if (isVyvx && keyCustomer.customerId) {
      dispatch(
        handleGenerateSecretVyvxApiKey(apiKeyId, keyCustomer.customerId, () => {
          toast.success(t("TOAST_GENERATE_SECRET"));
          // TODO: Refresth page / info
        })
      );
    } else if (keyCustomer.customerId) {
      dispatch(
        handleGenerateSecretCdnApiKey(
          apiKeyId,
          Number(keyCustomer.customerId),
          () => {
            toast.success(t("TOAST_GENERATE_SECRET"));
            // TODO: Refresth page / info
          }
        )
      );
    }
  };

  return (
    <>
      <PageTitle>{t("API_KEY_PAGE_DETAIL_TABLE_TITLE")}</PageTitle>
      <ApiKeyDetailCard
        title={
          isVyvx
            ? t("API_KEY_PAGE_DETAIL_TABLE_COMPANY_LABEL")
            : t("API_KEY_PAGE_DETAIL_TABLE_ACCESS_GROUP_LABEL")
        }
        fieldType={ApiKeyFieldType.CustomerDropdown}
        fieldProps={{
          value: keyCustomer,
          onSelect: setKeyCustomer,
        }}
        fiberCompanies={fiberCompanies}
        divider
        isVyvx={isVyvx}
      />
      <ApiKeyDetailCard
        title={t("API_KEY_PAGE_DETAIL_TABLE_ROLE_LABEL")}
        fieldType={ApiKeyFieldType.DropdownType}
        fieldProps={{
          placeholder: keyRole,
          items: roleDropdownItems,
          onSelect: (item) => setKeyRole(item.label),
        }}
        divider
        isVyvx={isVyvx}
      />
      <ApiKeyDetailCard
        title={t("API_KEY_PAGE_DETAIL_TABLE_NAME_LABEL")}
        fieldType={ApiKeyFieldType.InputFieldType}
        fieldProps={{
          value: keyName,
          onChange: setKeyName,
        }}
        divider
        isVyvx={isVyvx}
      />
      <ApiKeyDetailCard
        maxWidth={300}
        title={t("API_KEY_PAGE_DETAIL_TABLE_SECRET_LABEL")}
        fieldType={ApiKeyFieldType.SecretType}
        fieldProps={{
          disabled: true,
          value: keySecret,
        }}
        itemToCopy={keySecret}
        handleSecretGeneration={generateSecretApiKey}
        divider
        isVyvx={isVyvx}
      />
      <ApiKeyDetailCard
        title={t("API_KEY_PAGE_DETAIL_TABLE_OWNER_LABEL")}
        additionalInfoTitle={t("API_KEY_PAGE_DETAIL_TABLE_OWNER_INFO_TITLE")}
        additionalInfoContent={t(
          "API_KEY_PAGE_DETAIL_TABLE_OWNER_INFO_DETAILS"
        )}
        fieldType={ApiKeyFieldType.DropdownType}
        fieldProps={{
          items: ownersDropdownItems,
          placeholder: keyOwner,
          onSelect: (item) => setKeyOwner(item.label),
        }}
        divider
        isVyvx={isVyvx}
      />
      <ApiKeyDetailCard
        title={t("API_KEY_PAGE_DETAIL_TABLE_NOTES_LABEL")}
        fieldType={ApiKeyFieldType.TextArea}
        fieldProps={{
          value: keyNotes,
          onChange: setKeyNotes,
        }}
        divider
        isVyvx={isVyvx}
      />
    </>
  );
};
