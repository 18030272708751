import type { TFunction } from "react-i18next";

import type {
  CCHO,
  CCHOExt,
  CCHOInt,
} from "../../../models/configuration/definitions/matchlogic";
import { VALID_STRINGS } from "../../../modules/caching/configurations/configuration_page/match_rules/match_rules_page/features/list/feature_block/cchoFeatureBlock/constants";
import { isString } from "../../../utils/string";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import type { FeatureComponent } from "./types";

type CacheControlHeaderOverrideComponent = FeatureComponent<CCHO>;

type BaseProps = {
  dataTestId: string;
  tPropertyPage: TFunction<"configurationPropertyPage">;
};

type IntCCHOProps = CCHOInt &
  BaseProps & {
    isExt: boolean;
  };

type ExtCCHOProps = CCHOExt & BaseProps;

const IntCCHO = ({
  cachePolicy,
  isExt,
  tPropertyPage,
  dataTestId,
}: IntCCHOProps) => {
  const isTTL = !isString(cachePolicy, VALID_STRINGS);

  return (
    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_CCHO_INTERNAL_TITLE")}
        dataTestId={`${dataTestId}-ccho-int-type-title`}
      >
        {tPropertyPage(
          isTTL ? "FEATURE_CARD_CCHO_TTL" : "FEATURE_CARD_CCHO_NON_TTL_OPTION"
        )}
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={
          tPropertyPage("FEATURE_CARD_CCHO_INTERNAL") +
          " " +
          tPropertyPage(
            isTTL ? "FEATURE_CARD_CCHO_TTL" : "FEATURE_CARD_CCHO_NON_TTL"
          )
        }
        dataTestId={`${dataTestId}-ccho-int-policy-title`}
      >
        {cachePolicy}
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_CCHO_EXTERNAL")}
        dataTestId={`${dataTestId}-ccho-int-external-title`}
      >
        {`${isExt}`}
      </DetailedFeatureCardBlock>
    </DetailedFeatureCardRow>
  );
};

const ExtCCHO = ({
  cachePolicy,
  force,
  tPropertyPage,
  dataTestId,
}: ExtCCHOProps) => {
  const isTTL = !isString(cachePolicy, VALID_STRINGS);

  return (
    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_CCHO_EXTERNAL_TITLE")}
        dataTestId={`${dataTestId}-ccho-ext-type-title`}
      >
        {tPropertyPage(
          isTTL ? "FEATURE_CARD_CCHO_TTL" : "FEATURE_CARD_CCHO_NON_TTL_OPTION"
        )}
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={
          tPropertyPage("FEATURE_CARD_CCHO_EXTERNAL") +
          " " +
          tPropertyPage(
            isTTL ? "FEATURE_CARD_CCHO_TTL" : "FEATURE_CARD_CCHO_NON_TTL"
          )
        }
        dataTestId={`${dataTestId}-ccho-ext-policy-title`}
      >
        {cachePolicy}
      </DetailedFeatureCardBlock>

      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_CCHO_FORCE_TITLE")}
        dataTestId={`${dataTestId}-ccho-ext-force`}
      >
        {`${!!force}`}
      </DetailedFeatureCardBlock>
    </DetailedFeatureCardRow>
  );
};

export const CacheControlHeaderOverride: CacheControlHeaderOverrideComponent = ({
  feature: { int, ext },
  tPropertyPage,
  dataTestId,
}) => {
  const isExt = !!ext;

  return (
    <>
      {!!int && (
        <IntCCHO
          {...int}
          isExt={isExt}
          tPropertyPage={tPropertyPage}
          dataTestId={dataTestId}
        />
      )}

      {isExt && (
        <ExtCCHO
          {...(ext as ExtCCHOProps)}
          tPropertyPage={tPropertyPage}
          dataTestId={dataTestId}
        />
      )}
    </>
  );
};
