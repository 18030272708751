import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import { useAppDispatch } from "../../../store/types";
import {
  fetchVyvxApiKey,
  fetchVyvxApiKeys,
  fetchVyvxApiKeysRoles,
  fetchVyvxApiKeysOwners,
  fetchCdnApiKeysOwners,
  fetchCdnApiKeysRoles,
  fetchCdnApiKeys,
  fetchCdnApiKey,
} from "../../../store/slices/user-management-api-keys/thunk";
import { useUserVyvxId } from "../../../store/slices/user/hooks";
import {
  useCdnApiKeysStatus,
  useCdnApiKeyStatus,
  useVyvxApiKeysStatus,
  useVyvxApiKeyStatus,
} from "../../../store/slices/user-management-api-keys/hooks";
import { Status } from "../../../@types/status";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";
import { useQuery } from "../../../hooks/use-query";
import { useLocalStorage } from "../../../hooks/use-local-storage";

export const CdnApiKeysLayer: FC = ({ children }) => {
  const { t } = useTranslation("layers");
  const dispatch = useAppDispatch();
  const loadingStatus = useCdnApiKeysStatus();

  const [queryAccessGroupId] = useQuery("access_group_id");
  const [localStorageAccessGroupId] = useLocalStorage<string>(
    "cmv3.access_group_id"
  );

  useEffect(() => {
    dispatch(
      fetchCdnApiKeys(
        queryAccessGroupId
          ? parseInt(queryAccessGroupId, 10)
          : parseInt(localStorageAccessGroupId, 10)
      )
    );
    dispatch(
      fetchCdnApiKeysOwners(
        queryAccessGroupId
          ? parseInt(queryAccessGroupId, 10)
          : parseInt(localStorageAccessGroupId, 10)
      )
    );
    dispatch(fetchCdnApiKeysRoles());
  }, []);

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("API_KEY_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (loadingStatus === Status.ERROR) {
    return <ErrorLayer dataTestId="cdn-api-keys-layer-error" />;
  }

  return <>{children}</>;
};

export const CdnApiKeyLayer: FC = ({ children }) => {
  const { t } = useTranslation("layers");
  const dispatch = useAppDispatch();
  const loadingStatus = useCdnApiKeyStatus();

  const [queryAccessGroupId] = useQuery("access_group_id");
  const [localStorageAccessGroupId] = useLocalStorage<string>(
    "cmv3.access_group_id"
  );
  const match = useRouteMatch<{ apiKeyId: string }>([
    "/userManagement/apiKeys/cdn/:apiKeyId",
  ]);

  useEffect(() => {
    if (match?.params.apiKeyId) {
      dispatch(
        fetchCdnApiKey(
          queryAccessGroupId
            ? parseInt(queryAccessGroupId, 10)
            : parseInt(localStorageAccessGroupId, 10),
          parseInt(match.params.apiKeyId, 10)
        )
      );
      dispatch(
        fetchCdnApiKeysOwners(
          queryAccessGroupId
            ? parseInt(queryAccessGroupId, 10)
            : parseInt(localStorageAccessGroupId, 10)
        )
      );
      dispatch(fetchCdnApiKeysRoles());
    }
  }, [match?.params.apiKeyId]);

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("API_KEY_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (loadingStatus === Status.ERROR) {
    return <ErrorLayer dataTestId="cdn-api-key-layer-error" />;
  }

  return <>{children}</>;
};

export const VyvxApiKeysLayer: FC = ({ children }) => {
  const { t } = useTranslation("layers");
  const dispatch = useAppDispatch();
  const companyId = useUserVyvxId();
  const loadingStatus = useVyvxApiKeysStatus();

  useEffect(() => {
    dispatch(fetchVyvxApiKeysRoles());
    if (companyId) {
      dispatch(fetchVyvxApiKeys(companyId));
      dispatch(fetchVyvxApiKeysOwners(companyId));
    }
  }, [companyId]);

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("API_KEY_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (loadingStatus === Status.ERROR) {
    return <ErrorLayer dataTestId="vyvx-api-keys-layer-error" />;
  }

  return <>{children}</>;
};

export const VyvxApiKeyLayer: FC = ({ children }) => {
  const { t } = useTranslation("layers");
  const dispatch = useAppDispatch();
  const companyId = useUserVyvxId();
  const loadingStatus = useVyvxApiKeyStatus();

  const match = useRouteMatch<{ apiKeyId: string }>([
    "/userManagement/apiKeys/vyvx/:apiKeyId",
  ]);

  useEffect(() => {
    dispatch(fetchVyvxApiKeysRoles());
    if (companyId) {
      dispatch(fetchVyvxApiKey(companyId, Number(match?.params.apiKeyId)));
      dispatch(fetchVyvxApiKeysOwners(companyId));
    }
  }, []);

  if (loadingStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("API_KEY_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (loadingStatus === Status.ERROR) {
    return <ErrorLayer dataTestId="vyvx-api-key-layer-error" />;
  }

  return <>{children}</>;
};
