import { exitMimick } from "./thunks";
import { useAppDispatch, useAppSelector } from "./../../types";

/* dispatch */
export const useDispatchExitMimick = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(exitMimick());
};

/* selectors */
export const useUserIsMimickUNSAFE = () =>
  useAppSelector((store) => store.user.value?.isMimick);

export const useUserFirstNameUNSAFE = (): string | undefined =>
  useAppSelector((store) => store.user.value?.firstName);

export const useUserLastNameUNSAFE = (): string | undefined =>
  useAppSelector((store) => store.user.value?.lastName);

export const useUserOriginalAuthenticatedFirstnameUNSAFE = ():
  | string
  | null
  | undefined =>
  useAppSelector((store) => store.user.value?.originalAuthenticatedFirstname);

export const useUserId = () => {
  const userId = useAppSelector((store) => store.user.value!.userId);
  if (userId === undefined) {
    throw new Error("'userId' is undefined in user");
  }

  return userId;
};

export const useUserEmail = () => {
  const email = useAppSelector((store) => store.user.value!.email);
  if (email === undefined) {
    throw new Error("'email' is undefined in user");
  }

  return email;
};

export const useUserStatus = () => {
  return useAppSelector((store) => store.user.status);
};

export const useUserVyvxType = () => {
  const userType = useAppSelector((store) => store.user.value!.vyvxUserType);
  if (userType === undefined) {
    throw new Error("'vyvxUserType' is undefined in user");
  }

  return userType;
};

export const useUserVyvxId = () => {
  const userVyvxId = useAppSelector(
    (store) => store.user.value!.vyvxFiberCompanyAbbr
  );
  if (userVyvxId === undefined) {
    throw new Error("'vyvxFiberCompanyAbbr' is undefined in user");
  }
  return userVyvxId;
};

export const useIsUserInternal = (): boolean => {
  const ldapUserName = useAppSelector(
    (store) => store.user.value?.ldapUsername
  );
  if (ldapUserName === undefined) {
    throw new Error("'value' is undefined in user");
  }
  return !!ldapUserName;
};
