import styled from "@emotion/styled";
import { FC, ComponentType } from "react";
import { Redirect, Route } from "react-router-dom";

import { UserRoles } from "../../../models/permissions";
import { ExistingFeatures } from "../../../store/slices/feature-flags/types";
import { Loader } from "../Loader/Loader";
import { PermissionStatus, usePermissions } from "./usePermissions";

interface IProtectedRouteProps {
  path: string;
  redirect: string;
  permissions?: UserRoles | UserRoles[];
  flags?: ExistingFeatures;
  component?: ComponentType;
}

export const ProtectedRoute: FC<IProtectedRouteProps> = ({
  path,
  redirect,
  permissions,
  flags,
  component,
}) => {
  const status = usePermissions({
    permissions,
    flags,
  });
  switch (status) {
    case PermissionStatus.AWAITING:
      return (
        <Route
          path={path}
          component={() => (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        />
      );
    case PermissionStatus.AUTHORIZED:
      return <Route path={path} component={component} />;
    case PermissionStatus.DENIED:
      return (
        <Route path={path}>
          <Redirect to={redirect} />
        </Route>
      );
    default:
      throw new Error(`Unknown protection status: ${status}`);
  }
};

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;
