import type { ReactElement } from "react";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { CachePolicy } from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import type { FormProps } from "./types";

export const IntNonTTL = ({
  disabled,
  t,
  register,
}: Omit<FormProps, "tMisc" | "maxWidth" | "unregister">): ReactElement => (
  <PropertyDefinitionCard
    title={t("FEATURE_CARD_CCHO_ICP_TYPE_STRING_TITLE")}
    asterisk
    additionalInfoTitle={t(
      "FEATURE_CARD_CCHO_ICP_TYPE_STRING_DESCRIPTION_TITLE"
    )}
    additionalInfoContent={t(
      "FEATURE_CARD_CCHO_ICP_TYPE_STRING_DESCRIPTION_CONTENT"
    )}
    divider
    dataTestId="ccho-icp-type-string"
    fieldType={FieldType.OtherType}
    fieldProps={{
      other: (
        <RadioButtonsSelector
          inline
          headlessRadio={true}
          radioButtonsProps={[
            {
              ...register("cacheControlHeaderOverride.int.cachePolicy"),
              value: CachePolicy.NO_CACHE,
              disabled,
              label: t("CACHE_POLICY_STRING_NO_CACHE_LABEL"),
              id: "ccho-int-string-no-cache",
              dataTestId: "ccho-int-string-no-cache-radio",
            },
            {
              ...register("cacheControlHeaderOverride.int.cachePolicy"),
              value: CachePolicy.NO_STORE,
              disabled,
              label: t("CACHE_POLICY_STRING_NO_STORE_LABEL"),
              id: "ccho-int-no-store",
              dataTestId: "ccho-int-no-store-radio",
            },
            {
              ...register("cacheControlHeaderOverride.int.cachePolicy"),
              value: CachePolicy.AS_IS,
              disabled,
              label: t("CACHE_POLICY_STRING_AS_IS_LABEL"),
              id: "ccho-int-as-is",
              dataTestId: "ccho-int-as-is-radio",
            },
          ]}
        />
      ),
    }}
  />
);
