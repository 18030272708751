import { FC, useState, useEffect } from "react";
import styled from "@emotion/styled";

import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { DropdownMenu } from "../../atoms/Dropdown/DropdownMenu";
import { DropDownContent } from "../Dropdown/DropdownContent";
import { Icons } from "../../atoms/Icon/Icon";
import { RotatingIcon } from "../../atoms/RotatingIcon/RotatingIcon";
import {
  BaseDropdownSelect,
  BaseLabel,
} from "../../atoms/BaseDropdown/BaseDropdownSelect";
import { Input } from "../../atoms/Input/Input";
import { useDropdown } from "../Dropdown/useDropdown";

export interface IDropDownProps {
  items: IDropdownItem[];
  placeholder: string;
  multiSelect?: boolean;
  selectedItems: IDropdownItem[];
  setSelectedItems: (selection: IDropdownItem[]) => void;
  className?: string;
  dataTestId?: string;
}

export const SearchDropDown: FC<IDropDownProps> = ({
  items,
  placeholder,
  multiSelect = false,
  selectedItems,
  setSelectedItems,
  className,
  dataTestId,
}) => {
  const [filteredIems, setFilteredItems] = useState(items);
  const [search, setSearch] = useState("");
  const {
    isOpen,
    setIsOpen,
    dropdownRef,
    opening,
    handleSelection,
  } = useDropdown({
    items: filteredIems,
    selectedItems,
    setSelectedItems,
    multiSelect,
    onClose: () => {
      setSearch("");
    },
  });

  useEffect(() => {
    if (!search) {
      setFilteredItems(items);
    } else {
      setFilteredItems(
        items.filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search]);

  return (
    <div className={`chi-dropdown ${className}`} ref={dropdownRef}>
      <BaseDropdownSelect className="base-dropdown-select" isOpen={isOpen}>
        <Label
          isOpen={isOpen}
          label={
            selectedItems.length !== 0 && !!selectedItems[0].label
              ? selectedItems[0].label
              : placeholder
          }
        />

        <RotatingIcon
          name={Icons.CHEVRON_DOWN}
          color="secondary"
          onClick={() => {
            setIsOpen((current) => !current);
          }}
          isOpen={isOpen}
          dataTestId={`${dataTestId}-button`}
        />
      </BaseDropdownSelect>

      {opening(
        ({ opacity }, show) =>
          show && (
            <DropdownMenu style={{ opacity }} className="chi-dropdown__menu">
              <SearchLabel value={search} onChange={setSearch} />
              <DropDownContent
                items={filteredIems}
                isOpen={isOpen}
                onSelect={handleSelection}
                selectedItems={selectedItems || []}
                dataTestId={dataTestId}
              />
            </DropdownMenu>
          )
      )}
    </div>
  );
};

const StyledInput = styled(Input)`
  width: 90%;
  margin: 1% 5%;
`;

const Label = ({ isOpen, label }: { isOpen: boolean; label?: string }) => (
  <BaseLabel isOpen={isOpen}>{label}</BaseLabel>
);

const SearchLabel = ({
  onChange,
  value,
}: {
  value: string;
  onChange: (search: string) => void;
}) => (
  <StyledInput
    value={value}
    onChange={(e) => onChange(e.currentTarget.value)}
  />
);
