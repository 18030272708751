import { useState } from "react";

import { IBanner } from "../../../models/banner";
import { BannerModal } from "../BannerModal/BannerModal";

export interface IBannersProps {
  banners: IBanner[];
}

export const Banners: React.FC<IBannersProps> = ({ banners }) => {
  const [activeBanners, setActiveBanners] = useState<IBanner[]>(banners);

  let idx = 0;
  banners.forEach((b, i) => {
    if (b.priority) idx = i;
  });

  return (
    <>
      {activeBanners.length > 0 && (
        <BannerModal
          id={banners[idx].id}
          notificationId={banners[idx].notificationId}
          name={banners[idx].name}
          body={banners[idx].message}
          onAcknowledge={() => {
            setActiveBanners(activeBanners.slice(1));
          }}
        />
      )}
    </>
  );
};
