import styled from "@emotion/styled";
import { Icon, Icons, IconProps } from "../Icon/Icon";

export const BreadcrumbArrow = (
  props: Omit<IconProps, "name">
): JSX.Element => (
  <SmallArrow
    size={{ width: 4, height: 8 }}
    {...props}
    name={Icons.ARROW_SMALL}
  />
);

const SmallArrow = styled(Icon)`
  margin: 6px 10px;

  & > path {
    fill: currentcolor;
  }
`;
