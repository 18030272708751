import type { ReactElement } from "react";
import type { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import type { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import { useIsViewMode } from "../../../../../../../../../../store/slices/permissions/hooks";
import { uriRegex } from "../../../../../../../../../../utils/regex";
import { ValidationErrors } from "../../types";
import { FeatureBlock } from "../FeatureBlock";
import { HeadersTable, HeadersTableProps } from "./HeadersTable";

interface DenyPolicyFeatureBlockProps
  extends Pick<
    HeadersTableProps,
    "register" | "handleCreateFeature" | "setError" | "clearErrors"
  > {
  datatestId: string;
  errors: ValidationErrors["denyPolicy"];
  initIsOpen: boolean;
  maxWidth: number;
  onDelete: () => void;
  denyPolicy: Exclude<IFeatures["denyPolicy"], undefined>;
}

export const DenyPolicyFeatureBlock = ({
  clearErrors,
  datatestId,
  denyPolicy,
  errors,
  handleCreateFeature,
  initIsOpen,
  maxWidth,
  onDelete,
  register,
  setError,
}: DenyPolicyFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();

  const denialAction = denyPolicy.denial.action;
  const isRedirect = denialAction === "redirect";

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_DENY_POLICY_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_DENY_POLICY_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_DENY_POLICY_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_DENY_POLICY_HEADERS_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_DENY_POLICY_HEADERS_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_DENY_POLICY_HEADERS_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            maxWidth={800}
            fieldProps={{
              other: (
                <HeadersTable
                  clearErrors={clearErrors}
                  disabled={isViewMode}
                  errors={errors?.headers}
                  handleCreateFeature={handleCreateFeature}
                  headers={denyPolicy.headers ?? []}
                  register={register}
                  setError={setError}
                />
              ),
            }}
            divider
            dataTestId="deny-policy-headers"
          />

          <PropertyDefinitionCard
            title={t("FEATURE_CARD_DENY_POLICY_ACTION_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_DENY_POLICY_ACTION_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_DENY_POLICY_ACTION_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      ...register("denyPolicy.denial.action"),
                      disabled: isViewMode,
                      value: "redirect",
                      label: t("FEATURE_CARD_DENY_POLICY_ACTION_RADIO_1_LABEL"),
                      id: "deny-action-redirect",
                      dataTestId: "deny-policy-redirect-radio",
                    },
                    {
                      ...register("denyPolicy.denial.action"),
                      value: "error",
                      disabled: isViewMode,
                      label: t("FEATURE_CARD_DENY_POLICY_ACTION_RADIO_2_LABEL"),
                      id: "deny-action-error",
                      dataTestId: "deny-policy-error-radio",
                      onClick: () => {
                        handleCreateFeature("denyPolicy.denial", {
                          action: "error",
                        });
                      },
                    },
                  ]}
                />
              ),
            }}
            divider={isRedirect}
            dataTestId="deny-policy-action"
          />

          {isRedirect && (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_DENY_POLICY_REDIRECT_URL_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_DENY_POLICY_REDIRECT_URL_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_DENY_POLICY_REDIRECT_URL_DESCRIPTION_CONTENT"
              )}
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <HeadlessInputField
                    disabled={isViewMode}
                    placeholder={t(
                      "FEATURE_CARD_DENY_POLICY_REDIRECT_URL_PLACEHOLDER"
                    )}
                    {...register("denyPolicy.denial.redirectUrl", {
                      required: tMisc("ERROR_REQUIRED_FIELD"),
                      pattern: {
                        value: new RegExp(uriRegex),
                        message: tMisc("ERROR_MUST_BE_URL"),
                      },
                    })}
                    data-testid="deny-policy-redirect-url-input"
                  />
                ),
              }}
              maxWidth={maxWidth}
              errorMessage={
                (errors?.denial as
                  | {
                      action?: FieldError | undefined;
                      redirectUrl?: FieldError | undefined;
                    }
                  | undefined)?.redirectUrl?.message
              }
              dataTestId="deny-policy-redirect-url"
              asterisk
            />
          )}
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName="denyPolicy"
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="deny-policy"
      featureType={FeatureTypes.ACCESS_CONTROL}
    />
  );
};
