import { Status } from "../../../@types/status";
import { CertificateState } from "../../../models/certificate";
import { IVersionHistory } from "../../../models/configuration/configuration_details.interfaces";
import {
  IGeneratedResponseHeader,
  IHeaderDefinitionHeader,
  IMatchRule,
} from "../../../models/configuration/definitions";
import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { LuaVariable } from "../../../models/configuration/definitions/lua";
import {
  IMatchBlock,
  MatchBlocks,
} from "../../../models/configuration/definitions/matchlogic";
import {
  IMember,
  IOrigin,
  OriginTypes,
} from "../../../models/configuration/definitions/origin";
import {
  IAliasOverride,
  IProtocolSetting,
  Logging,
  TrafficType,
} from "../../../models/configuration/definitions/property";
import { ListType } from "../../../models/configuration/definitions/simpleDefinition";
import { IConfigurationError } from "../../../models/configuration/errors";
import {
  IActiveConfigurationsResponse,
  IProductionMigrationData,
} from "../../../models/configuration/production";
import { PropertyMetaData } from "../../../models/metadata/properties";

export interface CachingSlice {
  properties: PropertyMetaData[];
  configurations: ConfigurationType[];
  production: Production | undefined;
  selectedConfiguration: ConfigurationType | undefined;
  errors: IConfigurationError[];
  certificates: CertificateType[];
  status: Status;
  apiErrorCode?: number;
  configDetailsStatus: Status;
}

export enum UpdateVersionActions {
  DISCARD = "discard",
  COMMIT = "commit",
}

export interface ConfigurationDeleteResponseData
  extends Required<
    Pick<
      ConfigurationResponseData,
      | "comment"
      | "configName"
      | "schemaVersion"
      | "subscriberId"
      | "transactionId"
      | "versionId"
    >
  > {
  href: string;
}

export interface ConfigurationResponseData {
  configName: string;
  subscriberId: number;
  transactionId?: string;
  comment?: string;
  createdTime: string;
  modifiedTime?: string;
  schemaVersion?: string;
  versionId: number;
}

export interface ConfigurationType extends ConfigurationResponseData {
  unhandledFields: [string, any][];
  id: string;
  isLocalDraft: boolean;
  config?: ConfigurationDetailsType;
  versions: IVersionHistory[];
  errors: IConfigurationError[];
}

export interface ConfigurationDetailsType
  extends Pick<ConfigDetailsResponseData, "genSchemaVersion" | "subscriberId"> {
  unhandledFields: [string, any][];
  generatedResponseDefinitions: GeneratedResponseDefinitionType[];
  headerDefinitions: HeaderDefinitionType[];
  luaDefinitions: LuaDefinitionType[];
  matchLogicDefinitions: MatchLogicDefinitionType[];
  originFillPolicyDefinitions: OriginDefinitionType[];
  propertyDefinitions: PropertyDefinitionType[];
  reportingOverrideDefinitions: ReportingOverrideDefinitionType[];
  simpleListDefinitions: SimpleDefinitionType[];
  tokenAuthenticationDefinitions: TokenDefinitionType[];
}

export interface ConfigDetailsResponseData {
  genSchemaVersion: string;
  subscriberId: number;
  generatedResponseDefinitions?: GeneratedResponseDefinitionsType;
  headerDefinitions?: HeaderDefinitionsType;
  luaDefinitions?: LuaDefinitionsType;
  matchLogicDefinitions?: MatchLogicDefinitionsType;
  originFillPolicyDefinitions?: OriginDefinitionsType;
  propertyDefinitions?: PropertyDefinitionsType;
  reportingOverrideDefinitions?: ReportingOverrideDefinitionsType;
  simpleListDefinitions?: SimpleDefinitionsType;
  tokenAuthenticationDefinitions?: TokenDefinitionsType;
}

interface TokenDefinitionsType {
  [key: string]: TokenDefinitionType;
}

export interface TokenDefinitionType extends DefinitionType {
  sharedSecrets: string[];
  queryParameterNames?: string[];
  hash?: string;
  nva?: string;
  nvb?: string;
  datePreference: "gmt" | "epoch";
  queryParameterControl: "include" | "exclude";
}

interface SimpleDefinitionsType {
  [key: string]: SimpleDefinitionType;
}

export interface SimpleDefinitionType extends DefinitionType {
  listType: ListType;
  elements: string[];
}

interface ReportingOverrideDefinitionsType {
  [key: string]: ReportingOverrideDefinitionType;
}

export interface ReportingOverrideDefinitionType extends DefinitionType {
  reportingName: string;
}

interface PropertyDefinitionsType {
  [key: string]: PropertyDefinitionResponseType;
}

export interface PropertyDefinitionType extends DefinitionType {
  signature?: string;
  matchLogic?: string;
  trafficType?: TrafficType;
  primaryAlias?: string;
  aliases?: string[];
  aliasOverrides?: IAliasOverride[];
  protocolSettings: IProtocolSetting;
  logging?: Logging;
  originFillPolicy?: string;
}

export interface PropertyDefinitionResponseType extends DefinitionResponseType {
  signature?: string;
  matchLogic?: string;
  trafficType?: TrafficType;
  primaryAlias?: string;
  aliases?: string[];
  aliasOverrides?: IAliasOverride[];
  protocolSettings: IProtocolSetting;
  logging?: Logging;
  originFillPolicy?: string;
}

interface OriginDefinitionsType {
  [key: string]: OriginDefinitionResponseType;
}

export interface OriginDefinitionType extends DefinitionType {
  members: IMember[];
  conditions?: IOrigin;
  originType: OriginTypes;
}

interface OriginDefinitionResponseType
  extends Omit<OriginDefinitionType, "type"> {
  type: OriginTypes;
}

interface MatchLogicDefinitionsType {
  [key: string]: MatchLogicDefinitionResponseType;
}

export interface MatchLogicDefinitionResponseType extends DefinitionType {
  matchBlocks: {
    [MatchBlocks.CLIENT_REQ]?: IMatchBlock;
    [MatchBlocks.ORIGIN_REQ]?: IMatchBlock;
    [MatchBlocks.ORIGIN_RESP]?: IMatchBlock;
  };
}

export interface MatchLogicDefinitionType extends DefinitionType {
  matchBlocks: {
    [MatchBlocks.CLIENT_REQ]?: IMatchBlock;
    [MatchBlocks.ORIGIN_REQ]?: IMatchBlock;
    [MatchBlocks.ORIGIN_RESP]?: IMatchBlock;
  };
}

interface GeneratedResponseDefinitionsType {
  [key: string]: GeneratedResponseDefinitionType;
}

export interface GeneratedResponseDefinitionType extends DefinitionType {
  headers?: IGeneratedResponseHeader[];
  mimeType?: string;
  payload?: string;
  reason?: string;
  statusCode: number;
}

interface LuaDefinitionsType {
  [key: string]: LuaDefinitionType;
}

export interface LuaDefinitionType extends DefinitionType {
  heavy?: boolean;
  script: string;
  signature?: string;
  variables?: LuaVariable[];
}

interface HeaderDefinitionsType {
  [key: string]: HeaderDefinitionType;
}

export interface HeaderDefinitionType extends DefinitionType {
  headers: IHeaderDefinitionHeader[];
}

export interface DefinitionType extends DefinitionResponseType {
  unhandledFields: [string, any][];
  id: string;
  name: string;
  type: TypeDefinitions;
  _schemaDescription?: string;
}

export interface DefinitionResponseType {
  description?: string;
}

export interface PrepareOptionsType<T extends DefinitionType = DefinitionType> {
  definitions?: T[];
  deadPathExceptions?: string[];
}

export type LocalStoredConfigurationType = ConfigurationResponseData & {
  config?: ConfigDetailsResponseData;
};

export interface ILocalStoredData {
  [subscriberId: string]: LocalStoredConfigurationType[];
}

export type ConfigurationDefinitions =
  | GeneratedResponseDefinitionType[]
  | HeaderDefinitionType[]
  | LuaDefinitionType[]
  | MatchLogicDefinitionType[]
  | OriginDefinitionType[]
  | PropertyDefinitionType[]
  | ReportingOverrideDefinitionType[]
  | SimpleDefinitionType[]
  | TokenDefinitionType[];

export type ConfigurationDefinition = ConfigurationDefinitions[number];

export interface CertificateResponseType {
  aliases: any | null;
  auto_renew: any | null;
  cert_bundle: string | null;
  cert_data: any | null;
  cert_name: string;
  cert_states: CertificateState[];
  certificate: string | null;
  creation_time: string;
  fingerprint: string | null;
  href: string;
  issuer: string | null;
  modified_time: string;
  new_aliases: string[];
  new_cert: null;
  new_cert_data: string | any;
  new_subject: any | null;
  renewal_failure_count: any | null;
  revoke_failure_count: any | null;
  serial_number: string | null;
  state: number;
  subject: string | null;
  transaction_id: any | null;
  type: string;
  validity_not_after: any | null;
  validity_not_before: any | null;
  version_id: number;
}

export interface CertificateType
  extends Omit<
    CertificateResponseType,
    | "aliases"
    | "cert_data"
    | "new_cert_data"
    | "new_subject"
    | "renewal_failure_count"
    | "revoke_failure_count"
    | "transaction_id"
  > {
  aliases: string | null;
  cert_data: string | null;
  new_cert_data: string;
  new_subject: string | null;
  renewal_failure_count: number | null;
  revoke_failure_count: number | null;
  transaction_id: number | null;
}

export interface CreateOriginDefinitionType
  extends Omit<OriginDefinitionType, "originType" | "type"> {
  type: OriginDefinitionType["originType"];
}

export interface Production {
  productionData?: IActiveConfigurationsResponse;
  migrationData?: IProductionMigrationData;
  isMigration: boolean;
}

export interface IIsDeployedData {
  current?: number;
  candidate?: number;
}

export interface MatchRulePayload {
  mldName: string;
  matchBlockQueried: MatchBlocks;
  matchGroupQueried: string;
  matchRuleQueried: string;
  matchRule: IMatchRule;
}
