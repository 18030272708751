import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "react-spring";

import { EmptyPage } from "../../../../components/atoms/EmptyPage/EmptyPage";
import { Loader } from "../../../../components/atoms/Loader/Loader";
import { Protected } from "../../../../components/atoms/Protected/Protected";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { InputField } from "../../../../components/molecules/InputField/InputField";
import { ConfigurationList } from "../../../../components/organisms/ConfigurationList/ConfigurationList";
import { GuidanceModal } from "../../../../components/organisms/GuidanceModal/GuidanceModal";
import { UserRoles } from "../../../../models/permissions";
import { ConfigurationNameModal } from "../configuration_page/ConfigurationNameModal";

import EmptyPageDarkSrc from "../../../../assets/images/empty_page_dark.png";
import EmptyPageLightSrc from "../../../../assets/images/empty_page_light.png";
import { useConfigurations } from "../../../../store/slices/caching/hooks";
import { FlexBox } from "../../../../components/atoms/FlexBox/FlexBox";
import { ModuleContent } from "../../../../components/atoms/Module/Module";

const PLACEHOLDER_IMAGES = { light: EmptyPageLightSrc, dark: EmptyPageDarkSrc };
const SCROLL_ID = "configuration_list";

export const ConfigurationsList = (): ReactElement => {
  const configurations = useConfigurations();

  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation("configurationsPage");

  const appearsAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <ModuleContent id={SCROLL_ID}>
      <ConfigurationsContainer style={appearsAnimation}>
        {!configurations ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : configurations.length > 0 ? (
          <ScrollableListContainer>
            <FlexBox>
              <InputFieldContainer>
                <InputField
                  hasBorder
                  onChange={setSearchValue}
                  placeholder={t("CONFIGURATION_SEARCH_BAR_PLACEHOLDER")}
                  isSearchField={true}
                />
              </InputFieldContainer>
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <ConfigurationNameModal />
              </Protected>
            </FlexBox>
            <ExpandableContent
              title={t("CONFIGURATION_DESCRIPTION_TITLE")}
              content={t("CONFIGURATION_DESCRIPTION_CONTENT")}
            />
            <ConfigurationList
              configurations={configurations}
              searchedValue={searchValue}
            />
          </ScrollableListContainer>
        ) : (
          <EmptyPage
            images={PLACEHOLDER_IMAGES}
            title={t("EMPTY_CONFIGURATION_LIST_TITLE")}
            content={t("EMPTY_CONFIGURATION_LIST_CONTENT")}
          >
            <GuidanceModal
              buttonLabel={t("GUIDANCE_BUTTON_LABEL")}
              title={t("GUIDANCE_MODAL_TITLE")}
              content={t("GUIDANCE_MODAL_CONTENT")}
            />
            <Protected permissions={UserRoles.EDIT_CONFIG}>
              <Margin />
              <ConfigurationNameModal />
            </Protected>
          </EmptyPage>
        )}
      </ConfigurationsContainer>
    </ModuleContent>
  );
};

const ConfigurationsContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled(FlexBox)`
  margin-top: 10em;
`;

const InputFieldContainer = styled.span`
  width: 100%;
`;

const ScrollableListContainer = styled.div`
  padding: ${({ theme }) =>
    `2rem ${theme.spacings.pageContainer} 0 ${theme.spacings.pageContainer}`};
`;

const Margin = styled.span`
  margin: 1rem;
`;
