import { AxiosError } from "axios";

import { httpClient } from "../../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../../../models/error";
import { IReport } from "../report/models";

export interface ValueWithPercentage {
  value: number;
  percentage?: number;
}

export enum Metrics {
  mbPerSec = "mbPerSec",
  reqPerSec = "reqPerSec",
  missMbPerSec = "missMbPerSec",
  missPerSec = "missPerSec",
  fourZeroThreePerSec = "fourZeroThreePerSec",
  fourZeroFourPerSec = "fourZeroFourPerSec",
  fourZeroSixPerSec = "fourZeroSixPerSec",
  fiveZeroThreePerSec = "fiveZeroThreePerSec",
  fiveZeroFourPerSec = "fiveZeroFourPerSec",
  fiveXXPerSec = "fiveXXPerSec",
  ptperSec = "ptperSec",
  hitRatePercent = "hitRatePercent",
  authPercent = "authPercent",
  signedPercent = "signedPercent",
}

export enum NonSiteMetrics {
  totalProperties = "totalProperties",
  totalPopLocations = "totalPopLocations",
}

export interface Site {
  [Metrics.mbPerSec]: number;
  [Metrics.reqPerSec]: number;
  [Metrics.missMbPerSec]: number;
  [Metrics.missPerSec]: number;
  [Metrics.fourZeroThreePerSec]: number;
  [Metrics.fourZeroFourPerSec]: number;
  [Metrics.fourZeroSixPerSec]: number;
  [Metrics.fiveZeroThreePerSec]: number;
  [Metrics.fiveZeroFourPerSec]: number;
  [Metrics.fiveXXPerSec]: number;
  [Metrics.ptperSec]: number;
  [Metrics.hitRatePercent]: number;
  [Metrics.authPercent]: number;
  [Metrics.signedPercent]: number;
}

export interface SiteByProperty extends Site {
  propertyId: number;
  propertyName: string;
}

export interface SiteByMetro extends SiteByRegion {
  metro: string;
}

export interface SiteByRegion extends Site {
  region: Region;
}

export interface CurrentData {
  time: number;
  accessGroup: Site;
  regions: SiteByRegion[];
  metros: SiteByMetro[];
}

export enum Region {
  Africa = "Africa",
  AsiaPacific = "Asia/Pacific",
  Europe = "Europe",
  LatinAmerica = "Latin America",
  MiddleEast = "Middle East",
  NorthAmerica = "North America",
  SouthAmerica = "South America",
}

export const MapSiteFieldsToLabel = new Map<Metrics | NonSiteMetrics, string>([
  [Metrics.mbPerSec, "Mb/sec"],
  [Metrics.reqPerSec, "Req/sec"],
  [Metrics.missMbPerSec, "Miss Mb/sec"],
  [Metrics.missPerSec, "Miss/sec"],
  [Metrics.fourZeroThreePerSec, "403/sec"],
  [Metrics.fourZeroFourPerSec, "404/sec"],
  [Metrics.fourZeroSixPerSec, "406/sec"],
  [Metrics.fiveZeroThreePerSec, "503/sec"],
  [Metrics.fiveZeroFourPerSec, "504/sec"],
  [Metrics.fiveXXPerSec, "5xx/sec"],
  [Metrics.ptperSec, "PT/sec"],
  [Metrics.hitRatePercent, "Hit Rate %"],
  [Metrics.authPercent, "Auth %"],
  [Metrics.signedPercent, "Signed %"],
  [NonSiteMetrics.totalProperties, "Total Active Properties"],
  [NonSiteMetrics.totalPopLocations, "Total Pop Locations"],
]);

export type IHistorical = {
  time: number;
  site: Site;
}[];

export interface Property {
  id: string;
  name: string;
  active: boolean;
}

export interface RegionHistory {
  region: string;
  points: Point[];
}

export interface Point {
  time: number;
  site: Site;
}

export const getUserReports = async (
  accessGroupId: number
): Promise<IReport[]> => {
  try {
    const { data } = await httpClient.get<IReport[]>(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/customReports`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getUserReports()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export type TableSiteWithPercentage = {
  [Metrics.reqPerSec]: ValueWithPercentage;
  [Metrics.mbPerSec]: ValueWithPercentage;
  [Metrics.missMbPerSec]: ValueWithPercentage;
  [Metrics.missPerSec]: ValueWithPercentage;
  [Metrics.fourZeroFourPerSec]: ValueWithPercentage;
  [Metrics.fiveZeroThreePerSec]: ValueWithPercentage;
  [Metrics.fiveZeroFourPerSec]: ValueWithPercentage;
  [Metrics.fiveXXPerSec]: ValueWithPercentage;
  [Metrics.hitRatePercent]: ValueWithPercentage;
};

export enum FixedWidgetIds {
  GLOBAL_REPORT = "globalReport",
  REQ_PER_SEC = "reqPerSec",
  HIT_RATE_PERCENT = "hitRatePercent",
  LOCATIONS_SPLIT = "locationsSplit",
  PROPERTIES_SPLIT = "propertiesSplit",
}
