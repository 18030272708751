import { AxiosError } from "axios";
import { ProductionSlot } from "..";
import { httpClient } from "../../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../../error";
import { SubscriberDataNotFoundError } from "../../error/SubscriberDataNotFoundError";

export interface IProductionConfigurationData {
  state: string;
  sourceConfigName: string;
  sourceConfigVersionId: number;
  versionId: number;
  modifiedTime: string;
  promotedTime: string;
  comment: string;
  transactionId: string;
  schemaVersion: string;
  percentActive?: number;
}

export interface IProductionMigrationData {
  subscriberId: number;
  deploymentStatus: DeploymentState;
  slots: {
    current: {
      state: string;
      isLegacy: boolean;
    };
    candidate: IProductionConfigurationData & { percentActive: number };
  };
}

export enum DeploymentState {
  UNDEPLOYED = "undeployed",
  PREPARING = "preparing",
  INPROGRESS = "inProgress",
  COMPLETE = "complete",
  MIGRATION = "migration",
  ERROR = "error",
}

export interface IActiveConfigurationsResponse {
  subscriberId: number;
  deploymentStatus: DeploymentState;
  slots: {
    current?: IProductionConfigurationData;
    candidate?: IProductionConfigurationData & { percentActive: number };
  };
}

export interface ICnames {
  cnames: {
    cname: string;
    alias: string;
  }[];
}

export interface IDeployData {
  sourceConfig: string;
  comment: string;
  forcePercentActive?: number;
}

export const deployConfiguration = async (
  subscriberId: string,
  slot: ProductionSlot,
  payload: IDeployData
): Promise<IActiveConfigurationsResponse> => {
  try {
    const { data } = await httpClient.put<
      IDeployData,
      IActiveConfigurationsResponse
    >(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production/${slot}`,
      payload
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "deployConfiguration()",
      ErrorLevel.FATAL,
      error.response,
      // Prevent sending 409 to Sentry.
      // We still want to track 400 which
      // represent errors in the config
      // that should have been spotted
      // during validation
      error.response?.status !== 409
    );
  }
};

export const getActive = async (
  subscriberId: string
): Promise<IActiveConfigurationsResponse | IProductionMigrationData> => {
  try {
    const { data } = await httpClient.get<IActiveConfigurationsResponse>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    const is404 = error.response?.status === 404;

    if (is404) {
      throw new SubscriberDataNotFoundError(error);
    } else {
      throw new APIError(
        error.message,
        "getActive()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }
};

export const getActiveCnames = async (
  subscriberId: string
): Promise<ICnames | undefined> => {
  try {
    const { data } = await httpClient.get<ICnames>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production/cnames`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    const is404 = error.response?.status === 404;

    if (is404) {
      throw new SubscriberDataNotFoundError(error);
    } else {
      throw new APIError(
        error.message,
        "getActiveCnames()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }
};

export const changeCandidatePercentActive = async (
  subscriberId: string,
  candidatePercentActive: number
): Promise<IActiveConfigurationsResponse> => {
  try {
    const { data } = await httpClient.put<
      { percentActive: number },
      IActiveConfigurationsResponse
    >(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production/candidate/changePercentActive`,
      {
        percentActive: candidatePercentActive,
      }
    );

    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "changeCandidatePercentActive()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const promoteCandidateToCurrent = async (
  subscriberId: string
): Promise<IActiveConfigurationsResponse | undefined> => {
  try {
    const { data } = await httpClient.put<
      unknown,
      IActiveConfigurationsResponse
    >(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production/candidate/promoteToCurrent`,
      {}
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "promoteCandidateToCurrent()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const deleteCandidateConfiguration = async (
  subscriberId: string
): Promise<IActiveConfigurationsResponse | undefined> => {
  try {
    const { data } = await httpClient.delete<IActiveConfigurationsResponse>(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/configs/production/candidate`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "deleteCandidateConfiguration()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
