import { FC } from "react";
import styled from "@emotion/styled";

export enum FeatureTypes {
  ACCESS_CONTROL = "Access Control",
  CACHE_CONTROL = "Cache Control",
  REQUEST_MANIPULATION = "Request Manipulation",
  CONTENT_MANIPULATION = "Content Manipulation",
  ORIGIN_SELECTION = "Origin Selection",
  LOGGING = "Logging",
  LUA = "Lua",
}

export const FeatureType: FC<{ featureType: FeatureTypes }> = ({
  featureType,
}) => (
  <>
    <MiddleDot>•</MiddleDot>
    <Font>Type: {featureType}</Font>
  </>
);

const Font = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const MiddleDot = styled(Font)`
  margin: 0 0.5rem;
`;
