import type { ReactElement } from "react";
import type {
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import { IpDefinitionModal } from "../../../../../../../../../components/molecules/IpDefinitionModal/IpDefinitionModal";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { TokenModal } from "../../../../../../../../../components/molecules/TokenModal/TokenModal";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  SimpleDefinitionType,
  TokenDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { uriRegex } from "../../../../../../../../../utils/regex";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { toName } from "./helpers";

interface ITokenFeatureBlockProps {
  handleCreate: (d: TokenDefinitionType | SimpleDefinitionType) => void;
  onDelete: () => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  register: UseFormRegister<IFeatures>;
  setError: UseFormSetError<IFeatures>;
  clearErrors: UseFormClearErrors<IFeatures>;
  initIsOpen: boolean;
  tokenAuthentication: Exclude<IFeatures["tokenAuthentication"], undefined>;
  config: ConfigurationDetailsType;
  maxWidth: number;
  errors: ValidationErrors;
  datatestId: string;
}

export const TokenFeatureBlock = ({
  handleCreate,
  onDelete,
  handleCreateFeature,
  register,
  setError,
  clearErrors,
  initIsOpen,
  tokenAuthentication,
  config,
  maxWidth,
  errors,
  datatestId,
}: ITokenFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();

  const isRedirect = tokenAuthentication.denial?.action === "redirect";

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_TOKEN_AUTHENTICATION_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_TOKEN_AUTHENTICATION_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_TOKEN_AUTHENTICATION_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_TOKEN_AUTHENTICATION_DEFINITION_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_DEFINITION_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_DEFINITION_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            maxWidth={maxWidth}
            errorMessage={errors.tokenAuthentication?.name?.message}
            fieldProps={{
              other: config.tokenAuthenticationDefinitions ? (
                <DefinitionDropdown
                  id="tokenAuthentication.name"
                  type={FeatureType.TOKEN}
                  disabled={isViewMode}
                  items={config.tokenAuthenticationDefinitions.map(
                    (definition) => ({ definition })
                  )}
                  selected={
                    config.tokenAuthenticationDefinitions.filter(
                      (def) => tokenAuthentication.name === def.name
                    ) as TokenDefinitionType[]
                  }
                  onSelect={(value) =>
                    handleCreateFeature(
                      "tokenAuthentication.name",
                      value[0]?.name
                    )
                  }
                  allowMultiSelection={false}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  dataTestId="token-definition"
                  parentConfig={config}
                />
              ) : (
                <TokenModal parentConfig={config} onSubmit={handleCreate} />
              ),
            }}
            divider
            dataTestId="token-definition"
          />

          <PropertyDefinitionCard
            title={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_ACTION_ON_DENIAL_TITLE"
            )}
            additionalInfoTitle={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_ACTION_ON_DENIAL_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_ACTION_ON_DENIAL_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      ...register("tokenAuthentication.denial.action"),
                      value: "redirect",
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_TOKEN_AUTHENTICATION_ACTION_ON_DENIAL_RADIO_1_LABEL"
                      ),
                      id: "token-action-redirect",
                      dataTestId: "token-action-redirect",
                    },
                    {
                      ...register("tokenAuthentication.denial.action"),
                      value: "error",
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_TOKEN_AUTHENTICATION_ACTION_ON_DENIAL_RADIO_2_LABEL"
                      ),
                      id: "token-action-error",
                      dataTestId: "token-action-error",
                      onClick: () => {
                        handleCreateFeature("tokenAuthentication.denial", {
                          action: "error",
                        });
                      },
                    },
                  ]}
                />
              ),
            }}
            divider
            dataTestId="token-action-on-denial"
          />

          {isRedirect && (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_TOKEN_AUTHENTICATION_REDIRECT_URL_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_TOKEN_AUTHENTICATION_REDIRECT_URL_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_TOKEN_AUTHENTICATION_REDIRECT_URL_DESCRIPTION_CONTENT"
              )}
              required
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <HeadlessInputField
                    disabled={isViewMode}
                    placeholder={t(
                      "FEATURE_CARD_TOKEN_AUTHENTICATION_REDIRECT_URL_PLACEHOLDER"
                    )}
                    {...register("tokenAuthentication.denial.redirectUrl", {
                      required: tMisc("ERROR_REQUIRED_FIELD"),
                      pattern: {
                        value: new RegExp(uriRegex),
                        message: tMisc("ERROR_MUST_BE_URL"),
                      },
                    })}
                    data-testid="token-redirect-url-input"
                  />
                ),
              }}
              errorMessage={
                (errors.tokenAuthentication?.denial as
                  | {
                      action?: FieldError | undefined;
                      redirectUrl?: FieldError | undefined;
                    }
                  | undefined)?.redirectUrl?.message
              }
              maxWidth={maxWidth}
              divider
              dataTestId="token-redirect-url"
            />
          )}

          <PropertyDefinitionCard
            title={t("FEATURE_CARD_TOKEN_AUTHENTICATION_IP_WHITELIST_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_IP_WHITELIST_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_TOKEN_AUTHENTICATION_IP_WHITELIST_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            maxWidth={maxWidth}
            errorMessage={
              (errors.tokenAuthentication?.ipWhitelist as
                | FieldError
                | undefined)?.message
            }
            fieldProps={{
              other: config.simpleListDefinitions ? (
                <DefinitionDropdown
                  id={"tokenAuthentication.ipWhitelist"}
                  disabled={isViewMode}
                  type={FeatureType.IP}
                  items={config.simpleListDefinitions
                    .filter((def) => def.listType === "ip")
                    .map((definition) => ({ definition }))}
                  selected={
                    tokenAuthentication.ipWhitelist
                      ? (config.simpleListDefinitions.filter(
                          (def) =>
                            def.listType === "ip" &&
                            tokenAuthentication.ipWhitelist!.includes(def.name)
                        ) as SimpleDefinitionType[])
                      : []
                  }
                  onSelect={(selected) =>
                    handleCreateFeature(
                      "tokenAuthentication.ipWhitelist",
                      selected.map(toName)
                    )
                  }
                  dataTestId="token-ip-whitelist"
                  parentConfig={config}
                />
              ) : (
                <IpDefinitionModal
                  parentConfig={config}
                  onSubmit={handleCreate}
                />
              ),
            }}
            dataTestId="token-ip-whitelist"
          />
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"tokenAuthentication"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="token"
      featureType={FeatureTypes.ACCESS_CONTROL}
    />
  );
};
