import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import type {
  FieldError,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

import { ReactComponent as RemoveSVG } from "../../../../../../../../../../assets/icons/circle_minus_outline.svg";
import { Protected } from "../../../../../../../../../../components/atoms/Protected/Protected";
import { InputField } from "../../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IDropdownItem } from "../../../../../../../../../../components/organisms/Dropdown/Dropdown";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import {
  ICustomLogDataLiteral,
  ICustomLogDataVariable,
} from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../../../../models/permissions";
import { ValidationErrors } from "../../types";
import { getVariablePrefix, isOfTypeVariable } from "./helpers";
import { VariableExpression, VariableFields } from "./types";
import { VariableEntry } from "./VariableEntry";

export interface ICustomLogDataEntryProps {
  disabled: boolean;
  customLogData: Exclude<IFeatures["customLogData"], undefined>;
  i: number;
  t: TFunction<"configurationMatchRulesPage">;
  entriesCount: number;
  errors: ValidationErrors;
  updateEntry: (
    entry: ICustomLogDataLiteral | ICustomLogDataVariable,
    index: number
  ) => void;
  removeEntry: (index: number) => void;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  maxWidth: number;
  handleDeleteFeature: (featureName: string) => void;
}

const VARIABLE_EXPRESSIONS: IDropdownItem<VariableExpression | string>[] = [
  {
    label: "$prop.listener-addr",
    value: VariableExpression.PROP_LISTENER_ADDR,
    dataTestId: "dropdown-item-prop-listener-addr",
  },
  {
    label: "$prop.cli-ssl.proto",
    value: VariableExpression.PROP_LISTENER_PROTO,
    dataTestId: "dropdown-item-prop-cli-ssl-proto",
  },
  {
    label: "$req.h.",
    value: VariableExpression.REQ_HEADER,
    dataTestId: "dropdown-item-req-h",
  },
  {
    label: "$resp.h.",
    value: VariableExpression.RESP_HEADER,
    dataTestId: "dropdown-item-resp-h",
  },
  {
    label: "$resp.status",
    value: VariableExpression.RESP_STATUS,
    dataTestId: "dropdown-item-resp-status",
  },
  {
    label: "Enter a Custom Expression",
    value: "",
    dataTestId: "dropdown-item-custom-expression",
  },
];

const KEY_NAME_ERR = {
  type: "manual",
  message: "Key name or variable expression must be unique.",
};

const VARIABLE_ERR = {
  type: "manual",
  message: "Variable expression or key name must be unique.",
};

const getVariableExpression = (
  variableExpressions: IDropdownItem<VariableExpression | string>[],
  variableFields: VariableFields,
  variablePrefix: VariableExpression | string
) => {
  const variable = variableFields[variablePrefix as VariableExpression];
  const customVariable = typeof variablePrefix !== "undefined" && !variable;

  if (customVariable) {
    variableExpressions[variableExpressions.length - 1].value = variablePrefix;
  }

  return variableExpressions;
};

const getSignature = (
  entry: ICustomLogDataLiteral | ICustomLogDataVariable
): string | undefined => {
  if (isOfTypeVariable(entry)) {
    return entry.signature;
  } else {
    return undefined;
  }
};

const getLiteralString = (
  entry: ICustomLogDataLiteral | ICustomLogDataVariable
): string | undefined => {
  if (!isOfTypeVariable(entry)) {
    return entry.literalString;
  } else {
    return undefined;
  }
};

const duplicatedEntry = (
  customLogData: (ICustomLogDataVariable | ICustomLogDataLiteral)[],
  entryIndex: number,
  keyName: string
) => (d: ICustomLogDataVariable | ICustomLogDataLiteral, index: number) => {
  const notSelf = index !== entryIndex;
  const isNextDuplicated = notSelf && entryIndex > index;
  const isKeyDuplicated = d.keyName === keyName;

  if (isNextDuplicated && isKeyDuplicated) {
    const entry = customLogData[entryIndex];

    if (isOfTypeVariable(d) && isOfTypeVariable(entry)) {
      return d.variableName === entry.variableName;
    } else if (!isOfTypeVariable(d) && !isOfTypeVariable(entry)) {
      return d.literalString === entry.literalString;
    }
  }
};

const duplicatedVariable = (
  entry: ICustomLogDataVariable,
  entryIndex: number
) => (d: ICustomLogDataVariable, index: number) =>
  index !== entryIndex &&
  d.keyName === entry.keyName &&
  (d as ICustomLogDataVariable).variableName === entry.variableName;

const duplicatedLiteralString = (
  entryIndex: number,
  keyName: string,
  literalString: string
) => (d: ICustomLogDataLiteral | ICustomLogDataVariable, index: number) => {
  const notSelf = index !== entryIndex;
  const isNextDuplicated = notSelf && entryIndex > index;
  const isKeyDuplicated = d.keyName === keyName;

  return (
    isNextDuplicated &&
    isKeyDuplicated &&
    !isOfTypeVariable(d) &&
    d.literalString === literalString
  );
};

export const CustomLogDataEntry = ({
  clearErrors,
  customLogData,
  disabled,
  entriesCount,
  errors,
  i,
  maxWidth,
  register,
  removeEntry,
  setError,
  t,
  updateEntry,
  handleDeleteFeature,
}: ICustomLogDataEntryProps): ReactElement => {
  const [tMisc] = useTranslation("misc");

  const isVariableEntry = isOfTypeVariable(customLogData[i]);

  const [variablePrefix, setVariablePrefix] = useState<
    VariableExpression | string | undefined
  >(getVariablePrefix(customLogData[i]));

  const variableFields: VariableFields = {
    [VariableExpression.PROP_LISTENER_ADDR]: {
      description: t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_PROP_LISTNER_ADDR_DESCRIPTION"
      ),
    },
    [VariableExpression.PROP_LISTENER_PROTO]: {
      description: t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_PROP_LISTNER_PROTO_DESCRIPTION"
      ),
    },
    [VariableExpression.REQ_HEADER]: {
      description: t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_REQUEST_HEADER_DESCRIPTION_CONTENT"
      ),
    },
    [VariableExpression.RESP_HEADER]: {
      description: t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_RESPONSE_HEADER_DESCRIPTION"
      ),
    },
    [VariableExpression.RESP_STATUS]: {
      description: t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_RESPONSE_STATUS_DESCRIPTION"
      ),
    },
  };

  const signature = getSignature(customLogData[i]);
  const errMsg = errors.customLogData?.[i];
  const literalStringErrMsg = (errMsg as
    | {
        keyName?: FieldError | undefined;
        literalString?: FieldError | undefined;
      }
    | undefined)?.literalString?.message;
  const variableNameErrMsg = (errMsg as
    | {
        keyName?: FieldError | undefined;
        variableName?: FieldError | undefined;
        signature?: FieldError | undefined;
      }
    | undefined)?.variableName?.message;

  return (
    <>
      <PropertyDefinitionCard
        asterisk
        divider
        title={t("FEATURE_CARD_CUSTOM_LOG_DATA_KEY_TITLE")}
        additionalInfoTitle={t(
          "FEATURE_CARD_CUSTOM_LOG_DATA_KEY_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "FEATURE_CARD_CUSTOM_LOG_DATA_KEY_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.OtherType}
        fieldProps={{
          other: (
            <InputContainer>
              <InputField
                placeholder={t("FEATURE_CARD_CUSTOM_LOG_DATA_KEY_PLACEHOLDER")}
                value={customLogData[i].keyName}
                register={register}
                validation={{
                  required: tMisc("ERROR_REQUIRED_FIELD"),
                  pattern: {
                    value: /^([a-zA-Z0-9_]$|[a-zA-Z0-9_][a-zA-Z0-9_-]*[a-zA-Z0-9_]$)/,
                    message: tMisc("INVALID_NAME", {
                      pattern:
                        "^([a-zA-Z0-9_]$|[a-zA-Z0-9_][a-zA-Z0-9_-]*[a-zA-Z0-9_]$)",
                    }),
                  },
                  validate: (value: string) => {
                    if (
                      customLogData.filter(
                        duplicatedEntry(customLogData, i, value)
                      ).length >= 1
                    ) {
                      return "Key name or variable expression must be unique.";
                    }

                    return true;
                  },
                }}
                id={`customLogData[${i}].keyName`}
                disabled={disabled}
                onChange={(value) => {
                  let newEntry: ICustomLogDataLiteral | ICustomLogDataVariable;
                  const entry = customLogData[i];
                  if (isOfTypeVariable(entry)) {
                    newEntry = {
                      keyName: value,
                      variableName: entry.variableName,
                      signature,
                    };
                  } else {
                    newEntry = {
                      keyName: value,
                      literalString: entry.literalString,
                    };
                  }
                  updateEntry(newEntry, i);
                }}
                hasBorder
                dataTestId={`customLogData-${i}-key`}
              />
              {entriesCount > 1 && (
                <Protected permissions={UserRoles.EDIT_CONFIG}>
                  <Remove
                    onClick={() => {
                      removeEntry(i);
                    }}
                    data-testid={`custom-log-data-remove-key-name-${i}`}
                  />
                </Protected>
              )}
            </InputContainer>
          ),
        }}
        maxWidth={maxWidth}
        errorMessage={errors.customLogData?.[i]?.keyName?.message}
        dataTestId={`custom-log-data-key-${i}`}
      />

      <PropertyDefinitionCard
        asterisk
        divider
        title={t("FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_TITLE")}
        additionalInfoTitle={t(
          "FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.RadioButtonsType}
        fieldProps={{
          inline: true,
          radioButtonsProps: [
            {
              disabled,
              value: "variable",
              onClick: () => {
                if (!isOfTypeVariable(customLogData[i])) {
                  const newEntry: {
                    keyName: string;
                    variableName: string;
                    signature?: string;
                  } = {
                    keyName: customLogData[i].keyName,
                    variableName: "",
                  };

                  if (signature) {
                    newEntry.signature = signature;
                  }

                  updateEntry(newEntry, i);

                  if (literalStringErrMsg) {
                    clearErrors(`customLogData[${i}].literalString`);
                  }
                }
              },
              label: t("FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_RADIO_1_LABEL"),
              name: `custom-log-data-type-${i}`,
              id: `type-variable-${i}`,
              defaultChecked: isOfTypeVariable(customLogData[i]),
              dataTestId: `custom-log-data-variable-radio-button-${i}`,
            },
            {
              disabled,
              value: "literal",
              onClick: () => {
                if (isOfTypeVariable(customLogData[i])) {
                  updateEntry(
                    {
                      keyName: customLogData[i].keyName,
                      literalString: "",
                    },
                    i
                  );
                }
                setVariablePrefix(undefined);
              },
              label: t("FEATURE_CARD_CUSTOM_LOG_DATA_TYPE_RADIO_2_LABEL"),
              name: `custom-log-data-type-${i}`,
              id: `type-literal-${i}`,
              defaultChecked: !isOfTypeVariable(customLogData[i]),
              dataTestId: `custom-log-data-literal-radio-button-${i}`,
            },
          ],
        }}
      />

      {isVariableEntry ? (
        <VariableEntry
          clearErrors={(errID) => {
            if (
              !(
                (customLogData as ICustomLogDataVariable[]).filter(
                  duplicatedVariable(
                    customLogData[i] as ICustomLogDataVariable,
                    i
                  )
                ).length >= 1
              ) &&
              errID !== `customLogData[${i}].variableName`
            ) {
              clearErrors(errID);
            }
          }}
          disabled={disabled}
          entriesCount={entriesCount}
          errors={errors}
          i={i}
          items={getVariableExpression(
            VARIABLE_EXPRESSIONS,
            variableFields,
            variablePrefix!
          ).map((_variableExpression) => {
            const variableExpression = { ..._variableExpression };

            variableExpression.dataTestId += `-${i}`;

            if (variableExpression.value === variablePrefix) {
              variableExpression.default = true;
            }

            return variableExpression;
          })}
          maxWidth={maxWidth}
          onSelect={(selectedItem) => {
            setVariablePrefix(selectedItem.value);
            const entry = customLogData[i];
            const newEntry = signature
              ? {
                  keyName: entry.keyName,
                  variableName: selectedItem.value as string,
                  signature,
                }
              : {
                  keyName: entry.keyName,
                  variableName: selectedItem.value as string,
                };

            updateEntry(newEntry, i);

            if (
              customLogData.filter(
                (d, index) =>
                  index !== i &&
                  d.keyName === newEntry.keyName &&
                  (d as ICustomLogDataVariable).variableName ===
                    newEntry.variableName
              ).length >= 1
            ) {
              setError(`customLogData[${i}].keyName`, KEY_NAME_ERR);
              setError(`customLogData[${i}].variableName`, VARIABLE_ERR);
            } else if (
              selectedItem.value !== VariableExpression.REQ_HEADER &&
              selectedItem.value !== VariableExpression.RESP_HEADER &&
              variableNameErrMsg
            ) {
              clearErrors(`customLogData[${i}].variableName`);
            }
          }}
          register={register}
          setError={setError}
          t={t}
          tMisc={tMisc}
          variableFields={variableFields}
          variablePrefix={variablePrefix!}
          handleDeleteFeature={handleDeleteFeature}
        />
      ) : (
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_CUSTOM_LOG_DATA_LITERAL_STRING_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_CUSTOM_LOG_DATA_LITERAL_STRING_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_CUSTOM_LOG_DATA_LITERAL_STRING_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.InputFieldType}
          fieldProps={{
            disabled,
            placeholder: t(
              "FEATURE_CARD_CUSTOM_LOG_DATA_LITERAL_STRING_PLACEHOLDER"
            ),
            register,
            id: `customLogData[${i}].literalString`,
            value: getLiteralString(customLogData[i]),
            onChange: (literalString) => {
              updateEntry(
                {
                  keyName: customLogData[i].keyName,
                  literalString,
                },
                i
              );
            },
            dataTestId: `custom-log-data-literal-string-input-${i}`,
            validation: {
              validate: (value: string) => {
                if (
                  customLogData.filter(
                    duplicatedLiteralString(i, customLogData[i].keyName, value)
                  ).length >= 1
                ) {
                  return "Literal string or key name must be unique.";
                }

                return true;
              },
            },
          }}
          maxWidth={maxWidth}
          errorMessage={literalStringErrMsg}
          divider={i < entriesCount - 1}
          dataTestId="custom-log-data-literal"
        />
      )}
    </>
  );
};

const Remove = styled(RemoveSVG)`
  margin: 4px 8px 0px 8px;
  width: 1em !important;
  height: 1em !important;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.red60};
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;
