import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { animated, config, useSpring } from "react-spring";

import { LabelValueDropdownButton } from "../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { SubHeaderCustomerDropdown } from "../../../../components/organisms/SubHeaderDropdown/SubHeaderCustomerDropdown";
import { ISubHeaderOption } from "../../../../components/organisms/SubHeaderDropdown/types";
import { useClickOutsideRef } from "../../../../hooks/use-detect-click-outside-ref";
import { toggleBool } from "../../../../utils/toggleBool";
import { ApiKeysCustomerFilter } from "./types";

interface ApiKeysCustomerDropdownProps {
  customerFilter: ApiKeysCustomerFilter;
  onCustomerChange: (value: ApiKeysCustomerFilter) => void;
  isFilter?: boolean;
  isVyvx: boolean;
  customerDropdownItems?: ISubHeaderOption<string | number>[];
}

export const ApiKeysCustomerDropdown = ({
  customerFilter,
  onCustomerChange,
  isFilter = false,
  isVyvx,
  customerDropdownItems,
}: ApiKeysCustomerDropdownProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const style = useSpring({
    config: { ...config.stiff, mass: 0.5 },
    opacity: openDropdown ? 1 : 0,
  });

  const ref = useRef(null);
  useClickOutsideRef(ref, () => setOpenDropdown(false));

  return (
    <DropdownContainer className={`chi-dropdown`} ref={ref}>
      {isFilter ? (
        <LabelContainer onClick={() => setOpenDropdown(toggleBool)}>
          <LabelValueDropdownButton
            placeholder={
              isVyvx
                ? t("COMPANY_DROPDOWN_LABEL")
                : t("ACCESS_GROUP_DROPDOWN_LABEL")
            }
            value={customerFilter.customerName}
          />
        </LabelContainer>
      ) : (
        <DropdownButton
          id="search-dropdown-button"
          className="chi-button chi-dropdown__trigger"
          type="button"
          onClick={() => setOpenDropdown(toggleBool)}
        >
          {customerFilter.customerName === "all"
            ? isVyvx
              ? t("COMPANY_DROPDOWN_PLACEOLDER")
              : t("ACCESS_GROUP_DROPDOWN_PLACEOLDER")
            : customerFilter.customerName}
        </DropdownButton>
      )}
      <DropwdownContent style={style}>
        <SubHeaderCustomerDropdown
          options={customerDropdownItems}
          onSelect={(option: ISubHeaderOption<string | number>) => {
            if (onCustomerChange) {
              onCustomerChange({
                customerName: option.text,
                customerId: String(option.value),
              });
              setOpenDropdown(false);
            }
          }}
          open={openDropdown}
          isVyvx={isVyvx}
        />
      </DropwdownContent>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  padding: 0px;
  height: 100%;
`;

const LabelContainer = styled.div``;

const DropdownButton = styled.button`
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-weight: normal !important;
  line-height: normal !important;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;
`;

const DropwdownContent = styled(animated.div)``;
