import {
  ReportingOverrideDefinitionType,
  ConfigurationDetailsType,
} from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getReportingOverrideReferences = (
  def: ReportingOverrideDefinitionType,
  config: ConfigurationDetailsType
): number =>
  getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .filter((feature) => feature?.reportingOverride === def.name).length;
