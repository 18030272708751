import { useAppSelector } from "../../types";
import { UserManagementApiKeysSlice } from "./types";

export const useVyvxApiKeysRoles = (): UserManagementApiKeysSlice["vyvxRoles"] =>
  useAppSelector((store) => store.userManagementApiKeys.vyvxRoles);

export const useVyvxApiKeysStatus = (): UserManagementApiKeysSlice["vyvxApiKeysStatus"] =>
  useAppSelector((store) => store.userManagementApiKeys.vyvxApiKeysStatus);

export const useVyvxApiKeys = (): UserManagementApiKeysSlice["vyvxApiKeys"] =>
  useAppSelector((store) => store.userManagementApiKeys.vyvxApiKeys);
export const useVyvxApiKey = (): UserManagementApiKeysSlice["vyvxApiKey"] =>
  useAppSelector((store) => store.userManagementApiKeys.vyvxApiKey);

export const useVyvxApiKeyStatus = (): UserManagementApiKeysSlice["vyvxApiKeyStatus"] =>
  useAppSelector((store) => store.userManagementApiKeys.vyvxApiKeyStatus);

export const useVyvxApiKeysOwners = (): UserManagementApiKeysSlice["vyvxApiKeysOwners"] =>
  useAppSelector((store) => store.userManagementApiKeys.vyvxApiKeysOwners);

export const useVyvxApiKeysFiberCompanies = (): UserManagementApiKeysSlice["fiberCompanies"] => {
  const fiberCompanies = useAppSelector(
    (store) => store.userManagementApiKeys.vyvxApiKeys?.fiberCompanies
  );
  if (fiberCompanies === undefined) {
    throw new Error("'fiberCompanies' is undefined in apikeys");
  }

  return fiberCompanies;
};

export const useCdnApiKeysRoles = (): UserManagementApiKeysSlice["cdnRoles"] =>
  useAppSelector((store) => store.userManagementApiKeys.cdnRoles);

export const useCdnApiKeysStatus = (): UserManagementApiKeysSlice["cdnApiKeysStatus"] =>
  useAppSelector((store) => store.userManagementApiKeys.cdnApiKeysStatus);

export const useCdnApiKeys = (): UserManagementApiKeysSlice["cdnApiKeys"] =>
  useAppSelector((store) => store.userManagementApiKeys.cdnApiKeys);
export const useCdnApiKey = (): UserManagementApiKeysSlice["cdnApiKey"] =>
  useAppSelector((store) => store.userManagementApiKeys.cdnApiKey);

export const useCdnApiKeyStatus = (): UserManagementApiKeysSlice["cdnApiKeyStatus"] =>
  useAppSelector((store) => store.userManagementApiKeys.cdnApiKeyStatus);

export const useCdnApiKeysOwners = (): UserManagementApiKeysSlice["cdnApiKeysOwners"] =>
  useAppSelector((store) => store.userManagementApiKeys.cdnApiKeysOwners);
