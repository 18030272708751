import { APIError, ErrorLevel } from "../../../../models/error";
import { ConfigurationType, PropertyDefinitionType } from "../types";

export const getConfigurationProperty = (
  configuration: ConfigurationType,
  propertyName: string
): PropertyDefinitionType => {
  try {
    const propIndex = configuration.config!.propertyDefinitions.findIndex(
      (p) => p.name === propertyName
    );
    return configuration.config!.propertyDefinitions[propIndex];
  } catch (err) {
    throw new APIError(
      `Property ${propertyName} not found in configuration: ${configuration.configName}`,
      "configuration_details: getProperty",
      ErrorLevel.FATAL,
      { config: configuration, err, propertyName }
    );
  }
};
