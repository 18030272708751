import { useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { Button } from "../../atoms/Button/Button";
import { APIError } from "../../../models/error";
import { Toast } from "../../atoms/Toast/Toast";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { IModalChildren } from "../../atoms/Modal/Modal";
import { useThemeContext } from "../../../contexts/themeContext";

export interface ErrorModalContentProps extends IModalChildren {
  errorDetails: APIError["data"];
}

export const ErrorModalContent = ({
  errorDetails,
  closeModal,
}: ErrorModalContentProps): JSX.Element => {
  const { t } = useTranslation("misc");
  const { colorset } = useThemeContext();

  const copyErrorToClipboard = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(errorDetails, null, 2));
    toast.success("Successfully copied text to clipboard", {
      containerId: "copiedToClipboardModal",
    });
  }, [errorDetails]);

  return (
    <ErrorModalContentContainer>
      <ErrorModalTitle>Error Details</ErrorModalTitle>
      <ClipboardButton role="button" onClick={copyErrorToClipboard}>
        <Icon name={Icons.COPY_OUTLINE} />
        <CopySpan>{t("COPY_IN_CLIPBOARD")}</CopySpan>
      </ClipboardButton>
      <ErrorsContentExpanded>
        {JSON.stringify(errorDetails, null, 2)}
      </ErrorsContentExpanded>
      <CancelButton
        label={t("GOT_IT")}
        onClick={() => closeModal && closeModal()}
      />
      <Toast // This component is needed to display the copy toaster above the modal
        colorset={colorset}
        enableMultiContainer
        containerId="copiedToClipboardModal"
        position="bottom-right"
      />
    </ErrorModalContentContainer>
  );
};

const ErrorModalContentContainer = styled.div`
  padding: 32px;
`;

const ErrorModalTitle = styled.h3``;

const ErrorsContentExpanded = styled.pre`
  padding: 16px;
  color: ${({ theme }) => theme.text.error} !important;
  background: ${({ theme }) => theme.backgrounds.mutedLight} !important;
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 8px;
  max-height: 25em;
  overflow: scroll;
`;

const CancelButton = styled(Button)`
  float: right;
  margin-top: 32px;
`;

const ClipboardButton = styled.p`
  color: ${({ theme }) => theme.text.highlight};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const CopySpan = styled.span`
  margin-left: 8px;
`;
