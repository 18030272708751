import { useTranslation } from "react-i18next";

import { Icon, Icons } from "../../../../../../components/atoms/Icon/Icon";
import { Modal } from "../../../../../../components/atoms/Modal/Modal";
import { FeatureFlagModalChildProps } from "../../../models/models";
import { FeatureFlagsModalContent } from "./FeatureFlagsModalContent";

export const FeatureFlagsModal: React.FC<FeatureFlagModalChildProps> = ({
  handleSaveFeatureFlag,
  isUpdate,
  featureFlagsHeader,
  dataTestId,
}) => {
  const { t } = useTranslation("featureFlagsModalPage");

  return (
    <Modal
      openButtonProps={{
        label: t("CREATE_FEATURE_FLAGS_BUTTON"),
        dataTestId: `${dataTestId}-create-button`,
      }}
      customButton={
        isUpdate ? (
          <Icon name={Icons.EDIT} color="highlight" dataTestId={dataTestId} />
        ) : undefined
      }
      size="large"
    >
      <FeatureFlagsModalContent
        handleSaveFeatureFlag={handleSaveFeatureFlag}
        isUpdate={isUpdate}
        featureFlagsHeader={featureFlagsHeader}
        dataTestId={dataTestId}
      />
    </Modal>
  );
};
