import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../../components/atoms/Button/Button";
import { EmptyPageDescription } from "../../../../../components/atoms/EmptyPage/EmptyPageWrapper";

import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";
import { CdnApiKeysLayer } from "../../../../../components/layers/ApiKeys/ApiKeys";
import { ErrorLayer } from "../../../../../components/molecules/ErrorLayer/ErrorLayer";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import {
  useCdnApiKeys,
  useCdnApiKeysOwners,
  useCdnApiKeysRoles,
} from "../../../../../store/slices/user-management-api-keys/hooks";
import { ApiKeysTable } from "../ApiKeysTable";

export const CdnApiKeys = (): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const history = useHistory();
  const roles = useCdnApiKeysRoles();
  const owners = useCdnApiKeysOwners();
  const apiKeys = useCdnApiKeys();

  return (
    <Container>
      <ExpandableContent
        title={t("API_KEYS_PAGE_DESCRIPTION_EXPAND_TITLE")}
        content={t("API_KEYS_PAGE_DESCRIPTION_EXPAND_CONTENT")}
      />
      <CdnApiKeysLayer>
        {!!apiKeys && !!roles && !!owners ? (
          <ApiKeysTable
            apiKeys={apiKeys}
            roles={roles}
            owners={owners}
            isVyvx={false}
          />
        ) : (
          <>
            <ErrorLayer
              title={t("API_KEYS_ERROR_TITLE")}
              content={
                <EmptyPageDescription>
                  <div>{t("API_KEY_NOT_FOUND")}</div>
                  <Button
                    onClick={() => {
                      history.push("/userManagement/apiKeys");
                    }}
                    label={t("API_KEY_PAGE_ERROR_RETURN_BUTTON")}
                  />
                </EmptyPageDescription>
              }
            />
          </>
        )}
      </CdnApiKeysLayer>
    </Container>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
  min-height: 100%;
  padding-top: 16px;
`;
