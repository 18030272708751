import { useAppDispatch, useAppSelector } from "../../types";
import { setAccessToken, setRefreshToken } from "./slice";
import { AuthSlice } from "./types";

export const useDispatchRefeshToken = () => {
  const dispatch = useAppDispatch();

  return (token: string) => {
    try {
      dispatch(setRefreshToken(token));
      window.localStorage.setItem("cmv3.refresh_token", JSON.stringify(token));
    } catch (error) {
      console.error(error);
      dispatch(setRefreshToken(""));
    }
  };
};

export const useDispatchAccessToken = () => {
  const dispatch = useAppDispatch();

  return (token: string) => {
    dispatch(setAccessToken(token));
  };
};

export const useRefreshToken = (): AuthSlice["refeshToken"] =>
  useAppSelector((store) => store.auth.refeshToken);

export const useAccessToken = (): string =>
  useAppSelector((store) => store.auth.accessToken) as string;

export const useAccessTokenState = (): [
  AuthSlice["refeshToken"],
  AuthSlice["loading"]
] => {
  const accessToken = useAppSelector((store) => store.auth.accessToken);
  const loading = useAppSelector((store) => store.auth.loading);

  return [accessToken, loading];
};
