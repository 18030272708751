import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Banners } from "../../components/molecules/Banner/Banner";
import { SubHeaderCaching } from "../../components/organisms/SubHeader/SubHeaderCaching/SubHeaderCaching";
import { IRoute, Tabs } from "../../components/organisms/Tabs/Tabs";
import { Banner } from "../../models/banner";
import { CachingRouter } from "./Caching.router";
import { useDispatchAPIError } from "../../store/slices/api-error/hooks";
import { CachingLayer } from "../../components/layers/Caching/Caching";
import { useUserId } from "../../store/slices/user/hooks";
import { httpClient } from "../../core/http-client";
import {
  ModuleHeader,
  ModuleTitle,
} from "../../components/atoms/Module/Module";
import { Subscriber } from "../../components/layers/Subscriber/Subscriber";

export interface ICachingProps {}

enum CachingMaintenanceStatus {
  NONE = "none",
  CURRENT = "current",
  TWO_HOURS = "2hours",
  TWENTY_FOUR_HOURS = "24hours",
}
interface ICachingMaintenanceStatus {
  status: CachingMaintenanceStatus;
  startTimeMillis: number;
  endTimeMillis: number;
}

export const Caching: React.FC<ICachingProps> = () => {
  const history = useHistory();
  const { t } = useTranslation("header");
  const match = useRouteMatch<{
    version: string;
    module: string;
    name: string;
    submodule: string;
    subname: string;
  }>([
    "/:version/:module/:name/:submodule/:subname",
    "/:version/:module/:name/:submodule",
    "/:version/:module/:name",
    "/:version/:module",
  ]);

  const tabList = [
    { route: "production", label: t("PRODUCTION") },
    { route: "configurations", label: t("CONFIGURATIONS") },
    { route: "certificates", label: t("CERTIFICATES") },
    { route: "environment_history", label: t("ENVIRONMENT_HISTORY") },
    { route: "subscriber_settings", label: t("SUBSCRIBER_SETTINGS") },
  ];

  const initialTab = tabList.find((tab) => tab.route === match?.params.module);

  const [cachingTopic, setCachingTopic] = useState<IRoute>(
    initialTab ? initialTab : tabList[0]
  );
  const [banners, setBanners] = useState<Banner[]>([]);

  useEffect(() => {
    if (!match?.params.name) {
      history.push(`/cmv3/${cachingTopic.route}`);
    }
  }, [cachingTopic, history]);

  useEffect(() => {
    const splittedPath = history.location.pathname.split("/");
    if (splittedPath.length === 3 && cachingTopic.route !== splittedPath[2]) {
      setCachingTopic(
        tabList.find((tab) => tab.route === splittedPath[2]) || tabList[0]
      );
    }
  }, [history.location]);

  const currentIndex = tabList.findIndex(
    (tab) => tab.route === cachingTopic.route
  );

  const handleMediaPortalError = useDispatchAPIError();

  const userId = useUserId();

  useEffect(() => {
    Banner.getAll(userId).then(setBanners).catch(handleMediaPortalError);

    httpClient
      .get<ICachingMaintenanceStatus>("/serviceConfiguration/v3/status")
      .then((res) => {
        if (res.data.status === "current") {
          httpClient.goToOldMP("/ui/myServicesCaching");
        }
      });
  }, []);

  return (
    <>
      <SubHeaderCaching />
      <Subscriber>
        <CachingLayer>
          {banners.length > 0 && <Banners banners={banners} />}
          {!match?.params.name && (
            <ModuleHeader>
              <ModuleTitle moduleTitle="Caching" />
              <Tabs
                onSelectionChange={setCachingTopic}
                tabsList={tabList}
                initialIndex={currentIndex === -1 ? 0 : currentIndex}
                lastHasMargin
              />
            </ModuleHeader>
          )}
          <CachingRouter />
        </CachingLayer>
      </Subscriber>
    </>
  );
};
