import { FC } from "react";

import { SectionLayout } from "../../../../../components/molecules/SectionLayout/SectionLayout";
import { ChartPropertiesTableContent } from "./ChartPropertiesTableContent";
import { useCurrentPropertyStatus } from "../../../../../store/slices/rtm/hooks";
import { SetupStatus } from "../../../../../store/slices/rtm/types";
import { WidgetLayout } from "../../../../../components/molecules/WidgetLayout/WidgetLayout";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { Loader } from "../../../../../components/atoms/Loader/Loader";

export const ChartPropertiesTable: FC = () => {
  const propertyStatus = useCurrentPropertyStatus();

  const shouldRenderLoader = propertyStatus === SetupStatus.SETUP;

  return shouldRenderLoader ? (
    <WidgetLayout>
      <FlexBox>
        <Loader size={40} />
      </FlexBox>
    </WidgetLayout>
  ) : (
    <SectionLayout title="Properties">
      <ChartPropertiesTableContent />
    </SectionLayout>
  );
};
