import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { ErrorLevel, APIError } from "../models/error";
import { sleep } from "../utils/sleep";

export interface IGetAccessTokenPayload {
  refresh_token: string;
}

export interface IGetAccessTokenResponse {
  access_token: string;
}

const requestInterceptor = (ctx: HTTPClient) => async (
  request: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  if (
    ctx.accessToken === undefined ||
    request.headers.Authorization === undefined ||
    request.headers.Authorization === "Bearer undefined"
  ) {
    let attempts = 0;
    while (attempts < ctx.timeoutTries && ctx.accessToken === undefined) {
      attempts += 1;
      await sleep(100);
    }
    if (ctx.accessToken === undefined) {
      return Promise.reject("Missing access token");
    } else {
      request.headers.Authorization = `Bearer ${ctx.accessToken}`;
    }
  }
  while (ctx.isRefreshing) {
    await sleep(100);
  }
  return request;
};

const refreshTokenInterceptor = (ctx: HTTPClient) => async (error: any) => {
  const originalRequest = error.config;
  if (
    error.response &&
    error.response.status === 401 &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true;
    if (!ctx.isRefreshing) {
      const item = window.localStorage.getItem("cmv3.refresh_token");
      const refresh_token = item ? JSON.parse(item) : "";
      ctx.isRefreshing = true;
      return axios
        .post<
          { refresh_token: string },
          AxiosResponse<{ access_token: string }>
        >(`${ctx.baseURL}/auth/login/refresh`, {
          refresh_token,
        })
        .then((res) => {
          ctx.accessToken = res.data.access_token;
          originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
          return axios(originalRequest);
        })
        .catch((err) => {
          // Identify failure in refresh token call
          if (err?.config?.url.includes("/refresh")) {
            const mp_host =
              process.env.REACT_APP_MP_HOST || "https://mp-test.lumen.com";
            window.location.replace(mp_host + "/ui/login");
          } else {
            throw err;
          }
        })
        .finally(() => {
          ctx.isRefreshing = false;
        });
    } else {
      while (ctx.isRefreshing) {
        await sleep(100);
      }
      originalRequest.headers.Authorization = `Bearer ${ctx.accessToken}`;
      return axios(originalRequest);
    }
  }
  return Promise.reject(error);
};

class HTTPClient {
  private _baseURL: string;
  private _accessToken?: string;
  private _client: AxiosInstance;
  private _timeoutTries = 10;
  private _isRefreshing = false;

  constructor(baseURL: string) {
    this._baseURL = `${baseURL}/v1`;
    this._client = axios.create({
      baseURL: `${baseURL}/v1`,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this._client.interceptors.request.use(requestInterceptor(this));
    this._client.interceptors.response.use(
      (response) => response,
      refreshTokenInterceptor(this)
    );
  }

  get accessToken(): string | undefined {
    return this._accessToken;
  }
  set accessToken(newAccessToken: string | undefined) {
    this._accessToken = newAccessToken;
  }

  get baseURL(): string {
    return this._baseURL;
  }
  set baseURL(newBaseURL: string) {
    this._baseURL = `${newBaseURL}/v1`;
    this._client.defaults.baseURL = `${newBaseURL}/v1`;
  }

  get client(): AxiosInstance {
    return this._client;
  }

  get timeoutTries(): number {
    return this._timeoutTries;
  }

  get isRefreshing(): boolean {
    return this._isRefreshing;
  }

  set isRefreshing(value: boolean) {
    this._isRefreshing = value;
  }

  public async get<T>(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return await this.client.get(endpoint, {
      ...config,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ...config?.headers,
      },
    });
  }

  public async post<T = any, R = any>(
    endpoint: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return await this.client.post(endpoint, data, {
      ...config,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ...config?.headers,
      },
    });
  }

  public async put<T = any, R = any>(
    endpoint: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return await this.client.put(endpoint, data, {
      ...config,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ...config?.headers,
      },
    });
  }

  public async delete<T>(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return await this.client.delete(endpoint, {
      ...config,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ...config?.headers,
      },
    });
  }

  public goToOldMP(uri: string) {
    const mp_host =
      process.env.REACT_APP_MP_HOST || "https://mp-test.lumen.com";

    const item = window.localStorage.getItem("cmv3.refresh_token");
    const refresh_token = item ? JSON.parse(item) : "";
    axios
      .post<{ refresh_token: string }, AxiosResponse<{ access_token: string }>>(
        `${this.baseURL}/auth/login/refresh`,
        {
          refresh_token,
        }
      )
      .then((res) => {
        window.location.replace(
          `${mp_host}/cp/redirect/inbound?redirect_url=${mp_host}${uri}&access_token=${res.data.access_token}`
        );
      })
      .catch((err) => {
        new APIError(
          (err as Error).message,
          "HttpClient Old MediaPortal navigation",
          ErrorLevel.FATAL,
          {
            accessToken: this.accessToken,
            refresh_token,
          }
        );

        window.location.replace(`${mp_host}/ui/login`);
      });
  }

  public async getAccessToken(refresh_token: string): Promise<string> {
    const { data } = await axios.post<
      IGetAccessTokenPayload,
      AxiosResponse<IGetAccessTokenResponse>
    >(`${this.baseURL}/auth/login/refresh`, {
      refresh_token,
    });

    return data.access_token;
  }
}

export const httpClient = new HTTPClient(
  process.env.REACT_APP_API_HOST ||
    "https://lab-edge-delivery-api.netauto.nsatc.net"
);
