import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/atoms/Button/Button";
import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";
import { ErrorLayer } from "../../../../../components/molecules/ErrorLayer/ErrorLayer";
import { EmptyPageDescription } from "../../../../../components/atoms/EmptyPage/EmptyPageWrapper";
import {
  useVyvxApiKey,
  useVyvxApiKeysFiberCompanies,
  useVyvxApiKeysOwners,
  useVyvxApiKeysRoles,
} from "../../../../../store/slices/user-management-api-keys/hooks";
import { ApiKeyPageContent } from "./ApiKeyPageContent";
import { VyvxApiKeyLayer } from "../../../../../components/layers/ApiKeys/ApiKeys";

export const VyvxApiKeyPage = (): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const history = useHistory();
  const apiKeyInfo = useVyvxApiKey();
  const roles = useVyvxApiKeysRoles();
  const owners = useVyvxApiKeysOwners();
  const fiberCompanies = useVyvxApiKeysFiberCompanies();

  return (
    <VyvxApiKeyLayer>
      <Container>
        {!!apiKeyInfo && !!roles && !!owners ? (
          <ApiKeyPageContent
            apiKeyInfo={apiKeyInfo}
            isVyvx={true}
            roles={roles}
            owners={owners}
            fiberCompanies={fiberCompanies}
          />
        ) : (
          <ErrorLayer
            title={t("API_KEY_PAGE_ERROR_TITLE")}
            content={
              <EmptyPageDescription>
                <div>{t("API_KEY_NOT_FOUND")}</div>
                <Button
                  onClick={() => {
                    history.push("/userManagement/apiKeys/vyvx");
                  }}
                  label={t("API_KEY_PAGE_ERROR_RETURN_BUTTON")}
                />
              </EmptyPageDescription>
            }
          />
        )}
      </Container>
    </VyvxApiKeyLayer>
  );
};

const Container = styled(PageContainer)``;
