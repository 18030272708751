import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type ReportingOverrideComponent = FeatureComponent<
  Exclude<IFeatures["uriRewrite"], undefined>
>;

export const ReportingOverride: ReportingOverrideComponent = ({
  feature,
  tPropertyPage,
}) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_REPORTING_OVERRIDE_TITLE")}
    >
      {feature}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
