import { FC } from "react";
import styled from "@emotion/styled";

export interface ITooltipTextProps {
  text: string;
  x: number;
  y: number;
  open: boolean;
  dataTestId?: string;
}

export const TooltipText: FC<ITooltipTextProps> = ({
  text,
  x,
  y,
  open,
  dataTestId,
}) => (
  <>
    {open && (
      <TooltipTextContainer
        x={x}
        y={y}
        data-testid={dataTestId}
        className="popup"
      >
        {text}
      </TooltipTextContainer>
    )}
  </>
);

const TooltipTextContainer = styled.div<{
  x: number;
  y: number;
}>`
  position: fixed;
  top: ${(props) => `${props.y}px`};
  left: ${(props) => `${props.x}px`};
  background-color: ${({ theme }) => theme.text.primary};
  color: ${({ theme }) => theme.text.secondaryAlt};
  border-radius: 4px;
  z-index: 99999;
  padding: 8px;
  border: solid 1px ${({ theme }) => theme.borders.mutedDark};
  opacity: 0.9;
`;
