import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import EmptyPageDarkSrc from "../../../../../assets/images/empty_configuration_page_dark.png";
import EmptyPageLightSrc from "../../../../../assets/images/empty_configuration_page_light.png";
import { EmptyPage } from "../../../../../components/atoms/EmptyPage/EmptyPage";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { InputField } from "../../../../../components/molecules/InputField/InputField";
import { Subway } from "../../../../../components/organisms/Subway/Subway";
import { VersionHistoryCard } from "../../../../../components/organisms/VersionHistoryCard/VersionHistoryCard";
import { ProductionSlot } from "../../../../../models/configuration";
import { IVersionHistory } from "../../../../../models/configuration/configuration_details.interfaces";
import {
  useIsProductionDeployed,
  useSelectedConfiguration,
} from "../../../../../store/slices/caching/hooks";

const PLACEHOLDER_IMAGES = { light: EmptyPageLightSrc, dark: EmptyPageDarkSrc };

interface IVersionProps {}

export const Versions: React.FC<IVersionProps> = () => {
  const { t } = useTranslation("configurationVersionsPage");
  const selectedConfiguration = useSelectedConfiguration();
  const [history, setHistory] = useState<IVersionHistory[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const deploymentData = useIsProductionDeployed(
    selectedConfiguration?.configName ?? ""
  );

  useEffect(() => {
    if (selectedConfiguration) {
      const versions = selectedConfiguration.versions;

      setHistory(versions);
    }
  }, [selectedConfiguration?.versions]);

  const filterHistory = useCallback(
    (item: IVersionHistory) => {
      if (!searchValue) return true;
      if (("v" + item.versionId.toString()).includes(searchValue)) {
        return true;
      }
      if (item.comment?.includes(searchValue)) {
        return true;
      }
      if (
        item.modifiedTime &&
        format(new Date(item.modifiedTime), "PPPppp")
          .toLowerCase()
          .includes(searchValue)
      ) {
        return true;
      }
      return false;
    },
    [searchValue]
  );

  return history.length > 0 ? (
    <VersionsContainer>
      <SearchContainer>
        <InputField
          hasBorder
          onChange={(value) => setSearchValue(value.toLowerCase())}
          placeholder={t("SEARCH_VERSION_PLACEHOLDER")}
          isSearchField
        />
      </SearchContainer>
      <br />
      <ExpandableContentContainer>
        <ExpandableContent
          title={t("VERSION_DESCRIPTION_TITLE")}
          content={t("VERSION_DESCRIPTION_CONTENT")}
        />
      </ExpandableContentContainer>
      <Subway
        activeIndex={history
          .filter(filterHistory)
          .reduce((prev: number | undefined, curr, index) => {
            if (curr.versionId === selectedConfiguration?.versionId) {
              return index;
            }
            return prev;
          }, undefined)}
        componentList={history.filter(filterHistory).map((version, i) => (
          <VersionHistoryCard
            key={i}
            versionHistory={version}
            productionSlot={
              version.versionId === deploymentData?.current
                ? ProductionSlot.CURRENT
                : version.versionId === deploymentData?.candidate
                ? ProductionSlot.CANDIDATE
                : undefined
            }
          />
        ))}
      />
    </VersionsContainer>
  ) : (
    <EmptyPage
      images={PLACEHOLDER_IMAGES}
      title={t("EMPTY_VERSIONS_LIST_TITLE")}
      content={t("EMPTY_VERSIONS_LIST_CONTENT")}
    />
  );
};

const VersionsContainer = styled.div``;

const ExpandableContentContainer = styled.div`
  padding-left: 21px;
`;

const SearchContainer = styled.div``;
