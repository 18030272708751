import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AnchorWrapper } from "../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { Divider } from "../../../../components/atoms/Divider/Divider";
import { MenuItem } from "../../../../components/molecules/AnchorMenu/AnchorMenu";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { useSubscriberSettingsSystemLimits } from "../../../../store/slices/subscriber-settings/hooks";
import {
  H2,
  TableInfoContainer,
  TableInfoTitle,
} from "./SubscriberSettings.styled";

interface InfoSectionProps {
  menu: MenuItem;
}

export const SystemLimitsSection = ({
  menu,
}: InfoSectionProps): ReactElement => {
  const { t } = useTranslation("subscribersSettingsPage");
  const infos = useSubscriberSettingsSystemLimits();

  const systemLimitsTable = [
    {
      title: t("SUBSCRIBER_SETTINGS_ALIASES_PER_SUB"),
      value: infos.aliasesPerProp,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_ALT_NAME_PER_CERT"),
      value: infos.aliasesPerProp,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_CCHO_EXT"),
      value: infos.ccho.ext,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_CCHO_INT"),
      value: infos.ccho.int,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_CUSTOM_LOG_DATA_KEY_NAME_LENGTH"),
      value: infos.customLogDataKeyNameLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_CUSTOM_LOG_DATA_VALUE_LENGTH"),
      value: infos.customLogDataValueLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_DEFAULT_CACHE"),
      value: infos.defaultCache,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_DEFINITIONS_PER_BLOC"),
      value: infos.definitionsPerBlock,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_ELEMENTS_PER_CUSTOM_LOG_DATA"),
      value: infos.elementsPerCustomLogData,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_EXPRESSION_LENGTH"),
      value: infos.expressionLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_FAILED_REFRESHED_TTL"),
      value: infos.failedRefreshTTL,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_HEADER_VALUE_LENGTH"),
      value: infos.headerValueLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_HEADER_NAME_LENGTH"),
      value: infos.headerNameLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_ELEMENTS_PER_SIMPLE_LIST"),
      value: infos.elementsPerSimpleList,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_LUA_SCRIPT_LENGTH"),
      value: infos.luaScriptLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_MATCH_GROUP_PER_GROUP"),
      value: infos.matchGroupPerBlock,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_MATCH_LOGIC_PER_SUB"),
      value: infos.matchLogicPerSub,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_MATCH_RULE_PER_GROUP"),
      value: infos.matchRulePerGroup,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_NEGATIVE_TLL"),
      value: infos.negativeTTL,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_PROPS_PER_SUB"),
      value: infos.propsPerSub,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_SIMPLE_LIST_ELEMENT_LENGTH"),
      value: infos.simpleListElementLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_ELEMENTS_PER_LIST"),
      value: infos.simpleListElementLength,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_REPORT_IDS_PER_SUB"),
      value: infos.reportIdsPerSub,
    },

    {
      title: t("SUBSCRIBER_SETTINGS_SERVICE_IMAGE_SIZE"),
      value: infos.serviceImageSize,
    },
  ];

  return (
    <>
      <AnchorWrapper
        id={menu.id}
        ref={menu.ref}
        key={menu.name}
        data-testid="subscriber_settings-systemLimits"
      >
        <H2>{t("SUBSCRIBER_SETTINGS_ANCHOR_SYSTEM_LIMITS")}</H2>
        <ExpandableContent
          title={t("SUBSCRIBER_SETTINGS_SYSTEM_LIMITS_DESCRIPTION_TITLE")}
          content={t("SUBSCRIBER_SETTINGS_SYSTEM_LIMITS_DESCRIPTION_CONTENT")}
        />
        <TableInfoContainer>
          {systemLimitsTable.map((subscriberInfo, index) => (
            <div key={index}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TableInfoTitle>{subscriberInfo.title}</TableInfoTitle>
                    </TableCell>
                    {typeof subscriberInfo.value === "number" ? (
                      <TableCell>
                        <span>{subscriberInfo.value}</span>
                      </TableCell>
                    ) : (
                      Object.entries(subscriberInfo.value).map(
                        ([key, value]) => {
                          return (
                            <TableCell key={key}>
                              <p>{key}</p>
                              <b>{value}</b>
                            </TableCell>
                          );
                        }
                      )
                    )}
                  </TableRow>
                </TableBody>
              </Table>
              {index !== systemLimitsTable.length - 1 && <Divider />}
            </div>
          ))}
        </TableInfoContainer>
      </AnchorWrapper>
    </>
  );
};

const Table = styled.div`
  display: table;
  width: 65%;
`;

const TableBody = styled.div`
  display: table-row-group;
`;

const TableRow = styled.div`
  display: table-row;
`;

const TableCell = styled.div<{ title?: boolean }>`
  display: table-cell;
  padding: 3px 10px;
  width: 25%;

  &:first-child {
    width: 50%;
  }
`;
