import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type UriRewriteComponent = FeatureComponent<
  Exclude<IFeatures["uriRewrite"], undefined>
>;

export const UriRewrite: UriRewriteComponent = ({ feature, tPropertyPage }) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock title={tPropertyPage("FEATURE_CARD_URI_TITLE")}>
      {feature}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
