import styled from "@emotion/styled";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLink } from "../../../../../../components/atoms/ButtonLink/ButtonLink";

import {
  FlexBox,
  FlexBoxBase,
} from "../../../../../../components/atoms/FlexBox/FlexBox";
import { TitleContentButtonsModalBody } from "../../../../../../components/molecules/TitleContentButtonsModalBody/TitleContentButtonsModalBody";
import { Dropdown } from "../../../../../../components/organisms/Dropdown/Dropdown";
import {
  useVyvxAvailableFiberCompanies,
  useVyvxAvailableRoles,
  useVyvxAvailableSatelliteCompanies,
} from "../../../../../../store/slices/vyvx/hooks";
import { VyvxCompanyType } from "./EditVyvxAssociatedCompaniesModal";

interface EditVyvxCompaniesModalProps {
  selectedRole?: string;
  selectedFiberCompany?: string;
  selectedSatelliteCompany?: string;
  setSelectedRole: (role: string) => void;
  setSelectedFiberCompany: (fiberCompany: string) => void;
  setSelectedSatelliteCompany: (satelliteCompany: string) => void;
  openAssociatedCompaniesModal: (type: VyvxCompanyType) => void;
  onCancel: () => void;
  onSubmit: () => void;
}

export const EditVyvxCompaniesModal = ({
  selectedRole,
  selectedFiberCompany,
  selectedSatelliteCompany,
  setSelectedRole,
  setSelectedFiberCompany,
  setSelectedSatelliteCompany,
  openAssociatedCompaniesModal,
  onCancel,
  onSubmit,
}: EditVyvxCompaniesModalProps): ReactElement => {
  const { t } = useTranslation("usersPage");

  const availableRoles = useVyvxAvailableRoles();
  const availableFiberCompanies = useVyvxAvailableFiberCompanies();
  const availableSatelliteCompanies = useVyvxAvailableSatelliteCompanies();

  return (
    <TitleContentButtonsModalBody
      title={t("VYVX_SECTION_EDIT_COMPANIES_MODAL_TITLE")}
      cancelButton={{
        label: t("VYVX_SECTION_EDIT_COMPANIES_MODAL_CANCEL_BUTTON_LABEL"),
        backgroundColor: "baseLight",
        textColor: "primary",
        onClick: onCancel,
      }}
      submitButton={{
        label: t("VYVX_SECTION_EDIT_COMPANIES_MODAL_SUBMIT_BUTTON_LABEL"),
        onClick: onSubmit,
      }}
    >
      <Data>
        <Line>
          <Label>{t("VYVX_SECTION_EDIT_COMPANIES_MODAL_ROLE_LABEL")}</Label>
          <ColumnContainer>
            <DropdownContainer>
              <Dropdown
                items={availableRoles.map((role) => ({
                  label: role,
                  value: role,
                  default: role === selectedRole,
                }))}
                id="user-vyvx-role-dropdown"
                placeholder=""
                onSelect={(item) => setSelectedRole(item.value)}
              />
            </DropdownContainer>
          </ColumnContainer>
        </Line>
        <Line>
          <Label>{t("VYVX_SECTION_EDIT_COMPANIES_MODAL_FIBER_LABEL")}</Label>
          <ColumnContainer>
            <DropdownContainer>
              <Dropdown
                items={availableFiberCompanies.map((fiberCompany) => ({
                  label: fiberCompany.companyName,
                  value: fiberCompany.companyAbbr,
                  default: fiberCompany.companyAbbr === selectedFiberCompany,
                }))}
                id="user-vyvx-fiber-company-dropdown"
                placeholder=""
                onSelect={(item) => setSelectedFiberCompany(item.value)}
              />
            </DropdownContainer>
            <Link
              label={t(
                "VYVX_SECTION_EDIT_COMPANIES_MODAL_FIBER_ASSOCIATED_COMPANIES_LABEL"
              )}
              onClick={() =>
                openAssociatedCompaniesModal(VyvxCompanyType.FIBER)
              }
            />
          </ColumnContainer>
        </Line>
        <Line>
          <Label>
            {t("VYVX_SECTION_EDIT_COMPANIES_MODAL_SATELLITE_LABEL")}
          </Label>
          <ColumnContainer>
            <DropdownContainer>
              <Dropdown
                items={availableSatelliteCompanies.map((satelliteCompany) => ({
                  label: satelliteCompany.companyName,
                  value: satelliteCompany.companyAbbr,
                  default:
                    satelliteCompany.companyAbbr === selectedSatelliteCompany,
                }))}
                id="user-vyvx-satellite-company-dropdown"
                placeholder=""
                onSelect={(item) => setSelectedSatelliteCompany(item.value)}
              />
            </DropdownContainer>
            <Link
              label={t(
                "VYVX_SECTION_EDIT_COMPANIES_MODAL_SATELLITE_ASSOCIATED_COMPANIES_LABEL"
              )}
              onClick={() =>
                openAssociatedCompaniesModal(VyvxCompanyType.SATELLITE)
              }
            />
          </ColumnContainer>
        </Line>
      </Data>
    </TitleContentButtonsModalBody>
  );
};

const Data = styled.div`
  padding: 32px 0 16px 0;
`;

const Line = styled(FlexBox)`
  justify-content: space-between;
  padding-bottom: 16px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ColumnContainer = styled(FlexBoxBase)`
  flex-direction: column;
  width: 60%;
  align-items: start;
`;

const DropdownContainer = styled.div`
  width: 100%;
  .chi-dropdown {
    width: 100%;
    margin: 0 !important;

    .chi-dropdown__trigger {
      width: 100%;
      padding: 12px;

      ::after {
        right: 12px;
        position: absolute;
      }
    }

    .chi-dropdown__menu {
      max-height: 300px !important;
      div {
        overflow: scroll;
        max-height: 300px;
      }
    }
  }
`;

const Link = styled(ButtonLink)`
  padding-top: 8px;
`;
