import styled from "@emotion/styled";
import { FC } from "react";
import { Card } from "../../atoms/Card/Card";
import { RadioInput } from "../../atoms/RadioInput/RadioInput";

export interface RadioCardProps {
  selected: boolean;
  onSelect: () => void;
}

export const RadioCard: FC<RadioCardProps> = ({
  selected,
  onSelect,
  children,
}) => {
  return (
    <StyledCard onClick={onSelect}>
      <RadioInput selected={selected ? 1 : 0} />
      {children}
    </StyledCard>
  );
};

export const StyledCard = styled(Card)`
  padding: 16px;
  cursor: pointer;
`;
