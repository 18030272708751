import { ReportingOverrideDefinitionType } from "../../../store/slices/caching/types";

export interface IReportingOverrideDefinition {
  description?: string;
  reportingName: string;
}

export const getReportingOverrideDefinitionDescription = (
  def: ReportingOverrideDefinitionType
): string => def.reportingName;
