import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { IModalChildren } from "../../../../../../components/atoms/Modal/Modal";
import { VyvxLayer } from "../../../../../../components/layers/Vyvx/Vyvx";
import { APIError } from "../../../../../../models/error";
import { updateUserVyvxData } from "../../../../../../models/user-management/users";
import { UserVyvxData } from "../../../../../../models/user-management/users/users.interfaces";
import { VyvxCompany } from "../../../../../../models/vyvx/vyvx.interfaces";
import { handleAPIError } from "../../../../../../store/slices/api-error/thunks";
import { fetchUser } from "../../../../../../store/slices/user-management-users/thunk";
import { useAppDispatch } from "../../../../../../store/types";
import {
  EditVyvxAssociatedCompaniesModal,
  VyvxCompanyType,
} from "./EditVyvxAssociatedCompaniesModal";
import { EditVyvxCompaniesModal } from "./EditVyvxCompaniesModal";

interface EditVyvxModalProps extends IModalChildren {
  userId: number;
  vyvxData: UserVyvxData;
}

enum VyvxModalStatus {
  COMPANIES = "companies",
  ASSOCIATED_COMPANIES = "associated_companies",
}

export const EditVyvxModal = ({
  userId,
  vyvxData,
  closeModal,
}: EditVyvxModalProps): ReactElement => {
  const [modalStatus, setModalStatus] = useState(VyvxModalStatus.COMPANIES);
  const [associatedCompaniesType, setAssociatedCompaniesType] = useState(
    VyvxCompanyType.FIBER
  );
  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    vyvxData.role
  );
  const [selectedFiberCompany, setSelectedFiberCompany] = useState<
    string | undefined
  >(vyvxData.fiberCompanyAbbr);
  const [
    selectedFiberAssociatedCompanies,
    setSelectedFiberAssociatedCompanies,
  ] = useState(vyvxData.fiberAssociatedCompanies || []);
  const [selectedSatelliteCompany, setSelectedSatelliteCompany] = useState<
    string | undefined
  >(vyvxData.satelliteCompanyAbbr);
  const [
    selectedSatelliteAssociatedCompanies,
    setSelectedSatelliteAssociatedCompanies,
  ] = useState(vyvxData.satelliteAssociatedCompanies || []);

  const { t } = useTranslation("usersPage");
  const dispatch = useAppDispatch();

  return (
    <VyvxLayer>
      {modalStatus === VyvxModalStatus.COMPANIES ? (
        <EditVyvxCompaniesModal
          selectedRole={selectedRole}
          selectedFiberCompany={selectedFiberCompany}
          selectedSatelliteCompany={selectedSatelliteCompany}
          setSelectedRole={setSelectedRole}
          setSelectedFiberCompany={(company: string) => {
            setSelectedFiberCompany(company);
            setSelectedFiberAssociatedCompanies([]);
          }}
          setSelectedSatelliteCompany={(company: string) => {
            setSelectedSatelliteCompany(company);
            setSelectedSatelliteAssociatedCompanies([]);
          }}
          openAssociatedCompaniesModal={(type: VyvxCompanyType) => {
            setModalStatus(VyvxModalStatus.ASSOCIATED_COMPANIES);
            setAssociatedCompaniesType(type);
          }}
          onCancel={() => {
            if (closeModal) closeModal();
          }}
          onSubmit={async () => {
            try {
              await updateUserVyvxData(userId, {
                fiberCompanyAbbr: selectedFiberCompany,
                fiberAssociatedCompanies: selectedFiberAssociatedCompanies.map(
                  (company) => company.companyAbbr
                ),
                satelliteCompanyAbbr: selectedSatelliteCompany,
                satelliteAssociatedCompanies: selectedSatelliteAssociatedCompanies.map(
                  (company) => company.companyAbbr
                ),
                role: selectedRole,
                timezone: vyvxData.timezone,
                preferences: vyvxData.preferences,
              });
              dispatch(fetchUser(userId));

              toast.success(
                t("VYVX_SECTION_EDIT_COMPANIES_MODAL_SUCCESS_TOAST")
              );
            } catch (err) {
              dispatch(handleAPIError(err as APIError));
            } finally {
              if (closeModal) closeModal();
            }
          }}
        />
      ) : (
        <EditVyvxAssociatedCompaniesModal
          type={associatedCompaniesType}
          companyAbbr={
            associatedCompaniesType === VyvxCompanyType.FIBER
              ? selectedFiberCompany
              : selectedSatelliteCompany
          }
          onCancel={() => setModalStatus(VyvxModalStatus.COMPANIES)}
          onSubmit={(
            selectedCompanies: VyvxCompany[],
            type: VyvxCompanyType
          ) => {
            if (type === VyvxCompanyType.FIBER) {
              setSelectedFiberAssociatedCompanies(selectedCompanies);
            } else {
              setSelectedSatelliteAssociatedCompanies(selectedCompanies);
            }
            setModalStatus(VyvxModalStatus.COMPANIES);
          }}
          selectedCompanies={(associatedCompaniesType === VyvxCompanyType.FIBER
            ? selectedFiberAssociatedCompanies
            : selectedSatelliteAssociatedCompanies
          ).map((company) => company.companyName)}
        />
      )}
    </VyvxLayer>
  );
};
