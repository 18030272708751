import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { IModalChildren } from "../../../../../../components/atoms/Modal/Modal";
import { TitleContentButtonsModalBody } from "../../../../../../components/molecules/TitleContentButtonsModalBody/TitleContentButtonsModalBody";
import { ModalLineInputField } from "../../../../../../components/molecules/ModalLineInputField/ModalLineInputField";
import { Timezone } from "../../../../../../models/user-management/users/users.interfaces";
import { FlexBox } from "../../../../../../components/atoms/FlexBox/FlexBox";
import { Dropdown } from "../../../../../../components/organisms/Dropdown/Dropdown";
import { Checkbox } from "../../../../../../components/molecules/Checkbox/Checkbox";

interface UserPreferences {
  email: string;
  timezone?: Timezone;
  confirmationLetterEmail?: boolean;
  inNetwork?: boolean;
}

interface EditUserPreferencesModalProps extends IModalChildren {
  userPreferences: UserPreferences;
  save: (
    email: string,
    timezone?: Timezone,
    confirmationLetterEmail?: boolean,
    inNetwork?: boolean
  ) => void;
  isVyvx: boolean;
}

export const EditUserPreferencesModal = ({
  userPreferences,
  save,
  isVyvx,
  closeModal,
}: EditUserPreferencesModalProps): ReactElement => {
  const [email, setEmail] = useState(userPreferences.email);
  const [timezone, setTimezone] = useState(userPreferences.timezone);
  const [confirmationLetterEmail, setConfirmationLetterEmail] = useState(
    userPreferences.confirmationLetterEmail
  );
  const [inNetwork, setInNetwork] = useState(userPreferences.inNetwork);
  const { t } = useTranslation("usersPage");

  return (
    <TitleContentButtonsModalBody
      title={t("EDIT_PREFERENCES_MODAL_TITLE")}
      cancelButton={{
        label: t("EDIT_PREFERENCES_MODAL_CANCEL_BUTTON_LABEL"),
        backgroundColor: "baseLight",
        textColor: "primary",
        onClick: closeModal || (() => {}),
      }}
      submitButton={{
        label: t("EDIT_PREFERENCES_MODAL_SAVE_BUTTON_LABEL"),
        onClick: () => {
          save(email, timezone, confirmationLetterEmail, inNetwork);
          if (closeModal) closeModal();
        },
      }}
    >
      <Fields>
        <ModalLineInputField
          label={t("EDIT_PREFERENCES_MODAL_EMAIL_LABEL")}
          value={email}
          onChange={setEmail}
        />
        {isVyvx && (
          <>
            <Line>
              <Label>{t("EDIT_PREFERENCES_MODAL_TIMEZONE_LABEL")}</Label>
              <DropdownContainer>
                <Dropdown
                  id="preferences-timezone-dropdown"
                  placeholder={timezone || Timezone.GMT}
                  items={Array.from(Object.values(Timezone)).map((value) => ({
                    label: value,
                    value,
                  }))}
                  onSelect={(item) => setTimezone(item.value)}
                />
              </DropdownContainer>
            </Line>
            <CheckboxLine>
              <Label>
                {t("EDIT_PREFERENCES_MODAL_CONFIRMATION_LETTER_EMAIL_LABEL")}
              </Label>
              <PreferenceCheckboxContainer>
                <Checkbox
                  label={t(
                    "EDIT_PREFERENCES_MODAL_CONFIRMATION_LETTER_EMAIL_BOX_LABEL"
                  )}
                  id="preferences-confirmation-letter-email-checkbox"
                  onChange={setConfirmationLetterEmail}
                  checked={confirmationLetterEmail}
                />
              </PreferenceCheckboxContainer>
            </CheckboxLine>
            <CheckboxLine>
              <Label>{t("EDIT_PREFERENCES_MODAL_SHOW_IN_NETWORK_LABEL")}</Label>
              <PreferenceCheckboxContainer>
                <Checkbox
                  label={t("EDIT_PREFERENCES_MODAL_SHOW_IN_NETWORK_BOX_LABEL")}
                  id="preferences-show-in-network-checkbox"
                  onChange={setInNetwork}
                  checked={inNetwork}
                />
              </PreferenceCheckboxContainer>
            </CheckboxLine>
          </>
        )}
      </Fields>
    </TitleContentButtonsModalBody>
  );
};

const Fields = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
`;

const Line = styled(FlexBox)`
  justify-content: space-between;
  padding-bottom: 16px;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const DropdownContainer = styled.div`
  width: 60%;
  .chi-dropdown {
    width: 100%;
    margin: 0 !important;

    button {
      width: 100%;
      padding: 12px;
      line-height: 1rem;
      ::after {
        position: absolute;
        right: 14px;
      }
    }
  }
`;

const PreferenceCheckboxContainer = styled.div`
  width: 60%;
`;

const CheckboxLine = styled(Line)`
  padding-top: 8px;
  padding-bottom: 24px;
`;
