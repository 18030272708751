import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  NamingModal,
  NamingModalProps,
} from "../../../../components/molecules/NamingModal/NamingModal";
import { CertificateType } from "../../../../store/slices/caching/types";

export interface IAddCertificateModalProps {
  certificates?: CertificateType[];
  openButtonProps?: Partial<NamingModalProps["openButtonProps"]>;
}

export const AddCertificateModal = ({
  certificates,
  openButtonProps = { size: "lg" },
}: IAddCertificateModalProps): ReactElement => {
  const history = useHistory();

  const [t] = useTranslation("certificatesPage");
  const [tMisc] = useTranslation("misc");

  return (
    <NamingModal
      id="add-certificate"
      title={t("NAME_MODAL_TITLE")}
      description={t("NAME_MODAL_DESCRIPTION")}
      inputPlaceholder={t("NAME_MODAL_INPUT_PLACEHOLDER")}
      openButtonProps={{
        label: t("ADD_CERTIFICATE_BUTTON"),
        dataTestId: "add-certificate-button",
        ...openButtonProps,
      }}
      cancelButtonProps={{
        label: tMisc("CANCEL"),
      }}
      saveButtonProps={{
        label: tMisc("CONFIRM"),
        onClick: (name: string) => {
          history.push("/cmv3/certificates/add", { cert_name: name });
        },
      }}
      checkErrors={(value: string) => {
        const result: string[] = [];
        if (value.includes(" ")) result.push(t("NAME_MODAL_SPACE_ERROR"));

        if (certificates?.some((cert) => cert.cert_name === value)) {
          result.push(t("NAME_MODAL_USED_NAME_ERROR"));
        }

        return result;
      }}
    />
  );
};
