export type VariableFields = {
  [key in VariableExpression]: {
    description: string;
  };
};

export enum VariableExpression {
  PROP_LISTENER_ADDR = "$prop.listener-addr",
  PROP_LISTENER_PROTO = "$prop.cli-ssl.proto",
  REQ_HEADER = "$req.h.",
  RESP_HEADER = "$resp.h.",
  RESP_STATUS = "$resp.status",
}
