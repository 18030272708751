import { createContext, useContext } from "react";
import { TableState } from "../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { Radios } from "../../components/templates/Locations/types";
import { Region, TableSiteWithPercentage } from "../../models/models";

export interface WidgetFilters {
  [FilterFields.PROPERTY]?: PropertiesFilter;
  [FilterFields.REGION]?: RegionFilter;
  [FilterFields.PERIOD]?: PeriodFilter;
  [FilterFields.SPLIT_TABLE_METRICS]?: (keyof TableSiteWithPercentage)[];
  [FilterFields.CHART_OR_MAP_DISPLAY]?: Radios;
  [FilterFields.SELECTED_METRO]?: string[];
  [FilterFields.SELECTED_REGION]?: Region[];
  [FilterFields.OPENED_REGIONS]?: number[];
  [FilterFields.TABLE_STATE]?: TableState<Record<string, unknown>>;
  [FilterFields.SELECTED_PROPERTIES]?: number[];
}

export enum FilterFields {
  PROPERTY = "property",
  REGION = "region",
  PERIOD = "period",
  SPLIT_TABLE_METRICS = "splitTableMetrics",
  CHART_OR_MAP_DISPLAY = "chartOrMapDisplay",
  SELECTED_METRO = "selectedMetros",
  SELECTED_REGION = "selectedRegion",
  OPENED_REGIONS = "openedRegions",
  TABLE_STATE = "tableState",
  SELECTED_PROPERTIES = "selectedProperties",
}

export type SavedPreferences = Map<string, WidgetFilters>;

export type UpdateFilterFonctionArgs =
  | { key: FilterFields.PROPERTY; value: PropertiesFilter }
  | { key: FilterFields.REGION; value: RegionFilter }
  | { key: FilterFields.PERIOD; value: PeriodFilter }
  | {
      key: FilterFields.SPLIT_TABLE_METRICS;
      value: (keyof TableSiteWithPercentage)[];
    }
  | { key: FilterFields.CHART_OR_MAP_DISPLAY; value: Radios }
  | { key: FilterFields.SELECTED_METRO; value: string[] }
  | { key: FilterFields.SELECTED_REGION; value: Region[] }
  | { key: FilterFields.OPENED_REGIONS; value: number[] }
  | { key: FilterFields.TABLE_STATE; value: TableState<any> }
  | { key: FilterFields.SELECTED_PROPERTIES; value: number[] };

export type IFiltersContext = {
  preferences: SavedPreferences;
  updateWidgetPreference: (
    widgetId: string,
    arg: UpdateFilterFonctionArgs
  ) => void;
};

export type PropertiesFilter = string[];

export type RegionFilter = Region[];

export enum PeriodFilter {
  ALL_TIME = "allTime",
  LAST_30MINS = "last30mins",
  LAST_HOUR = "lastHour",
}

export const FiltersContext = createContext<IFiltersContext | undefined>(
  undefined
);

export const useFiltersContext = (): IFiltersContext => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error("useFilters must be used within a FiltersContextProvider");
  }
  return context;
};
