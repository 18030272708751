import styled from "@emotion/styled";

import { FlexBox } from "../FlexBox/FlexBox";

export const RowInfoContainer = styled.div`
  margin-top: 36px;
  & > div:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const RowInfo = styled(FlexBox)`
  justify-content: flex-start;
  & > div:not(:last-child) {
    margin-right: 32px;
  }
`;
