import { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../store/types";
import { handleFetchSubscriberData } from "../../../store/slices/caching/thunks";
import {
  useCachingApiErrorCode,
  useCachingStatus,
  useDispatchProduction,
} from "../../../store/slices/caching/hooks";
import { Status } from "../../../@types/status";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";
import { EmptyPageDescription } from "../../atoms/EmptyPage/EmptyPageWrapper";
import ErrorPageWaitingDarkSrc from "../../../assets/images/error_page_waiting_dark.png";
import ErrorPageWaitingLightSrc from "../../../assets/images/error_page_waiting_light.png";

export const CachingLayer: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const dispatchProduction = useDispatchProduction();
  const subscriberId = useAppSelector((store) => store.subscriber.subscriberId);
  const scid = useAppSelector((store) => store.subscriber.scid);
  const status = useCachingStatus();
  const apiErrorCode = useCachingApiErrorCode();
  const intervalTimeoutRef = useRef<number>();
  const { t } = useTranslation("layers");

  useEffect(() => {
    dispatch(handleFetchSubscriberData());

    intervalTimeoutRef.current = window.setInterval(dispatchProduction, 30000);

    return () => {
      clearInterval(intervalTimeoutRef.current);
    };
    // Ignore dispatch & dispatchProduction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberId]);

  if (status === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("CACHING_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (status === Status.SUCCESS && subscriberId && scid) {
    return <>{children}</>;
  }

  if (status === Status.ERROR) {
    const props =
      apiErrorCode === 403010
        ? {
            content: (
              <EmptyPageDescription>
                {t("CACHING_ERROR_SCID_NOT_ACTIVATED")}
              </EmptyPageDescription>
            ),
            title: "",
            images: {
              light: ErrorPageWaitingLightSrc,
              dark: ErrorPageWaitingDarkSrc,
            },
          }
        : undefined;
    return <ErrorLayer {...props} />;
  }

  return null;
};
