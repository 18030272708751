import { AppThunk } from "./../../store";
import { httpClient } from "./../../../core/http-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { AuthSlice } from "./types";

const mp_host = process.env.REACT_APP_MP_HOST || "https://mp-test.lumen.com";
export const auth_page_link = `${mp_host}/ui/login`;

const initialState: AuthSlice = {
  accessToken: "",
  refeshToken: getRefreshToken(),
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (
      state,
      action: PayloadAction<AuthSlice["accessToken"]>
    ) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (
      state,
      action: PayloadAction<AuthSlice["refeshToken"]>
    ) => {
      state.refeshToken = action.payload;
    },
    setLoading: (state, action: PayloadAction<AuthSlice["loading"]>) => {
      state.loading = action.payload;
    },
  },
});

export const setAccessToken = (token: string): AppThunk => (dispatch) => {
  dispatch(authSlice.actions.setAccessToken(token));
  httpClient.accessToken = token;
};

export const { setRefreshToken, setLoading } = authSlice.actions;

export default authSlice.reducer;
