import { FC, useContext, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { toast } from "react-toastify";

import { ReactComponent as CrossSVG } from "../../../assets/icons/cross.svg";
import { Theme, ThemeContext } from "../../../contexts/themeContext";
import { useIsMounted } from "../../../hooks/use-is-mounted";
import { APIError } from "../../../models/error";
import { sendFeedback } from "../../../models/feedback";
import { Button as B } from "../../atoms/Button/Button";
import { Button } from "./Button";
import { FeedbackInput } from "./FeedbackInput";
import { FileUpload } from "./FileUpload";
import { useTranslation } from "react-i18next";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { InputField } from "../../molecules/InputField/InputField";
import { TextArea } from "../../molecules/TextArea/TextArea";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";

const TRANSITION_MS = 400;
const MAX_FILE_TO_UPLOAD = 3;

export const Feedback: FC = () => {
  const modalRef = useRef(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isMounted = useIsMounted();
  const handleMediaPortalError = useDispatchAPIError();
  const { t } = useTranslation("misc");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);

  const [loading, setLoading] = useState(false);

  const { theme } = useContext(ThemeContext);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await sendFeedback(subject, message, files);
      handleClose(true);
      toast.success("Thank you for the feedback!");
    } catch (error) {
      if (isMounted) {
        setLoading(false);
      }

      handleMediaPortalError(error as APIError);
    }
  };

  const handleClose = (shouldReset = false) => {
    sidebarRef.current?.classList.remove("open");

    timeoutRef.current = setTimeout(() => {
      if (isMounted.current) {
        setDrawerOpen(false);

        if (shouldReset) {
          setSubject("");
          setMessage("");
          setFiles([]);
          setLoading(false);
        }
      }
    }, TRANSITION_MS);
  };

  const handleCloseOnClick = () => {
    handleClose();
  };

  useEffect(() => {
    if (drawerOpen) sidebarRef.current?.classList.add("open");

    return () => {
      clearTimeout(timeoutRef.current!);
    };
  }, [drawerOpen]);

  return (
    <>
      <Button
        onClick={() => {
          setDrawerOpen(true);
        }}
      />
      <Modal
        ref={modalRef}
        open={drawerOpen}
        onClick={(e) => {
          if (e.target === modalRef.current) handleClose();
        }}
      >
        <ModalContent ref={sidebarRef}>
          <ModalTitleContainer>
            <CrossContainer
              role="button"
              onClick={handleCloseOnClick}
              data-testid="feedback-modal-cross-button"
            >
              <Cross />
            </CrossContainer>
            <ModalTitle>{t("FEEDBACK_TITLE")}</ModalTitle>
          </ModalTitleContainer>
          <ModalBody>
            <CustomRemark
              rehypeReactOptions={{
                components: {
                  p: (props: any) => <RemarkP {...props} />,
                  ul: (props: any) => <RemarkUl {...props} />,
                },
              }}
            >
              {t("FEEDBACK_DESCRIPTION")}
            </CustomRemark>

            <FeedbackInput
              id="subject"
              label={t("FEEDBACK_INPUT_LABEL_SUBJECT")}
            >
              <InputField
                className="feedback-input-container"
                placeholder={t("FEEDBACK_INPUT_PLACEHOLDER_SUBJECT")}
                value={subject}
                id="feedback-subject-input"
                onChange={setSubject}
                hasBorder
                dataTestId="feedback-subject-input"
              />
            </FeedbackInput>

            <FeedbackInput
              id="message"
              label={t("FEEDBACK_INPUT_LABEL_MESSAGE")}
            >
              <StyledTextArea
                className="-text--xs"
                onChange={setMessage}
                placeholder={t("FEEDBACK_INPUT_PLACEHOLDER_MESSAGE")}
                value={message}
                testID="feedback-message-input"
              />
            </FeedbackInput>

            <FileUpload
              files={files}
              maxFiles={MAX_FILE_TO_UPLOAD}
              setFiles={(newFiles) => {
                setFiles((currentFiles) =>
                  currentFiles.concat(newFiles).slice(0, MAX_FILE_TO_UPLOAD)
                );
              }}
              onDeleteFile={(index) => {
                setFiles((_files) => _files.filter((_, i) => i !== index));
              }}
            />

            <ButtonsContainer>
              <B
                className={theme === Theme.LIGHT_MODE ? "-light" : "-dark"}
                onClick={handleCloseOnClick}
                label={t("CANCEL")}
                dataTestId="feedback-form-cancel-button"
                backgroundColor="baseLight"
                textColor="primary"
                borderColor="mutedLight"
              />
              <B
                onClick={handleSubmit}
                label={t("SEND")}
                dataTestId="feedback-form-send-button"
                disabled={loading || !subject || !message}
              />
            </ButtonsContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const Modal = styled.div<{ open: boolean }>`
  display: none;
  font-family: Inter;
  text-align: initial;
  ${({ open }) =>
    open &&
    css`
      display: block;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9998;
      background-color: rgba(0, 0, 0, 0.64);
    `}
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.base};
  width: 40%;
  height: 100%;
  margin-left: auto;
  position: relative;
  overflow: auto;
  transition: all ${TRANSITION_MS / 1000}s ease-out;
  right: -100px;
  opacity: 0;
  color: ${({ theme }) => theme.text.primary};

  &.open {
    right: 0;
    opacity: 1;
  }
`;

const ModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: 32px;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;

const ModalBody = styled.div`
  margin: 24px;
  padding: 32px;
  border-radius: 8px;

  ol,
  ul {
    margin: 0;
    padding: 0;
    padding-left: 18px;
  }

  ol {
    li {
      margin-bottom: 32px;
    }

    ol {
      list-style-type: lower-alpha;
      padding: 0;
      margin-left: 18px;
      margin-top: 32px;
    }
  }

  ul {
    margin-bottom: 32px;
  }

  li {
    &::marker {
      font-weight: 600;
    }
  }

  & > .feedback-mb {
    margin-bottom: 32px;
  }
`;

const CrossContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 24px;
  transition-duration: 0.2s;
  transition-property: transform;

  &:active {
    transform: translateY(4px);
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.2) rotate(90deg);
  }
`;

const Cross = styled(CrossSVG)`
  height: 1em;
  fill: ${({ theme }) => theme.icon.secondary};
`;

const RemarkP = styled.p`
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 0;
  margin-bottom: 32px;

  strong {
    font-size: 16px !important;
  }
`;

const RemarkUl = styled.ul`
  font-size: 16px !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;

  & > button:first-child {
    margin-right: 16px;
  }
`;

const StyledTextArea = styled(TextArea)`
  :focus {
    box-shadow: none;
  }
`;
