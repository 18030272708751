import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import {
  RowInfo,
  RowInfoContainer,
} from "../../../../../components/atoms/RowInfo/RowInfo";
import { RowInfoDetails } from "../../../../../components/molecules/RowInfoDetails/RowInfoDetails";
import { AccessGroupInfo } from "../../../../../models/user-management/access-group/access-group.interfaces";

export const AccessGroupDetails = ({
  accessGroupInfo,
}: {
  accessGroupInfo?: AccessGroupInfo;
}): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  if (!accessGroupInfo) {
    return <></>;
  }

  return (
    <RowInfoContainer>
      <RowInfo>
        <RowInfoDetails
          title={t("ACCESS_GROUP_PAGE_DETAILS_ACCESS_GROUP_LABEL")}
          value={accessGroupInfo.accessGroupId}
        />
        <RowInfoDetails
          title={t("ACCESS_GROUP_PAGE_DETAILS_CUSTOMER_NUMBER_LABEL")}
          value={accessGroupInfo.busOrgId}
        />
        <RowInfoDetails
          title={t("ACCESS_GROUPS_TABLE_CREATION_DATE_LABEL")}
          value={format(new Date(accessGroupInfo.createdDate), "PPP")}
        />
      </RowInfo>
      <RowInfo>
        <RowInfoDetails
          title={t("ACCESS_GROUP_PAGE_DETAILS_PARENT_LABEL")}
          value={accessGroupInfo.parentAccessGroupName}
        />
        <RowInfoDetails
          title={t("ACCESS_GROUP_PAGE_DESCRIPTION_LABEL")}
          value={accessGroupInfo.description}
        />
      </RowInfo>
    </RowInfoContainer>
  );
};
