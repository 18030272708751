import { FC } from "react";

import {
  MapSiteFieldsToLabel,
  Metrics,
} from "../../../modules/rtm/models/models";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { HeadlessDropDown } from "../Dropdown/HeadlessDropdown";

export const MetricsDropdownItems = [
  {
    label: MapSiteFieldsToLabel.get(Metrics.reqPerSec) as string,
    value: Metrics.reqPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.mbPerSec) as string,
    value: Metrics.mbPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.missMbPerSec) as string,
    value: Metrics.missMbPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.missPerSec) as string,
    value: Metrics.missPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.fourZeroFourPerSec) as string,
    value: Metrics.fourZeroFourPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.fiveZeroThreePerSec) as string,
    value: Metrics.fiveZeroThreePerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.fiveZeroFourPerSec) as string,
    value: Metrics.fiveZeroFourPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.fiveXXPerSec) as string,
    value: Metrics.fiveXXPerSec,
  },
  {
    label: MapSiteFieldsToLabel.get(Metrics.hitRatePercent) as string,
    value: Metrics.hitRatePercent,
  },
];

export const MetricSelectionDropdown: FC<{
  selectedItems: IDropdownItem[];
  onChange: (values: IDropdownItem[]) => void;
}> = ({ onChange, selectedItems }) => {
  return (
    <HeadlessDropDown
      items={MetricsDropdownItems}
      multiSelect
      selectedItems={selectedItems}
      setSelectedItems={onChange}
      button={(props) => (
        <Icon
          name={Icons.TRIPLE_DOT}
          onClick={props.onClick}
          size={{ width: 18, height: 12 }}
        />
      )}
      position="left"
    />
  );
};
