import styled from "@emotion/styled";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../components/atoms/Button/Button";
import { ButtonLink } from "../../../../../../components/atoms/ButtonLink/ButtonLink";
import { CustomCheckboxInput } from "../../../../../../components/atoms/Checkbox/CustomCheckBoxInput";
import {
  FlexBox,
  FlexBoxBase,
} from "../../../../../../components/atoms/FlexBox/FlexBox";
import { HeadlessDataTable } from "../../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../../../components/atoms/UserManagementTable/UserManagementTable";
import { SearchBar } from "../../../../../../components/molecules/SearchBar/SearchBar";
import { useBool } from "../../../../../../hooks/use-bool/useBool";

import { APIError } from "../../../../../../models/error";
import {
  getVyvxAvailableAssociatedFiberCompanies,
  getVyvxAvailableAssociatedSatelliteCompanies,
} from "../../../../../../models/vyvx";
import {
  VyvxAssociatedCompany,
  VyvxCompany,
} from "../../../../../../models/vyvx/vyvx.interfaces";
import { handleAPIError } from "../../../../../../store/slices/api-error/thunks";
import { useAppDispatch } from "../../../../../../store/types";
import {
  buildDisplayedAssociatedCompanies,
  generateAssociatedCompaniesSelectionState,
  handleFilterAssociatedCompanies,
  handleToggleAssociatedCompany,
} from "../helpers";

export enum VyvxCompanyType {
  FIBER = "fiber",
  SATELLITE = "satellite",
}

export type DisplayedAssociatedCompany = {
  label: string;
  id: string;
  children: string[];
};

interface EditVyvxAssociatedCompaniesModalProps {
  type: VyvxCompanyType;
  companyAbbr?: string;
  onCancel: () => void;
  onSubmit: (selectedCompanies: VyvxCompany[], type: VyvxCompanyType) => void;
  selectedCompanies: string[];
}

export const EditVyvxAssociatedCompaniesModal = ({
  type,
  companyAbbr,
  onCancel,
  onSubmit,
  selectedCompanies,
}: EditVyvxAssociatedCompaniesModalProps): ReactElement => {
  // These contain both groups & associated companies
  const [associatedCompanies, setAssociatedCompanies] = useState<
    DisplayedAssociatedCompany[]
  >([]);
  const [search, setSearch] = useState("");
  const [showSelectedOnly, toggleShowSelectedOnly] = useBool(false);
  // Only selected associated companies are included in here
  const [selectedRows, setSelectedRows] = useState<string[]>(selectedCompanies);

  const dispatch = useAppDispatch();
  const { t } = useTranslation("usersPage");

  useEffect(() => {
    if (companyAbbr) {
      switch (type) {
        case VyvxCompanyType.FIBER:
          getVyvxAvailableAssociatedFiberCompanies(companyAbbr)
            .then((companies: VyvxAssociatedCompany[]) =>
              setAssociatedCompanies(
                buildDisplayedAssociatedCompanies(companies)
              )
            )
            .catch((err) => dispatch(handleAPIError(err as APIError)));
          break;
        case VyvxCompanyType.SATELLITE:
          getVyvxAvailableAssociatedSatelliteCompanies(companyAbbr)
            .then((companies: VyvxAssociatedCompany[]) =>
              setAssociatedCompanies(
                buildDisplayedAssociatedCompanies(companies)
              )
            )
            .catch((err) => dispatch(handleAPIError(err as APIError)));
      }
    }
  }, [type, companyAbbr]);

  const displayedAssociatedCompanies: DisplayedAssociatedCompany[] = useMemo(
    () =>
      handleFilterAssociatedCompanies(
        search,
        showSelectedOnly,
        selectedRows
      )(associatedCompanies),
    [search, showSelectedOnly, selectedRows, associatedCompanies]
  );

  return (
    <Container>
      <Title>{t("VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_TITLE")}</Title>
      <SearchBar
        placeholder={t(
          "VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_SEARCH_PLACEHOLDER"
        )}
        onChange={setSearch}
      />
      <TableContainer>
        <HeadlessDataTable
          data={displayedAssociatedCompanies}
          columns={[
            {
              dataField: "label",
              name: t(
                "VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_TABLE_TITLE"
              ),
              formatter: (row) => {
                const status = generateAssociatedCompaniesSelectionState(
                  row,
                  selectedRows
                );

                return (
                  <Cell child={row.children.length === 0}>
                    <CustomCheckboxInput
                      selectionStatus={status}
                      onChange={() =>
                        setSelectedRows(
                          handleToggleAssociatedCompany(row, status)
                        )
                      }
                    />
                    <Label>{row.label}</Label>
                  </Cell>
                );
              },
            },
          ]}
          keyField="id"
          TableWrapper={UserManagementTable}
          TableHeadRow={StyledHeaderTr}
          TableHeadRowCell={UserManagementTableHeaderCell}
          TableBodyRow={UserManagementTableBodyRow}
          TableBodyRowCell={StyledBodyCell}
        />
      </TableContainer>
      <ButtonsRow>
        <ButtonLink
          label={
            showSelectedOnly
              ? t("VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_SHOW_ALL")
              : t(
                  "VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_SHOW_SELECTED",
                  {
                    counter: selectedRows.length,
                  }
                )
          }
          onClick={toggleShowSelectedOnly}
        />
        <FlexBox>
          <CancelButton
            label={t(
              "VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_CANCEL_BUTTON_LABEL"
            )}
            backgroundColor="baseLight"
            textColor="primary"
            borderColor="white"
            onClick={onCancel}
          />
          <SubmitButton
            label={t(
              "VYVX_SECTION_EDIT_ASSOCIATED_COMPANIES_MODAL_SUBMIT_BUTTON_LABEL"
            )}
            onClick={() =>
              onSubmit(
                associatedCompanies
                  .filter(
                    (company) =>
                      selectedRows.includes(company.label) &&
                      company.children.length === 0
                  )
                  .map((company) => ({
                    companyName: company.label,
                    companyAbbr: company.id,
                  })),
                type
              )
            }
          />
        </FlexBox>
      </ButtonsRow>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  max-width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const TableContainer = styled.div`
  margin: 32px 0;
  height: 45vh;
  overflow-y: auto;
`;

const Cell = styled(FlexBoxBase)<{ child: boolean }>`
  padding-left: ${({ child }) => (child ? "8px" : 0)};
  align-items: center;
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 16px;
`;

const StyledHeaderTr = styled(UserManagementTableHeaderRow)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;

const StyledBodyCell = styled.td`
  padding-top: 16px !important;
  padding-bottom: 16px;
`;

const ButtonsRow = styled(FlexBoxBase)`
  justify-content: space-between;
`;

const CancelButton = styled(Button)`
  padding: 12px 16px;
  margin-right: 16px;
`;

const SubmitButton = styled(Button)`
  padding: 12px 16px;
`;
