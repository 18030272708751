import { ORDERED_DEF_NAMES } from "../../store/slices/caching/constants";
import {
  PreparedConfiguration,
  prepareConfig,
} from "../../store/slices/caching/helpers/prepareConfig";
import { useSelectedConfiguration } from "../../store/slices/caching/hooks";
import { ConfigurationType } from "../../store/slices/caching/types";
import { getConfigDetailsByDefinitions } from "../../utils/getConfigDetailsByDefinitions";
import { useBool } from "../use-bool/useBool";
import { useOrderedDefinitions } from "../use-ordered-definitions/useOrderedDefinitions";

export const useRawJSON: () => {
  configuration: PreparedConfiguration | undefined;
  rawJSON: string;
  selectedConfiguration: ConfigurationType | undefined;
  definitionsToDisplay: typeof ORDERED_DEF_NAMES;
  setDefinitionsToDisplay: (defNames: typeof ORDERED_DEF_NAMES) => void;
  autoScroll: boolean;
  toggleAutoScroll: () => void;
} = () => {
  const selectedConfiguration = useSelectedConfiguration();
  const configuration = selectedConfiguration
    ? prepareConfig(false, selectedConfiguration)
    : undefined;
  const {
    definitionsToDisplay,
    setDefinitionsToDisplay,
  } = useOrderedDefinitions();
  const [autoScroll, toggleAutoScroll] = useBool(true);

  return {
    autoScroll,
    configuration,
    definitionsToDisplay,
    rawJSON: JSON.stringify(
      {
        comment: selectedConfiguration?.comment,
        config: selectedConfiguration?.config
          ? getConfigDetailsByDefinitions(
              selectedConfiguration.config,
              definitionsToDisplay
            )
          : undefined,
      },
      null,
      2
    ),
    selectedConfiguration,
    setDefinitionsToDisplay,
    toggleAutoScroll,
  };
};
