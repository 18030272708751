import { ReactElement, useContext } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { TextButton } from "../../../../../../components/atoms/TextButton/TextButton";
import { CodeEditor } from "../../../../../../components/organisms/CodeEditor/CodeEditor";
import { DiffEditor } from "../../../../../../components/organisms/DiffEditor/DiffEditor";
import { useRawJSON } from "../../../../../../hooks/use-raw-json/useRawJSON";
import { IVersionHistory } from "../../../../../../models/configuration/configuration_details.interfaces";
import { useConfigurations } from "../../../../../../store/slices/caching/hooks";
import { getConfigDetailsByDefinitions } from "../../../../../../utils/getConfigDetailsByDefinitions";
import { comparisonContext } from "../../comparisonContext";
import { CompareVersionModal } from "./CompareVersionModal";
import { ConfigNamesRow } from "../../../../../../components/organisms/ComparisonHeader/ConfigNamesRow";
import { ComparisonHeader } from "../../../../../../components/organisms/ComparisonHeader/ComparisonHeader";

export const RawJson = ({
  propertyName,
  showSearchBar,
  versions,
}: {
  propertyName?: string;
  showSearchBar?: boolean;
  versions?: IVersionHistory[];
}): ReactElement => {
  const configurations = useConfigurations();
  const {
    configToCompare,
    isComparison,
    selectedConfiguration,
    selectedVersion,
    setSelectedConfiguration,
    setSelectedVersion,
  } = useContext(comparisonContext);

  const {
    autoScroll,
    definitionsToDisplay,
    rawJSON,
    selectedConfiguration: defaultConfiguration,
    setDefinitionsToDisplay,
    toggleAutoScroll,
  } = useRawJSON();
  const searchText = propertyName ?? "";

  const shouldRenderComparison =
    defaultConfiguration &&
    !!versions &&
    versions.length > 0 &&
    !!configurations &&
    configurations.length > 0;

  return (
    <RawJsonContainer>
      <CTAContainer
        showSearchBar={showSearchBar && !selectedVersion}
        splitEditor={isComparison}
      >
        {shouldRenderComparison ? (
          <>
            <CompareVersionModal
              key={selectedConfiguration?.id}
              versions={versions!}
              configuration={defaultConfiguration!}
              configurations={configurations!}
              setSelectedVersion={setSelectedVersion}
              setSelectedConfiguration={setSelectedConfiguration}
            />
            {selectedVersion ? (
              <CloseComparisonBtn
                onClick={() => {
                  setSelectedVersion(undefined);
                }}
              />
            ) : (
              <CopyToClipboardBtn rawJSON={rawJSON} />
            )}
          </>
        ) : (
          <CopyToClipboardBtn rawJSON={rawJSON} />
        )}
      </CTAContainer>
      {isComparison ? (
        <>
          <ConfigNamesRow>
            <ComparisonHeader
              autoScroll={autoScroll}
              config1Name={defaultConfiguration?.configName}
              config1Version={defaultConfiguration?.versionId}
              config2Name={selectedConfiguration?.configName}
              config2Version={selectedVersion?.text}
              definitionsToDisplay={definitionsToDisplay}
              setDefinitionsToDisplay={setDefinitionsToDisplay}
              toggleAutoScroll={toggleAutoScroll}
            />
          </ConfigNamesRow>
          <DiffEditor
            id="json-diff-editor"
            codes={[
              rawJSON,
              JSON.stringify(
                {
                  comment: configToCompare?.comment,
                  config: configToCompare?.config
                    ? getConfigDetailsByDefinitions(
                        configToCompare.config,
                        definitionsToDisplay
                      )
                    : undefined,
                },
                null,
                2
              ),
            ]}
            autoScroll={autoScroll}
          />
        </>
      ) : (
        <CodeEditor
          id="json-editor"
          readOnly
          interactive
          code={rawJSON}
          onChange={() => {}}
          dynamicHeight
          searchText={searchText}
          showSearchBar={showSearchBar}
        />
      )}
    </RawJsonContainer>
  );
};

const CloseComparisonBtn = ({ onClick }: { onClick: () => void }) => {
  const [t] = useTranslation("misc");

  return (
    <TextButton onClick={onClick} data-testid="raw-json-close-the-comparison">
      {t("CLOSE_COMPARISON")}
    </TextButton>
  );
};

const CopyToClipboardBtn = ({ rawJSON }: { rawJSON: string }) => {
  const [t] = useTranslation("rawJsonPage");

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(rawJSON)
      .then(() => {
        toast.success(t("ALERT_COPY_SUCCESS"));
      })
      .catch(() => {
        toast.error(t("ALERT_COPY_FAIL"));
      });
  };

  return (
    <TextButton
      onClick={copyToClipboard}
      data-testid="raw-json-copy-to-clipboard"
    >
      {t("COPY_TO_CLIPBOARD")}
    </TextButton>
  );
};

const RawJsonContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const CTAContainer = styled.div<{
  showSearchBar?: boolean;
  splitEditor?: boolean;
}>`
  position: absolute;
  top: ${({ showSearchBar, splitEditor }) =>
    (showSearchBar ? 84 : 24) + (splitEditor ? 40 : 0)}px;
  right: 38px;
  color: ${({ theme }) => theme.interactive.buttonPrimary};
  z-index: 100;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;

  & > div:not(:last-child) {
    margin-right: 35px;
  }
`;
