import { ReactElement, useRef, useState } from "react";
import styled from "@emotion/styled";
import { animated, config, useTransition } from "react-spring";

import { InputField } from "../../molecules/InputField/InputField";
import { Button } from "../../atoms/Button/Button";
import {
  buildNewDropdownItems,
  filterOnSearch,
  getSelectedItems,
  tickAll,
} from "./helpers";
import {
  FilterMultiSelectDropdownItem,
  FilterMultiSelectDropdownOption,
} from "../../atoms/FilterMultiSelectDropdownOption/FilterMultiSelectDropdownOption";
import { SelectionStatus } from "../../atoms/Checkbox/CustomCheckBoxInput";
import { useClickOutsideRef } from "../../../hooks/use-detect-click-outside-ref";

export interface FilterMultiSelectDropdownProps<T> {
  onFilter: (items: FilterMultiSelectDropdownItem<T>[]) => void;
  items: FilterMultiSelectDropdownItem<T>[];
  searchPlaceholder: string;
  applyButtonLabel: string;
  button: JSX.Element;
  hasSelectAll: boolean;
}

export const FilterMultiSelectDropdown = <T,>({
  onFilter,
  items,
  searchPlaceholder,
  applyButtonLabel,
  button,
  hasSelectAll,
}: FilterMultiSelectDropdownProps<T>): ReactElement => {
  const [dropdownItems, setDropdownItems] = useState<
    FilterMultiSelectDropdownItem<T>[]
  >(items);
  const [searchValue, setSearchValue] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectAllSelectedStatus, setSelectAllSelectedStatus] = useState(
    SelectionStatus.NOT_SELECTED
  );

  const opening = useTransition(open, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideRef(dropdownRef, () => {
    setOpen(false);
  });

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButtonContainer
        onClick={(e) => {
          setOpen((current) => !current);
          e.stopPropagation();
        }}
      >
        {button}
      </DropdownButtonContainer>
      {opening(
        (styles, show) =>
          show && (
            <Container style={styles}>
              <InputContainer>
                <InputField
                  hasBorder
                  onChange={setSearchValue}
                  placeholder={searchPlaceholder}
                  isSearchField={true}
                />
              </InputContainer>
              <DataContainer>
                {hasSelectAll && (
                  <FilterMultiSelectDropdownOption
                    item={{
                      label: "Select All",
                      value: "Select All",
                      status: selectAllSelectedStatus,
                    }}
                    onSelect={(item: FilterMultiSelectDropdownItem<string>) => {
                      const newStatus =
                        item.status === SelectionStatus.SELECTED
                          ? SelectionStatus.NOT_SELECTED
                          : SelectionStatus.SELECTED;
                      setDropdownItems((oldItems) =>
                        tickAll(oldItems, newStatus)
                      );
                      setSelectAllSelectedStatus(newStatus);
                    }}
                  />
                )}
                {filterOnSearch(
                  dropdownItems,
                  searchValue.trim().toLowerCase()
                ).map((item) => (
                  <FilterMultiSelectDropdownOption
                    key={item.label}
                    item={item}
                    onSelect={(newItem: FilterMultiSelectDropdownItem<T>) => {
                      setDropdownItems((oldItems) =>
                        buildNewDropdownItems(oldItems, newItem)
                      );
                      setSelectAllSelectedStatus(SelectionStatus.NOT_SELECTED);
                    }}
                  />
                ))}
              </DataContainer>
              <ButtonContainer>
                <ApplyButton
                  label={applyButtonLabel}
                  onClick={() => {
                    onFilter(getSelectedItems(dropdownItems));
                    setOpen(false);
                  }}
                />
              </ButtonContainer>
            </Container>
          )
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  display: inline-block;
`;

const DropdownButtonContainer = styled.div``;

const Container = styled(animated.div)`
  position: absolute;
  min-width: 303px;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  z-index: 5;
  padding: 4px;
  box-shadow: 0px 8px 16px rgba(36, 37, 38, 0.12);
`;

const InputContainer = styled.div`
  padding: 16px 1px 0 16px;
`;

const DataContainer = styled.div`
  max-height: 300px;
  overflow: scroll;
`;

const ButtonContainer = styled.div`
  padding: 16px;
`;

const ApplyButton = styled(Button)`
  width: 100%;
  justify-content: center;
  padding: 12px 0;
`;
