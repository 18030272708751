/* eslint-disable react/jsx-key */
import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { BlockingNavModal } from "../../../../../../components/atoms/BlockingNavModal/BlockingNavModal";
import { AnchorMenu } from "../../../../../../components/molecules/AnchorMenu/AnchorMenu";
import { useAnchorMenu } from "../../../../../../hooks/use-anchor-menu";
import { useSelectedConfiguration } from "../../../../../../store/slices/caching/hooks";
import {
  MatchLogicDefinitionType,
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { MatchRulesSection } from "./MatchRulesSection";
import { PropertyDefinitionSection } from "./PropertyDefinition";
import { useProperty } from "./useProperty";
import { usePropertyHeader } from "./usePropertyHeader";
import { cloneDefinition } from "../../../../../../utils/object";

export interface PropertyProps {
  propertyName: string;
}

export const Property = ({ propertyName }: PropertyProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");

  const selectedConfiguration = useSelectedConfiguration();

  const [property, setProperty] = useState<PropertyDefinitionType>();
  const [
    currentMatchLogic,
    setCurrentMatchLogic,
  ] = useState<MatchLogicDefinitionType>();
  const [
    originDefinition,
    setOriginDefinition,
  ] = useState<OriginDefinitionType>();

  const { updateProperty, updateOrigin } = useProperty(
    propertyName,
    property,
    setProperty,
    setCurrentMatchLogic,
    setOriginDefinition
  );

  const { handleTransition, onSave } = usePropertyHeader(
    propertyName,
    property,
    currentMatchLogic,
    originDefinition,
    setProperty
  );

  const updateMatchLogic = (newDefinition?: MatchLogicDefinitionType) => {
    const clonedCurrentMatchLogic = cloneDefinition(newDefinition);
    setCurrentMatchLogic(clonedCurrentMatchLogic);
    onSave(true, {
      property,
      originDefinition,
      currentMatchLogic: clonedCurrentMatchLogic,
    });
  };

  const config = {
    itemNames: [
      {
        name: t("ANCHOR_MENU_1_LABEL"),
        id: "definition",
        subItems: [
          {
            name: t("PROPERTY_SECTION_ORIGIN_TITLE"),
            id: "origin",
          },
          {
            name: t("PROPERTY_CARD_ALIASES_TITLE"),
            id: "aliases",
          },
          {
            name: t("PROPERTY_CARD_ALIASES_OVERRIDE_SECTION_TITLE"),
            id: "overrides",
          },
          {
            name: t("PROPERTY_SECTION_TRAFFIC_TYPE_TITLE"),
            id: "settings",
          },
        ],
      },
      {
        name: t("ANCHOR_MENU_2_LABEL"),
        id: "match_logic",
        subItems: [
          {
            name: t("ANCHOR_MENU_2_SUB_1_LABEL"),
            id: "clientReq",
          },
          {
            name: t("ANCHOR_MENU_2_SUB_2_LABEL"),
            id: "originReq",
          },
          {
            name: t("ANCHOR_MENU_2_SUB_3_LABEL"),
            id: "originResp",
          },
        ],
      },
    ],
  };

  const menu = useAnchorMenu(config);

  return (
    <PropertyContainer>
      {propertyName && selectedConfiguration && property ? (
        <>
          <AnchorMenu
            menuItems={menu}
            hasPadding={false}
            scrollId="configuration_page"
            defaultActive={0}
          />
          <BlockingNavModal
            when={true}
            shouldBlockNavigation={handleTransition}
            onSave={async (...params) => onSave(...params)}
            dataTestId="property"
          />
          <Sections>
            <PropertyDefinitionSection
              property={property}
              menu={menu[0]}
              updateProperty={updateProperty}
              updateOrigin={updateOrigin}
              originDefinition={originDefinition}
            />
            <MatchRulesSection
              updateProperty={updateProperty}
              menu={menu[1]}
              currentProperty={property}
              currentMatchLogic={currentMatchLogic}
              updateMatchLogic={updateMatchLogic}
            />
          </Sections>
        </>
      ) : null}
    </PropertyContainer>
  );
};

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 2rem;
`;

const Sections = styled.div`
  width: calc(100% - 250px);
`;
