import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const HorizontalFieldName = styled.h6<{ required?: boolean }>`
  display: table-cell;
  font-weight: 500 !important;
  padding: 0px 15px 15px 0px;
  width: 30% !important;
  ${({ required, theme }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: ${theme.text.error};
      }
    `};
`;
