import { FC } from "react";
import styled from "@emotion/styled";

import { Button, ButtonProps } from "../../atoms/Button/Button";
import { FlexBoxBase } from "../../atoms/FlexBox/FlexBox";

interface TitleContentButtonsModalBodyProps {
  title: string;
  cancelButton?: ButtonProps;
  submitButton?: ButtonProps;
}

export const TitleContentButtonsModalBody: FC<TitleContentButtonsModalBodyProps> = ({
  title,
  cancelButton,
  submitButton,
  children,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
      <ButtonsContainer>
        {cancelButton && <CancelButton {...cancelButton} />}
        {submitButton && <SubmitButton {...submitButton} />}
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  width: 588px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

const ButtonsContainer = styled(FlexBoxBase)`
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  margin-right: 16px;
  padding: 12px 16px;
`;

const SubmitButton = styled(Button)`
  padding: 12px 16px;
`;
