import { ReactElement, useContext } from "react";
import styled from "@emotion/styled";
import { Theme, ThemeContext } from "../../../contexts/themeContext/index";

interface AdditionalInputInfoProps {
  children?: string;
}

export const AdditionalInputInfo = ({
  children,
}: AdditionalInputInfoProps): ReactElement => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      {children && (
        <AdditionalInputInfoContent isdarkmode={theme === Theme.DARK_MODE}>
          {children}
        </AdditionalInputInfoContent>
      )}
    </>
  );
};
const AdditionalInputInfoContent = styled.div<{ isdarkmode: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme, isdarkmode }) =>
    isdarkmode ? theme.text.secondary : theme.text.muted};
  margin-left: 16px;
`;
