import { Badge } from "../../../../../components/atoms/Badge/Badge";
import { Invalidation } from "../../../../../models/invalidation";

interface InvalidationStatusProps extends Pick<Invalidation, "progress"> {
  label: string;
  dataTestId?: string;
}

const getContainerColor = (progress: number) =>
  progress === 0 ? "grey60" : progress < 100 ? "cyan40" : "green60";

export const InvalidationStatus = ({
  label,
  progress,
  dataTestId,
}: InvalidationStatusProps): JSX.Element => {
  return (
    <Badge
      label={label}
      color={getContainerColor(progress)}
      dataTestId={dataTestId}
    />
  );
};
