import type { ReactElement } from "react";
import type {
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import { IpDefinitionModal } from "../../../../../../../../../components/molecules/IpDefinitionModal/IpDefinitionModal";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import { DefinitionDropdown } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  SimpleDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { uriRegex } from "../../../../../../../../../utils/regex";
import { ValidationErrors } from "../types";
import { FeatureBlock } from "./FeatureBlock";
import { getDefsByType, selectedNames, toName } from "./helpers";

export interface IpFeatureBlockProps {
  handleCreateDefinition: (d: SimpleDefinitionType) => void;
  onDelete: (fieldName?: string) => void;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  register: UseFormRegister<IFeatures>;
  setError: UseFormSetError<IFeatures>;
  clearErrors: UseFormClearErrors<IFeatures>;
  errors: ValidationErrors;
  initIsOpen: boolean;
  ipRestrictions: Exclude<IFeatures["ipRestrictions"], undefined>;
  config: ConfigurationDetailsType;
  maxWidth?: number;
  datatestId: string;
}

export const IpFeatureBlock = ({
  handleCreateDefinition,
  handleCreateFeature,
  onDelete,
  register,
  setError,
  clearErrors,
  errors,
  initIsOpen,
  config,
  ipRestrictions,
  maxWidth,
  datatestId,
}: IpFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();

  const actionType = ipRestrictions.actionType;
  const denialAction = ipRestrictions.denial?.action;
  const isActionDeny = actionType === "deny";
  const isRedirect = denialAction === "redirect";

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_IP_BLOCKING_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_IP_BLOCKING_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_IP_BLOCKING_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          <PropertyDefinitionCard
            title={t("FEATURE_CARD_IP_BLOCKING_DEFINITION_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_IP_BLOCKING_DEFINITION_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_IP_BLOCKING_DEFINITION_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            maxWidth={maxWidth}
            errorMessage={
              (errors.ipRestrictions?.nameList as FieldError | undefined)
                ?.message
            }
            fieldProps={{
              other: config.simpleListDefinitions ? (
                <DefinitionDropdown
                  id="ipRestrictions.nameList"
                  disabled={isViewMode}
                  type={FeatureType.IP}
                  items={getDefsByType("ip", config.simpleListDefinitions)}
                  selected={config.simpleListDefinitions.filter(
                    selectedNames(ipRestrictions.nameList)
                  )}
                  onSelect={(selected) =>
                    handleCreateFeature(
                      "ipRestrictions.nameList",
                      selected.map(toName)
                    )
                  }
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  dataTestId="ip-list"
                  parentConfig={config}
                />
              ) : (
                <IpDefinitionModal
                  parentConfig={config}
                  onSubmit={handleCreateDefinition}
                />
              ),
            }}
            divider
            dataTestId="ip-list-definitions"
          />

          <PropertyDefinitionCard
            title={t("FEATURE_CARD_IP_BLOCKING_ACTION_TYPE_TITLE")}
            additionalInfoTitle={t(
              "FEATURE_CARD_IP_BLOCKING_ACTION_TYPE_DESCRIPTION_TITLE"
            )}
            additionalInfoContent={t(
              "FEATURE_CARD_IP_BLOCKING_ACTION_TYPE_DESCRIPTION_CONTENT"
            )}
            fieldType={FieldType.OtherType}
            fieldProps={{
              other: (
                <RadioButtonsSelector
                  inline
                  headlessRadio={true}
                  radioButtonsProps={[
                    {
                      ...register("ipRestrictions.actionType"),
                      value: "allow",
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_IP_BLOCKING_ACTION_TYPE_RADIO_1_LABEL"
                      ),
                      id: "ip-action-allow",
                      dataTestId: "restriction-allow",
                      onChange: () => {
                        handleCreateFeature(
                          "ipRestrictions.actionType",
                          "allow"
                        );
                        handleCreateFeature("ipRestrictions.denial", undefined);
                      },
                    },
                    {
                      ...register("ipRestrictions.actionType"),
                      value: "deny",
                      disabled: isViewMode,
                      label: t(
                        "FEATURE_CARD_IP_BLOCKING_ACTION_TYPE_RADIO_2_LABEL"
                      ),
                      id: "ip-action-deny",
                      dataTestId: "restriction-deny",
                      onChange: () => {
                        handleCreateFeature(
                          "ipRestrictions.actionType",
                          "deny"
                        );
                        handleCreateFeature("ipRestrictions.denial", {
                          action: "error",
                        });
                      },
                    },
                  ]}
                />
              ),
            }}
            divider={isActionDeny}
            dataTestId="ip-list-action"
          />

          {isActionDeny && (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_IP_BLOCKING_ACTION_ON_DENIAL_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_IP_BLOCKING_ACTION_ON_DENIAL_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_IP_BLOCKING_ACTION_ON_DENIAL_DESCRIPTION_CONTENT"
              )}
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <RadioButtonsSelector
                    inline
                    headlessRadio={true}
                    radioButtonsProps={[
                      {
                        ...register("ipRestrictions.denial.action"),
                        value: "redirect",
                        disabled: isViewMode,
                        label: t(
                          "FEATURE_CARD_IP_BLOCKING_ACTION_ON_DENIAL_RADIO_1_LABEL"
                        ),
                        id: "ip-action-redirect",
                        dataTestId: "action-on-denial-redirect",
                      },
                      {
                        ...register("ipRestrictions.denial.action"),
                        value: "error",
                        disabled: isViewMode,
                        label: t(
                          "FEATURE_CARD_IP_BLOCKING_ACTION_ON_DENIAL_RADIO_2_LABEL"
                        ),
                        id: "ip-action-error",
                        dataTestId: "action-on-denial-error",
                        onChange: () => {
                          handleCreateFeature("ipRestrictions.denial", {
                            action: "error",
                          });
                        },
                      },
                    ]}
                  />
                ),
              }}
              divider={isRedirect}
              dataTestId="ip-list-action-on-denial"
            />
          )}

          {isRedirect && (
            <PropertyDefinitionCard
              title={t("FEATURE_CARD_IP_BLOCKING_REDIRECT_URL_TITLE")}
              additionalInfoTitle={t(
                "FEATURE_CARD_IP_BLOCKING_REDIRECT_URL_DESCRIPTION_TITLE"
              )}
              additionalInfoContent={t(
                "FEATURE_CARD_IP_BLOCKING_REDIRECT_URL_DESCRIPTION_CONTENT"
              )}
              fieldType={FieldType.OtherType}
              fieldProps={{
                other: (
                  <HeadlessInputField
                    disabled={isViewMode}
                    placeholder={t(
                      "FEATURE_CARD_IP_BLOCKING_REDIRECT_URL_PLACEHOLDER"
                    )}
                    {...register("ipRestrictions.denial.redirectUrl", {
                      required: tMisc("ERROR_REQUIRED_FIELD"),
                      pattern: {
                        value: new RegExp(uriRegex),
                        message: tMisc("ERROR_MUST_BE_URL"),
                      },
                    })}
                    data-testid="ip-list-redirect-url-input"
                  />
                ),
              }}
              errorMessage={
                (errors.ipRestrictions?.denial as
                  | {
                      action?: FieldError | undefined;
                      redirectUrl?: FieldError | undefined;
                    }
                  | undefined)?.redirectUrl?.message
              }
              maxWidth={maxWidth}
              dataTestId="ip-list-redirect-url"
            />
          )}
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"ipRestrictions"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="ip-list"
      featureType={FeatureTypes.ACCESS_CONTROL}
    />
  );
};
