import { FC, useEffect, useReducer } from "react";

import { httpClient } from "../../../../../core/http-client";
import {
  FixedWidgetIds,
  MapSiteFieldsToLabel,
  TableSiteWithPercentage,
} from "../../../models/models";
import { WidgetLayout } from "../../../../../components/molecules/WidgetLayout/WidgetLayout";
import { PropertiesDataTable } from "./DataTable";
import { historyToChartData } from "./helpers";
import {
  TableProperty,
  PropertyHistory,
  TableMetrics,
  PropertySplit,
} from "./types";
import {
  DEFAULT_CHART_COLORS,
  LineChart,
} from "../../../../../components/atoms/LineChart/LineChart";
import { MetricSelectionDropdown } from "../../../../../components/organisms/MetricSelectionDropdown/MetricSelectionDropdown";
import {
  FilterFields,
  PropertiesFilter,
  useFiltersContext,
} from "../../../context/filters";
import { initialState, reducer } from "./reducer";
import { useDispatchAPIError } from "../../../../../store/slices/api-error/hooks";
import { useCurrentByProperty } from "../../../../../store/slices/rtm/hooks";

const filterProperties = (propertyFilter: PropertiesFilter) => (
  prop: TableProperty | PropertyHistory
): boolean => {
  if (propertyFilter.length === 0) {
    return true;
  }
  return propertyFilter.some((pf) => prop.propertyName === pf);
};

export const ChartPropertiesTableContent: FC = () => {
  const { preferences, updateWidgetPreference } = useFiltersContext();
  const propertyFilter =
    preferences.get(FixedWidgetIds.GLOBAL_REPORT)?.property || [];

  const currentByProperty = useCurrentByProperty();

  const [
    {
      properties,
      propertyHistory,
      sortField,
      sortOrder,
      selectedMetrics,
      selectedRows,
      registeredProperties,
    },
    dispatchTableState,
  ] = useReducer(reducer, {
    ...initialState,
    selectedMetrics:
      preferences.get(FixedWidgetIds.PROPERTIES_SPLIT)?.splitTableMetrics ||
      initialState.selectedMetrics,
    sortOrder:
      preferences.get(FixedWidgetIds.PROPERTIES_SPLIT)?.tableState?.sortOrder ||
      initialState.sortOrder,
    sortField:
      (preferences.get(FixedWidgetIds.PROPERTIES_SPLIT)?.tableState
        ?.sortField as keyof TableSiteWithPercentage) || initialState.sortField,
    selectedRows:
      preferences.get(FixedWidgetIds.PROPERTIES_SPLIT)?.selectedProperties ||
      initialState.selectedRows,
  });

  useEffect(() => {
    if (currentByProperty) {
      dispatchTableState({
        type: "UPDATE_PROPERTY_HISTORY",
        payload: { currentByProperty },
      });
    }
  }, [currentByProperty]);

  const handleMediaPortalError = useDispatchAPIError();

  useEffect(() => {
    Promise.all(
      registeredProperties.map((id) =>
        httpClient.get<PropertySplit>(
          `/realTimeMonitoring/v1/properties/${id}/current`
        )
      )
    )
      .then((res) => {
        if (currentByProperty) {
          dispatchTableState({
            type: "UPDATE_PROPERTIES_DATA",
            payload: {
              propertySplits: res.map((r, i) => ({
                propertyId: registeredProperties[i],
                data: r.data,
              })),
              currentByProperty,
            },
          });
        }
      })
      .catch((err) => {
        handleMediaPortalError(err);
        if (currentByProperty) {
          dispatchTableState({
            type: "UPDATE_PROPERTIES_DATA",
            payload: { currentByProperty },
          });
        }
      });
    // ignore registeredProperties and handleMediaPortalError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentByProperty]);

  useEffect(() => {
    if (selectedRows) {
      selectedRows
        .filter(
          (id) =>
            propertyHistory.find((item) => item.propertyId === id) === undefined
        )
        .forEach((id) => {
          httpClient
            .get<PropertyHistory>(
              `/realTimeMonitoring/v1/properties/${id}/history`
            )
            .then((res) => {
              dispatchTableState({
                type: "UPDATE_PROPERTIES_HISTORY",
                payload: {
                  history: res.data,
                },
              });
            })
            .catch(handleMediaPortalError);
        });
    }
  }, [selectedRows]);

  return (
    <>
      <WidgetLayout title={MapSiteFieldsToLabel.get(sortField)}>
        <LineChart
          data={propertyHistory
            .filter((pH) => selectedRows?.includes(pH.propertyId))
            .filter(filterProperties(propertyFilter))
            .map(historyToChartData(sortField))}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              itemWidth: 160,
              itemHeight: 20,
              translateY: 50,
              symbolShape: ({ x, y, fill }) => (
                <rect x={x + 4} y={y + 8} width={10} height={2} fill={fill} />
              ),
              itemTextColor: "#ACB0B5",
              data: propertyHistory
                .filter((pH) => selectedRows?.includes(pH.propertyId))
                .filter(filterProperties(propertyFilter))
                .map((prop, i) => ({
                  id: prop.propertyId,
                  label:
                    prop.propertyName.length > 30
                      ? prop.propertyName.slice(0, 27) + "..."
                      : prop.propertyName,
                  color: DEFAULT_CHART_COLORS[i],
                })),
            },
          ]}
        />
      </WidgetLayout>
      <WidgetLayout
        title="Split per property"
        cta={
          <MetricSelectionDropdown
            selectedItems={selectedMetrics.map((metric) => ({
              label: MapSiteFieldsToLabel.get(metric) as string,
              value: metric,
            }))}
            onChange={(values) => {
              if (values.length === 0) {
                return;
              }
              const test = values.findIndex((v) => v.value === sortField);
              if (values.length > 0 && test === -1) {
                dispatchTableState({
                  type: "UPDATE_TABLE_STATE",
                  payload: {
                    sortField: values[0].value as keyof TableMetrics,
                    sortOrder: "desc",
                  },
                });
              }
              const newSelectedMetrics = values.map(
                (v) => v.value as keyof TableMetrics
              );
              dispatchTableState({
                type: "UPDATE_SELECTED_METRICS",
                payload: {
                  selectedMetrics: newSelectedMetrics,
                },
              });
              updateWidgetPreference(FixedWidgetIds.PROPERTIES_SPLIT, {
                key: FilterFields.SPLIT_TABLE_METRICS,
                value: newSelectedMetrics,
              });
            }}
          />
        }
      >
        <PropertiesDataTable
          properties={properties.filter(filterProperties(propertyFilter))}
          selectedMetrics={selectedMetrics}
          selectedRows={selectedRows || []}
          tableState={{ sortField, sortOrder }}
          handleUpdate={dispatchTableState}
        />
      </WidgetLayout>
    </>
  );
};
