import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/alert.svg";
import { Slider } from "../../../../components/atoms/Slider/Slider";
import {
  Button,
  ButtonProps,
} from "../../../../components/atoms/Button/Button";
import { DeploymentCard } from "../../../../components/molecules/DeploymentCard/DeploymentCard";
import { Tooltip } from "../../../../components/molecules/Tooltip/Tooltip";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { useDeploymentConfigs } from "../../../../hooks/use-deployment-configs";
import {
  Configuration,
  ProductionSlot,
} from "../../../../models/configuration";
import { IDeployData } from "../../../../models/configuration/production";
import { CMv3APIError, APIError } from "../../../../models/error";
import { EditorModal } from "./EditorModal";
import { PreComparisonModal } from "./PreComparisonModal";
import { PreComparisonModalFromVersions } from "./PreComparisonModalFromVersions";
import { IDeploymentModalData } from "./types";
import {
  IModalChildren,
  Modal,
} from "../../../../components/atoms/Modal/Modal";
import { useSubscriberId } from "../../../../store/slices/subscriber/hooks";
import { ConfigurationType } from "../../../../store/slices/caching/types";
import {
  useDispatchProduction,
  useIsProductionDeployed,
} from "../../../../store/slices/caching/hooks";

interface ButtonDeploymentModal {
  configuration: IDeploymentModalData | undefined;
  openButtonProps: Omit<ButtonProps, "onClick">;
  open?: boolean;
  onClose?: () => void;
  onError: (err: APIError<CMv3APIError>) => void;
}

interface ControlledDeploymentModal {
  configuration: IDeploymentModalData | undefined;
  openButtonProps?: Omit<ButtonProps, "onClick">;
  open: boolean;
  onClose: () => void;
  onError: (err: APIError<CMv3APIError>) => void;
}

type DeploymentModal = ButtonDeploymentModal | ControlledDeploymentModal;

interface IDeploymentModalChild extends IModalChildren {
  configuration: IDeploymentModalData;
  current: string;
  candidate: string;
  initialCurrent: string;
  initialCandidate: string;
  initialCurrentPercentage: number | undefined;
  setCurrent: (value: string) => void;
  setCandidate: (value: string) => void;
  onError: (err: APIError<CMv3APIError>) => void;
  onCompareBtnClick: () => void;
}

const useIsConfigurationDeployed = (
  configuration: DeploymentModal["configuration"]
) => {
  const deploymentData = useIsProductionDeployed(
    configuration?.configName ?? ""
  );

  return (
    deploymentData.candidate === configuration?.versionId ||
    deploymentData.current === configuration?.versionId
  );
};

export const DeploymentModal = ({
  configuration,
  openButtonProps,
  open,
  onClose,
  onError,
}: DeploymentModal): ReactElement => {
  const isConfigurationDeployed = useIsConfigurationDeployed(configuration);
  const { t } = useTranslation("configurationsPage");

  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [configToCompare, setConfigToCompare] = useState<ConfigurationType>();

  const [
    current,
    candidate,
    initialCurrent,
    initialCandidate,
    initialCurrentPercentage,
    setCurrent,
    setCandidate,
  ] = useDeploymentConfigs();

  const openCompareModal = () => {
    setCompareModalOpen(true);
  };
  const shouldRenderEditorModal = !!configToCompare;
  const shouldRenderComparisonModal =
    compareModalOpen && !!(configuration as ConfigurationType).versionId;

  const selectPopupText = (deployed: boolean, draft: boolean): string => {
    if (draft) {
      return t("CONFIGURATION_IS_LOCAL_DRAFT");
    } else if (deployed) {
      t("CONFIGURATION_ALREADY_DEPLOYED_POPUP");
    }
    return t("CONFIGURATION_DEPLOY_POPUP");
  };

  return (
    <>
      {configuration && openButtonProps ? (
        <Tooltip
          active={
            (candidate !== "" && initialCurrentPercentage !== 100) ||
            isConfigurationDeployed ||
            (configuration as ConfigurationType).isLocalDraft
          }
          popupText={selectPopupText(
            isConfigurationDeployed,
            (configuration as ConfigurationType).isLocalDraft
          )}
          id={`deployment-modal-go-live-button-popup-${configuration.transactionId}`}
          dataTestId={`deployment-modal-go-live-button-popup-${configuration.configName}`}
        >
          <Modal
            openButtonProps={{
              ...openButtonProps,
              disabled:
                (candidate !== "" && initialCurrentPercentage !== 100) ||
                isConfigurationDeployed ||
                (configuration as ConfigurationType).isLocalDraft,
            }}
          >
            {shouldRenderEditorModal ? (
              <EditorModal
                configBase={configuration}
                configToCompare={configToCompare!}
                onCancelClick={() => {
                  setCompareModalOpen(false);
                  setConfigToCompare(undefined);
                }}
              />
            ) : shouldRenderComparisonModal ? (
              <PreComparisonModal
                configuration={configuration as ConfigurationType}
                onCancelClick={() => {
                  setCompareModalOpen(false);
                }}
                setConfigToCompare={setConfigToCompare}
              />
            ) : (
              <DeploymentModalChild
                configuration={configuration}
                current={current}
                candidate={candidate}
                initialCurrent={initialCurrent}
                initialCandidate={initialCandidate}
                initialCurrentPercentage={initialCurrentPercentage}
                setCurrent={setCurrent}
                setCandidate={setCandidate}
                onError={onError}
                onCompareBtnClick={openCompareModal}
              />
            )}
          </Modal>
        </Tooltip>
      ) : (
        configuration &&
        open !== undefined &&
        onClose && (
          <Modal customButton={<></>} open={open} onClose={onClose}>
            {shouldRenderEditorModal ? (
              <EditorModal
                configBase={configuration}
                configToCompare={configToCompare!}
                onCancelClick={() => {
                  setCompareModalOpen(false);
                  setConfigToCompare(undefined);
                }}
              />
            ) : shouldRenderComparisonModal ? (
              <PreComparisonModalFromVersions
                configuration={configuration}
                onCancelClick={() => {
                  setCompareModalOpen(false);
                }}
                setConfigToCompare={setConfigToCompare}
              />
            ) : (
              <DeploymentModalChild
                configuration={configuration}
                current={current}
                candidate={candidate}
                initialCurrent={initialCurrent}
                initialCandidate={initialCandidate}
                initialCurrentPercentage={initialCurrentPercentage}
                setCurrent={setCurrent}
                setCandidate={setCandidate}
                onError={onError}
                onCompareBtnClick={openCompareModal}
              />
            )}
          </Modal>
        )
      )}
    </>
  );
};

export const DeploymentModalChild: FC<IDeploymentModalChild> = ({
  closeModal,
  configuration,
  current,
  candidate,
  initialCurrent,
  initialCandidate,
  initialCurrentPercentage,
  setCurrent,
  setCandidate,
  onError,
  onCompareBtnClick,
}) => {
  const [currentPercentage, setCurrentPercentage] = useState<number>();
  const [selectedSlot, setSelectedSlot] = useState<ProductionSlot>();
  const [notes, setNotes] = useState<string>("");

  const dispatchProduction = useDispatchProduction();
  const subscriberId = useSubscriberId();
  const { t } = useTranslation("configurationsPage");
  const [tmisc] = useTranslation("misc");
  const history = useHistory();

  useEffect(() => {
    if (currentPercentage === undefined) {
      setCurrentPercentage(initialCurrentPercentage);
    }
  }, [initialCurrentPercentage, currentPercentage]);

  const deploy = useCallback(() => {
    if (selectedSlot && subscriberId) {
      const percentageData: IDeployData = {
        sourceConfig: `configs/stored/${configuration.configName}/history/${configuration.versionId}`,
        comment: notes,
      };
      if (
        selectedSlot === ProductionSlot.CANDIDATE &&
        currentPercentage !== 100
      ) {
        percentageData.forcePercentActive =
          currentPercentage !== undefined ? 100 - currentPercentage : 0;
      }
      Configuration.production
        .deployConfiguration(
          subscriberId.toString(),
          selectedSlot,
          percentageData
        )
        .then((activeConfigs) => {
          dispatchProduction();
          Configuration.checkDeploymentStatus(
            subscriberId!.toString(),
            activeConfigs.slots,
            selectedSlot
          ).then((transaction) => {
            if (transaction && transaction.state === "errored") {
              toast.error(
                <div>
                  <h5>{t("CONFIGURATION_DEPLOYMENT_FAILED")}</h5>
                  <p>Type: {transaction.operationData.type}</p>
                  <ul>
                    {(transaction.messages || []).map((message) => {
                      return <li key={message}>{message}</li>;
                    })}
                  </ul>
                  <Button
                    label={t("CONFIGURATION_DEPLOYMENT_FAILED_CTA")}
                    onClick={() => {
                      history.push(
                        "/cmv3/environment_history#deployment_history"
                      );
                    }}
                  />
                </div>,
                {
                  autoClose: false,
                }
              );
            }
          });

          toast.success(
            t("CONFIGURATION_MODAL_SUBMIT_SUCCESS", {
              slot: selectedSlot,
            })
          );
        })
        .catch((err) => {
          const error = err as APIError<CMv3APIError>;
          onError(error);
        });
    }
  }, [configuration, currentPercentage, notes, selectedSlot, subscriberId]);

  const hasActiveCandidate = () => {
    // if initialCurrentPercentage === undefined, there is no data deployed
    // if initialCurrentPercentage !== 100, it means there is a candidate slot, and it has more than 0% traffic
    return (
      initialCurrentPercentage !== undefined && initialCurrentPercentage !== 100
    );
  };

  return (
    <Container>
      <Header className="-text--h2">{t("CONFIGURATION_MODAL_TITLE")}</Header>
      <ExpandableContent
        title={t("CONFIGURATION_DEPLOY_MODAL_DESCRIPTION_TITLE")}
        content={t("CONFIGURATION_DEPLOY_MODAL_DESCRIPTION_CONTENT")}
      />
      <ConfigurationContainer>
        <SlotContainer data-testid="deployment-modal-slot-current">
          <ConfigurationHeader className="-text--h4">
            {t("CONFIGURATION_MODAL_SLOT_1_TITLE")}
          </ConfigurationHeader>
          <Tooltip
            active={initialCandidate !== ""}
            popupText={t("CONFIGURATION_MODAL_SLOT_1_POPUP_TEXT")}
            id={`${configuration.transactionId}-slot-1`}
          >
            <DeploymentCard
              selected={selectedSlot === ProductionSlot.CURRENT}
              onClick={() => {
                setSelectedSlot(ProductionSlot.CURRENT);
                setCurrent(
                  `${configuration.configName} ${tmisc("VERSION", {
                    version: configuration.versionId,
                  })}`
                );
              }}
              value={currentPercentage !== undefined ? currentPercentage : 100}
              configurationName={current}
              isNewConfig={
                current ===
                `${configuration.configName} ${tmisc("VERSION", {
                  version: configuration.versionId,
                })}`
              }
              disabled={initialCandidate !== ""}
            />
          </Tooltip>
        </SlotContainer>
        <SlotContainer data-testid="deployment-modal-slot-candidate">
          <ConfigurationHeader className="-text--h4">
            {t("CONFIGURATION_MODAL_SLOT_2_TITLE")}
          </ConfigurationHeader>
          <Tooltip
            active={initialCurrent === ""}
            popupText={t("CONFIGURATION_MODAL_SLOT_2_POPUP_TEXT")}
            id={`${configuration.transactionId}-deployment-modal-candidate-popup`}
            dataTestId="deployment-modal-candidate-popup"
          >
            <DeploymentCardContainer>
              <DeploymentCard
                selected={selectedSlot === ProductionSlot.CANDIDATE}
                onClick={() => {
                  setSelectedSlot(ProductionSlot.CANDIDATE);
                  setCandidate(
                    `${configuration.configName} ${tmisc("VERSION", {
                      version: configuration.versionId,
                    })}`
                  );
                }}
                value={
                  currentPercentage !== undefined ? 100 - currentPercentage : 0
                }
                configurationName={candidate}
                isNewConfig={
                  candidate ===
                  `${configuration.configName} ${tmisc("VERSION", {
                    version: configuration.versionId,
                  })}`
                }
                disabled={initialCurrent === ""}
              />
            </DeploymentCardContainer>
          </Tooltip>
        </SlotContainer>
      </ConfigurationContainer>
      {selectedSlot && (
        <SliderContainer>
          <Slider
            initialValue={
              selectedSlot === ProductionSlot.CURRENT
                ? currentPercentage || 100
                : currentPercentage
                ? 100 - currentPercentage
                : 0
            }
            onChange={
              selectedSlot === ProductionSlot.CURRENT
                ? setCurrentPercentage
                : (value: number) => setCurrentPercentage(100 - value)
            }
          />
        </SliderContainer>
      )}
      <NotesContainer>
        <NotesInfo
          dangerouslySetInnerHTML={{
            __html: t("CONFIGURATION_MODAL_NOTES_LABEL"),
          }}
        />
        <NotesInput
          placeholder={t("CONFIGURATION_MODAL_NOTES_PLACEHOLDER")}
          onChange={(e) => setNotes(e.target.value)}
        />
      </NotesContainer>
      <DeploymentAlertContainer>
        <StyledInfoIcon />
        {t("CONFIGURATION_MODAL_WARNING_TEXT")}
      </DeploymentAlertContainer>
      <ModalFooter>
        <Button
          onClick={onCompareBtnClick}
          label={t("CONFIGURATION_COMPARE_WITH")}
        />
        <ButtonsContainer>
          <CancelButtonContainer>
            {closeModal && (
              <Button
                onClick={() => {
                  closeModal();
                  setCurrent(initialCurrent);
                  setCandidate(initialCandidate);
                }}
                label={t("CONFIGURATION_MODAL_CANCEL_BUTTON")}
                backgroundColor="mutedDark"
                textColor="primaryAlt"
              />
            )}
          </CancelButtonContainer>
          <SubmitButtonContainer data-testid="deployment-modal-submit">
            {closeModal && (
              <Tooltip
                popupText={t("CONFIGURATION_MODAL_SUBMIT_BUTTON_POPUP_TEXT")}
                active={hasActiveCandidate()}
                id={`${configuration.transactionId}-deployment-modal-submit-button`}
              >
                <Button
                  onClick={() => {
                    closeModal();
                    if (selectedSlot) {
                      deploy();
                    }
                  }}
                  label={t("CONFIGURATION_MODAL_SUBMIT_BUTTON")}
                  disabled={selectedSlot === undefined || hasActiveCandidate()}
                />
              </Tooltip>
            )}
          </SubmitButtonContainer>
        </ButtonsContainer>
      </ModalFooter>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border-radius: 7px;
  width: 800px;
  padding: 32px;
  cursor: default;
`;

const Header = styled.h3``;

const ConfigurationContainer = styled.div`
  display: flex;
`;

const SlotContainer = styled.div`
  width: 50%;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
`;

const ConfigurationHeader = styled.h4``;

const DeploymentCardContainer = styled.div`
  display: flex;
  height: 100%;
`;

const SliderContainer = styled.div`
  padding-top: 50px;
`;

const NotesContainer = styled.div`
  display: flex;
  height: 80px;
  padding-top: 30px;
`;

const NotesInfo = styled.div`
  width: 60%;
`;

const NotesInput = styled.textarea`
  width: 100%;
  border-radius: 4px;
  resize: none;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.backgrounds.mutedLight : theme.backgrounds.baseLight};
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary};
`;

const DeploymentAlertContainer = styled.div`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.borders.mutedDark};
  border-left: solid 4px ${({ theme }) => theme.borders.warning};
  display: flex;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0 5px 10px -10px;
  margin: 32px 0;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-right: 16px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButtonContainer = styled.div`
  padding-right: 15px;
`;

const SubmitButtonContainer = styled.div``;
