import React, { useState } from "react";
import styled from "@emotion/styled";
import { isObject } from "lodash";

import { Icon, Icons } from "../../atoms/Icon/Icon";
import { Asterisk } from "../../atoms/Asterisk/Asterisk";
import { ExpandableContent } from "../ExpandableContent/ExpandableContent";

export interface AccordionProps {
  title: string;
  icon?: Icons;
  description?:
    | string
    | { descriptionTitle: string; descriptionContent: string };
  dataTestId?: string;
  mandatory?: boolean;
  onDelete?: () => void;
  className?: string;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  icon = Icons.SERVER,
  description,
  children,
  dataTestId,
  mandatory = false,
  className,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(mandatory);

  return (
    <Container className={className}>
      <Header mandatory={mandatory ? 1 : 0} className={"header"}>
        <LeftPart>
          <TitleIconContainer>
            <Icon name={icon} />
          </TitleIconContainer>
          <LeftSectionContainer>
            <CardTitle>
              {title}
              {mandatory && <Asterisk />}
            </CardTitle>
            {description &&
              (isObject(description) ? (
                <ExpandableContent
                  title={description.descriptionTitle}
                  content={description.descriptionContent}
                />
              ) : (
                <CardDescription>{description}</CardDescription>
              ))}
          </LeftSectionContainer>
        </LeftPart>
        <div>
          {onDelete && (
            <TrashIcon
              name={Icons.TRASH}
              onClick={onDelete}
              dataTestId={`${dataTestId}-delete-button`}
            />
          )}
          <ArrowIcon
            dataTestId={`${dataTestId}-open-arrow`}
            name={Icons.CHEVRON_DOWN}
            onClick={() => {
              setIsOpen((value) => !value);
            }}
            isopen={isOpen ? 1 : 0}
          />
        </div>
      </Header>
      {isOpen && <Body className={"body"}>{children}</Body>}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled.div<{ mandatory: number }>`
  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme, mandatory }) =>
    mandatory ? theme.backgrounds.base : theme.backgrounds.baseLight};
  align-items: center;
  overflow: hidden;
`;

const Body = styled.div`
  padding: 24px;
`;

const TrashIcon = styled(Icon)`
  margin-right: 16px;
`;

const ArrowIcon = styled(Icon)<{ isopen: number }>`
  transition: transform 0.2s;
  transform: rotate(${({ isopen }) => (isopen ? "180deg" : "0deg")});
`;

const LeftPart = styled.div`
  display: flex;
`;

const LeftSectionContainer = styled.div``;

const TitleIconContainer = styled.div`
  line-height: 2rem;
  margin-right: 8px;
`;

const CardDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.text.primary};
`;

const CardTitle = styled.h4`
  display: flex;
  margin: 0 !important;
`;
