import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IMatchRule } from "../../../../../../models/configuration/definitions";
import { MatchBlocks } from "../../../../../../models/configuration/definitions/matchlogic";
import { getQueryParams } from "../../../../../../utils/getQueryParams";
import {
  ErrorNames,
  LocalizedExpressionError,
} from "./conditions-ui/LocalizedMediaPortalError";
import { splitExpression } from "./conditions-ui/splitExpression";

export const useInitialPreview = (
  matchBlock: MatchBlocks,
  matchRule: IMatchRule
): true | false => {
  const location = useLocation();
  const history = useHistory();
  const firstRenderRef = useRef(true);

  if (firstRenderRef.current && matchRule) {
    firstRenderRef.current = false;

    const expression = /\s(?:and|or)\s/g;
    const splittedExpressions = matchRule.expression
      ? matchRule.expression.split(expression).map((chunk) => chunk.trim())
      : [];
    const parsingErrors = splittedExpressions.reduce<
      LocalizedExpressionError[]
    >((prev, curr) => {
      const { errors } = splitExpression(curr, matchBlock);
      return [
        ...prev,
        ...errors.filter(
          (err) => err.name === ErrorNames.EXPRESSION_PARSING_ERROR
        ),
      ];
    }, []);

    // Show code editor if the preview is not possible
    if (parsingErrors.length && location.search.includes("&editionMode=UI")) {
      const queryParams = getQueryParams(location);
      queryParams.set("editionMode", "codeEditor");
      history.push(`${location.pathname}?${queryParams.toString()}`);
      return true;
    }

    return false;
  }

  return false;
};
