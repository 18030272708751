import { ReactElement, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AddButton } from "../../../../../../../../components/atoms/AddButton/AddButton";
import { Protected } from "../../../../../../../../components/atoms/Protected/Protected";
import { Accordion } from "../../../../../../../../components/molecules/Accordion/Accordion";
import { getDuplicateElementsWithIndexes } from "../../../../../../../../components/molecules/DefinitionModal/helpers";
import { ExpandableContent } from "../../../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { PropertyDefinitionBlock } from "../../../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { Protocols } from "../../../../../../../../models/configuration/definitions/origin";
import {
  PropertySection,
  PropertySubsection,
  ServiceTypes,
} from "../../../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../../../../../../models/configuration/errors";
import { UserRoles } from "../../../../../../../../models/permissions";
import {
  useConfigurationsErrors,
  useProperties,
  useSelectedConfiguration,
} from "../../../../../../../../store/slices/caching/hooks";
import {
  handleAddConfigurationError,
  handleRemoveConfigurationError,
  handleUpdateConfigurationErrors,
} from "../../../../../../../../store/slices/caching/thunks";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import { useAppDispatch } from "../../../../../../../../store/types";
import {
  autoFillAlias,
  getAliasAutoFill,
  getAliasWithoutAutoFill,
  getInvalidBasicHttp2EncryptConfigTranslate,
  getUpdatedProperty,
  getUpdatedPropertyWithPrimaryAlias,
  isInvalidBasicHttp2EncryptConfig,
} from "./helpers";
import { HttpProtocolsCard } from "./HttpProtocolsCard";
import { HttpsConfigCard } from "./HttpsConfigCard";

interface PrimaryProtocolSettingsProps {
  property: PropertyDefinitionType;
  onPropertyChange: (property: PropertyDefinitionType) => void;
}

export const PrimaryProtocolSettings = ({
  property,
  onPropertyChange,
}: PrimaryProtocolSettingsProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const errors = useConfigurationsErrors();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation("configurationPropertyPage");
  const properties = useProperties();

  const isHttps = property.protocolSettings.protocols.includes(Protocols.HTTPS);

  const propertyNameIndex = useMemo(
    () => properties.findIndex((p) => p.name === property.name),
    [property.primaryAlias, property.name, properties]
  );

  const [primaryAliasError, setPrimaryAliasError] = useState<string>();
  const [secondaryAliasesErrors, setSecondaryAliasesErrors] = useState<
    { index: number; msg: string }[]
  >([]);
  const [encryptionError, setEncryptionError] = useState<string>();

  const setSecondaryAliases = (aliases: string[]) => {
    onPropertyChange({ ...property, aliases });
  };

  useEffect(() => {
    const errorsToAdd: IConfigurationError[] = [];
    const errorsToFilter: ((config: IConfigurationError) => boolean)[] = [];
    const propIndex = properties.findIndex(
      (prop) => prop.name === property.name
    );

    if (
      propIndex !== -1 &&
      properties[propIndex].primaryAlias !== property.primaryAlias &&
      !isViewMode
    ) {
      onPropertyChange({
        ...property,
        primaryAlias: properties[propIndex].primaryAlias,
      });
      if (selectedConfiguration?.config) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type: ConfigurationErrorType.PRIMARY_ALIAS_CHANGED,
          data: {},
        });
      }
    }

    // Secondary aliases validations
    const secondaryAliases = property.aliases ?? [];

    if (property.primaryAlias && secondaryAliases) {
      const _secondaryAliasesErrors: typeof secondaryAliasesErrors = [];

      secondaryAliases.forEach((sA, index) => {
        if (sA === property.primaryAlias) {
          _secondaryAliasesErrors.push({
            index,
            msg: "Primary alias and secondary alias are must be unique.",
          });
        } else {
          const duplicates = getDuplicateElementsWithIndexes(secondaryAliases);

          if (duplicates.length > 0) {
            duplicates.forEach((duplicate) => {
              _secondaryAliasesErrors.push({
                index: duplicate.index,
                msg: t("ERROR_DUPLICATE_ALIAS"),
              });
            });
          }
        }
      });

      if (_secondaryAliasesErrors.length > 0) {
        errorsToAdd.push({
          level: ConfigurationErrorLevel.ERROR,
          type:
            ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_AND_SECONDARY_ALIAS_ARE_NOT_UNIQUE,
          data: {
            section: PropertySection.ALIASES,
            subsection: PropertySubsection.SECONDARY_ALIASES,
          },
        });
      } else {
        errorsToFilter.push(
          (err) =>
            !(
              err.level === ConfigurationErrorLevel.ERROR &&
              err.type ===
                ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_AND_SECONDARY_ALIAS_ARE_NOT_UNIQUE &&
              err.data?.section === PropertySection.ALIASES &&
              err.data?.subsection === PropertySubsection.SECONDARY_ALIASES
            )
        );
      }

      setSecondaryAliasesErrors(_secondaryAliasesErrors);
    }

    dispatch(handleUpdateConfigurationErrors(errorsToAdd, errorsToFilter));
  }, [properties, selectedConfiguration?.config, property]);

  const isViewMode = useIsViewMode();

  const registeredProperty = propertyNameIndex !== -1;

  return (
    <>
      <PropertyDefinitionBlock
        initIsOpen
        onArrowClick={setIsOpen}
        title={property.primaryAlias || "No primary alias yet"}
        badgeLabel={t("PROPERTY_CARD_ALIASES_PRIMARY_BADGE_LABEL")}
        badgeColor="green50"
        dataTestId="alias"
        propertyCards={
          <>
            <ExpandableContent
              title={t("ALIASES_DESCRIPTION_TITLE")}
              content={t("ALIASES_DESCRIPTION_CONTENT")}
            />
            {isOpen ? (
              <>
                <br />
                <PropertyDefinitionCard
                  required
                  title={t("PROPERTY_CARD_ALIASES_PRIMARY_ALIAS_TITLE")}
                  additionalInfoTitle={t(
                    "PROPERTY_CARD_ALIASES_PRIMARY_ALIAS_DESCRIPTION_TITLE"
                  )}
                  additionalInfoContent={t(
                    "PROPERTY_CARD_ALIASES_PRIMARY_ALIAS_DESCRIPTION_CONTENT"
                  )}
                  additionalInputInfo={getAliasAutoFill(property)}
                  fieldType={FieldType.InputFieldType}
                  fieldProps={{
                    value: getAliasWithoutAutoFill(property.primaryAlias),
                    placeholder: t(
                      "PROPERTY_CARD_ALIASES_PRIMARY_ALIAS_PLACEHOLDER"
                    ),
                    onChange: (newAlias) => {
                      let errorMessage: string | undefined = undefined;
                      const errorsToAdd: IConfigurationError[] = [];
                      const errorsToFilter: ((
                        config: IConfigurationError
                      ) => boolean)[] = [];

                      if (newAlias.length === 0) {
                        errorsToAdd.push({
                          level: ConfigurationErrorLevel.ERROR,
                          type:
                            ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_EMPTY,
                          data: {
                            section: PropertySection.ALIASES,
                            subsection: PropertySubsection.PRIMARY_ALIAS,
                          },
                        });
                        errorMessage = t("ERROR_PRIMARY_ALIAS_EMPTY");
                      } else {
                        errorsToFilter.push(
                          (err) =>
                            !(
                              err.type ===
                                ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_EMPTY &&
                              err.data?.section === PropertySection.ALIASES &&
                              err.data?.subsection ===
                                PropertySubsection.PRIMARY_ALIAS
                            )
                        );
                      }

                      const primaryAliasIndex = properties.findIndex(
                        (p) => p.primaryAlias === newAlias
                      );
                      if (primaryAliasIndex !== -1) {
                        errorsToAdd.push({
                          level: ConfigurationErrorLevel.ERROR,
                          type: ConfigurationErrorType.ALREADY_IN_REGISTRY,
                          data: {
                            section: PropertySection.ALIASES,
                            subsection: PropertySubsection.PRIMARY_ALIAS,
                          },
                        });
                        errorMessage = t(
                          "ERROR_PRIMARY_ALIAS_ALREADY_REGISTERED",
                          {
                            propertyName: properties[primaryAliasIndex].name,
                          }
                        );
                      } else {
                        errorsToFilter.push(
                          (err) =>
                            err.type !==
                            ConfigurationErrorType.ALREADY_IN_REGISTRY
                        );
                      }

                      dispatch(
                        handleUpdateConfigurationErrors(
                          errorsToAdd,
                          errorsToFilter
                        )
                      );
                      setPrimaryAliasError(errorMessage);
                      onPropertyChange({
                        ...property,
                        primaryAlias: autoFillAlias(property, newAlias),
                      });
                    },
                    info: errors.find(
                      (err) =>
                        err.type ===
                        ConfigurationErrorType.PRIMARY_ALIAS_CHANGED
                    )
                      ? {
                          message: t("WARNING_PRIMARY_ALIAS_CHANGED"),
                          theme: "warning",
                        }
                      : undefined,
                    disabled: isViewMode || registeredProperty,
                    hoverMessage:
                      isViewMode || registeredProperty
                        ? t("HOVER_MESSAGE")
                        : undefined,
                    dataTestId: "alias-primary-alias-input",
                  }}
                  errorMessage={
                    primaryAliasError ||
                    (errors.find(
                      (e) =>
                        e.type ===
                        ConfigurationErrorType.PROPERTY_PRIMARY_ALIAS_EMPTY
                    ) &&
                      t("ERROR_PRIMARY_ALIAS_EMPTY"))
                  }
                  dataTestId="alias-primary-alias-input"
                />
                <br />
                <SectionWrapper>
                  <Accordion
                    dataTestId="secondary-aliases"
                    title={t("PROPERTY_CARD_ALIASES_SECONDARY_ALIAS_TITLE")}
                    description={t(
                      "PROPERTY_CARD_ALIASES_SECONDARY_ALIAS_DESCRIPTION"
                    )}
                  >
                    {property.aliases?.map((alias, i) => {
                      let errorMessage = "";

                      const secondaryAliasErr = secondaryAliasesErrors.find(
                        (e) => e.index === i
                      );

                      if (secondaryAliasErr) {
                        errorMessage = secondaryAliasErr.msg;
                      } else if (
                        errors.some(
                          (err) =>
                            err.type ===
                              ConfigurationErrorType.PROPERTY_SECONDARY_ALIAS_EMPTY &&
                            err.data?.section === PropertySection.ALIASES &&
                            err.data?.subsection ===
                              PropertySubsection.SECONDARY_ALIASES &&
                            err.data?.index === i
                        )
                      ) {
                        errorMessage = t("ERROR_SECONDARY_ALIAS_EMPTY");
                      }

                      return (
                        <SecondaryAliasInputContainer key={i}>
                          <PropertyDefinitionCard
                            fieldType={FieldType.InputFieldType}
                            additionalInputInfo={getAliasAutoFill(property)}
                            fieldProps={{
                              value: getAliasWithoutAutoFill(alias),
                              placeholder: t(
                                "PROPERTY_CARD_ALIASES_SECONDARY_ALIAS_PLACEHOLDER"
                              ),
                              onChange: (newAlias) => {
                                if (newAlias.length === 0) {
                                  dispatch(
                                    handleAddConfigurationError(
                                      ConfigurationErrorLevel.ERROR,
                                      ConfigurationErrorType.PROPERTY_SECONDARY_ALIAS_EMPTY,
                                      {
                                        section: PropertySection.ALIASES,
                                        subsection:
                                          PropertySubsection.SECONDARY_ALIASES,
                                        index: i,
                                      }
                                    )
                                  );
                                } else {
                                  dispatch(
                                    handleRemoveConfigurationError(
                                      (err) =>
                                        !(
                                          err.type ===
                                            ConfigurationErrorType.PROPERTY_SECONDARY_ALIAS_EMPTY &&
                                          err.data?.section ===
                                            PropertySection.ALIASES &&
                                          err.data?.subsection ===
                                            PropertySubsection.SECONDARY_ALIASES &&
                                          err.data?.index === i
                                        )
                                    )
                                  );
                                }

                                setSecondaryAliases(
                                  property.aliases!.map(
                                    (secondaryAlias, index) =>
                                      index === i
                                        ? (autoFillAlias(
                                            property,
                                            newAlias
                                          ) as string)
                                        : secondaryAlias
                                  )
                                );
                              },
                              onDelete: () => {
                                setSecondaryAliases(
                                  (property.aliases ?? []).filter(
                                    (_, _i) => _i !== i
                                  )
                                );
                                dispatch(
                                  handleRemoveConfigurationError(
                                    (err) =>
                                      !(
                                        err.type ===
                                          ConfigurationErrorType.PROPERTY_SECONDARY_ALIAS_EMPTY &&
                                        err.data?.section ===
                                          PropertySection.ALIASES &&
                                        err.data?.subsection ===
                                          PropertySubsection.SECONDARY_ALIASES &&
                                        err.data?.i === i
                                      )
                                  )
                                );
                              },
                              disabled: isViewMode,
                              dataTestId: `alias-secondary-alias-${i}`,
                            }}
                            errorMessage={errorMessage}
                            dataTestId={`alias-secondary-alias-${i}`}
                          />
                        </SecondaryAliasInputContainer>
                      );
                    })}
                    <Protected permissions={UserRoles.EDIT_CONFIG}>
                      <AddButton
                        label={t(
                          "PROPERTY_SECTION_ALIASES_ADD_SECONDARY_ALIAS_BUTTON"
                        )}
                        dataTestId="alias-add-secondary-alias-button"
                        onClick={() => {
                          setSecondaryAliases([
                            ...(property.aliases ?? []),
                            autoFillAlias(property, "") as string,
                          ]);
                        }}
                        outline
                      />
                    </Protected>
                  </Accordion>
                </SectionWrapper>
                <br />
                <SectionWrapper>
                  <AliasExpendableCard
                    mandatory
                    dataTestId="primary-alias-delivery-protocol"
                    title={t("PROPERTY_CARD_ALIASES_PROTOCOL_TITLE")}
                    description={t(
                      "PROPERTY_CARD_ALIASES_PROTOCOL_DESCRIPTION"
                    )}
                  >
                    <HttpProtocolsCard
                      cardDataTestId="alias-protocol"
                      divider={isOpen && isHttps}
                      errorMessage={
                        errors.some(
                          (err) =>
                            err.type ===
                              ConfigurationErrorType.PROPERTY_PROTOCOL_EMPTY &&
                            err.data.section === PropertySection.ALIASES &&
                            err.data.subsection ===
                              PropertySubsection.PROTOCOL_SETTINGS
                        )
                          ? t("ERROR_ALIAS_NO_PROTOCOL")
                          : undefined
                      }
                      httpDataTestId="alias-protocol-http"
                      httpId="primaryAlias-protocol-http"
                      httpLabel={t(
                        "PROPERTY_CARD_ALIASES_PROTOCOL_CHECKBOX_HTTP_LABEL"
                      )}
                      httpsDataTestId="alias-protocol-https"
                      httpsId="primaryAlias-protocol-https"
                      httpsLabel={t(
                        "PROPERTY_CARD_ALIASES_PROTOCOL_CHECKBOX_HTTPS_LABEL"
                      )}
                      httpDisabled={isViewMode}
                      httpsDisabled={
                        isViewMode ||
                        (registeredProperty &&
                          property.protocolSettings.httpsConfig?.serviceType ===
                            ServiceTypes.BASIC)
                      }
                      httpsPopupText={
                        registeredProperty &&
                        property.protocolSettings.httpsConfig?.serviceType ===
                          ServiceTypes.BASIC
                          ? "Changing his field will change postfix of your primary alias. We disabled editing because primary alias cannot be changed after it has been deployed / registered to the network. You can find all primary aliases that have been registered to the network on Production Tab → Property Registry Table."
                          : ""
                      }
                      placeholder={t(
                        "PROPERTY_CARD_ALIASES_PROTOCOL_PLACEHOLDER"
                      )}
                      protocols={property.protocolSettings.protocols}
                      title={t("PROPERTY_CARD_ALIASES_PROTOCOL_TITLE")}
                      onHttpChange={(newProtocols) => {
                        onPropertyChange({
                          ...property,
                          protocolSettings: {
                            ...property.protocolSettings,
                            protocols: newProtocols,
                          },
                        });
                      }}
                      onHttpsChange={(protocols, httpsConfig) => {
                        let primaryAlias = property.primaryAlias;
                        let aliases = property.aliases;

                        if (protocols.includes(Protocols.HTTPS)) {
                          aliases = property.aliases?.map((alias) =>
                            autoFillAlias(
                              {
                                protocolSettings: {
                                  httpsConfig,
                                } as any,
                              },
                              alias
                            )
                          ) as string[] | undefined;
                          primaryAlias = autoFillAlias(
                            {
                              protocolSettings: {
                                httpsConfig,
                              } as any,
                            },
                            property.primaryAlias
                          );
                        } else {
                          primaryAlias = getAliasWithoutAutoFill(
                            property.primaryAlias
                          );
                          aliases = property.aliases?.map(
                            getAliasWithoutAutoFill
                          ) as string[] | undefined;
                        }

                        onPropertyChange({
                          ...property,
                          aliases,
                          primaryAlias,
                          protocolSettings: {
                            ...property.protocolSettings,
                            protocols,
                            httpsConfig,
                          },
                        });
                      }}
                      registeredProperty={registeredProperty}
                    />
                    {isHttps && (
                      <HttpsConfigCard
                        protocolSettings={property.protocolSettings}
                        updateProtocolSettings={(protocolSettings) => {
                          const errorsToAdd: IConfigurationError[] = [];
                          const errorsToFilter: ((
                            config: IConfigurationError
                          ) => boolean)[] = [];
                          let _encryptionError: string | undefined = undefined;

                          if (
                            isInvalidBasicHttp2EncryptConfig(protocolSettings)
                          ) {
                            errorsToAdd.push({
                              level: ConfigurationErrorLevel.ERROR,
                              type:
                                ConfigurationErrorType.PROPERTY_ALIAS_ENCRYPTION_LEVEL_INVALID,
                              data: {
                                section: PropertySection.ALIASES,
                              },
                            });

                            _encryptionError = t(
                              getInvalidBasicHttp2EncryptConfigTranslate(
                                protocolSettings
                              )
                            );
                          } else {
                            errorsToFilter.push(
                              (err) =>
                                !(
                                  err.level === ConfigurationErrorLevel.ERROR &&
                                  err.type ===
                                    ConfigurationErrorType.PROPERTY_ALIAS_ENCRYPTION_LEVEL_INVALID &&
                                  err.data?.section === PropertySection.ALIASES
                                )
                            );
                          }

                          setEncryptionError(_encryptionError);

                          dispatch(
                            handleUpdateConfigurationErrors(
                              errorsToAdd,
                              errorsToFilter
                            )
                          );

                          onPropertyChange(
                            getUpdatedPropertyWithPrimaryAlias(
                              getUpdatedProperty(property, protocolSettings),
                              protocolSettings
                            )
                          );
                        }}
                        id="primary"
                        encryptionError={encryptionError}
                        registeredProperty={registeredProperty}
                      />
                    )}
                  </AliasExpendableCard>
                </SectionWrapper>
              </>
            ) : undefined}
          </>
        }
      />
    </>
  );
};

const SectionWrapper = styled.div`
  width: 100%;
`;

const SecondaryAliasInputContainer = styled.div`
  margin-bottom: 16px;
`;

const AliasExpendableCard = styled(Accordion)`
  overflow: unset;
`;
