import styled from "@emotion/styled";
import { FC } from "react";

import { ReactComponent as PlusSvg } from "../../../assets/icons/plus.svg";

interface LabelButtonProps {
  label: string;
  dataTestId?: string;
  onClick: () => void;
}

export const LabelButton: FC<LabelButtonProps> = ({
  label,
  dataTestId,
  onClick,
}) => {
  return (
    <LabelButtonContainer onClick={onClick} data-testid={dataTestId}>
      <PlusIcon /> {label}
    </LabelButtonContainer>
  );
};

const LabelButtonContainer = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.icon.highlight};
  font-size: 14px;
  font-weight: 500;

  margin-top: 20px;
  margin-left: 10px;

  cursor: pointer;
`;

const PlusIcon = styled(PlusSvg)`
  width: 14px;
  margin-right: 5px;
  * {
    fill: ${({ theme }) => theme.icon.highlight};
  }
`;
