import styled from "@emotion/styled";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../atoms/Button/Button";
import { IModalChildren, Modal } from "../../atoms/Modal/Modal";
import { TrashIcon } from "../../atoms/TrashIcon/TrashIcon";

interface UnbindMatchLogicModalProps {
  handleDelete: () => void;
}

export const UnbindMatchLogicModal = ({
  handleDelete,
}: UnbindMatchLogicModalProps): ReactElement => (
  <Modal customButton={<TrashIcon dataTestId="match-logic-unbind-button" />}>
    <ChildUnbindMatchLogicModal handleDelete={handleDelete} />
  </Modal>
);

interface IChildUnbindMatchLogicModalProps extends IModalChildren {
  handleDelete: () => void;
}

const ChildUnbindMatchLogicModal: React.FC<IChildUnbindMatchLogicModalProps> = ({
  handleDelete,
  closeModal,
}) => {
  const { t } = useTranslation("configurationPropertyPage");

  return (
    <ModalChildContainer data-testid="unbind-match-logic-modal-child">
      <Content>
        <HeaderContent data-testid="delete-match-logic-title">
          {t("MATCH_LOGIC_DELETE_TITLE")}
        </HeaderContent>
        <BodyContent>
          <TextContent data-testid="delete-match-logic-content-1">
            {t("MATCH_LOGIC_DELETE_CONTENT_1")}
          </TextContent>
          <TextContent data-testid="delete-match-logic-content-2">
            {t("MATCH_LOGIC_DELETE_CONTENT_2")}
          </TextContent>
        </BodyContent>
        <ButtonsContainer>
          <Button
            label="Cancel"
            onClick={() => {
              closeModal && closeModal();
            }}
            backgroundColor="baseLight"
            textColor="primary"
            borderColor="mutedLight"
            dataTestId="unbind-match-logic-modal-cancel-button"
          />
          <Button
            label="Validate"
            backgroundColor="red60"
            textColor="primaryAlt"
            onClick={() => {
              handleDelete();
              closeModal && closeModal();
            }}
            dataTestId="unbind-match-logic-modal-validate-button"
          />
        </ButtonsContainer>
      </Content>
    </ModalChildContainer>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContent = styled.h3``;

const BodyContent = styled.div``;

const TextContent = styled.p``;

const ModalChildContainer = styled.div`
  padding: 32px;
  overflow: auto;
  font-size: 1rem !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;
