import {
  CCHO,
  FailedRefreshTTL,
  ICachePolicyInt,
  NegativeTTL,
} from "../models/configuration/definitions/matchlogic";
import { VALID_STRINGS } from "../modules/caching/configurations/configuration_page/match_rules/match_rules_page/features/list/feature_block/cchoFeatureBlock/constants";
import { VALID_STRINGS as VALID_STRINGS_NEGATIVE } from "../modules/caching/configurations/configuration_page/match_rules/match_rules_page/features/list/feature_block/NegativeTTLFeatureBlock";
import { isString } from "./string";

export const convertCCHO = (ccho: CCHO | undefined): void => {
  if (
    ccho?.ext?.cachePolicy &&
    !isString(ccho.ext.cachePolicy, VALID_STRINGS) &&
    typeof ccho.ext.cachePolicy !== "number"
  ) {
    const parsedValue = parseInt(ccho.ext.cachePolicy, 10);
    ccho.ext.cachePolicy = isNaN(parsedValue) ? 0 : parsedValue;
  }

  if (
    ccho?.int?.cachePolicy &&
    !isString(ccho.int.cachePolicy, VALID_STRINGS) &&
    typeof ccho.int.cachePolicy !== "number"
  ) {
    const parsedValue = parseInt(ccho.int.cachePolicy, 10);
    ccho.int.cachePolicy = isNaN(parsedValue) ? 0 : parsedValue;
  }
};

export const convertDefaultCache = (
  defaultCache: ICachePolicyInt | undefined
): void => {
  if (
    typeof defaultCache?.cachePolicy !== "undefined" &&
    typeof defaultCache.cachePolicy !== "number"
  ) {
    const parsedValue = parseInt(defaultCache.cachePolicy, 10);
    defaultCache.cachePolicy = isNaN(parsedValue) ? 0 : parsedValue;
  }
};

export const convertFailedRefreshTTL = (
  failedRefreshTTL: FailedRefreshTTL | undefined
): void => {
  if (
    typeof failedRefreshTTL?.ttl !== "undefined" &&
    typeof failedRefreshTTL.ttl !== "number"
  ) {
    const parsedValue = parseInt(failedRefreshTTL.ttl, 10);
    failedRefreshTTL.ttl = isNaN(parsedValue) ? 0 : parsedValue;
  }
};

export const convertNegativeTTL = (
  negativeTTL: NegativeTTL | undefined
): void => {
  if (
    typeof negativeTTL !== "undefined" &&
    !isString(negativeTTL.cachePolicy, VALID_STRINGS_NEGATIVE) &&
    typeof negativeTTL.cachePolicy !== "number"
  ) {
    const parsedValue = parseInt(negativeTTL.cachePolicy, 10);
    negativeTTL.cachePolicy = isNaN(parsedValue) ? 0 : parsedValue;
  }
};
