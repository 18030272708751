import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import EmptyPageDarkSrc from "../../../../assets/images/empty_page_dark.png";
import EmptyPageLightSrc from "../../../../assets/images/empty_page_light.png";
import { EmptyPage } from "../../../../components/atoms/EmptyPage/EmptyPage";
import { Protected } from "../../../../components/atoms/Protected/Protected";
import { GuidanceModal } from "../../../../components/organisms/GuidanceModal/GuidanceModal";
import { UserRoles } from "../../../../models/permissions";
import { AddCertificateModal } from "./AddCertificateModal";

const PLACEHOLDER_IMAGES = { light: EmptyPageLightSrc, dark: EmptyPageDarkSrc };

export const EmptyList = (): ReactElement => {
  const { t } = useTranslation("certificatesPage");

  return (
    <EmptyPage
      images={PLACEHOLDER_IMAGES}
      title={t("EMPTY_CERTIFICATE_LIST_TITLE")}
      content={t("EMPTY_CERTIFICATE_LIST_DESCRIPTION")}
      dataTestId="certificates-list-empty"
    >
      <GuidanceModal
        buttonLabel={t("GUIDANCE_BUTTON_LABEL")}
        title={t("GUIDANCE_MODAL_TITLE")}
        content={t("GUIDANCE_MODAL_CONTENT")}
      />
      <Protected permissions={UserRoles.EDIT_CONFIG}>
        <Margin />
        <AddCertificateModal
          openButtonProps={{
            size: "md",
            label: t("EMPTY_CERTIFICATE_LIST_GET_STARTED_BUTTON"),
          }}
        />
      </Protected>
    </EmptyPage>
  );
};

const Margin = styled.span`
  margin: 1rem;
`;
