import { MutableRefObject, useEffect } from "react";
import { split as SplitEditor } from "react-ace";

import { handleNewScrollPosition, setDataTestID } from "./helpers";

export const useSplitEditorScroller = (
  editorRef: MutableRefObject<SplitEditor | null>,
  autoScroll: boolean
): void => {
  useEffect(() => {
    const [editor1, editor2] = editorRef.current?.split.$editors ?? [];

    setDataTestID(editor1, "split-editor-1");
    setDataTestID(editor2, "split-editor-2");

    const editor1ScrollHandler = (scrollTop: number) => {
      handleNewScrollPosition(editor2, editor1, scrollTop);
    };
    const editor2ScrollHandler = (scrollTop: number) => {
      handleNewScrollPosition(editor1, editor2, scrollTop);
    };

    if (autoScroll) {
      editor1?.getSession().on("changeScrollTop", editor1ScrollHandler);
      editor2?.getSession().on("changeScrollTop", editor2ScrollHandler);
    }

    return () => {
      if (autoScroll) {
        editor1?.getSession().off("changeScrollTop", editor1ScrollHandler);
        editor2?.getSession().off("changeScrollTop", editor2ScrollHandler);
      }
    };

    // Ignore dep editorRef
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScroll]);
};
