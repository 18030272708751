import { FC, useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";

import { SpinningIcon } from "../../atoms/SpinningIcon/SpinningIcon";
import {
  DropDownHeaderContainer,
  Label,
  Selected,
} from "../../atoms/DropDownHeader/DropDownHeader";
import { FixedWidgetIds, Property } from "../../../modules/rtm/models/models";
import { DropdownMenu } from "../../atoms/DropDownMenu/DropDownMenu";
import { DropDownContent } from "../Dropdown/DropdownContent";
import {
  FilterFields,
  useFiltersContext,
} from "../../../modules/rtm/context/filters";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { useDropdown } from "../Dropdown/useDropdown";
import { Icons } from "../../atoms/Icon/Icon";
import { SearchBar } from "../../molecules/SearchBar/SearchBar";
import { useProperties } from "../../../store/slices/rtm/hooks";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";
import { Button } from "../../atoms/Button/Button";
import { useTranslation } from "react-i18next";

const displayHeader = (
  properties?: string[],
  selectedPropeties?: IDropdownItem[]
) => {
  if (properties && properties.length > 0) {
    if (
      properties &&
      selectedPropeties &&
      properties.length === selectedPropeties.length
    ) {
      return "All";
    }
    if (selectedPropeties && selectedPropeties.length > 0) {
      return selectedPropeties.length === 1
        ? selectedPropeties[0].label
        : `${selectedPropeties.length} properties`;
    }
  }
  return "No item selected";
};

export const PropertyFilter: FC<{ propertyIDs?: number[] }> = ({
  propertyIDs,
}) => {
  const navStateRef = useRef(propertyIDs !== undefined);
  const properties = useProperties();
  const { preferences, updateWidgetPreference } = useFiltersContext();
  const [displayedProperties, setDisplayedProperties] = useState<Property[]>(
    properties || []
  );
  const [selectedProperties, setSelectedProperties] = useState<IDropdownItem[]>(
    preferences.get(FixedWidgetIds.GLOBAL_REPORT)?.property?.map((p) => ({
      value: p,
      label: p,
    })) || []
  );

  const onSearch = (search: string) => {
    setDisplayedProperties(
      properties?.filter((prop) => prop.name.includes(search)) || []
    );
  };

  const propertyItems = displayedProperties?.map((prop) => ({
    value: prop.name,
    label: prop.name,
  }));

  const handleSelectProperties = (value: IDropdownItem[]) => {
    updateWidgetPreference(FixedWidgetIds.GLOBAL_REPORT, {
      key: FilterFields.PROPERTY,
      value: value.map((item) => item.value),
    });
    setSelectedProperties(value);
  };

  const { isOpen, setIsOpen, handleSelection, dropdownRef } = useDropdown({
    items: propertyItems,
    selectedItems: selectedProperties ? selectedProperties : [],
    setSelectedItems: handleSelectProperties,
    multiSelect: true,
  });

  useEffect(() => {
    if (properties) {
      if (propertyIDs && navStateRef.current) {
        const propertiesToSelect: IDropdownItem[] = [];
        propertyIDs?.forEach((id) => {
          const foundProperty = properties.find(
            (prop) => parseInt(prop.id, 10) === id
          );
          if (foundProperty) {
            propertiesToSelect.push({
              value: foundProperty.name,
              label: foundProperty.name,
            });
          }
        });
        handleSelectProperties(propertiesToSelect);
      } else {
        const savedProperties = preferences.get(FixedWidgetIds.GLOBAL_REPORT)
          ?.property;
        handleSelectProperties(
          !!savedProperties
            ? savedProperties.map((prop) => ({ value: prop, label: prop }))
            : properties.map((prop) => ({ value: prop.name, label: prop.name }))
        );
      }
      setDisplayedProperties(properties);
    }
  }, [properties, propertyIDs]);

  const { t } = useTranslation("misc");

  return (
    <div className="chi-dropdown" ref={dropdownRef}>
      <DropDownHeaderContainer isOpen={isOpen}>
        <Label isOpen={isOpen}>Property</Label>
        <Selected>
          {displayHeader(
            properties?.map((p) => p.name),
            selectedProperties
          )}
        </Selected>
        <SpinningIcon
          name={Icons.CHEVRON_DOWN}
          color="secondary"
          onClick={() => {
            setIsOpen((current) => !current);
            if (isOpen) {
              if (properties) setDisplayedProperties(properties);
            }
          }}
          isOpen={isOpen}
        />
      </DropDownHeaderContainer>
      <MultiMenuContainer>
        {isOpen && (
          <DropdownMenu>
            <DropDownContent
              items={propertyItems}
              header={
                <FlexBox>
                  <SearchBar
                    placeholder="Search a property"
                    onChange={onSearch}
                  />
                  <Button
                    label={t("CLEAR")}
                    onClick={() => {
                      navStateRef.current = false;
                      handleSelectProperties([]);
                    }}
                    backgroundColor="red60"
                    textColor="primaryAlt"
                  />
                </FlexBox>
              }
              multiSelect
              isOpen={isOpen}
              onSelect={handleSelection}
              selectedItems={selectedProperties || []}
            />
          </DropdownMenu>
        )}
      </MultiMenuContainer>
    </div>
  );
};

const MultiMenuContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
`;
