import { ApiKeysData } from "../../../../models/user-management/apiKeys/apiKeys.interfaces";

import {
  ApiKeysCustomerFilter,
  ApiKeysOrder,
  ApiKeysStatus,
  FilteredApiKeysData,
} from "./types";

export const filterApiKeys = (
  input: string,
  roleFilter: string,
  statusFilter: ApiKeysStatus | undefined,
  sortOrder: string,
  customerFilter: ApiKeysCustomerFilter,
  isVyvx: boolean
) => (apiKeys: ApiKeysData[]): FilteredApiKeysData[] => {
  const result = [...apiKeys];
  const search = input.toLowerCase();
  sortOrder === ApiKeysOrder.RECENT
    ? result.sort(
        (a, b) => Number(a.lastModifiedDate) - Number(b.lastModifiedDate)
      )
    : result.sort(
        (a, b) => Number(b.lastModifiedDate) - Number(a.lastModifiedDate)
      );

  return result.filter((apiKey) => {
    const searchCondition =
      apiKey.id.toLowerCase().includes(search) ||
      apiKey.name.toLowerCase().includes(search) ||
      apiKey.contact.toLowerCase().includes(search);

    const statusCondition =
      statusFilter === ApiKeysStatus.ALL
        ? true
        : statusFilter === ApiKeysStatus.ACTIVE
        ? apiKey.status === ApiKeysStatus.ACTIVE
        : statusFilter === ApiKeysStatus.DISABLED
        ? apiKey.status === ApiKeysStatus.DISABLED
        : false;

    const rolesCondition =
      roleFilter === "all" ? true : roleFilter === apiKey.role ? true : false;

    const companyFilter =
      customerFilter.customerName === "all"
        ? true
        : false || isVyvx
        ? customerFilter.customerId === apiKey.vyvxCompanyAbbr
        : customerFilter.customerId === apiKey.accessGroupId;
    return (
      searchCondition && statusCondition && rolesCondition && companyFilter
    );
  });
};
