import { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { WidgetLayout } from "../../../components/molecules/WidgetLayout/WidgetLayout";
import { Loader } from "../../../components/atoms/Loader/Loader";
import { LoaderContainer } from "../../../components/atoms/LoaderContainer/LoaderContainer";
import { FeatureFlagsDataTable } from "./components/templates/Table/FeatureFlagsDataTable";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { APIError } from "../../../models/error";
import {
  createFeatureFlag,
  deleteFeatureFlag,
  FeatureFlagHeader,
  FeatureFlagsData,
  getFeatureFlags,
  updateFeatureFlag,
} from "./models/models";
import { FeatureFlagsModal } from "./components/templates/Table/FeatureFlagsModal";

export const FeatureFlags: FC = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsData[]>([]);
  const [loadingFeatureFlags, setLoadingFeatureFlags] = useState<boolean>(
    false
  );
  const { t } = useTranslation("featureFlagsIndexPage");
  const handleMediaPortalError = useDispatchAPIError();

  const fetchFeatureFlags = async () => {
    setLoadingFeatureFlags(true);
    try {
      const { data } = await getFeatureFlags();
      setFeatureFlags(data);
    } catch (error) {
      handleMediaPortalError(error as APIError);
      setFeatureFlags([]);
    }
    setLoadingFeatureFlags(false);
  };

  const handleDeleteFeatureFlag = async (
    featureFlagId: string,
    featureFlagName: string
  ) => {
    try {
      await deleteFeatureFlag(featureFlagId);
      toast.success(t("DELETE_SUCCEED"));
      fetchFeatureFlags();
    } catch (error) {
      handleMediaPortalError(error as APIError);
    }
  };

  const handleSaveFeatureFlag = async (
    featureFlag: FeatureFlagHeader,
    isUpdate: boolean,
    featureFlagId?: string
  ) => {
    try {
      if (!isUpdate) {
        await createFeatureFlag(featureFlag);
        toast.success(t("CREATE_SUCCEED"));
      } else if (isUpdate && featureFlagId) {
        await updateFeatureFlag(featureFlag, featureFlagId);
        toast.success(t("UPDATE_SUCCEED"));
      }
      await fetchFeatureFlags();
    } catch (error) {
      handleMediaPortalError(error as APIError);
    }
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  return loadingFeatureFlags ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <WidgetLayout title={t("LIST_OF_FEATURE_FLAGS")}>
        <FeatureFlagsModal
          handleSaveFeatureFlag={handleSaveFeatureFlag}
          isUpdate={false}
          dataTestId="feature-flag-modal"
        />
        <FeatureFlagsDataTable
          featureFlags={featureFlags}
          handleDeleteFeatureFlag={handleDeleteFeatureFlag}
          handleSaveFeatureFlag={handleSaveFeatureFlag}
          dataTestId="feature-flag"
        />
      </WidgetLayout>
    </>
  );
};
