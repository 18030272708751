import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import certificatePage from "./locales/en/cmv3/certificatePage.json";
import certificatesPage from "./locales/en/cmv3/certificatesPage.json";
import configurationDefinitionsPage from "./locales/en/cmv3/configurationDefinitionsPage.json";
import configurationMatchRulesPage from "./locales/en/cmv3/configurationMatchRulesPage.json";
import configurationPage from "./locales/en/cmv3/configurationPage.json";
import configurationPropertiesPage from "./locales/en/cmv3/configurationPropertiesPage.json";
import configurationPropertyPage from "./locales/en/cmv3/configurationPropertyPage.json";
import configurationsPage from "./locales/en/cmv3/configurationsPage.json";
import configurationVersionsPage from "./locales/en/cmv3/configurationVersionsPage.json";
import environmentHistoryPage from "./locales/en/cmv3/environmentHistoryPage.json";
import header from "./locales/en/cmv3/header.json";
import productionPage from "./locales/en/cmv3/productionPage.json";
import rawJsonPage from "./locales/en/cmv3/rawJsonPage.json";
import subscribersSettingsPage from "./locales/en/cmv3/subscribersSettingsPage.json";

import contentInvalidationPage from "./locales/en/content-invalidation/contentInvalidationPage.json";

import rtmReportPage from "./locales/en/rtm/reportPage.json";

import misc from "./locales/en/common/misc.json";
import layers from "./locales/en/common/layers.json";

import bannersModalPage from "./locales/en/portalhub/banners/bannersModalPage.json";
import bannersDataTablePage from "./locales/en/portalhub/banners/bannersDataTablePage.json";
import bannersIndexPage from "./locales/en/portalhub/banners/bannersIndexPage.json";

import featureFlagsModalPage from "./locales/en/portalhub/feature-flags/featureFlagsModalPage.json";
import featureFlagsDataTablePage from "./locales/en/portalhub/feature-flags/featureFlagsdataTablePage.json";
import featureFlagsIndexPage from "./locales/en/portalhub/feature-flags/featureFlagsIndexPage.json";

import accessGroupsPage from "./locales/en/user-management/accessGroupsPage.json";
import usersPage from "./locales/en/user-management/usersPage.json";
import userManagementHeader from "./locales/en/user-management/userManagementHeader.json";
import apiKeysPage from "./locales/en/user-management/apiKeysPage.json";
import roleModal from "./locales/en/user-management/roleModal.json";

const resources = {
  en: {
    certificatePage,
    certificatesPage,
    configurationDefinitionsPage,
    configurationMatchRulesPage,
    configurationPage,
    configurationPropertiesPage,
    configurationPropertyPage,
    configurationsPage,
    configurationVersionsPage,
    contentInvalidationPage,
    environmentHistoryPage,
    header,
    layers,
    misc,
    productionPage,
    rawJsonPage,
    rtmReportPage,
    subscribersSettingsPage,
    bannersModalPage,
    bannersDataTablePage,
    bannersIndexPage,
    featureFlagsModalPage,
    featureFlagsDataTablePage,
    featureFlagsIndexPage,
    accessGroupsPage,
    usersPage,
    userManagementHeader,
    apiKeysPage,
    roleModal,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  debug: true,
  interpolation: {
    // react already safes from xss
    escapeValue: false,
  },
});

export default i18n;
