import {
  AccessGroupCreationData,
  ExternalUserPayload,
  InternalUserPayload,
} from "../../../models/user-management/access-group/access-group.interfaces";
import {
  deleteAccessGroup,
  updateAccessGroupInfo,
  getAccessGroupInfos,
  getAccessGroupMembers,
  getAvailableProducts,
  updateAccessGroupProducts,
  removeAccessGroupsUsers,
  addAccessGroupsUsers,
} from "../../../models/user-management/access-group";
import { Status } from "../../../@types/status";
import { APIError } from "../../../models/error";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import {
  setAccessGroupInfos,
  setAccessGroupMembers,
  setAvailableProducts,
  setStatus,
} from "./slice";

export const fetchAccessGroupData = (accessGroupId: number): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setStatus(Status.LOADING));
    const [infos, members] = await Promise.all([
      getAccessGroupInfos(accessGroupId),
      getAccessGroupMembers(accessGroupId),
    ]);
    dispatch(setAccessGroupInfos(infos));
    dispatch(setAccessGroupMembers(members));
    dispatch(setStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
    dispatch(setStatus(Status.ERROR));
  }
};

export const fetchAvailableProducts = (id: number): AppThunk => async (
  dispatch
) => {
  try {
    const availableProducts = await getAvailableProducts(id);
    if (availableProducts) {
      dispatch(setAvailableProducts(availableProducts));
    }
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchAccessGroupMembers = (id: number): AppThunk => async (
  dispatch
) => {
  try {
    const members = await getAccessGroupMembers(id);
    dispatch(setAccessGroupMembers(members));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleUpdateAccessGroupInfos = (
  id: number,
  body: AccessGroupCreationData,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    const updatedInfos = await updateAccessGroupInfo(id, body);
    dispatch(setAccessGroupInfos(updatedInfos));
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleDeleteAccessGroup = (
  id: number,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await deleteAccessGroup(id);
    dispatch(setAccessGroupInfos(undefined));
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleUpdateProducts = (
  id: number,
  newProducts: {
    nis: string[];
    scids: string[];
  },
  cb?: () => void
): AppThunk => async (dispatch) => {
  try {
    await updateAccessGroupProducts(id, newProducts);
    dispatch(fetchAvailableProducts(id));
    if (cb) cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleDeleteUsers = (
  id: number,
  userIds: number[],
  cb?: () => void
): AppThunk => async (dispatch) => {
  try {
    await removeAccessGroupsUsers(id, userIds);
    if (cb) cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleAddUsers = (
  id: number,
  externalUsers: ExternalUserPayload[],
  internalUsers: InternalUserPayload[],
  cb?: () => void
): AppThunk => async (dispatch) => {
  try {
    await addAccessGroupsUsers(id, externalUsers, internalUsers);
    if (cb) cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};
