import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status } from "../../../@types/status";
import { FeatureFlagsSlice } from "./types";

const initialState: FeatureFlagsSlice = {
  value: undefined,
  status: Status.IDLE,
};

export const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setFeatureFlags: (
      state,
      action: PayloadAction<FeatureFlagsSlice["value"]>
    ) => {
      state.value = action.payload;
    },
    setStatus: (state, action: PayloadAction<FeatureFlagsSlice["status"]>) => {
      state.status = action.payload;
    },
  },
});

export const { setFeatureFlags, setStatus } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
