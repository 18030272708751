import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";
import { FeatureComponent } from "./types";

type IpRestrictionsComponent = FeatureComponent<
  Exclude<IFeatures["ipRestrictions"], undefined>
>;

export const IpRestrictions: IpRestrictionsComponent = ({
  feature: { denial, actionType, nameList },
  tPropertyPage,
  dataTestId,
}) => (
  <>
    <DetailedFeatureCardRow>
      {!!(nameList && nameList.length > 0) && (
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_IR_TITLE")}
        >
          <ExpandableList dataTestId="ipRestrictions">
            {nameList.map(renderColContent)}
          </ExpandableList>
        </DetailedFeatureCardBlock>
      )}

      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_IR_RESTRICTION_TITLE")}
        dataTestId={`${dataTestId}-restriction`}
      >
        {actionType}
      </DetailedFeatureCardBlock>

      {!!(actionType === "deny" && denial) && (
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_IR_AOD_TITLE")}
          dataTestId={`${dataTestId}-deny-action`}
        >
          {denial?.action}
        </DetailedFeatureCardBlock>
      )}
    </DetailedFeatureCardRow>

    {!!(actionType === "deny" && denial?.action === "redirect") && (
      <DetailedFeatureCardRow>
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_IR_REDIRECT_TITLE")}
          dataTestId={`${dataTestId}-redirect-url`}
        >
          {denial.redirectUrl}
        </DetailedFeatureCardBlock>
      </DetailedFeatureCardRow>
    )}
  </>
);
