import styled from "@emotion/styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../components/atoms/Button/Button";
import {
  FlexBox,
  FlexBoxBase,
} from "../../../../../components/atoms/FlexBox/FlexBox";
import { IModalChildren } from "../../../../../components/atoms/Modal/Modal";
import { UserTag } from "../../../../../components/atoms/UserTag/UserTag";
import { RadioCard } from "../../../../../components/molecules/RadioCard/RadioCard";
import {
  ExternalUserPayload,
  InternalUserPayload,
} from "../../../../../models/user-management/access-group/access-group.interfaces";
import { useAccessGroupInfos } from "../../../../../store/slices/user-management-access-group/hooks";
import { AddUserDataWithInternal, UserType } from "./helpers";
import { UserSearchBar } from "./UserSearchBar";
import { useAppDispatch } from "../../../../../store/types";
import { handleAddUsers } from "../../../../../store/slices/user-management-access-group/thunk";
import { ConditionalRender } from "../../../../../components/atoms/Protected/ConditionalRender";
import { useIsUserInternal } from "../../../../../store/slices/user/hooks";

export const AddMemberModalContent = ({
  closeModal,
}: IModalChildren): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  const [userType, setUserType] = useState<UserType>(UserType.EXTERNAL);

  const accessGroupInfo = useAccessGroupInfos();

  const [selectedUsers, setSelectedUsers] = useState<AddUserDataWithInternal[]>(
    []
  );

  const dispatch = useAppDispatch();

  const onAddUsers = async () => {
    if (accessGroupInfo?.accessGroupId) {
      const { externalUsers, internalUsers } = selectedUsers.reduce<{
        internalUsers: InternalUserPayload[];
        externalUsers: ExternalUserPayload[];
      }>(
        (prev, current) => {
          if (current.internal) {
            return {
              ...prev,
              internalUsers: [
                ...prev.internalUsers,
                {
                  ldapUsername: current.userId,
                  // We put 30 by default which is reporting
                  roleId: 30,
                  mailMessage: "",
                },
              ],
            };
          }
          return {
            ...prev,
            externalUsers: [
              ...prev.externalUsers,
              {
                email: current.description,
                // We put 30 by default which is reporting
                roleId: 30,
                mailMessage: "",
              },
            ],
          };
        },
        { internalUsers: [], externalUsers: [] }
      );
      dispatch(
        handleAddUsers(
          accessGroupInfo.accessGroupId,
          externalUsers,
          internalUsers,
          () => {
            if (closeModal) closeModal();
          }
        )
      );
    }
  };

  const isLoggedInUserInternal = useIsUserInternal();

  return (
    <Container>
      <Title>{t("ACCESS_GROUP_ADD_USERS_MODAL_TITLE")}</Title>
      <RadioContainer>
        <ConditionalRender condition={isLoggedInUserInternal}>
          <RadioCard
            selected={userType === UserType.INTERNAL}
            onSelect={() => {
              setUserType(UserType.INTERNAL);
            }}
          >
            <CardTitle>
              {t("ACCESS_GROUP_ADD_USERS_MODAL_INTERNAL_TITLE")}
            </CardTitle>
            <CardContent>
              {t("ACCESS_GROUP_ADD_USERS_MODAL_INTERNAL_DESCRIPTION")}
            </CardContent>
          </RadioCard>
        </ConditionalRender>
        <RadioCard
          selected={userType === UserType.EXTERNAL}
          onSelect={() => {
            setUserType(UserType.EXTERNAL);
          }}
        >
          <CardTitle>
            {t("ACCESS_GROUP_ADD_USERS_MODAL_EXTERNAL_TITLE")}
          </CardTitle>
          <CardContent>
            {t("ACCESS_GROUP_ADD_USERS_MODAL_EXTERNAL_DESCRIPTION")}
          </CardContent>
        </RadioCard>
      </RadioContainer>
      <UserSearchBar
        onSelect={(user) => {
          setSelectedUsers((current) => [...current, user]);
        }}
        userType={userType}
      />
      <Content>
        {selectedUsers.map((user) => (
          <UserTag
            key={user.userId}
            firstName={user.firstName}
            lastName={user.lastName}
            userPosition={user.description}
            onDelete={() => {
              setSelectedUsers((current) =>
                current.filter((u) => user.userId !== u.userId)
              );
            }}
          />
        ))}
      </Content>
      <RightButtonsContainer>
        <Button
          label={t("ACCESS_GROUP_ADD_PRODUCTS_MODAL_CANCEL")}
          backgroundColor="baseLight"
          textColor="primary"
          borderColor="white"
          onClick={() => {
            if (closeModal) closeModal();
          }}
        />
        <Button
          label={
            selectedUsers.length === 0
              ? t("ACCESS_GROUP_ADD_USERS_MODAL_BUTTON_EMPTY")
              : selectedUsers.length === 1
              ? t("ACCESS_GROUP_ADD_USERS_MODAL_BUTTON_SINGULAR")
              : t("ACCESS_GROUP_ADD_USERS_MODAL_BUTTON_PLURAL", {
                  count: selectedUsers.length,
                })
          }
          disabled={selectedUsers.length === 0}
          onClick={onAddUsers}
        />
      </RightButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
`;

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
`;

const CardContent = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.text.secondary};
`;

const RadioContainer = styled(FlexBox)`
  gap: 16px;
  margin-bottom: 32px;
`;

const RightButtonsContainer = styled(FlexBox)`
  justify-content: flex-end;
`;

const Content = styled(FlexBoxBase)`
  align-items: flex-start;
  flex-direction: column;
  padding-top: 16px;
  overflow-y: auto;
  height: 12em;
  gap: 8px;
`;
