import { TableState } from "../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { Metrics, Site } from "./models/models";

export const safeNAN = <T>(n: T): number | T => (Number.isNaN(n) ? 0 : n);

export const fixFloat = (n: number): string => n.toFixed(2);

export const getPercentage = (n1: number, n2: number): number =>
  safeNAN((n1 / n2) * 100);

export const isMetricPositive = (metric: Metrics): boolean => {
  switch (metric) {
    case Metrics.authPercent:
    case Metrics.hitRatePercent:
    case Metrics.mbPerSec:
    case Metrics.ptperSec:
    case Metrics.reqPerSec:
    case Metrics.signedPercent:
      return true;
    default:
      return false;
  }
};

export const handleSortByMetric = <
  T extends Site,
  Data extends Pick<
    T,
    | Metrics.reqPerSec
    | Metrics.missMbPerSec
    | Metrics.missPerSec
    | Metrics.fourZeroFourPerSec
    | Metrics.fiveZeroThreePerSec
    | Metrics.fiveZeroFourPerSec
    | Metrics.fiveXXPerSec
    | Metrics.hitRatePercent
  >
>(
  sortField: TableState<Data>["sortField"],
  sortOrder: TableState<Data>["sortOrder"],
  list: Data[]
): Data[] => {
  list.sort((r1, r2) => {
    const val1 = r1[sortField];
    const val2 = r2[sortField];

    if (val1 > val2) {
      return sortOrder === "asc" ? 1 : -1;
    } else if (val1 < val2) {
      return sortOrder === "asc" ? -1 : 1;
    } else {
      return 0;
    }
  });

  return list;
};
