import { httpClient } from "../../core/http-client";
import { APIError } from "../error";

export interface Invalidation {
  id: string;
  createdAt: number;
  publisherEmail: string;
  notificationEmail: string;
  propertyName: string;
  paths: string[];
  notify: boolean;
  force: boolean;
  ignoreCase: boolean;
  progress: number;
}

export interface PropertyPath {
  propertyId: number;
  paths: string[];
}

export interface InvalidationProperty {
  id: number;
  name: string;
}

export interface CreateInvalidation {
  force: boolean;
  notify: boolean;
  ignoreCase: boolean;
  propertyPathList: PropertyPath;
}

export interface CreateInvalidationBody {
  force: boolean;
  notify: boolean;
  ignoreCase: boolean;
  propertyPathList: PropertyPath[];
}

export const getInvalidationProperties = async (
  accessGroup: number
): Promise<InvalidationProperty[]> => {
  try {
    const { data } = await httpClient.get<{ data: InvalidationProperty[] }>(
      `/contentInvalidation/v1/accessGroups/${accessGroup}/properties`
    );

    return data.data;
  } catch (err) {
    const error = err as any;
    throw new APIError(error.message, "getInvalidationProperties");
  }
};

export const getInvalidations = async (
  accessGroup: number
): Promise<Invalidation[]> => {
  try {
    const GET_INVALIDATIONS_ENDPOINT = `/contentInvalidation/v1/accessGroups/${accessGroup}/invalidations`;

    const { data } = await httpClient.get<{ data: Invalidation[] }>(
      GET_INVALIDATIONS_ENDPOINT
    );

    return data.data;
  } catch (err) {
    const error = err as any;
    throw new APIError(error.message, "getInvalidations");
  }
};

export const submitInvalidation = async (
  accessGroup: number,
  invalidation: CreateInvalidation
): Promise<Invalidation> => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  try {
    const { data } = await httpClient.post<
      CreateInvalidationBody,
      Invalidation[]
    >(
      `/contentInvalidation/v1/accessGroups/${accessGroup}/invalidations`,
      { ...invalidation, propertyPathList: [invalidation.propertyPathList] },
      {
        headers,
      }
    );

    return data[0];
  } catch (err) {
    const error = err as Error;
    throw new APIError(error.message, "submitInvalidation");
  }
};
