import { AxiosError } from "axios";
import { httpClient } from "../../core/http-client";
import { APIError, CMv3APIError, ErrorLevel } from "../error";
import { VyvxAssociatedCompany, VyvxCompany } from "./vyvx.interfaces";

export const getVyvxAvailableRoles = async (): Promise<string[]> => {
  try {
    const { data } = await httpClient.get<{ roles: string[] }>(
      "/vyvx/availableRoles"
    );
    return data.roles;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getVyvxAvailableRoles()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getVyvxAvailableFiberCompanies = async (): Promise<
  VyvxCompany[]
> => {
  try {
    const { data } = await httpClient.get<{ fiberCompanies: VyvxCompany[] }>(
      "/vyvx/availableFiberCompanies"
    );
    return data.fiberCompanies;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getVyvxAvailableFiberCompanies()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getVyvxAvailableSatelliteCompanies = async (): Promise<
  VyvxCompany[]
> => {
  try {
    const { data } = await httpClient.get<{
      satelliteCompanies: VyvxCompany[];
    }>("/vyvx/availableSatelliteCompanies");
    return data.satelliteCompanies;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getVyvxAvailableSatelliteCompanies()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getVyvxAvailableAssociatedFiberCompanies = async (
  fiberCompanyAbbr: string
): Promise<VyvxAssociatedCompany[]> => {
  try {
    const { data } = await httpClient.get<{
      associatedCompanies: VyvxAssociatedCompany[];
    }>(`/vyvx/fiber/${fiberCompanyAbbr}/availableAssociatedCompanies`);
    return data.associatedCompanies;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getVyvxAvailableAssociatedFiberCompanyes()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getVyvxAvailableAssociatedSatelliteCompanies = async (
  satelliteCompanyAbbr: string
): Promise<VyvxAssociatedCompany[]> => {
  try {
    const { data } = await httpClient.get<{
      associatedCompanies: VyvxAssociatedCompany[];
    }>(`/vyvx/satellite/${satelliteCompanyAbbr}/availableAssociatedCompanies`);
    return data.associatedCompanies;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getVyvxAvailableAssociatedSatelliteCompanyes()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
