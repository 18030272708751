import {
  IMember,
  Protocols,
  DEFAULT_HTTPS_PORT,
  DEFAULT_TIMEOUT_RETRIES,
  DEFAULT_WEBROOT,
} from "../../../../models/configuration/definitions/origin";

export const generateDefaultMember = (idx?: number): IMember => ({
  name: `Member${idx === undefined ? 1 : idx + 1}`,
  origin: {
    host: "",
    protocol: Protocols.HTTPS,
    port: DEFAULT_HTTPS_PORT,
    timeoutRetries: DEFAULT_TIMEOUT_RETRIES,
    webroot: DEFAULT_WEBROOT,
  },
});
