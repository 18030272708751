import { ReactElement, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "../../../hooks/use-query";
import {
  useAccessTokenState,
  useDispatchAccessToken,
} from "../../../store/slices/auth/hooks";
import { handleAccessToken } from "../../../store/slices/auth/thunks";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";

interface AccessTokenProps {
  children: ReactNode;
}

export const AccessToken = ({
  children,
}: AccessTokenProps): ReactElement | null => {
  const [queryAccessToken] = useQuery("access_token");
  const dispatchAccessToken = useDispatchAccessToken();
  const dispatch = useAppDispatch();
  const [accessToken, loading] = useAccessTokenState();

  useEffect(() => {
    if (queryAccessToken) {
      dispatchAccessToken(queryAccessToken);
    } else {
      dispatch(handleAccessToken());
    }

    // Ignore dispatch, dispatchAccessToken.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryAccessToken]);

  if (accessToken) return <>{children}</>;

  if (loading) {
    return <Loader />;
  }

  return null;
};

const Loader = () => {
  const [t] = useTranslation("layers");

  return (
    <LoaderWithBackground>
      {t("ACCESS_TOKEN_LAYER_LOADING_DESC")}
    </LoaderWithBackground>
  );
};
