import { SetupStatus } from "./types";
import { httpClient } from "../../../core/http-client";
import { AppThunk } from "../../store";

import {
  CurrentData,
  IHistorical,
  Point,
  Property,
  SiteByProperty,
} from "../../../modules/rtm/models/models";
import { handleAPIError } from "../api-error/thunks";
import {
  addMetroToHistory,
  setCurrentAccessGroupData,
  setCurrentByMetro,
  setCurrentByProperty,
  setCurrentByRegion,
  setCurrentDataStatus,
  setCurrentPropertyStatus,
  setHistorical,
  setHistoryStatus,
  setMetroHistoryStatus,
  setProperties,
  setRegionHistoryStatus,
  setRegionsHistory,
  updateHistorical,
  updateMetrosHistoryWithCurrentData,
  updateRegionsHistory,
} from "./slice";
import { MetroHistory } from "../../../modules/rtm/components/templates/Locations/types";
import { Status } from "../../../@types/status";

export const handleFetchCurrentData = (initialSetup = false): AppThunk => (
  dispatch,
  getState
) => {
  const accessGroupId = getState().accessGroups.accessGroupId;

  if (!accessGroupId) {
    throw new Error('"accessGroupId" is undefined in "rtm"');
  }

  dispatch(
    setCurrentDataStatus(initialSetup ? SetupStatus.SETUP : Status.LOADING)
  );
  dispatch(
    setCurrentPropertyStatus(initialSetup ? SetupStatus.SETUP : Status.LOADING)
  );

  httpClient
    .get<CurrentData>(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/current`
    )
    .then((res) => {
      const {
        time,
        accessGroup: accessGroupData,
        regions: regionsData,
        metros: metrosData,
      } = res.data;
      dispatch(setCurrentAccessGroupData(accessGroupData));
      dispatch(setCurrentByMetro(metrosData));
      dispatch(setCurrentByRegion(regionsData));
      dispatch(updateRegionsHistory({ values: regionsData, time }));
      dispatch(
        updateMetrosHistoryWithCurrentData({ values: metrosData, time })
      );
      dispatch(updateHistorical({ site: accessGroupData, time }));
      dispatch(setCurrentDataStatus(Status.SUCCESS));
    })
    .catch((err) => {
      dispatch(handleAPIError(err));
      dispatch(setCurrentDataStatus(Status.ERROR));
    });
  httpClient
    .get<{ time: number; properties: SiteByProperty[] }>(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/current?select=property`
    )
    .then((res) => {
      dispatch(setCurrentByProperty(res.data.properties));
      dispatch(setCurrentPropertyStatus(Status.SUCCESS));
    })
    .catch((err) => {
      dispatch(handleAPIError(err));
      dispatch(setCurrentPropertyStatus(Status.ERROR));
    });
};

export const handleFetchProperties = (): AppThunk => (dispatch, getState) => {
  const accessGroupId = getState().accessGroups.accessGroupId;

  if (!accessGroupId) {
    throw new Error('"accessGroupId" is undefined in "rtm"');
  }

  httpClient
    .get<Property[]>(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/properties`
    )
    .then((res) => {
      dispatch(setProperties(res.data));
    })
    .catch((err) => dispatch(handleAPIError(err)));
};

export const handleFetchHistoricalData = (initialSetup = false): AppThunk => (
  dispatch,
  getState
) => {
  const accessGroupId = getState().accessGroups.accessGroupId;

  if (!accessGroupId) {
    throw new Error('"accessGroupId" is undefined in "rtm"');
  }

  dispatch(setHistoryStatus(initialSetup ? SetupStatus.SETUP : Status.LOADING));

  httpClient
    .get<IHistorical>(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/history`
    )
    .then((res) => {
      dispatch(setHistorical(res.data));
      dispatch(setHistoryStatus(Status.SUCCESS));
    })
    .catch((err) => {
      dispatch(handleAPIError(err));
      dispatch(setHistoryStatus(Status.ERROR));
    });
};

export const handleFetchHistoryForRegions = (
  initialSetup = false
): AppThunk => (dispatch, getState) => {
  const accessGroupId = getState().accessGroups.accessGroupId;

  if (!accessGroupId) {
    throw new Error('"accessGroupId" is undefined in "rtm"');
  }

  dispatch(
    setRegionHistoryStatus(initialSetup ? SetupStatus.SETUP : Status.LOADING)
  );

  httpClient
    .get<
      {
        region: string;
        points: Point[] | null;
      }[]
    >(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/history?group=region`
    )
    .then((res) => {
      const regionHistory = res.data.map(({ region, points }) =>
        !!points ? { region, points } : { region, points: [] }
      );

      dispatch(setRegionsHistory(regionHistory));
      dispatch(setRegionHistoryStatus(Status.SUCCESS));
    })
    .catch((err) => {
      dispatch(handleAPIError(err));
      dispatch(setRegionHistoryStatus(Status.ERROR));
    });
};

export const handleFetchHistoryForMetro = ({
  metro,
  region,
}: {
  metro: string;
  region: string;
}): AppThunk => (dispatch, getState) => {
  const accessGroupId = getState().accessGroups.accessGroupId;

  if (!accessGroupId) {
    throw new Error('"accessGroupId" is undefined in "rtm"');
  }

  dispatch(setMetroHistoryStatus(Status.LOADING));

  httpClient
    .get<MetroHistory>(
      `/realTimeMonitoring/v1/accessGroups/${accessGroupId}/metros/${metro}/history`
    )
    .then((res) => {
      dispatch(addMetroToHistory(res.data));
      dispatch(setMetroHistoryStatus(Status.SUCCESS));
    })
    .catch((err) => {
      dispatch(handleAPIError(err));
      dispatch(addMetroToHistory({ region, metro, points: [] }));
      dispatch(setMetroHistoryStatus(Status.ERROR));
    });
};
