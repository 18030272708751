import {
  ComponentProps,
  CSSProperties,
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import styled from "@emotion/styled";

export type SortOrder = "asc" | "desc";

type ColumnFormatter<Row> = (row: Row, rowIndex: number) => ReactNode;

export interface ColumnDescription<Data extends Record<string, unknown>> {
  name: ReactNode;
  dataField?: keyof Data;
  formatter: ColumnFormatter<Data>;
  sortable?: boolean;
  style?: CSSProperties;
}

export interface TableState<SortableFields extends Record<string, unknown>> {
  sortOrder: SortOrder;
  sortField: keyof SortableFields;
}

interface HeadlessDataTableProps<
  Data extends Record<string, unknown>,
  SortableFields extends Record<string, unknown>
> {
  data: Data[];
  columns: ColumnDescription<Data>[];
  defaultSorted?: { dataField: keyof SortableFields; order: SortOrder };
  keyField: string | ((value: Data) => string);
  onTableStateChanged?: (tableState: TableState<SortableFields>) => void;
  expandParams?: {
    expandedRows: number[];
    render: ColumnFormatter<Data>;
  };

  // UI components to override
  TableWrapper?: FC;
  TableHead?: FC;
  TableHeadRow?: FC;
  TableHeadRowCell?: FC<
    Pick<ComponentProps<typeof StyledTableHeadRowCell>, "onClick" | "style">
  >;
  TableBody?: FC;
  TableBodyRow?: FC;
  TableBodyRowCell?: FC;
  RowExpander?: FC<any>;
}

export const HeadlessDataTable = <
  Data extends Record<string, unknown>,
  SortableFields extends Partial<Data>
>({
  columns,
  data,
  keyField,
  defaultSorted,
  onTableStateChanged,
  TableWrapper = StyledTable,
  TableHead = StyledTableHead,
  TableHeadRow = StyledTableHeadRow,
  TableHeadRowCell = StyledTableHeadRowCell,
  TableBody = StyledTableBody,
  TableBodyRow = StyledTableBodyRow,
  TableBodyRowCell = StyledTableBodyRowCell,
  expandParams,
}: HeadlessDataTableProps<Data, SortableFields>): JSX.Element => {
  const [sortOrder, toggleSortOrder] = useReducer<
    (prev: SortOrder) => SortOrder
  >((prev) => (prev === "asc" ? "desc" : "asc"), defaultSorted?.order ?? "asc");
  const [sortField, setSortField] = useState<keyof SortableFields>(
    defaultSorted?.dataField ?? ""
  );

  useEffect(() => {
    if (!!onTableStateChanged) {
      onTableStateChanged({ sortField, sortOrder });
    }

    // Ignore dep "onTableStateChanged"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortField, sortOrder]);

  return (
    <TableWrapper>
      <TableHead>
        <TableHeadRow>
          {columns.map(({ dataField, name, sortable, style }) => (
            <TableHeadRowCell
              key={dataField as string}
              onClick={() => {
                if (sortable && typeof dataField !== "undefined") {
                  toggleSortOrder();
                  setSortField(dataField);
                }
              }}
              style={style}
            >
              {name}
            </TableHeadRowCell>
          ))}
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {data.map((row, rowIndex) => (
          <Fragment
            key={
              typeof keyField === "string"
                ? (row[keyField] as string)
                : keyField(row)
            }
          >
            <TableBodyRow>
              {columns.map((column, i) => (
                <TableBodyRowCell key={(column.dataField as string) || i}>
                  {column.formatter(row, rowIndex)}
                </TableBodyRowCell>
              ))}
            </TableBodyRow>
            {!!expandParams &&
              expandParams.expandedRows.includes(rowIndex) &&
              expandParams.render(row, rowIndex)}
          </Fragment>
        ))}
      </TableBody>
    </TableWrapper>
  );
};

const StyledTable = styled.table``;
const StyledTableHead = styled.thead``;
const StyledTableHeadRow = styled.tr``;
const StyledTableHeadRowCell = styled.th``;
const StyledTableBody = styled.tbody``;
const StyledTableBodyRow = styled.tr``;
const StyledTableBodyRowCell = styled.td``;
