import { MouseEvent, ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Icon, Icons } from "../../atoms/Icon/Icon";
import { Modal } from "../../atoms/Modal/Modal";
import { PresentationModal } from "../PresentationModal/PresentationModal";

interface DeleteConfigurationModalProps {
  configName: string;
  onDelete: () => void;
}

export const DeleteConfigurationModal = ({
  configName,
  onDelete,
}: DeleteConfigurationModalProps): ReactElement => {
  const { t } = useTranslation("configurationsPage");

  return (
    <Container onClick={(e: MouseEvent) => e.stopPropagation()}>
      <Modal
        customButton={
          <IconContainer>
            <Trash
              name={Icons.TRASH}
              color="secondary"
              dataTestId={`configuration-card-delete-button-${configName}`}
            />
          </IconContainer>
        }
      >
        <PresentationModal
          title={t("CONFIGURATION_DELETE_MODAL_TITLE")}
          onSubmit={onDelete}
          button={{
            label: t("CONFIGURATION_DELETE_MODAL_CONFIRM_BUTTON"),
          }}
          dataTestId={`configuration-delete-modal-${configName}`}
        >
          {t("CONFIGURATION_DELETE_MODAL_TEXT")}
          <br />
          <br />
          <b>{configName}</b>
        </PresentationModal>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  cursor: default;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const Trash = styled(Icon)`
  cursor: pointer;
`;
