import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  HorizontalFormBody,
  HorizontalFormContainer,
} from "../../../../../../../../../components/atoms/HorizontalFormLayout/HorizontalFormLayout";
import {
  TableHeaderWrapper,
  TableHeader,
  AddButtonContainer,
  AddButton,
} from "../../../../../../../../../components/molecules/DefinitionModal/DefinitionModal.styled";
import { getDuplicatedHeaderNames } from "../../../../../../../../../components/molecules/DefinitionModal/helpers";
import {
  AuthenticationTypes,
  IHeadersAuthentication,
  IMember,
} from "../../../../../../../../../models/configuration/definitions/origin";
import {
  PropertySection,
  PropertySubsection,
} from "../../../../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
  IConfigurationError,
} from "../../../../../../../../../models/configuration/errors";
import {
  handleAddConfigurationError,
  handleUpdateConfigurationErrors,
} from "../../../../../../../../../store/slices/caching/thunks";
import { OriginDefinitionType } from "../../../../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../../../../store/types";
import { getHeadersErrors } from "../../../../../../../../../utils/getHeadersErrors";
import { HeadersRow } from "./HeadersRow";

interface IHeadersTableProps {
  onChange: (updatedOriginDef: Partial<OriginDefinitionType>) => void;
  member: IMember;
  originDefinition: OriginDefinitionType;
  disabled: boolean;
  originIndex: number;
  dataTestId?: string;
  isHeadersNameEmptyError: boolean;
}

export const HeadersTable: React.FC<IHeadersTableProps> = ({
  onChange,
  member,
  originDefinition,
  disabled,
  originIndex,
  dataTestId,
  isHeadersNameEmptyError,
}) => {
  const parameters = member.authentication
    ?.parameters as IHeadersAuthentication[];
  const [headers, setHeaders] = useState<IHeadersAuthentication[]>(
    parameters.length < 1 ? [{ name: "", value: "" }] : parameters
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation("configurationPropertyPage");

  const addHeader = () => {
    setHeaders((_headers) => [..._headers, { name: "", value: "" }]);
  };

  const removeHeader = (index: number) => {
    headers.splice(index, 1);
    setHeaders([...headers]);
  };

  useEffect(() => {
    const errorsToRemove: ((err: IConfigurationError) => boolean)[] = [];

    if (!getHeadersErrors(headers)) {
      errorsToRemove.push(
        (err) =>
          !(
            err.type ===
              ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_EMPTY &&
            err.data?.section === PropertySection.ORIGIN &&
            err.data?.subsection === PropertySubsection.ORIGIN &&
            err.data?.authType === AuthenticationTypes.HEADERS &&
            err.data?.index === originIndex
          )
      );
    }

    if (getDuplicatedHeaderNames(headers).length === 0) {
      errorsToRemove.push(
        (err) =>
          !(
            err.type ===
              ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_DUPLICATE &&
            err.data?.section === PropertySection.ORIGIN &&
            err.data?.subsection === PropertySubsection.ORIGIN &&
            err.data?.authType === AuthenticationTypes.HEADERS &&
            err.data?.index === originIndex
          )
      );
    }

    dispatch(handleUpdateConfigurationErrors(undefined, errorsToRemove));

    if (member.authentication?.parameters !== headers) {
      onChange({
        members: originDefinition.members.map((m, i) =>
          i === originIndex
            ? {
                ...member,
                authentication: {
                  ...member.authentication,
                  type: AuthenticationTypes.HEADERS,
                  parameters: headers,
                },
              }
            : m
        ),
      });
    }
  }, [headers]);

  return (
    <HorizontalFormContainer>
      {headers && headers.length > 0 && (
        <>
          <TableHeaderWrapper>
            <TableHeader
              required
              data-testid={`${dataTestId}-header-name-header`}
            >
              {t("PROPERTY_CARD_ORIGIN_AUTHENTICATION_HEADERS_NAME_TITLE")}
            </TableHeader>
            <TableHeader data-testid={`${dataTestId}-header-value-header`}>
              {t("PROPERTY_CARD_ORIGIN_AUTHENTICATION_HEADERS_VALUE_TITLE")}
            </TableHeader>
          </TableHeaderWrapper>
          <HorizontalFormBody>
            {headers.map(
              (header: { name: string; value?: string }, i: number) => (
                <HeadersRow
                  disabled={disabled}
                  header={header}
                  rowIndex={i}
                  key={i}
                  removeHeader={removeHeader}
                  onNameChange={(e: any) => {
                    const name = e.target.value;

                    if (name.length === 0 && !isHeadersNameEmptyError) {
                      dispatch(
                        handleAddConfigurationError(
                          ConfigurationErrorLevel.ERROR,
                          ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_EMPTY,
                          {
                            section: PropertySection.ORIGIN,
                            subsection: PropertySubsection.ORIGIN,
                            authType: AuthenticationTypes.HEADERS,
                            index: originIndex,
                          }
                        )
                      );
                    }

                    headers[i].name = name;

                    if (getDuplicatedHeaderNames(headers).length > 0) {
                      dispatch(
                        handleAddConfigurationError(
                          ConfigurationErrorLevel.ERROR,
                          ConfigurationErrorType.PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_DUPLICATE,
                          {
                            section: PropertySection.ORIGIN,
                            subsection: PropertySubsection.ORIGIN,
                            authType: AuthenticationTypes.HEADERS,
                            index: originIndex,
                          }
                        )
                      );
                    }

                    setHeaders([...headers]);
                  }}
                  onValueChange={(e: any) => {
                    headers[i].value = e.target.value;
                    setHeaders([...headers]);
                  }}
                  dataTestId={`${dataTestId}-${i}`}
                  canDelete={i > 0}
                />
              )
            )}
          </HorizontalFormBody>
        </>
      )}
      {!disabled && (
        <AddButtonContainer>
          <AddButton
            onClick={() => {
              addHeader();
            }}
            data-testid={`${dataTestId}-add-header-button`}
          >
            + Add Header
          </AddButton>
        </AddButtonContainer>
      )}
    </HorizontalFormContainer>
  );
};
