import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { Icon, Icons } from "../Icon/Icon";

export interface AddButtonProps {
  label: string;
  dataTestId?: string;
  outline?: boolean;
  filled?: boolean;
  onClick: (event?: React.MouseEvent<HTMLDivElement>) => void;
}

export const AddButton: React.FC<AddButtonProps> = ({
  label,
  dataTestId,
  outline,
  filled: filled,
  onClick,
}) => (
  <AddButtonContainer onClick={onClick} outline={outline} filled={filled}>
    <StyledButton data-testid={dataTestId} filled={filled}>
      <PlusIcon
        name={Icons.PLUS}
        filled={filled}
        size={{ width: 14, height: 16 }}
      />
      &nbsp;{label}
    </StyledButton>
  </AddButtonContainer>
);

const StyledButton = styled.div<{ filled?: boolean }>`
  color: ${({ theme, filled }) =>
    filled ? theme.text.primaryAlt : theme.text.highlight};
  display: flex;
  align-items: center;
`;

const AddButtonContainer = styled.div<{ outline?: boolean; filled?: boolean }>`
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  flex-wrap: wrap;
  border-radius: 8px;
  cursor: pointer;
  ${({ outline, filled, theme }) => {
    if (filled) {
      return css`
        background-color: ${theme.backgrounds.highlight};
        border: 1px solid ${theme.borders.highlight};
        color: ${theme.text.primaryAlt};
      `;
    } else if (outline) {
      return css`
        border: 1px solid ${theme.borders.highlight};
      `;
    }
  }}
`;

const PlusIcon = styled(Icon)<{ filled?: boolean }>`
  margin-right: 5px;
  * {
    fill: ${({ theme, filled }) =>
      filled ? theme.icon.white : theme.backgrounds.highlight};
  }
`;
