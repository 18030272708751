import {
  CSSProperties,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import AceEditor from "react-ace";
import ReactAce from "react-ace/lib/ace";
import { css } from "@emotion/react";

import "brace/mode/javascript";
import "ace-builds/src-noconflict/theme-clouds";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-searchbox";
import { Theme, ThemeContext } from "../../../contexts/themeContext";
import { useReRenderOnSizeChangeOrScroll } from "../../../hooks/use-rerender-on-size-change-or-scroll/useReRenderOnSizeChangeOrScroll";

interface CodeEditorProps {
  onChange: (newCode: string) => void;
  code: string;
  id: string;
  readOnly?: boolean;
  interactive?: boolean;
  dynamicHeight?: boolean;
  searchText?: string;
  showSearchBar?: boolean;
}

export const CodeEditor = ({
  code,
  id,
  onChange,
  readOnly,
  interactive,
  dynamicHeight,
  searchText,
  showSearchBar = false,
}: CodeEditorProps): ReactElement => {
  const { colorset, theme } = useContext(ThemeContext);
  const [sizeChanged] = useReRenderOnSizeChangeOrScroll();
  const [blurDimension, setBlurDimension] = useState<{
    width: number;
    height: number;
  }>();
  const aceEditorRef = useRef<ReactAce>(null);

  useEffect(() => {
    if (aceEditorRef.current) {
      setBlurDimension({
        width: aceEditorRef.current.refEditor.clientWidth + 1,
        height: aceEditorRef.current.refEditor.clientHeight + 1,
      });
    }
  }, [aceEditorRef.current, sizeChanged]);

  useEffect(() => {
    if (aceEditorRef.current) {
      if (showSearchBar) {
        aceEditorRef.current.editor.execCommand("find");
      }
      if (searchText) {
        aceEditorRef.current.editor.find(searchText);
      }
    }
  }, [searchText, showSearchBar]);

  const AceEditorStyles: CSSProperties = {
    border: `1px solid ${colorset.borders.mutedLight}`,
  };

  if (dynamicHeight && aceEditorRef.current) {
    AceEditorStyles.height = "100%";

    // We need to force the Ace editor to re-render so that the bottom margin
    // gap can disappear. Setting the "showPrintMargin={false}" prop is somehow
    // not enought to make this work.
    aceEditorRef.current.editor.resize();
  }

  return (
    <CodeEditorContainer dynamicHeight>
      {readOnly && !interactive && (
        <ReadonlyBlur style={{ ...blurDimension }} />
      )}
      <AceEditor
        ref={aceEditorRef}
        value={code}
        mode="javascript"
        readOnly={readOnly}
        theme={theme === Theme.LIGHT_MODE ? "clouds" : "chaos"}
        wrapEnabled
        width="100%"
        onChange={onChange}
        name={id}
        editorProps={{ $blockScrolling: true }}
        highlightActiveLine={false}
        enableLiveAutocompletion
        showGutter
        showPrintMargin={false}
        style={AceEditorStyles}
        setOptions={{ useWorker: false }}
      />
    </CodeEditorContainer>
  );
};

const ReadonlyBlur = styled.div`
  position: absolute;
  opacity: 0.3;
  background-color: ${({ theme }) => theme.backgrounds.muted};
  z-index: 10;
`;

const CodeEditorContainer = styled.div<{
  dynamicHeight: boolean;
}>`
  height: 100%;
  width: 100%;
  position: relative;
  .ace_gutter {
    background-color: ${({ theme }) => theme.backgrounds.info};
    .ace_gutter-active-line {
      /* transition: background-color 1s; */
      background-color: ${({ theme }) =>
        `${theme.backgrounds.highlight} !important`};
    }

    .ace_gutter-cell {
      color: white;
      background-color: ${({ theme }) => `${theme.backgrounds.info}`};
      /* transition: background-color 0.3s; */
    }
  }
  ${({ dynamicHeight }) =>
    dynamicHeight &&
    css`
      .ace_content {
        height: 100% !important;
      }
    `}
`;
