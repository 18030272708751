import { ReactElement } from "react";

import { PeriodFilter } from "../../../modules/rtm/context/filters/index";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { DropdownMultiSelect } from "../Dropdown/DropdownMultiSelect";

const timeFilterItems = [
  {
    label: "Last 30 mins",
    value: PeriodFilter.LAST_30MINS,
  },
  {
    label: "Last hour",
    value: PeriodFilter.LAST_HOUR,
  },
  {
    label: "All time",
    value: PeriodFilter.ALL_TIME,
  },
];

interface TimeFilterProps {
  value: PeriodFilter;
  onChange: (value: PeriodFilter) => void;
  dataTestId?: string;
}

export const TimeFilter = ({
  value,
  onChange,
  dataTestId,
}: TimeFilterProps): ReactElement => (
  <DropdownMultiSelect
    dataTestId={dataTestId}
    items={timeFilterItems}
    placeholder="Period"
    selectedItems={[
      timeFilterItems.find((item) => item.value === value) as IDropdownItem,
    ]}
    setSelectedItems={(s) => {
      const [selected] = s;
      onChange(selected.value as PeriodFilter);
    }}
  />
);
