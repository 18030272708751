import styled from "@emotion/styled";
import { ReactElement } from "react";

import ErrorPageDarkSrc from "../../../assets/images/error_page_dark.png";
import ErrorPageLightSrc from "../../../assets/images/error_page_light.png";
import {
  EmptyPageBanner,
  EmptyPageContainer,
  EmptyPageDescription,
  EmptyPageTitle,
  EmptyPageWrapper,
} from "../../atoms/EmptyPage/EmptyPageWrapper";
import { useImage } from "../../atoms/EmptyPage/useImage";
import { Link } from "../../atoms/Link/Link";

const PLACEHOLDER_IMAGES = { light: ErrorPageLightSrc, dark: ErrorPageDarkSrc };

interface ErrorLayerProps {
  title?: string;
  content?: ReactElement;
  images?: { light: string; dark: string };
  dataTestId?: string;
}

export const ErrorLayer = ({
  dataTestId,
  title = "Something went wrong",
  content = (
    <EmptyPageDescription>
      Please, try refreshing the page. If the problem persists, contact{" "}
      <Link href="mailto:CDNsupport@lumen.com">CDNsupport@lumen.com</Link>
    </EmptyPageDescription>
  ),
  images = PLACEHOLDER_IMAGES,
}: ErrorLayerProps): ReactElement => (
  <ErrorLayerContainer>
    <EmptyPageWrapper data-testid={dataTestId}>
      <EmptyPageContainer>
        <EmptyPageBanner src={useImage(images)} alt="error illustration" />
        <EmptyPageTitle>{title}</EmptyPageTitle>
        {content}
      </EmptyPageContainer>
    </EmptyPageWrapper>
  </ErrorLayerContainer>
);

const ErrorLayerContainer = styled.div`
  height: 100%;

  & img {
    width: 600px;
  }
`;
