import { ReactElement } from "react";
import styled from "@emotion/styled";

import { useComparisonContext } from "./comparisonContext";
import { ConfigurationHeaderSwitcher } from "./ConfigurationHeaderSwitcher";
import { ConfigurationPageRouter } from "./ConfigurationPage.router";
import { ModuleContent } from "../../../../components/atoms/Module/Module";

const SCROLL_ID = "configuration_page";

export const ConfigurationPage = (): ReactElement => {
  useComparisonContext();

  return (
    <>
      <ConfigurationHeaderSwitcher />
      <ModuleContent id={SCROLL_ID}>
        <RouterContainer>
          <ConfigurationPageRouter />
        </RouterContainer>
      </ModuleContent>
    </>
  );
};

const RouterContainer = styled.div`
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer}`};
  display: flex;
  flex-direction: column;
  height: 100%;
`;
