import { FC } from "react";
// Ignore import/named eslint check to import CustomTypeOptions
// eslint-disable-next-line import/named
import { CustomTypeOptions, useTranslation } from "react-i18next";

import {
  MapSiteFieldsToLabel,
  Metrics,
  NonSiteMetrics,
} from "../../../modules/rtm/models/models";
import {
  useCurrentAccessGroupData,
  useProperties,
} from "../../../store/slices/rtm/hooks";
import { SingleNumber } from "../../molecules/SingleNumber/SingleNumber";

const MetricToHelpMessageMap = new Map<
  Metrics | NonSiteMetrics,
  keyof CustomTypeOptions["resources"]["rtmReportPage"]
>([
  [Metrics.reqPerSec, "REQ_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.mbPerSec, "MB_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.missMbPerSec, "MISS_MB_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.missPerSec, "MISS_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.fourZeroFourPerSec, "404_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.fiveZeroThreePerSec, "503_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.fiveZeroFourPerSec, "504_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.fiveXXPerSec, "5XX_PER_SEC_TOOLTIP_DESCRIPTION"],
  [Metrics.hitRatePercent, "HIT_RATE_PERCENTAGE_TOOLTIP_DESCRIPTION"],
  [Metrics.fourZeroThreePerSec, "FOURZEROTHREEPERSEC_TOOLTIP_DESCRIPTION"],
  [Metrics.fourZeroSixPerSec, "FOURZEROSIXPERSEC_TOOLTIP_DESCRIPTION"],
  [Metrics.ptperSec, "PTPERSEC_TOOLTIP_DESCRIPTION"],
  [Metrics.authPercent, "AUTHPERCENT_TOOLTIP_DESCRIPTION"],
  [Metrics.signedPercent, "SIGNEDPERCENT_TOOLTIP_DESCRIPTION"],
  [NonSiteMetrics.totalProperties, "TOTALPROPERTIES_TOOLTIP_DESCRIPTION"],
  [NonSiteMetrics.totalPopLocations, "TOTALPOPLOCATIONS_TOOLTIP_DESCRIPTION"],
]);

export const SingleNumberWidget: FC<{
  metric: Metrics | NonSiteMetrics;
  dataTestId?: string;
}> = ({ metric, dataTestId }) => {
  const properties = useProperties();
  const currentAccessGroupData = useCurrentAccessGroupData();
  const { t } = useTranslation("rtmReportPage");
  const contentName = MetricToHelpMessageMap.get(metric);
  const displayValue = (m: Metrics | NonSiteMetrics): number | undefined => {
    switch (m) {
      case NonSiteMetrics.totalProperties:
        return properties?.length;
      case NonSiteMetrics.totalPopLocations:
        return 0;
      default:
        return currentAccessGroupData && currentAccessGroupData[m];
    }
  };
  return (
    <SingleNumber
      title={MapSiteFieldsToLabel.get(metric) || "No title"}
      value={displayValue(metric)}
      helpMessage={contentName && t(contentName)}
      dataTestId={dataTestId && dataTestId}
    />
  );
};
