import { FC } from "react";
import styled from "@emotion/styled";
import { Icon, Icons } from "../Icon/Icon";

export interface TagProps {
  text: string;
  onDelete: () => void;
  Container?: FC;
  Text?: FC;
  dataTestId?: string;
}

export const Tag: React.FC<TagProps> = ({
  text,
  onDelete,
  Container = TagConainer,
  Text = TagText,
  dataTestId,
}) => {
  return (
    <Container>
      <Text>{text}</Text>
      <Cross
        size={12}
        name={Icons.CROSS}
        onClick={onDelete}
        dataTestId={dataTestId}
      />
    </Container>
  );
};

const TagConainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.backgrounds.mutedLight};
  color: ${({ theme }) => theme.text.primary};
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 3px;
`;

const TagText = styled.span``;

const Cross = styled(Icon)`
  margin-left: 3.5px;
  filter: brightness(1.3);
`;
