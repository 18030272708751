import { ReactElement } from "react";
import { Route } from "react-router-dom";
import { Users } from ".";
import { UserManagementUser } from "../../../../components/layers/UserManagementUser/UserManagementUser";
import { UserManagementUsersLayer } from "../../../../components/layers/UserManagementUsers/UserManagementUsers";
import { UserPage } from "./user";

export const UsersRouter = (): ReactElement => {
  return (
    <UserManagementUsersLayer>
      <Route exact path="/userManagement/users">
        <Users />
      </Route>
      <Route path="/userManagement/users/:userId">
        <UserManagementUser>
          <UserPage />
        </UserManagementUser>
      </Route>
    </UserManagementUsersLayer>
  );
};
