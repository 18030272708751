import { useRef, useState } from "react";
import styled from "@emotion/styled";
import { config, useTransition } from "react-spring";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import { SearchBar } from "../../../../../components/molecules/SearchBar/SearchBar";
import { UserManagementUserData } from "../../../../../models/user-management/users/users.interfaces";
import { useUsers } from "../../../../../store/slices/user-management-users/hooks";
import { DropdownMenu } from "../../../../../components/atoms/Dropdown/DropdownMenu";
import { useClickOutsideRef } from "../../../../../hooks/use-detect-click-outside-ref";
import {
  AddUserData,
  AddUserDataWithInternal,
  isUserInternal,
  UserType,
  validateEmail,
} from "./helpers";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import {
  UserDisplayContainer,
  UserDropdownOption,
  UserOption,
} from "./UserSearchResult";
import { searchInternalUsers } from "../../../../../models/user-management/access-group";
import { useAppDispatch } from "../../../../../store/types";
import { handleAPIError } from "../../../../../store/slices/api-error/thunks";
import { APIError } from "../../../../../models/error";

const handleFilterExternalUsers = (search: string, userType: UserType) => (
  user: UserManagementUserData
) => {
  if (search === "") {
    return true;
  }
  const testInternal =
    isUserInternal(user) === (userType === UserType.INTERNAL);

  const userFullName = `${user.firstName} ${user.lastName}`.toLowerCase();
  return (
    (!!userFullName.toLowerCase().match(search.toLowerCase()) ||
      !!user.email.toLowerCase().match(search.toLowerCase())) &&
    testInternal
  );
};

export interface UserSearchBarProps {
  onSelect: (selection: AddUserDataWithInternal) => void;
  userType: UserType;
}

export const UserSearchBar = ({
  onSelect,
  userType,
}: UserSearchBarProps): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  const users = useUsers();

  const [isOpen, setIsOpen] = useState(false);

  const [usersToDisplay, setUsersToDisplay] = useState<AddUserData[]>([]);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useClickOutsideRef(dropdownRef, () => {
    setIsOpen(false);
  });

  const transitions = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0rem" },
    enter: { opacity: 1, height: "3rem" },
    leave: { opacity: 0, height: "0rem" },
  });

  const [search, setSearch] = useState("");

  const dispatch = useAppDispatch();

  const handleSearchInternalUser = async (value: string) => {
    if (value.length === 0) {
      setUsersToDisplay([]);
      setIsOpen(false);
      return;
    }
    try {
      const foundUsers = await searchInternalUsers(value);
      setUsersToDisplay(
        foundUsers.map((user) => ({
          lastName: user.lastName,
          firstName: user.firstName,
          description: user.title,
          userId: user.id,
        }))
      );
      setIsOpen(true);
    } catch (err) {
      dispatch(handleAPIError(err as APIError));
    }
  };

  const handleSearchExternalUser = (value: string) => {
    if (value.length === 0) {
      setUsersToDisplay([]);
      setIsOpen(false);
      return;
    }
    const newUsers = users
      .filter(handleFilterExternalUsers(value, userType))
      .slice(0, 10);
    if (newUsers.length === 0) {
      setUsersToDisplay([]);
    }
    setUsersToDisplay(
      newUsers.map((user) => ({
        lastName: user.lastName,
        firstName: user.firstName,
        description: user.email,
        userId: user.userId.toString(),
      }))
    );
    setIsOpen(true);
  };

  const debouncedHandler = debounce((value) => {
    setSearch(value);
    if (userType === UserType.EXTERNAL) {
      handleSearchExternalUser(value);
    } else {
      handleSearchInternalUser(value);
    }
  }, 1000);

  return (
    <div style={{ width: "100%" }} className="chi-dropdown" ref={dropdownRef}>
      <SearchBar
        placeholder={t("ACCESS_GROUP_ADD_USERS_MODAL_SEARCH_PLACEHOLDER")}
        onChange={(value) => debouncedHandler(value)}
      />
      {isOpen && (
        <DropdownMenu
          style={{
            boxShadow: "0px 8px 16px rgba(36, 37, 38, 0.12)",
          }}
          className="chi-dropdown__menu"
        >
          <OptionsContainer>
            {transitions(
              (_styles, _show) =>
                _show &&
                usersToDisplay.map((user, index) => (
                  <UserOption
                    key={user.userId}
                    styles={_styles}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    userPosition={user.description}
                    index={index}
                    onClick={() => {
                      onSelect({
                        ...user,
                        internal: userType === UserType.INTERNAL,
                      });
                      setIsOpen(false);
                    }}
                  />
                ))
            )}
            {userType === UserType.EXTERNAL &&
              (validateEmail(search) && usersToDisplay.length === 0 ? (
                <UserDropdownOption
                  index={0}
                  onClick={() => {
                    onSelect({
                      firstName: "",
                      lastName: "",
                      description: search,
                      userId: search,
                      internal: false,
                    });
                    setIsOpen(false);
                  }}
                >
                  <AddExternalUserText>
                    {t("ACCESS_GROUP_ADD_USERS_MODAL_ADD_EXTERNAL_EMAIL", {
                      email: search,
                    })}
                  </AddExternalUserText>
                </UserDropdownOption>
              ) : (
                <UserDropdownOption index={0}>
                  <UserDisplayContainer>
                    <Icon name={Icons.INFO_OUTLINE} />
                    <InviteExternalUserText>
                      {t("ACCESS_GROUP_ADD_USERS_MODAL_NO_USERS_FOUND")}
                    </InviteExternalUserText>
                  </UserDisplayContainer>
                </UserDropdownOption>
              ))}
          </OptionsContainer>
        </DropdownMenu>
      )}
    </div>
  );
};

const InviteExternalUserText = styled.div`
  color: ${({ theme }) => theme.text.highlight};
`;

const AddExternalUserText = styled(InviteExternalUserText)`
  font-weight: 600;
`;

const OptionsContainer = styled.div`
  overflow-y: scroll;
  max-height: 250px;
`;
