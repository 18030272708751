import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { animated, config, useSpring } from "react-spring";

import { ThemeContext } from "../../../contexts/themeContext";
import { SubwayStop } from "../../atoms/SubwayStop/SubwayStop";

export interface SubwayProps {
  componentList: JSX.Element[];
  activeIndex?: number;
}

export const Subway = ({
  componentList,
  activeIndex,
}: SubwayProps): ReactElement => {
  const topContainerRef = useRef<HTMLDivElement>(null);
  const { colorset } = useContext(ThemeContext);

  const [lineHeight, setLineHeight] = useState("0px");
  const [colorFirstDot, setColorFirstDot] = useState(
    colorset.borders.mutedLight
  );
  const animatedLine = useSpring({
    config: { ...config.molasses, friction: 60 },
    height: lineHeight,
    delay: 250,
    onRest: (animationResult) =>
      animationResult.finished && setColorFirstDot(colorset.text.highlight),
  });

  const appearsAnimation = useSpring({
    opacity: componentList.length ? 1 : 0,
  });

  useEffect(() => {
    if (topContainerRef && topContainerRef.current) {
      setLineHeight(topContainerRef.current.clientHeight - 10 + "px");
    }
  }, [componentList]);

  useEffect(() => {
    if (lineHeight !== "0px") {
      setColorFirstDot(colorset.text.highlight);
    }
  }, [colorset]);

  useEffect(() => {
    if (activeIndex !== undefined) {
      document
        .getElementById(`subway-item-${activeIndex}`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [activeIndex]);

  return (
    <SubwayContainer ref={topContainerRef} style={appearsAnimation}>
      <Line style={animatedLine} />
      {componentList.map((item, i) => (
        <SubwayRow key={i} id={`subway-item-${i}`}>
          <SubwayStop
            bgColor={
              activeIndex !== undefined && i === activeIndex
                ? colorFirstDot
                : colorset.borders.mutedLight
            }
          />
          <ComponentContainer>{item}</ComponentContainer>
        </SubwayRow>
      ))}
    </SubwayContainer>
  );
};

const SubwayContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SubwayRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 5px 0px;
`;

const ComponentContainer = styled.div`
  width: 100%;
`;

const Line = styled(animated.div)`
  position: absolute;
  width: 2px;
  background-color: #e3e3e3;
  z-index: 2;
  left: 23px;
  bottom: 5px;
`;
