import { FC, useContext } from "react";
import styled from "@emotion/styled";

import { Theme, ThemeContext } from "../../../contexts/themeContext";
import { Loader } from "../Loader/Loader";

export const LoaderWithBackground: FC = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === Theme.DARK_MODE;

  return (
    <LoaderContainer isDarkMode={isDarkMode}>
      <Loader />
      <Description isDarkMode={isDarkMode}>{children}</Description>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${({ theme, isDarkMode }) =>
    isDarkMode ? theme.backgrounds.mutedDark : theme.backgrounds.baseLight};
`;

const Description = styled.p<{ isDarkMode: boolean }>`
  color: ${({ theme }) => theme.text.primary};
`;
