import { IFeatures } from "../../../models/configuration/definitions";
import { VALID_STRINGS } from "../../../modules/caching/configurations/configuration_page/match_rules/match_rules_page/features/list/feature_block/NegativeTTLFeatureBlock";
import { isString } from "../../../utils/string";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type NegativeTTLComponent = FeatureComponent<
  Exclude<IFeatures["negativeTTL"], undefined>
>;

export const NegativeTTL: NegativeTTLComponent = ({
  feature: { cachePolicy },
  tPropertyPage,
  dataTestId,
}) => {
  const isTTL = !isString(cachePolicy, VALID_STRINGS);

  return (
    <DetailedFeatureCardRow>
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_NEGATIVE_TTL_TITLE")}
        dataTestId={`${dataTestId}-type-title`}
      >
        {isTTL ? "Int" : cachePolicy}
      </DetailedFeatureCardBlock>

      {isTTL && (
        <DetailedFeatureCardBlock
          title={tPropertyPage("FEATURE_CARD_NEGATIVE_TTL_NUMBER_TITLE")}
          dataTestId={`${dataTestId}-ttl-title`}
        >
          {cachePolicy}
        </DetailedFeatureCardBlock>
      )}
    </DetailedFeatureCardRow>
  );
};
