import { ReactElement } from "react";
import styled from "@emotion/styled";

import {
  useVersionAction,
  UseVersionActionParams,
} from "../../../hooks/user-version-action/useVersionAction";
import { Icon, Icons } from "../../atoms/Icon/Icon";

interface ViewVersionButtonProps extends UseVersionActionParams {
  onClick: () => void;
}

export const ViewVersionButton = ({
  onClick,
  ...params
}: ViewVersionButtonProps): ReactElement => {
  useVersionAction(params);

  return <ViewIcon name={Icons.EYE} color="secondary" onClick={onClick} />;
};

const ViewIcon = styled(Icon)`
  margin-left: 16px;
  cursor: pointer;
`;
