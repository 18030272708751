import { AxiosError } from "axios";
import { httpClient } from "../../../core/http-client";

import { ErrorLevel, APIError, MediaPortalAPIError } from "../../error";
import {
  AddUserAccessGroupsData,
  UpdateVyvxData,
  UpdateUserInfoData,
  UserManagementUserData,
} from "./users.interfaces";

export const getUsers = async (): Promise<UserManagementUserData[]> => {
  try {
    const { data } = await httpClient.get<UserManagementUserData[]>("/users");
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getUsers()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getUser = async (
  userId: number
): Promise<UserManagementUserData> => {
  try {
    const { data } = await httpClient.get<UserManagementUserData>(
      `/users/${userId}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getUser()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const updateUserInfo = async (
  userId: number,
  data: UpdateUserInfoData
): Promise<void> => {
  try {
    await httpClient.put<UpdateUserInfoData>(`/users/${userId}`, data);
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateUserInfo()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const updateUserVyvxData = async (
  userId: number,
  data: UpdateVyvxData
): Promise<void> => {
  try {
    await httpClient.put(`/users/${userId}/vyvx`, data);
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateUserVyvxData()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const addUserToAccessGroups = async (
  userId: number,
  accessGroupsToAdd: number[]
): Promise<void> => {
  try {
    await httpClient.post<AddUserAccessGroupsData>(
      `/users/${userId}/accessGroups/add`,
      {
        accessGroups: accessGroupsToAdd.map((id) => ({
          id,
          // We put 30 by default which is reporting
          roleId: 30,
        })),
        mailMessage: "",
      }
    );
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "addUserToAccessGroups()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const removeUserFromAccessGroups = async (
  userId: number,
  accessGroups: number[]
): Promise<void> => {
  try {
    await httpClient.post<{ accessGroups: number[] }>(
      `/users/${userId}/accessGroups/delete`,
      {
        accessGroups,
      }
    );
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "removeUserFromAccessGroups()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const resetUserPassword = async (userId: number): Promise<void> => {
  try {
    await httpClient.post<{ userId: number }>("/auth/resetPassword", {
      userId,
    });
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "resetUserPassword()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
