import { ReactElement, useEffect } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  PropertySection,
  PropertySubsection,
} from "../../../../../../../../models/configuration/definitions/property";
import {
  ConfigurationErrorLevel,
  ConfigurationErrorType,
} from "../../../../../../../../models/configuration/errors";
import {
  handleAddConfigurationError,
  handleRemoveConfigurationError,
} from "../../../../../../../../store/slices/caching/thunks";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../../../store/types";
import { PrimaryProtocolSettings } from "./PrimaryProtocolSettings";

interface AliasesSectionProps {
  property: PropertyDefinitionType;
  onPropertyChange: (property: PropertyDefinitionType) => void;
}

export const AliasesSection = ({
  property,
  onPropertyChange,
}: AliasesSectionProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (property.protocolSettings.protocols.length === 0) {
      dispatch(
        handleAddConfigurationError(
          ConfigurationErrorLevel.ERROR,
          ConfigurationErrorType.PROPERTY_PROTOCOL_EMPTY,
          {
            section: PropertySection.ALIASES,
            subsection: PropertySubsection.PROTOCOL_SETTINGS,
          }
        )
      );
    } else {
      dispatch(
        handleRemoveConfigurationError(
          (err) =>
            !(
              err.type === ConfigurationErrorType.PROPERTY_PROTOCOL_EMPTY &&
              err.data?.section === PropertySection.ALIASES &&
              err.data?.subsection === PropertySubsection.PROTOCOL_SETTINGS
            )
        )
      );
    }
  }, [property.protocolSettings.protocols.length]);

  return (
    <AliasesSectionContainer>
      <Subsection>{t("PROPERTY_CARD_ALIASES_TITLE")}</Subsection>
      <PrimaryProtocolSettings
        key="primary"
        property={property}
        onPropertyChange={onPropertyChange}
      />
    </AliasesSectionContainer>
  );
};

const AliasesSectionContainer = styled.div``;

const Subsection = styled.h4``;
