import { v4 } from "uuid";
import { ConfigurationResponseData, ConfigurationType } from "../types";

export const parseConfiguration = (
  configuration: ConfigurationResponseData
): ConfigurationType => {
  const parsedConfig: ConfigurationType = {
    comment: configuration.comment,
    configName: configuration.configName,
    createdTime: configuration.createdTime,
    errors: [],
    id: v4(),
    isLocalDraft: false,
    modifiedTime: configuration.modifiedTime,
    schemaVersion: configuration.schemaVersion || "3.0",
    subscriberId: configuration.subscriberId,
    transactionId: configuration.transactionId,
    unhandledFields: [],
    versionId: configuration.versionId,
    versions: [],
  };

  Object.entries(configuration).map(([key, value]) => {
    if (!Array.from(Object.keys(parsedConfig)).find((item) => item === key)) {
      parsedConfig.unhandledFields.push([key, value]);
    }
  });

  return parsedConfig;
};
