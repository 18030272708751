import { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { SubscriberSettings } from "./SubscriberSettings";

export const SubscriberSettingsRouter: FC = () => {
  return (
    <Switch>
      <Route
        path={"/cmv3/subscriber_settings"}
        component={SubscriberSettings}
      />
    </Switch>
  );
};
