import { ButtonHTMLAttributes, InputHTMLAttributes } from "react";
import type {
  FieldValues,
  RegisterOptions,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import { IDropdownItem } from "../../../../components/organisms/Dropdown/Dropdown";

export enum ApiKeyActions {
  SECRET = "Secret",
  DISABLE = "Disable",
  ENABLE = "Enable",
  DELETE = "Delete",
}

export enum ApiKeysStatus {
  ALL = "All",
  ACTIVE = "Active",
  DISABLED = "Disabled",
}

export enum ApiKeysOrder {
  OLDEST = "Date (Oldest)",
  RECENT = "Date (Recent)",
}

export type FilteredApiKeysData = {
  id: string;
  name: string;
  createdDate: string;
  createdBy: string;
  role: string;
  contact: string;
  status: string;
  accessGroup: string | null;
  secret: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  notes: string;
  accessGroupId: string | null;
  accessGroupName: string | null;
  vyvxCompanyAbbr: string | null;
  vyvxRoleId: number | null;
  termsWarning: boolean;
  termsError: boolean;
};

export enum ApiKeyFieldType {
  InputFieldType,
  DropdownType,
  SecretType,
  TextArea,
  CustomerDropdown,
}

export interface ApiKeyFieldProps {
  placeholder?: string;
  label?: string;
  legend?: string;
  inline?: boolean;
  value?: any;
  hoverMessage?: string;
  name?: string;
  disabled?: boolean;
  inputDisabled?: boolean;
  items?: IDropdownItem<any>[];
  id?: string;
  other?: JSX.Element;
  validation?: RegisterOptions;
  inputType?: InputHTMLAttributes<any>["type"];
  dropdownType?: ButtonHTMLAttributes<any>["type"];
  min?: number;
  outline?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onClick?: (value: any) => void;
  onSelect?: (value: any) => void;
  onDelete?: () => void;
  register?: UseFormRegister<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

export interface ApiKeysCustomerFilter {
  customerName: string;
  customerId: string;
}
