import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { isDefinitionNameValid } from "../../../utils/string";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  AddButton,
  Field,
  FieldInput,
  FieldName,
  Remove,
  Table,
  TableBody,
  TableCell,
} from "../DefinitionModal/DefinitionModal.styled";
import { getDuplicateElements } from "../DefinitionModal/helpers";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { TokenDefinitionType } from "../../../store/slices/caching/types";
import { createTokenDefinition } from "../../../store/slices/caching/helpers/token-definition/createTokenDefinition";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";
import { getTokenReferences } from "../../../store/slices/caching/helpers/token-definition/getTokenReferences";

type TokenModalProps = IDefaultDefinitionModalProps<TokenDefinitionType>;

export const TokenModal = ({
  definition,
  parentConfig,
  onSubmit,
}: TokenModalProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();

  const { t } = useTranslation("configurationDefinitionsPage");

  const [name, setName] = useState(definition?.name ?? "");
  const [description, setDescription] = useState(
    definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
  );
  const [datePref, setDatePref] = useState<
    TokenDefinitionType["datePreference"]
  >(definition?.datePreference || "epoch");
  const [hash, setHash] = useState<string>(definition?.hash || "");
  const [nva, setNva] = useState<string>(definition?.nva || "");
  const [nvb, setNvb] = useState<string>(definition?.nvb || "");
  const [sharedSecrets, setSharedSecrets] = useState<string[]>(
    definition?.sharedSecrets || [""]
  );
  const [queryParameterControl, setQueryParameterControl] = useState<
    TokenDefinitionType["queryParameterControl"]
  >(definition?.queryParameterControl || "exclude");
  const [queryParameterNames, setqueryParameterNames] = useState<string[]>(
    definition?.queryParameterNames || []
  );

  const [error, setError] = useState<string>("");

  const addSharedSecret = () => {
    setSharedSecrets([...sharedSecrets, ""]);
  };

  const removeSharedSecret = (index: number) => {
    setSharedSecrets((_sharedSecrets) =>
      _sharedSecrets.filter((_, i) => i !== index)
    );
  };

  const addqueryParameterNames = () => {
    setqueryParameterNames([...queryParameterNames, ""]);
  };

  const removequeryParameterNames = (index: number) => {
    setqueryParameterNames((_queryParameterNames) =>
      _queryParameterNames.filter((_, i) => i !== index)
    );
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.TOKEN]
      )
    ) {
      setError(t("ERROR_DEFINITION_TOKEN_ALREADY_EXISTS", { name }));
      return false;
    }

    if (!(datePref.length > 0)) {
      setError(t("ERROR_DEFINITION_TOKEN_DATE_PREFERENCE_NOT_EXISTS"));
      return false;
    }

    if (!(queryParameterControl.length > 0)) {
      setError(t("ERROR_DEFINITION_TOKEN_QUERY_PARAMETER_CONTROL_NOT_EXISTS"));
      return false;
    }

    if (getDuplicateElements(queryParameterNames).length > 0) {
      setError(t("ERROR_DEFINITION_DUPLICATE_TOKEN_QUERY_PARAMETERS_NAMES"));
      return false;
    }

    if (
      !(sharedSecrets.length > 0) ||
      !sharedSecrets.every((sharedSecret) => sharedSecret.length > 0)
    ) {
      setError(t("ERROR_DEFINITION_TOKEN_SHARED_SECRET_NOT_EXISTS"));
      return false;
    }

    if (sharedSecrets.length > 10) {
      setError(t("ERROR_DEFINITION_TOKEN_SHARED_SECRET_TOO_MUCH"));
      return false;
    }

    if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    return true;
  };

  const handleSubmit = (tokenDefintion: TokenDefinitionType) => {
    if (!tokenDefintion.hash) {
      delete tokenDefintion.hash;
    }
    if (!tokenDefintion.nva) {
      delete tokenDefintion.nva;
    }
    if (!tokenDefintion.nvb) {
      delete tokenDefintion.nvb;
    }
    if (
      tokenDefintion.queryParameterNames &&
      tokenDefintion.queryParameterNames.length < 1
    ) {
      delete tokenDefintion.queryParameterNames;
    }
    onSubmit(tokenDefintion);
  };

  const initState = () => {
    setError("");
    setName(definition?.name ?? "");
    setDescription(
      definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
    );
    setHash(definition?.hash || "");
    setNva(definition?.nva || "");
    setNvb(definition?.nvb || "");
    setQueryParameterControl(definition?.queryParameterControl || "exclude");
    setSharedSecrets(definition?.sharedSecrets || [""]);
    setqueryParameterNames(definition?.queryParameterNames || []);
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="tokendefinition-name"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
          dataTestId="token-modal-header"
        />
      }
      references={definition ? getTokenReferences(definition, parentConfig) : 0}
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        setError("");
        if (definition) {
          handleSubmit({
            ...definition,
            name,
            _schemaDescription: description,
            datePreference: datePref,
            hash,
            nva,
            nvb,
            sharedSecrets,
            queryParameterControl,
            queryParameterNames,
          });
        } else {
          if (selectedConfiguration && selectedConfiguration.config) {
            handleSubmit(
              createTokenDefinition(name, {
                sharedSecrets,
                queryParameterNames,
                hash,
                nva,
                nvb,
                datePreference: datePref,
                queryParameterControl,
                description,
              })
            );
          }
        }
        initState();
      }}
      dataTestId="token"
    >
      <Table>
        <TableBody>
          <Field>
            <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>
            <FieldInput
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
              data-testid="token-modal-definition-name-input"
            />
          </Field>
          <Field>
            <FieldName required>
              {t("EDIT_DEFINITION_TOKEN_DATE_PREFERENCE_FIELD_LABEL")}
            </FieldName>
            <TableCell>
              <div className="chi-form__item -inline">
                <div className="chi-radio" onClick={() => setDatePref("gmt")}>
                  <FieldInput
                    type="radio"
                    name="datePref"
                    className="chi-radio__input"
                    checked={datePref === "gmt"}
                    onChange={() => {}}
                    data-testid="token-modal-time-format-gmt-field"
                  />
                  <Label htmlFor="gmt" className="chi-radio__label">
                    {t("EDIT_DEFINITION_TOKEN_DATE_PREFERENCE_FIELD_GMT_LABEL")}
                  </Label>
                </div>
              </div>
              <div className="chi-form__item -inline">
                <div className="chi-radio" onClick={() => setDatePref("epoch")}>
                  <FieldInput
                    type="radio"
                    name="datePref"
                    className="chi-radio__input"
                    checked={datePref === "epoch"}
                    onChange={() => {}}
                    data-testid="token-modal-time-format-epoch-field"
                  />
                  <Label htmlFor="epoch" className="chi-radio__label">
                    {t(
                      "EDIT_DEFINITION_TOKEN_DATE_PREFERENCE_FIELD_EPOCH_LABEL"
                    )}
                  </Label>
                </div>
              </div>
            </TableCell>
          </Field>

          <Field>
            <FieldName>{t("EDIT_DEFINITION_TOKEN_HASH_FIELD_LABEL")}</FieldName>
            <FieldInput
              onChange={(event) => setHash(event.target.value)}
              value={hash}
              placeholder={t("EDIT_DEFINITION_TOKEN_HASH_FIELD_PLACEHOLDER")}
              data-testid="token-modal-hash-field"
            />
          </Field>

          <Field>
            <FieldName>{t("EDIT_DEFINITION_TOKEN_NVA_FIELD_LABEL")}</FieldName>
            <FieldInput
              onChange={(event) => setNva(event.target.value)}
              value={nva}
              placeholder={t("EDIT_DEFINITION_TOKEN_NVA_FIELD_PLACEHOLDER")}
              data-testid="token-modal-nva-field"
            />
          </Field>

          <Field>
            <FieldName>{t("EDIT_DEFINITION_TOKEN_NVB_FIELD_LABEL")}</FieldName>
            <FieldInput
              onChange={(event) => setNvb(event.target.value)}
              value={nvb}
              placeholder={t("EDIT_DEFINITION_TOKEN_NVB_FIELD_PLACEHOLDER")}
              data-testid="token-modal-nvb-field"
            />
          </Field>

          <Field>
            <FieldName required>
              {t("EDIT_DEFINITION_TOKEN_QUERY_PARAMETER_CONTROL_FIELD_LABEL")}
            </FieldName>
            <TableCell>
              <div className="chi-form__item -inline">
                <div
                  className="chi-radio"
                  onClick={() => setQueryParameterControl("exclude")}
                >
                  <FieldInput
                    type="radio"
                    name="queryparamcontrol"
                    value="exclude"
                    className="chi-radio__input"
                    checked={queryParameterControl === "exclude"}
                    onChange={() => {}}
                    data-testid="token-modal-query-parameter-exclude-field"
                  />
                  <Label htmlFor="exclude" className="chi-radio__label">
                    {t(
                      "EDIT_DEFINITION_TOKEN_QUERY_PARAMETER_CONTROL_FIELD_EXCLUDE_LABEL"
                    )}
                  </Label>
                </div>
              </div>
              <div className="chi-form__item -inline">
                <div
                  className="chi-radio"
                  onClick={() => setQueryParameterControl("include")}
                >
                  <FieldInput
                    type="radio"
                    name="queryparamcontrol"
                    value="include"
                    className="chi-radio__input"
                    checked={queryParameterControl === "include"}
                    onChange={() => {}}
                    data-testid="token-modal-query-parameter-include-field"
                  />
                  <Label htmlFor="include" className="chi-radio__label">
                    {t(
                      "EDIT_DEFINITION_TOKEN_QUERY_PARAMETER_CONTROL_FIELD_INCLUDE_LABEL"
                    )}
                  </Label>
                </div>
              </div>
            </TableCell>
          </Field>

          <Field>
            <FieldName required>
              {t("EDIT_DEFINITION_TOKEN_SHARED_SECRETS_FIELD_LABEL")}
            </FieldName>
            <TableCell>
              <Table>
                <TableBody>
                  {sharedSecrets.map((sharedSecret, i) => (
                    <Field key={i}>
                      <TableCell bottomPadding="thin">
                        <FieldInput
                          value={sharedSecret}
                          placeholder={t(
                            "EDIT_DEFINITION_TOKEN_SHARED_SECRETS_FIELD_PLACEHOLDER"
                          )}
                          onChange={(event) => {
                            setSharedSecrets((_sharedSecrets) =>
                              _sharedSecrets.map((_sharedSecret, index) =>
                                i === index ? event.target.value : _sharedSecret
                              )
                            );
                          }}
                          data-testid={`token-shared-secrets-field-${i}`}
                        />
                      </TableCell>
                      <TableCell
                        centered
                        bottomPadding="thin"
                        data-testid={`token-shared-secrets-delete-${i}`}
                      >
                        <Remove onClick={() => removeSharedSecret(i)} />
                      </TableCell>
                    </Field>
                  ))}
                </TableBody>
              </Table>
              <AddButton
                onClick={addSharedSecret}
                data-testid="token-add-shared-secrets-button"
              >
                {t("ADD_SHARED_SECRET_BUTTON")}
              </AddButton>
            </TableCell>
          </Field>

          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_TOKEN_QUERY_PARAMETERS_NAMES_FIELD_LABEL")}
            </FieldName>
            <TableCell>
              <Table>
                <TableBody>
                  {queryParameterNames.map((parameterName, i) => (
                    <Field key={i}>
                      <TableCell bottomPadding="thin">
                        <FieldInput
                          value={parameterName}
                          placeholder={t(
                            "EDIT_DEFINITION_TOKEN_QUERY_PARAMETERS_NAMES_FIELD_PLACEHOLDER"
                          )}
                          onChange={(event) => {
                            setqueryParameterNames((_queryParameterNames) =>
                              _queryParameterNames.map(
                                (_queryParameterName, index) =>
                                  i === index
                                    ? event.target.value
                                    : _queryParameterName
                              )
                            );
                          }}
                          data-testid={`token-query-parameters-name-field-${i}`}
                        />
                      </TableCell>
                      <TableCell centered bottomPadding="thin">
                        <Remove onClick={() => removequeryParameterNames(i)} />
                      </TableCell>
                    </Field>
                  ))}
                  <AddButton
                    onClick={addqueryParameterNames}
                    data-testid="token-add-query-parameters-name-button"
                  >
                    {t("ADD_QUERY_PARAMETER_BUTTON")}
                  </AddButton>
                </TableBody>
              </Table>
            </TableCell>
          </Field>
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_TOKEN_DESCRIPTION_FIELD_LABEL")}
            </FieldName>
            <FieldInput
              placeholder={t(
                "EDIT_DEFINITION_TOKEN_DESCRIPTION_FIELD_PLACEHOLDER"
              )}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              data-testid="token-modal-description-field"
            />
          </Field>
        </TableBody>
      </Table>
      {error && (
        <NotificationCard
          dataTestId="error-token-modal"
          theme="error"
          title={error}
        />
      )}
    </DefinitionModal>
  );
};

const Label = styled.label``;
