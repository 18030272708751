import { ReactElement, useState } from "react";
import styled from "@emotion/styled";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Protected } from "../../../../../../../../../../components/atoms/Protected/Protected";
import { DeleteModal } from "../../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import { IVariableValueList } from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../../../../models/permissions";
import { createLuaDefinition } from "../../../../../../../../../../store/slices/caching/helpers/lua-definition/createLuaDefinition";
import {
  ConfigurationDetailsType,
  LuaDefinitionType,
} from "../../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../../store/slices/permissions/hooks";
import { IValidationErrors } from "../../FeaturesList";
import { FeatureBlock } from "../FeatureBlock";
import { LuaItem, LuaItemProps } from "./LuaItem";

interface RequestLuaFeatureBlockProps
  extends Pick<LuaItemProps, "handleCreateFeature"> {
  reqLua: Exclude<IFeatures["reqLua"], undefined>;
  config: ConfigurationDetailsType;
  maxWidth: number;
  initIsOpen: boolean;
  errors: IValidationErrors;
  datatestId: string;
  onDelete: () => void;
  register: UseFormRegister<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
}

export const RequestLuaFeatureBlock = ({
  reqLua,
  config,
  maxWidth,
  initIsOpen,
  errors,
  datatestId,
  onDelete,
  handleCreateFeature,
  setError,
  clearErrors,
}: RequestLuaFeatureBlockProps): ReactElement => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  const [addedDefinitions, setAddedDefinitions] = useState(() => {
    const luaDefinitions = reqLua.nameList.reduce((_luaDefinitions, name) => {
      const def = config.luaDefinitions.find((luaDef) => luaDef.name === name);

      if (def) {
        _luaDefinitions.push(def);
      }

      return _luaDefinitions;
    }, [] as LuaDefinitionType[]);

    if (luaDefinitions.length < 1) {
      luaDefinitions.push(createLuaDefinition("", { script: "" }));
    }

    return luaDefinitions;
  });

  const [showAddButton, setShowAddButton] = useState<boolean>(true);

  const addDefinition = () => {
    setAddedDefinitions((_addedDefinitions) => [
      ..._addedDefinitions,
      createLuaDefinition("", { script: "" }),
    ]);

    handleCreateFeature(
      `reqLua.nameList[${reqLua.nameList.length}]` as Parameters<
        typeof handleCreateFeature
      >[0],
      ""
    );
  };

  const updateDefinition = (
    definition: LuaDefinitionType | undefined,
    index: number
  ) => {
    setAddedDefinitions((_addedDefinitions) =>
      _addedDefinitions.reduce((defs, def, i) => {
        if (i === index) {
          if (!definition) {
            return defs;
          }

          defs.push(definition);
        } else {
          defs.push(def);
        }

        return defs;
      }, [] as typeof addedDefinitions)
    );

    if (definition) {
      handleCreateFeature(
        `reqLua.nameList[${index}]` as Parameters<
          typeof handleCreateFeature
        >[0],
        definition.name
      );
    }

    if (definition?.variables) {
      let variableValueList: IVariableValueList[];
      const variableValues = definition.variables.map(({ name, type }) => ({
        name,
        value: type === "string" ? "" : type === "number" ? 0 : true,
      }));

      if (!reqLua.variableValueList) {
        variableValueList = [{ luaName: definition.name, variableValues }];
      } else if (
        reqLua.variableValueList.filter((v) => v.luaName === definition.name)
          .length === 0
      ) {
        variableValueList = [
          ...reqLua.variableValueList,
          { luaName: definition.name, variableValues },
        ];
      } else {
        variableValueList = reqLua.variableValueList;
      }

      handleCreateFeature("reqLua.variableValueList", variableValueList);
    }
  };

  const removeDefinition = (index: number) => {
    if (addedDefinitions.length > 1) {
      const _addedDefinitions = addedDefinitions.filter((_, i) => i !== index);
      setAddedDefinitions(_addedDefinitions);

      const nameList = reqLua.nameList.filter((_, i) => i !== index);

      handleCreateFeature("reqLua.nameList", nameList);

      if (reqLua.variableValueList) {
        const addedDefinitionNames = _addedDefinitions.map((def) => def.name);
        const variableValueList = reqLua.variableValueList.filter((def) =>
          addedDefinitionNames.includes(def.luaName)
        );
        handleCreateFeature("reqLua.variableValueList", variableValueList);
      }

      clearErrors(
        Object.keys(errors).filter((key) => key.startsWith(`reqLua-${index}`))
      );
    }
  };

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_REQUEST_LUA_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_REQUEST_LUA_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_REQUEST_LUA_DESCRIPTION_CONTENT"
      )}
      fields={
        <>
          {addedDefinitions.map((def, i) => (
            <LuaItem
              disabled={isViewMode}
              rowIndex={i}
              key={i}
              feature={reqLua}
              luaType={"reqLua"}
              config={config}
              maxWidth={maxWidth}
              currentDefinition={def.name !== "" ? def : undefined}
              availableDefinitions={config.luaDefinitions}
              addedDefinitions={addedDefinitions}
              handleCreateFeature={handleCreateFeature}
              updateDefinition={updateDefinition}
              removeDefinition={
                addedDefinitions.length > 1 ? removeDefinition : undefined
              }
              setShowAddButton={setShowAddButton}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
            />
          ))}
          <Protected permissions={UserRoles.EDIT_CONFIG}>
            {(addedDefinitions.length === 0 || showAddButton) && (
              <AddButton
                className="chi-button -primary -flat"
                onClick={addDefinition}
                type="button"
                data-testid="add-req-lua-definition-button"
              >
                <PlusSign>+</PlusSign>
                <div>{t("FEATURE_CARD_REQUEST_LUA_ADD_BUTTON")}</div>
              </AddButton>
            )}
          </Protected>
        </>
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"reqLua"}
          onDelete={() => {
            onDelete();
            clearErrors(
              Object.keys(errors).filter((key) => key.startsWith("reqLua"))
            );
          }}
          dataTestId={datatestId}
        />
      }
      dataTestId="req-lua"
      featureType={FeatureTypes.LUA}
    />
  );
};

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  border: none !important;
  cursor: pointer;
  font-size: 14px !important;

  margin: 32px 0px 0px 0px !important;
  padding: 0px 4px 0px 0px !important;

  :hover {
    opacity: 0.5;
  }
`;

export const PlusSign = styled.div`
  font-size: 30px;
  font-weight: 300;
  padding: 0px 4px 4px 0px;
`;
