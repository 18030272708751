import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";

export interface OnScrollEventDetails {
  scrollTop: number;
  offsetTop: number;
}

export const useScrollLayout = (id: string) => {
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const nodeRef = useRef<HTMLDivElement>();

  const measuredRef = (node: HTMLDivElement) => {
    if (node !== null) {
      nodeRef.current = node;
      setContainerHeight(window.innerHeight - node.offsetTop);
    }
  };

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const event = new CustomEvent<OnScrollEventDetails>(`custom-scroll-${id}`, {
      detail: {
        scrollTop: e.currentTarget.scrollTop,
        offsetTop: e.currentTarget.offsetTop,
      },
    });
    document.dispatchEvent(event);
  };

  const handleResize = useCallback(() => {
    if (nodeRef.current) {
      setContainerHeight(window.innerHeight - nodeRef.current.offsetTop);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { measuredRef, containerHeight, onScroll };
};

export const useScrollLayoutListener = (
  id: string,
  callback: (ev: CustomEvent<OnScrollEventDetails>) => void
): void => {
  useLayoutEffect(() => {
    document.addEventListener(`custom-scroll-${id}`, callback as EventListener);
    return () => {
      document.removeEventListener(
        `custom-scroll-${id}`,
        callback as EventListener
      );
    };
  });
};

export const ScrollContainer = styled.div<{ containerHeight: number }>`
  height: ${({ containerHeight }) => containerHeight}px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
