import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoaderWithBackground } from "../../components/atoms/LoaderWithBackground/LoaderWithBackground";
import { APIError } from "../../models/error";
import {
  CreateInvalidation,
  getInvalidations,
  Invalidation,
  submitInvalidation,
} from "../../models/invalidation";
import { useDispatchAPIError } from "../../store/slices/api-error/hooks";
import { useAccessGroupId } from "../../store/slices/access-groups/hooks";
import { ContentInvalidation } from "./components/templates/content-invalidation/ContentInvalidation";

export const ContentInvalidationPage = () => {
  const [t] = useTranslation("contentInvalidationPage");
  const [invalidations, setInvalidations] = useState<Invalidation[] | null>(
    null
  );
  const accessGroupId = useAccessGroupId();
  const handleMediaPortalError = useDispatchAPIError();
  const [intervalTimeout, setIntervalTimeout] = useState<NodeJS.Timeout>();

  const createInvalidation = async (invalidation: CreateInvalidation) => {
    try {
      await submitInvalidation(accessGroupId!, invalidation);
    } catch (err) {
      const error = err as APIError;
      handleMediaPortalError(error);
    }
  };

  const fetchInvalidations = useCallback(async () => {
    try {
      const data = await getInvalidations(accessGroupId!);
      setInvalidations(data);
    } catch (err) {
      setInvalidations([]);
      const error = err as APIError;
      handleMediaPortalError(error);
    }
  }, [accessGroupId]);

  useEffect(() => {
    fetchInvalidations();
    if (intervalTimeout) {
      clearInterval(intervalTimeout);
    }

    setIntervalTimeout(setInterval(fetchInvalidations, 60 * 1000));
  }, [fetchInvalidations]);

  return invalidations ? (
    <ContentInvalidation
      invalidations={invalidations}
      onSubmit={async (invalidation) => {
        await createInvalidation(invalidation);
        setInvalidations(null);
        await fetchInvalidations();
      }}
    />
  ) : (
    <LoaderContainer>
      <LoaderWithBackground>{t("RETRIEVING_INFORMATION")}</LoaderWithBackground>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
