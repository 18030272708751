import { OperatorsDictionnary, OPERATORS_DICTIONNARY } from "./dictionnaries";

export const NEGATED_OPERATORS: OperatorsDictionnary = new Map();

export const NON_NEGATED_OPERATORS: OperatorsDictionnary = new Map();

for (const [operator, operatorDetails] of OPERATORS_DICTIONNARY) {
  if (operator.startsWith("negated_")) {
    NEGATED_OPERATORS.set(operator, operatorDetails);
  } else {
    NON_NEGATED_OPERATORS.set(operator, operatorDetails);
  }
}
