import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Card } from "../../../../../components/atoms/Card/Card";
import { Center } from "../../../../../components/atoms/Center/Center";
import { TextGroup } from "../../../../../components/atoms/Text/TextGroup";
import {
  CreateInvalidation,
  Invalidation,
} from "../../../../../models/invalidation";
import { useUserEmail } from "../../../../../store/slices/user/hooks";
import { Information } from "./Information";
import { InvalidationStatus } from "./InvalidationStatus";
import { Modal } from "./Modal";
import { SearchBar } from "./SearchBar";
import { Accordion } from "../../../../../components/molecules/Accordion/Accordion";
import {
  ModuleContent,
  ModuleHeader,
  ModuleTitle,
} from "../../../../../components/atoms/Module/Module";
import { SubHeaderAccessGroup } from "../../../../../components/organisms/SubHeader/SubHeaderAccessGroup/SubHeaderAccessGroup";
import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";

interface ContentInvalidationProps {
  invalidations: Invalidation[];
  onSubmit: (invalidation: CreateInvalidation) => void;
}

export type InvalidationScope = "all" | "current_user";
export type InvalidationStatus =
  | "all"
  | "pending"
  | "in_progress"
  | "completed";
export type SortByOption = "asc" | "desc";

const SCROLL_ID = "content-invalidation";

export const ContentInvalidation = ({
  invalidations,
  onSubmit,
}: ContentInvalidationProps): JSX.Element => {
  const [t] = useTranslation("contentInvalidationPage");
  const [tMisc] = useTranslation("misc");
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<InvalidationStatus>("all");
  const [
    invalidationsScope,
    setInvaldationsScope,
  ] = useState<InvalidationScope>("all");
  const [filtredInvalidations, setFilteredInvalidations] = useState<
    Invalidation[]
  >(invalidations);
  const [sortOrder, setSortOrder] = useState<SortByOption>("asc");

  const userEmail = useUserEmail();

  useEffect(() => {
    const s = search.toLowerCase();
    sortOrder === "asc"
      ? invalidations.sort((a, b) => b.createdAt - a.createdAt)
      : invalidations.sort((a, b) => a.createdAt - b.createdAt);

    setFilteredInvalidations(
      invalidations.filter((invalidation) => {
        const searchCondition =
          invalidation.id.toLowerCase().includes(s) ||
          invalidation.propertyName.toLowerCase().includes(s) ||
          invalidation.paths.join(" ").toLowerCase().includes(s);

        const userCondition =
          invalidationsScope === "all" ||
          invalidation.publisherEmail === userEmail;

        const statusCondition =
          status === "all"
            ? true
            : status === "pending"
            ? invalidation.progress === 0
            : status === "completed"
            ? invalidation.progress >= 100
            : invalidation.progress > 0 && invalidation.progress < 100;

        return searchCondition && userCondition && statusCondition;
      })
    );
  }, [invalidations, search, status, invalidationsScope, userEmail, sortOrder]);

  return (
    <>
      <SubHeaderAccessGroup />
      <ModuleHeader>
        <ModuleTitle moduleTitle={t("APPLICATION_NAME")} isBeta />
      </ModuleHeader>
      <ModuleContent id={SCROLL_ID}>
        <StyledPageContainer>
          <SearchBar
            onSearch={setSearch}
            setModalOpen={setModalOpen}
            scope={invalidationsScope}
            setScope={setInvaldationsScope}
            status={status}
            setStatus={setStatus}
            sortOrder={sortOrder}
            setSortOder={setSortOrder}
          />

          <Information />

          <Modal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onSubmit={onSubmit}
            dataTestId="modal"
          />

          {invalidations.length === 0 ? (
            <NoInvalidations>{t("NO_INVALIDATIONS_MESSAGE")}</NoInvalidations>
          ) : (
            <InvalidationList>
              {filtredInvalidations.map((invalidation, index) => (
                <StyledCard className="invalidation-card" key={invalidation.id}>
                  <CardHeader>
                    <CardTitleContainer>
                      <CardTitle data-testid={`title-of-index-${index}`}>
                        {invalidation.id}
                      </CardTitle>
                    </CardTitleContainer>
                    <InvalidationStatus
                      progress={invalidation.progress}
                      label={
                        invalidation.progress === 0
                          ? tMisc("PENDING")
                          : invalidation.progress < 100
                          ? tMisc("IN_PROGRESS")
                          : tMisc("COMPLETED")
                      }
                      dataTestId={`status-of-index-${index}`}
                    />
                  </CardHeader>
                  <CardBody>
                    <TextGroup
                      title={t("SUBMIT_DATE")}
                      subtitle={Intl.DateTimeFormat("en-US", {
                        dateStyle: "short",
                        timeStyle: "medium",
                      }).format(new Date(invalidation.createdAt))}
                      dataTestId={`submit-date-of-index-${index}`}
                    />
                    <TextGroup
                      title={t("PRIMARY_ALIAS")}
                      subtitle={invalidation.propertyName}
                      dataTestId={`primary-alias-of-index-${index}`}
                    />
                    <TextGroup
                      title={t("SUBMITTED_BY")}
                      subtitle={invalidation.publisherEmail}
                      dataTestId={`submitted-by-of-index-${index}`}
                    />
                    <TextGroup
                      title={t("INVALIDATION_TYPE")}
                      subtitle={invalidation.force ? t("FORCE") : t("NORMAL")}
                      dataTestId={`invalidation-type-of-index-${index}`}
                    />
                    <TextGroup
                      title={t("NOTIFICATIONS")}
                      subtitle={invalidation.notify ? t("TRUE") : t("FALSE")}
                      dataTestId={`notifications-of-index-${index}`}
                    />
                    <TextGroup
                      title={t("IGNORE_CASE_COLUMN")}
                      subtitle={
                        invalidation.ignoreCase ? t("TRUE") : t("FALSE")
                      }
                      dataTestId={`ignore-case-of-index-${index}`}
                    />
                    <TextGroup
                      title={tMisc("PROGRESS")}
                      subtitle={`${invalidation.progress.toString()} %`}
                      dataTestId={`progress-of-index-${index}`}
                    />
                  </CardBody>
                  <PathsContainer>
                    <Paths
                      title={`${invalidation.paths.length} ${t("PATH_LIST")}`}
                      dataTestId={`paths-of-index-${index}`}
                    >
                      {invalidation.paths.map((path, i) => {
                        return (
                          <PathName
                            key={path}
                            data-testId={`path-name-of-index-${index}-and-paths-index-${i}`}
                          >
                            {path}
                          </PathName>
                        );
                      })}
                    </Paths>
                  </PathsContainer>
                </StyledCard>
              ))}
            </InvalidationList>
          )}
        </StyledPageContainer>
      </ModuleContent>
    </>
  );
};

const NoInvalidations = styled(Center)`
  margin-top: 20vh;
`;

const StyledPageContainer = styled(PageContainer)`
  & > div {
    margin-bottom: 16px;
  }
`;

const StyledCard = styled(Card)`
  padding: 32px;
`;

const CardHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const InvalidationList = styled.div`
  & > .invalidation-card {
    margin-bottom: 16px;
  }
`;

const CardTitleContainer = styled.div`
  margin-right: 16px;
`;

const CardTitle = styled.span`
  font-size: large;
  font-weight: 600;
`;

const CardBody = styled.div`
  display: flex;

  & > div {
    margin-right: 32px;
  }
`;

const PathsContainer = styled.div`
  margin-top: 20px;
`;

const PathName = styled.div`
  padding-bottom: 8px;
`;

const Paths = styled(Accordion)`
  .header {
    padding: 10px 24px;
  }
  .body {
    padding: 0px 24px;
  }
`;
