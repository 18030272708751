import { AxiosError } from "axios";

import { ErrorLevel, APIError } from "./../../../../models/error/index";
import { CMv3APIError } from "./../../../../models/error/APIError";
import { httpClient } from "./../../../../core/http-client";

export interface IUserHeader {
  authenticatedCUID: string;
  authenticatedFirstName: string;
  authenticatedLastName: string;
  authenticatedUserID: number;
  authenticatedUsername: string;
  impersonating: boolean;
  menuIdList: string[];
}

export const getHeader = async (): Promise<IUserHeader> => {
  try {
    const { data } = await httpClient.get<IUserHeader>("/auth/user/header");
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getHeader()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
