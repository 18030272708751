import styled from "@emotion/styled";

export const VerticalDivider = styled.div`
  width: 2px;
  height: 1rem;
  border-left: 2px solid
    ${({ theme }) => `${theme.borders.highlightLight} !important`};
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
`;
