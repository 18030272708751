import { FC } from "react";

import { Authentication } from "../Authentication/Authentication";
import { FeatureFlags } from "../FeatureFlags/FeatureFlags";

export const Global: FC = ({ children }) => (
  <Authentication>
    <FeatureFlags>{children}</FeatureFlags>
  </Authentication>
);
