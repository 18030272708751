import React from "react";
import format from "date-fns/format";
import styled from "@emotion/styled";

import { IVersionHistory } from "../../../../models/configuration/configuration_details.interfaces";
import { EnvironmentHistoryVersionCard } from "../../../../components/molecules/EnvironmentHistoryVersionCard/EnvironmentHistoryVersionCard";

export interface IVersionsList {
  versionHistory: IVersionHistory[];
}

export const VersionsList: React.FC<IVersionsList> = ({ versionHistory }) => {
  const datedVersions: { [date: string]: IVersionHistory[] } = {};
  versionHistory.forEach((version) => {
    if (version && version.modifiedTime !== undefined) {
      const date = new Date(version.modifiedTime);
      const formattedDate = `${format(date, "eeee, MMMM do yyyy")}`;
      if (datedVersions[formattedDate]) {
        datedVersions[formattedDate].push(version);
      } else {
        datedVersions[formattedDate] = [version];
      }
    }
  });

  return (
    <VersionsListContainer>
      <ContainerTest>
        {Object.entries(datedVersions).map((dateVersion, i) => (
          <Block key={i} className="block">
            <DateHeader className="-text--h4">{dateVersion[0]}</DateHeader>
            {dateVersion[1].map((version, j) => (
              <VersionCardContainer key={j}>
                <EnvironmentHistoryVersionCard versionHistory={version} />
              </VersionCardContainer>
            ))}
          </Block>
        ))}
      </ContainerTest>
    </VersionsListContainer>
  );
};

const VersionsListContainer = styled.div`
  & > .block:not(:last-child) {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.backgrounds.highlightLight}`};
  }
`;

const Block = styled.div`
  &:not(:first-child) {
    padding: 2rem 0rem 2.5rem 0rem;
  }

  .-text--h4:first-child {
    margin-top: 0 !important;
  }
`;

const VersionCardContainer = styled.div`
  padding: 0.2rem 0rem;
`;

const DateHeader = styled.div`
  padding-bottom: 0.5rem;
`;

const ContainerTest = styled.div``;
