import { AccessGroup } from "../../../../models/user/user.interfaces";
import { ISubHeaderOption } from "./types";

export const buildAccessGroupOptions = (
  data: AccessGroup[]
): ISubHeaderOption<number>[] => {
  return data.map((item) => ({
    text: item.name,
    value: item.id,
    open: false,
    children: item.list ? buildAccessGroupOptions(item.list) : undefined,
  }));
};

export const findAccessGroup = (
  accessGroupList: AccessGroup[],
  accessGroupId: number
): AccessGroup | undefined => {
  for (const elem of accessGroupList) {
    if (elem.id === accessGroupId) {
      return elem;
    }
    if (elem.list !== undefined) {
      const found = findAccessGroup(elem.list, accessGroupId);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};
