import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { IFromMatchRule } from "../../../../../../@types/matchRules";
import { BlockingNavModal } from "../../../../../../components/atoms/BlockingNavModal/BlockingNavModal";
import { withSubscriberSettings } from "../../../../../../components/layers/SubscriberSettings/SubscriberSettings";
import { MatchRulesLocations } from "../../../../../../components/organisms/MatchRulesHeader/MatchRulesHeader";
import { useQuery } from "../../../../../../hooks/use-query";
import { useSaveValidatedMatchLogic } from "../../../../../../hooks/use-save-validated-match-logic/useSaveValidatedMatchLogic";
import {
  IFeatures,
  MatchBlocks,
} from "../../../../../../models/configuration/definitions/matchlogic";
import { useSelectedConfigurationName } from "../../../../../../store/slices/caching/hooks";
import { Conditions } from "./Conditions";
import { Features } from "./features/Features";
import { useMatchRulesPage } from "./useMatchRulesPage";

interface IMatchRulesPageProps {
  propertyName: string | undefined;
}

export const MatchRulesPage = withSubscriberSettings<IMatchRulesPageProps>(
  ({ propertyName }) => {
    const [
      matchRuleLocation,
      matchRule,
      matchBlockQueried,
      handleSaveMatchRule,
      onMatchRuleChange,
      handleTransition,
      initialMatchRule,
    ] = useMatchRulesPage();
    const history = useHistory();
    const [matchGroupQueried] = useQuery<MatchBlocks>("matchGroup");
    const [matchRuleQueried] = useQuery("matchRule");
    const configName = useSelectedConfigurationName();
    const [tMisc] = useTranslation("misc");

    const {
      clearErrors,
      formState,
      handleSubmit,
      register,
      setError,
      unregister,
      reset,
      watch,
      setValue,
    } = useForm<IFeatures>({
      mode: "all",
      defaultValues: initialMatchRule?.features,
    });
    const handleSaveOnNavigation = useSaveValidatedMatchLogic(
      matchBlockQueried,
      matchRule,
      () => {}
    );

    const onSubmit = (cb?: (err?: boolean) => void) =>
      handleSubmit(
        (values) => {
          handleSaveMatchRule(values);
          toast.success(tMisc("SUCCESS_CHANGES_SAVED_LOCALLY"));
          if (typeof cb !== "undefined") {
            cb(true);
          }
        },
        () => {
          if (typeof cb !== "undefined") {
            cb(false);
          }
          toast.error(tMisc("ERROR_INCORRECTLY_FILLED_FIELDS"));
        }
      );

    return (
      <MatchRulesContainer>
        {matchRule ? (
          <>
            <BlockingNavModal
              when={true}
              shouldBlockNavigation={(loc) =>
                handleTransition(loc, { ...matchRule, features: watch() })
              }
              onSave={() =>
                new Promise((resolve) => {
                  handleSaveOnNavigation().then((result) => {
                    if (result !== false) {
                      onSubmit(resolve)();
                    } else {
                      resolve(false);
                    }
                  });
                })
              }
              customNavigator={() => {
                history.push(
                  `/cmv3/configurations/${configName}/properties/${propertyName}`,
                  {
                    fromMatchRule: {
                      matchBlock: matchBlockQueried,
                      matchGroup: Number(matchGroupQueried),
                      matchRule: matchRuleQueried,
                    },
                  } as IFromMatchRule
                );
              }}
            />
            <PageWrapper>
              {matchRuleLocation === MatchRulesLocations.CONDITIONS ? (
                <Conditions
                  matchRule={matchRule}
                  matchBlock={matchBlockQueried}
                  handleMatchRuleChange={onMatchRuleChange}
                  handleSaveMatchRule={() => {
                    handleSaveMatchRule(watch());
                  }}
                />
              ) : (
                <Features
                  matchBlock={matchBlockQueried}
                  setValue={setValue}
                  onSubmit={onSubmit()}
                  watch={watch}
                  errors={formState.errors}
                  register={register}
                  unregister={unregister}
                  reset={reset}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              )}
            </PageWrapper>
          </>
        ) : null}
      </MatchRulesContainer>
    );
  }
);

const MatchRulesContainer = styled.div``;
const PageWrapper = styled.div``;
