import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated } from "react-spring";

export const DropdownButton = styled(animated.button)<{
  buttonprops: {
    outline: boolean;
    bold: boolean;
  };
}>`
  box-sizing: border-box;
  height: 100%;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`}!important;
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.backgrounds.mutedLight
      : theme.backgrounds.baseLight}!important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;

  ${({ buttonprops }) =>
    !buttonprops.bold &&
    css`
      font-weight: 430 !important;
    `}

  ${({ buttonprops, theme }) =>
    buttonprops.outline
      ? css`
          border: 1px solid ${theme.borders.mutedLight} !important;
        `
      : css`
          border: none !important;
          box-shadow: none !important;
        `}
`;
