import styled from "@emotion/styled";

export const Input = styled.input`
  height: 100%;
  height: 40px !important;
  line-height: 1em;
  font-size: large;
  border: none;
  border: 1px solid black;
  border-radius: 3px;
  padding: 8px;
`;
