import {
  ComponentProps,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
} from "react";
import styled from "@emotion/styled";

export interface RadioButtonProps<Value> {
  value: Value;
  onClick: (value: Value) => void;
  label: string;
  id: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  danger?: boolean;
  name: string;
  dataTestId?: string;
}

export const RadioButton = <Value extends any>({
  value,
  onClick,
  label,
  id,
  disabled = false,
  defaultChecked = false,
  danger = false,
  name,
  dataTestId,
}: RadioButtonProps<Value>): ReactElement => (
  <RadioBase
    name={name}
    danger={danger}
    id={id}
    disabled={disabled}
    checked={defaultChecked}
    onClick={() => {
      onClick(value);
    }}
    dataTestId={dataTestId}
    label={label}
  />
);

export const HeadlessRadioField = forwardRef<
  HTMLInputElement,
  ComponentProps<typeof RadioBase>
>(({ danger = false, dataTestId, ...rest }, ref) => (
  <RadioBase ref={ref} danger={danger} dataTestId={dataTestId} {...rest} />
));

export interface RadioBaseProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id: string;
  label: string;
  danger?: boolean;
  dataTestId?: string;
}

const RadioBase = forwardRef<HTMLInputElement, RadioBaseProps>(
  ({ id, danger = false, dataTestId, label, disabled, ...rest }, ref) => (
    <div className="chi-radio">
      <input
        ref={ref}
        className={`chi-radio__input ${danger && "-danger"}`}
        type="radio"
        id={id}
        readOnly
        data-testid={dataTestId}
        disabled={disabled}
        {...rest}
      />
      <Label className="chi-radio__label" htmlFor={id} disabled={disabled}>
        {label}
      </Label>
    </div>
  )
);

const Label = styled.label<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey60 : theme.text.primary}!important;
`;
