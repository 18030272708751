import { ReactElement } from "react";
import styled from "@emotion/styled";

import {
  TextSize,
  ContentSwitcherTab,
} from "../../atoms/ContentSwitcherTab/ContentSwitcherTab";

interface Item<T extends unknown> {
  label: string;
  value: T;
  dataTestId?: string;
}

export interface ContentSwitcherProps<T extends unknown> {
  onChange: (tab: Item<T>) => void;
  size?: TextSize;
  tabs: Item<T>[];
  value: T;
}

export const ContentSwitcher = <T,>({
  onChange,
  size,
  tabs,
  value,
}: ContentSwitcherProps<T>): ReactElement => (
  <TabsContainer>
    {tabs.map((tab, i) => (
      <ContentSwitcherTab
        key={i}
        selected={tab.value === value}
        onClick={() => onChange(tab)}
        data-testid={tab.dataTestId}
        size={size}
      >
        {tab.label}
      </ContentSwitcherTab>
    ))}
  </TabsContainer>
);

const TabsContainer = styled.div`
  display: inline-grid;
  grid-auto-columns: 1fr;

  border-radius: 4px;
  overflow: hidden;
`;
