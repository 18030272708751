import { ReactElement } from "react";
import { useHistory } from "react-router-dom";

import { VersionActions } from "../../organisms/VersionHistoryCard/VersionHistoryCard";
import { UpdateVersionActions } from "../../../store/slices/caching/types";
import { DeploymentModal } from "../../../modules/caching/configurations/deployment";
import {
  DiscardCommitModal,
  DiscardCommitModalMode,
} from "../DiscardCommitModal/DiscardCommitModal";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { useAppDispatch } from "../../../store/types";
import { handleUpdateSelectedConfigurationVersion } from "../../../store/slices/caching/thunks";
import {
  UseVersionActionParams,
  useVersionAction,
} from "../../../hooks/user-version-action/useVersionAction";

export const VersionActionModal = ({
  activeAction,
  resetActiveAction,
  versionHistory,
}: UseVersionActionParams): ReactElement | null => {
  const selectedConfiguration = useSelectedConfiguration();
  const handleMediaPortalError = useDispatchAPIError();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useVersionAction({ activeAction, resetActiveAction, versionHistory });

  switch (activeAction) {
    case VersionActions.LIVE:
      return (
        <DeploymentModal
          configuration={versionHistory}
          open
          onClose={resetActiveAction}
          onError={handleMediaPortalError}
        />
      );
    case VersionActions.EDIT:
      if (selectedConfiguration?.isLocalDraft) {
        return (
          <DiscardCommitModal
            open
            onClose={resetActiveAction}
            onDiscard={() => {
              if (
                selectedConfiguration &&
                selectedConfiguration.versionId !== versionHistory.versionId
              ) {
                dispatch(
                  handleUpdateSelectedConfigurationVersion(
                    versionHistory.versionId,
                    UpdateVersionActions.DISCARD
                  )
                );
                history.push("properties");
              }
            }}
            onSubmit={() => {
              if (
                selectedConfiguration &&
                selectedConfiguration.versionId !== versionHistory.versionId
              ) {
                dispatch(
                  handleUpdateSelectedConfigurationVersion(
                    versionHistory.versionId,
                    UpdateVersionActions.COMMIT
                  )
                );
                history.push("properties");
              }
            }}
            mode={DiscardCommitModalMode.EDIT_OLD_VERSION}
          />
        );
      } else {
        return null;
      }
    default:
      return null;
  }
};
