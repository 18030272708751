import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { Fields } from "./Condition";
import {
  ErrorNames,
  LocalizedExpressionError,
} from "./LocalizedMediaPortalError";

type LocalizedErrors = Partial<Record<Fields, string>>;

export const useExpressionParsingError = (
  condition: string,
  errors: LocalizedExpressionError[],
  onError: (expressionError: boolean) => void,
  onChange: (expressionChunk: string) => void
): undefined | LocalizedErrors => {
  const { t } = useTranslation("configurationMatchRulesPage");
  const prevConditionRef = useRef(condition);

  const localizedErrors: LocalizedErrors = {};
  let expressionError = false;

  for (const error of errors) {
    if (error.name === ErrorNames.EXPRESSION_PARSING_ERROR) {
      onError(true);
      return;
    }

    if (error.data?.missingField === Fields.EXPECTED_VALUE) {
      localizedErrors[Fields.EXPECTED_VALUE] = t(error.message);
    } else {
      expressionError = true;
    }
  }

  const isNewCondition = prevConditionRef.current !== condition;
  if (isNewCondition) {
    prevConditionRef.current = condition;

    if (condition.length) {
      onChange(condition);
    }

    onError(expressionError);
  }

  return localizedErrors;
};
