import { LuaDefinitionType } from "../../../store/slices/caching/types";

export interface LuaVariable {
  name: string;
  type: "string" | "number" | "boolean";
}

export interface ILuaDefinition {
  description?: string;
  heavy?: boolean;
  script: string;
  signature?: string;
  variables?: LuaVariable[];
}

export const getLuaDefinitionDescription = (def: LuaDefinitionType): string =>
  def.variables
    ?.map(
      (variable) => `{ name: "${variable.name}", type: "${variable.type}" }`
    )
    .join(", ") || def.script;
