import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { ArrowLeftIcon } from "../../../../../components/atoms/ArrowLeftIcon/ArrowLeftIcon";
import { PageTitle } from "../../../../../components/atoms/PageTitle/PageTitle";
import { Badge } from "../../../../../components/atoms/Badge/Badge";
import { statusBadgeColors } from "../types";
import { computeDateDifference } from "../helpers";
import { UserManagementUserData } from "../../../../../models/user-management/users/users.interfaces";
import { UserActionDropdown } from "./UserActionDropdown";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { EditUserInfoModal } from "./modals/EditUserInfoModal";
import { updateUserInfo } from "../../../../../models/user-management/users";
import { useAppDispatch } from "../../../../../store/types";
import { fetchUser } from "../../../../../store/slices/user-management-users/thunk";
import { handleAPIError } from "../../../../../store/slices/api-error/thunks";
import { APIError } from "../../../../../models/error";

interface UserHeaderProps {
  userInfo: UserManagementUserData;
}

export const UserHeader = ({ userInfo }: UserHeaderProps): ReactElement => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [openEditInfoModal, setOpenEditInfoModal] = useState<boolean>(false);
  const { t } = useTranslation("usersPage");

  const { dateValue, dateLabel } = computeDateDifference(
    Date.parse(userInfo.lastLogin)
  );

  const saveUserInfo = (
    firstName: string,
    lastName: string,
    userName: string
  ) => {
    updateUserInfo(userInfo.userId, {
      firstName,
      lastName,
      userName,
      email: userInfo.email,
      status: userInfo.status,
    })
      .then(() => {
        dispatch(fetchUser(userInfo.userId));
        toast.success(t("EDIT_USER_INFO_SUCCESS_TOAST_LABEL"));
      })
      .catch((err) => {
        dispatch(handleAPIError(err as APIError));
      });
  };

  return (
    <>
      <HeaderContainer>
        <FlexBox>
          <ArrowLeftIcon
            onClick={() => {
              history.push("/userManagement/users");
            }}
          />
          <PageTitle>
            {userInfo?.firstName + " " + userInfo?.lastName}
          </PageTitle>
          <EditIcon
            name={Icons.EDIT}
            onClick={() => setOpenEditInfoModal(true)}
          />
        </FlexBox>
        <UserActionDropdown userInfo={userInfo} />
      </HeaderContainer>
      <DataContainer>
        <DataItem>
          <Label>{t("USER_PAGE_STATUS_LABEL")}</Label>
          <Value>
            <Badge
              label={
                userInfo.status[0].toUpperCase() + userInfo.status.substring(1)
              }
              color={statusBadgeColors.get(userInfo.status)}
            />
          </Value>
        </DataItem>
        <DataItem>
          <Label>{t("USER_PAGE_EMAIL_LABEL")}</Label>
          <Value>{userInfo.userName}</Value>
        </DataItem>
        <DataItem>
          <Label>{t("USER_PAGE_CREATION_DATE")}</Label>
          <Value>
            {userInfo.createdAt !== "" &&
              format(Date.parse(userInfo.createdAt), "d MMM. yyyy")}
          </Value>
        </DataItem>
        <DataItem>
          <Label>{t("USER_PAGE_LAST_LOGIN")}</Label>
          <Value>
            {userInfo.lastLogin !== "" &&
              `${format(Date.parse(userInfo.lastLogin), "d MMM. yyyy")} (${t(
                "LAST_LOGIN",
                {
                  value: dateValue,
                  label: dateLabel,
                }
              )})`}
          </Value>
        </DataItem>
      </DataContainer>
      <Modal
        customButton={<></>}
        open={openEditInfoModal}
        onClose={() => setOpenEditInfoModal(false)}
      >
        <EditUserInfoModal userInfo={userInfo} save={saveUserInfo} />
      </Modal>
    </>
  );
};

const HeaderContainer = styled(FlexBox)`
  justify-content: space-between;
`;

const EditIcon = styled(Icon)`
  margin-left: 32px;
  cursor: pointer;
`;

const DataContainer = styled(FlexBox)`
  padding-top: 32px;
  justify-content: flex-start;
`;

const DataItem = styled.div`
  padding-right: 32px;
  align-self: start;
`;

const Label = styled.div`
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
