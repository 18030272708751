import { Dispatch, FC, SetStateAction } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import {
  Dropdown,
  IDropdownItem,
} from "../../../../../../../components/organisms/Dropdown/Dropdown";
import { FilterFeatureTypeValue } from "../types";
import { InputField } from "../../../../../../../components/molecules/InputField/InputField";

interface IFeaturesSearchBar {
  dropdownItems: IDropdownItem<FilterFeatureTypeValue>[];
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSelectedFeatureType: (
    value: SetStateAction<IDropdownItem<FilterFeatureTypeValue>>
  ) => void;
  searchValue: string;
}

export const FeaturesSearchBar: FC<IFeaturesSearchBar> = ({
  dropdownItems,
  setSearchValue,
  setSelectedFeatureType,
  searchValue,
}) => {
  const { t } = useTranslation("configurationMatchRulesPage");

  return (
    <SearchBarContainer>
      <InputField
        onChange={setSearchValue}
        hasBorder={true}
        isSearchField={true}
        placeholder={t("FEATURES_TAB_SEARCH_BAR_PLACEHOLDER")}
        dataTestId="match-rule-features-search-input"
        value={searchValue}
        autoFocus
      />
      <DropdownContainer>
        <Dropdown
          items={dropdownItems}
          id="slot-selector"
          placeholder={t("FEATURES_TAB_FILTER_DROPDOWN_ALL")}
          onSelect={(item) => setSelectedFeatureType(item)}
          dataTestId="match-rule-features-dropdown"
          type="button"
        />
      </DropdownContainer>
    </SearchBarContainer>
  );
};

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

const DropdownContainer = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  & .chi-dropdown__menu {
    left: unset !important;
    right: 0;
  }
`;
