import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../@types/status";
import { UserManagementUsersSlice } from "./types";

const initialState: UserManagementUsersSlice = {
  users: [],
  usersListStatus: Status.IDLE,
  user: undefined,
  userStatus: Status.IDLE,
};

export const userManagementUsersSlice = createSlice({
  name: "userManagementUsers",
  initialState,
  reducers: {
    setUsers: (
      state,
      action: PayloadAction<UserManagementUsersSlice["users"]>
    ) => {
      state.users = action.payload;
    },
    setUserManagementUsersListStatus: (
      state,
      action: PayloadAction<UserManagementUsersSlice["usersListStatus"]>
    ) => {
      state.usersListStatus = action.payload;
    },
    setUser: (
      state,
      action: PayloadAction<UserManagementUsersSlice["user"]>
    ) => {
      state.user = action.payload;
    },
    setUserManagementUserStatus: (
      state,
      action: PayloadAction<UserManagementUsersSlice["userStatus"]>
    ) => {
      state.userStatus = action.payload;
    },
    resetUser: (state) => {
      state.user = undefined;
      state.userStatus = Status.IDLE;
    },
  },
});

export const {
  setUsers,
  setUserManagementUsersListStatus,
  setUser,
  setUserManagementUserStatus,
  resetUser,
} = userManagementUsersSlice.actions;

export default userManagementUsersSlice.reducer;
