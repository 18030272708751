import { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import { CdnApiKeyPage } from "./apiKey/cdnApiKey";

import { VyvxApiKeyPage } from "./apiKey/vyvxApiKey";
import { CdnApiKeys } from "./cdn";
import { VyvxApiKeys } from "./vyvx";

export const ApiKeysRouter = (): ReactElement => {
  return (
    <Switch>
      <Route
        exact
        path={["/userManagement/apiKeys", "/userManagement/apiKeys/cdn"]}
      >
        <CdnApiKeys />
      </Route>
      <Route exact path="/userManagement/apiKeys/vyvx">
        <VyvxApiKeys />
      </Route>
      <Route exact path="/userManagement/apiKeys/vyvx/:apiKeyId">
        <VyvxApiKeyPage />
      </Route>
      <Route exact path="/userManagement/apiKeys/cdn/:apiKeyId">
        <CdnApiKeyPage />
      </Route>
    </Switch>
  );
};
