import { FC } from "react";
import { linearGradientDef } from "@nivo/core";
import { LineSvgProps, ResponsiveLine, Serie } from "@nivo/line";
import { isArray } from "lodash";

import { GraphContainer } from "../GraphContainer/GraphContainer";
import { MetricsTooltip } from "../MetricsTooltip/MetricsTooltip";
import styled from "@emotion/styled";
import { FlexBox } from "../FlexBox/FlexBox";

type LineSvgPropsOptional = Omit<LineSvgProps, "data" | "enableArea" | "fill">;

export interface ILineChartProps extends LineSvgPropsOptional {
  data: Serie[];
  dataTestId?: string;
}

export const DEFAULT_CHART_COLORS = [
  "#2072F8",
  "#FF645C",
  "#FFCC89",
  "#76EAB8",
  "#4ED8E4",
  "#B277ED",
  "#FA8EB2",
];

const defaultLineChartProps: LineSvgPropsOptional = {
  margin: { bottom: 50, left: 40, top: 5 },
  animate: true,
  enablePoints: false,
  enableSlices: "x",
  enableGridX: false,
  yScale: {
    type: "linear",
    min: 0,
    max: "auto",
  },
  xScale: { type: "time", format: "native" },
  axisBottom: {
    format: "%H:%M",
    tickValues: "every 30 minutes",
  },
  axisLeft: {
    tickValues: 4,
  },
  gridYValues: 4,
  colors: DEFAULT_CHART_COLORS,
  curve: "linear",
  defs: [
    linearGradientDef("gradientA", [
      { offset: 0, color: "rgb(0, 128, 220)" },
      { offset: 75, color: "rgb(0, 128, 220)", opacity: 0 },
    ]),
  ],
  legends: [
    {
      anchor: "bottom",
      direction: "row",
      itemWidth: 50,
      itemHeight: 20,
      translateY: 50,
      symbolShape: ({ x, y, fill }) => (
        <rect x={x + 4} y={y + 8} width={10} height={2} fill={fill} />
      ),
      itemTextColor: "#ACB0B5",
    },
  ],
  sliceTooltip: MetricsTooltip,
  theme: {
    grid: {
      line: {
        stroke: "#EDF0F2",
        strokeWidth: 1,
        strokeDasharray: "2 2",
      },
    },
    axis: {
      ticks: {
        text: {
          fill: "#D0D4D9",
        },
        line: {
          strokeOpacity: 0,
        },
      },
    },
  },
};

export const LineChart: FC<ILineChartProps> = ({
  data,
  dataTestId,
  ...restChartProps
}) => (
  <GraphContainer height={250}>
    {data.every((serie) => serie.data.length === 0) ? (
      <EmptyChart data-testid={`${dataTestId}-empty`}>No Data</EmptyChart>
    ) : (
      <>
        {/** div used in the tests */}
        <div data-testid={dataTestId}></div>
        <ResponsiveLine
          data={data}
          {...defaultLineChartProps}
          enableArea={isArray(data) && data.length === 1}
          fill={
            isArray(data) && data.length > 1
              ? undefined
              : [{ match: "*", id: "gradientA" }]
          }
          {...restChartProps}
        />
      </>
    )}
  </GraphContainer>
);

const EmptyChart = styled(FlexBox)`
  font-weight: 800;
  color: ${({ theme }) => theme.text.mutedAlt};
  font-size: 4em;
`;
