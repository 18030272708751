import { useState, useReducer, Reducer, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  Certificate,
  CertificateEditInput,
} from "../../../../models/certificate";
import { CMv3APIError, APIError } from "../../../../models/error";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../../store/slices/subscriber/hooks";

export enum CertificateReducerActions {
  password = "Password",
  certificate = "Certificate",
  bundle = "Certificate Bundle",
  private_key = "Private Key",
  name = "Name",
}

export enum ExistingCertificateActions {
  existing = "Existing",
}

export type ICertificateReducerActionType =
  | {
      type: CertificateReducerActions;
      payload: string;
    }
  | {
      type: ExistingCertificateActions.existing;
      payload: CertificateEditInput;
    };

export const mapEntryToField: Record<
  CertificateReducerActions,
  keyof CertificateEditInput
> = {
  [CertificateReducerActions.certificate]: "certificate",
  [CertificateReducerActions.bundle]: "intermediate_certs",
  [CertificateReducerActions.password]: "password",
  [CertificateReducerActions.private_key]: "private_key",
  [CertificateReducerActions.name]: "cert_name",
};

const certificateReducer = (
  state: CertificateEditInput,
  action: ICertificateReducerActionType
): CertificateEditInput => {
  switch (action.type) {
    case CertificateReducerActions.certificate:
    case CertificateReducerActions.password:
    case CertificateReducerActions.private_key:
    case CertificateReducerActions.name:
    case CertificateReducerActions.bundle:
      return { ...state, [mapEntryToField[action.type]]: action.payload };
    case ExistingCertificateActions.existing:
      return action.payload;
    default:
      throw new Error(`Unknown action: ${action}`);
  }
};

const isPrivateKeyEncrypted = (key: string): boolean => {
  if (!!key.match(/BEGIN ENCRYPTED PRIVATE KEY/g)) {
    return true;
  }
  const split = key.split("\n");
  if (split.length <= 1) {
    return false;
  }
  const encryptionLine = split[1];
  if (!!encryptionLine.match(/Proc-Type: [0-9],ENCRYPTED/)) {
    return true;
  }
  return false;
};

export const useCertificateCrupdate = () => {
  const [hasUnsavedChanged, setHasUnsavedChanged] = useState(false);
  const [isNotCommited, setIsNotCommited] = useState(false);
  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();
  const { cert_name: paramCertName } = useParams<{ cert_name: string }>();
  const { t } = useTranslation("certificatePage");
  const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);

  const [certificate, dispatch] = useReducer<
    Reducer<CertificateEditInput, ICertificateReducerActionType>
  >(certificateReducer, {
    cert_name: "",
  });

  const location = useLocation<{ cert_name?: string }>();

  useEffect(() => {
    if (paramCertName) {
      const localString = window.localStorage.getItem(
        `certificate_${paramCertName}`
      );
      const localCertificateData = localString
        ? JSON.parse(localString)
        : undefined;
      if (localCertificateData) {
        dispatch({
          type: ExistingCertificateActions.existing,
          payload: localCertificateData,
        });
        setIsNotCommited(true);
      } else if (subscriberId) {
        Certificate.getCertByName(subscriberId, paramCertName)
          .then((cert) => {
            dispatch({
              type: ExistingCertificateActions.existing,
              payload: cert.getEditableFields(),
            });
          })
          .catch(handleMediaPortalError);
      }
    } else if (location.state?.cert_name) {
      dispatch({
        type: CertificateReducerActions.name,
        payload: location.state.cert_name,
      });
      setHasUnsavedChanged(true);
    }
  }, [paramCertName, location, subscriberId]);

  const onInputChange = (type: CertificateReducerActions) => (
    content: string
  ) => {
    setHasUnsavedChanged(true);
    if (type === CertificateReducerActions.private_key) {
      if (isPrivateKeyEncrypted(content)) {
        setIsPasswordRequired(true);
      } else {
        setIsPasswordRequired(false);
      }
    }
    dispatch({ type, payload: content });
  };

  const validateInput = (
    certData: CertificateEditInput,
    isNewCert: boolean
  ): string[] => {
    const messages: string[] = [];
    if (certData.cert_name === "") {
      messages.push(t("CERTIFICATE_VALIDATION_ERROR_NAME_MISSING"));
    }
    // TODO: Check for existing certificates with the same name in the case a cert creation
    if (certData.cert_name.includes("-")) {
      messages.push(t("CERTIFICATE_VALIDATION_ERROR_NAME_INVALID"));
    }

    if (!certData.certificate && isNewCert) {
      messages.push(t("CERTIFICATE_VALIDATION_ERROR_CERTIFICATE_MISSING"));
    }
    if (!certData.private_key) {
      messages.push(t("CERTIFICATE_VALIDATION_ERROR_PRIVATE_KEY_MISSING"));
    }
    if (
      certData.private_key &&
      isPrivateKeyEncrypted(certData.private_key) &&
      !certData.password
    ) {
      messages.push(t("CERTIFICATE_VALIDATION_ERROR_PASSWORD_MISSING"));
    }
    return messages;
  };

  const submitCert = useCallback(
    (isNewCert: boolean) => {
      const errorMessages = validateInput(certificate, isNewCert);
      if (errorMessages.length === 0) {
        Certificate.crupdateCertificate(
          subscriberId,
          certificate as CertificateEditInput
        )
          .then(() => {
            toast.success(
              t("SUCCESS_TOAST_TITLE", {
                action: t(
                  isNewCert ? "CERTIFICATE_SAVED" : "CERTIFICATE_UPDATED"
                ),
                certificateName: certificate.cert_name,
              })
            );
            setHasUnsavedChanged(false);
          })
          .catch((err) => {
            const error = err as APIError<CMv3APIError>;
            const messages = error.data?.errors ?? [];
            const comp = (
              <div>
                <h5>{t("ERROR_TOAST_TITLE")}</h5>
                <ul>
                  {messages.map(({ message }, i) => (
                    <li key={i}>{message}</li>
                  ))}
                </ul>
              </div>
            );
            toast.error(comp, {
              autoClose: 15000,
            });
          });
      } else {
        const comp = (
          <div>
            <h5>{t("ERROR_TOAST_TITLE")}</h5>
            <ul>
              {errorMessages.map((msg, i) => (
                <li key={i}>{msg}</li>
              ))}
            </ul>
          </div>
        );
        toast.warn(comp, {
          autoClose: 15000,
        });
      }
    },
    [subscriberId, certificate]
  );

  return {
    isCreation: location.pathname.endsWith("/add"),
    isNotCommited,
    cert_name: location.state?.cert_name || paramCertName,
    certificate,
    isPasswordRequired,
    submitCert,
    onInputChange,
    hasUnsavedChanged,
  };
};
