import type { IMatchRule } from "../../../../../../models/configuration/definitions";
import type { ICustomLogDataVariable } from "../../../../../../models/configuration/definitions/matchlogic";
import {
  getVariableNameSuffix,
  getVariablePrefix,
} from "./features/list/feature_block/customLogDataFeatureBlock/helpers";

interface CustomMatchRule extends IMatchRule {
  features: IMatchRule["features"] & Record<string, any>;
}

export const denormalizeCustomLogData = (
  matchRule: CustomMatchRule
): IMatchRule => {
  matchRule.features.customLogData?.forEach((cld, i) => {
    if (matchRule.features[`customLogData-${i}-variableName`]) {
      (cld as ICustomLogDataVariable).variableName +=
        matchRule.features[`customLogData-${i}-variableName`];

      delete matchRule.features[`customLogData-${i}-variableName`];
    }
  });

  return matchRule;
};

export const normalizeCustomLogData = (matchRule: CustomMatchRule): void => {
  matchRule.features.customLogData?.forEach((_cld, i) => {
    const variablePrefix = getVariablePrefix(_cld);
    if (variablePrefix) {
      const cld = _cld as ICustomLogDataVariable;
      const variableNameSuffix = getVariableNameSuffix(cld.variableName);

      if (variableNameSuffix !== false) {
        matchRule.features[
          `customLogData-${i}-variableName`
        ] = variableNameSuffix;

        cld.variableName = variablePrefix;
      }
    }
  });
};
