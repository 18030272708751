import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Status } from "../../../@types/status";
import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { IMatchRule } from "../../../models/configuration/definitions/matchlogic";
import { IConfigurationError } from "../../../models/configuration/errors";
import { PropertyMetaData } from "../../../models/metadata/properties";
import { updateGeneratedResponseReferences } from "./helpers/generated-response-definition/updateGeneratedResponseReferences";
import { updateHeaderReferences } from "./helpers/header-definition/updateHeaderReferences";
import { updateOriginReferences } from "./helpers/origin-definition/updateOriginReferences";
import { updateReportingOverrideReferences } from "./helpers/reporting-override-definition/updateReportingOverrideReferences";
import { updateSimpleDefinitionReferences } from "./helpers/simple-definition/updateSimpleDefinitionReferences";
import { updateTokenReferences } from "./helpers/token-definition/updateTokenReferences";
import { updatePropertiesReference } from "./helpers/updatePropertiesReference";
import {
  CachingSlice,
  CertificateType,
  ConfigurationDefinition,
  ConfigurationDefinitions,
  ConfigurationType,
  MatchRulePayload,
  Production,
  SimpleDefinitionType,
} from "./types";

const initialState: CachingSlice = {
  properties: [],
  configurations: [],
  production: undefined,
  selectedConfiguration: undefined,
  errors: [],
  certificates: [],
  status: Status.IDLE,
  configDetailsStatus: Status.IDLE,
};

export const cachingSlice = createSlice({
  name: "caching",
  initialState,
  reducers: {
    setProperties: (state, action: PayloadAction<PropertyMetaData[]>) => {
      state.properties = action.payload;
    },
    setConfigurations: (state, action: PayloadAction<ConfigurationType[]>) => {
      state.configurations = action.payload;
    },
    addConfiguration: (state, action: PayloadAction<ConfigurationType>) => {
      state.configurations.push(action.payload);
    },
    setProduction: (state, action: PayloadAction<Production>) => {
      state.production = action.payload;
    },
    setSelectedConfiguration: (
      state,
      action: PayloadAction<ConfigurationType>
    ) => {
      state.selectedConfiguration = action.payload;
    },
    clearSelectedConfiguration: (state) => {
      state.selectedConfiguration = undefined;
    },
    setErrors: (state, action: PayloadAction<IConfigurationError[]>) => {
      state.errors = action.payload;
    },
    addError: (state, action: PayloadAction<IConfigurationError>) => {
      state.errors.push(action.payload);
    },
    setCertificates: (state, action: PayloadAction<CertificateType[]>) => {
      state.certificates = action.payload;
    },
    setDefinition: (
      state,
      action: PayloadAction<ConfigurationDefinitions[number]>
    ) => {
      const selectedConfiguration = state.selectedConfiguration!.config!;
      const newDefinitions = selectedConfiguration![action.payload.type];
      if (newDefinitions) {
        newDefinitions.push(action.payload as any);
      } else {
        selectedConfiguration[action.payload.type] = [action.payload as any];
      }
    },
    updateMatchrule: (state, action: PayloadAction<MatchRulePayload>) => {
      const matchLogic = state.selectedConfiguration!.config!.matchLogicDefinitions.find(
        (mld) => mld.name === action.payload.mldName
      );
      const matchBlock =
        matchLogic?.matchBlocks[action.payload.matchBlockQueried];
      const matchGroup =
        matchBlock?.matchGroups[Number(action.payload.matchGroupQueried)];

      action.payload.matchRuleQueried
        .split("-")
        .reduce<IMatchRule | IMatchRule[] | undefined>(
          (prev, curr, level, queries) => {
            if (level < queries.length - 1) {
              if (level % 2 === 0) {
                // Matchrule
                return (prev as IMatchRule[])?.[Number(curr)];
              }

              return (prev as IMatchRule).matchGroups?.[Number(curr)]
                .matchRules;
            } else {
              (prev as IMatchRule[])[Number(curr)] = action.payload.matchRule;
            }
          },
          matchGroup?.matchRules
        );
    },
    updateDefinition: (
      state,
      action: PayloadAction<ConfigurationDefinition | ConfigurationDefinition[]>
    ) => {
      const selectedConfig = state.selectedConfiguration!.config!;
      let newDefinitions: ConfigurationDefinition[];

      if (!Array.isArray(action.payload)) {
        newDefinitions = [action.payload];
      } else {
        newDefinitions = action.payload;
      }

      newDefinitions.forEach((newDefinition) => {
        const defsToUpdate = selectedConfig[newDefinition.type];
        let defIndex = -1;

        for (let i = 0; i < defsToUpdate.length; i++) {
          const defToUpdate = defsToUpdate[i];
          if (defToUpdate.id === newDefinition.id) {
            defIndex = i;
            break;
          }
        }

        // Update definition
        if (defIndex > -1) {
          const oldName = defsToUpdate[defIndex].name;
          const newName = newDefinition.name;
          const shouldUpdateMatchRuleReferences = oldName !== newName;
          defsToUpdate[defIndex] = newDefinition;

          if (shouldUpdateMatchRuleReferences) {
            let updateFunc:
              | ((
                  newName: string,
                  oldName: string
                ) => (matchRule: IMatchRule) => IMatchRule)
              | undefined;

            // Update match rule reference
            switch (newDefinition.type) {
              case TypeDefinitions.GENERATED_RESPONSE:
                updateFunc = updateGeneratedResponseReferences;
                break;

              case TypeDefinitions.REPORTING_OVERRIDE:
                updateFunc = updateReportingOverrideReferences;
                break;

              case TypeDefinitions.TOKEN:
                updateFunc = updateTokenReferences;
                break;

              case TypeDefinitions.HEADER:
                updateFunc = updateHeaderReferences;
                break;

              case TypeDefinitions.ORIGIN:
                updateFunc = updateOriginReferences;
                break;

              case TypeDefinitions.SIMPLE_DEFINITION:
                updateFunc = updateSimpleDefinitionReferences(
                  (newDefinition as SimpleDefinitionType).listType
                );
                break;

              default:
                updateFunc = undefined;
            }

            if (typeof updateFunc !== "undefined") {
              selectedConfig.matchLogicDefinitions = selectedConfig.matchLogicDefinitions.map(
                (matchLogic) => {
                  const newMatchBlocks = Object.entries(
                    matchLogic.matchBlocks
                  ).map(([name, matchBlock]) => {
                    if (matchBlock) {
                      matchBlock.matchGroups = matchBlock.matchGroups.map(
                        (matchGroup) => {
                          const newMatchrules = matchGroup.matchRules.map(
                            updateFunc!(oldName, newName)
                          );

                          matchGroup.matchRules = newMatchrules;
                          return matchGroup;
                        }
                      );

                      return [name, matchBlock];
                    } else {
                      return [name, matchBlock];
                    }
                  });

                  matchLogic.matchBlocks = Object.fromEntries(newMatchBlocks);
                  return matchLogic;
                }
              );

              // Update property reference
              if (newDefinition.type === TypeDefinitions.ORIGIN) {
                selectedConfig.propertyDefinitions = selectedConfig.propertyDefinitions.map(
                  updatePropertiesReference(oldName, newName)
                );
              }
            }
          }
        } else {
          defsToUpdate.push(newDefinition as any);
        }
      });
    },
    deleteDefinition: (
      state,
      action: PayloadAction<ConfigurationDefinitions[number]>
    ) => {
      const selectedConfig = state.selectedConfiguration!.config!;
      const definition = action.payload;

      selectedConfig[definition.type] = (selectedConfig[
        definition.type
      ] as any[]).filter(
        (def: ConfigurationDefinitions[number]) => def.name !== definition.name
      );

      if (definition.type === TypeDefinitions.MATCH_LOGIC) {
        selectedConfig.propertyDefinitions.forEach((property) => {
          if (property.matchLogic && property.matchLogic === definition.name) {
            property.matchLogic = undefined;
          }
        });
      }
    },
    setStatus: (state, action: PayloadAction<CachingSlice["status"]>) => {
      state.status = action.payload;
    },
    setApiErrorCode: (
      state,
      action: PayloadAction<CachingSlice["apiErrorCode"]>
    ) => {
      state.apiErrorCode = action.payload;
    },
    setConfigDetailsStatus: (
      state,
      action: PayloadAction<CachingSlice["configDetailsStatus"]>
    ) => {
      state.configDetailsStatus = action.payload;
    },
  },
});

export const {
  setProperties,
  setConfigurations,
  addConfiguration,
  setProduction,
  setSelectedConfiguration,
  clearSelectedConfiguration,
  setErrors,
  addError,
  setCertificates,
  setDefinition,
  updateDefinition,
  deleteDefinition,
  setStatus,
  setApiErrorCode,
  setConfigDetailsStatus,
  updateMatchrule,
} = cachingSlice.actions;

export default cachingSlice.reducer;
