import { ReactElement } from "react";
import styled from "@emotion/styled";

export interface IField {
  label: string;
  value: string | null;
}

export interface CertificateMetadataProps {
  fields: IField[];
}

export const CertificateMetadata = ({
  fields,
}: CertificateMetadataProps): ReactElement => (
  <MetaDataContainer>
    {fields.map(
      (field, i) =>
        field.value && (
          <MetaData key={i}>
            <Label className="-text--md">{field.label}</Label>
            <Data>{field.value}</Data>
          </MetaData>
        )
    )}
  </MetaDataContainer>
);

const MetaDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;

const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

const Data = styled.h6`
  margin: 0 !important;
`;
