import { ReactElement } from "react";

import { useDropdown } from "./useDropdown";
import { DropDownContent } from "./DropdownContent";
import { DropdownMenu } from "../../atoms/DropDownMenu/DropDownMenu";
import { IDropdownItem } from "../../molecules/DropdownOption/DropdownOption";
import { toggleBool } from "../../../utils/toggleBool";

export interface IHeadlessDropDownProps<T = string> {
  items: IDropdownItem<T>[];
  button: (props: { onClick: () => void; open: boolean }) => JSX.Element;
  multiSelect?: boolean;
  selectedItems: IDropdownItem<T>[];
  setSelectedItems: (selection: IDropdownItem<T>[]) => void;
  position?: "left" | "right";
}

export const HeadlessDropDown = <T,>({
  items,
  button,
  multiSelect = false,
  selectedItems,
  setSelectedItems,
  position = "right",
}: IHeadlessDropDownProps<T>): ReactElement => {
  const {
    isOpen,
    setIsOpen,
    dropdownRef,
    opening,
    handleSelection,
  } = useDropdown<T>({
    items,
    multiSelect,
    selectedItems,
    setSelectedItems,
  });
  return (
    <div className="chi-dropdown" ref={dropdownRef}>
      {button({
        onClick: () => {
          setIsOpen(toggleBool);
        },
        open: isOpen,
      })}
      {opening(
        (styles, show) =>
          show && (
            <DropdownMenu
              style={styles}
              className="chi-dropdown__menu"
              position={position}
            >
              <DropDownContent
                items={items}
                isOpen={isOpen}
                onSelect={handleSelection}
                selectedItems={selectedItems || []}
                multiSelect={multiSelect}
              />
            </DropdownMenu>
          )
      )}
    </div>
  );
};
