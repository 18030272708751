import { OriginDefinitionType } from "../../../store/slices/caching/types";

export enum Protocols {
  HTTP = "http",
  HTTPS = "https",
}

export enum OriginTypes {
  SINGLE = "singleOrigin",
  FAILOVER = "failoverGroup",
}

export const ORIGIN_USE_CLIENT_HOST_VALUE = "$oreq.h.host";
export const DEFAULT_HTTPS_PORT = 443;
export const DEFAULT_HTTP_PORT = 80;
export const DEFAULT_TIMEOUT_RETRIES = 0;
export const DEFAULT_WEBROOT = "/";
export const MINIMUM_HTTP_TIMEOUT = 0.001;

interface Origin {
  host: string;
  protocol: "http" | "https";
  port: number;
  webroot?: string;
  address?: string;
  ignoreInvalidCert?: boolean;
  httpTimeout?: number;
  connectTimeout?: number;
  timeoutRetries?: number;
}

export interface IAwsv4 {
  accessKeyId: string;
  secret: string;
  region?: string;
  hostname?: string;
}

export interface IHeadersAuthentication {
  name: string;
  value: string;
}

export interface IMember {
  name: string;
  origin: Origin;
  authentication?: {
    type: AuthenticationTypes;
    parameters: IHeadersAuthentication[] | IAwsv4;
  };
}

export interface IOrigin {
  expression?: string;
  signature?: string;
  failoverOn4xx?: boolean;
  failoverOn5xx?: boolean;
}

export interface IOriginDefinition {
  type: OriginTypes;
  members: IMember[];
  conditions?: IOrigin;
}

export enum AuthenticationTypes {
  AWSV4 = "awsV4",
  HEADERS = "headers",
}

export const getOriginDefinitionDescription = (
  def: OriginDefinitionType
): string => `type: ${def.originType}`;
