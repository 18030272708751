import type { ReactElement } from "react";
import type { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { RadioButtonsSelector } from "../../../../../../../../../components/molecules/RadioButtonsSelector/RadioButtonsSelector";
import type { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { FeatureBlock } from "./FeatureBlock";

interface FollowRedirectsProps {
  register: UseFormRegister<IFeatures>;
  initIsOpen?: boolean;
  onDelete: () => void;
}

export const FollowRedirects = ({
  register,
  initIsOpen,
  onDelete,
}: FollowRedirectsProps): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");
  const disabled = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_FOLLOW_REDIRECTS_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_FOLLOW_REDIRECTS_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_FOLLOW_REDIRECTS_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_FOLLOW_REDIRECTS_RADIOS_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_FOLLOW_REDIRECTS_RADIOS_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_FOLLOW_REDIRECTS_RADIOS_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          fieldProps={{
            other: (
              <RadioButtonsSelector
                inline
                headlessRadio={true}
                radioButtonsProps={[
                  {
                    ...register("followRedirects"),
                    value: "never",
                    disabled,
                    label: t("FEATURE_CARD_FOLLOW_REDIRECTS_RADIO_1_LABEL"),
                    id: "follow-redirects-never",
                    dataTestId: "follow-redirects-radio-never",
                  },
                  {
                    ...register("followRedirects"),
                    value: "always",
                    disabled,
                    label: t("FEATURE_CARD_FOLLOW_REDIRECTS_RADIO_2_LABEL"),
                    id: "follow-redirects-always",
                    dataTestId: "follow-redirects-radio-always",
                  },
                ]}
              />
            ),
          }}
          dataTestId="follow-redirects"
        />
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName="followRedirects"
          onDelete={onDelete}
          dataTestId="followRedirects"
        />
      }
      dataTestId="follow-redirects"
      featureType={FeatureTypes.REQUEST_MANIPULATION}
    />
  );
};
