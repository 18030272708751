import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Center } from "../../../../components/atoms/Center/Center";
import { Loader } from "../../../../components/atoms/Loader/Loader";
import { TextButton } from "../../../../components/atoms/TextButton/TextButton";
import { ComparisonHeader } from "../../../../components/organisms/ComparisonHeader/ComparisonHeader";
import { ConfigNamesRow } from "../../../../components/organisms/ComparisonHeader/ConfigNamesRow";
import { DiffEditor } from "../../../../components/organisms/DiffEditor/DiffEditor";
import { useBool } from "../../../../hooks/use-bool/useBool";
import { useOrderedDefinitions } from "../../../../hooks/use-ordered-definitions/useOrderedDefinitions";
import { useConfigurations } from "../../../../store/slices/caching/hooks";
import { getConfigurationVersionDetailsToCompare } from "../../../../store/slices/caching/services";
import { ConfigurationType } from "../../../../store/slices/caching/types";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";
import { getConfigDetailsByDefinitions } from "../../../../utils/getConfigDetailsByDefinitions";
import { IDeploymentModalData } from "./types";

interface EditorModalProps {
  configBase: IDeploymentModalData;
  configToCompare: ConfigurationType;
  onCancelClick: () => void;
}

export const EditorModal = ({
  configBase,
  configToCompare,
  onCancelClick,
}: EditorModalProps): ReactElement => {
  const mountedRef = useRef(false);
  const configurations = useConfigurations();
  const handleMediaPortalError = useDispatchAPIError();
  const { t } = useTranslation("misc");

  const [configExtended, setConfigExtended] = useState<ConfigurationType>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    mountedRef.current = true;

    setLoading(true);

    const configToGetDetails = configurations.find(
      (c) => c.configName === configBase.configName
    )!;

    getConfigurationVersionDetailsToCompare(
      configToGetDetails,
      configBase.versionId
    )
      .then((res) => {
        if (mountedRef.current) {
          setConfigExtended(res);
        }
      })
      .catch(handleMediaPortalError)
      .finally(() => {
        if (mountedRef.current) {
          setLoading(false);
        }
      });
  }, []);

  return (
    <EditorModalContainer width={window.innerWidth * 0.9}>
      {loading ? (
        <StyledCenter>
          <Loader />
        </StyledCenter>
      ) : !mountedRef.current ? (
        <StyledCenter>Fetching details</StyledCenter>
      ) : !configExtended ? (
        <StyledCenter>Failed to fetch details</StyledCenter>
      ) : (
        <>
          <CTAContainer>
            <CTA
              onClick={onCancelClick}
              data-testid="raw-json-close-the-comparison"
            >
              {t("CLOSE_COMPARISON")}
            </CTA>
          </CTAContainer>
          <Comparison configurations={[configExtended, configToCompare]} />
        </>
      )}
    </EditorModalContainer>
  );
};

const Comparison = ({
  configurations,
}: {
  configurations: ConfigurationType[];
}): ReactElement => {
  const [c1, c2] = configurations;
  const [autoScroll, toggleAutoScroll] = useBool(true);
  const {
    definitionsToDisplay,
    setDefinitionsToDisplay,
  } = useOrderedDefinitions();

  return (
    <>
      <StyledConfigNamesRow>
        <ComparisonHeader
          autoScroll={autoScroll}
          config1Name={c1.configName}
          config1Version={c1.versionId}
          config2Name={c2.configName}
          config2Version={c2.versionId}
          definitionsToDisplay={definitionsToDisplay}
          setDefinitionsToDisplay={setDefinitionsToDisplay}
          toggleAutoScroll={toggleAutoScroll}
        />
      </StyledConfigNamesRow>
      <DiffEditor
        id="json-diff-editor-modal"
        codes={[
          JSON.stringify(
            {
              comment: c1.comment,
              config: c1.config
                ? getConfigDetailsByDefinitions(c1.config, definitionsToDisplay)
                : undefined,
            },
            null,
            2
          ),
          JSON.stringify(
            {
              comment: c2.comment,
              config: c2.config
                ? getConfigDetailsByDefinitions(c2.config, definitionsToDisplay)
                : undefined,
            },
            null,
            2
          ),
        ]}
        autoScroll={autoScroll}
      />
    </>
  );
};

const EditorModalContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  max-width: 1800px;
`;

const StyledConfigNamesRow = styled(ConfigNamesRow)`
  padding: 16px;
  margin-bottom: 0;
`;

const StyledCenter = styled(Center)`
  height: 100%;
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0 1rem;
`;

const CTA = styled(TextButton)`
  color: ${({ theme }) => theme.interactive.buttonPrimary};
  font-size: 13px;
  line-height: 16px;
`;
