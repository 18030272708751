import { Status } from "../../../@types/status";
import { httpClient } from "../../../core/http-client";
import { AppThunk } from "../../store";
import { setFeatureFlags, setStatus } from "./slice";
import { FeatureFlag, FeatureFlags } from "./types";

const baseURL =
  process.env.REACT_APP_FEATURE_FLAGS_HOST ||
  "https://lab-edge-delivery.netauto.nsatc.net/api/feature-flags";

// These flags will be used when there is an error querying the flags while loading the app
const defaultFeatureFlags = {};

export const RENDER_LOADER = "render_loader" as const;

export const fetchFeatureFlags = (
  loading?: typeof RENDER_LOADER
): AppThunk => async (dispatch, getState) => {
  try {
    const { accessGroupId } = getState().accessGroups;
    const shouldRenderLoader = loading === RENDER_LOADER;

    if (typeof accessGroupId === "undefined") {
      throw new Error(
        '"accessGroupId" must be defined to fetch feature flags.'
      );
    }
    if (shouldRenderLoader) {
      dispatch(setStatus(Status.LOADING));
    }

    const { data } = await httpClient.get<{ data: FeatureFlag[] }>("", {
      params: {
        accessGroup: accessGroupId,
      },
      baseURL,
    });

    dispatch(
      setFeatureFlags(
        data.data.reduce((featureFlags, featureFlag) => {
          featureFlags[featureFlag.name] = featureFlag;

          return featureFlags;
        }, {} as FeatureFlags)
      )
    );

    dispatch(setStatus(Status.SUCCESS));
  } catch (err) {
    const { value } = getState().featureFlags;
    if (value === undefined) {
      dispatch(setFeatureFlags(defaultFeatureFlags));
      dispatch(setStatus(Status.ERROR));
    }
  }
};
