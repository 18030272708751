import { ReactElement } from "react";
import { useRouteMatch } from "react-router-dom";

import { ConfigurationHeader } from "../../../../components/organisms/ConfigurationHeader/ConfigurationHeader";
import { MatchRulesHeader } from "../../../../components/organisms/MatchRulesHeader/MatchRulesHeader";
import { PropertyHeader } from "../../../../components/organisms/PropertyHeader/PropertyHeader";
import { RawJsonHeader } from "../../../../components/organisms/RawJsonHeader/RawJsonHeader";
import { getConfigurationProperty } from "../../../../store/slices/caching/helpers/getConfigurationProperty";
import { useSelectedConfiguration } from "../../../../store/slices/caching/hooks";
import { PropertyDefinitionType } from "../../../../store/slices/caching/types";

export const ConfigurationHeaderSwitcher = (): ReactElement | null => {
  const selectedConfiguration = useSelectedConfiguration();

  const rawJsonUrl = useRouteMatch<{
    configName: string;
    propertyName?: string;
  }>([
    "/cmv3/configurations/:configName/raw-json",
    "/cmv3/configurations/:configName/properties/:propertyName/raw-json",
  ]);

  const addPropertyUrl = useRouteMatch<{
    configName: string;
    propertyName: string;
  }>("/cmv3/configurations/:configName/properties/:propertyName");

  const configurationUrl = useRouteMatch<{
    configName: string;
    propertyName: string;
  }>("/cmv3/configurations/:configName/properties/:propertyName/match_rules");

  const saveProperty = () => {
    const event = new CustomEvent("save_config_event");
    document.dispatchEvent(event);
  };

  if (configurationUrl) {
    return (
      <MatchRulesHeader propertyName={configurationUrl.params.propertyName} />
    );
  } else if (rawJsonUrl) {
    return <RawJsonHeader propertyName={rawJsonUrl.params.propertyName} />;
  } else if (addPropertyUrl) {
    // TODO: REWORK ON NULL CASE
    return selectedConfiguration ? (
      <PropertyHeader
        configInModification={selectedConfiguration}
        onSave={saveProperty}
        property={
          getConfigurationProperty(
            selectedConfiguration,
            addPropertyUrl.params.propertyName
          ) ||
          ({
            name: addPropertyUrl.params.propertyName,
          } as PropertyDefinitionType)
        }
      />
    ) : null;
  } else {
    return <ConfigurationHeader />;
  }
};
