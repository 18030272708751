import { ORDERED_DEF_NAMES } from "../../store/slices/caching/constants";

export const getOrderedDefNames = (
  definitions: typeof ORDERED_DEF_NAMES
): typeof ORDERED_DEF_NAMES =>
  ORDERED_DEF_NAMES.reduce((orderedDefNames, defName) => {
    if (definitions.includes(defName)) {
      orderedDefNames.push(defName);
    }

    return orderedDefNames;
  }, [] as typeof definitions);
