import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type GeneratedResponseComponent = FeatureComponent<
  Exclude<IFeatures["generatedResponse"], undefined>
>;

export const GeneratedResponse: GeneratedResponseComponent = ({
  feature,
  tPropertyPage,
}) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_GENERATED_RESPONSE_TITLE")}
    >
      {feature}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
