import { ReactElement } from "react";

import { useAppDispatch } from "../../../../store/types";
import { BasicSubHeader } from "../BasicSubHeader/BasicSubHeader";
import { handleSetAccessGroupId } from "../../../../store/slices/access-groups/thunks";
import { handleClearSubscriber } from "../../../../store/slices/subscriber/thunks";

export const SubHeaderAccessGroup = (): ReactElement => {
  const dispatch = useAppDispatch();

  const handleSelectAccessGroup = (newAccessGroupId: number) => {
    dispatch(handleSetAccessGroupId(newAccessGroupId));
    // Remove subscriber data in memory and local storage
    // when changing access group without selecting a new scid.
    dispatch(handleClearSubscriber());
  };

  return (
    <BasicSubHeader hideScids onAccessGroupSelect={handleSelectAccessGroup} />
  );
};
