import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";
import { FeatureComponent } from "./types";

type DenyPolicyType = Exclude<IFeatures["denyPolicy"], undefined>;

type DenyPolicyHeaders = Exclude<DenyPolicyType["headers"], undefined>;

type DenyPolicyComponent = FeatureComponent<DenyPolicyType>;

const DenyPolicyHeader = ({ name, value }: DenyPolicyHeaders[number]) =>
  renderColContent(`${name} - ${value}`);

export const DenyPolicy: DenyPolicyComponent = ({
  feature: { denial, headers },
  tPropertyPage,
  dataTestId,
}) => (
  <DetailedFeatureCardRow>
    {!!(headers && headers.length > 0) && (
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_DENY_POLICY_HEADERS_TITLE")}
        dataTestId={`${dataTestId}-headers`}
      >
        <ExpandableList dataTestId="denyPolicy">
          {headers.map(DenyPolicyHeader)}
        </ExpandableList>
      </DetailedFeatureCardBlock>
    )}

    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_DENY_POLICY_ACTION_TITLE")}
      dataTestId={`${dataTestId}-action`}
    >
      {denial.action}
    </DetailedFeatureCardBlock>

    {denial.action === "redirect" && (
      <DetailedFeatureCardBlock
        title={tPropertyPage("FEATURE_CARD_DENY_POLICY_REDIRECT_URL_TITLE")}
        dataTestId={`${dataTestId}-redirect`}
      >
        {denial.redirectUrl}
      </DetailedFeatureCardBlock>
    )}
  </DetailedFeatureCardRow>
);
