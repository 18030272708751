import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactElement } from "react";

import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { Button as DeleteButton } from "../../atoms/Button/Button";
import {
  FileDropzoneProps,
  GetBorderStyle,
  FileDropZone,
} from "../FileDropzone/FileDropzone";
import { InputLabel } from "./InputLabel";

interface FileUploadProps
  extends Required<Pick<FileDropzoneProps, "maxFiles">> {
  files: File[];
  setFiles: FileDropzoneProps["onFileChange"];
  onDeleteFile: (fileI: number) => unknown;
}

const getDropdzoneBorderStyle: GetBorderStyle = (theme, isRejected) =>
  `1px dashed ${isRejected ? theme.colors.red60 : theme.borders.mutedLight}`;

const Files = ({
  files,
  maxFiles,
  onClick,
  onDeleteFile,
}: Pick<FileUploadProps, "files" | "onDeleteFile" | "maxFiles"> &
  Pick<UploadMoreProps, "onClick">): ReactElement => {
  const availableSpot = maxFiles - files.length;
  const canUploadMore = availableSpot > 0;

  return (
    <>
      {files.map((file, index) => (
        <FileRow key={file.name + file.size}>
          <FileName>{file.name}</FileName>
          <DeleteButton
            onClick={() => {
              onDeleteFile(index);
            }}
            backgroundColor="red60"
            textColor="primaryAlt"
            label="Delete file"
          />
        </FileRow>
      ))}
      {canUploadMore && (
        <UploadMore onClick={onClick} availableSpot={availableSpot} />
      )}
    </>
  );
};

interface UploadMoreProps {
  availableSpot: number;
  onClick: () => void;
}

const UploadMore = ({
  availableSpot,
  onClick,
}: UploadMoreProps): ReactElement => {
  let content = `Add ${availableSpot} more file`;

  if (availableSpot > 1) {
    content += "s";
  }

  return (
    <div>
      <StyledA onClick={onClick}>{content}</StyledA>
    </div>
  );
};

export const FileUpload = ({
  files,
  setFiles,
  onDeleteFile,
  maxFiles,
}: FileUploadProps): JSX.Element => {
  const fileUploaded = files.length > 0;

  return (
    <UploadContainer className="feedback-mb" fileUploaded={fileUploaded}>
      <InputLabel>Attachment files</InputLabel>
      <FileDropZone
        onFileChange={setFiles}
        getBorderStyle={getDropdzoneBorderStyle}
        accept={[".jpeg", ".jpg", ".png"]}
        maxFiles={maxFiles}
      >
        {({ open, isRejected }) => (
          <>
            {isRejected && (
              <StyledRejectedP>
                You can upload up to 3 images only.
              </StyledRejectedP>
            )}
            {fileUploaded ? (
              <Files
                files={files}
                maxFiles={maxFiles}
                onClick={open}
                onDeleteFile={onDeleteFile}
              />
            ) : (
              <>
                <StyledP>
                  Drag a file here or <StyledA onClick={open}>browse</StyledA>{" "}
                  for a file to upload.
                </StyledP>
                <UploadIcon />
              </>
            )}
          </>
        )}
      </FileDropZone>
    </UploadContainer>
  );
};

const UploadContainer = styled.div<{ fileUploaded: boolean }>`
  & .dropzone {
    background-color: ${({ theme }) => theme.backgrounds.baseLight};
    ${({ fileUploaded }) =>
      fileUploaded &&
      css`
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: start;
      `}
  }
`;

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FileName = styled.p``;

const StyledP = styled.p`
  text-align: center;
`;

const StyledRejectedP = styled(StyledP)`
  color: ${({ theme }) => theme.text.error};
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.text.highlight} !important;

  &:hover {
    cursor: pointer;
    text-decoration: none !important;
  }
`;
