import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { ArrowLeftIcon } from "../../../../../components/atoms/ArrowLeftIcon/ArrowLeftIcon";
import { Button } from "../../../../../components/atoms/Button/Button";
import {
  FlexBox,
  FlexBoxBase,
} from "../../../../../components/atoms/FlexBox/FlexBox";
import { PageContainer } from "../../../../../components/atoms/PageContainer/PageContainer";
import { Divider } from "../../../../../components/atoms/Divider/Divider";
import { PageTitle } from "../../../../../components/atoms/PageTitle/PageTitle";
import { ApiKeyActions, ApiKeysCustomerFilter, ApiKeysStatus } from "../types";
import { Badge } from "../../../../../components/atoms/Badge/Badge";
import {
  ApiKeyData,
  ApiKeysOwners,
  ApiKeysRoles,
} from "../../../../../models/user-management/apiKeys/apiKeys.interfaces";
import { RowInfo } from "../../../../../components/atoms/RowInfo/RowInfo";
import { RowInfoDetails } from "../../../../../components/molecules/RowInfoDetails/RowInfoDetails";
import { ApiKeysActions } from "../ApiKeysActions";
import { ApiKeyDetails } from "./ApiKeyDetails";
import { useState } from "react";
import { useAppDispatch } from "../../../../../store/types";
import {
  handleUpdateCdnApiKey,
  handleUpdateVyvxApiKey,
} from "../../../../../store/slices/user-management-api-keys/thunk";
import { VyvxFiberCompany } from "../../../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { RowInfoDetailsWithOwner } from "../../../../../components/molecules/RowInfoDetailsWithOwner/RowInfoDetailsWithOwner";

interface ApiKeyPageContentProps {
  apiKeyInfo: ApiKeyData;
  isVyvx: boolean;
  roles: ApiKeysRoles[];
  owners: ApiKeysOwners[];
  fiberCompanies?: VyvxFiberCompany[];
}

export const ApiKeyPageContent = ({
  apiKeyInfo,
  isVyvx,
  roles,
  owners,
  fiberCompanies,
}: ApiKeyPageContentProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const history = useHistory();
  const dispatch = useAppDispatch();

  const roleName = roles.find((elem) => Number(elem.id) === apiKeyInfo.roleId)
    ?.name;

  const [keyRole, setKeyRole] = useState<string>(roleName ? roleName : "");
  const [keyOwner, setKeyOwner] = useState<string>(apiKeyInfo.contactUserName);
  const [keyName, setKeyName] = useState<string>(apiKeyInfo.name);
  const [keyNotes, setKeyNotes] = useState<string>(apiKeyInfo.notes);
  const [keyCustomer, setKeyCustomer] = useState<ApiKeysCustomerFilter>({
    customerName: isVyvx
      ? apiKeyInfo.vyvxCompanyAbbr
        ? apiKeyInfo.vyvxCompanyAbbr
        : ""
      : apiKeyInfo.accessGroupName
      ? apiKeyInfo.accessGroupName
      : "",
    customerId: isVyvx
      ? apiKeyInfo.vyvxCompanyAbbr
        ? apiKeyInfo.vyvxCompanyAbbr
        : "0"
      : apiKeyInfo.accessGroupId
      ? String(apiKeyInfo.accessGroupId)
      : "0",
  });

  const apiKeysActionsDropdownItems = [
    {
      item: {
        label: ApiKeyActions.ENABLE,
        value: "Enable",
      },
    },
    {
      item: {
        label: ApiKeyActions.DISABLE,
        value: "Disable",
      },
    },
    {
      item: {
        label: ApiKeyActions.DELETE,
        value: "Delete",
      },
    },
  ];

  const updateApiKeyInfos = () => {
    const roleId = roles.find((roleItem) => roleItem.name === keyRole)?.id;
    const contactId = owners.find((ownerItem) => ownerItem.name === keyOwner)
      ?.id;

    if (isVyvx && keyCustomer.customerId && contactId && roleId) {
      const body = {
        contactId,
        name: keyName,
        notes: keyNotes,
        vyvxRoleId: Number(roleId),
      };
      dispatch(
        handleUpdateVyvxApiKey(
          keyCustomer.customerId,
          Number(apiKeyInfo.id),
          body
        )
      );
    } else if (keyCustomer.customerId && contactId && roleId) {
      const body = {
        name: keyName,
        contactId,
        accessGroupId: Number(keyCustomer.customerId),
        roleId: Number(roleId),
        notes: keyNotes,
      };
      dispatch(
        handleUpdateCdnApiKey(
          Number(apiKeyInfo.id),
          Number(keyCustomer.customerId),
          body
        )
      );
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <FlexBox>
          <ArrowLeftIcon
            onClick={() => {
              history.push("/userManagement/apiKeys");
            }}
          />
          <PageTitle>{apiKeyInfo?.id}</PageTitle>
        </FlexBox>
        <ButtonsContainer>
          <ApiKeysActions
            apiKeyId={Number(apiKeyInfo.id)}
            companyId={apiKeyInfo.vyvxCompanyAbbr}
            accessGroupId={apiKeyInfo.accessGroupId}
            apiKeyStatus={apiKeyInfo.status}
            apiKeysActionDropdownItems={apiKeysActionsDropdownItems}
            isVyvx={isVyvx}
          />
          <Button
            onClick={() => {
              history.push(`/userManagement/vyvx/apiKeys/${apiKeyInfo.id}`);
            }}
            label={t("API_KEY_PAGE_CANCEL_BUTTON")}
            borderColor="blue60"
            backgroundColor="baseLight"
            textColor="blue60"
          />
          <Button
            onClick={updateApiKeyInfos}
            label={t("API_KEY_PAGE_SAVE_BUTTON")}
          />
        </ButtonsContainer>
      </HeaderContainer>
      <RowInfo>
        <RowInfoDetails
          title={t("API_KEYS_TABLE_STATUS_PLACEHOLDER")}
          value={
            <Badge
              label={apiKeyInfo.status}
              color={
                apiKeyInfo.status === ApiKeysStatus.ACTIVE
                  ? "green50"
                  : "grey60"
              }
            />
          }
        />
        <RowInfoDetailsWithOwner
          title={t("API_KEYS_TABLE_CREATED_DATE_LABEL")}
          value={format(new Date(apiKeyInfo.createdDate), "LLL d, yyyy")}
          owner={apiKeyInfo.createdUser}
        />
        <RowInfoDetailsWithOwner
          title={t("API_KEYS_TABLE_MODIFIED_DATE_LABEL")}
          value={format(new Date(apiKeyInfo.updatedDate), "LLL d, yyyy")}
          owner={apiKeyInfo.updatedUser}
        />
      </RowInfo>
      <StyledDivider />
      <ApiKeyDetails
        isVyvx={isVyvx}
        roles={roles}
        owners={owners}
        apiKeyId={apiKeyInfo.id}
        keyRole={keyRole}
        setKeyRole={setKeyRole}
        keyOwner={keyOwner}
        setKeyOwner={setKeyOwner}
        keyCustomer={keyCustomer}
        setKeyCustomer={setKeyCustomer}
        keyName={keyName}
        setKeyName={setKeyName}
        keyNotes={keyNotes}
        setKeyNotes={setKeyNotes}
        keySecret={apiKeyInfo.secretKey}
        fiberCompanies={fiberCompanies}
      />
    </Container>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
`;

const HeaderContainer = styled(FlexBox)`
  justify-content: space-between;
  padding-bottom: 22px;
`;

const ButtonsContainer = styled(FlexBoxBase)`
  & > * {
    margin-right: 16px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 40px !important;
  margin-bottom: 40px !important;
`;
