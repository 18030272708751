import { ForwardedRef, forwardRef, ReactNode } from "react";

interface AnchorWrapperProps {
  children: ReactNode;
}

type AnchroWrapperSignature = (
  props: AnchorWrapperProps & {
    ref: ForwardedRef<HTMLDivElement> | undefined;
    id: string;
  }
) => JSX.Element;

export const AnchorWrapper = forwardRef<HTMLDivElement, AnchorWrapperProps>(
  ({ children, ...props }, ref) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
) as AnchroWrapperSignature;
