import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Dropdown,
  DropdownPosition,
  IDropdownItem,
} from "../../../../components/organisms/Dropdown/Dropdown";
import { ApiKeyActions } from "./types";
import { useAppDispatch } from "../../../../store/types";
import { Modal } from "../../../../components/atoms/Modal/Modal";
import { PresentationModal } from "../../../../components/molecules/PresentationModal/PresentationModal";
import {
  handleActivateCdnApiKey,
  handleActivateVyvxApiKey,
  handleDeleteCdnApiKey,
  handleDeleteVyvxApiKey,
  handleDisableCdnApiKey,
  handleDisableVyvxApiKey,
  handleGenerateSecretCdnApiKey,
  handleGenerateSecretVyvxApiKey,
} from "../../../../store/slices/user-management-api-keys/thunk";
import { CustomRemark } from "../../../../components/atoms/CustomRemark/CustomRemark";
import { useState } from "react";
import { Icon, Icons } from "../../../../components/atoms/Icon/Icon";

interface ApiKeysActionsProps {
  apiKeyId: number;
  companyId: string | null;
  accessGroupId: number | null;
  apiKeyStatus: string;
  apiKeysActionDropdownItems: {
    item: IDropdownItem<string>;
  }[];
  isVyvx: boolean;
}

export const ApiKeysActions = ({
  apiKeyId,
  companyId,
  accessGroupId,
  apiKeyStatus,
  apiKeysActionDropdownItems,
  isVyvx,
}: ApiKeysActionsProps): JSX.Element => {
  const { t } = useTranslation("apiKeysPage");
  const dispatch = useAppDispatch();

  const [openSecretModal, setOpenSecretModal] = useState(false);
  const [openEnableModal, setOpenEnableModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <ActionsContainer>
      <Dropdown
        id="api-key-card-dropdown"
        placeholder=""
        customButton={<Icon name={Icons.TRIPLE_DOT} size={20} />}
        onSelect={(item) => {
          switch (item.label) {
            case ApiKeyActions.SECRET:
              setOpenSecretModal(true);
              break;
            case ApiKeyActions.ENABLE:
              setOpenEnableModal(true);
              break;
            case ApiKeyActions.DISABLE:
              setOpenDisableModal(true);
              break;
            case ApiKeyActions.DELETE:
              setOpenDeleteModal(true);
              break;
          }
        }}
        outline={false}
        items={apiKeysActionDropdownItems.map(
          (dropdownItem) => dropdownItem.item
        )}
        position={DropdownPosition.LEFT}
      />
      <Modal
        open={openSecretModal}
        customButton={<></>}
        onClose={() => {
          setOpenSecretModal(false);
        }}
        size="medium"
      >
        <PresentationModal
          title={t("MODAL_SECRET_TITLE")}
          button={{ label: t("BUTTON_LABEL_OK") }}
          onSubmit={() => {
            if (companyId && isVyvx) {
              dispatch(
                handleGenerateSecretVyvxApiKey(apiKeyId, companyId, () => {
                  toast.success(t("TOAST_GENERATE_SECRET"));
                  // TODO: Refresth page / list
                })
              );
            } else if (accessGroupId) {
              dispatch(
                handleGenerateSecretCdnApiKey(apiKeyId, accessGroupId, () => {
                  toast.success(t("TOAST_GENERATE_SECRET"));
                  // TODO: Refresth page / list
                })
              );
            }
          }}
        >
          <CustomRemark>{t("MODAL_SECRET_CONTENT")}</CustomRemark>
        </PresentationModal>
      </Modal>
      <Modal
        open={openEnableModal}
        customButton={<></>}
        onClose={() => {
          setOpenEnableModal(false);
        }}
        size="medium"
      >
        <PresentationModal
          title={t("MODAL_ENABLE_TITLE")}
          button={{ label: t("BUTTON_LABEL_OK") }}
          onSubmit={() => {
            if (companyId && isVyvx) {
              dispatch(
                handleActivateVyvxApiKey(apiKeyId, companyId, () => {
                  toast.success(t("TOAST_ENABLE_KEY"));

                  // TODO: Refresth page / list
                })
              );
            } else if (accessGroupId) {
              dispatch(
                handleActivateCdnApiKey(apiKeyId, accessGroupId, () => {
                  toast.success(t("TOAST_ENABLE_KEY"));
                  // TODO: Refresth page / list
                })
              );
            }
          }}
        >
          <CustomRemark>{t("MODAL_ENABLE_CONTENT")}</CustomRemark>
        </PresentationModal>
      </Modal>
      <Modal
        open={openDisableModal}
        customButton={<></>}
        onClose={() => {
          setOpenDisableModal(false);
        }}
        size="medium"
      >
        <PresentationModal
          title={t("MODAL_DISABLE_TITLE")}
          button={{ label: t("BUTTON_LABEL_OK") }}
          onSubmit={() => {
            if (companyId && isVyvx) {
              dispatch(
                handleDisableVyvxApiKey(apiKeyId, companyId, () => {
                  toast.success(t("TOAST_DISABLE_KEY"));
                  // TODO: Refresth page / list
                })
              );
            } else if (accessGroupId) {
              dispatch(
                handleDisableCdnApiKey(apiKeyId, accessGroupId, () => {
                  toast.success(t("TOAST_DISABLE_KEY"));
                  // TODO: Refresth page / list
                })
              );
            }
          }}
        >
          <CustomRemark>{t("MODAL_DISABLE_CONTENT")}</CustomRemark>
        </PresentationModal>
      </Modal>
      <Modal
        open={openDeleteModal}
        customButton={<></>}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        size="medium"
      >
        <PresentationModal
          title={t("MODAL_DELETE_TITLE")}
          button={{
            label: t("BUTTON_LABEL_DELETE"),
            backgroundColor: "red60",
            textColor: "primaryAlt",
          }}
          onSubmit={() => {
            if (companyId && isVyvx) {
              dispatch(
                handleDeleteVyvxApiKey(apiKeyId, companyId, () => {
                  toast.success(t("TOAST_DELETE_KEY"));
                  // TODO: Refresth page / list
                })
              );
            } else if (accessGroupId) {
              dispatch(
                handleDeleteCdnApiKey(apiKeyId, accessGroupId, () => {
                  toast.success(t("TOAST_DELETE_KEY"));
                  // TODO: Refresth page / list
                })
              );
            }
          }}
        >
          <CustomRemark>
            {t("MODAL_DELETE_CONTENT", { status: apiKeyStatus })}
          </CustomRemark>
        </PresentationModal>
      </Modal>
    </ActionsContainer>
  );
};

const ActionsContainer = styled.div`
  margin-top: 15px;
`;
