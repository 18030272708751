import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ReactComponent as SimpleArrowSvg } from "../../../assets/icons/arrow_simple.svg";
import { UserRoles } from "../../../models/permissions";
import { Badge } from "../../atoms/Badge/Badge";
import { Protected } from "../../atoms/Protected/Protected";
import { TrashIcon } from "../../atoms/TrashIcon/TrashIcon";
import { ExpandableContent } from "../ExpandableContent/ExpandableContent";
import { FeatureType, FeatureTypes } from "../FeatureType/FeatureType";
import { ColorSet } from "../../../contexts/themeContext";

export interface IPropertyDefinitionBlockProps {
  propertyCards?: JSX.Element;
  title?: string;
  badgeLabel?: string;
  badgeColor?: keyof ColorSet["colors"];
  displayArrow?: boolean;
  onArrowClick?: (isOpen: boolean) => void;
  onDelete?: () => void;
  alwaysOpen?: boolean;
  initIsOpen?: boolean;
  dataTestId?: string;
  customDeleteButton?: JSX.Element;
  additionalInfoTitle?: string;
  additionalInfoContent?: string;
  featureType?: FeatureTypes;
}

export const PropertyDefinitionBlock: React.FC<IPropertyDefinitionBlockProps> = ({
  propertyCards,
  title,
  badgeLabel,
  badgeColor,
  displayArrow = true,
  onArrowClick,
  onDelete,
  alwaysOpen = true,
  initIsOpen = false,
  dataTestId,
  customDeleteButton,
  additionalInfoTitle,
  additionalInfoContent,
  featureType,
}) => {
  const [isOpen, setIsOpen] = useState(initIsOpen);
  const shouldOpen = alwaysOpen || isOpen;
  const isFeatureType = !!featureType;

  // This useEffect is needed to hydrate the component after it's mounted
  // https://stackoverflow.com/a/59263342/7280833
  useEffect(() => {
    setIsOpen(initIsOpen);
  }, [initIsOpen]);

  useEffect(() => {
    onArrowClick && onArrowClick(isOpen);
  }, [isOpen]);

  return (
    <BlockContainer data-testid={`property-definition-block-${dataTestId}`}>
      {title ? (
        <UpperPart>
          <MidPart>
            <SubPart>
              <Title isFeatureType={isFeatureType}>{title}</Title>
              {isFeatureType && (
                <FeatureType featureType={featureType as FeatureTypes} />
              )}
              {badgeLabel && <Badge label={badgeLabel} color={badgeColor} />}
            </SubPart>

            {shouldOpen && additionalInfoTitle && (
              <Description>
                <ExpandableContent
                  title={additionalInfoTitle}
                  content={additionalInfoContent}
                />
              </Description>
            )}
          </MidPart>
          <SubPart justify={!!onDelete || !!customDeleteButton}>
            {(onDelete || customDeleteButton) && (
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                {customDeleteButton ? (
                  customDeleteButton
                ) : (
                  <TrashIcon
                    onClick={() => onDelete && onDelete()}
                    dataTestId={dataTestId && `${dataTestId}-delete-button`}
                  />
                )}
                {(onArrowClick || displayArrow) && <Divider />}
              </Protected>
            )}
            {(onArrowClick || displayArrow) && (
              <SimpleArrowIcon
                isopen={isOpen ? 1 : 0}
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                data-testid={`property-definition-block-${dataTestId}-open-arrow`}
              />
            )}
          </SubPart>
        </UpperPart>
      ) : null}
      {shouldOpen && propertyCards}
    </BlockContainer>
  );
};

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  background-color: ${({ theme }) =>
    `${theme.backgrounds.baseLight} !important`};
  border-radius: 8px;

  width: 100%;
  padding: 32px;
  margin-bottom: 20px;
  border: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`};
`;

const UpperPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.h4<{ isFeatureType: boolean }>`
  margin: 0 0 !important;
  ${({ isFeatureType }) =>
    !isFeatureType &&
    css`
      margin-right: 20px !important;
    `}
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin: 0 !important;
  color: ${({ theme }) => theme.text.secondary};
`;

const SubPart = styled.div<{ justify?: boolean }>`
  display: flex;
  align-items: center;
  ${({ justify }) => {
    return (
      justify &&
      css`
        width: 5rem;
        justify-content: ${justify ? "space-between" : "end"};
      `
    );
  }}
`;

const MidPart = styled.div`
  margin-right: auto;
`;

const SimpleArrowIcon = styled(SimpleArrowSvg)<{ isopen: number }>`
  cursor: pointer;
  transition: transform 0.2s;
  transform: rotate(${({ isopen }) => (isopen ? "180deg" : "0deg")});
  stroke: ${({ theme }) => theme.backgrounds.highlight};
`;

const Divider = styled.div`
  width: 2px;
  height: 1rem;
  border-left: 2px solid ${({ theme }) => `${theme.borders.base} !important`};
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
`;
