import { IConfigDetailsData } from "../../../../models/configuration/configuration_details";
import {
  IMatchGroup,
  IMatchRule,
} from "../../../../models/configuration/definitions";
import {
  convertCCHO,
  convertDefaultCache,
  convertFailedRefreshTTL,
  convertNegativeTTL,
} from "../../../../utils/matchRuleUtils";

const refactorMatchRule = (matchRule: IMatchRule): void => {
  convertCCHO(matchRule.features.cacheControlHeaderOverride);
  convertDefaultCache(matchRule.features.defaultCache);
  convertFailedRefreshTTL(matchRule.features.failedRefreshTTL);
  convertNegativeTTL(matchRule.features.negativeTTL);
};

const stringToInteger = (matchGroup: IMatchGroup) => {
  matchGroup.matchRules.forEach(refactorMatchRule);
};

export const preCommit = (config: IConfigDetailsData): IConfigDetailsData => {
  if (typeof config.matchLogicDefinitions !== "undefined") {
    Object.keys(config.matchLogicDefinitions).forEach((matchLogic) => {
      const matchBlocks = config.matchLogicDefinitions![matchLogic].matchBlocks;
      matchBlocks?.clientReq?.matchGroups.forEach(stringToInteger);
      matchBlocks?.originReq?.matchGroups.forEach(stringToInteger);
      matchBlocks?.originResp?.matchGroups.forEach(stringToInteger);
    });
  }

  return config;
};
