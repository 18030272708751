import { format } from "date-fns";
import { CertificateType } from "../types";
import { getCertificateStatus } from "./getCertificateStatus";

export const getCertMetadata = (
  cert: CertificateType
): {
  label: string;
  value: string | null;
}[] => [
  {
    label: "Created At",
    value: cert.creation_time
      ? format(new Date(cert.creation_time), "MMM do y 'at' h:mm a")
      : null,
  },
  {
    label: "State",
    value: getCertificateStatus(cert.cert_states).join(", "),
  },
  {
    label: "From",
    value: cert.validity_not_before
      ? format(new Date(cert.validity_not_before), "MMM do y 'at' h:mm a")
      : null,
  },
  {
    label: "To",
    value: cert.validity_not_after
      ? format(new Date(cert.validity_not_after), "MMM do y 'at' h:mm a")
      : null,
  },
  { label: "Type", value: cert.type },
];
