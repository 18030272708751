import { useMemo } from "react";
import styled from "@emotion/styled";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { DragAndDropList } from "../../../../../../../../components/organisms/DragAndDropList/DragAndDropList";
import { IItem } from "../../../../../../../../components/organisms/DragAndDropList/useDragAndDrop";
import {
  IMatchGroup,
  IMatchRule,
} from "../../../../../../../../models/configuration/definitions";
import { ConfigurationErrorType } from "../../../../../../../../models/configuration/errors";
import {
  useConfigurationsErrors,
  useIsLatestVersionConfig,
} from "../../../../../../../../store/slices/caching/hooks";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";
import { renderMatchRule } from "./renderMatchRule";
import { UseMatchBlockItemsParams } from "./types";

export const useMatchBlockItems = ({
  matchBlock,
  matchBlockName,
  fromMatchRulePath,
  dataTestId,
  isCompact,
  onChange,
  setDeleteMatchRule,
}: Omit<UseMatchBlockItemsParams, "location">): IItem<IMatchGroup>[] => {
  const location = useLocation();
  const history = useHistory();
  const errors = useConfigurationsErrors();
  const isViewMode = useIsViewMode();
  const [t] = useTranslation("configurationPropertyPage");
  const [tMatchRulesPage] = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isLatestVersionConfig = useIsLatestVersionConfig();

  return useMemo(
    () =>
      matchBlock
        ? matchBlock.matchGroups.map(
            renderMatchGroup({
              history,
              matchBlockName,
              onChange,
              isCompact,
              setDeleteMatchRule,
              errors,
              isViewMode,
              tMatchRulesPage,
              t,
              tMisc,
              matchBlock,
              location,
              fromMatchRulePath,
              dataTestId,
              isLatestVersionConfig,
            })
          )
        : [],
    [isViewMode, matchBlock, matchBlockName, onChange]
  );
};

const renderMatchGroup = (
  options: Pick<
    UseMatchBlockItemsParams,
    | "dataTestId"
    | "fromMatchRulePath"
    | "isCompact"
    | "matchBlockName"
    | "setDeleteMatchRule"
    | "onChange"
    | "location"
  > & {
    errors: ReturnType<typeof useConfigurationsErrors>;
    history: ReturnType<typeof useHistory>;
    isViewMode: boolean;
    tMatchRulesPage: TFunction<"configurationMatchRulesPage">;
    t: TFunction<"configurationPropertyPage">;
    tMisc: TFunction<"misc">;
    matchBlock: Exclude<UseMatchBlockItemsParams["matchBlock"], undefined>;
    isLatestVersionConfig: boolean;
  }
) => (group: IMatchGroup, groupIndex: number): IItem => {
  const {
    history,
    isViewMode,
    matchBlockName,
    dataTestId,
    isCompact,
    tMatchRulesPage,
    fromMatchRulePath,
    setDeleteMatchRule,
    matchBlock,
    onChange,
    errors,
    isLatestVersionConfig,
    location,
    t,
    tMisc,
  } = options;
  const childItems: IItem<IMatchRule>[] = group.matchRules.map(
    renderMatchRule({
      dataTestId,
      fromMatchRulePath,
      group,
      groupIndex,
      history,
      isCompact,
      isViewMode,
      matchBlockName,
      preIndexes: "",
      setDeleteMatchRule,
      t,
      tMisc,
      tMatchRulesPage,
      onChange: (parentPath, newMrOrder) => {
        parentPath
          .split("-")
          .reduce<IMatchRule | IMatchRule[] | undefined>(
            (prev, curr, level, queries) => {
              if (level < queries.length - 1) {
                if (level % 2 === 0) {
                  // Matchrule
                  return (prev as IMatchRule[])?.[Number(curr)];
                }

                return (prev as IMatchRule).matchGroups?.[Number(curr)]
                  .matchRules;
              } else {
                const matchGroups = (prev as IMatchRule).matchGroups;
                if (matchGroups) {
                  if (level === 0) {
                    matchGroups[0].matchRules = newMrOrder;
                  } else {
                    matchGroups[Number(queries[level])].matchRules = newMrOrder;
                  }
                }
              }
            },
            group.matchRules
          );

        matchBlock.matchGroups[groupIndex] = { ...group };
        // Must have timeout because of drag and drop animation
        setTimeout(() => onChange(matchBlock), 1000);
      },
      onAdd: (parentPath) => {
        parentPath
          .split("-")
          .reduce<IMatchRule | IMatchRule[] | undefined>(
            (prev, curr, level, queries) => {
              if (level < queries.length - 1) {
                if (level % 2 === 0) {
                  // Matchrule
                  return (prev as IMatchRule[])?.[Number(curr)];
                }

                return (prev as IMatchRule).matchGroups?.[Number(curr)]
                  .matchRules;
              } else {
                const mr = (prev as IMatchRule[])[Number(curr)];
                if (typeof mr.matchGroups === "undefined") {
                  mr.matchGroups = [{ matchRules: [] }];
                }
                // There can be only one match group in nested levels.
                mr.matchGroups[0].matchRules.push({ features: {} });
              }
            },
            group.matchRules
          );

        matchBlock.matchGroups[groupIndex] = { ...group };
        // Must have timeout because of drag and drop animation
        setTimeout(() => onChange(matchBlock), 1000);
      },
      isLatestVersionConfig,
      location,
    })
  );

  return {
    title: t("MATCH_LOGIC_CARD_MATCH_RULE_GROUP_TITLE"),
    content: (
      <DragAndDropList
        isChild
        items={childItems}
        onOrderChange={(newList) => {
          group.matchRules = newList.map((item) => item.context.obj);
          matchBlock.matchGroups[groupIndex] = { ...group };
          // Must have timeout because of drag and drop animation
          setTimeout(() => onChange(matchBlock), 1000);
        }}
        dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule`}
      />
    ),
    context: { index: groupIndex, obj: group },
    errorMessage: errors.some(
      (err) =>
        err.type ===
          ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_RULES &&
        err.data?.matchBlock === matchBlockName &&
        err.data?.matchGroupIndex === groupIndex
    )
      ? t("ERROR_MATCH_LOGIC_NO_MATCH_RULES")
      : undefined,
  };
};

export const AddButtonContainer = styled.div`
  & > div {
    padding-bottom: 0;
  }
`;
