import type { ReactElement } from "react";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FeatureType } from "../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { GeneratedResponseModal } from "../../../../../../../../../components/molecules/GeneratedResponseModal/GeneratedResponseModal";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import {
  DefinitionDropdown,
  DefinitionDropdownItem,
} from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import {
  ConfigurationDetailsType,
  GeneratedResponseDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { IValidationErrors } from "../FeaturesList";
import { FeatureBlock } from "./FeatureBlock";

interface IGeneratedResponseFeatureBlockProps {
  clearErrors: UseFormClearErrors<FieldValues>;
  config: ConfigurationDetailsType;
  errors: IValidationErrors;
  generatedResponseName: string | undefined;
  handleCreateDefinition: (definition: GeneratedResponseDefinitionType) => void;
  handleCreateFeature: (featureName: keyof IFeatures, newFeature: any) => void;
  initIsOpen: boolean;
  maxWidth: number;
  onDelete: () => void;
  setError: UseFormSetError<FieldValues>;
}

const ID = "generatedResponse";
const DATA_TEST_ID = "generated-response";

const toDropdownItem = (
  definition: GeneratedResponseDefinitionType
): DefinitionDropdownItem => ({ definition });

export const GeneratedResponseFeatureBlock = ({
  clearErrors,
  config,
  errors,
  generatedResponseName,
  handleCreateDefinition,
  handleCreateFeature,
  initIsOpen,
  maxWidth,
  onDelete,
  setError,
}: IGeneratedResponseFeatureBlockProps): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");
  const isViewMode = useIsViewMode();

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_GENERATED_RESPONSE_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_GENERATED_RESPONSE_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_GENERATED_RESPONSE_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_GENERATED_RESPONSE_DEFINITION_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_GENERATED_RESPONSE_DEFINITION_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_GENERATED_RESPONSE_DEFINITION_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          maxWidth={maxWidth}
          errorMessage={errors[ID]?.message}
          fieldProps={{
            other: config.generatedResponseDefinitions ? (
              <DefinitionDropdown
                id={ID}
                type={FeatureType.GENERATED_RESPONSE}
                disabled={isViewMode}
                items={config.generatedResponseDefinitions.map(toDropdownItem)}
                selected={
                  config.generatedResponseDefinitions.filter(
                    (def) => def.name === generatedResponseName
                  ) as GeneratedResponseDefinitionType[]
                }
                allowMultiSelection={false}
                onSelect={(selected) => {
                  handleCreateFeature(ID, selected[0]?.name ?? "");
                }}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                dataTestId={DATA_TEST_ID}
                parentConfig={config}
              />
            ) : (
              <GeneratedResponseModal
                parentConfig={config}
                onSubmit={handleCreateDefinition}
              />
            ),
          }}
          dataTestId={ID}
        />
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={ID}
          onDelete={onDelete}
          dataTestId={ID}
        />
      }
      dataTestId={DATA_TEST_ID}
      featureType={FeatureTypes.REQUEST_MANIPULATION}
    />
  );
};
