import { toast } from "react-toastify";

import { IMatchRule } from "../../models/configuration/definitions";
import {
  MatchBlocks,
  validateExpression,
} from "../../models/configuration/definitions/matchlogic";
import { CMv3APIError, APIError } from "../../models/error";
import { useDispatchAPIError } from "../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../store/slices/subscriber/hooks";

export const useSaveValidatedMatchLogic = (
  matchBlock: MatchBlocks,
  matchRule: IMatchRule | undefined,
  onSuccessCb: () => void
): (() => Promise<void | false>) => {
  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();

  const expression = /\s(and|or)\s/g;

  const splittedExpressions = matchRule?.expression
    ? matchRule.expression.split(expression).map((chunk) => chunk.trim())
    : [];

  return async () => {
    try {
      const validatedExpresssion = await validateExpression(
        subscriberId,
        matchBlock,
        splittedExpressions.join(" ")
      );
      if (validatedExpresssion && validatedExpresssion.valid) {
        onSuccessCb();
      } else {
        toast.warn(
          `Your expression is not valid ${(validatedExpresssion as any).error}`,
          { autoClose: false, hideProgressBar: true, progress: undefined }
        );
        return false;
      }
    } catch (error) {
      handleMediaPortalError(error as APIError<CMv3APIError>);
    }
  };
};
