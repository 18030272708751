import { ReactElement } from "react";
import {
  FieldType,
  PropertyDefinitionCard,
  PropertyDefinitionCardProps,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { Protocols } from "../../../../../../../../models/configuration/definitions/origin";
import {
  EncryptionLevels,
  HttpsConfig,
  Protocol,
  ServiceTypes,
} from "../../../../../../../../models/configuration/definitions/property";
import { SubscriberPermissions } from "../../../../../../../../models/subscriber";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useCheckSubscriberPremiumFeature } from "../../../../../../../../store/slices/subscriber/hooks";

interface HttpProtocolCardProps
  extends Pick<
    PropertyDefinitionCardProps,
    "divider" | "title" | "errorMessage"
  > {
  cardDataTestId: string;
  httpDataTestId: string;
  httpId: string;
  httpLabel: string;
  httpsDataTestId: string;
  httpsId: string;
  httpsLabel: string;
  httpDisabled: boolean;
  httpsDisabled: boolean;
  httpsPopupText?: string;
  placeholder: string;
  protocols: Protocol[];
  registeredProperty?: boolean;
  onHttpChange: (newProtocols: Protocol[]) => void;
  onHttpsChange: (
    newProtocols: Protocol[],
    newHttpsConfig: HttpsConfig | undefined
  ) => void;
}

export const HttpProtocolsCard = ({
  cardDataTestId,
  divider,
  errorMessage,
  httpDataTestId,
  httpId,
  httpLabel,
  httpsDataTestId,
  httpsId,
  httpsLabel,
  httpDisabled,
  httpsDisabled,
  placeholder,
  protocols,
  title,
  httpsPopupText,
  registeredProperty = false,
  onHttpChange,
  onHttpsChange,
}: HttpProtocolCardProps): ReactElement => {
  const checkSubscriberPermissions = useCheckSubscriberPremiumFeature();

  return (
    <PropertyDefinitionCard
      required
      title={title}
      divider={divider}
      errorMessage={errorMessage}
      dataTestId={cardDataTestId}
      fieldType={FieldType.CheckboxSelectorType}
      fieldProps={{
        placeholder,
        inline: true,
        checkboxProps: [
          {
            id: httpId,
            label: httpLabel,
            checked: protocols.includes(Protocols.HTTP),
            onChange: () => {
              onHttpChange(
                protocols.includes(Protocols.HTTP)
                  ? protocols.filter((protocol) => protocol !== Protocols.HTTP)
                  : [...protocols, Protocols.HTTP]
              );
            },
            disabled: httpDisabled,
            dataTestId: httpDataTestId,
          },
          {
            id: httpsId,
            checked: protocols.includes(Protocols.HTTPS),
            label: httpsLabel,
            onChange: () => {
              let newProtocols: Protocol[];
              let newHttpsConfig: PropertyDefinitionType["protocolSettings"]["httpsConfig"];

              if (protocols.includes(Protocols.HTTPS)) {
                newProtocols = protocols.filter(
                  (protocol) => protocol !== Protocols.HTTPS
                );

                newHttpsConfig = undefined;
              } else {
                newProtocols = [...protocols, Protocols.HTTPS];

                const [checkedBASIC, checkedSNI] = checkSubscriberPermissions([
                  SubscriberPermissions.SSL_BASIC,
                  SubscriberPermissions.SSL_PREMIUM,
                ]);

                if (!checkedBASIC && checkedSNI) {
                  newHttpsConfig = {
                    serviceType: ServiceTypes.SNI,
                    encryptionLevel: EncryptionLevels.DEFAULT,
                  };
                } else {
                  newHttpsConfig = {
                    serviceType: registeredProperty
                      ? ServiceTypes.SNI
                      : ServiceTypes.BASIC,
                    encryptionLevel: EncryptionLevels.DEFAULT,
                  };
                }
              }

              onHttpsChange(newProtocols, newHttpsConfig);
            },
            disabled: httpsDisabled,
            dataTestId: httpsDataTestId,
            popupText: httpsPopupText,
          },
        ],
      }}
    />
  );
};
