import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Button } from "../../../../components/atoms/Button/Button";
import { Certificate } from "../../../../models/certificate";
import { TextContent } from "../../../../components/molecules/TextContent/TextContent";
import { Loader } from "../../../../components/atoms/Loader/Loader";
import { Icons } from "../../../../components/atoms/Icon/Icon";
import { useIsMounted } from "../../../../hooks/use-is-mounted";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../../store/slices/subscriber/hooks";
import { DetailsHeader } from "./DetailsHeader";
import { ModuleContent } from "../../../../components/atoms/Module/Module";

const SCROLL_ID = "certificate_details";

export const DetailsPage = (): ReactElement => {
  const isMounted = useIsMounted();
  const { cert_name } = useParams<{ cert_name: string }>();
  const [certificate, setCertificate] = useState<Certificate>();
  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();
  const { t } = useTranslation("certificatePage");

  useEffect(() => {
    if (cert_name) {
      Certificate.getCertByName(subscriberId, cert_name)
        .then((data) => {
          if (isMounted) {
            setCertificate(data);
          }
        })
        .catch(handleMediaPortalError);
    }
  }, [cert_name, subscriberId, isMounted]);

  return (
    <>
      <DetailsHeader certificate={certificate} />
      {certificate ? (
        <ModuleContent id={SCROLL_ID}>
          <Container>
            <ContentDisplaySection
              title={t("CERTIFICATE_CARD_CERTIFICATE_TITLE")}
              certName={certificate.cert_name}
              content={certificate.certificate}
              field="certificate"
            />
            <ContentDisplaySection
              title={t("CERTIFICATE_CARD_BUNDLE_TITLE")}
              certName={certificate.cert_name}
              content={certificate.cert_bundle}
              field="certificate-bundle"
            />
          </Container>
        </ModuleContent>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  );
};

const ContentDisplaySection: FC<{
  content?: string | null;
  title: string;
  certName: string;
  field: string;
}> = ({ content, title, certName, field }) => {
  const { t } = useTranslation("certificatePage");

  const copyContent = useCallback(() => {
    navigator.clipboard.writeText(content || "");
  }, [content]);

  const downloadContent = useCallback(() => {
    if (content) {
      const blob = new Blob([content], { type: "text/plain" });
      const a = document.createElement("a");
      a.setAttribute(
        "download",
        `${certName}_${title.toLowerCase()}_certificate.txt`
      );
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
    }
  }, [certName, content, title]);

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{title}</SectionTitle>
        <ButtonsContainer>
          <Button
            label={t("CERTIFICATE_CARD_COPY_BUTTON")}
            disabled={!content}
            backgroundColor="baseLight"
            textColor="highlight"
            borderColor="highlight"
            onClick={copyContent}
            icon={{ name: Icons.COPY }}
            dataTestId={`${field}-copy-button`}
          />
          <Button
            label={t("CERTIFICATE_CARD_DOWNLOAD_BUTTON")}
            disabled={!content}
            backgroundColor="baseLight"
            textColor="highlight"
            borderColor="highlight"
            onClick={downloadContent}
            icon={{ name: Icons.FILE_DOWNLOAD }}
            dataTestId={`${field}-download-button`}
          />
        </ButtonsContainer>
      </SectionHeader>
      <TextContentContainer>
        <TextContent content={content || t("CERTIFICATE_CARD_NO_CONTENT")} />
      </TextContentContainer>
    </SectionContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 0.5em;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer}`};
`;

const SectionContainer = styled.div`
  margin-bottom: 2em;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
`;

const SectionTitle = styled.h3`
  margin: 0 !important;
`;

const TextContentContainer = styled.div`
  padding: 2em;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
`;
