import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Link } from "../../../components/atoms/Link/Link";
import { ModuleContent } from "../../../components/atoms/Module/Module";
import { withSubscriberSettings } from "../../../components/layers/SubscriberSettings/SubscriberSettings";
import { AnchorMenu } from "../../../components/molecules/AnchorMenu/AnchorMenu";
import { ExpandableContent } from "../../../components/molecules/ExpandableContent/ExpandableContent";
import { IUseAnchorMenu, useAnchorMenu } from "../../../hooks/use-anchor-menu";
import { useSubscriberSettingsReportingOverrides } from "../../../store/slices/subscriber-settings/hooks";
import { InfoSection } from "./subscriberSettings_page/InfoSection";
import { ReportingOverridesSection } from "./subscriberSettings_page/reportingOverrides";
import { SystemLimitsSection } from "./subscriberSettings_page/SystemLimitsSection";
import { TrafficTypesSection } from "./subscriberSettings_page/TrafficTypes";

export const SubscriberSettings = withSubscriberSettings(() => {
  const _reportingOverrides = useSubscriberSettingsReportingOverrides();

  const { t } = useTranslation("subscribersSettingsPage");

  const anchorMenuConfig: IUseAnchorMenu = {
    itemNames: [
      {
        name: t("SUBSCRIBER_SETTINGS_ANCHOR_INFO"),
        id: "info",
      },
      {
        name: t("SUBSCRIBER_SETTINGS_ANCHOR_TRAFFIC_TYPES"),
        id: "traffic_types",
      },
      { name: t("SUBSCRIBER_SETTINGS_ANCHOR_SYSTEM_LIMITS"), id: "limits" },
      {
        name: t("SUBSCRIBER_SETTINGS_ANCHOR_REPORTING_OVERRIDES"),
        id: "overrides",
        subItems: Object.keys(_reportingOverrides).map((reportingOverride) => ({
          name: reportingOverride,
          id: reportingOverride,
        })),
      },
    ],
  };

  const menu = useAnchorMenu(anchorMenuConfig);

  const [info, trafficTypes, systemLimits, reportingOverrides] = menu;

  return (
    <ModuleContent id="subscriber_settings">
      <SubscriberSettingsLayout>
        <AnchorMenu
          menuItems={menu}
          scrollId="subscriber_settings"
          defaultActive={0}
          footer={
            <HelpSection>
              <HelpTitle>{t("SUBSCRIBER_SETTINGS_FOOTER_TITLE")}</HelpTitle>
              {t("SUBSCRIBER_SETTINGS_FOOTER_CONTENT")}{" "}
              <Link href="mailto:CDNsupport@lumen.com">
                CDNsupport@lumen.com
              </Link>
            </HelpSection>
          }
        />
        <SubscriberSettingsContainer>
          <ExpandableContent
            title={t("SUBSCRIBER_SETTINGS_DESCRIPTION_TITLE")}
            content={t("SUBSCRIBER_SETTINGS_DESCRIPTION_CONTENT")}
          />

          <InfoSection menu={info} />

          <TrafficTypesSection menu={trafficTypes} />

          <SystemLimitsSection menu={systemLimits} />

          <ReportingOverridesSection menu={reportingOverrides} />
        </SubscriberSettingsContainer>
      </SubscriberSettingsLayout>
    </ModuleContent>
  );
});

const SubscriberSettingsLayout = styled.div`
  display: flex;
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer}`};
`;

const SubscriberSettingsContainer = styled.div`
  width: 100%;
`;

const HelpSection = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  padding: 16px 32px;
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  border-radius: 8px;
  max-width: fit-content;
`;

const HelpTitle = styled.h4`
  margin-top: 0 !important;
`;
