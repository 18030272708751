import { toast } from "react-toastify";

import { ErrorToast } from "../../../components/molecules/ErrorToast/ErrorToast";
import { ErrorLevel, APIError } from "../../../models/error";
import { AppThunk } from "../../store";
import { setErrorData, setShowErrorModal } from "./slice";

export const handleAPIError = (err: APIError): AppThunk => (dispatch) => {
  switch (err.errorLevel) {
    case ErrorLevel.WARNING:
      toast.warn(
        <ErrorToast
          error={err}
          onViewMoreDetailsClick={() => {
            dispatch(setErrorData(err.data));
            dispatch(setShowErrorModal(true));
          }}
        />,
        {
          autoClose: 15000,
        }
      );
      break;
    case ErrorLevel.ERROR:
    case ErrorLevel.FATAL:
      toast.error(
        <ErrorToast
          error={err}
          onViewMoreDetailsClick={() => {
            dispatch(setErrorData(err.data));
            dispatch(setShowErrorModal(true));
          }}
        />,
        {
          autoClose: 15000,
        }
      );
      break;
  }
};
