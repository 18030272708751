import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { BannersData, BannerHeader } from "../../../models/models";
import { DeleteBannersModal } from "./DeleteBannersModal";
import { ExpiredBannersModal } from "./ExpiredBannersModal";
import { BannersModal } from "./BannersModal";
import { HeadlessDataTable } from "../../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { FieldName } from "../../../../../../components/molecules/SortColumn/SortColumn";

interface BannersDataTableProps {
  banners: BannersData[];
  handleDeleteBanners: (bannerId: string) => Promise<void>;
  handleSaveBanners: (banner: BannerHeader, isUpdate: boolean) => Promise<void>;
  handleExpiredBanners: (
    bannerId: string,
    notificationId: number
  ) => Promise<void>;
  dataTestId?: string;
}

export const BannersDataTable: React.FC<BannersDataTableProps> = ({
  banners,
  handleDeleteBanners,
  handleSaveBanners,
  handleExpiredBanners,
  dataTestId,
}) => {
  const { t } = useTranslation("bannersDataTablePage");

  const handleStatus = (banner: BannersData) => {
    const nowTime = new Date().toLocaleString("en-GB", {
      timeStyle: "long",
      dateStyle: "medium",
    });
    const bannerTime = new Date(banner.endTime).toLocaleString("en-GB", {
      timeStyle: "long",
      dateStyle: "medium",
    });
    if (nowTime >= bannerTime) {
      return 0;
    } else {
      return 1;
    }
  };

  return (
    <HeadlessDataTable
      data={banners}
      columns={[
        {
          dataField: "startTime",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-starttime`}>
                {new Date(_banner.startTime).toLocaleString("en-GB", {
                  timeStyle: "long",
                  dateStyle: "medium",
                })}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("START_DATE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "endTime",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-endtime`}>
                {new Date(_banner.endTime).toLocaleString("en-GB", {
                  timeStyle: "long",
                  dateStyle: "medium",
                })}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("END_DATE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "name",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-name`}>
                {_banner.name}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("NAME_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "message",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-message`}>
                {_banner.message}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("MESSAGE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "serviceType",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel
                data-testid={`${dataTestId}-${index}-servicetype`}
              >
                {_banner.serviceType}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("SERVICE_TYPE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "userType",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-usertype`}>
                {_banner.userType}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("USER_TYPE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "createdAt",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-createdat`}>
                {new Date(_banner.createdAt).toLocaleString("en-GB", {
                  timeZone: "UTC",
                  timeStyle: "long",
                  dateStyle: "medium",
                })}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("CREATED_AT_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "updatedAt",
          formatter: (_banner, index) => {
            return _banner.updatedAt ? (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-updatedat`}>
                {new Date(_banner.updatedAt).toLocaleString("en-GB", {
                  timeZone: "UTC",
                  timeStyle: "long",
                  dateStyle: "medium",
                })}
              </SingleLineLabel>
            ) : (
              <SingleLineLabel>{t("NO_UPDATE")}</SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("LAST_UPDATE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "notificationId",
          formatter: (_banner, index) => {
            return (
              <SingleLineLabel data-testid={`${dataTestId}-${index}-status`}>
                {handleStatus(_banner) ? t("ACTIVE") : t("EXPIRED")}
              </SingleLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("STATUS_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "id",
          formatter: (_banner, index) => {
            return (
              <IconLineLabel>
                <BannersModal
                  handleSaveBanners={handleSaveBanners}
                  isUpdate={true}
                  bannerHeader={_banner}
                  dataTestId={`${dataTestId}-${index}`}
                />
                <IconWrapper>
                  <DeleteBannersModal
                    bannersName={_banner.name}
                    onDelete={() => handleDeleteBanners(_banner.id)}
                    dataTestId={`${dataTestId}-${index}-delete-button`}
                  />
                </IconWrapper>
              </IconLineLabel>
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("EDIT_DELETE_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
        {
          dataField: "priority",
          formatter: (_banner) => {
            return (
              <ExpiredBannersModal
                handleExpiredBanners={handleExpiredBanners}
                bannerId={_banner.id}
                bannerName={_banner.name}
                notificationId={_banner.notificationId}
                dataTestId={`${dataTestId}-epxired-button`}
              />
            );
          },
          name: (
            <StyledFieldName isActive={false}>
              {t("EXPIRED_COLUMN")}
            </StyledFieldName>
          ),
          sortable: false,
        },
      ]}
      keyField="name"
    />
  );
};

const SingleLineLabel = styled.div`
  padding: 4px 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

const IconLineLabel = styled.div`
  display: flex;
  padding: 4px 7px;
  line-height: 16px;
`;

const IconWrapper = styled.div`
  margin-left: 15px;
`;

const StyledFieldName = styled(FieldName)`
  padding: 4px 6px;
  display: flex;
`;
