import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { config, useSpring } from "react-spring";

import { Slider, Tab } from "../../atoms/Tab/Tab";

export interface IRoute {
  route: string;
  label: string;
}

export interface ITabsProps {
  onSelectionChange: (selection: IRoute) => void;
  tabsList: IRoute[];
  initialIndex?: number;
  lastHasMargin?: boolean;
}

export const Tabs: FC<ITabsProps> = ({
  onSelectionChange,
  tabsList,
  initialIndex,
  lastHasMargin = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<{
    ref: RefObject<HTMLDivElement>;
    route: IRoute;
  }>();
  const tabs = tabsList.map((tab) => ({
    ref: createRef<HTMLDivElement>(),
    route: tab,
  }));

  const [sliderPosition, setSliderPosition] = useSpring(() => ({
    config: { ...config.stiff, mass: 0.5 },
    left: "0px",
    width: "0px",
  }));

  useEffect(() => {
    if (tabs.length) setSelectedTab(tabs[initialIndex || 0]);
    const currentTabRef = tabs[initialIndex || 0].ref.current;
    setSliderPosition({
      left:
        currentTabRef && containerRef.current
          ? `${currentTabRef.offsetLeft - containerRef.current.offsetLeft}px`
          : "0px",
      width: currentTabRef ? `${currentTabRef.clientWidth}px` : "0px",
    });
  }, [setSliderPosition, initialIndex]);

  const updateSliderPosition = useCallback(() => {
    if (selectedTab) {
      setSliderPosition({
        left:
          selectedTab.ref.current && containerRef.current
            ? `${
                selectedTab.ref.current.offsetLeft -
                containerRef.current.offsetLeft
              }px`
            : "0px",
        width: selectedTab.ref.current
          ? `${selectedTab.ref.current.clientWidth}px`
          : "0px",
      });
    }
  }, [setSliderPosition, selectedTab]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSliderPosition);
  }, [updateSliderPosition]);

  const setSelection = useCallback(
    (selection) => {
      setSliderPosition({
        left:
          selection.ref.current && containerRef.current
            ? `${
                selection.ref.current.offsetLeft -
                containerRef.current.offsetLeft
              }px`
            : "0px",
        width: selection.ref.current
          ? `${selection.ref.current.clientWidth}px`
          : "0px",
      });
      onSelectionChange(selection.route);
      setSelectedTab(selection);
    },
    [onSelectionChange, setSliderPosition]
  );

  return (
    <TabsSelectorContainer ref={containerRef}>
      <TabsContainer>
        {tabs.map((tab, i) => (
          <TabWrapper
            floatRight={i === tabs.length - 1 && lastHasMargin}
            key={i}
          >
            <Tab
              ref={tab.ref}
              selected={selectedTab === tab}
              className="-text--h4"
              onClick={() => setSelection(tab)}
              data-testid={`tab-selector-${tab.route.route}`}
            >
              {tab.route.label}
            </Tab>
          </TabWrapper>
        ))}
      </TabsContainer>
      <Slider style={sliderPosition} />
    </TabsSelectorContainer>
  );
};

const TabsSelectorContainer = styled.div``;

const TabsContainer = styled.div`
  display: flex;
  > :first-of-type {
    margin-left: 0px !important;
  }
`;

const TabWrapper = styled.div<{ floatRight: boolean }>`
  margin: 0px 25px;
  ${({ floatRight }) => {
    if (floatRight) {
      return css`
        margin-left: auto;
      `;
    }
  }}
`;
