import { ConfigurationType } from "../types";

export const isConfigurationLatestVersion = (
  config: ConfigurationType
): boolean => {
  for (const version of config.versions) {
    if (version.versionId > config.versionId) {
      return false;
    }
  }

  return true;
};
