import { AxiosError } from "axios";

import { httpClient } from "../../../core/http-client";
import { MatchLogicDefinitionType } from "../../../store/slices/caching/types";
import { ErrorLevel, CMv3APIError, APIError } from "../../error";

export enum CachePolicy {
  AS_IS = "as-is",
  NO_CACHE = "no-cache",
  NO_STORE = "no-store",
}

export interface ICachePolicyInt {
  // The input element returns a string value, so it must be converted to a
  // number before being committed
  cachePolicy: number | string;
}

interface ICachePolicyString {
  cachePolicy: CachePolicy.NO_CACHE | CachePolicy.NO_STORE;
}

interface ICachePolicyAsIs {
  cachePolicy: CachePolicy.AS_IS;
}

type DenialRedirect = {
  action: "redirect";
  redirectUrl?: string;
};

type DenialError = {
  action: "error";
};

type IDenial = DenialRedirect | DenialError;

export interface ICustomLogDataVariable {
  keyName: string;
  variableName: string;
  signature?: string;
}

export interface ICustomLogDataLiteral {
  keyName: string;
  literalString: string;
}

export interface IVariableValueList {
  luaName: string;
  variableValues: {
    name: string;
    value: string | number | boolean;
  }[];
}

export interface ILuaFeature {
  nameList: string[];
  variableValueList?: IVariableValueList[];
}

export type CCHOInt = ICachePolicyAsIs | ICachePolicyString | ICachePolicyInt;

export interface CCHOExt {
  // The input element returns a string value, so it must be converted to a
  // number before being committed
  cachePolicy: string | number | CachePolicy.NO_CACHE | CachePolicy.NO_STORE;
  force?: boolean;
}

export interface CCHO {
  ext?: CCHOExt;
  int?: CCHOInt;
}

export interface FailedRefreshTTL {
  // The input element returns a string value, so it must be converted to a
  // number before being committed
  ttl: string | number;
}

export type NegativeTTL = ICachePolicyInt | ICachePolicyString;

export interface IFeatures {
  allowCompress?: boolean;
  generatedResponse?: string;
  cacheControlHeaderOverride?: CCHO;
  cacheKey?: string;
  customLogData?: (ICustomLogDataVariable | ICustomLogDataLiteral)[];
  defaultCache?: ICachePolicyInt;
  denyPolicy?: {
    denial: IDenial;
    headers?: { name: string; value: string }[];
  };
  disableIfNoneMatch?: boolean;
  failedRefreshTTL?: FailedRefreshTTL;
  followRedirects?: "never" | "always";
  geoRestrictions?: {
    nameList: string[];
    actionType: string;
    denial?: IDenial;
    ipWhitelist?: string[];
    matchAnonymizers: boolean;
  };
  ipRestrictions?: {
    nameList: string[];
    actionType: "allow" | "deny";
    denial?: IDenial;
  };
  negativeTTL?: NegativeTTL;
  originFillPolicy?: string;
  qshMode?: boolean | { nameList: string[]; actionType: "include" | "exclude" };
  reportingOverride?: string;
  reqHeaders?: { nameList: string[] };
  reqLua?: ILuaFeature;
  respHeaders?: { nameList: string[] };
  respLua?: ILuaFeature;
  serve200For416?: boolean;
  staleContentControl?: {
    staleIfError: {
      enabled: boolean;
      http4xxErrors: boolean;
      http5xxErrors: boolean;
    };
    staleWhileRevalidate: {
      enabled: boolean;
    };
  };
  tokenAuthentication?: {
    name: string;
    denial: IDenial;
    ipWhitelist?: string[];
  };
  uriRewrite?: string;
}

export interface IMatchRule {
  description?: string;
  expression?: string;
  signature?: string;
  features: IFeatures;
  matchGroups?: IMatchGroup[];
}

export interface IMatchGroup {
  description?: string;
  matchRules: IMatchRule[];
}

export interface IMatchBlock {
  description?: string;
  matchGroups: IMatchGroup[];
}

export enum MatchBlocks {
  CLIENT_REQ = "clientReq",
  ORIGIN_REQ = "originReq",
  ORIGIN_RESP = "originResp",
}

export interface IMatchLogicDefinition {
  matchBlocks: {
    [MatchBlocks.CLIENT_REQ]?: IMatchBlock;
    [MatchBlocks.ORIGIN_REQ]?: IMatchBlock;
    [MatchBlocks.ORIGIN_RESP]?: IMatchBlock;
  };
}

export const validateExpression = async (
  subscriberId: number,
  matchRuleBlockType: MatchBlocks,
  expression: string
): Promise<IValidateExpressionResponseObject> => {
  try {
    const response = await httpClient.post<
      {
        expressions: [
          {
            location: MatchBlocks;
            expression: string;
          }
        ];
      },
      IValidateExpressionResponse
    >(
      `/serviceConfiguration/v3/subscribers/${subscriberId}/tools/expressions/validate`,
      { expressions: [{ location: matchRuleBlockType, expression }] }
    );
    if (!response.data.expressions.length) {
      return {
        expression,
        internal: false,
        location: matchRuleBlockType,
        valid: true,
      };
    }
    return response.data.expressions[0];
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "Match Logic Expression Validation",
      ErrorLevel.WARNING,
      error.response
    );
  }
};

interface IValidateExpressionResponse {
  expressions: IValidateExpressionResponseObject[];
}

export interface IValidateExpressionResponseObject {
  error?: string;
  expression: string;
  identifiers?: [{ identifier: string; internal: boolean; allowed: boolean }];
  internal: boolean;
  location: MatchBlocks;
  valid: boolean;
}

export const getMatchLogicDefinitionDescription = (
  def: MatchLogicDefinitionType
): string => `${Object.keys(def.matchBlocks || {}).length} matchblocks`;
