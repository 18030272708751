import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import {
  IFromMatchRule,
  IFromMatchRulePath,
} from "../../../../../../@types/matchRules";
import { AnchorWrapper } from "../../../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { ErrorContainer } from "../../../../../../components/atoms/ErrorContainer/ErrorContainer";
import { AddFeatureCard } from "../../../../../../components/molecules/AddFeatureCard/AddFeatureCard";
import { MenuItem } from "../../../../../../components/molecules/AnchorMenu/AnchorMenu";
import { UnbindMatchLogicModal } from "../../../../../../components/molecules/UnbindMatchLogicModal/UnbindMatchLogicModal";
import { ExpandableContent } from "../../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { PropertyDefinitionBlock } from "../../../../../../components/molecules/PropertyDefinitionBlock/PropertyDefinitionBlock";
import { MatchBlocks } from "../../../../../../models/configuration/definitions/matchlogic";
import { PropertySection } from "../../../../../../models/configuration/definitions/property";
import { ConfigurationErrorType } from "../../../../../../models/configuration/errors";
import { useConfigurationsErrors } from "../../../../../../store/slices/caching/hooks";
import { handleRemoveConfigurationError } from "../../../../../../store/slices/caching/thunks";
import {
  MatchLogicDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../store/slices/permissions/hooks";
import { useAppDispatch } from "../../../../../../store/types";
import { AddMatchLogicModal } from "./AddMatchLogicModal";
import { MatchBlock } from "./sections/match_rules/MatchBlock";
import { FlexBoxBase } from "../../../../../../components/atoms/FlexBox/FlexBox";
import { FontLink } from "../../../../../../components/atoms/Font/Font";
import { useCompact } from "./useCompact";

interface MatchRulesSectionProps {
  menu: MenuItem;
  currentMatchLogic: MatchLogicDefinitionType | undefined;
  currentProperty: PropertyDefinitionType;
  updateProperty: (newDefinition: PropertyDefinitionType) => void;
  updateMatchLogic: (newDefinition?: MatchLogicDefinitionType) => void;
}

export const MatchRulesSection = ({
  menu,
  currentMatchLogic,
  updateMatchLogic,
  updateProperty,
  currentProperty,
}: MatchRulesSectionProps): ReactElement => {
  const { t } = useTranslation("configurationPropertyPage");
  const isViewMode = useIsViewMode();
  const errors = useConfigurationsErrors();
  const dispatch = useAppDispatch();

  const [clientReq, originReq, originResp] = menu.subItems!;

  const location = useLocation<IFromMatchRule>();

  const [fromMatchRule, setFromMatchRule] = useState<IFromMatchRulePath>();

  const [isCompact, toggleCompact] = useCompact();

  useEffect(() => {
    if (location.state && location.state.fromMatchRule) {
      setFromMatchRule(location.state.fromMatchRule);
    }
  }, [location]);

  const compactStr = isCompact ? "detailed" : "compact";

  return (
    <AnchorWrapper id={menu.id} ref={menu.ref}>
      <Header>
        <HeaderContainer>
          <SubSection>{t("MATCH_LOGIC_SECTION_TITLE")}</SubSection>
          <FontLink
            data-testid={`${compactStr}-view-button`}
            onClick={toggleCompact}
          >
            {`Switch to ${compactStr} view`}
          </FontLink>
        </HeaderContainer>
        <ExpandableContent
          title={t("MATCH_LOGIC_DESCRIPTION_TITLE")}
          content={t("MATCH_LOGIC_DESCRIPTION_CONTENT")}
        />
      </Header>
      {currentProperty.matchLogic ? (
        <PropertyDefinitionBlock
          title={
            currentMatchLogic?.name || t("MATCH_LOGIC_CARD_NAME_DEFAULT_TITLE")
          }
          dataTestId="property-match-logic-title"
          displayArrow={false}
          customDeleteButton={
            isViewMode ? undefined : (
              <UnbindMatchLogicModal
                handleDelete={() => {
                  dispatch(
                    handleRemoveConfigurationError(
                      (err) =>
                        !(err.data?.section === PropertySection.MATCH_LOGIC)
                    )
                  );
                  updateProperty({ ...currentProperty, matchLogic: undefined });
                  updateMatchLogic(undefined);
                }}
              />
            )
          }
          propertyCards={
            <BlocksContainer>
              {errors.some(
                (err) =>
                  err.type ===
                    ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_BLOCKS &&
                  err.data?.section === PropertySection.MATCH_LOGIC
              ) && (
                <ErrorContainer>
                  {t("ERROR_MATCH_LOGIC_NO_MATCH_BLOCKS")}
                </ErrorContainer>
              )}

              {/* CLIENT REQ */}
              <AnchorWrapper
                id={clientReq.id}
                ref={clientReq.ref}
                key={clientReq.name}
              >
                <MatchBlock
                  setFromMatchRule={setFromMatchRule}
                  fromMatchRulePath={fromMatchRule}
                  key={MatchBlocks.CLIENT_REQ}
                  additionalInfoTitle={t(
                    "MATCH_LOGIC_CLIENT_REQUEST_DESCRIPTION_TITLE"
                  )}
                  additionalInfoContent={t(
                    "MATCH_LOGIC_CLIENT_REQUEST_DESCRIPTION_CONTENT"
                  )}
                  header={t("MATCH_LOGIC_CARD_CLIENT_REQUEST_TITLE")}
                  matchBlockName={MatchBlocks.CLIENT_REQ}
                  matchBlock={currentMatchLogic?.matchBlocks?.clientReq}
                  onChange={(newMatchBlock) => {
                    if (currentMatchLogic?.matchBlocks.clientReq) {
                      updateMatchLogic({
                        ...currentMatchLogic,
                        matchBlocks: {
                          ...currentMatchLogic.matchBlocks,
                          clientReq: newMatchBlock,
                        },
                      });
                    }
                  }}
                  addMatchBlock={() => {
                    if (currentMatchLogic) {
                      dispatch(
                        handleRemoveConfigurationError(
                          (err) =>
                            !(
                              err.type ===
                                ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_BLOCKS &&
                              err.data?.section === PropertySection.MATCH_LOGIC
                            )
                        )
                      );
                      updateMatchLogic({
                        ...currentMatchLogic,
                        matchBlocks: {
                          ...currentMatchLogic.matchBlocks,
                          clientReq: {
                            ...currentMatchLogic.matchBlocks.clientReq,
                            matchGroups: [{ description: "", matchRules: [] }],
                          },
                        },
                      });
                    }
                  }}
                  errorMessage={
                    errors.some(
                      (err) =>
                        err.type ===
                          ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS &&
                        err.data?.section === PropertySection.MATCH_LOGIC &&
                        err.data?.matchBlock === MatchBlocks.CLIENT_REQ
                    )
                      ? t("ERROR_MATCH_LOGIC_NO_MATCH_GROUPS")
                      : undefined
                  }
                  dataTestId="clientReq-section"
                  isCompact={isCompact}
                />
              </AnchorWrapper>

              {/* ORIGIN REQ */}
              <AnchorWrapper
                id={originReq.id}
                ref={originReq.ref}
                key={originReq.name}
              >
                <MatchBlock
                  setFromMatchRule={setFromMatchRule}
                  fromMatchRulePath={fromMatchRule}
                  key={MatchBlocks.ORIGIN_REQ}
                  additionalInfoTitle={t(
                    "MATCH_LOGIC_ORIGIN_REQUEST_DESCRIPTION_TITLE"
                  )}
                  additionalInfoContent={t(
                    "MATCH_LOGIC_ORIGIN_REQUEST_DESCRIPTION_CONTENT"
                  )}
                  header={t("MATCH_LOGIC_CARD_ORIGIN_REQUEST_TITLE")}
                  matchBlockName={MatchBlocks.ORIGIN_REQ}
                  matchBlock={currentMatchLogic?.matchBlocks?.originReq}
                  onChange={(newMatchBlock) => {
                    if (currentMatchLogic?.matchBlocks.originReq) {
                      updateMatchLogic({
                        ...currentMatchLogic,
                        matchBlocks: {
                          ...currentMatchLogic.matchBlocks,
                          originReq: newMatchBlock,
                        },
                      });
                    }
                  }}
                  addMatchBlock={() => {
                    if (currentMatchLogic) {
                      dispatch(
                        handleRemoveConfigurationError(
                          (err) =>
                            !(
                              err.type ===
                                ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_BLOCKS &&
                              err.data?.section === PropertySection.MATCH_LOGIC
                            )
                        )
                      );
                      updateMatchLogic({
                        ...currentMatchLogic,
                        matchBlocks: {
                          ...currentMatchLogic.matchBlocks,
                          originReq: {
                            ...currentMatchLogic.matchBlocks.originReq,
                            matchGroups: [{ description: "", matchRules: [] }],
                          },
                        },
                      });
                    }
                  }}
                  errorMessage={
                    errors.some(
                      (err) =>
                        err.type ===
                          ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS &&
                        err.data?.section === PropertySection.MATCH_LOGIC &&
                        err.data?.matchBlock === MatchBlocks.ORIGIN_REQ
                    )
                      ? t("ERROR_MATCH_LOGIC_NO_MATCH_GROUPS")
                      : undefined
                  }
                  dataTestId="originReq-section"
                  isCompact={isCompact}
                />
              </AnchorWrapper>

              {/* ORIGIN RESP */}
              <AnchorWrapper
                id={originResp.id}
                ref={originResp.ref}
                key={originResp.name}
              >
                <MatchBlock
                  setFromMatchRule={setFromMatchRule}
                  fromMatchRulePath={fromMatchRule}
                  key={MatchBlocks.ORIGIN_RESP}
                  additionalInfoTitle={t(
                    "MATCH_LOGIC_ORIGIN_RESPONSE_DESCRIPTION_TITLE"
                  )}
                  additionalInfoContent={t(
                    "MATCH_LOGIC_ORIGIN_RESPONSE_DESCRIPTION_CONTENT"
                  )}
                  header={t("MATCH_LOGIC_CARD_ORIGIN_RESPONSE_TITLE")}
                  matchBlockName={MatchBlocks.ORIGIN_RESP}
                  matchBlock={currentMatchLogic?.matchBlocks?.originResp}
                  onChange={(newMatchBlock) => {
                    if (currentMatchLogic?.matchBlocks.originResp) {
                      updateMatchLogic({
                        ...currentMatchLogic,
                        matchBlocks: {
                          ...currentMatchLogic.matchBlocks,
                          originResp: newMatchBlock,
                        },
                      });
                    }
                  }}
                  addMatchBlock={() => {
                    if (currentMatchLogic) {
                      dispatch(
                        handleRemoveConfigurationError(
                          (err) =>
                            !(
                              err.type ===
                                ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_BLOCKS &&
                              err.data?.section === PropertySection.MATCH_LOGIC
                            )
                        )
                      );
                      updateMatchLogic({
                        ...currentMatchLogic,
                        matchBlocks: {
                          ...currentMatchLogic.matchBlocks,
                          originResp: {
                            ...currentMatchLogic.matchBlocks.originResp,
                            matchGroups: [{ description: "", matchRules: [] }],
                          },
                        },
                      });
                    }
                  }}
                  errorMessage={
                    errors.some(
                      (err) =>
                        err.type ===
                          ConfigurationErrorType.PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS &&
                        err.data?.section === PropertySection.MATCH_LOGIC &&
                        err.data?.matchBlock === MatchBlocks.ORIGIN_RESP
                    )
                      ? t("ERROR_MATCH_LOGIC_NO_MATCH_GROUPS")
                      : undefined
                  }
                  isCompact={isCompact}
                  dataTestId="originResp-section"
                />
              </AnchorWrapper>
            </BlocksContainer>
          }
        />
      ) : (
        <PropertyDefinitionBlock
          propertyCards={
            <AddFeatureCard
              title={t("MATCH_LOGIC_BLOCK_TITLE")}
              customButton={
                isViewMode ? (
                  <></>
                ) : (
                  <AddMatchLogic>
                    <AddMatchLogicModal
                      updateMatchLogic={updateMatchLogic}
                      currentProperty={currentProperty}
                      updateProperty={updateProperty}
                    />
                  </AddMatchLogic>
                )
              }
              description={t("MATCH_LOGIC_BLOCK_DESCRIPTION")}
            />
          }
        />
      )}
    </AnchorWrapper>
  );
};

const Header = styled.div`
  margin-bottom: 10px;
`;

const HeaderContainer = styled(FlexBoxBase)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const SubSection = styled.h4``;

const BlocksContainer = styled.div`
  width: 100%;
`;

const AddMatchLogic = styled.div``;
