import { format } from "date-fns";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";

import { AccessGroupRow } from "../../atoms/AccessGroupRow/AccessGroupRow";
import { Icons } from "../../atoms/Icon/Icon";
import { AccessGroup } from "../../../models/user/user.interfaces";
import { useBool } from "../../../hooks/use-bool/useBool";
import { LinkArrow } from "../LinkCTA/LinkCTA";
import { useState } from "react";

export const NestedAccessGroupRow = ({
  id,
  createdDate,
  name,
  list,
  nestedIndex,
}: AccessGroup & { nestedIndex: number }): JSX.Element => {
  const [isOpen, toggleIsOpen] = useBool(false);
  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);
  return (
    <>
      <StyledTableBodyRow
        key={id}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <VariablePaddingCell factor={nestedIndex}>
          <AccessGroupRow
            id={id}
            name={name}
            childrenCount={list?.length || 0}
            toggleIsOpen={toggleIsOpen}
            isOpen={isOpen}
          />
        </VariablePaddingCell>
        <td>{format(Date.parse(createdDate), "MMM d, yyyy H:mm")}</td>
        <td>
          {isHovering && (
            <LinkArrow
              name={Icons.ARROW_LEFT}
              onClick={() => {
                history.push(`/userManagement/accessGroups/${id}`);
              }}
            />
          )}
        </td>
      </StyledTableBodyRow>
      {isOpen &&
        list?.map((child) => (
          <NestedAccessGroupRow
            key={child.id}
            {...child}
            nestedIndex={nestedIndex + 1}
          />
        ))}
    </>
  );
};

const VariablePaddingCell = styled.td<{ factor: number }>`
  padding-left: ${({ factor }) => 16 * (factor + 1)}px;
`;

const StyledTableBodyRow = styled.tr`
  :hover {
    background: ${({ theme }) => theme.backgrounds.base};
    border-radius: 6px;
  }
  td {
    padding-top: 0;
    padding-bottom: 0;
  }
  td:first-child {
    border-radius: 6px 0 0 6px;
  }
  td:last-child {
    border-radius: 0 6px 6px 0;
    padding-right: 0;
  }
`;
