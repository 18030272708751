import styled from "@emotion/styled";
import { FC } from "react";
import { Card } from "../../atoms/Card/Card";
import { Icon, Icons } from "../../atoms/Icon/Icon";

export const QuoteBox: FC = ({ children }) => {
  return (
    <Container>
      <Bar />
      <StyledCard>
        <StyledIcon name={Icons.INFO} size={20} />
        <Content>{children}</Content>
      </StyledCard>
    </Container>
  );
};

const StyledIcon = styled(Icon)`
  margin-right: 16px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 0;
`;
const Bar = styled.div`
  background: #6099f1;
  width: 4px;
`;
const Container = styled.div`
  display: flex;
`;
const Content = styled.div``;
