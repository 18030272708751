import { MatchBlocks } from "../../../../../../../models/configuration/definitions/matchlogic";
import { ErrorLevel } from "../../../../../../../models/error";
import { Fields } from "./Condition";
import {
  ErrorNames,
  LocalizedExpressionError,
  LocalizedMediaPortalError,
} from "./LocalizedMediaPortalError";
import { CONDITIONS_TYPES_DICTIONNARY, ConditionTypes } from "./dictionnaries";
import { operatorParser } from "./operatorParser";
import { ISplittedExpression, OperatorParserData } from "./types";

export const splitExpression = (
  expression: string,
  matchBlock: MatchBlocks
): ISplittedExpression => {
  let splittedLeft: string[] = [];
  let conditionType;

  const result: OperatorParserData = {
    leftOfOperator: "",
    rightOfOperator: "",
    sensitive: true,
    englishOperator: false,
    operator: undefined,
    errors: [],
  };
  const errors: LocalizedExpressionError[] = [];

  if (expression.length) {
    operatorParser(expression, result);

    if (!result.rightOfOperator.trim().length) {
      errors.push(
        new LocalizedMediaPortalError(
          "ERROR_MATCH_RULE_CARD_EXPECTED_VALUE_MISSING",
          ErrorNames.MISSING_FIELD,
          ErrorLevel.WARNING,
          { expression, missingField: Fields.EXPECTED_VALUE }
        )
      );
    }

    for (const [_conditionType, { cooked }] of CONDITIONS_TYPES_DICTIONNARY) {
      const originRespCondition =
        _conditionType === ConditionTypes.RESPONSE_HEADER ||
        _conditionType === ConditionTypes.RESPONSE_STATUS;
      const shouldSkipCondition =
        matchBlock !== MatchBlocks.ORIGIN_RESP && originRespCondition;

      if (shouldSkipCondition) {
        continue;
      } else if (
        result.leftOfOperator.split(cooked.replace("%headerName", "")).length >
        1
      ) {
        conditionType = _conditionType;
        break;
      }
    }

    if (!conditionType) {
      errors.push(
        new LocalizedMediaPortalError(
          "ERROR_MATCH_RULE_CARD_CONDITION_TYPE_MISSING_OR_NOT_SUPPORTED",
          ErrorNames.EXPRESSION_PARSING_ERROR,
          ErrorLevel.WARNING,
          { expression, missingField: Fields.CONDITION_TYPE }
        )
      );
    }

    splittedLeft = result.leftOfOperator.split(".");
  }

  return {
    errors,
    operator: result.operator,
    conditionType,
    objectName: splittedLeft.length
      ? splittedLeft[splittedLeft.length - 1]
      : "",
    expectedValue: result.rightOfOperator,
    sensitive: result.sensitive,
    englishOperator: result.englishOperator,
  };
};
