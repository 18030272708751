import { FC } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Button, ButtonProps } from "../../atoms/Button/Button";
import { TextField } from "../TextField/TextField";
import { IModalChildren } from "../../atoms/Modal/Modal";

interface IPresentationModal extends IModalChildren {
  title: string;
  button?: Omit<ButtonProps, "onClick">;
  titleEditable?: boolean;
  dataTestId?: string;
  onSubmit?: () => void;
  onTitleSave?: (title: string) => void;
}

interface ConfirmPresentationModal extends IPresentationModal {
  button: Omit<ButtonProps, "onClick">;
  dataTestId?: string;
  onSubmit: () => void;
}

type PresentationModalProps = IPresentationModal | ConfirmPresentationModal;

export const PresentationModal: FC<PresentationModalProps> = ({
  title,
  button,
  children,
  titleEditable = false,
  dataTestId,
  closeModal,
  onSubmit,
  onTitleSave,
}) => {
  const { t } = useTranslation("misc");

  return (
    <ModalContainer>
      <Title
        id={"presentation-modal-title"}
        text={title}
        editable={titleEditable}
        onSave={onTitleSave}
        className="-text--h3"
      />
      {children}
      <ModalFooter>
        <Button
          label={t("CANCEL")}
          onClick={() => closeModal && closeModal()}
          backgroundColor="baseLight"
          textColor="primary"
          borderColor="mutedLight"
        />
        {button && (
          <Button
            label={button.label}
            backgroundColor={button.backgroundColor}
            textColor={button.textColor}
            borderColor={button.borderColor}
            onClick={() => {
              onSubmit && onSubmit();
              closeModal && closeModal();
            }}
            dataTestId={`submit-button-${dataTestId}`}
          />
        )}
      </ModalFooter>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  padding: 32px;
`;

const ModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  .button:not(:last-child) {
    margin-right: 8px;
  }
`;

const Title = styled(TextField)``;
