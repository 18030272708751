import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Accordion } from "../../../../../../../../components/molecules/Accordion/Accordion";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { OriginDefinitionType } from "../../../../../../../../store/slices/caching/types";
import { useIsViewMode } from "../../../../../../../../store/slices/permissions/hooks";

interface IFailoverOriginProps {
  originDefinition: OriginDefinitionType;
  onChange: (updatedOriginDef: Partial<OriginDefinitionType>) => void;
}

export const FailoverOrigin: React.FC<IFailoverOriginProps> = ({
  originDefinition,
  onChange,
}) => {
  const { t } = useTranslation("configurationPropertyPage");
  const isViewMode = useIsViewMode();

  return (
    <SectionWrapper>
      <Accordion title="Failover Conditions" dataTestId="failover-condition">
        <PropertyDefinitionCard
          divider
          title={t("PROPERTY_CARD_ORIGIN_FAILOVER_4XX_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ORIGIN_FAILOVER_4XX_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ORIGIN_FAILOVER_4XX_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.CheckboxType}
          fieldProps={{
            value: originDefinition.conditions?.failoverOn4xx,
            checked: originDefinition.conditions?.failoverOn4xx,
            label: t("PROPERTY_CARD_ORIGIN_FAILOVER_4XX_CHECKBOX_LABEL"),
            onChange: (failoverOn4xx) => {
              onChange({
                conditions: {
                  ...(originDefinition.conditions ?? {}),
                  failoverOn4xx,
                },
              });
            },
            disabled: isViewMode,
            dataTestId: "origin-failover-failover-400",
          }}
          dataTestId="origin-failover-failover-400"
        />
        <PropertyDefinitionCard
          title={t("PROPERTY_CARD_ORIGIN_FAILOVER_5XX_TITLE")}
          additionalInfoTitle={t(
            "PROPERTY_CARD_ORIGIN_FAILOVER_5XX_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "PROPERTY_CARD_ORIGIN_FAILOVER_5XX_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.CheckboxType}
          fieldProps={{
            value: originDefinition.conditions?.failoverOn5xx,
            checked: originDefinition.conditions?.failoverOn5xx,
            label: t("PROPERTY_CARD_ORIGIN_FAILOVER_5XX_CHECKBOX_LABEL"),
            onChange: (failoverOn5xx) => {
              onChange({
                conditions: {
                  ...(originDefinition.conditions ?? {}),
                  failoverOn5xx,
                },
              });
            },
            disabled: isViewMode,
            dataTestId: "origin-failover-failover-500",
          }}
          dataTestId="origin-failover-failover-500"
        />
      </Accordion>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  width: 100%;
`;
