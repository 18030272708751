import styled from "@emotion/styled";
import { animated } from "react-spring";

import { ButtonLink } from "../../../../../components/atoms/ButtonLink/ButtonLink";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import {
  Direction,
  SlidingToaster,
} from "../../../../../components/atoms/SlidingToaster/SlidingToaster";

export interface BulkRemoveToasterProps {
  open: boolean;
  text: string;
  label: string;
  onClick: () => void;
}

export const BulkRemoveToaster = ({
  open,
  text,
  label,
  onClick,
}: BulkRemoveToasterProps): JSX.Element => (
  <SlidingToaster isOpen={open} direction={Direction.BOTTOM}>
    <Container>
      <Content>
        <Text>{text}</Text>
        <ButtonLink label={label} onClick={onClick} />
      </Content>
    </Container>
  </SlidingToaster>
);

const Content = styled(FlexBox)`
  justify-content: space-between;
`;

const Text = styled.div`
  font-weight: 600;
`;

const Container = styled(animated.div)`
  padding: 16px 20px;
  width: 400px;
  background: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  box-shadow: 0px 8px 16px rgba(36, 37, 38, 0.12);
  border-radius: 8px;
`;
