import styled from "@emotion/styled";
import { useState, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button } from "../../../../../../components/atoms/Button/Button";
import {
  HorizontalFormBody,
  HorizontalFormContainer,
} from "../../../../../../components/atoms/HorizontalFormLayout/HorizontalFormLayout";
import {
  HorizontalInputField,
  HorizontalField,
} from "../../../../../../components/molecules/HorizontalInputField/HorizontalInputField";
import { HorizontalFieldName } from "../../../../../../components/atoms/HorizontalFieldName/HorizontalFieldName";
import { Checkbox } from "../../../../../../components/molecules/Checkbox/Checkbox";
import {
  NewBannersModalChildProps,
  BannerServiceTypes,
  BannerUserTypes,
  BannerHeader,
} from "../../../models/models";
import { Dropdown } from "../../../../../../components/organisms/Dropdown/Dropdown";
import { FlexBox } from "../../../../../../components/atoms/FlexBox/FlexBox";
import {
  bannersReducer,
  BannersReducerAction,
  defaultBanner,
} from "./BannersReducer";

const serviceDropdownItems = [
  {
    label: BannerServiceTypes.CACHING,
    value: BannerServiceTypes.CACHING,
    default: false,
    dataTestId: "service-selector-0",
  },
  {
    label: BannerServiceTypes.EDGE,
    value: BannerServiceTypes.EDGE,
    default: false,
    dataTestId: "service-selector-1",
  },
  {
    label: BannerServiceTypes.ITM,
    value: BannerServiceTypes.ITM,
    default: false,
    dataTestId: "service-selector-2",
  },
  {
    label: BannerServiceTypes.MESH,
    value: BannerServiceTypes.MESH,
    default: false,
    dataTestId: "service-selector-3",
  },
  {
    label: BannerServiceTypes.OBJECT,
    value: BannerServiceTypes.OBJECT,
    default: false,
    dataTestId: "service-selector-4",
  },
  {
    label: BannerServiceTypes.ORCHESTRATOR,
    value: BannerServiceTypes.ORCHESTRATOR,
    default: false,
    dataTestId: "service-selector-5",
  },
  {
    label: BannerServiceTypes.ORIGIN,
    value: BannerServiceTypes.ORIGIN,
    default: false,
    dataTestId: "service-selector-6",
  },
];

const userDropdownItems = [
  {
    label: BannerUserTypes.ALL,
    value: BannerUserTypes.ALL,
    default: false,
    dataTestId: "user-selector-0",
  },
  {
    label: BannerUserTypes.INTERNAL_ALL,
    value: BannerUserTypes.INTERNAL_ALL,
    default: false,
    dataTestId: "user-selector-1",
  },
  {
    label: BannerUserTypes.INTERNAL_Vyvx,
    value: BannerUserTypes.INTERNAL_Vyvx,
    default: false,
    dataTestId: "user-selector-2",
  },
];

export const BannersModalContent: React.FC<NewBannersModalChildProps> = ({
  closeModal,
  handleSaveBanners,
  bannerHeader,
  isUpdate,
  dataTestId,
}) => {
  const { t } = useTranslation("bannersModalPage");
  const [startingNow, setStartingNow] = useState<boolean>(true);

  const [_bannerHeader, dispatchBanners] = useReducer<
    Reducer<BannerHeader, BannersReducerAction>
  >(bannersReducer, bannerHeader ?? defaultBanner);

  const handleConfirm = () => {
    handleSaveBanners(_bannerHeader, isUpdate, _bannerHeader.id);
    closeModal && closeModal();
  };

  const handleStartingNow = () => {
    setStartingNow((preStartingNow) => !preStartingNow);
  };

  return (
    <NewPropertyModalContainer>
      <Title>
        {isUpdate ? t("TITLE_UPDATE_BANNER") : t("TITLE_CREATE_BANNER")}
      </Title>
      <HorizontalFormContainer>
        <HorizontalFormBody>
          <HorizontalInputField
            placeholder={t("NAME_BANNER_FORM_INPUT_PLACEHOLDER")}
            value={_bannerHeader.name}
            label={"Banner Name"}
            onChange={(event) => {
              dispatchBanners({
                type: "UPDATE_NAME",
                payload: { name: event.target.value },
              });
            }}
            dataTestId={`${dataTestId}-name`}
            required
          />
          <HorizontalInputField
            placeholder={t("MESSAGE_BANNER_FORM_INPUT_PLACEHOLDER")}
            value={_bannerHeader.message}
            label={"Banner Message"}
            onChange={(event) => {
              dispatchBanners({
                type: "UPDATE_MESSAGE",
                payload: { message: event.target.value },
              });
            }}
            dataTestId={`${dataTestId}-message`}
            required
          />
          <HorizontalField>
            <HorizontalFieldName>
              {t("SERVICE_TYPE_BANNER")}
            </HorizontalFieldName>
            <DropdownContainer>
              <Dropdown
                items={serviceDropdownItems.map((serviceDropdownItem) => ({
                  ...serviceDropdownItem,
                  deafult:
                    serviceDropdownItem.value === _bannerHeader.serviceType,
                }))}
                id="service-selector"
                placeholder={t("SERVICE_TYPE_BANNER_FORM_INPUT_PLACEHOLDER")}
                onSelect={(item) =>
                  dispatchBanners({
                    type: "UPDATE_SERVICE_TYPE",
                    payload: { serviceType: item.value },
                  })
                }
                dataTestId={`${dataTestId}-service-selector`}
              />
            </DropdownContainer>
          </HorizontalField>
          <HorizontalField>
            <HorizontalFieldName required>
              {t("BANNER_PRIORITY")}
            </HorizontalFieldName>
            <Checkbox
              id={"Banners_Priority"}
              value={_bannerHeader.priority}
              onClick={() => {}}
              onChange={() => {
                dispatchBanners({
                  type: "UPDATE_PRIORITY",
                  payload: { priority: !_bannerHeader.priority },
                });
              }}
              checked={_bannerHeader.priority}
              label={t("PRIORITY_BANNER_FORM_INPUT_PLACEHOLDER")}
              dataTestId={`${dataTestId}-priority`}
            />
          </HorizontalField>
          <HorizontalField>
            <HorizontalFieldName required>
              {t("USER_TYPE_BANNER")}
            </HorizontalFieldName>
            <DropdownContainer>
              <Dropdown
                items={userDropdownItems.map((userDropdownItem) => ({
                  ...userDropdownItem,
                  deafult: userDropdownItem.value === _bannerHeader.userType,
                }))}
                id="user-selector"
                placeholder={t("USER_TYPE_BANNER_FORM_INPUT_PLACEHOLDER")}
                onSelect={(item) =>
                  dispatchBanners({
                    type: "UPDATE_USER_TYPE",
                    payload: { userType: item.value },
                  })
                }
                dataTestId={`${dataTestId}-user-selector`}
              />
            </DropdownContainer>
          </HorizontalField>
          <HorizontalField>
            <HorizontalFieldName required>
              {t("BANNER_START_DATE")}
              <em>{t("LOCAL_TIMEZONE")}</em>
            </HorizontalFieldName>
            {!isUpdate && (
              <Checkbox
                id={"Starting_Now"}
                value={startingNow}
                onClick={() => {}}
                onChange={handleStartingNow}
                checked={startingNow}
                label={t("STARTING_NOW_BANNER_FORM_INPUT_PLACEHOLDER")}
                dataTestId={`${dataTestId}-starting-now`}
              />
            )}
            {(!startingNow || isUpdate) && (
              <DatePickerContainer>
                <DatePicker
                  selected={new Date(_bannerHeader.startTime)}
                  onChange={(date) => {
                    if (date) {
                      dispatchBanners({
                        type: "UPDATE_START_TIME",
                        payload: { startTime: date.toISOString() },
                      });
                    }
                  }}
                  showTimeSelect
                  dateFormat="MMMM d, HH:mm z"
                  timeFormat="HH:mm"
                  minDate={new Date()}
                />
              </DatePickerContainer>
            )}
          </HorizontalField>
          <HorizontalField>
            <HorizontalFieldName required>
              {t("BANNER_END_DATE")} <em>{t("LOCAL_TIMEZONE")}</em>
            </HorizontalFieldName>
            <DatePicker
              selected={new Date(_bannerHeader.endTime)}
              onChange={(date) => {
                if (date) {
                  dispatchBanners({
                    type: "UPDATE_END_TIME",
                    payload: { endTime: date.toISOString() },
                  });
                }
              }}
              showTimeSelect
              dateFormat="MMMM d, HH:mm z"
              timeFormat="HH:mm"
              minDate={new Date()}
            />
          </HorizontalField>
        </HorizontalFormBody>
      </HorizontalFormContainer>
      <Buttons>
        <ButtonContainer>
          <Button
            backgroundColor="mutedDark"
            textColor="primaryAlt"
            label={t("BANNERS_FORM_CANCEL_BUTTON")}
            onClick={() => closeModal && closeModal()}
            dataTestId={`${dataTestId}-cancel`}
          />
        </ButtonContainer>
        <ButtonContainer>
          <Button
            label={t("BANNERS_FORM_CONFIRM_BUTTON")}
            disabled={
              !_bannerHeader.name.length ||
              !_bannerHeader.message.length ||
              !_bannerHeader.userType.length ||
              !_bannerHeader.startTime.length ||
              !_bannerHeader.endTime.length
            }
            onClick={handleConfirm}
            dataTestId={`${dataTestId}-confirm`}
          />
        </ButtonContainer>
      </Buttons>
    </NewPropertyModalContainer>
  );
};

const NewPropertyModalContainer = styled(FlexBox)`
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
`;

const Title = styled.h3`
  margin-bottom: 50px !important;
  text-align: center;
`;

const Buttons = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;
`;

const ButtonContainer = styled.div`
  margin-left: 32px;
`;

const DropdownContainer = styled.div`
  margin-left: -8px;
  margin-top: -10px;
`;

const DatePickerContainer = styled.div`
  padding-bottom: 8px;
`;
