import { ComponentProps, ReactElement, useContext } from "react";
import styled from "@emotion/styled";

import { Theme, ThemeContext } from "../../../contexts/themeContext";
import {
  HeadlessRadioField,
  RadioButton,
  RadioButtonProps,
} from "../../atoms/RadioButton/RadioButton";
import { Tooltip } from "../Tooltip/Tooltip";
import { HelpIconButton } from "../HelpIconButton/HelpIconButton";

export type RadioButtonsSelectorProps<T> = {
  legend?: string;
  required?: boolean;
  optional?: boolean;
  helpMessage?: string;
  errorMessage?: string;
  inline?: boolean;
} & (
  | {
      headlessRadio: true;
      radioButtonsProps: ComponentProps<typeof HeadlessRadioField>[];
    }
  | {
      headlessRadio: false;
      radioButtonsProps: RadioProps<T>[];
    }
);

export interface RadioProps<T> extends RadioButtonProps<T> {
  popupText?: string;
}

export const RadioButtonsSelector = <T,>({
  legend,
  required = false,
  optional = false,
  helpMessage = "",
  errorMessage = "",
  inline = false,
  radioButtonsProps,
  headlessRadio,
}: RadioButtonsSelectorProps<T>): ReactElement => {
  const { theme } = useContext(ThemeContext);

  return (
    <fieldset>
      {legend && (
        <LegendContainer>
          <Legend className="chi-label" isDark={theme === Theme.DARK_MODE}>
            {legend}
          </Legend>
          {optional && (
            <Optional className="chi-label__optional" title="Optional field">
              (optional)
            </Optional>
          )}
          {(required || errorMessage.length > 0) && (
            <Asterisk className="chi-label__required" title="Required field">
              *
            </Asterisk>
          )}
          {helpMessage.length > 0 && (
            <HelpIconButton helpMessage={helpMessage} />
          )}
        </LegendContainer>
      )}
      {headlessRadio
        ? radioButtonsProps.map((_props: any) => {
            const props = _props as ComponentProps<typeof HeadlessRadioField>;

            return (
              <div
                className={`chi-form__item ${inline && "-inline"}`}
                key={props.id}
              >
                <HeadlessRadioField {...props} />
              </div>
            );
          })
        : radioButtonsProps.map((_props: any) => {
            const { popupText, ...props } = _props as RadioProps<any>;
            const Radio = <RadioButton {...props} />;

            return (
              <div
                className={`chi-form__item ${inline && "-inline"}`}
                key={props.id}
              >
                {popupText ? (
                  <Tooltip
                    popupText={popupText}
                    id={props.id}
                    dataTestId={props.dataTestId}
                  >
                    {Radio}
                  </Tooltip>
                ) : (
                  Radio
                )}
              </div>
            );
          })}

      {errorMessage.length > 0 && (
        <div className="chi-label -status -danger">{errorMessage}</div>
      )}
    </fieldset>
  );
};

const LegendContainer = styled.div`
  display: flex;
`;

const Legend = styled.legend<{ isDark: boolean }>`
  margin-top: 2px;
  color: ${({ isDark }) => (isDark ? "#e3e3e3" : "inherit")};
`;

const Optional = styled.div`
  margin-left: 5px;
  margin-top: 2px;
  font-size: 0.875rem;
`;

const Asterisk = styled.div`
  margin-left: 5px;
  color: #d62015;
`;
