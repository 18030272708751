import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface InfiniteScrollContainerProps<T> {
  data: T[];
  pageSize: number;
  onUpdateData: (newData: T[]) => void;
  children: ReactNode;
  scrollableTarget: ReactNode;
}

export const InfiniteScrollContainer = <T,>({
  children,
  data,
  pageSize,
  onUpdateData,
  scrollableTarget,
}: InfiniteScrollContainerProps<T>): ReactElement => {
  const [tableData, setTableData] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const updateTableData = useCallback(
    (reset = false) => {
      const newData: T[] = [];
      const newPage = reset ? 0 : page;
      if (newPage * pageSize < data.length) {
        for (
          let i = newPage * pageSize;
          i < Math.min((newPage + 1) * pageSize, data.length);
          i++
        ) {
          newData.push(data[i]);
        }
        setHasMore(true);
        setPage(newPage + 1);
      } else {
        setHasMore(false);
      }

      if (reset) {
        onUpdateData(newData);
        setTableData(newData);
      } else {
        onUpdateData([...tableData, ...newData]);
        setTableData((prevData) => {
          const updatedData = [...prevData, ...newData];
          return updatedData;
        });
      }
    },
    [tableData, page, data]
  );

  useEffect(() => {
    updateTableData(true);
  }, [data]);

  return (
    <InfiniteScroll
      dataLength={tableData.length}
      next={updateTableData}
      hasMore={hasMore}
      loader={null}
      scrollableTarget={scrollableTarget}
    >
      {children}
    </InfiniteScroll>
  );
};
