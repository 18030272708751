import { ReactElement, ReactNode } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { UserRoles } from "../../../models/permissions";
import { ListItem } from "../../atoms/ListItem/ListItem";
import { Badge } from "../../atoms/Badge/Badge";
import { ExpandableContent } from "../ExpandableContent/ExpandableContent";
import { Protected } from "../../atoms/Protected/Protected";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { PresentationModal } from "../PresentationModal/PresentationModal";
import { Modal } from "../../atoms/Modal/Modal";
import {
  ConfigurationDefinition,
  ConfigurationDetailsType,
  OriginDefinitionType,
} from "../../../store/slices/caching/types";
import { isOfType } from "../../../store/slices/caching/helpers/isOfType";
import { getDefinitionReferences } from "../../../store/slices/caching/helpers/getDefinitionReferences";
import { getDefinitionDescription } from "../../../store/slices/caching/helpers/getDefinitionDescription";
import { getOriginDefinitionPropertyReferences } from "../../../store/slices/caching/helpers/getOriginDefinitionPropertyReferences";

export interface DefinitionListItemProps<
  Definition extends ConfigurationDefinition
> {
  title?: string;
  additionalInfoTitle?: string;
  additionalInfoContent?: string;
  items?: Definition[];
  isLocalDraft?: boolean;
  forceShowDelete?: (definition: Definition) => boolean;

  renderEditModal: (item: Definition) => ReactNode;
  onDelete: (item: Definition) => void;
  renderAddModal: () => ReactNode;
  customDescription?: (item: Definition) => string;
  referenceObjectName?: { singular: string; plural: string };
  dataTestId?: string;
  parentConfig: ConfigurationDetailsType;
}

const formatDescription = (description: string) => {
  const maxDescriptionLength = 60;
  if (description.length > maxDescriptionLength) {
    return description.slice(0, maxDescriptionLength).concat("...");
  }

  return description;
};

interface ReferencesProps {
  reference: string;
}

const References = ({ reference }: ReferencesProps) => {
  const text = reference.split(/<b>|<\/b>/);

  return (
    <span>
      {text.map((chunk, i) =>
        i % 2 === 0 ? chunk : <BoldChunk>{chunk}</BoldChunk>
      )}
    </span>
  );
};

export function DefinitionListItem<Definition extends ConfigurationDefinition>({
  title,
  additionalInfoTitle,
  additionalInfoContent,
  items,
  isLocalDraft,
  forceShowDelete,
  renderEditModal,
  onDelete,
  renderAddModal,
  customDescription,
  referenceObjectName,
  dataTestId,
  parentConfig,
}: DefinitionListItemProps<Definition>): ReactElement {
  const [tConfigurationDefinitionsPage] = useTranslation(
    "configurationDefinitionsPage"
  );
  const [tMisc] = useTranslation("misc");

  const hasNoReferences = (item: ConfigurationDefinition) => {
    return (
      getDefinitionReferences(item, parentConfig) === 0 &&
      (!isOfType(item, TypeDefinitions.ORIGIN) ||
        (isOfType(item, TypeDefinitions.ORIGIN) &&
          getOriginDefinitionPropertyReferences(item.name, parentConfig) === 0))
    );
  };

  return (
    <DefinitionCardContainer
      className="definition-card"
      data-testid={`definition-card-${dataTestId}`}
    >
      <DefinitionSection>
        {!!title && (
          <DefinitionInfo>
            <DefinitionGroupTitle
              data-testid={`definition-card-counter-${dataTestId}`}
            >
              {title} ({items?.length ?? 0})
            </DefinitionGroupTitle>
          </DefinitionInfo>
        )}
        {!!additionalInfoTitle && (
          <AdditionalInfoContainer>
            <ExpandableContent
              title={additionalInfoTitle}
              content={additionalInfoContent}
            />
          </AdditionalInfoContainer>
        )}
        <DefinitionsList>
          {items?.map((item, i) => (
            <Card
              key={item.id}
              data-testid={`definition-card-${dataTestId}-${i}`}
            >
              <LeftSection>
                <CardHeader>
                  <DefinitionName>
                    <h5>{item.name}</h5>
                  </DefinitionName>
                  <References
                    reference={
                      referenceObjectName
                        ? tConfigurationDefinitionsPage("CUSTOM_REFERENCES", {
                            count: getDefinitionReferences(item, parentConfig),
                            rule:
                              getDefinitionReferences(item, parentConfig) === 1
                                ? referenceObjectName.singular
                                : referenceObjectName.plural,
                          })
                        : isOfType(item, TypeDefinitions.ORIGIN)
                        ? tConfigurationDefinitionsPage(
                            "MATCH_RULES_ORIGIN_INFO",
                            {
                              count: getDefinitionReferences(
                                item,
                                parentConfig
                              ),
                              propertyCount: getOriginDefinitionPropertyReferences(
                                item.name,
                                parentConfig
                              ),
                              rule: tConfigurationDefinitionsPage(
                                `${
                                  getDefinitionReferences(
                                    item,
                                    parentConfig
                                  ) === 1
                                    ? "RULE"
                                    : "RULES"
                                }` as const
                              ),
                              property: tConfigurationDefinitionsPage(
                                `${
                                  getOriginDefinitionPropertyReferences(
                                    item.name,
                                    parentConfig
                                  ) === 1
                                    ? "PROPERTY"
                                    : "PROPERTIES"
                                }` as const
                              ),
                            }
                          )
                        : tConfigurationDefinitionsPage("MATCH_RULES_INFO", {
                            count: getDefinitionReferences(item, parentConfig),
                            propertyCount: isOfType<OriginDefinitionType>(
                              item as OriginDefinitionType,
                              TypeDefinitions.ORIGIN
                            )
                              ? getOriginDefinitionPropertyReferences(
                                  (item as OriginDefinitionType).name,
                                  parentConfig
                                )
                              : 0,
                            rule: tConfigurationDefinitionsPage(
                              `${
                                getDefinitionReferences(item, parentConfig) ===
                                1
                                  ? "RULE"
                                  : "RULES"
                              }` as const
                            ),
                          })
                    }
                  />
                </CardHeader>
                <DefinitionDescription>
                  {customDescription
                    ? customDescription(item)
                    : formatDescription(getDefinitionDescription(item))}
                </DefinitionDescription>
              </LeftSection>
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <RightSection>
                  {isLocalDraft && (
                    <Badge
                      helpText={tMisc("UNSUBMITTED_CHANGES_DESCRIPTION")}
                      label={tMisc("UNSUBMITTED_CHANGES_LABEL")}
                      color="yellow40"
                    />
                  )}
                  <CardButtons>
                    {renderEditModal(item)}
                    {(hasNoReferences(item) ||
                      (forceShowDelete && forceShowDelete(item))) && (
                      <Modal
                        customButton={
                          <TrashIcon
                            dataTestId={`definition-card-delete-button-${dataTestId}-${i}`}
                            name={Icons.TRASH}
                          />
                        }
                      >
                        <PresentationModal
                          title={tConfigurationDefinitionsPage(
                            "DELETE_DEFINITION_TITLE"
                          )}
                          onSubmit={() => onDelete(item)}
                          button={{
                            label: tConfigurationDefinitionsPage(
                              "DELETE_DEFINITION_BUTTON"
                            ),
                          }}
                          dataTestId={`definition-card-delete-${dataTestId}`}
                        >
                          <ModalBody>
                            {tConfigurationDefinitionsPage(
                              "DELETE_DEFINITION_CONTENT"
                            )}
                            &nbsp;
                            <b>{item.name}</b>
                          </ModalBody>
                        </PresentationModal>
                      </Modal>
                    )}
                  </CardButtons>
                </RightSection>
              </Protected>
            </Card>
          ))}
          <DefinitionFooter>{renderAddModal()}</DefinitionFooter>
        </DefinitionsList>
      </DefinitionSection>
    </DefinitionCardContainer>
  );
}

export const CardsContainer = styled.div`
  & > div:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.borders.mutedLight}`};
  }
`;

export const DefinitionCardContainer = styled.section``;

const DefinitionSection = styled.div``;

const CardHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const DefinitionName = styled.span`
  margin-right: 24px;
`;

const DefinitionInfo = styled.article`
  max-width: 25%;
`;

const DefinitionGroupTitle = styled.h4`
  margin: 0;
  margin-bottom: 8px;
`;

const DefinitionsList = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const DefinitionFooter = styled.div`
  margin-top: 1em;

  button.add-definition-btn {
    color: ${({ theme }) => theme.text.highlight};
  }
`;

const AdditionalInfoContainer = styled.div``;

const Card = styled(ListItem)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  border-radius: 8px;

  flex-wrap: nowrap;
  cursor: default;
`;

const CardButtons = styled.div`
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const TrashIcon = styled(Icon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.icon.muted};
  }
`;

const LeftSection = styled.section``;
const RightSection = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .chi-badge {
    margin-right: 3em;
  }
`;

const DefinitionDescription = styled.div`
  color: ${({ theme }) => theme.text.muted};
  padding-right: 16px;
`;

const ModalBody = styled.div``;

const BoldChunk = styled.b`
  color: ${({ theme }) => theme.text.highlight};
`;
