import styled from "@emotion/styled";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../../../components/atoms/Button/Button";
import {
  IUseAnchorMenu,
  useAnchorMenu,
} from "../../../../hooks/use-anchor-menu";
import { CertificateHeader } from "./EditHeader";
import { UploadSection } from "./UploadSection";

import { AnchorWrapper } from "../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { CustomRemark } from "../../../../components/atoms/CustomRemark/CustomRemark";
import { AnchorMenu } from "../../../../components/molecules/AnchorMenu/AnchorMenu";
import {
  CertificateReducerActions,
  mapEntryToField,
  useCertificateCrupdate,
} from "./useCertificateCrupdate";
import { ModuleContent } from "../../../../components/atoms/Module/Module";

const SCROLL_ID = "certificate_edit";

export const EditPage: FC = () => {
  const {
    isCreation,
    isNotCommited,
    isPasswordRequired,
    cert_name,
    certificate,
    submitCert,
    onInputChange,
    hasUnsavedChanged,
  } = useCertificateCrupdate();
  const { t } = useTranslation("certificatePage");
  const { t: tMisc } = useTranslation("misc");

  const items: IUseAnchorMenu = {
    itemNames: [
      {
        name: t("CERTIFICATE_LABEL"),
        value: CertificateReducerActions.certificate,
        id: "certificate",
      },
      {
        name: t("CERTIFICATE_BUNDLE_LABEL"),
        value: CertificateReducerActions.bundle,
        id: "bundle",
      },
      {
        name: t("PRIVATE_KEY_LABEL"),
        value: CertificateReducerActions.private_key,
        id: "private_key",
      },
      {
        name: t("PASSWORD_LABEL"),
        value: CertificateReducerActions.password,
        id: "password",
      },
    ],
  };

  const fieldRequired = [true, false, true, isPasswordRequired];

  const menuDescriptions = [
    t("CERTIFICATE_DESCRIPTION"),
    t("CERTIFICATE_BUNDLE_DESCRIPTION"),
    t("PRIVATE_KEY_DESCRIPTION"),
    t("PASSWORD_DESCRIPTION"),
  ];

  const menu = useAnchorMenu(items);

  const history = useHistory();

  return (
    <>
      <CertificateHeader
        submitCert={submitCert}
        onCertificateNameChange={onInputChange(CertificateReducerActions.name)}
        certificateName={certificate.cert_name || cert_name}
        isNewCert={isCreation}
        hasUnsavedChanged={hasUnsavedChanged}
        isNotCommited={isNotCommited}
        handleNavBack={() => {
          if (hasUnsavedChanged) {
            toast.warn(
              <div>
                <h5>{t("INFO_TOAST_TITLE")}</h5>
                <p>
                  <CustomRemark>
                    {isCreation
                      ? t("WARNING_TOAST_DESCRIPTION_CREATION")
                      : t("WARNING_TOAST_DESCRIPTION_STANDARD")}
                  </CustomRemark>
                </p>
                <Button
                  label={
                    isCreation
                      ? t("WARNING_TOAST_PROCEED_BUTTON")
                      : t("WARNING_TOAST_DISCARD_BUTTON")
                  }
                  onClick={() => {
                    history.push("/cmv3/certificates");
                  }}
                  dataTestId="certificate-page-proceed-button"
                />
                {!isCreation && (
                  <Button
                    label={tMisc("SAVE")}
                    onClick={() => {
                      window.localStorage.setItem(
                        `certificate_${cert_name}`,
                        JSON.stringify(certificate)
                      );
                      history.push("/cmv3/certificates");
                    }}
                    disabled={!cert_name}
                  />
                )}
              </div>,
              {
                autoClose: false,
                progress: undefined,
                hideProgressBar: true,
              }
            );
          } else {
            history.push("/cmv3/certificates");
          }
        }}
      />
      <ModuleContent id={SCROLL_ID}>
        <CertificateContentContainer data-testid="certificate-page-content">
          <AnchorMenu
            menuItems={menu}
            hasPadding={false}
            scrollId={SCROLL_ID}
          />
          <div>
            {menu.map(({ name: entry, value, ref, id }, i) => (
              <AnchorWrapper id={id} ref={ref} key={entry}>
                <UploadSection
                  title={entry}
                  hasFileUpload={entry !== "Password"}
                  description={menuDescriptions[i]}
                  onContentChange={onInputChange(
                    value as CertificateReducerActions
                  )}
                  required={fieldRequired[i]}
                  content={
                    certificate[
                      mapEntryToField[value as CertificateReducerActions]
                    ] || ""
                  }
                  dataTestId={`certificate-form-${entry.toLowerCase()}`}
                />
              </AnchorWrapper>
            ))}
          </div>
        </CertificateContentContainer>
      </ModuleContent>
    </>
  );
};

const CertificateContentContainer = styled.div`
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer}`};
  display: flex;
  flex-direction: row;
`;
