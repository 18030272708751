import { FC } from "react";
import styled from "@emotion/styled";

import { InputLabel } from "./InputLabel";

interface FeedbackInputProps {
  id: string;
  label: string;
}

export const FeedbackInput: FC<FeedbackInputProps> = ({
  id,
  label,
  children,
}) => (
  <FormInput className="feedback-mb">
    <InputLabel>
      {label}
      <Asterisk
        className="chi-label__required"
        title="Required field"
        data-testid={`feedback-${id}-label-asterisk`}
      >
        *
      </Asterisk>
    </InputLabel>

    {children}
  </FormInput>
);

const FormInput = styled.div`
  display: flex;

  & > .feedback-input-container {
    margin-right: 0;
  }
  & input {
    padding: 0;
  }
`;

const Asterisk = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.text.error};
`;
