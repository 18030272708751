import { IFeatures } from "../../../models/configuration/definitions";
import { HeadersBase } from "./HeadersBase";
import { FeatureComponent } from "./types";

type ReqHeadersComponent = FeatureComponent<
  Exclude<IFeatures["reqHeaders"], undefined>
>;

export const ReqHeaders: ReqHeadersComponent = ({ feature, tPropertyPage }) => (
  <HeadersBase
    dataTestId="reqHeaders"
    title={tPropertyPage("FEATURE_CARD_HEADERS_TITLE")}
    {...feature}
  />
);
