import { ReactElement } from "react";
import styled from "@emotion/styled";
import { IFeatures } from "../../../models/configuration/definitions";

import { LuaDefinitionType } from "../../../store/slices/caching/types";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardColContent,
  DetailedFeatureCardColTitle,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { ExpandableList } from "../../molecules/ExpandableList/ExpandableList";
import { renderColContent } from "./renderColContent";

export type LuaType = Exclude<IFeatures["reqLua"], undefined>;

interface LuaCardProps extends LuaDefinitionType, LuaType {
  index: number;
  dataTestId: string;
}

const getVariableValue = (
  variableValueList: LuaType["variableValueList"],
  luaName: string,
  variableName: string
) =>
  variableValueList
    ?.find((v) => v.luaName === luaName)
    ?.variableValues.find((v) => v.name === variableName)?.value;

export const LuaCard = ({
  id,
  name,
  _schemaDescription,
  variables,
  variableValueList,
  index,
  dataTestId,
}: LuaCardProps): ReactElement => {
  const shouldRenderVariables = !!variables && variables.length > 0;
  const shouldRenderDescription = !!_schemaDescription;

  return (
    <DetailedFeatureCardRow key={id}>
      <DetailedFeatureCardBlock
        dataTestId={`${dataTestId}-definition-${index}`}
        title={`Definition ${index}`}
      >
        {name}
      </DetailedFeatureCardBlock>

      {(shouldRenderVariables || shouldRenderDescription) && (
        <Block>
          {shouldRenderVariables && (
            <Card data-testid={`${dataTestId}-variable-${index}`}>
              <DetailedFeatureCardColTitle>
                {`Variables ${index}`}
              </DetailedFeatureCardColTitle>

              <DetailedFeatureCardColContent>
                <ExpandableList dataTestId="lua-variables">
                  {variables!.map((variable) => {
                    let children = `${variable.name} - ${variable.type}`;

                    const value = getVariableValue(
                      variableValueList,
                      name,
                      variable.name
                    );

                    if (value) {
                      children += ` - ${value}`;
                    }

                    return renderColContent(children);
                  })}
                </ExpandableList>
              </DetailedFeatureCardColContent>
            </Card>
          )}

          {shouldRenderDescription && (
            <Card data-testid={`${dataTestId}-description-${index}`}>
              <DetailedFeatureCardColTitle>
                {`Description ${index}`}
              </DetailedFeatureCardColTitle>

              <DetailedFeatureCardColContent>
                {_schemaDescription}
              </DetailedFeatureCardColContent>
            </Card>
          )}
        </Block>
      )}
    </DetailedFeatureCardRow>
  );
};

const Block = styled.div`
  max-width: 66%;
`;

const Card = styled.div`
  &:first-child:not(:only-child) {
    padding-bottom: 8px;
  }
`;
