import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { SimpleDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createSimpleDefinition = (
  name: string,
  rest: Pick<SimpleDefinitionType, "description" | "elements" | "listType">
): SimpleDefinitionType => ({
  ...parseDefinition({
    name,
    description: rest.description,
    type: TypeDefinitions.SIMPLE_DEFINITION,
  }),
  elements: rest.elements,
  listType: rest.listType,
});
