import { FC } from "react";
import styled from "@emotion/styled";

import { Link } from "react-router-dom";
import { Icon, Icons } from "../../atoms/Icon/Icon";

export interface ILinkCTAProps {
  to: string;
  message: string;
}

export const LinkCTA: FC<ILinkCTAProps> = ({ to, message }) => (
  <StyledLink to={to}>
    {message}
    <LinkArrow name={Icons.ARROW_LEFT} />
  </StyledLink>
);

export const LinkArrow = styled(Icon)`
  transform: rotate(180deg);
  margin-left: 4px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.text.highlight} !important;
  font-weight: 600;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none !important;
  }
`;
