import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Status } from "../../../@types/status";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { useQuery } from "../../../hooks/use-query";
import { useSubscriberStatus } from "../../../store/slices/subscriber/hooks";
import { handleSubscriber } from "../../../store/slices/subscriber/thunks";
import { useAppDispatch } from "../../../store/types";
import { EmptyPageDescription } from "../../atoms/EmptyPage/EmptyPageWrapper";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";

export const Subscriber: FC = ({ children }) => {
  const [queryScid] = useQuery("scid");
  const [querySubscriberId] = useQuery("subscriber_id");
  const dispatch = useAppDispatch();
  const [localStorageScid] = useLocalStorage<string>("cmv3.scid");
  const [localStorageSubscriberId] = useLocalStorage<string>(
    "cmv3.subscriber_id"
  );

  const { t } = useTranslation("layers");

  const subscriberStatus = useSubscriberStatus();

  const isSubscriberIdMissing = !localStorageSubscriberId && !queryScid;

  useEffect(() => {
    if (!isSubscriberIdMissing) {
      dispatch(
        handleSubscriber(
          queryScid || localStorageScid,
          querySubscriberId
            ? parseInt(querySubscriberId, 10)
            : parseInt(localStorageSubscriberId, 10)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (subscriberStatus === Status.SUCCESS) {
    return <>{children}</>;
  }

  if (subscriberStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("SUBSCRIBER_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (subscriberStatus === Status.ERROR) {
    return <ErrorLayer dataTestId="subscriber-layer-premium-features-error" />;
  }

  if (isSubscriberIdMissing) {
    return (
      <ErrorLayer
        title="Missing subscriber ID"
        content={
          <EmptyPageDescription>
            {t("SUBSCRIBER_LAYER_ERROR_MESSAGE")}
          </EmptyPageDescription>
        }
        dataTestId="missing-subscriber-id-error-layer"
      />
    );
  }

  return null;
};
