import styled from "@emotion/styled";
import { Badge } from "../../../../components/atoms/Badge/Badge";

export const H2 = styled.h2``;
export const H3 = styled.h3``;

export const TableInfoContainer = styled.div`
  padding: 32px;
  background: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid transparent;
  border-radius: 8px;
`;

export const TableInfoTitle = styled.b``;

export const TableInfo = styled.div`
  display: flex;
  width: 65%;
  justify-content: space-between;
`;

export const TableInfoFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LargeTableInfoContainer = styled(TableInfoContainer)`
  display: flex;
`;

export const InfoBadge = styled(Badge)`
  margin-right: 8px;
`;
