import styled from "@emotion/styled";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const ConditionError: FC = () => {
  const { t } = useTranslation("configurationMatchRulesPage");

  return (
    <ErrorsContainer data-testid="preview-unsupported-error">
      <h2>{t("ERROR_MATCH_RULE_CONDITION_PREVIEW_UNSUPPORTED")}</h2>
      <ErrorMessage>
        {t("ERROR_MATCH_RULE_CONDITION_TAB_MESSAGE_PART_1")}
        <br />
        {t("ERROR_MATCH_RULE_CONDITION_TAB_MESSAGE_PART_2")}
      </ErrorMessage>
    </ErrorsContainer>
  );
};

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ErrorMessage = styled.p``;
