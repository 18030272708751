import { Context, createContext, useContext, useEffect, useState } from "react";

import { IOption } from "../../../../components/molecules/SearchDropdown/SearchDropdown";
import { useRawJSON } from "../../../../hooks/use-raw-json/useRawJSON";
import { getConfigurationVersionDetailsToCompare } from "../../../../store/slices/caching/services";
import { ConfigurationType } from "../../../../store/slices/caching/types";

interface ComparisonContext {
  configToCompare: ConfigurationType | undefined;
  isComparison: boolean;
  selectedConfiguration: ConfigurationType | undefined;
  selectedVersion: IOption<number> | undefined;
  setSelectedConfiguration: (param: ConfigurationType | undefined) => void;
  setSelectedVersion: (param: IOption<number> | undefined) => void;
}

const initialContext: ComparisonContext = {
  configToCompare: undefined,
  isComparison: false,
  selectedConfiguration: undefined,
  selectedVersion: undefined,
  setSelectedConfiguration: () => {},
  setSelectedVersion: () => {},
};

export const comparisonContext: Context<ComparisonContext> = createContext(
  initialContext
);

export const useComparisonContext = (): void => {
  const context = useContext(comparisonContext);

  const [selectedVersion, setSelectedVersion] = useState<IOption<number>>();
  const [configToCompare, setConfigToCompare] = useState<ConfigurationType>();
  const [selectedConfiguration, setSelectedConfiguration] = useState(
    useRawJSON().selectedConfiguration
  );

  const isComparison = typeof selectedVersion !== "undefined";

  useEffect(() => {
    if (selectedConfiguration && selectedVersion?.value) {
      getConfigurationVersionDetailsToCompare(
        selectedConfiguration,
        selectedVersion?.value
      ).then(setConfigToCompare);
    }
  }, [selectedConfiguration, selectedVersion?.value]);

  context.configToCompare = configToCompare;
  context.isComparison = isComparison;
  context.selectedConfiguration = selectedConfiguration;
  context.selectedVersion = selectedVersion;
  context.setSelectedConfiguration = setSelectedConfiguration;
  context.setSelectedVersion = setSelectedVersion;
};
