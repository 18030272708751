import { FC, useState } from "react";
import styled from "@emotion/styled";
// eslint-disable-next-line import/named
import { CustomTypeOptions, useTranslation } from "react-i18next";

import { ContentSwitcher } from "../../molecules/ContentSwitcher/ContentSwitcher";
import { CnameListTab } from "../CnameListTab/CnameListTab";
import { PropertyListTab } from "../PropertyList/PropertyListTab";

const ProductionDataTab: Record<
  "PROPERTY" | "CNAMES",
  keyof Pick<
    CustomTypeOptions["resources"]["productionPage"],
    "PRODUCTION_TAB_PROPERTY" | "PRODUCTION_TAB_CNAMES"
  >
> = {
  PROPERTY: "PRODUCTION_TAB_PROPERTY",
  CNAMES: "PRODUCTION_TAB_CNAMES",
};

export const ProductionData: FC = () => {
  const { t } = useTranslation("productionPage");

  const tabs = [
    {
      label: t(ProductionDataTab.PROPERTY),
      value: ProductionDataTab.PROPERTY,
      dataTestId: "production-data-property",
    },
    {
      label: t(ProductionDataTab.CNAMES),
      value: ProductionDataTab.CNAMES,
      dataTestId: "production-data-cnames",
    },
  ];

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].value);

  return (
    <Container>
      <ContentSwitcherContainer>
        <ContentSwitcher
          tabs={tabs}
          onChange={(tab) => setSelectedTab(tab.value)}
          value={selectedTab}
        />
      </ContentSwitcherContainer>
      {selectedTab === ProductionDataTab.PROPERTY ? (
        <PropertyListTab />
      ) : (
        <CnameListTab />
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: solid 1px ${({ theme }) => theme.borders.mutedLight};
  border-radius: 7px;
  margin-bottom: 64px;
  padding: 24px;
`;

const ContentSwitcherContainer = styled.div`
  margin-bottom: 24px;
`;
