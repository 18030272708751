import { useCallback } from "react";
import styled from "@emotion/styled";
import { config, useTransition } from "react-spring";

import { GeneratedResponseModal } from "../GeneratedResponseModal/GeneratedResponseModal";
import { GeoBlockingModal } from "../GeoBlockingModal/GeoBlockingModal";
import { HeaderDefinitionModal } from "../HeaderDefinitionModal/HeaderDefinitionModal";
import { LuaDefinitionModal } from "../LuaDefinitionModal/LuaDefinitionModal";
import { ReportingOverridesModal } from "../ReportingOverridesModal/ReportingOverridesModal";
import { TokenModal } from "../TokenModal/TokenModal";
import { IpDefinitionModal } from "../IpDefinitionModal/IpDefinitionModal";
import { OriginModal } from "../OriginDefinitionModal/OriginDefinitionModal";
import { StringDefinitionModal } from "../StringDefinitionModal/StringDefinitionModal";
import { DefinitionDropdownItem as DropdownItem } from "../../organisms/DefinitionDropdown/DefinitionDropdown";
import { DefinitionDropdownItem } from "../../atoms/DefinitionDropdownItem/DefinitionDropdownItem";
import { useAppDispatch } from "../../../store/types";
import { handleCreateDefinition } from "../../../store/slices/caching/thunks";
import {
  ConfigurationDefinition,
  ConfigurationDetailsType,
  GeneratedResponseDefinitionType,
  HeaderDefinitionType,
  LuaDefinitionType,
  OriginDefinitionType,
  ReportingOverrideDefinitionType,
  SimpleDefinitionType,
  TokenDefinitionType,
} from "../../../store/slices/caching/types";
import { getOriginDefinitionPropertyReferences } from "../../../store/slices/caching/helpers/getOriginDefinitionPropertyReferences";

export enum FeatureType {
  GEO = "geo",
  GENERATED_RESPONSE = "generatedResponse",
  HEADERS = "headers",
  LUA = "lua",
  REPORTING_OVERRIDE = "reportingOverride",
  TOKEN = "token",
  IP = "ip",
  STRING = "string",
  ORIGIN = "origin",
}

interface DefinitionDropdownItemsProps {
  isOpen: boolean;
  items: DropdownItem[];
  type: FeatureType;
  selectedItems: ConfigurationDefinition[] | undefined;
  allowMultiSelection: boolean;
  allowEdit: boolean;
  allowAdd: boolean;
  dataTestId?: string;
  parentConfig: ConfigurationDetailsType;
  setIsOpen: (isOpen: boolean) => void;
  onSelect: (i: ConfigurationDefinition[]) => void;
}

export const DefinitionDropdownItems: React.FC<DefinitionDropdownItemsProps> = ({
  isOpen,
  items,
  type,
  setIsOpen,
  onSelect,
  selectedItems,
  allowMultiSelection,
  allowEdit,
  allowAdd,
  dataTestId,
  parentConfig,
}) => {
  const dispatch = useAppDispatch();
  const transitions = useTransition(isOpen, {
    config: { ...config.stiff, mass: 0.5 },
    from: { opacity: 0, height: "0rem" },
    enter: { opacity: 1, height: "2rem" },
    leave: { opacity: 0, height: "0rem" },
  });

  const handleCreate = (definition: ConfigurationDefinition) => {
    dispatch(handleCreateDefinition(definition));
  };

  const renderModal = (
    onSubmit: (i: ConfigurationDefinition) => void,
    definition?: ConfigurationDefinition
  ) => {
    switch (type) {
      case FeatureType.GENERATED_RESPONSE:
        return (
          <GeneratedResponseModal
            onSubmit={onSubmit}
            definition={
              definition && (definition as GeneratedResponseDefinitionType)
            }
            parentConfig={parentConfig}
          />
        );
      case FeatureType.GEO:
        return (
          <GeoBlockingModal
            onSubmit={onSubmit}
            definition={definition as SimpleDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.HEADERS:
        return (
          <HeaderDefinitionModal
            onSubmit={onSubmit}
            definition={definition as HeaderDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.REPORTING_OVERRIDE:
        return (
          <ReportingOverridesModal
            onSubmit={onSubmit}
            definition={definition as ReportingOverrideDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.TOKEN:
        return (
          <TokenModal
            onSubmit={onSubmit}
            definition={definition as TokenDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.LUA:
        return (
          <LuaDefinitionModal
            onSubmit={onSubmit}
            definition={definition as LuaDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.IP:
        return (
          <IpDefinitionModal
            onSubmit={onSubmit}
            definition={definition as SimpleDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.STRING:
        return (
          <StringDefinitionModal
            onSubmit={onSubmit}
            definition={definition as SimpleDefinitionType}
            parentConfig={parentConfig}
          />
        );
      case FeatureType.ORIGIN:
        return (
          <OriginModal
            onSubmit={onSubmit}
            definition={definition as OriginDefinitionType}
            parentConfig={parentConfig}
          />
        );
    }
  };

  const handleMultiClick = useCallback(
    (item: ConfigurationDefinition) => {
      let newItems = [];
      if (selectedItems) {
        if (selectedItems.includes(item)) {
          newItems = selectedItems.filter((i) => i !== item);
        } else {
          newItems = [...selectedItems, item];
        }
      } else {
        newItems = [item];
      }
      onSelect(newItems);
    },
    [selectedItems]
  );

  const handleSingleClick = useCallback(
    (item: ConfigurationDefinition) => {
      if (selectedItems?.includes(item)) {
        onSelect([]);
      } else {
        onSelect([item]);
      }
      setIsOpen(!isOpen);
    },
    [selectedItems]
  );

  return (
    <DropdownItemsContainer>
      <Items>
        {transitions(
          (style, show) =>
            show &&
            items.map(({ definition, disabled }, i) => (
              <DefinitionDropdownItem
                key={i}
                onClick={() => {
                  allowMultiSelection
                    ? handleMultiClick(definition)
                    : handleSingleClick(definition);
                }}
                renderModal={renderModal}
                allowEdit={allowEdit}
                isSelected={selectedItems?.includes(definition) || false}
                item={definition}
                propertyReferences={
                  type === FeatureType.ORIGIN
                    ? getOriginDefinitionPropertyReferences(
                        definition.name,
                        parentConfig
                      )
                    : 0
                }
                index={i}
                dataTestId={dataTestId}
                style={style}
              />
            ))
        )}
      </Items>
      {allowAdd && (
        <AddButtonContainer>{renderModal(handleCreate)}</AddButtonContainer>
      )}
    </DropdownItemsContainer>
  );
};

const DropdownItemsContainer = styled.div``;

const AddButtonContainer = styled.div`
  padding: 12px 0px 8px 0px;
  border-top: ${({ theme }) => `1px solid ${theme.borders.highlightLight}`};
`;

const Items = styled.div`
  max-height: 204px;
  overflow: auto;
`;
