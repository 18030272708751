import { AxiosError } from "axios";
import { ErrorLevel, CMv3APIError, APIError } from ".";

export class SubscriberDataNotFoundError extends APIError {
  constructor(error: AxiosError<CMv3APIError>) {
    super(
      error.message,
      "Subscriber Data Not Found",
      ErrorLevel.WARNING,
      error.response,
      false
    );
  }
}
