import { DefinitionDropdownProps } from "../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import {
  ConfigurationDefinition,
  HeaderDefinitionType,
  SimpleDefinitionType,
} from "../../../../../../../../../store/slices/caching/types";

export const getDefsByType = (
  listType: SimpleDefinitionType["listType"],
  simpleListDefinitions: SimpleDefinitionType[]
): DefinitionDropdownProps["items"] => {
  const result = [];

  for (const definition of simpleListDefinitions) {
    if (definition.listType === listType) {
      result.push({ definition });
    }
  }

  return result;
};

export const getDefsByHeader = (
  headerListDefinitions: HeaderDefinitionType[]
): DefinitionDropdownProps["items"] => {
  const result = [];

  for (const definition of headerListDefinitions) {
    result.push({ definition });
  }

  return result;
};

export const selectedNames = (nameList: string[]) => (
  def: SimpleDefinitionType | HeaderDefinitionType
): boolean => nameList.indexOf(def.name) > -1;

export const toName = (def: ConfigurationDefinition): string => def.name;

export const toItem = <D>(definition: D): { definition: D } => ({ definition });
