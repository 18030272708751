import styled from "@emotion/styled";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { httpClient } from "../../../../core/http-client";
import { useClickOutsideRef } from "../../../../hooks/use-detect-click-outside-ref";
import { FlexBox } from "../../../atoms/FlexBox/FlexBox";
import { Icon, Icons } from "../../../atoms/Icon/Icon";
import { IScidDropdownOption, ISubHeaderOption } from "./types";
import { buildAccessGroupOptions, findAccessGroup } from "./helpers";
import {
  useAccessGroupId,
  useAccessGroups,
} from "../../../../store/slices/access-groups/hooks";
import { handleSubscriber } from "../../../../store/slices/subscriber/thunks";
import { useAppDispatch } from "../../../../store/types";
import { Breadcrumbs } from "../../../molecules/Breadcrumbs/Breadcrumbs";
import { BreadcrumbLabel } from "../../../atoms/BreadcrumbLabel/BreadcrumbLabel";
import { SubHeaderCustomerDropdown } from "../../SubHeaderDropdown/SubHeaderCustomerDropdown";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";
import { getScidsWithAliases } from "../../../../models/user";
import { SubHeaderScidDropdown } from "../../SubHeaderDropdown/SubHeaderScidDropdown";
import { BreadcrumbScid } from "./BreadcrumbScid";
import { useUnsafeScid } from "../../../../store/slices/subscriber/hooks";
import { handleSetAccessGroupId } from "../../../../store/slices/access-groups/thunks";

const versionDisplays = new Map([
  ["3.0", "V3"],
  ["2.0", "V2"],
  ["1.5", "V1"],
  ["1.0", "V1"],
]);

type BasicSubHeaderProps =
  | {
      hideScids: true;
      onAccessGroupSelect: (accessGroupId: number) => void;
      redirectPath?: never;
    }
  | {
      hideScids?: false;
      redirectPath: string;
      onAccessGroupSelect?: never;
    };

export const BasicSubHeader = ({
  redirectPath,
  hideScids,
  onAccessGroupSelect,
}: BasicSubHeaderProps): ReactElement => {
  const accessGroupId = useAccessGroupId();
  const accessGroups = useAccessGroups();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleMediaPortalError = useDispatchAPIError();
  const scid = useUnsafeScid();

  const [accessGroupOptions, setAccessGroupOptions] = useState<
    ISubHeaderOption<number>[]
  >();

  const [scidOptions, setScidOptions] = useState<
    ISubHeaderOption<IScidDropdownOption>[]
  >();

  const [selectedAccessGroup, setSelectedAccessGroup] = useState<string>();
  const [newAccessGroupId, setNewAccessGroupId] = useState<number>();
  const [isPrimaryDropdownOpen, setIsPrimaryDropdownOpen] = useState<boolean>(
    false
  );
  const [
    isSecondaryDropdownOpen,
    setIsSecondaryDropdownOpen,
  ] = useState<boolean>(false);

  const closeDropdowns = () => {
    setIsPrimaryDropdownOpen(false);
    setIsSecondaryDropdownOpen(false);
    setNewAccessGroupId(undefined);
    setScidOptions(undefined);
  };

  const ref = useRef(null);
  useClickOutsideRef(ref, closeDropdowns);

  const openAccessGroupDropdown = () => {
    setScidOptions(undefined);
    setIsPrimaryDropdownOpen(!isPrimaryDropdownOpen);
    setNewAccessGroupId(undefined);
    setIsSecondaryDropdownOpen(false);
  };

  const handleSetNewAccessGroup = (value: number | undefined) => {
    if (value) {
      getScidsWithAliases(value)
        .then((data) => {
          if (data) {
            setScidOptions(
              data.map((item) => ({
                text: item.scid,
                value: {
                  subscriberId: item.subscriberId,
                  ssVersion:
                    item.selfServiceVersion &&
                    versionDisplays.has(item.selfServiceVersion)
                      ? versionDisplays.get(item.selfServiceVersion)!
                      : "VERSION UNDEFINED",
                  csn: item.csn,
                  aliases: item.cachingProperties?.map((i) => i.name),
                },
              }))
            );
          }
        })
        .catch(handleMediaPortalError);
    }
    setNewAccessGroupId(value);
  };

  useEffect(() => {
    if (accessGroups !== undefined) {
      setAccessGroupOptions(
        accessGroups.map((accessGroup) => ({
          text: accessGroup.name,
          value: accessGroup.id,
          open: true,
          children:
            accessGroup.list && buildAccessGroupOptions(accessGroup.list),
        }))
      );
      setSelectedAccessGroup(
        findAccessGroup(accessGroups, accessGroupId)?.name
      );
    }
  }, [accessGroups, accessGroupId]);

  return (
    <HeaderContainer ref={ref}>
      <DropdownContainer>
        <Icon name={Icons.HAMBURGER} onClick={openAccessGroupDropdown} />
        <SubHeaderCustomerDropdown
          options={accessGroupOptions}
          onSelect={(option: ISubHeaderOption<number>) => {
            if (onAccessGroupSelect) {
              onAccessGroupSelect(option.value);
              setIsPrimaryDropdownOpen(false);
            } else {
              handleSetNewAccessGroup(option.value);
              setIsSecondaryDropdownOpen(true);
            }
          }}
          open={isPrimaryDropdownOpen}
        />
        {!hideScids && (
          <SubHeaderScidDropdown
            options={scidOptions}
            onSelect={(option: ISubHeaderOption<IScidDropdownOption>) => {
              if (newAccessGroupId) {
                if (option.value.ssVersion === "V3") {
                  dispatch(handleSetAccessGroupId(newAccessGroupId));
                  dispatch(
                    handleSubscriber(
                      option.text,
                      parseInt(option.value.subscriberId, 10)
                    )
                  );

                  if (redirectPath) history.push(redirectPath);
                } else {
                  httpClient.goToOldMP(
                    `/ui/myServicesCaching&access_group_id=${newAccessGroupId.toString()}&scid=${
                      option.text
                    }&subscriber_id=${option.value.subscriberId}`
                  );
                }
              }
              closeDropdowns();
            }}
            open={isSecondaryDropdownOpen}
            isPrimaryDropdownOpen={isPrimaryDropdownOpen}
          />
        )}
      </DropdownContainer>
      {selectedAccessGroup && (
        <Breadcrumbs>
          <BreadcrumbLabel
            onClick={openAccessGroupDropdown}
            data-testid="sub-header-access-group"
            id="sub-header-dropdown-selected-access-group"
          >
            {selectedAccessGroup}
          </BreadcrumbLabel>
          {!hideScids && (
            <BreadcrumbScid
              scid={scid}
              onClick={() => {
                handleSetNewAccessGroup(
                  isSecondaryDropdownOpen ? undefined : accessGroupId
                );
                setIsPrimaryDropdownOpen(false);
                setIsSecondaryDropdownOpen(!isSecondaryDropdownOpen);
              }}
            />
          )}
        </Breadcrumbs>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(FlexBox)`
  justify-content: flex-start;
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
  padding: ${({ theme }) => `1rem 0 0 ${theme.spacings.pageContainer}`};
`;

const DropdownContainer = styled(FlexBox)`
  flex-direction: column;
  padding-right: 10px;
`;
