import {
  GeneratedResponseDefinitionType,
  ConfigurationDetailsType,
} from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getGeneratedResponseReferences = (
  def: GeneratedResponseDefinitionType,
  config: ConfigurationDetailsType
): number =>
  getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .map((feature) => feature?.generatedResponse)
    .filter((generatedResponse) => generatedResponse === def.name).length;
