import styled from "@emotion/styled";

export const EmptyPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  color: ${({ theme }) => theme.text.secondary};
  line-height: 24px;
`;
export const EmptyPageContainer = styled.div`
  max-width: 600px;
  text-align: center;
`;
export const EmptyPageBanner = styled.img`
  display: block;
  width: 300px;
  margin: auto;
  margin-bottom: 3rem;
`;
export const EmptyPageTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
export const EmptyPageDescription = styled.div`
  font-weight: 400;

  &,
  li,
  p,
  p strong {
    font-size: 16px !important;
  }

  ol,
  ul {
    text-align: left;
  }
`;
export const EmptyPageButtonsContainer = styled.div`
  margin-top: 3rem;
`;
