import { FC } from "react";
import { BreadcrumbLabel } from "../../../atoms/BreadcrumbLabel/BreadcrumbLabel";
import { useTranslation } from "react-i18next";

interface BreadcrumbScidProps {
  onClick: () => void;
  scid: string | undefined;
}

export const BreadcrumbScid: FC<BreadcrumbScidProps> = ({ scid, onClick }) => {
  const { t } = useTranslation("configurationPage");
  return (
    <BreadcrumbLabel
      onClick={onClick}
      data-testid="sub-header-scid"
      id="sub-header-selected-scid"
    >
      {scid || t("MISSING_SCID")}
    </BreadcrumbLabel>
  );
};
