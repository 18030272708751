export enum ConfigurationErrorLevel {
  ERROR = "error",
  WARN = "warn",
}

export enum ConfigurationErrorType {
  INVALID_NAME = "invalid_name",
  ALREADY_IN_REGISTRY = "already_in_registry",
  PRIMARY_ALIAS_CHANGED = "primary_alias_changed",
  PROPERTY_CERTIFICATE_EMPTY = "property_certificate_empty",
  PROPERTY_PRIMARY_ALIAS_EMPTY = "property_primary_alias_empty",
  PROPERTY_PRIMARY_ALIAS_INVALID_ENDING_HTTPS2_UNCHECKED = "property_primary_alias_invalid_ending_https2_unchecked",
  PROPERTY_PRIMARY_ALIAS_INVALID_ENDING_HTTPS2_CHECKED = "property_primary_alias_invalid_ending_https2_checked",
  PROPERTY_PRIMARY_ALIAS_AND_SECONDARY_ALIAS_ARE_NOT_UNIQUE = "property_primary_alias_and_secondary_alias_are_not_unique",
  PROPERTY_PRIMARY_ALIAS_AND_ALIAS_OVERRIDES_ARE_NOT_UNIQUE = "property_primary_alias_and_alias_overrides_are_not_unique",
  PROPERTY_ALIAS_ENCRYPTION_LEVEL_INVALID = "property_primary_alias_encryption_level_invalid",
  PROPERTY_PROTOCOL_EMPTY = "property_protocol_empty",
  PROPERTY_SECONDARY_ALIAS_EMPTY = "property_secondary_alias_empty",
  PROPERTY_ALIAS_OVERRIDE_ALIAS_EMPTY = "property_alias_override_alias_empty",
  PROPERTY_ALIAS_OVERRIDE_PROTOCOL_EMPTY = "property_alias_override_protocol_empty",
  PROPERTY_MATCH_LOGIC_NO_MATCH_BLOCKS = "property_match_logic_no_match_blocks",
  PROPERTY_MATCH_LOGIC_NO_MATCH_GROUPS = "property_match_logic_no_match_groups",
  PROPERTY_MATCH_LOGIC_NO_MATCH_RULES = "property_match_logic_no_match_rules",
  PROPERTY_ORIGIN_AUTHENTICATION_ACCESS_KEY_EMPTY = "property_origin_authentication_access_key_empty",
  PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_EMPTY = "property_origin_authentication_headers_name_empty",
  PROPERTY_ORIGIN_AUTHENTICATION_HEADERS_NAME_DUPLICATE = "property_origin_authentication_headers_name_duplicate",
  PROPERTY_ORIGIN_AUTHENTICATION_SECRET_EMPTY = "property_origin_authentication_secret_empty",
  PROPERTY_ORIGIN_CONNECTION_TIMEOUT_TOO_LOW = "property_origin_connection_timeout_too_low",
  PROPERTY_ORIGIN_DEFINITION_EMPTY = "property_origin_definition_empty",
  PROPERTY_ORIGIN_DEFINITION_NAME_DUPLICATE = "property_origin_definition_name_duplicate",
  PROPERTY_ORIGIN_DEFINITION_NAME_EMPTY = "property_origin_definition_name_empty",
  PROPERTY_ORIGIN_DEFINITION_NAME_REGEX = "property_origin_definition_name_regex",
  PROPERTY_ORIGIN_HOST_ADDRESS_EMPTY = "property_origin_host_address_empty",
  PROPERTY_ORIGIN_HOST_EMPTY = "property_origin_host_empty",
  PROPERTY_ORIGIN_HTTP_TIMEOUT_TOO_LOW = "property_origin_http_timeout_too_low",
  PROPERTY_ORIGIN_NAME_DUPLICATE = "property_origin_name_duplicate",
  PROPERTY_ORIGIN_NAME_EMPTY = "property_origin_name_empty",
  PROPERTY_ORIGIN_NAME_SIZE = "property_origin_name_size",
  PROPERTY_ORIGIN_PORT_EMPTY = "property_origin_port_empty",
  PROPERTY_ORIGIN_TIMEOUT_RETRIES_TOO_LOW = "property_origin_timeout_retries_too_low",
  PROPERTY_ORIGIN_WEBROOT_INVALID = "property_origin_webroot_invalid",
}

export interface IConfigurationError {
  type: ConfigurationErrorType;
  level: ConfigurationErrorLevel;
  data?: any;
}
