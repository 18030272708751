import { FC, useEffect, useReducer, useState } from "react";

import {
  FixedWidgetIds,
  MapSiteFieldsToLabel,
  Metrics,
  Region as RegionEnum,
  RegionHistory,
  SiteByMetro,
  SiteByRegion,
  TableSiteWithPercentage,
} from "../../../models/models";
import { WidgetLayout } from "../../../../../components/molecules/WidgetLayout/WidgetLayout";
import { RegionsDataTable } from "./DataTable";
import { historyMetroToChartData, historyRegionToChartData } from "./helpers";
import { MetroHistory, RegionColor, TableMetrics, TableRegion } from "./types";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { BubbleMap } from "../../../../../components/atoms/WorldMap/BubbleMap";
import { LineChart } from "../../../../../components/atoms/LineChart/LineChart";
import { WorldMap } from "../../../../../components/atoms/WorldMap/WorldMap";
import { MetricSelectionDropdown } from "../../../../../components/organisms/MetricSelectionDropdown/MetricSelectionDropdown";
import { isMetricPositive } from "../../../helpers";
import { initialState, reducer } from "./reducer";
import { ColorSet } from "../../../../../contexts/themeContext";
import { FilterFields, useFiltersContext } from "../../../context/filters";
import { useAppDispatch } from "../../../../../store/types";
import { handleFetchHistoryForMetro } from "../../../../../store/slices/rtm/thunks";

const POSITIVE_METRIC_COLOR_SCALE: (keyof ColorSet["colors"])[] = [
  "navy100",
  "navy70",
  "navy50",
  "navy30",
  "navy20",
  "grey20",
  "grey20",
];

const NEGATIVE_METRIC_COLOR_SCALE: (keyof ColorSet["colors"])[] = [
  "red70",
  "red50",
  "red40",
  "red30",
  "red20",
  "grey20",
  "grey20",
];

const CHART_COLORS_LIGHT: (keyof ColorSet["colors"])[] = [
  "navy20",
  "red20",
  "yellow20",
  "cyan20",
  "green20",
  "purple20",
  "pink20",
];

const WHITE_TEXT_COLOR = "white";

const BLACK_TEXT_COLOR = "grey100";

const TEXT_COLORS: (keyof ColorSet["colors"])[] = [
  WHITE_TEXT_COLOR,
  WHITE_TEXT_COLOR,
  WHITE_TEXT_COLOR,
  BLACK_TEXT_COLOR,
  BLACK_TEXT_COLOR,
  BLACK_TEXT_COLOR,
  BLACK_TEXT_COLOR,
];

export const ChartMapTableContent: FC<{
  showChart: boolean;
  regionsHistory: RegionHistory[];
  metrosHistory: MetroHistory[];
  currentByRegion: SiteByRegion[];
  currentByMetro: SiteByMetro[];
}> = ({
  currentByMetro,
  currentByRegion,
  showChart,
  regionsHistory,
  metrosHistory,
}) => {
  const { preferences, updateWidgetPreference } = useFiltersContext();

  const dispatch = useAppDispatch();

  const [
    {
      regions,
      selectedRegions,
      selectedMetros,
      sortField,
      sortOrder,
      selectedMetrics,
    },
    dispatchTableState,
  ] = useReducer(reducer, {
    ...initialState,
    selectedRegions: preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)
      ?.selectedRegion,
    selectedMetrics:
      preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)?.splitTableMetrics ||
      initialState.selectedMetrics,
    selectedMetros: preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)
      ?.selectedMetros,
    sortOrder:
      preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)?.tableState?.sortOrder ||
      initialState.sortOrder,
    sortField:
      (preferences.get(FixedWidgetIds.LOCATIONS_SPLIT)?.tableState
        ?.sortField as keyof TableSiteWithPercentage) || initialState.sortField,
  });

  const regionColors = regions.reduce((regionsMap, { region }, i) => {
    if (showChart) {
      regionsMap[region] = {
        color: BLACK_TEXT_COLOR,
        backgroundColor: CHART_COLORS_LIGHT[i],
      };
    } else {
      let scale = POSITIVE_METRIC_COLOR_SCALE;
      const textColors = TEXT_COLORS;
      if (!isMetricPositive(sortField)) {
        scale = NEGATIVE_METRIC_COLOR_SCALE;
      }
      regionsMap[region] = {
        color: textColors[sortOrder === "asc" ? textColors.length - 1 - i : i],
        backgroundColor: scale[sortOrder === "asc" ? scale.length - 1 - i : i],
      };
    }
    return regionsMap;
  }, {} as Record<TableRegion["region"], RegionColor>);

  useEffect(() => {
    dispatchTableState({
      type: "UPDATE_REGIONS_DATA",
      payload: { currentByMetro, currentByRegion },
    });
  }, [currentByMetro, currentByRegion]);

  useEffect(() => {
    const selectedMetrosPreferences = preferences.get(
      FixedWidgetIds.LOCATIONS_SPLIT
    )?.selectedMetros;
    if (selectedMetrosPreferences) {
      selectedMetrosPreferences.forEach((metro) => {
        dispatch(handleFetchHistoryForMetro({ metro, region: "" }));
      });
    }
  }, []);

  const handleDisplayBubbleMap = () => {
    setIsBubbleOpen(true);
  };

  const [isBubbleOpen, setIsBubbleOpen] = useState(false);

  return (
    <>
      <WidgetLayout
        cta={
          showChart ? (
            <></>
          ) : (
            <Icon
              name={Icons.EXPAND}
              onClick={handleDisplayBubbleMap}
              color="secondary"
            />
          )
        }
        title={MapSiteFieldsToLabel.get(sortField as Metrics)}
      >
        <Modal
          size="extra-large"
          open={isBubbleOpen}
          customButton={<></>}
          onClose={() => {
            setIsBubbleOpen(false);
          }}
        >
          <WidgetLayout
            title={`Geographical Allocation - ${MapSiteFieldsToLabel.get(
              sortField
            )}`}
            cta={
              <Icon
                name={Icons.CROSS}
                color="primary"
                onClick={() => setIsBubbleOpen(false)}
              />
            }
          >
            <BubbleMap metric={sortField} />
          </WidgetLayout>
        </Modal>
        {showChart ? (
          <LineChart
            data={regionsHistory
              .filter((rh) =>
                (selectedRegions || []).includes(rh.region as RegionEnum)
              )
              .map(historyRegionToChartData(sortField as Metrics))
              .concat(
                metrosHistory
                  .filter((mh) => (selectedMetros || []).includes(mh.metro))
                  .map(historyMetroToChartData(sortField as Metrics))
              )}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                itemWidth: 100,
                itemHeight: 20,
                translateY: 50,
                symbolShape: ({ x, y, fill }) => (
                  <rect x={x + 4} y={y + 8} width={10} height={2} fill={fill} />
                ),
                itemTextColor: "#ACB0B5",
              },
            ]}
          />
        ) : (
          <WorldMap
            regionColors={regionColors}
            selectedRegions={selectedRegions || []}
          />
        )}
      </WidgetLayout>
      <WidgetLayout
        title="Split per POP location"
        cta={
          <MetricSelectionDropdown
            selectedItems={selectedMetrics.map((metric) => ({
              label: metric,
              value: metric,
            }))}
            onChange={(values) => {
              if (values.length === 0) {
                return;
              }
              const test = values.findIndex((v) => v.value === sortField);
              if (values.length > 0 && test === -1) {
                dispatchTableState({
                  type: "UPDATE_TABLE_STATE",
                  payload: {
                    sortField: values[0].value as keyof TableMetrics,
                    sortOrder: "desc",
                  },
                });
              }
              const newSelectedMetrics = values.map(
                (v) => v.value as keyof TableMetrics
              );
              dispatchTableState({
                type: "UPDATE_SELECTED_METRICS",
                payload: {
                  selectedMetrics: newSelectedMetrics,
                },
              });
              updateWidgetPreference(FixedWidgetIds.LOCATIONS_SPLIT, {
                key: FilterFields.SPLIT_TABLE_METRICS,
                value: newSelectedMetrics,
              });
            }}
          />
        }
      >
        <RegionsDataTable
          regions={regions}
          selectedRegions={selectedRegions || []}
          selectedMetros={selectedMetros || []}
          handleUpdate={dispatchTableState}
          regionColors={regionColors}
          selectedMetrics={selectedMetrics}
          tableState={{ sortField, sortOrder }}
        />
      </WidgetLayout>
    </>
  );
};
