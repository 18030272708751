import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Configuration } from "../../../models/configuration";
import { Button } from "../../atoms/Button/Button";
import { IModalChildren } from "../../atoms/Modal/Modal";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../store/slices/subscriber/hooks";

interface IDeleteSlotModal extends IModalChildren {
  onSubmit: () => void;
}

export const DeleteSlotModal = ({
  closeModal,
  onSubmit,
}: IDeleteSlotModal): ReactElement => {
  const { t } = useTranslation("productionPage");
  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();

  return (
    <Container>
      <Title className="-text--h2">
        {t("PRODUCTION_MODAL_DELETE_SLOT_TITLE")}
      </Title>
      <Info>{t("PRODUCTION_MODAL_DELETE_SLOT_INFO")}</Info>
      <ButtonsContainer>
        <CancelButtonContainer>
          {closeModal && (
            <Button
              onClick={() => {
                closeModal();
              }}
              label={t("PRODUCTION_MODAL_DELETE_SLOT_CANCEL_BUTTON")}
              size="lg"
              backgroundColor="baseLight"
              textColor="primary"
              borderColor="mutedLight"
            />
          )}
        </CancelButtonContainer>
        <SubmitButtonContainer data-testid="delete-slot-submit-button">
          {closeModal && (
            <Button
              onClick={() => {
                Configuration.production
                  .deleteCandidateConfiguration(subscriberId.toString())
                  .then(onSubmit)
                  .catch(handleMediaPortalError);
                closeModal();
              }}
              label={t("PRODUCTION_MODAL_DELETE_SLOT_DELETE_BUTTON")}
              size="lg"
            />
          )}
        </SubmitButtonContainer>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.h2``;

const Info = styled.div`
  margin: 32px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButtonContainer = styled.div`
  padding-right: 15px;
`;

const SubmitButtonContainer = styled.div``;
