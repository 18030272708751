import { APIError } from "./../../../models/error/index";
import { Status } from "../../../@types/status";
import {
  checkUserPermissions,
  getUserPermissions,
  UserRoles,
} from "../../../models/permissions";
import { AppThunk } from "./../../store";
import { setIsViewMode, setPermissions, setStatus } from "./slice";
import { handleAPIError } from "../api-error/thunks";

export const handleGetUserPermissions = (
  accessGroupId: number,
  shouldRenderLoader = true
): AppThunk => async (dispatch) => {
  try {
    if (shouldRenderLoader) dispatch(setStatus(Status.LOADING));
    const userPermissions = await getUserPermissions(accessGroupId);
    dispatch(setPermissions(userPermissions));
    dispatch(
      setIsViewMode(
        !checkUserPermissions(UserRoles.EDIT_CONFIG, userPermissions)
      )
    );
    if (shouldRenderLoader) dispatch(setStatus(Status.SUCCESS));
  } catch (err) {
    const error = err as APIError;
    dispatch(setStatus(Status.ERROR));
    dispatch(handleAPIError(error));
  }
};
