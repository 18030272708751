import { FC, useEffect } from "react";

import { useQuery } from "../../../hooks/use-query";
import {
  useDispatchRefeshToken,
  useRefreshToken,
} from "../../../store/slices/auth/hooks";

export const RefreshToken: FC = ({ children }) => {
  const [queryRefreshToken] = useQuery("refresh_token");

  // refresh tokens are valid for 24h
  const dispatchRefeshToken = useDispatchRefeshToken();
  const refreshToken = useRefreshToken();

  useEffect(() => {
    if (queryRefreshToken && queryRefreshToken !== refreshToken) {
      dispatchRefeshToken(queryRefreshToken);
    }

    // Ignore dep dispatchRefeshToken.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRefreshToken, refreshToken]);

  return <>{children}</>;
};
