import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Center } from "../../../../components/atoms/Center/Center";
import { Loader } from "../../../../components/atoms/Loader/Loader";
import {
  useConfigurations,
  useSelectedConfiguration,
} from "../../../../store/slices/caching/hooks";
import { getConfigurationVersionDetailsToCompare } from "../../../../store/slices/caching/services";
import { ConfigurationType } from "../../../../store/slices/caching/types";
import { useDispatchAPIError } from "../../../../store/slices/api-error/hooks";
import { ComparisonModal, ComparisonModalProps } from "./ComparisonModal";
import { getConfigurationOptions } from "./helpers";
import { IDeploymentModalData } from "./types";

interface PreComparisonModalProps
  extends Omit<ComparisonModalProps, "configuration" | "configurationOptions"> {
  configuration: IDeploymentModalData;
}

export const PreComparisonModalFromVersions = ({
  configuration,
  ...comparisonModalProps
}: PreComparisonModalProps): ReactElement => {
  const configurations = useConfigurations();
  const selectedConfiguration = useSelectedConfiguration();

  const handleMediaPortalError = useDispatchAPIError();

  const [loading, setLoading] = useState(false);
  const [
    configurationWithDetails,
    setConfigurationWithDetails,
  ] = useState<ConfigurationType>();

  useEffect(() => {
    let isMounted = true;

    setLoading(true);

    if (selectedConfiguration) {
      getConfigurationVersionDetailsToCompare(
        selectedConfiguration,
        configuration.versionId
      )
        .then((res) => {
          if (isMounted) {
            setConfigurationWithDetails(res);
          }
        })
        .catch(handleMediaPortalError)
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [configuration.versionId]);

  if (loading) {
    return (
      <CompareListContainer>
        <Center>
          <Loader />
        </Center>
      </CompareListContainer>
    );
  }

  if (configurationWithDetails) {
    const configurationOptions = getConfigurationOptions(
      configurations ?? [],
      (c) => c.configName === configurationWithDetails.configName
    );

    return (
      <ComparisonModal
        {...comparisonModalProps}
        configuration={configurationWithDetails}
        configurationOptions={configurationOptions}
      />
    );
  }

  return <CompareListContainer />;
};

const CompareListContainer = styled.div`
  padding: 32px;
  width: 600px;

  & > div {
    margin-bottom: 32px;
  }
`;
