import { ReactElement, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { SearchBar } from "../../../../../components/molecules/SearchBar/SearchBar";
import { Dropdown } from "../../../../../components/organisms/Dropdown/Dropdown";
import { LabelValueDropdownButton } from "../../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { UserAccessGroup } from "../../../../../models/user-management/users/users.interfaces";
import { Button } from "../../../../../components/atoms/Button/Button";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";
import { HeadlessDataTable } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { AccessGroupRow } from "../../../../../components/atoms/AccessGroupRow/AccessGroupRow";
import {
  buildAccessGroupsWithChildren,
  UserAccessGroupWithChildren,
} from "./helpers";
import { useAccessGroups } from "../../../../../store/slices/access-groups/hooks";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../../components/atoms/UserManagementTable/UserManagementTable";
import {
  NestedUserAccessGroupRow,
  NestedUserAccessGroupRowFields,
} from "../../../../../components/molecules/NestedUserAccessGroupRow/NestedUserAccessGroupRow";
import { UserAccessGroupActionsDropdown } from "./UserAccessGroupActionsDropdown";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { UserEditAccessGroupsModal } from "./modals/UserEditAccessGroupsModal";

interface UserAccessGroupDataProps {
  userId: number;
  accessGroups: UserAccessGroup[];
}

const rowFields = [
  NestedUserAccessGroupRowFields.ACCESS_GROUP,
  NestedUserAccessGroupRowFields.ROLE,
  NestedUserAccessGroupRowFields.ACTIONS,
];

export const UserAccessGroupData = ({
  userId,
  accessGroups,
}: UserAccessGroupDataProps): ReactElement => {
  const { t } = useTranslation("usersPage");
  const [searchValue, setSearchValue] = useState("");
  const [selectedRole, setSelectedRole] = useState<string>("");
  const allAccessGroups = useAccessGroups();

  const roleDropdownItems = [{ label: "All roles", value: "" }].concat(
    Array.from(new Set(accessGroups.map((ag) => ag.roleName.trim())))
      .filter((role) => role !== "")
      .map((item) => ({
        label: item,
        value: item,
      }))
  );

  const accessGroupData = useMemo(
    () =>
      buildAccessGroupsWithChildren(
        accessGroups,
        allAccessGroups,
        searchValue,
        selectedRole
      ),
    [accessGroups, allAccessGroups, searchValue, selectedRole]
  );
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  return (
    <Container>
      <Title>{t("ACCESS_GROUPS_SECTION_TITLE")}</Title>
      <ExpandableContent
        title={t("ACCESS_GROUPS_SECTION_EXPANDABLE_CONTENT_TITLE")}
        content={t("ACCESS_GROUPS_SECTION_EXPANDABLE_CONTENT_TEXT")}
      />
      <FilterLine>
        <SearchBarContainer>
          <SearchBar
            placeholder={t("SEARCH_BAR_PLACEHOLDER")}
            onChange={setSearchValue}
          />
        </SearchBarContainer>
        <Dropdown<string>
          customButton={
            <LabelValueDropdownButton placeholder="Role" value={selectedRole} />
          }
          id="users-role-dropdown"
          placeholder=""
          items={roleDropdownItems}
          onSelect={(role) => {
            setSelectedRole(role.value);
          }}
        />
        <Modal
          customButton={
            <EditAccessGroupButton
              label={t("ACCESS_GROUPS_SECTION_EDIT_ACCESS_GROUP_BUTTON")}
              onClick={() => {}}
            />
          }
        >
          <UserEditAccessGroupsModal
            userAccessGroups={accessGroups}
            userId={userId}
          />
        </Modal>
      </FilterLine>
      <HeadlessDataTable
        data={accessGroupData}
        columns={[
          {
            dataField: "name",
            name: t("ACCESS_GROUPS_SECTION_TABLE_ACCESS_GROUP_LABEL"),
            formatter: ({ id, name, list }, rowIndex) => (
              <AccessGroupRow
                id={id}
                name={name}
                childrenCount={list?.length || 0}
                isOpen={expandedRows.includes(rowIndex)}
                toggleIsOpen={() => {
                  setExpandedRows((eR) => {
                    if (eR.includes(rowIndex)) {
                      return eR.filter((rI) => rI !== rowIndex);
                    } else {
                      return [...eR, rowIndex];
                    }
                  });
                }}
              />
            ),
            style: { width: "65%" },
          },
          {
            dataField: "roleName",
            name: t("ACCESS_GROUPS_SECTION_TABLE_ROLES_LABEL"),
            formatter: (row) => row.roleName,
            style: { width: "30%" },
          },
          {
            name: "",
            formatter: (row) => (
              <UserAccessGroupActionsDropdown
                userId={userId}
                accessGroupId={row.id}
                role={row.roleName}
              />
            ),
          },
        ]}
        expandParams={{
          expandedRows,
          render: (row) => (
            <>
              {row.list?.map((ag: UserAccessGroupWithChildren) => (
                <NestedUserAccessGroupRow
                  key={ag.id}
                  {...ag}
                  nestedIndex={1}
                  userId={userId}
                  fields={rowFields}
                />
              ))}
            </>
          ),
        }}
        keyField="id"
        TableWrapper={UserManagementTable}
        TableHeadRow={UserManagementTableHeaderRow}
        TableHeadRowCell={UserManagementTableHeaderCell}
        TableBodyRow={UserManagementTableBodyRow}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 32px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 16px;
`;

const FilterLine = styled(FlexBox)``;

const SearchBarContainer = styled.div`
  width: 75%;
`;

const EditAccessGroupButton = styled(Button)`
  padding: 12px 16px;
`;
