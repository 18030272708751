import styled from "@emotion/styled";

import {
  BorderlessTable,
  BorderlessTableBodyRow,
} from "../BorderlessTable/BorderlessTable";

export const UserManagementTable = styled(BorderlessTable)`
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.borders.mutedLight};
`;

export const UserManagementTableHeaderRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.borders.mutedLight};
  th {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  th:first-child {
    padding-left: 16px;
  }
  th:last-child {
    padding-right: 16px;
  }
`;

export const UserManagementTableHeaderCell = styled.th`
  font-weight: 600;
  color: ${({ theme }) => theme.text.secondary};
`;

export const UserManagementTableBodyRow = styled(BorderlessTableBodyRow)`
  :hover {
    background: ${({ theme }) => theme.backgrounds.base};
    border-radius: 6px;
  }
  td {
    padding-top: 0px;
  }
  td:first-child {
    border-radius: 6px 0 0 6px;
  }
  td:last-child {
    border-radius: 0 6px 6px 0;
  }
`;
