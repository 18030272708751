import { AxiosError } from "axios";
import { httpClient } from "../../../../core/http-client";
import { APIError, MediaPortalAPIError, ErrorLevel } from "../../../error";

import {
  ApiKeyData,
  ApiKeysData,
  ApiKeysOwners,
  ApiKeysRoles,
} from "../apiKeys.interfaces";
import {
  vyvxApiKeyRequestData,
  VyvxApiKeysData,
  VyvxApiKeysMetaData,
} from "./vyvxApiKeys.interfaces";

export const getVyvxApiKeyRoles = async (): Promise<ApiKeysRoles[]> => {
  try {
    const { data } = await httpClient.get<ApiKeysRoles[]>("/companies/roles");
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getVyvxApiKeyRoles()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

const initVyvxApiKeysMetadata = (
  name: string,
  vyvxApiKeysMetataData: ApiKeysData
): ApiKeysData => {
  return {
    parentVyvxAbbr: name,
    id: vyvxApiKeysMetataData.id,
    name: vyvxApiKeysMetataData.name,
    createdDate: vyvxApiKeysMetataData.createdDate,
    createdBy: vyvxApiKeysMetataData.createdBy,
    role: vyvxApiKeysMetataData.role,
    contact: vyvxApiKeysMetataData.contact,
    status: vyvxApiKeysMetataData.status,
    accessGroup: vyvxApiKeysMetataData.accessGroup,
    secret: vyvxApiKeysMetataData.secret,
    lastModifiedDate: vyvxApiKeysMetataData.lastModifiedDate,
    lastModifiedBy: vyvxApiKeysMetataData.lastModifiedBy,
    notes: vyvxApiKeysMetataData.notes,
    accessGroupId: vyvxApiKeysMetataData.accessGroupId,
    accessGroupName: vyvxApiKeysMetataData.accessGroupName,
    vyvxCompanyAbbr: vyvxApiKeysMetataData.vyvxCompanyAbbr,
    vyvxRoleId: vyvxApiKeysMetataData.vyvxRoleId,
    termsWarning: vyvxApiKeysMetataData.termsWarning,
    termsError: vyvxApiKeysMetataData.termsError,
  };
};

export const getVyvxApiKeys = async (
  companyId: string
): Promise<VyvxApiKeysData> => {
  try {
    const { data } = await httpClient.get<VyvxApiKeysMetaData>(
      `/companies/${companyId}/apiKeys`
    );
    const apiKeys: ApiKeysData[] = [];
    Object.entries(data.apiKeys).forEach(([name, keys]) =>
      keys.forEach((key) => apiKeys.push(initVyvxApiKeysMetadata(name, key)))
    );
    return {
      fiberCompanies: data.fiberCompanies,
      apiKeys,
      primaryFiberCompanyAbbr: data.primaryFiberCompanyAbbr,
    };
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getVyvxApiKeys()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getVyvxApiKey = async (
  companyId: string,
  apiKeyId: number
): Promise<ApiKeyData> => {
  try {
    const { data } = await httpClient.get<ApiKeyData>(
      `/companies/${companyId}/apiKeys/${apiKeyId}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const generateSecretVyvxApiKey = async (
  companyId: string,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.get<void>(
      `/companies/${companyId}/apiKeys/${apiKeyId}/refreshSecret`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "generateSecretVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const activateVyvxApiKey = async (
  companyId: string,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.put(
      `/companies/${companyId}/apiKeys/${apiKeyId}/activate`,
      undefined
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "activateVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const disableVyvxApiKey = async (
  companyId: string,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.put(
      `/companies/${companyId}/apiKeys/${apiKeyId}/disable`,
      undefined
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "disableVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const deleteVyvxApiKey = async (
  companyId: string,
  apiKeyId: number
): Promise<void> => {
  try {
    const { data } = await httpClient.delete<void>(
      `/companies/${companyId}/apiKeys/${apiKeyId}`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "deleteVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getVyvxApiKeyOwners = async (
  companyId: string
): Promise<ApiKeysOwners[]> => {
  try {
    const { data } = await httpClient.get<ApiKeysOwners[]>(
      `/companies/${companyId}/owners`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "getVyvxApiKeyOwners()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const createVyvxApiKey = async (
  companyId: string,
  body: vyvxApiKeyRequestData
): Promise<vyvxApiKeyRequestData> => {
  try {
    const { data } = await httpClient.post(
      `/companies/${companyId}/apiKeys`,
      body
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "createVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const updateVyvxApiKey = async (
  companyId: string,
  apiKeyId: number,
  body: vyvxApiKeyRequestData
): Promise<ApiKeyData> => {
  try {
    const { data } = await httpClient.put(
      `/companies/${companyId}/apiKeys/${apiKeyId}`,
      body
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<MediaPortalAPIError>;
    throw new APIError(
      error.message,
      "updateVyvxApiKey()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
