import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { APIErrorState } from "./types";

const initialState: APIErrorState = {
  errorData: null,
  showErrorModal: false,
};

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setErrorData: (
      state,
      action: PayloadAction<APIErrorState["errorData"]>
    ) => {
      state.errorData = action.payload;
    },
    setShowErrorModal: (
      state,
      action: PayloadAction<APIErrorState["showErrorModal"]>
    ) => {
      state.showErrorModal = action.payload;
    },
    resetErrorState: (state) => {
      state.showErrorModal = false;
      state.errorData = null;
    },
  },
});

export const {
  resetErrorState,
  setErrorData,
  setShowErrorModal,
} = apiErrorSlice.actions;

export default apiErrorSlice.reducer;
