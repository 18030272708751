import styled from "@emotion/styled";

export const FlexBoxBase = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexBox = styled(FlexBoxBase)`
  justify-content: center;
  align-items: center;
`;
