import { httpClient } from "../../core/http-client";
import { format } from "date-fns";
import { AxiosError } from "axios";

import { ErrorLevel, CMv3APIError, APIError } from "../error";

export enum CertificateState {
  ACTIVE = "active",
  STD_PENDING = "stdPending",
  WAITING_CERTIFICATE = "waiting certificate",
  ACCEPTED_CSR_DATA = "leAcceptedCsrData",
  CNAME_CREATION_STARTED = "leCnameCreationStarted",
  CNAME_CREATION_COMPLETE = "leCnameCreationComplete",
  CNAME_CREATION_FAILED = "leCnameCreationFailed",
  CREATE_RENEW_STARTED = "leCreateRenewStarted",
  CREATE_RENEW_WORKING_CHALLENGES = "leCreateRenewWorkingChallenges",
  CREATE_RENEW_AWAITING_RESPONSE = "leCreateRenewAwaitingLeResponse",
  CREATE_RENEW_FAILED = "leCreateRenewFailed",
  CLEANING_CONFIGS = "leCleaningConfigs",
  REVOKE_IN_PROGRESS = "leRevokeInProgress",
  REVOKE_COMPLETE = "leRevokeComplete",
  REVOKE_FAILED = "leRevokeFailed",
  UNREFERENCED = "leUnreferenced",
}

export enum CertificateValidity {
  NOT_VALID_YET = "NOT_VALID_YET",
  VALID = "VALID",
  EXPIRES_SOON = "EXPIRES_SOON",
  EXPIRED = "EXPIRED",
}

export const MapCertStateToDisplay = {
  [CertificateState.ACTIVE]: "Active",
  [CertificateState.STD_PENDING]: "Pending",
  [CertificateState.WAITING_CERTIFICATE]: "Awaiting Certificate",
  [CertificateState.ACCEPTED_CSR_DATA]: "CSR Accepted",
  [CertificateState.CNAME_CREATION_STARTED]: "CNAME Creation Started",
  [CertificateState.CNAME_CREATION_COMPLETE]: "CNAME Creation Complete",
  [CertificateState.CNAME_CREATION_FAILED]: "CNAME Creation Failed",
  [CertificateState.CREATE_RENEW_STARTED]: "Renewal Started",
  [CertificateState.CREATE_RENEW_WORKING_CHALLENGES]:
    "Renewal Working Challenge",
  [CertificateState.CREATE_RENEW_AWAITING_RESPONSE]:
    "Renewal Awaiting Response",
  [CertificateState.CREATE_RENEW_FAILED]: "Renewal Failed",
  [CertificateState.CLEANING_CONFIGS]: "Cleaning Configs",
  [CertificateState.REVOKE_IN_PROGRESS]: "Revoke In Progress",
  [CertificateState.REVOKE_COMPLETE]: "Revoke Complete",
  [CertificateState.REVOKE_FAILED]: "Revoke Failed",
  [CertificateState.UNREFERENCED]: "Unreferenced",
};

export interface CertificateEditableFields {
  certificate?: string;
  password?: string;
  private_key?: string;
  intermediate_certs?: string;
}

export interface CertificateEditInput extends CertificateEditableFields {
  cert_name: string;
}

export interface ICertificate {
  aliases: any | null;
  auto_renew: any | null;
  cert_bundle: string | null;
  cert_data: any | null;
  cert_name: string;
  cert_states: CertificateState[];
  certificate: string | null;
  creation_time: string;
  fingerprint: string | null;
  href: string;
  issuer: string | null;
  modified_time: string;
  new_aliases: string[];
  new_cert: null;
  new_cert_data: string | any;
  new_subject: any | null;
  renewal_failure_count: any | null;
  revoke_failure_count: any | null;
  serial_number: string | null;
  state: number;
  subject: string | null;
  transaction_id: any | null;
  type: string;
  validity_not_after: any | null;
  validity_not_before: any | null;
  version_id: number;
}

export class Certificate {
  public aliases: string | null;
  public auto_renew: any | null;
  public cert_bundle: string | null;
  public cert_data: string | null;
  public cert_name: string;
  public cert_states: CertificateState[];
  public certificate: string | null;
  public creation_time: string;
  public fingerprint: string | null;
  public href: string;
  public issuer: string | null;
  public modified_time: string;
  public new_aliases: string[];
  public new_cert: null;
  public new_cert_data: string;
  public new_subject: string | null;
  public renewal_failure_count: number | null;
  public revoke_failure_count: number | null;
  public serial_number: string | null;
  public state: number;
  public subject: string | null;
  public transaction_id: number | null;
  public type: string;
  public validity_not_after: any | null;
  public validity_not_before: any | null;
  public version_id: number;

  constructor(certificate: ICertificate) {
    this.aliases = certificate.aliases;
    this.auto_renew = certificate.auto_renew;
    this.cert_bundle = certificate.cert_bundle;
    this.cert_data = certificate.cert_data;
    this.cert_name = certificate.cert_name;
    this.cert_states = certificate.cert_states;
    this.certificate = certificate.certificate;
    this.creation_time = certificate.creation_time;
    this.fingerprint = certificate.fingerprint;
    this.href = certificate.href;
    this.issuer = certificate.issuer;
    this.modified_time = certificate.modified_time;
    this.new_aliases = certificate.new_aliases;
    this.new_cert = certificate.new_cert;
    this.new_cert_data = certificate.new_cert_data;
    this.new_subject = certificate.new_subject;
    this.renewal_failure_count = certificate.renewal_failure_count;
    this.revoke_failure_count = certificate.revoke_failure_count;
    this.serial_number = certificate.serial_number;
    this.state = certificate.state;
    this.subject = certificate.subject;
    this.transaction_id = certificate.transaction_id;
    this.type = certificate.type;
    this.validity_not_after = certificate.validity_not_after;
    this.validity_not_before = certificate.validity_not_before;
    this.version_id = certificate.version_id;
  }

  public get status(): string[] {
    return this.cert_states.map((state) => MapCertStateToDisplay[state]);
  }

  public static async getCertByName(
    subscriberId: number,
    certName: string
  ): Promise<Certificate> {
    try {
      const { data } = await httpClient.get<ICertificate>(
        `/serviceConfiguration/v2/subscribers/${subscriberId}/certs/${certName}`
      );
      return new Certificate(data);
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;

      throw new APIError(
        error.message,
        "getCertByName()",
        ErrorLevel.ERROR,
        error.response
      );
    }
  }

  public get metadata(): { label: string; value: string | null }[] {
    return [
      {
        label: "Created At",
        value: this.creation_time
          ? format(new Date(this.creation_time), "MMM do y 'at' h:mm a")
          : null,
      },
      {
        label: "State",
        value: this.status.join(", "),
      },
      {
        label: "From",
        value: this.validity_not_before
          ? format(new Date(this.validity_not_before), "MMM do y 'at' h:mm a")
          : null,
      },
      {
        label: "To",
        value: this.validity_not_after
          ? format(new Date(this.validity_not_after), "MMM do y 'at' h:mm a")
          : null,
      },
      { label: "Type", value: this.type },
    ];
  }

  public static async crupdateCertificate(
    subscriberId: number | undefined,
    certificateData: CertificateEditInput
  ): Promise<void> {
    const safeData: {
      certificate?: string;
      intermediate_certs?: string;
      private_key_data?: { private_key?: string; password?: string };
    } = {};
    if (certificateData.certificate) {
      safeData.certificate = certificateData.certificate;
    }
    if (certificateData.intermediate_certs) {
      safeData.intermediate_certs = certificateData.intermediate_certs;
    }
    if (certificateData.private_key) {
      safeData.private_key_data = { private_key: certificateData.private_key };
      if (certificateData.password) {
        safeData.private_key_data.password = certificateData.password;
      }
    }
    try {
      await httpClient.put(
        `/serviceConfiguration/v2/subscribers/${subscriberId}/certs/${certificateData.cert_name}`,
        safeData
      );
    } catch (err) {
      const error = err as AxiosError<CMv3APIError>;

      throw new APIError(
        error.message,
        "crupdateCertificate()",
        ErrorLevel.ERROR,
        // Only let the reponse data to prevent keeping
        // sensitive infos like certificates details in Sentry.
        error.response?.data
      );
    }
  }

  public getEditableFields(): CertificateEditInput {
    const result: CertificateEditInput = {
      cert_name: this.cert_name,
      certificate: this.certificate || "",
    };
    if (this.cert_bundle) {
      result.intermediate_certs = this.cert_bundle;
    }
    return result;
  }
}
