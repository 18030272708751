import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Banners } from "./banners";
import { FeatureFlags } from "./feature-flags";

export const PortalHub = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/banners`} component={Banners} />
      <Route path={`${path}/featureflags`} component={FeatureFlags} />
    </Switch>
  );
};
