import { ReactElement, useContext } from "react";
import styled from "@emotion/styled";
import { animated, config, useSpring } from "react-spring";

import { ThemeContext } from "../../../contexts/themeContext";

interface SubwayStopProps {
  bgColor: string;
}

export const SubwayStop = ({ bgColor }: SubwayStopProps): ReactElement => {
  const { colorset } = useContext(ThemeContext);

  const dotColorAnimated = useSpring({
    config: config.slow,
    backgroundColor: bgColor,
  });

  const aroundDotColorAnimated = useSpring({
    backgroundColor: colorset.backgrounds.base,
  });

  return (
    <SubwayStopContainer>
      <AroundDot style={aroundDotColorAnimated}>
        <Dot style={dotColorAnimated} />
      </AroundDot>
    </SubwayStopContainer>
  );
};

const AroundDot = styled(animated.div)`
  width: 24px;
  height: 24px;
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

const Dot = styled(animated.div)`
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

const SubwayStopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
`;
