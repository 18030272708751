import styled from "@emotion/styled";

export const RadioInput = styled.div<{ selected: 0 | 1 }>`
  width: 16px;
  height: 16px;
  border: ${({ theme, selected }) =>
    selected === 0
      ? `1px solid ${theme.borders.muted}`
      : `4px solid ${theme.borders.highlight}`};
  border-radius: 8px;
`;
