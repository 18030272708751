import { ITransactionHistory } from "../../../models/configuration/configuration_details.interfaces";

export const getLatestTime = (transaction: ITransactionHistory): string =>
  transaction.completeTime !== undefined
    ? transaction.completeTime
    : transaction.startTime !== undefined
    ? transaction.startTime
    : transaction.queueTime !== undefined
    ? transaction.queueTime
    : "";
