import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../../@types/status";
import { VyvxSlice } from "./types";

const initialState: VyvxSlice = {
  availableRoles: [],
  availableFiberCompanies: [],
  availableSatelliteCompanies: [],
  vyvxStatus: Status.IDLE,
};

export const vyvxSlice = createSlice({
  name: "vyvx",
  initialState,
  reducers: {
    setVyvxStatus: (state, action: PayloadAction<VyvxSlice["vyvxStatus"]>) => {
      state.vyvxStatus = action.payload;
    },
    setVyvxData: (
      state,
      action: PayloadAction<Omit<VyvxSlice, "vyvxStatus">>
    ) => {
      state.availableRoles = action.payload.availableRoles;
      state.availableFiberCompanies = action.payload.availableFiberCompanies;
      state.availableSatelliteCompanies =
        action.payload.availableSatelliteCompanies;
    },
  },
});

export const { setVyvxStatus, setVyvxData } = vyvxSlice.actions;

export default vyvxSlice.reducer;
