import { ReactElement } from "react";
import styled from "@emotion/styled";

import { useBool } from "../../../hooks/use-bool/useBool";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { Icon, Icons } from "../../atoms/Icon/Icon";

interface ExpandableContentProps {
  title: string;
  content?: string;
  dataTestId?: string;
}

export const ExpandableContent = ({
  title,
  content,
  dataTestId,
}: ExpandableContentProps): ReactElement => {
  const [isOpen, toggleOpen] = useBool(false);
  const contentIsNotEmpty = !!content;

  return (
    <Container data-testid={`${dataTestId}-container`}>
      <TitleContainer isClick={contentIsNotEmpty} onClick={toggleOpen}>
        {contentIsNotEmpty && (
          <FatArrowIcon
            isOpen={isOpen ? 1 : 0}
            data-testid={`${dataTestId}-open-arrow`}
            name={Icons.ARROW_FAT}
            isClick={contentIsNotEmpty}
            size={12}
          />
        )}
        <Title isClick={contentIsNotEmpty}>
          <CustomRemark>{title}</CustomRemark>
        </Title>
      </TitleContainer>
      {contentIsNotEmpty && isOpen && (
        <Content>
          <CustomRemark>
            {content as Exclude<typeof content, undefined>}
          </CustomRemark>
        </Content>
      )}
    </Container>
  );
};

const Container = styled.div``;

const TitleContainer = styled.div<{ isClick: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: ${({ isClick }) => (isClick ? "pointer" : "initial")};
`;

const FatArrowIcon = styled(Icon)<{ isOpen: number; isClick: boolean }>`
  margin-right: 10px;
  transition: transform 0.2s;
  transform: rotate(${({ isOpen }) => (isOpen ? "90deg" : "0deg")});
  cursor: ${({ isClick }) => (isClick ? "pointer" : "initial")};
`;

const Title = styled.div<{ isClick: boolean }>`
  font-size: 14px;
  margin: 0 !important;
  color: ${({ isClick, theme }) =>
    isClick ? theme.text.highlight : theme.text.secondary};
`;

const Content = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.text.secondary};
  padding-left: 1rem;
`;
