import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 85%;
    `}
`;
