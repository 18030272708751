import { RefObject } from "react";

import { OnScrollEventDetails } from "../../../hooks/use-scroll-layout";
import { AppThunk } from "../../store";
import { setHide } from "./slice";

export const handleToggleHeader = (
  details: OnScrollEventDetails,
  headerRef: RefObject<HTMLDivElement> | null
): AppThunk => (dispatch, getState) => {
  const { deafaultHeaderHeight, hide } = getState().header;

  const headerHeight = headerRef?.current?.clientHeight ?? deafaultHeaderHeight;
  const enoughtScrollingToHideHeader = headerHeight * 2;

  let newHide: boolean;
  if (!hide && details.scrollTop > enoughtScrollingToHideHeader) {
    newHide = true;
  } else if (hide && details.scrollTop > 0) {
    newHide = true;
  } else {
    newHide = false;
  }

  const shoulddDispatch = hide !== newHide;
  if (shoulddDispatch) {
    dispatch(setHide(newHide));
  }
};
