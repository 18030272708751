import { useEffect, useState } from "react";
import {
  ITransactionHistory,
  IVersionHistory,
} from "../../../models/configuration/configuration_details.interfaces";
import { Configuration, ProductionSlot } from "../../../models/configuration";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../store/slices/subscriber/hooks";

export const useEnvironmentHistory = (): [
  envHistory?: IVersionHistory[],
  transactionHistory?: ITransactionHistory[]
] => {
  const subscriberId = useSubscriberId();
  const [versionHistory, setVersionHistory] = useState<IVersionHistory[]>();
  const [transactionHistory, setTransactionHistory] = useState<
    ITransactionHistory[]
  >();
  const handleMediaPortalError = useDispatchAPIError();

  useEffect(() => {
    Promise.allSettled([
      Configuration.getProductionHistory(
        subscriberId.toString(),
        ProductionSlot.CANDIDATE
      ),
      Configuration.getProductionHistory(
        subscriberId.toString(),
        ProductionSlot.CURRENT
      ),
    ])
      .then((resp) =>
        resp.map((element) => {
          if (element.status === "fulfilled") {
            return element.value;
          }

          handleMediaPortalError(element.reason);

          return [];
        })
      )
      .then((history) => {
        setVersionHistory(history.flat());
      });
  }, [subscriberId]);

  useEffect(() => {
    Promise.allSettled([Configuration.getTransactions(subscriberId.toString())])
      .then((resp) =>
        resp.map((element) => {
          if (element.status === "fulfilled") {
            return element.value;
          }

          handleMediaPortalError(element.reason);

          return [];
        })
      )
      .then((history) => {
        setTransactionHistory(history.flat());
      });
  }, [subscriberId]);

  return [versionHistory, transactionHistory];
};
