import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { AnchorWrapper } from "../../../../components/atoms/AnchorWrapper/AnchorWrapper";
import { Divider } from "../../../../components/atoms/Divider/Divider";
import { MenuItem } from "../../../../components/molecules/AnchorMenu/AnchorMenu";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { useSubscriberSettingsInfo } from "../../../../store/slices/subscriber-settings/hooks";
import {
  H2,
  TableInfo,
  TableInfoContainer,
  TableInfoTitle,
} from "./SubscriberSettings.styled";

interface InfoSectionProps {
  menu: MenuItem;
}

export const InfoSection = ({ menu }: InfoSectionProps): ReactElement => {
  const { t } = useTranslation("subscribersSettingsPage");
  const infos = useSubscriberSettingsInfo();

  const infoTable = [
    {
      title: t("SUBSCRIBER_SETTINGS_SUBSCRIBER_ID"),
      value: infos.subscriberId,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_SUBSCRIBER_NAME"),
      value: infos.subscriberName,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_ITM_NETWORK"),
      value: infos.itmNetwork,
    },
    { title: t("SUBSCRIBER_SETTINGS_SCID"), value: infos.scid },
    {
      title: t("SUBSCRIBER_SETTINGS_CREATION_TIME"),
      value: infos.creationTime,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_MODIFIED_TIME"),
      value: infos.modifiedTime,
    },
    {
      title: t("SUBSCRIBER_SETTINGS_VERSION_ID"),
      value: infos.versionId,
    },
  ];

  return (
    <AnchorWrapper
      id={menu.id}
      ref={menu.ref}
      key={menu.name}
      data-testid="subscriber_settings-info"
    >
      <H2>{t("SUBSCRIBER_SETTINGS_ANCHOR_INFO")}</H2>
      <ExpandableContent
        title={t("SUBSCRIBER_SETTINGS_INFO_DESCRIPTION_TITLE")}
        content={t("SUBSCRIBER_SETTINGS_INFO_DESCRIPTION_CONTENT")}
      />
      <TableInfoContainer>
        {infoTable.map((subscriberInfo, index) => {
          return (
            <div key={index}>
              <TableInfo>
                <TableInfoTitle>{subscriberInfo.title}</TableInfoTitle>
                <span>{subscriberInfo.value}</span>
              </TableInfo>
              {index !== infoTable.length - 1 && <Divider />}
            </div>
          );
        })}
      </TableInfoContainer>
    </AnchorWrapper>
  );
};
