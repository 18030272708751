import { toDate } from "date-fns";

import {
  Metrics,
  Region as RegionEnum,
  RegionHistory,
  SiteByMetro,
  TableSiteWithPercentage,
  SiteByRegion,
} from "../../../models/models";
import { getPercentage } from "../../../helpers";
import { MetroHistory, TableMetrics, TableRegion } from "./types";
import { TableState } from "../../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";

export const historyRegionToChartData = (metric: Metrics) => (
  rH: RegionHistory
): { id: string; data: { x: Date; y: number }[] } => ({
  id: rH.region,
  data: rH.points.map(({ time, site }) => ({
    x: toDate(time * 1000),
    y: site[metric],
  })),
});

export const historyMetroToChartData = (metric: Metrics) => (
  rH: MetroHistory
): { id: string; data: { x: Date; y: number }[] } => ({
  id: rH.metro,
  data: rH.points.map(({ time, site }) => ({
    x: toDate(time * 1000),
    y: site[metric],
  })),
});

export const handleSortByMetric = <Data extends TableSiteWithPercentage>(
  sortField: TableState<TableSiteWithPercentage>["sortField"],
  sortOrder: TableState<TableSiteWithPercentage>["sortOrder"],
  list: Data[]
): Data[] => {
  list.sort((r1, r2) => {
    const val1 = r1[sortField];
    const val2 = r2[sortField];

    if (val1.value > val2.value) {
      return sortOrder === "asc" ? 1 : -1;
    } else if (val1.value < val2.value) {
      return sortOrder === "asc" ? -1 : 1;
    } else {
      return 0;
    }
  });

  return list;
};

export const handleSortRegionsAndMetros = (
  sortField: TableState<TableMetrics>["sortField"],
  sortOrder: TableState<TableRegion>["sortOrder"],
  regions: TableRegion[]
): TableRegion[] => {
  const sortedRegions = handleSortByMetric(sortField, sortOrder, regions);
  sortedRegions.forEach((sortedRegion) => {
    sortedRegion.metroList = handleSortByMetric(sortField, sortOrder, [
      ...sortedRegion.metroList,
    ]);
  });

  return sortedRegions;
};

export const mapOutRegion = (r: TableRegion): RegionEnum => r.region;

export const groupByRegion = (
  metroList: SiteByMetro[],
  regionList: SiteByRegion[]
): TableRegion[] => {
  const totalValues = regionList.reduce(
    (prev, curr) => ({
      reqPerSec: prev.reqPerSec + curr.reqPerSec,
      mbPerSec: prev.mbPerSec + curr.mbPerSec,
      missMbPerSec: prev.missMbPerSec + curr.missMbPerSec,
      missPerSec: prev.missPerSec + curr.missPerSec,
      fourZeroFourPerSec: prev.fourZeroFourPerSec + curr.fourZeroFourPerSec,
      fiveZeroThreePerSec: prev.fiveZeroThreePerSec + curr.fiveZeroThreePerSec,
      fiveZeroFourPerSec: prev.fiveZeroFourPerSec + curr.fiveZeroFourPerSec,
      fiveXXPerSec: prev.fiveXXPerSec + curr.fiveXXPerSec,
    }),
    {
      reqPerSec: 0,
      mbPerSec: 0,
      missMbPerSec: 0,
      missPerSec: 0,
      fourZeroFourPerSec: 0,
      fiveZeroThreePerSec: 0,
      fiveZeroFourPerSec: 0,
      fiveXXPerSec: 0,
    }
  );
  const regionsMap = regionList.map((regionData) => {
    const metrosInRegion = metroList.filter(
      (metro) => metro.region === regionData.region
    );
    return {
      region: regionData.region,
      reqPerSec: { value: regionData.reqPerSec, percentage: 0 },
      mbPerSec: { value: regionData.mbPerSec, percentage: 0 },
      missMbPerSec: { value: regionData.missMbPerSec, percentage: 0 },
      missPerSec: { value: regionData.missPerSec, percentage: 0 },
      fourZeroFourPerSec: {
        value: regionData.fourZeroFourPerSec,
        percentage: 0,
      },
      fiveZeroThreePerSec: {
        value: regionData.fiveZeroThreePerSec,
        percentage: 0,
      },
      fiveZeroFourPerSec: {
        value: regionData.fiveZeroFourPerSec,
        percentage: 0,
      },
      fiveXXPerSec: { value: regionData.fiveXXPerSec, percentage: 0 },
      hitRatePercent: { value: regionData.hitRatePercent },
      metroList: metrosInRegion.map((metro) => ({
        metro: metro.metro,
        reqPerSec: { value: metro.reqPerSec, percentage: 0 },
        mbPerSec: { value: metro.mbPerSec, percentage: 0 },
        missMbPerSec: { value: metro.missMbPerSec, percentage: 0 },
        missPerSec: { value: metro.missPerSec, percentage: 0 },
        fourZeroFourPerSec: { value: metro.fourZeroFourPerSec, percentage: 0 },
        fiveZeroThreePerSec: {
          value: metro.fiveZeroThreePerSec,
          percentage: 0,
        },
        fiveZeroFourPerSec: { value: metro.fiveZeroFourPerSec, percentage: 0 },
        fiveXXPerSec: { value: metro.fiveXXPerSec, percentage: 0 },
        hitRatePercent: { value: metro.hitRatePercent },
      })),
    };
  });

  // Add percentages
  regionsMap.forEach((metrics) => {
    metrics.fiveZeroFourPerSec.percentage = getPercentage(
      metrics.fiveZeroFourPerSec.value,
      totalValues.fiveZeroFourPerSec
    );
    metrics.fiveZeroThreePerSec.percentage = getPercentage(
      metrics.fiveZeroThreePerSec.value,
      totalValues.fiveZeroThreePerSec
    );
    metrics.fourZeroFourPerSec.percentage = getPercentage(
      metrics.fourZeroFourPerSec.value,
      totalValues.fourZeroFourPerSec
    );
    metrics.reqPerSec.percentage = getPercentage(
      metrics.reqPerSec.value,
      totalValues.reqPerSec
    );
    metrics.mbPerSec.percentage = getPercentage(
      metrics.mbPerSec.value,
      totalValues.mbPerSec
    );
    metrics.fiveXXPerSec.percentage = getPercentage(
      metrics.fiveXXPerSec.value,
      totalValues.fiveXXPerSec
    );
    metrics.missPerSec.percentage = getPercentage(
      metrics.missPerSec.value,
      totalValues.missPerSec
    );
    metrics.missMbPerSec.percentage = getPercentage(
      metrics.missMbPerSec.value,
      totalValues.missMbPerSec
    );

    metrics.metroList.forEach((metro) => {
      metro.fiveZeroFourPerSec.percentage = getPercentage(
        metro.fiveZeroFourPerSec.value,
        metrics.fiveZeroFourPerSec.value
      );
      metro.fiveZeroThreePerSec.percentage = getPercentage(
        metro.fiveZeroThreePerSec.value,
        metrics.fiveZeroThreePerSec.value
      );
      metro.fourZeroFourPerSec.percentage = getPercentage(
        metro.fiveZeroFourPerSec.value,
        metrics.fiveZeroFourPerSec.value
      );
      metro.reqPerSec.percentage = getPercentage(
        metro.reqPerSec.value,
        metrics.reqPerSec.value
      );
      metro.mbPerSec.percentage = getPercentage(
        metro.mbPerSec.value,
        metrics.mbPerSec.value
      );
      metro.fiveXXPerSec.percentage = getPercentage(
        metro.fiveXXPerSec.value,
        metrics.fiveXXPerSec.value
      );
      metro.missMbPerSec.percentage = getPercentage(
        metro.missMbPerSec.value,
        metrics.missMbPerSec.value
      );
      metro.missPerSec.percentage = getPercentage(
        metro.missPerSec.value,
        metrics.missPerSec.value
      );
    });
  });

  return regionsMap;
};
