import { ReactElement } from "react";
import styled from "@emotion/styled";

export interface TextAreaProps {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  className?: string;
  testID?: string;
}

export const TextArea = ({
  onChange,
  value,
  placeholder,
  className,
  testID,
}: TextAreaProps): ReactElement => (
  <StyledTextArea
    onChange={(e) => {
      onChange(e.target.value);
    }}
    placeholder={placeholder}
    value={value}
    className={className}
    data-testid={testID}
  />
);

const StyledTextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  color: ${({ theme }) => theme.text.primary};
  border-radius: 3px;
  padding: 1em;
  width: 100%;
  height: 7em;
  resize: none;
  outline: none;
  :focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.borders.mutedLight};
  }
`;
