import {
  FocusEvent,
  HTMLAttributes,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated, config, useSpring } from "react-spring";

import { Icon, Icons } from "../../atoms/Icon/Icon";

type TextSize = "xsmall" | "small" | "medium" | "large" | "xlarge";

export interface TextFieldProps extends HTMLAttributes<HTMLSpanElement> {
  /** id must be a unique identifier unique in the DOM */
  id: string;
  text: string;
  editable?: boolean;
  size?: TextSize;
  editColor?: boolean;

  onSave?: (text: string) => void;
  dataTestId?: string;
}

export const TextField = ({
  id,
  text,
  editable = false,
  size,
  className,
  onSave,
  editColor = false,
  dataTestId,
  ...props
}: TextFieldProps): ReactElement => {
  const [content, setContent] = useState(text);
  const [isEditing, setIsEditing] = useState(false);
  const animation = useSpring({
    opacity: isEditing ? 0 : 1,
    config: { ...config.stiff, mass: 0.5 },
  });

  useEffect(() => {
    setContent(text);
  }, [text, isEditing]);

  const handleKeyPress = (event: KeyboardEvent<HTMLSpanElement>) => {
    if (editable) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.currentTarget.blur();
      }
    }
  };

  // https://stackoverflow.com/a/4238971/7280833
  const handleFocus = (event: FocusEvent<HTMLSpanElement>) => {
    const range = document.createRange();
    range.selectNodeContents(event.currentTarget);
    range.collapse(false);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleBlur = (event: FocusEvent<HTMLSpanElement>) => {
    if (editable) {
      if (onSave) {
        onSave(event.currentTarget.innerText);
        setContent(event.currentTarget.innerText);
      }
      setIsEditing(false);
    }
  };

  const handleEditIconClick = () => {
    const input = document.getElementById(id) as HTMLSpanElement | null;
    if (input) {
      input.focus();
      setIsEditing(true);
    }
  };

  return (
    <TextFieldContainer className={className} size={size}>
      <Input
        id={id}
        role="textbox"
        contentEditable={editable}
        onClick={() => {
          editable && setIsEditing(true);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        color={props.color}
        suppressContentEditableWarning
        data-testid={dataTestId}
      >
        {content}
      </Input>
      <AnimatedIcon style={animation}>
        {editable && (
          <EditIcon
            onClick={handleEditIconClick}
            editcolor={editColor ? 1 : 0}
            name={Icons.EDIT}
            size={14}
          />
        )}
      </AnimatedIcon>
    </TextFieldContainer>
  );
};

const TextFieldContainer = styled.div<{ size?: TextSize }>`
  display: flex;
  align-items: center;
  ${({ size }) => {
    switch (size) {
      case "xsmall":
        return css`
          font-size: 0.75rem !important;
          line-height: 1rem !important;
        `;

      case "small":
        return css`
          font-size: 0.8125rem !important;
          line-height: 1.25rem !important;
        `;

      case "medium":
        return css`
          font-size: 0.875rem !important;
          line-height: 1.5rem !important;
        `;
      case "large":
        return css`
          font-size: 1rem !important;
          line-height: 1.5rem !important;
        `;
      case "xlarge":
        return css`
          font-size: 1.125rem !important;
          line-height: 1.75rem !important;
        `;
    }
  }}
`;

const AnimatedIcon = styled(animated.span)`
  cursor: pointer;
`;

const EditIcon = styled(Icon)<{ editcolor?: number }>`
  margin-left: 16px;
  fill: ${({ editcolor, theme }) =>
    editcolor ? theme.icon.highlight : theme.icon.muted};
`;

const Input = styled.span<{ color?: string }>`
  padding: 3px;
  color: ${({ color, theme }) => color || theme.text.primary};
  line-height: 1.1em;
  line-break: anywhere;
`;
