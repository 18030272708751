import { useState } from "react";
import styled from "@emotion/styled";
import { animated, useSpring } from "react-spring";
import { useHistory } from "react-router-dom";

import { httpClient } from "../../../../core/http-client";
import { IGeneratedMenu } from "./useOldNavigator";

interface IOldItems {
  menu: IGeneratedMenu;
}

export const OldItems: React.FC<IOldItems> = ({ menu }) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverAnimation = useSpring({
    height: isHovered ? "40px" : "0px",
    opacity: isHovered ? 1 : 0,
  });
  const history = useHistory();

  return (
    <OldNavigatorCategory
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Title
        onClick={() => httpClient.goToOldMP(menu.href)}
        data-testid={`${menu.title}-navigator-dropdown`}
      >
        {menu.title}
      </Title>
      {isHovered && menu.items.length ? (
        <OldDrop>
          {menu.items.map((item, i) => (
            <Item
              style={hoverAnimation}
              key={i}
              onClick={() =>
                item.isNewPortal
                  ? history.push(item.href)
                  : httpClient.goToOldMP(item.href)
              }
              data-testid={`${item.title}-navigator-item`}
            >
              {item.title}
            </Item>
          ))}
        </OldDrop>
      ) : null}
    </OldNavigatorCategory>
  );
};

const OldNavigatorCategory = styled.div`
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 20px;
  height: 100%;

  @media (min-width: 1440px) {
    margin-right: 40px;
  }
`;

const OldDrop = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  background-color: ${({ theme }) => theme.backgrounds.baseLight}!important;
  position: absolute;
  box-shadow: 0px 3px 6px #d6d6d6;
  z-index: 99999;
`;

const Item = styled(animated.div)`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  padding: 5px 16px;
  color: ${({ theme }) => theme.text.primary}!important;
  height: 40px;
  width: 100%;
  line-height: 23px;
  font-weight: normal;
  transition: background-color 0.6s;
  :hover {
    color: #000000 !important;
    background-color: ${({ theme }) => theme.backgrounds.mutedLight}!important;
    text-decoration: underline;
  }
`;

const Title = styled.div``;
