import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ReactComponent as RemoveSVG } from "../../../../../../../../../../assets/icons/circle_minus_outline.svg";
import { Protected } from "../../../../../../../../../../components/atoms/Protected/Protected";
import { FeatureType } from "../../../../../../../../../../components/molecules/DefinitionDropdownItems/DefinitionDropdownItems";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { DefinitionDropdown } from "../../../../../../../../../../components/organisms/DefinitionDropdown/DefinitionDropdown";
import { IFeatures } from "../../../../../../../../../../models/configuration/definitions";
import { ILuaFeature } from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../../../../models/permissions";
import {
  ConfigurationDetailsType,
  LuaDefinitionType,
} from "../../../../../../../../../../store/slices/caching/types";
import { IValidationErrors } from "../../FeaturesList";
import { VariablesTable } from "./VariablesTable";

export interface LuaItemProps {
  disabled: boolean;
  feature: ILuaFeature;
  luaType: "reqLua" | "respLua";
  config: ConfigurationDetailsType;
  maxWidth: number;
  currentDefinition?: LuaDefinitionType;
  availableDefinitions: LuaDefinitionType[];
  addedDefinitions: LuaDefinitionType[];
  rowIndex: number;
  errors: IValidationErrors;
  handleCreateFeature: UseFormSetValue<IFeatures>;
  updateDefinition: (definition: LuaDefinitionType, index: number) => void;
  removeDefinition: ((index: number) => void) | undefined;
  setShowAddButton: (showAddButton: boolean) => void;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
}

export const LuaItem: FC<LuaItemProps> = ({
  disabled,
  feature,
  luaType,
  config,
  maxWidth,
  currentDefinition,
  availableDefinitions,
  addedDefinitions,
  rowIndex,
  errors,
  updateDefinition,
  removeDefinition,
  setShowAddButton,
  handleCreateFeature,
  setError,
  clearErrors,
}) => {
  const { t } = useTranslation("configurationMatchRulesPage");

  const [selectedDefinition, setSelectedDefinition] = useState<
    LuaDefinitionType | undefined
  >(currentDefinition);

  useEffect(() => {
    setShowAddButton(!!selectedDefinition);
  }, [selectedDefinition, setShowAddButton]);

  useEffect(() => {
    setSelectedDefinition(currentDefinition);
  }, [currentDefinition]);

  return (
    <>
      <PropertyDefinitionCard
        title={t("FEATURE_CARD_LUA_ITEM_AVAILABLE_DEFINITION_TITLE", {
          index: rowIndex + 1,
        })}
        additionalInfoTitle={t(
          "FEATURE_CARD_LUA_ITEM_AVAILABLE_DEFINITION_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={t(
          "FEATURE_CARD_LUA_ITEM_AVAILABLE_DEFINITION_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.OtherType}
        maxWidth={maxWidth}
        errorMessage={
          errors[`${luaType}-${rowIndex}-dropdown`] &&
          errors[`${luaType}-${rowIndex}-dropdown`].message
        }
        fieldProps={{
          other: config.luaDefinitions ? (
            <DefinitionContainer>
              <DefinitionDropdown
                id={`${luaType}-${rowIndex}-dropdown`}
                disabled={disabled}
                items={availableDefinitions.map((definition) => ({
                  definition,
                  disabled:
                    selectedDefinition?.name !== definition.name &&
                    addedDefinitions.includes(definition),
                }))}
                type={FeatureType.LUA}
                allowAdd={false}
                allowMultiSelection={false}
                onSelect={(value) => {
                  setSelectedDefinition(value[0] as LuaDefinitionType);
                  updateDefinition(value[0] as LuaDefinitionType, rowIndex);
                }}
                selected={selectedDefinition ? [selectedDefinition] : []}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                dataTestId={`${luaType}-${rowIndex}`}
                parentConfig={config}
              />
              <Protected permissions={UserRoles.EDIT_CONFIG}>
                {removeDefinition ? (
                  <Remove
                    onClick={() => {
                      removeDefinition(rowIndex);
                      selectedDefinition === undefined &&
                        setShowAddButton(true);
                    }}
                    data-testid={`${luaType}-title-${rowIndex}-delete`}
                  />
                ) : (
                  <></>
                )}
              </Protected>
            </DefinitionContainer>
          ) : (
            <div>{t("FEATURE_CARD_LUA_ITEM_MISSING_DEFINITION")}</div>
          ),
        }}
        divider={!!selectedDefinition}
        dataTestId={`${luaType}-title-${rowIndex}`}
      />
      {selectedDefinition && (
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_LUA_ITEM_VARIABLES_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_LUA_ITEM_VARIABLES_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_LUA_ITEM_VARIABLES_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          fieldProps={{
            other: (
              <>
                {selectedDefinition.variables ? (
                  <VariablesTable
                    dataTestId={`${luaType}-variables-${rowIndex}`}
                    disabled={disabled}
                    luaType={luaType}
                    variables={selectedDefinition.variables}
                    selectedDefinition={selectedDefinition}
                    errors={errors}
                    rowIndex={rowIndex}
                    handleCreateFeature={handleCreateFeature}
                    setError={setError}
                    clearErrors={clearErrors}
                    variableValueList={feature.variableValueList ?? []}
                  />
                ) : (
                  t("FEATURE_CARD_LUA_ITEM_HAS_NO_VARIABLES")
                )}
              </>
            ),
          }}
          divider={rowIndex < addedDefinitions.length - 1}
          dataTestId={`${luaType}-variables-${rowIndex}`}
        />
      )}
    </>
  );
};

const DefinitionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Remove = styled(RemoveSVG)`
  margin: 4px 8px 0px 8px;
  width: 1em !important;
  height: 1em !important;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.colors.red60};
  }
`;
