import { SimpleDefinitionType, ConfigurationDetailsType } from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getSimpleReferences = (
  def: SimpleDefinitionType,
  config: ConfigurationDetailsType
): number => {
  const features = getMatchRules(config).map(
    (mastchRule) => mastchRule?.features
  );

  switch ((def as SimpleDefinitionType).listType) {
    case "geo":
      return features
        .map((feature) => feature?.geoRestrictions?.nameList)
        .filter((nameList) => nameList?.includes(def.name)).length;
    case "ip":
      return features
        .map((feature) => feature?.ipRestrictions?.nameList)
        .filter((nameList) => nameList?.includes(def.name)).length;
    default:
      return 0;
  }
};
