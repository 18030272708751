import type { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { CCHOInt } from "../../../../../../../../../../models/configuration/definitions/matchlogic";
import { useIsViewMode } from "../../../../../../../../../../store/slices/permissions/hooks";
import { InternalPolicyType } from "./InternalPolicyType";
import type { PolicyProps } from "./types";

export const InternalPolicy = ({
  errors,
  handleCreateFeature,
  maxWidth,
  policy,
  register,
  unregister,
}: PolicyProps<CCHOInt>): ReactElement => {
  const [t] = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");
  const isViewMode = useIsViewMode();

  return (
    <>
      <PropertyDefinitionCard
        title={t("FEATURE_CARD_CCHO_ICP_TITLE")}
        additionalInfoTitle={t("FEATURE_CARD_CCHO_INTERNAL_DESCRIPTION_TITLE")}
        additionalInfoContent={t(
          "FEATURE_CARD_CCHO_INTERNAL_DESCRIPTION_CONTENT"
        )}
        fieldType={FieldType.CheckboxType}
        fieldProps={{
          label: t("FEATURE_CARD_CCHO_INTERNAL_CHECKBOX_LABEL"),
          disabled: isViewMode,
          checked: !!policy,
          onChange: () => {
            handleCreateFeature(
              "cacheControlHeaderOverride.int",
              !!policy ? undefined : { cachePolicy: "" }
            );
          },
          dataTestId: "ccho-icp",
        }}
        divider
        dataTestId="ccho-icp"
        errorMessage={
          (errors.cacheControlHeaderOverride as { message?: string })?.message
        }
      />

      {policy && (
        <InternalPolicyType
          cachePolicy={policy.cachePolicy}
          disabled={isViewMode}
          handleCreateFeature={handleCreateFeature}
          maxWidth={maxWidth}
          numberErrorMessage={
            errors.cacheControlHeaderOverride?.int?.cachePolicy?.message
          }
          register={register}
          unregister={unregister}
          t={t}
          tMisc={tMisc}
        />
      )}
    </>
  );
};
