import { ReactElement } from "react";
// eslint-disable-next-line import/named
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DeleteModal } from "../../../../../../../../../components/molecules/DeleteModal/DeleteModal";
import { FeatureTypes } from "../../../../../../../../../components/molecules/FeatureType/FeatureType";
import { HeadlessInputField } from "../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IFeatures } from "../../../../../../../../../models/configuration/definitions";
import { useIsViewMode } from "../../../../../../../../../store/slices/permissions/hooks";
import { IValidationErrors } from "../FeaturesList";
import { FeatureBlock } from "./FeatureBlock";

interface IURIRewriteFeatureBlockProps {
  uriRewrite: Exclude<IFeatures["uriRewrite"], undefined>;
  initIsOpen: boolean;
  maxWidth: number;
  errors: IValidationErrors;
  datatestId: string;
  onDelete: () => void;
  handleCreateFeature: (featureName: keyof IFeatures, newFeature: any) => void;
  register: UseFormRegister<IFeatures>;
}

export const URIRewriteFeatureBlock = ({
  uriRewrite,
  initIsOpen,
  maxWidth,
  errors,
  datatestId,
  onDelete,
  handleCreateFeature,
  register,
}: IURIRewriteFeatureBlockProps): ReactElement => {
  const isViewMode = useIsViewMode();
  const { t } = useTranslation("configurationMatchRulesPage");
  const [tMisc] = useTranslation("misc");

  return (
    <FeatureBlock
      title={t("FEATURE_CARD_URI_REWRITE_TITLE")}
      additionalInfoTitle={t(
        "FEATURE_CARD_ADDED_URI_REWRITE_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_ADDED_URI_REWRITE_DESCRIPTION_CONTENT"
      )}
      fields={
        <PropertyDefinitionCard
          title={t("FEATURE_CARD_URI_REWRITE_DEFINITION_TITLE")}
          additionalInfoTitle={t(
            "FEATURE_CARD_URI_REWRITE_DEFINITION_DESCRIPTION_TITLE"
          )}
          additionalInfoContent={t(
            "FEATURE_CARD_URI_REWRITE_DEFINITION_DESCRIPTION_CONTENT"
          )}
          fieldType={FieldType.OtherType}
          fieldProps={{
            other: (
              <HeadlessInputField
                disabled={isViewMode}
                placeholder={t(
                  "FEATURE_CARD_URI_REWRITE_DEFINITION_PLACEHOLDER"
                )}
                {...register("uriRewrite", {
                  required: tMisc("ERROR_REQUIRED_FIELD"),
                })}
                data-testid="uri-rewrite-input"
              />
            ),
          }}
          maxWidth={maxWidth}
          errorMessage={errors.uriRewrite && errors.uriRewrite.message}
          dataTestId="uri-rewrite"
        />
      }
      initIsOpen={initIsOpen}
      customDeleteButton={
        <DeleteModal
          title={t("FEATURE_CARD_DELETE_FEATURE_TITLE")}
          deleteItemName={"uriRewrite"}
          onDelete={onDelete}
          dataTestId={datatestId}
        />
      }
      dataTestId="uri-rewrite"
      featureType={FeatureTypes.REQUEST_MANIPULATION}
    />
  );
};
