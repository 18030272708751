import { IConfigDetailsData } from "../../../../models/configuration/configuration_details";
import { TypeDefinitions } from "../../../../models/configuration/definitions/definition";
import { EXCEPTIONS_WHEN_EMPTY } from "../constants";
import {
  DefinitionType,
  PropertyDefinitionType,
  PrepareOptionsType,
  MatchLogicDefinitionType,
  ConfigurationDetailsType,
} from "../types";
import { prepareDefinition } from "./prepareDefinition";

const isPropertyDefinition = (
  definition: DefinitionType
): definition is PropertyDefinitionType =>
  Object.prototype.hasOwnProperty.call(definition, "signature") &&
  Object.prototype.hasOwnProperty.call(definition, "matchLogic") &&
  Object.prototype.hasOwnProperty.call(definition, "trafficType") &&
  Object.prototype.hasOwnProperty.call(definition, "primaryAlias") &&
  Object.prototype.hasOwnProperty.call(definition, "aliases") &&
  Object.prototype.hasOwnProperty.call(definition, "aliasOverrides") &&
  Object.prototype.hasOwnProperty.call(definition, "protocolSettings") &&
  Object.prototype.hasOwnProperty.call(definition, "logging") &&
  Object.prototype.hasOwnProperty.call(definition, "originFillPolicy");

const preparePropertyDefinition = (
  isStorage: boolean,
  definition: PropertyDefinitionType,
  options?: PrepareOptionsType<MatchLogicDefinitionType>
) => {
  const test = options?.definitions?.every(
    (matchLogic) => matchLogic.name !== definition.matchLogic
  );

  return prepareDefinition(isStorage, {
    ...definition,
    matchLogic: test && !isStorage ? undefined : definition.matchLogic,
  });
};

export const prepareConfigDetails = (
  isStorage: boolean,
  config: ConfigurationDetailsType
): IConfigDetailsData => {
  const {
    genSchemaVersion,
    subscriberId,
    unhandledFields,
    ...definitions
  } = config;
  const partialDefinitions: IConfigDetailsData = {
    genSchemaVersion,
    subscriberId,
  };

  const detailsData = Object.entries(definitions).reduce(
    (acc, [currentKey, currentValue]) => {
      acc[currentKey as TypeDefinitions] = Object.fromEntries(
        (currentValue as DefinitionType[]).map((value) => {
          if (isPropertyDefinition(value)) {
            return [
              value.name,
              preparePropertyDefinition(isStorage, value, {
                definitions: config.matchLogicDefinitions,
              }) as any,
            ];
          }
          return [
            value.name,
            prepareDefinition(isStorage, value, {
              deadPathExceptions: EXCEPTIONS_WHEN_EMPTY,
            }) as any,
          ];
        })
      );
      return acc;
    },
    {} as typeof partialDefinitions
  ) as IConfigDetailsData;

  return {
    ...Object.fromEntries(unhandledFields),
    ...partialDefinitions,
    ...detailsData,
  };
};
