import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { checkUserPermissions, UserRoles } from "../../../models/permissions";
import { useUserPermissions } from "../../../store/slices/permissions/hooks";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { EditOrViewIcon } from "../../atoms/EditOrViewIcon/EditOrViewIcon";
import { Modal } from "../../atoms/Modal/Modal";
import { VerticalDivider } from "../../atoms/VerticalDivider/VerticalDivider";
import { InfoCard } from "../InfoCard/InfoCard";
import { PresentationModal } from "../PresentationModal/PresentationModal";
import { FlexBox } from "../../atoms/FlexBox/FlexBox";
import { Icon, Icons } from "../../atoms/Icon/Icon";

export interface InfosProps {
  dataTestId?: string;
  info?: string;
  isInRegistry: boolean;
  propertyName: string;
  onDelete: () => void;
  onEdit: () => void;
}

export const Infos = ({
  dataTestId,
  info,
  isInRegistry,
  propertyName,
  onDelete,
  onEdit,
}: InfosProps): ReactElement => {
  const permissions = useUserPermissions();

  const [t] = useTranslation("configurationPropertiesPage");
  const [tMisc] = useTranslation("misc");

  const isEditMode = checkUserPermissions(UserRoles.EDIT_CONFIG, permissions);

  return (
    <FlexBox>
      {info && <InfoCard message={info} />}

      <EditOrViewIcon
        isViewMode={!isEditMode}
        onClick={onEdit}
        dataTestId={dataTestId && `${dataTestId}-button`}
      />

      {isEditMode && (
        <>
          <Divider />

          <Modal
            customButton={
              <Icon
                name={Icons.TRASH}
                color="secondary"
                data-testid={`${dataTestId}-delete-property`}
              />
            }
          >
            <PresentationModal
              title={t(
                isInRegistry
                  ? "DELETE_IN_REGISTRY_PROPERTY_MODAL_TITLE"
                  : "DELETE_PROPERTY_MODAL_TITLE"
              )}
              dataTestId={"delete-property"}
              button={{
                label: tMisc(isInRegistry ? "REMOVE" : "DELETE"),
              }}
              onSubmit={onDelete}
            >
              <CustomRemark>
                {t(
                  isInRegistry
                    ? "DELETE_PROPERTY_IN_REGISTRY_CONTENT"
                    : "DELETE_PROPERTY_CONTENT",
                  { propertyName }
                )}
              </CustomRemark>
            </PresentationModal>
          </Modal>
        </>
      )}
    </FlexBox>
  );
};

const Divider = styled(VerticalDivider)`
  margin: 0 1em;
`;
