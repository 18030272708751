import { FC, useEffect } from "react";
import { useAccessGroupId } from "../../../store/slices/access-groups/hooks";
import {
  handleFetchCurrentData,
  handleFetchHistoricalData,
  handleFetchProperties,
  handleFetchHistoryForRegions,
} from "../../../store/slices/rtm/thunks";
import { useAppDispatch } from "../../../store/types";

export const RTMLayer: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const accessGroupId = useAccessGroupId();

  useEffect(() => {
    const refetchWrapper = () => {
      dispatch(handleFetchCurrentData());
    };
    dispatch(handleFetchCurrentData(true));
    dispatch(handleFetchHistoricalData(true));
    dispatch(handleFetchHistoryForRegions(true));
    dispatch(handleFetchProperties());
    const reftechInterval = setInterval(refetchWrapper, 60000);
    return () => {
      clearInterval(reftechInterval);
    };
    // ignore dispatch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGroupId]);

  return <>{children}</>;
};
