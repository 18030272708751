import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FlexBox } from "../FlexBox/FlexBox";

export const DropDownHeaderContainer = styled(FlexBox)<{ isOpen: boolean }>`
  ${({ isOpen, theme }) =>
    isOpen
      ? css`
          background: ${theme.backgrounds.highlightLight};
          border: 1px solid ${theme.borders.highlight};
        `
      : css`
          background: ${theme.backgrounds.baseLight};
          border: 1px solid ${theme.borders.mutedLight};
        `}
  padding: 4px 12px;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const Label = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      font-weight: 600;
    `}
  color: ${({ theme }) => theme.text.muted};
  margin-right: 8px;
`;

export const Selected = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.backgrounds.base};
  border-radius: 4px;
  font-weight: 600;
  margin-right: 8px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
