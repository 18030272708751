import { Route, Switch } from "react-router-dom";

import { ContentInvalidationPage } from "./ContentInvalidationPage";

export const ContentInvalidation = (): JSX.Element => {
  return (
    <Switch>
      <Route path="/" component={ContentInvalidationPage} />
    </Switch>
  );
};
