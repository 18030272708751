import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { HeadlessModal } from "../../../../../components/atoms/HeadlessModal/HeadlessModal";
import { ModalBody } from "../../../../../components/molecules/ModalBody/ModalBody";
import { Toggle } from "../../../../../components/atoms/Toggle/Toggle";
import {
  CreateInvalidation,
  getInvalidationProperties,
  InvalidationProperty,
} from "../../../../../models/invalidation";
import { HeadlessInputTag } from "../../../../../components/molecules/HeadlessInputTag/HeadlessInputTag";
import { Tag } from "../../../../../components/atoms/Tag/Tag";
import { Card } from "../../../../../components/atoms/Card/Card";
import { ExpandableContent } from "../../../../../components/molecules/ExpandableContent/ExpandableContent";
import { Error } from "../../../../../components/atoms/Text/Error";
import { hasControlChar, isASCII } from "../../../../../utils/string";
import { APIError } from "../../../../../models/error";
import { Checkbox } from "../../../../../components/molecules/Checkbox/Checkbox";
import { SearchDropDown } from "../../../../../components/organisms/SearchDropdown/SearchDropdown";
import { RadioButton } from "../../../../../components/atoms/RadioButton/RadioButton";
import { useDispatchAPIError } from "../../../../../store/slices/api-error/hooks";
import { useAccessGroupId } from "../../../../../store/slices/access-groups/hooks";
import { CustomRemark } from "../../../../../components/atoms/CustomRemark/CustomRemark";
import { Asterisk } from "../../../../../components/atoms/Asterisk/Asterisk";
import { FlexBox } from "../../../../../components/atoms/FlexBox/FlexBox";

interface ModalProps {
  modalOpen: boolean;
  setModalOpen: (param: boolean) => void;
  onSubmit: (invalidation: CreateInvalidation) => void;
  dataTestId?: string;
}

enum FormErrorType {
  MISSING_PROPERTY = "missing_property",
  MISSING_PATH = "missing_path",
  INVALID_PATH = "invalid_path",
}

const hasInvalidPathCharacter = (str: string) => {
  return ["#", "<", ">", "|"].some((char) => str.includes(char));
};

export const Modal = ({
  modalOpen,
  setModalOpen,
  onSubmit,
  dataTestId,
}: ModalProps): JSX.Element => {
  const [t] = useTranslation("contentInvalidationPage");
  const [tMisc] = useTranslation("misc");
  const accessGroupId = useAccessGroupId();
  const [properties, setProperties] = useState<InvalidationProperty[]>([]);
  const [errors, setErrors] = useState<FormErrorType[]>([]);
  const handleMediaPortalError = useDispatchAPIError();

  const defaultInvalidation: CreateInvalidation = {
    force: false,
    notify: false,
    ignoreCase: false,
    propertyPathList: { propertyId: -1, paths: [] },
  };

  const [invalidation, setInvalidation] = useState<CreateInvalidation>(
    defaultInvalidation
  );

  useEffect(() => {
    try {
      getInvalidationProperties(accessGroupId!).then(setProperties);
    } catch (err) {
      const error = err as APIError;
      handleMediaPortalError(error);
    }
  }, [accessGroupId]);

  const isValidTag = (tag: string) => {
    return (
      isASCII(tag) && !hasControlChar(tag) && !hasInvalidPathCharacter(tag)
    );
  };

  useEffect(() => {
    if (!invalidation.propertyPathList.paths.every(isValidTag)) {
      setErrors((e) => [...e, FormErrorType.INVALID_PATH]);
    } else {
      setErrors((e) =>
        e.filter((value) => value === FormErrorType.INVALID_PATH)
      );
    }
  }, [invalidation.propertyPathList]);

  const validateForm = () => {
    let isValid = true;

    if (invalidation.propertyPathList.propertyId === -1) {
      setErrors((e) => [...e, FormErrorType.MISSING_PROPERTY]);
      isValid = false;
    }

    if (invalidation.propertyPathList.paths.length === 0) {
      setErrors((e) => [...e, FormErrorType.MISSING_PATH]);
      isValid = false;
    }

    if (!invalidation.propertyPathList.paths.every(isValidTag)) {
      setErrors((e) => [...e, FormErrorType.INVALID_PATH]);
      isValid = false;
    }

    return isValid;
  };

  return (
    <HeadlessModal
      onClose={() => {
        setModalOpen(false);
      }}
      isOpen={modalOpen}
    >
      <ModalBody
        title={t("CREATE_NEW_REQUEST")}
        onClose={() => {
          setModalOpen(false);
          setInvalidation(defaultInvalidation);
        }}
        submitButton={{
          label: tMisc("COMMIT"),
          onSubmit: () => {
            setErrors([]);
            if (validateForm()) {
              onSubmit(invalidation);
              setModalOpen(false);
              setInvalidation(defaultInvalidation);
            }
          },
        }}
        dataTestId={`${dataTestId}-buttons`}
      >
        <>
          <ExpandableContent
            title={t("INVALIDATION_TIPS_TITLE")}
            content={t("INVALIDATION_TIPS_CONTENT")}
          />
          <div>
            <ModalBodyContainer>
              <ModalBodyRow>
                <ModalBodyCol size={4}>
                  <ModalBodyColContainer>
                    <ModalBodyColTitle>
                      {t("INVALIDATION_TYPE")}
                    </ModalBodyColTitle>
                    <Asterisk />
                  </ModalBodyColContainer>
                </ModalBodyCol>
                <InvalidationCol size={6}>
                  <RadioButton
                    id="invalidation-type-normal"
                    label={t("NORMAL")}
                    value="normal"
                    name="normal"
                    defaultChecked={!invalidation.force}
                    onClick={() => {
                      setInvalidation((i) => ({ ...i, force: false }));
                    }}
                  />
                  <RadioButton
                    id="invalidation-type-force"
                    label={t("FORCE")}
                    value="force"
                    name="force"
                    defaultChecked={invalidation.force}
                    onClick={() => {
                      setInvalidation((i) => ({ ...i, force: true }));
                    }}
                  />
                </InvalidationCol>
              </ModalBodyRow>
              <ExpandableContent
                title={t("INVALIDATION_TYPE_HELPER_TITLE")}
                content={t("INVALIDATION_TYPE_HELPER_DESCRIPTION")}
              />
            </ModalBodyContainer>
            <ModalBodyContainer>
              <ModalBodyRowCentered>
                <ModalBodyCol size={4}>
                  <ModalBodyColTitle>{t("IGNORE_CASE")}</ModalBodyColTitle>
                </ModalBodyCol>
                <ModalBodyCol size={6}>
                  <Checkbox
                    onChange={(checked) => {
                      setInvalidation((i) => ({
                        ...i,
                        ignoreCase: checked,
                      }));
                    }}
                    checked={invalidation.ignoreCase}
                    label={t("IGNORE_CASE_DESCRIPTION")}
                    id="ci-modal-checkbox"
                  />
                </ModalBodyCol>
              </ModalBodyRowCentered>
              <ExpandableContent
                title={t("IGNORE_CASE_HELPER_TITLE")}
                content={t("IGNORE_CASE_HELPER_DESCRIPTION")}
              />
            </ModalBodyContainer>
            <ModalBodyContainer>
              <ModalBodyRow>
                <ModalBodyCol size={4}>
                  <ModalBodyColContainer>
                    <ModalBodyColTitle>
                      {t("CACHING_PROPERTY")}
                    </ModalBodyColTitle>
                    <Asterisk />
                  </ModalBodyColContainer>
                </ModalBodyCol>
                <ModalBodyCol size={6}>
                  <StyledDropdown
                    items={properties.map((property) => {
                      return {
                        label: property.name,
                        value: property.id.toString(),
                      };
                    })}
                    placeholder={t("CACHING_SELECT_PROPERTY")}
                    selectedItems={[
                      {
                        label:
                          properties.find(
                            (prop) =>
                              prop.id ===
                              invalidation.propertyPathList.propertyId
                          )?.name || "",
                        value: invalidation.propertyPathList.propertyId.toString(),
                      },
                    ]}
                    setSelectedItems={(selectedItems) => {
                      const [property] = selectedItems;
                      if (property) {
                        setInvalidation((i) => ({
                          ...i,
                          propertyPathList: {
                            propertyId: parseInt(property.value, 10),
                            paths: invalidation.propertyPathList.paths,
                          },
                        }));
                      }
                    }}
                    dataTestId={`${dataTestId}-caching-property`}
                  />
                  {errors.includes(FormErrorType.MISSING_PROPERTY) && (
                    <Error data-testid={`${dataTestId}-missing-property`}>
                      {t("NO_PROPERTY_ERROR_MESSAGE")}y
                    </Error>
                  )}
                </ModalBodyCol>
              </ModalBodyRow>
              <ExpandableContent
                title={t("CACHING_PROPERTY_HELPER_TITLE")}
                content={t("CACHING_PROPERTY_HELPER_DESCRIPTION")}
              />
            </ModalBodyContainer>
            <ModalBodyContainer>
              <ModalBodyRow>
                <ModalBodyCol size={4}>
                  <ModalBodyColContainer>
                    <ModalBodyColTitle>{t("PATHS")}</ModalBodyColTitle>
                    <Asterisk />
                  </ModalBodyColContainer>
                </ModalBodyCol>
                <ModalBodyCol size={6}>
                  <HeadlessInputTagWrapper
                    onClick={() => {
                      document.getElementById("input-tag")?.focus();
                    }}
                  >
                    <HeadlessInputTag
                      additonalSeperators={[" ", ",", ";"]}
                      id="input-tag"
                      disabled={
                        invalidation.propertyPathList.paths.length === 50
                      }
                      placeholder="/img/*.jpg"
                      onTagsChange={(tags) => {
                        setInvalidation((i) => ({
                          ...i,
                          propertyPathList: {
                            propertyId: i.propertyPathList.propertyId,
                            paths: tags,
                          },
                        }));
                      }}
                      Tag={(props) => <Tag {...props} />}
                      Input={InputTagInput}
                      dataTestId={`${dataTestId}-add-new-path`}
                    />
                  </HeadlessInputTagWrapper>
                  {errors.includes(FormErrorType.MISSING_PATH) && (
                    <Error data-testid={`${dataTestId}-missing-path`}>
                      {t("NO_PATH_ERROR_MESSAGE")}
                    </Error>
                  )}
                  {errors.includes(FormErrorType.INVALID_PATH) && (
                    <Error data-testid={`${dataTestId}-invalid-path`}>
                      {t("INVALID_PATH_ERROR")}
                    </Error>
                  )}
                </ModalBodyCol>
              </ModalBodyRow>
              <ExpandableContent
                title={t("PATHS_HELPER_TITLE")}
                content={t("PATHS_HELPER_DESCRIPTION")}
              />
            </ModalBodyContainer>
            <ModalBodyContainer>
              <ModalBodyRow>
                <ModalBodyCol size={4}>
                  <ModalBodyColTitle>{t("NOTIFICATION")}</ModalBodyColTitle>
                </ModalBodyCol>
                <NotificationsCol size={6}>
                  <Toggle
                    onClick={(checked) => {
                      setInvalidation((prevInval) => ({
                        ...prevInval,
                        notify: checked,
                      }));
                    }}
                  />
                  <CustomRemark>{t("NOTIFICATION_DESCRIPTION")}</CustomRemark>
                </NotificationsCol>
              </ModalBodyRow>
              <ExpandableContent
                title={t("NOTIFICATION_HELPER_TITLE")}
                content={t("NOTIFICATION_HELPER_DESCRIPTION")}
              />
            </ModalBodyContainer>
          </div>
        </>
      </ModalBody>
    </HeadlessModal>
  );
};

const InputTagInput = styled.input`
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  color: ${({ theme }) => theme.text.primary};
`;

const HeadlessInputTagWrapper = styled(Card)`
  padding: 8px;
  overflow: auto;
`;
const ModalBodyContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.borders.highlightLight};
`;
const ModalBodyRow = styled.div`
  display: flex;
`;

const ModalBodyRowCentered = styled(ModalBodyRow)`
  align-items: center;
`;

const ModalBodyColContainer = styled(FlexBox)`
  justify-content: start;
`;

const ModalBodyCol = styled.div<{ size: number }>`
  width: ${({ size }) => `${size * 10}%`};
  padding-top: 32px;
`;

const InvalidationCol = styled(ModalBodyCol)`
  display: flex;
  & > div:first-child {
    margin-right: 32px;
  }
`;

const NotificationsCol = styled(ModalBodyCol)`
  display: flex;
  & > div:first-child {
    margin-right: 15px;
  }
`;

const ModalBodyColTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.text.primary};
`;

const StyledDropdown = styled(SearchDropDown)`
  width: 100%;
  & > .base-dropdown-select {
    padding: 12px;
  }
`;
