import { ReactElement, useContext, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type {
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import type { TFunction } from "react-i18next";

import {
  Icon,
  Icons,
} from "../../../../../../../../../../components/atoms/Icon/Icon";
import { HeadlessInputField } from "../../../../../../../../../../components/molecules/InputField/InputField";
import {
  FieldType,
  PropertyDefinitionCard,
} from "../../../../../../../../../../components/molecules/PropertyDefinitionCard/PropertyDefinitionCard";
import { IDropdownItem } from "../../../../../../../../../../components/organisms/Dropdown/Dropdown";
import {
  Theme,
  ThemeContext,
} from "../../../../../../../../../../contexts/themeContext";
import type { ValidationErrors } from "../../types";
import { VariableExpression, VariableFields } from "./types";

interface HeaderProps {
  disabled: boolean;
  divider: boolean;
  handleDeleteFeature: (featureName: string) => void;
  id: string;
  maxWidth: number;
  register: UseFormRegister<FieldValues>;
  t: TFunction<"configurationMatchRulesPage">;
  title: string;
  tMisc: TFunction<"misc">;
  variableNameErr?: string;
}

const Header = ({
  disabled,
  divider,
  handleDeleteFeature,
  id,
  maxWidth,
  register,
  t,
  title,
  tMisc,
  variableNameErr,
}: HeaderProps) => {
  useEffect(() => {
    return () => {
      handleDeleteFeature(id);
    };
  }, []);

  return (
    <PropertyDefinitionCard
      title={title}
      additionalInfoTitle={t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_REQUEST_HEADER_DESCRIPTION_TITLE"
      )}
      additionalInfoContent={t(
        "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_REQUEST_HEADER_DESCRIPTION_CONTENT"
      )}
      fieldType={FieldType.OtherType}
      fieldProps={{
        other: (
          <HeadlessInputField
            disabled={disabled}
            placeholder={t(
              "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_HEADER_PLACEHOLDER"
            )}
            {...register(id, {
              required: tMisc("ERROR_REQUIRED_FIELD"),
            })}
            data-testid={id}
          />
        ),
      }}
      maxWidth={maxWidth}
      errorMessage={variableNameErr}
      divider={divider}
      dataTestId="custom-log-data-variable-header"
    />
  );
};

interface VariableEntryProps
  extends Omit<HeaderProps, "divider" | "id" | "title" | "variableNameErr"> {
  clearErrors?: UseFormClearErrors<FieldValues>;
  entriesCount: number;
  errors: ValidationErrors;
  i: number;
  items: IDropdownItem<VariableExpression | string | undefined>[];
  onSelect: (selectedItem: { value: VariableExpression | string }) => void;
  setError?: UseFormSetError<FieldValues>;
  variableFields: VariableFields;
  variablePrefix: VariableExpression | string;
}

export const VariableEntry = ({
  clearErrors,
  disabled,
  entriesCount,
  errors,
  handleDeleteFeature,
  i,
  items,
  maxWidth,
  onSelect,
  register,
  setError,
  t,
  tMisc,
  variableFields,
  variablePrefix,
}: VariableEntryProps): ReactElement => {
  const variable = variableFields[variablePrefix as VariableExpression] as
    | {
        description: string;
      }
    | undefined;
  const customVariable = typeof variablePrefix !== "undefined" && !variable;
  const themeContext = useContext(ThemeContext);

  const headerExpression =
    variablePrefix === VariableExpression.REQ_HEADER ||
    variablePrefix === VariableExpression.RESP_HEADER;
  const notLastEntry = i < entriesCount - 1;

  return (
    <>
      <PropertyDefinitionCard
        title={t("FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_TITLE")}
        additionalInfoTitle={t(
          "FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_DESCRIPTION_TITLE"
        )}
        additionalInfoContent={customVariable ? "" : variable?.description}
        fieldType={FieldType.DropdownType}
        fieldProps={{
          key: customVariable,
          disabled,
          placeholder: customVariable
            ? "Custom Expression"
            : variablePrefix ||
              t("FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_PLACEHOLDER"),
          id: `customLogData[${i}].variableName`,
          items,
          dropdownType: "button",
          outline: false,
          onSelect,
          clearErrors,
          setError,
          dataTestId: `custom-log-data-variable-dropdown-${i}`,
        }}
        divider={headerExpression || customVariable || notLastEntry}
        errorMessage={
          (!customVariable &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            errors.customLogData?.[i]?.variableName?.message) ||
          ""
        }
        dataTestId={`custom-log-data-variable-${i}`}
        required
      />
      {(customVariable && (
        <>
          <PropertyDefinitionCard
            asterisk
            title="Value"
            fieldType={FieldType.InputFieldType}
            fieldProps={{
              disabled,
              register,
              validation: {
                required: tMisc("ERROR_REQUIRED_FIELD"),
              },
              onChange: (value: VariableExpression | string) => {
                onSelect({ value });
              },
              placeholder: "Enter Value Manually",
              id: `customLogData[${i}].variableName`,
              dataTestId: `customLogData[${i}].variableName`,
            }}
            maxWidth={maxWidth}
            errorMessage={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              errors.customLogData?.[i]?.variableName?.message
            }
            dataTestId={`custom-log-data-custom-expression-${i}`}
          />
          <VersionWarningContainer
            data-testid={`customLogData-${i}-custom-expression-alert`}
            isdarkmode={themeContext.theme === Theme.DARK_MODE}
          >
            <VersionWarning>
              <AlertIcon name={Icons.ALERT} />
              The validity of a custom variable expression will be checked once
              you commit your changes to the API.
            </VersionWarning>
          </VersionWarningContainer>
        </>
      )) ||
        (headerExpression && (
          <Header
            title={
              variable?.description ||
              t("FEATURE_CARD_CUSTOM_LOG_DATA_VARIABLE_REQUEST_HEADER_TITLE")
            }
            disabled={disabled}
            divider={entriesCount > 1}
            variableNameErr={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              errors[`customLogData-${i}-variableName`]?.message
            }
            id={`customLogData-${i}-variableName`}
            maxWidth={maxWidth}
            register={register}
            t={t}
            tMisc={tMisc}
            handleDeleteFeature={handleDeleteFeature}
          />
        ))}
    </>
  );
};

const VersionWarningContainer = styled.div<{ isdarkmode: boolean }>`
  background-color: ${({ theme }) => theme.backgrounds.warningLight};
  padding-left: 24px;
  width: 100%;
  margin: 24px 0;
  ${({ isdarkmode }) =>
    isdarkmode &&
    css`
      color: #000000;
    `}
`;

const VersionWarning = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

const AlertIcon = styled(Icon)`
  margin-right: 16px;

  * {
    fill: ${({ theme }) => theme.colors.yellow40};
  }
`;
