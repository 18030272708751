import styled from "@emotion/styled";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { FlexBoxBase } from "../../../../../components/atoms/FlexBox/FlexBox";
import { Icon, Icons } from "../../../../../components/atoms/Icon/Icon";
import { Modal } from "../../../../../components/atoms/Modal/Modal";
import { UserVyvxData } from "../../../../../models/user-management/users/users.interfaces";
import { EditVyvxModal } from "./modals/EditVyvxModal";

interface UserVyvxCompaniesDataProps {
  userId: number;
  vyvxData: UserVyvxData;
}

export const UserVyvxCompaniesData = ({
  userId,
  vyvxData,
}: UserVyvxCompaniesDataProps): ReactElement => {
  const [openEditVyvxModal, setOpenEditVyvxModal] = useState(false);
  const { t } = useTranslation("usersPage");

  return (
    <>
      <TitleContainer>
        <Title>{t("VYVX_SECTION_TITLE")}</Title>
        <EditIcon
          name={Icons.EDIT}
          onClick={() => setOpenEditVyvxModal(true)}
        />
      </TitleContainer>
      <DataContainer>
        <ColumnContainer>
          <ColumnLabel>{t("VYVX_SECTION_FIBER_LABEL")}</ColumnLabel>
          <PrimaryValue>{vyvxData.fiberCompanyName}</PrimaryValue>
          {vyvxData.fiberAssociatedCompanies &&
            vyvxData.fiberAssociatedCompanies.map((company) => (
              <SecondaryValue key={company.companyAbbr}>
                {company.companyName}
              </SecondaryValue>
            ))}
        </ColumnContainer>
        <ColumnContainer>
          <ColumnLabel>{t("VYVX_SECTION_SATELLITE_LABEL")}</ColumnLabel>
          <PrimaryValue>{vyvxData.satelliteCompanyName}</PrimaryValue>
          {vyvxData.satelliteAssociatedCompanies &&
            vyvxData.satelliteAssociatedCompanies.map((company) => (
              <SecondaryValue key={company.companyAbbr}>
                {company.companyName}
              </SecondaryValue>
            ))}
        </ColumnContainer>
        <ColumnContainer>
          <ColumnLabel>{t("VYVX_SECTION_ROLE_LABEL")}</ColumnLabel>
          <PrimaryValue>{vyvxData.role}</PrimaryValue>
        </ColumnContainer>
      </DataContainer>
      <Modal
        customButton={<></>}
        open={openEditVyvxModal}
        onClose={() => setOpenEditVyvxModal(false)}
        overflow="visible"
      >
        <EditVyvxModal vyvxData={vyvxData} userId={userId} />
      </Modal>
    </>
  );
};

const TitleContainer = styled(FlexBoxBase)`
  align-items: center;
`;

const EditIcon = styled(Icon)`
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding-right: 16px;
`;

const DataContainer = styled(FlexBoxBase)`
  padding-top: 32px;
`;

const ColumnContainer = styled(FlexBoxBase)`
  flex-direction: column;
  padding-right: 32px;
`;

const ColumnLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 2px 0;
`;

const PrimaryValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 2px 0;
`;

const SecondaryValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 2px 0;
`;
