import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { IHeaderDefinitionHeader } from "../../../models/configuration/definitions";
import { TypeDefinitions } from "../../../models/configuration/definitions/definition";
import { isDefinitionNameValid } from "../../../utils/string";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { TextField } from "../TextField/TextField";
import {
  DefinitionModal,
  IDefaultDefinitionModalProps,
} from "../DefinitionModal/DefinitionModal";
import {
  AddButton,
  AddButtonContainer,
  Field,
  FieldInput,
  FieldName,
  Remove,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderWrapper,
} from "../DefinitionModal/DefinitionModal.styled";
import { getDuplicatedHeaderNames } from "../DefinitionModal/helpers";
import { useSelectedConfiguration } from "../../../store/slices/caching/hooks";
import { HeaderDefinitionType } from "../../../store/slices/caching/types";
import { createHeaderDefinition } from "../../../store/slices/caching/helpers/header-definition/createHeaderDefinition";
import { getHeaderReferences } from "../../../store/slices/caching/helpers/header-definition/getHeaderReferences";
import { isDefinitionNameUnique } from "../../../store/slices/caching/helpers/isDefinitionNameUnique";

type HeaderModalProps = IDefaultDefinitionModalProps<HeaderDefinitionType>;

export const HeaderDefinitionModal = ({
  definition,
  parentConfig,
  onSubmit,
}: HeaderModalProps): ReactElement => {
  const selectedConfiguration = useSelectedConfiguration();
  const { t } = useTranslation("configurationDefinitionsPage");

  const [name, setName] = useState(definition?.name ?? "");
  const [description, setDescription] = useState(
    definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
  );
  const [headers, setHeaders] = useState(
    definition?.headers || [{ name: "", value: "" }]
  );
  const [error, setError] = useState("");

  const addHeader = () => {
    setHeaders((_headers) => [..._headers, { name: "", value: "" }]);
  };

  const updateHeader = (header: IHeaderDefinitionHeader, index: number) => {
    setHeaders((_headers) =>
      _headers.map((h, i) => (i === index ? header : h))
    );
  };

  const removeHeader = (index: number) => {
    setHeaders((_headers) => _headers.filter((_, i) => i !== index));
  };

  const validation = () => {
    if (
      (!definition || (definition && definition.name !== name)) &&
      selectedConfiguration &&
      selectedConfiguration.config &&
      !isDefinitionNameUnique(
        name,
        selectedConfiguration.config[TypeDefinitions.HEADER]
      )
    ) {
      setError(t("ERROR_DEFINITION_HEADER_ALREADY_EXISTS", { name }));
      return false;
    }

    if (!(headers.length > 0)) {
      setError(t("ERROR_DEFINITION_HEADER_NOT_EXISTS"));
      return false;
    }

    if (!headers.every((header) => header.name.length > 0)) {
      setError(t("ERROR_DEFINITION_HEADER_NAME_NOT_EXISTS"));
      return false;
    }

    if (!isDefinitionNameValid(name)) {
      setError(t("ERROR_DEFINITION_INVALID_NAME"));
      return false;
    }

    if (getDuplicatedHeaderNames(headers).length > 0) {
      setError(t("ERROR_DEFINITION_DUPLICATE_HEADER_NAMES"));
      return false;
    }

    return true;
  };

  const initState = () => {
    setError("");
    setName(definition?.name ?? "");
    setDescription(
      definition?._schemaDescription || t("DEFAULT_DEFINITION_DESCRIPTION")
    );
    setHeaders(definition?.headers || [{ name: "", value: "" }]);
  };

  return (
    <DefinitionModal
      header={
        <TextField
          id="headerdefinition-name"
          className="-text--h4"
          text={name || t("DEFAULT_DEFINITION_NAME")}
          dataTestId="header-modal-header"
        />
      }
      references={
        definition ? getHeaderReferences(definition, parentConfig) : 0
      }
      onCancel={initState}
      isEdit={!!definition}
      validation={validation}
      onSubmit={() => {
        if (definition) {
          onSubmit({
            ...definition,
            name,
            _schemaDescription: description,
            headers,
          });
        } else {
          if (selectedConfiguration && selectedConfiguration.config) {
            onSubmit(createHeaderDefinition(name, { description, headers }));
          }
        }

        initState();
      }}
      dataTestId="header"
    >
      <Table>
        <TableBody>
          <Field>
            <FieldName required>{t("DEFINITION_NAME_INPUT_LABEL")}</FieldName>
            <FieldInput
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder={t("DEFINITION_NAME_INPUT_PLACEHOLDER")}
              data-testid="header-modal-definition-name-input"
            />
          </Field>
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_HEADER_HEADERS_FIELD_LABEL")}
            </FieldName>
            <Table>
              <TableHeaderWrapper>
                <TableHeader required>
                  {t("EDIT_DEFINITION_HEADER_HEADER_NAME_COLUMN")}
                </TableHeader>
                <TableHeader>
                  {t("EDIT_DEFINITION_HEADER_HEADER_VALUE_COLUMN")}
                </TableHeader>
                <TableHeader></TableHeader>
              </TableHeaderWrapper>
              <TableBody>
                {headers.map((header, i) => (
                  <Field key={`row-${i}`}>
                    <TableCell rightPadding bottomPadding="thin">
                      <FieldInput
                        value={header.name}
                        placeholder={t(
                          "EDIT_DEFINITION_HEADER_HEADER_NAME_COLUMN_PLACEHOLDER"
                        )}
                        onChange={(event) =>
                          updateHeader(
                            { name: event.target.value, value: header.value },
                            i
                          )
                        }
                        data-testid={`header-header-name-field-${i}`}
                      />
                    </TableCell>
                    <TableCell leftPadding bottomPadding="thin">
                      <FieldInput
                        value={header.value || ""}
                        placeholder={t(
                          "EDIT_DEFINITION_HEADER_HEADER_VALUE_COLUMN_PLACEHOLDER"
                        )}
                        onChange={(event) =>
                          updateHeader(
                            { name: header.name, value: event.target.value },
                            i
                          )
                        }
                        data-testid={`header-header-value-field-${i}`}
                      />
                    </TableCell>
                    <TableCell centered>
                      <Remove onClick={() => removeHeader(i)} />
                    </TableCell>
                  </Field>
                ))}
              </TableBody>
              <AddButtonContainer>
                <AddButton
                  onClick={() => addHeader()}
                  data-testid="header-add-header-button"
                >
                  {t("ADD_HEADER_BUTTON")}
                </AddButton>
              </AddButtonContainer>
            </Table>
          </Field>
          <Field>
            <FieldName>
              {t("EDIT_DEFINITION_HEADER_DESCRIPTION_FIELD_LABEL")}
            </FieldName>
            <FieldInput
              value={description}
              placeholder={t(
                "EDIT_DEFINITION_HEADER_DESCRIPTION_FIELD_PLACEHOLDER"
              )}
              onChange={(event) => setDescription(event.target.value)}
              data-testid="header-modal-description-field"
            />
          </Field>
        </TableBody>
      </Table>
      {error && (
        <NotificationCard
          dataTestId="error-header-modal"
          theme="error"
          title={error}
        />
      )}
    </DefinitionModal>
  );
};
