import { Status } from "../../../@types/status";
import { APIError } from "../../../models/error";
import {
  activateCdnApiKey,
  deleteCdnApiKey,
  disableCdnApiKey,
  generateSecretCdnApiKey,
  getCdnApiKey,
  getCdnApiKeyOwners,
  getCdnApiKeyRoles,
  getCdnApiKeys,
  updateCdnApiKey,
} from "../../../models/user-management/apiKeys/cdn";
import { CdnApiKeyRequestData } from "../../../models/user-management/apiKeys/cdn/cdnApiKeys.interfaces";
import {
  getVyvxApiKeyOwners,
  activateVyvxApiKey,
  deleteVyvxApiKey,
  disableVyvxApiKey,
  generateSecretVyvxApiKey,
  getVyvxApiKey,
  getVyvxApiKeyRoles,
  getVyvxApiKeys,
  updateVyvxApiKey,
} from "../../../models/user-management/apiKeys/vyvx";
import { vyvxApiKeyRequestData } from "../../../models/user-management/apiKeys/vyvx/vyvxApiKeys.interfaces";
import { AppThunk } from "../../store";
import { handleAPIError } from "../api-error/thunks";
import {
  setCdnApiKey,
  setCdnApiKeys,
  setCdnApiKeysOwners,
  setCdnApiKeysStatus,
  setCdnApiKeyStatus,
  setCdnApiKeysRoles,
  setVyvxApiKey,
  setVyvxApiKeys,
  setVyvxApiKeysOwners,
  setVyvxApiKeysStatus,
  setVyvxApiKeyStatus,
  setVyvxApiKeysRoles,
} from "./slice";

export const fetchVyvxApiKeysRoles = (): AppThunk => async (dispatch) => {
  try {
    const data = await getVyvxApiKeyRoles();
    dispatch(setVyvxApiKeysRoles(data));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchVyvxApiKeys = (companyId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setVyvxApiKeysStatus(Status.LOADING));
    const data = await getVyvxApiKeys(companyId);
    dispatch(setVyvxApiKeys(data));
    dispatch(setVyvxApiKeysStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setVyvxApiKeysStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchVyvxApiKey = (
  companyId: string,
  apiKeyId: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(setVyvxApiKeyStatus(Status.LOADING));
    const data = await getVyvxApiKey(companyId, apiKeyId);
    dispatch(setVyvxApiKey(data));
    dispatch(setVyvxApiKeyStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setVyvxApiKeyStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchVyvxApiKeysOwners = (companyId: string): AppThunk => async (
  dispatch
) => {
  try {
    const data = await getVyvxApiKeyOwners(companyId);
    dispatch(setVyvxApiKeysOwners(data));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleGenerateSecretVyvxApiKey = (
  apiKeyId: number,
  companyId: string,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await generateSecretVyvxApiKey(companyId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleActivateVyvxApiKey = (
  apiKeyId: number,
  companyId: string,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await activateVyvxApiKey(companyId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleDisableVyvxApiKey = (
  apiKeyId: number,
  companyId: string,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await disableVyvxApiKey(companyId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleDeleteVyvxApiKey = (
  apiKeyId: number,
  companyId: string,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await deleteVyvxApiKey(companyId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleUpdateVyvxApiKey = (
  companyId: string,
  apiKeyId: number,
  body: vyvxApiKeyRequestData
): AppThunk => async (dispatch) => {
  try {
    const data = await updateVyvxApiKey(companyId, apiKeyId, body);
    dispatch(setVyvxApiKey(data));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchCdnApiKeys = (accessGroupId: number): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setCdnApiKeysStatus(Status.LOADING));
    const data = await getCdnApiKeys(accessGroupId);
    dispatch(setCdnApiKeys(data));
    dispatch(setCdnApiKeysStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setCdnApiKeysStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchCdnApiKey = (
  accessGroupId: number,
  apiKeyId: number
): AppThunk => async (dispatch) => {
  try {
    dispatch(setCdnApiKeyStatus(Status.LOADING));
    const data = await getCdnApiKey(accessGroupId, apiKeyId);
    dispatch(setCdnApiKey(data));
    dispatch(setCdnApiKeyStatus(Status.SUCCESS));
  } catch (err) {
    dispatch(setCdnApiKeyStatus(Status.ERROR));
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleGenerateSecretCdnApiKey = (
  apiKeyId: number,
  accessGroupId: number,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await generateSecretCdnApiKey(accessGroupId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleActivateCdnApiKey = (
  apiKeyId: number,
  accessGroupId: number,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await activateCdnApiKey(accessGroupId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleDisableCdnApiKey = (
  apiKeyId: number,
  accessGroupId: number,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await disableCdnApiKey(accessGroupId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleDeleteCdnApiKey = (
  apiKeyId: number,
  accessGroupId: number,
  cb: () => void
): AppThunk => async (dispatch) => {
  try {
    await deleteCdnApiKey(accessGroupId, apiKeyId);
    cb();
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchCdnApiKeysOwners = (
  accessGroupId: number
): AppThunk => async (dispatch) => {
  try {
    const data = await getCdnApiKeyOwners(accessGroupId);
    dispatch(setCdnApiKeysOwners(data));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const fetchCdnApiKeysRoles = (): AppThunk => async (dispatch) => {
  try {
    const data = await getCdnApiKeyRoles();
    dispatch(setCdnApiKeysRoles(data));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};

export const handleUpdateCdnApiKey = (
  apiKeyId: number,
  accessGroupId: number,
  body: CdnApiKeyRequestData
): AppThunk => async (dispatch) => {
  try {
    const data = await updateCdnApiKey(accessGroupId, apiKeyId, body);
    dispatch(setCdnApiKey(data));
  } catch (err) {
    dispatch(handleAPIError(err as APIError));
  }
};
