import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { AccessGroupsTable } from "./AccessGroupsTable";
import { PageContainer } from "../../../../components/atoms/PageContainer/PageContainer";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";

export const AccessGroups = (): JSX.Element => {
  const { t } = useTranslation("accessGroupsPage");

  return (
    <Container>
      <ExpandableContent
        title={t("ACCESS_GROUPS_PAGE_DESCRIPTION_EXPAND_TITLE")}
        content={t("ACCESS_GROUPS_PAGE_DESCRIPTION_EXPAND_CONTENT")}
      />
      <AccessGroupsTable />
    </Container>
  );
};

const Container = styled(PageContainer)`
  background-color: ${({ theme }) => `${theme.backgrounds.baseLight} `};
  min-height: 100%;
  padding-top: 16px;
`;
