import { AxiosError } from "axios";

import { httpClient } from "../../core/http-client";
import { ErrorLevel, CMv3APIError, APIError } from "../error";
import { UserRoles } from "../permissions";
import { AccessGroup, IScidWithAliases } from "./user.interfaces";

export interface UserData {
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  actualTokenExpirationMillis: number;
  countBusOrgMappingForCdnInvoice: number;
  credentialsNonExpired: boolean;
  authorities: { authority: UserRoles }[];
  domain: string;
  email: string;
  enabled: boolean;
  firstName: string;
  itmCookie: null;
  itmNetwork: null;
  lastName: string;
  ldapUsername: string;
  mvnsEnabled: boolean;
  opsPortalToken: null;
  password: string;
  preferredTimeZone: null;
  preferredTimeZoneDisplay: null;
  productList: string[];
  selfServiceCategory: null;
  sessionTimeoutSeconds: null;
  termsOfUseDate: number;
  userId: number;
  username: string;
  originalAuthenticatedUsername: string | null;
  originalAuthenticatedFirstname: string | null;
  vyvxFiberCompanyAbbr: string | null;
  vyvxUserType: string | null;
}

export interface User extends UserData {
  isMimick: boolean;
}

export const initUser = (userData: UserData): User => {
  return {
    accountNonExpired: userData.accountNonExpired,
    accountNonLocked: userData.accountNonLocked,
    authorities: userData.authorities,
    actualTokenExpirationMillis: userData.actualTokenExpirationMillis,
    countBusOrgMappingForCdnInvoice: userData.countBusOrgMappingForCdnInvoice,
    credentialsNonExpired: userData.credentialsNonExpired,
    domain: userData.domain,
    email: userData.email,
    enabled: userData.enabled,
    firstName: userData.firstName,
    itmCookie: userData.itmCookie,
    itmNetwork: userData.itmNetwork,
    lastName: userData.lastName,
    ldapUsername: userData.ldapUsername,
    mvnsEnabled: userData.mvnsEnabled,
    opsPortalToken: userData.opsPortalToken,
    password: userData.password,
    preferredTimeZone: userData.preferredTimeZone,
    preferredTimeZoneDisplay: userData.preferredTimeZoneDisplay,
    productList: userData.productList,
    selfServiceCategory: userData.selfServiceCategory,
    sessionTimeoutSeconds: userData.sessionTimeoutSeconds,
    termsOfUseDate: userData.termsOfUseDate,
    userId: userData.userId,
    username: userData.username,
    originalAuthenticatedUsername: userData.originalAuthenticatedUsername,
    originalAuthenticatedFirstname: userData.originalAuthenticatedFirstname,
    vyvxFiberCompanyAbbr: userData.vyvxFiberCompanyAbbr,
    vyvxUserType: userData.vyvxUserType,
    isMimick: userData.originalAuthenticatedUsername !== null,
  };
};

export const getAccessGroups = async (): Promise<AccessGroup[]> => {
  try {
    const { data } = await httpClient.get<AccessGroup[]>("/accessgroup/list");
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getAccessGroups()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const getScidsWithAliases = async (
  accessGroupId: number
): Promise<IScidWithAliases[] | undefined> => {
  try {
    const { data } = await httpClient.get<IScidWithAliases[]>(
      `/accessGroups/${accessGroupId}/aliases`
    );
    return data;
  } catch (err) {
    const error = err as AxiosError<CMv3APIError>;
    throw new APIError(
      error.message,
      "getScidsWithAliases()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const enterUserMimick = async (
  loggedInUser: User,
  newUsername: string,
  refresh_token: string
): Promise<{ access_token: string; refresh_token: string }> => {
  if (loggedInUser.isMimick) {
    throw new APIError(
      "Already impersonating, cannot mimicking another user.",
      "enterUserMimick",
      ErrorLevel.ERROR,
      undefined,
      false
    );
  }

  try {
    const { data } = await httpClient.post<
      { username: string; refresh_token: string },
      { access_token: string; refresh_token: string }
    >("/auth/user/impersonate/switch", {
      username: newUsername,
      refresh_token,
    });

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new APIError(
      error.message,
      "enterUserMimick()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};

export const exitUserMimick = async (
  loggedInUser: User,
  refresh_token: string
): Promise<{ access_token: string; refresh_token: string }> => {
  if (!loggedInUser.isMimick) {
    throw new APIError(
      "No user impersonated, cannot stop mimicking.",
      "Exiting Mimick User",
      ErrorLevel.ERROR
    );
  }

  try {
    const { data } = await httpClient.post<
      { refresh_token: string },
      { access_token: string; refresh_token: string }
    >("/auth/user/impersonate/exit", { refresh_token });

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new APIError(
      error.message,
      "exitUserMimick()",
      ErrorLevel.ERROR,
      error.response
    );
  }
};
