import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { animated, config, useSpring } from "react-spring";
import { useIsMounted } from "../../../hooks/use-is-mounted";

import { SubHeaderDropdownOption } from "./SubHeaderDropdownOption";
import { ISubHeaderOption } from "./types";
import { Icon, IconProps, Icons } from "../../atoms/Icon/Icon";

export enum SubHeaderDropdownState {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

interface SubHeaderDropdownProps<T> {
  searchPlaceholder: string;
  options?: ISubHeaderOption<T>[];
  open: boolean;
  state: SubHeaderDropdownState;
  onSearch: (
    search: string,
    options: ISubHeaderOption<T>[]
  ) => ISubHeaderOption<T>[];
  onSelect: (option: ISubHeaderOption<T>) => void;
  marginLeft?: string;
}

export const SubHeaderDropdown = <T extends unknown>({
  searchPlaceholder,
  options,
  onSelect,
  open,
  state,
  onSearch,
  marginLeft,
}: SubHeaderDropdownProps<T>): ReactElement => {
  const isMounted = useIsMounted();
  const [search, setSearch] = useState<string>("");
  const [dropdownOptions, setDropdownOptions] = useState<ISubHeaderOption<T>[]>(
    options || []
  );
  const [selectedValue, setSelectedValue] = useState<ISubHeaderOption<T>>();

  const style = useSpring({
    config: { ...config.stiff, mass: 0.5 },
    opacity: open ? 1 : 0,
    height: open ? "260px" : "0",
    display: open ? "block" : "none",
  });

  useEffect(() => {
    if (search && options) {
      setDropdownOptions(onSearch(search, options));
    } else {
      setDropdownOptions(options || []);
    }
  }, [options, search, onSearch]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => isMounted.current && setSelectedValue(undefined), 500);
      setSearch("");
    }
  }, [open]);

  return (
    <DropdownContainer className="chi_dropdown">
      <DropwdownContent style={style} state={state} marginLeft={marginLeft}>
        {selectedValue ? (
          <Container>
            <SelectedOption>{selectedValue.text}</SelectedOption>
          </Container>
        ) : (
          <>
            <SearchBar className="chi-input__wrapper -icon--left" role="search">
              <SearchInput
                className="chi-input"
                type="text"
                placeholder={searchPlaceholder}
                aria-label="Search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              <SearchIcon />
            </SearchBar>
            {dropdownOptions !== undefined && dropdownOptions.length > 0 ? (
              <OptionsList>
                {dropdownOptions.map((option, index) => (
                  <SubHeaderDropdownOption
                    key={`subheader-dropdown-${index}`}
                    option={option}
                    onClick={(value) => {
                      setSelectedValue(value);
                      onSelect(value);
                    }}
                    state={state}
                  />
                ))}
              </OptionsList>
            ) : (
              options !== undefined && (
                <SubHeaderDropdownOption
                  option={{
                    text: "No matching patterns found",
                    value: undefined,
                  }}
                  onClick={() => {}}
                  state={state}
                  isDefault
                />
              )
            )}
          </>
        )}
      </DropwdownContent>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div``;

const DropwdownContent = styled(animated.div)<{
  state: SubHeaderDropdownState;
  marginLeft?: string;
}>`
  position: absolute;
  width: ${({ state }) =>
    state === SubHeaderDropdownState.SECONDARY ? "750px" : "232px"};
  z-index: 10;
  margin-top: 8px;
  margin-left: ${({ state, marginLeft }) =>
    marginLeft || (state === SubHeaderDropdownState.SECONDARY && "232px")};
  border-left: ${({ state, theme }) =>
    state === SubHeaderDropdownState.SECONDARY &&
    `solid 1px ${theme.borders.mutedLight}`};
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  box-shadow: 0 5px 50px -35px;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  width: 100%;
`;

const SelectedOption = styled.span`
  color: ${({ theme }) => `${theme.text.highlight} !important`};
  background-color: ${({ theme }) => theme.backgrounds.highlightLight};
  border-left: solid 2px ${({ theme }) => theme.borders.highlight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 24px;
  width: 96%;
  margin: 4px;
`;

const OptionsList = styled(animated.div)`
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  overflow-y: scroll;
  min-height: 220px;
  max-height: 440px;
`;

const SearchBar = styled.div`
  height: 40px;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  color: ${({ theme }) => `${theme.text.primary} !important`};
`;

const SearchInput = styled.input`
  border: none !important;
  border-bottom: solid 1px ${({ theme }) => theme.borders.mutedLight} !important;
  height: 40px !important;
  line-height: 1em;
  font-size: large;
  background: transparent;
  color: ${({ theme }) => `${theme.text.primary} !important`};
`;

const SearchIcon = (props: Omit<IconProps, "name">) => (
  <SearchIconBase {...props} name={Icons.SEARCH} className="chi-icon" />
);

const SearchIconBase = styled(Icon)`
  margin-left: 4px;
  margin-top: 4px;
  path {
    fill: #8e9399;
  }
`;
