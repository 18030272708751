import { ReactElement, useCallback, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

interface TextContentProps {
  content: string;
}

export const TextContent = ({ content }: TextContentProps): ReactElement => {
  const { t } = useTranslation("misc");
  const [displayAll, setDisplayAll] = useState(false);
  const [hasSeeMore, setHasSeeMore] = useState<boolean>(false);

  const pRef = useRef<HTMLParagraphElement>(null);

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      // 5 is the max number of lines to clamp and 20 is 1.25rem in px which is the line-height of .chi p tag
      setHasSeeMore(node.offsetHeight >= 5 * 20);
    }
  }, []);

  return (
    <ContentContainer>
      <StyledContentDisplay
        hasSeeMore={hasSeeMore}
        displayAll={displayAll}
        ref={measuredRef}
      >
        {content}
      </StyledContentDisplay>
      {hasSeeMore && (
        <DisplayButton
          onClick={() => {
            if (displayAll) {
              if (pRef.current) pRef.current.scrollTop = 0;
              setDisplayAll(false);
            } else {
              setDisplayAll(true);
            }
          }}
        >
          {t(displayAll ? "SEE_LESS" : "SEE_MORE")}
        </DisplayButton>
      )}
    </ContentContainer>
  );
};

const ContentContainer = styled.div``;

const StyledContentDisplay = styled.p<{
  displayAll: boolean;
  hasSeeMore: boolean;
}>`
  ${({ hasSeeMore }) =>
    hasSeeMore &&
    css`
      max-height: 40vh;
    `}
  overflow-wrap: break-word;
  white-space: break-spaces;
  ${({ displayAll, hasSeeMore }) =>
    hasSeeMore &&
    (displayAll
      ? css`
          overflow-y: scroll;
        `
      : css`
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        `)}
`;

const DisplayButton = styled.a`
  margin: 5px 0px !important;
  color: ${({ theme }) => theme.text.highlight};
  cursor: pointer;
`;
