import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  BlockingType,
  CustomLocation,
} from "../../../../../../components/atoms/BlockingNavModal/BlockingNavModal";
import { MatchBlocks } from "../../../../../../models/configuration/definitions/matchlogic";
import { ConfigurationErrorLevel } from "../../../../../../models/configuration/errors";
import { ErrorLevel, APIError } from "../../../../../../models/error";
import { setPropertyName } from "../../../../../../store/slices/caching/helpers/setPropertyName";
import {
  useConfigurationsErrors,
  useProperties,
  useSelectedConfiguration,
} from "../../../../../../store/slices/caching/hooks";
import {
  handleUpdateConfigurationErrors,
  handleUpdateDefinition,
} from "../../../../../../store/slices/caching/thunks";
import {
  ConfigurationDefinition,
  MatchLogicDefinitionType,
  OriginDefinitionType,
  PropertyDefinitionType,
} from "../../../../../../store/slices/caching/types";
import { useAppDispatch } from "../../../../../../store/types";
import { cloneDefinition } from "../../../../../../utils/object";
import { computeDiff, getErrorsToAdd } from "./helpers";
import { GetErrorsToAddParams } from "./types";

interface IUsePropertyHeaderReturnType {
  onSave: (...params: any[]) => void;
  handleTransition: (loc: CustomLocation) => boolean | BlockingType;
}

export const usePropertyHeader = (
  propertyName: string,
  _property: PropertyDefinitionType | undefined,
  _currentMatchLogic: MatchLogicDefinitionType | undefined,
  _originDefinition: OriginDefinitionType | undefined,
  setProperty: (param: PropertyDefinitionType) => void
): IUsePropertyHeaderReturnType => {
  const errors = useConfigurationsErrors();
  const selectedConfiguration = useSelectedConfiguration();
  const dispatch = useAppDispatch();

  const properties = useProperties();

  const [t] = useTranslation("configurationPropertyPage");
  const [tMisc] = useTranslation("misc");

  const history = useHistory();

  const propertyHasErrors = useCallback(
    ({
      property,
      originDefinition,
      currentMatchLogic,
    }: GetErrorsToAddParams) => {
      let hasErrors = errors.some(
        (err) => err.level === ConfigurationErrorLevel.ERROR
      );

      const errorsToAdd = getErrorsToAdd({
        currentMatchLogic,
        originDefinition,
        property,
      });

      if (errorsToAdd.length > 0) {
        dispatch(handleUpdateConfigurationErrors(errorsToAdd));
        hasErrors = true;
      }

      return hasErrors;
    },
    [_property, _currentMatchLogic, _originDefinition, errors]
  );

  const getDefsToUpdate = ({
    property,
    originDefinition,
    currentMatchLogic,
  }: GetErrorsToAddParams): ConfigurationDefinition[] | false => {
    const defToUpdate: ConfigurationDefinition[] = [];

    if (property) {
      if (
        propertyHasErrors({
          property,
          originDefinition,
          currentMatchLogic,
        })
      ) {
        toast.error(t("ERROR_INCORRECT_FIELDS"), {
          toastId: "property-save-error-incorrect-fields",
        });
        return false;
      } else {
        const updatedProperty = { ...property };
        if (originDefinition) {
          updatedProperty.originFillPolicy = originDefinition.name;
        }
        defToUpdate.push(
          cloneDefinition(updatedProperty) as ConfigurationDefinition
        );
      }
    }
    if (currentMatchLogic) {
      defToUpdate.push(
        cloneDefinition(currentMatchLogic) as ConfigurationDefinition
      );
    }
    if (originDefinition) {
      defToUpdate.push(
        cloneDefinition(originDefinition) as ConfigurationDefinition
      );
      if (selectedConfiguration?.config) {
        const originDefintionIndex = selectedConfiguration.config.originFillPolicyDefinitions.findIndex(
          (def) => def.id === originDefinition.id
        );

        if (originDefintionIndex !== -1) {
          dispatch(
            handleUpdateDefinition(
              selectedConfiguration.config.originFillPolicyDefinitions.map(
                (originFillPolicyDefinition, i) =>
                  i === originDefintionIndex
                    ? {
                        ...originFillPolicyDefinition,
                        name: originDefinition.name,
                      }
                    : originFillPolicyDefinition
              )
            )
          );
        }
      }
    }

    return defToUpdate;
  };

  const savePropertyCallback = useCallback(
    (
      displayToast = true,
      values = {
        property: _property,
        originDefinition: _originDefinition,
        currentMatchLogic: _currentMatchLogic,
      }
    ) => {
      const defToUpdate = getDefsToUpdate(values);

      if (defToUpdate === false) {
        return false;
      }

      if (defToUpdate.length) {
        dispatch(handleUpdateDefinition(defToUpdate));
        if (displayToast) {
          toast.success(tMisc("SUCCESS_CHANGES_SAVED_LOCALLY"), {
            toastId: "property-save-success",
          });
        }
      }
      return true;
    },
    [_property, _currentMatchLogic, _originDefinition, errors]
  );

  const renamePropertyCallback = useCallback(
    (event: CustomEvent<{ name: string; primaryAlias?: string }>) => {
      const { name: newName, primaryAlias } = event.detail;
      if (savePropertyCallback()) {
        try {
          if (_property) {
            const newProperty = {
              ..._property,
              name: setPropertyName(
                _property,
                selectedConfiguration!.config!,
                newName,
                properties
              ),
            };

            if (primaryAlias) {
              newProperty.primaryAlias = primaryAlias;
            }

            dispatch(handleUpdateDefinition(newProperty));
            setProperty(newProperty);

            history.push(
              `/cmv3/configurations/${selectedConfiguration?.configName}/properties/${newName}`
            );
          }
        } catch (err: any) {
          const error = err as APIError;
          if (error.errorLevel === ErrorLevel.WARNING) {
            toast.warn(error.message, {
              autoClose: false,
              hideProgressBar: true,
              progress: undefined,
            });
          } else {
            toast.error(error.message, {
              autoClose: false,
              hideProgressBar: true,
              progress: undefined,
            });
          }
        }
      }
    },
    [savePropertyCallback, properties, selectedConfiguration, _property]
  );

  const navbackCallback = useCallback(() => {
    history.push(
      `/cmv3/configurations/${selectedConfiguration?.configName}/properties`
    );
  }, [selectedConfiguration]);

  const handleTransition = useCallback(
    (loc: CustomLocation) => {
      // double check if changes circles to initial property state
      const prop = selectedConfiguration?.config?.propertyDefinitions?.find(
        (p) => p.id === _property?.id
      );
      const matchLogic =
        selectedConfiguration?.config?.matchLogicDefinitions[
          selectedConfiguration?.config?.matchLogicDefinitions.findIndex(
            (mld) => mld.name === prop?.matchLogic
          )
        ];
      const foundDefinition = selectedConfiguration?.config?.originFillPolicyDefinitions.find(
        (od) => od.name === _property?.originFillPolicy
      );
      const diff = computeDiff(
        _property,
        prop,
        _currentMatchLogic,
        matchLogic,
        _originDefinition,
        foundDefinition
      );
      if (loc.pathname.includes("match_rules")) {
        if (
          diff.includes(MatchBlocks.CLIENT_REQ) ||
          diff.includes(MatchBlocks.ORIGIN_REQ) ||
          diff.includes(MatchBlocks.ORIGIN_RESP)
        ) {
          return BlockingType.BLOCK_LEAVING;
        }
      }
      return diff.length !== 0;
    },
    [
      selectedConfiguration,
      _property,
      _currentMatchLogic,
      _originDefinition,
      propertyName,
      propertyHasErrors,
    ]
  );

  useEffect(() => {
    document.addEventListener("save_config_event", savePropertyCallback);
    document.addEventListener("navback_property_event", navbackCallback);
    document.addEventListener(
      "rename_property_event",
      renamePropertyCallback as EventListener
    );
    return () => {
      document.removeEventListener("save_config_event", savePropertyCallback);
      document.removeEventListener("navback_property_event", navbackCallback);
      document.removeEventListener(
        "rename_property_event",
        renamePropertyCallback as EventListener
      );
    };
  }, [navbackCallback, savePropertyCallback]);

  return {
    onSave: savePropertyCallback,
    handleTransition,
  };
};
