import styled from "@emotion/styled";
import format from "date-fns-tz/format/index.js";
import { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Badge } from "../../../../components/atoms/Badge/Badge";
import { Button } from "../../../../components/atoms/Button/Button";
import { FilterMultiSelectDropdownItem } from "../../../../components/atoms/FilterMultiSelectDropdownOption/FilterMultiSelectDropdownOption";
import { FlexBox } from "../../../../components/atoms/FlexBox/FlexBox";
import {
  ColumnDescription,
  HeadlessDataTable,
} from "../../../../components/atoms/HeadlessDataTable/HeadlessDataTable";
import { Icon, Icons } from "../../../../components/atoms/Icon/Icon";
import { InfiniteScrollContainer } from "../../../../components/atoms/InfiniteScrollContainer/InfiniteScrollContainer";
import { LabelValueDropdownButton } from "../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import {
  UserManagementTable,
  UserManagementTableBodyRow,
  UserManagementTableHeaderCell,
  UserManagementTableHeaderRow,
} from "../../../../components/atoms/UserManagementTable/UserManagementTable";
import { SearchBar } from "../../../../components/molecules/SearchBar/SearchBar";
import { Dropdown } from "../../../../components/organisms/Dropdown/Dropdown";
import { FieldsSelectionDropdown } from "../../../../components/organisms/FieldsSelectionDropdown/FieldsSelectionDropdown";
import { FilterMultiSelectDropdown } from "../../../../components/organisms/FilterMultiSelectDropdown/FilterMultiSelectDropdown";
import { UserStatus } from "../../../../models/user-management/users/users.interfaces";
import { useHasCdn } from "../../../../store/slices/permissions/hooks";
import { useUsers } from "../../../../store/slices/user-management-users/hooks";
import { useUserVyvxType } from "../../../../store/slices/user/hooks";
import { enterMimick } from "../../../../store/slices/user/thunks";
import { useAppDispatch } from "../../../../store/types";
import {
  buildRolesDropdownItems,
  computeDateDifference,
  processUsers,
} from "./helpers";
import { ProcessedUsers, statusBadgeColors, UserType } from "./types";
import { UserActionDropdown } from "./user/UserActionDropdown";

enum Fields {
  TYPE = "type",
  STATUS = "status",
  ACCESS_GROUP = "accessGroup",
  FIBER_COMPANY = "fiberCompany",
  SATELLITE_COMPANY = "satelliteCompany",
  ROLE = "role",
  CREATION_DATE = "creationDate",
  LAST_LOGIN = "lastLogin",
}

export const UsersTable = (): ReactElement => {
  const { t } = useTranslation("usersPage");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<UserStatus>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<UserType>();
  const [tableData, setTableData] = useState<ProcessedUsers[]>([]);
  const dispatch = useAppDispatch();

  const users = useUsers();
  const hasCdn = useHasCdn();
  const hasVyvx = !!useUserVyvxType();

  // If the user has only Vyvx, we show Vyvx, otherwise we show CDN
  const showVyvx = hasVyvx && !hasCdn;

  const [selectedFields, setSelectedFields] = useState<Fields[]>(
    [Fields.TYPE, Fields.STATUS]
      .concat(
        showVyvx
          ? [Fields.FIBER_COMPANY, Fields.SATELLITE_COMPANY]
          : [Fields.ACCESS_GROUP]
      )
      .concat([Fields.ROLE, Fields.CREATION_DATE, Fields.LAST_LOGIN])
  );

  const processedUsers = useMemo(
    () =>
      processUsers(
        searchValue,
        selectedRoles,
        selectedStatus,
        selectedType
      )(users),
    [users, searchValue, selectedStatus, selectedRoles, selectedType]
  );

  const roleDropdownItems: FilterMultiSelectDropdownItem<string>[] = buildRolesDropdownItems(
    Array.from(
      new Set(
        users
          .map((user) =>
            user.accessGroups.map((group) => group.roleName.trim())
          )
          .flat()
      )
    ).filter((role) => role !== ""),
    Array.from(
      new Set(
        users
          .filter((user) => user.vyvx?.role)
          .map((user) => user.vyvx!.role!.trim())
      )
    ),
    selectedRoles,
    t("ROLES_DROPDOWN_CDN_LABEL"),
    t("ROLES_DROPDOWN_VYVX_LABEL")
  );

  const statusDropdownItems = [
    {
      label: t("STATUS_DROPDOWN_ALL"),
      value: undefined,
      default: true,
    },
    {
      label: t("STATUS_DROPDOWN_ACTIVE"),
      value: UserStatus.ACTIVE,
    },
    {
      label: t("STATUS_DROPDOWN_DISABLED"),
      value: UserStatus.DISABLED,
    },
    {
      label: t("STATUS_DROPDOWN_EXPIRED"),
      value: UserStatus.EXPIRED,
    },
    {
      label: t("STATUS_DROPDOWN_INACTIVE"),
      value: UserStatus.INACTIVE,
    },
    {
      label: t("STATUS_DROPDOWN_LOCKED"),
      value: UserStatus.LOCKED,
    },
    {
      label: t("STATUS_DROPDOWN_PENDING"),
      value: UserStatus.PENDING,
    },
  ];

  const typeDropdownItems = [
    {
      label: t("TYPE_DROPDOWN_ALL"),
      value: undefined,
      default: true,
    },
    {
      label: t("TYPE_DROPDOWN_CDN"),
      value: UserType.CDN,
    },
    {
      label: t("TYPE_DROPDOWN_VYVX"),
      value: UserType.VYVX,
    },
  ];

  const fieldsDropdownItemsMap = new Map<Fields, string>([
    [Fields.TYPE, t("USERS_TABLE_TYPE_LABEL")],
    [Fields.STATUS, t("USERS_TABLE_STATUS_LABEL")],
    [Fields.ACCESS_GROUP, t("USERS_TABLE_ACCESS_GROUP_LABEL")],
    [Fields.FIBER_COMPANY, t("USERS_TABLE_FIBER_COMPANY_LABEL")],
    [Fields.SATELLITE_COMPANY, t("USERS_TABLE_SATELLITE_COMPANY_LABEL")],
    [Fields.ROLE, t("USERS_TABLE_ROLE_LABEL")],
    [Fields.CREATION_DATE, t("USERS_TABLE_CREATED_AT_LABEL")],
    [Fields.LAST_LOGIN, t("USERS_TABLE_LAST_LOGIN_LABEL")],
  ]);

  const handleCopyEmail = (email: string) => () => {
    navigator.clipboard.writeText(email).then(() => {
      toast.success(t("COPY_EMAIL_MESSAGE", { email }));
    });
  };

  const fields = new Map<Fields, ColumnDescription<ProcessedUsers>>([
    [
      Fields.TYPE,
      {
        name: t("USERS_TABLE_TYPE_LABEL"),
        formatter: (row: ProcessedUsers) => {
          if (row.accessGroups.length > 0 && row.vyvxRole) {
            return t("USERS_TABLE_TYPE_CDN_AND_VYVX");
          } else if (row.vyvxRole) {
            return t("USERS_TABLE_TYPE_VYVX");
          } else if (row.accessGroups.length > 0) {
            return t("USERS_TABLE_TYPE_CDN");
          } else {
            return "";
          }
        },
        style: { width: "10%" },
      },
    ],
    [
      Fields.STATUS,
      {
        dataField: "status" as keyof ProcessedUsers,
        name: t("USERS_TABLE_STATUS_LABEL"),
        formatter: (row: ProcessedUsers) => (
          <Badge
            label={row.status[0].toUpperCase() + row.status.substring(1)}
            color={statusBadgeColors.get(row.status)}
          />
        ),
        style: { width: "10%" },
      },
    ],
    [
      Fields.ACCESS_GROUP,
      {
        dataField: "accessGroups" as keyof ProcessedUsers,
        name: t("USERS_TABLE_ACCESS_GROUP_LABEL"),
        formatter: (row: ProcessedUsers) => (
          <AccessGroupLabel>
            {row.parentAccessGroup.name +
              (row.accessGroupsCounter > 1
                ? " " +
                  t("USERS_TABLE_ADDITIONAL_GROUP", {
                    counter: row.accessGroupsCounter - 1,
                  })
                : "")}
          </AccessGroupLabel>
        ),
        style: { width: "13%" },
      },
    ],
    [
      Fields.FIBER_COMPANY,
      {
        dataField: "fiberCompanyName" as keyof ProcessedUsers,
        name: t("USERS_TABLE_FIBER_COMPANY_LABEL"),
        formatter: (row: ProcessedUsers) => row.fiberCompanyName,
        style: { width: "10%" },
      },
    ],
    [
      Fields.SATELLITE_COMPANY,
      {
        dataField: "satelliteCompanyName" as keyof ProcessedUsers,
        name: t("USERS_TABLE_SATELLITE_COMPANY_LABEL"),
        formatter: (row: ProcessedUsers) => row.satelliteCompanyName,
        style: { width: "10%" },
      },
    ],
    [
      Fields.ROLE,
      {
        name: t("USERS_TABLE_ROLE_LABEL"),
        formatter: (row: ProcessedUsers) => (
          <RoleLabel>
            {(row.parentAccessGroup.roleName || row.vyvxRole) +
              (row.rolesCounter > 1
                ? " " +
                  t("USERS_TABLE_ADDITIONAL_GROUP", {
                    counter: row.rolesCounter - 1,
                  })
                : "")}
          </RoleLabel>
        ),
        style: { width: "15%" },
      },
    ],
    [
      Fields.CREATION_DATE,
      {
        dataField: "createdAt" as keyof ProcessedUsers,
        name: t("USERS_TABLE_CREATED_AT_LABEL"),
        formatter: (row: ProcessedUsers) => {
          if (row.createdAt === "") return "";
          return format(Date.parse(row.createdAt), "MMM do, yyyy");
        },
        style: { width: "10%" },
      },
    ],
    [
      Fields.LAST_LOGIN,
      {
        dataField: "lastLogin" as keyof ProcessedUsers,
        name: t("USERS_TABLE_LAST_LOGIN_LABEL"),
        formatter: (row: ProcessedUsers) => {
          if (row.lastLogin === "") return "";
          const { dateValue, dateLabel } = computeDateDifference(
            Date.parse(row.lastLogin)
          );
          return t("LAST_LOGIN", {
            value: dateValue,
            label: dateLabel,
          });
        },
        style: { width: "10%" },
      },
    ],
  ]);

  return (
    <>
      <Line>
        <SearchBarContainer>
          <SearchBar
            placeholder={t("SEARCH_BAR_PLACEHOLDER")}
            onChange={setSearchValue}
          />
        </SearchBarContainer>
        <ButtonsContainer>
          <FieldsDropdownContainer>
            <FieldsSelectionDropdown
              items={Array.from(fieldsDropdownItemsMap.entries()).map(
                ([key, value]) => ({
                  label: value,
                  value: key,
                })
              )}
              selectedItems={selectedFields.map((field) => ({
                label: fieldsDropdownItemsMap.get(field)!,
                value: field,
              }))}
              onChange={(values) =>
                // We need to do this to keep the same order for the fields
                setSelectedFields(
                  Array.from(fieldsDropdownItemsMap.keys()).filter(
                    (key) => values.find((v) => v.value === key) !== undefined
                  )
                )
              }
            />
          </FieldsDropdownContainer>
          <ExportButton
            label={t("EXPORT_BUTTON_LABEL")}
            onClick={() => {}}
            icon={{ name: Icons.FILE_DOWNLOAD }}
            backgroundColor="baseLight"
            textColor="primary"
            borderColor="mutedLight"
          />
          <AddButton label={t("ADD_BUTTON_LABEL")} onClick={() => {}} />
        </ButtonsContainer>
      </Line>
      <FilterMultiSelectDropdown
        button={
          <LabelValueDropdownButton
            placeholder={t("ROLES_DROPDOWN_PLACEHOLDER_LABEL")}
            value={
              selectedRoles.length === 0
                ? ""
                : selectedRoles.length === 1
                ? selectedRoles[0]
                : t("ROLES_DROPDOWN_COUNTER", { counter: selectedRoles.length })
            }
          />
        }
        applyButtonLabel={t("ROLES_DROPDOWN_APPLY_LABEL")}
        searchPlaceholder={t("ROLES_DROPDOWN_SEARCH_PLACEHOLDER")}
        items={roleDropdownItems}
        onFilter={(selectedItems) => {
          const newItems: string[] = [];
          selectedItems.forEach((item) => {
            if (item.subItems) {
              item.subItems.forEach((i) => {
                newItems.push(i.value);
              });
            }
          });
          setSelectedRoles(newItems);
        }}
        hasSelectAll
      />
      <Dropdown<UserStatus | undefined>
        customButton={
          <LabelValueDropdownButton
            placeholder={t("STATUS_DROPDOWN_LABEL")}
            value={selectedStatus}
          />
        }
        id="users-status-dropdown"
        placeholder=""
        items={statusDropdownItems}
        onSelect={(status) => {
          setSelectedStatus(status.value);
        }}
      />
      <Dropdown<UserType | undefined>
        customButton={
          <LabelValueDropdownButton
            placeholder={t("USER_TYPE_DROPDOWN_LABEL")}
            value={selectedType}
          />
        }
        id="users-type-dropdown"
        placeholder=""
        items={typeDropdownItems}
        onSelect={(type) => {
          setSelectedType(type.value);
        }}
      />
      <InfiniteScrollContainer
        data={processedUsers}
        pageSize={100}
        onUpdateData={setTableData}
        scrollableTarget="users-table-container"
      >
        <TableContainer id="users-table-container">
          <HeadlessDataTable
            data={tableData}
            columns={[
              {
                dataField: "fullName",
                name: t("USERS_TABLE_NAME_LABEL"),
                formatter: (row) => (
                  <DataContainer>
                    <Name>{row.fullName}</Name>
                    <IconContainer>
                      <MimicIcon
                        name={Icons.ARROW_RIGHT_TO_BRACKET}
                        onClick={() => dispatch(enterMimick(row.userName))}
                      />
                    </IconContainer>
                  </DataContainer>
                ),
                style: { width: "15%" },
              },
              {
                dataField: "userName",
                name: t("USERS_TABLE_EMAIL_LABEL"),
                formatter: (row) => (
                  <DataContainer>
                    <Email>
                      {row.userName.replace(
                        "@",
                        "@" + String.fromCharCode(8203)
                      )}
                    </Email>
                    <IconContainer>
                      <Icon
                        name={Icons.COPY_OUTLINE}
                        color="secondary"
                        onClick={handleCopyEmail(row.userName)}
                      />
                    </IconContainer>
                  </DataContainer>
                ),
                style: { width: "20%" },
              },
              ...selectedFields.map((field) => fields.get(field)!),
              {
                name: "",
                formatter: (row) => (
                  <ActionsContainer>
                    <UserActionDropdown
                      userInfo={row.originalData}
                      canShowDetails
                    />
                  </ActionsContainer>
                ),
                style: { width: "5%" },
              },
            ]}
            keyField="userId"
            TableWrapper={UserManagementTable}
            TableHeadRow={StyledHeaderTr}
            TableHeadRowCell={UserManagementTableHeaderCell}
            TableBodyRow={UserManagementTableBodyRow}
            TableBodyRowCell={StyledBodyCell}
          />
        </TableContainer>
      </InfiniteScrollContainer>
    </>
  );
};

const Line = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
`;

const SearchBarContainer = styled.div`
  width: 75%;
`;

const ButtonsContainer = styled(FlexBox)`
  padding-left: 16px;
`;

const FieldsDropdownContainer = styled.div`
  padding-right: 16px;
`;

const ExportButton = styled(Button)`
  padding: 12px 16px;
  margin-right: 16px;
  border-color: ${({ theme }) => theme.borders.highlight};
  color: ${({ theme }) => theme.text.highlight};
`;

const AddButton = styled(Button)`
  padding: 12px 16px;
`;

const DataContainer = styled(FlexBox)`
  justify-content: flex-start;
`;

const Name = styled.div`
  padding-right: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const IconContainer = styled.div`
  min-width: 16px;
`;

const Email = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-right: 4px;
`;

const MimicIcon = styled(Icon)`
  cursor: pointer;
`;

const ActionsContainer = styled(FlexBox)`
  justify-content: flex-end;
`;

const AccessGroupLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.secondary};
`;

const RoleLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.text.secondary};
`;

const StyledHeaderTr = styled(UserManagementTableHeaderRow)`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
`;

const StyledBodyCell = styled.td`
  padding-top: 16px !important;
  padding-bottom: 16px;
`;

const TableContainer = styled.div`
  height: 70vh;
  overflow-y: auto;
`;
