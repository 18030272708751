import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated, config, useTransition } from "react-spring";

import { Button, ButtonProps } from "../../atoms/Button/Button";
import { Icon, IconProps, Icons } from "../../atoms/Icon/Icon";
import { CustomRemark } from "../../atoms/CustomRemark/CustomRemark";
import { ColorSet } from "../../../contexts/themeContext";

export interface HelpIconButtonProps {
  helpMessage?: string;
  disabled?: boolean;
  color?: keyof ColorSet["icon"] | keyof ColorSet["colors"];
  cta?: ButtonProps[];
  position?: "top" | "bottom";
}

export const HelpIconButton = ({
  helpMessage,
  disabled = false,
  color,
  cta,
  position = "top",
}: HelpIconButtonProps): ReactElement => {
  const iconRef = useRef<HTMLButtonElement>(null);
  const helpMessageRef = useRef<HTMLDivElement>(null);

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [messagePosition, setMessagePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const handleClick = useCallback(
    (e: any) => {
      if (
        helpMessageRef.current?.contains(e.target) ||
        iconRef.current?.contains(e.target)
      ) {
        return;
      }
      setShowMessage(false);
    },
    [setShowMessage, helpMessageRef, iconRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);

  const opening = useTransition(showMessage, {
    config: config.gentle,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    if (iconRef.current && helpMessageRef.current) {
      setMessagePosition({
        x:
          iconRef.current.offsetLeft +
          iconRef.current.clientWidth / 2 -
          helpMessageRef.current.clientWidth / 2,
        y:
          position === "bottom"
            ? iconRef.current.offsetTop + 15 * 2
            : iconRef.current.offsetTop -
              helpMessageRef.current.clientHeight -
              15,
      });
    }
  }, [
    iconRef,
    iconRef.current,
    helpMessageRef,
    helpMessageRef.current,
    helpMessage,
  ]);

  return (
    <>
      <div className="chi-label__help">
        <HelpButton
          ref={iconRef}
          className={`chi-button -icon -sm -flat ${disabled && "-disabled"}`}
          id="example__help-button"
          aria-label="Help"
          data-target="#example__help-popover"
          onClick={() => {
            setShowMessage(!showMessage);
          }}
          disabled={disabled}
        >
          <InfoIcon
            color={color}
            className="chi-icon icon-circle-info-outline"
            name={Icons.INFO_OUTLINE}
          />
        </HelpButton>
      </div>
      {opening(
        (styles, show) =>
          show && (
            <Section
              id="help-popover"
              messagePosition={messagePosition}
              ref={helpMessageRef}
              style={styles}
              position={position}
            >
              <HelpMessageContainer className="-px--2">
                <HelpMessage>
                  {!!helpMessage && <CustomRemark>{helpMessage}</CustomRemark>}
                </HelpMessage>
                {cta && (
                  <CTAContainer>
                    {cta.map((button) => (
                      <Button key={button.label} {...button} />
                    ))}
                  </CTAContainer>
                )}
              </HelpMessageContainer>
            </Section>
          )
      )}
    </>
  );
};

const Section = styled(animated.section)<{
  messagePosition: { x: number; y: number };
  position: "top" | "bottom";
}>`
  position: absolute;
  min-width: 200px;
  max-width: 500px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ messagePosition }) =>
    css`
      left: ${messagePosition.x}px;
      top: ${messagePosition.y}px;
    `}

  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
  border-radius: 0.25rem;
  background-color: white;
  padding-bottom: 0.75rem;

  ::after {
    content: "";
    border-left-color: transparent;
    border-top-color: transparent;
    display: block;
    height: 0.75rem;
    margin: 0;
    position: absolute;
    width: 0.75rem;

    transform: rotate(45deg);
    border: 0.375rem solid #fff;
    ${({ position }) =>
      position === "top"
        ? css`
            bottom: -0.375rem;
            box-shadow: 3px 3px 3px 0 rgb(0 0 0 / 12%);
          `
        : css`
            top: -0.375rem;
          `}
  }
`;

const HelpMessage = styled.p`
  color: #242526;
`;

const HelpMessageContainer = styled.div``;

const HelpButton = styled.button<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 50%;
    `}
`;

const InfoIcon = styled<FC<Omit<IconProps, "color">>>(Icon)<{
  color?: keyof ColorSet["icon"] | keyof ColorSet["colors"];
}>`
  ${({ color, theme }) => {
    const colors = { ...theme.icon, ...theme.colors };
    return (
      color !== undefined &&
      css`
        * {
          fill: ${colors[color]} !important;
        }
      `
    );
  }}
`;

const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
