import { useEffect, useState } from "react";

/**
 * This hook can be used to force a component just to re-render on window
 * resized or scrolled.
 */
export const useReRenderOnSizeChangeOrScroll = () => {
  const [sizeChanged, setSizeChanged] = useState(0);

  useEffect(() => {
    const setSize = () => {
      setSizeChanged(Math.random());
    };

    window.addEventListener("resize", setSize);
    window.addEventListener("scroll", setSize);

    return () => {
      window.removeEventListener("resize", setSize);
      window.removeEventListener("scroll", setSize);
    };
  }, []);

  return [sizeChanged, setSizeChanged] as const;
};
