import { FC } from "react";
import styled from "@emotion/styled";
// eslint-disable-next-line import/named
import { CustomTypeOptions, useTranslation } from "react-i18next";

import { DeploymentState } from "../../../models/configuration/production";
import { Badge } from "../../atoms/Badge/Badge";
import { ColorSet } from "../../../contexts/themeContext";

export interface IProductionHeaderProps {
  deploymentState: DeploymentState;
}

const LOCALIZED_DEPLOYMENT_STATES: Record<
  DeploymentState,
  keyof CustomTypeOptions["resources"]["productionPage"]
> = {
  complete: "DEPLOYMENT_STATE_COMPLETE",
  inProgress: "DEPLOYMENT_STATE_IN_PROGRESS",
  error: "DEPLOYMENT_STATE_ERROR",
  migration: "DEPLOYMENT_STATE_MIGRATION",
  preparing: "DEPLOYMENT_STATE_PREPARING",
  undeployed: "DEPLOYMENT_STATE_UNDEPLOYED",
};

const PRODUCTION_BADGE_COLORS: { [key in string]: keyof ColorSet["colors"] } = {
  undeployed: "grey40",
  preparing: "navy80",
  inProgress: "green50",
  complete: "green60",
  migration: "pink50",
  error: "red50",
};

export const ProductionHeader: FC<IProductionHeaderProps> = ({
  deploymentState = DeploymentState.UNDEPLOYED,
}) => {
  const { t } = useTranslation("productionPage");

  return (
    <TitleContainer data-testid="production-deployment-card">
      <Title>{t("PRODUCTION_SLOTS_TITLE")}</Title>
      <BadgeContainer>
        <Label>{t("PRODUCTION_DEPLOYMENT_CARD_TITLE")}</Label>
        <Badge
          label={t(LOCALIZED_DEPLOYMENT_STATES[deploymentState])}
          color={PRODUCTION_BADGE_COLORS[deploymentState]}
        />
      </BadgeContainer>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: 0 !important;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  margin: 0 !important;
  padding-right: 8px;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.secondary};
`;
