import {
  ConditionTypes,
  Operators,
  CONDITIONS_TYPES_DICTIONNARY,
  OPERATORS_DICTIONNARY,
} from "./dictionnaries";

export function setNewExpression(
  conditionType: ConditionTypes,
  objectName: string,
  operator: Operators,
  newValue: string,
  operatorSensitive: boolean,
  englishOperator: boolean
): string {
  const newComponent = CONDITIONS_TYPES_DICTIONNARY.get(
    conditionType
  )!.cooked.replace("%headerName", objectName);

  const newOperator = OPERATORS_DICTIONNARY.get(operator)![
    englishOperator
      ? operatorSensitive
        ? "englishOperatorSensitive"
        : "englishOperatorInsensitive"
      : operatorSensitive
      ? "sensitive"
      : "insensitive"
  ];

  return `${newComponent} ${newOperator} ${newValue}`;
}
