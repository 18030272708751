import { CertificateResponseType, CertificateType } from "../types";

export const parseCertificate = ({
  aliases,
  auto_renew,
  cert_bundle,
  cert_data,
  cert_name,
  cert_states,
  certificate,
  creation_time,
  fingerprint,
  href,
  issuer,
  modified_time,
  new_aliases,
  new_cert,
  new_cert_data,
  new_subject,
  renewal_failure_count,
  revoke_failure_count,
  serial_number,
  state,
  subject,
  transaction_id,
  type,
  validity_not_after,
  validity_not_before,
  version_id,
}: CertificateResponseType): CertificateType => ({
  aliases,
  auto_renew,
  cert_bundle,
  cert_data,
  cert_name,
  cert_states,
  certificate,
  creation_time,
  fingerprint,
  href,
  issuer,
  modified_time,
  new_aliases,
  new_cert,
  new_cert_data,
  new_subject,
  renewal_failure_count,
  revoke_failure_count,
  serial_number,
  state,
  subject,
  transaction_id,
  type,
  validity_not_after,
  validity_not_before,
  version_id,
});
