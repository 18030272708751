import { ComponentProps, ReactElement } from "react";
import styled from "@emotion/styled";

import { Icon, Icons } from "../Icon/Icon";

type EditIconProps = Omit<
  ComponentProps<typeof EditIconBase>,
  "name" | "color"
>;

export const EditIcon = (props: EditIconProps): ReactElement => (
  <EditIconBase {...props} name={Icons.EDIT} color="secondary" />
);

const EditIconBase = styled(Icon)`
  margin-left: 16px;
`;
