import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { FlexBox } from "../../../../components/atoms/FlexBox/FlexBox";
import { LabelValueDropdownButton } from "../../../../components/atoms/LabelValueDropdownButton/LabelValueDropdownButton";
import { Modal } from "../../../../components/atoms/Modal/Modal";
import { SearchBar } from "../../../../components/molecules/SearchBar/SearchBar";
import { Dropdown } from "../../../../components/organisms/Dropdown/Dropdown";
import { useAccessGroups } from "../../../../store/slices/access-groups/hooks";
import { ChildAccessGroupModal } from "./ChildAccessGroupModal";
import { DateFilter } from "./types";

export interface AccessGroupTableFilterProps {
  onSearchInputChange: (value: string) => void;
  creationDateFilter: DateFilter;
  onCreationDateFilterChange: (value: DateFilter) => void;
}

export const AccessGroupTableFilter = ({
  onSearchInputChange,
  creationDateFilter,
  onCreationDateFilterChange,
}: AccessGroupTableFilterProps): JSX.Element => {
  const accessGroups = useAccessGroups();

  const { t } = useTranslation("accessGroupsPage");

  const creationDateDropdownItemsMap: Map<DateFilter, string> = new Map([
    [DateFilter.ALL_TIME, t("CREATION_DATE_FILTER_ALL_TIME_LABEL")],
    [DateFilter.LAST_24_HOURS, t("CREATION_DATE_FILTER_LATS_24_HOURS_LABEL")],
    [DateFilter.LAST_7_DAYS, t("CREATION_DATE_FILTER_LATS_7_DAYS_LABEL")],
    [DateFilter.LAST_30_DAYS, t("CREATION_DATE_FILTER_LATS_30_DAYS_LABEL")],
  ]);

  return (
    <Container>
      <div style={{ width: "60%" }}>
        <SearchBar
          placeholder={t("ACCESS_GROUPS_TABLE_SEARCH_PLACEHOLDER")}
          onChange={onSearchInputChange}
        />
      </div>
      <Part2>
        <Dropdown<DateFilter>
          id="creation-date-selection"
          customButton={
            <LabelValueDropdownButton
              placeholder="Creation date"
              // TODO: fix type issue and remove undefined option
              value={creationDateDropdownItemsMap.get(creationDateFilter) || ""}
            />
          }
          items={Array.from(creationDateDropdownItemsMap, ([value, label]) => ({
            value,
            label,
          }))}
          placeholder={t("ACCESS_GROUPS_TABLE_CREATION_DATE_PLACEHOLDER")}
          onSelect={(item) => {
            onCreationDateFilterChange(item.value);
          }}
        />
        <Modal
          openButtonProps={{
            label: t("ACCESS_GROUPS_CREATE_CHILD_BUTTON"),
          }}
          size="large"
        >
          <ChildAccessGroupModal
            current={{
              name: "",
              description: "",
              id: 0,
            }}
            parent={accessGroups[0]}
            domain={accessGroups[0]}
          />
        </Modal>
      </Part2>
    </Container>
  );
};

const Container = styled(FlexBox)`
  justify-content: start;
`;

const Part2 = styled(FlexBox)`
  justify-content: space-between;
`;
