import { Renderer, EditorInstance } from "./types";

const getMaxTop = (renderer: Renderer) =>
  renderer.layerConfig.maxHeight -
  renderer.$size.scrollerHeight +
  renderer.scrollMargin.bottom;

const getScrollRatio = (scrollTop: number, maxTop: number) =>
  scrollTop / maxTop;

const getNewPosition = (maxTop: number, scrollRatio: number) =>
  maxTop * scrollRatio;

const setNewScrollPosition = (
  editorToSet: EditorInstance,
  editorToGet: EditorInstance,
  scrollTop: number
): void => {
  editorToSet
    .getSession()
    .setScrollTop(
      getNewPosition(
        getMaxTop(editorToSet.renderer),
        getScrollRatio(scrollTop, getMaxTop(editorToGet.renderer))
      )
    );
};

export const handleNewScrollPosition = (
  editorToSet: EditorInstance,
  editorToGet: EditorInstance,
  scrollTop: number
): void => {
  if (editorToSet.renderer.$vScroll) {
    setNewScrollPosition(editorToSet, editorToGet, scrollTop);
  }
};

export const setDataTestID = (editor: EditorInstance, testID: string): void => {
  editor.container.setAttribute("data-testid", testID);
};
