import { TypeDefinitions } from "../../../../../models/configuration/definitions/definition";
import { ReportingOverrideDefinitionType } from "../../types";
import { parseDefinition } from "../parseDefinition";

export const createReportingOverrideDefinition = (
  name: string,
  rest: Pick<ReportingOverrideDefinitionType, "description" | "reportingName">
): ReportingOverrideDefinitionType => ({
  ...parseDefinition({
    name,
    description: rest.description,
    type: TypeDefinitions.REPORTING_OVERRIDE,
  }),
  reportingName: rest.reportingName,
});
