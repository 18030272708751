import { FC } from "react";
import styled from "@emotion/styled";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { DefinitionsPage } from "./definitions/definitions_page";
import { MatchRulesPage } from "./match_rules/match_rules_page";
import { Properties } from "./properties";
import { Property } from "./properties/property_page";
import { RawJson } from "./properties/property_page/RawJson";
import { Versions } from "./versions";
import { useSelectedConfiguration } from "../../../../store/slices/caching/hooks";
import { Configuration } from "../../../../components/layers/Configuration/Configuration";

enum CMV3Modules {
  CONFIGURATIONS = "configurations",
  CERTIFICATES = "certificates",
  PRODUCTION = "production",
  ENVIRONMENT_HISTORY = "environment_history",
  SUBSCRIBER_SETTINGS = "subscriber_settings",
}

export const ConfigurationPageRouter: FC = () => {
  const selectedConfiguration = useSelectedConfiguration();

  const match = useRouteMatch<{
    version: string;
    module: CMV3Modules;
    name: string;
    submodule: string;
    subname: string;
  }>([
    "/:version/:module/:name/:submodule/:subname",
    "/:version/:module/:name/:submodule",
    "/:version/:module/:name",
  ]);

  const propertyName = match?.params.subname;

  return (
    <Configuration>
      <Container>
        <Switch>
          <Route path="/cmv3/configurations/:configName/properties/:propertyName/match_rules">
            <MatchRulesPage propertyName={propertyName} />
          </Route>
          <Route path="/cmv3/configurations/:configName/properties/:propertyName/raw-json">
            <RawJson
              propertyName={propertyName}
              versions={selectedConfiguration?.versions}
            />
          </Route>
          <Route path="/cmv3/configurations/:configName/properties/:propertyName">
            <Property propertyName={propertyName || "Error"} />
          </Route>

          <Route path="/cmv3/configurations/:configName/raw-json">
            <RawJson versions={selectedConfiguration?.versions} showSearchBar />
          </Route>
          <Route path="/cmv3/configurations/:configName/properties">
            <Properties />
          </Route>
          <Route path="/cmv3/configurations/:configName/versions">
            <Versions />
          </Route>
          <Route path="/cmv3/configurations/:configName/definitions">
            <DefinitionsPage />
          </Route>
        </Switch>
      </Container>
    </Configuration>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
