import styled from "@emotion/styled";

import { Center } from "../Center/Center";

export const Card = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.backgrounds.baseLight};
  border: 1px solid ${({ theme }) => theme.borders.mutedLight};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const CardHeader = styled(Center)`
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CardTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const CardBody = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
`;

export const WidgetCard = styled(Card)`
  max-width: 50%;
  flex-grow: 1;
  flex-basis: 0;
`;
