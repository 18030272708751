import { useAppSelector } from "../../types";
import { UserManagementUsersSlice } from "./types";

export const useUsers = (): UserManagementUsersSlice["users"] =>
  useAppSelector((store) => store.userManagementUsers.users);

export const useUserManagementUsersListStatus = (): UserManagementUsersSlice["usersListStatus"] =>
  useAppSelector((store) => store.userManagementUsers.usersListStatus);

export const useUser = (): UserManagementUsersSlice["user"] =>
  useAppSelector((store) => store.userManagementUsers.user);

export const useUserManagementUserStatus = (): UserManagementUsersSlice["userStatus"] =>
  useAppSelector((store) => store.userManagementUsers.userStatus);
