import styled from "@emotion/styled";
import { Fragment, ReactNode } from "react";
import type { TFunction } from "react-i18next";
import { useHistory } from "react-router-dom";

import { AddButton } from "../../../../../../../../components/atoms/AddButton/AddButton";
import { EditOrViewIcon } from "../../../../../../../../components/atoms/EditOrViewIcon/EditOrViewIcon";
import {
  Icon,
  Icons,
} from "../../../../../../../../components/atoms/Icon/Icon";
import { Protected } from "../../../../../../../../components/atoms/Protected/Protected";
import { DetailedFeatureCard } from "../../../../../../../../components/organisms/DetailedFeatureCard/DetailedFeatureCard";
import { DragAndDropList } from "../../../../../../../../components/organisms/DragAndDropList/DragAndDropList";
import { IItem } from "../../../../../../../../components/organisms/DragAndDropList/useDragAndDrop";
import {
  Dropdown,
  IDropdownItem,
} from "../../../../../../../../components/organisms/Dropdown/Dropdown";
import {
  IMatchGroup,
  IMatchRule,
} from "../../../../../../../../models/configuration/definitions";
import { IFeatures } from "../../../../../../../../models/configuration/definitions/matchlogic";
import { UserRoles } from "../../../../../../../../models/permissions";
import { FEATURE_NAMES } from "./constants";
import { parseConditions, reduceOperators } from "./helpers";
import {
  ExpressionContainer,
  MatchRuleConditionText,
  MatchRuleExpressionText,
  MatchRuleFeatureText,
} from "./styles";
import { UseMatchBlockItemsParams } from "./types";
import { AddButtonContainer } from "./useMatchBlockItems";
import { FOCUS_FEATURE_QUERY } from "../../../../constants";

interface RenderMatchRuleOptions
  extends Pick<
    UseMatchBlockItemsParams,
    | "dataTestId"
    | "matchBlockName"
    | "isCompact"
    | "fromMatchRulePath"
    | "setDeleteMatchRule"
    | "location"
  > {
  history: ReturnType<typeof useHistory>;
  isViewMode: boolean;
  groupIndex: number;
  tMatchRulesPage: TFunction<"configurationMatchRulesPage">;
  t: TFunction<"configurationPropertyPage">;
  tMisc: TFunction<"misc">;
  group: IMatchGroup;
  parentPath?: string;
  preIndexes: string;
  onChange: (parentPath: string, mr: IMatchRule[]) => void;
  onAdd: (parentPath: string) => void;
  isLatestVersionConfig: boolean;
}

const MAX_DEPTH_SUB_RULES = 7;

export const renderMatchRule = (options: RenderMatchRuleOptions) => (
  rule: IMatchRule,
  ruleIndex: number,
  matchRules: IMatchRule[]
): IItem => {
  const {
    dataTestId,
    fromMatchRulePath,
    group,
    groupIndex,
    history,
    isCompact,
    isViewMode,
    matchBlockName,
    setDeleteMatchRule,
    t,
    tMisc,
    tMatchRulesPage,
    parentPath,
    onChange,
    preIndexes,
    onAdd,
    isLatestVersionConfig,
    location,
  } = options;
  const title: ReactNode[] = [];
  const rulePath = parentPath ? `${parentPath}-${ruleIndex}` : `${ruleIndex}`;

  if (rule.expression) {
    const result: Record<"expression" | "operator", string>[] = [];
    parseConditions(rule.expression, result);
    reduceOperators(result);

    title.push(
      <ExpressionContainer>
        <div>
          <MatchRuleConditionText>
            {ruleIndex === 0 ? "If" : "Else if"}
          </MatchRuleConditionText>
          {result.map(({ expression, operator }, i) => (
            <Fragment key={expression + operator + i}>
              {i > 0 && <br />}
              <MatchRuleExpressionText>
                <b>{`${operator} `}</b>
                {expression}
              </MatchRuleExpressionText>
            </Fragment>
          ))}
        </div>
        <EditOrViewIcon
          isViewMode={isViewMode}
          onClick={() => {
            history.push(
              `${location.pathname}/match_rules?matchBlock=${matchBlockName}&matchGroup=${groupIndex}&matchRule=${rulePath}&matchRulelocation=conditions`
            );
          }}
          dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-edit-0`}
        />
      </ExpressionContainer>
    );
  } else {
    title.push(
      <ExpressionContainer>
        <div>No condition</div>
        <EditOrViewIcon
          isViewMode={isViewMode}
          onClick={() => {
            history.push(
              `${location.pathname}/match_rules?matchBlock=${matchBlockName}&matchGroup=${groupIndex}&matchRule=${rulePath}&matchRulelocation=conditions`
            );
          }}
          dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-edit-0`}
        />
      </ExpressionContainer>
    );
  }

  if (rule.features) {
    const featureKeys = (Object.keys(
      rule.features
    ) as (keyof IFeatures)[]).filter((key) => rule.features[key] !== undefined);

    const navigateToFeatures = (focusFeature?: keyof IFeatures) => () => {
      history.push(
        `${location.pathname}/match_rules?` +
          `matchBlock=${matchBlockName}` +
          `&matchGroup=${groupIndex}` +
          `&matchRule=${rulePath}` +
          "&matchRulelocation=features" +
          `&${FOCUS_FEATURE_QUERY}=${focusFeature}`
      );
    };

    if (featureKeys.length > 0) {
      title.push(
        <>
          <MatchRuleConditionText>
            {rule.expression
              ? "Then"
              : matchRules.length === 1
              ? "Always Apply"
              : "Else"}
          </MatchRuleConditionText>
          {isCompact ? (
            <>
              <MatchRuleFeatureText>
                {featureKeys.join(", ")}
              </MatchRuleFeatureText>
              <EditOrViewIcon
                isViewMode={isViewMode}
                onClick={() => {
                  history.push(
                    `${location.pathname}/match_rules?matchBlock=${matchBlockName}&matchGroup=${groupIndex}&matchRule=${rulePath}&matchRulelocation=features`
                  );
                }}
                dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-edit-1`}
              />
            </>
          ) : (
            <div>
              {FEATURE_NAMES.reduce<JSX.Element[]>((features, featureName) => {
                const feature = rule.features[featureName];

                if (
                  typeof feature !== "undefined" &&
                  (featureName !== "cacheKey" || isLatestVersionConfig)
                ) {
                  features.push(
                    <DetailedFeatureCard
                      key={featureName}
                      name={featureName}
                      value={feature}
                      isViewMode={isViewMode}
                      isCompact={isCompact}
                      tMatchRulesPage={tMatchRulesPage}
                      tPropertyPage={t}
                      onClick={navigateToFeatures(featureName)}
                      dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-edit-1-${featureName}`}
                    />
                  );
                }

                return features;
              }, [])}

              <Protected permissions={UserRoles.EDIT_CONFIG}>
                <AddButtonContainer>
                  <AddButton
                    onClick={() => {
                      history.push(
                        `${location.pathname}/match_rules?matchBlock=${matchBlockName}&matchGroup=${groupIndex}&matchRule=${rulePath}&matchRulelocation=features&newFeaturePopup=true`
                      );
                    }}
                    label="Add feature"
                    dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-add-feature`}
                  />
                </AddButtonContainer>
              </Protected>
            </div>
          )}
        </>
      );
    } else {
      title.push(
        <ExpressionContainer>
          <div>No features</div>
          <EditOrViewIcon
            isViewMode={isViewMode}
            onClick={navigateToFeatures()}
            dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-edit-1`}
          />
        </ExpressionContainer>
      );
    }
  }

  const canAddSubRule =
    isLatestVersionConfig && rulePath.split("-").length < MAX_DEPTH_SUB_RULES;

  return {
    highlight:
      fromMatchRulePath?.matchBlock === matchBlockName &&
      fromMatchRulePath?.matchGroup === groupIndex &&
      fromMatchRulePath?.matchRule === rulePath,
    title,
    content:
      isLatestVersionConfig && rule.matchGroups ? (
        <DragAndDropList
          isChild
          items={rule.matchGroups.reduce((items, mg, mgIndex) => {
            const _matchRules = mg.matchRules.map(
              renderMatchRule({
                ...options,
                parentPath: `${rulePath}-${mgIndex}`,
                preIndexes: preIndexes
                  ? `${preIndexes}-${ruleIndex}`
                  : `${ruleIndex}`,
              })
            );

            return items.concat(_matchRules);
          }, [] as IItem<IMatchRule>[])}
          onOrderChange={(newList) => {
            // Must have timeout because of drag and drop animation
            setTimeout(() => {
              onChange(
                newList[0].context.parentPath!,
                newList.map((data) => data.context.obj)
              );
            }, 1000);
          }}
          dataTestId={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}`}
        />
      ) : (
        rule.description || ""
      ),
    context: { index: ruleIndex, obj: rule, parentPath },
    index: preIndexes
      ? `${preIndexes}-${ruleIndex}`
          .split("-")
          .reduce(
            (prev, curr, i) =>
              i === 0 ? `${Number(curr) + 1}` : `${prev}.${Number(curr) + 1}`,
            ""
          )
      : `${ruleIndex + 1}.`,
    nestedMenu: (callback) => (
      <Protected permissions={UserRoles.EDIT_CONFIG}>
        <Dropdown
          items={
            [
              {
                label: tMisc("DELETE"),
                value: "delete",
                dataTestId: `${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-delete`,
              },
              canAddSubRule && {
                label: "Add sub-rule",
                value: "add",
                dataTestId: `${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-add`,
              },
            ].filter(Boolean) as IDropdownItem<string>[]
          }
          id={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-actions`}
          placeholder=""
          onSelect={({ value }) => {
            if (value === "delete") {
              setDeleteMatchRule({
                group,
                groupIndex,
                ruleIndex: 0,
                callback,
                rulePath,
              });
            } else {
              if (rulePath) {
                onAdd(rulePath);
              }
            }
          }}
          customButton={
            <DropdownButtonContainer
              data-testid={`${dataTestId}-match-group-${groupIndex}-match-rule-${rulePath}-dropdown`}
            >
              <Options
                name={Icons.TRIPLE_DOT}
                size={{ width: 18, height: 5 }}
              />
            </DropdownButtonContainer>
          }
        />
      </Protected>
    ),
  };
};

export const DropdownButtonContainer = styled.div`
  height: 1rem;
  display: flex;
  align-items: center;
`;

export const Options = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.icon.secondary};
  }
  cursor: pointer;
`;
