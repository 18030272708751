// eslint-disable-next-line import/named
import { CustomTypeOptions } from "react-i18next";
import {
  EncryptionLevels,
  HttpsConfig,
  IProtocolSetting,
  IPropertyDefinition,
  ServiceTypes,
} from "../../../../../../../../models/configuration/definitions/property";
import { PropertyDefinitionType } from "../../../../../../../../store/slices/caching/types";

export const isInvalidBasicHttpsConfig = <
  Obj extends Pick<IPropertyDefinition, "protocolSettings">
>(
  obj: Obj
): boolean =>
  obj.protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC &&
  !obj.protocolSettings.httpsConfig?.http2;

export const isValidBasicHttpsConfig = <
  Obj extends Pick<IPropertyDefinition, "protocolSettings">
>(
  obj: Obj
): boolean | undefined =>
  obj.protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC &&
  !obj.protocolSettings.httpsConfig?.http2;

export const isInvalidBasicHttp2Config = <
  Obj extends Pick<IPropertyDefinition, "protocolSettings">
>(
  obj: Obj
): boolean | undefined =>
  obj.protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC &&
  obj.protocolSettings.httpsConfig?.http2;

export const isValidBasicHttp2Config = <
  Obj extends Pick<IPropertyDefinition, "protocolSettings">
>(
  obj: Obj
): boolean | undefined =>
  obj.protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC &&
  obj.protocolSettings.httpsConfig?.http2;

export const getInvalidBasicHttp2EncryptConfigTranslate = <
  Obj extends IPropertyDefinition["protocolSettings"]
>(
  protocolSettings: Obj
): keyof CustomTypeOptions["resources"]["configurationPropertyPage"] =>
  protocolSettings.httpsConfig!.encryptionLevel === EncryptionLevels.DEFAULT
    ? "ERROR_PRIMARY_ALIAS_INVALID_ENCRYPTION_LEVEL_DEFAULT"
    : "ERROR_PRIMARY_ALIAS_INVALID_ENCRYPTION_LEVEL_RISKY";

export const isInvalidBasicHttp2EncryptConfig = <
  Obj extends IPropertyDefinition["protocolSettings"]
>(
  protocolSettings: Obj
): boolean | undefined =>
  protocolSettings.httpsConfig?.serviceType === ServiceTypes.BASIC &&
  protocolSettings.httpsConfig?.http2 &&
  protocolSettings.httpsConfig?.encryptionLevel !== EncryptionLevels.ADVANCED;

export const autoFillAlias = <
  Obj extends Pick<IPropertyDefinition, "protocolSettings">
>(
  obj: Obj,
  alias: string | undefined
): string | undefined => {
  if (alias) {
    if (isInvalidBasicHttpsConfig(obj)) {
      alias = alias
        .replaceAll(".secure2.footprint.net", "")
        .replaceAll(".secure.footprint.net", "")
        .concat(".secure.footprint.net");
    } else if (isInvalidBasicHttp2Config(obj)) {
      alias = alias
        .replaceAll(".secure2.footprint.net", "")
        .replaceAll(".secure.footprint.net", "")
        .concat(".secure2.footprint.net");
    }
  }

  return alias;
};

export const getAliasAutoFill = <
  Property extends Pick<IPropertyDefinition, "protocolSettings">
>(
  property: Property
): string | undefined => {
  if (isValidBasicHttpsConfig(property)) {
    return ".secure.footprint.net";
  }

  if (isValidBasicHttp2Config(property)) {
    return ".secure2.footprint.net";
  }

  return;
};

export const getAliasWithoutAutoFill = (alias?: string): string | undefined => {
  if (!alias) {
    return alias;
  } else {
    const secureIndex = alias.indexOf(".secure.footprint.net");

    if (secureIndex > 0) {
      return alias.slice(0, secureIndex);
    }

    const secure2Index = alias?.indexOf(".secure2.footprint.net");

    if (secure2Index > 0) {
      return alias.slice(0, secure2Index);
    }

    return alias;
  }
};

export const getUpdatedProperty = <
  Obj extends Pick<IPropertyDefinition, "protocolSettings" | "aliases">
>(
  property: Obj,
  protocolSettings: IProtocolSetting
): Obj => {
  const shouldDeleteAliasesAutoFills =
    protocolSettings.httpsConfig!.serviceType === ServiceTypes.SNI;

  return {
    ...property,
    protocolSettings,
    aliases: property.aliases
      ? shouldDeleteAliasesAutoFills
        ? (property.aliases.map(getAliasWithoutAutoFill) as string[])
        : (property.aliases.map((alias) =>
            autoFillAlias({ protocolSettings }, alias)
          ) as string[])
      : property.aliases,
  };
};

export const getUpdatedPropertyWithPrimaryAlias = (
  property: PropertyDefinitionType,
  protocolSettings: IProtocolSetting
): PropertyDefinitionType => {
  const shouldDeleteAliasesAutoFills =
    protocolSettings.httpsConfig!.serviceType === ServiceTypes.SNI;

  return {
    ...property,
    primaryAlias: shouldDeleteAliasesAutoFills
      ? getAliasWithoutAutoFill(property.primaryAlias)
      : autoFillAlias(property, property.primaryAlias),
  };
};

export const setProtocolSettingsServiceType = <
  Obj extends IPropertyDefinition["protocolSettings"]
>(
  protocolSettings: Obj,
  httpsConfig: Partial<HttpsConfig>
): Obj => ({
  ...protocolSettings,
  httpsConfig: { ...protocolSettings.httpsConfig, ...httpsConfig },
});
