import { ConfigurationDetailsType, HeaderDefinitionType } from "../../types";
import { getMatchRules } from "../getMatchRules";

export const getHeaderReferences = (
  def: HeaderDefinitionType,
  config: ConfigurationDetailsType
): number =>
  getMatchRules(config)
    .map((matchRule) => matchRule?.features)
    .map((feature) => feature?.reqHeaders)
    .filter((reqHeader) => reqHeader?.nameList.includes(def.name)).length;
