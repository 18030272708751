import { FC, useContext } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { ColorSet, ThemeContext } from "../../../contexts/themeContext";
import { RegionColor } from "../../../modules/rtm/components/templates/Locations/types";

import { Region } from "../../../modules/rtm/models/models";

const geoUrl = "/countries.json";

const MAP_REGIONS: Record<string, Region> = {
  "Australia and New Zealand": Region.AsiaPacific,
  Caribbean: Region.SouthAmerica,
  "Central America": Region.LatinAmerica,
  "Central Asia": Region.AsiaPacific,
  "Eastern Africa": Region.Africa,
  "Eastern Asia": Region.AsiaPacific,
  "Eastern Europe": Region.Europe,
  Melanesia: Region.AsiaPacific,
  "Middle Africa": Region.Africa,
  "Northern Africa": Region.Africa,
  "Northern America": Region.NorthAmerica,
  "Northern Europe": Region.Europe,
  "South America": Region.SouthAmerica,
  "South-Eastern Asia": Region.AsiaPacific,
  "Southern Africa": Region.Africa,
  "Southern Asia": Region.AsiaPacific,
  "Southern Europe": Region.Europe,
  "Western Africa": Region.Africa,
  "Western Asia": Region.MiddleEast,
  "Western Europe": Region.Europe,
};

const REGION_COLOR_DEFAULT = "grey20";

interface IWorldMapProps {
  regionColors: Record<Region, RegionColor>;
  selectedRegions: Region[];
}

export const WorldMap: FC<IWorldMapProps> = ({
  regionColors,
  selectedRegions,
}) => {
  const { colorset } = useContext(ThemeContext);

  return (
    <ComposableMap
      height={360}
      projection="geoMercator"
      projectionConfig={{
        scale: 60,
        rotate: [-10, 0, 0],
        parallels: [0, 20],
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.reduce((geoComponents, geo) => {
            if (geo.properties.NAME === "Antarctica") return geoComponents;

            let regionColor: keyof ColorSet["colors"] = REGION_COLOR_DEFAULT;
            const region = MAP_REGIONS[geo.properties.SUBREGION];

            if (selectedRegions.includes(region) && regionColors[region]) {
              regionColor = regionColors[region].backgroundColor;
            }

            geoComponents.push(
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={colorset.colors[regionColor]}
              />
            );

            return geoComponents;
          }, [] as JSX.Element[])
        }
      </Geographies>
    </ComposableMap>
  );
};
