import { GeneratedResponseDefinitionType } from "../../../store/slices/caching/types";

export interface IGeneratedResponseHeader {
  name: string;
  value: string;
}

export interface IGeneratedResponseDefinition {
  description?: string;
  headers?: IGeneratedResponseHeader[];
  mimeType?: string;
  payload?: string;
  reason?: string;
  statusCode: number;
}

export const getGeneratedResponseDefinitionDescription = (
  def: GeneratedResponseDefinitionType
): string =>
  def.headers
    ?.map((header) => `{ name: "${header.name}", value: "${header.value}" }`)
    .join(", ") || `Status code: ${def.statusCode}`;
