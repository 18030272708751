import { FC } from "react";

export interface ConditionalRenderProps {
  condition: boolean | (() => boolean);
  fallback?: JSX.Element;
}

export const ConditionalRender: FC<ConditionalRenderProps> = ({
  children,
  condition,
  fallback = <></>,
}) => {
  if (typeof condition === "function" ? condition() : condition) {
    return <>{children}</>;
  }
  return fallback;
};
