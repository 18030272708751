import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Status } from "../../../@types/status";
import { useUserStatus } from "../../../store/slices/user/hooks";
import { loginByAccessToken } from "../../../store/slices/user/thunks";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";

export const User: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const userStatus = useUserStatus();
  const [t] = useTranslation("layers");

  useEffect(() => {
    dispatch(loginByAccessToken());
  }, []);

  if (userStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("USER_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (userStatus === Status.SUCCESS) {
    return <>{children}</>;
  }

  if (userStatus === Status.ERROR) {
    return <ErrorLayer dataTestId="user-layer-error" />;
  }

  return null;
};
