import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
// eslint-disable-next-line import/named
import { CustomTypeOptions, TFunction, useTranslation } from "react-i18next";
import format from "date-fns/format";

import {
  Dropdown,
  IDropdownItem,
} from "../../../../components/organisms/Dropdown/Dropdown";
import { ExpandableContent } from "../../../../components/molecules/ExpandableContent/ExpandableContent";
import { State, TransactionType } from "../../../../models/configuration";
import { ITransactionHistory } from "../../../../models/configuration/configuration_details.interfaces";
import { TransactionsList } from "./TransactionsList";
import { InputField } from "../../../../components/molecules/InputField/InputField";
import { getLatestTime } from "../../utils/getLatestTime";

export interface ITransactionsProps {
  transactionHistory: ITransactionHistory[];
}

type TKeys = keyof CustomTypeOptions["resources"]["environmentHistoryPage"];

const getDisplayString = (
  transaction: ITransactionHistory,
  tfunction: TFunction<"environmentHistoryPage">
): string => {
  const latestTime = getLatestTime(transaction);

  return `${latestTime && format(new Date(latestTime), "h:mm a")} ${
    transaction.state
  } ${transaction.transactionId} ${transaction.operationData.type} ${
    transaction.operationData.slot
      ? `${tfunction("DISPLAY_STRING_PRODUCTION", {
          production:
            transaction.operationData.slot[0].toUpperCase() +
            transaction.operationData.slot.slice(1),
        })}`
      : ""
  } ${
    transaction.operationData.sourceConfigName
      ? `${transaction.operationData.sourceConfigName}`
      : ""
  } ${
    transaction.operationData.sourceConfigVersionId
      ? `(${tfunction("DISPLAY_STRING_VERSION", {
          version: transaction.operationData.sourceConfigVersionId,
        })})`
      : ""
  } ${
    transaction.operationData.percentActive
      ? `${transaction.operationData.percentActive}%`
      : ""
  }`;
};

export const Transactions: FC<ITransactionsProps> = ({
  transactionHistory,
}) => {
  const { t } = useTranslation("environmentHistoryPage");
  const [filteredHistory, setFilteredHistory] = useState<ITransactionHistory[]>(
    []
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchHistory, setSearchHistory] = useState<ITransactionHistory[]>([]);

  const defaultStateDropdownItem = {
    label: t("TRANSACTIONS_TAB_STATES_DROPDOWN_ALL"),
    value: "all",
    default: true,
  };
  const defaultTypeDropdownItem = {
    label: t("TRANSACTIONS_TAB_TYPES_DROPDOWN_ALL"),
    value: "all",
    default: true,
  };

  const stateDropdownItems = [defaultStateDropdownItem].concat(
    Object.values(State).map((state) => {
      return {
        label: t(
          `TRANSACTIONS_TAB_STATES_DROPDOWN_${state.toUpperCase()}` as TKeys
        ),
        value: state,
        default: false,
        dataTestId: `environment-history-transactions-state-dropdown-${state}`,
      };
    })
  );

  const typeDropdownItems = [defaultTypeDropdownItem].concat(
    Object.values(TransactionType).map((type) => {
      return {
        label: t(
          `TRANSACTIONS_TAB_TYPES_DROPDOWN_${type.toUpperCase()}` as TKeys
        ),
        value: type,
        default: false,
        dataTestId: `environment-history-transactions-type-dropdown-${type}`,
      };
    })
  );

  const [selectedState, setSelectedState] = useState<IDropdownItem<string>>(
    defaultStateDropdownItem
  );
  const [selectedType, setSelectedType] = useState<IDropdownItem<string>>(
    defaultTypeDropdownItem
  );

  const handleSearch = useCallback(() => {
    setFilteredHistory(
      searchHistory &&
        searchHistory.filter((transaction) => {
          if (transaction) {
            const displayString = getDisplayString(transaction, t);

            return displayString
              .toLowerCase()
              .includes(searchValue.toLowerCase().trim());
          }
        })
    );
  }, [searchValue, searchHistory]);

  useEffect(() => {
    if (selectedState.value === "all" && selectedType.value === "all") {
      setSearchHistory(transactionHistory);
    } else if (selectedState.value === "all" && selectedType.value !== "all") {
      setSearchHistory(
        transactionHistory.filter(
          (h) => h.operationData.type === selectedType.value
        )
      );
    } else if (selectedState.value !== "all" && selectedType.value === "all") {
      setSearchHistory(
        transactionHistory.filter((h) => h.state === selectedState.value)
      );
    } else {
      setSearchHistory(
        transactionHistory.filter(
          (h) =>
            h.state === selectedState.value &&
            h.operationData.type === selectedType.value
        )
      );
    }
  }, [transactionHistory, selectedState, selectedType]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <TransactionsContainer>
      <SearchBarContainer>
        <InputField
          onChange={setSearchValue}
          hasBorder={true}
          isSearchField={true}
          placeholder={t("TRANSACTIONS_TAB_SEARCH_BAR_PLACEHOLDER")}
          dataTestId="environment-history-transactions-search-input"
        />
        <DropdownContainer>
          <Dropdown
            items={stateDropdownItems}
            id="state-selector"
            placeholder={t("TRANSACTIONS_TAB_STATES_DROPDOWN_PLACEHOLDER")}
            onSelect={(item) => setSelectedState(item)}
            dataTestId="environment-history-transactions-state-dropdown"
          />
        </DropdownContainer>
        <DropdownContainer>
          <Dropdown
            items={typeDropdownItems}
            id="type-selector"
            placeholder={t("TRANSACTIONS_TAB_TYPES_DROPDOWN_PLACEHOLDER")}
            onSelect={(item) => setSelectedType(item)}
            dataTestId="environment-history-transactions-type-dropdown"
          />
        </DropdownContainer>
      </SearchBarContainer>
      <ExpandableContentContainer>
        <ExpandableContent
          title={t("TRANSACTION_VERSIONS_DESCRIPTION_TITLE")}
          content={t("TRANSACTION_VERSIONS_DESCRIPTION_CONTENT")}
        />
      </ExpandableContentContainer>
      <ScrollableListContainer>
        {filteredHistory && filteredHistory.length > 0 && (
          <TransactionsList transactionHistory={filteredHistory} />
        )}
      </ScrollableListContainer>
    </TransactionsContainer>
  );
};

const ScrollableListContainer = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`};
`;

const TransactionsContainer = styled.div``;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacings.pageContainer}`};
`;

const ExpandableContentContainer = styled.div`
  padding: ${({ theme }) => `2rem ${theme.spacings.pageContainer} 0`};
`;

const DropdownContainer = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
`;
