import { FC } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export enum Direction {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

const getStyleFromDirection = (isOpen: 0 | 1, direction: Direction) => {
  switch (direction) {
    case Direction.BOTTOM:
      return css`
        bottom: 0;
        transform: translate(-50%, ${isOpen === 1 ? "-22px" : "100%"});
      `;
    case Direction.TOP:
      return css`
        top: 0;
        transform: translate(-50%, ${isOpen === 1 ? "22px" : "-100%"});
      `;
  }
};

export const SlidingToaster: FC<{ isOpen: boolean; direction?: Direction }> = ({
  children,
  isOpen,
  direction = Direction.BOTTOM,
}) => (
  <Container isOpen={isOpen ? 1 : 0} direction={direction}>
    {children}
  </Container>
);

const Container = styled.div<{ isOpen: 0 | 1; direction: Direction }>`
  ${({ isOpen, direction }) => getStyleFromDirection(isOpen, direction)}
  left: 50%;
  position: absolute;
  z-index: 999;
  transition: all 0.5s;
`;
