import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Status } from "../../../@types/status";
import {
  usePermissionsStatus,
  useUserPermissions,
} from "../../../store/slices/permissions/hooks";
import { handleGetUserPermissions } from "../../../store/slices/permissions/thunk";
import { useAccessGroupId } from "../../../store/slices/access-groups/hooks";
import { useAppDispatch } from "../../../store/types";
import { LoaderWithBackground } from "../../atoms/LoaderWithBackground/LoaderWithBackground";
import { ErrorLayer } from "../../molecules/ErrorLayer/ErrorLayer";

export const Permissions: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const accessGroupId = useAccessGroupId();
  const permissionsStatus = usePermissionsStatus();
  const permissions = useUserPermissions();
  const [t] = useTranslation("layers");

  useEffect(() => {
    dispatch(
      handleGetUserPermissions(accessGroupId, permissions === undefined)
    );
  }, [accessGroupId]);

  if (permissionsStatus === Status.LOADING) {
    return (
      <LoaderWithBackground>
        {t("PERMISSIONS_LAYER_LOADING_DESC")}
      </LoaderWithBackground>
    );
  }

  if (permissionsStatus === Status.SUCCESS) {
    return <>{children}</>;
  }

  if (permissionsStatus === Status.ERROR) {
    return <ErrorLayer />;
  }

  return null;
};
