import { useState } from "react";

import { IFiltersContext, SavedPreferences, UpdateFilterFonctionArgs } from ".";

const getPreferencesFromLocalStorage = (id: string): SavedPreferences => {
  const savedString = window.localStorage.getItem(id);
  if (savedString) {
    const parsedString = JSON.parse(savedString);
    return new Map(parsedString);
  }
  return new Map();
};

const savePreferencesInLocalStorage = (id: string, value: SavedPreferences) => {
  window.localStorage.setItem(id, JSON.stringify(Array.from(value)));
};

export const useGenerateFilterContext = (id: string): IFiltersContext => {
  const [preferences, setPreferences] = useState<SavedPreferences>(
    getPreferencesFromLocalStorage(id)
  );

  const updateWidgetPreference = (
    widgetId: string,
    arg: UpdateFilterFonctionArgs
  ) => {
    preferences.set(widgetId, {
      ...preferences.get(widgetId),
      [arg.key]: arg.value,
    });
    savePreferencesInLocalStorage(id, preferences);
    setPreferences(new Map(preferences));
  };

  return {
    preferences,
    updateWidgetPreference,
  };
};
