import { useTranslation } from "react-i18next";

import { CustomRemark } from "../../../../../components/atoms/CustomRemark/CustomRemark";
import { QuoteBox } from "../../../../../components/molecules/QuoteBox/QuoteBox";

export const Information = () => {
  const [t] = useTranslation("contentInvalidationPage");
  return (
    <QuoteBox>
      <CustomRemark>{t("INVALIDATION_INFORMATION")}</CustomRemark>
    </QuoteBox>
  );
};
