import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useIsMounted } from "../../../hooks/use-is-mounted";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import { Badge } from "../../atoms/Badge/Badge";
import { Loader } from "../../atoms/Loader/Loader";
import { LoaderContainer } from "../../atoms/LoaderContainer/LoaderContainer";
import { ExpandableContent } from "../../molecules/ExpandableContent/ExpandableContent";
import { SearchBar } from "../../molecules/StructuredList/SearchBar";
import {
  Bold,
  Column,
  DataContainer,
  Line,
  Regular,
  StatusColumn,
  Title,
} from "../../molecules/StructuredList/StructuredList";
import { Dropdown, IDropdownItem } from "../Dropdown/Dropdown";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { useProperties } from "../../../store/slices/caching/hooks";
import {
  PropertyMetaData,
  PropertyStatus,
} from "../../../models/metadata/properties";
import { useUserEmail } from "../../../store/slices/user/hooks";
import { Protected } from "../../atoms/Protected/Protected";
import { ExistingFeatures } from "../../../store/slices/feature-flags/types";

const defaultDropdownItem: IDropdownItem<string> = {
  label: "All",
  value: "",
  default: false,
};

const sortProperties = (a: PropertyMetaData, b: PropertyMetaData) =>
  a.status > b.status ? 1 : a.status < b.status ? -1 : 0;

export const PropertyListTab: React.FC = () => {
  const { t } = useTranslation("productionPage");
  const isMounted = useIsMounted();
  const [propertiesData, setPropertiesData] = useState<PropertyMetaData[]>();
  const [searchValue, setSearchValue] = useState<string>("");
  const properties = useProperties();
  const userEmail = useUserEmail();
  const history = useHistory();
  const [filter, setFilter] = useLocalStorage<IDropdownItem<string>>(
    `property_registry_filter_${userEmail}`,
    {
      ...defaultDropdownItem,
      default: true,
    }
  );

  const dropdownItems = [defaultDropdownItem].concat(
    Object.values(PropertyStatus).map((status) => {
      return {
        label: status[0].toUpperCase() + status.slice(1),
        value: status,
        default: status === filter.value,
        dataTestId: `production-property-status-${status}`,
      };
    })
  );

  useEffect(() => {
    if (properties) {
      if (isMounted.current) {
        setPropertiesData(properties);
      }
    }
  }, [isMounted]);

  const filteredProperties = useCallback((): PropertyMetaData[] | undefined => {
    if (propertiesData) {
      return propertiesData
        .filter(
          (item) =>
            (item.primaryAlias.includes(searchValue.trim()) ||
              item.name.includes(searchValue.trim()) ||
              item.propertyId.toString().includes(searchValue.trim())) &&
            (filter.value ? item.status === filter.value : true)
        )
        .sort(sortProperties);
    }
  }, [searchValue, propertiesData, filter]);

  return (
    <>
      <ExpandableContent
        title={t("PROPERTIES_DESCRIPTION_TITLE")}
        content={t("PROPERTIES_DESCRIPTION_CONTENT")}
      />
      <br />
      {propertiesData ? (
        <>
          <SearchBar
            dropdownID="status-selector"
            dropdownItems={dropdownItems}
            dropdownPlaceholder="Filter"
            dropdownTestID="production-property-dropdown"
            onDropdownSelect={setFilter}
            onSearchChange={setSearchValue}
            searchPlaceholder={t("PROPERTY_LIST_SEARCH_BAR_PLACEHOLDER")}
            searchTestID="production-property-input"
          />
          <DataContainer>
            <Line>
              <Column width="50%">
                <Title>{t("PROPERTY_LIST_COLUMN_PRIMARY_NAME")}</Title>
              </Column>
              <Column width="30%">
                <Title>{t("PROPERTY_LIST_COLUMN_PROPERTY_NAME")}</Title>
              </Column>
              <Column width="10%">
                <Title>{t("PROPERTY_LIST_COLUMN_PROPERTY_ID")}</Title>
              </Column>
              <StatusColumn width="5%">
                <StatusTitle>{t("PROPERTY_LIST_COLUMN_STATUS")}</StatusTitle>
              </StatusColumn>
            </Line>
            {propertiesData &&
              filteredProperties()!.map((item, index) => (
                <Line
                  key={index}
                  data-testid={`production-property-line-${index}`}
                >
                  <Column width="50%">
                    <Bold>{item.primaryAlias}</Bold>
                  </Column>
                  <Column width="30%">
                    <Regular>{item.name}</Regular>
                  </Column>
                  <Column width="10%">
                    <Regular>{item.propertyId}</Regular>
                  </Column>
                  <StatusColumn width="5%">
                    <Regular>
                      <Badge
                        label={
                          item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)
                        }
                        color={
                          item.status === PropertyStatus.ACTIVE
                            ? "green50"
                            : "grey40"
                        }
                      />
                    </Regular>
                  </StatusColumn>
                  <Protected flags={ExistingFeatures.RTM}>
                    <StatusColumn width="5%">
                      <Dropdown
                        placeholder=""
                        customButton={
                          <Icon
                            name={Icons.TRIPLE_DOT}
                            size={{ width: 18, height: 5 }}
                          />
                        }
                        id={`navigate-rtm-${item.propertyId}`}
                        items={[
                          {
                            label: "Navigate to real time monitoring",
                            value: "rtmlink",
                          },
                        ]}
                        onSelect={(elem) => {
                          history.push("/rtm/properties", {
                            propertyId: item.propertyId,
                          });
                        }}
                      />
                    </StatusColumn>
                  </Protected>
                </Line>
              ))}
          </DataContainer>
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  );
};

const StatusTitle = styled(Title)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
