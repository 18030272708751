import { IFeatures } from "../../../models/configuration/definitions";
import {
  DetailedFeatureCardBlock,
  DetailedFeatureCardRow,
} from "../../molecules/DetailedFeatureCard/DetailedFeatureCard";
import { FeatureComponent } from "./types";

type DefaultCacheComponent = FeatureComponent<
  Exclude<IFeatures["defaultCache"], undefined>
>;

export const DefaultCache: DefaultCacheComponent = ({
  feature: { cachePolicy },
  tPropertyPage,
}) => (
  <DetailedFeatureCardRow>
    <DetailedFeatureCardBlock
      title={tPropertyPage("FEATURE_CARD_DEFAULT_CACHE_TTL_TITLE")}
    >
      {cachePolicy}
    </DetailedFeatureCardBlock>
  </DetailedFeatureCardRow>
);
