import { ReactElement } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { Configuration } from "../../../models/configuration";
import { Button } from "../../atoms/Button/Button";
import { IModalChildren } from "../../atoms/Modal/Modal";
import { Tooltip } from "../Tooltip/Tooltip";
import { useDispatchAPIError } from "../../../store/slices/api-error/hooks";
import { useSubscriberId } from "../../../store/slices/subscriber/hooks";

interface PromoteToCurrent extends IModalChildren {
  canPromote: boolean;
  onSubmit: () => void;
  isMigration: boolean;
}

export const PromoteToCurrentModal = ({
  closeModal,
  canPromote,
  onSubmit,
  isMigration,
}: PromoteToCurrent): ReactElement => {
  const { t } = useTranslation("productionPage");
  const subscriberId = useSubscriberId();
  const handleMediaPortalError = useDispatchAPIError();

  return (
    <Container>
      <Title className="-text--h2">
        {t("PRODUCTION_MODAL_PROMOTE_TO_CURRENT_TITLE")}
      </Title>
      <Info>
        {isMigration
          ? t("PRODUCTION_MODAL_PROMOTE_TO_CURRENT_MIGRATION_INFO")
          : t("PRODUCTION_MODAL_PROMOTE_TO_CURRENT_INFO")}
      </Info>
      <ButtonsContainer>
        <CancelButtonContainer data-testid="promote-to-current-cancel-button">
          {closeModal && (
            <Button
              onClick={() => {
                closeModal();
              }}
              label={t("PRODUCTION_MODAL_PROMOTE_TO_CURRENT_CANCEL_BUTTON")}
              backgroundColor="baseLight"
              textColor="highlight"
              borderColor="highlight"
            />
          )}
        </CancelButtonContainer>
        <SubmitButtonContainer>
          {closeModal && (
            <Tooltip
              popupText={t("PRODUCTION_MODAL_PROMOTE_TO_CURRENT_POPUP_TEXT")}
              active={!canPromote}
              id="promote-to-current-submit-popup"
              dataTestId="promote-to-current-submit-popup"
            >
              <Button
                onClick={() => {
                  Configuration.production
                    .promoteCandidateToCurrent(subscriberId.toString())
                    .then(onSubmit)
                    .catch(handleMediaPortalError);
                  closeModal();
                }}
                label={t("PRODUCTION_MODAL_PROMOTE_TO_CURRENT_SUBMIT_BUTTON")}
                disabled={!canPromote}
                dataTestId="promote-to-current-submit-button"
              />
            </Tooltip>
          )}
        </SubmitButtonContainer>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.h2``;

const Info = styled.div`
  margin: 32px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButtonContainer = styled.div`
  padding-right: 15px;
`;

const SubmitButtonContainer = styled.div``;
