import { ReactElement } from "react";
import { Status } from "../../../@types/status";
import { Icon, Icons } from "../Icon/Icon";
import { Loader } from "../Loader/Loader";

export const QueryLoader = ({
  status,
  render = {
    [Status.IDLE]: <></>,
    [Status.LOADING]: <Loader />,
    [Status.SUCCESS]: <Icon name={Icons.CHECK} />,
    [Status.ERROR]: <Icon name={Icons.CROSS} color="red50" />,
  },
}: {
  status: Status;
  render?: {
    [Status.IDLE]: ReactElement;
    [Status.LOADING]: ReactElement;
    [Status.SUCCESS]: ReactElement;
    [Status.ERROR]: ReactElement;
  };
}): JSX.Element => {
  return render[status];
};
