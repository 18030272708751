import { Status } from "../../../@types/status";
import { IActiveConfigurationsResponse } from "../../../models/configuration/production";
import { useAppDispatch, useAppSelector } from "../../types";
import { handleFetchProduction } from "./thunks";
import type {
  CachingSlice,
  IIsDeployedData,
  LuaDefinitionType,
  Production,
} from "./types";

export const useProperties = (): CachingSlice["properties"] =>
  useAppSelector((store) => store.caching.properties);

export const useConfigurations = (): CachingSlice["configurations"] =>
  useAppSelector((store) => store.caching.configurations);

export const useDispatchProduction = (): (() => void) => {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(handleFetchProduction());
  };
};

export const useProduction = (): Production => {
  const value = useAppSelector((store) => store.caching.production);

  if (typeof value === "undefined") {
    throw new Error('"value" is undefined in "production"');
  }

  return value;
};

export const useProductionData = ():
  | IActiveConfigurationsResponse
  | undefined =>
  useAppSelector((store) => store.caching.production?.productionData);

export const useIsProductionDeployed = (configName: string): IIsDeployedData =>
  useAppSelector((store) => {
    const activeProductionData = store.caching.production?.productionData;
    const deployed: IIsDeployedData = {};

    if (activeProductionData) {
      if (activeProductionData.slots.current?.sourceConfigName === configName) {
        deployed.current =
          activeProductionData.slots.current.sourceConfigVersionId;
      }
      if (
        activeProductionData.slots.candidate?.sourceConfigName === configName
      ) {
        deployed.candidate =
          activeProductionData.slots.candidate.sourceConfigVersionId;
      }
    }

    return deployed;
  });

export const useSelectedConfiguration = (): CachingSlice["selectedConfiguration"] =>
  useAppSelector((store) => store.caching.selectedConfiguration);

export const useSelectedConfigurationName = (): string | undefined =>
  useAppSelector((store) => store.caching.selectedConfiguration?.configName);

export const useConfigurationsErrors = (): CachingSlice["errors"] =>
  useAppSelector((store) => store.caching.errors);

export const useCertificates = (): CachingSlice["certificates"] =>
  useAppSelector((store) => store.caching.certificates);

export const useCachingStatus = (): Status =>
  useAppSelector((store) => store.caching.status);

export const useCachingApiErrorCode = (): number | undefined =>
  useAppSelector((store) => store.caching.apiErrorCode);

export const useConfigurationDetailsStatus = (): Status =>
  useAppSelector((store) => store.caching.configDetailsStatus);

export const useLuaDefinitions = (): LuaDefinitionType[] =>
  useAppSelector(
    (store) => store.caching.selectedConfiguration?.config?.luaDefinitions
  ) ?? [];

export const useIsLatestVersionConfig = (version = "3.1"): boolean =>
  useAppSelector(
    (store) => store.caching.selectedConfiguration?.schemaVersion === version
  );
