import { FC, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { ProductionSlot } from "../../../models/configuration";
import { IProductionConfigurationData } from "../../../models/configuration/production";
import { UserRoles } from "../../../models/permissions";
import { Badge } from "../../atoms/Badge/Badge";
import { Dropdown } from "../../organisms/Dropdown/Dropdown";
import { Icon, Icons } from "../../atoms/Icon/Icon";
import { EditAllocationModal } from "../EditAllocationModal/EditAllocationModal";
import { Protected } from "../../atoms/Protected/Protected";
import { Modal } from "../../atoms/Modal/Modal";
import { PromoteToCurrentModal } from "../PromoteToCurrentModal/PromoteToCurrentModal";
import { DeleteSlotModal } from "../DeleteSlotModal/DeleteSlotModal";

interface IProductionSlotCard {
  slot: ProductionSlot;
  currentConfigData: IProductionConfigurationData | undefined;
  candidateConfigData: IProductionConfigurationData | undefined;
  activationPercent: number;
  onUpdate: () => void;
  isMigration?: boolean;
  version: number;
}

enum ProductionActions {
  ALLOCATION = "allocation",
  HISTORY = "history",
  PROMOTE = "promote",
  DELETE = "delete",
}

export const ProductionSlotCard: FC<IProductionSlotCard> = ({
  slot,
  currentConfigData,
  candidateConfigData,
  activationPercent,
  onUpdate,
  version,
  isMigration = false,
}) => {
  const { t } = useTranslation("productionPage");
  const [tmisc] = useTranslation("misc");

  const [activeAction, setActiveAction] = useState<ProductionActions>();

  const slotLabels = new Map<ProductionSlot, string>([
    [ProductionSlot.CURRENT, t("CURRENT")],
    [ProductionSlot.CANDIDATE, t("CANDIDATE")],
  ]);

  const dropdownItems = [
    {
      label: t("PRODUCTION_SLOTS_CARD_DROPDOWN_ALLOCATION"),
      value: ProductionActions.ALLOCATION,
      dataTestId: "production-dropdown-edit-allocation",
    },
  ]
    .concat(
      slot === ProductionSlot.CANDIDATE
        ? [
            {
              label: t("PRODUCTION_SLOTS_CARD_DROPDOWN_PROMOTE"),
              value: ProductionActions.PROMOTE,
              dataTestId: "production-dropdown-promote",
            },
          ]
        : []
    )
    .concat(
      slot === ProductionSlot.CANDIDATE && !isMigration
        ? [
            {
              label: t("PRODUCTION_SLOTS_CARD_DROPDOWN_DELETE"),
              value: ProductionActions.DELETE,
              dataTestId: "production-dropdown-delete",
            },
          ]
        : []
    );

  const renderActionModal = useCallback(() => {
    switch (activeAction) {
      case ProductionActions.ALLOCATION:
        return (
          <EditAllocationModal
            slot={slot}
            activationPercent={activationPercent}
            current={
              currentConfigData
                ? `${currentConfigData.sourceConfigName} ${tmisc("VERSION", {
                    version: currentConfigData.sourceConfigVersionId,
                  })}`
                : undefined
            }
            candidate={
              candidateConfigData
                ? `${candidateConfigData.sourceConfigName} ${tmisc("VERSION", {
                    version: candidateConfigData.sourceConfigVersionId,
                  })}`
                : undefined
            }
            onSubmit={onUpdate}
            isMigration={isMigration}
          />
        );
      case ProductionActions.DELETE:
        return <DeleteSlotModal onSubmit={onUpdate} />;
      case ProductionActions.PROMOTE:
        return (
          <PromoteToCurrentModal
            onSubmit={onUpdate}
            canPromote={activationPercent === 100}
            isMigration={isMigration}
          />
        );
      default:
        return;
    }
  }, [activeAction]);

  const configData =
    slot === ProductionSlot.CURRENT ? currentConfigData! : candidateConfigData!;

  return (
    <>
      <Header>
        <Title>
          {isMigration && t("MIGRATION").concat(" ")}
          {slot && slotLabels.get(slot)!}
        </Title>
        <Protected permissions={UserRoles.EDIT_CONFIG}>
          <Dropdown
            items={dropdownItems}
            id={`${slot}-actions`}
            placeholder=""
            onSelect={(item) => setActiveAction(item.value)}
            customButton={
              <DropdownButtonContainer
                data-testid={`production-slot-${slot}-dropdown`}
              >
                <Options
                  name={Icons.TRIPLE_DOT}
                  size={{ width: 18, height: 5 }}
                />
              </DropdownButtonContainer>
            }
          />
        </Protected>
      </Header>
      <ConfigurationBox slot={slot}>
        <NameContainer>
          <Name slot={slot}>
            {t("PRODUCTION_SLOT_CARD_SOURCE_CONFIG_LABEL", {
              name: configData.sourceConfigName,
              version: configData.sourceConfigVersionId,
            })}
          </Name>
          <BadgeContainer>
            <Badge
              label={
                configData.state[0].toUpperCase() + configData.state.slice(1)
              }
            />
          </BadgeContainer>
        </NameContainer>
        <TrafficData slot={slot}>
          <TrafficLabel>{t("PRODUCTION_SLOT_CARD_TRAFFIC_LABEL")}</TrafficLabel>
          <TrafficValue>{`${activationPercent}%`}</TrafficValue>
        </TrafficData>
      </ConfigurationBox>
      <MetadataContainer>
        <Promoted>
          <Label>{t("PRODUCTION_SLOT_CARD_PROMOTED_LABEL")}</Label>
          <Data>
            {format(new Date(configData.promotedTime), "MMM do yyyy")} at
            {format(new Date(configData.promotedTime), " h:mm a")}
          </Data>
        </Promoted>
        <Version>
          <Label>{t("PRODUCTION_SLOT_CARD_SOURCE_CONFIG_VERSION_LABEL")}</Label>
          <Data>{version}</Data>
        </Version>
      </MetadataContainer>
      {activeAction !== undefined && (
        <Modal
          customButton={<></>}
          open={activeAction !== undefined}
          onClose={() => setActiveAction(undefined)}
        >
          {renderActionModal()!}
        </Modal>
      )}
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h5`
  margin: 0 !important;
  color: ${({ theme }) => theme.text.secondary};
`;

const DropdownButtonContainer = styled.div`
  height: 1rem;
  display: flex;
  align-items: center;
`;

const Options = styled(Icon)`
  path {
    fill: ${({ theme }) => theme.icon.primary};
  }
`;

const ConfigurationBox = styled.div<{ slot: ProductionSlot }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, slot }) =>
    slot === ProductionSlot.CURRENT
      ? theme.backgrounds.highlight
      : theme.backgrounds.muted};
  padding: 20px;
  margin-top: 20px;
  border-radius: 4px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Name = styled.h5<{ slot: ProductionSlot }>`
  margin: 0 !important;
  color: ${({ theme, slot }) =>
    slot === ProductionSlot.CURRENT
      ? theme.text.primaryAlt
      : theme.text.primary};
  padding-right: 8px;
`;

const BadgeContainer = styled.div`
  height: 1rem;
`;

const TrafficData = styled.div<{ slot: ProductionSlot }>`
  display: flex;
  align-items: center;
  color: ${({ theme, slot }) =>
    slot === ProductionSlot.CURRENT
      ? theme.text.primaryAlt
      : theme.text.primary};
`;

const TrafficLabel = styled.h5`
  margin: 0 !important;
`;

const TrafficValue = styled.h4`
  margin: 0 !important;
  padding-left: 8px;
  font-weight: 800 !important;
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const Promoted = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
`;

const Version = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 0 !important;
  color: ${({ theme }) => theme.text.secondary};
`;

const Data = styled.h4`
  margin: 0 !important;
`;
